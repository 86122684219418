export enum Inclusions {
  AllBedroomsDown = "allBedroomsDown",
  AllBedroomsUp = "allBedroomsUp",
  AtticFan = "atticFan",
  BarWet = "barWet",
  BeamedCeilings = "beamedCeilings",
  BreakfastBar = "breakfastBar",
  BuiltinBookcase = "builtinBookcase",
  BuiltinEntertainment = "builtinEntertainment",
  CarbonMonoxideDetector = "carbonMonoxideDetector",
  CathedralCeiling = "cathedralCeiling",
  CeilingFans = "ceilingFans",
  CentralDistributionPlumbingSystem = "centralDistributionPlumbingSystem",
  Chandelier = "chandelier",
  CofferedCeiling = "cofferedCeiling",
  CrownMolding = "crownMolding",
  DoubleVanity = "doubleVanity",
  Dumbwaiter = "dumbwaiter",
  EatinKitchen = "eatinKitchen",
  Elevator = "elevator",
  EntranceFoyer = "entranceFoyer",
  FireAlarmSystem = "fireAlarmSystem",
  FloorPlanOpen = "floorPlanOpen",
  FloorPlanSplit = "floorPlanSplit",
  FormalDining = "formalDining",
  GameRoom = "gameRoom",
  GraniteCounters = "graniteCounters",
  HighCeilings = "highCeilings",
  HisandHersClosets = "hisandHersClosets",
  InLawSuites = "inLawSuites",
  KitDinCombo = "kitDinCombo",
  LaminateCounters = "laminateCounters",
  LivDinCombo = "livDinCombo",
  LowFlowPlumbingFixtures = "lowFlowPlumbingFixtures",
  MasterDown = "masterDown",
  MasterUp = "masterUp",
  MultipleDiningAreas = "multipleDiningAreas",
  MultipleLivingAreas = "multipleLivingAreas",
  NaturalWoodwork = "naturalWoodwork",
  NoDining = "noDining",
  Office = "office",
  Pantry = "pantry",
  RecessedLighting = "recessedLighting",
  SecuritySystemOwned = "securitySystemOwned",
  SeparateShower = "separateShower",
  ShowerOnly = "showerOnly",
  ShowerTubCombo = "showerTubCombo",
  Shutters = "shutters",
  ShuttersPlantation = "shuttersPlantation",
  SmartHome = "smartHome",
  SmartThermostat = "smartThermostat",
  SmokeDetector = "smokeDetector",
  SplitBedroom = "splitBedroom",
  StoneCounters = "stoneCounters",
  Storage = "storage",
  TileCounters = "tileCounters",
  TrackLighting = "trackLighting",
  TrayCeiling = "trayCeiling",
  TubGarden = "tubGarden",
  TubJacuzzi = "tubJacuzzi",
  TubSoaking = "tubSoaking",
  VaultedCeiling = "vaultedCeiling",
  WalkInCloset = "walkInCloset",
  WaterSenseFixture = "waterSenseFixture",
  WaterSoftenerOwned = "waterSoftenerOwned",
  WiredForData = "wiredForData",
  WiredForSecurity = "wiredForSecurity",
  WiredForSpeakers = "wiredForSpeakers"
}

export const InclusionsLabel = new Map<Inclusions, string>([
  [Inclusions.AllBedroomsDown, "All Bedrooms Down"],
  [Inclusions.AllBedroomsUp, "All Bedrooms Up"],
  [Inclusions.AtticFan, "Attic Fan"],
  [Inclusions.BarWet, "Bar-Wet"],
  [Inclusions.BeamedCeilings, "Beamed Ceilings"],
  [Inclusions.BreakfastBar, "Breakfast Bar"],
  [Inclusions.BuiltinBookcase, "Built-in Bookcase"],
  [Inclusions.BuiltinEntertainment, "Built-in Entertainment"],
  [Inclusions.CarbonMonoxideDetector, "Carbon Monoxide Detector"],
  [Inclusions.CathedralCeiling, "Cathedral Ceiling(s)"],
  [Inclusions.CeilingFans, "Ceiling Fan(s)"],
  [
    Inclusions.CentralDistributionPlumbingSystem,
    "Central Distribution Plumbing System"
  ],
  [Inclusions.Chandelier, "Chandelier"],
  [Inclusions.CofferedCeiling, "Coffered Ceiling(s)"],
  [Inclusions.CrownMolding, "Crown Molding"],
  [Inclusions.DoubleVanity, "Double Vanity"],
  [Inclusions.Dumbwaiter, "Dumbwaiter"],
  [Inclusions.EatinKitchen, "Eat-in Kitchen"],
  [Inclusions.Elevator, "Elevator"],
  [Inclusions.EntranceFoyer, "Entrance Foyer"],
  [Inclusions.FireAlarmSystem, "Fire Alarm System"],
  [Inclusions.FloorPlanOpen, "Floor Plan-Open"],
  [Inclusions.FloorPlanSplit, "Floor Plan-Split"],
  [Inclusions.FormalDining, "Formal Dining"],
  [Inclusions.GameRoom, "Game Room"],
  [Inclusions.GraniteCounters, "Granite Counters"],
  [Inclusions.HighCeilings, "High Ceilings"],
  [Inclusions.HisandHersClosets, "His and Hers Closets"],
  [Inclusions.InLawSuites, "In-Law Suites"],
  [Inclusions.KitDinCombo, "Kit/Din Combo"],
  [Inclusions.LaminateCounters, "Laminate Counters"],
  [Inclusions.LivDinCombo, "Liv/Din Combo"],
  [Inclusions.LowFlowPlumbingFixtures, "Low Flow Plumbing Fixtures"],
  [Inclusions.MasterDown, "Master Down"],
  [Inclusions.MasterUp, "Master Up"],
  [Inclusions.MultipleDiningAreas, "Multiple Dining Areas"],
  [Inclusions.MultipleLivingAreas, "Multiple Living Areas"],
  [Inclusions.NaturalWoodwork, "Natural Woodwork"],
  [Inclusions.NoDining, "No Dining"],
  [Inclusions.Office, "Office"],
  [Inclusions.Pantry, "Pantry"],
  [Inclusions.RecessedLighting, "Recessed Lighting"],
  [Inclusions.SecuritySystemOwned, "Security System-Owned"],
  [Inclusions.SeparateShower, "Separate Shower"],
  [Inclusions.ShowerOnly, "Shower Only"],
  [Inclusions.ShowerTubCombo, "Shower/Tub Combo"],
  [Inclusions.Shutters, "Shutters"],
  [Inclusions.ShuttersPlantation, "Shutters-Plantation"],
  [Inclusions.SmartHome, "Smart Home"],
  [Inclusions.SmartThermostat, "Smart Thermostat"],
  [Inclusions.SmokeDetector, "Smoke Detector"],
  [Inclusions.SplitBedroom, "Split Bedroom"],
  [Inclusions.StoneCounters, "Stone Counters"],
  [Inclusions.Storage, "Storage"],
  [Inclusions.TileCounters, "Tile Counters"],
  [Inclusions.TrackLighting, "Track Lighting"],
  [Inclusions.TrayCeiling, "Tray Ceiling(s)"],
  [Inclusions.TubGarden, "Tub - Garden"],
  [Inclusions.TubJacuzzi, "Tub - Jacuzzi"],
  [Inclusions.TubSoaking, "Tub-Soaking"],
  [Inclusions.VaultedCeiling, "Vaulted Ceiling(s)"],
  [Inclusions.WalkInCloset, "Walk-In Closet(s)"],
  [Inclusions.WaterSenseFixture, "WaterSense Fixture(s)"],
  [Inclusions.WaterSoftenerOwned, "Water Softener-Owned"],
  [Inclusions.WiredForData, "Wired for Data"],
  [Inclusions.WiredForSecurity, "Wired for Security"],
  [Inclusions.WiredForSpeakers, "Wired for Speakers"]
]);
