export enum WaterSewer {
  AerobicSeptic = "aerobicSeptic",
  CityAtStreet = "cityAtStreet",
  CityOnProperty = "cityOnProperty",
  CityWater = "cityWater",
  CoOpWater = "coOpWater",
  MunicipalUtilityDistrict = "municipalUtilityDistrict",
  PublicSewer = "publicSewer",
  SeparateSewerMeters = "separateSewerMeters",
  SeparateWaterMeters = "separateWaterMeters",
  Septic = "septic",
  SepticRequired = "septicRequired",
  OtherWaterSeeRemarks = "otherWaterSeeRemarks"
}

export const WaterSewerLabel = new Map<WaterSewer, string>([
  [WaterSewer.AerobicSeptic, "Aerobic Septic"],
  [WaterSewer.CityAtStreet, "City at Street"],
  [WaterSewer.CityOnProperty, "City on Property"],
  [WaterSewer.CityWater, "City Water"],
  [WaterSewer.CoOpWater, "Co-Op Water"],
  [WaterSewer.MunicipalUtilityDistrict, "Municipal Utility District"],
  [WaterSewer.PublicSewer, "Public Sewer"],
  [WaterSewer.SeparateSewerMeters, "Separate Sewer Meters"],
  [WaterSewer.SeparateWaterMeters, "Separate Water Meters"],
  [WaterSewer.Septic, "Septic"],
  [WaterSewer.SepticRequired, "Septic Required"],
  [WaterSewer.OtherWaterSeeRemarks, "Other Water-See Remarks"]
]);
