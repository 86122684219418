import { FieldRulesProps } from "@homesusa/form";

const roomNumberRule = (
  label: string,
  requiredToSubmit = true
): FieldRulesProps => ({
  label,
  maxLength: 1,
  requiredToSubmit,
  numberFormat: {
    min: 0
  }
});

export const spacesDimensionsRules = {
  bathsFull: roomNumberRule("# Full Baths"),
  bathsHalf: roomNumberRule("# Half Baths"),
  livingAreas: roomNumberRule("# Living Areas", false),
  diningAreas: roomNumberRule("# Dining Areas", false),
  numBedrooms: roomNumberRule("# Bedrooms")
};
