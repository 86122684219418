import React, { Fragment } from "react";

import { JsonImportStatus } from "core/enums";
import { ToolbarAlertStyled as AlertStyled } from "core/styles";

export function JsonStatusAlert({
  status
}: {
  status: JsonImportStatus;
}): JSX.Element {
  if (status == JsonImportStatus.AwaitingApproval) {
    return <AlertStyled severity="warning">Awaiting Approval</AlertStyled>;
  }

  if (status == JsonImportStatus.Rejected) {
    return <AlertStyled severity="error">Rejected</AlertStyled>;
  }

  if (status == JsonImportStatus.Approved) {
    return <AlertStyled severity="success">Approved</AlertStyled>;
  }

  if (status == JsonImportStatus.Matched) {
    return <AlertStyled severity="success">Matched</AlertStyled>;
  }

  return <Fragment></Fragment>;
}
