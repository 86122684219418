export enum GreenFeatures {
  DroughtTolerantPlants = "droughtTolerantPlants",
  EnhancedAirFiltration = "enhancedAirFiltration",
  EFIrrigationControl = "efIrrigationControl",
  EnergyRecoveryVentilator = "energyRecoveryVentilator",
  LowFlowCommode = "lowFlowCommode",
  LowFlowFixture = "lowFlowFixture",
  MechanicalFreshAir = "mechanicalFreshAir",
  RainFreezeSensors = "rainFreezeSensors",
  RainWaterCatchment = "rainWaterCatchment"
}
export const GreenFeaturesLabel = new Map<GreenFeatures, string>([
  [GreenFeatures.DroughtTolerantPlants, "Drought Tolerant Plants"],
  [GreenFeatures.EnhancedAirFiltration, "Enhanced Air Filtration"],
  [GreenFeatures.EFIrrigationControl, "EF Irrigation Control"],
  [GreenFeatures.EnergyRecoveryVentilator, "Energy Recovery Ventilator"],
  [GreenFeatures.LowFlowCommode, "Low Flow Commode"],
  [GreenFeatures.LowFlowFixture, "Low Flow Fixture"],
  [GreenFeatures.MechanicalFreshAir, "Mechanical Fresh Air"],
  [GreenFeatures.RainFreezeSensors, "Rain/Freeze Sensors"],
  [GreenFeatures.RainWaterCatchment, "Rain Water Catchment"]
]);
