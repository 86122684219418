import React from "react";
import { NeedingActionStatuses } from "@homesusa/core";
import { GridResponse } from "@homesusa/grid-table";
import { PhotoRequestGrid } from "core/interfaces";

export const useHasPendingPhotoRequest = (
  entityId: string,
  getPhotoRequests?: (params: {
    entityId: string;
    status?: string[];
    pageSize?: number;
    startIndex?: number;
  }) => Promise<GridResponse<PhotoRequestGrid>>
): {
  hasPendingPhotoRequest: boolean;
  setHasPendingPhotoRequest: (value: boolean) => void;
} => {
  const [hasPendingPhotoRequest, setHasPendingPhotoRequest] =
    React.useState(false);

  const checkPendingPhotoRequest = React.useCallback(
    async (entityId: string): Promise<void> => {
      if (!getPhotoRequests) {
        return;
      }
      const requests = await getPhotoRequests({
        entityId,
        status: NeedingActionStatuses,
        pageSize: 100,
        startIndex: 0
      });
      setHasPendingPhotoRequest(requests.total > 0);
    },
    [getPhotoRequests]
  );

  React.useEffect(() => {
    checkPendingPhotoRequest(entityId);
  }, [entityId, checkPendingPhotoRequest]);

  return { hasPendingPhotoRequest, setHasPendingPhotoRequest };
};
