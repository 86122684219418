export enum Laundry {
  Closet = "closet",
  DryerConnectionElec = "dryerConnectionElec",
  DryerConnectionGas = "dryerConnectionGas",
  Electric = "electric",
  Gas = "gas",
  InKitchen = "inKitchen",
  Inside = "inside",
  LaundryRoom = "laundryRoom",
  LowerLevel = "lowerLevel",
  MainLevel = "mainLevel",
  Sink = "sink",
  StackableOnly = "stackableOnly",
  UpperLevel = "upperLevel",
  UtilityLaundryRoom = "utilityLaundryRoom",
  WasherConnection = "washerConnection"
}

export const LaundryLabel = new Map<Laundry, string>([
  [Laundry.Closet, "Closet"],
  [Laundry.DryerConnectionElec, "Dryer Connection Elec"],
  [Laundry.DryerConnectionGas, "Dryer Connection Gas"],
  [Laundry.Electric, "Electric"],
  [Laundry.Gas, "Gas"],
  [Laundry.InKitchen, "In Kitchen"],
  [Laundry.Inside, "Inside"],
  [Laundry.LaundryRoom, "Laundry Room"],
  [Laundry.LowerLevel, "Lower Level"],
  [Laundry.MainLevel, "Main Level"],
  [Laundry.Sink, "Sink"],
  [Laundry.StackableOnly, "Stackable Only"],
  [Laundry.UpperLevel, "Upper Level"],
  [Laundry.UtilityLaundryRoom, "Utility/Laundry Room"],
  [Laundry.WasherConnection, "Washer Connection"]
]);
