export enum GreenBuildingVerification {
  ENERGYSTARCertifiedHomes = "energystarCertifiedHomes",
  HERSIndexScore = "hersIndexScore",
  HomeEnergyScore = "homeEnergyScore",
  HomePerformanceWithENERGYSTAR = "homePerformanceWithENERGYSTAR",
  LEEDForHomes = "leedForHomes",
  NGBSNewConstruction = "ngbsNewConstruction",
  NGBSSmallProjectsRemodel = "ngbsSmallProjectsRemodel",
  WaterSense = "waterSense"
}

export const GreenBuildingVerificationLabel = new Map<
  GreenBuildingVerification,
  string
>([
  [
    GreenBuildingVerification.ENERGYSTARCertifiedHomes,
    "ENERGY STAR Certified Homes"
  ],
  [GreenBuildingVerification.HERSIndexScore, "HERS Index Score"],
  [GreenBuildingVerification.HomeEnergyScore, "Home Energy Score"],
  [
    GreenBuildingVerification.HomePerformanceWithENERGYSTAR,
    "Home Performance with ENERGY STAR"
  ],
  [GreenBuildingVerification.LEEDForHomes, "LEED For Homes"],
  [GreenBuildingVerification.NGBSNewConstruction, "NGBS New Construction"],
  [
    GreenBuildingVerification.NGBSSmallProjectsRemodel,
    "NGBS Small Projects Remodel"
  ],
  [GreenBuildingVerification.WaterSense, "WaterSense"]
]);
