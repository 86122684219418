import { AlertType, AlertColumnName } from "../enums";

const baseListingColumns = [
  AlertColumnName.MlsStatus,
  AlertColumnName.Address,
  AlertColumnName.Community,
  AlertColumnName.Builder
];

const baseListingColumnsExtended = [
  ...baseListingColumns,
  AlertColumnName.MlsNumber,
  AlertColumnName.MarketCode
];

export const AlertTypeColumns = new Map<AlertType, AlertColumnName[]>([
  [
    AlertType.PastDueEstimatedClosingDate,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.EstimatedClosedDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.PastDueEstimatedCompletionDate,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.ConstructionCompletionDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.AgentBonusExpirationDate,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.BonusExpirationDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.TempOffMarketBackOnMarketDaysOrLess,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.BackOnMarketDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.LockedListings,
    [
      AlertColumnName.MlsNumber,
      AlertColumnName.MlsStatus,
      AlertColumnName.Address,
      AlertColumnName.Community,
      AlertColumnName.Builder,
      AlertColumnName.LastUser,
      AlertColumnName.SysModifiedOn
    ]
  ],
  [
    AlertType.ExpiringListings,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.ExpirationDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.CompletedHomesWithoutPhotoRequest,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.ConstructionCompletionDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.PastDuePhotoRequests,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.AssignedOn,
      AlertColumnName.AssignedTo,
      AlertColumnName.ContactDate,
      AlertColumnName.ScheduleDate
    ]
  ],
  [
    AlertType.NotListedInMls,
    [
      AlertColumnName.MarketCode,
      AlertColumnName.Address,
      AlertColumnName.Community,
      AlertColumnName.Builder,
      AlertColumnName.LastUser,
      AlertColumnName.SysModifiedOn
    ]
  ],
  [
    AlertType.CommunityProfilesMissingInformation,
    [
      AlertColumnName.MarketCode,
      AlertColumnName.Community,
      AlertColumnName.Builder,
      AlertColumnName.Obtained,
      AlertColumnName.MissingField
    ]
  ],
  [
    AlertType.EstimatedClosingDaysOrLess,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.EstimatedClosedDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.CompletionDateDueDaysOrLess,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.ConstructionCompletionDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.AgentBonusExpirationDateOrLess,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.BonusExpirationDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.TempOffMarketBackOnMarket,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.BackOnMarketDate,
      AlertColumnName.LastUser
    ]
  ],
  [
    AlertType.CurrentDaysOnMarketOverDays,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.Dom,
      AlertColumnName.LastUser,
      AlertColumnName.SysModifiedOn
    ]
  ],
  [
    AlertType.InadequatePublicRemarks,
    [
      ...baseListingColumnsExtended,
      AlertColumnName.PublicRemarks,
      AlertColumnName.LastUser,
      AlertColumnName.SysModifiedOn
    ]
  ],
  [
    AlertType.MarketChangesSinceYesterday,
    [
      AlertColumnName.MarketCode,
      AlertColumnName.MlsNumber,
      AlertColumnName.Address,
      AlertColumnName.Community,
      AlertColumnName.Builder,
      AlertColumnName.OldPrice,
      AlertColumnName.NewPrice,
      AlertColumnName.OldmlsStatus,
      AlertColumnName.NewmlsStatus,
      AlertColumnName.ChangedOn
    ]
  ],
  [
    AlertType.OrphanListings,
    [
      AlertColumnName.MarketCode,
      AlertColumnName.MlsStatus,
      AlertColumnName.Address,
      AlertColumnName.Builder,
      AlertColumnName.LastUser,
      AlertColumnName.SysModifiedOn
    ]
  ],
  [
    AlertType.XmlListingUpdatedWithoutRequest,
    [...baseListingColumnsExtended, AlertColumnName.XmlErrorMessage]
  ],
  [
    AlertType.XmlLockedListingsImported,
    [
      ...baseListingColumns,
      AlertColumnName.City,
      AlertColumnName.LastUser,
      AlertColumnName.SysModifiedOn
    ]
  ],
  [AlertType.ScraperHomesToListNow, baseListingColumnsExtended],
  [AlertType.ScraperLeaseToListNow, baseListingColumnsExtended],
  [AlertType.ScraperAvailableCommunities, baseListingColumnsExtended],
  [AlertType.ScraperAvailablePlans, baseListingColumnsExtended],
  [AlertType.ScraperListingsAvailableForRestore, baseListingColumnsExtended],
  [AlertType.ScraperLeasesAvailableForRestore, baseListingColumnsExtended],
  [AlertType.ScraperFilesProcessed, baseListingColumnsExtended]
]);
