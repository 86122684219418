export enum BillingFrequency {
  Annually = "annually",
  BiMonthly = "biMonthly",
  BiWeekly = "biWeekly",
  Daily = "daily",
  Monthly = "monthly",
  OneTime = "oneTime",
  Quarterly = "quarterly",
  Seasonal = "seasonal",
  SemiAnnual = "semiAnnual",
  SemiMonthly = "semiMonthly",
  Weekly = "weekly",
  Other = "other"
}
export const BillingFrequencyLabel = new Map<BillingFrequency, string>([
  [BillingFrequency.Annually, "Annually"],
  [BillingFrequency.BiMonthly, "Bi-Monthly"],
  [BillingFrequency.BiWeekly, "Bi-Weekly"],
  [BillingFrequency.Daily, "Daily"],
  [BillingFrequency.Monthly, "Monthly"],
  [BillingFrequency.OneTime, "One Time"],
  [BillingFrequency.Quarterly, "Quarterly"],
  [BillingFrequency.Seasonal, "Seasonal"],
  [BillingFrequency.SemiAnnual, "Semi-Annual"],
  [BillingFrequency.SemiMonthly, "Semi-Monthly"],
  [BillingFrequency.Weekly, "Weekly"],
  [BillingFrequency.Other, "Other"]
]);
