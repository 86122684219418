export enum Foundation {
  BrickMortar = "brickMortar",
  Slab = "slab",
  Stone = "stone",
  Other = "other"
}

export const FoundationLabel = new Map<Foundation, string>([
  [Foundation.BrickMortar, "Brick/Mortar"],
  [Foundation.Slab, "Slab"],
  [Foundation.Stone, "Stone"],
  [Foundation.Other, "Other"]
]);
