export enum TopoLandDescription {
  Acres025 = "acres025",
  Acres255 = "acres255",
  Acres51 = "acres51",
  Acres13 = "acres13",
  AlleyAccess = "alleyAccess",
  BackstoGolfCourse = "backstoGolfCourse",
  BackstoGreenbelt = "backstoGreenbelt",
  Canal = "canal",
  CornerLot = "cornerLot",
  CuldeSac = "culdeSac",
  ExceptionalView = "exceptionalView",
  Greenbelt = "greenbelt",
  Irregular = "irregular",
  Level = "level",
  MatureTrees = "matureTrees",
  OnGolfCourse = "onGolfCourse",
  Open = "open",
  Secluded = "secluded",
  Sloping = "sloping",
  WoodedPartial = "woodedPartial",
  Xeriscape = "xeriscape",
  ZeroLotLine = "zeroLotLine",
  OtherSeeRemarks = "otherSeeRemarks",
  None = "none"
}
export const TopoLandDescriptionLabel = new Map<TopoLandDescription, string>([
  [TopoLandDescription.Acres025, "0-.25 Acres"],
  [TopoLandDescription.Acres255, ".25-.5 Acres"],
  [TopoLandDescription.Acres51, ".5-1 Acres"],
  [TopoLandDescription.Acres13, "1-3 Acres"],
  [TopoLandDescription.AlleyAccess, "Alley Access"],
  [TopoLandDescription.BackstoGolfCourse, "Backs to Golf Course"],
  [TopoLandDescription.BackstoGreenbelt, "Backs to Greenbelt"],
  [TopoLandDescription.Canal, "Canal"],
  [TopoLandDescription.CornerLot, "Corner Lot"],
  [TopoLandDescription.CuldeSac, "Cul-de-Sac"],
  [TopoLandDescription.ExceptionalView, "Exceptional View"],
  [TopoLandDescription.Greenbelt, "Greenbelt"],
  [TopoLandDescription.Irregular, "Irregular"],
  [TopoLandDescription.Level, "Level"],
  [TopoLandDescription.MatureTrees, "Mature Trees"],
  [TopoLandDescription.OnGolfCourse, "On Golf Course"],
  [TopoLandDescription.Open, "Open"],
  [TopoLandDescription.Secluded, "Secluded"],
  [TopoLandDescription.Sloping, "Sloping"],
  [TopoLandDescription.WoodedPartial, "Wooded-Partial"],
  [TopoLandDescription.Xeriscape, "Xeriscape"],
  [TopoLandDescription.ZeroLotLine, "Zero Lot Line"],
  [TopoLandDescription.OtherSeeRemarks, "Other-See Remarks"],
  [TopoLandDescription.None, "None"]
]);
