import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";
import {
  DocumentsAvailable,
  DocumentsAvailableLabel,
  FrontFaces,
  FrontFacesLabel,
  SqFtSource,
  SqFtSourceLabel,
  ListingType,
  ListingTypeLabel
} from "markets/ctx/enums";

import {
  constructionStageRules,
  constructionStartYearRules,
  typeCategoryRules,
  yearBuiltSourceRules
} from "../../sections/property-rules";

export const propertyInfoRules: FormRules = {
  constructionCompletionDate: {
    label: "Estimated Completion Date",
    tooltip: "Estimated completion date where applicable."
  },
  constructionStartYear: constructionStartYearRules,
  constructionStage: constructionStageRules,
  legalDescription: {
    requiredToSubmit: true,
    label: "Legal Description",
    maxLength: 255
  },
  propertyId: {
    label: "Property ID"
  },
  updateGeocodes: { label: "Update Geocodes" },
  latitude: latitudeRule,
  longitude: longitudeRule,
  frontFaces: {
    label: "Front Faces",
    options: getOptionsFromEnumAndMap(
      Object.values(FrontFaces),
      FrontFacesLabel
    )
  },
  typeCategory: typeCategoryRules,
  listingType: {
    label: "Listing Type",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(ListingType),
      ListingTypeLabel
    )
  },
  yearBuiltSource: yearBuiltSourceRules,
  documentsAvailable: {
    label: "Documents On File",
    requiredToSubmit: true,
    lastOptions: [DocumentsAvailable.None, DocumentsAvailable.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(DocumentsAvailable),
      DocumentsAvailableLabel
    ),
    rows: 2,
    noneOption: [DocumentsAvailable.None]
  },
  sqFtTotal: {
    requiredToSubmit: true,
    label: "Apx Total SqFt",
    numberFormat: {
      min: 500,
      max: 10000,
      thousandSeparator: true
    },
    maxLength: 10
  },
  sqFtSource: {
    label: "SqFt Source",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(SqFtSource),
      SqFtSourceLabel
    )
  }
};
