import React, { Fragment } from "react";

import { SubmitButton } from "@homesusa/layout";
import { RoleEmployee } from "@homesusa/core";
import { useHasRole, useUserProfile } from "@homesusa/auth";
import { LockedStatus } from "core/enums";

export function UnlockButton({
  onClick,
  lockedByUsername,
  lockedById,
  status
}: {
  onClick?: () => Promise<void>;
  hideButton?: boolean;
  lockedByUsername?: string;
  lockedById?: string;
  status?: LockedStatus;
}): JSX.Element {
  const { hasEmployeeRole, isMlsAdministrator } = useHasRole();
  const userProfile = useUserProfile();

  if (
    !status ||
    LockedStatus.NoLocked === status ||
    (LockedStatus.Closed === status && !isMlsAdministrator)
  ) {
    return <Fragment></Fragment>;
  }

  const lockedBy = lockedByUsername ? (
    <span>Locked by {lockedByUsername} </span>
  ) : null;

  if (
    [LockedStatus.LockedByUser, LockedStatus.LockedBySystem].includes(status) &&
    !isMlsAdministrator
  ) {
    return <div>{lockedBy}</div>;
  }

  return (
    <div>
      {lockedBy}
      {onClick &&
        (userProfile?.id == lockedById ||
          isMlsAdministrator ||
          hasEmployeeRole([RoleEmployee.CompanyAdmin])) && (
          <SubmitButton
            buttonProps={{
              sx: {
                backgroundColor: "white",
                color: (theme): string => theme.palette.primary.dark,
                borderBlockColor: (theme): string => theme.palette.primary.dark
              }
            }}
            onClick={onClick}
          >
            Unlock
          </SubmitButton>
        )}
    </div>
  );
}
