export enum PatioAndPorchFeatures {
  Awnings = "awnings",
  Covered = "covered",
  Deck = "deck"
}

export const PatioAndPorchFeaturesLabel = new Map<
  PatioAndPorchFeatures,
  string
>([
  [PatioAndPorchFeatures.Awnings, "Awning(s)"],
  [PatioAndPorchFeatures.Covered, "Covered"],
  [PatioAndPorchFeatures.Deck, "Deck"]
]);
