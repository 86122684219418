import React from "react";

import { RoleEmployee, useMounted } from "@homesusa/core";
import { AuthCompanyContext } from "@homesusa/auth";
import { FormMode } from "@homesusa/form";
import { MediaContext } from "@homesusa/media";

export const useListingReadOnly = (
  propsReadOnly = false
): {
  formMode: FormMode;
  changeFormMode: (mode: FormMode) => void;
} => {
  const [formMode, setFormMode] = React.useState<FormMode>(FormMode.Update);
  const { mediaStateDispatch } = React.useContext(MediaContext);
  const { currentEmployee } = React.useContext(AuthCompanyContext);
  const mounted = useMounted();

  React.useEffect(() => {
    const isReadOnly =
      !mounted.current ||
      !!propsReadOnly ||
      currentEmployee?.roleName == RoleEmployee.Readonly ||
      currentEmployee?.roleName == RoleEmployee.SalesEmployeeReadonly ||
      currentEmployee?.roleName == RoleEmployee.CompanyAdminReadonly;
    setFormMode(isReadOnly ? FormMode.ReadOnly : FormMode.Update);
    mediaStateDispatch({ type: "ReadOnly", payload: isReadOnly });
  }, [propsReadOnly, mounted.current, currentEmployee]);

  const changeFormMode = (mode: FormMode): void => setFormMode(mode);

  return { formMode, changeFormMode };
};
