import { FormRules } from "@homesusa/form";
import { salesOfficeRules } from "core/rules/sales-office";
import { ctxCityRule } from "../../sections";

export const ctxSalesOfficeRules: FormRules = {
  ...salesOfficeRules,
  salesOfficeCity: {
    ...ctxCityRule,
    required: false
  }
};
