import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";
import { BillingReport, Comparison } from "../interfaces";
import { BillFilter, PaginationFilter } from "../interfaces/filters";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

const getBillingReport =
  (market: MarketCode) =>
  async (params: BillFilter): Promise<GridResponse<BillingReport>> => {
    const gatewayMarket = getGatewayMarket(market);
    const apiUrl = `${gatewayUrl}/api/${gatewayMarket}`;
    return axios
      .get<GridResponse<BillingReport>>(`${apiUrl}/bills`, {
        params
      })
      .then((response) => response.data);
  };

const createInvoice = async (
  listingIds: string[],
  market: MarketCode,
  startDate?: Date,
  endDate?: Date,
  companyId?: string
): Promise<string> => {
  const gatewayMarket = getGatewayMarket(market);
  const apiUrl = `${gatewayUrl}/api/${gatewayMarket}`;
  const response = await axios.post<string>(`${apiUrl}/bills`, {
    listingIds,
    companyId,
    startDate,
    endDate
  });
  return response.data;
};

const getComparisonReport = async (
  // eslint-disable-next-line
  { market, ...params }: PaginationFilter & { market: MarketCode }
): Promise<GridResponse<Comparison>> => {
  // const gatewayMarket = getGatewayMarket(market);
  const report: Comparison[] = [
    {
      listingBroker: "HomesUSA.com",
      builder: "Brohn",
      cdom: "214",
      mlsNumber: "6040153",
      mlsStatus: "active",
      community: "Arrowpoint",
      address: "117 Crescent",
      city: "GEORGETOWN",
      listDate: new Date("2022-09-30"),
      refreshed: new Date("2022-09-28"),
      mlsPrice: 457990,
      varience: 0,
      builderPrice: 457990
    },
    {
      listingBroker: "HomesUSA.com",
      builder: "Brohn",
      cdom: "90",
      mlsNumber: "1966135",
      mlsStatus: "active",
      community: "Lago Vista",
      address: "21708 Osage",
      city: "LAGOVISTA",
      listDate: new Date("2022-09-30"),
      refreshed: new Date("2022-09-28"),
      mlsPrice: 399990,
      varience: 24000,
      builderPrice: 423990
    }
  ];
  return {
    data: report,
    total: report.length
  };
};

export { getBillingReport, getComparisonReport, createInvoice };
