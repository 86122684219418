import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  Appliances,
  AppliancesLabel,
  BodyOfWater,
  BodyOfWaterLabel,
  Construction,
  ConstructionLabel,
  CoolingSystem,
  CoolingSystemLabel,
  Disclosures,
  DisclosuresLabel,
  DistanceToWaterAccess,
  DistanceToWaterAccessLabel,
  DocumentsAvailable,
  DocumentsAvailableLabel,
  ExteriorFeature,
  ExteriorFeatureLabel,
  Fence,
  FenceLabel,
  FireplaceDescription,
  FireplaceDescriptionLabel,
  Flooring,
  FlooringLabel,
  Foundation,
  FoundationLabel,
  GarageDescription,
  GarageDescriptionLabel,
  HeatSystem,
  HeatSystemLabel,
  InteriorFeatures,
  InteriorFeaturesLabel,
  Kitchen,
  KitchenLabel,
  LaundryFeatures,
  LaundryFeaturesLabel,
  LaundryLocation,
  LaundryLocationLabel,
  NeighborhoodAmenities,
  NeighborhoodAmenitiesLabel,
  PatioAndPorchFeatures,
  PatioAndPorchFeaturesLabel,
  PrimaryBathroom,
  PrimaryBathroomLabel,
  PrimaryBedroom,
  PrimaryBedroomLabel,
  RestrictionsDesc,
  RestrictionsDescLabel,
  RoofDescription,
  RoofDescriptionLabel,
  SecurityFeatures,
  SecurityFeaturesLabel,
  Utility,
  UtilityLabel,
  View,
  ViewLabel,
  Water,
  WaterLabel,
  WaterSewer,
  WaterSewerLabel,
  WaterfrontFeatures,
  WaterfrontFeaturesLabel,
  WindowsFeatures,
  WindowsFeaturesLabel
} from "markets/abor/enums";

export const distanceToWaterAccessRule = {
  label: "Distance to Water Access",
  options: getOptionsFromEnumAndMap(
    Object.values(DistanceToWaterAccess),
    DistanceToWaterAccessLabel
  )
};
export const waterfrontFeaturesRule = {
  label: "Waterfront Features",
  options: getOptionsFromEnumAndMap(
    Object.values(WaterfrontFeatures),
    WaterfrontFeaturesLabel
  ),
  noneOption: [WaterfrontFeatures.None],
  lastOptions: [WaterfrontFeatures.None],
  requiredToSubmit: true
};

export const utilitiesRules: FormRules = {
  fireplaces: {
    requiredToSubmit: true,
    label: "# Fireplaces",
    numberFormat: {
      min: 0
    },
    maxLength: 1
  },
  fireplaceDescription: {
    label: "Fireplace Description",
    options: getOptionsFromEnumAndMap(
      Object.values(FireplaceDescription),
      FireplaceDescriptionLabel
    ),
    rows: 2
  },

  floors: {
    label: "Flooring",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Flooring), FlooringLabel),
    rows: 2,
    max: 4
  },

  appliances: {
    label: "Appliances",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Appliances),
      AppliancesLabel
    ),
    rows: 2
  },

  roofDescription: {
    label: "Roof",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(RoofDescription),
      RoofDescriptionLabel
    ),
    rows: 2,
    max: 4
  },

  foundation: {
    label: "Foundation",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Foundation),
      FoundationLabel
    ),
    rows: 2
  },

  constructionMaterials: {
    label: "Construction",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Construction),
      ConstructionLabel
    ),
    rows: 2
  },

  fencing: {
    label: "Fence",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Fence), FenceLabel),
    rows: 2
  },

  view: {
    label: "View",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(View), ViewLabel),
    rows: 2,
    lastOptions: [View.None],
    noneOption: [View.None]
  },

  patioAndPorchFeatures: {
    label: "Patio and Porch Features",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(PatioAndPorchFeatures),
      PatioAndPorchFeaturesLabel
    ),
    rows: 2,
    lastOptions: [PatioAndPorchFeatures.None],
    noneOption: [PatioAndPorchFeatures.None]
  },

  utilitiesDescription: {
    label: "Utility",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Utility), UtilityLabel),
    rows: 2
  },
  kitchenFeatures: {
    label: "Kitchen",
    options: getOptionsFromEnumAndMap(Object.values(Kitchen), KitchenLabel),
    max: 6
  },
  garageDescription: {
    label: "Garage Description",
    options: getOptionsFromEnumAndMap(
      Object.values(GarageDescription),
      GarageDescriptionLabel
    ),
    requiredToSubmit: true
  },
  garageSpaces: {
    label: "Garage Spaces",
    minLength: 1,
    maxLength: 10,
    requiredToSubmit: true
  },
  interiorFeatures: {
    label: "Interior",
    options: getOptionsFromEnumAndMap(
      Object.values(InteriorFeatures),
      InteriorFeaturesLabel
    ),
    requiredToSubmit: true
  },
  windowFeatures: {
    label: "Windows Features",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(WindowsFeatures),
      WindowsFeaturesLabel
    ),
    lastOptions: [WindowsFeatures.None],
    noneOption: [WindowsFeatures.None]
  },
  securityFeatures: {
    label: "Security Features",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(SecurityFeatures),
      SecurityFeaturesLabel
    ),
    lastOptions: [SecurityFeatures.None],
    noneOption: [SecurityFeatures.None]
  },
  waterAccessDescription: {
    label: "Primary Bathroom",
    options: getOptionsFromEnumAndMap(
      Object.values(PrimaryBathroom),
      PrimaryBathroomLabel
    )
  },
  masterBedroomFeatures: {
    label: "Primary Bedroom",
    options: getOptionsFromEnumAndMap(
      Object.values(PrimaryBedroom),
      PrimaryBedroomLabel
    )
  },
  laundryFeatures: {
    label: "Laundry Features",
    options: getOptionsFromEnumAndMap(
      Object.values(LaundryFeatures),
      LaundryFeaturesLabel
    ),
    rows: 2
  },
  laundryLocation: {
    label: "Laundry Location",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(LaundryLocation),
      LaundryLocationLabel
    ),
    rows: 2,
    max: 3
  },
  exteriorFeatures: {
    label: "Exterior",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(ExteriorFeature),
      ExteriorFeatureLabel
    ),
    max: 12,
    noneOption: [ExteriorFeature.None],
    lastOptions: [ExteriorFeature.None]
  },
  restrictionsDescription: {
    label: "Restrictions Desc",
    options: getOptionsFromEnumAndMap(
      Object.values(RestrictionsDesc),
      RestrictionsDescLabel
    ),
    requiredToSubmit: true,
    lastOptions: [RestrictionsDesc.None],
    noneOption: [RestrictionsDesc.None]
  },
  neighborhoodAmenities: {
    label: "Neighborhood Amenities",
    options: getOptionsFromEnumAndMap(
      Object.values(NeighborhoodAmenities),
      NeighborhoodAmenitiesLabel
    ),
    requiredToSubmit: true,
    lastOptions: [NeighborhoodAmenities.None],
    noneOption: [NeighborhoodAmenities.None],
    max: 20
  },
  waterSource: {
    label: "Water",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Water), WaterLabel),
    rows: 2,
    max: 2
  },
  heatSystem: {
    label: "Heating System",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(HeatSystem),
      HeatSystemLabel
    )
  },
  coolingSystem: {
    requiredToSubmit: true,
    label: "Cooling System",
    options: getOptionsFromEnumAndMap(
      Object.values(CoolingSystem),
      CoolingSystemLabel
    )
  },
  waterSewer: {
    label: "Water/Sewer",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(WaterSewer),
      WaterSewerLabel
    )
  },
  disclosures: {
    label: "Disclosures",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Disclosures),
      DisclosuresLabel
    )
  },
  documentsAvailable: {
    label: "Documents Available",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(DocumentsAvailable),
      DocumentsAvailableLabel
    )
  },
  waterBodyName: {
    label: "Body of Water",
    options: getOptionsFromEnumAndMap(
      Object.values(BodyOfWater),
      BodyOfWaterLabel
    )
  }
};
