export enum Flooring {
  Carpet = "carpet",
  Concrete = "concrete",
  Laminate = "laminate",
  Linoleum = "linoleum",
  Marble = "marble",
  Parquet = "parquet",
  SeeRemarks = "seeRemarks",
  Slate = "slate",
  Terrazzo = "terrazzo",
  Tile = "tile",
  Vinyl = "vinyl",
  Wood = "wood"
}
export const FlooringLabel = new Map<Flooring, string>([
  [Flooring.Carpet, "Carpet"],
  [Flooring.Concrete, "Concrete"],
  [Flooring.Laminate, "Laminate"],
  [Flooring.Linoleum, "Linoleum"],
  [Flooring.Marble, "Marble"],
  [Flooring.Parquet, "Parquet"],
  [Flooring.SeeRemarks, "See Remarks"],
  [Flooring.Slate, "Slate"],
  [Flooring.Terrazzo, "Terrazzo"],
  [Flooring.Tile, "Tile"],
  [Flooring.Vinyl, "Vinyl"],
  [Flooring.Wood, "Wood"]
]);
