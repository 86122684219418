export enum MasterBedroom {
  CeilingFan = "ceilingFan",
  ClosetsMulti = "closetsMulti",
  ClosetsWalkIn = "closetsWalkIn",
  DualMasters = "dualMasters",
  OutsideAccess = "outsideAccess",
  ShowerSeparate = "showerSeparate",
  ShowerOnly = "showerOnly",
  ShowerTubCombo = "showerTubCombo",
  SittingStudyRoom = "sittingStudyRoom",
  Split = "split",
  TubGarden = "tubGarden",
  TubJetted = "tubJetted",
  TubOnly = "tubOnly",
  VanityDouble = "vanityDouble",
  VanitySeparate = "vanitySeparate",
  VanitySingle = "vanitySingle"
}

export const MasterBedroomLabel = new Map<MasterBedroom, string>([
  [MasterBedroom.CeilingFan, "Ceiling Fan"],
  [MasterBedroom.ClosetsMulti, "Closets - Multi"],
  [MasterBedroom.ClosetsWalkIn, "Closets - Walk-In"],
  [MasterBedroom.DualMasters, "Dual Masters"],
  [MasterBedroom.OutsideAccess, "Outside Access"],
  [MasterBedroom.ShowerSeparate, "Shower - Separate"],
  [MasterBedroom.ShowerOnly, "Shower Only"],
  [MasterBedroom.ShowerTubCombo, "Shower/Tub Combo"],
  [MasterBedroom.SittingStudyRoom, "Sitting/Study Room"],
  [MasterBedroom.Split, "Split"],
  [MasterBedroom.TubGarden, "Tub - Garden"],
  [MasterBedroom.TubJetted, "Tub - Jetted"],
  [MasterBedroom.TubOnly, "Tub Only"],
  [MasterBedroom.VanityDouble, "Vanity - Double"],
  [MasterBedroom.VanitySeparate, "Vanity - Separate"],
  [MasterBedroom.VanitySingle, "Vanity - Single"]
]);
