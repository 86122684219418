export enum FireplaceLocation {
  Den = "den",
  DiningRoom = "diningRoom",
  FamilyRoom = "familyRoom",
  GuestQuarters = "guestQuarters",
  Kitchen = "kitchen",
  LivingRoom = "livingRoom",
  MasterBathMasterBedroom = "masterBathMasterBedroom",
  Patio = "patio",
  OtherFPLocation = "otherFPLocation"
}

export const FireplaceLocationLabel: Record<FireplaceLocation, string> = {
  [FireplaceLocation.Den]: "Den",
  [FireplaceLocation.DiningRoom]: "Dining Room",
  [FireplaceLocation.FamilyRoom]: "Family Room",
  [FireplaceLocation.GuestQuarters]: "Guest Quarters",
  [FireplaceLocation.Kitchen]: "Kitchen",
  [FireplaceLocation.LivingRoom]: "Living Room",
  [FireplaceLocation.MasterBathMasterBedroom]: "Master Bath Master Bedroom",
  [FireplaceLocation.Patio]: "Patio",
  [FireplaceLocation.OtherFPLocation]: "Other FP Location"
};
