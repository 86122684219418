import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  Disclosure,
  DisclosureTypeLabel,
  HomeFaces,
  HomeFacesLabel,
  Pool,
  PoolLabel,
  RestrictionsDescription,
  RestrictionsDescriptionLabel,
  WasherConnection,
  WasherConnectionLabel,
  WaterfrontFeature,
  WaterfrontFeatureLabel
} from "markets/har/enums";
import { utilitiesRules } from "../../sections";

export const featuresInfoRules: FormRules = {
  ...utilitiesRules,
  propertyDescription: {
    label: "Property Description",
    requiredToSubmit: false,
    maxLength: 924,
    minLength: 25,
    minLengthSuggestion: 100,
    rows: 5,
    helperText:
      "MLS number, Built by <BUILDER> and Completion Info will be added as a prefix to this field. DO NOT INCLUDE."
  },
  homeFaces: {
    label: "Front Faces",
    options: getOptionsFromEnumAndMap(Object.values(HomeFaces), HomeFacesLabel)
  },
  pool: {
    label: "Private Pool Description",
    options: getOptionsFromEnumAndMap(Object.values(Pool), PoolLabel),
    rows: 2
  },
  hasPool: {
    requiredToSubmit: true,
    label: "Pool - Private"
  },
  washerConnections: {
    label: "Washer/Dryer",
    options: getOptionsFromEnumAndMap(
      Object.values(WasherConnection),
      WasherConnectionLabel
    ),
    rows: 2
  },
  restrictionsDescription: {
    label: "Restrictions",
    options: getOptionsFromEnumAndMap(
      Object.values(RestrictionsDescription),
      RestrictionsDescriptionLabel
    ),
    rows: 2,
    requiredToSubmit: true
  },
  disclosures: {
    label: "Disclosures",
    options: getOptionsFromEnumAndMap(
      Object.values(Disclosure),
      DisclosureTypeLabel
    ),
    rows: 2,
    requiredToSubmit: true
  },
  waterfrontFeatures: {
    label: "Waterfront Features",
    options: getOptionsFromEnumAndMap(
      Object.values(WaterfrontFeature),
      WaterfrontFeatureLabel
    )
  }
};
