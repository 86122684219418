import React, { Fragment } from "react";

import { Button, Typography, Grid, Box } from "@mui/material";

import { Stepper as HusaStepper, Company } from "@homesusa/core";
import { FormCreateBtnGroupStyled } from "../styles";
import { SelectCompany } from "./select-company.component";

export const FormCreateStepsContext = React.createContext<{
  company?: Company;
  setCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
}>(Object.assign({}));

export function FormCreateSteps({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [activeStep, setActiveStep] = React.useState(0);
  const [company, setCompany] = React.useState<Company>();

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <FormCreateStepsContext.Provider value={{ company, setCompany }}>
      <HusaStepper
        steps={["Select", "Submit"]}
        activeStep={activeStep}
        setActiveStep={(value): void => setActiveStep(value)}
      >
        <HusaStepper.Content index={0} selected={activeStep}>
          <Fragment>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Select Company</Typography>
              </Grid>
              <Grid item xs={12}>
                <SelectCompany
                  onSelect={(value): void => setCompany(value)}
                  defaultSelected={company?.id}
                />
              </Grid>
            </Grid>
            {company && (
              <FormCreateBtnGroupStyled>
                <Button onClick={handleNext}>Next</Button>
              </FormCreateBtnGroupStyled>
            )}
          </Fragment>
        </HusaStepper.Content>
        <HusaStepper.Content index={1} selected={activeStep}>
          <Box sx={{ mt: 3 }} />
          {company ? children : null}
        </HusaStepper.Content>
      </HusaStepper>
    </FormCreateStepsContext.Provider>
  );
}
