import React from "react";
import { CheckCircle, InsertDriveFile } from "@mui/icons-material";

import { ImportSource } from "core/enums";
import { ListType } from "modules/dashboard/enums";
import { ProcessButton } from "./process-button.component";
import { ListLaterButton } from "./list-later-button/list-later-component";
import { DeleteButton } from "./delete-button.component";

export function ImpotedActionsButton({
  listingId,
  importSource,
  isSuggestedComps = false
}: {
  listingId: string;
  importSource: ImportSource;
  isSuggestedComps?: boolean;
}): JSX.Element {
  return (
    <div className="actions">
      {!isSuggestedComps ? (
        <>
          <ProcessButton
            listingId={listingId}
            listType={ListType.Now}
            iconButton={<CheckCircle />}
            color="success"
            importSource={importSource}
          />
          <ListLaterButton listId={listingId} importSource={importSource} />
        </>
      ) : (
        <ProcessButton
          listingId={listingId}
          listType={ListType.Compare}
          importSource={importSource}
          iconButton={<InsertDriveFile />}
        />
      )}
      <DeleteButton importSource={importSource} listingId={listingId} />
    </div>
  );
}
