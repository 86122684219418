export enum RealtorType {
  ResSnglFam = "resSnglFam",
  ResCondoTwnhs = "resCondoTwnhs",
  ResMobileHm = "resMobileHm",
  Rental = "rental"
}

export const RealtorTypeLabel: Record<RealtorType, string> = {
  [RealtorType.ResSnglFam]: "Res - Sngl Fam",
  [RealtorType.ResCondoTwnhs]: "Res - Condo/Twnhs",
  [RealtorType.ResMobileHm]: "Res - Mobile Hm",
  [RealtorType.Rental]: "Rental"
};
