import React from "react";

import { Columns } from "@homesusa/grid-table";
import { useHasRole } from "@homesusa/auth";
import { RoleEmployee } from "@homesusa/core";
import { AuthCompanyContext } from "@homesusa/auth";
import { getMarketCityRule } from "markets/utils";
import { ImportSource } from "core/enums";
import { XmlListing } from "../interfaces/alerts/xml";
import { XmlListingGridColumns } from "../constants";
import { ImpotedActionsButton } from "../components/import-list-now-buttons";

type columnXml = Columns<XmlListing>;

export const useXmlGridColumns = (isSuggestedComps: boolean): columnXml => {
  const { hasEmployeeRole } = useHasRole();
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const isReadOnly = React.useMemo(
    () =>
      hasEmployeeRole([
        RoleEmployee.Readonly,
        RoleEmployee.CompanyAdminReadonly
      ]),
    [hasEmployeeRole]
  );
  const baseColumns = React.useMemo(
    () => [
      ...XmlListingGridColumns(
        getMarketCityRule(currentMarket)?.options ?? {}
      ).map((column) => ({
        ...column,
        Cell: ({
          row,
          index
        }: {
          row: { original: XmlListing };
          index: number;
        }): JSX.Element => {
          const value =
            typeof column.accessor === "function"
              ? column.accessor(row.original, index, {
                  subRows: [],
                  depth: 0,
                  data: []
                })
              : row.original[column.accessor as keyof XmlListing];

          return (
            <div
              style={{ color: row.original.listLaterDate ? "red" : "inherit" }}
            >
              {value}
            </div>
          );
        }
      }))
    ],
    [currentMarket]
  );
  const columns = React.useMemo(() => {
    if (isReadOnly) {
      return baseColumns;
    } else {
      return [
        ...baseColumns,
        {
          Header: "Actions",
          disableSortBy: true,
          accessor: (data: XmlListing): string => data.id,
          disableTooltipCell: true,
          Cell: function ListNow({ value }: { value: string }): JSX.Element {
            return (
              <ImpotedActionsButton
                isSuggestedComps={isSuggestedComps}
                listingId={value}
                importSource={ImportSource.Xml}
              />
            );
          }
        }
      ];
    }
  }, [isReadOnly, baseColumns, isSuggestedComps]);

  return columns;
};
