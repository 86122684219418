export enum NameOfPid {
  Colonies = "colonies",
  Greenways = "greenways",
  HeritageHills = "heritageHills",
  Pinnacle = "pinnacle",
  PointWest = "pointWest",
  QuailCreek = "quailCreek",
  TownSquare = "townSquare",
  Tutbury = "tutbury",
  Vineyards = "vineyards"
}

export const NameOfPidLabel: Record<NameOfPid, string> = {
  [NameOfPid.Colonies]: "Colonies PID",
  [NameOfPid.Greenways]: "Greenways PID",
  [NameOfPid.HeritageHills]: "Heritage Hills PID",
  [NameOfPid.Pinnacle]: "Pinnacle PID",
  [NameOfPid.PointWest]: "Point West PID",
  [NameOfPid.QuailCreek]: "Quail Creek PID",
  [NameOfPid.TownSquare]: "Town Square PID",
  [NameOfPid.Tutbury]: "Tutbury PID",
  [NameOfPid.Vineyards]: "Vineyards PID"
};
