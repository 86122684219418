import { ChartState } from "../interfaces";
import { ChartStateActionType, ChartStateActions } from "./chart-state.actions";

export const initialState: ChartState = {
  totalRemiders: { value: 0, total: 0 },
  totalUrgents: { value: 0, total: 0 },
  totalDetailds: { value: 0, total: 0 },
  totalXml: { value: 0, total: 0 },
  totalAgentBonus: 0,
  totalActiveAndPending: 0,
  totalUnlisted: 0,
  totalLockedListings: 0,
  listingStatus: [
    { name: "Active", dataPoints: [] },
    { name: "Pending", dataPoints: [] },
    { name: "Sold", dataPoints: [] }
  ],
  discrepancyData: {
    inventoryInXml: 0,
    inventoryListedInMLS: 0,
    listedInMlsAndBuilder: 0,
    listingDiscrepancy: 0,
    averageDiscrepancy: 0,
    totalDiscrepancy: 0
  }
};

export const chartStateReducer = (
  state: ChartState,
  action: ChartStateActions
): ChartState => {
  switch (action.type) {
    case ChartStateActionType.SetTotalReminders:
      return {
        ...state,
        totalRemiders: action.payload
      };

    case ChartStateActionType.SetTotalUrgents:
      return {
        ...state,
        totalUrgents: action.payload
      };

    case ChartStateActionType.SetTotalDetailed:
      return {
        ...state,
        totalDetailds: action.payload
      };

    case ChartStateActionType.SetTotalXml:
      return {
        ...state,
        totalXml: action.payload
      };

    case ChartStateActionType.SetTotalAgentBonus:
      return {
        ...state,
        totalAgentBonus: action.payload
      };

    case ChartStateActionType.SetListingStatus:
      return {
        ...state,
        listingStatus: action.payload
      };

    case ChartStateActionType.SetTotalActiveAndPending:
      return {
        ...state,
        totalActiveAndPending: action.payload
      };

    case ChartStateActionType.SetTotalLockedListings:
      return {
        ...state,
        totalLockedListings: action.payload
      };

    case ChartStateActionType.SetTotalUnlisted:
      return {
        ...state,
        totalUnlisted: action.payload
      };

    case ChartStateActionType.SetInitialState:
      return {
        ...action.payload
      };

    case ChartStateActionType.SetDiscrepancyData:
      return {
        ...state,
        discrepancyData: {
          inventoryListedInMLS: action.payload.mlsListings,
          inventoryInXml: action.payload.xmlListings,
          listedInMlsAndBuilder: action.payload.listingsInBoth,
          listingDiscrepancy: action.payload.listingsPriceDiscrepancy,
          averageDiscrepancy: action.payload.averageDiscrepancy,
          totalDiscrepancy: action.payload.totalDiscrepancy
        }
      };

    case ChartStateActionType.Clean:
      return initialState;

    default:
      return state;
  }
};
