export enum GreenVerificationSource {
  Assessor = "assessor",
  Builder = "builder",
  ContractorOrInstaller = "contractorOrInstaller",
  ProgramSponsor = "programSponsor",
  ProgramVerifier = "programVerifier"
}

export const GreenVerificationSourceLabel = new Map<
  GreenVerificationSource,
  string
>([
  [GreenVerificationSource.Assessor, "Assessor"],
  [GreenVerificationSource.Builder, "Builder"],
  [GreenVerificationSource.ContractorOrInstaller, "Contractor or Installer"],
  [GreenVerificationSource.ProgramSponsor, "Program Sponsor"],
  [GreenVerificationSource.ProgramVerifier, "Program Verifier"]
]);
