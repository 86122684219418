export enum AlertColumnName {
  MarketCode = "marketCode",
  MlsNumber = "mlsNumber",
  MlsStatus = "mlsStatus",
  Address = "address",
  Community = "subdivision",
  Builder = "ownerName",
  EstimatedClosedDate = "estimatedClosedDate",
  LastUser = "modifiedBy",
  ConstructionCompletionDate = "constructionCompletionDate",
  BonusExpirationDate = "bonusExpirationDate",
  BackOnMarketDate = "backOnMarketDate",
  SysModifiedOn = "sysModifiedOn",
  ExpirationDate = "expirationDate",
  AssignedOn = "assignedOn",
  AssignedTo = "assignedTo",
  ContactDate = "contactDate",
  ScheduleDate = "scheduleDate",
  Obtained = "obtained",
  MissingField = "missingField",
  PublicRemarks = "publicRemarks",
  OldPrice = "oldPrice",
  NewPrice = "newPrice",
  OldmlsStatus = "oldStatus",
  NewmlsStatus = "newStatus",
  ChangedOn = "marketModifiedOn",
  XmlErrorMessage = "xmlErrorMessage",
  Dom = "dom",
  City = "city"
}
