export enum SprinklerSystemDescription {
  Automatic = "automatic",
  Backyard = "backyard",
  DripOnlyBubblers = "dripOnlyBubblers",
  FrontYard = "frontYard",
  InGround = "inGround",
  Partial = "partial",
  RainSensor = "rainSensor",
  Zoned = "zoned",
  OtherSeeRemarks = "otherSeeRemarks"
}
export const SprinklerSystemDescriptionLabel = new Map<
  SprinklerSystemDescription,
  string
>([
  [SprinklerSystemDescription.Automatic, "Automatic"],
  [SprinklerSystemDescription.Backyard, "Backyard"],
  [SprinklerSystemDescription.DripOnlyBubblers, "Drip Only/Bubblers"],
  [SprinklerSystemDescription.FrontYard, "Front Yard"],
  [SprinklerSystemDescription.InGround, "In-Ground"],
  [SprinklerSystemDescription.Partial, "Partial"],
  [SprinklerSystemDescription.RainSensor, "Rain Sensor"],
  [SprinklerSystemDescription.Zoned, "Zoned"],
  [SprinklerSystemDescription.OtherSeeRemarks, "Other-See Remarks"]
]);
