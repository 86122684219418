export enum LotImprovements {
  StateHighway = "stateHighway",
  StreetGutters = "streetGutters",
  Sidewalks = "sidewalks",
  PrivateRoad = "privateRoad",
  StreetPaved = "streetPaved",
  Other = "other",
  FireHydrantWithinFiveHundred = "fireHydrantWithinFiveHundred",
  Dirt = "dirt",
  Curbs = "curbs",
  CityStreet = "cityStreet",
  Asphalt = "asphalt",
  Streetlights = "streetlights",
  InterstateHighwayOneMileOrLess = "interstateHighwayOneMileOrLess",
  EasementRoad = "easementRoad"
}

export const LotImprovementsLabel = new Map<LotImprovements, string>([
  [LotImprovements.StateHighway, "State Highway"],
  [LotImprovements.StreetGutters, "Street Gutters"],
  [LotImprovements.Sidewalks, "Sidewalks"],
  [LotImprovements.PrivateRoad, "Private Road"],
  [LotImprovements.StreetPaved, "Street Paved"],
  [LotImprovements.Other, "Other"],
  [LotImprovements.FireHydrantWithinFiveHundred, "Fire Hydrant w/in 500'"],
  [LotImprovements.Dirt, "Dirt"],
  [LotImprovements.Curbs, "Curbs"],
  [LotImprovements.CityStreet, "City Street"],
  [LotImprovements.Asphalt, "Asphalt"],
  [LotImprovements.Streetlights, "Streetlights"],
  [
    LotImprovements.InterstateHighwayOneMileOrLess,
    "Interstate Hwy - 1 Mile or less"
  ],
  [LotImprovements.EasementRoad, "Easement Road"]
]);
