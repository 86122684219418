import React from "react";
import { Tooltip, Typography } from "@mui/material";

export function TruncateText({
  text,
  maxWidth = 180,
  fontSize = "18px"
}: {
  text: string;
  maxWidth?: number;
  fontSize?: number | string;
}): JSX.Element {
  return (
    <Tooltip title={text} sx={{ maxWidth, fontSize }}>
      <Typography noWrap>{text}</Typography>
    </Tooltip>
  );
}
