import React from "react";
import { Box, Typography } from "@mui/material";

export const Legend = ({
  color,
  total,
  title,
  maxDigitsTotal
}: {
  color: string;
  total: number;
  title: string;
  maxDigitsTotal: number;
}): JSX.Element => {
  const totalStyle = {
    color,
    minWidth: `${maxDigitsTotal}ch`,
    display: "inline-block"
  };
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <div>
        <Box
          sx={{
            backgroundColor: color,
            width: "13px",
            height: "13px",
            borderRadius: "100%"
          }}
        />
      </div>
      <Typography fontSize={"13px"} color="GrayText" sx={{ minWidth: "100%" }}>
        <span style={totalStyle}>{total}</span> - {title}
      </Typography>
    </Box>
  );
};
