import { FieldRulesProps, FormRules } from "@homesusa/form";

const bonusExpirationDateRule = (): FieldRulesProps => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const today3y = new Date(today);
  today3y.setFullYear(today3y.getFullYear() + 3);

  return {
    label: "Concession Expiration Date",
    minDate: today,
    maxDate: today3y
  };
};

export const agentBonusRules: FormRules = {
  bonusExpirationDate: bonusExpirationDateRule(),
  hasBonusWithAmount: { label: "Seller Concession With Amount" },
  agentBonusAmountType: {
    label: "Seller Concession Amount Type"
  },
  agentBonusAmount: {
    label: "Seller Concession Amount",
    tooltip:
      "Seller Concession field with an amount is required even if the amount is 0"
  },
  readableAgentBonusAmount: { label: "Seller Concession Amount" },
  hasAgentBonus: {
    label: "Seller Concession",
    options: { true: "Yes", false: "No" }
  }
};
