import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  RoomLevel,
  RoomLevelLabel,
  RoomType,
  RoomTypeLabel
} from "markets/amarillo/enums";
import { RoomRules } from "markets/amarillo/interfaces";

export const roomsRules: RoomRules = {
  fields: {
    roomType: {
      label: "Rooms Name",
      options: getOptionsFromEnumAndMap(Object.values(RoomType), RoomTypeLabel)
    },
    level: {
      label: "Level",
      options: getOptionsFromEnumAndMap(
        Object.values(RoomLevel),
        RoomLevelLabel
      ),
      sort: false
    },
    width: {
      label: "Width",
      numberFormat: {
        min: 4
      },
      maxLength: 2
    },
    length: {
      label: "Length",
      numberFormat: {
        min: 4
      },
      maxLength: 2
    },
    remarks: {
      label: "Remarks",
      maxLength: 50
    }
  },
  roomRemarks: [RoomType.MasterBedroom]
};
