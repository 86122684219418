import React, { Context, Fragment } from "react";
import { Stack } from "@mui/system";

import { useHasRole } from "@homesusa/auth";
import { AvailableXmlStatus, XmlStatus } from "core/enums";
import { EntityContext } from "core/interfaces";
import { XmlStatusAlert } from "./xml-status-alert.component";

export function XmlStatusButton<
  TEntity extends {
    id: string;
    xmlStatus: XmlStatus;
  },
  TEntityContext extends EntityContext<TEntity>
>({
  entityContext,
  children
}: {
  entityContext: Context<TEntityContext>;
  children: React.ReactNode;
}): JSX.Element {
  const { isMlsAdministrator } = useHasRole();
  const {
    entity: { xmlStatus }
  } = React.useContext(entityContext);

  if (AvailableXmlStatus.includes(xmlStatus)) {
    return <Fragment></Fragment>;
  }

  if (!isMlsAdministrator) {
    return <XmlStatusAlert status={xmlStatus} />;
  }

  return (
    <Stack direction="row" spacing={1}>
      <XmlStatusAlert status={xmlStatus} />
      {children}
    </Stack>
  );
}
