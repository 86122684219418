export enum Utilities {
  AerobicSeptic = "aerobicSeptic",
  Alley = "alley",
  CitySewer = "citySewer",
  CityWater = "cityWater",
  CoMembershipIncluded = "coMembershipIncluded",
  CoWater = "coWater",
  CommunityMailbox = "communityMailbox",
  Curbs = "curbs",
  IndividualGasMeter = "individualGasMeter",
  IndividualWaterMeter = "individualWaterMeter",
  MasterGasMeter = "masterGasMeter",
  MasterWaterMeter = "masterWaterMeter",
  MUDSewer = "mudSewer",
  MUDWater = "mudWater",
  OutsideCityLimits = "outsideCityLimits",
  OverheadUtilities = "overheadUtilities",
  PrivateSewer = "privateSewer",
  PrivateWater = "privateWater",
  RuralWaterDistrict = "ruralWaterDistrict",
  Septic = "septic",
  Sidewalk = "sidewalk",
  UndergroundUtilities = "undergroundUtilities",
  Unincorporated = "unincorporated",
  Other = "other"
}

export const UtilitiesLabel = new Map<Utilities, string>([
  [Utilities.AerobicSeptic, "Aerobic Septic"],
  [Utilities.Alley, "Alley"],
  [Utilities.CitySewer, "City Sewer"],
  [Utilities.CityWater, "City Water"],
  [Utilities.CoMembershipIncluded, "Co-op Membership Included"],
  [Utilities.CoWater, "Co-op Water"],
  [Utilities.CommunityMailbox, "Community Mailbox"],
  [Utilities.Curbs, "Curbs"],
  [Utilities.IndividualGasMeter, "Individual Gas Meter"],
  [Utilities.IndividualWaterMeter, "Individual Water Meter"],
  [Utilities.MasterGasMeter, "Master Gas Meter"],
  [Utilities.MasterWaterMeter, "Master Water Meter"],
  [Utilities.MUDSewer, "MUD Sewer"],
  [Utilities.MUDWater, "MUD Water"],
  [Utilities.OutsideCityLimits, "Outside City Limits"],
  [Utilities.OverheadUtilities, "Overhead Utilities"],
  [Utilities.PrivateSewer, "Private Sewer"],
  [Utilities.PrivateWater, "Private Water"],
  [Utilities.RuralWaterDistrict, "Rural Water District"],
  [Utilities.Septic, "Septic"],
  [Utilities.Sidewalk, "Sidewalk"],
  [Utilities.UndergroundUtilities, "Underground Utilities"],
  [Utilities.Unincorporated, "Unincorporated"],
  [Utilities.Other, "Other"]
]);
