export enum State {
  TX = "tx",
  CO = "co",
  NM = "nm",
  OK = "ok",
  Other = "other"
}

export const StateLabel: Record<State, string> = {
  [State.TX]: "TX",
  [State.CO]: "CO",
  [State.NM]: "NM",
  [State.OK]: "OK",
  [State.Other]: "Other"
};
