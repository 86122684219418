import { aborStatusRule } from "../sections";

export const listingRules = {
  mlsStatus: aborStatusRule,
  listPrice: {
    label: "List Price",
    requiredToSubmit: true,
    numberFormat: {
      max: 3000000,
      min: 100000,
      prefix: "$",
      thousandSeparator: true
    },
    maxLength: 10
  },
  expirationDate: { label: "Expiration Date", requiredToSubmit: true }
};
