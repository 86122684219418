export enum PropertySubTypeLots {
  MultiLots = "multiLots",
  SingleLot = "singleLot",
  SeeRemarks = "seeRemarks"
}
export const PropertySubTypeLotsLabels = new Map<PropertySubTypeLots, string>([
  [PropertySubTypeLots.MultiLots, "MultiLots (Adjacent)"],
  [PropertySubTypeLots.SingleLot, "Single Lot"],
  [PropertySubTypeLots.SeeRemarks, "See Remarks"]
]);
