export enum SchoolDistrict {
  AWBrownFellowshipCharterS = "awBrownFellowshipCharterS",
  APlusAcademy = "aPlusAcademy",
  Abbott = "abbott",
  Abernathy = "abernathy",
  Abilene = "abilene",
  Academy = "academy",
  AcademyofAcceleratedLearnin = "academyofAcceleratedLearnin",
  AcademyofBeaumont = "academyofBeaumont",
  AcademyofCareersandTechnol = "academyofCareersandTechnol",
  AcademyofDallas = "academyofDallas",
  AcademyofHouston = "academyofHouston",
  AcceleratedIntermediateAcade = "acceleratedIntermediateAcade",
  Adrian = "adrian",
  AguaDulce = "aguaDulce",
  AlPriceStateJuvenileCorrec = "alPriceStateJuvenileCorrec",
  AlamoHeights = "alamoHeights",
  Alaska = "alaska",
  AlbaGolden = "albaGolden",
  Albany = "albany",
  Aldine = "aldine",
  Aledo = "aledo",
  Alice = "alice",
  Alief = "alief",
  AliefMontessoriCommunitySch = "aliefMontessoriCommunitySch",
  Allen = "allen",
  AlphaCharterSchool = "alphaCharterSchool",
  AlphonsoCrutchsLifeSupport = "alphonsoCrutchsLifeSupport",
  Alpine = "alpine",
  Alto = "alto",
  Alvarado = "alvarado",
  Alvin = "alvin",
  Alvord = "alvord",
  Amarillo = "amarillo",
  AmericanAcademyofExcellence = "americanAcademyofExcellence",
  AmericanYouthWorksCharterS = "americanYouthWorksCharterS",
  Amherst = "amherst",
  AmigosporVidaFriendsforLI = "amigosporVidaFriendsforLI",
  Anahuac = "anahuac",
  AndersonShiroCons = "andersonShiroCons",
  Andrews = "andrews",
  Angleton = "angleton",
  Anguilla = "anguilla",
  Anna = "anna",
  Anson = "anson",
  Anthony = "anthony",
  Anton = "anton",
  AppleSprings = "appleSprings",
  Aquilla = "aquilla",
  AransasCounty = "aransasCounty",
  AransasPass = "aransasPass",
  ArcherCity = "archerCity",
  Argyle = "argyle",
  Arizona = "arizona",
  Arkansas = "arkansas",
  ArlingtonClassicsAcademy = "arlingtonClassicsAcademy",
  Arlington = "arlington",
  Arp = "arp",
  Aruba = "aruba",
  Aspermont = "aspermont",
  Athens = "athens",
  Atlanta = "atlanta",
  Aubrey = "aubrey",
  AustinCanAcademyCharterSch = "austinCanAcademyCharterSch",
  Austin = "austin",
  Australia = "australia",
  AustwellTivoli = "austwellTivoli",
  Avalon = "avalon",
  Avery = "avery",
  Avinger = "avinger",
  Axtell = "axtell",
  Azle = "azle",
  AzlewayCharterSchool = "azlewayCharterSchool",
  Bahamas = "bahamas",
  Baird = "baird",
  Ballinger = "ballinger",
  Balmorhea = "balmorhea",
  Bandera = "bandera",
  Bangs = "bangs",
  Banquete = "banquete",
  Barbados = "barbados",
  BarbersHill = "barbersHill",
  Bartlett = "bartlett",
  Bastrop = "bastrop",
  BayAreaCharterSchool = "bayAreaCharterSchool",
  BayCity = "bayCity",
  BeatriceMayesInstituteChart = "beatriceMayesInstituteChart",
  Beaumont = "beaumont",
  Beckville = "beckville",
  Beeville = "beeville",
  Belize = "belize",
  Bellevue = "bellevue",
  Bells = "bells",
  Bellville = "bellville",
  Belton = "belton",
  BenBoltPalitoBlanco = "benBoltPalitoBlanco",
  Benavides = "benavides",
  Benjamin = "benjamin",
  BenjisSpecialEducationalAc = "benjisSpecialEducationalAc",
  BexarCountyAcademy = "bexarCountyAcademy",
  BigSandy = "bigSandy",
  BigSpring = "bigSpring",
  BigSpringsCharterSchool = "bigSpringsCharterSchool",
  Birdville = "birdville",
  BishopCons = "bishopCons",
  BlackwellCons = "blackwellCons",
  Blanco = "blanco",
  Bland = "bland",
  Blanket = "blanket",
  Bloomburg = "bloomburg",
  BloomingGrove = "bloomingGrove",
  Bloomington = "bloomington",
  BlueRidge = "blueRidge",
  BluffDale = "bluffDale",
  Blum = "blum",
  Boerne = "boerne",
  Boles = "boles",
  Boling = "boling",
  Bonham = "bonham",
  Booker = "booker",
  BordenCounty = "bordenCounty",
  Borger = "borger",
  Bosqueville = "bosqueville",
  Bovina = "bovina",
  Bowie = "bowie",
  Boyd = "boyd",
  BoysRanch = "boysRanch",
  Brackett = "brackett",
  Brady = "brady",
  Brazil = "brazil",
  Brazos = "brazos",
  BrazosRiverCharterSchool = "brazosRiverCharterSchool",
  BrazosSchoolforInquiry = "brazosSchoolforInquiry",
  Brazosport = "brazosport",
  Breckenridge = "breckenridge",
  Bremond = "bremond",
  Brenham = "brenham",
  BridgeCity = "bridgeCity",
  Bridgeport = "bridgeport",
  BrightIdeasCharter = "brightIdeasCharter",
  BritishVirginIslands = "britishVirginIslands",
  Broaddus = "broaddus",
  Brock = "brock",
  Bronte = "bronte",
  Brookeland = "brookeland",
  Brookesmith = "brookesmith",
  BrooksCounty = "brooksCounty",
  Brownfield = "brownfield",
  Brownsboro = "brownsboro",
  Brownsville = "brownsville",
  Brownwood = "brownwood",
  BrucevilleEddy = "brucevilleEddy",
  Bryan = "bryan",
  Bryson = "bryson",
  Buckholts = "buckholts",
  BuenaVista = "buenaVista",
  Buffalo = "buffalo",
  Bulgaria = "bulgaria",
  Bullard = "bullard",
  Buna = "buna",
  Burkburnett = "burkburnett",
  Burkeville = "burkeville",
  Burleson = "burleson",
  BurnetCons = "burnetCons",
  BurnhamWoodCharterSchool = "burnhamWoodCharterSchool",
  Burton = "burton",
  Bushland = "bushland",
  Byers = "byers",
  Bynum = "bynum",
  CaddoMills = "caddoMills",
  Calallen = "calallen",
  Caldwell = "caldwell",
  CalhounCo = "calhounCo",
  California = "california",
  Callisburg = "callisburg",
  Calvert = "calvert",
  CalvinNelmsCharterSchools = "calvinNelmsCharterSchools",
  Cameron = "cameron",
  Campbell = "campbell",
  Canada = "canada",
  Canadian = "canadian",
  Canton = "canton",
  Canutillo = "canutillo",
  Canyon = "canyon",
  CareerPlusLearningAcademy = "careerPlusLearningAcademy",
  Carlisle = "carlisle",
  CarrizoSpringsCons = "carrizoSpringsCons",
  Carroll = "carroll",
  CarrolltonFarmersBranch = "carrolltonFarmersBranch",
  Carthage = "carthage",
  Castleberry = "castleberry",
  CaymanIslands = "caymanIslands",
  Cayuga = "cayuga",
  CedarCrestCharterSchool = "cedarCrestCharterSchool",
  CedarHill = "cedarHill",
  CedarRidgeCharterSchool = "cedarRidgeCharterSchool",
  CedarsInternationalAcademy = "cedarsInternationalAcademy",
  Celeste = "celeste",
  Celina = "celina",
  Center = "center",
  CenterPoint = "centerPoint",
  Centerville = "centerville",
  CentralHeights = "centralHeights",
  Central = "central",
  Channelview = "channelview",
  Channing = "channing",
  ChapelHill = "chapelHill",
  Charlotte = "charlotte",
  Cherokee = "cherokee",
  Chester = "chester",
  Chico = "chico",
  ChildrenFirstAcademyofDall = "childrenFirstAcademyofDall",
  ChildrenFirstAcademyofHous = "childrenFirstAcademyofHous",
  ChildrenoftheSunCharterSc = "childrenoftheSunCharterSc",
  Childress = "childress",
  Chile = "chile",
  Chillicothe = "chillicothe",
  Chilton = "chilton",
  ChinaSpring = "chinaSpring",
  Chireno = "chireno",
  Chisum = "chisum",
  Christoval = "christoval",
  Cisco = "cisco",
  CityView = "cityView",
  Clarendon = "clarendon",
  Clarksville = "clarksville",
  Claude = "claude",
  ClearCreek = "clearCreek",
  Cleburne = "cleburne",
  Cleveland = "cleveland",
  Clifton = "clifton",
  Clint = "clint",
  ClydeCons = "clydeCons",
  Coahoma = "coahoma",
  CoastalBendYouthCity = "coastalBendYouthCity",
  ColdspringOakhurstCons = "coldspringOakhurstCons",
  Coleman = "coleman",
  CollegeStation = "collegeStation",
  Collinsville = "collinsville",
  Colmesneil = "colmesneil",
  Colorado = "colorado",
  ColumbiaBrazoria = "columbiaBrazoria",
  Columbus = "columbus",
  Comal = "comal",
  Comanche = "comanche",
  Comfort = "comfort",
  Commerce = "commerce",
  Community = "community",
  ComoPicktonC = "comoPicktonC",
  ComquestAcademy = "comquestAcademy",
  Comstock = "comstock",
  Connally = "connally",
  Connecticut = "connecticut",
  Conroe = "conroe",
  Coolidge = "coolidge",
  Cooper = "cooper",
  Coppell = "coppell",
  CopperasCove = "copperasCove",
  CorpusChristi = "corpusChristi",
  CorriganCamden = "corriganCamden",
  Corsicana = "corsicana",
  CorsicanaResidentialTreatmen = "corsicanaResidentialTreatmen",
  CostaRica = "costaRica",
  CottonCenter = "cottonCenter",
  Cotulla = "cotulla",
  Coupland = "coupland",
  Covington = "covington",
  Crandall = "crandall",
  Crane = "crane",
  CranfillsGap = "cranfillsGap",
  Crawford = "crawford",
  CrockettCoConsCSD = "crockettCoConsCSD",
  Crockett = "crockett",
  CrockettStateSchool = "crockettStateSchool",
  Crosby = "crosby",
  CrosbytonC = "crosbytonC",
  CrossPlains = "crossPlains",
  CrossRoads = "crossRoads",
  CrossroadsCommunityEdCtrCh = "crossroadsCommunityEdCtrCh",
  Crowell = "crowell",
  Crowley = "crowley",
  CrystalCity = "crystalCity",
  Cuero = "cuero",
  CulbersonCountyAllamoore = "culbersonCountyAllamoore",
  CumberlandAcademy = "cumberlandAcademy",
  Cumby = "cumby",
  Cushing = "cushing",
  CypressFairbanks = "cypressFairbanks",
  DaingerfieldLoneStar = "daingerfieldLoneStar",
  Dalhart = "dalhart",
  DallasCanAcademyCharter = "dallasCanAcademyCharter",
  DallasCommunityCharterSchoo = "dallasCommunityCharterSchoo",
  DallasCountyJuvenileJustice = "dallasCountyJuvenileJustice",
  Dallas = "dallas",
  Damon = "damon",
  Danbury = "danbury",
  Darrouzett = "darrouzett",
  Dawson = "dawson",
  Dayton = "dayton",
  DeLeon = "deLeon",
  Decatur = "decatur",
  DeerPark = "deerPark",
  Dekalb = "dekalb",
  DelValle = "delValle",
  DellCity = "dellCity",
  Denison = "denison",
  Denton = "denton",
  DenverCity = "denverCity",
  Desoto = "desoto",
  Detroit = "detroit",
  Devers = "devers",
  Devine = "devine",
  Dew = "dew",
  Deweyville = "deweyville",
  DHanis = "dHanis",
  Diboll = "diboll",
  Dickinson = "dickinson",
  Dilley = "dilley",
  DimeBox = "dimeBox",
  Dimmitt = "dimmitt",
  Divide = "divide",
  DoddCity = "doddCity",
  DominicanRepublic = "dominicanRepublic",
  Donna = "donna",
  DossConsCSD = "dossConsCSD",
  Douglass = "douglass",
  DrMLGarzaGonzalezCharter = "drMLGarzaGonzalezCharter",
  DrippingSprings = "drippingSprings",
  Driscoll = "driscoll",
  Dublin = "dublin",
  Dumas = "dumas",
  DuncanOK = "duncanOK",
  Duncanville = "duncanville",
  EagleAcademyofBeaumont = "eagleAcademyofBeaumont",
  EagleAcademyofBrownsville = "eagleAcademyofBrownsville",
  EagleAcademyofBryan = "eagleAcademyofBryan",
  EagleAcademyofDallas = "eagleAcademyofDallas",
  EagleAcademyofDelRio = "eagleAcademyofDelRio",
  EagleAcademyofFortWorth = "eagleAcademyofFortWorth",
  EagleAcademyofLaredo = "eagleAcademyofLaredo",
  EagleAcademyofLubbock = "eagleAcademyofLubbock",
  EagleAcademyofMidland = "eagleAcademyofMidland",
  EagleAcademyofPharrMcAllen = "eagleAcademyofPharrMcAllen",
  EagleAcademyofSanAntonio = "eagleAcademyofSanAntonio",
  EagleAcademyofTexarkana = "eagleAcademyofTexarkana",
  EagleAcademyofTyler = "eagleAcademyofTyler",
  EagleAcademyofWaco = "eagleAcademyofWaco",
  EagleAdvantageSchool = "eagleAdvantageSchool",
  EagleMTSaginaw = "eagleMTSaginaw",
  EaglePass = "eaglePass",
  Eanes = "eanes",
  Early = "early",
  EastBernard = "eastBernard",
  EastCentral = "eastCentral",
  EastChambers = "eastChambers",
  EastFortWorthMontessoriAca = "eastFortWorthMontessoriAca",
  EastTexasCharterSchools = "eastTexasCharterSchools",
  Eastland = "eastland",
  EctorCounty = "ectorCounty",
  Ector = "ector",
  Ecuador = "ecuador",
  EdcouchElsa = "edcouchElsa",
  EdenC = "edenC",
  EdenParkAcademy = "edenParkAcademy",
  Edgewood = "edgewood",
  EdinburgConsolidated = "edinburgConsolidated",
  Edna = "edna",
  EducationCenter = "educationCenter",
  EducationCenterInternational = "educationCenterInternational",
  EhrhartSchool = "ehrhartSchool",
  ElCampo = "elCampo",
  ElPasoAcademy = "elPasoAcademy",
  ElPaso = "elPaso",
  ElPasoSchoolofExcellence = "elPasoSchoolofExcellence",
  ElSalvador = "elSalvador",
  Electra = "electra",
  Elgin = "elgin",
  Elkhart = "elkhart",
  ElysianFields = "elysianFields",
  EncinoSchool = "encinoSchool",
  Ennis = "ennis",
  Era = "era",
  ErathExcelsAcademyInc = "erathExcelsAcademyInc",
  Etoile = "etoile",
  Eula = "eula",
  Eustace = "eustace",
  Evadale = "evadale",
  Evant = "evant",
  Everman = "everman",
  EvinsRegionalJuvenileCenter = "evinsRegionalJuvenileCenter",
  EvolutionAcademyCharterScho = "evolutionAcademyCharterScho",
  Excelsior = "excelsior",
  Ezzell = "ezzell",
  Fabens = "fabens",
  Fairfield = "fairfield",
  FallsCity = "fallsCity",
  Fannindel = "fannindel",
  Farmersville = "farmersville",
  Farwell = "farwell",
  Fayetteville = "fayetteville",
  Ferris = "ferris",
  Flatonia = "flatonia",
  Florence = "florence",
  Floresville = "floresville",
  Florida = "florida",
  FlourBluff = "flourBluff",
  Floydada = "floydada",
  FocusLearningAcademy = "focusLearningAcademy",
  Follett = "follett",
  Forestburg = "forestburg",
  Forney = "forney",
  Forsan = "forsan",
  FortBend = "fortBend",
  FortElliottC = "fortElliottC",
  FortWorthAcademyofFineArt = "fortWorthAcademyofFineArt",
  FortWorthCanAcademy = "fortWorthCanAcademy",
  FortWorth = "fortWorth",
  France = "france",
  Franklin = "franklin",
  Frankston = "frankston",
  Fredericksburg = "fredericksburg",
  Freer = "freer",
  Frenship = "frenship",
  Friendswood = "friendswood",
  Friona = "friona",
  Frisco = "frisco",
  Frost = "frost",
  FruitofExcellence = "fruitofExcellence",
  Fruitvale = "fruitvale",
  FtDavis = "ftDavis",
  FtHancock = "ftHancock",
  FtSamHouston = "ftSamHouston",
  FtStockton = "ftStockton",
  GabrielTafollaCharterSchool = "gabrielTafollaCharterSchool",
  Gainesville = "gainesville",
  GalenaPark = "galenaPark",
  Galveston = "galveston",
  Ganado = "ganado",
  Garland = "garland",
  Garner = "garner",
  Garrison = "garrison",
  Gary = "gary",
  Gatesville = "gatesville",
  GatewayAcademy = "gatewayAcademy",
  GatewayCharterAcademy = "gatewayCharterAcademy",
  Gause = "gause",
  GeorgeGervinAcademy = "georgeGervinAcademy",
  GeorgeISanchez = "georgeISanchez",
  GeorgeISanchezCharterHSSA = "georgeISanchezCharterHSSA",
  GeorgeWest = "georgeWest",
  Georgetown = "georgetown",
  Georgia = "georgia",
  Germany = "germany",
  Gholson = "gholson",
  Giddings = "giddings",
  GiddingsStateSchool = "giddingsStateSchool",
  Gilmer = "gilmer",
  GirlsBoysPrepAcademy = "girlsBoysPrepAcademy",
  Gladewater = "gladewater",
  GlasscockCounty = "glasscockCounty",
  GlenRose = "glenRose",
  Godley = "godley",
  GoldBurg = "goldBurg",
  GoldenRuleCharterSchool = "goldenRuleCharterSchool",
  Goldthwaite = "goldthwaite",
  Goliad = "goliad",
  Gonzales = "gonzales",
  Goodrich = "goodrich",
  GooseCreekC = "gooseCreekC",
  Gordon = "gordon",
  Gorman = "gorman",
  Grady = "grady",
  Graford = "graford",
  Graham = "graham",
  Granbury = "granbury",
  GrandPrairie = "grandPrairie",
  GrandSaline = "grandSaline",
  GrandfallsRoyalty = "grandfallsRoyalty",
  Grandview = "grandview",
  GrandviewHopkins = "grandviewHopkins",
  Granger = "granger",
  GrapeCreek = "grapeCreek",
  Grapeland = "grapeland",
  GrapevineColleyville = "grapevineColleyville",
  Greenville = "greenville",
  Greenwood = "greenwood",
  GregoryPortland = "gregoryPortland",
  Groesbeck = "groesbeck",
  Groom = "groom",
  Groveton = "groveton",
  Gruver = "gruver",
  GuardianAngelPerformanceAca = "guardianAngelPerformanceAca",
  Guatemala = "guatemala",
  GulfShoresAcademy = "gulfShoresAcademy",
  Gunter = "gunter",
  Gustine = "gustine",
  GuthrieCSD = "guthrieCSD",
  HaleCenter = "haleCenter",
  Hallettsville = "hallettsville",
  Hallsburg = "hallsburg",
  Hallsville = "hallsville",
  Hamilton = "hamilton",
  Hamlin = "hamlin",
  HamshireFannett = "hamshireFannett",
  Happy = "happy",
  Hardin = "hardin",
  HardinJefferson = "hardinJefferson",
  Harlandale = "harlandale",
  Harleton = "harleton",
  HarlingenCons = "harlingenCons",
  Harmony = "harmony",
  HarmonyScienceAcademy = "harmonyScienceAcademy",
  HarmonyScienceAcademyAust = "harmonyScienceAcademyAust",
  Harper = "harper",
  HarrisCountyJuvenileJustice = "harrisCountyJuvenileJustice",
  Harrold = "harrold",
  Hart = "hart",
  Hartley = "hartley",
  HartsBluff = "hartsBluff",
  HaskellC = "haskellC",
  Hawkins = "hawkins",
  Hawley = "hawley",
  HaysCons = "haysCons",
  Hearne = "hearne",
  Hedley = "hedley",
  HeightsCharterSchool = "heightsCharterSchool",
  Hemphill = "hemphill",
  Hempstead = "hempstead",
  Henderson = "henderson",
  Henrietta = "henrietta",
  Hereford = "hereford",
  Hermleigh = "hermleigh",
  Hico = "hico",
  Hidalgo = "hidalgo",
  Higgins = "higgins",
  HiggsCarterKingGiftedTal = "higgsCarterKingGiftedTal",
  HighIsland = "highIsland",
  Highland = "highland",
  HighlandPark = "highlandPark",
  Hillsboro = "hillsboro",
  Hitchcock = "hitchcock",
  Holland = "holland",
  Holliday = "holliday",
  Hondo = "hondo",
  Honduras = "honduras",
  HoneyGrove = "honeyGrove",
  HonorsAcademy = "honorsAcademy",
  Hooks = "hooks",
  HoustonAlternativePreparator = "houstonAlternativePreparator",
  HoustonCanAcademyCharterSc = "houstonCanAcademyCharterSc",
  HoustonGatewayAcademy = "houstonGatewayAcademy",
  HoustonHeightsLearningAcade = "houstonHeightsLearningAcade",
  Houston = "houston",
  Howe = "howe",
  Hubbard = "hubbard",
  Huckabay = "huckabay",
  Hudson = "hudson",
  Huffman = "huffman",
  HughesSprings = "hughesSprings",
  HullDaisetta = "hullDaisetta",
  Humble = "humble",
  Hunt = "hunt",
  Huntington = "huntington",
  Huntsville = "huntsville",
  HurstEulessBedford = "hurstEulessBedford",
  Hutto = "hutto",
  IAmThatIAmAcademy = "iAmThatIAmAcademy",
  IDEAAcademy = "ideaAcademy",
  Idalou = "idalou",
  ImpactCharter = "impactCharter",
  India = "india",
  Indiana_DoNotUse = "indiana_DoNotUse",
  Indonesia = "indonesia",
  Industrial = "industrial",
  Ingleside = "ingleside",
  Ingram = "ingram",
  InspiredVisionAcademy = "inspiredVisionAcademy",
  International = "international",
  Iola = "iola",
  IowaParkCons = "iowaParkCons",
  Ira = "ira",
  IraanSheffield = "iraanSheffield",
  Iredell = "iredell",
  IrionCo = "irionCo",
  Irving = "irving",
  Italy = "italy",
  ItalyIsd = "italyIsd",
  Itasca = "itasca",
  Jacksboro = "jacksboro",
  Jacksonville = "jacksonville",
  Jamaica = "jamaica",
  JamiesHouseCharterSchool = "jamiesHouseCharterSchool",
  Jarrell = "jarrell",
  Jasper = "jasper",
  JaytonGirard = "jaytonGirard",
  JeanMassieuAcademy = "jeanMassieuAcademy",
  Jefferson = "jefferson",
  JesseJacksonAcademy = "jesseJacksonAcademy",
  JimHoggCounty = "jimHoggCounty",
  JimNedCons = "jimNedCons",
  Joaquin = "joaquin",
  JohnHWoodCharterSchool = "johnHWoodCharterSchool",
  JohnsonCity = "johnsonCity",
  Jonesboro = "jonesboro",
  Joshua = "joshua",
  Jourdanton = "jourdanton",
  JuanBGalavizCharterSchool = "juanBGalavizCharterSchool",
  JubileeAcademicCenter = "jubileeAcademicCenter",
  Judson = "judson",
  Junction = "junction",
  Karnack = "karnack",
  KarnesCity = "karnesCity",
  KatherineAnnePorterSchool = "katherineAnnePorterSchool",
  Katy = "katy",
  Kaufman = "kaufman",
  Keene = "keene",
  Keller = "keller",
  Kelton = "kelton",
  Kemp = "kemp",
  Kendleton = "kendleton",
  KenedyCountyWideCSD = "kenedyCountyWideCSD",
  Kenedy = "kenedy",
  Kennard = "kennard",
  Kennedale = "kennedale",
  Kentucky_DoNotUse = "kentucky_DoNotUse",
  Kerens = "kerens",
  Kermit = "kermit",
  Kerrville = "kerrville",
  Kilgore = "kilgore",
  Killeen = "killeen",
  Kingston = "kingston",
  Kingsville = "kingsville",
  KippIncCharter = "kippIncCharter",
  KirbyvilleC = "kirbyvilleC",
  Klein = "klein",
  Klondike = "klondike",
  Knippa = "knippa",
  KnoxCityOBrien = "knoxCityOBrien",
  Kopperl = "kopperl",
  Kountze = "kountze",
  Kress = "kress",
  Krum = "krum",
  LaAmistadLoveLearningAca = "laAmistadLoveLearningAca",
  LaEscueladelasAmericas = "laEscueladelasAmericas",
  LaFeria = "laFeria",
  LaGloria = "laGloria",
  LaGrange = "laGrange",
  LaJoya = "laJoya",
  LaMarque = "laMarque",
  LaPorte = "laPorte",
  LaPryor = "laPryor",
  LaVega = "laVega",
  LaVernia = "laVernia",
  LaVilla = "laVilla",
  Lackland = "lackland",
  LagoVista = "lagoVista",
  LakeDallas = "lakeDallas",
  LakeTravis = "lakeTravis",
  LakeWorth = "lakeWorth",
  LamarConsolidated = "lamarConsolidated",
  Lamesa = "lamesa",
  Lampasas = "lampasas",
  Lancaster = "lancaster",
  Laneville = "laneville",
  Lapoynor = "lapoynor",
  Laredo = "laredo",
  Lasara = "lasara",
  Latexo = "latexo",
  Lazbuddie = "lazbuddie",
  Leakey = "leakey",
  Leander = "leander",
  Leary = "leary",
  Lefors = "lefors",
  Leggett = "leggett",
  Leon = "leon",
  Leonard = "leonard",
  Levelland = "levelland",
  LeverettsChapel = "leverettsChapel",
  Lewisville = "lewisville",
  Lexington = "lexington",
  LibertyHill = "libertyHill",
  Liberty = "liberty",
  LibertyEylau = "libertyEylau",
  LifeSchool = "lifeSchool",
  LighthouseCharterSchool = "lighthouseCharterSchool",
  Lindale = "lindale",
  LindenKildareCons = "lindenKildareCons",
  Lindsay = "lindsay",
  Lingleville = "lingleville",
  Lipan = "lipan",
  LittleCypressMauricevilleCi = "littleCypressMauricevilleCi",
  LittleElm = "littleElm",
  Littlefield = "littlefield",
  Livingston = "livingston",
  Llano = "llano",
  Lockhart = "lockhart",
  Lockney = "lockney",
  Lohn = "lohn",
  Lometa = "lometa",
  London = "london",
  LoneOak = "loneOak",
  Longview = "longview",
  Loop = "loop",
  Loraine = "loraine",
  Lorena = "lorena",
  Lorenzo = "lorenzo",
  LosFresnosCons = "losFresnosCons",
  Louise = "louise",
  Louisiana = "louisiana",
  Lovejoy = "lovejoy",
  Lovelady = "lovelady",
  Lubbock = "lubbock",
  LubbockCooper = "lubbockCooper",
  LuedersAvoca = "luedersAvoca",
  Lufkin = "lufkin",
  Luling = "luling",
  Lumberton = "lumberton",
  LyfordC = "lyfordC",
  Lytle = "lytle",
  Mabank = "mabank",
  MadisonvilleCons = "madisonvilleCons",
  Magnolia = "magnolia",
  Maine = "maine",
  MainlandPreparatoryAcademy = "mainlandPreparatoryAcademy",
  Malakoff = "malakoff",
  Malone = "malone",
  Malta = "malta",
  Manor = "manor",
  Mansfield = "mansfield",
  Marathon = "marathon",
  MarbleFalls = "marbleFalls",
  Marfa = "marfa",
  Marietta = "marietta",
  Marion = "marion",
  Marlin = "marlin",
  MarlinOrientationandAssessm = "marlinOrientationandAssessm",
  Marshall = "marshall",
  Mart = "mart",
  MartinsMill = "martinsMill",
  Martinsville = "martinsville",
  Maryland = "maryland",
  Mason = "mason",
  MasonicHome = "masonicHome",
  Matagorda = "matagorda",
  Mathis = "mathis",
  Maud = "maud",
  May = "may",
  Maypearl = "maypearl",
  McAllen = "mcAllen",
  McCamey = "mcCamey",
  McCulloughAcademyofExcellen = "mcCulloughAcademyofExcellen",
  McDade = "mcDade",
  McGregor = "mcGregor",
  McKinney = "mcKinney",
  McLean = "mcLean",
  McLennanCoStJuvenileCorrec = "mcLennanCoStJuvenileCorrec",
  McLeod = "mcLeod",
  McMullenCounty = "mcMullenCounty",
  Meadow = "meadow",
  MedicalCenterCharterSchool = "medicalCenterCharterSchool",
  Medina = "medina",
  MedinaValley = "medinaValley",
  Megargel = "megargel",
  Melissa = "melissa",
  Memphis = "memphis",
  Menard = "menard",
  Mercedes = "mercedes",
  Meridian = "meridian",
  Merkel = "merkel",
  Mesquite = "mesquite",
  MetroCharterAcademy = "metroCharterAcademy",
  Mexia = "mexia",
  Mexico = "mexico",
  Meyersville = "meyersville",
  Miami = "miami",
  MidlandAcademyCharterSchool = "midlandAcademyCharterSchool",
  Midland = "midland",
  Midlothian = "midlothian",
  MidValleyAcademy = "midValleyAcademy",
  Midway = "midway",
  MidwayClayCounty = "midwayClayCounty",
  Milano = "milano",
  Mildred = "mildred",
  Miles = "miles",
  Milford = "milford",
  MillerGrove = "millerGrove",
  Millsap = "millsap",
  Mineola = "mineola",
  MineralWells = "mineralWells",
  MirandoCity = "mirandoCity",
  MissionCons = "missionCons",
  MonahansWickettPyote = "monahansWickettPyote",
  Montague = "montague",
  Montana = "montana",
  MonteAlto = "monteAlto",
  Montgomery = "montgomery",
  Moody = "moody",
  Moran = "moran",
  Morgan = "morgan",
  MorganMill = "morganMill",
  Morton = "morton",
  MotleyCounty = "motleyCounty",
  Moulton = "moulton",
  MountCalm = "mountCalm",
  MountEnterprise = "mountEnterprise",
  MountPleasant = "mountPleasant",
  MountVernon = "mountVernon",
  Muenster = "muenster",
  Muleshoe = "muleshoe",
  Mullin = "mullin",
  Mumford = "mumford",
  MundayC = "mundayC",
  Murchison = "murchison",
  Nacogdoches = "nacogdoches",
  NancyNeyCharterSchool = "nancyNeyCharterSchool",
  Natalia = "natalia",
  Navarro = "navarro",
  Navasota = "navasota",
  Nazareth = "nazareth",
  Neches = "neches",
  Nederland = "nederland",
  Needville = "needville",
  Nevada = "nevada",
  NewBoston = "newBoston",
  NewBraunfels = "newBraunfels",
  NewCaney = "newCaney",
  NewDeal = "newDeal",
  NewDiana = "newDiana",
  NewFrontiersCharterSchool = "newFrontiersCharterSchool",
  NewHome = "newHome",
  NewMexico = "newMexico",
  NewSummerfield = "newSummerfield",
  NewWaverly = "newWaverly",
  NewYork = "newYork",
  Newcastle = "newcastle",
  Newton = "newton",
  Nicaragua = "nicaragua",
  NixonSmileyCons = "nixonSmileyCons",
  Nocona = "nocona",
  Nordheim = "nordheim",
  Normangee = "normangee",
  NorthCarolina = "northCarolina",
  NorthEast = "northEast",
  NorthForest = "northForest",
  NorthHillsSchool = "northHillsSchool",
  NorthHopkins = "northHopkins",
  NorthHoustonHSForBusiness = "northHoustonHSForBusiness",
  NorthLamar = "northLamar",
  NorthZulch = "northZulch",
  Northside = "northside",
  Northwest = "northwest",
  NorthwestMathematicsScience = "northwestMathematicsScience",
  NorthwestPreparatory = "northwestPreparatory",
  NovaCharterSchool = "novaCharterSchool",
  NovaCharterSchoolSoutheast = "novaCharterSchoolSoutheast",
  Novice = "novice",
  NTREISTESTONLY = "ntreistestonly",
  NuecesCanyonC = "nuecesCanyonC",
  Nursery = "nursery",
  NyosCharterSchool = "nyosCharterSchool",
  Oakwood = "oakwood",
  OdemEdroy = "odemEdroy",
  ODonnell = "oDonnell",
  OdysseyAcademyInc = "odysseyAcademyInc",
  Oglesby = "oglesby",
  Oklahoma = "oklahoma",
  Olfen = "olfen",
  Olney = "olney",
  Olton = "olton",
  Onalaska = "onalaska",
  OneStopMultiserviceCharter = "oneStopMultiserviceCharter",
  OrangeGrove = "orangeGrove",
  Orangefield = "orangefield",
  OreCity = "oreCity",
  Oregon = "oregon",
  OutreachWordAcademy = "outreachWordAcademy",
  Overton = "overton",
  Paducah = "paducah",
  PaintCreek = "paintCreek",
  PaintRock = "paintRock",
  Palacios = "palacios",
  Palestine = "palestine",
  Palmer = "palmer",
  PaloPinto = "paloPinto",
  Pampa = "pampa",
  Panama = "panama",
  Panhandle = "panhandle",
  PanolaCharterSchool = "panolaCharterSchool",
  PanolaPublicSD = "panolaPublicSD",
  PantherCreekCons = "pantherCreekCons",
  ParadigmAcceleratedSchool = "paradigmAcceleratedSchool",
  Paradise = "paradise",
  Paris = "paris",
  Pasadena = "pasadena",
  PasoDelNorteAcademy = "pasoDelNorteAcademy",
  PattonSprings = "pattonSprings",
  Pawnee = "pawnee",
  Pearland = "pearland",
  Pearsall = "pearsall",
  Peaster = "peaster",
  PecosBarstowToyah = "pecosBarstowToyah",
  PegasusCharterSchool = "pegasusCharterSchool",
  Penelope = "penelope",
  PerrinWhittCons = "perrinWhittCons",
  Perryton = "perryton",
  Peru = "peru",
  Petersburg = "petersburg",
  Petrolia = "petrolia",
  Pettus = "pettus",
  Pewitt = "pewitt",
  Pflugerville = "pflugerville",
  PharrSanJuanAlamo = "pharrSanJuanAlamo",
  PhoenixCharterSchool = "phoenixCharterSchool",
  PilotPoint = "pilotPoint",
  PineTree = "pineTree",
  PineywoodsCommunityAcademy = "pineywoodsCommunityAcademy",
  Pittsburg = "pittsburg",
  Plains = "plains",
  Plainview = "plainview",
  Plano = "plano",
  PleasantGrove = "pleasantGrove",
  Pleasanton = "pleasanton",
  PlemonsStinnettPhillipsCons = "plemonsStinnettPhillipsCons",
  PointIsabel = "pointIsabel",
  Ponder = "ponder",
  Poolville = "poolville",
  PorVidaAcadCharterHS = "porVidaAcadCharterHS",
  PortAransas = "portAransas",
  PortArthur = "portArthur",
  PortNechesGroves = "portNechesGroves",
  PositiveSolutionsCharterSch = "positiveSolutionsCharterSch",
  Post = "post",
  Poteet = "poteet",
  Poth = "poth",
  Pottsboro = "pottsboro",
  PrairieLea = "prairieLea",
  PrairieValley = "prairieValley",
  Prairiland = "prairiland",
  Premont = "premont",
  Presidio = "presidio",
  Priddy = "priddy",
  Princeton = "princeton",
  PringleMorseCons = "pringleMorseCons",
  Progreso = "progreso",
  Prosper = "prosper",
  PuertoRico = "puertoRico",
  Quanah = "quanah",
  QueenCity = "queenCity",
  Quinlan = "quinlan",
  Quitman = "quitman",
  RadianceAcademyofLearning = "radianceAcademyofLearning",
  Rains = "rains",
  Ralls = "ralls",
  RamirezCSD = "ramirezCSD",
  RanchAcademy = "ranchAcademy",
  RandolphField = "randolphField",
  Ranger = "ranger",
  Rankin = "rankin",
  RapoportCharterSchool = "rapoportCharterSchool",
  RaulYzaguirreSchoolforSucc = "raulYzaguirreSchoolforSucc",
  RavenSchool = "ravenSchool",
  Raymondville = "raymondville",
  ReaganCounty = "reaganCounty",
  RedLick = "redLick",
  RedOak = "redOak",
  Redwater = "redwater",
  Refugio = "refugio",
  Ricardo = "ricardo",
  RiceCons = "riceCons",
  Rice = "rice",
  RichardMilburnAcademyAmar = "richardMilburnAcademyAmar",
  RichardMilburnAcademyBeau = "richardMilburnAcademyBeau",
  RichardMilburnAcademyCorp = "richardMilburnAcademyCorp",
  RichardMilburnAcademyEcto = "richardMilburnAcademyEcto",
  RichardMilburnAcademyFort = "richardMilburnAcademyFort",
  RichardMilburnAcademyLubb = "richardMilburnAcademyLubb",
  RichardMilburnAcademyMidl = "richardMilburnAcademyMidl",
  RichardMilburnAcademySubu = "richardMilburnAcademySubu",
  RichardMilburnAcademyKillee = "richardMilburnAcademyKillee",
  Richards = "richards",
  Richardson = "richardson",
  RichlandSprings = "richlandSprings",
  Riesel = "riesel",
  RioGrandeCityC = "rioGrandeCityC",
  RioHondo = "rioHondo",
  RioVista = "rioVista",
  RipleyHouseCharterSchool = "ripleyHouseCharterSchool",
  RiseAcademy = "riseAcademy",
  RisingStar = "risingStar",
  RiverRoad = "riverRoad",
  Rivercrest = "rivercrest",
  Riviera = "riviera",
  RobertLee = "robertLee",
  Robinson = "robinson",
  Robstown = "robstown",
  RobyCons = "robyCons",
  Rochelle = "rochelle",
  Rochester = "rochester",
  RochesterCountyLine = "rochesterCountyLine",
  Rockdale = "rockdale",
  Rocksprings = "rocksprings",
  Rockwall = "rockwall",
  Rogers = "rogers",
  Roma = "roma",
  RonJacksonStateJuvenileCor = "ronJacksonStateJuvenileCor",
  Roosevelt = "roosevelt",
  Ropes = "ropes",
  Roscoe = "roscoe",
  RosebudLott = "rosebudLott",
  Rotan = "rotan",
  RoundRock = "roundRock",
  RoundTopCarmine = "roundTopCarmine",
  Roxton = "roxton",
  Royal = "royal",
  RoyseCity = "royseCity",
  Rule = "rule",
  Runge = "runge",
  Rusk = "rusk",
  SandSCons = "sandSCons",
  Sabinal = "sabinal",
  Sabine = "sabine",
  SabinePass = "sabinePass",
  SaintJo = "saintJo",
  Salado = "salado",
  Saltillo = "saltillo",
  SamRayburn = "samRayburn",
  Samnorwood = "samnorwood",
  SanAngelo = "sanAngelo",
  SanAntonioCanHighSchool = "sanAntonioCanHighSchool",
  SanAntonio = "sanAntonio",
  SanAntonioPreparatoryAcadem = "sanAntonioPreparatoryAcadem",
  SanAntonioSchoolforInquiry = "sanAntonioSchoolforInquiry",
  SanAntonioTechnologyAcadem = "sanAntonioTechnologyAcadem",
  SanAugustine = "sanAugustine",
  SanBenitoCons = "sanBenitoCons",
  SanDiego = "sanDiego",
  SanElizario = "sanElizario",
  SanFelipeDelRioCons = "sanFelipeDelRioCons",
  SanIsidro = "sanIsidro",
  SanMarcosCons = "sanMarcosCons",
  SanMarcosPreparatorySchool = "sanMarcosPreparatorySchool",
  SanPerlita = "sanPerlita",
  SanSaba = "sanSaba",
  SanSabaStateSchool = "sanSabaStateSchool",
  SanVicente = "sanVicente",
  SandsC = "sandsC",
  Sanford = "sanford",
  Sanger = "sanger",
  SantaAnna = "santaAnna",
  SantaFe = "santaFe",
  SantaGertrudis = "santaGertrudis",
  SantaMaria = "santaMaria",
  SantaRosa = "santaRosa",
  Santo = "santo",
  Savoy = "savoy",
  SchertzCiboloUCity = "schertzCiboloUCity",
  Schleicher = "schleicher",
  SchoolofExcellenceinEducat = "schoolofExcellenceinEducat",
  SchoolofLiberalArtsandSci = "schoolofLiberalArtsandSci",
  Schulenburg = "schulenburg",
  Scotland = "scotland",
  ScurryRosser = "scurryRosser",
  Seagraves = "seagraves",
  Sealy = "sealy",
  SeashoreLearningCtrCharter = "seashoreLearningCtrCharter",
  Seguin = "seguin",
  Seminole = "seminole",
  SentryTechnologyPrepSchool = "sentryTechnologyPrepSchool",
  SerNinosCharterSchool = "serNinosCharterSchool",
  Seymour = "seymour",
  Shallowater = "shallowater",
  Shamrock = "shamrock",
  Sharyland = "sharyland",
  ShekinahRadianceAcademy = "shekinahRadianceAcademy",
  Shelbyville = "shelbyville",
  Sheldon = "sheldon",
  Shepherd = "shepherd",
  Sherman = "sherman",
  Shiner = "shiner",
  Sidney = "sidney",
  SierraBlanca = "sierraBlanca",
  Silsbee = "silsbee",
  Silverton = "silverton",
  Simms = "simms",
  Sinton = "sinton",
  SivellsBend = "sivellsBend",
  SkidmoreTynan = "skidmoreTynan",
  Slaton = "slaton",
  Slidell = "slidell",
  Slocum = "slocum",
  Smithville = "smithville",
  Smyer = "smyer",
  Snook = "snook",
  Snyder = "snyder",
  Socorro = "socorro",
  Somerset = "somerset",
  Somerville = "somerville",
  Sonora = "sonora",
  SouthAfrica = "southAfrica",
  SouthPlainsAcademy = "southPlainsAcademy",
  SouthSanAntonio = "southSanAntonio",
  SouthTexas = "southTexas",
  Southland = "southland",
  Southside = "southside",
  SouthwestHighSchool = "southwestHighSchool",
  Southwest = "southwest",
  SouthwestPreparatorySchool = "southwestPreparatorySchool",
  Spade = "spade",
  Spain = "spain",
  Spearman = "spearman",
  Splendora = "splendora",
  SpringBranch = "springBranch",
  SpringCreek = "springCreek",
  SpringHill = "springHill",
  Spring = "spring",
  SpringlakeEarth = "springlakeEarth",
  Springtown = "springtown",
  Spur = "spur",
  Spurger = "spurger",
  StAnthonyAcademy = "stAnthonyAcademy",
  StMarysAcademyCharterScho = "stMarysAcademyCharterScho",
  StLucia = "stLucia",
  StVincent = "stVincent",
  StaffordMunicipalSchoolDist = "staffordMunicipalSchoolDist",
  Stamford = "stamford",
  Stanton = "stanton",
  StarCharterSchool = "starCharterSchool",
  Star = "star",
  StateofAlabama = "stateofAlabama",
  StateofArizona = "stateofArizona",
  StateofArkansas = "stateofArkansas",
  StateofColorado = "stateofColorado",
  StateofFlorida = "stateofFlorida",
  StateofIdaho = "stateofIdaho",
  StateofIllinois = "stateofIllinois",
  StateofIndiana = "stateofIndiana",
  StateofKansas = "stateofKansas",
  StateofKentucky = "stateofKentucky",
  StateofMassachusetts = "stateofMassachusetts",
  StateofMichigan = "stateofMichigan",
  StateofMississippi = "stateofMississippi",
  StateofMissouri = "stateofMissouri",
  StateofMontana = "stateofMontana",
  StateofNewJersey = "stateofNewJersey",
  StateofNorthCarolina = "stateofNorthCarolina",
  StateofNorthDakota = "stateofNorthDakota",
  StateofOhio = "stateofOhio",
  StateofPennsylvania = "stateofPennsylvania",
  StateofSouthDakota = "stateofSouthDakota",
  StateofVirginia = "stateofVirginia",
  StateofWestVirginia = "stateofWestVirginia",
  StateofWisconsin = "stateofWisconsin",
  StateofWyoming = "stateofWyoming",
  Stephenville = "stephenville",
  StephenvilleIsd = "stephenvilleIsd",
  SterlingCity = "sterlingCity",
  Stockdale = "stockdale",
  Stratford = "stratford",
  Strawn = "strawn",
  Sudan = "sudan",
  SulphurBluff = "sulphurBluff",
  SulphurSprings = "sulphurSprings",
  SulphurOK = "sulphurOK",
  Sundown = "sundown",
  Sunnyvale = "sunnyvale",
  Sunray = "sunray",
  Sweeny = "sweeny",
  SweetHome = "sweetHome",
  Sweetwater = "sweetwater",
  Taft = "taft",
  Tahoka = "tahoka",
  Tarkington = "tarkington",
  Tatum = "tatum",
  Taylor = "taylor",
  Teague = "teague",
  TechnologyEducationCharterH = "technologyEducationCharterH",
  TekoaAcademyofAcceleratedS = "tekoaAcademyofAcceleratedS",
  Temple = "temple",
  Tenaha = "tenaha",
  Tennessee = "tennessee",
  TerlinguaCSD = "terlinguaCSD",
  TerrellCounty = "terrellCounty",
  Terrell = "terrell",
  Texarkana = "texarkana",
  TexasAMUniversityatGalv = "texasAMUniversityatGalv",
  TexasAcademyofExcellence = "texasAcademyofExcellence",
  TexasAcademyofLeadershipin = "texasAcademyofLeadershipin",
  TexasCity = "texasCity",
  TexasEmpowermentAcademy = "texasEmpowermentAcademy",
  TexasSchfortheBlindampVisu = "texasSchfortheBlindampVisu",
  TexasSchfortheDeaf = "texasSchfortheDeaf",
  TexasSerenityAcademy = "texasSerenityAcademy",
  TexasSerenityAcademyBaysho = "texasSerenityAcademyBaysho",
  TexasTechUniversityHighSch = "texasTechUniversityHighSch",
  Texhoma = "texhoma",
  Texline = "texline",
  ThePhilippines = "thePhilippines",
  TheZoeLearningAcademy = "theZoeLearningAcademy",
  TheresaBLeeAcademy = "theresaBLeeAcademy",
  Thorndale = "thorndale",
  Thrall = "thrall",
  ThreeRivers = "threeRivers",
  ThreeWay = "threeWay",
  Throckmorton = "throckmorton",
  Tidehaven = "tidehaven",
  Timpson = "timpson",
  Tioga = "tioga",
  Tolar = "tolar",
  TomBean = "tomBean",
  Tomball = "tomball",
  Tornillo = "tornillo",
  TovasTactileOralVisualAlte = "tovasTactileOralVisualAlte",
  TransformativeCharterAcademy = "transformativeCharterAcademy",
  TreetopsSchoolInternational = "treetopsSchoolInternational",
  Trent = "trent",
  Trenton = "trenton",
  Trinidad = "trinidad",
  TrinityBasinPreparatory = "trinityBasinPreparatory",
  Trinity = "trinity",
  Troup = "troup",
  Troy = "troy",
  Tulia = "tulia",
  TulosoMidway = "tulosoMidway",
  Tupelo = "tupelo",
  TurkeyQuitaque = "turkeyQuitaque",
  TwoDimensionsPreparatoryAca = "twoDimensionsPreparatoryAca",
  Tyler = "tyler",
  UnionGrove = "unionGrove",
  UnionHill = "unionHill",
  United = "united",
  UnivofHoustonCharterSchTe = "univofHoustonCharterSchTe",
  UniversalAcademy = "universalAcademy",
  UniversityCharterSchool = "universityCharterSchool",
  UniversityofNorthTexas = "universityofNorthTexas",
  UniversityofTexasatAustin = "universityofTexasatAustin",
  UniversityofTexasElementary = "universityofTexasElementary",
  Utopia = "utopia",
  UvaldeCons = "uvaldeCons",
  Valentine = "valentine",
  ValleyHigh = "valleyHigh",
  ValleyMills = "valleyMills",
  ValleyView = "valleyView",
  VanAlstyne = "vanAlstyne",
  Van = "van",
  VanVleck = "vanVleck",
  VanguardAcademy = "vanguardAcademy",
  VarnettCharterSchool = "varnettCharterSchool",
  Vega = "vega",
  Venus = "venus",
  Veribest = "veribest",
  Vernon = "vernon",
  Victoria = "victoria",
  Vidor = "vidor",
  Vysehrad = "vysehrad",
  WacoCharterSchoolEoac = "wacoCharterSchoolEoac",
  Waco = "waco",
  Waelder = "waelder",
  Walcott = "walcott",
  Wall = "wall",
  Waller = "waller",
  WalnutBend = "walnutBend",
  WalnutSprings = "walnutSprings",
  Warren = "warren",
  WaSetPreparatoryAcademy = "waSetPreparatoryAcademy",
  Washington = "washington",
  Waskom = "waskom",
  WaterValley = "waterValley",
  Waxahachie = "waxahachie",
  Weatherford = "weatherford",
  WebbCons = "webbCons",
  Weimar = "weimar",
  Wellington = "wellington",
  WellmanUnionCons = "wellmanUnionCons",
  Wells = "wells",
  Weslaco = "weslaco",
  WestHardinCountyCons = "westHardinCountyCons",
  WestHoustonCharterSchool = "westHoustonCharterSchool",
  West = "west",
  WestOrangeCoveCons = "westOrangeCoveCons",
  WestOso = "westOso",
  WestRusk = "westRusk",
  WestSabine = "westSabine",
  WestTexasStateSchool = "westTexasStateSchool",
  Westbrook = "westbrook",
  Westhoff = "westhoff",
  WestlakeAcademy = "westlakeAcademy",
  Westphalia = "westphalia",
  Westwood = "westwood",
  Wharton = "wharton",
  Wheeler = "wheeler",
  WhiteDeer = "whiteDeer",
  WhiteOak = "whiteOak",
  WhiteSettlement = "whiteSettlement",
  WhitefaceCons = "whitefaceCons",
  Whitehouse = "whitehouse",
  Whitesboro = "whitesboro",
  Whitewright = "whitewright",
  Whitharral = "whitharral",
  Whitney = "whitney",
  WichitaFalls = "wichitaFalls",
  Wildorado = "wildorado",
  Willis = "willis",
  WillsPoint = "willsPoint",
  WilmerHutchins = "wilmerHutchins",
  Wilson = "wilson",
  Wimberley = "wimberley",
  WindhamSchoolDistrict = "windhamSchoolDistrict",
  Windthorst = "windthorst",
  Winfield = "winfield",
  WinfreeAcademy = "winfreeAcademy",
  WinkLoving = "winkLoving",
  Winnsboro = "winnsboro",
  Winona = "winona",
  Winters = "winters",
  Woden = "woden",
  WolfeCity = "wolfeCity",
  Woodsboro = "woodsboro",
  Woodson = "woodson",
  Woodville = "woodville",
  Wortham = "wortham",
  Wylie = "wylie",
  WylieTaylorCo = "wylieTaylorCo",
  Yantis = "yantis",
  YesCollegePreparatorySchool = "yesCollegePreparatorySchool",
  Yoakum = "yoakum",
  Yorktown = "yorktown",
  Ysleta = "ysleta",
  ZapataCounty = "zapataCounty",
  Zavalla = "zavalla",
  Zephyr = "zephyr"
}

export const SchoolDistrictLabel: Record<SchoolDistrict, string> = {
  [SchoolDistrict.AWBrownFellowshipCharterS]: "A W Brown-Fellowship Charter S",
  [SchoolDistrict.APlusAcademy]: "A+ Academy",
  [SchoolDistrict.Abbott]: "Abbott ISD",
  [SchoolDistrict.Abernathy]: "Abernathy ISD",
  [SchoolDistrict.Abilene]: "Abilene ISD",
  [SchoolDistrict.Academy]: "Academy ISD",
  [SchoolDistrict.AcademyofAcceleratedLearnin]:
    "Academy of Accelerated Learnin",
  [SchoolDistrict.AcademyofBeaumont]: "Academy of Beaumont",
  [SchoolDistrict.AcademyofCareersandTechnol]: "Academy of Careers and Technol",
  [SchoolDistrict.AcademyofDallas]: "Academy of Dallas",
  [SchoolDistrict.AcademyofHouston]: "Academy of Houston",
  [SchoolDistrict.AcceleratedIntermediateAcade]:
    "Accelerated Intermediate Acade",
  [SchoolDistrict.Adrian]: "Adrian ISD",
  [SchoolDistrict.AguaDulce]: "Agua Dulce ISD",
  [SchoolDistrict.AlPriceStateJuvenileCorrec]: "Al Price State Juvenile Correc",
  [SchoolDistrict.AlamoHeights]: "Alamo Heights ISD",
  [SchoolDistrict.Alaska]: "Alaska",
  [SchoolDistrict.AlbaGolden]: "Alba-Golden ISD",
  [SchoolDistrict.Albany]: "Albany ISD",
  [SchoolDistrict.Aldine]: "Aldine ISD",
  [SchoolDistrict.Aledo]: "Aledo ISD",
  [SchoolDistrict.Alice]: "Alice ISD",
  [SchoolDistrict.Alief]: "Alief ISD",
  [SchoolDistrict.AliefMontessoriCommunitySch]:
    "Alief Montessori Community Sch",
  [SchoolDistrict.Allen]: "Allen ISD",
  [SchoolDistrict.AlphaCharterSchool]: "Alpha Charter School",
  [SchoolDistrict.AlphonsoCrutchsLifeSupport]: "Alphonso Crutch's-Life Support",
  [SchoolDistrict.Alpine]: "Alpine ISD",
  [SchoolDistrict.Alto]: "Alto ISD",
  [SchoolDistrict.Alvarado]: "Alvarado ISD",
  [SchoolDistrict.Alvin]: "Alvin ISD",
  [SchoolDistrict.Alvord]: "Alvord ISD",
  [SchoolDistrict.Amarillo]: "Amarillo ISD",
  [SchoolDistrict.AmericanAcademyofExcellence]:
    "American Academy of Excellence",
  [SchoolDistrict.AmericanYouthWorksCharterS]: "American Youth Works Charter S",
  [SchoolDistrict.Amherst]: "Amherst ISD",
  [SchoolDistrict.AmigosporVidaFriendsforLI]: "Amigos por Vida-Friends for LI",
  [SchoolDistrict.Anahuac]: "Anahuac ISD",
  [SchoolDistrict.AndersonShiroCons]: "Anderson-Shiro Cons ISD",
  [SchoolDistrict.Andrews]: "Andrews ISD",
  [SchoolDistrict.Angleton]: "Angleton ISD",
  [SchoolDistrict.Anguilla]: "Anguilla",
  [SchoolDistrict.Anna]: "Anna ISD",
  [SchoolDistrict.Anson]: "Anson ISD",
  [SchoolDistrict.Anthony]: "Anthony",
  [SchoolDistrict.Anton]: "Anton ISD",
  [SchoolDistrict.AppleSprings]: "Apple Springs ISD",
  [SchoolDistrict.Aquilla]: "Aquilla ISD",
  [SchoolDistrict.AransasCounty]: "Aransas County ISD",
  [SchoolDistrict.AransasPass]: "Aransas Pass ISD",
  [SchoolDistrict.ArcherCity]: "Archer City ISD",
  [SchoolDistrict.Argyle]: "Argyle ISD",
  [SchoolDistrict.Arizona]: "Arizona",
  [SchoolDistrict.Arkansas]: "Arkansas",
  [SchoolDistrict.ArlingtonClassicsAcademy]: "Arlington Classics Academy",
  [SchoolDistrict.Arlington]: "Arlington ISD",
  [SchoolDistrict.Arp]: "Arp ISD",
  [SchoolDistrict.Aruba]: "Aruba",
  [SchoolDistrict.Aspermont]: "Aspermont ISD",
  [SchoolDistrict.Athens]: "Athens ISD",
  [SchoolDistrict.Atlanta]: "Atlanta ISD",
  [SchoolDistrict.Aubrey]: "Aubrey ISD",
  [SchoolDistrict.AustinCanAcademyCharterSch]: "Austin Can Academy Charter Sch",
  [SchoolDistrict.Austin]: "Austin ISD",
  [SchoolDistrict.Australia]: "Australia",
  [SchoolDistrict.AustwellTivoli]: "Austwell-Tivoli ISD",
  [SchoolDistrict.Avalon]: "Avalon ISD",
  [SchoolDistrict.Avery]: "Avery ISD",
  [SchoolDistrict.Avinger]: "Avinger ISD",
  [SchoolDistrict.Axtell]: "Axtell ISD",
  [SchoolDistrict.Azle]: "Azle ISD",
  [SchoolDistrict.AzlewayCharterSchool]: "Azleway Charter School",
  [SchoolDistrict.Bahamas]: "Bahamas",
  [SchoolDistrict.Baird]: "Baird ISD",
  [SchoolDistrict.Ballinger]: "Ballinger ISD",
  [SchoolDistrict.Balmorhea]: "Balmorhea ISD",
  [SchoolDistrict.Bandera]: "Bandera ISD",
  [SchoolDistrict.Bangs]: "Bangs ISD",
  [SchoolDistrict.Banquete]: "Banquete ISD",
  [SchoolDistrict.Barbados]: "Barbados",
  [SchoolDistrict.BarbersHill]: "Barbers Hill ISD",
  [SchoolDistrict.Bartlett]: "Bartlett ISD",
  [SchoolDistrict.Bastrop]: "Bastrop ISD",
  [SchoolDistrict.BayAreaCharterSchool]: "Bay Area Charter School",
  [SchoolDistrict.BayCity]: "Bay City ISD",
  [SchoolDistrict.BeatriceMayesInstituteChart]:
    "Beatrice Mayes Institute Chart",
  [SchoolDistrict.Beaumont]: "Beaumont ISD",
  [SchoolDistrict.Beckville]: "Beckville ISD",
  [SchoolDistrict.Beeville]: "Beeville ISD",
  [SchoolDistrict.Belize]: "Belize",
  [SchoolDistrict.Bellevue]: "Bellevue ISD",
  [SchoolDistrict.Bells]: "Bells ISD",
  [SchoolDistrict.Bellville]: "Bellville ISD",
  [SchoolDistrict.Belton]: "Belton ISD",
  [SchoolDistrict.BenBoltPalitoBlanco]: "Ben Bolt-Palito Blanco ISD",
  [SchoolDistrict.Benavides]: "Benavides ISD",
  [SchoolDistrict.Benjamin]: "Benjamin ISD",
  [SchoolDistrict.BenjisSpecialEducationalAc]: "Benji's Special Educational Ac",
  [SchoolDistrict.BexarCountyAcademy]: "Bexar County Academy",
  [SchoolDistrict.BigSandy]: "Big Sandy ISD",
  [SchoolDistrict.BigSpring]: "Big Spring ISD",
  [SchoolDistrict.BigSpringsCharterSchool]: "Big Springs Charter School",
  [SchoolDistrict.Birdville]: "Birdville ISD",
  [SchoolDistrict.BishopCons]: "Bishop Cons ISD",
  [SchoolDistrict.BlackwellCons]: "Blackwell Cons ISD",
  [SchoolDistrict.Blanco]: "Blanco ISD",
  [SchoolDistrict.Bland]: "Bland ISD",
  [SchoolDistrict.Blanket]: "Blanket ISD",
  [SchoolDistrict.Bloomburg]: "Bloomburg ISD",
  [SchoolDistrict.BloomingGrove]: "Blooming Grove ISD",
  [SchoolDistrict.Bloomington]: "Bloomington ISD",
  [SchoolDistrict.BlueRidge]: "Blue Ridge ISD",
  [SchoolDistrict.BluffDale]: "Bluff Dale ISD",
  [SchoolDistrict.Blum]: "Blum ISD",
  [SchoolDistrict.Boerne]: "Boerne ISD",
  [SchoolDistrict.Boles]: "Boles ISD",
  [SchoolDistrict.Boling]: "Boling ISD",
  [SchoolDistrict.Bonham]: "Bonham ISD",
  [SchoolDistrict.Booker]: "Booker ISD",
  [SchoolDistrict.BordenCounty]: "Borden County ISD",
  [SchoolDistrict.Borger]: "Borger ISD",
  [SchoolDistrict.Bosqueville]: "Bosqueville ISD",
  [SchoolDistrict.Bovina]: "Bovina ISD",
  [SchoolDistrict.Bowie]: "Bowie ISD",
  [SchoolDistrict.Boyd]: "Boyd ISD",
  [SchoolDistrict.BoysRanch]: "Boys Ranch ISD",
  [SchoolDistrict.Brackett]: "Brackett ISD",
  [SchoolDistrict.Brady]: "Brady ISD",
  [SchoolDistrict.Brazil]: "Brazil",
  [SchoolDistrict.Brazos]: "Brazos ISD",
  [SchoolDistrict.BrazosRiverCharterSchool]: "Brazos River Charter School",
  [SchoolDistrict.BrazosSchoolforInquiry]: "Brazos School for Inquiry &amp; Cr",
  [SchoolDistrict.Brazosport]: "Brazosport ISD",
  [SchoolDistrict.Breckenridge]: "Breckenridge ISD",
  [SchoolDistrict.Bremond]: "Bremond ISD",
  [SchoolDistrict.Brenham]: "Brenham ISD",
  [SchoolDistrict.BridgeCity]: "Bridge City ISD",
  [SchoolDistrict.Bridgeport]: "Bridgeport ISD",
  [SchoolDistrict.BrightIdeasCharter]: "Bright Ideas Charter",
  [SchoolDistrict.BritishVirginIslands]: "British Virgin Islands",
  [SchoolDistrict.Broaddus]: "Broaddus ISD",
  [SchoolDistrict.Brock]: "Brock ISD",
  [SchoolDistrict.Bronte]: "Bronte ISD",
  [SchoolDistrict.Brookeland]: "Brookeland ISD",
  [SchoolDistrict.Brookesmith]: "Brookesmith ISD",
  [SchoolDistrict.BrooksCounty]: "Brooks County ISD",
  [SchoolDistrict.Brownfield]: "Brownfield ISD",
  [SchoolDistrict.Brownsboro]: "Brownsboro ISD",
  [SchoolDistrict.Brownsville]: "Brownsville ISD",
  [SchoolDistrict.Brownwood]: "Brownwood ISD",
  [SchoolDistrict.BrucevilleEddy]: "Bruceville-Eddy ISD",
  [SchoolDistrict.Bryan]: "Bryan ISD",
  [SchoolDistrict.Bryson]: "Bryson ISD",
  [SchoolDistrict.Buckholts]: "Buckholts ISD",
  [SchoolDistrict.BuenaVista]: "Buena Vista ISD",
  [SchoolDistrict.Buffalo]: "Buffalo ISD",
  [SchoolDistrict.Bulgaria]: "Bulgaria",
  [SchoolDistrict.Bullard]: "Bullard ISD",
  [SchoolDistrict.Buna]: "Buna ISD",
  [SchoolDistrict.Burkburnett]: "Burkburnett ISD",
  [SchoolDistrict.Burkeville]: "Burkeville ISD",
  [SchoolDistrict.Burleson]: "Burleson ISD",
  [SchoolDistrict.BurnetCons]: "Burnet Cons ISD",
  [SchoolDistrict.BurnhamWoodCharterSchool]: "Burnham Wood Charter School",
  [SchoolDistrict.Burton]: "Burton ISD",
  [SchoolDistrict.Bushland]: "Bushland ISD",
  [SchoolDistrict.Byers]: "Byers ISD",
  [SchoolDistrict.Bynum]: "Bynum ISD",
  [SchoolDistrict.CaddoMills]: "Caddo Mills ISD",
  [SchoolDistrict.Calallen]: "Calallen ISD",
  [SchoolDistrict.Caldwell]: "Caldwell ISD",
  [SchoolDistrict.CalhounCo]: "Calhoun Co ISD",
  [SchoolDistrict.California]: "California",
  [SchoolDistrict.Callisburg]: "Callisburg ISD",
  [SchoolDistrict.Calvert]: "Calvert ISD",
  [SchoolDistrict.CalvinNelmsCharterSchools]: "Calvin Nelms Charter Schools",
  [SchoolDistrict.Cameron]: "Cameron ISD",
  [SchoolDistrict.Campbell]: "Campbell ISD",
  [SchoolDistrict.Canada]: "Canada",
  [SchoolDistrict.Canadian]: "Canadian ISD",
  [SchoolDistrict.Canton]: "Canton ISD",
  [SchoolDistrict.Canutillo]: "Canutillo ISD",
  [SchoolDistrict.Canyon]: "Canyon ISD",
  [SchoolDistrict.CareerPlusLearningAcademy]: "Career Plus Learning Academy",
  [SchoolDistrict.Carlisle]: "Carlisle ISD",
  [SchoolDistrict.CarrizoSpringsCons]: "Carrizo Springs Cons ISD",
  [SchoolDistrict.Carroll]: "Carroll ISD",
  [SchoolDistrict.CarrolltonFarmersBranch]: "Carrollton-Farmers Branch ISD",
  [SchoolDistrict.Carthage]: "Carthage ISD",
  [SchoolDistrict.Castleberry]: "Castleberry ISD",
  [SchoolDistrict.CaymanIslands]: "Cayman Islands",
  [SchoolDistrict.Cayuga]: "Cayuga ISD",
  [SchoolDistrict.CedarCrestCharterSchool]: "Cedar Crest Charter School",
  [SchoolDistrict.CedarHill]: "Cedar Hill ISD",
  [SchoolDistrict.CedarRidgeCharterSchool]: "Cedar Ridge Charter School",
  [SchoolDistrict.CedarsInternationalAcademy]: "Cedars International Academy",
  [SchoolDistrict.Celeste]: "Celeste ISD",
  [SchoolDistrict.Celina]: "Celina ISD",
  [SchoolDistrict.Center]: "Center ISD",
  [SchoolDistrict.CenterPoint]: "Center Point ISD",
  [SchoolDistrict.Centerville]: "Centerville ISD",
  [SchoolDistrict.CentralHeights]: "Central Heights ISD",
  [SchoolDistrict.Central]: "Central ISD",
  [SchoolDistrict.Channelview]: "Channelview ISD",
  [SchoolDistrict.Channing]: "Channing ISD",
  [SchoolDistrict.ChapelHill]: "Chapel Hill ISD",
  [SchoolDistrict.Charlotte]: "Charlotte ISD",
  [SchoolDistrict.Cherokee]: "Cherokee ISD",
  [SchoolDistrict.Chester]: "Chester ISD",
  [SchoolDistrict.Chico]: "Chico ISD",
  [SchoolDistrict.ChildrenFirstAcademyofDall]: "Children First Academy of Dall",
  [SchoolDistrict.ChildrenFirstAcademyofHous]: "Children First Academy of Hous",
  [SchoolDistrict.ChildrenoftheSunCharterSc]: "Children of the Sun Charter Sc",
  [SchoolDistrict.Childress]: "Childress ISD",
  [SchoolDistrict.Chile]: "Chile",
  [SchoolDistrict.Chillicothe]: "Chillicothe ISD",
  [SchoolDistrict.Chilton]: "Chilton   ISD",
  [SchoolDistrict.ChinaSpring]: "China Spring ISD",
  [SchoolDistrict.Chireno]: "Chireno ISD",
  [SchoolDistrict.Chisum]: "Chisum ISD",
  [SchoolDistrict.Christoval]: "Christoval ISD",
  [SchoolDistrict.Cisco]: "Cisco ISD",
  [SchoolDistrict.CityView]: "City View ISD",
  [SchoolDistrict.Clarendon]: "Clarendon ISD",
  [SchoolDistrict.Clarksville]: "Clarksville ISD",
  [SchoolDistrict.Claude]: "Claude ISD",
  [SchoolDistrict.ClearCreek]: "Clear Creek ISD",
  [SchoolDistrict.Cleburne]: "Cleburne ISD",
  [SchoolDistrict.Cleveland]: "Cleveland ISD",
  [SchoolDistrict.Clifton]: "Clifton ISD",
  [SchoolDistrict.Clint]: "Clint ISD",
  [SchoolDistrict.ClydeCons]: "Clyde Cons ISD",
  [SchoolDistrict.Coahoma]: "Coahoma ISD",
  [SchoolDistrict.CoastalBendYouthCity]: "Coastal Bend Youth City",
  [SchoolDistrict.ColdspringOakhurstCons]: "Coldspring-Oakhurst Cons ISD",
  [SchoolDistrict.Coleman]: "Coleman ISD",
  [SchoolDistrict.CollegeStation]: "College Station ISD",
  [SchoolDistrict.Collinsville]: "Collinsville ISD",
  [SchoolDistrict.Colmesneil]: "Colmesneil ISD",
  [SchoolDistrict.Colorado]: "Colorado ISD",
  [SchoolDistrict.ColumbiaBrazoria]: "Columbia-Brazoria ISD",
  [SchoolDistrict.Columbus]: "Columbus ISD",
  [SchoolDistrict.Comal]: "Comal ISD",
  [SchoolDistrict.Comanche]: "Comanche ISD",
  [SchoolDistrict.Comfort]: "Comfort ISD",
  [SchoolDistrict.Commerce]: "Commerce ISD",
  [SchoolDistrict.Community]: "Community ISD",
  [SchoolDistrict.ComoPicktonC]: "Como-Pickton CISD",
  [SchoolDistrict.ComquestAcademy]: "Comquest Academy",
  [SchoolDistrict.Comstock]: "Comstock ISD",
  [SchoolDistrict.Connally]: "Connally ISD",
  [SchoolDistrict.Connecticut]: "Connecticut",
  [SchoolDistrict.Conroe]: "Conroe ISD",
  [SchoolDistrict.Coolidge]: "Coolidge ISD",
  [SchoolDistrict.Cooper]: "Cooper ISD",
  [SchoolDistrict.Coppell]: "Coppell ISD",
  [SchoolDistrict.CopperasCove]: "Copperas Cove ISD",
  [SchoolDistrict.CorpusChristi]: "Corpus Christi ISD",
  [SchoolDistrict.CorriganCamden]: "Corrigan-Camden ISD",
  [SchoolDistrict.Corsicana]: "Corsicana ISD",
  [SchoolDistrict.CorsicanaResidentialTreatmen]:
    "Corsicana Residential Treatmen",
  [SchoolDistrict.CostaRica]: "Costa Rica",
  [SchoolDistrict.CottonCenter]: "Cotton Center ISD",
  [SchoolDistrict.Cotulla]: "Cotulla ISD",
  [SchoolDistrict.Coupland]: "Coupland ISD",
  [SchoolDistrict.Covington]: "Covington ISD",
  [SchoolDistrict.Crandall]: "Crandall ISD",
  [SchoolDistrict.Crane]: "Crane ISD",
  [SchoolDistrict.CranfillsGap]: "Cranfills Gap ISD",
  [SchoolDistrict.Crawford]: "Crawford ISD",
  [SchoolDistrict.CrockettCoConsCSD]: "Crockett Co Cons CSD",
  [SchoolDistrict.Crockett]: "Crockett ISD",
  [SchoolDistrict.CrockettStateSchool]: "Crockett State School",
  [SchoolDistrict.Crosby]: "Crosby ISD",
  [SchoolDistrict.CrosbytonC]: "Crosbyton CISD",
  [SchoolDistrict.CrossPlains]: "Cross Plains ISD",
  [SchoolDistrict.CrossRoads]: "Cross Roads ISD",
  [SchoolDistrict.CrossroadsCommunityEdCtrCh]: "Crossroads Community Ed Ctr Ch",
  [SchoolDistrict.Crowell]: "Crowell ISD",
  [SchoolDistrict.Crowley]: "Crowley ISD",
  [SchoolDistrict.CrystalCity]: "Crystal City ISD",
  [SchoolDistrict.Cuero]: "Cuero ISD",
  [SchoolDistrict.CulbersonCountyAllamoore]: "Culberson County-Allamoore ISD",
  [SchoolDistrict.CumberlandAcademy]: "Cumberland Academy",
  [SchoolDistrict.Cumby]: "Cumby ISD",
  [SchoolDistrict.Cushing]: "Cushing ISD",
  [SchoolDistrict.CypressFairbanks]: "Cypress-Fairbanks ISD",
  [SchoolDistrict.DaingerfieldLoneStar]: "Daingerfield-Lone Star ISD",
  [SchoolDistrict.Dalhart]: "Dalhart ISD",
  [SchoolDistrict.DallasCanAcademyCharter]: "Dallas Can Academy Charter",
  [SchoolDistrict.DallasCommunityCharterSchoo]:
    "Dallas Community Charter Schoo",
  [SchoolDistrict.DallasCountyJuvenileJustice]:
    "Dallas County Juvenile Justice",
  [SchoolDistrict.Dallas]: "Dallas ISD",
  [SchoolDistrict.Damon]: "Damon ISD",
  [SchoolDistrict.Danbury]: "Danbury ISD",
  [SchoolDistrict.Darrouzett]: "Darrouzett ISD",
  [SchoolDistrict.Dawson]: "Dawson ISD",
  [SchoolDistrict.Dayton]: "Dayton ISD",
  [SchoolDistrict.DeLeon]: "De Leon ISD",
  [SchoolDistrict.Decatur]: "Decatur ISD",
  [SchoolDistrict.DeerPark]: "Deer Park ISD",
  [SchoolDistrict.Dekalb]: "Dekalb ISD",
  [SchoolDistrict.DelValle]: "Del Valle ISD",
  [SchoolDistrict.DellCity]: "Dell City ISD",
  [SchoolDistrict.Denison]: "Denison ISD",
  [SchoolDistrict.Denton]: "Denton ISD",
  [SchoolDistrict.DenverCity]: "Denver City ISD",
  [SchoolDistrict.Desoto]: "Desoto ISD",
  [SchoolDistrict.Detroit]: "Detroit ISD",
  [SchoolDistrict.Devers]: "Devers ISD",
  [SchoolDistrict.Devine]: "Devine ISD",
  [SchoolDistrict.Dew]: "Dew ISD",
  [SchoolDistrict.Deweyville]: "Deweyville ISD",
  [SchoolDistrict.DHanis]: "D'Hanis ISD",
  [SchoolDistrict.Diboll]: "Diboll ISD",
  [SchoolDistrict.Dickinson]: "Dickinson ISD",
  [SchoolDistrict.Dilley]: "Dilley ISD",
  [SchoolDistrict.DimeBox]: "Dime Box ISD",
  [SchoolDistrict.Dimmitt]: "Dimmitt ISD",
  [SchoolDistrict.Divide]: "Divide ISD",
  [SchoolDistrict.DoddCity]: "Dodd City ISD",
  [SchoolDistrict.DominicanRepublic]: "Dominican Republic",
  [SchoolDistrict.Donna]: "Donna ISD",
  [SchoolDistrict.DossConsCSD]: "Doss Cons CSD",
  [SchoolDistrict.Douglass]: "Douglass ISD",
  [SchoolDistrict.DrMLGarzaGonzalezCharter]: "Dr M L Garza-Gonzalez Charter",
  [SchoolDistrict.DrippingSprings]: "Dripping Springs ISD",
  [SchoolDistrict.Driscoll]: "Driscoll ISD",
  [SchoolDistrict.Dublin]: "Dublin ISD",
  [SchoolDistrict.Dumas]: "Dumas ISD",
  [SchoolDistrict.DuncanOK]: "Duncan, OK",
  [SchoolDistrict.Duncanville]: "Duncanville ISD",
  [SchoolDistrict.EagleAcademyofBeaumont]: "Eagle Academy of Beaumont",
  [SchoolDistrict.EagleAcademyofBrownsville]: "Eagle Academy of Brownsville",
  [SchoolDistrict.EagleAcademyofBryan]: "Eagle Academy of Bryan",
  [SchoolDistrict.EagleAcademyofDallas]: "Eagle Academy of Dallas",
  [SchoolDistrict.EagleAcademyofDelRio]: "Eagle Academy of Del Rio",
  [SchoolDistrict.EagleAcademyofFortWorth]: "Eagle Academy of Fort Worth",
  [SchoolDistrict.EagleAcademyofLaredo]: "Eagle Academy of Laredo",
  [SchoolDistrict.EagleAcademyofLubbock]: "Eagle Academy of Lubbock",
  [SchoolDistrict.EagleAcademyofMidland]: "Eagle Academy of Midland",
  [SchoolDistrict.EagleAcademyofPharrMcAllen]: "Eagle Academy of Pharr/McAllen",
  [SchoolDistrict.EagleAcademyofSanAntonio]: "Eagle Academy of San Antonio",
  [SchoolDistrict.EagleAcademyofTexarkana]: "Eagle Academy of Texarkana",
  [SchoolDistrict.EagleAcademyofTyler]: "Eagle Academy of Tyler",
  [SchoolDistrict.EagleAcademyofWaco]: "Eagle Academy of Waco",
  [SchoolDistrict.EagleAdvantageSchool]: "Eagle Advantage School",
  [SchoolDistrict.EagleMTSaginaw]: "Eagle MT-Saginaw ISD",
  [SchoolDistrict.EaglePass]: "Eagle Pass ISD",
  [SchoolDistrict.Eanes]: "Eanes ISD",
  [SchoolDistrict.Early]: "Early ISD",
  [SchoolDistrict.EastBernard]: "East Bernard ISD",
  [SchoolDistrict.EastCentral]: "East Central ISD",
  [SchoolDistrict.EastChambers]: "East Chambers ISD",
  [SchoolDistrict.EastFortWorthMontessoriAca]: "East Fort Worth Montessori Aca",
  [SchoolDistrict.EastTexasCharterSchools]: "East Texas Charter Schools",
  [SchoolDistrict.Eastland]: "Eastland ISD",
  [SchoolDistrict.EctorCounty]: "Ector County ISD",
  [SchoolDistrict.Ector]: "Ector ISD",
  [SchoolDistrict.Ecuador]: "Ecuador",
  [SchoolDistrict.EdcouchElsa]: "Edcouch-Elsa ISD",
  [SchoolDistrict.EdenC]: "Eden C I S D",
  [SchoolDistrict.EdenParkAcademy]: "Eden Park Academy",
  [SchoolDistrict.Edgewood]: "Edgewood ISD",
  [SchoolDistrict.EdinburgConsolidated]: "Edinburg Consolidated",
  [SchoolDistrict.Edna]: "Edna ISD",
  [SchoolDistrict.EducationCenter]: "Education Center",
  [SchoolDistrict.EducationCenterInternational]:
    "Education Center International",
  [SchoolDistrict.EhrhartSchool]: "Ehrhart School",
  [SchoolDistrict.ElCampo]: "El Campo ISD",
  [SchoolDistrict.ElPasoAcademy]: "El Paso Academy",
  [SchoolDistrict.ElPaso]: "El Paso ISD",
  [SchoolDistrict.ElPasoSchoolofExcellence]: "El Paso School of Excellence",
  [SchoolDistrict.ElSalvador]: "El Salvador",
  [SchoolDistrict.Electra]: "Electra ISD",
  [SchoolDistrict.Elgin]: "Elgin ISD",
  [SchoolDistrict.Elkhart]: "Elkhart ISD",
  [SchoolDistrict.ElysianFields]: "Elysian Fields ISD",
  [SchoolDistrict.EncinoSchool]: "Encino School",
  [SchoolDistrict.Ennis]: "Ennis ISD",
  [SchoolDistrict.Era]: "Era ISD",
  [SchoolDistrict.ErathExcelsAcademyInc]: "Erath Excels Academy Inc",
  [SchoolDistrict.Etoile]: "Etoile ISD",
  [SchoolDistrict.Eula]: "Eula ISD",
  [SchoolDistrict.Eustace]: "Eustace ISD",
  [SchoolDistrict.Evadale]: "Evadale ISD",
  [SchoolDistrict.Evant]: "Evant ISD",
  [SchoolDistrict.Everman]: "Everman ISD",
  [SchoolDistrict.EvinsRegionalJuvenileCenter]:
    "Evins Regional Juvenile Center",
  [SchoolDistrict.EvolutionAcademyCharterScho]:
    "Evolution Academy Charter Scho",
  [SchoolDistrict.Excelsior]: "Excelsior ISD",
  [SchoolDistrict.Ezzell]: "Ezzell ISD",
  [SchoolDistrict.Fabens]: "Fabens ISD",
  [SchoolDistrict.Fairfield]: "Fairfield ISD",
  [SchoolDistrict.FallsCity]: "Falls City ISD",
  [SchoolDistrict.Fannindel]: "Fannindel ISD",
  [SchoolDistrict.Farmersville]: "Farmersville ISD",
  [SchoolDistrict.Farwell]: "Farwell ISD",
  [SchoolDistrict.Fayetteville]: "Fayetteville ISD",
  [SchoolDistrict.Ferris]: "Ferris ISD",
  [SchoolDistrict.Flatonia]: "Flatonia ISD",
  [SchoolDistrict.Florence]: "Florence ISD",
  [SchoolDistrict.Floresville]: "Floresville ISD",
  [SchoolDistrict.Florida]: "Florida",
  [SchoolDistrict.FlourBluff]: "Flour Bluff ISD",
  [SchoolDistrict.Floydada]: "Floydada ISD",
  [SchoolDistrict.FocusLearningAcademy]: "Focus Learning Academy",
  [SchoolDistrict.Follett]: "Follett ISD",
  [SchoolDistrict.Forestburg]: "Forestburg ISD",
  [SchoolDistrict.Forney]: "Forney ISD",
  [SchoolDistrict.Forsan]: "Forsan ISD",
  [SchoolDistrict.FortBend]: "Fort Bend ISD",
  [SchoolDistrict.FortElliottC]: "Fort Elliott CISD",
  [SchoolDistrict.FortWorthAcademyofFineArt]: "Fort Worth Academy of Fine Art",
  [SchoolDistrict.FortWorthCanAcademy]: "Fort Worth Can Academy",
  [SchoolDistrict.FortWorth]: "Fort Worth ISD",
  [SchoolDistrict.France]: "France",
  [SchoolDistrict.Franklin]: "Franklin ISD",
  [SchoolDistrict.Frankston]: "Frankston ISD",
  [SchoolDistrict.Fredericksburg]: "Fredericksburg ISD",
  [SchoolDistrict.Freer]: "Freer ISD",
  [SchoolDistrict.Frenship]: "Frenship ISD",
  [SchoolDistrict.Friendswood]: "Friendswood ISD",
  [SchoolDistrict.Friona]: "Friona ISD",
  [SchoolDistrict.Frisco]: "Frisco ISD",
  [SchoolDistrict.Frost]: "Frost ISD",
  [SchoolDistrict.FruitofExcellence]: "Fruit of Excellence",
  [SchoolDistrict.Fruitvale]: "Fruitvale ISD",
  [SchoolDistrict.FtDavis]: "Ft Davis ISD",
  [SchoolDistrict.FtHancock]: "Ft Hancock ISD",
  [SchoolDistrict.FtSamHouston]: "Ft Sam Houston ISD",
  [SchoolDistrict.FtStockton]: "Ft Stockton ISD",
  [SchoolDistrict.GabrielTafollaCharterSchool]:
    "Gabriel Tafolla Charter School",
  [SchoolDistrict.Gainesville]: "Gainesville ISD",
  [SchoolDistrict.GalenaPark]: "Galena Park ISD",
  [SchoolDistrict.Galveston]: "Galveston ISD",
  [SchoolDistrict.Ganado]: "Ganado ISD",
  [SchoolDistrict.Garland]: "Garland ISD",
  [SchoolDistrict.Garner]: "Garner ISD",
  [SchoolDistrict.Garrison]: "Garrison ISD",
  [SchoolDistrict.Gary]: "Gary ISD",
  [SchoolDistrict.Gatesville]: "Gatesville ISD",
  [SchoolDistrict.GatewayAcademy]: "Gateway  Academy",
  [SchoolDistrict.GatewayCharterAcademy]: "Gateway Charter Academy",
  [SchoolDistrict.Gause]: "Gause ISD",
  [SchoolDistrict.GeorgeGervinAcademy]: "George Gervin Academy",
  [SchoolDistrict.GeorgeISanchez]: "George I Sanchez",
  [SchoolDistrict.GeorgeISanchezCharterHSSA]: "George I Sanchez Charter HS SA",
  [SchoolDistrict.GeorgeWest]: "George West ISD",
  [SchoolDistrict.Georgetown]: "Georgetown ISD",
  [SchoolDistrict.Georgia]: "Georgia",
  [SchoolDistrict.Germany]: "Germany",
  [SchoolDistrict.Gholson]: "Gholson ISD",
  [SchoolDistrict.Giddings]: "Giddings ISD",
  [SchoolDistrict.GiddingsStateSchool]: "Giddings State School",
  [SchoolDistrict.Gilmer]: "Gilmer ISD",
  [SchoolDistrict.GirlsBoysPrepAcademy]: "Girls &amp; Boys Prep Academy",
  [SchoolDistrict.Gladewater]: "Gladewater ISD",
  [SchoolDistrict.GlasscockCounty]: "Glasscock County ISD",
  [SchoolDistrict.GlenRose]: "Glen Rose ISD",
  [SchoolDistrict.Godley]: "Godley ISD",
  [SchoolDistrict.GoldBurg]: "Gold Burg ISD",
  [SchoolDistrict.GoldenRuleCharterSchool]: "Golden Rule Charter School",
  [SchoolDistrict.Goldthwaite]: "Goldthwaite ISD",
  [SchoolDistrict.Goliad]: "Goliad ISD",
  [SchoolDistrict.Gonzales]: "Gonzales ISD",
  [SchoolDistrict.Goodrich]: "Goodrich ISD",
  [SchoolDistrict.GooseCreekC]: "Goose Creek CISD",
  [SchoolDistrict.Gordon]: "Gordon ISD",
  [SchoolDistrict.Gorman]: "Gorman ISD",
  [SchoolDistrict.Grady]: "Grady ISD",
  [SchoolDistrict.Graford]: "Graford ISD",
  [SchoolDistrict.Graham]: "Graham ISD",
  [SchoolDistrict.Granbury]: "Granbury ISD",
  [SchoolDistrict.GrandPrairie]: "Grand Prairie ISD",
  [SchoolDistrict.GrandSaline]: "Grand Saline ISD",
  [SchoolDistrict.GrandfallsRoyalty]: "Grandfalls-Royalty ISD",
  [SchoolDistrict.Grandview]: "Grandview ISD",
  [SchoolDistrict.GrandviewHopkins]: "Grandview-Hopkins ISD",
  [SchoolDistrict.Granger]: "Granger ISD",
  [SchoolDistrict.GrapeCreek]: "Grape Creek ISD",
  [SchoolDistrict.Grapeland]: "Grapeland ISD",
  [SchoolDistrict.GrapevineColleyville]: "Grapevine-Colleyville ISD",
  [SchoolDistrict.Greenville]: "Greenville ISD",
  [SchoolDistrict.Greenwood]: "Greenwood ISD",
  [SchoolDistrict.GregoryPortland]: "Gregory-Portland ISD",
  [SchoolDistrict.Groesbeck]: "Groesbeck ISD",
  [SchoolDistrict.Groom]: "Groom ISD",
  [SchoolDistrict.Groveton]: "Groveton ISD",
  [SchoolDistrict.Gruver]: "Gruver ISD",
  [SchoolDistrict.GuardianAngelPerformanceAca]:
    "Guardian Angel Performance Aca",
  [SchoolDistrict.Guatemala]: "Guatemala",
  [SchoolDistrict.GulfShoresAcademy]: "Gulf Shores Academy",
  [SchoolDistrict.Gunter]: "Gunter ISD",
  [SchoolDistrict.Gustine]: "Gustine ISD",
  [SchoolDistrict.GuthrieCSD]: "Guthrie CSD",
  [SchoolDistrict.HaleCenter]: "Hale Center ISD",
  [SchoolDistrict.Hallettsville]: "Hallettsville ISD",
  [SchoolDistrict.Hallsburg]: "Hallsburg ISD",
  [SchoolDistrict.Hallsville]: "Hallsville ISD",
  [SchoolDistrict.Hamilton]: "Hamilton ISD",
  [SchoolDistrict.Hamlin]: "Hamlin ISD",
  [SchoolDistrict.HamshireFannett]: "Hamshire-Fannett ISD",
  [SchoolDistrict.Happy]: "Happy ISD",
  [SchoolDistrict.Hardin]: "Hardin ISD",
  [SchoolDistrict.HardinJefferson]: "Hardin-Jefferson ISD",
  [SchoolDistrict.Harlandale]: "Harlandale ISD",
  [SchoolDistrict.Harleton]: "Harleton ISD",
  [SchoolDistrict.HarlingenCons]: "Harlingen Cons ISD",
  [SchoolDistrict.Harmony]: "Harmony ISD",
  [SchoolDistrict.HarmonyScienceAcademy]: "Harmony Science Academy",
  [SchoolDistrict.HarmonyScienceAcademyAust]: "Harmony Science Academy - Aust",
  [SchoolDistrict.Harper]: "Harper ISD",
  [SchoolDistrict.HarrisCountyJuvenileJustice]:
    "Harris County Juvenile Justice",
  [SchoolDistrict.Harrold]: "Harrold ISD",
  [SchoolDistrict.Hart]: "Hart ISD",
  [SchoolDistrict.Hartley]: "Hartley ISD",
  [SchoolDistrict.HartsBluff]: "Harts Bluff ISD",
  [SchoolDistrict.HaskellC]: "Haskell CISD",
  [SchoolDistrict.Hawkins]: "Hawkins ISD",
  [SchoolDistrict.Hawley]: "Hawley ISD",
  [SchoolDistrict.HaysCons]: "Hays Cons ISD",
  [SchoolDistrict.Hearne]: "Hearne ISD",
  [SchoolDistrict.Hedley]: "Hedley ISD",
  [SchoolDistrict.HeightsCharterSchool]: "Heights Charter School",
  [SchoolDistrict.Hemphill]: "Hemphill ISD",
  [SchoolDistrict.Hempstead]: "Hempstead ISD",
  [SchoolDistrict.Henderson]: "Henderson ISD",
  [SchoolDistrict.Henrietta]: "Henrietta ISD",
  [SchoolDistrict.Hereford]: "Hereford ISD",
  [SchoolDistrict.Hermleigh]: "Hermleigh ISD",
  [SchoolDistrict.Hico]: "Hico ISD",
  [SchoolDistrict.Hidalgo]: "Hidalgo ISD",
  [SchoolDistrict.Higgins]: "Higgins ISD",
  [SchoolDistrict.HiggsCarterKingGiftedTal]:
    "Higgs/Carter/King Gifted &amp; Tal",
  [SchoolDistrict.HighIsland]: "High Island ISD",
  [SchoolDistrict.Highland]: "Highland ISD",
  [SchoolDistrict.HighlandPark]: "Highland Park ISD",
  [SchoolDistrict.Hillsboro]: "Hillsboro ISD",
  [SchoolDistrict.Hitchcock]: "Hitchcock ISD",
  [SchoolDistrict.Holland]: "Holland ISD",
  [SchoolDistrict.Holliday]: "Holliday ISD",
  [SchoolDistrict.Hondo]: "Hondo ISD",
  [SchoolDistrict.Honduras]: "Honduras",
  [SchoolDistrict.HoneyGrove]: "Honey Grove ISD",
  [SchoolDistrict.HonorsAcademy]: "Honors Academy",
  [SchoolDistrict.Hooks]: "Hooks ISD",
  [SchoolDistrict.HoustonAlternativePreparator]:
    "Houston Alternative Preparator",
  [SchoolDistrict.HoustonCanAcademyCharterSc]: "Houston Can Academy Charter Sc",
  [SchoolDistrict.HoustonGatewayAcademy]: "Houston Gateway Academy",
  [SchoolDistrict.HoustonHeightsLearningAcade]:
    "Houston Heights Learning Acade",
  [SchoolDistrict.Houston]: "Houston ISD",
  [SchoolDistrict.Howe]: "Howe ISD",
  [SchoolDistrict.Hubbard]: "Hubbard ISD",
  [SchoolDistrict.Huckabay]: "Huckabay ISD",
  [SchoolDistrict.Hudson]: "Hudson ISD",
  [SchoolDistrict.Huffman]: "Huffman ISD",
  [SchoolDistrict.HughesSprings]: "Hughes Springs ISD",
  [SchoolDistrict.HullDaisetta]: "Hull-Daisetta ISD",
  [SchoolDistrict.Humble]: "Humble ISD",
  [SchoolDistrict.Hunt]: "Hunt ISD",
  [SchoolDistrict.Huntington]: "Huntington ISD",
  [SchoolDistrict.Huntsville]: "Huntsville ISD",
  [SchoolDistrict.HurstEulessBedford]: "Hurst-Euless-Bedford ISD",
  [SchoolDistrict.Hutto]: "Hutto ISD",
  [SchoolDistrict.IAmThatIAmAcademy]: "I Am That I Am Academy",
  [SchoolDistrict.IDEAAcademy]: "I D E A  Academy",
  [SchoolDistrict.Idalou]: "Idalou ISD",
  [SchoolDistrict.ImpactCharter]: "Impact Charter",
  [SchoolDistrict.India]: "India",
  [SchoolDistrict.Indiana_DoNotUse]: "Indiana_Do Not Use",
  [SchoolDistrict.Indonesia]: "Indonesia",
  [SchoolDistrict.Industrial]: "Industrial ISD",
  [SchoolDistrict.Ingleside]: "Ingleside ISD",
  [SchoolDistrict.Ingram]: "Ingram ISD",
  [SchoolDistrict.InspiredVisionAcademy]: "Inspired Vision Academy",
  [SchoolDistrict.International]: "International",
  [SchoolDistrict.Iola]: "Iola ISD",
  [SchoolDistrict.IowaParkCons]: "Iowa Park Cons ISD",
  [SchoolDistrict.Ira]: "Ira ISD",
  [SchoolDistrict.IraanSheffield]: "Iraan-Sheffield ISD",
  [SchoolDistrict.Iredell]: "Iredell ISD",
  [SchoolDistrict.IrionCo]: "Irion Co ISD",
  [SchoolDistrict.Irving]: "Irving ISD",
  [SchoolDistrict.Italy]: "Italy",
  [SchoolDistrict.ItalyIsd]: "Italy ISD",
  [SchoolDistrict.Itasca]: "Itasca ISD",
  [SchoolDistrict.Jacksboro]: "Jacksboro ISD",
  [SchoolDistrict.Jacksonville]: "Jacksonville ISD",
  [SchoolDistrict.Jamaica]: "Jamaica",
  [SchoolDistrict.JamiesHouseCharterSchool]: "Jamie's House Charter School",
  [SchoolDistrict.Jarrell]: "Jarrell ISD",
  [SchoolDistrict.Jasper]: "Jasper ISD",
  [SchoolDistrict.JaytonGirard]: "Jayton-Girard ISD",
  [SchoolDistrict.JeanMassieuAcademy]: "Jean Massieu Academy",
  [SchoolDistrict.Jefferson]: "Jefferson ISD",
  [SchoolDistrict.JesseJacksonAcademy]: "Jesse Jackson Academy",
  [SchoolDistrict.JimHoggCounty]: "Jim Hogg County ISD",
  [SchoolDistrict.JimNedCons]: "Jim Ned Cons ISD",
  [SchoolDistrict.Joaquin]: "Joaquin ISD",
  [SchoolDistrict.JohnHWoodCharterSchool]: "John H Wood Charter School",
  [SchoolDistrict.JohnsonCity]: "Johnson City ISD",
  [SchoolDistrict.Jonesboro]: "Jonesboro ISD",
  [SchoolDistrict.Joshua]: "Joshua ISD",
  [SchoolDistrict.Jourdanton]: "Jourdanton ISD",
  [SchoolDistrict.JuanBGalavizCharterSchool]: "Juan B Galaviz Charter School",
  [SchoolDistrict.JubileeAcademicCenter]: "Jubilee Academic Center",
  [SchoolDistrict.Judson]: "Judson ISD",
  [SchoolDistrict.Junction]: "Junction ISD",
  [SchoolDistrict.Karnack]: "Karnack ISD",
  [SchoolDistrict.KarnesCity]: "Karnes City ISD",
  [SchoolDistrict.KatherineAnnePorterSchool]: "Katherine Anne Porter School",
  [SchoolDistrict.Katy]: "Katy ISD",
  [SchoolDistrict.Kaufman]: "Kaufman ISD",
  [SchoolDistrict.Keene]: "Keene ISD",
  [SchoolDistrict.Keller]: "Keller ISD",
  [SchoolDistrict.Kelton]: "Kelton ISD",
  [SchoolDistrict.Kemp]: "Kemp ISD",
  [SchoolDistrict.Kendleton]: "Kendleton ISD",
  [SchoolDistrict.KenedyCountyWideCSD]: "Kenedy County Wide CSD",
  [SchoolDistrict.Kenedy]: "Kenedy ISD",
  [SchoolDistrict.Kennard]: "Kennard ISD",
  [SchoolDistrict.Kennedale]: "Kennedale ISD",
  [SchoolDistrict.Kentucky_DoNotUse]: "Kentucky_Do Not Use",
  [SchoolDistrict.Kerens]: "Kerens ISD",
  [SchoolDistrict.Kermit]: "Kermit ISD",
  [SchoolDistrict.Kerrville]: "Kerrville ISD",
  [SchoolDistrict.Kilgore]: "Kilgore ISD",
  [SchoolDistrict.Killeen]: "Killeen ISD",
  [SchoolDistrict.Kingston]: "Kingston",
  [SchoolDistrict.Kingsville]: "Kingsville ISD",
  [SchoolDistrict.KippIncCharter]: "Kipp Inc Charter",
  [SchoolDistrict.KirbyvilleC]: "Kirbyville CISD",
  [SchoolDistrict.Klein]: "Klein ISD",
  [SchoolDistrict.Klondike]: "Klondike ISD",
  [SchoolDistrict.Knippa]: "Knippa ISD",
  [SchoolDistrict.KnoxCityOBrien]: "Knox City OBrien",
  [SchoolDistrict.Kopperl]: "Kopperl ISD",
  [SchoolDistrict.Kountze]: "Kountze ISD",
  [SchoolDistrict.Kress]: "Kress ISD",
  [SchoolDistrict.Krum]: "Krum ISD",
  [SchoolDistrict.LaAmistadLoveLearningAca]: "La Amistad Love & Learning Aca",
  [SchoolDistrict.LaEscueladelasAmericas]: "La Escuela de las Americas",
  [SchoolDistrict.LaFeria]: "La Feria ISD",
  [SchoolDistrict.LaGloria]: "La Gloria ISD",
  [SchoolDistrict.LaGrange]: "La Grange ISD",
  [SchoolDistrict.LaJoya]: "La Joya ISD",
  [SchoolDistrict.LaMarque]: "La Marque ISD",
  [SchoolDistrict.LaPorte]: "La Porte ISD",
  [SchoolDistrict.LaPryor]: "La Pryor ISD",
  [SchoolDistrict.LaVega]: "La Vega ISD",
  [SchoolDistrict.LaVernia]: "La Vernia ISD",
  [SchoolDistrict.LaVilla]: "La Villa ISD",
  [SchoolDistrict.Lackland]: "Lackland ISD",
  [SchoolDistrict.LagoVista]: "Lago Vista ISD",
  [SchoolDistrict.LakeDallas]: "Lake Dallas ISD",
  [SchoolDistrict.LakeTravis]: "Lake Travis ISD",
  [SchoolDistrict.LakeWorth]: "Lake Worth ISD",
  [SchoolDistrict.LamarConsolidated]: "Lamar Consolidated ISD",
  [SchoolDistrict.Lamesa]: "Lamesa ISD",
  [SchoolDistrict.Lampasas]: "Lampasas ISD",
  [SchoolDistrict.Lancaster]: "Lancaster ISD",
  [SchoolDistrict.Laneville]: "Laneville ISD",
  [SchoolDistrict.Lapoynor]: "Lapoynor ISD",
  [SchoolDistrict.Laredo]: "Laredo ISD",
  [SchoolDistrict.Lasara]: "Lasara ISD",
  [SchoolDistrict.Latexo]: "Latexo ISD",
  [SchoolDistrict.Lazbuddie]: "Lazbuddie ISD",
  [SchoolDistrict.Leakey]: "Leakey ISD",
  [SchoolDistrict.Leander]: "Leander ISD",
  [SchoolDistrict.Leary]: "Leary ISD",
  [SchoolDistrict.Lefors]: "Lefors ISD",
  [SchoolDistrict.Leggett]: "Leggett ISD",
  [SchoolDistrict.Leon]: "Leon ISD",
  [SchoolDistrict.Leonard]: "Leonard ISD",
  [SchoolDistrict.Levelland]: "Levelland ISD",
  [SchoolDistrict.LeverettsChapel]: "Leveretts Chapel ISD",
  [SchoolDistrict.Lewisville]: "Lewisville ISD",
  [SchoolDistrict.Lexington]: "Lexington ISD",
  [SchoolDistrict.LibertyHill]: "Liberty Hill ISD",
  [SchoolDistrict.Liberty]: "Liberty ISD",
  [SchoolDistrict.LibertyEylau]: "Liberty-Eylau ISD",
  [SchoolDistrict.LifeSchool]: "Life School",
  [SchoolDistrict.LighthouseCharterSchool]: "Lighthouse Charter School",
  [SchoolDistrict.Lindale]: "Lindale ISD",
  [SchoolDistrict.LindenKildareCons]: "Linden-Kildare Cons ISD",
  [SchoolDistrict.Lindsay]: "Lindsay ISD",
  [SchoolDistrict.Lingleville]: "Lingleville ISD",
  [SchoolDistrict.Lipan]: "Lipan ISD",
  [SchoolDistrict.LittleCypressMauricevilleCi]:
    "Little Cypress-Mauriceville Ci",
  [SchoolDistrict.LittleElm]: "Little Elm ISD",
  [SchoolDistrict.Littlefield]: "Littlefield ISD",
  [SchoolDistrict.Livingston]: "Livingston ISD",
  [SchoolDistrict.Llano]: "Llano ISD",
  [SchoolDistrict.Lockhart]: "Lockhart ISD",
  [SchoolDistrict.Lockney]: "Lockney ISD",
  [SchoolDistrict.Lohn]: "Lohn ISD",
  [SchoolDistrict.Lometa]: "Lometa ISD",
  [SchoolDistrict.London]: "London ISD",
  [SchoolDistrict.LoneOak]: "Lone Oak ISD",
  [SchoolDistrict.Longview]: "Longview ISD",
  [SchoolDistrict.Loop]: "Loop ISD",
  [SchoolDistrict.Loraine]: "Loraine ISD",
  [SchoolDistrict.Lorena]: "Lorena ISD",
  [SchoolDistrict.Lorenzo]: "Lorenzo ISD",
  [SchoolDistrict.LosFresnosCons]: "Los Fresnos Cons ISD",
  [SchoolDistrict.Louise]: "Louise ISD",
  [SchoolDistrict.Louisiana]: "Louisiana",
  [SchoolDistrict.Lovejoy]: "Lovejoy ISD",
  [SchoolDistrict.Lovelady]: "Lovelady ISD",
  [SchoolDistrict.Lubbock]: "Lubbock ISD",
  [SchoolDistrict.LubbockCooper]: "Lubbock-Cooper ISD",
  [SchoolDistrict.LuedersAvoca]: "Lueders-Avoca ISD",
  [SchoolDistrict.Lufkin]: "Lufkin ISD",
  [SchoolDistrict.Luling]: "Luling ISD",
  [SchoolDistrict.Lumberton]: "Lumberton ISD",
  [SchoolDistrict.LyfordC]: "Lyford CISD",
  [SchoolDistrict.Lytle]: "Lytle ISD",
  [SchoolDistrict.Mabank]: "Mabank ISD",
  [SchoolDistrict.MadisonvilleCons]: "Madisonville Cons ISD",
  [SchoolDistrict.Magnolia]: "Magnolia ISD",
  [SchoolDistrict.Maine]: "Maine",
  [SchoolDistrict.MainlandPreparatoryAcademy]: "Mainland Preparatory Academy",
  [SchoolDistrict.Malakoff]: "Malakoff ISD",
  [SchoolDistrict.Malone]: "Malone ISD",
  [SchoolDistrict.Malta]: "Malta ISD",
  [SchoolDistrict.Manor]: "Manor ISD",
  [SchoolDistrict.Mansfield]: "Mansfield ISD",
  [SchoolDistrict.Marathon]: "Marathon ISD",
  [SchoolDistrict.MarbleFalls]: "Marble Falls ISD",
  [SchoolDistrict.Marfa]: "Marfa ISD",
  [SchoolDistrict.Marietta]: "Marietta ISD",
  [SchoolDistrict.Marion]: "Marion ISD",
  [SchoolDistrict.Marlin]: "Marlin ISD",
  [SchoolDistrict.MarlinOrientationandAssessm]:
    "Marlin Orientation and Assessm",
  [SchoolDistrict.Marshall]: "Marshall ISD",
  [SchoolDistrict.Mart]: "Mart ISD",
  [SchoolDistrict.MartinsMill]: "Martins Mill ISD",
  [SchoolDistrict.Martinsville]: "Martinsville ISD",
  [SchoolDistrict.Maryland]: "Maryland",
  [SchoolDistrict.Mason]: "Mason ISD",
  [SchoolDistrict.MasonicHome]: "Masonic Home ISD",
  [SchoolDistrict.Matagorda]: "Matagorda ISD",
  [SchoolDistrict.Mathis]: "Mathis ISD",
  [SchoolDistrict.Maud]: "Maud ISD",
  [SchoolDistrict.May]: "May ISD",
  [SchoolDistrict.Maypearl]: "Maypearl ISD",
  [SchoolDistrict.McAllen]: "McAllen ISD",
  [SchoolDistrict.McCamey]: "McCamey ISD",
  [SchoolDistrict.McCulloughAcademyofExcellen]:
    "McCullough Academy of Excellen",
  [SchoolDistrict.McDade]: "McDade ISD",
  [SchoolDistrict.McGregor]: "McGregor ISD",
  [SchoolDistrict.McKinney]: "McKinney ISD",
  [SchoolDistrict.McLean]: "McLean ISD",
  [SchoolDistrict.McLennanCoStJuvenileCorrec]: "McLennan Co St Juvenile Correc",
  [SchoolDistrict.McLeod]: "McLeod ISD",
  [SchoolDistrict.McMullenCounty]: "McMullen County ISD",
  [SchoolDistrict.Meadow]: "Meadow ISD",
  [SchoolDistrict.MedicalCenterCharterSchool]: "Medical Center Charter School",
  [SchoolDistrict.Medina]: "Medina ISD",
  [SchoolDistrict.MedinaValley]: "Medina Valley ISD",
  [SchoolDistrict.Megargel]: "Megargel ISD",
  [SchoolDistrict.Melissa]: "Melissa ISD",
  [SchoolDistrict.Memphis]: "Memphis ISD",
  [SchoolDistrict.Menard]: "Menard ISD",
  [SchoolDistrict.Mercedes]: "Mercedes ISD",
  [SchoolDistrict.Meridian]: "Meridian ISD",
  [SchoolDistrict.Merkel]: "Merkel ISD",
  [SchoolDistrict.Mesquite]: "Mesquite ISD",
  [SchoolDistrict.MetroCharterAcademy]: "Metro Charter Academy",
  [SchoolDistrict.Mexia]: "Mexia ISD",
  [SchoolDistrict.Mexico]: "Mexico",
  [SchoolDistrict.Meyersville]: "Meyersville ISD",
  [SchoolDistrict.Miami]: "Miami ISD",
  [SchoolDistrict.MidlandAcademyCharterSchool]:
    "Midland Academy Charter School",
  [SchoolDistrict.Midland]: "Midland ISD",
  [SchoolDistrict.Midlothian]: "Midlothian ISD",
  [SchoolDistrict.MidValleyAcademy]: "Mid-Valley Academy",
  [SchoolDistrict.Midway]: "Midway ISD",
  [SchoolDistrict.MidwayClayCounty]: "Midway ISD, Clay County",
  [SchoolDistrict.Milano]: "Milano ISD",
  [SchoolDistrict.Mildred]: "Mildred ISD",
  [SchoolDistrict.Miles]: "Miles ISD",
  [SchoolDistrict.Milford]: "Milford ISD",
  [SchoolDistrict.MillerGrove]: "Miller Grove ISD",
  [SchoolDistrict.Millsap]: "Millsap ISD",
  [SchoolDistrict.Mineola]: "Mineola ISD",
  [SchoolDistrict.MineralWells]: "Mineral Wells ISD",
  [SchoolDistrict.MirandoCity]: "Mirando City ISD",
  [SchoolDistrict.MissionCons]: "Mission Cons ISD",
  [SchoolDistrict.MonahansWickettPyote]: "Monahans-Wickett-Pyote ISD",
  [SchoolDistrict.Montague]: "Montague ISD",
  [SchoolDistrict.Montana]: "Montana",
  [SchoolDistrict.MonteAlto]: "Monte Alto ISD",
  [SchoolDistrict.Montgomery]: "Montgomery ISD",
  [SchoolDistrict.Moody]: "Moody ISD",
  [SchoolDistrict.Moran]: "Moran ISD",
  [SchoolDistrict.Morgan]: "Morgan ISD",
  [SchoolDistrict.MorganMill]: "Morgan Mill ISD",
  [SchoolDistrict.Morton]: "Morton ISD",
  [SchoolDistrict.MotleyCounty]: "Motley County ISD",
  [SchoolDistrict.Moulton]: "Moulton ISD",
  [SchoolDistrict.MountCalm]: "Mount Calm ISD",
  [SchoolDistrict.MountEnterprise]: "Mount Enterprise ISD",
  [SchoolDistrict.MountPleasant]: "Mount Pleasant ISD",
  [SchoolDistrict.MountVernon]: "Mount Vernon ISD",
  [SchoolDistrict.Muenster]: "Muenster ISD",
  [SchoolDistrict.Muleshoe]: "Muleshoe ISD",
  [SchoolDistrict.Mullin]: "Mullin ISD",
  [SchoolDistrict.Mumford]: "Mumford ISD",
  [SchoolDistrict.MundayC]: "Munday CISD",
  [SchoolDistrict.Murchison]: "Murchison ISD",
  [SchoolDistrict.Nacogdoches]: "Nacogdoches ISD",
  [SchoolDistrict.NancyNeyCharterSchool]: "Nancy Ney Charter School",
  [SchoolDistrict.Natalia]: "Natalia ISD",
  [SchoolDistrict.Navarro]: "Navarro ISD",
  [SchoolDistrict.Navasota]: "Navasota ISD",
  [SchoolDistrict.Nazareth]: "Nazareth ISD",
  [SchoolDistrict.Neches]: "Neches ISD",
  [SchoolDistrict.Nederland]: "Nederland ISD",
  [SchoolDistrict.Needville]: "Needville ISD",
  [SchoolDistrict.Nevada]: "Nevada",
  [SchoolDistrict.NewBoston]: "New Boston ISD",
  [SchoolDistrict.NewBraunfels]: "New Braunfels ISD",
  [SchoolDistrict.NewCaney]: "New Caney ISD",
  [SchoolDistrict.NewDeal]: "New Deal ISD",
  [SchoolDistrict.NewDiana]: "New Diana ISD",
  [SchoolDistrict.NewFrontiersCharterSchool]: "New Frontiers Charter School",
  [SchoolDistrict.NewHome]: "New Home ISD",
  [SchoolDistrict.NewMexico]: "New Mexico",
  [SchoolDistrict.NewSummerfield]: "New Summerfield ISD",
  [SchoolDistrict.NewWaverly]: "New Waverly ISD",
  [SchoolDistrict.NewYork]: "New York",
  [SchoolDistrict.Newcastle]: "Newcastle ISD",
  [SchoolDistrict.Newton]: "Newton ISD",
  [SchoolDistrict.Nicaragua]: "Nicaragua",
  [SchoolDistrict.NixonSmileyCons]: "Nixon-Smiley Cons ISD",
  [SchoolDistrict.Nocona]: "Nocona ISD",
  [SchoolDistrict.Nordheim]: "Nordheim ISD",
  [SchoolDistrict.Normangee]: "Normangee ISD",
  [SchoolDistrict.NorthCarolina]: "North Carolina",
  [SchoolDistrict.NorthEast]: "North East ISD",
  [SchoolDistrict.NorthForest]: "North Forest ISD",
  [SchoolDistrict.NorthHillsSchool]: "North Hills School",
  [SchoolDistrict.NorthHopkins]: "North Hopkins ISD",
  [SchoolDistrict.NorthHoustonHSForBusiness]: "North Houston H S For Business",
  [SchoolDistrict.NorthLamar]: "North Lamar ISD",
  [SchoolDistrict.NorthZulch]: "North Zulch ISD",
  [SchoolDistrict.Northside]: "Northside ISD",
  [SchoolDistrict.Northwest]: "Northwest ISD",
  [SchoolDistrict.NorthwestMathematicsScience]: "Northwest Mathematics Science",
  [SchoolDistrict.NorthwestPreparatory]: "Northwest Preparatory",
  [SchoolDistrict.NovaCharterSchool]: "Nova Charter School",
  [SchoolDistrict.NovaCharterSchoolSoutheast]: "Nova Charter School (Southeast",
  [SchoolDistrict.Novice]: "Novice ISD",
  [SchoolDistrict.NTREISTESTONLY]: "NTREIS TEST ONLY",
  [SchoolDistrict.NuecesCanyonC]: "Nueces Canyon CISD",
  [SchoolDistrict.Nursery]: "Nursery ISD",
  [SchoolDistrict.NyosCharterSchool]: "Nyos Charter School",
  [SchoolDistrict.Oakwood]: "Oakwood ISD",
  [SchoolDistrict.OdemEdroy]: "Odem-Edroy ISD",
  [SchoolDistrict.ODonnell]: "O'Donnell ISD",
  [SchoolDistrict.OdysseyAcademyInc]: "Odyssey Academy Inc",
  [SchoolDistrict.Oglesby]: "Oglesby ISD",
  [SchoolDistrict.Oklahoma]: "Oklahoma",
  [SchoolDistrict.Olfen]: "Olfen ISD",
  [SchoolDistrict.Olney]: "Olney ISD",
  [SchoolDistrict.Olton]: "Olton ISD",
  [SchoolDistrict.Onalaska]: "Onalaska ISD",
  [SchoolDistrict.OneStopMultiserviceCharter]: "One Stop Multiservice Charter",
  [SchoolDistrict.OrangeGrove]: "Orange Grove ISD",
  [SchoolDistrict.Orangefield]: "Orangefield ISD",
  [SchoolDistrict.OreCity]: "Ore City ISD",
  [SchoolDistrict.Oregon]: "Oregon",
  [SchoolDistrict.OutreachWordAcademy]: "Outreach Word Academy",
  [SchoolDistrict.Overton]: "Overton ISD",
  [SchoolDistrict.Paducah]: "Paducah ISD",
  [SchoolDistrict.PaintCreek]: "Paint Creek ISD",
  [SchoolDistrict.PaintRock]: "Paint Rock ISD",
  [SchoolDistrict.Palacios]: "Palacios ISD",
  [SchoolDistrict.Palestine]: "Palestine ISD",
  [SchoolDistrict.Palmer]: "Palmer ISD",
  [SchoolDistrict.PaloPinto]: "Palo Pinto ISD",
  [SchoolDistrict.Pampa]: "Pampa ISD",
  [SchoolDistrict.Panama]: "Panama",
  [SchoolDistrict.Panhandle]: "Panhandle ISD",
  [SchoolDistrict.PanolaCharterSchool]: "Panola Charter School",
  [SchoolDistrict.PanolaPublicSD]: "Panola Public SD",
  [SchoolDistrict.PantherCreekCons]: "Panther Creek Cons ISD",
  [SchoolDistrict.ParadigmAcceleratedSchool]: "Paradigm Accelerated School",
  [SchoolDistrict.Paradise]: "Paradise ISD",
  [SchoolDistrict.Paris]: "Paris ISD",
  [SchoolDistrict.Pasadena]: "Pasadena ISD",
  [SchoolDistrict.PasoDelNorteAcademy]: "Paso Del Norte Academy",
  [SchoolDistrict.PattonSprings]: "Patton Springs ISD",
  [SchoolDistrict.Pawnee]: "Pawnee ISD",
  [SchoolDistrict.Pearland]: "Pearland ISD",
  [SchoolDistrict.Pearsall]: "Pearsall ISD",
  [SchoolDistrict.Peaster]: "Peaster ISD",
  [SchoolDistrict.PecosBarstowToyah]: "Pecos-Barstow-Toyah ISD",
  [SchoolDistrict.PegasusCharterSchool]: "Pegasus Charter School",
  [SchoolDistrict.Penelope]: "Penelope ISD",
  [SchoolDistrict.PerrinWhittCons]: "Perrin-Whitt Cons ISD",
  [SchoolDistrict.Perryton]: "Perryton ISD",
  [SchoolDistrict.Peru]: "Peru ISD",
  [SchoolDistrict.Petersburg]: "Petersburg ISD",
  [SchoolDistrict.Petrolia]: "Petrolia ISD",
  [SchoolDistrict.Pettus]: "Pettus ISD",
  [SchoolDistrict.Pewitt]: "Pewitt ISD",
  [SchoolDistrict.Pflugerville]: "Pflugerville ISD",
  [SchoolDistrict.PharrSanJuanAlamo]: "Pharr-San Juan-Alamo ISD",
  [SchoolDistrict.PhoenixCharterSchool]: "Phoenix Charter School",
  [SchoolDistrict.PilotPoint]: "Pilot Point ISD",
  [SchoolDistrict.PineTree]: "Pine Tree ISD",
  [SchoolDistrict.PineywoodsCommunityAcademy]: "Pineywoods Community Academy",
  [SchoolDistrict.Pittsburg]: "Pittsburg ISD",
  [SchoolDistrict.Plains]: "Plains ISD",
  [SchoolDistrict.Plainview]: "Plainview ISD",
  [SchoolDistrict.Plano]: "Plano ISD",
  [SchoolDistrict.PleasantGrove]: "Pleasant Grove ISD",
  [SchoolDistrict.Pleasanton]: "Pleasanton ISD",
  [SchoolDistrict.PlemonsStinnettPhillipsCons]:
    "Plemons-Stinnett-Phillips Cons",
  [SchoolDistrict.PointIsabel]: "Point Isabel ISD",
  [SchoolDistrict.Ponder]: "Ponder ISD",
  [SchoolDistrict.Poolville]: "Poolville ISD",
  [SchoolDistrict.PorVidaAcadCharterHS]: "Por Vida Acad Charter H S",
  [SchoolDistrict.PortAransas]: "Port Aransas ISD",
  [SchoolDistrict.PortArthur]: "Port Arthur ISD",
  [SchoolDistrict.PortNechesGroves]: "Port Neches-Groves ISD",
  [SchoolDistrict.PositiveSolutionsCharterSch]:
    "Positive Solutions Charter Sch",
  [SchoolDistrict.Post]: "Post ISD",
  [SchoolDistrict.Poteet]: "Poteet ISD",
  [SchoolDistrict.Poth]: "Poth ISD",
  [SchoolDistrict.Pottsboro]: "Pottsboro ISD",
  [SchoolDistrict.PrairieLea]: "Prairie Lea ISD",
  [SchoolDistrict.PrairieValley]: "Prairie Valley ISD",
  [SchoolDistrict.Prairiland]: "Prairiland ISD",
  [SchoolDistrict.Premont]: "Premont ISD",
  [SchoolDistrict.Presidio]: "Presidio ISD",
  [SchoolDistrict.Priddy]: "Priddy ISD",
  [SchoolDistrict.Princeton]: "Princeton ISD",
  [SchoolDistrict.PringleMorseCons]: "Pringle-Morse Cons ISD",
  [SchoolDistrict.Progreso]: "Progreso ISD",
  [SchoolDistrict.Prosper]: "Prosper ISD",
  [SchoolDistrict.PuertoRico]: "Puerto Rico",
  [SchoolDistrict.Quanah]: "Quanah ISD",
  [SchoolDistrict.QueenCity]: "Queen City ISD",
  [SchoolDistrict.Quinlan]: "Quinlan ISD",
  [SchoolDistrict.Quitman]: "Quitman ISD",
  [SchoolDistrict.RadianceAcademyofLearning]: "Radiance Academy of Learning",
  [SchoolDistrict.Rains]: "Rains ISD",
  [SchoolDistrict.Ralls]: "Ralls ISD",
  [SchoolDistrict.RamirezCSD]: "Ramirez CSD",
  [SchoolDistrict.RanchAcademy]: "Ranch Academy",
  [SchoolDistrict.RandolphField]: "Randolph Field ISD",
  [SchoolDistrict.Ranger]: "Ranger ISD",
  [SchoolDistrict.Rankin]: "Rankin ISD",
  [SchoolDistrict.RapoportCharterSchool]: "Rapoport Charter School",
  [SchoolDistrict.RaulYzaguirreSchoolforSucc]: "Raul Yzaguirre School for Succ",
  [SchoolDistrict.RavenSchool]: "Raven School",
  [SchoolDistrict.Raymondville]: "Raymondville ISD",
  [SchoolDistrict.ReaganCounty]: "Reagan County ISD",
  [SchoolDistrict.RedLick]: "Red Lick ISD",
  [SchoolDistrict.RedOak]: "Red Oak ISD",
  [SchoolDistrict.Redwater]: "Redwater ISD",
  [SchoolDistrict.Refugio]: "Refugio ISD",
  [SchoolDistrict.Ricardo]: "Ricardo ISD",
  [SchoolDistrict.RiceCons]: "Rice Cons ISD",
  [SchoolDistrict.Rice]: "Rice ISD",
  [SchoolDistrict.RichardMilburnAcademyAmar]: "Richard Milburn Academy - Amar",
  [SchoolDistrict.RichardMilburnAcademyBeau]: "Richard Milburn Academy - Beau",
  [SchoolDistrict.RichardMilburnAcademyCorp]: "Richard Milburn Academy - Corp",
  [SchoolDistrict.RichardMilburnAcademyEcto]: "Richard Milburn Academy - Ecto",
  [SchoolDistrict.RichardMilburnAcademyFort]: "Richard Milburn Academy - Fort",
  [SchoolDistrict.RichardMilburnAcademyLubb]: "Richard Milburn Academy - Lubb",
  [SchoolDistrict.RichardMilburnAcademyMidl]: "Richard Milburn Academy - Midl",
  [SchoolDistrict.RichardMilburnAcademySubu]: "Richard Milburn Academy - Subu",
  [SchoolDistrict.RichardMilburnAcademyKillee]:
    "Richard Milburn Academy-Killee",
  [SchoolDistrict.Richards]: "Richards ISD",
  [SchoolDistrict.Richardson]: "Richardson ISD",
  [SchoolDistrict.RichlandSprings]: "Richland Springs ISD",
  [SchoolDistrict.Riesel]: "Riesel ISD",
  [SchoolDistrict.RioGrandeCityC]: "Rio Grande City CISD",
  [SchoolDistrict.RioHondo]: "Rio Hondo ISD",
  [SchoolDistrict.RioVista]: "Rio Vista ISD",
  [SchoolDistrict.RipleyHouseCharterSchool]: "Ripley House Charter School",
  [SchoolDistrict.RiseAcademy]: "Rise Academy",
  [SchoolDistrict.RisingStar]: "Rising Star ISD",
  [SchoolDistrict.RiverRoad]: "River Road ISD",
  [SchoolDistrict.Rivercrest]: "Rivercrest ISD",
  [SchoolDistrict.Riviera]: "Riviera ISD",
  [SchoolDistrict.RobertLee]: "Robert Lee ISD",
  [SchoolDistrict.Robinson]: "Robinson ISD",
  [SchoolDistrict.Robstown]: "Robstown ISD",
  [SchoolDistrict.RobyCons]: "Roby Cons ISD",
  [SchoolDistrict.Rochelle]: "Rochelle ISD",
  [SchoolDistrict.Rochester]: "Rochester",
  [SchoolDistrict.RochesterCountyLine]: "Rochester County Line ISD",
  [SchoolDistrict.Rockdale]: "Rockdale ISD",
  [SchoolDistrict.Rocksprings]: "Rocksprings ISD",
  [SchoolDistrict.Rockwall]: "Rockwall ISD",
  [SchoolDistrict.Rogers]: "Rogers ISD",
  [SchoolDistrict.Roma]: "Roma ISD",
  [SchoolDistrict.RonJacksonStateJuvenileCor]: "Ron Jackson State Juvenile Cor",
  [SchoolDistrict.Roosevelt]: "Roosevelt ISD",
  [SchoolDistrict.Ropes]: "Ropes ISD",
  [SchoolDistrict.Roscoe]: "Roscoe ISD",
  [SchoolDistrict.RosebudLott]: "Rosebud-Lott ISD",
  [SchoolDistrict.Rotan]: "Rotan ISD",
  [SchoolDistrict.RoundRock]: "Round Rock ISD",
  [SchoolDistrict.RoundTopCarmine]: "Round Top-Carmine ISD",
  [SchoolDistrict.Roxton]: "Roxton ISD",
  [SchoolDistrict.Royal]: "Royal ISD",
  [SchoolDistrict.RoyseCity]: "Royse City ISD",
  [SchoolDistrict.Rule]: "Rule ISD",
  [SchoolDistrict.Runge]: "Runge ISD",
  [SchoolDistrict.Rusk]: "Rusk ISD",
  [SchoolDistrict.SandSCons]: "S and S Cons ISD",
  [SchoolDistrict.Sabinal]: "Sabinal ISD",
  [SchoolDistrict.Sabine]: "Sabine ISD",
  [SchoolDistrict.SabinePass]: "Sabine Pass ISD",
  [SchoolDistrict.SaintJo]: "Saint Jo ISD",
  [SchoolDistrict.Salado]: "Salado ISD",
  [SchoolDistrict.Saltillo]: "Saltillo ISD",
  [SchoolDistrict.SamRayburn]: "Sam Rayburn ISD",
  [SchoolDistrict.Samnorwood]: "Samnorwood ISD",
  [SchoolDistrict.SanAngelo]: "San Angelo ISD",
  [SchoolDistrict.SanAntonioCanHighSchool]: "San Antonio Can High School",
  [SchoolDistrict.SanAntonio]: "San Antonio ISD",
  [SchoolDistrict.SanAntonioPreparatoryAcadem]:
    "San Antonio Preparatory Academ",
  [SchoolDistrict.SanAntonioSchoolforInquiry]: "San Antonio School for Inquiry",
  [SchoolDistrict.SanAntonioTechnologyAcadem]: "San Antonio Technology  Academ",
  [SchoolDistrict.SanAugustine]: "San Augustine ISD",
  [SchoolDistrict.SanBenitoCons]: "San Benito Cons ISD",
  [SchoolDistrict.SanDiego]: "San Diego ISD",
  [SchoolDistrict.SanElizario]: "San Elizario ISD",
  [SchoolDistrict.SanFelipeDelRioCons]: "San Felipe-Del Rio Cons ISD",
  [SchoolDistrict.SanIsidro]: "San Isidro ISD",
  [SchoolDistrict.SanMarcosCons]: "San Marcos Cons ISD",
  [SchoolDistrict.SanMarcosPreparatorySchool]: "San Marcos Preparatory School",
  [SchoolDistrict.SanPerlita]: "San Perlita ISD",
  [SchoolDistrict.SanSaba]: "San Saba ISD",
  [SchoolDistrict.SanSabaStateSchool]: "San Saba State School",
  [SchoolDistrict.SanVicente]: "San Vicente ISD",
  [SchoolDistrict.SandsC]: "Sands CISD",
  [SchoolDistrict.Sanford]: "Sanford ISD",
  [SchoolDistrict.Sanger]: "Sanger ISD",
  [SchoolDistrict.SantaAnna]: "Santa Anna ISD",
  [SchoolDistrict.SantaFe]: "Santa Fe ISD",
  [SchoolDistrict.SantaGertrudis]: "Santa Gertrudis ISD",
  [SchoolDistrict.SantaMaria]: "Santa Maria ISD",
  [SchoolDistrict.SantaRosa]: "Santa Rosa ISD",
  [SchoolDistrict.Santo]: "Santo ISD",
  [SchoolDistrict.Savoy]: "Savoy ISD",
  [SchoolDistrict.SchertzCiboloUCity]: "Schertz-Cibolo-U City ISD",
  [SchoolDistrict.Schleicher]: "Schleicher ISD",
  [SchoolDistrict.SchoolofExcellenceinEducat]: "School of Excellence in Educat",
  [SchoolDistrict.SchoolofLiberalArtsandSci]: "School of Liberal Arts and Sci",
  [SchoolDistrict.Schulenburg]: "Schulenburg ISD",
  [SchoolDistrict.Scotland]: "Scotland",
  [SchoolDistrict.ScurryRosser]: "Scurry-Rosser ISD",
  [SchoolDistrict.Seagraves]: "Seagraves ISD",
  [SchoolDistrict.Sealy]: "Sealy ISD",
  [SchoolDistrict.SeashoreLearningCtrCharter]: "Seashore Learning Ctr Charter",
  [SchoolDistrict.Seguin]: "Seguin ISD",
  [SchoolDistrict.Seminole]: "Seminole ISD",
  [SchoolDistrict.SentryTechnologyPrepSchool]: "Sentry Technology Prep School",
  [SchoolDistrict.SerNinosCharterSchool]: "Ser-Ninos Charter School",
  [SchoolDistrict.Seymour]: "Seymour ISD",
  [SchoolDistrict.Shallowater]: "Shallowater ISD",
  [SchoolDistrict.Shamrock]: "Shamrock ISD",
  [SchoolDistrict.Sharyland]: "Sharyland ISD",
  [SchoolDistrict.ShekinahRadianceAcademy]: "Shekinah Radiance Academy",
  [SchoolDistrict.Shelbyville]: "Shelbyville ISD",
  [SchoolDistrict.Sheldon]: "Sheldon ISD",
  [SchoolDistrict.Shepherd]: "Shepherd ISD",
  [SchoolDistrict.Sherman]: "Sherman ISD",
  [SchoolDistrict.Shiner]: "Shiner ISD",
  [SchoolDistrict.Sidney]: "Sidney ISD",
  [SchoolDistrict.SierraBlanca]: "Sierra Blanca ISD",
  [SchoolDistrict.Silsbee]: "Silsbee ISD",
  [SchoolDistrict.Silverton]: "Silverton ISD",
  [SchoolDistrict.Simms]: "Simms ISD",
  [SchoolDistrict.Sinton]: "Sinton ISD",
  [SchoolDistrict.SivellsBend]: "Sivells Bend ISD",
  [SchoolDistrict.SkidmoreTynan]: "Skidmore-Tynan ISD",
  [SchoolDistrict.Slaton]: "Slaton ISD",
  [SchoolDistrict.Slidell]: "Slidell ISD",
  [SchoolDistrict.Slocum]: "Slocum ISD",
  [SchoolDistrict.Smithville]: "Smithville ISD",
  [SchoolDistrict.Smyer]: "Smyer ISD",
  [SchoolDistrict.Snook]: "Snook ISD",
  [SchoolDistrict.Snyder]: "Snyder ISD",
  [SchoolDistrict.Socorro]: "Socorro ISD",
  [SchoolDistrict.Somerset]: "Somerset ISD",
  [SchoolDistrict.Somerville]: "Somerville ISD",
  [SchoolDistrict.Sonora]: "Sonora ISD",
  [SchoolDistrict.SouthAfrica]: "South Africa",
  [SchoolDistrict.SouthPlainsAcademy]: "South Plains Academy",
  [SchoolDistrict.SouthSanAntonio]: "South San Antonio ISD",
  [SchoolDistrict.SouthTexas]: "South Texas ISD",
  [SchoolDistrict.Southland]: "Southland ISD",
  [SchoolDistrict.Southside]: "Southside ISD",
  [SchoolDistrict.SouthwestHighSchool]: "Southwest High School",
  [SchoolDistrict.Southwest]: "Southwest ISD",
  [SchoolDistrict.SouthwestPreparatorySchool]: "Southwest Preparatory School",
  [SchoolDistrict.Spade]: "Spade ISD",
  [SchoolDistrict.Spain]: "Spain",
  [SchoolDistrict.Spearman]: "Spearman ISD",
  [SchoolDistrict.Splendora]: "Splendora ISD",
  [SchoolDistrict.SpringBranch]: "Spring Branch ISD",
  [SchoolDistrict.SpringCreek]: "Spring Creek ISD",
  [SchoolDistrict.SpringHill]: "Spring Hill ISD",
  [SchoolDistrict.Spring]: "Spring ISD",
  [SchoolDistrict.SpringlakeEarth]: "Springlake-Earth ISD",
  [SchoolDistrict.Springtown]: "Springtown ISD",
  [SchoolDistrict.Spur]: "Spur ISD",
  [SchoolDistrict.Spurger]: "Spurger ISD",
  [SchoolDistrict.StAnthonyAcademy]: "St Anthony Academy",
  [SchoolDistrict.StMarysAcademyCharterScho]: "St Mary's Academy Charter Scho",
  [SchoolDistrict.StLucia]: "St. Lucia",
  [SchoolDistrict.StVincent]: "St. Vincent",
  [SchoolDistrict.StaffordMunicipalSchoolDist]:
    "Stafford Municipal School Dist",
  [SchoolDistrict.Stamford]: "Stamford ISD",
  [SchoolDistrict.Stanton]: "Stanton ISD",
  [SchoolDistrict.StarCharterSchool]: "Star Charter School",
  [SchoolDistrict.Star]: "Star ISD",
  [SchoolDistrict.StateofAlabama]: "State of Alabama",
  [SchoolDistrict.StateofArizona]: "State of Arizona",
  [SchoolDistrict.StateofArkansas]: "State of Arkansas",
  [SchoolDistrict.StateofColorado]: "State of Colorado",
  [SchoolDistrict.StateofFlorida]: "State of Florida",
  [SchoolDistrict.StateofIdaho]: "State of Idaho",
  [SchoolDistrict.StateofIllinois]: "State of Illinois",
  [SchoolDistrict.StateofIndiana]: "State of Indiana",
  [SchoolDistrict.StateofKansas]: "State of Kansas",
  [SchoolDistrict.StateofKentucky]: "State of Kentucky",
  [SchoolDistrict.StateofMassachusetts]: "State of Massachusetts",
  [SchoolDistrict.StateofMichigan]: "State of Michigan",
  [SchoolDistrict.StateofMississippi]: "State of Mississippi",
  [SchoolDistrict.StateofMissouri]: "State of Missouri",
  [SchoolDistrict.StateofMontana]: "State of Montana",
  [SchoolDistrict.StateofNewJersey]: "State of New Jersey",
  [SchoolDistrict.StateofNorthCarolina]: "State of North Carolina",
  [SchoolDistrict.StateofNorthDakota]: "State of North Dakota",
  [SchoolDistrict.StateofOhio]: "State of Ohio",
  [SchoolDistrict.StateofPennsylvania]: "State of Pennsylvania",
  [SchoolDistrict.StateofSouthDakota]: "State of South Dakota",
  [SchoolDistrict.StateofVirginia]: "State of Virginia",
  [SchoolDistrict.StateofWestVirginia]: "State of West Virginia",
  [SchoolDistrict.StateofWisconsin]: "State of Wisconsin",
  [SchoolDistrict.StateofWyoming]: "State of Wyoming",
  [SchoolDistrict.Stephenville]: "Stephenville",
  [SchoolDistrict.StephenvilleIsd]: "Stephenville ISD",
  [SchoolDistrict.SterlingCity]: "Sterling City ISD",
  [SchoolDistrict.Stockdale]: "Stockdale ISD",
  [SchoolDistrict.Stratford]: "Stratford ISD",
  [SchoolDistrict.Strawn]: "Strawn ISD",
  [SchoolDistrict.Sudan]: "Sudan ISD",
  [SchoolDistrict.SulphurBluff]: "Sulphur Bluff ISD",
  [SchoolDistrict.SulphurSprings]: "Sulphur Springs ISD",
  [SchoolDistrict.SulphurOK]: "Sulphur, OK",
  [SchoolDistrict.Sundown]: "Sundown ISD",
  [SchoolDistrict.Sunnyvale]: "Sunnyvale ISD",
  [SchoolDistrict.Sunray]: "Sunray ISD",
  [SchoolDistrict.Sweeny]: "Sweeny ISD",
  [SchoolDistrict.SweetHome]: "Sweet Home ISD",
  [SchoolDistrict.Sweetwater]: "Sweetwater ISD",
  [SchoolDistrict.Taft]: "Taft ISD",
  [SchoolDistrict.Tahoka]: "Tahoka ISD",
  [SchoolDistrict.Tarkington]: "Tarkington ISD",
  [SchoolDistrict.Tatum]: "Tatum ISD",
  [SchoolDistrict.Taylor]: "Taylor ISD",
  [SchoolDistrict.Teague]: "Teague ISD",
  [SchoolDistrict.TechnologyEducationCharterH]:
    "Technology Education Charter H",
  [SchoolDistrict.TekoaAcademyofAcceleratedS]: "Tekoa Academy of Accelerated S",
  [SchoolDistrict.Temple]: "Temple ISD",
  [SchoolDistrict.Tenaha]: "Tenaha ISD",
  [SchoolDistrict.Tennessee]: "Tennessee",
  [SchoolDistrict.TerlinguaCSD]: "Terlingua CSD",
  [SchoolDistrict.TerrellCounty]: "Terrell County ISD",
  [SchoolDistrict.Terrell]: "Terrell ISD",
  [SchoolDistrict.Texarkana]: "Texarkana ISD",
  [SchoolDistrict.TexasAMUniversityatGalv]: "Texas A & M University at Galv",
  [SchoolDistrict.TexasAcademyofExcellence]: "Texas Academy of Excellence",
  [SchoolDistrict.TexasAcademyofLeadershipin]: "Texas Academy of Leadership in",
  [SchoolDistrict.TexasCity]: "Texas City ISD",
  [SchoolDistrict.TexasEmpowermentAcademy]: "Texas Empowerment Academy",
  [SchoolDistrict.TexasSchfortheBlindampVisu]: "Texas Sch for the Blind & Visu",
  [SchoolDistrict.TexasSchfortheDeaf]: "Texas Sch for the Deaf",
  [SchoolDistrict.TexasSerenityAcademy]: "Texas Serenity Academy",
  [SchoolDistrict.TexasSerenityAcademyBaysho]: "Texas Serenity Academy (Baysho",
  [SchoolDistrict.TexasTechUniversityHighSch]: "Texas Tech University High Sch",
  [SchoolDistrict.Texhoma]: "Texhoma ISD",
  [SchoolDistrict.Texline]: "Texline ISD",
  [SchoolDistrict.ThePhilippines]: "The Philippines",
  [SchoolDistrict.TheZoeLearningAcademy]: "The Zoe Learning Academy",
  [SchoolDistrict.TheresaBLeeAcademy]: "Theresa B Lee Academy",
  [SchoolDistrict.Thorndale]: "Thorndale ISD",
  [SchoolDistrict.Thrall]: "Thrall ISD",
  [SchoolDistrict.ThreeRivers]: "Three Rivers ISD",
  [SchoolDistrict.ThreeWay]: "Three Way ISD",
  [SchoolDistrict.Throckmorton]: "Throckmorton ISD",
  [SchoolDistrict.Tidehaven]: "Tidehaven ISD",
  [SchoolDistrict.Timpson]: "Timpson ISD",
  [SchoolDistrict.Tioga]: "Tioga ISD",
  [SchoolDistrict.Tolar]: "Tolar ISD",
  [SchoolDistrict.TomBean]: "Tom Bean ISD",
  [SchoolDistrict.Tomball]: "Tomball ISD",
  [SchoolDistrict.Tornillo]: "Tornillo ISD",
  [SchoolDistrict.TovasTactileOralVisualAlte]: "Tovas-Tactile Oral Visual Alte",
  [SchoolDistrict.TransformativeCharterAcademy]:
    "Transformative Charter Academy",
  [SchoolDistrict.TreetopsSchoolInternational]: "Treetops School International",
  [SchoolDistrict.Trent]: "Trent ISD",
  [SchoolDistrict.Trenton]: "Trenton ISD",
  [SchoolDistrict.Trinidad]: "Trinidad ISD",
  [SchoolDistrict.TrinityBasinPreparatory]: "Trinity Basin Preparatory",
  [SchoolDistrict.Trinity]: "Trinity ISD",
  [SchoolDistrict.Troup]: "Troup ISD",
  [SchoolDistrict.Troy]: "Troy ISD",
  [SchoolDistrict.Tulia]: "Tulia ISD",
  [SchoolDistrict.TulosoMidway]: "Tuloso-Midway ISD",
  [SchoolDistrict.Tupelo]: "Tupelo ISD",
  [SchoolDistrict.TurkeyQuitaque]: "Turkey-Quitaque ISD",
  [SchoolDistrict.TwoDimensionsPreparatoryAca]:
    "Two Dimensions Preparatory Aca",
  [SchoolDistrict.Tyler]: "Tyler ISD",
  [SchoolDistrict.UnionGrove]: "Union Grove ISD",
  [SchoolDistrict.UnionHill]: "Union Hill ISD",
  [SchoolDistrict.United]: "United ISD",
  [SchoolDistrict.UnivofHoustonCharterSchTe]: "Univ of Houston Charter Sch-Te",
  [SchoolDistrict.UniversalAcademy]: "Universal Academy",
  [SchoolDistrict.UniversityCharterSchool]: "University Charter School",
  [SchoolDistrict.UniversityofNorthTexas]: "University of North Texas",
  [SchoolDistrict.UniversityofTexasatAustin]: "University of Texas at Austin",
  [SchoolDistrict.UniversityofTexasElementary]:
    "University of Texas Elementary",
  [SchoolDistrict.Utopia]: "Utopia ISD",
  [SchoolDistrict.UvaldeCons]: "Uvalde Cons ISD",
  [SchoolDistrict.Valentine]: "Valentine ISD",
  [SchoolDistrict.ValleyHigh]: "Valley High",
  [SchoolDistrict.ValleyMills]: "Valley Mills ISD",
  [SchoolDistrict.ValleyView]: "Valley View ISD",
  [SchoolDistrict.VanAlstyne]: "Van Alstyne ISD",
  [SchoolDistrict.Van]: "Van ISD",
  [SchoolDistrict.VanVleck]: "Van Vleck ISD",
  [SchoolDistrict.VanguardAcademy]: "Vanguard Academy",
  [SchoolDistrict.VarnettCharterSchool]: "Varnett Charter School",
  [SchoolDistrict.Vega]: "Vega ISD",
  [SchoolDistrict.Venus]: "Venus ISD",
  [SchoolDistrict.Veribest]: "Veribest ISD",
  [SchoolDistrict.Vernon]: "Vernon ISD",
  [SchoolDistrict.Victoria]: "Victoria ISD",
  [SchoolDistrict.Vidor]: "Vidor ISD",
  [SchoolDistrict.Vysehrad]: "Vysehrad ISD",
  [SchoolDistrict.WacoCharterSchoolEoac]: "Waco Charter School (EOAC)",
  [SchoolDistrict.Waco]: "Waco ISD",
  [SchoolDistrict.Waelder]: "Waelder ISD",
  [SchoolDistrict.Walcott]: "Walcott ISD",
  [SchoolDistrict.Wall]: "Wall ISD",
  [SchoolDistrict.Waller]: "Waller ISD",
  [SchoolDistrict.WalnutBend]: "Walnut Bend ISD",
  [SchoolDistrict.WalnutSprings]: "Walnut Springs ISD",
  [SchoolDistrict.Warren]: "Warren ISD",
  [SchoolDistrict.WaSetPreparatoryAcademy]: "Wa-Set Preparatory Academy",
  [SchoolDistrict.Washington]: "Washington",
  [SchoolDistrict.Waskom]: "Waskom ISD",
  [SchoolDistrict.WaterValley]: "Water Valley ISD",
  [SchoolDistrict.Waxahachie]: "Waxahachie ISD",
  [SchoolDistrict.Weatherford]: "Weatherford ISD",
  [SchoolDistrict.WebbCons]: "Webb Cons ISD",
  [SchoolDistrict.Weimar]: "Weimar ISD",
  [SchoolDistrict.Wellington]: "Wellington ISD",
  [SchoolDistrict.WellmanUnionCons]: "Wellman-Union Cons ISD",
  [SchoolDistrict.Wells]: "Wells ISD",
  [SchoolDistrict.Weslaco]: "Weslaco ISD",
  [SchoolDistrict.WestHardinCountyCons]: "West Hardin County Cons ISD",
  [SchoolDistrict.WestHoustonCharterSchool]: "West Houston Charter School",
  [SchoolDistrict.West]: "West ISD",
  [SchoolDistrict.WestOrangeCoveCons]: "West Orange-Cove Cons ISD",
  [SchoolDistrict.WestOso]: "West Oso ISD",
  [SchoolDistrict.WestRusk]: "West Rusk ISD",
  [SchoolDistrict.WestSabine]: "West Sabine ISD",
  [SchoolDistrict.WestTexasStateSchool]: "West Texas State School",
  [SchoolDistrict.Westbrook]: "Westbrook ISD",
  [SchoolDistrict.Westhoff]: "Westhoff ISD",
  [SchoolDistrict.WestlakeAcademy]: "Westlake Academy",
  [SchoolDistrict.Westphalia]: "Westphalia ISD",
  [SchoolDistrict.Westwood]: "Westwood ISD",
  [SchoolDistrict.Wharton]: "Wharton ISD",
  [SchoolDistrict.Wheeler]: "Wheeler ISD",
  [SchoolDistrict.WhiteDeer]: "White Deer ISD",
  [SchoolDistrict.WhiteOak]: "White Oak ISD",
  [SchoolDistrict.WhiteSettlement]: "White Settlement ISD",
  [SchoolDistrict.WhitefaceCons]: "Whiteface Cons ISD",
  [SchoolDistrict.Whitehouse]: "Whitehouse ISD",
  [SchoolDistrict.Whitesboro]: "Whitesboro ISD",
  [SchoolDistrict.Whitewright]: "Whitewright ISD",
  [SchoolDistrict.Whitharral]: "Whitharral ISD",
  [SchoolDistrict.Whitney]: "Whitney ISD",
  [SchoolDistrict.WichitaFalls]: "Wichita Falls ISD",
  [SchoolDistrict.Wildorado]: "Wildorado ISD",
  [SchoolDistrict.Willis]: "Willis ISD",
  [SchoolDistrict.WillsPoint]: "Wills Point ISD",
  [SchoolDistrict.WilmerHutchins]: "Wilmer-Hutchins ISD",
  [SchoolDistrict.Wilson]: "Wilson ISD",
  [SchoolDistrict.Wimberley]: "Wimberley ISD",
  [SchoolDistrict.WindhamSchoolDistrict]: "Windham School District",
  [SchoolDistrict.Windthorst]: "Windthorst ISD",
  [SchoolDistrict.Winfield]: "Winfield ISD",
  [SchoolDistrict.WinfreeAcademy]: "Winfree Academy",
  [SchoolDistrict.WinkLoving]: "Wink-Loving ISD",
  [SchoolDistrict.Winnsboro]: "Winnsboro ISD",
  [SchoolDistrict.Winona]: "Winona ISD",
  [SchoolDistrict.Winters]: "Winters ISD",
  [SchoolDistrict.Woden]: "Woden ISD",
  [SchoolDistrict.WolfeCity]: "Wolfe City ISD",
  [SchoolDistrict.Woodsboro]: "Woodsboro ISD",
  [SchoolDistrict.Woodson]: "Woodson ISD",
  [SchoolDistrict.Woodville]: "Woodville ISD",
  [SchoolDistrict.Wortham]: "Wortham ISD",
  [SchoolDistrict.Wylie]: "Wylie ISD",
  [SchoolDistrict.WylieTaylorCo]: "Wylie ISD, Taylor Co.",
  [SchoolDistrict.Yantis]: "Yantis ISD",
  [SchoolDistrict.YesCollegePreparatorySchool]:
    "Yes College Preparatory School",
  [SchoolDistrict.Yoakum]: "Yoakum ISD",
  [SchoolDistrict.Yorktown]: "Yorktown ISD",
  [SchoolDistrict.Ysleta]: "Ysleta ISD",
  [SchoolDistrict.ZapataCounty]: "Zapata County ISD",
  [SchoolDistrict.Zavalla]: "Zavalla ISD",
  [SchoolDistrict.Zephyr]: "Zephyr ISD"
};
