import React from "react";
import { CheckCircle, Warning } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ActionCard } from "../action-card/action-card.component";
import { useStyles } from "./alert-card.styles";

export const AlertCard = ({
  value = 0,
  title,
  subtitle,
  onClick
}: {
  value?: number;
  title: string;
  subtitle: string;
  onClick?: () => void;
}): JSX.Element => {
  const classes = useStyles();
  const alertClass = !!value ? "alert" : "noAlert";
  return (
    <ActionCard sx={classes.root} className={alertClass} onClick={onClick}>
      <Typography sx={classes.value}>{value}</Typography>
      <Box sx={classes.textContainers}>
        <Typography sx={classes.title}>{title}</Typography>
        <Typography sx={classes.subtitle}>{subtitle}</Typography>
      </Box>

      {!!value ? (
        <Warning sx={classes.icon} />
      ) : (
        <CheckCircle sx={classes.icon} />
      )}
    </ActionCard>
  );
};
