export enum SupplierOther {
  AboveGround = "aboveGround",
  CableTV = "cableTV",
  CityElectric = "cityElectric",
  CityGarbage = "cityGarbage",
  CoOpElectric = "coOpElectric",
  ElectricityAvailable = "electricityAvailable",
  FiberOptic = "fiberOptic",
  GasAvailable = "gasAvailable",
  HighSpeedInternet = "highSpeedInternet",
  NaturalGasAvailable = "naturalGasAvailable",
  OnSiteElectric = "onSiteElectric",
  PropaneButaneTankLeased = "propaneButaneTankLeased",
  PropaneButaneTankOwned = "propaneButaneTankOwned",
  TelephoneAvailable = "telephoneAvailable",
  UndergroundUtilities = "undergroundUtilities",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks",
  PrivateGarbageService = "privateGarbageService",
  ElectricityOnProperty = "electricityOnProperty",
  GasInstalled = "gasInstalled",
  SeparateMeters = "separateMeters"
}

export const SupplierOtherLabel = new Map<SupplierOther, string>([
  [SupplierOther.SeparateMeters, "Separate Meters"],
  [SupplierOther.GasInstalled, "Gas Installed"],
  [SupplierOther.ElectricityOnProperty, "Electricity on Property"],
  [SupplierOther.PrivateGarbageService, "Private Garbage Service"],
  [SupplierOther.AboveGround, "Above Ground"],
  [SupplierOther.CableTV, "Cable TV"],
  [SupplierOther.CityElectric, "City Electric"],
  [SupplierOther.CityGarbage, "City Garbage"],
  [SupplierOther.CoOpElectric, "Co-Op Electric"],
  [SupplierOther.ElectricityAvailable, "Electricity Available"],
  [SupplierOther.FiberOptic, "Fiber Optic"],
  [SupplierOther.GasAvailable, "Gas Available"],
  [SupplierOther.HighSpeedInternet, "High Speed Internet"],
  [SupplierOther.NaturalGasAvailable, "Natural Gas Available"],
  [SupplierOther.OnSiteElectric, "On-Site Electric"],
  [SupplierOther.PropaneButaneTankLeased, "Propane/Butane Tank-Leased"],
  [SupplierOther.PropaneButaneTankOwned, "Propane/Butane Tank-Owned"],
  [SupplierOther.TelephoneAvailable, "Telephone Available"],
  [SupplierOther.UndergroundUtilities, "Underground Utilities"],
  [SupplierOther.None, "None"],
  [SupplierOther.OtherSeeRemarks, "Other-See Remarks"]
]);
