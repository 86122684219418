import axios from "axios";

import { MarketCode } from "@homesusa/core";
import { GridResponse } from "@homesusa/grid-table";
import { ReverseProspectData } from "core/interfaces";
import { ListType } from "core/enums";
import {
  getListingsApiUrl,
  getListings as getListingsByType
} from "./listings.service";
import { ListingGrid } from "core/interfaces";
import { ListingFilter } from "core/interfaces/filters";

export const getSaleListingsApiUrl = (market: MarketCode): string =>
  getListingsApiUrl(ListType.Sale, market);

export const getListings = (
  market: MarketCode,
  listingType: ListType = ListType.Sale
): ((
  params: ListingFilter,
  companyId?: string
) => Promise<GridResponse<ListingGrid>>) =>
  getListingsByType(listingType, market);

export const getReverseProspecting = async (
  market: MarketCode,
  listingId: string
): Promise<ReverseProspectData> => {
  const apiUrl = getSaleListingsApiUrl(market);
  const response = await axios.get<ReverseProspectData>(
    `${apiUrl}/${listingId}/reverse-prospect`
  );
  return response.data;
};
