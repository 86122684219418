export enum HOAIncludes {
  Cable = "cable",
  CommonAreasMaintenance = "commonAreasMaintenance",
  MaintenanceGrounds = "maintenanceGrounds",
  MaintenanceStructure = "maintenanceStructure",
  Internet = "internet",
  Landscaping = "landscaping",
  Security = "security",
  SeeRemarks = "seeRemarks"
}
export const HOAIncludesLabel = new Map<HOAIncludes, string>([
  [HOAIncludes.Cable, "Cable"],
  [HOAIncludes.CommonAreasMaintenance, "Common Areas Maintenance"],
  [HOAIncludes.Internet, "Internet"],
  [HOAIncludes.Landscaping, "Landscaping"],
  [HOAIncludes.MaintenanceGrounds, "Maintenance Grounds"],
  [HOAIncludes.MaintenanceStructure, "Maintenance Structure"],
  [HOAIncludes.Security, "Security"],
  [HOAIncludes.SeeRemarks, "See Remarks"]
]);
