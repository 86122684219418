export enum CoolingSystem {
  Unit1 = "unit1",
  Units2 = "units2",
  Units3 = "units3",
  AtticFan = "atticFan",
  Central = "central",
  Electric = "electric",
  HeatPump = "heatPump",
  Solar = "solar",
  Zoned = "zoned",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const CoolingSystemLabel = new Map<CoolingSystem, string>([
  [CoolingSystem.Unit1, "1 Unit"],
  [CoolingSystem.Units2, "2 Units"],
  [CoolingSystem.Units3, "3+ Units"],
  [CoolingSystem.AtticFan, "AtticFan"],
  [CoolingSystem.Central, "Central"],
  [CoolingSystem.Electric, "Electric"],
  [CoolingSystem.HeatPump, "HeatPump"],
  [CoolingSystem.Solar, "Solar"],
  [CoolingSystem.Zoned, "Zoned"],
  [CoolingSystem.None, "None"],
  [CoolingSystem.OtherSeeRemarks, "Other-See Remarks"]
]);
