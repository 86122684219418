import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";
import { ImportSource, JsonImportStatus, XmlStatus } from "core/enums";
import { approveImportedPlan } from "core/services/import.services";
import { ImportPlan } from "../interfaces/alerts/import";
import { AlertBaseFilter } from "../interfaces";
import { ImportPlanService } from "../interfaces/services";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

const ImportPlanAlertService = (market: MarketCode): ImportPlanService => {
  const gatewayMarket = getGatewayMarket(market);
  const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/plans`;

  return {
    getAwaitingApprovalPlans: async (
      importSource: ImportSource,
      filter: AlertBaseFilter
    ): Promise<GridResponse<ImportPlan>> => {
      const params = {
        ...filter,
        ...(importSource == ImportSource.Json
          ? { jsonImportStatus: JsonImportStatus.AwaitingApproval }
          : { xmlStatus: XmlStatus.AwaitingApproval })
      };
      return axios
        .get<GridResponse<ImportPlan>>(apiUrl, { params })
        .then((response) => response.data);
    },

    approvePlan: approveImportedPlan(market)
  };
};

export default ImportPlanAlertService;
