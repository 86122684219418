import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { FormTabContent } from "@homesusa/form";
import { NotesManager } from "@homesusa/notes";

const ListingDetails = React.lazy(
  () => import("app/pages/listing-sale-details.component")
);
const ListingUpdate = React.lazy(
  () => import("app/pages/listing-form-update.component")
);
const ListingGrid = React.lazy(
  () => import("app/pages/listing-sale-grid.component")
);
const PhotoRequets = React.lazy(
  () =>
    import("modules/listings/components/photo-requests/photo-requets.component")
);
const ListingRequets = React.lazy(
  () => import("modules/listings/components/listing-request.component")
);
const ImportMedia = React.lazy(
  () => import("modules/listings/components/media/import-media.component")
);
const ImportNewMedia = React.lazy(
  () =>
    import("modules/sale-listings/components/media/import-new-media.component")
);

export function SaleListingRoutes(): JSX.Element {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route index element={<ListingGrid />} />
        <Route
          path=":id"
          element={
            <Suspense fallback={<LinearProgress />}>
              <ListingDetails />
            </Suspense>
          }
        >
          <Route path="import-new-media" element={<ImportNewMedia />} />
          <Route
            element={
              <Suspense fallback={<LinearProgress />}>
                <ListingUpdate />
              </Suspense>
            }
          >
            <Route path="notes" element={<NotesManager />} />
            <Route path="requests" element={<ListingRequets />} />
            <Route path="photo-requests" element={<PhotoRequets />} />
            <Route path="import-media" element={<ImportMedia />} />
            <Route index element={<FormTabContent />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}
