export enum View {
  Bridges = "bridges",
  Canal = "canal",
  Canyon = "canyon",
  City = "city",
  CityLights = "cityLights",
  CreekStream = "creekStream",
  Downtown = "downtown",
  Garden = "garden",
  GolfCourse = "golfCourse",
  HillCountry = "hillCountry",
  Lake = "lake",
  Marina = "marina",
  Neighborhood = "neighborhood",
  None = "none",
  Orchard = "orchard",
  Panoramic = "panoramic",
  ParkGreenbelt = "parkGreenbelt",
  Pasture = "pasture",
  Pond = "pond",
  Pool = "pool",
  River = "river",
  Rural = "rural",
  Skyline = "skyline",
  TreesWoods = "treesWoods",
  Vineyard = "vineyard",
  Water = "water"
}
export const ViewLabel = new Map<View, string>([
  [View.Bridges, "Bridge(s)"],
  [View.Canal, "Canal"],
  [View.Canyon, "Canyon"],
  [View.City, "City"],
  [View.CityLights, "City Lights"],
  [View.CreekStream, "Creek/Stream"],
  [View.Downtown, "Downtown"],
  [View.Garden, "Garden"],
  [View.GolfCourse, "Golf Course"],
  [View.HillCountry, "Hill Country"],
  [View.Lake, "Lake"],
  [View.Marina, "Marina"],
  [View.Neighborhood, "Neighborhood"],
  [View.None, "None"],
  [View.Orchard, "Orchard"],
  [View.Panoramic, "Panoramic"],
  [View.ParkGreenbelt, "Park/Greenbelt"],
  [View.Pasture, "Pasture"],
  [View.Pond, "Pond"],
  [View.Pool, "Pool"],
  [View.River, "River"],
  [View.Rural, "Rural"],
  [View.Skyline, "Skyline"],
  [View.TreesWoods, "Trees/Woods"],
  [View.Vineyard, "Vineyard"],
  [View.Water, "Water"]
]);
