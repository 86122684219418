export enum OtherStructures {
  AirplaneHangar = "airplaneHangar",
  Arena = "arena",
  Barns = "barns",
  Bathroom = "bathroom",
  BoatHouse = "boatHouse",
  Cabana = "cabana",
  Caves = "caves",
  Corrals = "corrals",
  CoveredArena = "coveredArena",
  Garages = "garages",
  Gazebo = "gazebo",
  GrainStorage = "grainStorage",
  Greenhouse = "greenhouse",
  GuestHouse = "guestHouse",
  KennelDogRun = "kennelDogRun",
  MobileHome = "mobileHome",
  Outbuilding = "outbuilding",
  OutdoorKitchen = "outdoorKitchen",
  PackingShed = "packingShed",
  Pergola = "pergola",
  PoolHouse = "poolHouse",
  PoultryCoop = "poultryCoop",
  Residence = "residence",
  RVBoatStorage = "rvBoatStorage",
  SecondResidence = "secondResidence",
  Shed = "shed",
  Storage = "storage",
  TennisCourts = "tennisCourts",
  Workshop = "workshop",
  None = "none",
  SeeRemarks = "seeRemarks"
}
export const OtherStructuresLabel = new Map<OtherStructures, string>([
  [OtherStructures.AirplaneHangar, "Airplane Hangar"],
  [OtherStructures.Arena, "Arena"],
  [OtherStructures.Barns, "Barn(s)"],
  [OtherStructures.Bathroom, "Bathroom"],
  [OtherStructures.BoatHouse, "Boat House"],
  [OtherStructures.Cabana, "Cabana"],
  [OtherStructures.Caves, "Cave(s)"],
  [OtherStructures.Corrals, "Corral(s)"],
  [OtherStructures.CoveredArena, "Covered Arena"],
  [OtherStructures.Garages, "Garage(s)"],
  [OtherStructures.Gazebo, "Gazebo"],
  [OtherStructures.GrainStorage, "Grain Storage"],
  [OtherStructures.Greenhouse, "Greenhouse"],
  [OtherStructures.GuestHouse, "Guest House"],
  [OtherStructures.KennelDogRun, "Kennel/Dog Run"],
  [OtherStructures.MobileHome, "Mobile Home"],
  [OtherStructures.Outbuilding, "Outbuilding"],
  [OtherStructures.OutdoorKitchen, "Outdoor Kitchen"],
  [OtherStructures.PackingShed, "Packing Shed"],
  [OtherStructures.Pergola, "Pergola"],
  [OtherStructures.PoolHouse, "Pool House"],
  [OtherStructures.PoultryCoop, "Poultry Coop"],
  [OtherStructures.Residence, "Residence"],
  [OtherStructures.RVBoatStorage, "RV/Boat Storage"],
  [OtherStructures.SecondResidence, "Second Residence"],
  [OtherStructures.Shed, "Shed"],
  [OtherStructures.Storage, "Storage"],
  [OtherStructures.TennisCourts, "Tennis Court(s)"],
  [OtherStructures.Workshop, "Workshop"],
  [OtherStructures.None, "None"],
  [OtherStructures.SeeRemarks, "See Remarks"]
]);
