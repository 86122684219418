import { FormRules } from "@homesusa/form";
import {
  getOptionsFromEnumAndRecord,
  getOptionsFromEnumAndMap
} from "@homesusa/core";
import {
  ElementarySchool,
  ElementarySchoolLabel,
  HighSchool,
  HighSchoolLabel,
  MiddleSchool,
  MiddleSchoolLabel,
  SchoolDistrict,
  SchoolDistrictLabel
} from "markets/sabor/enums";

export const schoolsRules: FormRules = {
  schoolDistrict: {
    label: "School District",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndRecord(
      Object.values(SchoolDistrict),
      SchoolDistrictLabel
    )
  },
  elementarySchool: {
    label: "Elementary School",
    options: getOptionsFromEnumAndRecord(
      Object.values(ElementarySchool),
      ElementarySchoolLabel
    )
  },
  middleSchool: {
    label: "Middle School",
    options: getOptionsFromEnumAndMap(
      Object.values(MiddleSchool),
      MiddleSchoolLabel
    )
  },
  highSchool: {
    label: "High School",
    options: getOptionsFromEnumAndRecord(
      Object.values(HighSchool),
      HighSchoolLabel
    )
  }
};
