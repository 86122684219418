import { getOptionsFromEnumAndRecord } from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { States, StatesLabel } from "core/enums";

export const streetNumberRule: FieldRulesProps = {
  label: "Street Number",
  required: true,
  requiredToSubmit: true,
  regex: "^(?!(0))[0-9]+(-[0-9]+){0,12}?$",
  regexMessage: "Must be digits or digits with a middle hyphen only",
  minLength: 1,
  maxLength: 12
};

export const streetNameRule: FieldRulesProps = {
  label: "Street Name",
  required: true,
  minLength: 1,
  maxLength: 50,
  notAllowedWhiteSpaces: "start",
  requiredToSubmit: true
};

export const stateRule: FieldRulesProps = Object.assign({
  required: true,
  label: "State",
  options: getOptionsFromEnumAndRecord(Object.values(States), StatesLabel),
  requiredToSubmit: true
});

export const latitudeRule: FieldRulesProps = {
  label: "Latitude",
  maxLength: 15,
  numberFormat: {
    decimalScale: 12
  }
};

export const longitudeRule: FieldRulesProps = {
  label: "Longitude",
  maxLength: 15,
  numberFormat: {
    decimalScale: 12
  }
};

export const unitNumberRules: FieldRulesProps = {
  label: "Unit #",
  minLength: 1,
  maxLength: 10
};
