export enum Construction {
  Adobe = "adobe",
  Asphalt = "asphalt",
  AtticCrawlHatchwaysInsulated = "atticCrawlHatchwaysInsulated",
  BlownInInsulation = "blownInInsulation",
  Brick = "brick",
  BrickVeneer = "brickVeneer",
  Cedar = "cedar",
  ClapBoard = "clapBoard",
  Concrete = "concrete",
  Frame = "frame",
  Glass = "glass",
  HardiPlankType = "hardiPlankType",
  ICatRecessedLighting = "iCatRecessedLighting",
  InsulatedConcreteForms = "insulatedConcreteForms",
  Log = "log",
  MasonryAllSides = "masonryAllSides",
  MasonryPartial = "masonryPartial",
  NaturalBuilding = "naturalBuilding",
  RadiantBarrier = "radiantBarrier",
  RecycledBioBasedInsulation = "recycledBioBasedInsulation",
  SidingRedwood = "sidingRedwood",
  SidingWood = "sidingWood",
  SidingVinyl = "sidingVinyl",
  SprayFoamInsulation = "sprayFoamInsulation",
  Stone = "stone",
  StoneVeneer = "stoneVeneer",
  Stucco = "stucco",
  SyntheticStucco = "syntheticStucco"
}
export const ConstructionLabel = new Map<Construction, string>([
  [Construction.Adobe, "Adobe"],
  [Construction.Asphalt, "Asphalt"],
  [
    Construction.AtticCrawlHatchwaysInsulated,
    "Attic/Crawl Hatchway(s) Insulated"
  ],
  [Construction.BlownInInsulation, "Blown-In Insulation"],
  [Construction.Brick, "Brick"],
  [Construction.BrickVeneer, "Brick Veneer"],
  [Construction.Cedar, "Cedar"],
  [Construction.ClapBoard, "ClapBoard"],
  [Construction.Concrete, "Concrete"],
  [Construction.Frame, "Frame"],
  [Construction.Glass, "Glass"],
  [Construction.HardiPlankType, "HardiPlank Type"],
  [Construction.ICatRecessedLighting, "ICAT Recessed Lighting"],
  [Construction.InsulatedConcreteForms, "ICFs (Insulated Concrete Forms)"],
  [Construction.Log, "Log"],
  [Construction.MasonryAllSides, "Masonry - All Sides"],
  [Construction.MasonryPartial, "Masonry - Partial"],
  [Construction.NaturalBuilding, "Natural Building "],
  [Construction.RadiantBarrier, "Radiant Barrier"],
  [Construction.RecycledBioBasedInsulation, "Recycled/Bio-Based Insulation"],
  [Construction.SidingRedwood, "Siding - Redwood"],
  [Construction.SidingWood, "Siding - Wood"],
  [Construction.SidingVinyl, "Siding-Vinyl"],
  [Construction.SprayFoamInsulation, "Spray Foam Insulation"],
  [Construction.Stone, "Stone"],
  [Construction.StoneVeneer, "Stone Veneer"],
  [Construction.Stucco, "Stucco"],
  [Construction.SyntheticStucco, "Synthetic Stucco"]
]);
