import { MarketCode } from "@homesusa/core";

export const getDownloaderUrl = (marketCode: MarketCode): string => {
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY}/api/downloader-`;
  switch (marketCode) {
    case MarketCode.Sabor:
      return apiUrl + "sabor";
    case MarketCode.CTX:
    case MarketCode.Austin:
    case MarketCode.Houston:
    case MarketCode.DFW:
      return apiUrl + "trestle";
    default:
      return marketCode;
  }
};
