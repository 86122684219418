export enum ListingRequestStatus {
  All = "all",
  Pending = "pending",
  Approved = "approved",
  Returned = "returned",
  Processing = "processing",
  Completed = "completed"
}

export const ListingRequestStatusLabel = new Map<ListingRequestStatus, string>([
  [ListingRequestStatus.All, "All"],
  [ListingRequestStatus.Processing, "Processing"],
  [ListingRequestStatus.Returned, "Returned"],
  [ListingRequestStatus.Pending, "Pending"],
  [ListingRequestStatus.Approved, "Approved"],
  [ListingRequestStatus.Completed, "Completed"]
]);

export const ListingRequestStatusColor = new Map<ListingRequestStatus, string>([
  [ListingRequestStatus.Processing, "#00706f"],
  [ListingRequestStatus.Returned, "#B7070D"],
  [ListingRequestStatus.Pending, "#125ED0"],
  [ListingRequestStatus.Approved, "#012b5b"],
  [ListingRequestStatus.Completed, "#012b5b"]
]);

export const ClosedListingRequestStatuses = [
  ListingRequestStatus.Completed,
  ListingRequestStatus.Returned
];
