export enum ShowingInstructions {
  Accompany = "accompany",
  AppointmentRequired = "appointmentRequired",
  ControlledAccess = "controlledAccess",
  LeaveBusinessCard = "leaveBusinessCard",
  LockboxBack = "lockboxBack",
  LockboxFront = "lockboxFront",
  LockboxLeftSide = "lockboxLeftSide",
  LockboxRightSide = "lockboxRightSide",
  NoAppointmentRequired = "noAppointmentRequired",
  NoSupraKeybox = "noSupraKeybox",
  SupraKeybox = "supraKeybox",
  TemporarilyNoShowings = "temporarilyNoShowings"
}

export const ShowingInstructionsLabel = new Map<ShowingInstructions, string>([
  [ShowingInstructions.Accompany, "Accompany"],
  [ShowingInstructions.AppointmentRequired, "Appointment Required"],
  [ShowingInstructions.ControlledAccess, "Controlled Access"],
  [ShowingInstructions.LeaveBusinessCard, "Leave Business Card"],
  [ShowingInstructions.LockboxBack, "Lockbox Back"],
  [ShowingInstructions.LockboxFront, "Lockbox Front"],
  [ShowingInstructions.LockboxLeftSide, "Lockbox Left Side"],
  [ShowingInstructions.LockboxRightSide, "Lockbox Right Side"],
  [ShowingInstructions.NoAppointmentRequired, "No Appointment Required"],
  [ShowingInstructions.NoSupraKeybox, "No Supra Keybox"],
  [ShowingInstructions.SupraKeybox, "Supra Keybox"],
  [ShowingInstructions.TemporarilyNoShowings, "Temporarily No Showings"]
]);
