import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  BreakfastRoomFeatures,
  KitchenRoomFeatures,
  MasterBedroomFeatures,
  RoomFeatures,
  RoomFeaturesLabel,
  RoomLevel,
  RoomLevelLabel,
  RoomType,
  RoomTypeLabel,
  UtilityRoomFeatures
} from "markets/dfw/enums";

export const roomsRules: {
  fields: FormRules;
  roomFeatures: Record<string, string[]>;
} = {
  fields: {
    roomType: {
      label: "Type",
      options: getOptionsFromEnumAndMap(Object.values(RoomType), RoomTypeLabel)
    },
    level: {
      label: "Level",
      options: getOptionsFromEnumAndMap(
        Object.values(RoomLevel),
        RoomLevelLabel
      ),
      sort: false
    },
    width: {
      label: "Width",
      numberFormat: {
        min: 4
      },
      maxLength: 2
    },
    length: {
      label: "Length",
      numberFormat: {
        min: 4
      },
      maxLength: 2
    },
    features: {
      label: "Features",
      options: getOptionsFromEnumAndMap(
        Object.values(RoomFeatures),
        RoomFeaturesLabel
      )
    }
  },
  roomFeatures: {
    [RoomType.BreakfastRoom]: BreakfastRoomFeatures,
    [RoomType.UtilityRoom]: UtilityRoomFeatures,
    [RoomType.KitchenRoom]: KitchenRoomFeatures,
    [RoomType.MasterBedroom]: MasterBedroomFeatures
  }
};
