import React from "react";

import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  ContingencyInfo,
  MlsStatus,
  ContingencyInfoLabel,
  SaleTerms,
  SaleTermsLabel
} from "markets/abor/enums";
import {
  ClosedDateTooltip,
  PendingDateTooltip,
  EstimatedCloseDateTooltip
} from "core/components";
import { getNextDayDate } from "core/utils/dates";

export const statusFieldLabels = {
  hasBuyerAgent: {
    label: "Represented by Buyer's Agent"
  }
};

export const rulesByStatus = new Map<MlsStatus, FormRules>([
  [MlsStatus.Active, {}],
  [
    MlsStatus.ActiveUnderContract,
    {
      pendingDate: {
        label: "Pending Date",
        requiredToSubmit: true,
        maxDate: new Date()
      },
      closedDate: {
        label: "Option End Date",
        tooltip: <ClosedDateTooltip />,
        minDate: new Date()
      },
      estimatedClosedDate: {
        label: "Estimated Close Date",
        tooltip: <EstimatedCloseDateTooltip />,
        requiredToSubmit: true,
        customFieldProps: {
          errorMessages: {
            minDate: "Estimated Close Date must be after Today's Date"
          }
        },
        minDate: getNextDayDate(new Date())
      },
      hasContingencyInfo: {
        label: "Property Sale Contingency"
      },
      contingencyInfo: {
        label: "Other Contingency Type",
        options: getOptionsFromEnumAndMap(
          Object.values(ContingencyInfo),
          ContingencyInfoLabel
        )
      }
    }
  ],
  [
    MlsStatus.Hold,
    {
      offMarketDate: {
        label: "Hold Date",
        requiredToSubmit: true
      },
      backOnMarketDate: {
        label: "Back On Market Date",
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Cancelled,
    {
      cancelledReason: {
        label: "Cancelled Reason",
        maxLength: 30,
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Pending,
    {
      estimatedClosedDate: {
        label: "Estimated Close Date",
        tooltip: <EstimatedCloseDateTooltip />,
        requiredToSubmit: true,
        customFieldProps: {
          errorMessages: {
            minDate: "Estimated Close Date must be after Today's Date"
          }
        },
        minDate: getNextDayDate(new Date())
      },
      pendingDate: {
        label: "Pending Date",
        requiredToSubmit: true,
        tooltip: <PendingDateTooltip />,
        maxDate: new Date()
      },
      hasContingencyInfo: {
        label: "Property Sale Contingency"
      },
      hasBuyerAgent: {
        label: "Represented by Buyer's Agent"
      },
      agentId: {
        label: "Buyer's Agent"
      }
    }
  ],

  [
    MlsStatus.Closed,
    {
      hasContingencyInfo: {
        label: "Property Sale Contingency"
      },
      pendingDate: {
        label: "Pending Date",
        requiredToSubmit: true,
        maxDate: new Date()
      },
      closedDate: {
        label: "Close Date",
        requiredToSubmit: true,
        tooltip: <ClosedDateTooltip />
      },
      closePrice: {
        label: "Close Price",
        numberFormat: {
          max: 2000000,
          min: 100000,
          prefix: "$",
          thousandSeparator: true
        },
        maxLength: 10,
        requiredToSubmit: true
      },
      hasBuyerAgent: {
        label: "Represented by Buyer's Agent?"
      },
      agentId: {
        label: "Buyer's Agent"
      },
      hasSecondBuyerAgent: {
        label: "Represented by second Buyer's Agent?"
      },
      agentIdSecond: {
        label: "Buyer's Agent 2"
      },

      sellConcess: {
        label: "Buyers Clsg Cost Pdby Sell",
        maxLength: 7,
        numberFormat: {
          min: 0,
          max: 99999,
          prefix: "$",
          thousandSeparator: true
        },
        requiredToSubmit: true
      },
      saleTerms: {
        label: "Sold Terms",
        options: getOptionsFromEnumAndMap(
          Object.values(SaleTerms),
          SaleTermsLabel
        ),
        requiredToSubmit: true
      }
    }
  ]
]);
