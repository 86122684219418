export enum CancelledOption {
  ResetDom = "resetDom",
  NotBuildingThisLot = "notBuildingThisLot",
  BuildingAnotherLot = "buildingAnotherLot",
  Repairs = "repairs",
  ChangingPlans = "changingPlans",
  SomethingTragic = "somethingTragic",
  ListingWithRealtor = "listingWithRealtor",
  Other = "other"
}

export const CancelledOptionsLabel = new Map<CancelledOption, string>([
  [CancelledOption.BuildingAnotherLot, "Building on another lot"],
  [CancelledOption.NotBuildingThisLot, "Not building on this lot"],
  [CancelledOption.Repairs, "Repairs"],
  [CancelledOption.ChangingPlans, "Changing Plans"],
  [CancelledOption.ResetDom, "Reset DOM"],
  [
    CancelledOption.SomethingTragic,
    "Something tragic like flooding, stolen appliances, etc."
  ],
  [CancelledOption.ListingWithRealtor, "Listing with Realtor"],
  [CancelledOption.Other, "Other"]
]);
