export enum MlsArea {
  Bivins = "bivins",
  SunsetWestlawn = "sunsetWestlawn",
  CountryClubAvondale = "countryClubAvondale",
  Owners = "owners",
  RidgeviewMedicalCenter = "ridgeviewMedicalCenter",
  WildHorseLake = "wildHorseLake",
  SanJacinto = "sanJacinto",
  WestHills = "westHills",
  QuailCreek = "quailCreek",
  NorthHeights = "northHeights",
  TascosaLaPaloma = "tascosaLaPaloma",
  Westcliff = "westcliff",
  ThompsonPark = "thompsonPark",
  Broadway = "broadway",
  Forest = "forest",
  TheWoodlands = "theWoodlands",
  PleasantValleyWest = "pleasantValleyWest",
  TheVineyards = "theVineyards",
  RiverRoadWest = "riverRoadWest",
  OliverEakleAC = "oliverEakleAC",
  Wolflin = "wolflin",
  LawrencePark = "lawrencePark",
  Olsen = "olsen",
  Belmar = "belmar",
  PuckettWest = "puckettWest",
  HomeDepot = "homeDepot",
  ParamountMays = "paramountMays",
  Ridgecrest = "ridgecrest",
  Puckett = "puckett",
  SleepyHollow = "sleepyHollow",
  Sakura = "sakura",
  WesternPlateau = "westernPlateau",
  CityPark = "cityPark",
  Colonies = "colonies",
  TownSquareTimeSquare = "townSquareTimeSquare",
  Glendale = "glendale",
  SouthparkWindsor = "southparkWindsor",
  Estacado = "estacado",
  Greenways = "greenways",
  HillsideTerrace = "hillsideTerrace",
  Westover = "westover",
  WestoverVillage = "westoverVillage",
  HeritageHills = "heritageHills",
  SevenTheater = "sevenTheater",
  SantaFeStation = "santaFeStation",
  Fairgrounds = "fairgrounds",
  Sunrise = "sunrise",
  BigTexanNorth = "bigTexanNorth",
  AmarilloAirport = "amarilloAirport",
  Stockyards = "stockyards",
  MartinRoad = "martinRoad",
  NorthAmarillo = "northAmarillo",
  Eastridge = "eastridge",
  WhitakerEast = "whitakerEast",
  FolsomAcres = "folsomAcres",
  Hamlet = "hamlet",
  MesaVerde = "mesaVerde",
  ParkTerrace = "parkTerrace",
  PleasantValleyEast = "pleasantValleyEast",
  RiverRoadEast = "riverRoadEast",
  RossPostOffice = "rossPostOffice",
  Lawndale = "lawndale",
  Oakdale = "oakdale",
  BigTexanSouth = "bigTexanSouth",
  SouthlawnNorth = "southlawnNorth",
  Gables = "gables",
  Tradewinds = "tradewinds",
  WindmillAcres = "windmillAcres",
  SouthGeorgia = "southGeorgia",
  SouthlawnSouth = "southlawnSouth",
  TradewindSquare = "tradewindSquare",
  HollywoodScotsman = "hollywoodScotsman",
  WesternBusinessPark = "westernBusinesSpark",
  FoxridgePheasantRun = "foxridgePheasantRun",
  SaturnTerrace = "saturnTerrace",
  CityView = "cityView",
  Pinnacle = "pinnacle",
  CanyonAllOther = "canyonAllOther",
  HunsleyHills = "hunsleyHills",
  Westgate = "westgate",
  HiddenValOrigTown = "hiddenValOrigTown",
  WTAMU = "wTAMU",
  AllOtherAreasInCanyon = "allOtherAreasInCanyon",
  CanyonEast = "canyonEast",
  MadisonPark = "madisonPark",
  SpringCanyon = "springCanyon",
  WalnutHills = "walnutHills",
  IndianHills = "indianHills",
  EagleTree = "eagleTree",
  Tascocita = "tascocita",
  BushlandNorth = "bushlandNorth",
  TwoDeerPark = "twoDeerPark",
  MesquiteAcres = "mesquiteAcres",
  RiverRoadWestCountry = "riverRoadWestCountry",
  RollingHills = "rollingHills",
  WestSideGardens = "westSideGardens",
  BroadwayAcres = "broadwayAcres",
  AppaloosaHills = "appaloosaHills",
  ChaparralHills = "chaparralHills",
  Cliffside = "cliffside",
  BishopHills = "bishopHills",
  BishopEstates = "bishopEstates",
  TascosaHills = "tascosaHills",
  TierraGrande = "tierraGrande",
  ValleDeOro = "valleDeOro",
  AllOthersNotIdentified = "allOthersNotIdentified",
  ZeroWestAcres = "zeroWestAcres",
  CoulterAcres = "coulterAcres",
  BushlandSouth = "bushlandSouth",
  Christy = "christy",
  GreyHawk = "greyHawk",
  BoatwrightTrew = "boatwrightTrew",
  ShermanAcresBackAcres = "shermanAcresBackAcres",
  CountryWest = "countryWest",
  LasCasas4CornersEast = "lasCasasFourCornersEast",
  StrawberryFields = "strawberryFields",
  HighlandSprings = "highlandSprings",
  FourCornersWildflowerMapleFields = "fourCornersWildflowerMapleFields",
  LakeRidgeSpringLake = "lakeRidgeSpringLake",
  DoveLanding = "doveLanding",
  DovePrairieEaglePoint = "dovePrairieEaglePoint",
  HuntersEstates = "huntersEstates",
  Umbarger = "umbarger",
  CanyonNorth = "canyonNorth",
  CanyonCountryClub = "canyonCountryClub",
  AllOthersNotIdentified2999 = "allOthersNotIdentified2999",
  ARGlenn = "aRGlenn",
  NorthLoopVillage = "northLoopVillage",
  RiverRoadGardens = "riverRoadGardens",
  MesillaPark = "mesillaPark",
  GoldenAcres = "goldenAcres",
  AllOthersNotIdentified3999 = "allOthersNotIdentified3999",
  MerridaCrestview = "merridaCrestView",
  GrandAveEstates = "grandAveEstates",
  LonesomeDove = "lonesomeDove",
  WesternEstates = "westernEstates",
  CanyonRidge = "canyonRidge",
  LakesideEstates = "lakesideEstates",
  SouthFork = "southFork",
  SundownAcres = "sundownAcres",
  HolidaySouth = "holidaySouth",
  OsageAcres = "osageAcres",
  SouthlandAcres = "southlandAcres",
  McMurtryAcres = "mcMurtryAcres",
  GreenAcreFarms = "greenAcreFarms",
  RanchAcresSouth = "ranchAcresSouth",
  PlantationFarms = "plantationFarms",
  TangleaireEstates = "tangleaireEstates",
  MescaleroPark = "mescaleroPark",
  TimbercreekCanyon = "timbercreekCanyon",
  Palisades = "palisades",
  RichlandAcres = "richlandAcres",
  LakeTanglewood = "lakeTanglewood",
  RiverFalls = "riverFalls",
  PullmanAcres = "pullmanAcres",
  RockwellAcres = "rockwellAcres",
  WhiteFenceFarms = "whiteFenceFarms",
  PaloDuro = "paloDuro",
  SendoraValley = "sendoraValley",
  DeerRidgeSmokyHollow = "deerRidgeSmokyHollow",
  TheCanyons = "theCanyons",
  SundayCanyon = "sundayCanyon",
  AllOthersNotIdentified4999 = "allOthersNotIdentified4999",
  NandWofPotterCounty = "nandWofPotterCounty",
  Dalhart = "dalhart",
  Dumas = "dumas",
  Sunray = "sunray",
  Vega = "vega",
  SandWofRandall = "sandWofRandall",
  Dimmitt = "dimmitt",
  Friona = "friona",
  Hereford = "hereford",
  Muleshoe = "muleshoe",
  Happy = "happy",
  Wildorado = "wildorado",
  NandEofPotterCounty = "nandEofPotterCounty",
  Borger = "borger",
  Canadian = "canadian",
  Fritch = "fritch",
  Gruver = "gruver",
  Pampa = "pampa",
  Panhandle = "panhandle",
  Perryton = "perryton",
  Spearman = "spearman",
  Stinnett = "stinnett",
  Wheeler = "wheeler",
  SandEofRandallCounty = "sandEofRandallCounty",
  Childress = "childress",
  Clarendon = "clarendon",
  Claude = "claude",
  Memphis = "memphis",
  Plainview = "plainview",
  Shamrock = "shamrock",
  Tulia = "tulia",
  Wellington = "wellington",
  Paducah = "paducah",
  Turkey = "turkey",
  Quanah = "quanah",
  Lubbock = "lubbock",
  NewMexico = "newmexico",
  Oklahoma = "oklahoma",
  AllOther = "allOther"
}

export const MlsAreaLabel: Record<MlsArea, string> = {
  [MlsArea.Bivins]: "0101 - Bivins",
  [MlsArea.SunsetWestlawn]: "0103 - Sunset/Westlawn",
  [MlsArea.CountryClubAvondale]: "0105 - Country Club/Avondale",
  [MlsArea.Owners]: "0106 - Owners",
  [MlsArea.RidgeviewMedicalCenter]: "0107 - Ridgeview Medical Center",
  [MlsArea.WildHorseLake]: "0110 - Wild Horse Lake",
  [MlsArea.SanJacinto]: "0111 - San Jacinto",
  [MlsArea.WestHills]: "0112 - West Hills",
  [MlsArea.QuailCreek]: "0113 - Quail Creek",
  [MlsArea.NorthHeights]: "0120 - North Heights",
  [MlsArea.TascosaLaPaloma]: "0122 - Tascosa/La Paloma",
  [MlsArea.Westcliff]: "0123 - Westcliff",
  [MlsArea.ThompsonPark]: "0130 - Thompson Park",
  [MlsArea.Broadway]: "0131 - Broadway",
  [MlsArea.Forest]: "0132 - Forest",
  [MlsArea.TheWoodlands]: "0133 - The Woodlands",
  [MlsArea.PleasantValleyWest]: "0140 - Pleasant Valley West",
  [MlsArea.TheVineyards]: "0141 - The Vineyards",
  [MlsArea.RiverRoadWest]: "0150 - River Road West",
  [MlsArea.OliverEakleAC]: "0200 - Oliver Eakle/AC",
  [MlsArea.Wolflin]: "0201 - Wolflin",
  [MlsArea.LawrencePark]: "0202 - Lawrence Park",
  [MlsArea.Olsen]: "0203 - Olsen",
  [MlsArea.Belmar]: "0204 - Belmar",
  [MlsArea.PuckettWest]: "0205 - Puckett West",
  [MlsArea.HomeDepot]: "0206 - Home Depot",
  [MlsArea.ParamountMays]: "0210 - Paramount/Mays",
  [MlsArea.Ridgecrest]: "0211 - Ridgecrest",
  [MlsArea.Puckett]: "0212 - Puckett",
  [MlsArea.SleepyHollow]: "0213 - Sleepy Hollow",
  [MlsArea.Sakura]: "0214 - Sakura",
  [MlsArea.WesternPlateau]: "0220 - Western Plateau",
  [MlsArea.CityPark]: "0221 - City Park",
  [MlsArea.Colonies]: "0222 - Colonies",
  [MlsArea.TownSquareTimeSquare]: "0223 - Town Square/Time Square",
  [MlsArea.Glendale]: "0230 - Glendale",
  [MlsArea.SouthparkWindsor]: "0231 - Southpark/Windsor",
  [MlsArea.Estacado]: "0233 - Estacado",
  [MlsArea.Greenways]: "0234 - Greenways",
  [MlsArea.HillsideTerrace]: "0235 - Hillside Terrace",
  [MlsArea.Westover]: "0240 - Westover",
  [MlsArea.WestoverVillage]: "0241 - Westover Village",
  [MlsArea.HeritageHills]: "0243 - Heritage Hills",
  [MlsArea.SevenTheater]: "0250 - I-27 Theater",
  [MlsArea.SantaFeStation]: "0300 - Santa Fe Station",
  [MlsArea.Fairgrounds]: "0301 - Fairgrounds",
  [MlsArea.Sunrise]: "0302 - Sunrise",
  [MlsArea.BigTexanNorth]: "0303 - Big Texan North",
  [MlsArea.AmarilloAirport]: "0304 - Amarillo Airport",
  [MlsArea.Stockyards]: "0310 - Stockyards",
  [MlsArea.MartinRoad]: "0320 - Martin Road",
  [MlsArea.NorthAmarillo]: "0321 - North Amarillo",
  [MlsArea.Eastridge]: "0322 - Eastridge",
  [MlsArea.WhitakerEast]: "0323 - Whitaker East",
  [MlsArea.FolsomAcres]: "0324 - Folsom Acres",
  [MlsArea.Hamlet]: "0330 - Hamlet",
  [MlsArea.MesaVerde]: "0331 - Mesa Verde",
  [MlsArea.ParkTerrace]: "0332 - Park Terrace",
  [MlsArea.PleasantValleyEast]: "0340 - Pleasant Valley East",
  [MlsArea.RiverRoadEast]: "0350 - River Road East",
  [MlsArea.RossPostOffice]: "0400 - Ross Post Office",
  [MlsArea.Lawndale]: "0401 - Lawndale",
  [MlsArea.Oakdale]: "0402 - Oakdale",
  [MlsArea.BigTexanSouth]: "0403 - Big Texan South",
  [MlsArea.SouthlawnNorth]: "0411 - Southlawn North",
  [MlsArea.Gables]: "0412 - Gables",
  [MlsArea.Tradewinds]: "0413 - Tradewinds",
  [MlsArea.WindmillAcres]: "0414 - Windmill Acres",
  [MlsArea.SouthGeorgia]: "0420 - South Georgia",
  [MlsArea.SouthlawnSouth]: "0421 - Southlawn South",
  [MlsArea.TradewindSquare]: "0422 - Tradewind Square",
  [MlsArea.HollywoodScotsman]: "0430 - Hollywood/Scotsman",
  [MlsArea.WesternBusinessPark]: "0431 - Western Business Park",
  [MlsArea.FoxridgePheasantRun]: "0433 - Foxridge/Pheasant Run",
  [MlsArea.SaturnTerrace]: "0435 - Saturn Terrace",
  [MlsArea.CityView]: "0441 - City View",
  [MlsArea.Pinnacle]: "0450 - Pinnacle",
  [MlsArea.CanyonAllOther]: "0900 - Canyon All Other",
  [MlsArea.HunsleyHills]: "0901 - Hunsley Hills",
  [MlsArea.Westgate]: "0902 - Westgate",
  [MlsArea.HiddenValOrigTown]: "0903 - Hidden Val/Orig Town",
  [MlsArea.WTAMU]: "0904 - WTAMU",
  [MlsArea.AllOtherAreasInCanyon]: "0905 - All Other Areas in Canyon",
  [MlsArea.CanyonEast]: "0906 - Canyon East",
  [MlsArea.MadisonPark]: "0910 - Madison Park",
  [MlsArea.SpringCanyon]: "0915 - Spring Canyon",
  [MlsArea.WalnutHills]: "1004 - Walnut Hills",
  [MlsArea.IndianHills]: "1028 - Indian Hills",
  [MlsArea.EagleTree]: "1037 - Eagle Tree",
  [MlsArea.Tascocita]: "1039 - Tascocita",
  [MlsArea.BushlandNorth]: "1054 - Bushland North",
  [MlsArea.TwoDeerPark]: "1065 - Two Deer Park",
  [MlsArea.MesquiteAcres]: "1107 - Mesquite Acres",
  [MlsArea.RiverRoadWestCountry]: "1109 - River Road West/Country",
  [MlsArea.RollingHills]: "1111 - Rolling Hills",
  [MlsArea.WestSideGardens]: "1112 - West Side Gardens",
  [MlsArea.BroadwayAcres]: "1113 - Broadway Acres",
  [MlsArea.AppaloosaHills]: "1118 - Appaloosa Hills",
  [MlsArea.ChaparralHills]: "1119 - Chaparral Hills",
  [MlsArea.Cliffside]: "1125 - Cliffside",
  [MlsArea.BishopHills]: "1127 - Bishop Hills",
  [MlsArea.BishopEstates]: "1129 - Bishop Estates",
  [MlsArea.TascosaHills]: "1137 - Tascosa Hills",
  [MlsArea.TierraGrande]: "1145 - Tierra Grande",
  [MlsArea.ValleDeOro]: "1396 - Valle De Oro",
  [MlsArea.AllOthersNotIdentified]: "1999 - All Others Not Identified",
  [MlsArea.ZeroWestAcres]: "2026 - I-40 West Acres",
  [MlsArea.CoulterAcres]: "2039 - Coulter Acres",
  [MlsArea.BushlandSouth]: "2054 - Bushland South",
  [MlsArea.Christy]: "2070 - Christy",
  [MlsArea.GreyHawk]: "2127 - Grey Hawk",
  [MlsArea.BoatwrightTrew]: "2133 - Boatwright Trew",
  [MlsArea.ShermanAcresBackAcres]: "2141 - Sherman Acres/Back Acres",
  [MlsArea.CountryWest]: "2142 - Country West",
  [MlsArea.LasCasas4CornersEast]: "2151 - Las Casas/4 Corners East",
  [MlsArea.StrawberryFields]: "2162 - Strawberry Fields",
  [MlsArea.HighlandSprings]: "2170 - Highland Springs",
  [MlsArea.FourCornersWildflowerMapleFields]:
    "2172 - 4 Corners/Wildflower/Maple Fields",
  [MlsArea.LakeRidgeSpringLake]: "2224 - Lake Ridge/Spring Lake",
  [MlsArea.DoveLanding]: "2227 - Dove Landing",
  [MlsArea.DovePrairieEaglePoint]: "2230 - Dove Prairie/Eagle Point",
  [MlsArea.HuntersEstates]: "2335 - Hunters Estates",
  [MlsArea.Umbarger]: "2380 - Umbarger",
  [MlsArea.CanyonNorth]: "2436 - Canyon North",
  [MlsArea.CanyonCountryClub]: "2439 - Canyon Country Club",
  [MlsArea.AllOthersNotIdentified2999]: "2999 - All Others Not Identified",
  [MlsArea.ARGlenn]: "3104 - A. R. Glenn",
  [MlsArea.NorthLoopVillage]: "3105 - North Loop Village",
  [MlsArea.RiverRoadGardens]: "3123 - River Road Gardens",
  [MlsArea.MesillaPark]: "3136 - Mesilla Park",
  [MlsArea.GoldenAcres]: "3165 - Golden Acres",
  [MlsArea.AllOthersNotIdentified3999]: "3999 - All Others Not Identified",
  [MlsArea.MerridaCrestview]: "4038 - Merrida/Crestview",
  [MlsArea.GrandAveEstates]: "4049 - Grand Ave Estates",
  [MlsArea.LonesomeDove]: "4051 - Lonesome Dove",
  [MlsArea.WesternEstates]: "4114 - Western Estates",
  [MlsArea.CanyonRidge]: "4118 - Canyon Ridge",
  [MlsArea.LakesideEstates]: "4119 - Lakeside Estates",
  [MlsArea.SouthFork]: "4128 - South Fork",
  [MlsArea.SundownAcres]: "4130 - Sundown Acres",
  [MlsArea.HolidaySouth]: "4132 - Holiday South",
  [MlsArea.OsageAcres]: "4133 - Osage Acres",
  [MlsArea.SouthlandAcres]: "4134 - Southland Acres",
  [MlsArea.McMurtryAcres]: "4135 - McMurtry Acres",
  [MlsArea.GreenAcreFarms]: "4136 - Green Acre Farms",
  [MlsArea.RanchAcresSouth]: "4139 - Ranch Acres South",
  [MlsArea.PlantationFarms]: "4142 - Plantation Farms",
  [MlsArea.TangleaireEstates]: "4144 - Tangleaire Estates",
  [MlsArea.MescaleroPark]: "4145 - Mescalero Park",
  [MlsArea.TimbercreekCanyon]: "4148 - Timbercreek Canyon",
  [MlsArea.Palisades]: "4149 - Palisades",
  [MlsArea.RichlandAcres]: "4160 - Richland Acres",
  [MlsArea.LakeTanglewood]: "4165 - Lake Tanglewood",
  [MlsArea.RiverFalls]: "4170 - River Falls",
  [MlsArea.PullmanAcres]: "4180 - Pullman Acres",
  [MlsArea.RockwellAcres]: "4203 - Rockwell Acres",
  [MlsArea.WhiteFenceFarms]: "4209 - White Fence Farms",
  [MlsArea.PaloDuro]: "4230 - Palo Duro",
  [MlsArea.SendoraValley]: "4235 - Sendora Valley",
  [MlsArea.DeerRidgeSmokyHollow]: "4238 - Deer Ridge/Smoky Hollow",
  [MlsArea.TheCanyons]: "4255 - The Canyons",
  [MlsArea.SundayCanyon]: "4273 - Sunday Canyon",
  [MlsArea.AllOthersNotIdentified4999]: "4999 - All Others Not Identified",
  [MlsArea.NandWofPotterCounty]: "5000 - N & W of Potter County",
  [MlsArea.Dalhart]: "5001 - Dalhart",
  [MlsArea.Dumas]: "5010 - Dumas",
  [MlsArea.Sunray]: "5020 - Sunray",
  [MlsArea.Vega]: "5040 - Vega",
  [MlsArea.SandWofRandall]: "6000 - S & W of Randall",
  [MlsArea.Dimmitt]: "6001 - Dimmitt",
  [MlsArea.Friona]: "6010 - Friona",
  [MlsArea.Hereford]: "6020 - Hereford",
  [MlsArea.Muleshoe]: "6030 - Muleshoe",
  [MlsArea.Happy]: "6040 - Happy",
  [MlsArea.Wildorado]: "6050 - Wildorado",
  [MlsArea.NandEofPotterCounty]: "7000 - N & E of Potter County",
  [MlsArea.Borger]: "7001 - Borger",
  [MlsArea.Canadian]: "7010 - Canadian",
  [MlsArea.Fritch]: "7015 - Fritch",
  [MlsArea.Gruver]: "7017 - Gruver",
  [MlsArea.Pampa]: "7220 - Pampa",
  [MlsArea.Panhandle]: "7225 - Panhandle",
  [MlsArea.Perryton]: "7230 - Perryton",
  [MlsArea.Spearman]: "7235 - Spearman",
  [MlsArea.Stinnett]: "7240 - Stinnett",
  [MlsArea.Wheeler]: "7250 - Wheeler",
  [MlsArea.SandEofRandallCounty]: "8000 - S & E of Randall County",
  [MlsArea.Childress]: "8001 - Childress",
  [MlsArea.Clarendon]: "8010 - Clarendon",
  [MlsArea.Claude]: "8012 - Claude",
  [MlsArea.Memphis]: "8020 - Memphis",
  [MlsArea.Plainview]: "8030 - Plainview",
  [MlsArea.Shamrock]: "8040 - Shamrock",
  [MlsArea.Tulia]: "8050 - Tulia",
  [MlsArea.Wellington]: "8060 - Wellington",
  [MlsArea.Paducah]: "8070 - Paducah",
  [MlsArea.Turkey]: "8080 - Turkey",
  [MlsArea.Quanah]: "8090 - Quanah",
  [MlsArea.Lubbock]: "8100 - Lubbock",
  [MlsArea.NewMexico]: "9001 - New Mexico",
  [MlsArea.Oklahoma]: "9100 - Oklahoma",
  [MlsArea.AllOther]: "9999 - All Other"
};
