import { FormRules } from "@homesusa/form";

import { openHousesRules, showingRules } from "../../sections";

export const showingInfoRules: FormRules = {
  ...openHousesRules,
  ...showingRules,
  agentPrivateRemarks: {
    label: "Agent Remarks",
    maxLength: 1000,
    disabled: true,
    rows: 5
  }
};
