import React from "react";
import { Box } from "@mui/material";
import { AlertSummaryProvider } from "modules/dashboard/context";
import { AlertGrid } from "./alert-grid.component";
import { AlertType } from "modules/dashboard/enums";

export const RightPanel = (): JSX.Element => {
  return (
    <Box sx={{ height: "600px" }}>
      <AlertSummaryProvider>
        <AlertGrid alertType={AlertType.XmlHomesToListNow} />
      </AlertSummaryProvider>
      <AlertSummaryProvider>
        <AlertGrid isSuggestedComps alertType={AlertType.XmlSuggestedComps} />
      </AlertSummaryProvider>
    </Box>
  );
};
