export enum SpecialtyRooms {
  AccessOnly = "accessOnly",
  AtticFan = "atticFan",
  Floored = "floored",
  FlooredPartially = "flooredPartially",
  PermanentStairs = "permanentStairs",
  PullDownStairs = "pullDownStairs",
  RadiantBarrier = "radiantBarrier",
  StorageOnly = "storageOnly",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const SpecialtyRoomsLabel = new Map<SpecialtyRooms, string>([
  [SpecialtyRooms.AccessOnly, "Access Only"],
  [SpecialtyRooms.AtticFan, "Attic Fan"],
  [SpecialtyRooms.Floored, "Floored"],
  [SpecialtyRooms.FlooredPartially, "Floored-Partially "],
  [SpecialtyRooms.PermanentStairs, "Permanent Stairs"],
  [SpecialtyRooms.PullDownStairs, "Pull Down Stairs"],
  [SpecialtyRooms.RadiantBarrier, "Radiant Barrier"],
  [SpecialtyRooms.StorageOnly, "Storage Only"],
  [SpecialtyRooms.None, "None"],
  [SpecialtyRooms.OtherSeeRemarks, "Other-See Remarks"]
]);
