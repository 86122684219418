export enum ElementarySchools {
  ArdenRoad = "ardenRoad",
  Avondale = "avondale",
  Belmar = "belmar",
  Bivins = "bivins",
  Borger = "borger",
  Bushland = "bushland",
  Cactus = "cactus",
  CityView = "cityView",
  Claude = "claude",
  Coronado = "coronado",
  Crestview = "crestview",
  Crockett = "crockett",
  DumasGreenAcres = "dumasGreenAcres",
  DumasMorningside = "dumasMorningSide",
  DumasNorth = "dumasNorth",
  DumasSunset = "dumasSunset",
  Eastridge = "eastridge",
  Emerson = "emerson",
  ForestHill = "forestHill",
  Gateway = "gateway",
  GeneHowe = "geneHowe",
  Glenwood = "glenWood",
  GreenAcres = "greenAcres",
  Hamlet = "hamlet",
  Happy = "happy",
  HeritageHills = "heritageHills",
  HighlandPark = "highlandPark",
  Hillcrest = "hillcrest",
  Hillside = "hillside",
  Hilltop = "hilltop",
  HumphreysHilnd = "humphreysHilnd",
  Lakeview = "lakeview",
  Lamar = "lamar",
  Landergin = "landergin",
  Lawndale = "lawndale",
  MesaVerde = "mesaVerde",
  Morningside = "morningside",
  NorthHeights = "northHeights",
  OakDale = "oakDale",
  OlsenPark = "olsenPark",
  Other = "other",
  Panhandle = "panhandle",
  ParamountTerrace = "paramountTerrace",
  ParkHills = "parkHills",
  aulBelton = "aulBelton",
  PleasantValley = "pleasantValley",
  Puckett = "puckett",
  ReevesHinger = "reevesHinger",
  Ridgecrest = "ridgecrest",
  Rogers = "rogers",
  RollingHills = "rollingHills",
  SanJacinto = "sanJacinto",
  Sanborn = "sanborn",
  SleepyHollow = "sleepyHollow",
  SouthGeorgia = "southGeorgia",
  Southlawn = "southlawn",
  SpringCanyon = "springCanyon",
  Sundown = "sundown",
  Sunrise = "sunrise",
  Sunset = "sunset",
  Tradewind = "tradewind",
  Vega = "vega",
  Westcliff = "westcliff",
  WesternPlateau = "westernPlateau",
  Whittier = "whittier",
  ildorado = "ildorado",
  Wills = "wills",
  Windsor = "windsor",
  Wolflin = "wolflin",
  Woodlands = "woodlands",
  Wright = "wright"
}

export const ElementarySchoolsLabel: Record<ElementarySchools, string> = {
  [ElementarySchools.ArdenRoad]: "Arden Road",
  [ElementarySchools.Avondale]: "Avondale",
  [ElementarySchools.Belmar]: "Belmar",
  [ElementarySchools.Bivins]: "Bivins",
  [ElementarySchools.Borger]: "Borger",
  [ElementarySchools.Bushland]: "Bushland",
  [ElementarySchools.Cactus]: "Cactus",
  [ElementarySchools.CityView]: "City View",
  [ElementarySchools.Claude]: "Claude",
  [ElementarySchools.Coronado]: "Coronado",
  [ElementarySchools.Crestview]: "Crestview",
  [ElementarySchools.Crockett]: "Crockett ",
  [ElementarySchools.DumasGreenAcres]: "Dumas Green Acres",
  [ElementarySchools.DumasMorningside]: "Dumas Morningside",
  [ElementarySchools.DumasNorth]: "Dumas North",
  [ElementarySchools.DumasSunset]: "Dumas Sunset",
  [ElementarySchools.Eastridge]: "Eastridge",
  [ElementarySchools.Emerson]: "Emerson",
  [ElementarySchools.ForestHill]: "Forest Hill",
  [ElementarySchools.Gateway]: "Gateway ",
  [ElementarySchools.GeneHowe]: "Gene Howe",
  [ElementarySchools.Glenwood]: "Glenwood",
  [ElementarySchools.GreenAcres]: "Green Acres",
  [ElementarySchools.Hamlet]: "Hamlet",
  [ElementarySchools.Happy]: "Happy",
  [ElementarySchools.HeritageHills]: "Heritage Hills",
  [ElementarySchools.HighlandPark]: "Highland Park",
  [ElementarySchools.Hillcrest]: "Hillcrest",
  [ElementarySchools.Hillside]: "Hillside",
  [ElementarySchools.Hilltop]: "Hilltop",
  [ElementarySchools.HumphreysHilnd]: "Humphreys Hilnd",
  [ElementarySchools.Lakeview]: "Lakeview",
  [ElementarySchools.Lamar]: "Lamar",
  [ElementarySchools.Landergin]: "Landergin",
  [ElementarySchools.Lawndale]: "Lawndale",
  [ElementarySchools.MesaVerde]: "Mesa Verde",
  [ElementarySchools.Morningside]: "Morningside",
  [ElementarySchools.NorthHeights]: "North Heights",
  [ElementarySchools.OakDale]: "Oak Dale",
  [ElementarySchools.OlsenPark]: "Olsen Park",
  [ElementarySchools.Other]: "Other",
  [ElementarySchools.Panhandle]: "Panhandle",
  [ElementarySchools.ParamountTerrace]: "Paramount Terrace",
  [ElementarySchools.ParkHills]: "Park Hills",
  [ElementarySchools.aulBelton]: "aul Belton",
  [ElementarySchools.PleasantValley]: "Pleasant Valley",
  [ElementarySchools.Puckett]: "Puckett",
  [ElementarySchools.ReevesHinger]: "Reeves-Hinger",
  [ElementarySchools.Ridgecrest]: "Ridgecrest",
  [ElementarySchools.Rogers]: "Rogers",
  [ElementarySchools.RollingHills]: "Rolling Hills",
  [ElementarySchools.SanJacinto]: "San Jacinto",
  [ElementarySchools.Sanborn]: "Sanborn",
  [ElementarySchools.SleepyHollow]: "Sleepy Hollow",
  [ElementarySchools.SouthGeorgia]: "South Georgia",
  [ElementarySchools.Southlawn]: "Southlawn",
  [ElementarySchools.SpringCanyon]: "Spring Canyon ",
  [ElementarySchools.Sundown]: "Sundown",
  [ElementarySchools.Sunrise]: "Sunrise",
  [ElementarySchools.Sunset]: "Sunset",
  [ElementarySchools.Tradewind]: "Tradewind",
  [ElementarySchools.Vega]: "Vega",
  [ElementarySchools.Westcliff]: "Westcliff",
  [ElementarySchools.WesternPlateau]: "Western Plateau",
  [ElementarySchools.Whittier]: "Whittier",
  [ElementarySchools.ildorado]: "ildorado",
  [ElementarySchools.Wills]: "Wills",
  [ElementarySchools.Windsor]: "Windsor",
  [ElementarySchools.Wolflin]: "Wolflin",
  [ElementarySchools.Woodlands]: "Woodlands",
  [ElementarySchools.Wright]: "Wright "
};
