export enum InteriorFeatures {
  Basement = "basement",
  Bonus = "bonus",
  ButlersPantry = "butlersPantry",
  Den = "den",
  DiningRoomFormal = "diningRoomFormal",
  DiningRoomKitCm = "diningRoomKitCm",
  DiningRoomLivCm = "diningRoomLivCm",
  Exercise = "exercise",
  GreatRoom = "greatRoom",
  IsolatedMaster = "isolatedMaster",
  Library = "library",
  LivingAreas = "livingAreas",
  Loft = "loft",
  Media = "media",
  MudRoom = "mudRoom",
  OfficeStudy = "officeStudy",
  Pantry = "pantry",
  PetRoom = "petRoom",
  SafeRoom = "safeRoom",
  SunRoom = "sunRoom",
  Utility = "utility",
  WineRoom = "wineRoom",
  Workshop = "workshop",
  Other = "other",
  SunkenLivingRoom = "sunkenLivingRoom"
}

export const InteriorFeaturesLabel = new Map<InteriorFeatures, string>([
  [InteriorFeatures.Basement, "Basement"],
  [InteriorFeatures.Bonus, "Bonus"],
  [InteriorFeatures.ButlersPantry, "Butlers Pantry"],
  [InteriorFeatures.Den, "Den"],
  [InteriorFeatures.DiningRoomFormal, "Dining Room - Formal"],
  [InteriorFeatures.DiningRoomKitCm, "Dining Room - Kit Cm"],
  [InteriorFeatures.DiningRoomLivCm, "Dining Room - Liv Cm"],
  [InteriorFeatures.Exercise, "Exercise"],
  [InteriorFeatures.GreatRoom, "Great Room"],
  [InteriorFeatures.IsolatedMaster, "Isolated Master"],
  [InteriorFeatures.Library, "Library"],
  [InteriorFeatures.LivingAreas, "Living Areas"],
  [InteriorFeatures.Loft, "Loft"],
  [InteriorFeatures.Media, "Media"],
  [InteriorFeatures.MudRoom, "Mud Room"],
  [InteriorFeatures.OfficeStudy, "Office/Study"],
  [InteriorFeatures.Pantry, "Pantry"],
  [InteriorFeatures.PetRoom, "Pet Room"],
  [InteriorFeatures.SafeRoom, "Safe Room"],
  [InteriorFeatures.SunRoom, "Sun Room"],
  [InteriorFeatures.Utility, "Utility"],
  [InteriorFeatures.WineRoom, "Wine Room"],
  [InteriorFeatures.Workshop, "Workshop"],
  [InteriorFeatures.Other, "Other"],
  [InteriorFeatures.SunkenLivingRoom, "Sunken Living Room"]
]);
