import {
  DfwCities,
  DfwCitiesLabel,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { County, CountyLabel } from "markets/dfw/enums";

export const getDfwCityLabel = (city: string | DfwCities): string =>
  DfwCitiesLabel[city as DfwCities] ?? city;

export const dfwCityRule: FieldRulesProps = {
  label: "City",
  options: getOptionsFromEnumAndRecord(Object.values(DfwCities), DfwCitiesLabel)
};

export const countyRules: FieldRulesProps = {
  label: "County",
  options: getOptionsFromEnumAndRecord(Object.values(County), CountyLabel),
  required: false
};
