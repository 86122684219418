import React from "react";
import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  CancelledOption,
  CancelledOptionsLabel,
  MlsStatus,
  SoldTerms,
  SoldTermsLabel
} from "markets/amarillo/enums";
import { ClosedDateTooltip, ContractDateTooltip } from "core/components";
import { getNextDayDate, getYesterday } from "core/utils/dates";

const buyerAgentRules = {
  hasBuyerAgent: {
    label: "Represented by Buyer's Agent"
  },
  hasSecondBuyerAgent: {
    label: "Represented by second Buyer's Agent"
  }
};

export const summaryStatusRules = buyerAgentRules;

export const rulesByStatus = new Map<MlsStatus, FormRules>([
  [
    MlsStatus.Pending,
    {
      estimatedClosedDate: {
        label: "Estimated Close Date",
        customFieldProps: {
          errorMessages: {
            minDate: "Estimated Close Date must be after Today's Date"
          }
        },
        minDate: getNextDayDate(new Date()),
        tooltip: <ClosedDateTooltip />,
        requiredToSubmit: true
      },
      pendingDate: {
        label: "Under Contract Date",
        tooltip: <ContractDateTooltip />,
        maxDate: new Date(),
        requiredToSubmit: true
      },
      hasBuyerAgent: buyerAgentRules.hasBuyerAgent,
      agentId: {
        label: "Buyer's Agent"
      }
    }
  ],
  [
    MlsStatus.UnderContractWContingency,
    {
      estimatedClosedDate: {
        label: "Estimated Close Date",
        customFieldProps: {
          errorMessages: {
            minDate: "Estimated Close Date must be after Today's Date"
          }
        },
        minDate: getNextDayDate(new Date()),
        tooltip: <ClosedDateTooltip />,
        requiredToSubmit: true
      },
      contractDate: {
        label: "Under Contract Date",
        tooltip: <ContractDateTooltip />,
        maxDate: new Date(),
        requiredToSubmit: true
      },
      hasBuyerAgent: buyerAgentRules.hasBuyerAgent,
      agentId: {
        label: "Buyer's Agent"
      }
    }
  ],
  [
    MlsStatus.Cancelled,
    {
      cancelledOption: {
        label: "Cancelled Option",
        lastOptions: [CancelledOption.Other],
        options: getOptionsFromEnumAndMap(
          Object.values(CancelledOption),
          CancelledOptionsLabel
        ),
        requiredToSubmit: true
      },
      cancelledReason: {
        label: "Cancelled Reason",
        maxLength: 300
      }
    }
  ],
  [
    MlsStatus.Closed,
    {
      pendingDate: {
        label: "Under Contract Date",
        tooltip: <ContractDateTooltip />,
        maxDate: getYesterday(),
        requiredToSubmit: true
      },
      closedDate: {
        maxDate: new Date(),
        tooltip: <ClosedDateTooltip />,
        label: "Sold Date",
        customFieldProps: {
          errorMessages: {
            minDate: "Closing Date must be after Contract Date"
          }
        },
        requiredToSubmit: true
      },
      closePrice: {
        label: "Sold Price",
        numberFormat: {
          max: 2000000,
          min: 100000,
          prefix: "$",
          thousandSeparator: true
        },
        maxLength: 10,
        requiredToSubmit: true
      },
      ...buyerAgentRules,
      agentId: {
        label: "Buyer's Agent"
      },
      agentIdSecond: {
        label: "Second Buyer's Agent"
      },
      sellConcess: {
        label: "Seller Contributions",
        maxLength: 7,
        numberFormat: {
          min: 0,
          max: 99999,
          prefix: "$",
          thousandSeparator: true
        },
        requiredToSubmit: true
      },
      soldTerms: {
        label: "Financing",
        options: getOptionsFromEnumAndMap(
          Object.values(SoldTerms),
          SoldTermsLabel
        ),
        rows: 2,
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Withdrawn,
    {
      offMarketDate: {
        label: "Off Market Date",
        requiredToSubmit: true
      },
      backOnMarketDate: {
        label: "Back On Market Date",
        requiredToSubmit: true
      }
    }
  ],
  [MlsStatus.Active, {}]
]);
