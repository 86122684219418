import { FormRules, zipCodeRule } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  stateRule,
  streetNameRule,
  streetNumberRule,
  unitNumberRules
} from "core/rules/common-rules";
import { countyRules, ctxCityRule } from "../sections";
import { MlsStatusForNewListings, MlsStatusLabel } from "markets/ctx/enums";

export const quickCreateListingRules: FormRules = {
  streetNumber: streetNumberRule,
  streetName: streetNameRule,
  state: stateRule,
  mlsStatus: {
    label: "List Status",
    required: true,
    options: getOptionsFromEnumAndMap(MlsStatusForNewListings, MlsStatusLabel)
  },
  zipCode: {
    ...zipCodeRule,
    required: true
  },
  city: {
    ...ctxCityRule,
    required: true
  },
  unitNumber: unitNumberRules,
  county: countyRules
};
