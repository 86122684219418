export enum InteriorFeatures {
  AlarmSystemOwned = "alarmSystemOwned",
  DryerIncluded = "dryerIncluded",
  WatersoftenerOwned = "watersoftenerOwned",
  DryBar = "dryBar",
  WetBar = "wetBar",
  DisabledAccess = "disabledAccess",
  IntercomSystem = "intercomSystem",
  WiredforSound = "wiredforSound",
  SteelBeams = "steelBeams",
  PrewiredforAlarmSystem = "prewiredforAlarmSystem",
  CentralVacuum = "centralVacuum",
  SpaHotTub = "spaHotTub",
  Balcony = "balcony",
  WasherIncluded = "washerIncluded",
  WaterSoftenerLeased = "waterSoftenerLeased",
  TwoStaircases = "twoStaircases",
  CrownMolding = "crownMolding",
  WindowCoverings = "windowCoverings",
  SplitLevel = "splitLevel",
  RefrigeratorIncluded = "refrigeratorIncluded",
  AlarmSystemLeased = "alarmSystemLeased",
  FormalEntryFoyer = "formalEntryFoyer",
  FireSmokeAlarm = "fireSmokeAlarm",
  HighCeiling = "highCeiling",
  Atrium = "atrium"
}

export const InteriorFeaturesLabel = new Map<InteriorFeatures, string>([
  [InteriorFeatures.AlarmSystemOwned, "Alarm System - Owned"],
  [InteriorFeatures.DryerIncluded, "Dryer Included"],
  [InteriorFeatures.WatersoftenerOwned, "Water softener Owned"],
  [InteriorFeatures.DryBar, "Dry Bar"],
  [InteriorFeatures.WetBar, "Wet Bar"],
  [InteriorFeatures.DisabledAccess, "Disabled Access"],
  [InteriorFeatures.IntercomSystem, "Intercom System"],
  [InteriorFeatures.WiredforSound, "Wired for Sound"],
  [InteriorFeatures.SteelBeams, "Steel Beams"],
  [InteriorFeatures.PrewiredforAlarmSystem, "Prewired for Alarm System"],
  [InteriorFeatures.CentralVacuum, "Central Vacuum"],
  [InteriorFeatures.SpaHotTub, "Spa/Hot Tub"],
  [InteriorFeatures.Balcony, "Balcony"],
  [InteriorFeatures.WasherIncluded, "Washer Included"],
  [InteriorFeatures.WaterSoftenerLeased, "Water Softener Leased"],
  [InteriorFeatures.TwoStaircases, "2 Staircases"],
  [InteriorFeatures.CrownMolding, "Crown Molding"],
  [InteriorFeatures.WindowCoverings, "Window Coverings"],
  [InteriorFeatures.SplitLevel, "Split Level"],
  [InteriorFeatures.RefrigeratorIncluded, "Refrigerator Included"],
  [InteriorFeatures.AlarmSystemLeased, "Alarm System - Leased"],
  [InteriorFeatures.FormalEntryFoyer, "Formal Entry/Foyer"],
  [InteriorFeatures.FireSmokeAlarm, "Fire/Smoke Alarm"],
  [InteriorFeatures.HighCeiling, "High Ceiling"],
  [InteriorFeatures.Atrium, "Atrium"]
]);
