export enum ShowingRequirements {
  AppointmentOnly = "appointmentOnly",
  CallKeyBox = "callKeyBox",
  CombinationLockBox = "combinationLockBox",
  GoShowNoApptNeeded = "goShowNoApptNeeded",
  KeyInOffice = "keyInOffice",
  NoLockBox = "noLockBox",
  SecuritySystem = "securitySystem",
  SeeRemarks = "seeRemarks",
  Special = "special",
  ToBeBuilt = "toBeBuilt",
  UnderConstruction = "underConstruction",
  ShowingService = "showingService"
}

export const ShowingRequirementsLabel = new Map<ShowingRequirements, string>([
  [ShowingRequirements.AppointmentOnly, "Appointment Only"],
  [ShowingRequirements.CallKeyBox, "Call-Key Box"],
  [ShowingRequirements.CombinationLockBox, "Combination Lock Box"],
  [ShowingRequirements.GoShowNoApptNeeded, "Go Show-No Appt. Needed"],
  [ShowingRequirements.KeyInOffice, "Key In Office"],
  [ShowingRequirements.NoLockBox, "No Lock Box"],
  [ShowingRequirements.SecuritySystem, "Security System"],
  [ShowingRequirements.SeeRemarks, "See Remarks"],
  [ShowingRequirements.Special, "Special"],
  [ShowingRequirements.ToBeBuilt, "To Be Built"],
  [ShowingRequirements.UnderConstruction, "Under Construction"],
  [ShowingRequirements.ShowingService, "Showing Service"]
]);
