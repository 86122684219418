export enum SqFtSource {
  AppraisalDistrict = "appraisalDistrict",
  Appraiser = "appraiser",
  Architect = "architect",
  Builder = "builder",
  Owner = "owner",
  Plans = "plans",
  Survey = "survey",
  Unknown = "unknown"
}
export const SqFtSourceLabel = new Map<SqFtSource, string>([
  [SqFtSource.AppraisalDistrict, "Appraisal District"],
  [SqFtSource.Appraiser, "Appraiser"],
  [SqFtSource.Architect, "Architect"],
  [SqFtSource.Builder, "Builder"],
  [SqFtSource.Owner, "Owner"],
  [SqFtSource.Plans, "Plans"],
  [SqFtSource.Survey, "Survey"],
  [SqFtSource.Unknown, "Unknown"]
]);
