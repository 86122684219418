export enum HOAFeeIncludes {
  Cable = "cable",
  ExteriorMaintenance = "exteriorMaintenance",
  Gas = "gas",
  Internet = "internet",
  Landscaping = "landscaping",
  TrashCollection = "trashCollection",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const HOAFeeIncludesLabel = new Map<HOAFeeIncludes, string>([
  [HOAFeeIncludes.Cable, "Cable"],
  [HOAFeeIncludes.ExteriorMaintenance, "Exterior Maintenance"],
  [HOAFeeIncludes.Gas, "Gas"],
  [HOAFeeIncludes.Internet, "Internet"],
  [HOAFeeIncludes.Landscaping, "Landscaping"],
  [HOAFeeIncludes.TrashCollection, "Trash Collection"],
  [HOAFeeIncludes.OtherSeeRemarks, "Other-See Remarks"]
]);
