import { FormRules } from "@homesusa/form";
import {
  distanceToWaterAccessRule,
  utilitiesRules,
  waterfrontFeaturesRule
} from "../../sections";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  GuestAccommodationsDescription,
  GuestAccommodationsDescriptionLabel,
  HomeFaces,
  HomeFacesLabel,
  UnitStyle,
  UnitStyleLabel
} from "markets/abor/enums";

export const featuresInfoRules: FormRules = {
  ...utilitiesRules,
  propertyDescription: {
    label: "Property Description",
    requiredToSubmit: true,
    rows: 5,
    maxLength: 4000,
    minLength: 25,
    minLengthSuggestion: 100,
    helperText:
      "MLS number, Built by <BUILDER> and Completion Info will be added as a prefix to this field. DO NOT INCLUDE."
  },
  unitStyle: {
    label: "Unit Style",
    options: getOptionsFromEnumAndMap(Object.values(UnitStyle), UnitStyleLabel)
  },
  guestAccommodationsDescription: {
    label: "Accommodations",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(GuestAccommodationsDescription),
      GuestAccommodationsDescriptionLabel
    ),
    noneOption: [GuestAccommodationsDescription.None],
    lastOptions: [GuestAccommodationsDescription.None]
  },
  guestBedroomsTotal: {
    label: "# Bedrooms"
  },
  guestFullBathsTotal: {
    label: "# Guest Full Baths"
  },
  guestHalfBathsTotal: {
    label: "# Guest Half Baths"
  },
  homeFaces: {
    label: "Home Faces",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(HomeFaces), HomeFacesLabel)
  },
  distanceToWaterAccess: distanceToWaterAccessRule,
  waterfrontFeatures: waterfrontFeaturesRule
};
