export enum Refreshments {
  Dinner = "dinner",
  Drinks = "drinks",
  Lunch = "lunch",
  None = "none",
  Snacks = "snacks"
}

export const RefreshmentsLabels = new Map<Refreshments, string>([
  [Refreshments.Lunch, "Lunch"],
  [Refreshments.Snacks, "Snacks"],
  [Refreshments.Drinks, "Drinks"],
  [Refreshments.Dinner, "Dinner"],
  [Refreshments.None, "None"]
]);
