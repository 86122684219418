import React from "react";
import { Company } from "core/interfaces/company";
import { getCompanyInfo } from "core/services/company.service";

export const useGetCompanyInfo = (
  companyId?: string
): [entity: Company | undefined, getEntity: () => Promise<void>] => {
  const [entity, setEntity] = React.useState<Company>();

  const getEntity = React.useCallback(async (): Promise<void> => {
    if (!companyId) {
      setEntity(undefined);
      return Promise.resolve();
    }
    return getCompanyInfo(companyId).then((company) => setEntity(company));
  }, [companyId]);

  React.useEffect(() => {
    getEntity();
  }, [getEntity]);

  return [entity, getEntity];
};
