import { FormRules } from "@homesusa/form";
import { salesOfficeRules } from "core/rules/sales-office";
import { harCityRule } from "../../sections";

export const harSalesOfficeRules: FormRules = {
  ...salesOfficeRules,
  salesOfficeCity: {
    ...harCityRule,
    required: false
  }
};
