import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  title: SxProps;
}

export const useStyles = (): Styles => {
  return {
    root: {
      display: "flex",
      rowGap: "16px",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center"
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      mb: 2
    }
  };
};
