import React from "react";

import { FieldRulesProps } from "@homesusa/form";
import { DownloaderService, QuicklisterService } from "../interfaces/services";

export const ReportsContext = React.createContext<{
  services: DownloaderService & QuicklisterService;
  marketRules: {
    city: FieldRulesProps;
    occupancy?: FieldRulesProps;
    mlsStatus: FieldRulesProps;
  };
}>(Object.assign({}));
