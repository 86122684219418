export enum Foundation {
  PierAndBeam = "pierAndBeam",
  Slab = "slab",
  Other = "other"
}

export const FoundationLabel = new Map<Foundation, string>([
  [Foundation.PierAndBeam, "Brick/Mortar"],
  [Foundation.Slab, "Slab"],
  [Foundation.Other, "Other"]
]);
