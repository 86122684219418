export enum FireplaceDescription {
  Gas = "gas",
  GasStarter = "gasStarter",
  GlassEnclosedScreen = "glassEnclosedScreen",
  Heatilator = "heatilator",
  GasLogsIncluded = "gasLogsIncluded",
  LivingRoom = "livingRoom",
  MockFireplace = "mockFireplace",
  NotApplicable = "notApplicable",
  One = "one",
  Other = "other",
  StoneRockBrick = "stoneRockBrick",
  Two = "two",
  WoodBurning = "woodBurning"
}

export const FireplaceDescriptionLabel = new Map<FireplaceDescription, string>([
  [FireplaceDescription.Gas, "Gas"],
  [FireplaceDescription.GasStarter, "Gas Starter"],
  [FireplaceDescription.GlassEnclosedScreen, "Glass/Enclosed Screen"],
  [FireplaceDescription.Heatilator, "Heatilator"],
  [FireplaceDescription.GasLogsIncluded, "Gas Logs Included"],
  [FireplaceDescription.LivingRoom, "Living Room"],
  [FireplaceDescription.MockFireplace, "Mock Fireplace"],
  [FireplaceDescription.NotApplicable, "Not Applicable"],
  [FireplaceDescription.One, "One"],
  [FireplaceDescription.Other, "Other"],
  [FireplaceDescription.StoneRockBrick, "Stone/Rock/Brick"],
  [FireplaceDescription.Two, "Two"],
  [FireplaceDescription.WoodBurning, "Wood Burning"]
]);
