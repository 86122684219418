import React from "react";

import { initializeGaugeTotal } from "../utils";
import { DashboardContext } from "../context/dashboard";
import { AlertType, ImportStatus } from "../enums";
import { ImportSource } from "core/enums";

type AlertTotal = { value: number; total: number };

export const useGetXmlAlertsTotal = (): (() => Promise<AlertTotal>) => {
  const {
    alertServices: { getAlertsCount },
    importServices: {
      getAwaitingApprovalCommunities,
      getAwaitingApprovalPlans
    },
    xmlServices: { getListings }
  } = React.useContext(DashboardContext);

  const xmlQlAlertActions = [
    AlertType.LockedListings,
    AlertType.XmlListingUpdatedWithoutRequest
  ];

  const getTotal = async (): Promise<AlertTotal> => {
    const baseFilter = { isOnlyCount: true };
    const qlAlertsCount = await getAlertsCount({ alerts: xmlQlAlertActions });
    const { total: listingDeletedCount } = await getListings({
      ...baseFilter,
      importStatus: ImportStatus.Deleted
    });
    const { total: listingAvailableCount } = await getListings({
      ...baseFilter,
      importStatus: ImportStatus.Available
    });
    const { total: communitiesCount } = await getAwaitingApprovalCommunities(
      ImportSource.Xml,
      baseFilter
    );
    const { total: plansCount } = await getAwaitingApprovalPlans(
      ImportSource.Xml,
      baseFilter
    );

    const value =
      qlAlertsCount +
      listingDeletedCount +
      listingAvailableCount +
      communitiesCount +
      plansCount;
    const { total } = initializeGaugeTotal(value, "xml");

    return { value, total };
  };

  return getTotal;
};
