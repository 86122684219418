export enum HighSchool {
  NotApplicable = "notApplicable",
  HighSchool = "highSchool",
  AlamoHeights = "alamoHeights",
  AlpineHigh = "alpineHigh",
  AnnaHighSchool = "annaHighSchool",
  Atascosa = "atascosa",
  Austin = "austin",
  Bandera = "bandera",
  Benevides = "benevides",
  BigWells = "bigWells",
  BillBrown = "billBrown",
  Blanco = "blanco",
  Boerne = "boerne",
  BoerneChampion = "boerneChampion",
  Brackenridge = "brackenridge",
  Brackett = "brackett",
  BrackettISD = "brackettISD",
  Brady = "brady",
  Burbank = "burbank",
  Burnet = "burnet",
  BusinessCareers = "businessCareers",
  ByronSteeleHigh = "byronSteeleHigh",
  CCWinn = "cCWinn",
  Chilton = "chilton",
  CrystalCity = "crystalCity",
  CalallenHighSchool = "calallenHighSchool",
  CallDistrict = "callDistrict",
  Canyon = "canyon",
  CanyonLake = "canyonLake",
  CarrizoSprings = "carrizoSprings",
  CarrizoSpringsPR = "carrizoSpringsPR",
  CenterPoint = "centerPoint",
  CenterPointPT = "centerPointPT",
  Challenge = "challenge",
  Champion = "champion",
  Charlotte = "charlotte",
  Churchill = "churchill",
  Clark = "clark",
  Clemens = "clemens",
  Comfort = "comfort",
  Comstock = "comstock",
  Corbet = "corbet",
  CotullaHighSchool = "cotullaHighSchool",
  CreekwoodPark = "creekwoodPark",
  CristalCity = "cristalCity",
  CueroHigh = "cueroHigh",
  DHanis = "dHanis",
  Davenport = "davenport",
  DelRio = "delRio",
  DelRioFreshman = "delRioFreshman",
  Devine = "devine",
  Dilley = "dilley",
  DrippingSpringsHigh = "drippingSpringsHigh",
  Evans = "evans",
  EaglePass = "eaglePass",
  EarlWarren = "earlWarren",
  EastCentral = "eastCentral",
  Edgewood = "edgewood",
  Edison = "edison",
  FlourBluff = "flourBluff",
  FallCity = "fallCity",
  Floresville = "floresville",
  FoxTech = "foxTech",
  Fredericksburg = "fredericksburg",
  GeorgeWest = "georgeWest",
  Gonzales = "gonzales",
  Groesbeck = "groesbeck",
  HarlanHS = "harlanHS",
  Harlandale = "harlandale",
  HarmonyScienceAcademy = "harmonyScienceAcademy",
  HarperHS = "harperHS",
  HealthCareers = "healthCareers",
  HelotesHigh = "helotesHigh",
  Highlands = "highlands",
  HillCntry = "hillCntry",
  HillCountry = "hillCountry",
  HolmesOliverW = "holmesOliverW",
  Hondo = "hondo",
  Hudspech = "hudspech",
  Hunt = "hunt",
  Ingram = "ingram",
  Jefferson = "jefferson",
  JohnFKennedy = "johnFKennedy",
  JohnJay = "johnJay",
  Johnson = "johnson",
  JohnsonCity = "johnsonCity",
  Jones = "jones",
  Jourdanton = "jourdanton",
  Judson = "judson",
  Junction = "junction",
  KarnesCityHigh = "karnesCityHigh",
  Kenedy = "kenedy",
  Kerrville = "kerrville",
  KnippaPreparatory = "knippaPreparatory",
  LaPryorSecondary = "laPryorSecondary",
  LaVernia = "laVernia",
  LagoVista = "lagoVista",
  LampasasHighSchool = "lampasasHighSchool",
  Lanier = "lanier",
  LargoVista = "largoVista",
  Leakey = "leakey",
  Leander = "leander",
  Lee = "lee",
  LegacyHighSchool = "legacyHighSchool",
  Lehman = "lehman",
  LibertyHighSchool = "libertyHighSchool",
  Llano = "llano",
  LouisDBrandeis = "louisDBrandeis",
  Luling = "luling",
  Lumberton = "lumberton",
  LyndonBJohnson = "lyndonBJohnson",
  Lytle = "lytle",
  Macarthur = "macarthur",
  Mackey = "mackey",
  Madison = "madison",
  MarbleFallsHigh = "marbleFallsHigh",
  Marion = "marion",
  Marshall = "marshall",
  MasonHigh = "masonHigh",
  Mathis = "mathis",
  Mccollum = "mccollum",
  Medina = "medina",
  MedinaValley = "medinaValley",
  Memorial = "memorial",
  Natalia = "natalia",
  NavarroHigh = "navarroHigh",
  NewBraunfel = "newBraunfel",
  Nixon = "nixon",
  NuecesCanyon = "nuecesCanyon",
  OConnor = "oConnor",
  Oconnor = "oconnor",
  OutKerr = "outKerr",
  Pearsall = "pearsall",
  Pieper = "pieper",
  Pleasanton = "pleasanton",
  PortAransas = "portAransas",
  PortIsabel = "portIsabel",
  Poteet = "poteet",
  Poth = "poth",
  RockportFultonPortAransas = "rockportFultonPortAransas",
  RobertGCole = "robertGCole",
  Rocksprings = "rocksprings",
  RonaldReagan = "ronaldReagan",
  Roosevelt = "roosevelt",
  Runge = "runge",
  Sabinal = "sabinal",
  SamHouston = "samHouston",
  SamuelClemens = "samuelClemens",
  SanMarcos = "sanMarcos",
  SantaFeHigh = "santaFeHigh",
  Schulenburg = "schulenburg",
  Seguin = "seguin",
  SmithsonValley = "smithsonValley",
  SoSanAntonioWest = "soSanAntonioWest",
  Somerset = "somerset",
  SotomayorHighSchool = "sotomayorHighSchool",
  SouthSanAntonio = "southSanAntonio",
  Southside = "southside",
  Southwest = "southwest",
  Steele = "steele",
  Stevens = "stevens",
  Stockdale = "stockdale",
  Taft = "taft",
  ThreeRivers = "threeRivers",
  TildenHighSchool = "tildenHighSchool",
  Tivy = "tivy",
  Utopia = "utopia",
  Uvalde = "uvalde",
  Vandegrift = "vandegrift",
  VanHighSchool = "vanHighSchool",
  VeteransMemorial = "veteransMemorial",
  Wagner = "wagner",
  Warren = "warren",
  WestCampus = "westCampus",
  WilliamBrennan = "williamBrennan",
  Wimberley = "wimberley",
  YorktownHigh = "yorktownHigh"
}

export const HighSchoolLabel: Record<HighSchool, string> = {
  [HighSchool.NotApplicable]: "Not Applicable",
  [HighSchool.HighSchool]: "High School",
  [HighSchool.AlamoHeights]: "Alamo Heights",
  [HighSchool.AlpineHigh]: "Alpine High",
  [HighSchool.AnnaHighSchool]: "Anna High School",
  [HighSchool.Atascosa]: "Atascosa",
  [HighSchool.Austin]: "Austin",
  [HighSchool.Bandera]: "Bandera",
  [HighSchool.Benevides]: "Benevides",
  [HighSchool.BigWells]: "Big Wells",
  [HighSchool.BillBrown]: "Bill Brown",
  [HighSchool.Blanco]: "Blanco",
  [HighSchool.Boerne]: "Boerne",
  [HighSchool.BoerneChampion]: "Boerne Champion",
  [HighSchool.Brackenridge]: "Brackenridge",
  [HighSchool.Brackett]: "Brackett",
  [HighSchool.BrackettISD]: "Brackett ISD",
  [HighSchool.Brady]: "Brady ISD",
  [HighSchool.Burbank]: "Burbank",
  [HighSchool.Burnet]: "Burnet",
  [HighSchool.BusinessCareers]: "Business Careers",
  [HighSchool.ByronSteeleHigh]: "Byron Steele High",
  [HighSchool.CCWinn]: "C.C. Winn",
  [HighSchool.Chilton]: "Chilton High School",
  [HighSchool.CrystalCity]: "Crystal City High School Zavala County",
  [HighSchool.CalallenHighSchool]: "Calallen High School",
  [HighSchool.CallDistrict]: "Call District",
  [HighSchool.Canyon]: "Canyon",
  [HighSchool.CanyonLake]: "Canyon Lake",
  [HighSchool.CarrizoSprings]: "Carrizo Springs",
  [HighSchool.CarrizoSpringsPR]: "Carrizo Springs",
  [HighSchool.CenterPoint]: "Center Point",
  [HighSchool.CenterPointPT]: "Center Point",
  [HighSchool.Challenge]: "Challenge",
  [HighSchool.Champion]: "Champion",
  [HighSchool.Charlotte]: "Charlotte",
  [HighSchool.Churchill]: "Churchill",
  [HighSchool.Clark]: "Clark",
  [HighSchool.Clemens]: "Clemens",
  [HighSchool.Comfort]: "Comfort",
  [HighSchool.Comstock]: "Comstock",
  [HighSchool.Corbet]: "Corbet",
  [HighSchool.CotullaHighSchool]: "Cotulla High School",
  [HighSchool.CreekwoodPark]: "Creekwood Park",
  [HighSchool.CristalCity]: "Cristal City",
  [HighSchool.CueroHigh]: "Cuero High",
  [HighSchool.DHanis]: "DHanis",
  [HighSchool.Davenport]: "Davenport",
  [HighSchool.DelRio]: "Del Rio",
  [HighSchool.DelRioFreshman]: "Del Rio Freshman",
  [HighSchool.Devine]: "Devine",
  [HighSchool.Dilley]: "Dilley",
  [HighSchool.DrippingSpringsHigh]: "Dripping Springs High School",
  [HighSchool.Evans]: "Evans",
  [HighSchool.EaglePass]: "Eagle Pass",
  [HighSchool.EarlWarren]: "Earl Warren",
  [HighSchool.EastCentral]: "East Central",
  [HighSchool.Edgewood]: "Edgewood",
  [HighSchool.Edison]: "Edison",
  [HighSchool.FlourBluff]: "Flour Bluff",
  [HighSchool.FallCity]: "Fall City",
  [HighSchool.Floresville]: "Floresville",
  [HighSchool.FoxTech]: "Fox Tech",
  [HighSchool.Fredericksburg]: "Fredericksburg",
  [HighSchool.GeorgeWest]: "George West",
  [HighSchool.Gonzales]: "Gonzales",
  [HighSchool.Groesbeck]: "Groesbeck",
  [HighSchool.HarlanHS]: "Harlan HS",
  [HighSchool.Harlandale]: "Harlandale",
  [HighSchool.HarmonyScienceAcademy]: "Harmony Science Academy",
  [HighSchool.HarperHS]: "Harper HS",
  [HighSchool.HealthCareers]: "Health Careers",
  [HighSchool.HelotesHigh]: "Helotes High",
  [HighSchool.Highlands]: "Highlands",
  [HighSchool.HillCntry]: "Hill Country",
  [HighSchool.HillCountry]: "Hill Country",
  [HighSchool.HolmesOliverW]: "Holmes Oliver W",
  [HighSchool.Hondo]: "Hondo",
  [HighSchool.Hudspech]: "Hudspech",
  [HighSchool.Hunt]: "Hunt",
  [HighSchool.Ingram]: "Ingram",
  [HighSchool.Jefferson]: "Jefferson",
  [HighSchool.JohnFKennedy]: "John F Kennedy",
  [HighSchool.JohnJay]: "John Jay",
  [HighSchool.Johnson]: "Johnson",
  [HighSchool.JohnsonCity]: "Johnson City",
  [HighSchool.Jones]: "Jones",
  [HighSchool.Jourdanton]: "Jourdanton",
  [HighSchool.Judson]: "Judson",
  [HighSchool.Junction]: "Junction",
  [HighSchool.KarnesCityHigh]: "Karnes City High",
  [HighSchool.Kenedy]: "Kenedy",
  [HighSchool.Kerrville]: "Kerrville",
  [HighSchool.KnippaPreparatory]: "Knippa Preparatory",
  [HighSchool.LaPryorSecondary]: "La Pryor Secondary",
  [HighSchool.LaVernia]: "La Vernia",
  [HighSchool.LagoVista]: "Lago Vista",
  [HighSchool.LampasasHighSchool]: "Lampasas High School",
  [HighSchool.Lanier]: "Lanier",
  [HighSchool.LargoVista]: "Largo Vista",
  [HighSchool.Leakey]: "Leakey",
  [HighSchool.Leander]: "Leander",
  [HighSchool.Lee]: "Lee",
  [HighSchool.LegacyHighSchool]: "Legacy High School",
  [HighSchool.Lehman]: "Lehman",
  [HighSchool.LibertyHighSchool]: "Liberty High School",
  [HighSchool.Llano]: "Llano",
  [HighSchool.LouisDBrandeis]: "Louis D Brandeis",
  [HighSchool.Luling]: "Luling",
  [HighSchool.Lumberton]: "Lumberton",
  [HighSchool.LyndonBJohnson]: "Lyndon B. Johnson",
  [HighSchool.Lytle]: "Lytle",
  [HighSchool.Macarthur]: "Macarthur",
  [HighSchool.Mackey]: "Mackey",
  [HighSchool.Madison]: "Madison",
  [HighSchool.MarbleFallsHigh]: "Marble Falls High",
  [HighSchool.Marion]: "Marion",
  [HighSchool.Marshall]: "Marshall",
  [HighSchool.MasonHigh]: "Mason High",
  [HighSchool.Mathis]: "Mathis",
  [HighSchool.Mccollum]: "Mccollum",
  [HighSchool.Medina]: "Medina",
  [HighSchool.MedinaValley]: "Medina Valley",
  [HighSchool.Memorial]: "Memorial",
  [HighSchool.Natalia]: "Natalia",
  [HighSchool.NavarroHigh]: "Navarro High",
  [HighSchool.NewBraunfel]: "New Braunfel",
  [HighSchool.Nixon]: "Nixon",
  [HighSchool.NuecesCanyon]: "Nueces Canyon",
  [HighSchool.OConnor]: "O'Connor",
  [HighSchool.Oconnor]: "Oconnor",
  [HighSchool.OutKerr]: "Out/Kerr",
  [HighSchool.Pearsall]: "Pearsall",
  [HighSchool.Pieper]: "Pieper",
  [HighSchool.Pleasanton]: "Pleasanton",
  [HighSchool.PortAransas]: "Port Aransas",
  [HighSchool.PortIsabel]: "Port Isabel",
  [HighSchool.Poteet]: "Poteet",
  [HighSchool.Poth]: "Poth",
  [HighSchool.RockportFultonPortAransas]: "Rockport/Fulton Port Aransas",
  [HighSchool.RobertGCole]: "Robert G Cole",
  [HighSchool.Rocksprings]: "Rocksprings",
  [HighSchool.RonaldReagan]: "Ronald Reagan",
  [HighSchool.Roosevelt]: "Roosevelt",
  [HighSchool.Runge]: "Runge",
  [HighSchool.Sabinal]: "Sabinal",
  [HighSchool.SamHouston]: "Sam Houston",
  [HighSchool.SamuelClemens]: "Samuel Clemens",
  [HighSchool.SanMarcos]: "San Marcos",
  [HighSchool.SantaFeHigh]: "Santa Fe High",
  [HighSchool.Schulenburg]: "Schulenburg",
  [HighSchool.Seguin]: "Seguin",
  [HighSchool.SmithsonValley]: "Smithson Valley",
  [HighSchool.SoSanAntonioWest]: "So. San Antonio West",
  [HighSchool.Somerset]: "Somerset",
  [HighSchool.SotomayorHighSchool]: "Sotomayor High School",
  [HighSchool.SouthSanAntonio]: "South San Antonio",
  [HighSchool.Southside]: "Southside",
  [HighSchool.Southwest]: "Southwest",
  [HighSchool.Steele]: "Steele",
  [HighSchool.Stevens]: "Stevens",
  [HighSchool.Stockdale]: "Stockdale",
  [HighSchool.Taft]: "Taft",
  [HighSchool.ThreeRivers]: "Three Rivers",
  [HighSchool.TildenHighSchool]: "Tilden High School",
  [HighSchool.Tivy]: "Tivy",
  [HighSchool.Utopia]: "Utopia",
  [HighSchool.Uvalde]: "Uvalde",
  [HighSchool.Vandegrift]: "Vandegrift",
  [HighSchool.VanHighSchool]: "Van High School",
  [HighSchool.VeteransMemorial]: "Veterans Memorial",
  [HighSchool.Wagner]: "Wagner",
  [HighSchool.Warren]: "Warren",
  [HighSchool.WestCampus]: "West Campus",
  [HighSchool.WilliamBrennan]: "William Brennan",
  [HighSchool.Wimberley]: "Wimberley",
  [HighSchool.YorktownHigh]: "Yorktown High"
};
