import { FormRules, FieldRulesProps } from "@homesusa/form";
import { financialRules, schoolsRules } from "../../sections";

export const financialSchoolsRules: Record<
  string,
  FormRules | FieldRulesProps
> = {
  ...financialRules,
  schools: schoolsRules
};
