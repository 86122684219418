export enum LockboxLocation {
  DoorFront = "doorFront",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const LockboxLocationLabel = new Map<LockboxLocation, string>([
  [LockboxLocation.DoorFront, "Door-Front"],
  [LockboxLocation.None, "None"],
  [LockboxLocation.OtherSeeRemarks, "Other-See Remarks"]
]);
