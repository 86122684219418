export enum FemaFloodPlain {
  No = "no",
  Partial = "partial",
  Yes100yr = "yes100yr",
  Yes500yr = "yes500yr",
  SeeRemarks = "seeRemarks"
}

export const FemaFloodPlainLabel = new Map<FemaFloodPlain, string>([
  [FemaFloodPlain.No, "No"],
  [FemaFloodPlain.Partial, "Partial"],
  [FemaFloodPlain.Yes100yr, "Yes 100 Years"],
  [FemaFloodPlain.Yes500yr, "Yes 500 Years"],
  [FemaFloodPlain.SeeRemarks, "See Remarks"]
]);
