import {
  AmarilloCities,
  AmarilloCitiesLabel,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { County, CountyLabel } from "markets/amarillo/enums";

export const getAmarilloCityLabel = (city: string | AmarilloCities): string =>
  AmarilloCitiesLabel[city as AmarilloCities] ?? city;

export const amarilloCityRule: FieldRulesProps = {
  label: "City",
  options: getOptionsFromEnumAndRecord(
    Object.values(AmarilloCities),
    AmarilloCitiesLabel
  )
};

export const countyRules: FieldRulesProps = {
  label: "County",
  options: getOptionsFromEnumAndRecord(Object.values(County), CountyLabel),
  required: false
};
