export enum HorseAmenities {
  Arena = "arena",
  Barn = "barn",
  BoardingFacilities = "boardingFacilities",
  BridlePath = "bridlePath",
  Corrals = "corrals",
  HayStorage = "hayStorage",
  Paddocks = "paddocks",
  PalpationChute = "palpationChute",
  Pasture = "pasture",
  RidingTrail = "ridingTrail",
  RoundPen = "roundPen",
  ShavingBin = "shavingBin",
  Stables = "stables",
  TackRoom = "tackRoom",
  TrailerStorage = "trailerStorage",
  WashRack = "washRack",
  None = "none",
  SeeRemarks = "seeRemarks"
}
export const HorseAmenitiesLabel = new Map<HorseAmenities, string>([
  [HorseAmenities.Arena, "Arena"],
  [HorseAmenities.Barn, "Barn"],
  [HorseAmenities.BoardingFacilities, "Boarding Facilities"],
  [HorseAmenities.BridlePath, "Bridle Path"],
  [HorseAmenities.Corrals, "Corral(s)"],
  [HorseAmenities.HayStorage, "Hay Storage"],
  [HorseAmenities.Paddocks, "Paddocks"],
  [HorseAmenities.PalpationChute, "Palpation Chute"],
  [HorseAmenities.Pasture, "Pasture"],
  [HorseAmenities.RidingTrail, "Riding Trail"],
  [HorseAmenities.RoundPen, "Round Pen"],
  [HorseAmenities.ShavingBin, "Shaving Bin"],
  [HorseAmenities.Stables, "Stable(s)"],
  [HorseAmenities.TackRoom, "Tack Room"],
  [HorseAmenities.TrailerStorage, "Trailer Storage"],
  [HorseAmenities.WashRack, "Wash Rack"],
  [HorseAmenities.None, "None"],
  [HorseAmenities.SeeRemarks, "See Remarks"]
]);
