export enum MlsArea {
  CentralNorth = "centralNorth",
  AustinCounty = "austinCounty",
  SouthCentral = "southCentral",
  WestTexas = "westTexas",
  CherokeeCounty = "cherokeeCounty",
  Panhandle = "panhandle",
  Central = "central",
  BrazosCounty = "brazosCounty",
  South = "south",
  FarWest = "farWest",
  WalkerCounty = "walkerCounty",
  GrimesCounty = "grimesCounty",
  FtBendWest = "ftBendWest",
  TrinityCounty = "trinityCounty",
  TomballCypress = "tomballCypress",
  SabineCounty = "sabineCounty",
  LibertyCounty = "libertyCounty",
  PolkCounty = "polkCounty",
  LeonCounty = "leonCounty",
  Southeast = "southeast",
  ClearLakeArea = "clearLakeArea",
  NewtonCounty = "newtonCounty",
  FreestoneCounty = "freestoneCounty",
  FtBendCentral = "ftBendCentral",
  Northeast = "northeast",
  Hwy6North = "hwy6North",
  SouthTexas = "southTexas",
  LeeCounty = "leeCounty",
  FtBendSouthwest = "ftBendSouthwest",
  BurlesonCounty = "burlesonCounty",
  TylerCounty = "tylerCounty",
  AldineWestfieldArea = "aldineWestfieldArea",
  CalhounCounty = "calhounCounty",
  MadisonCounty = "madisonCounty",
  HoustonCounty = "houstonCounty",
  FayetteCounty = "fayetteCounty",
  North = "north",
  NearNorth = "nearNorth",
  BrazoriaCounty = "brazoriaCounty",
  WallerCounty = "wallerCounty",
  HardinCounty = "hardinCounty",
  BellaireWest = "bellaireWest",
  MontgomeryCountyNE = "montgomeryCountyNE",
  JasperCounty = "jasperCounty",
  SpringBranch = "springBranch",
  AngelinaCounty = "angelinaCounty",
  AndersonCounty = "andersonCounty",
  OtherTexas = "otherTexas",
  FarNortheast = "farNortheast",
  MatagordaCounty = "matagordaCounty",
  Northwest = "northwest",
  WhartonCounty = "whartonCounty",
  ChambersCounty = "chambersCounty",
  OtherUnitedStates = "otherUnitedStates",
  JacksonCounty = "jacksonCounty",
  NacogdochesCounty = "nacogdochesCounty",
  LimestoneCounty = "limestoneCounty",
  SouthKatyArea = "southKatyArea",
  FarNorthwest = "farNorthwest",
  VictoriaCounty = "victoriaCounty",
  GalvestonCounty = "galvestonCounty",
  CentralTexas = "centralTexas",
  BellaireSouth = "bellaireSouth",
  CyFair = "cyFair",
  RobertsonCounty = "robertsonCounty",
  MontgomeryCountySW = "montgomeryCountySW",
  OtherInternational = "otherInternational",
  SanJacintoCounty = "sanJacintoCounty",
  PasadenaArea = "pasadenaArea",
  ColoradoCounty = "coloradoCounty",
  SanAugustineCounty = "sanAugustineCounty",
  JeffersonCounty = "jeffersonCounty",
  MilamCounty = "milamCounty",
  East = "east",
  MontgomeryCountySE = "montgomeryCountySE",
  West = "west",
  WashingtonCounty = "washingtonCounty",
  OrangeCounty = "orangeCounty",
  MontgomeryCountyNW = "montgomeryCountyNW",
  CentralWest = "centralWest",
  FtBendEast = "ftBendEast",
  NorthTexas = "northTexas",
  AliefArea = "aliefArea",
  Memorial = "memorial",
  LavacaCounty = "lavacaCounty",
  Southwest = "southwest"
}

export const MlsAreaLabel = new Map<MlsArea, string>([
  [MlsArea.CentralNorth, "9 - Central North"],
  [MlsArea.AustinCounty, "57 - Austin County"],
  [MlsArea.SouthCentral, "18 - South Central"],
  [MlsArea.WestTexas, "86 - West Texas"],
  [MlsArea.CherokeeCounty, "76 - Cherokee County"],
  [MlsArea.Panhandle, "87 - Panhandle"],
  [MlsArea.Central, "16 - Central"],
  [MlsArea.BrazosCounty, "61 - Brazos County"],
  [MlsArea.South, "4 - South"],
  [MlsArea.FarWest, "25 - Far West"],
  [MlsArea.WalkerCounty, "43 - Walker County"],
  [MlsArea.GrimesCounty, "60 - Grimes County"],
  [MlsArea.FtBendWest, "37 - Ft. Bend West"],
  [MlsArea.TrinityCounty, "42 - Trinity County"],
  [MlsArea.TomballCypress, "35 - Tomball/Cypress"],
  [MlsArea.SabineCounty, "79 - Sabine County"],
  [MlsArea.LibertyCounty, "52 - Liberty County"],
  [MlsArea.PolkCounty, "44 - Polk County"],
  [MlsArea.LeonCounty, "63 - Leon County"],
  [MlsArea.Southeast, "3 - Southeast"],
  [MlsArea.ClearLakeArea, "7 - Clear Lake Area"],
  [MlsArea.NewtonCounty, "47 - Newton County"],
  [MlsArea.FreestoneCounty, "74 - Freestone County"],
  [MlsArea.FtBendCentral, "29 - Ft. Bend Central"],
  [MlsArea.Northeast, "1 - Northeast"],
  [MlsArea.Hwy6North, "8 - Hwy 6 North"],
  [MlsArea.SouthTexas, "85 - South Texas"],
  [MlsArea.LeeCounty, "70 - Lee County"],
  [MlsArea.FtBendSouthwest, "30 - Ft. Bend Southwest"],
  [MlsArea.BurlesonCounty, "59 - Burleson County"],
  [MlsArea.TylerCounty, "48 - Tyler County"],
  [MlsArea.AldineWestfieldArea, "34 - Aldine-Westfield Area"],
  [MlsArea.CalhounCounty, "65 - Calhoun County"],
  [MlsArea.MadisonCounty, "62 - Madison County"],
  [MlsArea.HoustonCounty, "41 - Houston County"],
  [MlsArea.FayetteCounty, "69 - Fayette County"],
  [MlsArea.North, "12 - North"],
  [MlsArea.NearNorth, "11 - Near North"],
  [MlsArea.BrazoriaCounty, "5 - Brazoria County"],
  [MlsArea.WallerCounty, "26 - Waller County"],
  [MlsArea.HardinCounty, "49 - Hardin County"],
  [MlsArea.BellaireWest, "21 - Bellaire West"],
  [MlsArea.MontgomeryCountyNE, "19 - Montgomery County NE"],
  [MlsArea.JasperCounty, "46 - Jasper County"],
  [MlsArea.SpringBranch, "24 - Spring Branch"],
  [MlsArea.AngelinaCounty, "45 - Angelina County"],
  [MlsArea.AndersonCounty, "75 - Anderson County"],
  [MlsArea.OtherTexas, "80 - Other - Texas"],
  [MlsArea.FarNortheast, "32 - Far Northeast"],
  [MlsArea.MatagordaCounty, "54 - Matagorda County"],
  [MlsArea.Northwest, "13 - Northwest"],
  [MlsArea.WhartonCounty, "55 - Wharton County"],
  [MlsArea.ChambersCounty, "53 - Chambers County"],
  [MlsArea.OtherUnitedStates, "81 - Other - United States"],
  [MlsArea.JacksonCounty, "67 - Jackson County"],
  [MlsArea.NacogdochesCounty, "77 - Nacogdoches County"],
  [MlsArea.LimestoneCounty, "73 - Limestone County"],
  [MlsArea.SouthKatyArea, "36 - South Katy Area"],
  [MlsArea.FarNorthwest, "14 - Far Northwest"],
  [MlsArea.VictoriaCounty, "66 - Victoria County"],
  [MlsArea.GalvestonCounty, "33 - Galveston County"],
  [MlsArea.CentralTexas, "83 - Central Texas"],
  [MlsArea.BellaireSouth, "20 - Bellaire South"],
  [MlsArea.CyFair, "10 - Cy-Fair"],
  [MlsArea.RobertsonCounty, "72 - Robertson County"],
  [MlsArea.MontgomeryCountySW, "15 - Montgomery County SW"],
  [MlsArea.OtherInternational, "82 - Other - International"],
  [MlsArea.SanJacintoCounty, "64 - San Jacinto County"],
  [MlsArea.PasadenaArea, "6 - Pasadena Area"],
  [MlsArea.ColoradoCounty, "56 - Colorado County"],
  [MlsArea.SanAugustineCounty, "78 - San Augustine County"],
  [MlsArea.JeffersonCounty, "51 - Jefferson County"],
  [MlsArea.MilamCounty, "71 - Milam County"],
  [MlsArea.East, "2 - East"],
  [MlsArea.MontgomeryCountySE, "40 - Montgomery County SE"],
  [MlsArea.West, "31 - West"],
  [MlsArea.WashingtonCounty, "58 - Washington County"],
  [MlsArea.OrangeCounty, "50 - Orange County"],
  [MlsArea.MontgomeryCountyNW, "39 - Montgomery County NW"],
  [MlsArea.CentralWest, "22 - Central West"],
  [MlsArea.FtBendEast, "38 - Ft Bend East"],
  [MlsArea.NorthTexas, "84 - North Texas"],
  [MlsArea.AliefArea, "28 - Alief Area"],
  [MlsArea.Memorial, "23 - Memorial"],
  [MlsArea.LavacaCounty, "68 - Lavaca County"],
  [MlsArea.Southwest, "17 - Southwest"]
]);
