export enum Kitchen {
  BreakfastArea = "breakfastArea",
  BreakfastBar = "breakfastBar",
  BuiltInOven = "builtInOven",
  CenterIsland = "centerIsland",
  CookTop = "cookTop",
  CustomCabinets = "customCabinets",
  EatInKitchen = "eatInKitchen",
  Electric = "electric",
  Gas = "gas",
  GraniteCounterTop = "graniteCounterTop",
  Island = "island",
  OpenToDining = "openToDining",
  OpenToFamilyRoom = "openToFamilyRoom",
  Pantry = "pantry",
  PantryWalkIn = "pantryWalkIn",
  PotFiller = "potFiller",
  Range = "range",
  SolidCounterTops = "solidCounterTops",
  BuiltInMicrowave = "builtInMicrowave"
}

export const KitchenLabel = new Map<Kitchen, string>([
  [Kitchen.BreakfastArea, "Breakfast Area"],
  [Kitchen.BreakfastBar, "Breakfast Bar"],
  [Kitchen.BuiltInOven, "Built-In Oven"],
  [Kitchen.CenterIsland, "Center Island"],
  [Kitchen.CookTop, "Cook Top"],
  [Kitchen.CustomCabinets, "Custom Cabinets"],
  [Kitchen.EatInKitchen, "Eat in Kitchen"],
  [Kitchen.Electric, "Electric"],
  [Kitchen.Gas, "Gas"],
  [Kitchen.GraniteCounterTop, "Granite Counter Top"],
  [Kitchen.Island, "Island"],
  [Kitchen.OpenToDining, "Open To Dining"],
  [Kitchen.OpenToFamilyRoom, "Open to Family Room"],
  [Kitchen.Pantry, "Pantry"],
  [Kitchen.PantryWalkIn, "Pantry-Walk In"],
  [Kitchen.PotFiller, "Pot Filler"],
  [Kitchen.Range, "Range"],
  [Kitchen.SolidCounterTops, "Solid Counter Tops"],
  [Kitchen.BuiltInMicrowave, "Built-In Microwave"]
]);
