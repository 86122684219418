export enum FireplaceDescription {
  Bath = "bath",
  Bedroom = "bedroom",
  Brick = "brick",
  Circulating = "circulating",
  Decorative = "decorative",
  Den = "den",
  DiningRoom = "diningRoom",
  DoubleSided = "doubleSided",
  Electric = "electric",
  EPACertifiedWoodStove = "epaCertifiedWoodStove",
  EPAQualifiedFireplace = "epaQualifiedFireplace",
  FamilyRoom = "familyRoom",
  FirePit = "firePit",
  Gas = "gas",
  GasLogs = "gasLogs",
  GasStarter = "gasStarter",
  GlassDoors = "glassDoors",
  Heatilator = "heatilator",
  Kitchen = "kitchen",
  Library = "library",
  LivingRoom = "livingRoom",
  Masonry = "masonry",
  MasterBedroom = "masterBedroom",
  Metal = "metal",
  None = "none",
  Other = "other",
  Outside = "outside",
  Propane = "propane",
  RaisedHearth = "raisedHearth",
  RecreationRoom = "recreationRoom",
  SeeRemarks = "seeRemarks",
  SeeThroughFireplace = "seeThroughFireplace",
  Stone = "stone",
  Ventless = "ventless",
  WoodBurning = "woodBurning"
}

export const FireplaceDescriptionLabel = new Map<FireplaceDescription, string>([
  [FireplaceDescription.Bath, "Bath"],
  [FireplaceDescription.Bedroom, "Bedroom"],
  [FireplaceDescription.Brick, "Brick"],
  [FireplaceDescription.Circulating, "Circulating"],
  [FireplaceDescription.Decorative, "Decorative"],
  [FireplaceDescription.Den, "Den"],
  [FireplaceDescription.DiningRoom, "Dining Room"],
  [FireplaceDescription.DoubleSided, "Double Sided"],
  [FireplaceDescription.Electric, "Electric"],
  [FireplaceDescription.EPACertifiedWoodStove, "EPA Certified Wood Stove"],
  [FireplaceDescription.EPAQualifiedFireplace, "EPA Qualified Fireplace"],
  [FireplaceDescription.FamilyRoom, "Family Room"],
  [FireplaceDescription.FirePit, "Fire Pit"],
  [FireplaceDescription.Gas, "Gas"],
  [FireplaceDescription.GasLogs, "Gas Logs"],
  [FireplaceDescription.GasStarter, "Gas Starter"],
  [FireplaceDescription.GlassDoors, "Glass Doors"],
  [FireplaceDescription.Heatilator, "Heatilator"],
  [FireplaceDescription.Kitchen, "Kitchen"],
  [FireplaceDescription.Library, "Library"],
  [FireplaceDescription.LivingRoom, "Living Room"],
  [FireplaceDescription.Masonry, "Masonry"],
  [FireplaceDescription.MasterBedroom, "Master Bedroom"],
  [FireplaceDescription.Metal, "Metal"],
  [FireplaceDescription.None, "None"],
  [FireplaceDescription.Other, "Other"],
  [FireplaceDescription.Outside, "Outside"],
  [FireplaceDescription.Propane, "Propane"],
  [FireplaceDescription.RaisedHearth, "Raised Hearth"],
  [FireplaceDescription.RecreationRoom, "Recreation Room"],
  [FireplaceDescription.SeeRemarks, "See Remarks"],
  [FireplaceDescription.SeeThroughFireplace, "See Through Fireplace"],
  [FireplaceDescription.Stone, "Stone"],
  [FireplaceDescription.Ventless, "Ventless"],
  [FireplaceDescription.WoodBurning, "Wood Burning"]
]);
