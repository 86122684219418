export enum PropertySubType {
  Duplex = "duplex",
  FreeStanding = "freeStanding",
  Townhouse = "townhouse",
  GarageApartment = "garageApartment",
  PatioHome = "patioHome"
}

export const PropertySubTypeLabel = new Map<PropertySubType, string>([
  [PropertySubType.Duplex, "Duplex"],
  [PropertySubType.FreeStanding, "Free Standing"],
  [PropertySubType.Townhouse, "Townhouse"],
  [PropertySubType.GarageApartment, "Garage Apartment"],
  [PropertySubType.PatioHome, "Patio Home"]
]);
