export enum MlsArea {
  Hundred = "hundred",
  HundredOne = "hundredOne",
  HundredTwo = "hundredTwo",
  HundredThree = "hundredThree",
  HundredFour = "hundredFour",
  HundredFive = "hundredFive",
  TwoHundred = "twoHundred",
  ThreeHundred = "threeHundred",
  FourHundred = "fourHundred",
  FiveHundred = "fiveHundred",
  SixHundred = "sixHundred",
  SevenHundred = "sevenHundred",
  EightHundred = "eightHundred",
  NineHundred = "nineHundred",
  Thousand = "thousand",
  ThousandOne = "thousandOne",
  ThousandTwo = "thousandTwo",
  ThousandThree = "thousandThree",
  ThousandFour = "thousandFour",
  ThousandFive = "thousandFive",
  ThousandSix = "thousandSix",
  ThousandOneHundred = "thousandOneHundred",
  ThousandTwoHundred = "thousandTwoHundred",
  ThousandThreeHundred = "thousandThreeHundred",
  ThousandFourHundred = "thousandFourHundred",
  ThousandFiveHundred = "thousandFiveHundred",
  ThousandSixHundred = "thousandSixHundred",
  ThousandSevenHundred = "thousandSevenHundred",
  ThousandEightHundred = "thousandEightHundred",
  ThousandEightHundredOne = "thousandEightHundredOne",
  ThousandEightHundredTwo = "thousandEightHundredTwo",
  ThousandEightHundredThree = "thousandEightHundredThree",
  ThousandEightHundredFour = "thousandEightHundredFour",
  ThousandNineHundred = "thousandNineHundred",
  TwoThousand = "twoThousand",
  TwoThousandOne = "twoThousandOne",
  TwoThousandTwo = "twoThousandTwo",
  TwoThousandThree = "twoThousandThree",
  TwoThousandFour = "twoThousandFour",
  TwoThousandOneHundred = "twoThousandOneHundred",
  TwoThousandTwoHundred = "twoThousandTwoHundred",
  TwoThousandThreeHundred = "twoThousandThreeHundred",
  TwoThousandThreeHundredOne = "twoThousandThreeHundredOne",
  TwoThousandThreeHundredTwo = "twoThousandThreeHundredTwo",
  TwoThousandThreeHundredThree = "twoThousandThreeHundredThree",
  TwoThousandThreeHundredFour = "twoThousandThreeHundredFour",
  TwoThousandFourHundred = "twoThousandFourHundred",
  TwoThousandFiveHundred = "twoThousandFiveHundred",
  TwoThousandFiveHundredOne = "twoThousandFiveHundredOne",
  TwoThousandFiveHundredTwo = "twoThousandFiveHundredTwo",
  TwoThousandFiveHundredThree = "twoThousandFiveHundredThree",
  TwoThousandFiveHundredFour = "twoThousandFiveHundredFour",
  TwoThousandFiveHundredFive = "twoThousandFiveHundredFive",
  TwoThousandFiveHundredSix = "twoThousandFiveHundredSix",
  TwoThousandFiveHundredSeven = "twoThousandFiveHundredSeven",
  TwoThousandFiveHundredEight = "twoThousandFiveHundredEight",
  TwoThousandFiveHundredNine = "twoThousandFiveHundredNine",
  TwoThousandFiveHundredTen = "twoThousandFiveHundredTen",
  TwoThousandSixHundred = "twoThousandSixHundred",
  TwoThousandSixHundredOne = "twoThousandSixHundredOne",
  TwoThousandSixHundredTwo = "twoThousandSixHundredTwo",
  TwoThousandSixHundredThree = "twoThousandSixHundredThree",
  TwoThousandSixHundredFour = "twoThousandSixHundredFour",
  TwoThousandSixHundredFive = "twoThousandSixHundredFive",
  TwoThousandSixHundredSix = "twoThousandSixHundredSix",
  TwoThousandSixHundredSeven = "twoThousandSixHundredSeven",
  TwoThousandSixHundredEight = "twoThousandSixHundredEight",
  TwoThousandSixHundredNine = "twoThousandSixHundredNine",
  TwoThousandSixHundredTen = "twoThousandSixHundredTen",
  TwoThousandSixHundredEleven = "twoThousandSixHundredEleven",
  TwoThousandSixHundredTwelve = "twoThousandSixHundredTwelve",
  TwoThousandSixHundredThirteen = "twoThousandSixHundredThirteen",
  TwoThousandSixHundredFourteen = "twoThousandSixHundredFourteen",
  TwoThousandSixHundredFifteen = "twoThousandSixHundredFifteen",
  TwoThousandSixHundredSixteen = "twoThousandSixHundredSixteen",
  TwoThousandSixHundredSevenTeen = "twoThousandSixHundredSevenTeen",
  TwoThousandSixHundredEighteen = "twoThousandSixHundredEighteen",
  TwoThousandSixHundredNineteen = "twoThousandSixHundredNineteen",
  TwoThousandSixHundredTwenty = "twoThousandSixHundredTwenty",
  TwoThousandSixHundredTwentyOne = "twoThousandSixHundredTwentyOne",
  TwoThousandSixHundredTwentyTwo = "twoThousandSixHundredTwentyTwo",
  TwoThousandSixHundredTwentyThree = "twoThousandSixHundredTwentyThree",
  TwoThousandSixHundredTwentyFour = "twoThousandSixHundredTwentyFour",
  TwoThousandSevenHundred = "twoThousandSevenHundred",
  TwoThousandSevenHundredOne = "twoThousandSevenHundredOne",
  TwoThousandSevenHundredTwo = "twoThousandSevenHundredTwo",
  TwoThousandSevenHundredThree = "twoThousandSevenHundredThree",
  TwoThousandSevenHundredFour = "twoThousandSevenHundredFour",
  TwoThousandSevenHundredFive = "twoThousandSevenHundredFive",
  TwoThousandSevenHundredSix = "twoThousandSevenHundredSix",
  TwoThousandSevenHundredSeven = "twoThousandSevenHundredSeven",
  TwoThousandSevenHundredEight = "twoThousandSevenHundredEight",
  TwoThousandSevenHundredNine = "twoThousandSevenHundredNine",
  TwoThousandEightHundred = "twoThousandEightHundred",
  TwoThousandNineHundred = "twoThousandNineHundred",
  ThreeThousand = "threeThousand",
  ThreeThousandOneHundred = "threeThousandOneHundred"
}

export const MlsAreaLabel = new Map<MlsArea, string>([
  [MlsArea.Hundred, "0100"],
  [MlsArea.HundredOne, "0101"],
  [MlsArea.HundredTwo, "0102"],
  [MlsArea.HundredThree, "0103"],
  [MlsArea.HundredFour, "0104"],
  [MlsArea.HundredFive, "0105"],
  [MlsArea.TwoHundred, "0200"],
  [MlsArea.ThreeHundred, "0300"],
  [MlsArea.FourHundred, "0400"],
  [MlsArea.FiveHundred, "0500"],
  [MlsArea.SixHundred, "0600"],
  [MlsArea.SevenHundred, "0700"],
  [MlsArea.EightHundred, "0800"],
  [MlsArea.NineHundred, "0900"],
  [MlsArea.Thousand, "1000"],
  [MlsArea.ThousandOne, "1001"],
  [MlsArea.ThousandTwo, "1002"],
  [MlsArea.ThousandThree, "1003"],
  [MlsArea.ThousandFour, "1004"],
  [MlsArea.ThousandFive, "1005"],
  [MlsArea.ThousandSix, "1006"],
  [MlsArea.ThousandOneHundred, "1100"],
  [MlsArea.ThousandTwoHundred, "1200"],
  [MlsArea.ThousandThreeHundred, "1300"],
  [MlsArea.ThousandFourHundred, "1400"],
  [MlsArea.ThousandFiveHundred, "1500"],
  [MlsArea.ThousandSixHundred, "1600"],
  [MlsArea.ThousandSevenHundred, "1700"],
  [MlsArea.ThousandEightHundred, "1800"],
  [MlsArea.ThousandEightHundredOne, "1801"],
  [MlsArea.ThousandEightHundredTwo, "1802"],
  [MlsArea.ThousandEightHundredThree, "1803"],
  [MlsArea.ThousandEightHundredFour, "1804"],
  [MlsArea.ThousandNineHundred, "1900"],
  [MlsArea.TwoThousand, "2000"],
  [MlsArea.TwoThousandOne, "2001"],
  [MlsArea.TwoThousandTwo, "2002"],
  [MlsArea.TwoThousandThree, "2003"],
  [MlsArea.TwoThousandFour, "2004"],
  [MlsArea.TwoThousandOneHundred, "2100"],
  [MlsArea.TwoThousandTwoHundred, "2200"],
  [MlsArea.TwoThousandThreeHundred, "2300"],
  [MlsArea.TwoThousandThreeHundredOne, "2301"],
  [MlsArea.TwoThousandThreeHundredTwo, "2302"],
  [MlsArea.TwoThousandThreeHundredThree, "2303"],
  [MlsArea.TwoThousandThreeHundredFour, "2304"],
  [MlsArea.TwoThousandFourHundred, "2400"],
  [MlsArea.TwoThousandFiveHundred, "2500"],
  [MlsArea.TwoThousandFiveHundredOne, "2501"],
  [MlsArea.TwoThousandFiveHundredTwo, "2502"],
  [MlsArea.TwoThousandFiveHundredThree, "2503"],
  [MlsArea.TwoThousandFiveHundredFour, "2504"],
  [MlsArea.TwoThousandFiveHundredFive, "2505"],
  [MlsArea.TwoThousandFiveHundredSix, "2506"],
  [MlsArea.TwoThousandFiveHundredSeven, "2507"],
  [MlsArea.TwoThousandFiveHundredEight, "2508"],
  [MlsArea.TwoThousandFiveHundredNine, "2509"],
  [MlsArea.TwoThousandFiveHundredTen, "2510"],
  [MlsArea.TwoThousandSixHundred, "2600"],
  [MlsArea.TwoThousandSixHundredOne, "2601"],
  [MlsArea.TwoThousandSixHundredTwo, "2602"],
  [MlsArea.TwoThousandSixHundredThree, "2603"],
  [MlsArea.TwoThousandSixHundredFour, "2604"],
  [MlsArea.TwoThousandSixHundredFive, "2605"],
  [MlsArea.TwoThousandSixHundredSix, "2606"],
  [MlsArea.TwoThousandSixHundredSeven, "2607"],
  [MlsArea.TwoThousandSixHundredEight, "2608"],
  [MlsArea.TwoThousandSixHundredNine, "2609"],
  [MlsArea.TwoThousandSixHundredTen, "2610"],
  [MlsArea.TwoThousandSixHundredEleven, "2611"],
  [MlsArea.TwoThousandSixHundredTwelve, "2612"],
  [MlsArea.TwoThousandSixHundredThirteen, "2613"],
  [MlsArea.TwoThousandSixHundredFourteen, "2614"],
  [MlsArea.TwoThousandSixHundredFifteen, "2615"],
  [MlsArea.TwoThousandSixHundredSixteen, "2616"],
  [MlsArea.TwoThousandSixHundredSevenTeen, "2617"],
  [MlsArea.TwoThousandSixHundredEighteen, "2618"],
  [MlsArea.TwoThousandSixHundredNineteen, "2619"],
  [MlsArea.TwoThousandSixHundredTwenty, "2620"],
  [MlsArea.TwoThousandSixHundredTwentyOne, "2621"],
  [MlsArea.TwoThousandSixHundredTwentyTwo, "2622"],
  [MlsArea.TwoThousandSixHundredTwentyThree, "2623"],
  [MlsArea.TwoThousandSixHundredTwentyFour, "2624"],
  [MlsArea.TwoThousandSevenHundred, "2700"],
  [MlsArea.TwoThousandSevenHundredOne, "2701"],
  [MlsArea.TwoThousandSevenHundredTwo, "2702"],
  [MlsArea.TwoThousandSevenHundredThree, "2703"],
  [MlsArea.TwoThousandSevenHundredFour, "2704"],
  [MlsArea.TwoThousandSevenHundredFive, "2705"],
  [MlsArea.TwoThousandSevenHundredSix, "2706"],
  [MlsArea.TwoThousandSevenHundredSeven, "2707"],
  [MlsArea.TwoThousandSevenHundredEight, "2708"],
  [MlsArea.TwoThousandSevenHundredNine, "2709"],
  [MlsArea.TwoThousandEightHundred, "2800"],
  [MlsArea.TwoThousandNineHundred, "2900"],
  [MlsArea.ThreeThousand, "3000"],
  [MlsArea.ThreeThousandOneHundred, "3100"]
]);
