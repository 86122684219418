import React, { Fragment } from "react";

import { LockedStatus } from "core/enums";
import { ToolbarAlertStyled as AlertStyled } from "core/styles";

export function LockedStatusAlert({
  status
}: {
  status: LockedStatus;
}): JSX.Element {
  if (
    status == LockedStatus.LockedBySystem ||
    status == LockedStatus.LockedByUser
  ) {
    return <AlertStyled severity="warning">Awaiting MLS Update</AlertStyled>;
  }

  if (status == LockedStatus.LockedButNotSubmitted) {
    return (
      <AlertStyled severity="warning">Saved but not Submitted</AlertStyled>
    );
  }

  if (status == LockedStatus.ReturnedByMlsAdmin) {
    return (
      <AlertStyled severity="warning">
        Returned by MLS Department - Please, review notes section
      </AlertStyled>
    );
  }

  return <Fragment></Fragment>;
}
