import { styled } from "@mui/material";

const PREFIX = "HusaFormSteps";
const formStepsClasses = {
  subtitle: `${PREFIX}-subtitle`,
  buttonGroup: `${PREFIX}-buttonGroup`
};

const FormStepsStyled = styled("div")(({ theme }) => ({
  [`& .${formStepsClasses.subtitle}`]: {
    margin: theme.spacing(1),
    textAlign: "center"
  },
  [`& .${formStepsClasses.buttonGroup}`]: {
    marginTop: theme.spacing(2),
    textAlign: "center"
  }
}));

export { FormStepsStyled, formStepsClasses };
