export enum TypeCategory {
  Condominium = "condominium",
  SingleFamilyResidence = "singleFamilyResidence",
  Townhouse = "townhouse"
}
export const TypeCategoryLabel = new Map<TypeCategory, string>([
  [TypeCategory.Condominium, "Condominium"],
  [TypeCategory.Townhouse, "Townhome"],
  [TypeCategory.SingleFamilyResidence, "Single Family Residence"]
]);
