import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { StoriesFeatures, StoriesFeaturesLabel } from "markets/amarillo/enums";

const roomNumberRule = (
  label: string,
  requiredToSubmit = true
): FieldRulesProps => ({
  label,
  maxLength: 1,
  requiredToSubmit,
  numberFormat: {
    max: 9
  }
});

export const spacesDimensionsRules = {
  numBedrooms: roomNumberRule("# Bedrooms", true),
  numBathrooms: roomNumberRule("# Bathrooms", true),
  storiesFeatures: {
    requiredToSubmit: true,
    label: "Stories/Levels",
    options: getOptionsFromEnumAndMap(
      Object.values(StoriesFeatures),
      StoriesFeaturesLabel
    )
  }
};
