export enum RoomLevel {
  Basement = "basement",
  MainLevel = "mainLevel",
  SecondLevel = "secondLevel",
  ThirdLevel = "thirdLevel"
}

export const RoomLevelLabel = new Map<RoomLevel, string>([
  [RoomLevel.Basement, "Basement"],
  [RoomLevel.MainLevel, "Main Level"],
  [RoomLevel.SecondLevel, "2nd Level"],
  [RoomLevel.ThirdLevel, "3rd Level"]
]);
