export enum WaterAccessType {
  BoatDock = "boatDock",
  BoatHouse = "boatHouse",
  BoatLift = "boatLift",
  BoatRamp = "boatRamp",
  BoatSlip = "boatSlip",
  CommonDock = "commonDock",
  CommonRamp = "commonRamp",
  PublicDock = "publicDock",
  PublicRamp = "publicRamp",
  Shore = "shore",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const WaterAccessTypeLabel = new Map<WaterAccessType, string>([
  [WaterAccessType.BoatDock, "Boat Dock"],
  [WaterAccessType.BoatHouse, "Boat House"],
  [WaterAccessType.BoatLift, "Boat Lift"],
  [WaterAccessType.BoatRamp, "Boat Ramp"],
  [WaterAccessType.BoatSlip, "Boat Slip"],
  [WaterAccessType.CommonDock, "Common Dock"],
  [WaterAccessType.CommonRamp, "Common Ramp"],
  [WaterAccessType.PublicDock, "Public Dock"],
  [WaterAccessType.PublicRamp, "Public Ramp"],
  [WaterAccessType.Shore, "Shore"],
  [WaterAccessType.OtherSeeRemarks, "Other-See Remarks"]
]);
