import { FormRules } from "@homesusa/form";
import { spacesDimensionsRules } from "../../sections";
import {
  getOptionsFromEnumAndMap,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import {
  SqFtSource,
  SqFtSourceLabel
} from "markets/amarillo/enums/sqft-source.enum";
import { RealtorType, RealtorTypeLabel } from "markets/amarillo/enums";

export const spacesDimensionsInfoRules: FormRules = {
  ...spacesDimensionsRules,
  sqFtSource: {
    label: "Square Foot Source",
    options: getOptionsFromEnumAndMap(
      Object.values(SqFtSource),
      SqFtSourceLabel
    ),
    requiredToSubmit: true
  },
  realtorType: {
    label: "Realtor.COM Type",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndRecord(
      Object.values(RealtorType),
      RealtorTypeLabel
    )
  },
  numLivingAreas: {
    label: "Living Areas",
    numberFormat: {
      max: 4
    },
    maxLength: 1
  },
  numQuarterBaths: {
    label: "1/4 Baths",
    numberFormat: {
      max: 6,
      min: 0
    },
    maxLength: 1,
    requiredToSubmit: true
  },
  numHalfBaths: {
    label: "1/2 Baths",
    numberFormat: {
      max: 6,
      min: 0
    },
    maxLength: 1,
    requiredToSubmit: true
  },
  numThreeQuartersBaths: {
    label: "3/4 Baths",
    numberFormat: {
      max: 6,
      min: 0
    },
    maxLength: 1,
    requiredToSubmit: true
  },
  numFullBaths: {
    label: "Full Baths",
    numberFormat: {
      max: 6,
      min: 0
    },
    maxLength: 1,
    requiredToSubmit: true
  }
};
