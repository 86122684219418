export enum LotSize {
  ZeroAcre = "zeroAcre",
  OneAcre = "oneAcre",
  ThreeAcres = "threeAcres",
  FiveAcres = "fiveAcres",
  TenAcres = "tenAcres",
  CondoTownhome = "condoTownhome",
  ZeroLot = "zeroLot"
}

export const LotSizeLabel = new Map<LotSize, string>([
  [LotSize.ZeroAcre, "Less Than .5 Acre (not Zero)"],
  [LotSize.OneAcre, ".5 Acre to .99 Acre"],
  [LotSize.ThreeAcres, "1 Acre to 2.99 Acres"],
  [LotSize.FiveAcres, "3 Acres to 4.99 Acres"],
  [LotSize.TenAcres, "5 Acres to < 9.99 Acres"],
  [LotSize.CondoTownhome, "Condo/Townhome Lot"],
  [LotSize.ZeroLot, "Zero Lot"]
]);
