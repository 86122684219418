export enum HeatingFuel {
  Electric = "electric",
  Solar = "solar",
  Propane = "propane",
  PropaneLeased = "propaneLeased",
  Other = "other",
  NaturalGas = "naturalGas"
}

export const HeatingFuelLabel = new Map<HeatingFuel, string>([
  [HeatingFuel.Electric, "Electric"],
  [HeatingFuel.Solar, "Solar"],
  [HeatingFuel.Propane, "Propane"],
  [HeatingFuel.PropaneLeased, "Propane Leased"],
  [HeatingFuel.Other, "Other"],
  [HeatingFuel.NaturalGas, "Natural Gas"]
]);
