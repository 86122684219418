export enum Garage {
  One = "one",
  Two = "two",
  Three = "three",
  None = "none"
}

export const GarageLabel = new Map<Garage, string>([
  [Garage.One, "1"],
  [Garage.Two, "2"],
  [Garage.Three, "3+"],
  [Garage.None, "None"]
]);
