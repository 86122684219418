export enum FireplaceDescription {
  Bathroom = "bathroom",
  Bedroom = "bedroom",
  Circulating = "circulating",
  Decorative = "decorative",
  Den = "den",
  DiningRoom = "diningRoom",
  DoubleSided = "doubleSided",
  Electric = "electric",
  FamilyRoom = "familyRoom",
  GameRoom = "gameRoom",
  Gas = "gas",
  GasLogs = "gasLogs",
  GasStarter = "gasStarter",
  GlassEnclosedScreen = "glassEnclosedScreen",
  GreatFamilyRoom = "greatFamilyRoom",
  Heatilator = "heatilator",
  Insert = "insert",
  LivingRoom = "livingRoom",
  Masonry = "masonry",
  Master = "master",
  Metal = "metal",
  Outside = "outside",
  SeeThrough = "seeThrough",
  StoneRockBrick = "stoneRockBrick",
  Ventless = "ventless",
  WoodBurning = "woodBurning",
  None = "none"
}

export const FireplaceDescriptionLabel = new Map<FireplaceDescription, string>([
  [FireplaceDescription.Bathroom, "Bathroom"],
  [FireplaceDescription.Bedroom, "Bedroom"],
  [FireplaceDescription.Circulating, "Circulating"],
  [FireplaceDescription.Decorative, "Decorative"],
  [FireplaceDescription.Den, "Den"],
  [FireplaceDescription.DiningRoom, "Dining Room"],
  [FireplaceDescription.DoubleSided, "Double Sided"],
  [FireplaceDescription.Electric, "Electric"],
  [FireplaceDescription.FamilyRoom, "Family Room"],
  [FireplaceDescription.GameRoom, "Game Room"],
  [FireplaceDescription.Gas, "Gas"],
  [FireplaceDescription.GasLogs, "Gas Logs"],
  [FireplaceDescription.GasStarter, "Gas Starter"],
  [FireplaceDescription.GlassEnclosedScreen, "Glass/Enclosed Screen"],
  [FireplaceDescription.GreatFamilyRoom, "Great/Family Room"],
  [FireplaceDescription.Heatilator, "Heatilator"],
  [FireplaceDescription.Insert, "Insert"],
  [FireplaceDescription.LivingRoom, "Living Room"],
  [FireplaceDescription.Masonry, "Masonry"],
  [FireplaceDescription.Master, "Master"],
  [FireplaceDescription.Metal, "Metal"],
  [FireplaceDescription.Outside, "Outside"],
  [FireplaceDescription.SeeThrough, "See Through"],
  [FireplaceDescription.StoneRockBrick, "Stone/Rock/Brick"],
  [FireplaceDescription.Ventless, "Ventless"],
  [FireplaceDescription.WoodBurning, "Wood Burning"],
  [FireplaceDescription.None, "None"]
]);
