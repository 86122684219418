export enum FrontFaces {
  East = "east",
  North = "north",
  NorthEast = "northEast",
  NorthWest = "northWest",
  South = "south",
  SouthEast = "southEast",
  SouthWest = "southWest",
  West = "west"
}
export const FrontFacesLabel = new Map<FrontFaces, string>([
  [FrontFaces.East, "East"],
  [FrontFaces.North, "North"],
  [FrontFaces.NorthEast, "North-East"],
  [FrontFaces.NorthWest, "North-West"],
  [FrontFaces.South, "South"],
  [FrontFaces.SouthEast, "South-East"],
  [FrontFaces.SouthWest, "South-West"],
  [FrontFaces.West, "West"]
]);
