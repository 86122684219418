export enum DocumentsAvailable {
  AerialPhotos = "aerialPhotos",
  ALTASurvey = "altaSurvey",
  APODBackupAvailable = "apodBackupAvailable",
  Appraisal = "appraisal",
  ArcheologicalSite = "archeologicalSite",
  BuildingPlans = "buildingPlans",
  CondoDocsAvailable = "condoDocsAvailable",
  CostEstimates = "costEstimates",
  DeedRestrictions = "deedRestrictions",
  DevelopmentPlan = "developmentPlan",
  ECRAClearance = "ecraClearance",
  EnergyConservationAuditDisclosure = "energyConservationAuditDisclosure",
  EnvironmentandSustainability = "energyEnvironmentAndSustainability",
  EngineeringReport = "engineeringReport",
  EnvironmentalStudy = "environmentalStudy",
  EnvironmentalStudyComplete = "environmentalStudyComplete",
  FeasibilityStudy = "feasibilityStudy",
  FinancialStatement = "financialStatement",
  Financing = "financing",
  FlowageEasement = "flowageEasement",
  Geological = "geological",
  Historical = "historical",
  IncomeAndExpenseStatement = "incomeAndExpenseStatement",
  Leases = "leases",
  LegalDocuments = "legalDocuments",
  MunicipalUtilityDistrictMUD = "municipalUtilityDistrictMUD",
  OakWiltTest = "oakWiltTest",
  OffSiteRequirements = "offSiteRequirements",
  ProfitAndLossStatement = "profitAndLossStatement",
  PercTestNeeded = "percTestNeeded",
  PercTestResultsMap = "percTestResultsMap",
  PublicImprovementDistrict = "publicImprovementDistrict",
  RentRoll = "rentRoll",
  ScheduleofPersonalProperty = "scheduleofPersonalProperty",
  SecurityCodes = "securityCodes",
  SepticCertification = "septicCertification",
  SitePlan = "sitePlan",
  SoilTest = "soilTest",
  SpecialAssessmentDistrict = "specialAssessmentDistrict",
  StandardIndustrialClassification = "standardIndustrialClassification",
  Survey = "survey",
  TaxReturnAvailable = "taxReturnAvailable",
  TopographyMap = "topographyMap",
  UnitMixSchedule = "unitMixSchedule",
  UtilityEasement = "utilityEasement",
  VendorLeases = "vendorLeases",
  WaterCapacityQualityReport = "waterCapacityQualityReport",
  WaterWellReport = "waterWellReport",
  WaterWellTest = "waterWellTest",
  WetlandsApprovalWaiver = "wetlandsApprovalWaiver",
  WetlandsDelineationMap = "wetlandsDelineationMap",
  NoneAvailable = "noneAvailable",
  SeeRemarks = "seeRemarks"
}
export const DocumentsAvailableLabel = new Map<DocumentsAvailable, string>([
  [DocumentsAvailable.AerialPhotos, "Aerial Photos"],
  [DocumentsAvailable.ALTASurvey, "ALTA Survey"],
  [DocumentsAvailable.APODBackupAvailable, "APOD/Backup Available"],
  [DocumentsAvailable.Appraisal, "Appraisal"],
  [DocumentsAvailable.ArcheologicalSite, "Archeological Site"],
  [DocumentsAvailable.BuildingPlans, "Building Plans"],
  [DocumentsAvailable.CondoDocsAvailable, "Condo Docs Available"],
  [DocumentsAvailable.CostEstimates, "Cost Estimates"],
  [DocumentsAvailable.DeedRestrictions, "Deed Restrictions"],
  [DocumentsAvailable.DevelopmentPlan, "Development Plan"],
  [DocumentsAvailable.ECRAClearance, "ECRA Clearance"],
  [
    DocumentsAvailable.EnergyConservationAuditDisclosure,
    "Energy Conservation Audit Disclosure"
  ],
  [DocumentsAvailable.EnvironmentandSustainability, "Energy"],
  [DocumentsAvailable.EngineeringReport, "Engineering Report"],
  [DocumentsAvailable.EnvironmentalStudy, "Environmental Study"],
  [
    DocumentsAvailable.EnvironmentalStudyComplete,
    "Environmental Study Complete"
  ],
  [DocumentsAvailable.FeasibilityStudy, "Feasibility Study"],
  [DocumentsAvailable.FinancialStatement, "Financial Statement"],
  [DocumentsAvailable.Financing, "Financing"],
  [DocumentsAvailable.FlowageEasement, "Flowage Easement"],
  [DocumentsAvailable.Geological, "Geological"],
  [DocumentsAvailable.Historical, "Historical"],
  [DocumentsAvailable.IncomeAndExpenseStatement, "Income & Expense Statement"],
  [DocumentsAvailable.Leases, "Leases"],
  [DocumentsAvailable.LegalDocuments, "Legal Documents"],
  [
    DocumentsAvailable.MunicipalUtilityDistrictMUD,
    "Municipal Utility District (MUD)"
  ],
  [DocumentsAvailable.OakWiltTest, "Oak Wilt Test"],
  [DocumentsAvailable.OffSiteRequirements, "Off-Site Requirements"],
  [DocumentsAvailable.ProfitAndLossStatement, "Profit & Loss Statement"],
  [DocumentsAvailable.PercTestNeeded, "Perc Test Needed"],
  [DocumentsAvailable.PercTestResultsMap, "Perc Test Results/Map"],
  [DocumentsAvailable.PublicImprovementDistrict, "Public Improvement District"],
  [DocumentsAvailable.RentRoll, "Rent Roll"],
  [
    DocumentsAvailable.ScheduleofPersonalProperty,
    "Schedule of Personal Property"
  ],
  [DocumentsAvailable.SecurityCodes, "Security Codes"],
  [DocumentsAvailable.SepticCertification, "Septic Certification"],
  [DocumentsAvailable.SitePlan, "Site Plan"],
  [DocumentsAvailable.SoilTest, "Soil Test"],
  [DocumentsAvailable.SpecialAssessmentDistrict, "Special Assessment District"],
  [
    DocumentsAvailable.StandardIndustrialClassification,
    "Standard Industrial Classification"
  ],
  [DocumentsAvailable.Survey, "Survey"],
  [DocumentsAvailable.TaxReturnAvailable, "Tax Return Available"],
  [DocumentsAvailable.TopographyMap, "Topography Map"],
  [DocumentsAvailable.UnitMixSchedule, "Unit Mix Schedule"],
  [DocumentsAvailable.UtilityEasement, "Utility Easement"],
  [DocumentsAvailable.VendorLeases, "Vendor Leases"],
  [
    DocumentsAvailable.WaterCapacityQualityReport,
    "Water Capacity/Quality Report"
  ],
  [DocumentsAvailable.WaterWellReport, "Water/Well Report"],
  [DocumentsAvailable.WaterWellTest, "Water/Well Test"],
  [DocumentsAvailable.WetlandsApprovalWaiver, "Wetlands Approval/Waiver"],
  [DocumentsAvailable.WetlandsDelineationMap, "Wetlands Delineation Map"],
  [DocumentsAvailable.NoneAvailable, "None Available"],
  [DocumentsAvailable.SeeRemarks, "See Remarks"]
]);
