export enum JuniorSchool {
  Abell = "abell",
  Academy = "academy",
  Alamoheigh = "alamoheigh",
  Albany = "albany",
  Alvarado = "alvarado",
  Alvin = "alvin",
  AndersonShiro = "andersonShiro",
  Apollo = "apollo",
  AppleSprings = "appleSprings",
  Arp = "arp",
  Austin = "austin",
  Azle = "azle",
  Bailey = "bailey",
  Ballinger = "ballinger",
  Banquete = "banquete",
  Barnett = "barnett",
  Baycity = "baycity",
  Beckendorff = "beckendorff",
  Bedford = "bedford",
  Bellville = "bellville",
  BigSpring = "bigSpring",
  Bigsandy = "bigsandy",
  Bloominggr = "bloominggr",
  Boles = "boles",
  Bonnette = "bonnette",
  Bowie = "bowie",
  Brackett = "brackett",
  Breckenrid = "breckenrid",
  Brownsboro = "brownsboro",
  BrucevilleEddy = "brucevilleEddy",
  Buffalo = "buffalo",
  Buna = "buna",
  Burnett = "burnett",
  Cameron = "cameron",
  Canton = "canton",
  Canyon = "canyon",
  Carroll = "carroll",
  Carter = "carter",
  Carthage = "carthage",
  Celeste = "celeste",
  Celina = "celina",
  Centerville = "centerville",
  Central = "central",
  Chapelhill = "chapelhill",
  CharlesBaxter = "charlesBaxter",
  Childress = "childress",
  ChoiceOfSchool = "choiceOfSchool",
  Cisco = "cisco",
  CityView = "cityView",
  Clarendon = "clarendon",
  Clark = "clark",
  Claude = "claude",
  Clint = "clint",
  Clyde = "clyde",
  Columbus = "columbus",
  Connally = "connally",
  Cooper = "cooper",
  Copperasco = "copperasco",
  Coronado = "coronado",
  CorriganCamden = "corriganCamden",
  Crockett = "crockett",
  Crossroads = "crossroads",
  Crowell = "crowell",
  Cuero = "cuero",
  Daingerfie = "daingerfie",
  Detroit = "detroit",
  Devers = "devers",
  Dew = "dew",
  Dobie = "dobie",
  Dumas = "dumas",
  Eaglepass = "eaglepass",
  Ector = "ector",
  Edna = "edna",
  Electra = "electra",
  Elpaso = "elpaso",
  Ennis = "ennis",
  Estacado = "estacado",
  Eula = "eula",
  Euless = "euless",
  Evant = "evant",
  EvelynLoveColeman = "evelynLoveColeman",
  Everman = "everman",
  Fairfield = "fairfield",
  Fairview = "fairview",
  Farmersvil = "farmersvil",
  Farwell = "farwell",
  Ferguson = "ferguson",
  Ferris = "ferris",
  Finley = "finley",
  Flourbluff = "flourbluff",
  ForestMeadow = "forestMeadow",
  Forestmead = "forestmead",
  Forsan = "forsan",
  Forte = "forte",
  Friona = "friona",
  GWHarby = "gwHarby",
  Gainesvill = "gainesvill",
  Ganado = "ganado",
  Gatesville = "gatesville",
  Georgewest = "georgewest",
  Gilmer = "gilmer",
  GlenRose = "glenRose",
  Goodnight = "goodnight",
  Graham = "graham",
  Grandview = "grandview",
  Grapeland = "grapeland",
  Gray = "gray",
  Groveton = "groveton",
  Gunn = "gunn",
  Hallsville = "hallsville",
  Hamilton = "hamilton",
  Hardin = "hardin",
  Harleton = "harleton",
  Harmony = "harmony",
  Harwood = "harwood",
  Hearne = "hearne",
  Henrietta = "henrietta",
  Hico = "hico",
  Hillsboro = "hillsboro",
  Hooks = "hooks",
  Howard = "howard",
  HughesSprings = "hughesSprings",
  Hurst = "hurst",
  Hutcheson = "hutcheson",
  Industrial = "industrial",
  Iraan = "iraan",
  Isbill = "isbill",
  Itasca = "itasca",
  Jasper = "jasper",
  Jeffries = "jeffries",
  Karnescity = "karnescity",
  Katy = "katy",
  Keene = "keene",
  Kemp = "kemp",
  Kennedale = "kennedale",
  Kermit = "kermit",
  Kirbyville = "kirbyville",
  Knox = "knox",
  LaVega = "laVega",
  Lakehighla = "lakehighla",
  Leon = "leon",
  LeonTaylor = "leonTaylor",
  Leonard = "leonard",
  Liberty = "liberty",
  Lincoln = "lincoln",
  Linkildare = "linkildare",
  Livingston = "livingston",
  Llano = "llano",
  Loading = "loading",
  Lockhart = "lockhart",
  LockhartHsFreshman = "lockhartHsFreshman",
  Loraine = "loraine",
  Lovelady = "lovelady",
  LoweryFreshmanCenter = "loweryFreshmanCenter",
  LuedersAvoca = "luedersAvoca",
  Luehrs = "luehrs",
  Lytle = "lytle",
  Mabank = "mabank",
  Madisonville = "madisonville",
  Manvel = "manvel",
  Marfa = "marfa",
  Marshall = "marshall",
  Mason = "mason",
  Maypearl = "maypearl",
  McAdams = "mcAdams",
  Mcauliffe = "mcauliffe",
  Mcdowell = "mcdowell",
  Mcmillan = "mcmillan",
  Memorial = "memorial",
  Menard = "menard",
  Mexia = "mexia",
  Midway = "midway",
  Milano = "milano",
  Miles = "miles",
  Montgomery = "montgomery",
  Moreno = "moreno",
  Morton = "morton",
  Mountpleas = "mountpleas",
  Mtvernon = "mtvernon",
  Mullin = "mullin",
  Munday = "munday",
  Natalia = "natalia",
  NewWaverly = "newWaverly",
  Nichols = "nichols",
  NolanRyan = "nolanRyan",
  Norman = "norman",
  NorthShore = "northShore",
  Olney = "olney",
  Ousley = "ousley",
  PaintRock = "paintRock",
  Palacios = "palacios",
  Pampa = "pampa",
  Parkhill = "parkhill",
  Petrolia = "petrolia",
  Pinetree = "pinetree",
  PlanoEast = "planoEast",
  PlanoSenior = "planoSenior",
  PlanoWest = "planoWest",
  Ponder = "ponder",
  Poolville = "poolville",
  PrestwickK8Stem = "prestwickK8Stem",
  Pritchard = "pritchard",
  Quitman = "quitman",
  Rains = "rains",
  Rather = "rather",
  RayDCorbett = "rayDCorbett",
  Redoak = "redoak",
  Redwater = "redwater",
  Richardsno = "richardsno",
  RichardsonNorth = "richardsonNorth",
  RichardsonWest = "richardsonWest",
  Richardsow = "richardsow",
  Riesel = "riesel",
  RioHondo = "rioHondo",
  Rivercrest = "rivercrest",
  Robinson = "robinson",
  Rochelle = "rochelle",
  Rochester = "rochester",
  Rockdale = "rockdale",
  Rotan = "rotan",
  Rusk = "rusk",
  SantaAnna = "santaAnna",
  Sclee = "sclee",
  Scobee = "scobee",
  Seale = "seale",
  Shackelfor = "shackelfor",
  Snyder = "snyder",
  Somerset = "somerset",
  Somerville = "somerville",
  Sonora = "sonora",
  Springhill = "springhill",
  Stephenvil = "stephenvil",
  Stockdale = "stockdale",
  Teague = "teague",
  Travis = "travis",
  Trinity = "trinity",
  Tulia = "tulia",
  Uniongrove = "uniongrove",
  Uvalde = "uvalde",
  Valleymil = "valleymil",
  Valleyview = "valleyview",
  Van = "van",
  VanAlstyne = "vanAlstyne",
  VanHorn = "vanHorn",
  Vidor = "vidor",
  Waller = "waller",
  Warren = "warren",
  Wellington = "wellington",
  Westoso = "westoso",
  Westoverpk = "westoverpk",
  Westrusk = "westrusk",
  Westwood = "westwood",
  Whitehouse = "whitehouse",
  WichitaFalls = "wichitaFalls",
  Willspoint = "willspoint",
  Wilson = "wilson",
  Wimberley = "wimberley",
  Winters = "winters",
  Woden = "woden",
  Woodsboro = "woodsboro",
  Workman = "workman",
  Wylie = "wylie",
  WylieEast = "wylieEast",
  WylieWest = "wylieWest",
  Yoakum = "yoakum",
  Young = "young"
}

export const JuniorSchoolLabel: Record<JuniorSchool, string> = {
  [JuniorSchool.Abell]: "Abell",
  [JuniorSchool.Academy]: "Academy",
  [JuniorSchool.Alamoheigh]: "Alamoheigh",
  [JuniorSchool.Albany]: "Albany",
  [JuniorSchool.Alvarado]: "Alvarado",
  [JuniorSchool.Alvin]: "Alvin",
  [JuniorSchool.AndersonShiro]: "Anderson-Shiro",
  [JuniorSchool.Apollo]: "Apollo",
  [JuniorSchool.AppleSprings]: "Apple Springs",
  [JuniorSchool.Arp]: "Arp",
  [JuniorSchool.Austin]: "Austin",
  [JuniorSchool.Azle]: "Azle",
  [JuniorSchool.Bailey]: "Bailey",
  [JuniorSchool.Ballinger]: "Ballinger",
  [JuniorSchool.Banquete]: "Banquete",
  [JuniorSchool.Barnett]: "Barnett",
  [JuniorSchool.Baycity]: "Baycity",
  [JuniorSchool.Beckendorff]: "Beckendorff",
  [JuniorSchool.Bedford]: "Bedford",
  [JuniorSchool.Bellville]: "Bellville",
  [JuniorSchool.BigSpring]: "Big Spring",
  [JuniorSchool.Bigsandy]: "Bigsandy",
  [JuniorSchool.Bloominggr]: "Bloominggr",
  [JuniorSchool.Boles]: "Boles",
  [JuniorSchool.Bonnette]: "Bonnette",
  [JuniorSchool.Bowie]: "Bowie",
  [JuniorSchool.Brackett]: "Brackett",
  [JuniorSchool.Breckenrid]: "Breckenrid",
  [JuniorSchool.Brownsboro]: "Brownsboro",
  [JuniorSchool.BrucevilleEddy]: "Bruceville-Eddy",
  [JuniorSchool.Buffalo]: "Buffalo",
  [JuniorSchool.Buna]: "Buna",
  [JuniorSchool.Burnett]: "Burnett",
  [JuniorSchool.Cameron]: "Cameron",
  [JuniorSchool.Canton]: "Canton",
  [JuniorSchool.Canyon]: "Canyon",
  [JuniorSchool.Carroll]: "Carroll",
  [JuniorSchool.Carter]: "Carter",
  [JuniorSchool.Carthage]: "Carthage",
  [JuniorSchool.Celeste]: "Celeste",
  [JuniorSchool.Celina]: "Celina",
  [JuniorSchool.Centerville]: "Centerville",
  [JuniorSchool.Central]: "Central",
  [JuniorSchool.Chapelhill]: "Chapelhill",
  [JuniorSchool.CharlesBaxter]: "Charles Baxter",
  [JuniorSchool.Childress]: "Childress",
  [JuniorSchool.ChoiceOfSchool]: "Choice Of School",
  [JuniorSchool.Cisco]: "Cisco",
  [JuniorSchool.CityView]: "City View",
  [JuniorSchool.Clarendon]: "Clarendon",
  [JuniorSchool.Clark]: "Clark",
  [JuniorSchool.Claude]: "Claude",
  [JuniorSchool.Clint]: "Clint",
  [JuniorSchool.Clyde]: "Clyde",
  [JuniorSchool.Columbus]: "Columbus",
  [JuniorSchool.Connally]: "Connally",
  [JuniorSchool.Cooper]: "Cooper",
  [JuniorSchool.Copperasco]: "Copperasco",
  [JuniorSchool.Coronado]: "Coronado",
  [JuniorSchool.CorriganCamden]: "Corrigan-Camden",
  [JuniorSchool.Crockett]: "Crockett",
  [JuniorSchool.Crossroads]: "Crossroads",
  [JuniorSchool.Crowell]: "Crowell",
  [JuniorSchool.Cuero]: "Cuero",
  [JuniorSchool.Daingerfie]: "Daingerfie",
  [JuniorSchool.Detroit]: "Detroit",
  [JuniorSchool.Devers]: "Devers",
  [JuniorSchool.Dew]: "Dew",
  [JuniorSchool.Dobie]: "Dobie",
  [JuniorSchool.Dumas]: "Dumas",
  [JuniorSchool.Eaglepass]: "Eaglepass",
  [JuniorSchool.Ector]: "Ector",
  [JuniorSchool.Edna]: "Edna",
  [JuniorSchool.Electra]: "Electra",
  [JuniorSchool.Elpaso]: "Elpaso",
  [JuniorSchool.Ennis]: "Ennis",
  [JuniorSchool.Estacado]: "Estacado",
  [JuniorSchool.Eula]: "Eula",
  [JuniorSchool.Euless]: "Euless",
  [JuniorSchool.Evant]: "Evant",
  [JuniorSchool.EvelynLoveColeman]: "Evelyn Love Coleman",
  [JuniorSchool.Everman]: "Everman",
  [JuniorSchool.Fairfield]: "Fairfield",
  [JuniorSchool.Fairview]: "Fairview",
  [JuniorSchool.Farmersvil]: "Farmersvil",
  [JuniorSchool.Farwell]: "Farwell",
  [JuniorSchool.Ferguson]: "Ferguson",
  [JuniorSchool.Ferris]: "Ferris",
  [JuniorSchool.Finley]: "Finley",
  [JuniorSchool.Flourbluff]: "Flourbluff",
  [JuniorSchool.ForestMeadow]: "Forest Meadow",
  [JuniorSchool.Forestmead]: "Forestmead",
  [JuniorSchool.Forsan]: "Forsan",
  [JuniorSchool.Forte]: "Forte",
  [JuniorSchool.Friona]: "Friona",
  [JuniorSchool.GWHarby]: "G W Harby",
  [JuniorSchool.Gainesvill]: "Gainesvill",
  [JuniorSchool.Ganado]: "Ganado",
  [JuniorSchool.Gatesville]: "Gatesville",
  [JuniorSchool.Georgewest]: "Georgewest",
  [JuniorSchool.Gilmer]: "Gilmer",
  [JuniorSchool.GlenRose]: "Glen Rose",
  [JuniorSchool.Goodnight]: "Goodnight",
  [JuniorSchool.Graham]: "Graham",
  [JuniorSchool.Grandview]: "Grandview",
  [JuniorSchool.Grapeland]: "Grapeland",
  [JuniorSchool.Gray]: "Gray",
  [JuniorSchool.Groveton]: "Groveton",
  [JuniorSchool.Gunn]: "Gunn",
  [JuniorSchool.Hallsville]: "Hallsville",
  [JuniorSchool.Hamilton]: "Hamilton",
  [JuniorSchool.Hardin]: "Hardin",
  [JuniorSchool.Harleton]: "Harleton",
  [JuniorSchool.Harmony]: "Harmony",
  [JuniorSchool.Harwood]: "Harwood",
  [JuniorSchool.Hearne]: "Hearne",
  [JuniorSchool.Henrietta]: "Henrietta",
  [JuniorSchool.Hico]: "Hico",
  [JuniorSchool.Hillsboro]: "Hillsboro",
  [JuniorSchool.Hooks]: "Hooks",
  [JuniorSchool.Howard]: "Howard",
  [JuniorSchool.HughesSprings]: "Hughes Springs",
  [JuniorSchool.Hurst]: "Hurst",
  [JuniorSchool.Hutcheson]: "Hutcheson",
  [JuniorSchool.Industrial]: "Industrial",
  [JuniorSchool.Iraan]: "Iraan",
  [JuniorSchool.Isbill]: "Isbill",
  [JuniorSchool.Itasca]: "Itasca",
  [JuniorSchool.Jasper]: "Jasper",
  [JuniorSchool.Jeffries]: "Jeffries",
  [JuniorSchool.Karnescity]: "Karnescity",
  [JuniorSchool.Katy]: "Katy",
  [JuniorSchool.Keene]: "Keene",
  [JuniorSchool.Kemp]: "Kemp",
  [JuniorSchool.Kennedale]: "Kennedale",
  [JuniorSchool.Kermit]: "Kermit",
  [JuniorSchool.Kirbyville]: "Kirbyville",
  [JuniorSchool.Knox]: "Knox",
  [JuniorSchool.LaVega]: "La Vega",
  [JuniorSchool.Lakehighla]: "Lakehighla",
  [JuniorSchool.Leon]: "Leon",
  [JuniorSchool.LeonTaylor]: "Leon Taylor",
  [JuniorSchool.Leonard]: "Leonard",
  [JuniorSchool.Liberty]: "Liberty",
  [JuniorSchool.Lincoln]: "Lincoln",
  [JuniorSchool.Linkildare]: "Linkildare",
  [JuniorSchool.Livingston]: "Livingston",
  [JuniorSchool.Llano]: "Llano",
  [JuniorSchool.Loading]: "Loading",
  [JuniorSchool.Lockhart]: "Lockhart",
  [JuniorSchool.LockhartHsFreshman]: "Lockhart Hs Freshman",
  [JuniorSchool.Loraine]: "Loraine",
  [JuniorSchool.Lovelady]: "Lovelady",
  [JuniorSchool.LoweryFreshmanCenter]: "Lowery Freshman Center",
  [JuniorSchool.LuedersAvoca]: "Lueders-Avoca",
  [JuniorSchool.Luehrs]: "Luehrs",
  [JuniorSchool.Lytle]: "Lytle",
  [JuniorSchool.Mabank]: "Mabank",
  [JuniorSchool.Madisonville]: "Madisonville",
  [JuniorSchool.Manvel]: "Manvel",
  [JuniorSchool.Marfa]: "Marfa",
  [JuniorSchool.Marshall]: "Marshall",
  [JuniorSchool.Mason]: "Mason",
  [JuniorSchool.Maypearl]: "Maypearl",
  [JuniorSchool.McAdams]: "McAdams",
  [JuniorSchool.Mcauliffe]: "Mcauliffe",
  [JuniorSchool.Mcdowell]: "Mcdowell",
  [JuniorSchool.Mcmillan]: "Mcmillan",
  [JuniorSchool.Memorial]: "Memorial",
  [JuniorSchool.Menard]: "Menard",
  [JuniorSchool.Mexia]: "Mexia",
  [JuniorSchool.Midway]: "Midway",
  [JuniorSchool.Milano]: "Milano",
  [JuniorSchool.Miles]: "Miles",
  [JuniorSchool.Montgomery]: "Montgomery",
  [JuniorSchool.Moreno]: "Moreno",
  [JuniorSchool.Morton]: "Morton",
  [JuniorSchool.Mountpleas]: "Mountpleas",
  [JuniorSchool.Mtvernon]: "Mtvernon",
  [JuniorSchool.Mullin]: "Mullin",
  [JuniorSchool.Munday]: "Munday",
  [JuniorSchool.Natalia]: "Natalia",
  [JuniorSchool.NewWaverly]: "New Waverly",
  [JuniorSchool.Nichols]: "Nichols",
  [JuniorSchool.NolanRyan]: "Nolan Ryan",
  [JuniorSchool.Norman]: "Norman",
  [JuniorSchool.NorthShore]: "North Shore",
  [JuniorSchool.Olney]: "Olney",
  [JuniorSchool.Ousley]: "Ousley",
  [JuniorSchool.PaintRock]: "Paint Rock",
  [JuniorSchool.Palacios]: "Palacios",
  [JuniorSchool.Pampa]: "Pampa",
  [JuniorSchool.Parkhill]: "Parkhill",
  [JuniorSchool.Petrolia]: "Petrolia",
  [JuniorSchool.Pinetree]: "Pinetree",
  [JuniorSchool.PlanoEast]: "Plano East",
  [JuniorSchool.PlanoSenior]: "Plano Senior",
  [JuniorSchool.PlanoWest]: "Plano West",
  [JuniorSchool.Ponder]: "Ponder",
  [JuniorSchool.Poolville]: "Poolville",
  [JuniorSchool.PrestwickK8Stem]: "Prestwick K-8 Stem",
  [JuniorSchool.Pritchard]: "Pritchard",
  [JuniorSchool.Quitman]: "Quitman",
  [JuniorSchool.Rains]: "Rains",
  [JuniorSchool.Rather]: "Rather",
  [JuniorSchool.RayDCorbett]: "Ray D Corbett",
  [JuniorSchool.Redoak]: "Redoak",
  [JuniorSchool.Redwater]: "Redwater",
  [JuniorSchool.Richardsno]: "Richardsno",
  [JuniorSchool.RichardsonNorth]: "Richardson North",
  [JuniorSchool.RichardsonWest]: "Richardson West",
  [JuniorSchool.Richardsow]: "Richardsow",
  [JuniorSchool.Riesel]: "Riesel",
  [JuniorSchool.RioHondo]: "Rio Hondo",
  [JuniorSchool.Rivercrest]: "Rivercrest",
  [JuniorSchool.Robinson]: "Robinson",
  [JuniorSchool.Rochelle]: "Rochelle",
  [JuniorSchool.Rochester]: "Rochester",
  [JuniorSchool.Rockdale]: "Rockdale",
  [JuniorSchool.Rotan]: "Rotan",
  [JuniorSchool.Rusk]: "Rusk",
  [JuniorSchool.SantaAnna]: "Santa Anna",
  [JuniorSchool.Sclee]: "Sclee",
  [JuniorSchool.Scobee]: "Scobee",
  [JuniorSchool.Seale]: "Seale",
  [JuniorSchool.Shackelfor]: "Shackelfor",
  [JuniorSchool.Snyder]: "Snyder",
  [JuniorSchool.Somerset]: "Somerset",
  [JuniorSchool.Somerville]: "Somerville",
  [JuniorSchool.Sonora]: "Sonora",
  [JuniorSchool.Springhill]: "Springhill",
  [JuniorSchool.Stephenvil]: "Stephenvil",
  [JuniorSchool.Stockdale]: "Stockdale",
  [JuniorSchool.Teague]: "Teague",
  [JuniorSchool.Travis]: "Travis",
  [JuniorSchool.Trinity]: "Trinity",
  [JuniorSchool.Tulia]: "Tulia",
  [JuniorSchool.Uniongrove]: "Uniongrove",
  [JuniorSchool.Uvalde]: "Uvalde",
  [JuniorSchool.Valleymil]: "Valleymil",
  [JuniorSchool.Valleyview]: "Valleyview",
  [JuniorSchool.Van]: "Van",
  [JuniorSchool.VanAlstyne]: "Van Alstyne",
  [JuniorSchool.VanHorn]: "Van Horn",
  [JuniorSchool.Vidor]: "Vidor",
  [JuniorSchool.Waller]: "Waller",
  [JuniorSchool.Warren]: "Warren",
  [JuniorSchool.Wellington]: "Wellington",
  [JuniorSchool.Westoso]: "Westoso",
  [JuniorSchool.Westoverpk]: "Westoverpk",
  [JuniorSchool.Westrusk]: "Westrusk",
  [JuniorSchool.Westwood]: "Westwood",
  [JuniorSchool.Whitehouse]: "Whitehouse",
  [JuniorSchool.WichitaFalls]: "Wichita Falls",
  [JuniorSchool.Willspoint]: "Willspoint",
  [JuniorSchool.Wilson]: "Wilson",
  [JuniorSchool.Wimberley]: "Wimberley",
  [JuniorSchool.Winters]: "Winters",
  [JuniorSchool.Woden]: "Woden",
  [JuniorSchool.Woodsboro]: "Woodsboro",
  [JuniorSchool.Workman]: "Workman",
  [JuniorSchool.Wylie]: "Wylie",
  [JuniorSchool.WylieEast]: "Wylie East",
  [JuniorSchool.WylieWest]: "Wylie West",
  [JuniorSchool.Yoakum]: "Yoakum",
  [JuniorSchool.Young]: "Young"
};
