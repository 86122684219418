import React from "react";
import { Format } from "@homesusa/core";
import { Columns } from "@homesusa/grid-table";
import { JsonListing } from "../interfaces/alerts/json";
import { TruncateText } from "core/components";

export const JsonListingGridColumns = (
  cityOptions: Record<string, string>
): Columns<JsonListing> => [
  {
    Header: "Builder",
    accessor: "builderName"
  },
  {
    Header: "Subdivision",
    accessor: "subdivision"
  },
  {
    Header: "City",
    accessor: (data: JsonListing): string =>
      cityOptions[data.city] ?? data.city,
    Cell: ({ value }: { value: string }): JSX.Element => (
      <TruncateText text={value} />
    )
  },
  {
    Header: "Address",
    accessor: (data: JsonListing): string =>
      `${data.streetNumber} ${data.streetName}`,
    id: "street1"
  },
  {
    Header: "Price",
    accessor: (data: JsonListing): string => Format.Money(data.price)
  },
  {
    Header: "Last Updated",
    accessor: (data: JsonListing): string => Format.Date(data.sysModifiedOn),
    id: "sysModifiedOn"
  },
  {
    Header: "Obtained",
    accessor: (data: JsonListing): string => Format.Date(data.sysCreatedOn),
    id: "sysCreatedOn"
  }
];
