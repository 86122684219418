export enum ReportStatus {
  Active = "active",
  ActivePending = "activePending",
  Pending = "pending",
  ForSale = "forSale",
  Sold = "sold",
  Cma = "cma"
}

export const ReportStatusLabel = new Map<ReportStatus, string>([
  [ReportStatus.Active, "Active"],
  [ReportStatus.ActivePending, "Active/Pending"],
  [ReportStatus.Pending, "Pending"],
  [ReportStatus.ForSale, "For Sale"],
  [ReportStatus.Sold, "Sold"],
  [ReportStatus.Cma, "CMA"]
]);
