export enum AcceptableFinancing {
  Cash = "cash",
  Conventional = "conventional",
  FHA = "fha",
  FMHA = "fmha",
  SeeRemarks = "seeRemarks",
  LeaseBack = "leaseBack",
  TxVet = "texasVet",
  USDALoan = "usdaLoan",
  VALoan = "vaLoan"
}
export const AcceptableFinancingLabel = new Map<AcceptableFinancing, string>([
  [AcceptableFinancing.Cash, "Cash"],
  [AcceptableFinancing.Conventional, "Conventional"],
  [AcceptableFinancing.FHA, "FHA"],
  [AcceptableFinancing.FMHA, "FMHA (Fannie Mae)"],
  [AcceptableFinancing.SeeRemarks, "See Remarks"],
  [AcceptableFinancing.LeaseBack, "Lease Back"],
  [AcceptableFinancing.TxVet, "Texas Vet"],
  [AcceptableFinancing.USDALoan, "USDA Loan"],
  [AcceptableFinancing.VALoan, "VA Loan"]
]);
