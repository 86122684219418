export enum SpecialtyRooms {
  WalkInPantry = "walkInPantry",
  WalkInClosets = "walkInClosets",
  UtilityRoomInside = "utilityRoomInside",
  Telephone = "telephone",
  StudyLibrary = "studyLibrary",
  SeparateDiningRoom = "separateDiningRoom",
  Skylights = "skylights",
  SecondaryBedroomDown = "secondaryBedroomDown",
  PullDownStorage = "pullDownStorage",
  OpenFloorPlan = "openFloorPlan",
  MediaRoom = "mediaRoom",
  MaidQuarters = "maidQuarters",
  Loft = "loft",
  LaundryUpperLevel = "laundryUpperLevel",
  LaundryRoom = "laundryRoom",
  LaundryMainLevel = "laundryMainLevel",
  LaundryLowerLevel = "laundryLowerLevel",
  LaundryInKitchen = "laundryInKitchen",
  LaundryInCloset = "laundryInCloset",
  LivingDiningCombo = "livingDiningCombo",
  IslandKitchen = "islandKitchen",
  HighSpeedInternet = "highSpeedInternet",
  HighCeilings = "highCeilings",
  GameRoom = "gameRoom",
  FloridaRoom = "floridaRoom",
  EatInKitchen = "eatInKitchen",
  CableTVAvailable = "cableTVAvailable",
  BreakfastBar = "breakfastBar",
  AllBedroomsUpstairs = "allBedroomsUpstairs",
  AtticStorageOnly = "atticStorageOnly",
  AtticRadiantBarrierDecking = "atticRadiantBarrierDecking",
  AtticPullDownStairs = "atticPullDownStairs",
  AtticPermanentStairs = "atticPermanentStairs",
  AtticPartiallyFloored = "atticPartiallyFloored",
  AtticFloored = "atticFloored",
  AtticFan = "atticFan",
  AtticAccessOnly = "atticAccessOnly",
  AllBedroomsDownstairs = "allBedroomsDownstairs",
  ThreeLivingArea = "threeLivingArea",
  TwoLivingArea = "twoLivingArea",
  TwoEatingAreas = "twoEatingAreas",
  OneLivingArea = "oneLivingArea",
  FirstFloorLvlOrNoSteps = "firstFloorLvlOrNoSteps"
}
export const SpecialtyRoomsLabel = new Map<SpecialtyRooms, string>([
  [SpecialtyRooms.WalkInPantry, "Walk-In Pantry"],
  [SpecialtyRooms.WalkInClosets, "Walk in Closets"],
  [SpecialtyRooms.UtilityRoomInside, "Utility Room Inside"],
  [SpecialtyRooms.Telephone, "Telephone"],
  [SpecialtyRooms.StudyLibrary, "Study/Library"],
  [SpecialtyRooms.SeparateDiningRoom, "Separate Dining Room"],
  [SpecialtyRooms.Skylights, "Skylights"],
  [SpecialtyRooms.SecondaryBedroomDown, "Secondary Bedroom Down"],
  [SpecialtyRooms.PullDownStorage, "Pull Down Storage"],
  [SpecialtyRooms.OpenFloorPlan, "Open Floor Plan"],
  [SpecialtyRooms.MediaRoom, "Media Room"],
  [SpecialtyRooms.MaidQuarters, "Maid's Quarters"],
  [SpecialtyRooms.Loft, "Loft"],
  [SpecialtyRooms.LaundryUpperLevel, "Laundry Upper Level"],
  [SpecialtyRooms.LaundryRoom, "Laundry Room"],
  [SpecialtyRooms.LaundryMainLevel, "Laundry Main Level"],
  [SpecialtyRooms.LaundryLowerLevel, "Laundry Lower Level"],
  [SpecialtyRooms.LaundryInKitchen, "Laundry in Kitchen"],
  [SpecialtyRooms.LaundryInCloset, "Laundry in Closet"],
  [SpecialtyRooms.LivingDiningCombo, "Liv/Din Combo"],
  [SpecialtyRooms.IslandKitchen, "Island Kitchen"],
  [SpecialtyRooms.HighSpeedInternet, "High Speed Internet"],
  [SpecialtyRooms.HighCeilings, "High Ceilings"],
  [SpecialtyRooms.GameRoom, "Game Room"],
  [SpecialtyRooms.FloridaRoom, "Florida Room"],
  [SpecialtyRooms.EatInKitchen, "Eat-In Kitchen"],
  [SpecialtyRooms.CableTVAvailable, "Cable TV Available"],
  [SpecialtyRooms.BreakfastBar, "Breakfast Bar"],
  [SpecialtyRooms.AllBedroomsUpstairs, "All Bedrooms Upstairs"],
  [SpecialtyRooms.AtticStorageOnly, "Attic - Storage Only"],
  [
    SpecialtyRooms.AtticRadiantBarrierDecking,
    "Attic - Radiant Barrier Decking"
  ],
  [SpecialtyRooms.AtticPullDownStairs, "Attic - Pull Down Stairs"],
  [SpecialtyRooms.AtticPermanentStairs, "Attic - Permanent Stairs"],
  [SpecialtyRooms.AtticPartiallyFloored, "Attic - Partially Floored"],
  [SpecialtyRooms.AtticFloored, "Attic - Floored"],
  [SpecialtyRooms.AtticFan, "Attic - Attic Fan"],
  [SpecialtyRooms.AtticAccessOnly, "Attic - Access only"],
  [SpecialtyRooms.AllBedroomsDownstairs, "All Bedrooms Downstairs"],
  [SpecialtyRooms.ThreeLivingArea, "Three Living Area"],
  [SpecialtyRooms.TwoLivingArea, "Two Living Area"],
  [SpecialtyRooms.TwoEatingAreas, "Two Eating Areas"],
  [SpecialtyRooms.OneLivingArea, "One Living Area"],
  [SpecialtyRooms.FirstFloorLvlOrNoSteps, "1st Floor Lvl/No Steps"]
]);
