export enum Disclosures {
  ApprovedSeniorsProject = "approvedSeniorsProject",
  CorporateListing = "corporateListing",
  CorporateOwned = "corporateOwned",
  Exclusions = "exclusions",
  FamilialRelation = "familialRelation",
  HomeProtectionPlan = "homeProtectionPlan",
  LeadBasePaintAddendum = "leadBasePaintAddendum",
  MiLendersApproval = "miLendersApproval",
  MunicipalUtilityDistrictMUD = "municipalUtilityDistrictMUD",
  OtherDisclosures = "otherDisclosures",
  OtherRealEstateOwned = "otherRealEstateOwned",
  OwnerAgent = "ownerAgent",
  PlannedUnitDevelopment = "plannedUnitDevelopment",
  ProspectsReserved = "prospectsReserved",
  PublicImprovementDistrict = "publicImprovementDistrict",
  RealEstateOwnedLender = "realEstateOwnedLender",
  ReloAddendumRequired = "reloAddendumRequired",
  RentalRecordsAvailable = "rentalRecordsAvailable",
  ResidentialServiceContract = "residentialServiceContract",
  RightofFirstRefusalROFR = "rightofFirstRefusalROFR",
  SellerDisclosure = "sellerDisclosure",
  SellerProvidedSurveyAvailable = "sellerProvidedSurveyAvailable",
  SpecialAddendum = "specialAddendum",
  WarrantySeeRemarks = "warrantySeeRemarks",
  WaterDistrict = "waterDistrict",
  None = "none",
  SeeRemarks = "seeRemarks"
}
export const DisclosuresLabel = new Map<Disclosures, string>([
  [Disclosures.ApprovedSeniorsProject, "Approved Seniors Project"],
  [Disclosures.CorporateListing, "Corporate Listing"],
  [Disclosures.CorporateOwned, "Corporate Owned"],
  [Disclosures.Exclusions, "Exclusions"],
  [Disclosures.FamilialRelation, "Familial Relation"],
  [Disclosures.HomeProtectionPlan, "Home Protection Plan"],
  [Disclosures.LeadBasePaintAddendum, "Lead Base Paint Addendum"],
  [Disclosures.MiLendersApproval, "Mi/Lenders Approval"],
  [Disclosures.MunicipalUtilityDistrictMUD, "Municipal Utility District (MUD)"],
  [Disclosures.OtherDisclosures, "Other Disclosures"],
  [Disclosures.OtherRealEstateOwned, "Other Real Estate Owned"],
  [Disclosures.OwnerAgent, "Owner/Agent"],
  [Disclosures.PlannedUnitDevelopment, "Planned Unit Development"],
  [Disclosures.ProspectsReserved, "Prospects Reserved"],
  [Disclosures.PublicImprovementDistrict, "Public Improvement District"],
  [Disclosures.RealEstateOwnedLender, "Real Estate Owned (Lender)"],
  [Disclosures.ReloAddendumRequired, "Relo Addendum Required"],
  [Disclosures.RentalRecordsAvailable, "Rental Records Available"],
  [Disclosures.ResidentialServiceContract, "Residential Service Contract"],
  [Disclosures.RightofFirstRefusalROFR, "Right of First Refusal (ROFR)"],
  [Disclosures.SellerDisclosure, "Seller Disclosure"],
  [
    Disclosures.SellerProvidedSurveyAvailable,
    "Seller Provided Survey Available"
  ],
  [Disclosures.SpecialAddendum, "Special Addendum"],
  [Disclosures.WarrantySeeRemarks, "Warranty/See Remarks"],
  [Disclosures.WaterDistrict, "Water District"],
  [Disclosures.None, "None"],
  [Disclosures.SeeRemarks, "See Remarks"]
]);
