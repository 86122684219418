export enum PropCondition {
  TearDown = "tearDown",
  ToBeBuilt = "toBeBuilt",
  UnderConstruction = "underConstruction",
  SeeRemarks = "seeRemarks"
}
export const PropConditionLabel = new Map<PropCondition, string>([
  [PropCondition.TearDown, "Tear Down"],
  [PropCondition.ToBeBuilt, "To Be Built"],
  [PropCondition.UnderConstruction, "Under Construction"],
  [PropCondition.SeeRemarks, "See Remarks"]
]);
