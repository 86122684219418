import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isMonday,
  isSunday
} from "date-fns";

import {
  DatePicker,
  FormContext,
  FormContextActions,
  formContextReducer,
  FormMode,
  FormStyled,
  Select
} from "@homesusa/form";
import { useRefresh } from "@homesusa/core";

import { ChartFilters as Filters } from "../../../interfaces";
import { ChartContext } from "../../../context";
import { ChartAutoComplete } from "../../autocomplete/autocomplete.component";
import { CalendarInterval } from "../../../enums";
import { DateSelector } from "../date-selector/date-selector.component";
import { useStyles } from "./chart-filters.styles";
import { getIntervalDate } from "modules/dashboard/utils";

export const ChartFilters = (): JSX.Element => {
  const initialFilter = {
    calendarInterval: CalendarInterval.Weekly
  };
  const [filters, formDispatch] = React.useReducer<
    (state: Filters, action: FormContextActions) => Filters
  >(formContextReducer, initialFilter);
  const [refresh, setRefresh] = useRefresh();
  const [isCleanActive, setIsCleanActive] = React.useState(false);
  const { fetchChartPoints } = React.useContext(ChartContext);
  const classes = useStyles();

  const handleChange = (name: string, value?: unknown): void => {
    formDispatch({
      type: "FormChangeByInputName",
      inputName: name,
      payload: value
    });
  };

  const handleClick = (filters: Filters): void => {
    fetchChartPoints({ ...filters });
    setRefresh();
  };

  const searchFromDateSelector = (from: Date, to: Date): void => {
    handleChange("from", from);
    handleChange("to", to);
    handleClick({ ...filters, from, to });
  };

  const cleanFilters = (): void => {
    setIsCleanActive(true);
    formDispatch({
      type: "OverrideState",
      payload: initialFilter
    });
    handleClick(initialFilter);
  };

  const rangeOfDates = React.useMemo(() => {
    if (filters.calendarInterval === CalendarInterval.Weekly) {
      return { from: isMonday, to: isSunday };
    }
    return { from: isFirstDayOfMonth, to: isLastDayOfMonth };
  }, [filters.from, filters.to, filters.calendarInterval]);

  const manageDatesByInterval = (): void => {
    let from: Date | undefined;
    let to: Date | undefined;

    if (filters.from && filters.calendarInterval) {
      from = getIntervalDate(filters.from, filters.calendarInterval);
      handleChange("from", from);
    }
    if (filters.to && filters.calendarInterval) {
      to = getIntervalDate(filters.to, filters.calendarInterval, true);
      handleChange("to", to);
    }

    const newFilters = { ...filters, from, to };
    handleClick(newFilters);
  };

  React.useEffect(() => {
    if (!isCleanActive) manageDatesByInterval();
    else setIsCleanActive(false);
  }, [filters.calendarInterval]);

  if (refresh) return <></>;
  return (
    <FormContext.Provider
      value={{
        formState: filters,
        formDispatch,
        formRules: {},
        formMode: FormMode.Update,
        otherProps: {}
      }}
    >
      <FormStyled sx={classes.root}>
        <Box sx={classes.title}>
          <Typography variant="h2" color="primary.dark">
            Active/Pending/Sold Homes by Subdivision
          </Typography>
          <DateSelector handleSearch={searchFromDateSelector} />
        </Box>

        <Grid container spacing={2} wrap="wrap">
          <Grid item xs={3} xl={3}>
            <ChartAutoComplete
              label="Subdivision"
              onChange={handleChange}
              defaultValue={filters.subdivision}
            />
          </Grid>
          <Grid item xs={3} xl={2}>
            <Select
              label="Calendar interval"
              name="calendarInterval"
              defaultValue={filters.calendarInterval}
              removeBlankOption
              sort={false}
              size="small"
              options={{
                [CalendarInterval.Weekly]: "Weekly",
                [CalendarInterval.Monthly]: "Monthly"
              }}
            />
          </Grid>
          <Grid item xs={3} xl={2.5}>
            <DatePicker
              label="From"
              name="from"
              size="small"
              defaultValue={filters.from ? new Date(filters.from) : undefined}
              shouldDisableDate={(date): boolean => !rangeOfDates.from(date)}
            />
          </Grid>
          <Grid item xs={3} xl={2.5}>
            <DatePicker
              label="To"
              name="to"
              size="small"
              defaultValue={filters.to ? new Date(filters.to) : undefined}
              shouldDisableDate={(date): boolean => !rangeOfDates.to(date)}
            />
          </Grid>
          <Grid item xs={1.2} xl={1} alignItems="center" display="flex">
            <Button onClick={(): void => handleClick(filters)}>Search</Button>
          </Grid>
          <Grid item xs={1} xl={1} alignItems="center" display="flex">
            <Button variant="text" onClick={cleanFilters}>
              Clear
            </Button>
          </Grid>
        </Grid>
      </FormStyled>
    </FormContext.Provider>
  );
};
