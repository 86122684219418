export enum Water {
  MUD = "mud",
  Private = "private",
  Public = "public"
}
export const WaterLabel = new Map<Water, string>([
  [Water.MUD, "MUD"],
  [Water.Private, "Private"],
  [Water.Public, "Public"]
]);
