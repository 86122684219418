export enum SoldTerms {
  CashSale = "cashSale",
  Conventional = "conventional",
  FHA = "fha",
  Other = "other",
  TexasVet = "texasVet",
  VA = "va"
}
export const SoldTermsLabel = new Map<SoldTerms, string>([
  [SoldTerms.CashSale, "Cash Sale"],
  [SoldTerms.Conventional, "Conventional"],
  [SoldTerms.FHA, "FHA"],
  [SoldTerms.Other, "Other"],
  [SoldTerms.TexasVet, "Texas Vet"],
  [SoldTerms.VA, "VA"]
]);
