export enum ProposedTerms {
  Cash = "cash",
  Conventional = "conventional",
  FHA = "fha",
  Fixed = "fixed",
  FMHA = "fmha",
  Other = "other",
  TexasVet = "texasVet",
  USDA = "usda",
  VA = "va"
}
export const ProposedTermsLabel = new Map<ProposedTerms, string>([
  [ProposedTerms.Cash, "Cash"],
  [ProposedTerms.Conventional, "Conventional"],
  [ProposedTerms.FHA, "FHA"],
  [ProposedTerms.Fixed, "Fixed"],
  [ProposedTerms.FMHA, "FMHA"],
  [ProposedTerms.Other, "Other"],
  [ProposedTerms.TexasVet, "Texas Vet"],
  [ProposedTerms.USDA, "USDA"],
  [ProposedTerms.VA, "VA"]
]);
