export enum LotDescription {
  Agricultural = "agricultural",
  AlleyAccess = "alleyAccess",
  BackstoGreenbeltPark = "backstoGreenbeltPark",
  BackYard = "backYard",
  OnGolfCourse = "onGolfCourse",
  Bluff = "bluff",
  CityLot = "cityLot",
  Cleared = "cleared",
  ClosetoClubhouse = "closetoClubhouse",
  CornerLot = "cornerLot",
  CulDeSac = "culDeSac",
  Curbs = "curbs",
  Farm = "farm",
  FewTree = "fewTree",
  FlagLot = "flagLot",
  FrontYard = "frontYard",
  Garden = "garden",
  GentleSloping = "gentleSloping",
  InteriorLot = "interiorLot",
  IrregularLot = "irregularLot",
  Landscaped = "landscaped",
  Level = "level",
  NativePlants = "nativePlants",
  NearGolfCourse = "nearGolfCourse",
  NearPublicTransit = "nearPublicTransit",
  OpenLot = "openLot",
  Orchard = "orchard",
  PieShapedLot = "pieShapedLot",
  Private = "private",
  PrivateMaintainedRoad = "privateMaintainedRoad",
  PublicMaintainedRoad = "publicMaintainedRoad",
  RoadMaintenanceAgreement = "roadMaintenanceAgreement",
  RockOutcropping = "rockOutcropping",
  RollingSlope = "rollingSlope",
  SlopedDown = "slopedDown",
  SlopedUp = "slopedUp",
  SplitPossible = "splitPossible",
  SprinklerAutomatic = "sprinklerAutomatic",
  SprinklerBackYard = "sprinklerBackYard",
  SprinklerDripOnlyBubblers = "sprinklerDripOnlyBubblers",
  SprinklerInFront = "sprinklerInFront",
  SprinklerInground = "sprinklerInground",
  SprinklerManual = "sprinklerManual",
  SprinklerPartial = "sprinklerPartial",
  SprinklerRainSensor = "sprinklerRainSensor",
  SprinklerSideYard = "sprinklerSideYard",
  SteepSlope = "steepSlope",
  Subdivided = "subdivided",
  TreesHeavy = "treesHeavy",
  TreesLarge = "treesLarge",
  TreesMany = "treesMany",
  TreesMedium = "treesMedium",
  TreesModerate = "treesModerate",
  TreesSmall = "treesSmall",
  TreesSparse = "treesSparse",
  View = "view",
  Waterfall = "waterfall",
  Wetlands = "wetlands",
  Xeriscape = "xeriscape",
  ZeroLotLine = "zeroLotLine",
  None = "none",
  SeeRemarks = "seeRemarks"
}

export const LotDescriptionLabel = new Map<LotDescription, string>([
  [LotDescription.Agricultural, "Agricultural"],
  [LotDescription.AlleyAccess, "Alley Access"],
  [LotDescription.BackstoGreenbeltPark, "Back to Park/Greenbelt"],
  [LotDescription.BackYard, "Back Yard"],
  [LotDescription.OnGolfCourse, "On Golf Course"],
  [LotDescription.Bluff, "Bluff"],
  [LotDescription.CityLot, "City Lot"],
  [LotDescription.Cleared, "Cleared"],
  [LotDescription.ClosetoClubhouse, "Close to Clubhouse"],
  [LotDescription.CornerLot, "Corner Lot"],
  [LotDescription.CulDeSac, "Cul-De-Sac"],
  [LotDescription.Curbs, "Curbs"],
  [LotDescription.Farm, "Farm"],
  [LotDescription.FewTree, "Few Tree"],
  [LotDescription.FlagLot, "Flag Lot"],
  [LotDescription.FrontYard, "Front Yard"],
  [LotDescription.Garden, "Garden"],
  [LotDescription.GentleSloping, "Gentle Sloping"],
  [LotDescription.InteriorLot, "Interior Lot"],
  [LotDescription.IrregularLot, "Irregular Lot"],
  [LotDescription.Landscaped, "Landscaped"],
  [LotDescription.Level, "Level"],
  [LotDescription.NativePlants, "Native Plants"],
  [LotDescription.NearGolfCourse, "Near Golf Course"],
  [LotDescription.NearPublicTransit, "Near Public Transit"],
  [LotDescription.OpenLot, "Open Lot"],
  [LotDescription.Orchard, "Orchard(s)"],
  [LotDescription.PieShapedLot, "Pie Shaped Lot"],
  [LotDescription.Private, "Private"],
  [LotDescription.PrivateMaintainedRoad, "Private Maintained Road"],
  [LotDescription.PublicMaintainedRoad, "Public Maintained Road"],
  [LotDescription.RoadMaintenanceAgreement, "Road Maintenance Agreement"],
  [LotDescription.RockOutcropping, "Rock Outcropping"],
  [LotDescription.RollingSlope, "Rolling Slope"],
  [LotDescription.SlopedDown, "Sloped Down"],
  [LotDescription.SlopedUp, "Sloped Up"],
  [LotDescription.SplitPossible, "Split Possible"],
  [LotDescription.SprinklerAutomatic, "Sprinkler - Automatic"],
  [LotDescription.SprinklerBackYard, "Sprinkler - Back Yard"],
  [LotDescription.SprinklerDripOnlyBubblers, "Sprinkler - Drip Only/Bubblers"],
  [LotDescription.SprinklerInFront, "Sprinkler - In Front"],
  [LotDescription.SprinklerInground, "Sprinkler - In-ground"],
  [LotDescription.SprinklerManual, "Sprinkler - Manual"],
  [LotDescription.SprinklerPartial, "Sprinkler - Partial"],
  [LotDescription.SprinklerRainSensor, "Sprinkler - Rain Sensor"],
  [LotDescription.SprinklerSideYard, "Sprinkler - Side Yard"],
  [LotDescription.SteepSlope, "Steep Slope"],
  [LotDescription.Subdivided, "Subdivided"],
  [LotDescription.TreesHeavy, "Trees-Heavy"],
  [LotDescription.TreesLarge, "Trees-Large (Over 40 Ft)"],
  [LotDescription.TreesMany, "Trees-Many"],
  [LotDescription.TreesMedium, "Trees-Medium (20 Ft - 40 Ft)"],
  [LotDescription.TreesModerate, "Trees-Moderate"],
  [LotDescription.TreesSmall, "Trees-Small (Under 20 Ft)"],
  [LotDescription.TreesSparse, "Trees-Sparse"],
  [LotDescription.View, "Views"],
  [LotDescription.Waterfall, "Waterfall"],
  [LotDescription.Wetlands, "Wetlands"],
  [LotDescription.Xeriscape, "Xeriscape"],
  [LotDescription.ZeroLotLine, "Zero Lot Line"],
  [LotDescription.None, "None"],
  [LotDescription.SeeRemarks, "See Remarks"]
]);
