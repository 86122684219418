export enum InteriorFeatures {
  TwoPrimaryBath = "twoPrimaryBath",
  TwoPrimarySuites = "twoPrimarySuites",
  Bar = "bar",
  Bookcases = "bookcases",
  BreakfastBar = "breakfastBar",
  BuiltinFeatures = "builtinFeatures",
  CeilingFans = "ceilingFans",
  CeilingsBeamed = "ceilingsBeamed",
  CeilingsCathedral = "ceilingsCathedral",
  CeilingsCoffered = "ceilingsCoffered",
  CeilingsHigh = "ceilingsHigh",
  CeilingsTray = "ceilingsTray",
  CeilingsVaulted = "ceilingsVaulted",
  Chandelier = "chandelier",
  CrownMolding = "crownMolding",
  DoubleVanity = "doubleVanity",
  DryerElectricHookup = "dryerElectricHookup",
  DryerGasHookup = "dryerGasHookup",
  EatinKitchen = "eatinKitchen",
  EntranceFoyer = "entranceFoyer",
  FrenchDoors = "frenchDoors",
  HighSpeedInternet = "highSpeedInternet",
  InLawFloorplan = "inLawFloorplan",
  InteriorSteps = "interiorSteps",
  KitchenIsland = "kitchenIsland",
  LowFlowPlumbingFixtures = "lowFlowPlumbingFixtures",
  MultipleDiningAreas = "multipleDiningAreas",
  MultipleLivingAreas = "multipleLivingAreas",
  NaturalWoodwork = "naturalWoodwork",
  NoInteriorSteps = "noInteriorSteps",
  None = "none",
  OpenFloorplan = "openFloorplan",
  Pantry = "pantry",
  PrimaryBedroomonMain = "primaryBedroomonMain",
  RecessedLighting = "recessedLighting",
  SeeRemarks = "seeRemarks",
  SmartHome = "smartHome",
  SmartThermostat = "smartThermostat",
  SoakingTub = "soakingTub",
  SolarTubes = "solarTubes",
  SoundSystem = "soundSystem",
  Storage = "storage",
  TrackLighting = "trackLighting",
  WalkInClosets = "walkInClosets",
  WasherHookup = "washerHookup",
  WaterSenseFixtures = "waterSenseFixtures",
  WiredforData = "wiredforData",
  WiredforSound = "wiredforSound"
}

export const InteriorFeaturesLabel = new Map<InteriorFeatures, string>([
  [InteriorFeatures.TwoPrimaryBath, "2 Primary Bath"],
  [InteriorFeatures.TwoPrimarySuites, "2 Primary Suites"],
  [InteriorFeatures.Bar, "Bar"],
  [InteriorFeatures.Bookcases, "Bookcases"],
  [InteriorFeatures.BreakfastBar, "Breakfast Bar"],
  [InteriorFeatures.BuiltinFeatures, "Built-in Features"],
  [InteriorFeatures.CeilingFans, "Ceiling Fan(s)"],
  [InteriorFeatures.CeilingsBeamed, "Ceiling(s)-Beamed"],
  [InteriorFeatures.CeilingsCathedral, "Ceiling(s)-Cathedral"],
  [InteriorFeatures.CeilingsCoffered, "Ceiling(s)-Coffered"],
  [InteriorFeatures.CeilingsHigh, "Ceiling(s)-High"],
  [InteriorFeatures.CeilingsTray, "Ceiling(s)-Tray"],
  [InteriorFeatures.CeilingsVaulted, "Ceiling(s)-Vaulted"],
  [InteriorFeatures.Chandelier, "Chandelier"],
  [InteriorFeatures.CrownMolding, "Crown Molding"],
  [InteriorFeatures.DoubleVanity, "Double Vanity"],
  [InteriorFeatures.DryerElectricHookup, "Dryer-Electric Hookup"],
  [InteriorFeatures.DryerGasHookup, "Dryer-Gas Hookup"],
  [InteriorFeatures.EatinKitchen, "Eat-in Kitchen"],
  [InteriorFeatures.EntranceFoyer, "Entrance Foyer"],
  [InteriorFeatures.FrenchDoors, "French Doors"],
  [InteriorFeatures.HighSpeedInternet, "High Speed Internet"],
  [InteriorFeatures.InLawFloorplan, "In-Law Floorplan"],
  [InteriorFeatures.InteriorSteps, "Interior Steps"],
  [InteriorFeatures.KitchenIsland, "Kitchen Island"],
  [InteriorFeatures.LowFlowPlumbingFixtures, "Low Flow Plumbing Fixtures"],
  [InteriorFeatures.MultipleDiningAreas, "Multiple Dining Areas"],
  [InteriorFeatures.MultipleLivingAreas, "Multiple Living Areas"],
  [InteriorFeatures.NaturalWoodwork, "Natural Woodwork"],
  [InteriorFeatures.NoInteriorSteps, "No Interior Steps"],
  [InteriorFeatures.None, "None"],
  [InteriorFeatures.OpenFloorplan, "Open Floorplan"],
  [InteriorFeatures.Pantry, "Pantry"],
  [InteriorFeatures.PrimaryBedroomonMain, "Primary Bedroom on Main"],
  [InteriorFeatures.RecessedLighting, "Recessed Lighting"],
  [InteriorFeatures.SeeRemarks, "See Remarks"],
  [InteriorFeatures.SmartHome, "Smart Home"],
  [InteriorFeatures.SmartThermostat, "Smart Thermostat"],
  [InteriorFeatures.SoakingTub, "Soaking Tub"],
  [InteriorFeatures.SolarTubes, "Solar Tube(s)"],
  [InteriorFeatures.SoundSystem, "Sound System"],
  [InteriorFeatures.Storage, "Storage"],
  [InteriorFeatures.TrackLighting, "Track Lighting"],
  [InteriorFeatures.WalkInClosets, "Walk-In Closet(s)"],
  [InteriorFeatures.WasherHookup, "Washer Hookup"],
  [InteriorFeatures.WaterSenseFixtures, "WaterSense Fixture(s)"],
  [InteriorFeatures.WiredforData, "Wired for Data"],
  [InteriorFeatures.WiredforSound, "Wired for Sound"]
]);
