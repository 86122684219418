import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";

import { SubmitButton } from "@homesusa/layout";

export function Modal({
  open,
  onClose,
  title,
  actionButton,
  children
}: {
  open: boolean;
  onClose: () => void;
  title?: string;
  actionButton: { text: string; method: () => Promise<void> };
  children: React.ReactNode;
}): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog__title">
      {title ? <DialogTitle id="dialog__title">{title}</DialogTitle> : null}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SubmitButton onClick={actionButton.method}>
          {actionButton.text}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
