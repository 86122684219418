import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { agentBonusRules } from "core/rules/agent-bonus-rules";

import {
  LockboxLocation,
  LockboxLocationLabel,
  LockboxType,
  LockboxTypeLabel
} from "markets/ctx/enums";
import {
  openHousesRules,
  showingRules,
  buyersAgentCommissionRules
} from "../../sections";

export const showingInfoRules: FormRules = {
  ...buyersAgentCommissionRules,
  ...openHousesRules,
  ...showingRules,
  ...agentBonusRules,
  lockboxType: {
    label: "Lockbox Type",
    lastOptions: [LockboxType.None],
    options: getOptionsFromEnumAndMap(
      Object.values(LockboxType),
      LockboxTypeLabel
    )
  },
  lockboxLocation: {
    label: "Lockbox Location",
    lastOptions: [LockboxLocation.None, LockboxLocation.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(LockboxLocation),
      LockboxLocationLabel
    ),
    noneOption: [LockboxLocation.None]
  },
  publicRemarks: {
    label: "Public Remarks",
    maxLength: 2000,
    rows: 5,
    requiredToSubmit: true,
    helperText:
      "MLS number, Built by <BUILDER> and Completion Info will be added as a prefix to this field. DO NOT INCLUDE."
  },
  agentPrivateRemarks: {
    label: "Agent Remarks",
    maxLength: 1000
  }
};
