export enum Floors {
  Adobe = "adobe",
  Bamboo = "bamboo",
  Brick = "brick",
  Carpet = "carpet",
  CeramicTile = "ceramicTile",
  Concrete = "concrete",
  ConcreteStained = "concreteStained",
  CRIGreenLabelPlusCertifiedCarpet = "criGreenLabelPlusCertifiedCarpet",
  FloorScoreCertifiedFlooring = "floorScoreCertifiedFlooring",
  FSCOrSFICertifiedSourceHardwood = "fscOrSFICertifiedSourceHardwood",
  Hardwood = "hardwood",
  Laminate = "laminate",
  Linoleum = "linoleum",
  NoCarpet = "noCarpet",
  Slate = "slate",
  Stone = "stone",
  Terrazzo = "terrazzo",
  Tile = "tile",
  Vinyl = "vinyl",
  Wood = "wood"
}

export const FloorsLabel = new Map<Floors, string>([
  [Floors.Adobe, "Adobe"],
  [Floors.Bamboo, "Bamboo"],
  [Floors.Brick, "Brick"],
  [Floors.Carpet, "Carpet"],
  [Floors.CeramicTile, "Ceramic Tile"],
  [Floors.Concrete, "Concrete"],
  [Floors.ConcreteStained, "Concrete-Stained"],
  [
    Floors.CRIGreenLabelPlusCertifiedCarpet,
    "CRI Green Label Plus Certified Carpet"
  ],
  [Floors.FloorScoreCertifiedFlooring, "FloorScore(r) Certified Flooring"],
  [
    Floors.FSCOrSFICertifiedSourceHardwood,
    "FSC or SFI Certified Source Hardwood"
  ],
  [Floors.Hardwood, "Hardwood"],
  [Floors.Laminate, "Laminate"],
  [Floors.Linoleum, "Linoleum"],
  [Floors.NoCarpet, "No Carpet"],
  [Floors.Slate, "Slate"],
  [Floors.Stone, "Stone"],
  [Floors.Terrazzo, "Terrazzo"],
  [Floors.Tile, "Tile"],
  [Floors.Vinyl, "Vinyl"],
  [Floors.Wood, "Wood"]
]);
