import React from "react";

import { AlertSummaryContext } from "../context";
import { AlertType } from "../enums";

export const useRemoveElemFromGrid = (
  alertType: AlertType
): ((entityId: string) => void) => {
  const { setCurrentData, setAlertIncidents } =
    React.useContext(AlertSummaryContext);

  const removeElementFromAlertGrid = (entityId: string): void => {
    if (!setCurrentData || !setAlertIncidents) {
      return;
    }
    setCurrentData((prev) => {
      if (prev?.alertType != alertType) {
        return prev;
      }
      const newGridData = prev.gridData.data.filter(
        (row) => row.id != entityId
      );
      return {
        ...prev,
        gridData: {
          data: newGridData,
          total: newGridData.length
        }
      };
    });
    setAlertIncidents((prev) => ({
      ...prev,
      [alertType]: (prev[alertType] ?? 1) - 1
    }));
  };

  return removeElementFromAlertGrid;
};
