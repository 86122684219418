export enum GarageDescription {
  NotApplicable = "notApplicable",
  OneCarGarage = "oneCarGarage",
  TwoCarGarage = "twoCarGarage",
  ThreeCarGarage = "threeCarGarage",
  FourPlusCarGarage = "fourPlusCarGarage",
  Attached = "attached",
  Detached = "detached",
  Oversized = "oversized",
  RearEntry = "rearEntry",
  SideEntry = "sideEntry",
  Tandem = "tandem"
}

export const GarageDescriptionLabel = new Map<GarageDescription, string>([
  [GarageDescription.NotApplicable, "None/Not Applicable"],
  [GarageDescription.OneCarGarage, "One Car Garage"],
  [GarageDescription.TwoCarGarage, "Two Car Garage"],
  [GarageDescription.ThreeCarGarage, "Three Car Garage"],
  [GarageDescription.FourPlusCarGarage, "Four or More Car Garage"],
  [GarageDescription.Attached, "Attached"],
  [GarageDescription.Detached, "Detached"],
  [GarageDescription.Oversized, "Oversized"],
  [GarageDescription.RearEntry, "Rear Entry"],
  [GarageDescription.SideEntry, "Side Entry"],
  [GarageDescription.Tandem, "Tandem"]
]);
