export enum ShowingInstructions {
  AppointmentOnly = "appointmentOnly",
  AppointmentWOffice = "appointmentWOffice",
  CallFirstGo = "callFirstGo",
  Go = "go",
  OwnerMustAccompany = "ownerMustAccompany",
  ShowingService = "showingService",
  ShowingServiceApp = "showingServiceApp",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const ShowingInstructionsLabel = new Map<ShowingInstructions, string>([
  [ShowingInstructions.AppointmentOnly, "Appointment Only"],
  [ShowingInstructions.AppointmentWOffice, "Appointment w/Office"],
  [ShowingInstructions.CallFirstGo, "Call First-Go"],
  [ShowingInstructions.Go, "Go"],
  [ShowingInstructions.OwnerMustAccompany, "Owner Must Accompany"],
  [ShowingInstructions.ShowingService, "Showing Service"],
  [ShowingInstructions.ShowingServiceApp, "Showing Service App"],
  [ShowingInstructions.None, "None"],
  [ShowingInstructions.OtherSeeRemarks, "Other-See Remarks"]
]);
