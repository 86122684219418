import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Paper, Typography, Tooltip } from "@mui/material";

import { AppContext } from "@homesusa/layout";
import { useRefresh } from "@homesusa/core";

import { RouterPath } from "core/enums";
import { DiscrepancyKeyItems, DiscrepancyChartKeys } from "../../../enums";
import { DiscrepancyData, RangeChartPoint } from "../../../interfaces";
import { AmountDiscrepancy } from "./amount-discrepancy-component";
import { Legend } from "./legend-component";
import { useStyles } from "./discrepancy-area.styles";
import { RangeBarChart } from "../charts/range-bar-chart/range-bar-chart.component";

export const DiscrepancyArea = ({
  discrepancyData
}: {
  discrepancyData: DiscrepancyData;
}): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    appState: {
      layout: {
        sidebar: { openSubSidebar }
      }
    }
  } = React.useContext(AppContext);

  const [resetChart, setResetChart] = useRefresh();

  const discrepancyName = DiscrepancyKeyItems.get(
    DiscrepancyChartKeys.ListingDiscrepancy
  )?.name;

  const { totalDiscrepancy, averageDiscrepancy, ...listings } = discrepancyData;

  const hasDiscrepancy = listings.listingDiscrepancy > 0;
  const hasPoints = Object.values(listings).some((listing) => !!listing);

  const chartData: RangeChartPoint[] = React.useMemo(() => {
    return Object.values(DiscrepancyChartKeys)
      .map((key) => {
        const dataValue = discrepancyData[key as keyof DiscrepancyData];
        const keyItem = DiscrepancyKeyItems.get(key);

        return {
          percentage: dataValue,
          color: keyItem?.color,
          name: keyItem?.name ?? ""
        };
      })
      .filter((item): item is RangeChartPoint => item !== null);
  }, [listings]);
  const legends = React.useMemo((): RangeChartPoint[] => {
    if (!hasDiscrepancy) return chartData;

    const legendsFiltered = chartData.filter(
      (point): boolean => point.name !== discrepancyName
    );

    return legendsFiltered;
  }, [chartData]);

  const maxDigitsTotal = React.useMemo((): number => {
    const totals = legends.map((point): number => point.total);
    const max = Math.max(...totals);
    return max.toString().length;
  }, [legends]);

  React.useEffect(() => setResetChart(), [openSubSidebar]);

  return (
    <Paper elevation={3} sx={classes.root}>
      <Box sx={classes.chartContainer}>
        <Typography fontSize={"13px"} fontWeight="bold" color="GrayText">
          DISCREPANCY ANALYSIS
        </Typography>
        {!resetChart && (
          <RangeBarChart data={chartData} noHasData={!hasPoints} />
        )}

        <Box>
          {legends.map((point) => (
            <Legend
              key={point.name}
              color={point.color}
              total={point.total}
              title={point.name}
              maxDigitsTotal={maxDigitsTotal}
            />
          ))}
        </Box>
      </Box>
      {hasDiscrepancy && (
        <Box sx={classes.badIndicatorContainer}>
          <Tooltip title="Go to Discrepancy Report" arrow placement="left">
            <Box
              sx={classes.mlsDiscrepancyContainer}
              onClick={(): void =>
                navigate(`${RouterPath.Reports}/discrepancy`)
              }
            >
              <Typography color={"error"} fontSize="35px" fontWeight="bold">
                {listings.listingDiscrepancy}
              </Typography>
            </Box>
          </Tooltip>

          <Typography sx={classes.badAreaTitle}>
            Discrepancies between MLS & Builder
          </Typography>

          <AmountDiscrepancy
            amount={averageDiscrepancy}
            description="Avg. Discrepancy"
          />
          <AmountDiscrepancy
            amount={totalDiscrepancy}
            description="Total Discrepancy"
          />
        </Box>
      )}
    </Paper>
  );
};
