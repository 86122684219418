import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  BillingFrequency,
  BillingFrequencyLabel,
  HOAIncludes,
  HOAIncludesLabel,
  HoaRequirement,
  HoaRequirementLabel
} from "markets/abor/enums";

export const hoaRules: FormRules = {
  hoaFee: {
    label: "HOA Fee",
    replaceRegex: "[a-zA-Z-]+",
    maxLength: 7,
    numberFormat: {
      min: 10,
      prefix: "$",
      thousandSeparator: true,
      suffix: undefined,
      decimalScale: 2
    }
  },
  hoaName: {
    label: "Name",
    maxLength: 70
  },
  hasHoa: {
    label: "HOA"
  },
  billingFrequency: {
    label: "Billing Frequency",
    options: getOptionsFromEnumAndMap(
      Object.values(BillingFrequency),
      BillingFrequencyLabel
    )
  },
  hoaIncludes: {
    label: "HOA Includes",
    options: getOptionsFromEnumAndMap(
      Object.values(HOAIncludes),
      HOAIncludesLabel
    ),
    max: 5,
    rows: 2
  },
  hoaRequirement: {
    label: "HOA Requirement",
    options: getOptionsFromEnumAndMap(
      Object.values(HoaRequirement),
      HoaRequirementLabel
    ),
    sort: false
  }
};
