import React from "react";

import {
  GridTableContext,
  FetchData,
  getSortColumn
} from "@homesusa/grid-table";
import { FormContext } from "@homesusa/form";
import { ListType, ReportStatus } from "../enums";
import { Filters } from "./filters.component";

function NewHomesFilters({
  onFetch
}: {
  onFetch: (props: FetchData) => Promise<void>;
}): JSX.Element {
  const {
    instance: {
      state: { pageSize, pageIndex, sortBy, globalFilter }
    },
    setShowLoader
  } = React.useContext(GridTableContext);
  const {
    formState: { status }
  } = React.useContext(FormContext);

  React.useEffect(() => {
    setShowLoader(true);
    onFetch({
      pageSize,
      pageIndex,
      sortBy: getSortColumn(sortBy),
      globalFilter
    }).finally(() => setShowLoader(false));
  }, [status]);

  return (
    <Filters onFetch={onFetch}>
      <Filters.Status
        statusAvailable={[ReportStatus.ForSale, ReportStatus.Sold]}
      />
      <Filters.ListType typeAvailable={[ListType.Residential]} />
      <Filters.Price />
      <Filters.Address />
      <Filters.Subdivision />
    </Filters>
  );
}

export { NewHomesFilters };
