import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { FormTabContent } from "@homesusa/form";
import { RoleEmployee } from "@homesusa/core";
import { useHasRole } from "@homesusa/auth";
import { AppContext } from "@homesusa/layout";

// Lot Request
const LotRequestDetails = React.lazy(
  () => import("app/pages/listing-lot-request-details.component")
);
const LotRequestGrid = React.lazy(
  () => import("modules/lot-listing-requests/pages/grid.component")
);

export function LotRequestRoutes(): JSX.Element {
  const { appStateDispatch } = React.useContext(AppContext);
  const { hasEmployeeRole } = useHasRole();

  React.useEffect(() => {
    if (hasEmployeeRole([RoleEmployee.SalesEmployeeReadonly])) {
      appStateDispatch({
        type: "RemoveSidebarItem",
        payload: "listingRequests"
      });
    }
  }, [appStateDispatch, hasEmployeeRole]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route index element={<LotRequestGrid />} />
        <Route path=":id" element={<LotRequestDetails />}>
          <Route index element={<FormTabContent />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
