export enum Exterior {
  OneSideMasonry = "oneSideMasonry",
  TwoSidesMasonry = "twoSidesMasonry",
  ThreeSidesMasonry = "threeSidesMasonry",
  FourSidesMasonry = "fourSidesMasonry",
  Aluminum = "aluminum",
  Brick = "brick",
  CementFiber = "cementFiber",
  RockStoneVeneer = "rockStoneVeneer",
  Siding = "siding",
  StoneRock = "stoneRock",
  Stucco = "stucco",
  Vinyl = "vinyl",
  Wood = "wood"
}

export const ExteriorLabel = new Map<Exterior, string>([
  [Exterior.OneSideMasonry, "1 Side Masonry"],
  [Exterior.TwoSidesMasonry, "2 Sides Masonry"],
  [Exterior.ThreeSidesMasonry, "3 Sides Masonry"],
  [Exterior.FourSidesMasonry, "4 Sides Masonry"],
  [Exterior.Aluminum, "Aluminum"],
  [Exterior.Brick, "Brick"],
  [Exterior.CementFiber, "Cement Fiber"],
  [Exterior.RockStoneVeneer, "Rock/Stone Veneer"],
  [Exterior.Siding, "Siding"],
  [Exterior.StoneRock, "Stone/Rock"],
  [Exterior.Stucco, "Stucco"],
  [Exterior.Vinyl, "Vinyl"],
  [Exterior.Wood, "Wood"]
]);
