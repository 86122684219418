import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { FormTabContent } from "@homesusa/form";
import { NotesManager } from "@homesusa/notes";

const PlanDetails = React.lazy(
  () => import("app/pages/plan-details.component")
);
const PlanGrid = React.lazy(
  () => import("modules/plans/pages/plan-grid.component")
);
const ListingGrid = React.lazy(
  () => import("modules/plans/components/listing-grid.component")
);
const PhotoRequets = React.lazy(
  () =>
    import("modules/plans/components/photo-requests/photo-requets.component")
);

export function PlanRoutes(): JSX.Element {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route index element={<PlanGrid />} />
        <Route
          path=":id"
          element={
            <Suspense fallback={<LinearProgress />}>
              <PlanDetails />
            </Suspense>
          }
        >
          <Route path="notes" element={<NotesManager />} />
          <Route path="photo-requests" element={<PhotoRequets />} />
          <Route path="listings" element={<ListingGrid />} />
          <Route index element={<FormTabContent />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
