import {
  CtxCities,
  CtxCitiesLabel,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { County, CountyLabel } from "markets/ctx/enums";

export const getCtxCityLabel = (city: string | CtxCities): string =>
  CtxCitiesLabel[city as CtxCities] ?? city;

export const ctxCityRule: FieldRulesProps = {
  label: "City",
  lastOptions: [CtxCities.Other],
  options: getOptionsFromEnumAndRecord(Object.values(CtxCities), CtxCitiesLabel)
};

export const countyRules: FieldRulesProps = {
  label: "County",
  lastOptions: [County.Other],
  options: getOptionsFromEnumAndRecord(Object.values(County), CountyLabel),
  required: true
};
