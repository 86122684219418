import { FormRules } from "@homesusa/form";
import { agentBonusRules } from "core/rules/agent-bonus-rules";
import { financialRules } from "../../sections";
import { hoaRules } from "../../sections/hoa";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { LandTitleEvidence, LandTitleEvidenceLabel } from "markets/abor/enums";

export const financialInfoRules: FormRules = {
  ...agentBonusRules,
  taxRate: financialRules.taxRate,
  acceptableFinancing: financialRules.acceptableFinancing,
  readableBuyersAgentCommission: financialRules.readableBuyersAgentCommission,
  buyersAgentCommission: financialRules.buyersAgentCommission,
  buyersAgentCommissionType: financialRules.buyersAgentCommissionType,
  hasHoa: hoaRules.hasHoa,
  hoaRequirement: hoaRules.hoaRequirement,
  billingFrequency: hoaRules.billingFrequency,
  hoaIncludes: hoaRules.hoaIncludes,
  hoaName: hoaRules.hoaName,
  hoaFee: hoaRules.hoaFee,
  estimatedTax: {
    requiredToSubmit: true,
    label: "Estimated Tax",
    maxLength: 14,
    numberFormat: {
      min: 0,
      thousandSeparator: true,
      decimalScale: 2
    }
  },
  taxYear: {
    label: "Certified Year",
    requiredToSubmit: true
  },
  taxExemptions: financialRules.taxExemptions,
  landTitleEvidence: {
    label: "Title",
    options: getOptionsFromEnumAndMap(
      Object.values(LandTitleEvidence),
      LandTitleEvidenceLabel
    ),
    rows: 2
  },
  preferredTitleCompany: financialRules.titleCompany,
  taxAssesedValue: {
    label: "Tax Assesed Value",
    requiredToSubmit: true,
    maxLength: 10
  }
};
