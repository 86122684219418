export enum ShowingContactType {
  Agent = "agent",
  Occupant = "occupant",
  Owner = "owner",
  PropertyManager = "propertyManager"
}

export const ShowingContactTypeLabel = new Map<ShowingContactType, string>([
  [ShowingContactType.Agent, "Agent"],
  [ShowingContactType.Occupant, "Occupant"],
  [ShowingContactType.Owner, "Owner"],
  [ShowingContactType.PropertyManager, "Property Manager"]
]);
