import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  total: SxProps;
  legend: SxProps;
}

export const useStyles = (height: number): Styles => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      maxHeight: height * 0.69,
      overflow: "hidden"
    },
    total: {
      position: "absolute",
      bottom: "26%",
      fontSize: "1.8rem",
      zIndex: 1,
      textAlign: "center",
      width: "100%",
      fontWeight: "bold"
    },
    legend: {
      position: "absolute",
      zIndex: 1,
      bottom: 10,
      textAlign: "center",
      width: "100%",
      fontWeight: "bold"
    }
  };
};
