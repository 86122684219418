export enum NeighborhoodAmenities {
  Clubhouse = "clubhouse",
  ClusterMailbox = "clusterMailbox",
  CommonGrounds = "commonGrounds",
  ControlledAccess = "controlledAccess",
  Courtyard = "courtyard",
  Curbs = "curbs",
  DogParkPlayArea = "dogParkPlayArea",
  Fishing = "fishing",
  FitnessCenter = "fitnessCenter",
  GameRoom = "gameRoom",
  Gated = "gated",
  GolfCourse = "golfCourse",
  GoogleFiber = "googleFiber",
  HighSpeedInternet = "highSpeedInternet",
  KitchenFacilities = "kitchenFacilities",
  Lake = "lake",
  MediaCenterMovieTheatre = "mediaCenterMovieTheatre",
  None = "none",
  Park = "park",
  PetAmenities = "petAmenities",
  PicnicArea = "picnicArea",
  Playground = "playground",
  Pool = "pool",
  SeeRemarks = "seeRemarks",
  Sidewalks = "sidewalks",
  SportCourtFacility = "sportCourtFacility",
  Storage = "storage",
  StreetLights = "streetLights",
  TennisCourt = "tennisCourt",
  UndergroundUtilities = "undergroundUtilities",
  UVerse = "uVerse",
  WalkBikeHikeJogTrails = "walkBikeHikeJogTrails"
}
export const NeighborhoodAmenitiesLabel = new Map<
  NeighborhoodAmenities,
  string
>([
  [NeighborhoodAmenities.Clubhouse, "Clubhouse"],
  [NeighborhoodAmenities.ClusterMailbox, "Cluster Mailbox"],
  [NeighborhoodAmenities.CommonGrounds, "Common Grounds"],
  [NeighborhoodAmenities.ControlledAccess, "Controlled Access"],
  [NeighborhoodAmenities.Courtyard, "Courtyard"],
  [NeighborhoodAmenities.Curbs, "Curbs"],
  [NeighborhoodAmenities.DogParkPlayArea, "Dog Park/Play Area"],
  [NeighborhoodAmenities.Fishing, "Fishing"],
  [NeighborhoodAmenities.FitnessCenter, "Fitness Center"],
  [NeighborhoodAmenities.GameRoom, "Game Room"],
  [NeighborhoodAmenities.Gated, "Gated"],
  [NeighborhoodAmenities.GolfCourse, "Golf Course"],
  [NeighborhoodAmenities.GoogleFiber, "Google Fiber"],
  [NeighborhoodAmenities.HighSpeedInternet, "High Speed Internet"],
  [NeighborhoodAmenities.KitchenFacilities, "Kitchen Facilities"],
  [NeighborhoodAmenities.Lake, "Lake"],
  [NeighborhoodAmenities.MediaCenterMovieTheatre, "Media Center/Movie Theatre"],
  [NeighborhoodAmenities.None, "None"],
  [NeighborhoodAmenities.Park, "Park"],
  [NeighborhoodAmenities.PetAmenities, "Pet Amenities"],
  [NeighborhoodAmenities.PicnicArea, "Picnic Area"],
  [NeighborhoodAmenities.Playground, "Playground"],
  [NeighborhoodAmenities.Pool, "Pool"],
  [NeighborhoodAmenities.SeeRemarks, "See Remarks"],
  [NeighborhoodAmenities.Sidewalks, "Sidewalks"],
  [NeighborhoodAmenities.SportCourtFacility, "Sport Court(s)/Facility"],
  [NeighborhoodAmenities.Storage, "Storage"],
  [NeighborhoodAmenities.StreetLights, "Street Lights"],
  [NeighborhoodAmenities.TennisCourt, "Tennis Court(s)"],
  [NeighborhoodAmenities.UndergroundUtilities, "Underground Utilities"],
  [NeighborhoodAmenities.UVerse, "U-Verse"],
  [NeighborhoodAmenities.WalkBikeHikeJogTrails, "Walk/Bike/Hike/Jog Trails"]
]);
