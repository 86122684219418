export enum ListingType {
  ExclusiveAgency = "exclusiveAgency",
  ExclusiveRightToLease = "exclusiveRightToLease",
  ExclusiveRightToSell = "exclusiveRightToSell",
  ExclusiveRightWException = "exclusiveRightWException",
  LimitedServiceListing = "limitedServiceListing",
  MLSOnly = "mLSOnly",
  PropertyManagementAgreement = "propertyManagementAgreement"
}
export const ListingTypeLabel = new Map<ListingType, string>([
  [ListingType.ExclusiveAgency, "Exclusive Agency"],
  [ListingType.ExclusiveRightToLease, "Exclusive Right To Lease"],
  [ListingType.ExclusiveRightToSell, "Exclusive Right To Sell"],
  [ListingType.ExclusiveRightWException, "Exclusive Right Exception"],
  [ListingType.LimitedServiceListing, "Limited Service Listing"],
  [ListingType.MLSOnly, "MLS Only"],
  [ListingType.PropertyManagementAgreement, "Property Management Agreement"]
]);
