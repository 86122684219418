export enum SubArea {
  Addicks = "addicks",
  Bacliff = "bacliff",
  Waller = "waller",
  Webster = "webster",
  Weisenburger = "weisenburger",
  WestColumbia = "westColumbia",
  WestUniversityPlace = "westUniversityPlace",
  Willis = "willis",
  WoodbranchVillage = "woodbranchVillage",
  TheWoodlands = "theWoodlands",
  OtherArea = "otherArea",
  AustinCounty = "austinCounty",
  Baytown = "baytown",
  BrazoriaCounty = "brazoriaCounty",
  ChambersCounty = "chambersCounty",
  GalvestonCounty = "galvestonCounty",
  GrimesCounty = "grimesCounty",
  HarrisCountyUnincorp = "harrisCountyUnincorp",
  LibertyCounty = "libertyCounty",
  MatagordaCounty = "matagordaCounty",
  SanJacintoCounty = "sanJacintoCounty",
  WalkerCounty = "walkerCounty",
  WallerCounty = "wallerCounty",
  Bayview = "bayview",
  WashingtonCounty = "washingtonCounty",
  WhartonCounty = "whartonCounty",
  OtherCountiesinTexas = "otherCountiesinTexas",
  Galveston = "galveston",
  FortBendCountyUnincorp = "fortBendCountyUnincorp",
  Grapeland = "grapeland",
  Kennard = "kennard",
  Latexo = "latexo",
  Lovelady = "lovelady",
  Elkhart = "elkhart",
  Beasley = "beasley",
  Frankston = "frankston",
  Montalba = "montalba",
  Palestine = "palestine",
  Slocum = "slocum",
  TennesseeColony = "tennesseeColony",
  Huntsville = "huntsville",
  CrockettCounty = "crockettCounty",
  CrystalBeach = "crystalBeach",
  IowaColony = "iowaColony",
  Coldspring = "coldspring",
  Bellaire = "bellaire",
  Livingston = "livingston",
  Onalaska = "onalaska",
  SurfsideBeach = "surfsideBeach",
  Cleveland = "cleveland",
  Angleton = "angleton",
  Dayton = "dayton",
  Hardin = "hardin",
  Liberty = "liberty",
  PortBolivar = "portBolivar",
  Bellville = "bellville",
  Brookshire = "brookshire",
  BayCity = "bayCity",
  Matagorda = "matagorda",
  Sargent = "sargent",
  Palacios = "palacios",
  Austin = "austin",
  Westlake = "westlake",
  Rollingwood = "rollingwood",
  Lakeway = "lakeway",
  Pflugerville = "pflugerville",
  Georgetown = "georgetown",
  Brookside = "brookside",
  RoundRock = "roundRock",
  BeeCave = "beeCave",
  DrippingSprings = "drippingSprings",
  Wimberley = "wimberley",
  Driftwood = "driftwood",
  Bastrop = "bastrop",
  Taylor = "taylor",
  Manor = "manor",
  Elgin = "elgin",
  Hutto = "hutto",
  BunkerHill = "bunkerHill",
  Kyle = "kyle",
  LagoVista = "lagoVista",
  CedarPark = "cedarPark",
  Leander = "leander",
  Spicewood = "spicewood",
  MarbleFalls = "marbleFalls",
  Volente = "volente",
  Jonestown = "jonestown",
  BriarCliff = "briarCliff",
  LibertyHill = "libertyHill",
  Channelview = "channelview",
  Weir = "weir",
  HorseshoeBay = "horseshoeBay",
  Buda = "buda",
  PortOConnor = "portOConnor",
  JamaicaBeach = "jamaicaBeach",
  Somerville = "somerville",
  Groveton = "groveton",
  Anahuac = "anahuac",
  Bedias = "bedias",
  Dodge = "dodge",
  Richards = "richards",
  Riverside = "riverside",
  Shiro = "shiro",
  Bleiblerville = "bleiblerville",
  Brenham = "brenham",
  Bryan = "bryan",
  Burleigh = "burleigh",
  CatSpring = "catSpring",
  Burton = "burton",
  CollegeStation = "collegeStation",
  Aldine = "aldine",
  ClearLakeShores = "clearLakeShores",
  Columbus = "columbus",
  Fayetteville = "fayetteville",
  Frydek = "frydek",
  Industry = "industry",
  Kenney = "kenney",
  Nacogdoches = "nacogdoches",
  Nelsonville = "nelsonville",
  NewUlm = "newUlm",
  SanFelipe = "sanFelipe",
  Sealy = "sealy",
  CloverLeaf = "cloverLeaf",
  Sheridan = "sheridan",
  Sublime = "sublime",
  Wallis = "wallis",
  Brazoria = "brazoria",
  Damon = "damon",
  Danciger = "danciger",
  Guy = "guy",
  HolidayLakes = "holidayLakes",
  JonesCreek = "jonesCreek",
  Liverpool = "liverpool",
  Clute = "clute",
  OysterCreek = "oysterCreek",
  Quintana = "quintana",
  Richwood = "richwood",
  SandyPoint = "sandyPoint",
  Sweeny = "sweeny",
  Beach = "beach",
  BeachCity = "beachCity",
  Cove = "cove",
  DoubleBayou = "doubleBayou",
  Conroe = "conroe",
  Hankamer = "hankamer",
  OakIsland = "oakIsland",
  OldRiverWinfree = "oldRiverWinfree",
  SmithPoint = "smithPoint",
  Stowell = "stowell",
  Wallisville = "wallisville",
  Winnie = "winnie",
  EastBernard = "eastBernard",
  BayouVista = "bayouVista",
  Crosby = "crosby",
  Caplen = "caplen",
  EagleLake = "eagleLake",
  Gilchrist = "gilchrist",
  HighIsland = "highIsland",
  TikiIsland = "tikiIsland",
  Anderson = "anderson",
  Carlos = "carlos",
  Iola = "iola",
  Navasota = "navasota",
  RoansPrairie = "roansPrairie",
  Cypress = "cypress",
  Singleton = "singleton",
  Stoneham = "stoneham",
  Daisetta = "daisetta",
  Devers = "devers",
  Hull = "hull",
  Kenefick = "kenefick",
  MossHill = "mossHill",
  Romayor = "romayor",
  Rye = "rye",
  Shepherd = "shepherd",
  Danbury = "danbury",
  Tarkington = "tarkington",
  Blessing = "blessing",
  CedarLane = "cedarLane",
  Collegeport = "collegeport",
  Francitas = "francitas",
  Midfield = "midfield",
  PortLavaca = "portLavaca",
  VanVleck = "vanVleck",
  SugarValley = "sugarValley",
  DeerPark = "deerPark",
  Oakhurst = "oakhurst",
  PointBlank = "pointBlank",
  Carmine = "carmine",
  ChappellHill = "chappellHill",
  Independence = "independence",
  Washington = "washington",
  Dobbin = "dobbin",
  WhiteHall = "whiteHall",
  Ace = "ace",
  Ames = "ames",
  Dickinson = "dickinson",
  PlumGrove = "plumGrove",
  Raywood = "raywood",
  Saratoga = "saratoga",
  ElCampo = "elCampo",
  Markham = "markham",
  Pledger = "pledger",
  Wadsworth = "wadsworth",
  Hungerford = "hungerford",
  Ledbetter = "ledbetter",
  Lyons = "lyons",
  ElLago = "elLago",
  Millican = "millican",
  RoundTop = "roundTop",
  Alleyton = "alleyton",
  Boling = "boling",
  Danevang = "danevang",
  GlenFlora = "glenFlora",
  LaneCity = "laneCity",
  Lissie = "lissie",
  Louise = "louise",
  Nada = "nada",
  Almeda = "almeda",
  Freeport = "freeport",
  Wharton = "wharton",
  Lufkin = "lufkin",
  Crockett = "crockett",
  Hallettsville = "hallettsville",
  Normangee = "normangee",
  ValleyMills = "valleyMills",
  Shiner = "shiner",
  Moulton = "moulton",
  Yoakum = "yoakum",
  Rockport = "rockport",
  Fresno = "fresno",
  PortAransas = "portAransas",
  Weimar = "weimar",
  Schulenburg = "schulenburg",
  LaGrange = "laGrange",
  NewBraunfels = "newBraunfels",
  Brookeland = "brookeland",
  Friendswood = "friendswood",
  Fulshear = "fulshear",
  GalenaPark = "galenaPark",
  HedwigVillage = "hedwigVillage",
  Hempstead = "hempstead",
  Highlands = "highlands",
  HilshireVillage = "hilshireVillage",
  Hitchcock = "hitchcock",
  SantaFe = "santaFe",
  Hockley = "hockley",
  Houston = "houston",
  Huffman = "huffman",
  Humble = "humble",
  HuntersCreek = "huntersCreek",
  JacintoCity = "jacintoCity",
  JerseyVillage = "jerseyVillage",
  Katy = "katy",
  Kemah = "kemah",
  Kendleton = "kendleton",
  Alvin = "alvin",
  Kingwood = "kingwood",
  LaMarque = "laMarque",
  LaPorte = "laPorte",
  LakeJackson = "lakeJackson",
  LeagueCity = "leagueCity",
  Lomax = "lomax",
  Magnolia = "magnolia",
  Manvel = "manvel",
  McNair = "mcNair",
  MeadowsPlace = "meadowsPlace",
  Arcadia = "arcadia",
  MissouriCity = "missouriCity",
  MontBelvieu = "montBelvieu",
  Montgomery = "montgomery",
  MorgansPoint = "morgansPoint",
  NassauBay = "nassauBay",
  Needville = "needville",
  NewCaney = "newCaney",
  NewWaverly = "newWaverly",
  Orchard = "orchard",
  Pasadena = "pasadena",
  Arcola = "arcola",
  Pattison = "pattison",
  Pearland = "pearland",
  Pinehurst = "pinehurst",
  PineyPoint = "pineyPoint",
  Plantersville = "plantersville",
  Pleak = "pleak",
  Porter = "porter",
  PrairieView = "prairieView",
  Richmond = "richmond",
  RomanForest = "romanForest",
  Atascocita = "atascocita",
  Rosenberg = "rosenberg",
  Rosharon = "rosharon",
  SanLeon = "sanLeon",
  Seabrook = "seabrook",
  Sheldon = "sheldon",
  Shenandoah = "shenandoah",
  Shoreacres = "shoreacres",
  Simonton = "simonton",
  SouthHouston = "southHouston",
  SouthsidePlace = "southsidePlace",
  Barrett = "barrett",
  Splendora = "splendora",
  Spring = "spring",
  SpringValley = "springValley",
  Stafford = "stafford",
  SugarLand = "sugarLand",
  TaylorLakeVillage = "taylorLakeVillage",
  TexasCity = "texasCity",
  Thompson = "thompson",
  Tomball = "tomball",
  Trinity = "trinity",
  ClearLakeCity = "clearLakeCity"
}

export const SubAreaLabel = new Map<SubArea, string>([
  [SubArea.Addicks, "1 - Addicks"],
  [SubArea.Bacliff, "10 - Bacliff"],
  [SubArea.Waller, "100 - Waller"],
  [SubArea.Webster, "101 - Webster"],
  [SubArea.Weisenburger, "102 - Weisenburger"],
  [SubArea.WestColumbia, "103 - West Columbia"],
  [SubArea.WestUniversityPlace, "104 - West University Place"],
  [SubArea.Willis, "105 - Willis"],
  [SubArea.WoodbranchVillage, "106 - Woodbranch Village"],
  [SubArea.TheWoodlands, "107 - The Woodlands"],
  [SubArea.OtherArea, "108 - Other Area"],
  [SubArea.AustinCounty, "109 - Austin County"],
  [SubArea.Baytown, "11 - Baytown"],
  [SubArea.BrazoriaCounty, "110 - Brazoria County"],
  [SubArea.ChambersCounty, "111 - Chambers County"],
  [SubArea.GalvestonCounty, "112 - Galveston County"],
  [SubArea.GrimesCounty, "113 - Grimes County"],
  [SubArea.HarrisCountyUnincorp, "114 - Harris County Unincorp."],
  [SubArea.LibertyCounty, "115 - Liberty County"],
  [SubArea.MatagordaCounty, "116 - Matagorda County"],
  [SubArea.SanJacintoCounty, "117 - San Jacinto County"],
  [SubArea.WalkerCounty, "118 - Walker County"],
  [SubArea.WallerCounty, "119 - Waller County"],
  [SubArea.Bayview, "12 - Bayview"],
  [SubArea.WashingtonCounty, "120 - Washington County"],
  [SubArea.WhartonCounty, "121 - Wharton County"],
  [SubArea.OtherCountiesinTexas, "122 - Other Counties in Texas"],
  [SubArea.Galveston, "123 - Galveston"],
  [SubArea.FortBendCountyUnincorp, "124 - Fort Bend County Unincorp."],
  [SubArea.Grapeland, "125 - Grapeland"],
  [SubArea.Kennard, "126 - Kennard"],
  [SubArea.Latexo, "127 - Latexo"],
  [SubArea.Lovelady, "128 - Lovelady"],
  [SubArea.Elkhart, "129 - Elkhart"],
  [SubArea.Beasley, "13 - Beasley"],
  [SubArea.Frankston, "130 - Frankston"],
  [SubArea.Montalba, "131 - Montalba"],
  [SubArea.Palestine, "132 - Palestine"],
  [SubArea.Slocum, "133 - Slocum"],
  [SubArea.TennesseeColony, "134 - Tennessee Colony"],
  [SubArea.Huntsville, "135 - Huntsville"],
  [SubArea.CrockettCounty, "136 - Crockett County"],
  [SubArea.CrystalBeach, "137 - Crystal Beach"],
  [SubArea.IowaColony, "138 - Iowa Colony"],
  [SubArea.Coldspring, "139 - Coldspring"],
  [SubArea.Bellaire, "14 - Bellaire"],
  [SubArea.Livingston, "140 - Livingston"],
  [SubArea.Onalaska, "141 - Onalaska"],
  [SubArea.SurfsideBeach, "142 - Surfside Beach"],
  [SubArea.Cleveland, "143 - Cleveland"],
  [SubArea.Angleton, "144 - Angleton"],
  [SubArea.Dayton, "145 - Dayton"],
  [SubArea.Hardin, "146 - Hardin"],
  [SubArea.Liberty, "147 - Liberty"],
  [SubArea.PortBolivar, "148 - Port Bolivar"],
  [SubArea.Bellville, "149 - Bellville"],
  [SubArea.Brookshire, "15 - Brookshire"],
  [SubArea.BayCity, "150 - Bay City"],
  [SubArea.Matagorda, "151 - Matagorda"],
  [SubArea.Sargent, "152 - Sargent"],
  [SubArea.Palacios, "153 - Palacios"],
  [SubArea.Austin, "154 - Austin"],
  [SubArea.Westlake, "155 - Westlake"],
  [SubArea.Rollingwood, "156 - Rollingwood"],
  [SubArea.Lakeway, "157 - Lakeway"],
  [SubArea.Pflugerville, "158 - Pflugerville"],
  [SubArea.Georgetown, "159 - Georgetown"],
  [SubArea.Brookside, "16 - Brookside"],
  [SubArea.RoundRock, "160 - Round Rock"],
  [SubArea.BeeCave, "161 - Bee Cave"],
  [SubArea.DrippingSprings, "162 - Dripping Springs"],
  [SubArea.Wimberley, "163 - Wimberley"],
  [SubArea.Driftwood, "164 - Driftwood"],
  [SubArea.Bastrop, "165 - Bastrop"],
  [SubArea.Taylor, "166 - Taylor"],
  [SubArea.Manor, "167 - Manor"],
  [SubArea.Elgin, "168 - Elgin"],
  [SubArea.Hutto, "169 - Hutto"],
  [SubArea.BunkerHill, "17 - Bunker Hill"],
  [SubArea.Kyle, "170 - Kyle"],
  [SubArea.LagoVista, "171 - Lago Vista"],
  [SubArea.CedarPark, "172 - Cedar Park"],
  [SubArea.Leander, "173 - Leander"],
  [SubArea.Spicewood, "174 - Spicewood"],
  [SubArea.MarbleFalls, "175 - Marble Falls"],
  [SubArea.Volente, "176 - Volente"],
  [SubArea.Jonestown, "177 - Jonestown"],
  [SubArea.BriarCliff, "178 - Briar Cliff"],
  [SubArea.LibertyHill, "179 - Liberty Hill"],
  [SubArea.Channelview, "18 - Channelview"],
  [SubArea.Weir, "180 - Weir"],
  [SubArea.HorseshoeBay, "181 - Horseshoe Bay"],
  [SubArea.Buda, "182 - Buda"],
  [SubArea.PortOConnor, "183 - Port O Connor"],
  [SubArea.JamaicaBeach, "184 - Jamaica Beach"],
  [SubArea.Somerville, "185 - Somerville"],
  [SubArea.Groveton, "186 - Groveton"],
  [SubArea.Anahuac, "187 - Anahuac"],
  [SubArea.Bedias, "188 - Bedias"],
  [SubArea.Dodge, "189 - Dodge"],
  [SubArea.Richards, "190 - Richards"],
  [SubArea.Riverside, "191 - Riverside"],
  [SubArea.Shiro, "192 - Shiro"],
  [SubArea.Bleiblerville, "193 - Bleiblerville"],
  [SubArea.Brenham, "194 - Brenham"],
  [SubArea.Bryan, "195 - Bryan"],
  [SubArea.Burleigh, "196 - Burleigh"],
  [SubArea.CatSpring, "197 - Cat Spring"],
  [SubArea.Burton, "198 - Burton"],
  [SubArea.CollegeStation, "199 - College Station"],
  [SubArea.Aldine, "2 - Aldine"],
  [SubArea.ClearLakeShores, "20 - Clear Lake Shores"],
  [SubArea.Columbus, "200 - Columbus"],
  [SubArea.Fayetteville, "201 - Fayetteville"],
  [SubArea.Frydek, "202 - Frydek"],
  [SubArea.Industry, "203 - Industry"],
  [SubArea.Kenney, "204 - Kenney"],
  [SubArea.Nacogdoches, "205 - Nacogdoches"],
  [SubArea.Nelsonville, "206 - Nelsonville"],
  [SubArea.NewUlm, "207 - New Ulm"],
  [SubArea.SanFelipe, "208 - San Felipe"],
  [SubArea.Sealy, "209 - Sealy"],
  [SubArea.CloverLeaf, "21 - Clover Leaf"],
  [SubArea.Sheridan, "210 - Sheridan"],
  [SubArea.Sublime, "211 - Sublime"],
  [SubArea.Wallis, "212 - Wallis"],
  [SubArea.Brazoria, "213 - Brazoria"],
  [SubArea.Damon, "214 - Damon"],
  [SubArea.Danciger, "215 - Danciger"],
  [SubArea.Guy, "216 - Guy"],
  [SubArea.HolidayLakes, "217 - Holiday Lakes"],
  [SubArea.JonesCreek, "218 - Jones Creek"],
  [SubArea.Liverpool, "219 - Liverpool"],
  [SubArea.Clute, "22 - Clute"],
  [SubArea.OysterCreek, "220 - Oyster Creek"],
  [SubArea.Quintana, "221 - Quintana"],
  [SubArea.Richwood, "222 - Richwood"],
  [SubArea.SandyPoint, "223 - Sandy Point"],
  [SubArea.Sweeny, "225 - Sweeny"],
  [SubArea.Beach, "226 - Beach"],
  [SubArea.BeachCity, "227 - Beach City"],
  [SubArea.Cove, "228 - Cove"],
  [SubArea.DoubleBayou, "229 - Double Bayou"],
  [SubArea.Conroe, "23 - Conroe"],
  [SubArea.Hankamer, "230 - Hankamer"],
  [SubArea.OakIsland, "231 - Oak Island"],
  [SubArea.OldRiverWinfree, "232 - Old River Winfree"],
  [SubArea.SmithPoint, "234 - Smith Point"],
  [SubArea.Stowell, "235 - Stowell"],
  [SubArea.Wallisville, "236 - Wallisville"],
  [SubArea.Winnie, "237 - Winnie"],
  [SubArea.EastBernard, "238 - East Bernard"],
  [SubArea.BayouVista, "239 - Bayou Vista"],
  [SubArea.Crosby, "24 - Crosby"],
  [SubArea.Caplen, "240 - Caplen"],
  [SubArea.EagleLake, "241 - Eagle Lake"],
  [SubArea.Gilchrist, "242 - Gilchrist"],
  [SubArea.HighIsland, "243 - High Island"],
  [SubArea.TikiIsland, "244 - Tiki Island"],
  [SubArea.Anderson, "245 - Anderson"],
  [SubArea.Carlos, "246 - Carlos"],
  [SubArea.Iola, "247 - Iola"],
  [SubArea.Navasota, "248 - Navasota"],
  [SubArea.RoansPrairie, "249 - Roans Prairie"],
  [SubArea.Cypress, "25 - Cypress"],
  [SubArea.Singleton, "250 - Singleton"],
  [SubArea.Stoneham, "251 - Stoneham"],
  [SubArea.Daisetta, "252 - Daisetta"],
  [SubArea.Devers, "253 - Devers"],
  [SubArea.Hull, "254 - Hull"],
  [SubArea.Kenefick, "255 - Kenefick"],
  [SubArea.MossHill, "256 - Moss Hill"],
  [SubArea.Romayor, "257 - Romayor"],
  [SubArea.Rye, "258 - Rye"],
  [SubArea.Shepherd, "259 - Shepherd"],
  [SubArea.Danbury, "26 - Danbury"],
  [SubArea.Tarkington, "260 - Tarkington"],
  [SubArea.Blessing, "261 - Blessing"],
  [SubArea.CedarLane, "262 - Cedar Lane"],
  [SubArea.Collegeport, "263 - Collegeport"],
  [SubArea.Francitas, "265 - Francitas"],
  [SubArea.Midfield, "266 - Midfield"],
  [SubArea.PortLavaca, "267 - Port Lavaca"],
  [SubArea.VanVleck, "268 - Van Vleck"],
  [SubArea.SugarValley, "269 - Sugar Valley"],
  [SubArea.DeerPark, "27 - Deer Park"],
  [SubArea.Oakhurst, "270 - Oakhurst"],
  [SubArea.PointBlank, "271 - Point Blank"],
  [SubArea.Carmine, "272 - Carmine"],
  [SubArea.ChappellHill, "273 - Chappell Hill"],
  [SubArea.Independence, "274 - Independence"],
  [SubArea.Washington, "275 - Washington"],
  [SubArea.Dobbin, "276 - Dobbin"],
  [SubArea.WhiteHall, "277 - White Hall"],
  [SubArea.Ace, "278 - Ace"],
  [SubArea.Ames, "279 - Ames"],
  [SubArea.Dickinson, "28 - Dickinson"],
  [SubArea.PlumGrove, "280 - Plum Grove"],
  [SubArea.Raywood, "281 - Raywood"],
  [SubArea.Saratoga, "282 - Saratoga"],
  [SubArea.ElCampo, "283 - El Campo"],
  [SubArea.Markham, "284 - Markham"],
  [SubArea.Pledger, "285 - Pledger"],
  [SubArea.Wadsworth, "286 - Wadsworth"],
  [SubArea.Hungerford, "287 - Hungerford"],
  [SubArea.Ledbetter, "288 - Ledbetter"],
  [SubArea.Lyons, "289 - Lyons"],
  [SubArea.ElLago, "29 - El Lago"],
  [SubArea.Millican, "290 - Millican"],
  [SubArea.RoundTop, "291 - Round Top"],
  [SubArea.Alleyton, "292 - Alleyton"],
  [SubArea.Boling, "293 - Boling"],
  [SubArea.Danevang, "294 - Danevang"],
  [SubArea.GlenFlora, "295 - Glen Flora"],
  [SubArea.LaneCity, "296 - Lane City"],
  [SubArea.Lissie, "297 - Lissie"],
  [SubArea.Louise, "298 - Louise"],
  [SubArea.Nada, "299 - Nada"],
  [SubArea.Almeda, "3 - Almeda"],
  [SubArea.Freeport, "30 - Freeport"],
  [SubArea.Wharton, "300 - Wharton"],
  [SubArea.Lufkin, "301 - Lufkin"],
  [SubArea.Crockett, "302 - Crockett"],
  [SubArea.Hallettsville, "303 - Hallettsville"],
  [SubArea.Normangee, "304 - Normangee"],
  [SubArea.ValleyMills, "305 - Valley Mills"],
  [SubArea.Shiner, "306 - Shiner"],
  [SubArea.Moulton, "307 - Moulton"],
  [SubArea.Yoakum, "308 - Yoakum"],
  [SubArea.Rockport, "309 - Rockport"],
  [SubArea.Fresno, "31 - Fresno"],
  [SubArea.PortAransas, "310 - Port Aransas"],
  [SubArea.Weimar, "311 - Weimar"],
  [SubArea.Schulenburg, "312 - Schulenburg"],
  [SubArea.LaGrange, "313 - La Grange"],
  [SubArea.NewBraunfels, "314 - New Braunfels"],
  [SubArea.Brookeland, "315 - Brookeland"],
  [SubArea.Friendswood, "32 - Friendswood"],
  [SubArea.Fulshear, "33 - Fulshear"],
  [SubArea.GalenaPark, "34 - Galena Park"],
  [SubArea.HedwigVillage, "35 - Hedwig Village"],
  [SubArea.Hempstead, "36 - Hempstead"],
  [SubArea.Highlands, "37 - Highlands"],
  [SubArea.HilshireVillage, "38 - Hilshire Village"],
  [SubArea.Hitchcock, "39 - Hitchcock"],
  [SubArea.SantaFe, "4 - Santa Fe"],
  [SubArea.Hockley, "40 - Hockley"],
  [SubArea.Houston, "41 - Houston"],
  [SubArea.Huffman, "42 - Huffman"],
  [SubArea.Humble, "43 - Humble"],
  [SubArea.HuntersCreek, "44 - Hunters Creek"],
  [SubArea.JacintoCity, "45 - Jacinto City"],
  [SubArea.JerseyVillage, "46 - Jersey Village"],
  [SubArea.Katy, "47 - Katy"],
  [SubArea.Kemah, "48 - Kemah"],
  [SubArea.Kendleton, "49 - Kendleton"],
  [SubArea.Alvin, "5 - Alvin"],
  [SubArea.Kingwood, "50 - Kingwood"],
  [SubArea.LaMarque, "51 - La Marque"],
  [SubArea.LaPorte, "52 - La Porte"],
  [SubArea.LakeJackson, "53 - Lake Jackson"],
  [SubArea.LeagueCity, "54 - League City"],
  [SubArea.Lomax, "55 - Lomax"],
  [SubArea.Magnolia, "56 - Magnolia"],
  [SubArea.Manvel, "57 - Manvel"],
  [SubArea.McNair, "58 - McNair"],
  [SubArea.MeadowsPlace, "59 - Meadows Place"],
  [SubArea.Arcadia, "6 - Arcadia"],
  [SubArea.MissouriCity, "60 - Missouri City"],
  [SubArea.MontBelvieu, "61 - Mont Belvieu"],
  [SubArea.Montgomery, "62 - Montgomery"],
  [SubArea.MorgansPoint, "63 - Morgans Point"],
  [SubArea.NassauBay, "64 - Nassau Bay"],
  [SubArea.Needville, "65 - Needville"],
  [SubArea.NewCaney, "66 - New Caney"],
  [SubArea.NewWaverly, "67 - New Waverly"],
  [SubArea.Orchard, "68 - Orchard"],
  [SubArea.Pasadena, "69 - Pasadena"],
  [SubArea.Arcola, "7 - Arcola"],
  [SubArea.Pattison, "70 - Pattison"],
  [SubArea.Pearland, "71 - Pearland"],
  [SubArea.Pinehurst, "72 - Pinehurst"],
  [SubArea.PineyPoint, "73 - Piney Point"],
  [SubArea.Plantersville, "74 - Plantersville"],
  [SubArea.Pleak, "75 - Pleak"],
  [SubArea.Porter, "76 - Porter"],
  [SubArea.PrairieView, "77 - Prairie View"],
  [SubArea.Richmond, "78 - Richmond"],
  [SubArea.RomanForest, "79 - Roman Forest"],
  [SubArea.Atascocita, "8 - Atascocita"],
  [SubArea.Rosenberg, "80 - Rosenberg"],
  [SubArea.Rosharon, "81 - Rosharon"],
  [SubArea.SanLeon, "82 - San Leon"],
  [SubArea.Seabrook, "83 - Seabrook"],
  [SubArea.Sheldon, "84 - Sheldon"],
  [SubArea.Shenandoah, "85 - Shenandoah"],
  [SubArea.Shoreacres, "86 - Shoreacres"],
  [SubArea.Simonton, "87 - Simonton"],
  [SubArea.SouthHouston, "88 - South Houston"],
  [SubArea.SouthsidePlace, "89 - Southside Place"],
  [SubArea.Barrett, "9 - Barrett"],
  [SubArea.Splendora, "90 - Splendora"],
  [SubArea.Spring, "91 - Spring"],
  [SubArea.SpringValley, "92 - Spring Valley"],
  [SubArea.Stafford, "93 - Stafford"],
  [SubArea.SugarLand, "94 - Sugar Land"],
  [SubArea.TaylorLakeVillage, "95 - Taylor Lake Village"],
  [SubArea.TexasCity, "96 - Texas City"],
  [SubArea.Thompson, "97 - Thompson"],
  [SubArea.Tomball, "98 - Tomball"],
  [SubArea.Trinity, "99 - Trinity"],
  [SubArea.ClearLakeCity, "Clear Lake City"]
]);
