import React from "react";
import { Box } from "@mui/material";

import { FormContext, FormMode, getOptionValueFromRule } from "@homesusa/form";
import { Format, MarketCode } from "@homesusa/core";
import { AuthCompanyContext } from "@homesusa/auth";
import {
  GridTable,
  GridProvider,
  GridToolbar,
  Columns
} from "@homesusa/grid-table";
import { SqftToAcres } from "core/utils";
import { marketStatusToHusaStatus } from "markets/utils";
import { Listing } from "../interfaces";
import { ListType, ReportStatus, SellerTypeLabel } from "../enums";
import { useGetListings } from "../hooks";
import { Filters } from "./filters.component";
import { ReportDates } from "./report-dates.component";
import { ReportsContext } from "../contexts";

export function ActivePendingListingsReport(): JSX.Element {
  const { marketRules } = React.useContext(ReportsContext);
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const { formState, formDispatch, fetchData, fetchDataForDownloading, data } =
    useGetListings();
  const columns: Columns<Listing> = React.useMemo(() => {
    const status =
      formState.status == ReportStatus.ActivePending
        ? [
            {
              Header: "List Status",
              accessor: (data: Listing): string => {
                const status = marketStatusToHusaStatus(
                  currentMarket,
                  data.listStatus
                );
                return status
                  ? getOptionValueFromRule(marketRules.mlsStatus, status)
                  : data.listStatus;
              },
              id: "listStatus"
            }
          ]
        : [];

    return [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1
      },
      ...status,
      {
        Header: "MLS #",
        accessor: "mlsNumber"
      },
      {
        Header: "Address",
        accessor: "address"
      },
      {
        Header: "Unit #",
        accessor: "unitNumber"
      },
      {
        Header: "City",
        accessor: (data: Listing): string =>
          getOptionValueFromRule(marketRules.city, data.city),
        id: "city"
      },
      {
        Header: "Zip",
        accessor: "zip"
      },
      {
        Header: "Subdivision",
        accessor: "subdivision"
      },
      {
        Header: "List Price",
        accessor: (data: Listing): string => Format.Money(data.listPrice),
        id: "listPrice"
      },
      {
        Header: "SQFT",
        accessor: "sqFt"
      },
      {
        Header: "Year Built",
        accessor: "yearBuilt"
      },
      {
        Header: "New / Resale",
        accessor: (data: Listing): string =>
          data.isNewHome ? "New" : "Resale",
        id: "isNewHome"
      },
      {
        Header: "B/B/HB",
        headertooltip: "Bed / Bath / Half Bath",
        accessor: (data: Listing): string =>
          `${data.bedrooms}/${data.fullBath}/${data.halfBath}`,
        id: "bedrooms"
      },
      {
        Header: "Pool",
        accessor: (data: Listing): string => (data.hasPrivatePool ? "Y" : "N"),
        id: "hasPrivatePool"
      },
      {
        Header: "Acres",
        accessor: (data: Listing): string => {
          if (currentMarket !== MarketCode.Houston) return data.lotSize;
          return SqftToAcres(data.sqFt);
        }
      },
      {
        Header: "List Date",
        accessor: (data: Listing): string => Format.Date(data.listDate),
        id: "listDate"
      },
      {
        Header: "CDOM",
        accessor: "cdom"
      },
      {
        Header: "Owner",
        accessor: "ownerName"
      },
      ...(currentMarket === MarketCode.CTX
        ? []
        : [
            {
              Header: "Occup",
              accessor: (data: Listing): string =>
                marketRules.occupancy
                  ? getOptionValueFromRule(
                      marketRules.occupancy,
                      data.occupancy
                    )
                  : data.occupancy,
              id: "occupancy"
            }
          ]),
      {
        Header: "Seller Type",
        accessor: (data: Listing): string =>
          SellerTypeLabel.get(data.sellerType) ?? data.sellerType,
        id: "sellerType"
      }
    ];
  }, [formState.status, currentMarket]);

  return (
    <FormContext.Provider
      value={{
        formState: formState,
        formDispatch,
        formMode: FormMode.Update,
        formRules: {},
        otherProps: {}
      }}
    >
      <GridProvider
        options={{
          columns,
          data: data?.data,
          showHeaderTooltip: true
        }}
        totalRows={data?.total}
      >
        <Filters onFetch={fetchData}>
          <Filters.Status
            statusAvailable={[
              ReportStatus.ActivePending,
              ReportStatus.Active,
              ReportStatus.Pending
            ]}
          />
          <Filters.ListType typeAvailable={[ListType.Residential]} />
          <Filters.Price />
          <Filters.Address />
          <Filters.Subdivision />
        </Filters>
        <GridToolbar>
          <GridToolbar.ExportButtons
            onClick={fetchDataForDownloading}
            fileName="active-peding-listings"
          />
          <GridToolbar.SearchFilter />
        </GridToolbar>
        <Box mt={2} />
        <GridTable />
        <ReportDates data={data} />
      </GridProvider>
    </FormContext.Provider>
  );
}
