export enum HoaRequirement {
  Mandatory = "mandatory",
  Voluntary = "voluntary",
  None = "none"
}

export const HoaRequirementLabel = new Map<HoaRequirement, string>([
  [HoaRequirement.Mandatory, "Mandatory"],
  [HoaRequirement.Voluntary, "Voluntary"],
  [HoaRequirement.None, "None"]
]);
