export enum PossibleFinancing {
  Assumption = "assumption",
  Conventional = "conventional",
  Fha = "fha",
  Owner = "owner",
  TxVeteransLandBrd = "txVeteransLandBrd",
  Usda = "usda",
  Va = "va",
  OtherFinancing = "otherFinancing"
}

export const PossibleFinancingLabel: Record<PossibleFinancing, string> = {
  [PossibleFinancing.Assumption]: "Assumption",
  [PossibleFinancing.Conventional]: "Conventional",
  [PossibleFinancing.Fha]: "FHA",
  [PossibleFinancing.Owner]: "Owner",
  [PossibleFinancing.TxVeteransLandBrd]: "TX Veterans Land Brd",
  [PossibleFinancing.Usda]: "USDA",
  [PossibleFinancing.Va]: "VA",
  [PossibleFinancing.OtherFinancing]: "Other Financing"
};
