export enum RoofDescription {
  Aluminum = "aluminum",
  Composition = "composition",
  Tile = "tile",
  BuiltUp = "builtUp",
  Slate = "slate",
  WoodShingle = "woodShingle",
  Other = "other"
}

export const RoofDescriptionLabel = new Map<RoofDescription, string>([
  [RoofDescription.Aluminum, "Aluminum"],
  [RoofDescription.Composition, "Composition"],
  [RoofDescription.Tile, "Tile"],
  [RoofDescription.BuiltUp, "Built Up"],
  [RoofDescription.Slate, "Slate"],
  [RoofDescription.WoodShingle, "Wood Shingle"],
  [RoofDescription.Other, "Other"]
]);
