export enum IntermediateSchools {
  Austin = "austin",
  Bonham = "bonham",
  BorgerIntermediate = "borgerIntermediate",
  BorgerMiddle = "borgerMiddle",
  Bowie = "bowie",
  Bushland = "bushland",
  CanyonIntermedJrHigh = "canyonIntermedJrHigh",
  Claude = "claude",
  Crockett = "crockett",
  DeZavala = "deZavala",
  DumasIntermediate = "dumasIntermediate",
  Fannin = "fannin",
  GreenwaysWestPlains = "greenwaysWestPlains",
  HappyJuniorHigh = "happyJuniorHigh",
  HighlandPark = "highlandPark",
  Houston = "houston",
  Mann = "mann",
  Other = "other",
  Panhandle = "panhandle",
  PerrytonJuniorHigh = "perrytonJuniorHigh",
  PinnacleIntermediate = "pinnacleIntermediate",
  PinnacleRandallJrHigh = "pinnacleRandallJrHigh",
  RandallJuniorHighSchool = "randallJuniorHighSchool",
  Travis = "travis",
  Vega = "vega",
  WestPlainsJuniorHigh = "westPlainsJuniorHigh",
  Wildorado = "wildorado",
  WilliamsIntermediate = "williamsIntermediate",
  WillowVistRiverRd = "willowVistRiverRd"
}

export const IntermediateSchoolsLabel: Record<IntermediateSchools, string> = {
  [IntermediateSchools.Austin]: "Austin",
  [IntermediateSchools.Bonham]: "Bonham",
  [IntermediateSchools.BorgerIntermediate]: "Borger Intermediate",
  [IntermediateSchools.BorgerMiddle]: "Borger Middle",
  [IntermediateSchools.Bowie]: "Bowie",
  [IntermediateSchools.Bushland]: "Bushland",
  [IntermediateSchools.CanyonIntermedJrHigh]: "Canyon Intermed./Jr High",
  [IntermediateSchools.Claude]: "Claude",
  [IntermediateSchools.Crockett]: "Crockett",
  [IntermediateSchools.DeZavala]: "De Zavala",
  [IntermediateSchools.DumasIntermediate]: "Dumas Intermediate",
  [IntermediateSchools.Fannin]: "Fannin",
  [IntermediateSchools.GreenwaysWestPlains]: "Greenways/West Plains",
  [IntermediateSchools.HappyJuniorHigh]: "Happy Junior High",
  [IntermediateSchools.HighlandPark]: "Highland Park",
  [IntermediateSchools.Houston]: "Houston",
  [IntermediateSchools.Mann]: "Mann",
  [IntermediateSchools.Other]: "Other",
  [IntermediateSchools.Panhandle]: "Panhandle",
  [IntermediateSchools.PerrytonJuniorHigh]: "Perryton Junior High",
  [IntermediateSchools.PinnacleIntermediate]: "Pinnacle Intermediate",
  [IntermediateSchools.PinnacleRandallJrHigh]: "Pinnacle/Randall Jr. High",
  [IntermediateSchools.RandallJuniorHighSchool]: "Randall Junior High School",
  [IntermediateSchools.Travis]: "Travis",
  [IntermediateSchools.Vega]: "Vega",
  [IntermediateSchools.WestPlainsJuniorHigh]: "West Plains Junior High",
  [IntermediateSchools.Wildorado]: "Wildorado",
  [IntermediateSchools.WilliamsIntermediate]: "Williams Intermediate",
  [IntermediateSchools.WillowVistRiverRd]: "Willow Vist/River Rd"
};
