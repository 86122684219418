export enum LotSize {
  ZeroAcre = "zeroAcre",
  QuarterAcre = "quarterAcre",
  HalfAcre = "halfAcre",
  OneAcre = "oneAcres",
  TwoAcres = "twoAcres"
}

export const LotSizeLabel = new Map<LotSize, string>([
  [LotSize.ZeroAcre, "0 Up To 1/4 Acre"],
  [LotSize.QuarterAcre, "1/4 Up to 1/2 Acre"],
  [LotSize.HalfAcre, "1/2 Up to 1 Acre"],
  [LotSize.OneAcre, "1 Up to 2 Acres"],
  [LotSize.TwoAcres, "2 Up to 5 Acres"]
]);
