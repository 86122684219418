import React, { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

import { SubmitButton } from "@homesusa/layout";
import { getOptionsFromEnumAndMap, useMounted } from "@homesusa/core";
import {
  GridTableContext,
  FetchData,
  getSortColumn
} from "@homesusa/grid-table";
import {
  DefaultFormContextProps,
  FormContext,
  Select,
  Input,
  NumberInput
} from "@homesusa/form";
import { AddressFilter } from "../interfaces/filters";
import {
  ListType,
  ListTypeLabel,
  ReportStatus,
  ReportStatusLabel
} from "../enums";

function Filters({
  onFetch,
  children
}: {
  onFetch: (props: FetchData) => Promise<void>;
  children?: React.ReactNode;
}): JSX.Element {
  const mounted = useMounted();
  const {
    instance: {
      state: { pageSize, pageIndex, sortBy, globalFilter }
    },
    setShowLoader
  } = React.useContext(GridTableContext);

  const fetchData = (): Promise<void> => {
    setShowLoader(true);
    return onFetch({
      pageSize,
      pageIndex,
      sortBy: getSortColumn(sortBy),
      globalFilter
    }).finally(() => setShowLoader(false));
  };

  React.useEffect(() => {
    if (mounted.current) {
      fetchData();
    }
  }, [pageSize, pageIndex, sortBy, globalFilter]);

  return (
    <Fragment>
      <Typography variant="h1" sx={{ mt: 3, mb: 3 }}>
        Report Filters
      </Typography>
      <Grid container spacing={2} wrap="nowrap" sx={{ mb: 3 }}>
        {children}
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <SubmitButton onClick={fetchData}>Search</SubmitButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}

Filters.Address = function AddresFilters(): JSX.Element {
  const { formState } =
    React.useContext<DefaultFormContextProps<AddressFilter>>(FormContext);

  return (
    <Fragment>
      <Grid item xs={4} sm={3} md={2}>
        <Input label="City" defaultValue={formState?.city} name="city" />
      </Grid>
      <Grid item xs={4} sm={3} md={2}>
        <NumberInput
          label="Zip"
          defaultValue={formState?.zip}
          name="zip"
          maxLength={5}
          numberFormat={{
            min: 0
          }}
        />
      </Grid>
    </Fragment>
  );
};

Filters.Subdivision = function SubdivisionFilter(): JSX.Element {
  const { formState } =
    React.useContext<DefaultFormContextProps<AddressFilter>>(FormContext);

  return (
    <Grid item xs={4} sm={3} md={2}>
      <Input
        label="Subdivision"
        defaultValue={formState?.subdivision}
        name="subdivision"
      />
    </Grid>
  );
};

Filters.ListType = function ListTypeFilter({
  typeAvailable
}: {
  typeAvailable: ListType[];
}): JSX.Element {
  return (
    <Grid item xs={4} sm={3} md={2}>
      <Select
        label="List Type"
        defaultValue={ListType.Residential}
        name="listType"
        options={getOptionsFromEnumAndMap(
          Object.values(ListType).filter((type) =>
            typeAvailable?.includes(type)
          ),
          ListTypeLabel
        )}
        fullWidth
      />
    </Grid>
  );
};

Filters.Status = function StatusFilter({
  statusAvailable
}: {
  statusAvailable: ReportStatus[];
}): JSX.Element {
  return (
    <Grid item xs={4} sm={3} md={2}>
      <Select
        label="Status"
        defaultValue={
          statusAvailable.length > 0 ? statusAvailable[0] : undefined
        }
        name="status"
        options={statusAvailable.reduce(
          (a, x) => ({ ...a, [x.toString()]: ReportStatusLabel.get(x) }),
          {}
        )}
        fullWidth
      />
    </Grid>
  );
};

Filters.Price = function ListFilters(): JSX.Element {
  return (
    <Fragment>
      <Grid item xs={4} sm={3} md={2}>
        <NumberInput
          label="Min Price"
          name="minPrice"
          numberFormat={{
            min: 0,
            prefix: "$",
            thousandSeparator: true
          }}
        />
      </Grid>
      <Grid item xs={4} sm={3} md={2}>
        <NumberInput
          label="Max Price"
          name="maxPrice"
          numberFormat={{
            min: 0,
            prefix: "$",
            thousandSeparator: true
          }}
        />
      </Grid>
    </Fragment>
  );
};

export { Filters };
