import {
  getOptionsFromEnumAndMap,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";
import {
  MlsArea,
  MlsAreaLabel,
  ConstructionStage,
  ConstructionStageLabel,
  MlsZone,
  MlsZoneLabel
} from "markets/amarillo/enums";

import { constructionStartYearRules } from "../../sections/property-rules";

export const propertyInfoRules: FormRules = {
  constructionCompletionDate: {
    label: "Estimated Completion Date",
    tooltip: "Estimated completion date where applicable.",
    requiredToSubmit: true
  },
  constructionStage: {
    label: "Construction Stage",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(ConstructionStage),
      ConstructionStageLabel
    )
  },
  taxId: { label: "Tax ID #", maxLength: 50 },
  constructionStartYear: constructionStartYearRules,
  propertyId: {
    label: "Property ID",
    maxLength: 50
  },
  legalDescription: {
    label: "Legal Description",
    maxLength: 120,
    requiredToSubmit: true
  },
  commonName: {
    label: "Common Name"
  },
  zone: {
    label: "Zone",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndRecord(Object.values(MlsZone), MlsZoneLabel)
  },
  mlsArea: {
    label: "Area",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndRecord(Object.values(MlsArea), MlsAreaLabel)
  },
  lockBoxNumber: {
    label: "Lock Box Number",
    maxLength: 10
  },
  lotDimension: {
    label: "Lot Size Dimensions",
    maxLength: 25
  },
  acres: {
    label: "Acres",
    maxLength: 16,
    tooltip: "For less than one acre use a decimal - Example .25",
    numberFormat: {
      decimalScale: 4
    }
  },
  lotSize: {
    label: "Lot Size Square Feet",
    maxLength: 25
  },
  updateGeocodes: { label: "Update Geocodes" },
  latitude: latitudeRule,
  longitude: longitudeRule
};
