export enum Appliances {
  Cooktop = "cooktop",
  Dishwasher = "dishwasher",
  Disposal = "disposal",
  DoubleOven = "doubleOven",
  IceMaker = "iceMaker",
  Microwave = "microwave",
  OtherBuiltIn = "otherBuiltIn",
  Oven = "oven",
  Range = "range",
  Refrigerator = "refrigerator",
  TrashCompactor = "trashCompactor",
  WarmingOven = "warmingOven",
  WasherDryer = "washerDryer",
  WineRefrigerator = "wineRefrigerator",
  None = "none"
}

export const AppliancesLabel: Record<Appliances, string> = {
  [Appliances.Cooktop]: "Cooktop",
  [Appliances.Dishwasher]: "Dishwasher",
  [Appliances.Disposal]: "Disposal",
  [Appliances.DoubleOven]: "Double Oven",
  [Appliances.IceMaker]: "Ice Maker",
  [Appliances.Microwave]: "Microwave",
  [Appliances.OtherBuiltIn]: "Other Built In",
  [Appliances.Oven]: "Oven",
  [Appliances.Range]: "Range",
  [Appliances.Refrigerator]: "Refrigerator",
  [Appliances.TrashCompactor]: "Trash Compactor",
  [Appliances.WarmingOven]: "Warming Oven",
  [Appliances.WasherDryer]: "Washer/Dryer",
  [Appliances.WineRefrigerator]: "Wine Refrigerator",
  [Appliances.None]: "None"
};
