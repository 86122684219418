export enum LaundryFeatures {
  ElectricDryerHookup = "electricDryerHookup",
  GasDryerHookup = "gasDryerHookup",
  LaundryChute = "laundryChute",
  LaundrySink = "laundrySink",
  None = "none",
  StackableConnections = "stackableConnections",
  WasherHookup = "washerHookup"
}

export const LaundryFeaturesLabel = new Map<LaundryFeatures, string>([
  [LaundryFeatures.ElectricDryerHookup, "Electric Dryer Hookup"],
  [LaundryFeatures.GasDryerHookup, "Gas Dryer Hookup"],
  [LaundryFeatures.LaundryChute, "Laundry Chute"],
  [LaundryFeatures.None, "None"],
  [LaundryFeatures.WasherHookup, "Washer Hookup"],
  [LaundryFeatures.LaundrySink, "Laundry Sink"],
  [LaundryFeatures.StackableConnections, "Stackable W/D Connections"]
]);
