export enum PropertySubType {
  SingleFamilyResidence = "singleFamilyResidence",
  Condominium = "condominium",
  Townhouse = "townhouse",
  Duplex = "duplex"
}

export const PropertySubTypeLabel: Record<PropertySubType, string> = {
  [PropertySubType.SingleFamilyResidence]: "Single Family Residence",
  [PropertySubType.Condominium]: "Condominium",
  [PropertySubType.Townhouse]: "Townhouse",
  [PropertySubType.Duplex]: "Duplex"
};

export const ResidentialPropertyType = [
  PropertySubType.SingleFamilyResidence,
  PropertySubType.Condominium,
  PropertySubType.Townhouse
];

export const MultiFamilyPropertyType = [PropertySubType.Duplex];
