import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import { AuthCompanyContext, useHasRole } from "@homesusa/auth";
import { RouterPath } from "core/enums";
import { getActiveAndPendingStatus } from "markets/utils";
import { IndicatorComponent } from "../indicator-component/indicator.component";
import { DiscrepancyArea } from "../discrepancy-area/discrepancy-area.component";
import { AlertCard } from "../alert-card/alert-card.component";
import { ChartContext } from "../../../context";
import { AlertType } from "../../../enums";
import { GreetingMessage } from "../../greeting-message.component";
import { useStyles } from "./left-panel.styles";
import { AlertButtons } from "./alert-buttons.component";
import { DiscrepancyData } from "modules/dashboard/interfaces";

export const LeftPanel = (): JSX.Element => {
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const { isMlsAdministrator } = useHasRole();
  const navigate = useNavigate();
  const { chartState } = React.useContext(ChartContext);
  const classes = useStyles();
  const today = format(new Date(), "MM/dd/yyyy");
  const activeAndPendingStatus = React.useMemo(
    () => getActiveAndPendingStatus(currentMarket).toString(),
    [currentMarket]
  );

  const { lockedUrl, unlistedUrl } = React.useMemo(() => {
    if (isMlsAdministrator) {
      return {
        lockedUrl: `/alerts?alert=${AlertType.LockedListings}#detaileds`,
        unlistedUrl: `/alerts?alert=${AlertType.NotListedInMls}#reminders`
      };
    }

    return {
      lockedUrl: `/alerts?alert=${AlertType.LockedListings}#xml`,
      unlistedUrl: `/alerts?alert=${AlertType.NotListedInMls}#reminders`
    };
  }, [isMlsAdministrator]);
  const { currentCompany } = React.useContext(AuthCompanyContext);

  const funnelData: DiscrepancyData = React.useMemo(() => {
    if (!currentCompany?.name.includes("ABC Homes")) {
      return chartState.discrepancyData;
    }
    const data: DiscrepancyData = {
      ...chartState.discrepancyData,
      inventoryInXml: 57,
      inventoryListedInMLS: 73,
      listedInMlsAndBuilder: 30,
      listingDiscrepancy: 1,
      averageDiscrepancy: 25000,
      totalDiscrepancy: 25000
    };
    return data;
  }, [currentCompany, chartState.discrepancyData]);
  return (
    <Box sx={classes.root}>
      <GreetingMessage />
      <AlertButtons />
      <AlertCard
        title="CONCESSION EXPIRATION DATE"
        subtitle={`Next Expiration date ${today}`}
        value={chartState.totalAgentBonus}
        onClick={(): void =>
          navigate(
            `${RouterPath.DashboardAlerts}?alert=${AlertType.AgentBonusExpirationDate}#urgent`
          )
        }
      />
      <Box sx={classes.indicatorsContainer}>
        <IndicatorComponent
          title="LISTINGS"
          value={chartState.totalActiveAndPending}
          onClick={(): void =>
            navigate(
              `${RouterPath.ListingSaleGrid}?status=${activeAndPendingStatus}`
            )
          }
        />
        <IndicatorComponent
          title="LOCKED"
          value={chartState.totalLockedListings}
          onClick={(): void => navigate(lockedUrl)}
        />
        <IndicatorComponent
          title="UNLISTED"
          value={chartState.totalUnlisted}
          onClick={(): void => navigate(unlistedUrl)}
        />
      </Box>
      {!isMlsAdministrator && <DiscrepancyArea discrepancyData={funnelData} />}
    </Box>
  );
};
