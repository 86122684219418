import React, { Fragment } from "react";

import { XmlStatus } from "core/enums";
import { ToolbarAlertStyled as AlertStyled } from "core/styles";

export function XmlStatusAlert({ status }: { status: XmlStatus }): JSX.Element {
  if (status == XmlStatus.AwaitingApproval) {
    return <AlertStyled severity="warning">Awaiting Approval</AlertStyled>;
  }

  if (status == XmlStatus.Rejected) {
    return <AlertStyled severity="error">Rejected</AlertStyled>;
  }

  if (status == XmlStatus.Approved) {
    return <AlertStyled severity="success">Approved</AlertStyled>;
  }

  return <Fragment></Fragment>;
}
