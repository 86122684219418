import React from "react";

import { FormContextActions, formContextReducer } from "@homesusa/form";
import { FetchData } from "@homesusa/grid-table";
import { ListFilter } from "../interfaces/filters";
import { Listing, ReportDataset } from "../interfaces";
import { ReportsContext } from "../contexts";

export const useGetListings = (): {
  formState: ListFilter;
  formDispatch: React.Dispatch<FormContextActions<Listing>>;
  data: ReportDataset<Listing>;
  fetchDataForDownloading: () => Promise<Listing[]>;
  fetchData: (props: FetchData) => Promise<void>;
} => {
  const {
    services: { getListings }
  } = React.useContext(ReportsContext);
  const [formState, formDispatch] = React.useReducer<
    (state: ListFilter, action: FormContextActions) => ListFilter
  >(formContextReducer, {});

  const [data, setData] = React.useState<ReportDataset<Listing>>({
    data: [],
    total: 0,
    agentLastUpdate: undefined,
    officeLastUpdate: undefined,
    listingLastUpdate: undefined
  });

  const fetchData = async ({
    pageSize,
    pageIndex,
    sortBy,
    globalFilter
  }: FetchData): Promise<void> => {
    if (!formState.status) {
      return;
    }
    return getListings({
      ...formState,
      top: pageSize,
      skip: pageIndex,
      sortBy,
      searchBy: globalFilter
    }).then((response) => {
      setData(response);
    });
  };

  const fetchDataForDownloading = async (): Promise<Listing[]> => {
    return getListings({
      ...formState,
      top: data.total,
      skip: 0
    }).then((response) => {
      return response.data;
    });
  };

  return { formState, formDispatch, fetchData, fetchDataForDownloading, data };
};
