export enum WaterFeatures {
  BeltonLake = "beltonLake",
  BlancoRiver = "blancoRiver",
  Bluff = "bluff",
  BrazosRiver = "brazosRiver",
  Canal = "canal",
  CanyonLakeUSCorpofEngineers = "canyonLakeUSCorpofEngineers",
  CitySkylineView = "citySkylineView",
  ColoradoRiver = "coloradoRiver",
  ComalRiver = "comalRiver",
  CountrysideView = "countrysideView",
  CreekSeasonal = "creekSeasonal",
  CreekStream = "creekStream",
  Fields = "fields",
  GolfCourse = "golfCourse",
  Greenbelt = "greenbelt",
  GuadalupeRiver = "guadalupeRiver",
  HillCountryView = "hillCountryView",
  Lake = "lake",
  LakeAustin = "lakeAustin",
  LakeBastrop = "lakeBastrop",
  LakeBuchanan = "lakeBuchanan",
  LakeDunlap = "lakeDunlap",
  LakeLBJ = "lakeLBJ",
  LakeMcQueeney = "lakeMcQueeney",
  LakePlacid = "lakePlacid",
  LakeSeguin = "lakeSeguin",
  LakeTravis = "lakeTravis",
  MeadowLake = "meadowLake",
  NoneWaterFeatures = "noneWaterFeatures",
  Panoramic = "panoramic",
  PedernalesRiver = "pedernalesRiver",
  PondStockTank = "pondStockTank",
  River = "river",
  SanMarcosRiver = "sanMarcosRiver",
  SeeAgent = "seeAgent",
  StillhouseHollowLake = "stillhouseHollowLake",
  WaterAccess = "waterAccess",
  WaterView = "waterView",
  Waterfront = "waterfront",
  Woods = "woods",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const WaterFeaturesLabel = new Map<WaterFeatures, string>([
  [WaterFeatures.BeltonLake, "Belton Lake"],
  [WaterFeatures.BlancoRiver, "Blanco River"],
  [WaterFeatures.Bluff, "Bluff"],
  [WaterFeatures.BrazosRiver, "Brazos River"],
  [WaterFeatures.Canal, "Canal"],
  [
    WaterFeatures.CanyonLakeUSCorpofEngineers,
    "Canyon Lake/U.S. Corp of Engineers"
  ],
  [WaterFeatures.CitySkylineView, "City Skyline View"],
  [WaterFeatures.ColoradoRiver, "Colorado River"],
  [WaterFeatures.ComalRiver, "Comal River"],
  [WaterFeatures.CountrysideView, "Countryside View"],
  [WaterFeatures.CreekSeasonal, "Creek-Seasonal"],
  [WaterFeatures.CreekStream, "Creek/Stream"],
  [WaterFeatures.Fields, "Fields"],
  [WaterFeatures.GolfCourse, "Golf Course"],
  [WaterFeatures.Greenbelt, "Greenbelt"],
  [WaterFeatures.GuadalupeRiver, "Guadalupe River"],
  [WaterFeatures.HillCountryView, "Hill Country View"],
  [WaterFeatures.Lake, "Lake"],
  [WaterFeatures.LakeAustin, "Lake Austin"],
  [WaterFeatures.LakeBastrop, "Lake Bastrop"],
  [WaterFeatures.LakeBuchanan, "Lake Buchanan"],
  [WaterFeatures.LakeDunlap, "Lake Dunlap"],
  [WaterFeatures.LakeLBJ, "Lake LBJ"],
  [WaterFeatures.LakeMcQueeney, "Lake McQueeney"],
  [WaterFeatures.LakePlacid, "Lake Placid"],
  [WaterFeatures.LakeSeguin, "Lake Seguin"],
  [WaterFeatures.LakeTravis, "Lake Travis"],
  [WaterFeatures.MeadowLake, "Meadow Lake"],
  [WaterFeatures.NoneWaterFeatures, "None Water Features"],
  [WaterFeatures.Panoramic, "Panoramic"],
  [WaterFeatures.PedernalesRiver, "Pedernales River"],
  [WaterFeatures.PondStockTank, "Pond/Stock Tank"],
  [WaterFeatures.River, "River"],
  [WaterFeatures.SanMarcosRiver, "San Marcos River"],
  [WaterFeatures.SeeAgent, "See Agent"],
  [WaterFeatures.StillhouseHollowLake, "Stillhouse Hollow Lake"],
  [WaterFeatures.WaterAccess, "Water Access"],
  [WaterFeatures.WaterView, "Water View"],
  [WaterFeatures.Waterfront, "Waterfront"],
  [WaterFeatures.Woods, "Woods"],
  [WaterFeatures.None, "None"],
  [WaterFeatures.OtherSeeRemarks, "Other-See Remarks"]
]);
