export enum StreetType {
  Springs = "springs",
  Locks = "locks",
  Shore = "shore",
  Cliff = "cliff",
  Inlet = "inlet",
  Track = "track",
  Forks = "forks",
  Fall = "fall",
  Lodge = "lodge",
  Brook = "brook",
  Stravenue = "stravenue",
  Mews = "mews",
  Summit = "summit",
  Lane = "lane",
  Land = "land",
  Passage = "passage",
  Stream = "stream",
  Viaduct = "viaduct",
  Branch = "branch",
  Station = "station",
  Mill = "mill",
  Brooks = "brooks",
  Junction = "junction",
  Estates = "estates",
  Mission = "mission",
  Ferry = "ferry",
  Mountains = "mountains",
  Cliffs = "cliffs",
  Ranch = "ranch",
  Bluff = "bluff",
  Coves = "coves",
  Rest = "rest",
  Way = "way",
  Glen = "glen",
  Causeway = "causeway",
  Burg = "burg",
  Highway = "highway",
  Valleys = "valleys",
  Crossing = "crossing",
  Dam = "dam",
  Bayou = "bayou",
  Corner = "corner",
  Manors = "manors",
  Turnpike = "turnpike",
  Bottom = "bottom",
  View = "view",
  Tunnel = "tunnel",
  Pine = "pine",
  Fort = "fort",
  Skyway = "skyway",
  Loaf = "loaf",
  Keys = "keys",
  Island = "island",
  Cape = "cape",
  Parkway = "parkway",
  Islands = "islands",
  Canyon = "canyon",
  Motorway = "motorway",
  Blank = "blank",
  Street = "street",
  Neck = "neck",
  Annex = "annex",
  Rapids = "rapids",
  Knolls = "knolls",
  Forges = "forges",
  Ports = "ports",
  Avenue = "avenue",
  Gateway = "gateway",
  Arcade = "arcade",
  Glens = "glens",
  Falls = "falls",
  Road = "road",
  Square = "square",
  Prairie = "prairie",
  Spur = "spur",
  Green = "green",
  Path = "path",
  Meadow = "meadow",
  Divide = "divide",
  Lights = "lights",
  Camp = "camp",
  Spurs = "spurs",
  Court = "court",
  Park = "park",
  Forge = "forge",
  Flats = "flats",
  Greens = "greens",
  Knoll = "knoll",
  Plain = "plain",
  ByPass = "by",
  Forest = "forest",
  Wynd = "wynd",
  Courts = "courts",
  Union = "union",
  Ridges = "ridges",
  Pike = "pike",
  Crescent = "crescent",
  Ways = "ways",
  Manor = "manor",
  Extensions = "extensions",
  Downs = "downs",
  Dale = "dale",
  Expressway = "expressway",
  Estate = "estate",
  Lake = "lake",
  Rue = "rue",
  Ramp = "ramp",
  Boulevard = "boulevard",
  Rapid = "rapid",
  Grove = "grove",
  Haven = "haven",
  Orchard = "orchard",
  Shores = "shores",
  Loop = "loop",
  Chase = "chase",
  Crossroad = "crossroad",
  Wells = "wells",
  Well = "well",
  Oval = "oval",
  Pointe = "pointe",
  Harbor = "harbor",
  Hills = "hills",
  Commons = "commons",
  Light = "light",
  Parkways = "parkways",
  River = "river",
  Ville = "ville",
  Links = "links",
  Extension = "extension",
  Heights = "heights",
  Flat = "flat",
  Fork = "fork",
  Plaza = "plaza",
  Lakes = "lakes",
  Spring = "spring",
  Centers = "centers",
  Groves = "groves",
  Landing = "landing",
  Isle = "isle",
  Row = "row",
  Garden = "garden",
  Run = "run",
  Circle = "circle",
  Drive = "drive",
  Bend = "bend",
  Streets = "streets",
  Junctions = "junctions",
  Bridge = "bridge",
  Hollow = "hollow",
  Key = "key",
  Mountain = "mountain",
  Cove = "cove",
  Hill = "hill",
  Trafficway = "trafficway",
  End = "end",
  Bluffs = "bluffs",
  Views = "views",
  Unions = "unions",
  Trail = "trail",
  Trace = "trace",
  Pines = "pines",
  Place = "place",
  Squares = "squares",
  Alley = "alley",
  Harbors = "harbors",
  Close = "close",
  Walk = "walk",
  Common = "common",
  Plains = "plains",
  Gardens = "gardens",
  Mount = "mount",
  Ford = "ford",
  Course = "course",
  Creek = "creek",
  Fields = "fields",
  Woods = "woods",
  Wall = "wall",
  Villages = "villages",
  Walks = "walks",
  Shoals = "shoals",
  Overpass = "overpass",
  Route = "route",
  Mills = "mills",
  Field = "field",
  Meadows = "meadows",
  Port = "port",
  Beach = "beach",
  Fords = "fords",
  Drives = "drives",
  Burgs = "burgs",
  Circles = "circles",
  Throughway = "throughway",
  Center = "center",
  Village = "village",
  Valley = "valley",
  Curve = "curve",
  Points = "points",
  Vista = "vista",
  Ridge = "ridge",
  Underpass = "underpass",
  Corners = "corners",
  Terrace = "terrace",
  Club = "club",
  Mall = "mall",
  Pass = "pass",
  Point = "point",
  Radial = "radial",
  Crest = "crest",
  Shoal = "shoal",
  Freeway = "freeway",
  Roads = "roads",
  Parks = "parks",
  Lock = "lock"
}
export const StreetTypeLabel = new Map<StreetType, string>([
  [StreetType.Springs, "Springs"],
  [StreetType.Locks, "Locks"],
  [StreetType.Shore, "Shore"],
  [StreetType.Cliff, "Cliff"],
  [StreetType.Inlet, "Inlet"],
  [StreetType.Track, "Track"],
  [StreetType.Forks, "Forks"],
  [StreetType.Fall, "Fall"],
  [StreetType.Lodge, "Lodge"],
  [StreetType.Brook, "Brook"],
  [StreetType.Stravenue, "Stravenue"],
  [StreetType.Mews, "Mews"],
  [StreetType.Summit, "Summit"],
  [StreetType.Lane, "Lane"],
  [StreetType.Land, "Land"],
  [StreetType.Passage, "Passage"],
  [StreetType.Stream, "Stream"],
  [StreetType.Viaduct, "Viaduct"],
  [StreetType.Branch, "Branch"],
  [StreetType.Station, "Station"],
  [StreetType.Mill, "Mill"],
  [StreetType.Brooks, "Brooks"],
  [StreetType.Junction, "Junction"],
  [StreetType.Estates, "Estates"],
  [StreetType.Mission, "Mission"],
  [StreetType.Ferry, "Ferry"],
  [StreetType.Mountains, "Mountains"],
  [StreetType.Cliffs, "Cliffs"],
  [StreetType.Ranch, "Ranch"],
  [StreetType.Bluff, "Bluff"],
  [StreetType.Coves, "Coves"],
  [StreetType.Rest, "Rest"],
  [StreetType.Way, "Way"],
  [StreetType.Glen, "Glen"],
  [StreetType.Causeway, "Causeway"],
  [StreetType.Burg, "Burg"],
  [StreetType.Highway, "Highway"],
  [StreetType.Valleys, "Valleys"],
  [StreetType.Crossing, "Crossing"],
  [StreetType.Dam, "Dam"],
  [StreetType.Bayou, "Bayou"],
  [StreetType.Corner, "Corner"],
  [StreetType.Manors, "Manors"],
  [StreetType.Turnpike, "Turnpike"],
  [StreetType.Bottom, "Bottom"],
  [StreetType.View, "View"],
  [StreetType.Tunnel, "Tunnel"],
  [StreetType.Pine, "Pine"],
  [StreetType.Fort, "Fort"],
  [StreetType.Skyway, "Skyway"],
  [StreetType.Loaf, "Loaf"],
  [StreetType.Keys, "Keys"],
  [StreetType.Island, "Island"],
  [StreetType.Cape, "Cape"],
  [StreetType.Parkway, "Parkway"],
  [StreetType.Islands, "Islands"],
  [StreetType.Canyon, "Canyon"],
  [StreetType.Motorway, "Motorway"],
  [StreetType.Blank, "Blank"],
  [StreetType.Street, "Street"],
  [StreetType.Neck, "Neck"],
  [StreetType.Annex, "Annex"],
  [StreetType.Rapids, "Rapids"],
  [StreetType.Knolls, "Knolls"],
  [StreetType.Forges, "Forges"],
  [StreetType.Ports, "Ports"],
  [StreetType.Avenue, "Avenue"],
  [StreetType.Gateway, "Gateway"],
  [StreetType.Arcade, "Arcade"],
  [StreetType.Glens, "Glens"],
  [StreetType.Falls, "Falls"],
  [StreetType.Road, "Road"],
  [StreetType.Square, "Square"],
  [StreetType.Prairie, "Prairie"],
  [StreetType.Spur, "Spur"],
  [StreetType.Green, "Green"],
  [StreetType.Path, "Path"],
  [StreetType.Meadow, "Meadow"],
  [StreetType.Divide, "Divide"],
  [StreetType.Lights, "Lights"],
  [StreetType.Camp, "Camp"],
  [StreetType.Spurs, "Spurs"],
  [StreetType.Court, "Court"],
  [StreetType.Park, "Park"],
  [StreetType.Forge, "Forge"],
  [StreetType.Flats, "Flats"],
  [StreetType.Greens, "Greens"],
  [StreetType.Knoll, "Knoll"],
  [StreetType.Plain, "Plain"],
  [StreetType.ByPass, "ByPass"],
  [StreetType.Forest, "Forest"],
  [StreetType.Wynd, "Wynd"],
  [StreetType.Courts, "Courts"],
  [StreetType.Union, "Union"],
  [StreetType.Ridges, "Ridges"],
  [StreetType.Pike, "Pike"],
  [StreetType.Crescent, "Crescent"],
  [StreetType.Ways, "Ways"],
  [StreetType.Manor, "Manor"],
  [StreetType.Extensions, "Extensions"],
  [StreetType.Downs, "Downs"],
  [StreetType.Dale, "Dale"],
  [StreetType.Expressway, "Expressway"],
  [StreetType.Estate, "Estate"],
  [StreetType.Lake, "Lake"],
  [StreetType.Rue, "Rue"],
  [StreetType.Ramp, "Ramp"],
  [StreetType.Boulevard, "Boulevard"],
  [StreetType.Rapid, "Rapid"],
  [StreetType.Grove, "Grove"],
  [StreetType.Haven, "Haven"],
  [StreetType.Orchard, "Orchard"],
  [StreetType.Shores, "Shores"],
  [StreetType.Loop, "Loop"],
  [StreetType.Chase, "Chase"],
  [StreetType.Crossroad, "Crossroad"],
  [StreetType.Wells, "Wells"],
  [StreetType.Well, "Well"],
  [StreetType.Oval, "Oval"],
  [StreetType.Pointe, "Pointe"],
  [StreetType.Harbor, "Harbor"],
  [StreetType.Hills, "Hills"],
  [StreetType.Commons, "Commons"],
  [StreetType.Light, "Light"],
  [StreetType.Parkways, "Parkways"],
  [StreetType.River, "River"],
  [StreetType.Ville, "Ville"],
  [StreetType.Links, "Links"],
  [StreetType.Extension, "Extension"],
  [StreetType.Heights, "Heights"],
  [StreetType.Flat, "Flat"],
  [StreetType.Fork, "Fork"],
  [StreetType.Plaza, "Plaza"],
  [StreetType.Lakes, "Lakes"],
  [StreetType.Spring, "Spring"],
  [StreetType.Centers, "Centers"],
  [StreetType.Groves, "Groves"],
  [StreetType.Landing, "Landing"],
  [StreetType.Isle, "Isle"],
  [StreetType.Row, "Row"],
  [StreetType.Garden, "Garden"],
  [StreetType.Run, "Run"],
  [StreetType.Circle, "Circle"],
  [StreetType.Drive, "Drive"],
  [StreetType.Bend, "Bend"],
  [StreetType.Streets, "Streets"],
  [StreetType.Junctions, "Junctions"],
  [StreetType.Bridge, "Bridge"],
  [StreetType.Hollow, "Hollow"],
  [StreetType.Key, "Key"],
  [StreetType.Mountain, "Mountain"],
  [StreetType.Cove, "Cove"],
  [StreetType.Hill, "Hill"],
  [StreetType.Trafficway, "Trafficway"],
  [StreetType.End, "End"],
  [StreetType.Bluffs, "Bluffs"],
  [StreetType.Views, "Views"],
  [StreetType.Unions, "Unions"],
  [StreetType.Trail, "Trail"],
  [StreetType.Trace, "Trace"],
  [StreetType.Pines, "Pines"],
  [StreetType.Place, "Place"],
  [StreetType.Squares, "Squares"],
  [StreetType.Alley, "Alley"],
  [StreetType.Harbors, "Harbors"],
  [StreetType.Close, "Close"],
  [StreetType.Walk, "Walk"],
  [StreetType.Common, "Common"],
  [StreetType.Plains, "Plains"],
  [StreetType.Gardens, "Gardens"],
  [StreetType.Mount, "Mount"],
  [StreetType.Ford, "Ford"],
  [StreetType.Course, "Course"],
  [StreetType.Creek, "Creek"],
  [StreetType.Fields, "Fields"],
  [StreetType.Woods, "Woods"],
  [StreetType.Wall, "Wall"],
  [StreetType.Villages, "Villages"],
  [StreetType.Walks, "Walks"],
  [StreetType.Shoals, "Shoals"],
  [StreetType.Overpass, "Overpass"],
  [StreetType.Route, "Route"],
  [StreetType.Mills, "Mills"],
  [StreetType.Field, "Field"],
  [StreetType.Meadows, "Meadows"],
  [StreetType.Port, "Port"],
  [StreetType.Beach, "Beach"],
  [StreetType.Fords, "Fords"],
  [StreetType.Drives, "Drives"],
  [StreetType.Burgs, "Burgs"],
  [StreetType.Circles, "Circles"],
  [StreetType.Throughway, "Throughway"],
  [StreetType.Center, "Center"],
  [StreetType.Village, "Village"],
  [StreetType.Valley, "Valley"],
  [StreetType.Curve, "Curve"],
  [StreetType.Points, "Points"],
  [StreetType.Vista, "Vista"],
  [StreetType.Ridge, "Ridge"],
  [StreetType.Underpass, "Underpass"],
  [StreetType.Corners, "Corners"],
  [StreetType.Terrace, "Terrace"],
  [StreetType.Club, "Club"],
  [StreetType.Mall, "Mall"],
  [StreetType.Pass, "Pass"],
  [StreetType.Point, "Point"],
  [StreetType.Radial, "Radial"],
  [StreetType.Crest, "Crest"],
  [StreetType.Shoal, "Shoal"],
  [StreetType.Freeway, "Freeway"],
  [StreetType.Roads, "Roads"],
  [StreetType.Parks, "Parks"],
  [StreetType.Lock, "Lock"]
]);
