import React, { Suspense } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { Grid, LinearProgress, Typography } from "@mui/material";

import { Select } from "@homesusa/form";
import { useDocumentTitle } from "@homesusa/layout";
import { useHasRole, AuthCompanyContext } from "@homesusa/auth";
import { useGetCompanyInfo } from "core/hooks";
import { Report, ReportLabel } from "../enums";
import { ReportsProvider } from "../contexts";

export function ReportsOutlet(): JSX.Element {
  useDocumentTitle("Reports");
  const location = useLocation();
  const navigate = useNavigate();
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const [companyDetails] = useGetCompanyInfo(currentCompany?.id);
  const { isMlsAdministrator } = useHasRole();
  const reportOptions = React.useMemo(() => {
    let reportTypes = Object.values(Report);
    if (!isMlsAdministrator) {
      reportTypes = reportTypes.filter(
        (value) => value != Report.BillingReport
      );

      if (
        companyDetails &&
        !companyDetails.settingInfo.showSellingAgentInternalReport
      ) {
        reportTypes = reportTypes.filter(
          (value) => value != Report.SellingAgentsInternal
        );
      }
    }
    return reportTypes.reduce(
      (a, report) => ({ ...a, [report]: ReportLabel.get(report) }),
      {}
    );
  }, [isMlsAdministrator, companyDetails]);
  return (
    <Suspense fallback={<LinearProgress />}>
      <ReportsProvider>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Listing Reports
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={3} md={2}>
            <Select
              label="Report"
              defaultValue={
                Object.values(Report).find((report) =>
                  location.pathname.endsWith(report)
                )
                  ? location.pathname
                  : Report.ActivePendingListings
              }
              options={reportOptions}
              onChange={(value): void => {
                const fieldValue = value === undefined ? "" : value;
                navigate(fieldValue);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography sx={{ mb: 1, mt: 1 }}>
          MLS Reports - results reflect the last 12 months of data
        </Typography>
        <Outlet />
      </ReportsProvider>
    </Suspense>
  );
}
