export enum SellerConcessionDesc {
  None = "none",
  ClosingCosts = "closingCosts",
  HomeWarranty = "homeWarranty",
  Prepaid = "prepaid",
  Repairs = "repairs",
  OtherSeeAgentRemarks = "otherSeeAgentRemarks"
}
export const SellerConcessionDescLabel = new Map<SellerConcessionDesc, string>([
  [SellerConcessionDesc.None, "None"],
  [SellerConcessionDesc.ClosingCosts, "Closing Costs"],
  [SellerConcessionDesc.HomeWarranty, "Home Warranty"],
  [SellerConcessionDesc.Prepaid, "Prepaid"],
  [SellerConcessionDesc.Repairs, "Repairs"],
  [SellerConcessionDesc.OtherSeeAgentRemarks, "Other - See Agent Remarks"]
]);
