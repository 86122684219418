import React from "react";

import { CompanyServiceCode, ServiceSubscriptions } from "@homesusa/core";
import { getCompanyServices } from "core/services/company.service";

export const useHasCompanyService = (
  service: CompanyServiceCode,
  companyId?: string
): boolean | undefined => {
  const [companyServices, setCompanyServices] =
    React.useState<ServiceSubscriptions[]>();

  React.useEffect(() => {
    if (!!companyId) {
      getCompanyServices(companyId).then((response) =>
        setCompanyServices(response.data)
      );
    }
  }, [companyId]);

  const hasService = React.useMemo(
    () => companyServices?.some(({ serviceCode }) => serviceCode === service),
    [companyServices]
  );

  return hasService;
};
