import { useRefreshEntity } from "core/hooks";
import { ListingContext } from "modules/listings/contexts";

type LockedListingError = {
  response: { status: number; data: { message?: string } };
};

export const useCatchLockedListingError = (): ((
  error: LockedListingError
) => void) => {
  const refreshListing = useRefreshEntity(ListingContext);
  const catchError = (error: LockedListingError): void => {
    const {
      response: { status, data }
    } = error;
    if (
      status == 400 &&
      data &&
      (data.message?.includes("listing is locked") ||
        data.message?.includes("listing is awaiting"))
    ) {
      refreshListing();
    }
    throw error;
  };

  return catchError;
};
