import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { utilitiesRules } from "../../sections";
import {
  HomeFaces,
  HousingStyle,
  HomeFacesLabel,
  HousingStyleLabel,
  LotImprovements,
  LotImprovementsLabel,
  PrivatePool,
  PrivatePoolLabel,
  WindowCoverings,
  WindowCoveringsLabel
} from "markets/sabor/enums";

export const featuresInfoRules: FormRules = {
  ...utilitiesRules,
  propertyDescription: {
    label: "Property Description",
    requiredToSubmit: true,
    maxLength: 4000,
    minLength: 25,
    rows: 5,
    minLengthSuggestion: 100,
    helperText:
      "MLS number, Built by <BUILDER> and Completion Info will be added as a prefix to this field. DO NOT INCLUDE."
  },
  housingStyle: {
    requiredToSubmit: true,
    label: "Style of House",
    options: getOptionsFromEnumAndMap(
      Object.values(HousingStyle),
      HousingStyleLabel
    )
  },
  windowCoverings: {
    requiredToSubmit: true,
    label: "Window Coverings",
    options: getOptionsFromEnumAndMap(
      Object.values(WindowCoverings),
      WindowCoveringsLabel
    ),
    noneOption: [
      WindowCoverings.AllRemain,
      WindowCoverings.NoneRemain,
      WindowCoverings.SomeRemain
    ]
  },
  privatePool: {
    label: "Pool Features",
    options: getOptionsFromEnumAndMap(
      Object.values(PrivatePool),
      PrivatePoolLabel
    ),
    rows: 2,
    noneOption: [PrivatePool.None]
  },
  hasPrivatePool: {
    requiredToSubmit: true,
    label: "Private pool on property?"
  },
  homeFaces: {
    label: "Home Faces",
    options: getOptionsFromEnumAndMap(Object.values(HomeFaces), HomeFacesLabel)
  },
  lotImprovements: {
    label: "Lot Improvements",
    lastOptions: [LotImprovements.Other],
    options: getOptionsFromEnumAndMap(
      Object.values(LotImprovements),
      LotImprovementsLabel
    )
  }
};
