export enum SqFtSource {
  Appraiser = "appraiser",
  BuilderPlans = "builderPlans",
  AppraisalDist = "appraisalDist",
  Hud = "hud"
}
export const SqFtSourceLabel = new Map<SqFtSource, string>([
  [SqFtSource.Appraiser, "Appraiser"],
  [SqFtSource.BuilderPlans, "Bldr Plans"],
  [SqFtSource.AppraisalDist, "Appsl Dist"],
  [SqFtSource.Hud, "HUD"]
]);
