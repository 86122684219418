import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  badIndicatorContainer: SxProps;
  mlsDiscrepancyContainer: SxProps;
  badAreaTitle: SxProps;
  chartContainer: SxProps;
}

export const useStyles = (): Styles => {
  return {
    root: {
      display: "flex",
      flex: 1,
      columnGap: 1,
      overflow: "hidden"
    },
    badIndicatorContainer: {
      backgroundColor: "#ECEDF1",
      flex: 0.4,
      padding: "8px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      rowGap: 1.5
    },
    mlsDiscrepancyContainer: {
      backgroundColor: "white",
      borderRadius: "50%",
      height: "90px",
      width: "90px",
      border: "solid 10px #dadce4",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "error.main",
        cursor: "pointer",
        "& .MuiTypography-root": { color: "white" }
      },
      transition: "all 0.3s ease-in-out"
    },
    badAreaTitle: {
      color: "#EC6666",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "16px"
    },
    chartContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "8px",
      boxSizing: "border-box",
      "& canvas": {
        maxWidth: "100%",
        minHeight: "200px"
      }
    }
  };
};
