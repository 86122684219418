export enum RoomType {
  Living = "living",
  MediaRoom = "mediaRoom",
  Game = "game",
  Dining = "dining",
  Kitchen = "kitchen",
  PrimaryBedroom = "primaryBedroom",
  PrimaryBathroom = "primaryBathroom",
  Office = "office",
  Loft = "loft",
  Library = "library",
  Bonus = "bonus",
  Laundry = "laundry"
}

export const RoomTypeLabel = new Map<RoomType, string>([
  [RoomType.Living, "Living"],
  [RoomType.MediaRoom, "Media"],
  [RoomType.Game, "Game"],
  [RoomType.Dining, "Dining"],
  [RoomType.Kitchen, "Kitchen"],
  [RoomType.PrimaryBedroom, "Primary Bedroom"],
  [RoomType.PrimaryBathroom, "Primary Bathroom"],
  [RoomType.Office, "Office"],
  [RoomType.Loft, "Loft"],
  [RoomType.Library, "Library"],
  [RoomType.Bonus, "Bonus"],
  [RoomType.Laundry, "Laundry"]
]);

export const RoomTypeQuantity = new Map<RoomType, number>([
  [RoomType.Living, 2],
  [RoomType.MediaRoom, 1],
  [RoomType.Loft, 1],
  [RoomType.Office, 1],
  [RoomType.Library, 1],
  [RoomType.Laundry, 1],
  [RoomType.Dining, 1],
  [RoomType.Kitchen, 1],
  [RoomType.PrimaryBedroom, 1],
  [RoomType.PrimaryBathroom, 1],
  [RoomType.Game, 1],
  [RoomType.Bonus, 1]
]);
