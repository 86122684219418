import { FormRules } from "@homesusa/form";
import { salesOfficeRules } from "core/rules/sales-office";
import { aborCityRule } from "../../sections";

export const aborSalesOfficeRules: FormRules = {
  ...salesOfficeRules,
  salesOfficeCity: {
    ...aborCityRule,
    required: false
  },
  isSalesOffice: { label: "Sales Office" }
};
