import { DiscrepancyData } from "core/interfaces";
import { ChartState, StockChartData } from "../interfaces";

export enum ChartStateActionType {
  SetTotalReminders = "SET_TOTAL_REMINDERS",
  SetTotalUrgents = "SET_TOTAL_URGENTS",
  SetTotalDetailed = "SET_TOTAL_DETAILED",
  SetTotalXml = "SET_TOTAL_XML",
  SetTotalAgentBonus = "SET_TOTAL_AGENT_BONUS",
  SetListingStatus = "SET_LISTING_STATUS",
  SetTotalActiveAndPending = "SET_TOTAL_ACTIVE_AND_PENDING",
  SetTotalUnlisted = "SET_TOTAL_UNLISTED",
  SetTotalLockedListings = "SET_TOTAL_LOCKED_LISTINGS",
  SetDiscrepancyData = "SET_DISCREPANCY_DATA",
  SetInitialState = "SET_INITIAL_STATE",
  Clean = "CLEAN"
}

export type ChartStateActions =
  | {
      type:
        | ChartStateActionType.SetTotalReminders
        | ChartStateActionType.SetTotalUrgents
        | ChartStateActionType.SetTotalDetailed
        | ChartStateActionType.SetTotalXml;
      payload: { value: number; total: number };
    }
  | {
      type: ChartStateActionType.SetTotalAgentBonus;
      payload: number;
    }
  | {
      type: ChartStateActionType.SetListingStatus;
      payload: StockChartData[];
    }
  | {
      type: ChartStateActionType.SetTotalActiveAndPending;
      payload: number;
    }
  | {
      type: ChartStateActionType.SetTotalUnlisted;
      payload: number;
    }
  | {
      type: ChartStateActionType.SetTotalLockedListings;
      payload: number;
    }
  | {
      type: ChartStateActionType.SetTotalLockedListings;
      payload: number;
    }
  | {
      type: ChartStateActionType.SetInitialState;
      payload: ChartState;
    }
  | {
      type: ChartStateActionType.Clean;
    }
  | {
      type: ChartStateActionType.SetDiscrepancyData;
      payload: DiscrepancyData;
    };
