import { MarketCode } from "@homesusa/core";
import { GetImportSourceEndpoint, ImportSource } from "core/enums";
import { getGatewayMarket } from "core/utils";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;
export const getImportSourceUrl = (
  importSource: ImportSource,
  market: MarketCode
): string =>
  `${gatewayUrl}/api/${getGatewayMarket(market)}/${GetImportSourceEndpoint.get(
    importSource
  )}`;
