import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndRecord } from "@homesusa/core";
import {
  ElementarySchools,
  ElementarySchoolsLabel,
  HighSchool,
  HighSchoolLabel,
  IntermediateSchoolsLabel,
  IntermediateSchools,
  SchoolDistrict,
  SchoolDistrictLabel
} from "markets/amarillo/enums";

export const schoolsRules: FormRules = {
  schoolDistrict: {
    label: "School District",
    options: getOptionsFromEnumAndRecord(
      Object.values(SchoolDistrict),
      SchoolDistrictLabel
    ),
    requiredToSubmit: true
  },

  elementarySchool: {
    label: "Elementary School",
    options: getOptionsFromEnumAndRecord(
      Object.values(ElementarySchools),
      ElementarySchoolsLabel
    )
  },
  intermediateSchool: {
    label: "Intermediate School Name",
    options: getOptionsFromEnumAndRecord(
      Object.values(IntermediateSchools),
      IntermediateSchoolsLabel
    )
  },

  highSchool: {
    label: "High School",
    options: getOptionsFromEnumAndRecord(
      Object.values(HighSchool),
      HighSchoolLabel
    )
  }
};
