import React from "react";

import { FieldErrorProps } from "@homesusa/form";
import { MediaContext } from "@homesusa/media";
import { AuthCompanyContext } from "@homesusa/auth";
import { maxMediaMarket } from "modules/listings/enums";
import { minMediaMarket } from "../enums/min-media-market.enum";

export const useSubmitMedia = (): (() => Promise<FieldErrorProps | null>) => {
  const {
    service: { getAll }
  } = React.useContext(MediaContext);

  const { currentMarket } = React.useContext(AuthCompanyContext);

  const validateMedia = async (): Promise<FieldErrorProps | null> => {
    const mediaInfo = await getAll();
    const mediaCount = mediaInfo.media.length;

    let error: FieldErrorProps | null = null;

    const minMediaLimit = minMediaMarket[currentMarket];

    if (mediaCount < minMediaLimit) {
      error = {
        label: "Media",
        errors: [minMediaMsg(minMediaLimit)]
      };
    } else {
      const maxMediaLimit = maxMediaMarket[currentMarket];
      if (mediaCount > maxMediaLimit) {
        error = {
          label: "Media",
          errors: [maxMediaMsg(maxMediaLimit)]
        };
      }
    }

    return error;
  };

  const maxMediaMsg = (mediaCount: number): string => {
    return `No more than ${mediaCount} are allowed to submit this listing.`;
  };

  const minMediaMsg = (mediaCount: number): string =>
    `${mediaCount} Photos are required to submit this listing.`;

  return validateMedia;
};
