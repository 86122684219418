import {
  subDays,
  subMonths,
  subYears,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  startOfYear,
  endOfDay
} from "date-fns";
import { DateKey, CalendarInterval } from "../enums";

export const firstDayOfCurrentYear = (): Date => startOfYear(new Date());

export const todayIntheLastTimeOfTheDay = (): Date => endOfDay(new Date());

export const getWeekStart = (date: Date): Date => {
  return startOfWeek(date, { weekStartsOn: 1 });
};

export const getWeekEnd = (date: Date): Date => {
  return endOfWeek(date, { weekStartsOn: 1 });
};

export const getIntervalDate = (
  date: Date,
  interval: CalendarInterval,
  lastDate = false
): Date => {
  if (interval === CalendarInterval.Weekly) {
    return lastDate ? getWeekEnd(date) : getWeekStart(date);
  }
  return lastDate ? endOfMonth(date) : startOfMonth(date);
};

export const getDataByKey = (
  key: DateKey,
  interval: CalendarInterval
): Date => {
  const currentDate = new Date();
  switch (key) {
    case DateKey.OneMonth:
      return getIntervalDate(subMonths(currentDate, 1), interval);
    case DateKey.ThreeMonths:
      return getIntervalDate(subMonths(currentDate, 3), interval);
    case DateKey.OneYear:
      return getIntervalDate(subYears(currentDate, 1), interval);
    case DateKey.FirstDayOfCurrentYear:
      return getIntervalDate(firstDayOfCurrentYear(), interval);
    case DateKey.Today:
      return getIntervalDate(currentDate, interval);
    default:
      return getIntervalDate(subDays(currentDate, 1), interval);
  }
};
