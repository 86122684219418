export enum BathroomDescription {
  PrimaryBathShowerOnly = "primaryBathShowerOnly",
  NoPrimary = "noPrimary",
  PrimaryBathSeparateShower = "primaryBathSeparateShower",
  TubWithShower = "tubWithShower",
  DisabledAccess = "disabledAccess",
  FullSecondaryBathroomDown = "fullSecondaryBathroomDown",
  ShowerOnly = "showerOnly",
  TwoPrimaryBatchs = "twoPrimaryBatchs",
  PrimaryWithTub = "primaryWithTub",
  HollywoodBath = "hollywoodBath",
  HalfBath = "halfBath",
  DoubleSinks = "doubleSinks",
  Bidet = "bidet",
  VanityArea = "vanityArea",
  WhirpoolTub = "whirpoolTub"
}

export const BathroomDescriptionLabel = new Map<BathroomDescription, string>([
  [BathroomDescription.PrimaryBathShowerOnly, "Primary Bath Shower Only"],
  [BathroomDescription.NoPrimary, "No Primary"],
  [
    BathroomDescription.PrimaryBathSeparateShower,
    "Primary Bath + Separate Shower"
  ],
  [BathroomDescription.TubWithShower, "Tub w/shower"],
  [BathroomDescription.DisabledAccess, "Disabled Access"],
  [
    BathroomDescription.FullSecondaryBathroomDown,
    "Full Secondary Bathroom Down"
  ],
  [BathroomDescription.ShowerOnly, "Shower Only"],
  [BathroomDescription.TwoPrimaryBatchs, "Two Primary Baths"],
  [BathroomDescription.PrimaryWithTub, "Primary w/Tub"],
  [BathroomDescription.HollywoodBath, "Hollywood Bath"],
  [BathroomDescription.HalfBath, "Half Bath"],
  [BathroomDescription.DoubleSinks, "Double Sinks"],
  [BathroomDescription.Bidet, "Bidet"],
  [BathroomDescription.VanityArea, "Vanity Area"],
  [BathroomDescription.WhirpoolTub, "Whirlpool/Tub"]
]);
