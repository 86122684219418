import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  selector: SxProps;
}

export const useStyles = (): Styles => {
  return {
    root: {
      display: "flex",
      columnGap: "16px",
      backgroundColor: "#eff2f5",
      padding: "2px",
      boxSizing: "border-box"
    },
    selector: {
      padding: "3px",
      backgroundColor: "#eff2f5",
      color: "#5c6981",
      fontWeight: "bold",
      "&:hover": { background: "white" },
      "&.selected": { background: "white" }
    }
  };
};
