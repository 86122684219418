import { MlsStatusColor } from "core/enums";

export enum MlsStatus {
  Active = "active",
  ActiveOption = "activeOption",
  BackOnMarket = "backOnMarket",
  Cancelled = "cancelled",
  PriceChange = "priceChange",
  PendingSB = "pendingSB",
  Pending = "pending",
  ActiveRFR = "activeRFR",
  Sold = "sold",
  Withdrawn = "withdrawn"
}

export const MlsStatusForNewListings = [
  MlsStatus.Active,
  MlsStatus.ActiveOption,
  MlsStatus.PendingSB,
  MlsStatus.Pending,
  MlsStatus.ActiveRFR,
  MlsStatus.Sold
];

export const MlsStatusLabel = new Map<MlsStatus, string>([
  [MlsStatus.Active, "Active"],
  [MlsStatus.ActiveOption, "Active Option"],
  [MlsStatus.ActiveRFR, "Active RFR"],
  [MlsStatus.Pending, "Pending"],
  [MlsStatus.BackOnMarket, "Back On Market"],
  [MlsStatus.Cancelled, "Cancelled"],
  [MlsStatus.PendingSB, "Pending SB"],
  [MlsStatus.PriceChange, "Price Change"],
  [MlsStatus.Sold, "Sold"],
  [MlsStatus.Withdrawn, "Withdrawn"]
]);

export const StatusColorLabel = new Map<MlsStatus, MlsStatusColor>([
  [MlsStatus.Active, MlsStatusColor.Active],
  [MlsStatus.ActiveOption, MlsStatusColor.Active],
  [MlsStatus.ActiveRFR, MlsStatusColor.Active],
  [MlsStatus.BackOnMarket, MlsStatusColor.Withdrawn],
  [MlsStatus.Cancelled, MlsStatusColor.Canceled],
  [MlsStatus.Pending, MlsStatusColor.Pending],
  [MlsStatus.PendingSB, MlsStatusColor.Pending],
  [MlsStatus.PriceChange, MlsStatusColor.Withdrawn],
  [MlsStatus.Sold, MlsStatusColor.Sold],
  [MlsStatus.Withdrawn, MlsStatusColor.Withdrawn]
]);

export const MarketStatusToHusaStatus = new Map<string, MlsStatus>([
  ["ACT", MlsStatus.Active],
  ["PND", MlsStatus.Pending],
  ["AO", MlsStatus.ActiveOption],
  ["PCH", MlsStatus.PriceChange],
  ["PDB", MlsStatus.PendingSB],
  ["SLD", MlsStatus.Sold],
  ["WDN", MlsStatus.Withdrawn],
  ["CAN", MlsStatus.Cancelled],
  ["BOM", MlsStatus.BackOnMarket],
  ["RFR", MlsStatus.ActiveRFR]
]);

export const MlsStatusWithoutStatusFields: MlsStatus[] = [
  MlsStatus.Active,
  MlsStatus.ActiveOption,
  MlsStatus.ActiveRFR
];
