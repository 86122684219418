export enum ConstructionStage {
  Complete = "complete",
  ToBeBuilt = "toBeBuilt",
  Incomplete = "incomplete"
}
export const ConstructionStageLabel = new Map<ConstructionStage, string>([
  [ConstructionStage.Complete, "Complete"],
  [ConstructionStage.Incomplete, "Incomplete"],
  [ConstructionStage.ToBeBuilt, "To Be Built"]
]);
