interface KeyItems {
  name: string;
  color: string;
}

export enum DiscrepancyChartKeys {
  InventoryInXml = "inventoryInXml",
  InventoryListedInMLS = "inventoryListedInMLS",
  ListedInMlsAndBuilder = "listedInMlsAndBuilder",
  ListingDiscrepancy = "listingDiscrepancy"
}

export const DiscrepancyKeyItems = new Map<DiscrepancyChartKeys, KeyItems>([
  [
    DiscrepancyChartKeys.InventoryInXml,
    { color: "#147AD6", name: "Inventory on Website" }
  ],
  [
    DiscrepancyChartKeys.InventoryListedInMLS,
    { color: "#79D2DE", name: "Inventory listed in MLS" }
  ],

  [
    DiscrepancyChartKeys.ListedInMlsAndBuilder,
    { color: "#7E84A3", name: "Inventory in both" }
  ],
  [
    DiscrepancyChartKeys.ListingDiscrepancy,
    {
      color: "#EC6666",
      name: "Discrepancies between MLS & Builder"
    }
  ]
]);
