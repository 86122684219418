export enum NeighborhoodAmenities {
  BBQGrill = "bbqGrill",
  BikeTrails = "bikeTrails",
  ClubHouse = "clubHouse",
  ControlledAccess = "controlledAccess",
  DogPark = "dogPark",
  ExteriorLights = "exteriorLights",
  FishingPier = "fishingPier",
  FitnessCenter = "fitnessCenter",
  GolfCourseAccess = "golfCourseAccess",
  LakeRiverPark = "lakeRiverPark",
  LivingArea = "livingArea",
  ParkAccess = "parkAccess",
  Playground = "playground",
  RecreationRoom = "recreationRoom",
  SecuritySystem = "securitySystem",
  SportCourt = "sportCourt",
  TennisCourts = "tennisCourts",
  BasketballCourt = "basketballCourt",
  VolleyballCourt = "volleyballCourt",
  WalkingJoggingBikeTrails = "walkingJoggingBikeTrails",
  None = "none",
  KitchenFacilities = "kitchenFacilities",
  OutsideStorage = "outsideStorage",
  Sauna = "sauna",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const NeighborhoodAmenitiesLabel = new Map<
  NeighborhoodAmenities,
  string
>([
  [NeighborhoodAmenities.BBQGrill, "BBQ/Grill"],
  [NeighborhoodAmenities.BikeTrails, "Bike Trails"],
  [NeighborhoodAmenities.ClubHouse, "Club House"],
  [NeighborhoodAmenities.ControlledAccess, "Controlled Access"],
  [NeighborhoodAmenities.DogPark, "Dog Park"],
  [NeighborhoodAmenities.ExteriorLights, "Exterior Lights"],
  [NeighborhoodAmenities.FishingPier, "Fishing Pier"],
  [NeighborhoodAmenities.FitnessCenter, "Fitness Center"],
  [NeighborhoodAmenities.GolfCourseAccess, "Golf Course Access"],
  [NeighborhoodAmenities.LakeRiverPark, "Lake/River/Park"],
  [NeighborhoodAmenities.LivingArea, "Living Area"],
  [NeighborhoodAmenities.ParkAccess, "Park Access"],
  [NeighborhoodAmenities.Playground, "Playground"],
  [NeighborhoodAmenities.RecreationRoom, "Recreation Room"],
  [NeighborhoodAmenities.SecuritySystem, "Security System"],
  [NeighborhoodAmenities.SportCourt, "Sport Court(s)"],
  [NeighborhoodAmenities.TennisCourts, "Tennis Courts"],
  [NeighborhoodAmenities.BasketballCourt, "Basketball Court"],
  [NeighborhoodAmenities.VolleyballCourt, "Volleyball Court"],
  [
    NeighborhoodAmenities.WalkingJoggingBikeTrails,
    "Walking/Jogging/Bike Trails"
  ],
  [NeighborhoodAmenities.None, "None"],
  [NeighborhoodAmenities.KitchenFacilities, "Kitchen Facilities"],
  [NeighborhoodAmenities.OutsideStorage, "Outside Storage"],
  [NeighborhoodAmenities.Sauna, "Sauna"],
  [NeighborhoodAmenities.OtherSeeRemarks, "Other-See Remarks"]
]);
