export enum RoomLevel {
  Lower = "lower",
  Main = "main",
  Upper = "upper"
}

export const RoomLevelLabel = new Map<RoomLevel, string>([
  [RoomLevel.Lower, "Lower"],
  [RoomLevel.Main, "Main"],
  [RoomLevel.Upper, "Upper"]
]);
