import { useTheme } from "@mui/material";
import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  successContainer: SxProps;
  title: SxProps;
  notificationBox: SxProps;
  header: SxProps;
  table: SxProps;
  searchBar: SxProps;
  tableLayout: SxProps;
  alertGrid: SxProps;
}

export const useStyles = (): Styles => {
  const theme = useTheme();
  return {
    root: {
      height: "100%",
      padding: "24px"
    },
    successContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      rowGap: "8px",
      "& img": { height: "60px" }
    },
    title: {
      "&.danger": { color: theme.palette.error.dark },
      "&.warning": { color: theme.palette.warning.dark },
      "&.info": { color: theme.palette.info.main },
      "&.disabled": { color: theme.palette.grey[600] }
    },
    notificationBox: {
      padding: "8px 18px",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      flexWrap: "nowrap",
      whiteSpace: "nowrap",

      "&.danger": { background: theme.palette.error.dark },
      "&.warning": {
        background: theme.palette.warning.dark
      },
      "&.info": { background: theme.palette.info.main }
    },
    header: {
      display: "flex",
      columnGap: "16px",
      alignItems: "center",
      justifyContent: "space-between"
    },
    table: {
      overflowX: "auto",
      "&&::-webkit-scrollbar": { height: "8px" },
      "&&::-webkit-scrollbar-thumb": {
        background: "rgba(0,0,0,.3)",
        borderRadius: "3px"
      }
    },
    searchBar: {
      marginBottom: "16px"
    },
    tableLayout: {
      "& th": {
        whiteSpace: "nowrap",
        "& .HusaGridTable-row-header": {
          "&:last-child": {
            justifyContent: "flex-start"
          }
        }
      },
      "& td": {
        maxWidth: "10ch",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      },

      ":has(.actions)": {
        "& td": {
          maxWidth: "20ch",
          width: "fit-content",
          "& .actions": {
            maxWidth: "unset",
            display: "flex",
            padding: "0px",
            margin: "0px",
            justifyContent: "flex-start",
            columnGap: "6px"
          },
          "& .actions > button": {
            padding: "0px",
            margin: "0px"
          }
        }
      }
    },
    alertGrid: {
      height: "60%",
      padding: "24px",
      marginBottom: "10px"
    }
  };
};
