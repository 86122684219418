import React from "react";

import { Format, MarketCode, MarketCodeLabel } from "@homesusa/core";
import { Columns } from "@homesusa/grid-table";
import { CommunityGrid } from "core/interfaces";
import { TruncateText } from "core/components";

type columnCommunity = Columns<CommunityGrid>;

export const useCommunitySaleGridColumns = (
  cityOptions: Record<string, string>
): columnCommunity => {
  const columns: columnCommunity = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: (data: CommunityGrid): string => data.name,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Company",
        accessor: (data: CommunityGrid): string => data.builder,
        id: "Builder",
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "City",
        accessor: (data: CommunityGrid): string =>
          cityOptions[data.city] ?? data.city,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Market",
        accessor: (data: CommunityGrid): string =>
          MarketCodeLabel.get(data.market as MarketCode) ?? data.market
      },
      {
        Header: "Modified On",
        accessor: (data: CommunityGrid): string =>
          Format.Date(data.sysModifiedOn),
        id: "SysModifiedOn"
      },
      {
        Header: "Modified By",
        accessor: (data: CommunityGrid): string => data.modifiedBy ?? "",
        id: "SysModifiedBy"
      }
    ],
    [cityOptions]
  );

  return columns;
};
