export enum GreenCertification {
  NationalGreenBldgCert = "nationalGreenBldgCert",
  HomeEnergyRatingHers = "homeEnergyRatingHers",
  EnergyStarQualifiedHome = "energyStarQualifiedHome",
  LeedforHomes = "leedforHomes",
  EnvironmentsforLiving = "environmentsforLiving",
  GreenBuiltGulfCoast = "greenBuiltGulfCoast",
  OtherEnergyReport = "otherEnergyReport",
  OtherGreenCertification = "otherGreenCertification"
}

export const GreenCertificationLabel = new Map<GreenCertification, string>([
  [GreenCertification.NationalGreenBldgCert, "National Green Bldg Cert (NAHB)"],
  [GreenCertification.HomeEnergyRatingHers, "Home Energy Rating/HERS"],
  [GreenCertification.EnergyStarQualifiedHome, "Energy Star Qualified Home"],
  [GreenCertification.LeedforHomes, "LEED for Homes (USGBC)"],
  [GreenCertification.EnvironmentsforLiving, "Environments for Living"],
  [GreenCertification.GreenBuiltGulfCoast, "Green Built Gulf Coast"],
  [GreenCertification.OtherEnergyReport, "Other Energy Report"],
  [GreenCertification.OtherGreenCertification, "Other Green Certification"]
]);
