export enum WaterSewer {
  WaterDistrict = "waterDistrict",
  PublicSewer = "publicSewer",
  OtherWaterSewer = "otherWaterSewer",
  SepticTank = "septicTank",
  PublicWater = "publicWater",
  Aerobic = "aerobic"
}

export const WaterSewerLabel = new Map<WaterSewer, string>([
  [WaterSewer.WaterDistrict, "Water District"],
  [WaterSewer.PublicSewer, "Public Sewer"],
  [WaterSewer.OtherWaterSewer, "Other Water/Sewer"],
  [WaterSewer.SepticTank, "Septic Tank"],
  [WaterSewer.PublicWater, "Public Water"],
  [WaterSewer.Aerobic, "Aerobic"]
]);
