import { SxProps } from "@mui/system";

interface Styles {
  actionContainer: SxProps;
  root: SxProps;
  title: SxProps;
  value: SxProps;
}

export const useStyles = (): Styles => {
  return {
    actionContainer: {
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.07)",
        cursor: "pointer"
      }
    },
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      pb: 1,
      mx: 1
    },
    title: {
      fontSize: "13px",
      flex: 0.5,
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    value: {
      flex: 0.5,
      fontSize: "40px",
      fontWeight: "bold"
    }
  };
};
