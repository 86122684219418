import { FormRules } from "@homesusa/form";
import {
  utilitiesRules as commonUtilitiesRules,
  specialtyRoomsRules
} from "../../sections";

export const utilitiesRules: FormRules = {
  ...commonUtilitiesRules,
  specialtyRooms: specialtyRoomsRules
};
