export enum FireplaceDescription {
  WoodBurningFireplace = "woodBurningFireplace",
  MockFireplace = "mockFireplace",
  GasConnections = "gasConnections",
  GaslogFireplace = "gaslogFireplace",
  ElectricFireplace = "electricFireplace",
  Freestanding = "freestanding"
}

export const FireplaceDescriptionLabel = new Map<FireplaceDescription, string>([
  [FireplaceDescription.WoodBurningFireplace, "Wood Burning Fireplace"],
  [FireplaceDescription.MockFireplace, "Mock Fireplace"],
  [FireplaceDescription.GasConnections, "Gas Connections"],
  [FireplaceDescription.GaslogFireplace, "Gaslog Fireplace"],
  [FireplaceDescription.ElectricFireplace, "Electric Fireplace"],
  [FireplaceDescription.Freestanding, "Freestanding"]
]);
