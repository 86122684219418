export enum SchoolDistrict {
  Abbott = "abbott",
  Abernathy = "abernathy",
  Abilene = "abilene",
  Academy = "academy",
  Adrian = "adrian",
  AguaDulce = "aguaDulce",
  AlamoHeights = "alamoHeights",
  AlbaGolden = "albaGolden",
  Albany = "albany",
  Aldine = "aldine",
  Aledo = "aledo",
  Alice = "alice",
  Alief = "alief",
  Allen = "allen",
  Alpine = "alpine",
  Alto = "alto",
  Alvarado = "alvarado",
  Alvin = "alvin",
  Alvord = "alvord",
  Amarillo = "amarillo",
  Amherst = "amherst",
  Anahuac = "anahuac",
  AndersonShiroConsolidated = "andersonShiroConsolidated",
  Andrews = "andrews",
  Angleton = "angleton",
  Anna = "anna",
  Anson = "anson",
  Anthony = "anthony",
  Anton = "anton",
  AppleSprings = "appleSprings",
  Aquilla = "aquilla",
  AransasCounty = "aransasCounty",
  AransasPass = "aransasPass",
  ArcherCity = "archerCity",
  Argyle = "argyle",
  Arlington = "arlington",
  Arp = "arp",
  Aspermont = "aspermont",
  Athens = "athens",
  Atlanta = "atlanta",
  Aubrey = "aubrey",
  Austin = "austin",
  AustwellTivoli = "austwellTivoli",
  Avalon = "avalon",
  Avery = "avery",
  Avinger = "avinger",
  Axtell = "axtell",
  Azle = "azle",
  Baird = "baird",
  Ballinger = "ballinger",
  Balmorhea = "balmorhea",
  Bandera = "bandera",
  Bangs = "bangs",
  Banquete = "banquete",
  BarbersHill = "barbersHill",
  Bartlett = "bartlett",
  Bastrop = "bastrop",
  BayCity = "bayCity",
  Beaumont = "beaumont",
  Beckville = "beckville",
  Beeville = "beeville",
  Bellevue = "bellevue",
  Bells = "bells",
  Bellville = "bellville",
  Belton = "belton",
  BenBoltPalitoBlanco = "benBoltPalitoBlanco",
  Benavides = "benavides",
  Benjamin = "benjamin",
  BigSandy = "bigSandy",
  BigSpring = "bigSpring",
  Birdville = "birdville",
  BishopConsolidated = "bishopConsolidated",
  BlackwellConsolidated = "blackwellConsolidated",
  Blanco = "blanco",
  Bland = "bland",
  Blanket = "blanket",
  Bloomburg = "bloomburg",
  BloomingGrove = "bloomingGrove",
  Bloomington = "bloomington",
  BlueRidge = "blueRidge",
  BluffDale = "bluffDale",
  Blum = "blum",
  Boerne = "boerne",
  Boles = "boles",
  Boling = "boling",
  Bonham = "bonham",
  Booker = "booker",
  BordenCounty = "bordenCounty",
  Borger = "borger",
  Bosqueville = "bosqueville",
  Bovina = "bovina",
  Bowie = "bowie",
  Boyd = "boyd",
  BoysRanch = "boysRanch",
  Brackett = "brackett",
  Brady = "brady",
  Brazos = "brazos",
  Brazosport = "brazosport",
  Breckenridge = "breckenridge",
  Bremond = "bremond",
  Brenham = "brenham",
  BridgeCity = "bridgeCity",
  Bridgeport = "bridgeport",
  Broaddus = "broaddus",
  Brock = "brock",
  Bronte = "bronte",
  Brookeland = "brookeland",
  Brookesmith = "brookesmith",
  BrooksCounty = "brooksCounty",
  Brownfield = "brownfield",
  Brownsboro = "brownsboro",
  Brownsville = "brownsville",
  Brownwood = "brownwood",
  BrucevilleEddy = "brucevilleEddy",
  Bryan = "bryan",
  Bryson = "bryson",
  Buckholts = "buckholts",
  BuenaVista = "buenaVista",
  Buffalo = "buffalo",
  Bullard = "bullard",
  Buna = "buna",
  Burkburnett = "burkburnett",
  Burkeville = "burkeville",
  Burleson = "burleson",
  BurnetConsolidated = "burnetConsolidated",
  Burton = "burton",
  Bushland = "bushland",
  Bynum = "bynum",
  CaddoMills = "caddoMills",
  Calallen = "calallen",
  Caldwell = "caldwell",
  CalhounCounty = "calhounCounty",
  Callisburg = "callisburg",
  Calvert = "calvert",
  Cameron = "cameron",
  Campbell = "campbell",
  Canadian = "canadian",
  Canton = "canton",
  Canutillo = "canutillo",
  Canyon = "canyon",
  Carlisle = "carlisle",
  CarrizoSpringsCisd = "carrizoSpringsCisd",
  Carroll = "carroll",
  CarrolltonFarmersBranch = "carrolltonFarmersBranch",
  Carthage = "carthage",
  Castleberry = "castleberry",
  Cayuga = "cayuga",
  CedarHill = "cedarHill",
  Celeste = "celeste",
  Celina = "celina",
  Center = "center",
  CenterPoint = "centerPoint",
  Centerville = "centerville",
  CentralHeights = "centralHeights",
  Central = "central",
  Channelview = "channelview",
  Channing = "channing",
  ChapelHill = "chapelHill",
  Charlotte = "charlotte",
  Cherokee = "cherokee",
  Chester = "chester",
  Chico = "chico",
  Childress = "childress",
  Chillicothe = "chillicothe",
  Chilton = "chilton",
  ChinaSpring = "chinaSpring",
  Chireno = "chireno",
  Chisum = "chisum",
  Christoval = "christoval",
  Cisco = "cisco",
  CityView = "cityView",
  Clarendon = "clarendon",
  Clarksville = "clarksville",
  Claude = "claude",
  ClearCreek = "clearCreek",
  Cleburne = "cleburne",
  Cleveland = "cleveland",
  Clifton = "clifton",
  Clint = "clint",
  ClydeConsolidated = "clydeConsolidated",
  Coahoma = "coahoma",
  ColdspringOakhurstConsolidated = "coldspringOakhurstConsolidated",
  Coleman = "coleman",
  CollegeStation = "collegeStation",
  Collinsville = "collinsville",
  Colmesneil = "colmesneil",
  Colorado = "colorado",
  ColumbiaBrazoria = "columbiaBrazoria",
  Columbus = "columbus",
  Comal = "comal",
  Comanche = "comanche",
  Comfort = "comfort",
  Commerce = "commerce",
  Community = "community",
  ComoPicktonConsolidated = "comoPicktonConsolidated",
  Comstock = "comstock",
  Connally = "connally",
  Conroe = "conroe",
  Coolidge = "coolidge",
  Cooper = "cooper",
  Coppell = "coppell",
  CopperasCove = "copperasCove",
  CorpusChristi = "corpusChristi",
  CorriganCamden = "corriganCamden",
  Corsicana = "corsicana",
  CottonCenter = "cottonCenter",
  Cotulla = "cotulla",
  Coupland = "coupland",
  Covington = "covington",
  Crandall = "crandall",
  Crane = "crane",
  CranfillsGap = "cranfillsGap",
  Crawford = "crawford",
  CrockettCountyConsolidateCommonSchoolDistrict = "crockettCountyConsolidateCommonSchoolDistrict",
  Crockett = "crockett",
  Crosby = "crosby",
  CrosbytonConsolidated = "crosbytonConsolidated",
  CrossPlains = "crossPlains",
  Crowell = "crowell",
  Crowley = "crowley",
  CrystalCity = "crystalCity",
  Cuero = "cuero",
  CulbersonCountyAllamoore = "culbersonCountyAllamoore",
  Cumby = "cumby",
  Cushing = "cushing",
  CypressFairbanks = "cypressFairbanks",
  DHanis = "dHanis",
  DaingerfieldLoneStar = "daingerfieldLoneStar",
  Dalhart = "dalhart",
  Dallas = "dallas",
  Damon = "damon",
  Danbury = "danbury",
  Darrouzett = "darrouzett",
  Dawson = "dawson",
  Dayton = "dayton",
  DeLeon = "deLeon",
  Decatur = "decatur",
  DeerPark = "deerPark",
  Dekalb = "dekalb",
  DelValle = "delValle",
  DellCity = "dellCity",
  Denison = "denison",
  Denton = "denton",
  DenverCity = "denverCity",
  Desoto = "desoto",
  Detroit = "detroit",
  Devers = "devers",
  Devine = "devine",
  Dew = "dew",
  Deweyville = "deweyville",
  Diboll = "diboll",
  Dickinson = "dickinson",
  Dilley = "dilley",
  DimeBox = "dimeBox",
  Dimmitt = "dimmitt",
  Divide = "divide",
  DoddCity = "doddCity",
  Donna = "donna",
  DossConsolidateCommonSchoolDistrict = "dossConsolidateCommonSchoolDistrict",
  Douglass = "douglass",
  DrippingSprings = "drippingSprings",
  Driscoll = "driscoll",
  Dublin = "dublin",
  Dumas = "dumas",
  Duncanville = "duncanville",
  EagleMtSaginaw = "eagleMtSaginaw",
  EaglePass = "eaglePass",
  Eanes = "eanes",
  Early = "early",
  EastBernard = "eastBernard",
  EastCentral = "eastCentral",
  EastChambers = "eastChambers",
  Eastland = "eastland",
  EctorCounty = "ectorCounty",
  EdcouchElsa = "edcouchElsa",
  EdenConsolidated = "edenConsolidated",
  Edgewood = "edgewood",
  EdinburgConsolidated = "edinburgConsolidated",
  Edna = "edna",
  ElCampo = "elCampo",
  ElPaso = "elPaso",
  Electra = "electra",
  Elgin = "elgin",
  Elkhart = "elkhart",
  ElysianFields = "elysianFields",
  Ennis = "ennis",
  Era = "era",
  Etoile = "etoile",
  Eula = "eula",
  Eustace = "eustace",
  Evadale = "evadale",
  Evant = "evant",
  Everman = "everman",
  Excelsior = "excelsior",
  Ezzell = "ezzell",
  Fabens = "fabens",
  Fairfield = "fairfield",
  FallsCity = "fallsCity",
  Fannindel = "fannindel",
  Farmersville = "farmersville",
  Farwell = "farwell",
  Fayetteville = "fayetteville",
  Ferris = "ferris",
  Flatonia = "flatonia",
  Florence = "florence",
  Floresville = "floresville",
  FlourBluff = "flourBluff",
  Floydada = "floydada",
  Follett = "follett",
  Forestburg = "forestburg",
  Forney = "forney",
  Forsan = "forsan",
  FortBend = "fortBend",
  FortElliottConsolidated = "fortElliottConsolidated",
  FortStockton = "fortStockton",
  FortWorth = "fortWorth",
  Franklin = "franklin",
  Fredericksburg = "fredericksburg",
  Freer = "freer",
  Frenship = "frenship",
  Friendswood = "friendswood",
  Friona = "friona",
  Frisco = "frisco",
  Frost = "frost",
  Fruitvale = "fruitvale",
  FtDavis = "ftDavis",
  FtHancock = "ftHancock",
  FtSamHouston = "ftSamHouston",
  Gainesville = "gainesville",
  GalenaPark = "galenaPark",
  Galveston = "galveston",
  Ganado = "ganado",
  Garland = "garland",
  Garrison = "garrison",
  Gary = "gary",
  Gatesville = "gatesville",
  Gause = "gause",
  GeorgeWest = "georgeWest",
  Georgetown = "georgetown",
  Gholson = "gholson",
  Giddings = "giddings",
  Gilmer = "gilmer",
  Gladewater = "gladewater",
  GlasscockCounty = "glasscockCounty",
  GlenRose = "glenRose",
  Godley = "godley",
  GoldBurg = "goldBurg",
  Goldthwaite = "goldthwaite",
  Goliad = "goliad",
  Gonzales = "gonzales",
  Goodrich = "goodrich",
  GooseCreekCisd = "gooseCreekCisd",
  Gordon = "gordon",
  Gorman = "gorman",
  Grady = "grady",
  Graford = "graford",
  Graham = "graham",
  Granbury = "granbury",
  GrandPrairie = "grandPrairie",
  GrandSaline = "grandSaline",
  GrandfallsRoyalty = "grandfallsRoyalty",
  Grandview = "grandview",
  GrandviewHopkins = "grandviewHopkins",
  Granger = "granger",
  GrapeCreek = "grapeCreek",
  Grapeland = "grapeland",
  GrapevineColleyville = "grapevineColleyville",
  Greenville = "greenville",
  Greenwood = "greenwood",
  GregoryPortland = "gregoryPortland",
  Groesbeck = "groesbeck",
  Groom = "groom",
  Groveton = "groveton",
  Gruver = "gruver",
  Gunter = "gunter",
  Gustine = "gustine",
  GuthriCommonSchoolDistrict = "guthriCommonSchoolDistrict",
  HaleCenter = "haleCenter",
  HalletsvilleInVysehrad = "halletsvilleInVysehrad",
  Hallettsville = "hallettsville",
  Hallsburg = "hallsburg",
  Hallsville = "hallsville",
  Hamilton = "hamilton",
  Hamlin = "hamlin",
  HamshireFannett = "hamshireFannett",
  Happy = "happy",
  Hardin = "hardin",
  HardinJefferson = "hardinJefferson",
  Harlandale = "harlandale",
  Harleton = "harleton",
  HarlingenConsolidated = "harlingenConsolidated",
  Harmony = "harmony",
  Harper = "harper",
  Harrold = "harrold",
  Hart = "hart",
  Hartley = "hartley",
  HartsBluff = "hartsBluff",
  HaskellConsolidated = "haskellConsolidated",
  Hawkins = "hawkins",
  Hawley = "hawley",
  HaysCisd = "haysCisd",
  Hearne = "hearne",
  Hedley = "hedley",
  Hemphill = "hemphill",
  Hempstead = "hempstead",
  Henderson = "henderson",
  Henrietta = "henrietta",
  Hereford = "hereford",
  Hermleigh = "hermleigh",
  Hico = "hico",
  Hidalgo = "hidalgo",
  Higgins = "higgins",
  HighIsland = "highIsland",
  Highland = "highland",
  HighlandPark = "highlandPark",
  Hillsboro = "hillsboro",
  Hitchcock = "hitchcock",
  Holland = "holland",
  Holliday = "holliday",
  Hondo = "hondo",
  HoneyGrove = "honeyGrove",
  Hooks = "hooks",
  Houston = "houston",
  Howe = "howe",
  Hubbard = "hubbard",
  Huckabay = "huckabay",
  Hudson = "hudson",
  Huffman = "huffman",
  HughesSprings = "hughesSprings",
  HullDaisetta = "hullDaisetta",
  Humble = "humble",
  Hunt = "hunt",
  Huntington = "huntington",
  Huntsville = "huntsville",
  HurstEulessBedford = "hurstEulessBedford",
  Hutto = "hutto",
  Idalou = "idalou",
  Industrial = "industrial",
  Ingleside = "ingleside",
  Ingram = "ingram",
  Iola = "iola",
  IowaParkConsolidated = "iowaParkConsolidated",
  Ira = "ira",
  IraanSheffield = "iraanSheffield",
  Iredell = "iredell",
  IrionCounty = "irionCounty",
  Irving = "irving",
  Italy = "italy",
  Itasca = "itasca",
  Jacksboro = "jacksboro",
  Jacksonville = "jacksonville",
  Jarrell = "jarrell",
  Jasper = "jasper",
  JaytonGirard = "jaytonGirard",
  Jefferson = "jefferson",
  JimHoggCounty = "jimHoggCounty",
  JimNedConsolidated = "jimNedConsolidated",
  Joaquin = "joaquin",
  JohnsonCity = "johnsonCity",
  Jonesboro = "jonesboro",
  Joshua = "joshua",
  Jourdanton = "jourdanton",
  Judson = "judson",
  Junction = "junction",
  Karnack = "karnack",
  KarnesCity = "karnesCity",
  Katy = "katy",
  Kaufman = "kaufman",
  Keene = "keene",
  Keller = "keller",
  Kelton = "kelton",
  Kemp = "kemp",
  Kenedy = "kenedy",
  Kennard = "kennard",
  Kennedale = "kennedale",
  Kerens = "kerens",
  Kermit = "kermit",
  Kerrville = "kerrville",
  Kilgore = "kilgore",
  Killeen = "killeen",
  Kingsville = "kingsville",
  KirbyvilleConsolidated = "kirbyvilleConsolidated",
  Klein = "klein",
  Klondike = "klondike",
  Knippa = "knippa",
  KnoxCityOBrienConsolidated = "knoxCityOBrienConsolidated",
  Kopperl = "kopperl",
  Kountze = "kountze",
  Kress = "kress",
  Krum = "krum",
  LaFeria = "laFeria",
  LaGloria = "laGloria",
  LaGrange = "laGrange",
  LaJoya = "laJoya",
  LaMarque = "laMarque",
  LaPorte = "laPorte",
  LaPryor = "laPryor",
  LaVega = "laVega",
  LaVernia = "laVernia",
  LaVilla = "laVilla",
  Lackland = "lackland",
  LagoVista = "lagoVista",
  LakeDallas = "lakeDallas",
  LakeTravis = "lakeTravis",
  LakeWorth = "lakeWorth",
  LamarConsolidated = "lamarConsolidated",
  Lamesa = "lamesa",
  Lampasas = "lampasas",
  Lancaster = "lancaster",
  Laneville = "laneville",
  Lapoynor = "lapoynor",
  Laredo = "laredo",
  Lasara = "lasara",
  Latexo = "latexo",
  Lazbuddie = "lazbuddie",
  Leakey = "leakey",
  Leander = "leander",
  Leary = "leary",
  Lefors = "lefors",
  Leggett = "leggett",
  Leon = "leon",
  Leonard = "leonard",
  Levelland = "levelland",
  LeverettsChapel = "leverettsChapel",
  Lewisville = "lewisville",
  Lexington = "lexington",
  LibertyHill = "libertyHill",
  Liberty = "liberty",
  LibertyEylau = "libertyEylau",
  Lindale = "lindale",
  LindenKildareConsolidated = "lindenKildareConsolidated",
  Lindsay = "lindsay",
  Lingleville = "lingleville",
  Lipan = "lipan",
  LittleCypressMauricevilleConsolidated = "littleCypressMauricevilleConsolidated",
  LittleElm = "littleElm",
  Littlefield = "littlefield",
  Livingston = "livingston",
  Llano = "llano",
  Lockhart = "lockhart",
  Lockney = "lockney",
  Lohn = "lohn",
  Lometa = "lometa",
  London = "london",
  LoneOak = "loneOak",
  Longview = "longview",
  Loop = "loop",
  Loraine = "loraine",
  Lorena = "lorena",
  Lorenzo = "lorenzo",
  LosFresnosConsolidated = "losFresnosConsolidated",
  Louise = "louise",
  Lovejoy = "lovejoy",
  Lovelady = "lovelady",
  Lubbock = "lubbock",
  LubbockCooper = "lubbockCooper",
  LuedersAvoca = "luedersAvoca",
  Lufkin = "lufkin",
  Luling = "luling",
  Lumberton = "lumberton",
  LyfordConsolidated = "lyfordConsolidated",
  Lytle = "lytle",
  Mabank = "mabank",
  MadisonvilleConsolidated = "madisonvilleConsolidated",
  Magnolia = "magnolia",
  Malakoff = "malakoff",
  Malone = "malone",
  Malta = "malta",
  Manor = "manor",
  Mansfield = "mansfield",
  Marathon = "marathon",
  MarbleFalls = "marbleFalls",
  Marfa = "marfa",
  Marion = "marion",
  Marlin = "marlin",
  Marshall = "marshall",
  Mart = "mart",
  MartinsMill = "martinsMill",
  Martinsville = "martinsville",
  Mason = "mason",
  Matagorda = "matagorda",
  Mathis = "mathis",
  Maud = "maud",
  May = "may",
  Maypearl = "maypearl",
  Mcallen = "mcallen",
  Mccamey = "mccamey",
  Mcdade = "mcdade",
  Mcgregor = "mcgregor",
  Mckinney = "mckinney",
  Mclean = "mclean",
  Mcleod = "mcleod",
  McmullenCounty = "mcmullenCounty",
  Meadow = "meadow",
  Medina = "medina",
  MedinaValley = "medinaValley",
  Melissa = "melissa",
  Memphis = "memphis",
  Menard = "menard",
  Mercedes = "mercedes",
  Meridian = "meridian",
  Merkel = "merkel",
  Mesquite = "mesquite",
  Mexia = "mexia",
  Meyersville = "meyersville",
  Miami = "miami",
  Midland = "midland",
  Midlothian = "midlothian",
  Midway = "midway",
  Milano = "milano",
  Mildred = "mildred",
  Miles = "miles",
  Milford = "milford",
  MillerGrove = "millerGrove",
  Millsap = "millsap",
  Mineola = "mineola",
  MineralWells = "mineralWells",
  MissionConsolidated = "missionConsolidated",
  MonahansWickettPyote = "monahansWickettPyote",
  Montague = "montague",
  MonteAlto = "monteAlto",
  Montgomery = "montgomery",
  Moody = "moody",
  Moran = "moran",
  Morgan = "morgan",
  MorganMill = "morganMill",
  Morton = "morton",
  MotleyCounty = "motleyCounty",
  Moulton = "moulton",
  MountCalm = "mountCalm",
  MountEnterprise = "mountEnterprise",
  MountPleasant = "mountPleasant",
  MountVernon = "mountVernon",
  Muenster = "muenster",
  Muleshoe = "muleshoe",
  Mullin = "mullin",
  Mumford = "mumford",
  MundayConsolidated = "mundayConsolidated",
  Murchison = "murchison",
  Nacogdoches = "nacogdoches",
  Natalia = "natalia",
  Navarro = "navarro",
  Navasota = "navasota",
  Nazareth = "nazareth",
  Neches = "neches",
  Nederland = "nederland",
  Needville = "needville",
  NewBoston = "newBoston",
  NewBraunfels = "newBraunfels",
  NewCaney = "newCaney",
  NewDeal = "newDeal",
  NewDiana = "newDiana",
  NewHome = "newHome",
  NewSummerfield = "newSummerfield",
  NewWaverly = "newWaverly",
  Newcastle = "newcastle",
  Newton = "newton",
  NixonSmileyCisd = "nixonSmileyCisd",
  Nocona = "nocona",
  Nordheim = "nordheim",
  Normangee = "normangee",
  NorthEast = "northEast",
  NorthHopkins = "northHopkins",
  NorthLamar = "northLamar",
  NorthZulch = "northZulch",
  Northside = "northside",
  Northwest = "northwest",
  NuecesCanyonConsolidated = "nuecesCanyonConsolidated",
  Nursery = "nursery",
  ODonnell = "oDonnell",
  Oakwood = "oakwood",
  OdemEdroy = "odemEdroy",
  Oglesby = "oglesby",
  Olfen = "olfen",
  Olney = "olney",
  Olton = "olton",
  Onalaska = "onalaska",
  OrangeGrove = "orangeGrove",
  Orangefield = "orangefield",
  OreCity = "oreCity",
  Overton = "overton",
  Paducah = "paducah",
  PaintCreek = "paintCreek",
  PaintRock = "paintRock",
  Palacios = "palacios",
  Palestine = "palestine",
  Palmer = "palmer",
  PaloPinto = "paloPinto",
  Pampa = "pampa",
  Panhandle = "panhandle",
  PantherCreekConsolidated = "pantherCreekConsolidated",
  Paradise = "paradise",
  Paris = "paris",
  Pasadena = "pasadena",
  PattonSprings = "pattonSprings",
  Pawnee = "pawnee",
  Pearland = "pearland",
  Pearsall = "pearsall",
  Peaster = "peaster",
  PecosBarstowToyah = "pecosBarstowToyah",
  Penelope = "penelope",
  PerrinWhittConsolidated = "perrinWhittConsolidated",
  Perryton = "perryton",
  Petersburg = "petersburg",
  Petrolia = "petrolia",
  Pettus = "pettus",
  PewittConsolidated = "pewittConsolidated",
  Pflugerville = "pflugerville",
  PharrSanJuanAlamo = "pharrSanJuanAlamo",
  PilotPoint = "pilotPoint",
  PineTree = "pineTree",
  Pittsburg = "pittsburg",
  Plains = "plains",
  Plainview = "plainview",
  Plano = "plano",
  PleasantGrove = "pleasantGrove",
  Pleasanton = "pleasanton",
  PlemonsStinnettPhillipsConsolidated = "plemonsStinnettPhillipsConsolidated",
  PointIsabel = "pointIsabel",
  Ponder = "ponder",
  Poolville = "poolville",
  PortAransas = "portAransas",
  PortArthur = "portArthur",
  PortNechesGroves = "portNechesGroves",
  Post = "post",
  Poteet = "poteet",
  Poth = "poth",
  Pottsboro = "pottsboro",
  PrairieLea = "prairieLea",
  PrairieValley = "prairieValley",
  Prairiland = "prairiland",
  Premont = "premont",
  Presidio = "presidio",
  Priddy = "priddy",
  Princeton = "princeton",
  PringleMorseConsolidated = "pringleMorseConsolidated",
  Progreso = "progreso",
  Prosper = "prosper",
  Quanah = "quanah",
  QueenCity = "queenCity",
  Quinlan = "quinlan",
  Quitman = "quitman",
  Rains = "rains",
  Ralls = "ralls",
  RamireCommonSchoolDistrict = "ramireCommonSchoolDistrict",
  RandolphField = "randolphField",
  Ranger = "ranger",
  Rankin = "rankin",
  Raymondville = "raymondville",
  ReaganCounty = "reaganCounty",
  RedLick = "redLick",
  RedOak = "redOak",
  Redwater = "redwater",
  Refugio = "refugio",
  Ricardo = "ricardo",
  RiceConsolidated = "riceConsolidated",
  Rice = "rice",
  Richards = "richards",
  Richardson = "richardson",
  RichlandSprings = "richlandSprings",
  Riesel = "riesel",
  RioHondo = "rioHondo",
  RioVista = "rioVista",
  RisingStar = "risingStar",
  RiverRoad = "riverRoad",
  Rivercrest = "rivercrest",
  Riviera = "riviera",
  RobertLee = "robertLee",
  Robinson = "robinson",
  Robstown = "robstown",
  RobyConsolidated = "robyConsolidated",
  Rochelle = "rochelle",
  Rockdale = "rockdale",
  Rocksprings = "rocksprings",
  Rockwall = "rockwall",
  Rogers = "rogers",
  Roma = "roma",
  Roosevelt = "roosevelt",
  Ropes = "ropes",
  Roscoe = "roscoe",
  RosebudLott = "rosebudLott",
  Rotan = "rotan",
  RoundRock = "roundRock",
  RoundTopCarmine = "roundTopCarmine",
  Roxton = "roxton",
  Royal = "royal",
  RoyseCity = "royseCity",
  Rule = "rule",
  Runge = "runge",
  Rusk = "rusk",
  SAndSConsolidated = "sAndSConsolidated",
  Sabinal = "sabinal",
  Sabine = "sabine",
  SabinePass = "sabinePass",
  SaintJo = "saintJo",
  Salado = "salado",
  Saltillo = "saltillo",
  SamRayburn = "samRayburn",
  SanAngelo = "sanAngelo",
  SanAntonio = "sanAntonio",
  SanAugustine = "sanAugustine",
  SanBenitoConsolidated = "sanBenitoConsolidated",
  SanDiego = "sanDiego",
  SanElizario = "sanElizario",
  SanFelipeDelRioConsolidated = "sanFelipeDelRioConsolidated",
  SanIsidro = "sanIsidro",
  SanMarcosCisd = "sanMarcosCisd",
  SanPerlita = "sanPerlita",
  SanSaba = "sanSaba",
  SanVicente = "sanVicente",
  SanfordFritch = "sanfordFritch",
  Sanger = "sanger",
  SantaAnna = "santaAnna",
  SantaFe = "santaFe",
  SantaGertrudis = "santaGertrudis",
  SantaMaria = "santaMaria",
  SantaRosa = "santaRosa",
  Santo = "santo",
  Savoy = "savoy",
  SchertzCiboloUniversalCity = "schertzCiboloUniversalCity",
  Schleicher = "schleicher",
  Schulenburg = "schulenburg",
  ScurryRosser = "scurryRosser",
  Seagraves = "seagraves",
  Sealy = "sealy",
  Seguin = "seguin",
  Seminole = "seminole",
  Seymour = "seymour",
  Shallowater = "shallowater",
  Shamrock = "shamrock",
  Sharyland = "sharyland",
  Shelbyville = "shelbyville",
  Sheldon = "sheldon",
  Shepherd = "shepherd",
  Sherman = "sherman",
  Shiner = "shiner",
  Sidney = "sidney",
  SierraBlanca = "sierraBlanca",
  Silsbee = "silsbee",
  Silverton = "silverton",
  Simms = "simms",
  Sinton = "sinton",
  SivellsBend = "sivellsBend",
  SkidmoreTynan = "skidmoreTynan",
  Slaton = "slaton",
  Slidell = "slidell",
  Slocum = "slocum",
  Smithville = "smithville",
  Smyer = "smyer",
  Snook = "snook",
  Snyder = "snyder",
  Socorro = "socorro",
  Somerset = "somerset",
  Somerville = "somerville",
  Sonora = "sonora",
  SouthSanAntonio = "southSanAntonio",
  Southland = "southland",
  Southside = "southside",
  Southwest = "southwest",
  Spearman = "spearman",
  Splendora = "splendora",
  SpringBranch = "springBranch",
  SpringCreek = "springCreek",
  SpringHill = "springHill",
  Spring = "spring",
  SpringlakeEarth = "springlakeEarth",
  Springtown = "springtown",
  Spur = "spur",
  Spurger = "spurger",
  StaffordMunicipalSchoolDistrict = "staffordMunicipalSchoolDistrict",
  Stamford = "stamford",
  Stanton = "stanton",
  Star = "star",
  Stephenville = "stephenville",
  SterlingCity = "sterlingCity",
  Stockdale = "stockdale",
  Stratford = "stratford",
  Strawn = "strawn",
  Sudan = "sudan",
  SulphurBluff = "sulphurBluff",
  SulphurSprings = "sulphurSprings",
  Sundown = "sundown",
  Sunnyvale = "sunnyvale",
  Sunray = "sunray",
  Sweeny = "sweeny",
  SweetHome = "sweetHome",
  Sweetwater = "sweetwater",
  Taft = "taft",
  Tahoka = "tahoka",
  Tarkington = "tarkington",
  Tatum = "tatum",
  Taylor = "taylor",
  Teague = "teague",
  Temple = "temple",
  Tenaha = "tenaha",
  TerlinguCommonSchoolDistrict = "terlinguCommonSchoolDistrict",
  TerrellCounty = "terrellCounty",
  Terrell = "terrell",
  Texarkana = "texarkana",
  TexasCity = "texasCity",
  Texhoma = "texhoma",
  Texline = "texline",
  Thorndale = "thorndale",
  Thrall = "thrall",
  ThreeRivers = "threeRivers",
  ThreeWay = "threeWay",
  Throckmorton = "throckmorton",
  Tidehaven = "tidehaven",
  Timpson = "timpson",
  Tioga = "tioga",
  Tolar = "tolar",
  TomBean = "tomBean",
  Tomball = "tomball",
  Tornillo = "tornillo",
  Trent = "trent",
  Trenton = "trenton",
  Trinidad = "trinidad",
  Trinity = "trinity",
  Troup = "troup",
  Troy = "troy",
  Tulia = "tulia",
  TulosoMidway = "tulosoMidway",
  TurkeyQuitaque = "turkeyQuitaque",
  Tyler = "tyler",
  UnionGrove = "unionGrove",
  United = "united",
  Utopia = "utopia",
  UvaldeConsolidated = "uvaldeConsolidated",
  Valentine = "valentine",
  ValleyMills = "valleyMills",
  ValleyView = "valleyView",
  VanAlstyne = "vanAlstyne",
  Van = "van",
  VanVleck = "vanVleck",
  Vega = "vega",
  Venus = "venus",
  Veribest = "veribest",
  Vernon = "vernon",
  Victoria = "victoria",
  Vidor = "vidor",
  Vysehrad = "vysehrad",
  Waco = "waco",
  Waelder = "waelder",
  Walcott = "walcott",
  Wall = "wall",
  Waller = "waller",
  WalnutBend = "walnutBend",
  WalnutSprings = "walnutSprings",
  Warren = "warren",
  Waskom = "waskom",
  WaterValley = "waterValley",
  Waxahachie = "waxahachie",
  Weatherford = "weatherford",
  WebbConsolidated = "webbConsolidated",
  Weimar = "weimar",
  Wellington = "wellington",
  WellmanUnionConsolidated = "wellmanUnionConsolidated",
  Wells = "wells",
  Weslaco = "weslaco",
  WestHardinCountyConsolidated = "westHardinCountyConsolidated",
  West = "west",
  WestOrangeCoveConsolidated = "westOrangeCoveConsolidated",
  WestOso = "westOso",
  WestRusk = "westRusk",
  WestSabine = "westSabine",
  Westbrook = "westbrook",
  Westhoff = "westhoff",
  Westphalia = "westphalia",
  Westwood = "westwood",
  Wharton = "wharton",
  Wheeler = "wheeler",
  WhiteDeer = "whiteDeer",
  WhiteOak = "whiteOak",
  WhiteSettlement = "whiteSettlement",
  WhitefaceConsolidated = "whitefaceConsolidated",
  Whitehouse = "whitehouse",
  Whitesboro = "whitesboro",
  Whitewright = "whitewright",
  Whitharral = "whitharral",
  Whitney = "whitney",
  WichitaFalls = "wichitaFalls",
  Wildorado = "wildorado",
  Willis = "willis",
  WillsPoint = "willsPoint",
  Wilson = "wilson",
  Wimberley = "wimberley",
  Windthorst = "windthorst",
  Winfield = "winfield",
  WinkLoving = "winkLoving",
  Winnsboro = "winnsboro",
  Winona = "winona",
  Winters = "winters",
  Woden = "woden",
  WolfeCity = "wolfeCity",
  Woodsboro = "woodsboro",
  Woodson = "woodson",
  Woodville = "woodville",
  Wortham = "wortham",
  Wylie = "wylie",
  Yantis = "yantis",
  Yoakum = "yoakum",
  Yorktown = "yorktown",
  Ysleta = "ysleta",
  ZapataCounty = "zapataCounty",
  Zavalla = "zavalla",
  Zephyr = "zephyr"
}

export const SchoolDistrictLabel: Record<SchoolDistrict, string> = {
  [SchoolDistrict.Abbott]: "Abbott ISD",
  [SchoolDistrict.Abernathy]: "Abernathy ISD",
  [SchoolDistrict.Abilene]: "Abilene ISD",
  [SchoolDistrict.Academy]: "Academy ISD",
  [SchoolDistrict.Adrian]: "Adrian ISD",
  [SchoolDistrict.AguaDulce]: "Agua Dulce ISD",
  [SchoolDistrict.AlamoHeights]: "Alamo Heights ISD",
  [SchoolDistrict.AlbaGolden]: "Alba-Golden ISD",
  [SchoolDistrict.Albany]: "Albany ISD",
  [SchoolDistrict.Aldine]: "Aldine ISD",
  [SchoolDistrict.Aledo]: "Aledo ISD",
  [SchoolDistrict.Alice]: "Alice ISD",
  [SchoolDistrict.Alief]: "Alief ISD",
  [SchoolDistrict.Allen]: "Allen ISD",
  [SchoolDistrict.Alpine]: "Alpine ISD",
  [SchoolDistrict.Alto]: "Alto ISD",
  [SchoolDistrict.Alvarado]: "Alvarado ISD",
  [SchoolDistrict.Alvin]: "Alvin ISD",
  [SchoolDistrict.Alvord]: "Alvord ISD",
  [SchoolDistrict.Amarillo]: "Amarillo ISD",
  [SchoolDistrict.Amherst]: "Amherst ISD",
  [SchoolDistrict.Anahuac]: "Anahuac ISD",
  [SchoolDistrict.AndersonShiroConsolidated]: "Anderson-Shiro Consolidated ISD",
  [SchoolDistrict.Andrews]: "Andrews ISD",
  [SchoolDistrict.Angleton]: "Angleton ISD",
  [SchoolDistrict.Anna]: "Anna ISD",
  [SchoolDistrict.Anson]: "Anson ISD",
  [SchoolDistrict.Anthony]: "Anthony ISD",
  [SchoolDistrict.Anton]: "Anton ISD",
  [SchoolDistrict.AppleSprings]: "Apple Springs ISD",
  [SchoolDistrict.Aquilla]: "Aquilla ISD",
  [SchoolDistrict.AransasCounty]: "Aransas County ISD",
  [SchoolDistrict.AransasPass]: "Aransas Pass ISD",
  [SchoolDistrict.ArcherCity]: "Archer City ISD",
  [SchoolDistrict.Argyle]: "Argyle ISD",
  [SchoolDistrict.Arlington]: "Arlington ISD",
  [SchoolDistrict.Arp]: "Arp ISD",
  [SchoolDistrict.Aspermont]: "Aspermont ISD",
  [SchoolDistrict.Athens]: "Athens ISD",
  [SchoolDistrict.Atlanta]: "Atlanta ISD",
  [SchoolDistrict.Aubrey]: "Aubrey ISD",
  [SchoolDistrict.Austin]: "Austin ISD",
  [SchoolDistrict.AustwellTivoli]: "Austwell-Tivoli ISD",
  [SchoolDistrict.Avalon]: "Avalon ISD",
  [SchoolDistrict.Avery]: "Avery ISD",
  [SchoolDistrict.Avinger]: "Avinger ISD",
  [SchoolDistrict.Axtell]: "Axtell ISD",
  [SchoolDistrict.Azle]: "Azle ISD",
  [SchoolDistrict.Baird]: "Baird ISD",
  [SchoolDistrict.Ballinger]: "Ballinger ISD",
  [SchoolDistrict.Balmorhea]: "Balmorhea I.S.D.",
  [SchoolDistrict.Bandera]: "Bandera ISD",
  [SchoolDistrict.Bangs]: "Bangs ISD",
  [SchoolDistrict.Banquete]: "Banquete ISD",
  [SchoolDistrict.BarbersHill]: "Barbers Hill ISD",
  [SchoolDistrict.Bartlett]: "Bartlett ISD",
  [SchoolDistrict.Bastrop]: "Bastrop ISD",
  [SchoolDistrict.BayCity]: "Bay City ISD",
  [SchoolDistrict.Beaumont]: "Beaumont ISD",
  [SchoolDistrict.Beckville]: "Beckville ISD",
  [SchoolDistrict.Beeville]: "Beeville ISD",
  [SchoolDistrict.Bellevue]: "Bellevue ISD",
  [SchoolDistrict.Bells]: "Bells ISD",
  [SchoolDistrict.Bellville]: "Bellville ISD",
  [SchoolDistrict.Belton]: "Belton ISD",
  [SchoolDistrict.BenBoltPalitoBlanco]: "Ben Bolt-Palito Blanco ISD",
  [SchoolDistrict.Benavides]: "Benavides ISD",
  [SchoolDistrict.Benjamin]: "Benjamin ISD",
  [SchoolDistrict.BigSandy]: "Big Sandy ISD",
  [SchoolDistrict.BigSpring]: "Big Spring ISD",
  [SchoolDistrict.Birdville]: "Birdville ISD",
  [SchoolDistrict.BishopConsolidated]: "Bishop Consolidated ISD",
  [SchoolDistrict.BlackwellConsolidated]: "Blackwell Consolidated ISD",
  [SchoolDistrict.Blanco]: "Blanco ISD",
  [SchoolDistrict.Bland]: "Bland ISD",
  [SchoolDistrict.Blanket]: "Blanket ISD",
  [SchoolDistrict.Bloomburg]: "Bloomburg ISD",
  [SchoolDistrict.BloomingGrove]: "Blooming Grove ISD",
  [SchoolDistrict.Bloomington]: "Bloomington ISD",
  [SchoolDistrict.BlueRidge]: "Blue Ridge ISD",
  [SchoolDistrict.BluffDale]: "Bluff Dale ISD",
  [SchoolDistrict.Blum]: "Blum ISD",
  [SchoolDistrict.Boerne]: "Boerne ISD",
  [SchoolDistrict.Boles]: "Boles ISD",
  [SchoolDistrict.Boling]: "Boling ISD",
  [SchoolDistrict.Bonham]: "Bonham ISD",
  [SchoolDistrict.Booker]: "Booker ISD",
  [SchoolDistrict.BordenCounty]: "Borden County ISD",
  [SchoolDistrict.Borger]: "Borger ISD",
  [SchoolDistrict.Bosqueville]: "Bosqueville ISD",
  [SchoolDistrict.Bovina]: "Bovina ISD",
  [SchoolDistrict.Bowie]: "Bowie ISD",
  [SchoolDistrict.Boyd]: "Boyd ISD",
  [SchoolDistrict.BoysRanch]: "Boys Ranch ISD",
  [SchoolDistrict.Brackett]: "Brackett ISD",
  [SchoolDistrict.Brady]: "Brady ISD",
  [SchoolDistrict.Brazos]: "Brazos ISD",
  [SchoolDistrict.Brazosport]: "Brazosport ISD",
  [SchoolDistrict.Breckenridge]: "Breckenridge ISD",
  [SchoolDistrict.Bremond]: "Bremond ISD",
  [SchoolDistrict.Brenham]: "Brenham ISD",
  [SchoolDistrict.BridgeCity]: "Bridge City ISD",
  [SchoolDistrict.Bridgeport]: "Bridgeport ISD",
  [SchoolDistrict.Broaddus]: "Broaddus ISD",
  [SchoolDistrict.Brock]: "Brock ISD",
  [SchoolDistrict.Bronte]: "Bronte ISD",
  [SchoolDistrict.Brookeland]: "Brookeland ISD",
  [SchoolDistrict.Brookesmith]: "Brookesmith ISD",
  [SchoolDistrict.BrooksCounty]: "Brooks County ISD",
  [SchoolDistrict.Brownfield]: "Brownfield ISD",
  [SchoolDistrict.Brownsboro]: "Brownsboro ISD",
  [SchoolDistrict.Brownsville]: "Brownsville ISD",
  [SchoolDistrict.Brownwood]: "Brownwood ISD",
  [SchoolDistrict.BrucevilleEddy]: "Bruceville-Eddy ISD",
  [SchoolDistrict.Bryan]: "Bryan ISD",
  [SchoolDistrict.Bryson]: "Bryson ISD",
  [SchoolDistrict.Buckholts]: "Buckholts ISD",
  [SchoolDistrict.BuenaVista]: "Buena Vista ISD",
  [SchoolDistrict.Buffalo]: "Buffalo ISD",
  [SchoolDistrict.Bullard]: "Bullard ISD",
  [SchoolDistrict.Buna]: "Buna ISD",
  [SchoolDistrict.Burkburnett]: "Burkburnett ISD",
  [SchoolDistrict.Burkeville]: "Burkeville ISD",
  [SchoolDistrict.Burleson]: "Burleson ISD",
  [SchoolDistrict.BurnetConsolidated]: "Burnet Consolidated ISD",
  [SchoolDistrict.Burton]: "Burton ISD",
  [SchoolDistrict.Bushland]: "Bushland ISD",
  [SchoolDistrict.Bynum]: "Bynum ISD",
  [SchoolDistrict.CaddoMills]: "Caddo Mills ISD",
  [SchoolDistrict.Calallen]: "Calallen ISD",
  [SchoolDistrict.Caldwell]: "Caldwell ISD",
  [SchoolDistrict.CalhounCounty]: "Calhoun County ISD",
  [SchoolDistrict.Callisburg]: "Callisburg ISD",
  [SchoolDistrict.Calvert]: "Calvert ISD",
  [SchoolDistrict.Cameron]: "Cameron ISD",
  [SchoolDistrict.Campbell]: "Campbell ISD",
  [SchoolDistrict.Canadian]: "Canadian ISD",
  [SchoolDistrict.Canton]: "Canton ISD",
  [SchoolDistrict.Canutillo]: "Canutillo ISD",
  [SchoolDistrict.Canyon]: "Canyon ISD",
  [SchoolDistrict.Carlisle]: "Carlisle ISD",
  [SchoolDistrict.CarrizoSpringsCisd]: "Carrizo Springs Cisd",
  [SchoolDistrict.Carroll]: "Carroll ISD",
  [SchoolDistrict.CarrolltonFarmersBranch]: "Carrollton-Farmers Branch ISD",
  [SchoolDistrict.Carthage]: "Carthage ISD",
  [SchoolDistrict.Castleberry]: "Castleberry ISD",
  [SchoolDistrict.Cayuga]: "Cayuga ISD",
  [SchoolDistrict.CedarHill]: "Cedar Hill ISD",
  [SchoolDistrict.Celeste]: "Celeste ISD",
  [SchoolDistrict.Celina]: "Celina ISD",
  [SchoolDistrict.Center]: "Center ISD",
  [SchoolDistrict.CenterPoint]: "Center Point ISD",
  [SchoolDistrict.Centerville]: "Centerville ISD",
  [SchoolDistrict.CentralHeights]: "Central Heights ISD",
  [SchoolDistrict.Central]: "Central ISD",
  [SchoolDistrict.Channelview]: "Channelview ISD",
  [SchoolDistrict.Channing]: "Channing ISD",
  [SchoolDistrict.ChapelHill]: "Chapel Hill ISD",
  [SchoolDistrict.Charlotte]: "Charlotte ISD",
  [SchoolDistrict.Cherokee]: "Cherokee ISD",
  [SchoolDistrict.Chester]: "Chester ISD",
  [SchoolDistrict.Chico]: "Chico ISD",
  [SchoolDistrict.Childress]: "Childress ISD",
  [SchoolDistrict.Chillicothe]: "Chillicothe ISD",
  [SchoolDistrict.Chilton]: "Chilton Independent School District",
  [SchoolDistrict.ChinaSpring]: "China Spring ISD",
  [SchoolDistrict.Chireno]: "Chireno ISD",
  [SchoolDistrict.Chisum]: "Chisum ISD",
  [SchoolDistrict.Christoval]: "Christoval ISD",
  [SchoolDistrict.Cisco]: "Cisco ISD",
  [SchoolDistrict.CityView]: "City View ISD",
  [SchoolDistrict.Clarendon]: "Clarendon ISD",
  [SchoolDistrict.Clarksville]: "Clarksville ISD",
  [SchoolDistrict.Claude]: "Claude ISD",
  [SchoolDistrict.ClearCreek]: "Clear Creek ISD",
  [SchoolDistrict.Cleburne]: "Cleburne ISD",
  [SchoolDistrict.Cleveland]: "Cleveland ISD",
  [SchoolDistrict.Clifton]: "Clifton ISD",
  [SchoolDistrict.Clint]: "Clint ISD",
  [SchoolDistrict.ClydeConsolidated]: "Clyde Consolidated ISD",
  [SchoolDistrict.Coahoma]: "Coahoma ISD",
  [SchoolDistrict.ColdspringOakhurstConsolidated]:
    "Coldspring-Oakhurst Consolidated ISD",
  [SchoolDistrict.Coleman]: "Coleman ISD",
  [SchoolDistrict.CollegeStation]: "College Station ISD",
  [SchoolDistrict.Collinsville]: "Collinsville ISD",
  [SchoolDistrict.Colmesneil]: "Colmesneil ISD",
  [SchoolDistrict.Colorado]: "Colorado ISD",
  [SchoolDistrict.ColumbiaBrazoria]: "Columbia-Brazoria ISD",
  [SchoolDistrict.Columbus]: "Columbus ISD",
  [SchoolDistrict.Comal]: "Comal ISD",
  [SchoolDistrict.Comanche]: "Comanche ISD",
  [SchoolDistrict.Comfort]: "Comfort ISD",
  [SchoolDistrict.Commerce]: "Commerce ISD",
  [SchoolDistrict.Community]: "Community ISD",
  [SchoolDistrict.ComoPicktonConsolidated]: "Como-Pickton Consolidated ISD",
  [SchoolDistrict.Comstock]: "Comstock ISD",
  [SchoolDistrict.Connally]: "Connally ISD",
  [SchoolDistrict.Conroe]: "Conroe ISD",
  [SchoolDistrict.Coolidge]: "Coolidge ISD",
  [SchoolDistrict.Cooper]: "Cooper ISD",
  [SchoolDistrict.Coppell]: "Coppell ISD",
  [SchoolDistrict.CopperasCove]: "Copperas Cove ISD",
  [SchoolDistrict.CorpusChristi]: "Corpus Christi ISD",
  [SchoolDistrict.CorriganCamden]: "Corrigan-Camden ISD",
  [SchoolDistrict.Corsicana]: "Corsicana ISD",
  [SchoolDistrict.CottonCenter]: "Cotton Center ISD",
  [SchoolDistrict.Cotulla]: "Cotulla ISD",
  [SchoolDistrict.Coupland]: "Coupland ISD",
  [SchoolDistrict.Covington]: "Covington ISD",
  [SchoolDistrict.Crandall]: "Crandall ISD",
  [SchoolDistrict.Crane]: "Crane ISD",
  [SchoolDistrict.CranfillsGap]: "Cranfills Gap ISD",
  [SchoolDistrict.Crawford]: "Crawford ISD",
  [SchoolDistrict.CrockettCountyConsolidateCommonSchoolDistrict]:
    "Crockett County Consolidate Common School District",
  [SchoolDistrict.Crockett]: "Crockett ISD",
  [SchoolDistrict.Crosby]: "Crosby ISD",
  [SchoolDistrict.CrosbytonConsolidated]: "Crosbyton Consolidated ISD",
  [SchoolDistrict.CrossPlains]: "Cross Plains ISD",
  [SchoolDistrict.Crowell]: "Crowell ISD",
  [SchoolDistrict.Crowley]: "Crowley ISD",
  [SchoolDistrict.CrystalCity]: "Crystal City ISD",
  [SchoolDistrict.Cuero]: "Cuero ISD",
  [SchoolDistrict.CulbersonCountyAllamoore]: "Culberson County-Allamoore ISD",
  [SchoolDistrict.Cumby]: "Cumby ISD",
  [SchoolDistrict.Cushing]: "Cushing ISD",
  [SchoolDistrict.CypressFairbanks]: "Cypress-Fairbanks ISD",
  [SchoolDistrict.DHanis]: "D'Hanis ISD",
  [SchoolDistrict.DaingerfieldLoneStar]: "Daingerfield-Lone Star ISD",
  [SchoolDistrict.Dalhart]: "Dalhart ISD",
  [SchoolDistrict.Dallas]: "Dallas ISD",
  [SchoolDistrict.Damon]: "Damon ISD",
  [SchoolDistrict.Danbury]: "Danbury ISD",
  [SchoolDistrict.Darrouzett]: "Darrouzett ISD",
  [SchoolDistrict.Dawson]: "Dawson ISD",
  [SchoolDistrict.Dayton]: "Dayton ISD",
  [SchoolDistrict.DeLeon]: "De Leon ISD",
  [SchoolDistrict.Decatur]: "Decatur ISD",
  [SchoolDistrict.DeerPark]: "Deer Park ISD",
  [SchoolDistrict.Dekalb]: "Dekalb ISD",
  [SchoolDistrict.DelValle]: "Del Valle ISD",
  [SchoolDistrict.DellCity]: "Dell City ISD",
  [SchoolDistrict.Denison]: "Denison ISD",
  [SchoolDistrict.Denton]: "Denton ISD",
  [SchoolDistrict.DenverCity]: "Denver City ISD",
  [SchoolDistrict.Desoto]: "Desoto ISD",
  [SchoolDistrict.Detroit]: "Detroit ISD",
  [SchoolDistrict.Devers]: "Devers ISD",
  [SchoolDistrict.Devine]: "Devine ISD",
  [SchoolDistrict.Dew]: "Dew ISD",
  [SchoolDistrict.Deweyville]: "Deweyville ISD",
  [SchoolDistrict.Diboll]: "Diboll ISD",
  [SchoolDistrict.Dickinson]: "Dickinson ISD",
  [SchoolDistrict.Dilley]: "Dilley ISD",
  [SchoolDistrict.DimeBox]: "Dime Box ISD",
  [SchoolDistrict.Dimmitt]: "Dimmitt ISD",
  [SchoolDistrict.Divide]: "Divide ISD",
  [SchoolDistrict.DoddCity]: "Dodd City ISD",
  [SchoolDistrict.Donna]: "Donna ISD",
  [SchoolDistrict.DossConsolidateCommonSchoolDistrict]:
    "Doss Consolidate Common School District",
  [SchoolDistrict.Douglass]: "Douglass ISD",
  [SchoolDistrict.DrippingSprings]: "Dripping Springs ISD",
  [SchoolDistrict.Driscoll]: "Driscoll ISD",
  [SchoolDistrict.Dublin]: "Dublin ISD",
  [SchoolDistrict.Dumas]: "Dumas ISD",
  [SchoolDistrict.Duncanville]: "Duncanville ISD",
  [SchoolDistrict.EagleMtSaginaw]: "Eagle Mt-Saginaw ISD",
  [SchoolDistrict.EaglePass]: "Eagle Pass ISD",
  [SchoolDistrict.Eanes]: "Eanes ISD",
  [SchoolDistrict.Early]: "Early ISD",
  [SchoolDistrict.EastBernard]: "East Bernard ISD",
  [SchoolDistrict.EastCentral]: "East Central ISD",
  [SchoolDistrict.EastChambers]: "East Chambers ISD",
  [SchoolDistrict.Eastland]: "Eastland ISD",
  [SchoolDistrict.EctorCounty]: "Ector County ISD",
  [SchoolDistrict.EdcouchElsa]: "Edcouch-Elsa ISD",
  [SchoolDistrict.EdenConsolidated]: "Eden Consolidated ISD",
  [SchoolDistrict.Edgewood]: "Edgewood ISD",
  [SchoolDistrict.EdinburgConsolidated]: "Edinburg Consolidated ISD",
  [SchoolDistrict.Edna]: "Edna ISD",
  [SchoolDistrict.ElCampo]: "El Campo ISD",
  [SchoolDistrict.ElPaso]: "El Paso ISD",
  [SchoolDistrict.Electra]: "Electra ISD",
  [SchoolDistrict.Elgin]: "Elgin ISD",
  [SchoolDistrict.Elkhart]: "Elkhart ISD",
  [SchoolDistrict.ElysianFields]: "Elysian Fields ISD",
  [SchoolDistrict.Ennis]: "Ennis ISD",
  [SchoolDistrict.Era]: "Era ISD",
  [SchoolDistrict.Etoile]: "Etoile ISD",
  [SchoolDistrict.Eula]: "Eula ISD",
  [SchoolDistrict.Eustace]: "Eustace ISD",
  [SchoolDistrict.Evadale]: "Evadale ISD",
  [SchoolDistrict.Evant]: "Evant ISD",
  [SchoolDistrict.Everman]: "Everman ISD",
  [SchoolDistrict.Excelsior]: "Excelsior ISD",
  [SchoolDistrict.Ezzell]: "Ezzell ISD",
  [SchoolDistrict.Fabens]: "Fabens ISD",
  [SchoolDistrict.Fairfield]: "Fairfield ISD",
  [SchoolDistrict.FallsCity]: "Falls City ISD",
  [SchoolDistrict.Fannindel]: "Fannindel ISD",
  [SchoolDistrict.Farmersville]: "Farmersville ISD",
  [SchoolDistrict.Farwell]: "Farwell ISD",
  [SchoolDistrict.Fayetteville]: "Fayetteville ISD",
  [SchoolDistrict.Ferris]: "Ferris ISD",
  [SchoolDistrict.Flatonia]: "Flatonia ISD",
  [SchoolDistrict.Florence]: "Florence ISD",
  [SchoolDistrict.Floresville]: "Floresville ISD",
  [SchoolDistrict.FlourBluff]: "Flour Bluff ISD",
  [SchoolDistrict.Floydada]: "Floydada ISD",
  [SchoolDistrict.Follett]: "Follett ISD",
  [SchoolDistrict.Forestburg]: "Forestburg ISD",
  [SchoolDistrict.Forney]: "Forney ISD",
  [SchoolDistrict.Forsan]: "Forsan ISD",
  [SchoolDistrict.FortBend]: "Fort Bend ISD",
  [SchoolDistrict.FortElliottConsolidated]: "Fort Elliott Consolidated ISD",
  [SchoolDistrict.FortStockton]: "Fort Stockton ISD",
  [SchoolDistrict.FortWorth]: "Fort Worth ISD",
  [SchoolDistrict.Franklin]: "Franklin ISD",
  [SchoolDistrict.Fredericksburg]: "Fredericksburg ISD",
  [SchoolDistrict.Freer]: "Freer ISD",
  [SchoolDistrict.Frenship]: "Frenship ISD",
  [SchoolDistrict.Friendswood]: "Friendswood ISD",
  [SchoolDistrict.Friona]: "Friona ISD",
  [SchoolDistrict.Frisco]: "Frisco ISD",
  [SchoolDistrict.Frost]: "Frost ISD",
  [SchoolDistrict.Fruitvale]: "Fruitvale ISD",
  [SchoolDistrict.FtDavis]: "Ft Davis ISD",
  [SchoolDistrict.FtHancock]: "Ft Hancock ISD",
  [SchoolDistrict.FtSamHouston]: "Ft Sam Houston ISD",
  [SchoolDistrict.Gainesville]: "Gainesville ISD",
  [SchoolDistrict.GalenaPark]: "Galena Park ISD",
  [SchoolDistrict.Galveston]: "Galveston ISD",
  [SchoolDistrict.Ganado]: "Ganado ISD",
  [SchoolDistrict.Garland]: "Garland ISD",
  [SchoolDistrict.Garrison]: "Garrison ISD",
  [SchoolDistrict.Gary]: "Gary ISD",
  [SchoolDistrict.Gatesville]: "Gatesville ISD",
  [SchoolDistrict.Gause]: "Gause ISD",
  [SchoolDistrict.GeorgeWest]: "George West ISD",
  [SchoolDistrict.Georgetown]: "Georgetown ISD",
  [SchoolDistrict.Gholson]: "Gholson ISD",
  [SchoolDistrict.Giddings]: "Giddings ISD",
  [SchoolDistrict.Gilmer]: "Gilmer ISD",
  [SchoolDistrict.Gladewater]: "Gladewater ISD",
  [SchoolDistrict.GlasscockCounty]: "Glasscock County ISD",
  [SchoolDistrict.GlenRose]: "Glen Rose ISD",
  [SchoolDistrict.Godley]: "Godley ISD",
  [SchoolDistrict.GoldBurg]: "Gold Burg ISD",
  [SchoolDistrict.Goldthwaite]: "Goldthwaite ISD",
  [SchoolDistrict.Goliad]: "Goliad ISD",
  [SchoolDistrict.Gonzales]: "Gonzales ISD",
  [SchoolDistrict.Goodrich]: "Goodrich ISD",
  [SchoolDistrict.GooseCreekCisd]: "Goose Creek Cisd",
  [SchoolDistrict.Gordon]: "Gordon ISD",
  [SchoolDistrict.Gorman]: "Gorman ISD",
  [SchoolDistrict.Grady]: "Grady ISD",
  [SchoolDistrict.Graford]: "Graford ISD",
  [SchoolDistrict.Graham]: "Graham ISD",
  [SchoolDistrict.Granbury]: "Granbury ISD",
  [SchoolDistrict.GrandPrairie]: "Grand Prairie ISD",
  [SchoolDistrict.GrandSaline]: "Grand Saline ISD",
  [SchoolDistrict.GrandfallsRoyalty]: "Grandfalls-Royalty ISD",
  [SchoolDistrict.Grandview]: "Grandview ISD",
  [SchoolDistrict.GrandviewHopkins]: "Grandview-Hopkins ISD",
  [SchoolDistrict.Granger]: "Granger ISD",
  [SchoolDistrict.GrapeCreek]: "Grape Creek ISD",
  [SchoolDistrict.Grapeland]: "Grapeland ISD",
  [SchoolDistrict.GrapevineColleyville]: "Grapevine-Colleyville ISD",
  [SchoolDistrict.Greenville]: "Greenville ISD",
  [SchoolDistrict.Greenwood]: "Greenwood ISD",
  [SchoolDistrict.GregoryPortland]: "Gregory-Portland ISD",
  [SchoolDistrict.Groesbeck]: "Groesbeck ISD",
  [SchoolDistrict.Groom]: "Groom ISD",
  [SchoolDistrict.Groveton]: "Groveton ISD",
  [SchoolDistrict.Gruver]: "Gruver ISD",
  [SchoolDistrict.Gunter]: "Gunter ISD",
  [SchoolDistrict.Gustine]: "Gustine ISD",
  [SchoolDistrict.GuthriCommonSchoolDistrict]: "Guthri Common School District",
  [SchoolDistrict.HaleCenter]: "Hale Center ISD",
  [SchoolDistrict.HalletsvilleInVysehrad]:
    "Halletsville Isd (9-12) In Vysehrad",
  [SchoolDistrict.Hallettsville]: "Hallettsville ISD",
  [SchoolDistrict.Hallsburg]: "Hallsburg ISD",
  [SchoolDistrict.Hallsville]: "Hallsville ISD",
  [SchoolDistrict.Hamilton]: "Hamilton ISD",
  [SchoolDistrict.Hamlin]: "Hamlin ISD",
  [SchoolDistrict.HamshireFannett]: "Hamshire-Fannett ISD",
  [SchoolDistrict.Happy]: "Happy ISD",
  [SchoolDistrict.Hardin]: "Hardin ISD",
  [SchoolDistrict.HardinJefferson]: "Hardin-Jefferson ISD",
  [SchoolDistrict.Harlandale]: "Harlandale ISD",
  [SchoolDistrict.Harleton]: "Harleton ISD",
  [SchoolDistrict.HarlingenConsolidated]: "Harlingen Consolidated ISD",
  [SchoolDistrict.Harmony]: "Harmony ISD",
  [SchoolDistrict.Harper]: "Harper ISD",
  [SchoolDistrict.Harrold]: "Harrold ISD",
  [SchoolDistrict.Hart]: "Hart ISD",
  [SchoolDistrict.Hartley]: "Hartley ISD",
  [SchoolDistrict.HartsBluff]: "Harts Bluff ISD",
  [SchoolDistrict.HaskellConsolidated]: "Haskell Consolidated ISD",
  [SchoolDistrict.Hawkins]: "Hawkins ISD",
  [SchoolDistrict.Hawley]: "Hawley ISD",
  [SchoolDistrict.HaysCisd]: "Hays Cisd",
  [SchoolDistrict.Hearne]: "Hearne ISD",
  [SchoolDistrict.Hedley]: "Hedley ISD",
  [SchoolDistrict.Hemphill]: "Hemphill ISD",
  [SchoolDistrict.Hempstead]: "Hempstead ISD",
  [SchoolDistrict.Henderson]: "Henderson ISD",
  [SchoolDistrict.Henrietta]: "Henrietta ISD",
  [SchoolDistrict.Hereford]: "Hereford ISD",
  [SchoolDistrict.Hermleigh]: "Hermleigh ISD",
  [SchoolDistrict.Hico]: "Hico ISD",
  [SchoolDistrict.Hidalgo]: "Hidalgo ISD",
  [SchoolDistrict.Higgins]: "Higgins ISD",
  [SchoolDistrict.HighIsland]: "High Island ISD",
  [SchoolDistrict.Highland]: "Highland ISD",
  [SchoolDistrict.HighlandPark]: "Highland Park ISD",
  [SchoolDistrict.Hillsboro]: "Hillsboro ISD",
  [SchoolDistrict.Hitchcock]: "Hitchcock ISD",
  [SchoolDistrict.Holland]: "Holland ISD",
  [SchoolDistrict.Holliday]: "Holliday ISD",
  [SchoolDistrict.Hondo]: "Hondo ISD",
  [SchoolDistrict.HoneyGrove]: "Honey Grove ISD",
  [SchoolDistrict.Hooks]: "Hooks ISD",
  [SchoolDistrict.Houston]: "Houston ISD",
  [SchoolDistrict.Howe]: "Howe ISD",
  [SchoolDistrict.Hubbard]: "Hubbard ISD",
  [SchoolDistrict.Huckabay]: "Huckabay ISD",
  [SchoolDistrict.Hudson]: "Hudson ISD",
  [SchoolDistrict.Huffman]: "Huffman ISD",
  [SchoolDistrict.HughesSprings]: "Hughes Springs ISD",
  [SchoolDistrict.HullDaisetta]: "Hull-Daisetta ISD",
  [SchoolDistrict.Humble]: "Humble ISD",
  [SchoolDistrict.Hunt]: "Hunt ISD",
  [SchoolDistrict.Huntington]: "Huntington ISD",
  [SchoolDistrict.Huntsville]: "Huntsville ISD",
  [SchoolDistrict.HurstEulessBedford]: "Hurst-Euless-Bedford ISD",
  [SchoolDistrict.Hutto]: "Hutto ISD",
  [SchoolDistrict.Idalou]: "Idalou ISD",
  [SchoolDistrict.Industrial]: "Industrial ISD",
  [SchoolDistrict.Ingleside]: "Ingleside ISD",
  [SchoolDistrict.Ingram]: "Ingram ISD",
  [SchoolDistrict.Iola]: "Iola ISD",
  [SchoolDistrict.IowaParkConsolidated]: "Iowa Park Consolidated ISD",
  [SchoolDistrict.Ira]: "Ira ISD",
  [SchoolDistrict.IraanSheffield]: "Iraan-Sheffield ISD",
  [SchoolDistrict.Iredell]: "Iredell ISD",
  [SchoolDistrict.IrionCounty]: "Irion County ISD",
  [SchoolDistrict.Irving]: "Irving ISD",
  [SchoolDistrict.Italy]: "Italy ISD",
  [SchoolDistrict.Itasca]: "Itasca ISD",
  [SchoolDistrict.Jacksboro]: "Jacksboro ISD",
  [SchoolDistrict.Jacksonville]: "Jacksonville ISD",
  [SchoolDistrict.Jarrell]: "Jarrell ISD",
  [SchoolDistrict.Jasper]: "Jasper ISD",
  [SchoolDistrict.JaytonGirard]: "Jayton-Girard ISD",
  [SchoolDistrict.Jefferson]: "Jefferson ISD",
  [SchoolDistrict.JimHoggCounty]: "Jim Hogg County ISD",
  [SchoolDistrict.JimNedConsolidated]: "Jim Ned Consolidated ISD",
  [SchoolDistrict.Joaquin]: "Joaquin ISD",
  [SchoolDistrict.JohnsonCity]: "Johnson City ISD",
  [SchoolDistrict.Jonesboro]: "Jonesboro ISD",
  [SchoolDistrict.Joshua]: "Joshua ISD",
  [SchoolDistrict.Jourdanton]: "Jourdanton ISD",
  [SchoolDistrict.Judson]: "Judson ISD",
  [SchoolDistrict.Junction]: "Junction ISD",
  [SchoolDistrict.Karnack]: "Karnack ISD",
  [SchoolDistrict.KarnesCity]: "Karnes City",
  [SchoolDistrict.Katy]: "Katy ISD",
  [SchoolDistrict.Kaufman]: "Kaufman ISD",
  [SchoolDistrict.Keene]: "Keene ISD",
  [SchoolDistrict.Keller]: "Keller ISD",
  [SchoolDistrict.Kelton]: "Kelton ISD",
  [SchoolDistrict.Kemp]: "Kemp ISD",
  [SchoolDistrict.Kenedy]: "Kenedy ISD",
  [SchoolDistrict.Kennard]: "Kennard ISD",
  [SchoolDistrict.Kennedale]: "Kennedale ISD",
  [SchoolDistrict.Kerens]: "Kerens ISD",
  [SchoolDistrict.Kermit]: "Kermit ISD",
  [SchoolDistrict.Kerrville]: "Kerrville ISD",
  [SchoolDistrict.Kilgore]: "Kilgore ISD",
  [SchoolDistrict.Killeen]: "Killeen ISD",
  [SchoolDistrict.Kingsville]: "Kingsville ISD",
  [SchoolDistrict.KirbyvilleConsolidated]: "Kirbyville Consolidated ISD",
  [SchoolDistrict.Klein]: "Klein ISD",
  [SchoolDistrict.Klondike]: "Klondike ISD",
  [SchoolDistrict.Knippa]: "Knippa ISD",
  [SchoolDistrict.KnoxCityOBrienConsolidated]:
    "Knox City-O'Brien Consolidated ISD",
  [SchoolDistrict.Kopperl]: "Kopperl ISD",
  [SchoolDistrict.Kountze]: "Kountze ISD",
  [SchoolDistrict.Kress]: "Kress ISD",
  [SchoolDistrict.Krum]: "Krum ISD",
  [SchoolDistrict.LaFeria]: "La Feria ISD",
  [SchoolDistrict.LaGloria]: "La Gloria ISD",
  [SchoolDistrict.LaGrange]: "La Grange ISD",
  [SchoolDistrict.LaJoya]: "La Joya ISD",
  [SchoolDistrict.LaMarque]: "La Marque ISD",
  [SchoolDistrict.LaPorte]: "La Porte ISD",
  [SchoolDistrict.LaPryor]: "La Pryor ISD",
  [SchoolDistrict.LaVega]: "La Vega ISD",
  [SchoolDistrict.LaVernia]: "La Vernia ISD",
  [SchoolDistrict.LaVilla]: "La Villa ISD",
  [SchoolDistrict.Lackland]: "Lackland ISD",
  [SchoolDistrict.LagoVista]: "Lago Vista ISD",
  [SchoolDistrict.LakeDallas]: "Lake Dallas ISD",
  [SchoolDistrict.LakeTravis]: "Lake Travis ISD",
  [SchoolDistrict.LakeWorth]: "Lake Worth ISD",
  [SchoolDistrict.LamarConsolidated]: "Lamar Consolidated ISD",
  [SchoolDistrict.Lamesa]: "Lamesa ISD",
  [SchoolDistrict.Lampasas]: "Lampasas ISD",
  [SchoolDistrict.Lancaster]: "Lancaster ISD",
  [SchoolDistrict.Laneville]: "Laneville ISD",
  [SchoolDistrict.Lapoynor]: "Lapoynor ISD",
  [SchoolDistrict.Laredo]: "Laredo ISD",
  [SchoolDistrict.Lasara]: "Lasara ISD",
  [SchoolDistrict.Latexo]: "Latexo ISD",
  [SchoolDistrict.Lazbuddie]: "Lazbuddie ISD",
  [SchoolDistrict.Leakey]: "Leakey ISD",
  [SchoolDistrict.Leander]: "Leander ISD",
  [SchoolDistrict.Leary]: "Leary ISD",
  [SchoolDistrict.Lefors]: "Lefors ISD",
  [SchoolDistrict.Leggett]: "Leggett ISD",
  [SchoolDistrict.Leon]: "Leon ISD",
  [SchoolDistrict.Leonard]: "Leonard ISD",
  [SchoolDistrict.Levelland]: "Levelland ISD",
  [SchoolDistrict.LeverettsChapel]: "Leveretts Chapel ISD",
  [SchoolDistrict.Lewisville]: "Lewisville ISD",
  [SchoolDistrict.Lexington]: "Lexington ISD",
  [SchoolDistrict.LibertyHill]: "Liberty Hill ISD",
  [SchoolDistrict.Liberty]: "Liberty ISD",
  [SchoolDistrict.LibertyEylau]: "Liberty-Eylau ISD",
  [SchoolDistrict.Lindale]: "Lindale ISD",
  [SchoolDistrict.LindenKildareConsolidated]: "Linden-Kildare Consolidated ISD",
  [SchoolDistrict.Lindsay]: "Lindsay ISD",
  [SchoolDistrict.Lingleville]: "Lingleville ISD",
  [SchoolDistrict.Lipan]: "Lipan ISD",
  [SchoolDistrict.LittleCypressMauricevilleConsolidated]:
    "Little Cypress-Mauriceville Consolidated Independent Sd",
  [SchoolDistrict.LittleElm]: "Little Elm ISD",
  [SchoolDistrict.Littlefield]: "Littlefield ISD",
  [SchoolDistrict.Livingston]: "Livingston ISD",
  [SchoolDistrict.Llano]: "Llano ISD",
  [SchoolDistrict.Lockhart]: "Lockhart ISD",
  [SchoolDistrict.Lockney]: "Lockney ISD",
  [SchoolDistrict.Lohn]: "Lohn ISD",
  [SchoolDistrict.Lometa]: "Lometa ISD",
  [SchoolDistrict.London]: "London ISD",
  [SchoolDistrict.LoneOak]: "Lone Oak ISD",
  [SchoolDistrict.Longview]: "Longview ISD",
  [SchoolDistrict.Loop]: "Loop ISD",
  [SchoolDistrict.Loraine]: "Loraine ISD",
  [SchoolDistrict.Lorena]: "Lorena ISD",
  [SchoolDistrict.Lorenzo]: "Lorenzo ISD",
  [SchoolDistrict.LosFresnosConsolidated]: "Los Fresnos Consolidated ISD",
  [SchoolDistrict.Louise]: "Louise ISD",
  [SchoolDistrict.Lovejoy]: "Lovejoy ISD",
  [SchoolDistrict.Lovelady]: "Lovelady ISD",
  [SchoolDistrict.Lubbock]: "Lubbock ISD",
  [SchoolDistrict.LubbockCooper]: "Lubbock-Cooper ISD",
  [SchoolDistrict.LuedersAvoca]: "Lueders-Avoca ISD",
  [SchoolDistrict.Lufkin]: "Lufkin ISD",
  [SchoolDistrict.Luling]: "Luling ISD",
  [SchoolDistrict.Lumberton]: "Lumberton ISD",
  [SchoolDistrict.LyfordConsolidated]: "Lyford Consolidated ISD",
  [SchoolDistrict.Lytle]: "Lytle ISD",
  [SchoolDistrict.Mabank]: "Mabank ISD",
  [SchoolDistrict.MadisonvilleConsolidated]: "Madisonville Consolidated ISD",
  [SchoolDistrict.Magnolia]: "Magnolia ISD",
  [SchoolDistrict.Malakoff]: "Malakoff ISD",
  [SchoolDistrict.Malone]: "Malone ISD",
  [SchoolDistrict.Malta]: "Malta ISD",
  [SchoolDistrict.Manor]: "Manor ISD",
  [SchoolDistrict.Mansfield]: "Mansfield ISD",
  [SchoolDistrict.Marathon]: "Marathon ISD",
  [SchoolDistrict.MarbleFalls]: "Marble Falls ISD",
  [SchoolDistrict.Marfa]: "Marfa ISD",
  [SchoolDistrict.Marion]: "Marion ISD",
  [SchoolDistrict.Marlin]: "Marlin ISD",
  [SchoolDistrict.Marshall]: "Marshall ISD",
  [SchoolDistrict.Mart]: "Mart ISD",
  [SchoolDistrict.MartinsMill]: "Martins Mill ISD",
  [SchoolDistrict.Martinsville]: "Martinsville ISD",
  [SchoolDistrict.Mason]: "Mason ISD",
  [SchoolDistrict.Matagorda]: "Matagorda ISD",
  [SchoolDistrict.Mathis]: "Mathis ISD",
  [SchoolDistrict.Maud]: "Maud ISD",
  [SchoolDistrict.May]: "May ISD",
  [SchoolDistrict.Maypearl]: "Maypearl ISD",
  [SchoolDistrict.Mcallen]: "Mcallen ISD",
  [SchoolDistrict.Mccamey]: "Mccamey ISD",
  [SchoolDistrict.Mcdade]: "Mcdade ISD",
  [SchoolDistrict.Mcgregor]: "Mcgregor ISD",
  [SchoolDistrict.Mckinney]: "Mckinney ISD",
  [SchoolDistrict.Mclean]: "Mclean ISD",
  [SchoolDistrict.Mcleod]: "Mcleod ISD",
  [SchoolDistrict.McmullenCounty]: "Mcmullen County ISD",
  [SchoolDistrict.Meadow]: "Meadow ISD",
  [SchoolDistrict.Medina]: "Medina ISD",
  [SchoolDistrict.MedinaValley]: "Medina Valley ISD",
  [SchoolDistrict.Melissa]: "Melissa ISD",
  [SchoolDistrict.Memphis]: "Memphis ISD",
  [SchoolDistrict.Menard]: "Menard ISD",
  [SchoolDistrict.Mercedes]: "Mercedes ISD",
  [SchoolDistrict.Meridian]: "Meridian ISD",
  [SchoolDistrict.Merkel]: "Merkel ISD",
  [SchoolDistrict.Mesquite]: "Mesquite ISD",
  [SchoolDistrict.Mexia]: "Mexia ISD",
  [SchoolDistrict.Meyersville]: "Meyersville ISD",
  [SchoolDistrict.Miami]: "Miami ISD",
  [SchoolDistrict.Midland]: "Midland ISD",
  [SchoolDistrict.Midlothian]: "Midlothian ISD",
  [SchoolDistrict.Midway]: "Midway ISD",
  [SchoolDistrict.Milano]: "Milano ISD",
  [SchoolDistrict.Mildred]: "Mildred ISD",
  [SchoolDistrict.Miles]: "Miles ISD",
  [SchoolDistrict.Milford]: "Milford ISD",
  [SchoolDistrict.MillerGrove]: "Miller Grove ISD",
  [SchoolDistrict.Millsap]: "Millsap ISD",
  [SchoolDistrict.Mineola]: "Mineola ISD",
  [SchoolDistrict.MineralWells]: "Mineral Wells ISD",
  [SchoolDistrict.MissionConsolidated]: "Mission Consolidated ISD",
  [SchoolDistrict.MonahansWickettPyote]: "Monahans-Wickett-Pyote ISD",
  [SchoolDistrict.Montague]: "Montague ISD",
  [SchoolDistrict.MonteAlto]: "Monte Alto ISD",
  [SchoolDistrict.Montgomery]: "Montgomery ISD",
  [SchoolDistrict.Moody]: "Moody ISD",
  [SchoolDistrict.Moran]: "Moran ISD",
  [SchoolDistrict.Morgan]: "Morgan ISD",
  [SchoolDistrict.MorganMill]: "Morgan Mill ISD",
  [SchoolDistrict.Morton]: "Morton ISD",
  [SchoolDistrict.MotleyCounty]: "Motley County ISD",
  [SchoolDistrict.Moulton]: "Moulton ISD",
  [SchoolDistrict.MountCalm]: "Mount Calm ISD",
  [SchoolDistrict.MountEnterprise]: "Mount Enterprise ISD",
  [SchoolDistrict.MountPleasant]: "Mount Pleasant ISD",
  [SchoolDistrict.MountVernon]: "Mount Vernon ISD",
  [SchoolDistrict.Muenster]: "Muenster ISD",
  [SchoolDistrict.Muleshoe]: "Muleshoe ISD",
  [SchoolDistrict.Mullin]: "Mullin ISD",
  [SchoolDistrict.Mumford]: "Mumford ISD",
  [SchoolDistrict.MundayConsolidated]: "Munday Consolidated ISD",
  [SchoolDistrict.Murchison]: "Murchison ISD",
  [SchoolDistrict.Nacogdoches]: "Nacogdoches ISD",
  [SchoolDistrict.Natalia]: "Natalia ISD",
  [SchoolDistrict.Navarro]: "Navarro ISD",
  [SchoolDistrict.Navasota]: "Navasota ISD",
  [SchoolDistrict.Nazareth]: "Nazareth ISD",
  [SchoolDistrict.Neches]: "Neches ISD",
  [SchoolDistrict.Nederland]: "Nederland ISD",
  [SchoolDistrict.Needville]: "Needville ISD",
  [SchoolDistrict.NewBoston]: "New Boston ISD",
  [SchoolDistrict.NewBraunfels]: "New Braunfels ISD",
  [SchoolDistrict.NewCaney]: "New Caney ISD",
  [SchoolDistrict.NewDeal]: "New Deal ISD",
  [SchoolDistrict.NewDiana]: "New Diana ISD",
  [SchoolDistrict.NewHome]: "New Home ISD",
  [SchoolDistrict.NewSummerfield]: "New Summerfield ISD",
  [SchoolDistrict.NewWaverly]: "New Waverly ISD",
  [SchoolDistrict.Newcastle]: "Newcastle ISD",
  [SchoolDistrict.Newton]: "Newton ISD",
  [SchoolDistrict.NixonSmileyCisd]: "Nixon-Smiley Cisd",
  [SchoolDistrict.Nocona]: "Nocona ISD",
  [SchoolDistrict.Nordheim]: "Nordheim ISD",
  [SchoolDistrict.Normangee]: "Normangee ISD",
  [SchoolDistrict.NorthEast]: "North East ISD",
  [SchoolDistrict.NorthHopkins]: "North Hopkins ISD",
  [SchoolDistrict.NorthLamar]: "North Lamar ISD",
  [SchoolDistrict.NorthZulch]: "North Zulch ISD",
  [SchoolDistrict.Northside]: "Northside ISD",
  [SchoolDistrict.Northwest]: "Northwest ISD",
  [SchoolDistrict.NuecesCanyonConsolidated]: "Nueces Canyon Consolidated ISD",
  [SchoolDistrict.Nursery]: "Nursery ISD",
  [SchoolDistrict.ODonnell]: "O'Donnell ISD",
  [SchoolDistrict.Oakwood]: "Oakwood ISD",
  [SchoolDistrict.OdemEdroy]: "Odem-Edroy ISD",
  [SchoolDistrict.Oglesby]: "Oglesby ISD",
  [SchoolDistrict.Olfen]: "Olfen ISD",
  [SchoolDistrict.Olney]: "Olney ISD",
  [SchoolDistrict.Olton]: "Olton ISD",
  [SchoolDistrict.Onalaska]: "Onalaska ISD",
  [SchoolDistrict.OrangeGrove]: "Orange Grove ISD",
  [SchoolDistrict.Orangefield]: "Orangefield ISD",
  [SchoolDistrict.OreCity]: "Ore City ISD",
  [SchoolDistrict.Overton]: "Overton ISD",
  [SchoolDistrict.Paducah]: "Paducah ISD",
  [SchoolDistrict.PaintCreek]: "Paint Creek ISD",
  [SchoolDistrict.PaintRock]: "Paint Rock ISD",
  [SchoolDistrict.Palacios]: "Palacios ISD",
  [SchoolDistrict.Palestine]: "Palestine ISD",
  [SchoolDistrict.Palmer]: "Palmer ISD",
  [SchoolDistrict.PaloPinto]: "Palo Pinto ISD",
  [SchoolDistrict.Pampa]: "Pampa ISD",
  [SchoolDistrict.Panhandle]: "Panhandle ISD",
  [SchoolDistrict.PantherCreekConsolidated]: "Panther Creek Consolidated ISD",
  [SchoolDistrict.Paradise]: "Paradise ISD",
  [SchoolDistrict.Paris]: "Paris ISD",
  [SchoolDistrict.Pasadena]: "Pasadena ISD",
  [SchoolDistrict.PattonSprings]: "Patton Springs ISD",
  [SchoolDistrict.Pawnee]: "Pawnee ISD",
  [SchoolDistrict.Pearland]: "Pearland ISD",
  [SchoolDistrict.Pearsall]: "Pearsall ISD",
  [SchoolDistrict.Peaster]: "Peaster ISD",
  [SchoolDistrict.PecosBarstowToyah]: "Pecos-Barstow-Toyah ISD",
  [SchoolDistrict.Penelope]: "Penelope ISD",
  [SchoolDistrict.PerrinWhittConsolidated]: "Perrin-Whitt Consolidated ISD",
  [SchoolDistrict.Perryton]: "Perryton ISD",
  [SchoolDistrict.Petersburg]: "Petersburg ISD",
  [SchoolDistrict.Petrolia]: "Petrolia ISD",
  [SchoolDistrict.Pettus]: "Pettus ISD",
  [SchoolDistrict.PewittConsolidated]: "Pewitt Consolidated ISD",
  [SchoolDistrict.Pflugerville]: "Pflugerville ISD",
  [SchoolDistrict.PharrSanJuanAlamo]: "Pharr-San Juan-Alamo ISD",
  [SchoolDistrict.PilotPoint]: "Pilot Point ISD",
  [SchoolDistrict.PineTree]: "Pine Tree ISD",
  [SchoolDistrict.Pittsburg]: "Pittsburg ISD",
  [SchoolDistrict.Plains]: "Plains ISD",
  [SchoolDistrict.Plainview]: "Plainview ISD",
  [SchoolDistrict.Plano]: "Plano ISD",
  [SchoolDistrict.PleasantGrove]: "Pleasant Grove ISD",
  [SchoolDistrict.Pleasanton]: "Pleasanton ISD",
  [SchoolDistrict.PlemonsStinnettPhillipsConsolidated]:
    "Plemons-Stinnett-Phillips Consolidated Independent Sd",
  [SchoolDistrict.PointIsabel]: "Point Isabel ISD",
  [SchoolDistrict.Ponder]: "Ponder ISD",
  [SchoolDistrict.Poolville]: "Poolville ISD",
  [SchoolDistrict.PortAransas]: "Port Aransas ISD",
  [SchoolDistrict.PortArthur]: "Port Arthur ISD",
  [SchoolDistrict.PortNechesGroves]: "Port Neches-Groves ISD",
  [SchoolDistrict.Post]: "Post ISD",
  [SchoolDistrict.Poteet]: "Poteet ISD",
  [SchoolDistrict.Poth]: "Poth ISD",
  [SchoolDistrict.Pottsboro]: "Pottsboro ISD",
  [SchoolDistrict.PrairieLea]: "Prairie Lea ISD",
  [SchoolDistrict.PrairieValley]: "Prairie Valley ISD",
  [SchoolDistrict.Prairiland]: "Prairiland ISD",
  [SchoolDistrict.Premont]: "Premont ISD",
  [SchoolDistrict.Presidio]: "Presidio ISD",
  [SchoolDistrict.Priddy]: "Priddy ISD",
  [SchoolDistrict.Princeton]: "Princeton ISD",
  [SchoolDistrict.PringleMorseConsolidated]: "Pringle-Morse Consolidated ISD",
  [SchoolDistrict.Progreso]: "Progreso ISD",
  [SchoolDistrict.Prosper]: "Prosper ISD",
  [SchoolDistrict.Quanah]: "Quanah ISD",
  [SchoolDistrict.QueenCity]: "Queen City ISD",
  [SchoolDistrict.Quinlan]: "Quinlan ISD",
  [SchoolDistrict.Quitman]: "Quitman ISD",
  [SchoolDistrict.Rains]: "Rains ISD",
  [SchoolDistrict.Ralls]: "Ralls ISD",
  [SchoolDistrict.RamireCommonSchoolDistrict]: "Ramire Common School District",
  [SchoolDistrict.RandolphField]: "Randolph Field ISD",
  [SchoolDistrict.Ranger]: "Ranger ISD",
  [SchoolDistrict.Rankin]: "Rankin ISD",
  [SchoolDistrict.Raymondville]: "Raymondville ISD",
  [SchoolDistrict.ReaganCounty]: "Reagan County ISD",
  [SchoolDistrict.RedLick]: "Red Lick ISD",
  [SchoolDistrict.RedOak]: "Red Oak ISD",
  [SchoolDistrict.Redwater]: "Redwater ISD",
  [SchoolDistrict.Refugio]: "Refugio ISD",
  [SchoolDistrict.Ricardo]: "Ricardo ISD",
  [SchoolDistrict.RiceConsolidated]: "Rice Consolidated ISD",
  [SchoolDistrict.Rice]: "Rice ISD",
  [SchoolDistrict.Richards]: "Richards ISD",
  [SchoolDistrict.Richardson]: "Richardson ISD",
  [SchoolDistrict.RichlandSprings]: "Richland Springs ISD",
  [SchoolDistrict.Riesel]: "Riesel ISD",
  [SchoolDistrict.RioHondo]: "Rio Hondo ISD",
  [SchoolDistrict.RioVista]: "Rio Vista ISD",
  [SchoolDistrict.RisingStar]: "Rising Star ISD",
  [SchoolDistrict.RiverRoad]: "River Road ISD",
  [SchoolDistrict.Rivercrest]: "Rivercrest ISD",
  [SchoolDistrict.Riviera]: "Riviera ISD",
  [SchoolDistrict.RobertLee]: "Robert Lee ISD",
  [SchoolDistrict.Robinson]: "Robinson ISD",
  [SchoolDistrict.Robstown]: "Robstown ISD",
  [SchoolDistrict.RobyConsolidated]: "Roby Consolidated ISD",
  [SchoolDistrict.Rochelle]: "Rochelle ISD",
  [SchoolDistrict.Rockdale]: "Rockdale ISD",
  [SchoolDistrict.Rocksprings]: "Rocksprings ISD",
  [SchoolDistrict.Rockwall]: "Rockwall ISD",
  [SchoolDistrict.Rogers]: "Rogers ISD",
  [SchoolDistrict.Roma]: "Roma ISD",
  [SchoolDistrict.Roosevelt]: "Roosevelt ISD",
  [SchoolDistrict.Ropes]: "Ropes ISD",
  [SchoolDistrict.Roscoe]: "Roscoe ISD",
  [SchoolDistrict.RosebudLott]: "Rosebud-Lott ISD",
  [SchoolDistrict.Rotan]: "Rotan ISD",
  [SchoolDistrict.RoundRock]: "Round Rock ISD",
  [SchoolDistrict.RoundTopCarmine]: "Round Top-Carmine ISD",
  [SchoolDistrict.Roxton]: "Roxton ISD",
  [SchoolDistrict.Royal]: "Royal ISD",
  [SchoolDistrict.RoyseCity]: "Royse City ISD",
  [SchoolDistrict.Rule]: "Rule ISD",
  [SchoolDistrict.Runge]: "Runge ISD",
  [SchoolDistrict.Rusk]: "Rusk ISD",
  [SchoolDistrict.SAndSConsolidated]: "S And S Consolidated ISD",
  [SchoolDistrict.Sabinal]: "Sabinal ISD",
  [SchoolDistrict.Sabine]: "Sabine ISD",
  [SchoolDistrict.SabinePass]: "Sabine Pass ISD",
  [SchoolDistrict.SaintJo]: "Saint Jo ISD",
  [SchoolDistrict.Salado]: "Salado ISD",
  [SchoolDistrict.Saltillo]: "Saltillo ISD",
  [SchoolDistrict.SamRayburn]: "Sam Rayburn ISD",
  [SchoolDistrict.SanAngelo]: "San Angelo ISD",
  [SchoolDistrict.SanAntonio]: "San Antonio ISD",
  [SchoolDistrict.SanAugustine]: "San Augustine ISD",
  [SchoolDistrict.SanBenitoConsolidated]: "San Benito Consolidated ISD",
  [SchoolDistrict.SanDiego]: "San Diego ISD",
  [SchoolDistrict.SanElizario]: "San Elizario ISD",
  [SchoolDistrict.SanFelipeDelRioConsolidated]:
    "San Felipe-Del Rio Consolidated ISD",
  [SchoolDistrict.SanIsidro]: "San Isidro ISD",
  [SchoolDistrict.SanMarcosCisd]: "San Marcos Cisd",
  [SchoolDistrict.SanPerlita]: "San Perlita ISD",
  [SchoolDistrict.SanSaba]: "San Saba ISD",
  [SchoolDistrict.SanVicente]: "San Vicente ISD",
  [SchoolDistrict.SanfordFritch]: "Sanford-Fritch ISD",
  [SchoolDistrict.Sanger]: "Sanger ISD",
  [SchoolDistrict.SantaAnna]: "Santa Anna ISD",
  [SchoolDistrict.SantaFe]: "Santa Fe ISD",
  [SchoolDistrict.SantaGertrudis]: "Santa Gertrudis ISD",
  [SchoolDistrict.SantaMaria]: "Santa Maria ISD",
  [SchoolDistrict.SantaRosa]: "Santa Rosa ISD",
  [SchoolDistrict.Santo]: "Santo ISD",
  [SchoolDistrict.Savoy]: "Savoy ISD",
  [SchoolDistrict.SchertzCiboloUniversalCity]:
    "Schertz-Cibolo Universal City ISD",
  [SchoolDistrict.Schleicher]: "Schleicher ISD",
  [SchoolDistrict.Schulenburg]: "Schulenburg ISD",
  [SchoolDistrict.ScurryRosser]: "Scurry-Rosser ISD",
  [SchoolDistrict.Seagraves]: "Seagraves ISD",
  [SchoolDistrict.Sealy]: "Sealy ISD",
  [SchoolDistrict.Seguin]: "Seguin ISD",
  [SchoolDistrict.Seminole]: "Seminole ISD",
  [SchoolDistrict.Seymour]: "Seymour ISD",
  [SchoolDistrict.Shallowater]: "Shallowater ISD",
  [SchoolDistrict.Shamrock]: "Shamrock ISD",
  [SchoolDistrict.Sharyland]: "Sharyland ISD",
  [SchoolDistrict.Shelbyville]: "Shelbyville ISD",
  [SchoolDistrict.Sheldon]: "Sheldon ISD",
  [SchoolDistrict.Shepherd]: "Shepherd ISD",
  [SchoolDistrict.Sherman]: "Sherman ISD",
  [SchoolDistrict.Shiner]: "Shiner ISD",
  [SchoolDistrict.Sidney]: "Sidney ISD",
  [SchoolDistrict.SierraBlanca]: "Sierra Blanca ISD",
  [SchoolDistrict.Silsbee]: "Silsbee ISD",
  [SchoolDistrict.Silverton]: "Silverton ISD",
  [SchoolDistrict.Simms]: "Simms ISD",
  [SchoolDistrict.Sinton]: "Sinton ISD",
  [SchoolDistrict.SivellsBend]: "Sivells Bend ISD",
  [SchoolDistrict.SkidmoreTynan]: "Skidmore-Tynan ISD",
  [SchoolDistrict.Slaton]: "Slaton ISD",
  [SchoolDistrict.Slidell]: "Slidell ISD",
  [SchoolDistrict.Slocum]: "Slocum ISD",
  [SchoolDistrict.Smithville]: "Smithville",
  [SchoolDistrict.Smyer]: "Smyer ISD",
  [SchoolDistrict.Snook]: "Snook ISD",
  [SchoolDistrict.Snyder]: "Snyder ISD",
  [SchoolDistrict.Socorro]: "Socorro ISD",
  [SchoolDistrict.Somerset]: "Somerset ISD",
  [SchoolDistrict.Somerville]: "Somerville ISD",
  [SchoolDistrict.Sonora]: "Sonora ISD",
  [SchoolDistrict.SouthSanAntonio]: "South San Antonio ISD",
  [SchoolDistrict.Southland]: "Southland ISD",
  [SchoolDistrict.Southside]: "Southside ISD",
  [SchoolDistrict.Southwest]: "Southwest ISD",
  [SchoolDistrict.Spearman]: "Spearman ISD",
  [SchoolDistrict.Splendora]: "Splendora ISD",
  [SchoolDistrict.SpringBranch]: "Spring Branch ISD",
  [SchoolDistrict.SpringCreek]: "Spring Creek ISD",
  [SchoolDistrict.SpringHill]: "Spring Hill ISD",
  [SchoolDistrict.Spring]: "Spring ISD",
  [SchoolDistrict.SpringlakeEarth]: "Springlake-Earth ISD",
  [SchoolDistrict.Springtown]: "Springtown ISD",
  [SchoolDistrict.Spur]: "Spur ISD",
  [SchoolDistrict.Spurger]: "Spurger ISD",
  [SchoolDistrict.StaffordMunicipalSchoolDistrict]:
    "Stafford Municipal School District",
  [SchoolDistrict.Stamford]: "Stamford ISD",
  [SchoolDistrict.Stanton]: "Stanton ISD",
  [SchoolDistrict.Star]: "Star ISD",
  [SchoolDistrict.Stephenville]: "Stephenville",
  [SchoolDistrict.SterlingCity]: "Sterling City ISD",
  [SchoolDistrict.Stockdale]: "Stockdale ISD",
  [SchoolDistrict.Stratford]: "Stratford ISD",
  [SchoolDistrict.Strawn]: "Strawn ISD",
  [SchoolDistrict.Sudan]: "Sudan ISD",
  [SchoolDistrict.SulphurBluff]: "Sulphur Bluff ISD",
  [SchoolDistrict.SulphurSprings]: "Sulphur Springs ISD",
  [SchoolDistrict.Sundown]: "Sundown ISD",
  [SchoolDistrict.Sunnyvale]: "Sunnyvale ISD",
  [SchoolDistrict.Sunray]: "Sunray ISD",
  [SchoolDistrict.Sweeny]: "Sweeny ISD",
  [SchoolDistrict.SweetHome]: "Sweet Home ISD",
  [SchoolDistrict.Sweetwater]: "Sweetwater ISD",
  [SchoolDistrict.Taft]: "Taft ISD",
  [SchoolDistrict.Tahoka]: "Tahoka ISD",
  [SchoolDistrict.Tarkington]: "Tarkington ISD",
  [SchoolDistrict.Tatum]: "Tatum ISD",
  [SchoolDistrict.Taylor]: "Taylor ISD",
  [SchoolDistrict.Teague]: "Teague ISD",
  [SchoolDistrict.Temple]: "Temple ISD",
  [SchoolDistrict.Tenaha]: "Tenaha ISD",
  [SchoolDistrict.TerlinguCommonSchoolDistrict]:
    "Terlingu Common School District",
  [SchoolDistrict.TerrellCounty]: "Terrell County ISD",
  [SchoolDistrict.Terrell]: "Terrell ISD",
  [SchoolDistrict.Texarkana]: "Texarkana ISD",
  [SchoolDistrict.TexasCity]: "Texas City ISD",
  [SchoolDistrict.Texhoma]: "Texhoma ISD",
  [SchoolDistrict.Texline]: "Texline ISD",
  [SchoolDistrict.Thorndale]: "Thorndale ISD",
  [SchoolDistrict.Thrall]: "Thrall ISD",
  [SchoolDistrict.ThreeRivers]: "Three Rivers ISD",
  [SchoolDistrict.ThreeWay]: "Three Way ISD",
  [SchoolDistrict.Throckmorton]: "Throckmorton ISD",
  [SchoolDistrict.Tidehaven]: "Tidehaven ISD",
  [SchoolDistrict.Timpson]: "Timpson ISD",
  [SchoolDistrict.Tioga]: "Tioga ISD",
  [SchoolDistrict.Tolar]: "Tolar ISD",
  [SchoolDistrict.TomBean]: "Tom Bean ISD",
  [SchoolDistrict.Tomball]: "Tomball ISD",
  [SchoolDistrict.Tornillo]: "Tornillo ISD",
  [SchoolDistrict.Trent]: "Trent ISD",
  [SchoolDistrict.Trenton]: "Trenton ISD",
  [SchoolDistrict.Trinidad]: "Trinidad ISD",
  [SchoolDistrict.Trinity]: "Trinity ISD",
  [SchoolDistrict.Troup]: "Troup ISD",
  [SchoolDistrict.Troy]: "Troy ISD",
  [SchoolDistrict.Tulia]: "Tulia ISD",
  [SchoolDistrict.TulosoMidway]: "Tuloso-Midway ISD",
  [SchoolDistrict.TurkeyQuitaque]: "Turkey-Quitaque ISD",
  [SchoolDistrict.Tyler]: "Tyler ISD",
  [SchoolDistrict.UnionGrove]: "Union Grove ISD",
  [SchoolDistrict.United]: "United ISD",
  [SchoolDistrict.Utopia]: "Utopia ISD",
  [SchoolDistrict.UvaldeConsolidated]: "Uvalde Consolidated ISD",
  [SchoolDistrict.Valentine]: "Valentine ISD",
  [SchoolDistrict.ValleyMills]: "Valley Mills ISD",
  [SchoolDistrict.ValleyView]: "Valley View ISD",
  [SchoolDistrict.VanAlstyne]: "Van Alstyne ISD",
  [SchoolDistrict.Van]: "Van ISD",
  [SchoolDistrict.VanVleck]: "Van Vleck ISD",
  [SchoolDistrict.Vega]: "Vega ISD",
  [SchoolDistrict.Venus]: "Venus ISD",
  [SchoolDistrict.Veribest]: "Veribest ISD",
  [SchoolDistrict.Vernon]: "Vernon ISD",
  [SchoolDistrict.Victoria]: "Victoria ISD",
  [SchoolDistrict.Vidor]: "Vidor ISD",
  [SchoolDistrict.Vysehrad]: "Vysehrad ISD",
  [SchoolDistrict.Waco]: "Waco ISD",
  [SchoolDistrict.Waelder]: "Waelder ISD",
  [SchoolDistrict.Walcott]: "Walcott ISD",
  [SchoolDistrict.Wall]: "Wall ISD",
  [SchoolDistrict.Waller]: "Waller ISD",
  [SchoolDistrict.WalnutBend]: "Walnut Bend ISD",
  [SchoolDistrict.WalnutSprings]: "Walnut Springs ISD",
  [SchoolDistrict.Warren]: "Warren ISD",
  [SchoolDistrict.Waskom]: "Waskom ISD",
  [SchoolDistrict.WaterValley]: "Water Valley ISD",
  [SchoolDistrict.Waxahachie]: "Waxahachie ISD",
  [SchoolDistrict.Weatherford]: "Weatherford ISD",
  [SchoolDistrict.WebbConsolidated]: "Webb Consolidated ISD",
  [SchoolDistrict.Weimar]: "Weimar ISD",
  [SchoolDistrict.Wellington]: "Wellington ISD",
  [SchoolDistrict.WellmanUnionConsolidated]: "Wellman-Union Consolidated ISD",
  [SchoolDistrict.Wells]: "Wells ISD",
  [SchoolDistrict.Weslaco]: "Weslaco ISD",
  [SchoolDistrict.WestHardinCountyConsolidated]:
    "West Hardin County Consolidated ISD",
  [SchoolDistrict.West]: "West ISD",
  [SchoolDistrict.WestOrangeCoveConsolidated]:
    "West Orange-Cove Consolidated ISD",
  [SchoolDistrict.WestOso]: "West Oso ISD",
  [SchoolDistrict.WestRusk]: "West Rusk ISD",
  [SchoolDistrict.WestSabine]: "West Sabine ISD",
  [SchoolDistrict.Westbrook]: "Westbrook ISD",
  [SchoolDistrict.Westhoff]: "Westhoff ISD",
  [SchoolDistrict.Westphalia]: "Westphalia ISD",
  [SchoolDistrict.Westwood]: "Westwood ISD",
  [SchoolDistrict.Wharton]: "Wharton ISD",
  [SchoolDistrict.Wheeler]: "Wheeler ISD",
  [SchoolDistrict.WhiteDeer]: "White Deer ISD",
  [SchoolDistrict.WhiteOak]: "White Oak ISD",
  [SchoolDistrict.WhiteSettlement]: "White Settlement ISD",
  [SchoolDistrict.WhitefaceConsolidated]: "Whiteface Consolidated ISD",
  [SchoolDistrict.Whitehouse]: "Whitehouse ISD",
  [SchoolDistrict.Whitesboro]: "Whitesboro ISD",
  [SchoolDistrict.Whitewright]: "Whitewright ISD",
  [SchoolDistrict.Whitharral]: "Whitharral ISD",
  [SchoolDistrict.Whitney]: "Whitney ISD",
  [SchoolDistrict.WichitaFalls]: "Wichita Falls ISD",
  [SchoolDistrict.Wildorado]: "Wildorado ISD",
  [SchoolDistrict.Willis]: "Willis ISD",
  [SchoolDistrict.WillsPoint]: "Wills Point ISD",
  [SchoolDistrict.Wilson]: "Wilson ISD",
  [SchoolDistrict.Wimberley]: "Wimberley ISD",
  [SchoolDistrict.Windthorst]: "Windthorst ISD",
  [SchoolDistrict.Winfield]: "Winfield ISD",
  [SchoolDistrict.WinkLoving]: "Wink-Loving ISD",
  [SchoolDistrict.Winnsboro]: "Winnsboro ISD",
  [SchoolDistrict.Winona]: "Winona ISD",
  [SchoolDistrict.Winters]: "Winters ISD",
  [SchoolDistrict.Woden]: "Woden ISD",
  [SchoolDistrict.WolfeCity]: "Wolfe City ISD",
  [SchoolDistrict.Woodsboro]: "Woodsboro ISD",
  [SchoolDistrict.Woodson]: "Woodson ISD",
  [SchoolDistrict.Woodville]: "Woodville ISD",
  [SchoolDistrict.Wortham]: "Wortham ISD",
  [SchoolDistrict.Wylie]: "Wylie ISD",
  [SchoolDistrict.Yantis]: "Yantis ISD",
  [SchoolDistrict.Yoakum]: "Yoakum ISD",
  [SchoolDistrict.Yorktown]: "Yorktown ISD",
  [SchoolDistrict.Ysleta]: "Ysleta ISD",
  [SchoolDistrict.ZapataCounty]: "Zapata County ISD",
  [SchoolDistrict.Zavalla]: "Zavalla ISD",
  [SchoolDistrict.Zephyr]: "Zephyr ISD"
};
