export enum Foundation {
  Basement = "basement",
  Other = "other",
  Slab = "slab"
}
export const FoundationLabel = new Map<Foundation, string>([
  [Foundation.Basement, "Basement"],
  [Foundation.Other, "Other"],
  [Foundation.Slab, "Slab"]
]);
