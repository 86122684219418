export enum KitchenDescription {
  BreakfastBar = "breakfastBar",
  InstantHotWater = "instantHotWater",
  IslandWithCooktop = "islandWithCooktop",
  IslandWithoutCooktop = "islandWithoutCooktop",
  KitchenOpenFamilyRoom = "kitchenOpenFamilyRoom",
  Pantry = "pantry",
  PotFiller = "potFiller",
  PotsPansDrawers = "potsPansDrawers",
  ReverseOsmosis = "reverseOsmosis",
  SecondSink = "secondSink",
  SoftClosingCabinets = "softClosingCabinets",
  SoftClosingDrawers = "softClosingDrawers",
  UnderCabinetLighting = "underCabinetLighting",
  WalkInPantry = "walkInPantry"
}
export const KitchenDescriptionLabel = new Map<KitchenDescription, string>([
  [KitchenDescription.BreakfastBar, "Breakfast Bar"],
  [KitchenDescription.InstantHotWater, "Instant Hot Water"],
  [KitchenDescription.IslandWithCooktop, "Island w/ Cooktop"],
  [KitchenDescription.IslandWithoutCooktop, "Island w/o Cooktop"],
  [KitchenDescription.KitchenOpenFamilyRoom, "Kitchen open to Family Room"],
  [KitchenDescription.Pantry, "Pantry"],
  [KitchenDescription.PotFiller, "Pot Filler"],
  [KitchenDescription.PotsPansDrawers, "Pots/Pans Drawers"],
  [KitchenDescription.ReverseOsmosis, "Reverse Osmosis"],
  [KitchenDescription.SecondSink, "Second Sink"],
  [KitchenDescription.SoftClosingCabinets, "Soft Closing Cabinets"],
  [KitchenDescription.SoftClosingDrawers, "Soft Closing Drawers"],
  [KitchenDescription.UnderCabinetLighting, "Under Cabinet Lighting"],
  [KitchenDescription.WalkInPantry, "Walk-in Pantry"]
]);
