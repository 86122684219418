import React, { Fragment } from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

import { Modal } from "./modal.component";

export function DeleteIconButton({
  onClick,
  text,
  modalBody
}: {
  onClick: () => Promise<void>;
  text: string;
  modalBody: string | React.ReactNode;
}): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <Fragment>
      <Tooltip title={text}>
        <IconButton onClick={(): void => setShowModal(true)} aria-label={text}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={showModal}
        onClose={(): void => setShowModal(false)}
        title={text}
        actionButton={{ method: onClick, text }}
      >
        {modalBody}
      </Modal>
    </Fragment>
  );
}
