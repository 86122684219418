export enum BillingFrequency {
  Annually = "annually",
  Monthly = "monthly",
  Quarterly = "quarterly",
  SemiAnnually = "semiAnnually"
}
export const BillingFrequencyLabel = new Map<BillingFrequency, string>([
  [BillingFrequency.Annually, "Annually"],
  [BillingFrequency.Monthly, "Monthly"],
  [BillingFrequency.Quarterly, "Quarterly"],
  [BillingFrequency.SemiAnnually, "Semi-Annually"]
]);
