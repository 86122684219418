export enum SchoolDistrict {
  Academy = "academy",
  Austin = "austin",
  Bartlett = "bartlett",
  Bastrop = "bastrop",
  Belton = "belton",
  Blanco = "blanco",
  Boerne = "boerne",
  BrucevilleEddy = "brucevilleEddy",
  Buckholts = "buckholts",
  Burnet = "burnet",
  Cameron = "cameron",
  Cherokee = "cherokee",
  Comal = "comal",
  CopperasCove = "copperasCove",
  Coupland = "coupland",
  Cuero = "cuero",
  DelValle = "delValle",
  DimeBox = "dimeBox",
  Doss = "doss",
  DrippingSprings = "drippingSprings",
  Eanes = "eanes",
  Elgin = "elgin",
  Evant = "evant",
  Fayetteville = "fayetteville",
  Flatonia = "flatonia",
  Florence = "florence",
  Fredericksburg = "fredericksburg",
  Gatesville = "gatesville",
  Gause = "gause",
  Georgetown = "georgetown",
  Giddings = "giddings",
  Gonzales = "gonzales",
  Granger = "granger",
  Harper = "harper",
  Hays = "hays",
  Holland = "holland",
  Hutto = "hutto",
  Jarrell = "jarrell",
  JohnsonCity = "johnsonCity",
  Killeen = "killeen",
  LagoVista = "lagoVista",
  LaGrange = "laGrange",
  LakeTravis = "lakeTravis",
  Lampasas = "lampasas",
  LaVernia = "laVernia",
  Leander = "leander",
  Lexington = "lexington",
  LibertyHill = "libertyHill",
  Llano = "llano",
  Lockhart = "lockhart",
  Lometa = "lometa",
  Luling = "luling",
  Manor = "manor",
  MarbleFalls = "marbleFalls",
  Marion = "marion",
  Mason = "mason",
  McDade = "mcDade",
  Milano = "milano",
  Moody = "moody",
  Moulton = "moulton",
  Navarro = "navarro",
  NewBraunfels = "newBraunfels",
  NixonSmileyCons = "nixonSmileyCons",
  Other = "other",
  Pflugerville = "pflugerville",
  PrairieLea = "prairieLea",
  RichlandSprings = "richlandSprings",
  Rockdale = "rockdale",
  Rogers = "rogers",
  RosebudLott = "rosebudLott",
  RoundRock = "roundRock",
  RoundTopCarmine = "roundTopCarmine",
  Salado = "salado",
  SanMarcos = "sanMarcos",
  SanSaba = "sanSaba",
  SchertzCiboloUniversal = "schertzCiboloUniversal",
  Schulenburg = "schulenburg",
  Seguin = "seguin",
  Shiner = "shiner",
  Smithville = "smithville",
  Star = "star",
  Taylor = "taylor",
  Temple = "temple",
  Thorndale = "thorndale",
  Thrall = "thrall",
  Troy = "troy",
  Waelder = "waelder",
  Weimar = "weimar",
  Wimberley = "wimberley",
  Yoakum = "yoakum"
}

export const SchoolDistrictLabel = new Map<SchoolDistrict, string>([
  [SchoolDistrict.Academy, "Academy ISD"],
  [SchoolDistrict.Austin, "Austin ISD"],
  [SchoolDistrict.Bartlett, "Bartlett ISD"],
  [SchoolDistrict.Bastrop, "Bastrop ISD"],
  [SchoolDistrict.Belton, "Belton ISD"],
  [SchoolDistrict.Blanco, "Blanco ISD"],
  [SchoolDistrict.Boerne, "Boerne ISD"],
  [SchoolDistrict.BrucevilleEddy, "Bruceville-Eddy ISD"],
  [SchoolDistrict.Buckholts, "Buckholts ISD"],
  [SchoolDistrict.Burnet, "Burnet ISD"],
  [SchoolDistrict.Cameron, "Cameron ISD"],
  [SchoolDistrict.Cherokee, "Cherokee ISD"],
  [SchoolDistrict.Comal, "Comal ISD"],
  [SchoolDistrict.CopperasCove, "Copperas Cove ISD"],
  [SchoolDistrict.Coupland, "Coupland ISD"],
  [SchoolDistrict.Cuero, "Cuero ISD"],
  [SchoolDistrict.DelValle, "Del Valle ISD"],
  [SchoolDistrict.DimeBox, "Dime Box ISD"],
  [SchoolDistrict.Doss, "Doss ISD"],
  [SchoolDistrict.DrippingSprings, "Dripping Springs ISD"],
  [SchoolDistrict.Eanes, "Eanes ISD"],
  [SchoolDistrict.Elgin, "Elgin ISD"],
  [SchoolDistrict.Evant, "Evant ISD"],
  [SchoolDistrict.Fayetteville, "Fayetteville ISD"],
  [SchoolDistrict.Flatonia, "Flatonia ISD"],
  [SchoolDistrict.Florence, "Florence ISD"],
  [SchoolDistrict.Fredericksburg, "Fredericksburg ISD"],
  [SchoolDistrict.Gatesville, "Gatesville ISD"],
  [SchoolDistrict.Gause, "Gause ISD"],
  [SchoolDistrict.Georgetown, "Georgetown ISD"],
  [SchoolDistrict.Giddings, "Giddings ISD"],
  [SchoolDistrict.Gonzales, "Gonzales ISD"],
  [SchoolDistrict.Granger, "Granger ISD"],
  [SchoolDistrict.Harper, "Harper ISD"],
  [SchoolDistrict.Hays, "Hays CISD"],
  [SchoolDistrict.Holland, "Holland ISD"],
  [SchoolDistrict.Hutto, "Hutto ISD"],
  [SchoolDistrict.Jarrell, "Jarrell ISD"],
  [SchoolDistrict.JohnsonCity, "Johnson City ISD"],
  [SchoolDistrict.Killeen, "Killeen ISD"],
  [SchoolDistrict.LagoVista, "Lago Vista ISD"],
  [SchoolDistrict.LaGrange, "La Grange ISD"],
  [SchoolDistrict.LakeTravis, "Lake Travis ISD"],
  [SchoolDistrict.Lampasas, "Lampasas ISD"],
  [SchoolDistrict.LaVernia, "La Vernia ISD"],
  [SchoolDistrict.Leander, "Leander ISD"],
  [SchoolDistrict.Lexington, "Lexington ISD"],
  [SchoolDistrict.LibertyHill, "Liberty Hill ISD"],
  [SchoolDistrict.Llano, "Llano ISD"],
  [SchoolDistrict.Lockhart, "Lockhart ISD"],
  [SchoolDistrict.Lometa, "Lometa ISD"],
  [SchoolDistrict.Luling, "Luling ISD"],
  [SchoolDistrict.Manor, "Manor ISD"],
  [SchoolDistrict.MarbleFalls, "Marble Falls ISD"],
  [SchoolDistrict.Marion, "Marion ISD"],
  [SchoolDistrict.Mason, "Mason ISD"],
  [SchoolDistrict.McDade, "McDade ISD"],
  [SchoolDistrict.Milano, "Milano ISD"],
  [SchoolDistrict.Moody, "Moody ISD"],
  [SchoolDistrict.Moulton, "Moulton ISD"],
  [SchoolDistrict.Navarro, "Navarro ISD"],
  [SchoolDistrict.NewBraunfels, "New Braunfels ISD"],
  [SchoolDistrict.NixonSmileyCons, "Nixon-Smiley Cons ISD"],
  [SchoolDistrict.Other, "Other"],
  [SchoolDistrict.Pflugerville, "Pflugerville ISD"],
  [SchoolDistrict.PrairieLea, "Prairie Lea ISD"],
  [SchoolDistrict.RichlandSprings, "Richland Springs ISD"],
  [SchoolDistrict.Rockdale, "Rockdale ISD"],
  [SchoolDistrict.Rogers, "Rogers ISD"],
  [SchoolDistrict.RosebudLott, "Rosebud-Lott ISD"],
  [SchoolDistrict.RoundRock, "Round Rock ISD"],
  [SchoolDistrict.RoundTopCarmine, "Round Top-Carmine ISD"],
  [SchoolDistrict.Salado, "Salado ISD"],
  [SchoolDistrict.SanMarcos, "San Marcos ISD"],
  [SchoolDistrict.SanSaba, "San Saba ISD"],
  [SchoolDistrict.SchertzCiboloUniversal, "Schertz-Cibolo-Universal ISD"],
  [SchoolDistrict.Schulenburg, "Schulenburg ISD"],
  [SchoolDistrict.Seguin, "Seguin ISD"],
  [SchoolDistrict.Shiner, "Shiner ISD"],
  [SchoolDistrict.Smithville, "Smithville ISD"],
  [SchoolDistrict.Star, "Star ISD"],
  [SchoolDistrict.Taylor, "Taylor ISD"],
  [SchoolDistrict.Temple, "Temple ISD"],
  [SchoolDistrict.Thorndale, "Thorndale ISD"],
  [SchoolDistrict.Thrall, "Thrall ISD"],
  [SchoolDistrict.Troy, "Troy ISD"],
  [SchoolDistrict.Waelder, "Waelder ISD"],
  [SchoolDistrict.Weimar, "Weimar ISD"],
  [SchoolDistrict.Wimberley, "Wimberley ISD"],
  [SchoolDistrict.Yoakum, "Yoakum ISD"]
]);
