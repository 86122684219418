export enum RestrictionsType {
  Adult55 = "adult55",
  CityRestrictions = "cityRestrictions",
  CovenantDeed = "covenantDeed",
  Senior = "senior",
  Subdivision = "subdivision",
  None = "none"
}
export const RestrictionsTypeLabel = new Map<RestrictionsType, string>([
  [RestrictionsType.Adult55, "Adult 55+"],
  [RestrictionsType.CityRestrictions, "City Restrictions"],
  [RestrictionsType.CovenantDeed, "Covenant/Deed"],
  [RestrictionsType.Senior, "Senior"],
  [RestrictionsType.Subdivision, "Subdivision"],
  [RestrictionsType.None, "None"]
]);
