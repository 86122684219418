export enum RoofDescription {
  BuiltUp = "builtUp",
  Composition = "composition",
  Concrete = "concrete",
  FiberCement = "fiberCement",
  Metal = "metal",
  Othe = "othe",
  Shake = "shake",
  Tile = "tile",
  Wood = "wood",
  Shingle = "shingle"
}

export const RoofDescriptionLabel = new Map<RoofDescription, string>([
  [RoofDescription.BuiltUp, "Built-Up"],
  [RoofDescription.Composition, "Composition"],
  [RoofDescription.Concrete, "Concrete"],
  [RoofDescription.FiberCement, "Fiber Cement"],
  [RoofDescription.Metal, "Metal"],
  [RoofDescription.Othe, "Other"],
  [RoofDescription.Shake, "Shake"],
  [RoofDescription.Tile, "Tile"],
  [RoofDescription.Wood, "Wood"],
  [RoofDescription.Shingle, "Shingle"]
]);
