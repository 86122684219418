export enum CoolingSystem {
  CentralElectric = "centralElectric",
  CentralGas = "centralGas",
  HeatPump = "heatPump",
  Zoned = "zoned",
  SolarAssisted = "solarAssisted",
  OtherCooling = "otherCooling"
}

export const CoolingSystemLabel = new Map<CoolingSystem, string>([
  [CoolingSystem.CentralElectric, "Central Electric"],
  [CoolingSystem.CentralGas, "Central Gas"],
  [CoolingSystem.HeatPump, "Heat Pump"],
  [CoolingSystem.Zoned, "Zoned"],
  [CoolingSystem.SolarAssisted, "Solar Assisted"],
  [CoolingSystem.OtherCooling, "Other Cooling"]
]);
