export enum LockboxType {
  Codebox = "codebox",
  Combo = "combo",
  KeyWoffice = "keyWoffice",
  Supra = "supra",
  None = "none"
}

export const LockboxTypeLabel = new Map<LockboxType, string>([
  [LockboxType.Codebox, "Codebox"],
  [LockboxType.Combo, "Combo"],
  [LockboxType.KeyWoffice, "Key w/office"],
  [LockboxType.Supra, "Supra"],
  [LockboxType.None, "None"]
]);
