export enum BillingFrequency {
  Monthly = "monthly",
  Quarterly = "quarterly",
  BiAnnually = "biannually",
  Yearly = "yearly",
  Nomeetings = "noMeetings"
}

export const BillingFrequencyLabel: Record<BillingFrequency, string> = {
  [BillingFrequency.Monthly]: "Monthly",
  [BillingFrequency.Quarterly]: "Quarterly",
  [BillingFrequency.BiAnnually]: "Bi-Annually",
  [BillingFrequency.Yearly]: "Yearly",
  [BillingFrequency.Nomeetings]: "No Meetings"
};
