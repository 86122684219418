export enum LaundryLocation {
  Basement = "basement",
  Bathroom = "bathroom",
  Closet = "closet",
  Garage = "garage",
  Kitchen = "kitchen",
  UtilityRoom = "utilityRoom",
  None = "none",
  OtherLocation = "otherLocation"
}

export const LaundryLocationLabel: Record<LaundryLocation, string> = {
  [LaundryLocation.Basement]: "Basement",
  [LaundryLocation.Bathroom]: "Bathroom",
  [LaundryLocation.Closet]: "Closet",
  [LaundryLocation.Garage]: "Garage",
  [LaundryLocation.Kitchen]: "Kitchen",
  [LaundryLocation.UtilityRoom]: "Utility Room",
  [LaundryLocation.None]: "None",
  [LaundryLocation.OtherLocation]: "Other Location"
};
