export enum RoomLevel {
  First = "first",
  Second = "second",
  Third = "third",
  Basement = "basement",
  Main = "main",
  Lower = "lower",
  Upper = "upper"
}

export const RoomLevelLabel = new Map<RoomLevel, string>([
  [RoomLevel.First, "First"],
  [RoomLevel.Second, "Second"],
  [RoomLevel.Third, "Third"],
  [RoomLevel.Basement, "Basement"],
  [RoomLevel.Main, "Main"],
  [RoomLevel.Lower, "Lower"],
  [RoomLevel.Upper, "Upper"]
]);
