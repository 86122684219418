export enum GarageDescription {
  TwoCarsDoubleDoor = "twoCarsDoubleDoor",
  TwoCarsSingleDoor = "twoCarsSingleDoor",
  AreaAssigned = "areaAssigned",
  CircularDrive = "circularDrive",
  Detached = "detached",
  ElecVehicleChargingStations = "elecVehicleChargingStations",
  ElectricGate = "electricGate",
  EpoxyFlooring = "epoxyFlooring",
  GarageDoorOpener = "garageDoorOpener",
  GarageFacesFront = "garageFacesFront",
  GarageFacesRear = "garageFacesRear",
  GarageFacesSide = "garageFacesSide",
  Gated = "gated",
  None = "non",
  OnStreet = "onStreet",
  Other = "other",
  OutsideEntry = "outsideEntry",
  Oversized = "oversized",
  SideBySide = "sideBySide",
  TandemStyle = "tandemStyle"
}

export const GarageDescriptionLabel = new Map<GarageDescription, string>([
  [GarageDescription.TwoCarsDoubleDoor, "Garage Double Door"],
  [GarageDescription.TwoCarsSingleDoor, "Garage Single Door"],
  [GarageDescription.AreaAssigned, "Area Assigned"],
  [GarageDescription.CircularDrive, "Circle Drive"],
  [GarageDescription.Detached, "Detached"],
  [
    GarageDescription.ElecVehicleChargingStations,
    "Elec Vehicle Charging Station(s)"
  ],
  [GarageDescription.ElectricGate, "Electric Gate"],
  [GarageDescription.EpoxyFlooring, "Epoxy Flooring"],
  [GarageDescription.GarageDoorOpener, "Garage Door Opener"],
  [GarageDescription.GarageFacesFront, "Garage Faces Front"],
  [GarageDescription.GarageFacesRear, "Garage Faces Rear"],
  [GarageDescription.GarageFacesSide, "Garage Faces Side"],
  [GarageDescription.Gated, "Gated"],
  [GarageDescription.None, "None"],
  [GarageDescription.OnStreet, "On Street"],
  [GarageDescription.Other, "Other"],
  [GarageDescription.OutsideEntry, "Outside Entry"],
  [GarageDescription.Oversized, "Oversized"],
  [GarageDescription.SideBySide, "Side By Side"],
  [GarageDescription.TandemStyle, "Tandem Style"]
]);
