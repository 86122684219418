export enum ConstructionStage {
  Complete = "completeConstruction",
  ToBeBuilt = "toBeBuilt",
  UnderConstruction = "underConstruction"
}

export const ConstructionStageLabel = new Map<ConstructionStage, string>([
  [ConstructionStage.Complete, "Complete Construction"],
  [ConstructionStage.ToBeBuilt, "To Be Built"],
  [ConstructionStage.UnderConstruction, "Under Construction"]
]);
