import React from "react";
import { Grid } from "@mui/material";

import {
  GridTableContext,
  FetchData,
  getSortColumn
} from "@homesusa/grid-table";
import {
  FormContext,
  Select,
  DefaultFormContextProps,
  DatePicker
} from "@homesusa/form";
import { AuthCompanyContext } from "@homesusa/auth";
import { BillActionTypeFilter, BillActionTypeFilterLabel } from "core/enums";
import { BillFilter } from "../interfaces/filters";
import { Filters } from "./filters.component";
import { getOptionsFromEnumAndMap } from "@homesusa/core";

function BillingReportFilters({
  onFetch
}: {
  onFetch: (props: FetchData) => Promise<void>;
}): JSX.Element {
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const { formDispatch, formState } =
    React.useContext<DefaultFormContextProps<BillFilter>>(FormContext);
  const {
    instance: {
      state: { pageSize, pageIndex, sortBy, globalFilter }
    },
    setShowLoader
  } = React.useContext(GridTableContext);

  React.useEffect(() => {
    if (!formDispatch) {
      return;
    }
    formDispatch({
      type: "FormChangeByInputName",
      inputName: "companyId",
      payload: currentCompany?.id
    });
  }, [currentCompany]);

  React.useEffect(() => {
    setShowLoader(true);
    onFetch({
      pageSize,
      pageIndex,
      sortBy: getSortColumn(sortBy),
      globalFilter
    }).finally(() => setShowLoader(false));
  }, [formState.companyId]);

  const formDispatchByName = (inputName: string, payload?: unknown): void => {
    if (!formDispatch) {
      return;
    }
    formDispatch({
      type: "FormChangeByInputName",
      inputName,
      payload
    });
  };

  return (
    <Filters onFetch={onFetch}>
      <Grid item xs={4} sm={3} md={2}>
        <Select
          label="Action Type"
          name="actionType"
          options={getOptionsFromEnumAndMap(
            Object.values(BillActionTypeFilter),
            BillActionTypeFilterLabel
          )}
          fullWidth
          defaultValue={BillActionTypeFilter.All}
          sort={false}
        />
      </Grid>
      <Grid item xs={4} sm={3} md={2}>
        <DatePicker
          name="from"
          label="From"
          defaultValue={formState.from}
          onChange={(value): void => {
            formDispatchByName(
              "from",
              value
                ? new Date(
                    Date.UTC(
                      value.getFullYear(),
                      value.getMonth(),
                      value.getDate(),
                      0,
                      0,
                      0,
                      0
                    )
                  )
                : undefined
            );
          }}
        />
      </Grid>
      <Grid item xs={4} sm={3} md={2}>
        <DatePicker
          name="to"
          label="To"
          defaultValue={formState.to}
          onChange={(value): void => {
            formDispatchByName(
              "to",
              value
                ? new Date(
                    Date.UTC(
                      value.getFullYear(),
                      value.getMonth(),
                      value.getDate(),
                      23,
                      59,
                      59,
                      999
                    )
                  )
                : undefined
            );
          }}
        />
      </Grid>
    </Filters>
  );
}

export { BillingReportFilters };
