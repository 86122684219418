import { MarketCode } from "@homesusa/core";

export const getGatewayMarket = (marketCode: MarketCode): string => {
  switch (marketCode) {
    case MarketCode.Sabor:
      return "sabor";
    default:
      return marketCode;
  }
};
