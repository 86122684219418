import React, { Context, Fragment } from "react";
import { IconButton, Tooltip, Stack } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { cloneDeep } from "lodash";

import { PersistentRightModal } from "@homesusa/core";
import { SubmitButton } from "@homesusa/layout";
import { FormContext } from "@homesusa/form";
import { EntityContext } from "core/interfaces";
import { ListingSearch } from "./listing-search.component";

export function ImportFromListingButton<
  TEntity extends { id: string },
  TEntityContext extends EntityContext<TEntity>
>({
  entityContext,
  importFromListing
}: {
  entityContext: Context<TEntityContext>;
  importFromListing: (entityId: string, listingId: string) => Promise<TEntity>;
}): JSX.Element {
  const {
    entity: { id }
  } = React.useContext(entityContext);
  const {
    formDispatch,
    otherProps: { refreshForm }
  } = React.useContext(FormContext);
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const [listingId, setListingId] = React.useState<string>();
  const title = "Import From Listing";

  const overrideState = (response: TEntity): void => {
    setShowAddModal(false);
    if (formDispatch) {
      formDispatch({
        type: "OverrideState",
        payload: cloneDeep(response)
      });
    }
    if (refreshForm) {
      refreshForm();
    }
  };

  return (
    <Fragment>
      <Tooltip title={title}>
        <IconButton
          onClick={(): void => setShowAddModal(true)}
          aria-label={title}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>

      <PersistentRightModal
        title={title}
        open={showAddModal}
        onChange={(value): void => setShowAddModal(value)}
      >
        <Stack spacing={1}>
          <ListingSearch onSelect={(id): void => setListingId(id)} />
          {listingId != null && listingId !== "" && (
            <SubmitButton
              onClick={async (): Promise<void> => {
                const response = await importFromListing(id, listingId);
                if (response) {
                  overrideState(response);
                }
              }}
            >
              Import
            </SubmitButton>
          )}
        </Stack>
      </PersistentRightModal>
    </Fragment>
  );
}
