export enum HowSold {
  FirstSeller = "firstSeller",
  SecondSeller = "secondSeller",
  Assumable = "assumable",
  Carry = "carry",
  Cash = "cash",
  ContractForDeed = "contractForDeed",
  Conventional = "conventional",
  ConventionalTexasVet = "conventionalTexasVet",
  FHA = "fha",
  FHATexasVet = "fhaTexasVet",
  Other = "other",
  USDA = "usda",
  VA = "va",
  VATexasVet = "vaTexasVet",
  Wrap = "wrap"
}

export const HowSoldLabel = new Map<HowSold, string>([
  [HowSold.FirstSeller, "1st Seller"],
  [HowSold.SecondSeller, "2nd Seller"],
  [HowSold.Assumable, "Assumable"],
  [HowSold.Carry, "Carry"],
  [HowSold.Cash, "Cash"],
  [HowSold.ContractForDeed, "Contract for Deed"],
  [HowSold.Conventional, "Conventional"],
  [HowSold.ConventionalTexasVet, "Conventional/Texas Vet"],
  [HowSold.FHA, "FHA"],
  [HowSold.FHATexasVet, "FHA/Texas Vet"],
  [HowSold.Other, "Other"],
  [HowSold.USDA, "USDA"],
  [HowSold.VA, "VA"],
  [HowSold.VATexasVet, "VA/Texas Vet"],
  [HowSold.Wrap, "Wrap"]
]);
