import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";
import { LotSize, LotSizeLabel } from "markets/har/enums";

import {
  constructionStageRules,
  constructionStartYearRules,
  lotDescriptionRules,
  lotSizeSourceRules,
  mlsAreaRules,
  mapscoGridRules,
  legalSubdivisionRules,
  housingTypeRules,
  propertyTypeRules,
  isPlannedCommunityRules,
  plannedCommunityRules
} from "../../sections/property-rules";

export const propertyInfoRules: FormRules = {
  constructionCompletionDate: {
    label: "Estimated Completion Date",
    tooltip: "Estimated completion date where applicable."
  },
  constructionStartYear: constructionStartYearRules,
  constructionStage: constructionStageRules,
  legalDescription: {
    requiredToSubmit: true,
    label: "Legal Description",
    maxLength: 255
  },
  updateGeocodes: { label: "Update Geocodes" },
  latitude: latitudeRule,
  longitude: longitudeRule,
  lotSizeSqft: {
    label: "Lot Size (SQFT)",
    maxLength: 5
  },
  lotDimension: {
    label: "Lot Dimensions",
    maxLength: 25
  },
  lotSize: {
    label: "Acreage",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(LotSize), LotSizeLabel),
    sort: false
  },
  lotDescription: lotDescriptionRules,
  lotSizeSource: lotSizeSourceRules,
  taxId: { label: "Tax ID #", maxLength: 50 },
  mlsArea: mlsAreaRules,
  mapscoGrid: mapscoGridRules,
  sectionNum: {
    label: "Section #",
    requiredToSubmit: true
  },
  legalSubdivision: legalSubdivisionRules,
  housingType: housingTypeRules,
  propertyType: propertyTypeRules,
  isPlannedCommunity: isPlannedCommunityRules,
  plannedCommunity: plannedCommunityRules
};
