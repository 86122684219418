import React from "react";

export const useGetEntity = <D>(
  getEntityService: (id: string) => Promise<D>,
  entityId: string
): [
  entity: D | undefined,
  getEntity: (forceRefresh?: boolean) => Promise<D>
] => {
  const [entity, setEntity] = React.useState<D>();

  const getEntity = React.useCallback(
    async (forceRefresh = false) => {
      if (forceRefresh) {
        setEntity(undefined);
      }
      return getEntityService(entityId).then((data) => {
        setEntity(data);
        return data;
      });
    },
    [entityId]
  );

  return [entity, getEntity];
};
