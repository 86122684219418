export enum RangeDescription {
  Grill = "grill",
  ElectricCooktop = "electricCooktop",
  GasCooktop = "gasCooktop",
  FreestandingRange = "freestandingRange",
  GasRange = "gasRange",
  ElectricRange = "electricRange"
}

export const RangeDescriptionLabel = new Map<RangeDescription, string>([
  [RangeDescription.Grill, "Grill"],
  [RangeDescription.ElectricCooktop, "Electric Cooktop"],
  [RangeDescription.GasCooktop, "Gas Cooktop"],
  [RangeDescription.FreestandingRange, "Freestanding Range"],
  [RangeDescription.GasRange, "Gas Range"],
  [RangeDescription.ElectricRange, "Electric Range"]
]);
