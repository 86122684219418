import { useTheme } from "@mui/material";
import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  textContainers: SxProps;
  title: SxProps;
  value: SxProps;
  icon: SxProps;
  subtitle: SxProps;
}

export const useStyles = (): Styles => {
  const { palette } = useTheme();
  return {
    root: {
      display: "flex",
      columnGap: "16px",
      padding: "8px 16px",
      boxSizing: "border-box",
      alignItems: "center",
      position: "relative",
      color: palette.text.secondary,
      "&.alert": {
        backgroundColor: palette.error.main,
        color: "white",
        "&:hover": { background: "#731616" }
      }
    },
    textContainers: {
      display: "flex",
      flexDirection: "column",
      rowGap: "8px"
    },
    title: {
      fontSize: "13px",
      fontWeight: "bold"
    },
    value: {
      fontSize: "40px",
      fontWeight: "bold"
    },
    icon: {
      position: "absolute",
      right: "8px",
      top: "8px"
    },
    subtitle: {
      fontSize: "11px"
    }
  };
};
