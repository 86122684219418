export enum States {
  Alabama = "alabama",
  Alaska = "alaska",
  Arkansas = "arkansas",
  Arizona = "arizona",
  California = "california",
  Colorado = "colorado",
  Connecticut = "connecticut",
  DC = "dC",
  Delaware = "delaware",
  Florida = "florida",
  Georgia = "georgia",
  Hawaii = "hawaii",
  Iowa = "iowa",
  Idaho = "idaho",
  Illinois = "illinois",
  Indiana = "indiana",
  Kansas = "kansas",
  Kentucky = "kentucky",
  Louisiana = "louisiana",
  Massachusetts = "massachusetts",
  Maryland = "maryland",
  Maine = "maine",
  Michigan = "michigan",
  Minnesota = "minnesota",
  Missouri = "missouri",
  Mississippi = "mississippi",
  Montana = "montana",
  NorthCarolina = "northCarolina",
  NorthDakota = "northDakota",
  Nebraska = "nebraska",
  NewHampshire = "newHampshire",
  NewJersey = "newJersey",
  NewMexico = "newMexico",
  Nevada = "nevada",
  NewYork = "newYork",
  Oklahoma = "oklahoma",
  Ohio = "ohio",
  Oregon = "oregon",
  Pennsylvania = "pennsylvania",
  RhodeIsland = "rhodeIsland",
  SouthCarolina = "southCarolina",
  SouthDakota = "southDakota",
  Tennessee = "tennessee",
  Texas = "texas",
  Utah = "utah",
  Virginia = "virginia",
  Vermont = "vermont",
  Washington = "washington",
  Wisconsin = "wisconsin",
  WestVirginia = "westVirginia",
  Wyoming = "wyoming"
}

export const StatesLabel: Record<States, string> = {
  [States.Alabama]: "Alabama",
  [States.Alaska]: "Alaska",
  [States.Arkansas]: "Arkansas",
  [States.Arizona]: "Arizona",
  [States.California]: "California",
  [States.Colorado]: "Colorado",
  [States.Connecticut]: "Connecticut",
  [States.DC]: "D.C.",
  [States.Delaware]: "Delaware",
  [States.Florida]: "Florida",
  [States.Georgia]: "Georgia",
  [States.Hawaii]: "Hawaii",
  [States.Iowa]: "Iowa",
  [States.Idaho]: "Idaho",
  [States.Illinois]: "Illinois",
  [States.Indiana]: "Indiana",
  [States.Kansas]: "Kansas",
  [States.Kentucky]: "Kentucky",
  [States.Louisiana]: "Louisiana",
  [States.Massachusetts]: "Massachusetts",
  [States.Maryland]: "Maryland",
  [States.Maine]: "Maine",
  [States.Michigan]: "Michigan",
  [States.Minnesota]: "Minnesota",
  [States.Missouri]: "Missouri",
  [States.Mississippi]: "Mississippi",
  [States.Montana]: "Montana",
  [States.NorthCarolina]: "North Carolina",
  [States.NorthDakota]: "North Dakota",
  [States.Nebraska]: "Nebraska",
  [States.NewHampshire]: "New Hampshire",
  [States.NewJersey]: "New Jersey",
  [States.NewMexico]: "New Mexico",
  [States.Nevada]: "Nevada",
  [States.NewYork]: "New York",
  [States.Oklahoma]: "Oklahoma",
  [States.Ohio]: "Ohio",
  [States.Oregon]: "Oregon",
  [States.Pennsylvania]: "Pennsylvania",
  [States.RhodeIsland]: "Rhode Island",
  [States.SouthCarolina]: "South Carolina",
  [States.SouthDakota]: "South Dakota",
  [States.Tennessee]: "Tennessee",
  [States.Texas]: "Texas",
  [States.Utah]: "Utah",
  [States.Virginia]: "Virginia",
  [States.Vermont]: "Vermont",
  [States.Washington]: "Washington",
  [States.Wisconsin]: "Wisconsin",
  [States.WestVirginia]: "West Virginia",
  [States.Wyoming]: "Wyoming"
};
