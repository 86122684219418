import React from "react";
import { SxProps, Typography } from "@mui/material";

import { FormContext } from "@homesusa/form";
import { SectionBox } from "@homesusa/core";

export const SectionBoxWithError = ({
  customError,
  children,
  ...sectionBoxProps
}: {
  customError: string;
  title: string;
  children: React.ReactNode;
  sx?: SxProps;
}): JSX.Element => {
  const { formErrors } = React.useContext(FormContext);

  const sectionErrors = React.useMemo(
    () => (!!formErrors ? formErrors[customError]?.errors : undefined),
    [formErrors]
  );

  return (
    <SectionBox {...sectionBoxProps} hasError={!!sectionErrors}>
      {children}
      {!!sectionErrors && sectionErrors.length > 0 && (
        <Typography
          variant="caption"
          color="error"
          sx={{ mt: 2, display: "block" }}
        >
          {sectionErrors.join("/ ")}
        </Typography>
      )}
    </SectionBox>
  );
};
