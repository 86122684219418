import { FormRules } from "@homesusa/form";
import { showingRules } from "../../sections";

export const showingInfoRules: FormRules = {
  ...showingRules,
  agentPrivateRemarks: {
    label: "Agent or Private Remarks",
    maxLength: 1000,
    rows: 5
  },
  agentPrivateRemarksAdditional: {
    label: "Agent or Private Remarks Additional Information",
    maxLength: 1000,
    rows: 5
  },
  enableOpenHouses: {
    label: "Enable Open House Feature"
  },
  showOpenHousesPending: {
    label: "Show Open House if this listing is PENDING?"
  },
  lockBoxSerialNumber: {
    label: "Lock Box Serial Number"
  },
  showingInstructions: {
    ...showingRules.showingInstructions,
    minLengthSuggestion: 25
  }
};
