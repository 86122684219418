import React from "react";

import { AppContext } from "@homesusa/layout";
import { createInvoice } from "modules/reports/services/quicklister.service";
import { MarketCode } from "@homesusa/core";
import { AuthCompanyContext } from "@homesusa/auth";

export const useCreateInvoice = (
  selectedIds: string[],
  currentMarket: MarketCode,
  from?: Date,
  to?: Date
): (() => Promise<void>) => {
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const { addAlert } = React.useContext(AppContext);

  const onSave = async (): Promise<void> => {
    return createInvoice(
      selectedIds,
      currentMarket,
      from,
      to,
      currentCompany?.id
    ).then((id: string) => {
      addAlert({
        message: `Invoice was successfully created under id: ${id}`,
        variant: "success"
      });
    });
  };

  return onSave;
};
