import { FormRules, getFieldErrors, getValueByPath } from "@homesusa/form";

export const getRoomSaveErrors = <
  TRoomType,
  TRoom extends { roomType: TRoomType },
  TRoomTypeLabel extends Map<TRoomType, string>
>(
  rooms: TRoom[],
  roomRules: FormRules,
  roomTypeLabel: TRoomTypeLabel
): string[] => {
  const errors: string[] = rooms.reduce((current, room) => {
    const errorsByType = Object.entries(roomRules).reduce(
      (currentError, [inputName, rules]) => {
        const value = getValueByPath(room, inputName);
        const fieldErrors = getFieldErrors(value, rules, true);
        const errors =
          fieldErrors.length > 0
            ? [`${rules.label} ${fieldErrors.join(", ")}`]
            : [];
        return [...currentError, ...errors];
      },
      [] as string[]
    );
    return [
      ...current,
      ...(errorsByType.length > 0
        ? [`${roomTypeLabel.get(room.roomType)} - ${errorsByType.join(" / ")}`]
        : [])
    ];
  }, [] as string[]);
  return errors;
};
