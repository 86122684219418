export enum MlsArea {
  TenN = "tenN",
  TenS = "tenS",
  Eleven = "eleven",
  OneA = "oneA",
  OneB = "oneB",
  OneN = "oneN",
  Two = "two",
  TwoN = "twoN",
  Three = "three",
  ThreeE = "threeE",
  Four = "four",
  Five = "five",
  FiveE = "fiveE",
  Six = "six",
  Seven = "seven",
  EightE = "eightE",
  EightW = "eightW",
  Nine = "nine",
  BA = "ba",
  BL = "bl",
  BT = "bt",
  BU = "bu",
  BW = "bw",
  CC = "cc",
  CLN = "cln",
  CLS = "cls",
  CM = "cm",
  DT = "dt",
  EL = "el",
  FC = "fc",
  GP = "gp",
  GTE = "gte",
  GTW = "gtw",
  GU = "gu",
  GZ = "gz",
  HD = "hd",
  HH = "hh",
  HS = "hs",
  HU = "hu",
  HW = "hw",
  JA = "ja",
  KL = "kl",
  LC = "lc",
  LH = "lh",
  LL = "ll",
  LN = "ln",
  LP = "lp",
  LS = "ls",
  LW = "lw",
  MA = "ma",
  MC = "mc",
  MT = "mt",
  N = "n",
  NE = "ne",
  NW = "nw",
  OT = "ot",
  PF = "pf",
  RA = "ra",
  RN = "rn",
  RRE = "rre",
  RRW = "rrw",
  SC = "sc",
  SE = "se",
  SH = "sh",
  SS = "ss",
  SV = "sv",
  SWE = "swe",
  SWW = "sww",
  TCT = "tct",
  UT = "ut",
  W = "w",
  WE = "we",
  WW = "ww"
}

export const MlsAreaLabel = new Map<MlsArea, string>([
  [MlsArea.TenN, "10N"],
  [MlsArea.TenS, "10S"],
  [MlsArea.Eleven, "11"],
  [MlsArea.OneA, "1A"],
  [MlsArea.OneB, "1B"],
  [MlsArea.OneN, "1N"],
  [MlsArea.Two, "2"],
  [MlsArea.TwoN, "2N"],
  [MlsArea.Three, "3"],
  [MlsArea.ThreeE, "3E"],
  [MlsArea.Four, "4"],
  [MlsArea.Five, "5"],
  [MlsArea.FiveE, "5E"],
  [MlsArea.Six, "6"],
  [MlsArea.Seven, "7"],
  [MlsArea.EightE, "8E"],
  [MlsArea.EightW, "8W"],
  [MlsArea.Nine, "9"],
  [MlsArea.BA, "BA"],
  [MlsArea.BL, "BL"],
  [MlsArea.BT, "BT"],
  [MlsArea.BU, "BU"],
  [MlsArea.BW, "BW"],
  [MlsArea.CC, "CC"],
  [MlsArea.CLN, "CLN"],
  [MlsArea.CLS, "CLS"],
  [MlsArea.CM, "CM"],
  [MlsArea.DT, "DT"],
  [MlsArea.EL, "EL"],
  [MlsArea.FC, "FC"],
  [MlsArea.GP, "GP"],
  [MlsArea.GTE, "GTE"],
  [MlsArea.GTW, "GTW"],
  [MlsArea.GU, "GU"],
  [MlsArea.GZ, "GZ"],
  [MlsArea.HD, "HD"],
  [MlsArea.HH, "HH"],
  [MlsArea.HS, "HS"],
  [MlsArea.HU, "HU"],
  [MlsArea.HW, "HW"],
  [MlsArea.JA, "JA"],
  [MlsArea.KL, "KL"],
  [MlsArea.LC, "LC"],
  [MlsArea.LH, "LH"],
  [MlsArea.LL, "LL"],
  [MlsArea.LN, "LN"],
  [MlsArea.LP, "LP"],
  [MlsArea.LS, "LS"],
  [MlsArea.LW, "LW"],
  [MlsArea.MA, "MA"],
  [MlsArea.MC, "MC"],
  [MlsArea.MT, "MT"],
  [MlsArea.N, "N"],
  [MlsArea.NE, "NE"],
  [MlsArea.NW, "NW"],
  [MlsArea.OT, "OT"],
  [MlsArea.PF, "PF"],
  [MlsArea.RA, "RA"],
  [MlsArea.RN, "RN"],
  [MlsArea.RRE, "RRE"],
  [MlsArea.RRW, "RRW"],
  [MlsArea.SC, "SC"],
  [MlsArea.SE, "SE"],
  [MlsArea.SH, "SH"],
  [MlsArea.SS, "SS"],
  [MlsArea.SV, "SV"],
  [MlsArea.SWE, "SWE"],
  [MlsArea.SWW, "SWW"],
  [MlsArea.TCT, "TCT"],
  [MlsArea.UT, "UT"],
  [MlsArea.W, "W"],
  [MlsArea.WE, "WE"],
  [MlsArea.WW, "WW"]
]);
