import React from "react";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  LockBoxType,
  LockBoxTypeLabel,
  ShowingRequirements,
  ShowingRequirementsLabel
} from "markets/abor/enums";

export const showingRules: FormRules = {
  ownerName: {
    label: "Owner Name",
    disabled: true
  },
  directions: {
    requiredToSubmit: true,
    label: "Directions",
    maxLength: 2000,
    minLength: 25,
    rows: 6
  },
  showingInstructions: {
    label: "Showing Instructions",
    maxLength: 2000,
    rows: 5
  },
  occupantPhone: {
    label: "Showing Service Phone",
    regex: "^\\d{10}$",
    regexMessage: "invalid phone"
  },
  contactPhone: {
    requiredToSubmit: true,
    regex: "^\\d{10}$",
    regexMessage: "invalid phone",
    label: "Appt. Phone"
  },
  realtorContactEmail: {
    label: "Email for Realtors to Contact you",
    tooltip: (
      <p>
        Separate multiple email addresses with a semicolon <br />
        (i.e., john.smith@sample.com;jane.doe@sample.com).
      </p>
    ),
    isMultiple: true
  },
  lockBoxType: {
    label: "Lockbox Type",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(LockBoxType),
      LockBoxTypeLabel
    ),
    lastOptions: [LockBoxType.None]
  },
  showingRequirements: {
    label: "Showing Requirements",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(ShowingRequirements),
      ShowingRequirementsLabel
    ),
    rows: 2
  }
};
