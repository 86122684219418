export enum SchoolDistrict {
  Abilene = "abilene",
  AlamoHeightsISD = "alamoHeightsISD",
  AliceISD = "aliceISD",
  AlpineIsd = "alpineIsd",
  AngletonISD = "angletonISD",
  AnnaISD = "annaISD",
  Aransas = "aransas",
  AransasCountyISD = "aransasCountyISD",
  ArtisanParkAtVictoriaCommons = "artisanParkAtVictoriaCommons",
  AustinISD = "austinISD",
  AzleISD = "azleISD",
  BrownsVilleISD = "brownsVilleISD",
  BanderaIsd = "banderaIsd",
  BastropISD = "bastropISD",
  BeaCounty = "beaCounty",
  BeevilleISD = "beevilleISD",
  BeltonISD = "beltonISD",
  BenevidesISD = "benevidesISD",
  BirdvilleISD = "birdvilleISD",
  BishopCISD = "bishopCISD",
  Blanco = "blanco",
  Boerne = "boerne",
  Brackett = "brackett",
  BrackettISD = "brackettISD",
  BradyISD = "bradyISD",
  BrazosportISD = "brazosportISD",
  BrenhamISD = "brenhamISD",
  BrooksCountyISD = "brooksCountyISD",
  Brownsboro = "brownsboro",
  BrucksGerdes = "brucksGerdes",
  Bryan = "bryan",
  BullardISD = "bullardISD",
  BurnetISD = "burnetISD",
  CallDistrict = "callDistrict",
  Canyon = "canyon",
  ChiltonISD = "chiltonISD",
  CalallenISD = "calallenISD",
  CalhounCountyISD = "calhounCountyISD",
  CarrizoSpringsCISD = "carrizoSpringsCISD",
  CarrolltonFarmersBranchISD = "carrolltonFarmersBranchISD",
  Cayuga = "cayuga",
  CelinaISD = "celinaISD",
  CenterPoint = "centerPoint",
  ChapelHill = "chapelHill",
  Charlotte = "charlotte",
  ClearCreekISD = "clearCreekISD",
  Comal = "comal",
  Comfort = "comfort",
  ComstockISD = "comstockISD",
  CorpusChristiISD = "corpusChristiISD",
  CotullaISD = "cotullaISD",
  CrossPlainsISD = "crossPlainsISD",
  CrystalCiISD = "crystalCiISD",
  CrystalCityISD = "crystalCityISD",
  CueroISD = "cueroISD",
  CypressFairbanksISD = "cypressFairbanksISD",
  DHanisIsd = "dHanisIsd",
  DallasISD = "dallasISD",
  DelValleISD = "delValleISD",
  Devine = "devine",
  DickinsonISD = "dickinsonISD",
  Dilley = "dilley",
  DivideISD = "divideISD",
  DonnaISD = "donnaISD",
  DrippingSpringsISD = "drippingSpringsISD",
  EaglePassISD = "eaglePassISD",
  EanesISD = "eanesISD",
  EastCentralISD = "eastCentralISD",
  EdenISD = "edenISD",
  EdgewoodISD = "edgewoodISD",
  EdinburgConsolidatedISD = "edinburgConsolidatedISD",
  EdnaIndependentSchoolDistrict = "ednaIndependentSchoolDistrict",
  ElCampoISD = "elCampoISD",
  ElPasoIsd = "elPasoIsd",
  ElginISD = "elginISD",
  EvantISD = "evantISD",
  EzzellISD = "ezzellISD",
  FairField = "fairField",
  FlourBluffISD = "flourBluffISD",
  Freer = "freer",
  FallCityISD = "fallCityISD",
  FallsCityISD = "fallsCityISD",
  FloresvilleIsd = "floresvilleIsd",
  FortBendISD = "fortBendISD",
  FortWorthISD = "fortWorthISD",
  FrankstonISD = "frankstonISD",
  Fredericksburg = "fredericksburg",
  FriscoISD = "friscoISD",
  FtSamHoustonISD = "ftSamHoustonISD",
  Goliad = "goliad",
  GeorgeWestISD = "georgeWestISD",
  Georgetown = "georgetown",
  Gonzales = "gonzales",
  GrangerISD = "grangerISD",
  GrapevineColleyville = "grapevineColleyville",
  GroesbeckISD = "groesbeckISD",
  Harris = "harris",
  HarryCounty = "harryCounty",
  Hutto = "hutto",
  HallettsvilleISD = "hallettsvilleISD",
  HarlandaleISD = "harlandaleISD",
  HarlingenISD = "harlingenISD",
  HarperISD = "harperISD",
  HaysISD = "haysISD",
  HondoISD = "hondoISD",
  HoustonISD = "houstonISD",
  Hunt = "hunt",
  InglesideISD = "inglesideISD",
  Inverness = "inverness",
  Ingram = "ingram",
  IolaISD = "iolaISD",
  JimHoggCountyISD = "jimHoggCountyISD",
  JoeyTomlinsonElementarySchool = "joeyTomlinsonElementarySchool",
  JohnsonCity = "johnsonCity",
  Jourdanton = "jourdanton",
  Judson = "judson",
  JunctionISD = "junctionISD",
  KarnesCityISD = "karnesCityISD",
  KatyISD = "katyISD",
  KenedyISD = "kenedyISD",
  Kerrville = "kerrville",
  KilleenISD = "killeenISD",
  Kingsville = "kingsville",
  Kinney = "kinney",
  Knippa = "knippa",
  KnippasISD = "knippasISD",
  KnippaISD = "knippaISD",
  Kruegar = "kruegar",
  LeanderIS = "leanderIS",
  LockhartISD = "lockhartISD",
  LaGrangeISD = "laGrangeISD",
  LaJoyaISD = "laJoyaISD",
  LaPoynor = "laPoynor",
  LaPryorISD = "laPryorISD",
  LaSalleISD = "laSalleISD",
  LaVerniaIsd = "laVerniaIsd",
  LacklandIsd = "lacklandIsd",
  LagaVistaISD = "lagaVistaISD",
  LagoVista = "lagoVista",
  LakeTravisISD = "lakeTravisISD",
  LampasasISD = "lampasasISD",
  LaredoISD = "laredoISD",
  LatigoRanch = "latigoRanch",
  LeakeyISD = "leakeyISD",
  LeanderISD = "leanderISD",
  LedbetterISD = "ledbetterISD",
  LibertyHillISD = "libertyHillISD",
  LittleCypressMauricevilleCISD = "littleCypressMauricevilleCISD",
  Liverpool = "liverpool",
  LlanoISD = "llanoISD",
  LometaIsd = "lometaIsd",
  LoraineISD = "loraineISD",
  LosFresnosConsIsd = "losFresnosConsIsd",
  Luling = "luling",
  Lumberton = "lumberton",
  Lytle = "lytle",
  Manor = "manor",
  MenardISD = "menardISD",
  MontgomeryISDMontgomeryCounty = "montgomeryISDMontgomeryCounty",
  MarbleFallsIsd = "marbleFallsIsd",
  MarfaISD = "marfaISD",
  Marion = "marion",
  Marlin = "marlin",
  MasonIsd = "masonIsd",
  Mathis = "mathis",
  McAllenISD = "mcAllenISD",
  McCamey = "mcCamey",
  McMullenISD = "mcMullenISD",
  MeadowsofSilverOaks = "meadowsofSilverOaks",
  MedinaISD = "medinaISD",
  MedinaValleyISD = "medinaValleyISD",
  MercedesISD = "mercedesISD",
  Millrun = "millrun",
  MissionConsolidatedISD = "missionConsolidatedISD",
  Moulton = "moulton",
  NeedvilleISD = "needvilleISD",
  Nursery = "nursery",
  Natalia = "natalia",
  NavarroIsd = "navarroIsd",
  NewBraunfels = "newBraunfels",
  NixonIsd = "nixonIsd",
  NixonSmileyISD = "nixonSmileyISD",
  NordheimISD = "nordheimISD",
  NorthEastISD = "northEastISD",
  Northside = "northside",
  NorthwestISD = "northwestISD",
  NotApplicable = "notApplicable",
  NuecesCanyonISD = "nuecesCanyonISD",
  OnalaskaISD = "onalaskaISD",
  OrangeGrove = "orangeGrove",
  Palacios = "palacios",
  PsjaISD = "psjaISD",
  Palestine = "palestine",
  PawneeISD = "pawneeISD",
  PearlandISD = "pearlandISD",
  Pearsall = "pearsall",
  PettusISD = "pettusISD",
  PewittISD = "pewittISD",
  PflugervilleISD = "pflugervilleISD",
  Pleasanton = "pleasanton",
  PortAransas = "portAransas",
  PortIsabelIsd = "portIsabelIsd",
  PoteetIsd = "poteetIsd",
  Poth = "poth",
  PrairieLeaISD = "prairieLeaISD",
  PremontISD = "premontISD",
  Riata = "riata",
  RainsISD = "rainsISD",
  RalphLangley = "ralphLangley",
  RandolphFieldIsd = "randolphFieldIsd",
  ReaganHighSchool = "reaganHighSchool",
  RicardoISD = "ricardoISD",
  RichlandSpringsISD = "richlandSpringsISD",
  RioGrandeISD = "rioGrandeISD",
  RioHondoISD = "rioHondoISD",
  RivieraISD = "rivieraISD",
  RockspringsISD = "rockspringsISD",
  RockwallISD = "rockwallISD",
  Roma = "roma",
  RoundRockISD = "roundRockISD",
  RungeISD = "rungeISD",
  RuskISD = "ruskISD",
  SouthTexasISD = "southTexasISD",
  SpringISD = "springISD",
  SabinalISD = "sabinalISD",
  SanAngeloISD = "sanAngeloISD",
  SanAntonioISD = "sanAntonioISD",
  SanBenitoISD = "sanBenitoISD",
  SanDiegoISD = "sanDiegoISD",
  SanFelipeDelRioISD = "sanFelipeDelRioISD",
  SanMarcos = "sanMarcos",
  SanPerlitaISD = "sanPerlitaISD",
  SanSabaISD = "sanSabaISD",
  SantaFe = "santaFe",
  SaraBMcAndrew = "saraBMcAndrew",
  SchertzCiboloUniversalCityISD = "schertzCiboloUniversalCityISD",
  SchleicherCountyISD = "schleicherCountyISD",
  SchulenburgISD = "schulenburgISD",
  Seguin = "seguin",
  SharylandISD = "sharylandISD",
  ShinerISD = "shinerISD",
  SkidmoreTynan = "skidmoreTynan",
  SmithvilleISD = "smithvilleISD",
  Somerset = "somerset",
  SonoraISD = "sonoraISD",
  SouthSanAntonio = "southSanAntonio",
  SouthSideISD = "southSideISD",
  SouthwestISD = "southwestISD",
  SpringCreek = "springCreek",
  StockdaleIsd = "stockdaleIsd",
  SummitEstatesatFischerTexas = "summitEstatesatFischerTexas",
  SweetHomeISD = "sweetHomeISD",
  Taylor = "taylor",
  TravisISD = "travisISD",
  TerrellCountyISD = "terrellCountyISD",
  ThorndaleISD = "thorndaleISD",
  Thrall = "thrall",
  ThreeRivers = "threeRivers",
  Tomball = "tomball",
  TownCreekVillage = "townCreekVillage",
  TylerISD = "tylerISD",
  UnitedISD = "unitedISD",
  UnitedIndependentSchoolDistrict = "unitedIndependentSchoolDistrict",
  Utopia = "utopia",
  UvaldeCISD = "uvaldeCISD",
  UvaldeCounty = "uvaldeCounty",
  VictoriaISD = "victoriaISD",
  VidorISD = "vidorISD",
  ValVerde = "valVerde",
  ValleySpring = "valleySpring",
  VanISD = "vanISD",
  Weslaco = "weslaco",
  WacoISD = "wacoISD",
  WaelderIsd = "waelderIsd",
  WallISD = "wallISD",
  WalnutSpringsRanch = "walnutSpringsRanch",
  WaxahachieISD = "waxahachieISD",
  WeimarIndependentSchoolDistrict = "weimarIndependentSchoolDistrict",
  Westwood = "westwood",
  WhitehouseISD = "whitehouseISD",
  WillowCreekEstates = "willowCreekEstates",
  Wimberley = "wimberley",
  WodenISD = "wodenISD",
  Yoakum = "yoakum",
  YorktownISD = "yorktownISD",
  ZapataCountyISD = "zapataCountyISD",
  CopperasCoveISD = "copperasCoveISD"
}

export const SchoolDistrictLabel: Record<SchoolDistrict, string> = {
  [SchoolDistrict.Abilene]: "Abilene",
  [SchoolDistrict.AlamoHeightsISD]: "Alamo Heights I.S.D.",
  [SchoolDistrict.AliceISD]: "Alice ISD",
  [SchoolDistrict.AlpineIsd]: "Alpine Isd",
  [SchoolDistrict.AngletonISD]: "Angleton ISD",
  [SchoolDistrict.AnnaISD]: "Anna ISD",
  [SchoolDistrict.Aransas]: "Aransas",
  [SchoolDistrict.AransasCountyISD]: "Aransas County ISD",
  [SchoolDistrict.ArtisanParkAtVictoriaCommons]:
    "Artisan Park At Victoria Commons",
  [SchoolDistrict.AustinISD]: "Austin I.S.D.",
  [SchoolDistrict.AzleISD]: "Azle I.S.D",
  [SchoolDistrict.BrownsVilleISD]: "Brownsville ISD",
  [SchoolDistrict.BanderaIsd]: "Bandera Isd",
  [SchoolDistrict.BastropISD]: "Bastrop ISD",
  [SchoolDistrict.BeaCounty]: "Bea County",
  [SchoolDistrict.BeevilleISD]: "Beeville ISD",
  [SchoolDistrict.BeltonISD]: "Belton ISD",
  [SchoolDistrict.BenevidesISD]: "Benevides ISD",
  [SchoolDistrict.BirdvilleISD]: "Birdville ISD",
  [SchoolDistrict.BishopCISD]: "Bishop CISD",
  [SchoolDistrict.Blanco]: "Blanco",
  [SchoolDistrict.Boerne]: "Boerne",
  [SchoolDistrict.Brackett]: "Brackett ISD",
  [SchoolDistrict.BrackettISD]: "Brackett ISD",
  [SchoolDistrict.BradyISD]: "Brady ISD",
  [SchoolDistrict.BrazosportISD]: "Brazosport ISD",
  [SchoolDistrict.BrenhamISD]: "Brenham ISD",
  [SchoolDistrict.BrooksCountyISD]: "Brooks County ISD",
  [SchoolDistrict.Brownsboro]: "Brownsboro",
  [SchoolDistrict.BrucksGerdes]: "Brucks Gerdes",
  [SchoolDistrict.Bryan]: "Bryan",
  [SchoolDistrict.BullardISD]: "Bullard ISD",
  [SchoolDistrict.BurnetISD]: "Burnet ISD",
  [SchoolDistrict.CallDistrict]: "Call District",
  [SchoolDistrict.Canyon]: "Canyon",
  [SchoolDistrict.ChiltonISD]: "Chilton ISD",
  [SchoolDistrict.CalallenISD]: "Calallen ISD",
  [SchoolDistrict.CalhounCountyISD]: "Calhoun County ISD",
  [SchoolDistrict.CarrizoSpringsCISD]: "Carrizo Springs CISD",
  [SchoolDistrict.CarrolltonFarmersBranchISD]: "Carrollton/Farmers Branch ISD",
  [SchoolDistrict.Cayuga]: "Cayuga",
  [SchoolDistrict.CelinaISD]: "Celina ISD",
  [SchoolDistrict.CenterPoint]: "Center Point",
  [SchoolDistrict.ChapelHill]: "Chapel Hill",
  [SchoolDistrict.Charlotte]: "Charlotte",
  [SchoolDistrict.ClearCreekISD]: "Clear Creek ISD",
  [SchoolDistrict.Comal]: "Comal",
  [SchoolDistrict.Comfort]: "Comfort",
  [SchoolDistrict.ComstockISD]: "Comstock ISD",
  [SchoolDistrict.CorpusChristiISD]: "Corpus Christi ISD",
  [SchoolDistrict.CotullaISD]: "Cotulla I.S.D.",
  [SchoolDistrict.CrossPlainsISD]: "Cross Plains ISD",
  [SchoolDistrict.CrystalCiISD]: "Crystal City ISD",
  [SchoolDistrict.CrystalCityISD]: "Crystal City ISD",
  [SchoolDistrict.CueroISD]: "Cuero ISD",
  [SchoolDistrict.CypressFairbanksISD]: "Cypress Fairbanks ISD",
  [SchoolDistrict.DHanisIsd]: "DHanis Isd",
  [SchoolDistrict.DallasISD]: "Dallas ISD",
  [SchoolDistrict.DelValleISD]: "Del Valle ISD",
  [SchoolDistrict.Devine]: "Devine",
  [SchoolDistrict.DickinsonISD]: "Dickinson ISD",
  [SchoolDistrict.Dilley]: "Dilley",
  [SchoolDistrict.DivideISD]: "Divide ISD",
  [SchoolDistrict.DonnaISD]: "Donna ISD",
  [SchoolDistrict.DrippingSpringsISD]: "Dripping Springs ISD",
  [SchoolDistrict.EaglePassISD]: "Eagle Pass ISD",
  [SchoolDistrict.EanesISD]: "Eanes ISD",
  [SchoolDistrict.EastCentralISD]: "East Central I.S.D",
  [SchoolDistrict.EdenISD]: "Eden ISD",
  [SchoolDistrict.EdgewoodISD]: "Edgewood I.S.D",
  [SchoolDistrict.EdinburgConsolidatedISD]: "Edinburg Consolidated ISD",
  [SchoolDistrict.EdnaIndependentSchoolDistrict]:
    "Edna Independent School District",
  [SchoolDistrict.ElCampoISD]: "El Campo ISD",
  [SchoolDistrict.ElPasoIsd]: "El Paso Isd",
  [SchoolDistrict.ElginISD]: "Elgin ISD",
  [SchoolDistrict.EvantISD]: "Evant ISD",
  [SchoolDistrict.EzzellISD]: "Ezzell ISD",
  [SchoolDistrict.FairField]: "Fairfield",
  [SchoolDistrict.FlourBluffISD]: "Flour Bluff ISD",
  [SchoolDistrict.Freer]: "Freer",
  [SchoolDistrict.FallCityISD]: "Fall City ISD",
  [SchoolDistrict.FallsCityISD]: "Falls City ISD",
  [SchoolDistrict.FloresvilleIsd]: "Floresville Isd",
  [SchoolDistrict.FortBendISD]: "Fort Bend ISD",
  [SchoolDistrict.FortWorthISD]: "Fort Worth ISD",
  [SchoolDistrict.FrankstonISD]: "Frankston ISD",
  [SchoolDistrict.Fredericksburg]: "Fredericksburg",
  [SchoolDistrict.FriscoISD]: "Frisco ISD",
  [SchoolDistrict.FtSamHoustonISD]: "Ft Sam Houston I.S.D",
  [SchoolDistrict.Goliad]: "Goliad",
  [SchoolDistrict.GeorgeWestISD]: "George West ISD",
  [SchoolDistrict.Georgetown]: "Georgetown",
  [SchoolDistrict.Gonzales]: "Gonzales",
  [SchoolDistrict.GrangerISD]: "Granger I.S.D.",
  [SchoolDistrict.GrapevineColleyville]: "Grapevine-Colleyville",
  [SchoolDistrict.GroesbeckISD]: "Groesbeck ISD",
  [SchoolDistrict.Harris]: "Harris",
  [SchoolDistrict.HarryCounty]: "Harry County",
  [SchoolDistrict.Hutto]: "Hutto",
  [SchoolDistrict.HallettsvilleISD]: "Hallettsville ISD",
  [SchoolDistrict.HarlandaleISD]: "Harlandale I.S.D",
  [SchoolDistrict.HarlingenISD]: "Harlingen ISD",
  [SchoolDistrict.HarperISD]: "Harper I.S.D.",
  [SchoolDistrict.HaysISD]: "Hays I.S.D.",
  [SchoolDistrict.HondoISD]: "Hondo I.S.D.",
  [SchoolDistrict.HoustonISD]: "Houston ISD",
  [SchoolDistrict.Hunt]: "Hunt",
  [SchoolDistrict.InglesideISD]: "Ingleside ISD",
  [SchoolDistrict.Inverness]: "Inverness",
  [SchoolDistrict.Ingram]: "Ingram",
  [SchoolDistrict.IolaISD]: "Iola ISD",
  [SchoolDistrict.JimHoggCountyISD]: "Jim Hogg County ISD",
  [SchoolDistrict.JoeyTomlinsonElementarySchool]:
    "Joey Tomlinson Elementary School",
  [SchoolDistrict.JohnsonCity]: "Johnson City",
  [SchoolDistrict.Jourdanton]: "Jourdanton",
  [SchoolDistrict.Judson]: "Judson",
  [SchoolDistrict.JunctionISD]: "Junction I.S.D.",
  [SchoolDistrict.KarnesCityISD]: "Karnes City I.S.D.",
  [SchoolDistrict.KatyISD]: "Katy ISD",
  [SchoolDistrict.KenedyISD]: "Kenedy I.S.D.",
  [SchoolDistrict.Kerrville]: "Kerrville.",
  [SchoolDistrict.KilleenISD]: "Killeen ISD",
  [SchoolDistrict.Kingsville]: "Kingsville",
  [SchoolDistrict.Kinney]: "Kinney",
  [SchoolDistrict.Knippa]: "Knippa I.S.D.",
  [SchoolDistrict.KnippasISD]: "Knippa ISD",
  [SchoolDistrict.KnippaISD]: "Knippa ISD",
  [SchoolDistrict.Kruegar]: "Kruegar",
  [SchoolDistrict.LeanderIS]: "Leander IS",
  [SchoolDistrict.LockhartISD]: "Lockhart ISD",
  [SchoolDistrict.LaGrangeISD]: "La Grange ISD",
  [SchoolDistrict.LaJoyaISD]: "La Joya ISD",
  [SchoolDistrict.LaPoynor]: "La Poynor",
  [SchoolDistrict.LaPryorISD]: "La Pryor ISD",
  [SchoolDistrict.LaSalleISD]: "La Salle ISD",
  [SchoolDistrict.LaVerniaIsd]: "La Vernia Isd.",
  [SchoolDistrict.LacklandIsd]: "Lackland Isd",
  [SchoolDistrict.LagaVistaISD]: "Laga Vista ISD",
  [SchoolDistrict.LagoVista]: "Lago Vista",
  [SchoolDistrict.LakeTravisISD]: "Lake Travis ISD",
  [SchoolDistrict.LampasasISD]: "Lampasas ISD",
  [SchoolDistrict.LaredoISD]: "Laredo ISD",
  [SchoolDistrict.LatigoRanch]: "Latigo Ranch",
  [SchoolDistrict.LeakeyISD]: "Leakey ISD",
  [SchoolDistrict.LeanderISD]: "Leander I.S.D.",
  [SchoolDistrict.LedbetterISD]: "Ledbetter ISD",
  [SchoolDistrict.LibertyHillISD]: "Liberty Hill ISD",
  [SchoolDistrict.LittleCypressMauricevilleCISD]:
    "Little Cypress Mauriceville CISD",
  [SchoolDistrict.Liverpool]: "Liverpool",
  [SchoolDistrict.LlanoISD]: "Llano I.S.D.",
  [SchoolDistrict.LometaIsd]: "Lometa Isd",
  [SchoolDistrict.LoraineISD]: "Loraine ISD",
  [SchoolDistrict.LosFresnosConsIsd]: "Los Fresnos Cons Isd",
  [SchoolDistrict.Luling]: "Luling",
  [SchoolDistrict.Lumberton]: "Lumberton",
  [SchoolDistrict.Lytle]: "Lytle",
  [SchoolDistrict.Manor]: "Manor",
  [SchoolDistrict.MenardISD]: "Menard ISD",
  [SchoolDistrict.MontgomeryISDMontgomeryCounty]:
    "Montgomery ISD Montgomery County",
  [SchoolDistrict.MarbleFallsIsd]: "Marble Falls Isd",
  [SchoolDistrict.MarfaISD]: "Marfa ISD",
  [SchoolDistrict.Marion]: "Marion",
  [SchoolDistrict.Marlin]: "Marlin",
  [SchoolDistrict.MasonIsd]: "Mason Isd",
  [SchoolDistrict.Mathis]: "Mathis",
  [SchoolDistrict.McAllenISD]: "McAllen ISD",
  [SchoolDistrict.McCamey]: "McCamey",
  [SchoolDistrict.McMullenISD]: "McMullen ISD",
  [SchoolDistrict.MeadowsofSilverOaks]: "Meadows of Silver Oaks",
  [SchoolDistrict.MedinaISD]: "Medina ISD",
  [SchoolDistrict.MedinaValleyISD]: "Medina Valley I.S.D.",
  [SchoolDistrict.MercedesISD]: "Mercedes ISD",
  [SchoolDistrict.Millrun]: "Millrun",
  [SchoolDistrict.MissionConsolidatedISD]: "Mission Consolidated ISD",
  [SchoolDistrict.Moulton]: "Moulton",
  [SchoolDistrict.NeedvilleISD]: "Needville ISD",
  [SchoolDistrict.Nursery]: "Nursery",
  [SchoolDistrict.Natalia]: "Natalia",
  [SchoolDistrict.NavarroIsd]: "Navarro Isd",
  [SchoolDistrict.NewBraunfels]: "New Braunfels",
  [SchoolDistrict.NixonIsd]: "Nixon Isd",
  [SchoolDistrict.NixonSmileyISD]: "Nixon-Smiley ISD",
  [SchoolDistrict.NordheimISD]: "Nordheim ISD",
  [SchoolDistrict.NorthEastISD]: "North East I.S.D",
  [SchoolDistrict.Northside]: "Northside",
  [SchoolDistrict.NorthwestISD]: "Northwest ISD",
  [SchoolDistrict.NotApplicable]: "Not Applicable",
  [SchoolDistrict.NuecesCanyonISD]: "Nueces Canyon ISD",
  [SchoolDistrict.OnalaskaISD]: "Onalaska ISD",
  [SchoolDistrict.OrangeGrove]: "Orange Grove",
  [SchoolDistrict.Palacios]: "Palacios",
  [SchoolDistrict.PsjaISD]: "PSJA ISD",
  [SchoolDistrict.Palestine]: "Palestine",
  [SchoolDistrict.PawneeISD]: "Pawnee I.S.D.",
  [SchoolDistrict.PearlandISD]: "Pearland ISD",
  [SchoolDistrict.Pearsall]: "Pearsall",
  [SchoolDistrict.PettusISD]: "Pettus ISD",
  [SchoolDistrict.PewittISD]: "Pewitt ISD",
  [SchoolDistrict.PflugervilleISD]: "Pflugerville ISD",
  [SchoolDistrict.Pleasanton]: "Pleasanton",
  [SchoolDistrict.PortAransas]: "Port Aransas",
  [SchoolDistrict.PortIsabelIsd]: "Port Isabel Isd",
  [SchoolDistrict.PoteetIsd]: "Poteet Isd",
  [SchoolDistrict.Poth]: "Poth",
  [SchoolDistrict.PrairieLeaISD]: "Prairie Lea ISD",
  [SchoolDistrict.PremontISD]: "Premont ISD",
  [SchoolDistrict.Riata]: "Riata",
  [SchoolDistrict.RainsISD]: "Rains ISD",
  [SchoolDistrict.RalphLangley]: "Ralph Langley",
  [SchoolDistrict.RandolphFieldIsd]: "Randolph Field Isd",
  [SchoolDistrict.ReaganHighSchool]: "Reagan High School",
  [SchoolDistrict.RicardoISD]: "Ricardo ISD",
  [SchoolDistrict.RichlandSpringsISD]: "Richland Springs ISD",
  [SchoolDistrict.RioGrandeISD]: "Rio Grande ISD",
  [SchoolDistrict.RioHondoISD]: "Rio Hondo ISD",
  [SchoolDistrict.RivieraISD]: "Riviera ISD",
  [SchoolDistrict.RockspringsISD]: "Rocksprings ISD",
  [SchoolDistrict.RockwallISD]: "Rockwall ISD",
  [SchoolDistrict.Roma]: "Roma",
  [SchoolDistrict.RoundRockISD]: "Round Rock ISD",
  [SchoolDistrict.RungeISD]: "Runge ISD",
  [SchoolDistrict.RuskISD]: "Rusk ISD",
  [SchoolDistrict.SouthTexasISD]: "South Texas ISD",
  [SchoolDistrict.SpringISD]: "Spring ISD",
  [SchoolDistrict.SabinalISD]: "Sabinal ISD",
  [SchoolDistrict.SanAngeloISD]: "San Angelo ISD",
  [SchoolDistrict.SanAntonioISD]: "San Antonio I.S.D.",
  [SchoolDistrict.SanBenitoISD]: "San Benito ISD",
  [SchoolDistrict.SanDiegoISD]: "San Diego ISD",
  [SchoolDistrict.SanFelipeDelRioISD]: "San Felipe/Del Rio ISD",
  [SchoolDistrict.SanMarcos]: "San Marcos",
  [SchoolDistrict.SanPerlitaISD]: "San Perlita ISD",
  [SchoolDistrict.SanSabaISD]: "San Saba ISD",
  [SchoolDistrict.SantaFe]: "Santa Fe",
  [SchoolDistrict.SaraBMcAndrew]: "Sara B McAndrew",
  [SchoolDistrict.SchertzCiboloUniversalCityISD]:
    "Schertz-Cibolo-Universal City ISD",
  [SchoolDistrict.SchleicherCountyISD]: "Schleicher County ISD",
  [SchoolDistrict.SchulenburgISD]: "Schulenburg ISD",
  [SchoolDistrict.Seguin]: "Seguin",
  [SchoolDistrict.SharylandISD]: "Sharyland ISD",
  [SchoolDistrict.ShinerISD]: "Shiner ISD",
  [SchoolDistrict.SkidmoreTynan]: "Skidmore-Tynan",
  [SchoolDistrict.SmithvilleISD]: "Smithville ISD",
  [SchoolDistrict.Somerset]: "Somerset",
  [SchoolDistrict.SonoraISD]: "Sonora ISD",
  [SchoolDistrict.SouthSanAntonio]: "South San Antonio.",
  [SchoolDistrict.SouthSideISD]: "South Side I.S.D",
  [SchoolDistrict.SouthwestISD]: "Southwest I.S.D.",
  [SchoolDistrict.SpringCreek]: "Spring Creek",
  [SchoolDistrict.StockdaleIsd]: "Stockdale Isd",
  [SchoolDistrict.SummitEstatesatFischerTexas]:
    "Summit Estates at Fischer Texas",
  [SchoolDistrict.SweetHomeISD]: "Sweet Home ISD",
  [SchoolDistrict.Taylor]: "Taylor",
  [SchoolDistrict.TravisISD]: "Travis ISD",
  [SchoolDistrict.TerrellCountyISD]: "Terrell County ISD",
  [SchoolDistrict.ThorndaleISD]: "Thorndale ISD",
  [SchoolDistrict.Thrall]: "Thrall",
  [SchoolDistrict.ThreeRivers]: "Three Rivers",
  [SchoolDistrict.Tomball]: "Tomball",
  [SchoolDistrict.TownCreekVillage]: "Town Creek Village",
  [SchoolDistrict.TylerISD]: "Tyler ISD",
  [SchoolDistrict.UnitedISD]: "United I.S.D.",
  [SchoolDistrict.UnitedIndependentSchoolDistrict]:
    "United Independent School District",
  [SchoolDistrict.Utopia]: "Utopia",
  [SchoolDistrict.UvaldeCISD]: "Uvalde CISD",
  [SchoolDistrict.UvaldeCounty]: "Uvalde County",
  [SchoolDistrict.VictoriaISD]: "Victoria ISD",
  [SchoolDistrict.VidorISD]: "Vidor ISD",
  [SchoolDistrict.ValVerde]: "Val Verde",
  [SchoolDistrict.ValleySpring]: "Valley Spring",
  [SchoolDistrict.VanISD]: "Van ISD",
  [SchoolDistrict.Weslaco]: "Weslaco",
  [SchoolDistrict.WacoISD]: "Waco ISD",
  [SchoolDistrict.WaelderIsd]: "Waelder Isd",
  [SchoolDistrict.WallISD]: "Wall ISD",
  [SchoolDistrict.WalnutSpringsRanch]: "Walnut Springs Ranch",
  [SchoolDistrict.WaxahachieISD]: "Waxahachie ISD",
  [SchoolDistrict.WeimarIndependentSchoolDistrict]:
    "Weimar Independent School District",
  [SchoolDistrict.Westwood]: "Westwood",
  [SchoolDistrict.WhitehouseISD]: "Whitehouse ISD",
  [SchoolDistrict.WillowCreekEstates]: "Willow Creek Estates",
  [SchoolDistrict.Wimberley]: "Wimberley",
  [SchoolDistrict.WodenISD]: "Woden ISD",
  [SchoolDistrict.Yoakum]: "Yoakum",
  [SchoolDistrict.YorktownISD]: "Yorktown ISD",
  [SchoolDistrict.ZapataCountyISD]: "Zapata County ISD",
  [SchoolDistrict.CopperasCoveISD]: "Copperas Cove ISD"
};
