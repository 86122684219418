export enum LockBoxType {
  Both = "both",
  Combo = "combo",
  Supra = "supra",
  None = "none",
  SeeRemarks = "seeRemarks"
}

export const LockBoxTypeLabel = new Map<LockBoxType, string>([
  [LockBoxType.Both, "Both"],
  [LockBoxType.Combo, "Combo"],
  [LockBoxType.None, "None"],
  [LockBoxType.SeeRemarks, "See Remarks"],
  [LockBoxType.Supra, "SUPRA"]
]);
