export enum TitlePaidBy {
  Both = "both",
  Buyer = "buyer",
  NoTitlePolicy = "noTitlePolicy",
  Seller = "seller"
}
export const TitlePaidByLabel = new Map<TitlePaidBy, string>([
  [TitlePaidBy.Both, "Both"],
  [TitlePaidBy.Buyer, "Buyer"],
  [TitlePaidBy.NoTitlePolicy, "No Title Policy"],
  [TitlePaidBy.Seller, "Seller"]
]);
