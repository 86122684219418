import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps, FormRules } from "@homesusa/form";
import {
  SpecialtyRooms,
  SpecialtyRoomsLabel,
  Accessibility,
  AccessibilityLabel,
  CoolingSystem,
  CoolingSystemLabel,
  EnergyFeatures,
  EnergyFeaturesLabel,
  Exterior,
  ExteriorFeatures,
  ExteriorFeaturesLabel,
  ExteriorLabel,
  FireplaceDescription,
  FireplaceDescriptionLabel,
  Floors,
  FloorsLabel,
  Foundation,
  FoundationLabel,
  GreenCertification,
  GreenCertificationLabel,
  GreenFeatures,
  GreenFeaturesLabel,
  HeatingFuel,
  HeatingFuelLabel,
  HeatSystem,
  HeatSystemLabel,
  InclusionsLabel,
  Inclusions,
  NeighborhoodAmenitiesLabel,
  NeighborhoodAmenities,
  RoofDescription,
  RoofDescriptionLabel,
  WaterSewerLabel,
  WaterSewer
} from "markets/sabor/enums";

export const specialtyRoomsRules: FieldRulesProps = {
  requiredToSubmit: true,
  label: "Specialty Rooms",
  options: getOptionsFromEnumAndMap(
    Object.values(SpecialtyRooms),
    SpecialtyRoomsLabel
  )
};

export const utilitiesRules: FormRules = {
  exteriorFeatures: {
    label: "Exterior Features",
    options: getOptionsFromEnumAndMap(
      Object.values(ExteriorFeatures),
      ExteriorFeaturesLabel
    )
  },
  roofDescription: {
    label: "Roof",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(RoofDescription),
      RoofDescriptionLabel
    )
  },
  foundation: {
    label: "Foundation",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Foundation),
      FoundationLabel
    )
  },
  inclusions: {
    requiredToSubmit: true,
    label: "Inclusions",
    options: getOptionsFromEnumAndMap(
      Object.values(Inclusions),
      InclusionsLabel
    )
  },
  neighborhoodAmenities: {
    requiredToSubmit: true,
    label: "Neighborhood Amenities",
    options: getOptionsFromEnumAndMap(
      Object.values(NeighborhoodAmenities),
      NeighborhoodAmenitiesLabel
    ),
    noneOption: [NeighborhoodAmenities.None]
  },
  floors: {
    requiredToSubmit: true,
    label: "Flooring",
    options: getOptionsFromEnumAndMap(Object.values(Floors), FloorsLabel)
  },
  heatSystem: {
    requiredToSubmit: true,
    label: "Heating System Description",
    options: getOptionsFromEnumAndMap(
      Object.values(HeatSystem),
      HeatSystemLabel
    )
  },
  coolingSystem: {
    requiredToSubmit: true,
    label: "Cooling System Description",
    options: getOptionsFromEnumAndMap(
      Object.values(CoolingSystem),
      CoolingSystemLabel
    )
  },
  heatingFuel: {
    requiredToSubmit: true,
    label: "Heating Fuel",
    options: getOptionsFromEnumAndMap(
      Object.values(HeatingFuel),
      HeatingFuelLabel
    )
  },
  greenCertification: {
    label: "Green Certification",
    options: getOptionsFromEnumAndMap(
      Object.values(GreenCertification),
      GreenCertificationLabel
    )
  },
  energyFeatures: {
    label: "Energy Efficiency",
    options: getOptionsFromEnumAndMap(
      Object.values(EnergyFeatures),
      EnergyFeaturesLabel
    )
  },
  greenFeatures: {
    label: "Green Features",
    options: getOptionsFromEnumAndMap(
      Object.values(GreenFeatures),
      GreenFeaturesLabel
    )
  },
  waterSewer: {
    requiredToSubmit: true,
    label: "Water/Sewer",
    options: getOptionsFromEnumAndMap(
      Object.values(WaterSewer),
      WaterSewerLabel
    ),
    noneOption: [WaterSewer.NoneWater, WaterSewer.NoneSewer],
    groupNoneOptions: [
      {
        noneParent: WaterSewer.NoneWater,
        options: [
          WaterSewer.CoOpWater,
          WaterSewer.PrivateWell,
          WaterSewer.WaterStorage,
          WaterSewer.WaterSystem
        ]
      },
      {
        noneParent: WaterSewer.NoneSewer,
        options: [
          WaterSewer.AerobicSeptic,
          WaterSewer.Septic,
          WaterSewer.SewerSystem
        ]
      }
    ]
  },
  supplierElectricity: {
    label: "Supplier Electricity",
    maxLength: 25
  },
  supplierWater: {
    label: "Supplier Water",
    maxLength: 25
  },
  supplierGarbage: {
    label: "Supplier Garbage",
    maxLength: 25
  },
  supplierGas: {
    label: "Supplier Gas",
    maxLength: 25
  },
  supplierSewer: {
    label: "Supplier Sewer",
    maxLength: 25
  },
  supplierOther: {
    label: "Supplier Other",
    maxLength: 25
  },
  hasAccessibility: {
    requiredToSubmit: true,
    label: "Accessibility"
  },
  exterior: {
    requiredToSubmit: true,
    label: "Exterior (Type)",
    options: getOptionsFromEnumAndMap(Object.values(Exterior), ExteriorLabel)
  },
  accessibility: {
    label: "Accessibility Details",
    options: getOptionsFromEnumAndMap(
      Object.values(Accessibility),
      AccessibilityLabel
    ),
    rows: 2
  },
  fireplaceDescription: {
    label: "Fireplaces Features",
    options: getOptionsFromEnumAndMap(
      Object.values(FireplaceDescription),
      FireplaceDescriptionLabel
    ),
    rows: 2,
    noneOption: [FireplaceDescription.NotApplicable]
  },
  fireplaces: {
    label: "# Fireplaces",
    numberFormat: {
      min: 0
    },
    maxLength: 1
  }
};
