export enum WindowCoverings {
  AllRemain = "allRemain",
  NoneRemain = "noneRemain",
  SomeRemain = "someRemain"
}
export const WindowCoveringsLabel = new Map<WindowCoverings, string>([
  [WindowCoverings.AllRemain, "All Remain"],
  [WindowCoverings.NoneRemain, "None Remain"],
  [WindowCoverings.SomeRemain, "Some Remain"]
]);
