export enum RoomLevel {
  First = "first",
  Second = "second",
  Third = "third",
  Fourth = "fourth",
  InGarage = "inGarage",
  InHouse = "inHouse",
  Other = "other"
}

export const RoomLevelLabel = new Map<RoomLevel, string>([
  [RoomLevel.First, "1st"],
  [RoomLevel.Second, "2nd"],
  [RoomLevel.Third, "3rd"],
  [RoomLevel.Fourth, "4th"],
  [RoomLevel.InGarage, "In Garage"],
  [RoomLevel.InHouse, "In House"],
  [RoomLevel.Other, "Other"]
]);
