import { FormRules } from "@homesusa/form";

export const getFieldsByStatus = <
  Tenum extends string,
  TLabelMap extends Map<Tenum, FormRules>
>(
  mlsStatus: Tenum,
  rulesByStatus: TLabelMap
): { id: string }[] => {
  const rules = rulesByStatus.get(mlsStatus);
  if (rules == null || Object.entries(rules).length == 0) {
    return [];
  }
  return Object.keys(rules).map((key) => ({ id: key }));
};
