export enum RoomType {
  Bedroom2 = "bedroom2",
  Bedroom3 = "bedroom3",
  Bedroom4 = "bedroom4",
  Bedroom5 = "bedroom5",
  BonusRoom = "bonusRoom",
  BreakfastRoom = "breakfastRoom",
  DiningRoom = "diningRoom",
  GameRoom = "gameRoom",
  Kitchen = "kitchen",
  LivingRoom = "livingRoom",
  MasterBathroom = "masterBathroom",
  MasterBedroom = "masterBedroom",
  MediaRoom = "mediaRoom",
  MudRoom = "mudRoom",
  Office = "office",
  UtilityRoom = "utilityRoom"
}

export const RoomTypeLabel = new Map<RoomType, string>([
  [RoomType.Bedroom2, "Bedroom 2"],
  [RoomType.Bedroom3, "Bedroom 3"],
  [RoomType.Bedroom4, "Bedroom 4"],
  [RoomType.Bedroom5, "Bedroom 5"],
  [RoomType.BonusRoom, "Bonus Room"],
  [RoomType.BreakfastRoom, "Breakfast Room"],
  [RoomType.DiningRoom, "Dining Room"],
  [RoomType.GameRoom, "Game Room"],
  [RoomType.Kitchen, "Kitchen"],
  [RoomType.LivingRoom, "Living"],
  [RoomType.MasterBathroom, "Master Bathroom"],
  [RoomType.MasterBedroom, "Master Bedroom"],
  [RoomType.MediaRoom, "Media Room"],
  [RoomType.MudRoom, "Mud Room"],
  [RoomType.Office, "Office"],
  [RoomType.UtilityRoom, "Utility Room"]
]);

export const RoomTypeQuantity = new Map<RoomType, number>([
  [RoomType.LivingRoom, 2],
  [RoomType.UtilityRoom, 2]
]);
