export enum HousingType {
  CountryHomesAcreage = "countryHomesAcreage",
  SingleFamily = "singleFamily",
  TownhouseCondo = "townhouseCondo",
  MultiFamily = "multiFamily"
}

export const HousingTypeLabel = new Map<HousingType, string>([
  [HousingType.CountryHomesAcreage, "Country Homes/Acreage"],
  [HousingType.SingleFamily, "Single-Family"],
  [HousingType.TownhouseCondo, "Townhouse/Condo"],
  [HousingType.MultiFamily, "Multi-Family"]
]);
