import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { spacesDimensionsRules, specialtyRoomsRules } from "../../sections";
import {
  OtherParking,
  OtherParkingLabel,
  SqFtSource,
  SqFtSourceLabel,
  TypeCategory,
  TypeCategoryLabel
} from "markets/sabor/enums";

export const spacesDimensionsInfoRules: FormRules = {
  ...spacesDimensionsRules,
  typeCategory: {
    requiredToSubmit: true,
    label: "Type Category",
    options: getOptionsFromEnumAndMap(
      Object.values(TypeCategory),
      TypeCategoryLabel
    )
  },
  sqFtTotal: {
    requiredToSubmit: true,
    label: "Sqft",
    numberFormat: {
      min: 500,
      max: 10000,
      thousandSeparator: true
    },
    maxLength: 10
  },
  sqFtSource: {
    label: "Sqft Source",
    options: getOptionsFromEnumAndMap(
      Object.values(SqFtSource),
      SqFtSourceLabel
    )
  },
  specialtyRooms: specialtyRoomsRules,
  otherParking: {
    label: "Additional/Other Parking",
    options: getOptionsFromEnumAndMap(
      Object.values(OtherParking),
      OtherParkingLabel
    ),
    rows: 2
  }
};
