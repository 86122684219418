export enum PlannedCommunity {
  Aliana = "aliana",
  Artavia = "artavia",
  Atascocita = "atascocita",
  AuburnLakes = "auburnLakes",
  Balmoral = "balmoral",
  Bentwater = "bentwater",
  BluejackNational = "bluejackNational",
  BrazosTownCenter = "brazosTownCenter",
  Bridgeland = "bridgeland",
  Candela = "candela",
  CaneIsland = "caneIsland",
  CanyonGateatCincoRanch = "canyonGateatCincoRanch",
  CanyonGateatNorthpointe = "canyonGateatNorthpointe",
  CanyonGateattheBrazos = "canyonGateattheBrazos",
  CanyonLakesatStoneGate = "canyonLakesatStoneGate",
  CanyonLakesWest = "canyonLakesWest",
  CincoRanch = "cincoRanch",
  CityPark = "cityPark",
  ClearLakeCity = "clearLakeCity",
  ColesCrossing = "colesCrossing",
  Copperfield = "copperfield",
  CrossCreekRanch = "crossCreekRanch",
  CrownRanch = "crownRanch",
  CypressCreekLakes = "cypressCreekLakes",
  EagleSprings = "eagleSprings",
  Eaglewood = "eaglewood",
  Edgewater = "edgewater",
  Elyson = "elyson",
  Fairfield = "fairfield",
  FalconRanch = "falconRanch",
  FallCreek = "fallCreek",
  Fieldstone = "fieldstone",
  Firethorne = "firethorne",
  FirstColony = "firstColony",
  FulshearCreekCrossing = "fulshearCreekCrossing",
  FulshearLakes = "fulshearLakes",
  GleannlochFarms = "gleannlochFarms",
  GrandCentralPark = "grandCentralPark",
  GrandLakes = "grandLakes",
  GrandMission = "grandMission",
  GraysonLakes = "graysonLakes",
  GraystoneHills = "graystoneHills",
  Greatwood = "greatwood",
  Harborwalk = "harborwalk",
  Harmony = "harmony",
  HarpersPreserve = "harpersPreserve",
  HarvestGreen = "harvestGreen",
  ImperialatSugar = "imperialatSugar",
  ImperialOaks = "imperialOaks",
  JordanRanch = "jordanRanch",
  Kingwood = "kingwood",
  Kresston = "kresston",
  LagoMar = "lagoMar",
  LakeOlympia = "lakeOlympia",
  Lakemont = "lakemont",
  LakesatCreekside = "lakesatCreekside",
  LakesofBellaTerra = "lakesofBellaTerra",
  LakesofBellaTerraWest = "lakesofBellaTerraWest",
  LakesofBrightwater = "lakesofBrightwater",
  LakesofMissionGrove = "lakesofMissionGrove",
  LakesofSavannah = "lakesofSavannah",
  LongMeadowFarms = "longMeadowFarms",
  MagnoliaRidge = "magnoliaRidge",
  Meridiana = "meridiana",
  NewTerritory = "newTerritory",
  Newport = "newport",
  NorthGrove = "northGrove",
  OakhurstatKingwood = "oakhurstatKingwood",
  ParkLakes = "parkLakes",
  PecanGrove = "pecanGrove",
  Pomona = "pomona",
  QuailValley = "quailValley",
  RiverPark = "riverPark",
  RiverParkWest = "riverParkWest",
  Riverstone = "riverstone",
  RodeoPalms = "rodeoPalms",
  SantaRitaRanch = "santaRitaRanch",
  SedonaLakes = "sedonaLakes",
  SevenMeadows = "sevenMeadows",
  ShadowCreekRanch = "shadowCreekRanch",
  Sienna = "sienna",
  SierraVista = "sierraVista",
  SilverLake = "silverLake",
  Silvercreek = "silvercreek",
  SouthShoreHarbour = "southShoreHarbour",
  SouthernTrails = "southernTrails",
  SpringLakes = "springLakes",
  SpringTrails = "springTrails",
  SpringwoodsVillage = "springwoodsVillage",
  Steeplechase = "steeplechase",
  SterlingLakes = "sterlingLakes",
  StoneGate = "stoneGate",
  SugarCreek = "sugarCreek",
  SummerLakes = "summerLakes",
  Summerwood = "summerwood",
  Tamarron = "tamarron",
  Tavola = "tavola",
  Telfair = "telfair",
  TheFallsatImperialOaks = "theFallsatImperialOaks",
  TheGroves = "theGroves",
  TheHighlands = "theHighlands",
  TheWoodlands = "theWoodlands",
  TheWoodlandsHills = "theWoodlandsHills",
  TowneLake = "towneLake",
  Traditions = "traditions",
  TuscanLakes = "tuscanLakes",
  TwinOaks = "twinOaks",
  ValleyRanch = "valleyRanch",
  Vanbrooke = "vanbrooke",
  Veranda = "veranda",
  Vicksburg = "vicksburg",
  WestRanch = "westRanch",
  WestheimerLakes = "westheimerLakes",
  WestonLakes = "westonLakes",
  WestoverPark = "westoverPark",
  WildwoodatNorthpointe = "wildwoodatNorthpointe",
  Windrose = "windrose",
  WoodCreekReserve = "woodCreekReserve",
  WoodforestDevelopment = "woodforestDevelopment",
  WoodsonsReserve = "woodsonsReserve",
  Woodtrace = "woodtrace"
}

export const PlannedCommunityLabel = new Map<PlannedCommunity, string>([
  [PlannedCommunity.Aliana, "Aliana"],
  [PlannedCommunity.Artavia, "Artavia"],
  [PlannedCommunity.Atascocita, "Atascocita"],
  [PlannedCommunity.AuburnLakes, "Auburn Lakes"],
  [PlannedCommunity.Balmoral, "Balmoral"],
  [PlannedCommunity.Bentwater, "Bentwater"],
  [PlannedCommunity.BluejackNational, "Bluejack National"],
  [PlannedCommunity.BrazosTownCenter, "Brazos Town Center"],
  [PlannedCommunity.Bridgeland, "Bridgeland"],
  [PlannedCommunity.Candela, "Candela"],
  [PlannedCommunity.CaneIsland, "Cane Island"],
  [PlannedCommunity.CanyonGateatCincoRanch, "Canyon Gate at Cinco Ranch"],
  [PlannedCommunity.CanyonGateatNorthpointe, "Canyon Gate at Northpointe"],
  [PlannedCommunity.CanyonGateattheBrazos, "Canyon Gate at the Brazos"],
  [PlannedCommunity.CanyonLakesatStoneGate, "Canyon Lakes at Stone Gate"],
  [PlannedCommunity.CanyonLakesWest, "Canyon Lakes West"],
  [PlannedCommunity.CincoRanch, "Cinco Ranch"],
  [PlannedCommunity.CityPark, "City Park"],
  [PlannedCommunity.ClearLakeCity, "Clear Lake City"],
  [PlannedCommunity.ColesCrossing, "Coles Crossing"],
  [PlannedCommunity.Copperfield, "Copperfield"],
  [PlannedCommunity.CrossCreekRanch, "Cross Creek Ranch"],
  [PlannedCommunity.CrownRanch, "Crown Ranch"],
  [PlannedCommunity.CypressCreekLakes, "Cypress Creek Lakes"],
  [PlannedCommunity.EagleSprings, "Eagle Springs"],
  [PlannedCommunity.Eaglewood, "Eaglewood"],
  [PlannedCommunity.Edgewater, "Edgewater"],
  [PlannedCommunity.Elyson, "Elyson"],
  [PlannedCommunity.Fairfield, "Fairfield"],
  [PlannedCommunity.FalconRanch, "Falcon Ranch"],
  [PlannedCommunity.FallCreek, "Fall Creek"],
  [PlannedCommunity.Fieldstone, "Fieldstone"],
  [PlannedCommunity.Firethorne, "Firethorne"],
  [PlannedCommunity.FirstColony, "First Colony"],
  [PlannedCommunity.FulshearCreekCrossing, "Fulshear Creek Crossing"],
  [PlannedCommunity.FulshearLakes, "Fulshear Lakes"],
  [PlannedCommunity.GleannlochFarms, "Gleannloch Farms"],
  [PlannedCommunity.GrandCentralPark, "Grand Central Park"],
  [PlannedCommunity.GrandLakes, "Grand Lakes"],
  [PlannedCommunity.GrandMission, "Grand Mission"],
  [PlannedCommunity.GraysonLakes, "Grayson Lakes"],
  [PlannedCommunity.GraystoneHills, "Graystone Hills"],
  [PlannedCommunity.Greatwood, "Greatwood"],
  [PlannedCommunity.Harborwalk, "Harborwalk"],
  [PlannedCommunity.Harmony, "Harmony"],
  [PlannedCommunity.HarpersPreserve, "Harper's Preserve"],
  [PlannedCommunity.HarvestGreen, "Harvest Green"],
  [PlannedCommunity.ImperialatSugar, "Imperial at Sugar"],
  [PlannedCommunity.ImperialOaks, "Imperial Oaks"],
  [PlannedCommunity.JordanRanch, "Jordan Ranch"],
  [PlannedCommunity.Kingwood, "Kingwood"],
  [PlannedCommunity.Kresston, "Kresston"],
  [PlannedCommunity.LagoMar, "Lago Mar"],
  [PlannedCommunity.LakeOlympia, "Lake Olympia"],
  [PlannedCommunity.Lakemont, "Lakemont"],
  [PlannedCommunity.LakesatCreekside, "Lakes at Creekside"],
  [PlannedCommunity.LakesofBellaTerra, "Lakes of Bella Terra"],
  [PlannedCommunity.LakesofBellaTerraWest, "Lakes of Bella Terra West"],
  [PlannedCommunity.LakesofBrightwater, "Lakes of Brightwater"],
  [PlannedCommunity.LakesofMissionGrove, "Lakes of Mission Grove"],
  [PlannedCommunity.LakesofSavannah, "Lakes of Savannah"],
  [PlannedCommunity.LongMeadowFarms, "Long Meadow Farms"],
  [PlannedCommunity.MagnoliaRidge, "Magnolia Ridge"],
  [PlannedCommunity.Meridiana, "Meridiana"],
  [PlannedCommunity.NewTerritory, "New Territory"],
  [PlannedCommunity.Newport, "Newport"],
  [PlannedCommunity.NorthGrove, "North Grove"],
  [PlannedCommunity.OakhurstatKingwood, "Oakhurst at Kingwood"],
  [PlannedCommunity.ParkLakes, "Park Lakes"],
  [PlannedCommunity.PecanGrove, "Pecan Grove"],
  [PlannedCommunity.Pomona, "Pomona"],
  [PlannedCommunity.QuailValley, "Quail Valley"],
  [PlannedCommunity.RiverPark, "River Park"],
  [PlannedCommunity.RiverParkWest, "River Park West"],
  [PlannedCommunity.Riverstone, "Riverstone"],
  [PlannedCommunity.RodeoPalms, "Rodeo Palms"],
  [PlannedCommunity.SantaRitaRanch, "Santa Rita Ranch"],
  [PlannedCommunity.SedonaLakes, "Sedona Lakes"],
  [PlannedCommunity.SevenMeadows, "Seven Meadows"],
  [PlannedCommunity.ShadowCreekRanch, "Shadow Creek Ranch"],
  [PlannedCommunity.Sienna, "Sienna"],
  [PlannedCommunity.SierraVista, "Sierra Vista"],
  [PlannedCommunity.SilverLake, "Silver Lake"],
  [PlannedCommunity.Silvercreek, "Silvercreek"],
  [PlannedCommunity.SouthShoreHarbour, "South Shore Harbour"],
  [PlannedCommunity.SouthernTrails, "Southern Trails"],
  [PlannedCommunity.SpringLakes, "Spring Lakes"],
  [PlannedCommunity.SpringTrails, "Spring Trails"],
  [PlannedCommunity.SpringwoodsVillage, "Springwoods Village"],
  [PlannedCommunity.Steeplechase, "Steeplechase"],
  [PlannedCommunity.SterlingLakes, "Sterling Lakes"],
  [PlannedCommunity.StoneGate, "Stone Gate"],
  [PlannedCommunity.SugarCreek, "Sugar Creek"],
  [PlannedCommunity.SummerLakes, "Summer Lakes"],
  [PlannedCommunity.Summerwood, "Summerwood"],
  [PlannedCommunity.Tamarron, "Tamarron"],
  [PlannedCommunity.Tavola, "Tavola"],
  [PlannedCommunity.Telfair, "Telfair"],
  [PlannedCommunity.TheFallsatImperialOaks, "The Falls at Imperial Oaks"],
  [PlannedCommunity.TheGroves, "The Groves"],
  [PlannedCommunity.TheHighlands, "The Highlands"],
  [PlannedCommunity.TheWoodlands, "The Woodlands"],
  [PlannedCommunity.TheWoodlandsHills, "The Woodlands Hills"],
  [PlannedCommunity.TowneLake, "Towne Lake"],
  [PlannedCommunity.Traditions, "Traditions"],
  [PlannedCommunity.TuscanLakes, "Tuscan Lakes"],
  [PlannedCommunity.TwinOaks, "Twin Oaks"],
  [PlannedCommunity.ValleyRanch, "Valley Ranch"],
  [PlannedCommunity.Vanbrooke, "Vanbrooke"],
  [PlannedCommunity.Veranda, "Veranda"],
  [PlannedCommunity.Vicksburg, "Vicksburg"],
  [PlannedCommunity.WestRanch, "West Ranch"],
  [PlannedCommunity.WestheimerLakes, "Westheimer Lakes"],
  [PlannedCommunity.WestonLakes, "Weston Lakes"],
  [PlannedCommunity.WestoverPark, "Westover Park"],
  [PlannedCommunity.WildwoodatNorthpointe, "Wildwood at Northpointe"],
  [PlannedCommunity.Windrose, "Windrose"],
  [PlannedCommunity.WoodCreekReserve, "WoodCreek Reserve"],
  [PlannedCommunity.WoodforestDevelopment, "Woodforest Development"],
  [PlannedCommunity.WoodsonsReserve, "Woodson's Reserve"],
  [PlannedCommunity.Woodtrace, "Woodtrace"]
]);
