import { FormRules } from "@homesusa/form";
import { utilitiesRules } from "../../sections";
import {
  Appliances,
  AppliancesLabel,
  DetachedStructures,
  DetachedStructuresLabel,
  HomeFaces,
  HomeFacesLabel,
  HousingStyle,
  HousingStyleLabel,
  NameOfPid,
  NameOfPidLabel,
  ParkingFeatures,
  ParkingFeaturesLabel,
  SpecialFeatures,
  SpecialFeaturesLabel
} from "markets/amarillo/enums";
import {
  getOptionsFromEnumAndMap,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";

export const featuresInfoRules: FormRules = {
  ...utilitiesRules,
  propertyDescription: {
    label: "Property Description",
    requiredToSubmit: true,
    maxLength: 800,
    minLength: 25,
    minLengthSuggestion: 100,
    helperText:
      "MLS number, Built by <BUILDER> and Completion Info will be added as a prefix to this field. DO NOT INCLUDE."
  },
  isAuctioned: {
    label: "Auctioned"
  },
  hasForeignSeller: {
    label: "Foreign Seller"
  },
  hasLenderOwned: {
    label: "Lender Owned"
  },
  hasDistressedSale: {
    label: "Distressed Sale"
  },
  hasPotentialShortSale: {
    label: "Potential Short Sale"
  },
  hasTenant: {
    label: "Tenant"
  },
  hasForeclosed: {
    label: "Foreclosed"
  },
  hasSurveillanceEquipment: {
    label: "Surveillance equipment on-site"
  },
  dateLeaseExpires: {
    label: "Date Lease Expires",
    tooltip: "If home is for sale and lease, enter a date"
  },
  hasHollywoodJackAndJill: {
    label: "Hollywood/Jack&Jill"
  },
  hasPowder: {
    label: "Powder"
  },
  specialFeatures: {
    label: "Special Features",
    options: getOptionsFromEnumAndRecord(
      Object.values(SpecialFeatures),
      SpecialFeaturesLabel
    ),
    max: 19
  },
  otherSpecialFeatures: {
    label: "Other Special Features"
  },
  parkingFeatures: {
    label: "Parking Features",
    options: getOptionsFromEnumAndRecord(
      Object.values(ParkingFeatures),
      ParkingFeaturesLabel
    ),
    lastOptions: [ParkingFeatures.None],
    noneOption: [ParkingFeatures.None],
    max: 4
  },
  appliances: {
    label: "Appliances",
    options: getOptionsFromEnumAndRecord(
      Object.values(Appliances),
      AppliancesLabel
    ),
    max: 8
  },
  hasCarport: {
    label: "Carport"
  },
  hasRvParking: {
    label: "RV Parking"
  },
  garageRemarks: {
    label: "Garage Remarks"
  },
  housingStyle: {
    label: "Housing Style",
    options: getOptionsFromEnumAndMap(
      Object.values(HousingStyle),
      HousingStyleLabel
    )
  },
  detachedStructures: {
    label: "Detached Structures",
    options: getOptionsFromEnumAndRecord(
      Object.values(DetachedStructures),
      DetachedStructuresLabel
    ),
    lastOptions: [DetachedStructures.None],
    noneOption: [DetachedStructures.None]
  },
  homeFaces: {
    label: "Dwelling Faces",
    options: getOptionsFromEnumAndRecord(
      Object.values(HomeFaces),
      HomeFacesLabel
    )
  },
  nameOfPID: {
    label: "Name of PID",
    options: getOptionsFromEnumAndRecord(
      Object.values(NameOfPid),
      NameOfPidLabel
    )
  },
  isPropertyInPID: {
    label: "Is Property in PID?"
  },
  isInsideCityLimits: {
    label: "Inside City Limits"
  }
};
