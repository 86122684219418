export enum ShowingInstructions {
  Office = "office",
  Owner = "owner",
  Agent = "agent",
  ShowingTime = "showingTime"
}
export const ShowingInstructionsLabel = new Map<ShowingInstructions, string>([
  [ShowingInstructions.Office, "Office"],
  [ShowingInstructions.Owner, "Owner"],
  [ShowingInstructions.Agent, "Agent"],
  [ShowingInstructions.ShowingTime, "Showing Time"]
]);
