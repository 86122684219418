export enum Utility {
  AboveGroundUtilities = "aboveGroundUtilities",
  CableAvailable = "cableAvailable",
  ElectricityAvailable = "electricityAvailable",
  InternetCable = "internetCable",
  InternetFiber = "internetFiber",
  InternetSatelliteOther = "internetSatelliteOther",
  NaturalGasAvailable = "naturalGasAvailable",
  PhoneAvailable = "phoneAvailable",
  Propane = "propane",
  SewerAvailable = "sewerAvailable",
  SewerConnected = "sewerConnected",
  Solar = "solar",
  UndergroundUtilities = "undergroundUtilities",
  WaterAvailable = "waterAvailable",
  WaterConnected = "waterConnected",
  None = "none",
  SeeRemarks = "seeRemarks"
}
export const UtilityLabel = new Map<Utility, string>([
  [Utility.AboveGroundUtilities, "Above Ground Utilities"],
  [Utility.CableAvailable, "Cable Available"],
  [Utility.ElectricityAvailable, "Electricity Available"],
  [Utility.InternetCable, "Internet-Cable"],
  [Utility.InternetFiber, "Internet-Fiber"],
  [Utility.InternetSatelliteOther, "Internet-Satellite/Other"],
  [Utility.NaturalGasAvailable, "Natural Gas Available"],
  [Utility.PhoneAvailable, "Phone Available"],
  [Utility.Propane, "Propane"],
  [Utility.SewerAvailable, "Sewer Available"],
  [Utility.SewerConnected, "Sewer Connected"],
  [Utility.Solar, "Solar"],
  [Utility.UndergroundUtilities, "Underground Utilities"],
  [Utility.WaterAvailable, "Water Available"],
  [Utility.WaterConnected, "Water Connected"],
  [Utility.None, "None"],
  [Utility.SeeRemarks, "See Remarks"]
]);
