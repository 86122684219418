export enum GreenCertification {
  TwelveInchPlusAtticInsulation = "twelveInchPlusAtticInsulation",
  Appliances = "appliances",
  Construction = "construction",
  Doors = "doors",
  DroughtTolerantPlants = "droughtTolerantPlants",
  EnergyRecoveryVentilator = "energyRecoveryVentilator",
  EnhancedAirFiltration = "enhancedAirFiltration",
  ETIrrigationControl = "etIrrigationControl",
  ExposureShade = "exposureShade",
  GeoThermalHVAC = "geoThermalHVAC",
  HVAC = "hvac",
  Insulation = "insulation",
  Lighting = "lighting",
  LowFlowCommode = "lowFlowCommode",
  MechanicalFreshAir = "mechanicalFreshAir",
  RainFreezeSensors = "rainFreezeSensors",
  Thermostat = "thermostat",
  Waterheater = "waterheater",
  Window = "window"
}

export const GreenCertificationLabel = new Map<GreenCertification, string>([
  [
    GreenCertification.TwelveInchPlusAtticInsulation,
    "12 inch+ Attic Insulation"
  ],
  [GreenCertification.Appliances, "Appliances"],
  [GreenCertification.Construction, "Construction"],
  [GreenCertification.Doors, "Doors"],
  [GreenCertification.DroughtTolerantPlants, "Drought Tolerant Plants"],
  [GreenCertification.EnergyRecoveryVentilator, "Energy Recovery Ventilator"],
  [GreenCertification.EnhancedAirFiltration, "Enhanced Air Filtration"],
  [GreenCertification.ETIrrigationControl, "ET Irrigation Control"],
  [GreenCertification.ExposureShade, "Exposure/Shade"],
  [GreenCertification.GeoThermalHVAC, "Geo-thermal HVAC"],
  [GreenCertification.HVAC, "HVAC"],
  [GreenCertification.Insulation, "Insulation"],
  [GreenCertification.Lighting, "Lighting"],
  [GreenCertification.LowFlowCommode, "Low Flow Commode"],
  [GreenCertification.MechanicalFreshAir, "Mechanical Fresh Air"],
  [GreenCertification.RainFreezeSensors, "Rain / Freeze Sensors"],
  [GreenCertification.Thermostat, "Thermostat"],
  [GreenCertification.Waterheater, "Waterheater"],
  [GreenCertification.Window, "Windows"]
]);
