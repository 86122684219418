export enum AcceptableFinancing {
  CashSale = "cashSale",
  Conventional = "conventional",
  TexasVeteransLandBoard = "texasVeteransLandBoard",
  Other = "other",
  Fha = "fha",
  UsdaLoan = "usdaLoan",
  SellerContribution = "sellerContribution",
  Investor = "investor",
  Va = "va"
}

export const AcceptableFinancingLabel = new Map<AcceptableFinancing, string>([
  [AcceptableFinancing.CashSale, "Cash Sale"],
  [AcceptableFinancing.Conventional, "Conventional"],
  [AcceptableFinancing.TexasVeteransLandBoard, "Texas Veterans Land Board"],
  [AcceptableFinancing.Other, "Other"],
  [AcceptableFinancing.Fha, "FHA"],
  [AcceptableFinancing.UsdaLoan, "USDA Loan"],
  [
    AcceptableFinancing.SellerContribution,
    "Seller to Contribute to Buyer's Closing Costs"
  ],
  [AcceptableFinancing.Investor, "Investor"],
  [AcceptableFinancing.Va, "VA"]
]);
