import React from "react";
import { Typography, Stack } from "@mui/material";

import { useUserProfile, AuthCompanyContext } from "@homesusa/auth";

export const GreetingMessage = (): JSX.Element => {
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const user = useUserProfile();

  const salute = React.useMemo((): string => {
    const hour = new Date().getHours();

    // isMorning
    if (hour > 6 && hour < 12) {
      return `Good Morning, ${user?.firstName}`;
    }
    // isAfternoon
    if (hour >= 12 && hour <= 17) {
      return `Good Afternoon, ${user?.firstName}`;
    }
    // isEvening
    if (hour > 17 && hour <= 24) {
      return `Good Evening, ${user?.firstName}`;
    }
    // isNight
    if (hour > 0 && hour <= 6) {
      return `Good Night, ${user?.firstName}`;
    }
    return `Hello, ${user?.firstName}`;
  }, [user]);

  return (
    <Stack spacing={1} ml={3}>
      <Typography variant="h1">{salute}</Typography>
      <Typography variant="caption">
        {`Here's what's going on with your communities ${
          currentCompany?.name ? `at ${currentCompany.name}` : ""
        }`}
      </Typography>
    </Stack>
  );
};
