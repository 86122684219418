import React from "react";
import { Box, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useHasRole } from "@homesusa/auth";
import { Gauge } from "../charts/gauge/gauge.component";
import { ChartContext } from "../../../context";
import { useStyles } from "./left-panel.styles";

export const AlertButtons = (): JSX.Element => {
  const navigate = useNavigate();
  const { chartState } = React.useContext(ChartContext);
  const { isMlsAdministrator } = useHasRole();
  const classes = useStyles();

  if (isMlsAdministrator) {
    return (
      <Box sx={classes.gaugeContainer}>
        <Paper
          sx={classes.paperAction}
          elevation={3}
          onClick={(): void => navigate("/alerts#detaileds")}
        >
          <Gauge
            value={chartState.totalDetailds.value}
            total={chartState.totalDetailds.total}
            legend="Detailed Alerts"
          />
        </Paper>

        <Paper
          sx={classes.paperAction}
          elevation={3}
          onClick={(): void => navigate("/alerts#xml")}
        >
          <Gauge
            value={chartState.totalXml.value}
            total={chartState.totalXml.total}
            legend="Xml"
          />
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={classes.gaugeContainer}>
      <Paper
        sx={classes.paperAction}
        elevation={3}
        onClick={(): void => navigate("/alerts#urgent")}
      >
        <Gauge
          value={chartState.totalUrgents.value}
          total={chartState.totalUrgents.total}
          legend="Urgent"
        />
      </Paper>

      <Paper
        sx={classes.paperAction}
        elevation={3}
        onClick={(): void => navigate("/alerts#reminders")}
      >
        <Gauge
          value={chartState.totalRemiders.value}
          total={chartState.totalRemiders.total}
          legend="Reminder"
        />
      </Paper>
    </Box>
  );
};
