import axios from "axios";
import {
  CompanyEmployee,
  MarketCode,
  ServiceSubscriptions
} from "@homesusa/core";
import { Company } from "core/interfaces/company";
import { GridResponse } from "@homesusa/grid-table";

const apiUrl = process.env.REACT_APP_API_GATEWAY + "/api/companies";
const apiCompany = process.env.REACT_APP_API_COMPANY + "/api/companies";

const getCompanies = async (params: {
  marketCode?: MarketCode;
  corporationId?: string;
}): Promise<GridResponse<Company>> => {
  const response = await axios.get<GridResponse<Company>>(apiCompany, {
    params
  });
  return response.data;
};

const getCompanyInfo = async (id: string): Promise<Company> => {
  const response = await axios.get(`${apiUrl}/${id}`);
  const data: Company = response.data as Company;
  data.id = id;
  return data;
};

const getCompanyEmployees = async (
  companyId: string
): Promise<Array<CompanyEmployee>> => {
  const response: { data: Array<CompanyEmployee> } = await axios.get(
    `${apiUrl}/${companyId}/employees`
  );
  const data = response.data;
  return data;
};

const getCompanyServices = async (
  id: string
): Promise<GridResponse<ServiceSubscriptions>> => {
  const response = await axios.get<GridResponse<ServiceSubscriptions>>(
    `${apiUrl}/${id}/service-subscriptions`
  );
  return response.data;
};

export {
  getCompanies,
  getCompanyInfo,
  getCompanyEmployees,
  getCompanyServices
};
