export enum MineralsFeatures {
  All = "all",
  Partial = "partial",
  None = "none",
  SeeRemarks = "seeRemarks"
}
export const MineralsFeaturesLabel = new Map<MineralsFeatures, string>([
  [MineralsFeatures.All, "All"],
  [MineralsFeatures.Partial, "Partial"],
  [MineralsFeatures.None, "None"],
  [MineralsFeatures.SeeRemarks, "See Remarks"]
]);
