import React from "react";
import { Typography } from "@mui/material";

export const ContractDateTooltip = (): JSX.Element => (
  <Typography variant="caption" display={"block"}>
    <strong>Contract Date</strong> is the date the home went{" "}
    <strong>under contract</strong> (the day the sales contract was executed by
    the builder and the buyer).
  </Typography>
);

export const EstimatedCloseDateTooltip = (): JSX.Element => (
  <Typography variant="caption" display={"block"}>
    <strong>Estimated Close Date</strong> is the date you estimate the home{" "}
    <strong>will fund.</strong>
  </Typography>
);

export const ClosedDateTooltip = (): JSX.Element => (
  <Typography variant="caption" display={"block"}>
    <strong>Closing Date</strong> is the date the home sold and{" "}
    <strong>funded.</strong>
  </Typography>
);

export const WithdrawalDateTooltip = (): JSX.Element => (
  <Typography variant="caption" display={"block"}>
    <strong>Withdrawal Date</strong> is the date the home{" "}
    <strong>was withdrawn</strong>
  </Typography>
);

export const PendingDateTooltip = (): JSX.Element => (
  <Typography variant="caption" display={"block"}>
    <strong>Pending Date </strong>is the date the home went{" "}
    <strong>under contract</strong> (the day the sales contract was executed by
    the builder and the buyer).
  </Typography>
);
