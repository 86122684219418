import { FormRules } from "@homesusa/form";
import { countyRules, harCityRule } from "../sections";
import { ownerNameRule } from "./common-rules";
import { communityNameRule } from "modules/communities/rules/common-rules";

export const quickCreateCommunityRules: FormRules = {
  name: communityNameRule,
  ownerName: ownerNameRule,
  city: {
    ...harCityRule,
    required: true
  },
  county: countyRules
};
