export enum TypeCategory {
  Barndominium = "barndominium",
  Condominium = "condominium",
  GardenPatioHome = "gardenPatioHome",
  ManufacturedHome = "manufacturedHome",
  MobileHome = "mobileHome",
  ModularPrefab = "modularPrefab",
  SFM = "sfm",
  Townhouse = "townhouse"
}

export const TypeCategoryLabel = new Map<TypeCategory, string>([
  [TypeCategory.Barndominium, "Barndominium"],
  [TypeCategory.Condominium, "Condominium"],
  [TypeCategory.GardenPatioHome, "Garden/Patio Home"],
  [TypeCategory.ManufacturedHome, "Manufactured Home"],
  [TypeCategory.MobileHome, "Mobile Home"],
  [TypeCategory.ModularPrefab, "Modular/Prefab"],
  [TypeCategory.SFM, "Single Family"],
  [TypeCategory.Townhouse, "Townhouse"]
]);
