export enum HousingStyle {
  Colonial = "colonial",
  Condo = "condo",
  ContemporaryModern = "contemporaryModern",
  Craftsman = "craftsman",
  EnglishTudor = "englishTudor",
  French = "french",
  GardenHome = "gardenHome",
  HillCountry = "hillCountry",
  MediterraneanSpanish = "mediterraneanSpanish",
  Ranch = "ranch",
  Spanish = "spanish",
  SplitLevel = "splitLevel",
  Townhome = "townhome",
  Traditional = "traditional",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const HousingStyleLabel = new Map<HousingStyle, string>([
  [HousingStyle.Colonial, "Colonial"],
  [HousingStyle.Condo, "Condo"],
  [HousingStyle.ContemporaryModern, "Contemporary/Modern"],
  [HousingStyle.Craftsman, "Craftsman"],
  [HousingStyle.EnglishTudor, "English/Tudor"],
  [HousingStyle.French, "French"],
  [HousingStyle.GardenHome, "Garden Home"],
  [HousingStyle.HillCountry, "Hill Country"],
  [HousingStyle.MediterraneanSpanish, "Mediterranean/Spanish"],
  [HousingStyle.Ranch, "Ranch"],
  [HousingStyle.Spanish, "Spanish"],
  [HousingStyle.SplitLevel, "Split Level"],
  [HousingStyle.Townhome, "Townhome"],
  [HousingStyle.Traditional, "Traditional"],
  [HousingStyle.OtherSeeRemarks, "Other-See Remarks"]
]);
