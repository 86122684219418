export enum WaterSewerFeatures {
  City = "city",
  Septic = "septic",
  None = "none"
}

export const WaterSeweerFeaturesLabel: Record<WaterSewerFeatures, string> = {
  [WaterSewerFeatures.City]: "City",
  [WaterSewerFeatures.Septic]: "Septic",
  [WaterSewerFeatures.None]: "None"
};
