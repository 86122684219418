import React from "react";
import { QueryStats } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import CanvasJSReact from "app/assets/charts/canvasjs.react";
import { RangeChartPoint } from "modules/dashboard/interfaces";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

interface Props {
  data: RangeChartPoint[];
  height?: number;
  noHasData?: boolean;
}

export const RangeBarChart = (props: Props): JSX.Element => {
  const { data, height = 200 } = props;
  const points = data
    .map((item) => ({
      y: parseInt(item.percentage.toFixed(0)),
      color: item.color
    }))
    .sort((a, b) => b.y - a.y);

  const options = {
    animationEnabled: true,
    data: [
      {
        type: "funnel",
        indexLabel: "{y}",
        neckWidth: 5,
        neckHeight: 0,
        dataPoints: points
      }
    ]
  };
  const containerProps = {
    height: `${height}px`
  };
  if (props.noHasData)
    return (
      <Box
        minHeight="200px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <QueryStats sx={{ fontSize: "50px" }} color="disabled" />
        <Typography variant="h3" color="GrayText">
          No data available
        </Typography>
      </Box>
    );

  return <CanvasJSChart options={options} containerProps={containerProps} />;
};
