export enum CoolingSystem {
  CeilingFan = "ceilingFan",
  CentralAir = "centralAir",
  Dual = "dual",
  Electric = "electric",
  EnergyStarQualifiedEquipment = "energyStarQualifiedEquipment",
  MultiUnits = "multiUnits",
  Zoned = "zoned"
}
export const CoolingSystemLabel = new Map<CoolingSystem, string>([
  [CoolingSystem.CeilingFan, "Ceiling Fan(s)"],
  [CoolingSystem.CentralAir, "Central Air"],
  [CoolingSystem.Dual, "Dual"],
  [CoolingSystem.Electric, "Electric"],
  [
    CoolingSystem.EnergyStarQualifiedEquipment,
    "ENERGY STAR Qualified Equipment"
  ],
  [CoolingSystem.MultiUnits, "Multi Units"],
  [CoolingSystem.Zoned, "Zoned"]
]);
