export enum YearBuiltSource {
  AppraisalDistrict = "appraisalDistrict",
  Appraiser = "appraiser",
  Builder = "builder",
  Estimated = "estimated",
  OwnerSeller = "ownerSeller",
  PublicRecords = "publicRecords",
  Unknown = "unknown",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const YearBuiltSourceLabel = new Map<YearBuiltSource, string>([
  [YearBuiltSource.AppraisalDistrict, "Appraisal District"],
  [YearBuiltSource.Appraiser, "Appraiser"],
  [YearBuiltSource.Builder, "Builder"],
  [YearBuiltSource.Estimated, "Estimated"],
  [YearBuiltSource.OwnerSeller, "Owner/Seller"],
  [YearBuiltSource.PublicRecords, "Public Records"],
  [YearBuiltSource.Unknown, "Unknown"],
  [YearBuiltSource.OtherSeeRemarks, "Other-See Remarks"]
]);
