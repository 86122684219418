export enum RoofDescription {
  BuiltUp = "builtUp",
  Composition = "composition",
  Copper = "copper",
  FlatTile = "flatTile",
  GreenRoof = "greenRoof",
  Shingle = "shingle",
  Slate = "slate",
  SpanishTile = "spanishTile",
  Synthetic = "synthetic",
  Tile = "tile",
  Wood = "wood",
  Metal = "metal"
}
export const RoofDescriptionLabel = new Map<RoofDescription, string>([
  [RoofDescription.BuiltUp, "Built-Up"],
  [RoofDescription.Composition, "Composition"],
  [RoofDescription.Copper, "Copper"],
  [RoofDescription.FlatTile, "Flat Tile"],
  [RoofDescription.GreenRoof, "Green Roof"],
  [RoofDescription.Shingle, "Shingle"],
  [RoofDescription.Slate, "Slate"],
  [RoofDescription.SpanishTile, "Spanish Tile"],
  [RoofDescription.Synthetic, "Synthetic"],
  [RoofDescription.Tile, "Tile"],
  [RoofDescription.Wood, "Wood"],
  [RoofDescription.Metal, "Metal"]
]);
