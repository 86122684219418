export enum HeatSystem {
  Ceiling = "ceiling",
  Central = "central",
  Electric = "electric",
  EnergyStarQualifiedEquipment = "energyStarQualifiedEquipment",
  ExhaustFan = "exhaustFan",
  Fireplace = "fireplace",
  NaturalGas = "naturalGas",
  PassiveSolar = "passiveSolar",
  Propane = "propane",
  Zoned = "zoned"
}
export const HeatSystemLabel = new Map<HeatSystem, string>([
  [HeatSystem.Ceiling, "Ceiling"],
  [HeatSystem.Central, "Central"],
  [HeatSystem.Electric, "Electric"],
  [HeatSystem.EnergyStarQualifiedEquipment, "ENERGY STAR Qualified Equipment"],
  [HeatSystem.ExhaustFan, "Exhaust Fan"],
  [HeatSystem.Fireplace, "Fireplace(s)"],
  [HeatSystem.NaturalGas, "Natural Gas"],
  [HeatSystem.PassiveSolar, "Passive Solar"],
  [HeatSystem.Propane, "Propane"],
  [HeatSystem.Zoned, "Zoned"]
]);
