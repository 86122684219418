export enum WaterConservation {
  EfficientHotWaterDistribution = "efficientHotWaterDistribution",
  LowFlowFixtures = "lowFlowFixtures",
  WaterSmartLandscaping = "waterSmartLandscaping",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const WaterConservationLabel = new Map<WaterConservation, string>([
  [
    WaterConservation.EfficientHotWaterDistribution,
    "Efficient Hot Water Distribution"
  ],
  [WaterConservation.LowFlowFixtures, "Low-Flow Fixtures"],
  [WaterConservation.WaterSmartLandscaping, "Water-Smart Landscaping"],
  [WaterConservation.OtherSeeRemarks, "Other-See Remarks"]
]);
