export enum Fencing {
  BarbedWire = "barbedWire",
  ChainLink = "chainLink",
  Masonry = "masonry",
  Pipe = "pipe",
  SmoothWire = "smoothWire",
  Vinyl = "vinyl",
  WoodFence = "woodFence",
  WroughtIron = "wroughtIron",
  None = "none",
  OtherFence = "otherFence"
}

export const FencingLabel: Record<Fencing, string> = {
  [Fencing.BarbedWire]: "Barbed Wire",
  [Fencing.ChainLink]: "Chain Link",
  [Fencing.Masonry]: "Masonry",
  [Fencing.Pipe]: "Pipe",
  [Fencing.SmoothWire]: "Smooth Wire",
  [Fencing.Vinyl]: "Vinyl",
  [Fencing.WoodFence]: "Wood Fence",
  [Fencing.WroughtIron]: "Wrought Iron",
  [Fencing.None]: "None",
  [Fencing.OtherFence]: "Other Fence"
};
