import { FormRules, FieldRulesProps } from "@homesusa/form";
import { emailLeadRules, communityNameRule } from "modules/communities/rules";
import { amarilloSalesOfficeRules } from "./sales-office";
import { ownerNameRule } from "../common-rules";

export const profileRules: Record<string, FormRules | FieldRulesProps> = {
  emailMailViolationsWarnings: {
    label: "Email Violations Warnings",
    isMultiple: true
  },
  name: communityNameRule,
  ownerName: ownerNameRule,
  officePhone: { label: "Office Phone" },
  emailLead: emailLeadRules,
  salesOffice: amarilloSalesOfficeRules
};
