export enum CoolingSystem {
  OneCentral = "oneCentral",
  TwoCentral = "twoCentral",
  ThreePlusCentral = "threePlusCentral",
  Other = "other",
  Zoned = "zoned"
}

export const CoolingSystemLabel = new Map<CoolingSystem, string>([
  [CoolingSystem.OneCentral, "One Central"],
  [CoolingSystem.TwoCentral, "Two Central"],
  [CoolingSystem.ThreePlusCentral, "Three+ Central"],
  [CoolingSystem.Other, "Other"],
  [CoolingSystem.Zoned, "Zoned"]
]);
