export enum NeighborhoodAmenities {
  None = "none",
  BasketballCourt = "basketballCourt",
  BbqGrill = "bbqGrill",
  BikeTrails = "bikeTrails",
  Clubhouse = "clubhouse",
  ControlledAccess = "controlledAccess",
  SportsCourt = "sportsCourt",
  GolfCourse = "golfCourse",
  GuardedAccess = "guardedAccess",
  ParkPlayground = "parkPlayground",
  Pool = "pool",
  Tennis = "tennis",
  JoggingTrails = "joggingTrails",
  VolleyballCourt = "volleyballCourt",
  FishingPier = "fishingPier",
  WaterfrontAccess = "waterfrontAccess"
}
export const NeighborhoodAmenitiesLabel = new Map<
  NeighborhoodAmenities,
  string
>([
  [NeighborhoodAmenities.None, "None"],
  [NeighborhoodAmenities.BasketballCourt, "Basketball Court"],
  [NeighborhoodAmenities.BbqGrill, "BBQ/Grill"],
  [NeighborhoodAmenities.BikeTrails, "Bike Trails"],
  [NeighborhoodAmenities.Clubhouse, "Clubhouse"],
  [NeighborhoodAmenities.ControlledAccess, "Controlled Access"],
  [NeighborhoodAmenities.SportsCourt, "Sports Court"],
  [NeighborhoodAmenities.GolfCourse, "Golf Course"],
  [NeighborhoodAmenities.GuardedAccess, "Guarded Access"],
  [NeighborhoodAmenities.ParkPlayground, "Park/Playground"],
  [NeighborhoodAmenities.Pool, "Pool"],
  [NeighborhoodAmenities.Tennis, "Tennis"],
  [NeighborhoodAmenities.JoggingTrails, "Jogging Trails"],
  [NeighborhoodAmenities.VolleyballCourt, "Volleyball Court"],
  [NeighborhoodAmenities.FishingPier, "Fishing Pier"],
  [NeighborhoodAmenities.WaterfrontAccess, "Waterfront Access"]
]);
