import React, { Context } from "react";

import { AppContext } from "@homesusa/layout";
import { RoomsContextProps, RoomRules } from "core/interfaces/rooms";

export const useOnSaveRoom = <
  TRoomType extends string,
  TRoom extends { roomType: TRoomType },
  TRoomRules extends RoomRules,
  TRoomsContextProps extends RoomsContextProps<TRoomType, TRoom, TRoomRules>
>(
  roomContext: Context<TRoomsContextProps>,
  roomTypeQuantity: Map<TRoomType, number>,
  roomTypeLabel: Map<TRoomType, string>,
  maxRooms = 25
): ((roomState: TRoom) => void) => {
  const { addAlert } = React.useContext(AppContext);
  const { rooms, formDispatch } =
    React.useContext<TRoomsContextProps>(roomContext);
  const addRoomError = (errorMessage: string): void =>
    addAlert({
      message: errorMessage,
      variant: "error"
    });

  const onSaveRoom = (roomState: TRoom): void => {
    if (!formDispatch) {
      return;
    }
    const roomQty = roomTypeQuantity.get(roomState.roomType) ?? 1;
    if (
      rooms.filter((x) => x.roomType == roomState.roomType).length >= roomQty
    ) {
      addRoomError(
        `Only ${roomQty} ${roomTypeLabel.get(roomState.roomType)} allowed`
      );
    } else if (rooms.length >= maxRooms) {
      addRoomError(`A maximun of ${maxRooms} rooms are allowed.`);
    } else {
      formDispatch({
        type: "AddRoom",
        payload: roomState
      });
    }
  };

  return onSaveRoom;
};
