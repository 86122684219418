import React, { Fragment } from "react";

import { SubmitButton } from "@homesusa/layout";
import { useHasRole } from "@homesusa/auth";
import { LockedStatus } from "core/enums";

export function CloseButton({
  onClick,
  status
}: {
  onClick: () => Promise<void>;
  status?: LockedStatus;
}): JSX.Element {
  const { isMlsAdministrator } = useHasRole();

  if (!status || !isMlsAdministrator || status === LockedStatus.Closed) {
    return <Fragment></Fragment>;
  }

  return (
    <div>
      <SubmitButton
        buttonProps={{
          sx: {
            backgroundColor: "white",
            color: (theme): string => theme.palette.primary.dark,
            borderBlockColor: (theme): string => theme.palette.primary.dark
          }
        }}
        onClick={onClick}
      >
        Close Listing
      </SubmitButton>
    </div>
  );
}
