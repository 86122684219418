import React, { Context, Fragment } from "react";
import { Stack } from "@mui/system";

import { useHasRole } from "@homesusa/auth";
import { AvailableJsonImportStatus, JsonImportStatus } from "core/enums";
import { EntityContext } from "core/interfaces";
import { JsonStatusAlert } from "./json-status-alert.component";

export function JsonStatusButton<
  TEntity extends {
    id: string;
    jsonImportStatus: JsonImportStatus;
  },
  TEntityContext extends EntityContext<TEntity>
>({
  entityContext,
  children
}: {
  entityContext: Context<TEntityContext>;
  children: React.ReactNode;
}): JSX.Element {
  const { isMlsAdministrator } = useHasRole();
  const {
    entity: { jsonImportStatus }
  } = React.useContext(entityContext);

  if (AvailableJsonImportStatus.includes(jsonImportStatus)) {
    return <Fragment></Fragment>;
  }

  if (!isMlsAdministrator) {
    return <JsonStatusAlert status={jsonImportStatus} />;
  }

  return (
    <Stack direction="row" spacing={1}>
      <JsonStatusAlert status={jsonImportStatus} />
      {children}
    </Stack>
  );
}
