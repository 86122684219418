export enum DetachedStructures {
  Apartment = "apartment",
  Barn = "barn",
  BoatDock = "boatDock",
  Cellar = "cellar",
  Gazebo = "gazebo",
  Greenhouse = "greenhouse",
  GuestQuarters = "guestQuarters",
  HorseFacilities = "horseFacilities",
  PoolHouse = "poolHouse",
  StorageBuildings = "storageBuildings",
  StormBuildings = "stormBuildings",
  Workshop = "workshop",
  None = "none"
}

export const DetachedStructuresLabel: Record<DetachedStructures, string> = {
  [DetachedStructures.Apartment]: "Apartment",
  [DetachedStructures.Barn]: "Barn",
  [DetachedStructures.BoatDock]: "Boat Dock",
  [DetachedStructures.Cellar]: "Cellar",
  [DetachedStructures.Gazebo]: "Gazebo",
  [DetachedStructures.Greenhouse]: "Greenhouse",
  [DetachedStructures.GuestQuarters]: "Guest Quarters",
  [DetachedStructures.HorseFacilities]: "Horse Facilities",
  [DetachedStructures.PoolHouse]: "Pool House",
  [DetachedStructures.StorageBuildings]: "Storage Buildings",
  [DetachedStructures.StormBuildings]: "Storm Buildings",
  [DetachedStructures.Workshop]: "Workshop",
  [DetachedStructures.None]: "None"
};
