import React from "react";

import { Format } from "@homesusa/core";
import { Columns } from "@homesusa/grid-table";
import { ListingGrid as Listing } from "core/interfaces";
import { GridMarketCodeLabel } from "core/utils";
import { TruncateText } from "core/components";

type columnListing = Columns<Listing>;

export const useListingGridColumns = (
  cityOptions: { [value: string]: string },
  mlsStatueOptions: { [value: string]: string }
): columnListing => {
  const columns: columnListing = React.useMemo(
    () => [
      {
        Header: "MLS #",
        accessor: "mlsNumber"
      },
      {
        Header: "Market",
        accessor: (data: Listing): string =>
          GridMarketCodeLabel.get(data.marketCode) ?? "",
        canSort: false
      },
      {
        Header: "Builder",
        accessor: (data: Listing): string => data.ownerName,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        ),
        id: "ownerName"
      },
      {
        Header: "Subdivision",
        accessor: (data: Listing): string => data.subdivision,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Address",
        accessor: (data: Listing): string =>
          `${data.streetNum} ${data.streetName}${
            data.streetType ? ` ${data.streetType}` : ""
          }${data.unitNumber ? ` ${data.unitNumber}` : ""}`,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "City",
        accessor: (data: Listing): string =>
          cityOptions[data.city] ?? data.city,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Zip",
        accessor: "zipCode"
      },
      {
        Header: "Price",
        accessor: (data: Listing): string => Format.Money(data.listPrice),
        id: "listPrice"
      },
      {
        Header: "Listed",
        accessor: (data: Listing): string => Format.Date(data.listDate),
        id: "listDate"
      },
      {
        Header: "Status",
        accessor: (data: Listing): string =>
          mlsStatueOptions[data.mlsStatus] ?? data.mlsStatus,
        id: "mlsStatus",
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Updated",
        accessor: (data: Listing): string => Format.Date(data.marketModifiedOn),
        id: "marketModifiedOn"
      },
      {
        Header: "Modified",
        accessor: (data: Listing): string => Format.Date(data.sysModifiedOn),
        id: "sysModifiedOn"
      },
      {
        Header: "Modified By",
        accessor: (data: Listing): string => data.modifiedBy,
        id: "modifiedBy",
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      }
    ],
    [cityOptions, mlsStatueOptions]
  );

  return columns;
};
