export enum HousingStyle {
  Barndominium = "barndominium",
  Colonial = "colonial",
  Contem = "contem",
  Other = "other",
  Ranch = "ranch",
  Spanish = "spanish",
  Trad = "trad",
  Tudor = "tudor"
}

export const HousingStyleLabel: Map<HousingStyle, string> = new Map([
  [HousingStyle.Barndominium, "Barndominium"],
  [HousingStyle.Colonial, "Colonial"],
  [HousingStyle.Contem, "Contem"],
  [HousingStyle.Other, "Other"],
  [HousingStyle.Ranch, "Ranch"],
  [HousingStyle.Spanish, "Spanish"],
  [HousingStyle.Trad, "Trad"],
  [HousingStyle.Tudor, "Tudor"]
]);
