export enum ExteriorFeatures {
  CoveredPatioDeck = "coveredPatioDeck",
  BackYardFenced = "backYardFenced",
  OutdoorFireplace = "outdoorFireplace",
  PartiallyFenced = "partiallyFenced",
  NotFenced = "notFenced",
  SideYard = "sideYard",
  Balcony = "balcony",
  ControlledSubdivisionAccess = "controlledSubdivisionAccess",
  StormShutters = "stormShutters",
  RooftopDeck = "rooftopDeck",
  ScreenedPorch = "screenedPorch",
  SpaHotTub = "spaHotTub",
  OutdoorKitchen = "outdoorKitchen",
  BackYard = "backYard",
  WheelchairAccess = "wheelchairAccess",
  FullyFenced = "fullyFenced",
  PatioDeck = "patioDeck",
  Porch = "porch",
  SubdivisionTennisCourt = "subdivisionTennisCourt",
  BackGreenSpace = "backGreenSpace",
  SprinklerSystem = "sprinklerSystem"
}

export const ExteriorFeaturesLabel = new Map<ExteriorFeatures, string>([
  [ExteriorFeatures.CoveredPatioDeck, "Covered Patio/Deck"],
  [ExteriorFeatures.BackYardFenced, "Back Yard Fenced"],
  [ExteriorFeatures.OutdoorFireplace, "Outdoor Fireplace"],
  [ExteriorFeatures.PartiallyFenced, "Partially Fenced"],
  [ExteriorFeatures.NotFenced, "Not Fenced"],
  [ExteriorFeatures.SideYard, "Side Yard"],
  [ExteriorFeatures.Balcony, "Balcony"],
  [
    ExteriorFeatures.ControlledSubdivisionAccess,
    "Controlled Subdivision Access"
  ],
  [ExteriorFeatures.StormShutters, "Storm Shutters"],
  [ExteriorFeatures.RooftopDeck, "Rooftop Deck"],
  [ExteriorFeatures.ScreenedPorch, "Screened Porch"],
  [ExteriorFeatures.SpaHotTub, "Spa/Hot Tub"],
  [ExteriorFeatures.OutdoorKitchen, "Outdoor Kitchen"],
  [ExteriorFeatures.BackYard, "Back Yard"],
  [ExteriorFeatures.WheelchairAccess, "Wheelchair Access"],
  [ExteriorFeatures.FullyFenced, "Fully Fenced"],
  [ExteriorFeatures.PatioDeck, "Patio/Deck"],
  [ExteriorFeatures.Porch, "Porch"],
  [ExteriorFeatures.SubdivisionTennisCourt, "Subdivision Tennis Court"],
  [ExteriorFeatures.BackGreenSpace, "Back Green Space"],
  [ExteriorFeatures.SprinklerSystem, "Sprinkler System"]
]);
