export enum EnergyFeatures {
  OtherEnergyFeatures = "otherEnergyFeatures",
  EnergyStarAppliances = "energyStarAppliances",
  RadiantAtticBarrier = "radiantAtticBarrier",
  InsulationOther = "insulationOther",
  Hvac13Seer = "hvac13Seer",
  HighEfficiencyHvac = "highEfficiencyHvac",
  TanklessOnDemandH2OHeater = "tanklessOnDemandH2OHeater",
  SolarH2OHeater = "solarH2OHeater",
  EnergyStarReflectiveRoof = "energyStarReflectiveRoof",
  GeothermalSystem = "geothermalSystem",
  InsulatedLowEwindows = "insulatedLowEwindows",
  InsulationSprayFoam = "insulationSprayFoam",
  NorthSouthExposure = "northSouthExposure",
  InsulationBlownCellulose = "insulationBlownCellulose",
  SolarPVElectricPanels = "solarPVElectricPanels",
  StructuralInsulatedPanels = "structuralInsulatedPanels",
  AtticVents = "atticVents",
  SolarPanelOwned = "solarPanelOwned",
  StormWindows = "stormWindows",
  InsulatedDoors = "insulatedDoors",
  CeilingFans = "ceilingFans",
  SolarPanelLeased = "solarPanelLeased",
  AtticFan = "atticFan",
  InsulationBlownFiberglass = "insulationBlownFiberglass",
  DigitalProgramThermostat = "digitalProgramThermostat",
  SolarScreens = "solarScreens",
  InsulationRigidFoam = "insulationRigidFoam",
  EnergyStarCflledLights = "energyStarCflledLights",
  InsulationBatt = "insulationBatt"
}

export const EnergyFeaturesLabel = new Map<EnergyFeatures, string>([
  [EnergyFeatures.OtherEnergyFeatures, "Other Energy Features"],
  [EnergyFeatures.EnergyStarAppliances, "Energy Star Appliances"],
  [EnergyFeatures.RadiantAtticBarrier, "Radiant Attic Barrier"],
  [EnergyFeatures.InsulationOther, "Insulation - Other"],
  [EnergyFeatures.Hvac13Seer, "HVAC>13 SEER"],
  [EnergyFeatures.HighEfficiencyHvac, "High-Efficiency HVAC"],
  [EnergyFeatures.TanklessOnDemandH2OHeater, "Tankless/On-Demand H2O Heater"],
  [EnergyFeatures.SolarH2OHeater, "Solar H2O Heater"],
  [EnergyFeatures.EnergyStarReflectiveRoof, "Energy Star/Reflective Roof"],
  [EnergyFeatures.GeothermalSystem, "Geothermal System"],
  [EnergyFeatures.InsulatedLowEwindows, "Insulated/Low-E windows"],
  [EnergyFeatures.InsulationSprayFoam, "Insulation - Spray-Foam"],
  [EnergyFeatures.NorthSouthExposure, "North/South Exposure"],
  [EnergyFeatures.InsulationBlownCellulose, "Insulation - Blown Cellulose"],
  [EnergyFeatures.SolarPVElectricPanels, "Solar PV Electric Panels"],
  [EnergyFeatures.StructuralInsulatedPanels, "Structural Insulated Panels"],
  [EnergyFeatures.AtticVents, "Attic Vents"],
  [EnergyFeatures.SolarPanelOwned, "Solar Panel - Owned"],
  [EnergyFeatures.StormWindows, "Storm Windows"],
  [EnergyFeatures.InsulatedDoors, "Insulated Doors"],
  [EnergyFeatures.CeilingFans, "Ceiling Fans"],
  [EnergyFeatures.SolarPanelLeased, "Solar Panel - Leased"],
  [EnergyFeatures.AtticFan, "Attic Fan"],
  [EnergyFeatures.InsulationBlownFiberglass, "Insulation - Blown Fiberglass"],
  [EnergyFeatures.DigitalProgramThermostat, "Digital Program Thermostat"],
  [EnergyFeatures.SolarScreens, "Solar Screens"],
  [EnergyFeatures.InsulationRigidFoam, "Insulation - Rigid Foam"],
  [EnergyFeatures.EnergyStarCflledLights, "Energy Star/CFL/LED Lights"],
  [EnergyFeatures.InsulationBatt, "Insulation - Batt"]
]);
