export const getLabelsFromMap = <Tenum extends string>(
  maps: Map<Tenum, string>,
  values?: string[]
): string[] => {
  return values ? values.map((field) => maps.get(field as Tenum) ?? field) : [];
};

export const getLabelFromMap = <Tenum extends string>(
  maps: Map<Tenum, string>,
  values?: string
): string | undefined => {
  return values ? maps.get(values as Tenum) : undefined;
};

export const getLabelFromRecord = (
  maps: Record<string, string>,
  value?: string
): string | undefined => {
  return value != null ? maps[value] : undefined;
};
