export enum Report {
  ListingAgents = "listing-agent",
  SellingAgents = "selling-agent",
  SellingAgentsInternal = "selling-agent-internal",
  CmaListings = "cma-listings",
  NewHomes = "new-homes",
  ActivePendingListings = "active-pending-listings",
  RealtorOffices = "realtor-offices",
  BillingReport = "billing"
}

export const ReportLabel = new Map<Report, string>([
  [Report.ListingAgents, "Listing Agent"],
  [Report.SellingAgents, "Selling Agent"],
  [Report.SellingAgentsInternal, "Selling Agent (Internal)"],
  [Report.CmaListings, "CMA - Residential"],
  [Report.NewHomes, "New Homes"],
  [Report.ActivePendingListings, "Active/Pending Listings"],
  [Report.RealtorOffices, "Realtor Offices"],
  [Report.BillingReport, "Billing Report"]
]);
