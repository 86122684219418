import { FormRules, FieldRulesProps } from "@homesusa/form";
import { agentBonusRules } from "core/rules/agent-bonus-rules";
import {
  buyersAgentCommissionRules,
  financialRules,
  schoolsRules
} from "../../sections";

export const financialSchoolsRules: Record<
  string,
  FormRules | FieldRulesProps
> = {
  ...financialRules,
  ...buyersAgentCommissionRules,
  ...agentBonusRules,
  schools: schoolsRules,
  isMultipleTax: {
    label: "Multiple Tax"
  }
};
