import { FormRules, FieldRulesProps } from "@homesusa/form";
import { financialRules, schoolsRules, hoaRules } from "../../sections";
import { agentBonusRules } from "core/rules/agent-bonus-rules";

export const financialSchoolsRules: Record<
  string,
  FormRules | FieldRulesProps
> = {
  ...financialRules,
  ...hoaRules,
  ...agentBonusRules,
  schools: schoolsRules
};
