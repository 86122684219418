import React from "react";

import { FormContext, Select } from "@homesusa/form";
import { SectionBox } from "@homesusa/core";

export function SectionBoxSelect({
  name,
  inputLabel = "Features"
}: {
  name: string;
  inputLabel?: string;
}): JSX.Element {
  const { formRules } = React.useContext(FormContext);
  return (
    <SectionBox title={formRules ? formRules[name]?.label : ""}>
      <Select name={name} label={inputLabel} />
    </SectionBox>
  );
}
