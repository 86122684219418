import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import {
  ConstructionStage,
  ConstructionStageLabel,
  HousingType,
  HousingTypeLabel,
  LegalSubdivision,
  LotDescription,
  LotDescriptionLabel,
  LotSizeSource,
  LotSizeSourceLabel,
  MlsArea,
  MlsAreaLabel,
  PlannedCommunity,
  PlannedCommunityLabel,
  PropertySubType,
  PropertySubTypeLabel,
  legalSubdivisionLabel
} from "markets/har/enums";
import { constructionStartYearRules as constrnStartYearRules } from "modules/sale-listings/rules";

export const constructionStageRules: FieldRulesProps = {
  label: "Construction Stage",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(
    Object.values(ConstructionStage),
    ConstructionStageLabel
  )
};

export const propertyTypeRules: FieldRulesProps = {
  label: "Property Type",
  options: getOptionsFromEnumAndMap(
    Object.values(PropertySubType),
    PropertySubTypeLabel
  ),
  rows: 2,
  requiredToSubmit: true
};

export const lotDescriptionRules: FieldRulesProps = {
  label: "Lot Description",
  options: getOptionsFromEnumAndMap(
    Object.values(LotDescription),
    LotDescriptionLabel
  ),
  requiredToSubmit: true
};
export const lotSizeSourceRules: FieldRulesProps = {
  label: "Lot Size Source",
  options: getOptionsFromEnumAndMap(
    Object.values(LotSizeSource),
    LotSizeSourceLabel
  )
};

export const housingTypeRules: FieldRulesProps = {
  label: "Housing Type",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(
    Object.values(HousingType),
    HousingTypeLabel
  )
};
export const constructionStartYearRules: FieldRulesProps = {
  requiredToSubmit: true,
  label: "Year Built",
  ...constrnStartYearRules()
};

export const mlsAreaRules: FieldRulesProps = {
  label: "Market Area",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(Object.values(MlsArea), MlsAreaLabel)
};
export const mapscoGridRules: FieldRulesProps = {
  label: "Key Map",
  maxLength: 5
};
export const legalSubdivisionRules: FieldRulesProps = {
  label: "Legal Subdivision",
  options: getOptionsFromEnumAndMap(
    Object.values(LegalSubdivision),
    legalSubdivisionLabel
  ),
  requiredToSubmit: true
};
export const isPlannedCommunityRules: FieldRulesProps = {
  label: "Master Planned Community"
};
export const plannedCommunityRules: FieldRulesProps = {
  label: "Master Planned Community Name",
  options: getOptionsFromEnumAndMap(
    Object.values(PlannedCommunity),
    PlannedCommunityLabel
  )
};
