export enum DistanceToWaterAccess {
  OneToTwoMiles = "oneTwoMiles",
  TwoPlusMiles = "twoPlusMiles",
  InSubdivision = "inSubdivision",
  LessThanOneMile = "lessThanOneMile",
  SeeRemarks = "seeRemarks"
}
export const DistanceToWaterAccessLabel = new Map<
  DistanceToWaterAccess,
  string
>([
  [DistanceToWaterAccess.OneToTwoMiles, "1-2 Miles"],
  [DistanceToWaterAccess.TwoPlusMiles, "2+ Miles"],
  [DistanceToWaterAccess.InSubdivision, "In Subdivision"],
  [DistanceToWaterAccess.LessThanOneMile, "Less Than 1 Mile"],
  [DistanceToWaterAccess.SeeRemarks, "See Remarks"]
]);
