export enum StreetType {
  Avenue = "avenue",
  Boulevard = "boulevard",
  Circle = "circle",
  Court = "court",
  Drive = "drive",
  Gardens = "gardens",
  Highway = "highway",
  Lane = "lane",
  Loop = "loop",
  Parkway = "parkway",
  Place = "place",
  Point = "point",
  Road = "road",
  Run = "run",
  Square = "square",
  Street = "street",
  Terrace = "terrace",
  Trail = "trail",
  Village = "village",
  Way = "way"
}

export const StreetTypeLabel: Record<StreetType, string> = {
  [StreetType.Avenue]: "Avenue",
  [StreetType.Boulevard]: "Boulevard",
  [StreetType.Circle]: "Circle",
  [StreetType.Court]: "Court",
  [StreetType.Drive]: "Drive",
  [StreetType.Gardens]: "Gardens",
  [StreetType.Highway]: "Highway",
  [StreetType.Lane]: "Lane",
  [StreetType.Loop]: "Loop",
  [StreetType.Parkway]: "Parkway",
  [StreetType.Place]: "Place",
  [StreetType.Point]: "Point",
  [StreetType.Road]: "Road",
  [StreetType.Run]: "Run",
  [StreetType.Square]: "Square",
  [StreetType.Street]: "Street",
  [StreetType.Terrace]: "Terrace",
  [StreetType.Trail]: "Trail",
  [StreetType.Village]: "Village",
  [StreetType.Way]: "Way"
};
