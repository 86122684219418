import { AlertBaseFilter } from "../interfaces";
import axios from "axios";
import { XmlAlerts } from "../interfaces/alerts";
import { AlertType } from "../enums";
import { XmlListing } from "../interfaces/alerts/xml/xml-listing.interface";
import { GridResponse } from "@homesusa/grid-table";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export const getAlerts = async (
  params: AlertBaseFilter
): Promise<XmlAlerts> => {
  const response = await axios.get<XmlAlerts>(`${gatewayUrl}/api/xml/alerts`, {
    params
  });
  return response.data;
};

export const getAlertsByType = async (
  params: AlertBaseFilter,
  alertType: AlertType
): Promise<GridResponse<XmlListing>> => {
  const response = await axios.get<GridResponse<XmlListing>>(
    `${gatewayUrl}/api/xml/alerts/${alertType}`,
    {
      params
    }
  );
  return response.data;
};
