export enum MiddleSchool {
  NotApplicable = "notApplicable",
  MiddleSchool = "middleSchool",
  AJBrieseMeister = "ajBrieseMeister",
  AbrahamKazen = "abrahamKazen",
  AlamoHeights = "alamoHeights",
  AlanBShepard = "alanBShepard",
  AlanDShepard = "alanDShepard",
  Alpine = "alpine",
  AnnaMiddleSchool = "annaMiddleSchool",
  ArlenCA = "arlenCA",
  ArlonSeay = "arlonSeay",
  Armando = "armando",
  Atascosa = "atascosa",
  BTWilson = "bTWilson",
  Ball = "ball",
  Bandera = "bandera",
  BarbaraBush = "barbaraBush",
  BarnesJim = "barnesJim",
  Batesville = "batesville",
  BeaconHill = "beaconHill",
  Benevides = "benevides",
  BenitoJuarez = "benitoJuarez",
  Bernal = "bernal",
  BernardHarris = "bernardHarris",
  BigWells = "bigWells",
  BillBrown = "billBrown",
  Blanco = "blanco",
  BoerneMiddleNorth = "boerneMiddleNorth",
  BoerneMiddleSouth = "boerneMiddleSouth",
  Bonham = "bonham",
  Bowden = "bowden",
  Brackett = "brackett",
  BrackettISD = "brackettISD",
  Bradley = "bradley",
  Brentwood = "brentwood",
  Briesemiester = "briesemiester",
  Briscoe = "briscoe",
  Brundett = "brundett",
  Bulverde = "bulverde",
  Burnet = "burnet",
  ChiltonMiddleSchool = "chiltonMiddleSchool",
  CrystalCity = "crystalCity",
  CalallenMiddleSchool = "calallenMiddleSchool",
  CallDistrict = "callDistrict",
  Canyon = "canyon",
  CarrizoSprings = "carrizoSprings",
  CarrizoSpringsPR = "carrizoSpringsPR",
  CenterPoint = "centerPoint",
  CenterPointPT = "centerPointPT",
  Charlotte = "charlotte",
  ChristianEv = "christianEv",
  ChurchHill = "churchHill",
  Comal = "comal",
  Comfort = "comfort",
  Comstock = "comstock",
  Connally = "connally",
  Connell = "connell",
  Connley = "connley",
  Continental = "continental",
  Converse = "converse",
  Cooper = "cooper",
  Corbett = "corbett",
  Cotton = "cotton",
  Cotulla = "cotulla",
  CreekwoodPark = "creekwoodPark",
  CueroIntermediate = "cueroIntermediate",
  CueroJuniorHigh = "cueroJuniorHigh",
  DHanis = "dHanis",
  DrippingSpringsMiddle = "drippingSpringsMiddle",
  DanforthJrHighSchool = "danforthJrHighSchool",
  DanvilleMiddleSchool = "danvilleMiddleSchool",
  Davis = "davis",
  DelRio = "delRio",
  Devine = "devine",
  Dilley = "dilley",
  DobieJFrank = "dobieJFrank",
  DolphBriscoe = "dolphBriscoe",
  DorisMiller = "dorisMiller",
  Douglass = "douglass",
  Driscoll = "driscoll",
  Dwight = "dwight",
  EaglePass = "eaglePass",
  EastCentral = "eastCentral",
  EdWhite = "edWhite",
  Eisenhower = "eisenhower",
  ElaineSchlather = "elaineSchlather",
  EloiseJaphetAcademy = "eloiseJaphetAcademy",
  Emerson = "emerson",
  Esid = "esid",
  FlourBluff = "flourBluff",
  Folks = "folks",
  FourPoint = "fourPoint",
  FallCity = "fallCity",
  Fenwick = "fenwick",
  Floresville = "floresville",
  FrankNewmanMiddleSchool = "frankNewmanMiddleSchool",
  Frazier = "frazier",
  Fredericksburg = "fredericksburg",
  GatesAcademy = "gatesAcademy",
  Garner = "garner",
  GeorgeWest = "georgeWest",
  Gonzales = "gonzales",
  GusGarcia = "gusGarcia",
  HendersonElementary = "hendersonElementary",
  HalPeterson = "halPeterson",
  Harlandale = "harlandale",
  HarmonyScienceAcademy = "harmonyScienceAcademy",
  HarperMiddle = "harperMiddle",
  Harris = "harris",
  HawthorneAcademy = "hawthorneAcademy",
  HectorGarcia = "hectorGarcia",
  HelotesMid = "helotesMid",
  Heritage = "heritage",
  Hill = "hill",
  HobbyWilliam = "hobbyWilliam",
  Hondo = "hondo",
  Hudspech = "hudspech",
  Hunt = "hunt",
  Ingram = "ingram",
  Irving = "irving",
  Jackson = "jackson",
  Japhet = "japhet",
  JeffersonJrHigh = "jeffersonJrHigh",
  JimBarnes = "jimBarnes",
  JohnBConnally = "johnBConnally",
  JohnsonCity = "johnsonCity",
  Jones = "jones",
  JonesAnson = "jonesAnson",
  Jordan = "jordan",
  Jourdanton = "jourdanton",
  JudsonMiddleSchool = "judsonMiddleSchool",
  JuliusMatthey = "juliusMatthey",
  Junction = "junction",
  KarnesCityJrHigh = "karnesCityJrHigh",
  Kazen = "kazen",
  Kerrville = "kerrville",
  Kingsborough = "kingsborough",
  Kirby = "kirby",
  KittyHawk = "kittyHawk",
  KnippaPreparatory = "knippaPreparatory",
  Krueger = "krueger",
  Lamar = "lamar",
  LaPryorSecondary = "laPryorSecondary",
  LaVernia = "laVernia",
  LagoVista = "lagoVista",
  LargoVistaMid = "largoVistaMid",
  LampasasMiddleSchool = "lampasasMiddleSchool",
  LargoVista = "largoVista",
  LauraBWallace = "lauraBWallace",
  LauraIngallsWilder = "lauraIngallsWilder",
  Leakey = "leakey",
  Leal = "leal",
  Leander = "leander",
  Legacy = "legacy",
  Llano = "llano",
  LomaAlta = "lomaAlta",
  Longfellow = "longfellow",
  Lopez = "lopez",
  Losoya = "losoya",
  Lowell = "lowell",
  Luling = "luling",
  Lumberton = "lumberton",
  Luna = "luna",
  LyndonBJohnson = "lyndonBJohnson",
  Lytle = "lytle",
  MillerAcademy = "millerAcademy",
  Mann = "mann",
  MarbleFallsMid = "marbleFallsMid",
  Marion = "marion",
  MarkTwain = "markTwain",
  MartinLutherKing = "martinLutherKing",
  MaryHarper = "maryHarper",
  MasonMiddleSch = "masonMiddleSch",
  Mathis = "mathis",
  McAuliffeChrista = "mcAuliffeChrista",
  McDowell = "mcDowell",
  McNair = "mcNair",
  Medina = "medina",
  MedinaValley = "medinaValley",
  Memorial = "memorial",
  Metzger = "metzger",
  MillerAcademyD = "millerAcademyD",
  MillerMiddleSchool = "millerMiddleSchool",
  MissionAcademy = "missionAcademy",
  Morales = "morales",
  MountainValley = "mountainValley",
  Natalia = "natalia",
  Navarro = "navarro",
  NeffPat = "neffPat",
  NewBraunfel = "newBraunfel",
  Nimitz = "nimitz",
  Nixon = "nixon",
  NuecesCanyon = "nuecesCanyon",
  OHenryMiddle = "oHenryMiddle",
  OakCrest = "oakCrest",
  OakRunSchool = "oakRunSchool",
  Ogden = "ogden",
  OutKerr = "outKerr",
  OwenGoodnight = "owenGoodnight",
  PageMiddle = "pageMiddle",
  Pearsall = "pearsall",
  PeaseEM = "peaseEM",
  Peterson = "peterson",
  PieperRanch = "pieperRanch",
  Pleasanton = "pleasanton",
  Poe = "poe",
  PortIsabel = "portIsabel",
  Poteet = "poteet",
  Poth = "poth",
  Resnik = "resnik",
  RockportFultonPortAransas = "rockportFultonPortAransas",
  Rawlinson = "rawlinson",
  RayburnSam = "rayburnSam",
  Rhodes = "rhodes",
  RobertGCole = "robertGCole",
  RobertVale = "robertVale",
  Rocksprings = "rocksprings",
  Rogers = "rogers",
  RossSul = "rossSul",
  RoyalRidge = "royalRidge",
  Rudder = "rudder",
  Runge = "runge",
  Sabinal = "sabinal",
  Saegert = "saegert",
  Saisd = "saisd",
  Salado = "salado",
  SanFelipeMemorial = "sanFelipeMemorial",
  SantaFeJuniorHigh = "santaFeJuniorHigh",
  Schulenburg = "schulenburg",
  ScobeeJrHigh = "scobeeJrHigh",
  ScobyMiddleSchool = "scobyMiddleSchool",
  Seele = "seele",
  Seguin = "seguin",
  Sheppard = "sheppard",
  SmithsonValley = "smithsonValley",
  Somerset = "somerset",
  Southside = "southside",
  Southwest = "southwest",
  SpringBranch = "springBranch",
  Starky = "starky",
  SterlingHFly = "sterlingHFly",
  Stevenson = "stevenson",
  StinsonKatherine = "stinsonKatherine",
  Stockdale = "stockdale",
  Straus = "straus",
  Tafolla = "tafolla",
  Tejeda = "tejeda",
  TerrellWells = "terrellWells",
  TexHill = "texHill",
  ThreeRivers = "threeRivers",
  Truman = "truman",
  Utopia = "utopia",
  Uvalde = "uvalde",
  VanMiddleSchool = "vanMiddleSchool",
  VossMiddleSchool = "vossMiddleSchool",
  WestCampus = "westCampus",
  WestIntermediate = "westIntermediate",
  WesterMiddleSchool = "westerMiddleSchool",
  WheatleyEmerson = "wheatleyEmerson",
  WhiteEd = "whiteEd",
  Whittier = "whittier",
  Wilder = "wilder",
  Wimberly = "wimberly",
  Wood = "wood",
  Woodlake = "woodlake",
  WoodlakeHills = "woodlakeHills",
  WoodlawnPrimarySchool = "woodlawnPrimarySchool",
  Wools = "wools",
  Wrenn = "wrenn",
  YorktownJuniorHigh = "yorktownJuniorHigh",
  ZachryHB = "zachryHB",
  Zamora = "zamora",
  Kenedy = "kenedy"
}

export const MiddleSchoolLabel = new Map<MiddleSchool, string>([
  [MiddleSchool.NotApplicable, "Not Applicable"],
  [MiddleSchool.MiddleSchool, "Middle School"],
  [MiddleSchool.AJBrieseMeister, "A.J. Briesemeister"],
  [MiddleSchool.AbrahamKazen, "Abraham Kazen"],
  [MiddleSchool.AlamoHeights, "Alamo Heights"],
  [MiddleSchool.AlanBShepard, "Alan B. Shepard"],
  [MiddleSchool.AlanDShepard, "Alan D Shepard"],
  [MiddleSchool.Alpine, "Alpine"],
  [MiddleSchool.AnnaMiddleSchool, "Anna Middle School"],
  [MiddleSchool.ArlenCA, "Arlen CA"],
  [MiddleSchool.ArlonSeay, "Arlon Seay"],
  [MiddleSchool.Armando, "Armando"],
  [MiddleSchool.Atascosa, "Atascosa"],
  [MiddleSchool.BTWilson, "BT Wilson"],
  [MiddleSchool.Ball, "Ball"],
  [MiddleSchool.Bandera, "Bandera"],
  [MiddleSchool.BarbaraBush, "Barbara Bush"],
  [MiddleSchool.BarnesJim, "Barnes, Jim"],
  [MiddleSchool.Batesville, "Batesville"],
  [MiddleSchool.BeaconHill, "Beacon Hill"],
  [MiddleSchool.Benevides, "Benevides"],
  [MiddleSchool.BenitoJuarez, "Benito Juarez"],
  [MiddleSchool.Bernal, "Bernal"],
  [MiddleSchool.BernardHarris, "Bernard Harris"],
  [MiddleSchool.BigWells, "Big Wells"],
  [MiddleSchool.BillBrown, "Bill Brown"],
  [MiddleSchool.Blanco, "Blanco"],
  [MiddleSchool.BoerneMiddleNorth, "Boerne Middle N"],
  [MiddleSchool.BoerneMiddleSouth, "Boerne Middle S"],
  [MiddleSchool.Bonham, "Bonham"],
  [MiddleSchool.Bowden, "Bowden"],
  [MiddleSchool.Brackett, "Brackett"],
  [MiddleSchool.BrackettISD, "Brackett ISD"],
  [MiddleSchool.Bradley, "Bradley"],
  [MiddleSchool.Brentwood, "Brentwood"],
  [MiddleSchool.Briesemiester, "Briesemiester"],
  [MiddleSchool.Briscoe, "Briscoe"],
  [MiddleSchool.Brundett, "Brundett"],
  [MiddleSchool.Bulverde, "Bulverde"],
  [MiddleSchool.Burnet, "Burnet"],
  [MiddleSchool.ChiltonMiddleSchool, "Chilton Middle School"],
  [MiddleSchool.CrystalCity, "Crystal City Middle School  Zavala County"],
  [MiddleSchool.CalallenMiddleSchool, "Calallen Middle School"],
  [MiddleSchool.CallDistrict, "Call District"],
  [MiddleSchool.Canyon, "Canyon"],
  [MiddleSchool.CarrizoSprings, "Carrizo Springs"],
  [MiddleSchool.CarrizoSpringsPR, "Carrizo Springs"],
  [MiddleSchool.CenterPoint, "Center Point"],
  [MiddleSchool.CenterPointPT, "Center Point"],
  [MiddleSchool.Charlotte, "Charlotte"],
  [MiddleSchool.ChristianEv, "Christian Ev"],
  [MiddleSchool.ChurchHill, "Church Hill"],
  [MiddleSchool.Comal, "Comal"],
  [MiddleSchool.Comfort, "Comfort"],
  [MiddleSchool.Comstock, "Comstock"],
  [MiddleSchool.Connally, "Connally"],
  [MiddleSchool.Connell, "Connell"],
  [MiddleSchool.Connley, "Connley"],
  [MiddleSchool.Continental, "Continental"],
  [MiddleSchool.Converse, "Converse"],
  [MiddleSchool.Cooper, "Cooper"],
  [MiddleSchool.Corbett, "Corbett"],
  [MiddleSchool.Cotton, "Cotton"],
  [MiddleSchool.Cotulla, "Cotulla"],
  [MiddleSchool.CreekwoodPark, "Creekwood Park"],
  [MiddleSchool.CueroIntermediate, "Cuero Intermediate"],
  [MiddleSchool.CueroJuniorHigh, "Cuero Junior High"],
  [MiddleSchool.DHanis, "DHanis"],
  [MiddleSchool.DrippingSpringsMiddle, "Dripping Springs Middle Hays Cty"],
  [MiddleSchool.DanforthJrHighSchool, "Danforth Jr High School"],
  [MiddleSchool.DanvilleMiddleSchool, "Danville Middle School"],
  [MiddleSchool.Davis, "Davis"],
  [MiddleSchool.DelRio, "Del Rio"],
  [MiddleSchool.Devine, "Devine"],
  [MiddleSchool.Dilley, "Dilley"],
  [MiddleSchool.DobieJFrank, "Dobie J. Frank"],
  [MiddleSchool.DolphBriscoe, "Dolph Briscoe"],
  [MiddleSchool.DorisMiller, "Doris Miller"],
  [MiddleSchool.Douglass, "Douglass"],
  [MiddleSchool.Driscoll, "Driscoll"],
  [MiddleSchool.Dwight, "Dwight"],
  [MiddleSchool.EaglePass, "Eagle Pass"],
  [MiddleSchool.EastCentral, "East Central"],
  [MiddleSchool.EdWhite, "Ed White"],
  [MiddleSchool.Eisenhower, "Eisenhower"],
  [MiddleSchool.ElaineSchlather, "Elaine Schlather"],
  [MiddleSchool.EloiseJaphetAcademy, "Eloise Japhet Academy"],
  [MiddleSchool.Emerson, "Emerson"],
  [MiddleSchool.Esid, "Esid"],
  [MiddleSchool.FlourBluff, "Flour Bluff"],
  [MiddleSchool.Folks, "Folks"],
  [MiddleSchool.FourPoint, "Four Point"],
  [MiddleSchool.FallCity, "Fall City"],
  [MiddleSchool.Fenwick, "Fenwick"],
  [MiddleSchool.Floresville, "Floresville"],
  [MiddleSchool.FrankNewmanMiddleSchool, "Frank Newman Middle School"],
  [MiddleSchool.Frazier, "Frazier"],
  [MiddleSchool.Fredericksburg, "Fredericksburg"],
  [MiddleSchool.GatesAcademy, "Gates Academy"],
  [MiddleSchool.Garner, "Garner"],
  [MiddleSchool.GeorgeWest, "George West"],
  [MiddleSchool.Gonzales, "Gonzales"],
  [MiddleSchool.GusGarcia, "Gus Garcia"],
  [MiddleSchool.HendersonElementary, "Henderson Elementary"],
  [MiddleSchool.HalPeterson, "Hal Peterson"],
  [MiddleSchool.Harlandale, "Harlandale"],
  [MiddleSchool.HarmonyScienceAcademy, "Harmony Science Academy"],
  [MiddleSchool.HarperMiddle, "Harper Middle"],
  [MiddleSchool.Harris, "Harris"],
  [MiddleSchool.HawthorneAcademy, "Hawthorne Academy"],
  [MiddleSchool.HectorGarcia, "Hector Garcia"],
  [MiddleSchool.HelotesMid, "Helotes Mid"],
  [MiddleSchool.Heritage, "Heritage"],
  [MiddleSchool.Hill, "Hill"],
  [MiddleSchool.HobbyWilliam, "Hobby William P."],
  [MiddleSchool.Hondo, "Hondo"],
  [MiddleSchool.Hudspech, "Hudspech"],
  [MiddleSchool.Hunt, "Hunt"],
  [MiddleSchool.Ingram, "Ingram"],
  [MiddleSchool.Irving, "Irving"],
  [MiddleSchool.Jackson, "Jackson"],
  [MiddleSchool.Japhet, "Japhet"],
  [MiddleSchool.JeffersonJrHigh, "Jefferson Jr High"],
  [MiddleSchool.JimBarnes, "Jim Barnes"],
  [MiddleSchool.JohnBConnally, "John B. Connally"],
  [MiddleSchool.JohnsonCity, "Johnson City"],
  [MiddleSchool.Jones, "Jones"],
  [MiddleSchool.JonesAnson, "Jones Anson"],
  [MiddleSchool.Jordan, "Jordan"],
  [MiddleSchool.Jourdanton, "Jourdanton"],
  [MiddleSchool.JudsonMiddleSchool, "Judson Middle School"],
  [MiddleSchool.JuliusMatthey, "Julius Matthey"],
  [MiddleSchool.Junction, "Junction"],
  [MiddleSchool.KarnesCityJrHigh, "Karnes City Jr. High"],
  [MiddleSchool.Kazen, "Kazen"],
  [MiddleSchool.Kerrville, "Kerrville"],
  [MiddleSchool.Kingsborough, "Kingsborough"],
  [MiddleSchool.Kirby, "Kirby"],
  [MiddleSchool.KittyHawk, "Kitty Hawk"],
  [MiddleSchool.KnippaPreparatory, "Knippa Preparatory"],
  [MiddleSchool.Krueger, "Krueger"],
  [MiddleSchool.Lamar, "Lamar"],
  [MiddleSchool.LaPryorSecondary, "La Pryor Secondary"],
  [MiddleSchool.LaVernia, "La Vernia"],
  [MiddleSchool.LagoVista, "Lago Vista"],
  [MiddleSchool.LargoVistaMid, "Lago Vista"],
  [MiddleSchool.LampasasMiddleSchool, "Lampasas Middle School"],
  [MiddleSchool.LargoVista, "LargoVista"],
  [MiddleSchool.LauraBWallace, "Laura B Wallace"],
  [MiddleSchool.LauraIngallsWilder, "Laura Ingalls Wilder"],
  [MiddleSchool.Leakey, "Leakey"],
  [MiddleSchool.Leal, "Leal"],
  [MiddleSchool.Leander, "Leander"],
  [MiddleSchool.Legacy, "Legacy"],
  [MiddleSchool.Llano, "Llano"],
  [MiddleSchool.LomaAlta, "Loma Alta"],
  [MiddleSchool.Longfellow, "Longfellow"],
  [MiddleSchool.Lopez, "Lopez"],
  [MiddleSchool.Losoya, "Losoya"],
  [MiddleSchool.Lowell, "Lowell"],
  [MiddleSchool.Luling, "Luling"],
  [MiddleSchool.Lumberton, "Lumberton"],
  [MiddleSchool.Luna, "Luna"],
  [MiddleSchool.LyndonBJohnson, "Lyndon B. Johnson"],
  [MiddleSchool.Lytle, "Lytle"],
  [MiddleSchool.MillerAcademy, "Miller Academy"],
  [MiddleSchool.Mann, "Mann"],
  [MiddleSchool.MarbleFallsMid, "Marble Falls Mid"],
  [MiddleSchool.Marion, "Marion"],
  [MiddleSchool.MarkTwain, "Mark Twain"],
  [MiddleSchool.MartinLutherKing, "Martin Luther King"],
  [MiddleSchool.MaryHarper, "Mary Harper"],
  [MiddleSchool.MasonMiddleSch, "Mason Middle Sch"],
  [MiddleSchool.Mathis, "Mathis"],
  [MiddleSchool.McAuliffeChrista, "Mc Auliffe Christa"],
  [MiddleSchool.McDowell, "Mc Dowell"],
  [MiddleSchool.McNair, "Mc Nair"],
  [MiddleSchool.Medina, "Medina"],
  [MiddleSchool.MedinaValley, "Medina Valley"],
  [MiddleSchool.Memorial, "Memorial"],
  [MiddleSchool.Metzger, "Metzger"],
  [MiddleSchool.MillerAcademyD, "Miller Academy"],
  [MiddleSchool.MillerMiddleSchool, "Miller Middle School"],
  [MiddleSchool.MissionAcademy, "Mission Academy"],
  [MiddleSchool.Morales, "Morales"],
  [MiddleSchool.MountainValley, "Mountain Valley"],
  [MiddleSchool.Natalia, "Natalia"],
  [MiddleSchool.Navarro, "Navarro"],
  [MiddleSchool.NeffPat, "Neff Pat"],
  [MiddleSchool.NewBraunfel, "New Braunfel"],
  [MiddleSchool.Nimitz, "Nimitz"],
  [MiddleSchool.Nixon, "Nixon"],
  [MiddleSchool.NuecesCanyon, "Nueces Canyon"],
  [MiddleSchool.OHenryMiddle, "O. Henry Middle"],
  [MiddleSchool.OakCrest, "Oak Crest"],
  [MiddleSchool.OakRunSchool, "Oak Run School"],
  [MiddleSchool.Ogden, "Ogden"],
  [MiddleSchool.OutKerr, "Out/Kerr"],
  [MiddleSchool.OwenGoodnight, "Owen Goodnight"],
  [MiddleSchool.PageMiddle, "Page Middle"],
  [MiddleSchool.Pearsall, "Pearsall"],
  [MiddleSchool.PeaseEM, "Pease E. M."],
  [MiddleSchool.Peterson, "Peterson"],
  [MiddleSchool.PieperRanch, "Pieper Ranch"],
  [MiddleSchool.Pleasanton, "Pleasanton"],
  [MiddleSchool.Poe, "Poe"],
  [MiddleSchool.PortIsabel, "Port Isabel"],
  [MiddleSchool.Poteet, "Poteet"],
  [MiddleSchool.Poth, "Poth"],
  [MiddleSchool.Resnik, "Resnik"],
  [MiddleSchool.RockportFultonPortAransas, "Rockport/Fulton Port Aransas"],
  [MiddleSchool.Rawlinson, "Rawlinson"],
  [MiddleSchool.RayburnSam, "Rayburn Sam"],
  [MiddleSchool.Rhodes, "Rhodes"],
  [MiddleSchool.RobertGCole, "Robert G Cole"],
  [MiddleSchool.RobertVale, "Robert Vale"],
  [MiddleSchool.Rocksprings, "Rocksprings"],
  [MiddleSchool.Rogers, "Rogers"],
  [MiddleSchool.RossSul, "Ross Sul"],
  [MiddleSchool.RoyalRidge, "Royal Ridge"],
  [MiddleSchool.Rudder, "Rudder"],
  [MiddleSchool.Runge, "Runge"],
  [MiddleSchool.Sabinal, "Sabinal"],
  [MiddleSchool.Saegert, "Saegert"],
  [MiddleSchool.Saisd, "Saisd"],
  [MiddleSchool.Salado, "Salado"],
  [MiddleSchool.SanFelipeMemorial, "San Felipe Memorial"],
  [MiddleSchool.SantaFeJuniorHigh, "Santa Fe Junior High"],
  [MiddleSchool.Schulenburg, "Schulenburg"],
  [MiddleSchool.ScobeeJrHigh, "Scobee Jr High"],
  [MiddleSchool.ScobyMiddleSchool, "Scoby Middle School"],
  [MiddleSchool.Seele, "Seele"],
  [MiddleSchool.Seguin, "Seguin"],
  [MiddleSchool.Sheppard, "Sheppard"],
  [MiddleSchool.SmithsonValley, "Smithson Valley"],
  [MiddleSchool.Somerset, "Somerset"],
  [MiddleSchool.Southside, "Southside"],
  [MiddleSchool.Southwest, "Southwest"],
  [MiddleSchool.SpringBranch, "Spring Branch"],
  [MiddleSchool.Starky, "Starky"],
  [MiddleSchool.SterlingHFly, "Sterling H Fly"],
  [MiddleSchool.Stevenson, "Stevenson"],
  [MiddleSchool.StinsonKatherine, "Stinson Katherine"],
  [MiddleSchool.Stockdale, "Stockdale"],
  [MiddleSchool.Straus, "Straus"],
  [MiddleSchool.Tafolla, "Tafolla"],
  [MiddleSchool.Tejeda, "Tejeda"],
  [MiddleSchool.TerrellWells, "Terrell Wells"],
  [MiddleSchool.TexHill, "Tex Hill"],
  [MiddleSchool.ThreeRivers, "Three Rivers"],
  [MiddleSchool.Truman, "Truman"],
  [MiddleSchool.Utopia, "Utopia"],
  [MiddleSchool.Uvalde, "Uvalde"],
  [MiddleSchool.VanMiddleSchool, "Van Middle School"],
  [MiddleSchool.VossMiddleSchool, "Voss Middle School"],
  [MiddleSchool.WestCampus, "West Campus"],
  [MiddleSchool.WestIntermediate, "West Intermediate"],
  [MiddleSchool.WesterMiddleSchool, "Wester Middle School"],
  [MiddleSchool.WheatleyEmerson, "Wheatley Emerson"],
  [MiddleSchool.WhiteEd, "White Ed"],
  [MiddleSchool.Whittier, "Whittier"],
  [MiddleSchool.Wilder, "Wilder"],
  [MiddleSchool.Wimberly, "Wimberly"],
  [MiddleSchool.Wood, "Wood"],
  [MiddleSchool.Woodlake, "Woodlake"],
  [MiddleSchool.WoodlakeHills, "Woodlake Hills"],
  [MiddleSchool.WoodlawnPrimarySchool, "Woodlawn Primary School"],
  [MiddleSchool.Wools, "Wools"],
  [MiddleSchool.Wrenn, "Wrenn"],
  [MiddleSchool.YorktownJuniorHigh, "Yorktown Junior High"],
  [MiddleSchool.ZachryHB, "Zachry H. B."],
  [MiddleSchool.Zamora, "Zamora"],
  [MiddleSchool.Kenedy, "Kenedy"]
]);
