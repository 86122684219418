export enum GarageCarpotDescription {
  ExtraDriveway = "extraDriveway",
  Vehiclelift = "vehiclelift"
}

export const GarageCarpotDescriptionLabel = new Map<
  GarageCarpotDescription,
  string
>([
  [GarageCarpotDescription.ExtraDriveway, "Extra Driveway"],
  [GarageCarpotDescription.Vehiclelift, "Vehicle lift"]
]);
