import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";
import { ImportSource, JsonImportStatus, XmlStatus } from "core/enums";
import { approveImportedCommunity } from "core/services/import.services";
import { ImportSubdivision } from "../interfaces/alerts/import";
import { AlertBaseFilter } from "../interfaces";
import { ImportCommunityService } from "../interfaces/services";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

const ImportCommunityAlertService = (
  market: MarketCode
): ImportCommunityService => {
  const gatewayMarket = getGatewayMarket(market);
  const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/sale-communities`;
  return {
    getAwaitingApprovalCommunities: async (
      importSource: ImportSource,
      filter: AlertBaseFilter
    ): Promise<GridResponse<ImportSubdivision>> => {
      const params = {
        ...filter,
        ...(importSource == ImportSource.Json
          ? { jsonImportStatus: JsonImportStatus.AwaitingApproval }
          : { xmlStatus: XmlStatus.AwaitingApproval })
      };
      return axios
        .get<GridResponse<ImportSubdivision>>(apiUrl, { params })
        .then((response) => response.data);
    },
    approveCommunity: approveImportedCommunity(market)
  };
};

export default ImportCommunityAlertService;
