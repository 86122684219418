import React from "react";

import { AgentType } from "../enums";
import { useAgentListingsGridColumns } from "../hooks/grid/use-agent-report-columns";
import { AgentsReport } from "./agents-report.component";

export function SellingAgentsReport(): JSX.Element {
  const columns = useAgentListingsGridColumns(AgentType.Selling);
  return <AgentsReport columns={columns} agentType={AgentType.Selling} />;
}
