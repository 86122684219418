export enum WaterFeatures {
  City = "city",
  CommunityWell = "communityWell",
  PrivateWell = "privateWell",
  StorageTank = "storageTank"
}

export const WaterFeaturesLabel: Record<WaterFeatures, string> = {
  [WaterFeatures.City]: "City",
  [WaterFeatures.CommunityWell]: "Community Well",
  [WaterFeatures.PrivateWell]: "PrivateWell(s)",
  [WaterFeatures.StorageTank]: "Storage Tank"
};
