import { FormRules } from "@homesusa/form";
import { spacesDimensionsRules } from "../../sections";

import { roomsDescriptionRules } from "../../sections/rooms-description";
export const spacesDimensionsInfoRules: FormRules = {
  ...spacesDimensionsRules,
  ...roomsDescriptionRules,
  fireplaces: { label: "# Fireplaces" },

  sqftTotal: {
    requiredToSubmit: false,
    label: "Building SqFt",
    numberFormat: {
      min: 500,
      max: 10000,
      thousandSeparator: true
    },
    maxLength: 10
  }
};
