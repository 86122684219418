export enum Accessibility {
  FirstFloorBath = "firstFloorBath",
  FirstFloorBedroom = "firstFloorBedroom",
  FullBathBedFirstFloor = "fullBathBedFirstFloor",
  TwoPlusAccessExits = "twoPlusAccessExits",
  ThirtySixInchOrWideHalls = "thirtySixInchOrWideHalls",
  HallwaysFortyTwoInchWide = "hallwaysFortyTwoInchWide",
  ThresholdsLessThanFiveEightsOfInch = "thresholdsLessThanFiveEightsOfInch",
  DoorsWithLeverHandles = "doorsWithLeverHandles",
  DoorsSwingIn = "doorsSwingIn",
  EntrySlopeLessThanOneFoot = "entrySlopeLessThanOneFoot",
  ExtDoorOpeningThirtySixInchesPlus = "extDoorOpeningThirtySixInchesPlus",
  FlooringModifications = "flooringModifications",
  HearingModifications = "hearingModifications",
  IntDoorOpeningThirtyTwoPlus = "intDoorOpeningThirtyTwoPlus",
  LowBathroomMirrors = "lowBathroomMirrors",
  LowClosetRods = "lowClosetRods",
  LowPileCarpet = "lowPileCarpet",
  LevelDrive = "levelDrive",
  LevelLot = "levelLot",
  NearBusLine = "nearBusLine",
  NoCarpet = "noCarpet",
  NoStepsDown = "noStepsDown",
  NoStairs = "noStairs",
  Other = "other",
  StallShower = "stallShower",
  WheelchairAccessible = "wheelchairAccessible"
}

export const AccessibilityLabel = new Map<Accessibility, string>([
  [Accessibility.FirstFloorBath, "First Floor Bath"],
  [Accessibility.FirstFloorBedroom, "First Floor Bedroom"],
  [Accessibility.FullBathBedFirstFloor, "Full Bath/Bed on 1st Flr"],
  [Accessibility.TwoPlusAccessExits, "2+ Access Exits"],
  [Accessibility.ThirtySixInchOrWideHalls, "36 inch or more wide halls"],
  [Accessibility.HallwaysFortyTwoInchWide, 'Hallways 42" Wide'],
  [
    Accessibility.ThresholdsLessThanFiveEightsOfInch,
    "Thresholds less than 5/8 of an inch"
  ],
  [Accessibility.DoorsWithLeverHandles, "Doors w/Lever Handles"],
  [Accessibility.DoorsSwingIn, "Doors-Swing-In"],
  [Accessibility.EntrySlopeLessThanOneFoot, "Entry Slope less than 1 foot"],
  [Accessibility.ExtDoorOpeningThirtySixInchesPlus, 'Ext Door Opening 36"+'],
  [Accessibility.FlooringModifications, "Flooring Modifications"],
  [Accessibility.HearingModifications, "Hearing Modifications"],
  [Accessibility.IntDoorOpeningThirtyTwoPlus, 'Int Door Opening 32"+'],
  [Accessibility.LowBathroomMirrors, "Low Bathroom Mirrors"],
  [Accessibility.LowClosetRods, "Low Closet Rods"],
  [Accessibility.LowPileCarpet, "Low Pile Carpet"],
  [Accessibility.LevelDrive, "Level Drive"],
  [Accessibility.LevelLot, "Level Lot"],
  [Accessibility.NearBusLine, "Near Bus Line"],
  [Accessibility.NoCarpet, "No Carpet"],
  [Accessibility.NoStepsDown, "No Steps Down"],
  [Accessibility.NoStairs, "No Stairs"],
  [Accessibility.Other, "Other"],
  [Accessibility.StallShower, "Stall Shower"],
  [Accessibility.WheelchairAccessible, "Wheelchair Accessible"]
]);
