import { appStateDefault, AppType, AppState } from "@homesusa/layout";
import { sidebarItems, SidebarItemType } from "core/enums";

export const appState: AppState = {
  ...appStateDefault,
  currentApp: AppType.Quicklister,
  layout: {
    ...appStateDefault.layout,
    sidebar: {
      ...appStateDefault.layout.sidebar,
      selected: SidebarItemType.Listings,
      items: sidebarItems
    }
  }
};
