export enum CommunityFeatures {
  Gated = "gated",
  Golf = "golf",
  HistoricalDistrict = "historicalDistrict",
  Lake = "lake",
  None = "none"
}

export const CommunityFeaturesLabel: Record<CommunityFeatures, string> = {
  [CommunityFeatures.Gated]: "Gated",
  [CommunityFeatures.Golf]: "Golf ",
  [CommunityFeatures.HistoricalDistrict]: "Historical District ",
  [CommunityFeatures.Lake]: "Lake ",
  [CommunityFeatures.None]: "None"
};
