export enum ListedType {
  None = "",
  AwaitingMlsUpdate = "awaitingMlsUpdate",
  Listed = "listed",
  NonSold = "nonSold",
  Unlisted = "unlisted"
}

export const ListedTypeLabel = new Map<ListedType, string>([
  [ListedType.None, ""],
  [ListedType.AwaitingMlsUpdate, "Awaiting For MLS Update (AMU)"],
  [ListedType.Listed, "Listed"],
  [ListedType.NonSold, "Not Sold"],
  [ListedType.Unlisted, "Unlisted"]
]);
