export enum GolfCourseName {
  Tour18 = "tour18",
  RoyalOaksCountryClub = "royalOaksCountryClub",
  MagnoliaCreekGolfLinks = "magnoliaCreekGolfLinks",
  BraeBurnCountryClub = "braeBurnCountryClub",
  DeerwoodGolfClub = "deerwoodGolfClub",
  RiverOaksCountryClub = "riverOaksCountryClub",
  NoneSelected = "noneSelected",
  JerseyMeadowGolfCourse = "jerseyMeadowGolfCourse",
  NewportGolfClub = "newportGolfClub",
  QuailValleyGolfCourse = "quailValleyGolfCourse",
  ChaparralRecreationAssociation = "chaparralRecreationAssociation",
  ShadowHawkGolfClub = "shadowHawkGolfClub",
  RiverbendCountryClub = "riverbendCountryClub",
  KingwoodCoveGolfClub = "kingwoodCoveGolfClub",
  TheClubatCarltonWoodsCreekside = "theClubatCarltonWoodsCreekside",
  PineForestCountryClub = "pineForestCountryClub",
  BayOaksCountryClub = "bayOaksCountryClub",
  SouthShoreHarbourCC = "southShoreHarbourCC",
  JacksonOaksGolfClub = "jacksonOaksGolfClub",
  WaldenonLakeConroe = "waldenonLakeConroe",
  GalvestonCountryClub = "galvestonCountryClub",
  TheFallsGolfClub = "theFallsGolfClub",
  AprilSoundCountryClub = "aprilSoundCountryClub",
  SanJacintoCollegeGolfCourse = "sanJacintoCollegeGolfCourse",
  WoodlandsResortAndConfCenter = "woodlandsResortAndConfCenter",
  RiverPointeGolfClub = "riverPointeGolfClub",
  WindRoseGolfClub = "windRoseGolfClub",
  FriendswoodGolfCourse = "friendswoodGolfCourse",
  BentwaterCountryClub = "bentwaterCountryClub",
  PublinxGolf = "publinxGolf",
  HillcrestGolfClub = "hillcrestGolfClub",
  SpringValleyGolfClub = "springValleyGolfClub",
  ConroeCountryClub = "conroeCountryClub",
  GolfClubatCincoRanch = "golfClubatCincoRanch",
  RavensNestGolfClub = "ravensNestGolfClub",
  BendatBrazoria = "bendatBrazoria",
  TreelineGolfClub = "treelineGolfClub",
  GreatwoodGolfClub = "greatwoodGolfClub",
  ChambersCountyGolfCourse = "chambersCountyGolfCourse",
  WaldenonLakeHouston = "waldenonLakeHouston",
  TournamentPlayersCourse = "tournamentPlayersCourse",
  PecanGrovePlantationCC = "pecanGrovePlantationCC",
  HermannParkGolfCourse = "hermannParkGolfCourse",
  WaterwoodNationalGolfClub = "waterwoodNationalGolfClub",
  LongwoodGolfClub = "longwoodGolfClub",
  EvergreenPointGolfCourse = "evergreenPointGolfCourse",
  GrandLakeGolfClub = "grandLakeGolfClub",
  HoustonHillsGolfCourse = "houstonHillsGolfCourse",
  Classic3GolfCourse = "classic3GolfCourse",
  DickinsonCountryClub = "dickinsonCountryClub",
  BearCreekGolfWorld = "bearCreekGolfWorld",
  GleannlochFarmsGolfClub = "gleannlochFarmsGolfClub",
  RedstoneGolfClub = "redstoneGolfClub",
  InwoodForestCountryClub = "inwoodForestCountryClub",
  LochinvarGolfClub = "lochinvarGolfClub",
  TheLinksatWestFork = "theLinksatWestFork",
  RedWolfRunGolfClub = "redWolfRunGolfClub",
  ElkinsLakeCountryClub = "elkinsLakeCountryClub",
  MeadowbrookFarms = "meadowbrookFarms",
  WoodlandsCCPalmerCourse = "woodlandsCCPalmerCourse",
  BeaconLakesGolfClub = "beaconLakesGolfClub",
  HoustonOaksGolfandCC = "houstonOaksGolfandCC",
  TheVillageGolfClub = "theVillageGolfClub",
  SharpstownGolfCourse = "sharpstownGolfCourse",
  BlackHorseGolfClub = "blackHorseGolfClub",
  Other = "other",
  AugustaPinesGolfClub = "augustaPinesGolfClub",
  WoodlandsCCPlayerCourse = "woodlandsCCPlayerCourse",
  PearlandGolfClubatCountryPlace = "pearlandGolfClubatCountryPlace",
  GalvestonIslandMunicipal = "galvestonIslandMunicipal",
  WoodlandsCCEastCourse = "woodlandsCCEastCourse",
  MemorialParkGolfCourse = "memorialParkGolfCourse",
  WestwoodGolfClub = "westwoodGolfClub",
  GreenMeadowsGolfClub = "greenMeadowsGolfClub",
  TheBattlegroundatDeerPark = "theBattlegroundatDeerPark",
  HilltopLakesGolfCourse = "hilltopLakesGolfCourse",
  WedgewoodGolfCourse = "wedgewoodGolfCourse",
  KingwoodCountryClub = "kingwoodCountryClub",
  TomballCountryClub = "tomballCountryClub",
  TraditionsGolfClub = "traditionsGolfClub",
  BaywoodCountryClub = "baywoodCountryClub",
  CountryCampusGolfCourse = "countryCampusGolfCourse",
  WestonLakesCountryClub = "westonLakesCountryClub",
  WoodforestGolfClubatFishCreek = "woodforestGolfClubatFishCreek",
  HighMeadowRanchGolfClub = "highMeadowRanchGolfClub",
  TexasNationalGolfClub = "texasNationalGolfClub",
  RaveneauxCountryClub = "raveneauxCountryClub",
  BayouGolfClub = "bayouGolfClub",
  OakhurstGolfClub = "oakhurstGolfClub",
  OldOrchardGolfClub = "oldOrchardGolfClub",
  WillowCreekGolfClub = "willowCreekGolfClub",
  SiennaPlantationGolfClub = "siennaPlantationGolfClub",
  TheWildernessatLakeJackson = "theWildernessatLakeJackson",
  LakesideCountryClub = "lakesideCountryClub",
  RiverTerraceGolfCourse = "riverTerraceGolfCourse",
  TheClubatCarltonWoods = "theClubatCarltonWoods",
  NorthgateCountryClub = "northgateCountryClub",
  FoxCreekGolfClub = "foxCreekGolfClub",
  RiversideCountryClub = "riversideCountryClub",
  LaTorrettaLakeResortAndSpa = "laTorrettaLakeResortAndSpa",
  GooseCreekCountryClub = "gooseCreekCountryClub",
  LakeviewCountryClub = "lakeviewCountryClub",
  RiverPlantationCountryClub = "riverPlantationCountryClub",
  WoodsonsReserve = "woodsonsReserve",
  GusWorthamGolfCourse = "gusWorthamGolfCourse",
  SugarCreekCountryClub = "sugarCreekCountryClub",
  StephenFAustinGolfClub = "stephenFAustinGolfClub",
  MontgomeryCountyGolfCenter = "montgomeryCountyGolfCenter",
  WorldHoustonGolfCourse = "worldHoustonGolfCourse",
  HackberryGolfClub = "hackberryGolfClub",
  HeronLakesGolfCourse = "heronLakesGolfCourse",
  TexacoCountryClub = "texacoCountryClub",
  FortBendCountryClub = "fortBendCountryClub",
  SouthwyckGolfClub = "southwyckGolfClub",
  SugarHillGolfCourse = "sugarHillGolfCourse",
  WoodlandsCCTheOaks = "woodlandsCCTheOaks",
  WallerCountryClub = "wallerCountryClub",
  PasadenaMunicipalGolfCourse = "pasadenaMunicipalGolfCourse",
  HearthstoneCountryClub = "hearthstoneCountryClub",
  GolfcrestCountryClub = "golfcrestCountryClub",
  ChampionsGolfClub = "championsGolfClub",
  MoreGolfClub = "moreGolfClub",
  BrockParkMunicipal = "brockParkMunicipal",
  HoustonNationalGolfClub = "houstonNationalGolfClub",
  TheHoustonianGolfandCC = "theHoustonianGolfandCC",
  MulligansGolf = "mulligansGolf",
  IndianShoresGolfCourse = "indianShoresGolfCourse",
  CypresswoodGolfClub = "cypresswoodGolfClub",
  WestwoodShoresCountryClub = "westwoodShoresCountryClub",
  WildcatGolfClub = "wildcatGolfClub",
  CypressLakes = "cypressLakes",
  ColumbiaLakesResort = "columbiaLakesResort",
  AlvinGolfandCountryClub = "alvinGolfandCountryClub",
  LaTorretta = "laTorretta",
  TimberCreekGolfClub = "timberCreekGolfClub",
  TheClubatFalconPoint = "theClubatFalconPoint",
  TerraVerdeGolfClub = "terraVerdeGolfClub",
  RiverRidgeGolfClub = "riverRidgeGolfClub",
  HoustonCountryClub = "houstonCountryClub",
  TheGolfPreserveofAtascocita = "theGolfPreserveofAtascocita",
  GlenbrookGolfCourse = "glenbrookGolfCourse",
  BlaketreeNationalGolfClub = "blaketreeNationalGolfClub",
  SweetwaterCountryClub = "sweetwaterCountryClub",
  WillowForkCountryClub = "willowForkCountryClub",
  BayForestGolfCourse = "bayForestGolfCourse",
  CapeRoyaleGolfClub = "capeRoyaleGolfClub",
  PineCrestGolfClub = "pineCrestGolfClub",
  ClearCreekGolfClub = "clearCreekGolfClub",
  WillowispCountryClub = "willowispCountryClub",
  MelroseGolfCourse = "melroseGolfCourse",
  LegendaryOaksGolfCourse = "legendaryOaksGolfCourse",
  LakeWindcrestGolfClub = "lakeWindcrestGolfClub",
  EaglePointeGolfClub = "eaglePointeGolfClub",
  HighlandPinesGolfClub = "highlandPinesGolfClub"
}
export const GolfCourseLabel = new Map<GolfCourseName, string>([
  [GolfCourseName.Tour18, "Tour 18"],
  [GolfCourseName.RoyalOaksCountryClub, "Royal Oaks Country Club"],
  [GolfCourseName.MagnoliaCreekGolfLinks, "Magnolia Creek Golf Links"],
  [GolfCourseName.BraeBurnCountryClub, "BraeBurn Country Club"],
  [GolfCourseName.DeerwoodGolfClub, "Deerwood Golf Club"],
  [GolfCourseName.RiverOaksCountryClub, "River Oaks Country Club"],
  [GolfCourseName.NoneSelected, " None Selected"],
  [GolfCourseName.JerseyMeadowGolfCourse, "Jersey Meadow Golf Course"],
  [GolfCourseName.NewportGolfClub, "Newport Golf Club"],
  [GolfCourseName.QuailValleyGolfCourse, "Quail Valley Golf Course"],
  [
    GolfCourseName.ChaparralRecreationAssociation,
    "Chaparral Recreation Association"
  ],
  [GolfCourseName.ShadowHawkGolfClub, "Shadow Hawk Golf Club"],
  [GolfCourseName.RiverbendCountryClub, "Riverbend Country Club"],
  [GolfCourseName.KingwoodCoveGolfClub, "Kingwood Cove Golf Club"],
  [
    GolfCourseName.TheClubatCarltonWoodsCreekside,
    "The Club at Carlton Woods Creekside"
  ],
  [GolfCourseName.PineForestCountryClub, "Pine Forest Country Club"],
  [GolfCourseName.BayOaksCountryClub, "Bay Oaks Country Club"],
  [GolfCourseName.SouthShoreHarbourCC, "South Shore Harbour CC"],
  [GolfCourseName.JacksonOaksGolfClub, "Jackson Oaks Golf Club"],
  [GolfCourseName.WaldenonLakeConroe, "Walden on Lake Conroe"],
  [GolfCourseName.GalvestonCountryClub, "Galveston Country Club"],
  [GolfCourseName.TheFallsGolfClub, "The Falls Golf Club"],
  [GolfCourseName.AprilSoundCountryClub, "April Sound Country Club"],
  [
    GolfCourseName.SanJacintoCollegeGolfCourse,
    "San Jacinto College Golf Course"
  ],
  [
    GolfCourseName.WoodlandsResortAndConfCenter,
    "Woodlands Resort & Conf. Center"
  ],
  [GolfCourseName.RiverPointeGolfClub, "River Pointe Golf Club"],
  [GolfCourseName.WindRoseGolfClub, "WindRose Golf Club"],
  [GolfCourseName.FriendswoodGolfCourse, "Friendswood Golf Course"],
  [GolfCourseName.BentwaterCountryClub, "Bentwater Country Club"],
  [GolfCourseName.PublinxGolf, "Publinx Golf"],
  [GolfCourseName.HillcrestGolfClub, "Hillcrest Golf Club"],
  [GolfCourseName.SpringValleyGolfClub, "Spring Valley Golf Club"],
  [GolfCourseName.ConroeCountryClub, "Conroe Country Club"],
  [GolfCourseName.GolfClubatCincoRanch, "Golf Club at Cinco Ranch"],
  [GolfCourseName.RavensNestGolfClub, "Ravens Nest Golf Club"],
  [GolfCourseName.BendatBrazoria, "Bend at Brazoria"],
  [GolfCourseName.TreelineGolfClub, "Treeline Golf Club"],
  [GolfCourseName.GreatwoodGolfClub, "Greatwood Golf Club"],
  [GolfCourseName.ChambersCountyGolfCourse, "Chambers County Golf Course"],
  [GolfCourseName.WaldenonLakeHouston, "Walden on Lake Houston"],
  [GolfCourseName.TournamentPlayersCourse, "Tournament Players Course"],
  [GolfCourseName.PecanGrovePlantationCC, "Pecan Grove Plantation CC"],
  [GolfCourseName.HermannParkGolfCourse, "Hermann Park Golf Course"],
  [GolfCourseName.WaterwoodNationalGolfClub, "Waterwood National Golf Club"],
  [GolfCourseName.LongwoodGolfClub, "Longwood Golf Club"],
  [GolfCourseName.EvergreenPointGolfCourse, "Evergreen Point Golf Course"],
  [GolfCourseName.GrandLakeGolfClub, "Grand Lake Golf Club"],
  [GolfCourseName.HoustonHillsGolfCourse, "Houston Hills Golf Course"],
  [GolfCourseName.Classic3GolfCourse, "Classic 3 Golf Course"],
  [GolfCourseName.DickinsonCountryClub, "Dickinson Country Club"],
  [GolfCourseName.BearCreekGolfWorld, "Bear Creek Golf World"],
  [GolfCourseName.GleannlochFarmsGolfClub, "Gleannloch Farms Golf Club"],
  [GolfCourseName.RedstoneGolfClub, "Redstone Golf Club"],
  [GolfCourseName.InwoodForestCountryClub, "Inwood Forest Country Club"],
  [GolfCourseName.LochinvarGolfClub, "Lochinvar Golf Club"],
  [GolfCourseName.TheLinksatWestFork, "The Links at West Fork"],
  [GolfCourseName.RedWolfRunGolfClub, "Red Wolf Run Golf Club"],
  [GolfCourseName.ElkinsLakeCountryClub, "Elkins Lake Country Club"],
  [GolfCourseName.MeadowbrookFarms, "Meadowbrook Farms"],
  [GolfCourseName.WoodlandsCCPalmerCourse, "Woodlands CC - Palmer Course"],
  [GolfCourseName.BeaconLakesGolfClub, "Beacon Lakes Golf Club"],
  [GolfCourseName.HoustonOaksGolfandCC, "Houston Oaks Golf and CC"],
  [GolfCourseName.TheVillageGolfClub, "The Village Golf Club"],
  [GolfCourseName.SharpstownGolfCourse, "Sharpstown Golf Course"],
  [GolfCourseName.BlackHorseGolfClub, "BlackHorse Golf Club"],
  [GolfCourseName.Other, "Other"],
  [GolfCourseName.AugustaPinesGolfClub, "Augusta Pines Golf Club"],
  [GolfCourseName.WoodlandsCCPlayerCourse, "Woodlands CC - Player Course"],
  [
    GolfCourseName.PearlandGolfClubatCountryPlace,
    "Pearland Golf Club at Country Place"
  ],
  [GolfCourseName.GalvestonIslandMunicipal, "Galveston Island Municipal"],
  [GolfCourseName.WoodlandsCCEastCourse, "Woodlands CC - East Course"],
  [GolfCourseName.MemorialParkGolfCourse, "Memorial Park Golf Course"],
  [GolfCourseName.WestwoodGolfClub, "Westwood Golf Club"],
  [GolfCourseName.GreenMeadowsGolfClub, "Green Meadows Golf Club"],
  [GolfCourseName.TheBattlegroundatDeerPark, "The Battleground at Deer Park"],
  [GolfCourseName.HilltopLakesGolfCourse, "Hilltop Lakes Golf Course"],
  [GolfCourseName.WedgewoodGolfCourse, "Wedgewood Golf Course"],
  [GolfCourseName.KingwoodCountryClub, "Kingwood Country Club"],
  [GolfCourseName.TomballCountryClub, "Tomball Country Club"],
  [GolfCourseName.TraditionsGolfClub, "Traditions Golf Club"],
  [GolfCourseName.BaywoodCountryClub, "Baywood Country Club"],
  [GolfCourseName.CountryCampusGolfCourse, "Country Campus Golf Course"],
  [GolfCourseName.WestonLakesCountryClub, "Weston Lakes Country Club"],
  [
    GolfCourseName.WoodforestGolfClubatFishCreek,
    "Woodforest Golf Club at Fish Creek"
  ],
  [GolfCourseName.HighMeadowRanchGolfClub, "High Meadow Ranch Golf Club"],
  [GolfCourseName.TexasNationalGolfClub, "Texas National Golf Club"],
  [GolfCourseName.RaveneauxCountryClub, "Raveneaux Country Club"],
  [GolfCourseName.BayouGolfClub, "Bayou Golf Club"],
  [GolfCourseName.OakhurstGolfClub, "Oakhurst Golf Club"],
  [GolfCourseName.OldOrchardGolfClub, "Old Orchard Golf Club"],
  [GolfCourseName.WillowCreekGolfClub, "Willow Creek Golf Club"],
  [GolfCourseName.SiennaPlantationGolfClub, "Sienna Plantation Golf Club"],
  [GolfCourseName.TheWildernessatLakeJackson, "The Wilderness at Lake Jackson"],
  [GolfCourseName.LakesideCountryClub, "Lakeside Country Club"],
  [GolfCourseName.RiverTerraceGolfCourse, "River Terrace Golf Course"],
  [GolfCourseName.TheClubatCarltonWoods, "The Club at Carlton Woods"],
  [GolfCourseName.NorthgateCountryClub, "Northgate Country Club"],
  [GolfCourseName.FoxCreekGolfClub, "Fox Creek Golf Club"],
  [GolfCourseName.RiversideCountryClub, "Riverside Country Club"],
  [GolfCourseName.LaTorrettaLakeResortAndSpa, "La Torretta Lake Resort & Spa"],
  [GolfCourseName.GooseCreekCountryClub, "Goose Creek Country Club"],
  [GolfCourseName.LakeviewCountryClub, "Lakeview Country Club"],
  [GolfCourseName.RiverPlantationCountryClub, "River Plantation Country Club"],
  [GolfCourseName.WoodsonsReserve, "Woodson's Reserve"],
  [GolfCourseName.GusWorthamGolfCourse, "Gus Wortham Golf Course"],
  [GolfCourseName.SugarCreekCountryClub, "Sugar Creek Country Club"],
  [GolfCourseName.StephenFAustinGolfClub, "Stephen F. Austin Golf Club"],
  [GolfCourseName.MontgomeryCountyGolfCenter, "Montgomery County Golf Center"],
  [GolfCourseName.WorldHoustonGolfCourse, "World Houston Golf Course"],
  [GolfCourseName.HackberryGolfClub, "Hackberry Golf Club"],
  [GolfCourseName.HeronLakesGolfCourse, "Heron Lakes Golf Course"],
  [GolfCourseName.TexacoCountryClub, "Texaco Country Club*"],
  [GolfCourseName.FortBendCountryClub, "Fort Bend Country Club"],
  [GolfCourseName.SouthwyckGolfClub, "Southwyck Golf Club"],
  [GolfCourseName.SugarHillGolfCourse, "Sugar Hill Golf Course"],
  [GolfCourseName.WoodlandsCCTheOaks, "Woodlands CC - The Oaks"],
  [GolfCourseName.WallerCountryClub, "Waller Country Club"],
  [
    GolfCourseName.PasadenaMunicipalGolfCourse,
    "Pasadena Municipal Golf Course"
  ],
  [GolfCourseName.HearthstoneCountryClub, "Hearthstone Country Club"],
  [GolfCourseName.GolfcrestCountryClub, "Golfcrest Country Club"],
  [GolfCourseName.ChampionsGolfClub, "Champions Golf Club"],
  [GolfCourseName.MoreGolfClub, "More Golf Club"],
  [GolfCourseName.BrockParkMunicipal, "Brock Park Municipal"],
  [GolfCourseName.HoustonNationalGolfClub, "Houston National Golf Club"],
  [GolfCourseName.TheHoustonianGolfandCC, "The Houstonian Golf and CC"],
  [GolfCourseName.MulligansGolf, "Mulligans Golf"],
  [GolfCourseName.IndianShoresGolfCourse, "Indian Shores Golf Course"],
  [GolfCourseName.CypresswoodGolfClub, "Cypresswood Golf Club"],
  [GolfCourseName.WestwoodShoresCountryClub, "Westwood Shores Country Club"],
  [GolfCourseName.WildcatGolfClub, "Wildcat Golf Club"],
  [GolfCourseName.CypressLakes, "Cypress Lakes"],
  [GolfCourseName.ColumbiaLakesResort, "Columbia Lakes Resort"],
  [GolfCourseName.AlvinGolfandCountryClub, "Alvin Golf and Country Club"],
  [GolfCourseName.LaTorretta, "La Torretta"],
  [GolfCourseName.TimberCreekGolfClub, "Timber Creek Golf Club"],
  [GolfCourseName.TheClubatFalconPoint, "The Club at Falcon Point"],
  [GolfCourseName.TerraVerdeGolfClub, "Terra Verde Golf Club"],
  [GolfCourseName.RiverRidgeGolfClub, "River Ridge Golf Club"],
  [GolfCourseName.HoustonCountryClub, "Houston Country Club"],
  [
    GolfCourseName.TheGolfPreserveofAtascocita,
    "The Golf Preserve of Atascocita"
  ],
  [GolfCourseName.GlenbrookGolfCourse, "Glenbrook Golf Course"],
  [GolfCourseName.BlaketreeNationalGolfClub, "Blaketree National Golf Club"],
  [GolfCourseName.SweetwaterCountryClub, "Sweetwater Country Club"],
  [GolfCourseName.WillowForkCountryClub, "Willow Fork Country Club"],
  [GolfCourseName.BayForestGolfCourse, "Bay Forest Golf Course"],
  [GolfCourseName.CapeRoyaleGolfClub, "Cape Royale Golf Club"],
  [GolfCourseName.PineCrestGolfClub, "Pine Crest Golf Club"],
  [GolfCourseName.ClearCreekGolfClub, "Clear Creek Golf Club"],
  [GolfCourseName.WillowispCountryClub, "Willowisp Country Club"],
  [GolfCourseName.MelroseGolfCourse, "Melrose Golf Course"],
  [GolfCourseName.LegendaryOaksGolfCourse, "Legendary Oaks Golf Course"],
  [GolfCourseName.LakeWindcrestGolfClub, "Lake Windcrest Golf Club"],
  [GolfCourseName.EaglePointeGolfClub, "Eagle Pointe Golf Club"],
  [GolfCourseName.HighlandPinesGolfClub, "Highland Pines Golf Club"]
]);
