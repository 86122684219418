import React from "react";

import {
  FieldErrorProps,
  FormContext,
  getIdFromInputName
} from "@homesusa/form";

export const useDispatchErrors = <TCustomError extends string>(
  customErrors: Record<TCustomError, string>
): {
  addOrRemoveError: (
    inputName: string,
    payload?: FieldErrorProps | null
  ) => void;
  removeCustomErrors: () => void;
} => {
  const { formErrorsDispatch } = React.useContext(FormContext);

  const removeCustomErrors = (): void => {
    if (!formErrorsDispatch) {
      return;
    }
    Object.values(customErrors).forEach((inputName) => {
      formErrorsDispatch({
        type: "Remove",
        inputName: inputName as string
      });
    });
  };

  const addOrRemoveError = (
    inputName: string,
    payload?: FieldErrorProps | null
  ): void => {
    if (!formErrorsDispatch) {
      return;
    }
    if (payload && payload.errors.length > 0) {
      formErrorsDispatch({
        type: "Add",
        payload: {
          ...payload,
          id: getIdFromInputName(inputName)
        },
        inputName
      });
    } else {
      formErrorsDispatch({
        type: "Remove",
        inputName
      });
    }
  };

  return {
    addOrRemoveError,
    removeCustomErrors
  };
};
