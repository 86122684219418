export enum LotDescription {
  Corner = "corner",
  CulDeSac = "culDeSac",
  OnGolfCourse = "onGolfCourse",
  OnGreenbelt = "onGreenbelt",
  BluffView = "bluffView",
  CityView = "cityView",
  CountyView = "countyView",
  Irregular = "irregular",
  QuarterToHalfAcre = "quarterToHalfAcre",
  HalfToOneAcre = "halfToOneAcre",
  OneToTwoAcres = "oneToTwoAcres",
  ZeroLotLine = "zeroLotLine",
  PartiallyWooded = "partiallyWooded",
  Wooded = "wooded",
  MatureTrees = "matureTrees",
  Secluded = "secluded",
  GentlyRolling = "gentlyRolling",
  Sloping = "sloping",
  Level = "level",
  Xeriscaped = "xeriscaped",
  Creek = "creek",
  LakeDunlap = "lakeDunlap",
  OtherWaterAccessSeeRemarks = "otherWaterAccessSeeRemarks",
  WaterAccess = "waterAccess"
}

export const LotDescriptionLabel = new Map<LotDescription, string>([
  [LotDescription.Corner, "Corner"],
  [LotDescription.CulDeSac, "Cul-de-Sac/Dead End"],
  [LotDescription.OnGolfCourse, "On Golf Course"],
  [LotDescription.OnGreenbelt, "On Greenbelt"],
  [LotDescription.BluffView, "Bluff View"],
  [LotDescription.CityView, "City View"],
  [LotDescription.CountyView, "County View"],
  [LotDescription.Irregular, "Irregular"],
  [LotDescription.QuarterToHalfAcre, "1/4 - 1/2 Acre"],
  [LotDescription.HalfToOneAcre, "1/2-1 Acre"],
  [LotDescription.OneToTwoAcres, "1 - 2 Acres"],
  [LotDescription.ZeroLotLine, "Zero Lot Line"],
  [LotDescription.PartiallyWooded, "Partially Wooded"],
  [LotDescription.Wooded, "Wooded"],
  [LotDescription.MatureTrees, "Mature Trees (ext feat)"],
  [LotDescription.Secluded, "Secluded"],
  [LotDescription.GentlyRolling, "Gently Rolling"],
  [LotDescription.Sloping, "Sloping"],
  [LotDescription.Level, "Level"],
  [LotDescription.Xeriscaped, "Xeriscaped"],
  [LotDescription.Creek, "Creek"],
  [LotDescription.LakeDunlap, "Lake Dunlap"],
  [
    LotDescription.OtherWaterAccessSeeRemarks,
    "Other Water Access - See Remarks"
  ],
  [LotDescription.WaterAccess, "Water Access"]
]);
