export enum RoomFeatures {
  CeilingFan = "ceilingFan",
  DualMasters = "dualMasters",
  DownStairs = "downStairs",
  FullBath = "fullBath",
  HalfBath = "halfBath",
  MultiClosets = "multiClosets",
  NotApplicableNone = "notApplicable",
  Other = "other",
  OutsideAccess = "outsideAccess",
  SittingRoom = "sittingRoom",
  Split = "split",
  Upstairs = "upstairs",
  WalkInCloset = "walkInCloset",
  TubHasWhirlpool = "tubHasWhirlpool",
  TubShowerSeparate = "tubShowerSeparate",
  TubShowerCombo = "tubShowerCombo",
  TubOnly = "tubOnly",
  ShowerOnly = "showerOnly",
  SingleVanity = "singleVanity",
  SeparateVanity = "separateVanity",
  NoTubOrShower = "noTubOrShower",
  GardenTub = "gardenTub",
  DoubleVanity = "doubleVanity",
  Bidet = "bidet"
}

export const RoomFeaturesLabel = new Map<RoomFeatures, string>([
  [RoomFeatures.CeilingFan, "Ceiling Fan"],
  [RoomFeatures.DualMasters, "Dual Masters"],
  [RoomFeatures.DownStairs, "DownStairs"],
  [RoomFeatures.FullBath, "Full Bath"],
  [RoomFeatures.HalfBath, "Half Bath"],
  [RoomFeatures.MultiClosets, "Multi-Closets"],
  [RoomFeatures.NotApplicableNone, "Not Applicable/None"],
  [RoomFeatures.Other, "Other"],
  [RoomFeatures.OutsideAccess, "Outside Access"],
  [RoomFeatures.SittingRoom, "Sitting Room"],
  [RoomFeatures.Split, "Split"],
  [RoomFeatures.Upstairs, "Upstairs"],
  [RoomFeatures.WalkInCloset, "Walk-In Closet"],
  [RoomFeatures.TubHasWhirlpool, "Tub has Whirlpool"],
  [RoomFeatures.TubShowerSeparate, "Tub/Shower Separate"],
  [RoomFeatures.TubShowerCombo, "Tub/Shower Combo"],
  [RoomFeatures.TubOnly, "Tub Only"],
  [RoomFeatures.ShowerOnly, "Shower Only"],
  [RoomFeatures.SingleVanity, "Single Vanity"],
  [RoomFeatures.SeparateVanity, "Separate Vanity"],
  [RoomFeatures.NoTubOrShower, "None/No Tub or Shower"],
  [RoomFeatures.GardenTub, "Garden Tub"],
  [RoomFeatures.DoubleVanity, "Double Vanity"],
  [RoomFeatures.Bidet, "Bidet"]
]);

export const MasterBedroomRoomFeatures = [
  RoomFeatures.CeilingFan,
  RoomFeatures.DualMasters,
  RoomFeatures.DownStairs,
  RoomFeatures.FullBath,
  RoomFeatures.HalfBath,
  RoomFeatures.MultiClosets,
  RoomFeatures.NotApplicableNone,
  RoomFeatures.Other,
  RoomFeatures.OutsideAccess,
  RoomFeatures.SittingRoom,
  RoomFeatures.Split,
  RoomFeatures.Upstairs,
  RoomFeatures.WalkInCloset
];

export const MasterBathRoomFeatures = [
  RoomFeatures.TubHasWhirlpool,
  RoomFeatures.TubShowerSeparate,
  RoomFeatures.TubShowerCombo,
  RoomFeatures.ShowerOnly,
  RoomFeatures.SingleVanity,
  RoomFeatures.SeparateVanity,
  RoomFeatures.NoTubOrShower,
  RoomFeatures.GardenTub,
  RoomFeatures.DoubleVanity
];
