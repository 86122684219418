import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";
import { LotSize, LotSizeLabel } from "markets/dfw/enums";

import {
  constructionStageRules,
  constructionStartYearRules,
  lotDescriptionRules,
  housingTypeRules,
  propertySubTypeRules,
  plannedCommunityRules,
  lakeNameRules,
  hasPropertyAttachedRules,
  propertyTypeRules,
  lotSizeAreaRules
} from "../../sections/property-rules";

export const propertyInfoRules: FormRules = {
  lot: {
    label: "Lot",
    maxLength: 25
  },
  block: {
    label: "Block",
    maxLength: 25
  },
  ...lotSizeAreaRules,
  lotDimension: {
    label: "Lot Dimensions",
    maxLength: 25
  },
  lotSize: {
    label: "Lot Size/Acreage",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(LotSize), LotSizeLabel)
  },
  constructionCompletionDate: {
    label: "Estimated Completion Date",
    tooltip: "Estimated completion date where applicable."
  },
  constructionStage: constructionStageRules,
  constructionStartYear: constructionStartYearRules,
  taxId: { label: "Tax ID #", maxLength: 50 },
  plannedCommunity: plannedCommunityRules,
  lakeName: lakeNameRules,
  updateGeocodes: { label: "Update Geocodes" },
  latitude: latitudeRule,
  longitude: longitudeRule,
  propertyType: propertyTypeRules,
  housingType: housingTypeRules,
  hasPropertyAttached: hasPropertyAttachedRules,
  propertySubType: propertySubTypeRules,
  lotDescription: lotDescriptionRules
};
