import React from "react";

import { Company, SelectCompany as CoreSelectCompany } from "@homesusa/core";
import { useUserServices } from "@homesusa/auth";

export function SelectCompany({
  onSelect,
  defaultSelected
}: {
  defaultSelected?: string;
  onSelect: (value: Company | undefined) => void;
}): JSX.Element {
  const { getCompanies } = useUserServices();
  return (
    <CoreSelectCompany
      onSelect={onSelect}
      defaultSelected={defaultSelected}
      getCompanies={getCompanies}
    />
  );
}
