export enum County {
  Anderson = "anderson",
  Andrews = "andrews",
  Angelina = "angelina",
  Aransas = "aransas",
  Archer = "archer",
  Armstrong = "armstrong",
  Atascosa = "atascosa",
  Austin = "austin",
  Bailey = "bailey",
  Bandera = "bandera",
  Bastrop = "bastrop",
  Baylor = "baylor",
  Bee = "bee",
  Bell = "bell",
  Bexar = "bexar",
  Blanco = "blanco",
  Borden = "borden",
  Bosque = "bosque",
  Bowie = "bowie",
  Brazoria = "brazoria",
  Brazos = "brazos",
  Brewster = "brewster",
  Briscoe = "briscoe",
  Brooks = "brooks",
  Brown = "brown",
  Burleson = "burleson",
  Burnet = "burnet",
  Caldwell = "caldwell",
  Calhoun = "calhoun",
  Callahan = "callahan",
  Cameron = "cameron",
  Camp = "camp",
  Carson = "carson",
  Cass = "cass",
  Castro = "castro",
  Chambers = "chambers",
  Cherokee = "cherokee",
  Childress = "childress",
  Chisumisd = "chisumisd",
  Clay = "clay",
  Cochran = "cochran",
  Coke = "coke",
  Coleman = "coleman",
  Collin = "collin",
  Collingsworth = "collingsworth",
  Colorado = "colorado",
  Comal = "comal",
  Comanche = "comanche",
  Concho = "concho",
  Cooke = "cooke",
  Coryell = "coryell",
  Cottle = "cottle",
  Crane = "crane",
  Crockett = "crockett",
  Crosby = "crosby",
  Culberson = "culberson",
  Dallam = "dallam",
  Dallas = "dallas",
  Dawson = "dawson",
  DeafSmith = "deafSmith",
  Delta = "delta",
  Denton = "denton",
  DeWitt = "deWitt",
  Dickens = "dickens",
  Dimmit = "dimmit",
  Donley = "donley",
  Duval = "duval",
  Eastland = "eastland",
  Ector = "ector",
  Edwards = "edwards",
  Ellis = "ellis",
  ElPaso = "elPaso",
  Erath = "erath",
  Falls = "falls",
  Fannin = "fannin",
  Fayette = "fayette",
  Fisher = "fisher",
  Floyd = "floyd",
  Foard = "foard",
  FortBend = "fortBend",
  Franklin = "franklin",
  Freestone = "freestone",
  Frio = "frio",
  Gaines = "gaines",
  Galveston = "galveston",
  Garza = "garza",
  Gillespie = "gillespie",
  Glasscock = "glasscock",
  Goliad = "goliad",
  Gonzales = "gonzales",
  Gray = "gray",
  Grayson = "grayson",
  Gregg = "gregg",
  Grimes = "grimes",
  Guadalupe = "guadalupe",
  Hale = "hale",
  Hall = "hall",
  Hamilton = "hamilton",
  Hansford = "hansford",
  Hardeman = "hardeman",
  Hardin = "hardin",
  Harris = "harris",
  Harrison = "harrison",
  Hartley = "hartley",
  Haskell = "haskell",
  Hays = "hays",
  Hemphill = "hemphill",
  Henderson = "henderson",
  Hidalgo = "hidalgo",
  Hill = "hill",
  Hockley = "hockley",
  Hood = "hood",
  Hopkins = "hopkins",
  Houston = "houston",
  Howard = "howard",
  Hudspeth = "hudspeth",
  Hunt = "hunt",
  Hutchinson = "hutchinson",
  Irion = "irion",
  Jack = "jack",
  Jackson = "jackson",
  Jasper = "jasper",
  Jeff = "jeff",
  Jefferson = "jefferson",
  Jimhogg = "jimhogg",
  JimWells = "jimWells",
  Johnson = "johnson",
  Jones = "jones",
  Karnes = "karnes",
  Kaufman = "kaufman",
  Kendall = "kendall",
  Kenedy = "kenedy",
  Kent = "kent",
  Kerr = "kerr",
  Kimble = "kimble",
  King = "king",
  Kinney = "kinney",
  Kleberg = "kleberg",
  Knox = "knox",
  Knoxcounty = "knoxcounty",
  Lamar = "lamar",
  Lamb = "lamb",
  Lampasas = "lampasas",
  LaSalle = "laSalle",
  Lavaca = "lavaca",
  Lee = "lee",
  Leon = "leon",
  Liberty = "liberty",
  Limestone = "limestone",
  Lipscomb = "lipscomb",
  LiveOak = "liveOak",
  Llano = "llano",
  Loving = "loving",
  Lubbock = "lubbock",
  Lynn = "lynn",
  Madison = "madison",
  Marion = "marion",
  Martin = "martin",
  Mason = "mason",
  Matagorda = "matagorda",
  Maverick = "maverick",
  McCulloch = "mcculloch",
  McLennan = "mcLennan",
  Medina = "medina",
  Menard = "menard",
  Midland = "midland",
  Milam = "milam",
  Mills = "mills",
  Mitchell = "mitchell",
  Montague = "montague",
  Montgomery = "montgomery",
  Moore = "moore",
  Morris = "morris",
  Motley = "motley",
  Nacogdoches = "nacogdoches",
  Navarro = "navarro",
  Newton = "newton",
  Nolan = "nolan",
  Nueces = "nueces",
  Ochiltree = "ochiltree",
  Oldham = "oldham",
  Orange = "orange",
  Other = "other",
  PaloPinto = "paloPinto",
  Panola = "panola",
  Parker = "parker",
  Parmer = "parmer",
  Pecos = "pecos",
  Polk = "polk",
  Potter = "potter",
  Presidio = "presidio",
  Rains = "rains",
  Randall = "randall",
  Reagan = "reagan",
  Real = "real",
  RedRiver = "redRiver",
  Reeves = "reeves",
  Refugio = "refugio",
  Roberts = "roberts",
  Robertson = "robertson",
  Rockwall = "rockwall",
  Runnels = "runnels",
  Rusk = "rusk",
  Sabine = "sabine",
  SanAugustine = "sanAugustine",
  SanJacinto = "sanJacinto",
  SanPatricio = "sanPatricio",
  SanSaba = "sanSaba",
  Schleicher = "schleicher",
  Scurry = "scurry",
  Shackelford = "shackelford",
  Shelby = "shelby",
  Sherman = "sherman",
  Smith = "smith",
  Somervell = "somervell",
  Starr = "starr",
  Stephens = "stephens",
  Sterling = "sterling",
  Stonewall = "stonewall",
  Sutton = "sutton",
  Swisher = "swisher",
  Tarrant = "tarrant",
  Taylor = "taylor",
  Terrell = "terrell",
  Terry = "terry",
  Throckmorton = "throckmorton",
  Titus = "titus",
  TomGreen = "tomGreen",
  Travis = "travis",
  Trinity = "trinity",
  Tyler = "tyler",
  Upshur = "upshur",
  Upton = "upton",
  Uvalde = "uvalde",
  ValVerde = "valVerde",
  VanZandt = "vanZandt",
  Victoria = "victoria",
  Walker = "walker",
  Waller = "waller",
  Ward = "ward",
  Washington = "washington",
  Webb = "webb",
  Wharton = "wharton",
  Wheeler = "wheeler",
  Wichita = "wichita",
  Wilbarger = "wilbarger",
  Willacy = "willacy",
  Williamson = "williamson",
  Wilson = "wilson",
  Winkler = "winkler",
  Wise = "wise",
  Wood = "wood",
  Yoakum = "yoakum",
  Young = "young",
  Zapata = "zapata",
  Zavala = "zavala"
}

export const CountyLabel: Record<County, string> = {
  [County.Anderson]: "Anderson",
  [County.Andrews]: "Andrews",
  [County.Angelina]: "Angelina",
  [County.Aransas]: "Aransas",
  [County.Archer]: "Archer",
  [County.Armstrong]: "Armstrong",
  [County.Atascosa]: "Atascosa",
  [County.Austin]: "Austin",
  [County.Bailey]: "Bailey",
  [County.Bandera]: "Bandera",
  [County.Bastrop]: "Bastrop",
  [County.Baylor]: "Baylor",
  [County.Bee]: "Bee",
  [County.Bell]: "Bell",
  [County.Bexar]: "Bexar",
  [County.Blanco]: "Blanco",
  [County.Borden]: "Borden",
  [County.Bosque]: "Bosque",
  [County.Bowie]: "Bowie",
  [County.Brazoria]: "Brazoria",
  [County.Brazos]: "Brazos",
  [County.Brewster]: "Brewster",
  [County.Briscoe]: "Briscoe",
  [County.Brooks]: "Brooks",
  [County.Brown]: "Brown",
  [County.Burleson]: "Burleson",
  [County.Burnet]: "Burnet",
  [County.Caldwell]: "Caldwell",
  [County.Calhoun]: "Calhoun",
  [County.Callahan]: "Callahan",
  [County.Cameron]: "Cameron",
  [County.Camp]: "Camp",
  [County.Carson]: "Carson",
  [County.Cass]: "Cass",
  [County.Castro]: "Castro",
  [County.Chambers]: "Chambers",
  [County.Cherokee]: "Cherokee",
  [County.Childress]: "Childress",
  [County.Chisumisd]: "Chisum Isd",
  [County.Clay]: "Clay",
  [County.Cochran]: "Cochran",
  [County.Coke]: "Coke",
  [County.Coleman]: "Coleman",
  [County.Collin]: "Collin",
  [County.Collingsworth]: "Collingsworth",
  [County.Colorado]: "Colorado",
  [County.Comal]: "Comal",
  [County.Comanche]: "Comanche",
  [County.Concho]: "Concho",
  [County.Cooke]: "Cooke",
  [County.Coryell]: "Coryell",
  [County.Cottle]: "Cottle",
  [County.Crane]: "Crane",
  [County.Crockett]: "Crockett",
  [County.Crosby]: "Crosby",
  [County.Culberson]: "Culberson",
  [County.Dallam]: "Dallam",
  [County.Dallas]: "Dallas",
  [County.Dawson]: "Dawson",
  [County.DeafSmith]: "Deaf Smith",
  [County.Delta]: "Delta",
  [County.Denton]: "Denton",
  [County.DeWitt]: "DeWitt",
  [County.Dickens]: "Dickens",
  [County.Dimmit]: "Dimmit",
  [County.Donley]: "Donley",
  [County.Duval]: "Duval",
  [County.Eastland]: "Eastland",
  [County.Ector]: "Ector",
  [County.Edwards]: "Edwards",
  [County.Ellis]: "Ellis",
  [County.ElPaso]: "El Paso",
  [County.Erath]: "Erath",
  [County.Falls]: "Falls",
  [County.Fannin]: "Fannin",
  [County.Fayette]: "Fayette",
  [County.Fisher]: "Fisher",
  [County.Floyd]: "Floyd",
  [County.Foard]: "Foard",
  [County.FortBend]: "Fort Bend",
  [County.Franklin]: "Franklin",
  [County.Freestone]: "Freestone",
  [County.Frio]: "Frio",
  [County.Gaines]: "Gaines",
  [County.Galveston]: "Galveston",
  [County.Garza]: "Garza",
  [County.Gillespie]: "Gillespie",
  [County.Glasscock]: "Glasscock",
  [County.Goliad]: "Goliad",
  [County.Gonzales]: "Gonzales",
  [County.Gray]: "Gray",
  [County.Grayson]: "Grayson",
  [County.Gregg]: "Gregg",
  [County.Grimes]: "Grimes",
  [County.Guadalupe]: "Guadalupe",
  [County.Hale]: "Hale",
  [County.Hall]: "Hall",
  [County.Hamilton]: "Hamilton",
  [County.Hansford]: "Hansford",
  [County.Hardeman]: "Hardeman",
  [County.Hardin]: "Hardin",
  [County.Harris]: "Harris",
  [County.Harrison]: "Harrison",
  [County.Hartley]: "Hartley",
  [County.Haskell]: "Haskell",
  [County.Hays]: "Hays",
  [County.Hemphill]: "Hemphill",
  [County.Henderson]: "Henderson",
  [County.Hidalgo]: "Hidalgo",
  [County.Hill]: "Hill",
  [County.Hockley]: "Hockley",
  [County.Hood]: "Hood",
  [County.Hopkins]: "Hopkins",
  [County.Houston]: "Houston",
  [County.Howard]: "Howard",
  [County.Hudspeth]: "Hudspeth",
  [County.Hunt]: "Hunt",
  [County.Hutchinson]: "Hutchinson",
  [County.Irion]: "Irion",
  [County.Jack]: "Jack",
  [County.Jackson]: "Jackson",
  [County.Jasper]: "Jasper",
  [County.Jeff]: "Jeff",
  [County.Jefferson]: "Jefferson",
  [County.Jimhogg]: "Jim Hogg",
  [County.JimWells]: "Jim Wells",
  [County.Johnson]: "Johnson",
  [County.Jones]: "Jones",
  [County.Karnes]: "Karnes",
  [County.Kaufman]: "Kaufman",
  [County.Kendall]: "Kendall",
  [County.Kenedy]: "Kenedy",
  [County.Kent]: "Kent",
  [County.Kerr]: "Kerr",
  [County.Kimble]: "Kimble",
  [County.King]: "King",
  [County.Kinney]: "Kinney",
  [County.Kleberg]: "Kleberg",
  [County.Knox]: "Knox",
  [County.Knoxcounty]: "Knox County",
  [County.Lamar]: "Lamar",
  [County.Lamb]: "Lamb",
  [County.Lampasas]: "Lampasas",
  [County.LaSalle]: "La Salle",
  [County.Lavaca]: "Lavaca",
  [County.Lee]: "Lee",
  [County.Leon]: "Leon",
  [County.Liberty]: "Liberty",
  [County.Limestone]: "Limestone",
  [County.Lipscomb]: "Lipscomb",
  [County.LiveOak]: "Live Oak",
  [County.Llano]: "Llano",
  [County.Loving]: "Loving",
  [County.Lubbock]: "Lubbock",
  [County.Lynn]: "Lynn",
  [County.Madison]: "Madison",
  [County.Marion]: "Marion",
  [County.Martin]: "Martin",
  [County.Mason]: "Mason",
  [County.Matagorda]: "Matagorda",
  [County.Maverick]: "Maverick",
  [County.McCulloch]: "McCulloch",
  [County.McLennan]: "McLennan",
  [County.Medina]: "Medina",
  [County.Menard]: "Menard",
  [County.Midland]: "Midland",
  [County.Milam]: "Milam",
  [County.Mills]: "Mills",
  [County.Mitchell]: "Mitchell",
  [County.Montague]: "Montague",
  [County.Montgomery]: "Montgomery",
  [County.Moore]: "Moore",
  [County.Morris]: "Morris",
  [County.Motley]: "Motley",
  [County.Nacogdoches]: "Nacogdoches",
  [County.Navarro]: "Navarro",
  [County.Newton]: "Newton",
  [County.Nolan]: "Nolan",
  [County.Nueces]: "Nueces",
  [County.Ochiltree]: "Ochiltree",
  [County.Oldham]: "Oldham",
  [County.Orange]: "Orange",
  [County.Other]: "Out of State",
  [County.PaloPinto]: "Palo Pinto",
  [County.Panola]: "Panola",
  [County.Parker]: "Parker",
  [County.Parmer]: "Parmer",
  [County.Pecos]: "Pecos",
  [County.Polk]: "Polk",
  [County.Potter]: "Potter",
  [County.Presidio]: "Presidio",
  [County.Rains]: "Rains",
  [County.Randall]: "Randall",
  [County.Reagan]: "Reagan",
  [County.Real]: "Real",
  [County.RedRiver]: "Red River",
  [County.Reeves]: "Reeves",
  [County.Refugio]: "Refugio",
  [County.Roberts]: "Roberts",
  [County.Robertson]: "Robertson",
  [County.Rockwall]: "Rockwall",
  [County.Runnels]: "Runnels",
  [County.Rusk]: "Rusk",
  [County.Sabine]: "Sabine",
  [County.SanAugustine]: "San Augustine",
  [County.SanJacinto]: "San Jacinto",
  [County.SanPatricio]: "San Patricio",
  [County.SanSaba]: "San Saba",
  [County.Schleicher]: "Schleicher",
  [County.Scurry]: "Scurry",
  [County.Shackelford]: "Shackelford",
  [County.Shelby]: "Shelby",
  [County.Sherman]: "Sherman",
  [County.Smith]: "Smith",
  [County.Somervell]: "Somervell",
  [County.Starr]: "Starr",
  [County.Stephens]: "Stephens",
  [County.Sterling]: "Sterling",
  [County.Stonewall]: "Stonewall",
  [County.Sutton]: "Sutton",
  [County.Swisher]: "Swisher",
  [County.Tarrant]: "Tarrant",
  [County.Taylor]: "Taylor",
  [County.Terrell]: "Terrell",
  [County.Terry]: "Terry",
  [County.Throckmorton]: "Throckmorton",
  [County.Titus]: "Titus",
  [County.TomGreen]: "Tom Green",
  [County.Travis]: "Travis",
  [County.Trinity]: "Trinity",
  [County.Tyler]: "Tyler",
  [County.Upshur]: "Upshur",
  [County.Upton]: "Upton",
  [County.Uvalde]: "Uvalde",
  [County.ValVerde]: "Val Verde",
  [County.VanZandt]: "Van Zandt",
  [County.Victoria]: "Victoria",
  [County.Walker]: "Walker",
  [County.Waller]: "Waller",
  [County.Ward]: "Ward",
  [County.Washington]: "Washington",
  [County.Webb]: "Webb",
  [County.Wharton]: "Wharton",
  [County.Wheeler]: "Wheeler",
  [County.Wichita]: "Wichita",
  [County.Wilbarger]: "Wilbarger",
  [County.Willacy]: "Willacy",
  [County.Williamson]: "Williamson",
  [County.Wilson]: "Wilson",
  [County.Winkler]: "Winkler",
  [County.Wise]: "Wise",
  [County.Wood]: "Wood",
  [County.Yoakum]: "Yoakum",
  [County.Young]: "Young",
  [County.Zapata]: "Zapata",
  [County.Zavala]: "Zavala"
};
