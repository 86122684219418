import { Buckets, Option, StockChartData } from "../interfaces";

export const formatChartPoints = (data: Buckets[]): StockChartData[] => {
  const active: { x: Date; y: number }[] = [];
  const pending: { x: Date; y: number }[] = [];
  const sold: { x: Date; y: number }[] = [];

  data.forEach((item) => {
    active.push({
      x: new Date(item.key_as_string),
      y: item.total_active.value
    });
    pending.push({
      x: new Date(item.key_as_string),
      y: item.total_pending.value
    });
    sold.push({
      x: new Date(item.key_as_string),
      y: item.total_sold.value
    });
  });

  return [
    { name: "Active", dataPoints: active },
    { name: "Pending", dataPoints: pending },
    { name: "Sold", dataPoints: sold }
  ];
};

export const formatSubDivisionsArray = (data: Option[]): string[] => {
  return data.map((item) => item.key);
};
