export enum OpenHouseDays {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday"
}

export const Workdays = {
  [OpenHouseDays.Monday]: "Monday",
  [OpenHouseDays.Tuesday]: "Tuesday",
  [OpenHouseDays.Wednesday]: "Wednesday",
  [OpenHouseDays.Thursday]: "Thursday",
  [OpenHouseDays.Friday]: "Friday"
};

export const Weekends = {
  [OpenHouseDays.Saturday]: "Saturday",
  [OpenHouseDays.Sunday]: "Sunday"
};
