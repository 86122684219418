export enum FireplaceDescription {
  Bathroom = "bathroom",
  Bedroom = "bedroom",
  Circulating = "circulating",
  Decorative = "decorative",
  Den = "den",
  DoubleSided = "doubleSided",
  Electric = "electric",
  EPACertifiedWoodStove = "epaCertifiedWoodStove",
  FamilyRoom = "familyRoom",
  Gas = "gas",
  GasLog = "gasLog",
  GasStarter = "gasStarter",
  GreatRoom = "greatRoom",
  LivingRoom = "livingRoom",
  Masonry = "masonry",
  Metal = "metal",
  None = "none",
  Outside = "outside",
  SeeThrough = "seeThrough",
  Ventless = "ventless",
  WoodBurning = "woodBurning"
}
export const FireplaceDescriptionLabel = new Map<FireplaceDescription, string>([
  [FireplaceDescription.Bathroom, "Bathroom"],
  [FireplaceDescription.Bedroom, "Bedroom"],
  [FireplaceDescription.Circulating, "Circulating"],
  [FireplaceDescription.Decorative, "Decorative"],
  [FireplaceDescription.Den, "Den"],
  [FireplaceDescription.DoubleSided, "Double Sided"],
  [FireplaceDescription.Electric, "Electric"],
  [FireplaceDescription.EPACertifiedWoodStove, "EPA Certified Wood Stove"],
  [FireplaceDescription.FamilyRoom, "Family Room"],
  [FireplaceDescription.Gas, "Gas"],
  [FireplaceDescription.GasLog, "Gas Log"],
  [FireplaceDescription.GasStarter, "Gas Starter"],
  [FireplaceDescription.GreatRoom, "Great Room"],
  [FireplaceDescription.LivingRoom, "Living Room"],
  [FireplaceDescription.Masonry, "Masonry"],
  [FireplaceDescription.Metal, "Metal"],
  [FireplaceDescription.None, "None"],
  [FireplaceDescription.Outside, "Outside"],
  [FireplaceDescription.SeeThrough, "See Through"],
  [FireplaceDescription.Ventless, "Ventless"],
  [FireplaceDescription.WoodBurning, "Wood Burning"]
]);
