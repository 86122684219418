export enum AlertType {
  PastDueEstimatedClosingDate = "pastDueEstimatedClosingDate",
  EstimatedClosingDaysOrLess = "estimatedClosingDaysOrLess",
  PastDueEstimatedCompletionDate = "pastDueEstimatedCompletionDate",
  CompletionDateDueDaysOrLess = "completionDateDueDaysOrLess",
  AgentBonusExpirationDate = "agentBonusExpirationDate",
  AgentBonusExpirationDateOrLess = "agentBonusExpirationDateOrLess",
  TempOffMarketBackOnMarket = "tempOffMarketBackOnMarket",
  TempOffMarketBackOnMarketDaysOrLess = "tempOffMarketBackOnMarketDaysOrLess",
  LockedListings = "lockedListings",
  ExpiringListings = "expiringListings",
  NotListedInMls = "notListedInMls",
  ActiveAndPendingListing = "activeAndPendingListing",
  ComparableAndRelistListing = "comparableAndRelistListing",
  InadequatePublicRemarks = "inadequatePublicRemarks",
  CurrentDaysOnMarketOverDays = "currentDaysOnMarketOverDays",
  ActiveEmployees = "activeEmployees",
  PastDuePhotoRequests = "pastDuePhotoRequests",
  CompletedHomesWithoutPhotoRequest = "completedHomesWithoutPhotoRequest",
  OrphanListings = "orphanListings",
  CommunityProfilesMissingInformation = "communityProfilesMissingInformation",
  MarketChangesSinceYesterday = "marketChangesSinceYesterday",
  ScraperHomesToListNow = "scraperHomesToListNow",
  ScraperLeaseToListNow = "scraperLeaseToListNow",
  ScraperAvailableCommunities = "scraperAvailableCommunities",
  ScraperAvailablePlans = "scraperAvailablePlans",
  ScraperListingsAvailableForRestore = "scraperListingsAvailableForRestore",
  ScraperLeasesAvailableForRestore = "scraperLeasesAvailableForRestore",
  ScraperFilesProcessed = "scraperFilesProcessed",
  XmlFilesProcessed = "xmlFilesProcessed",
  XmlLockedListingsImported = "lockedListingsImported",
  XmlListingsAvailableForRestore = "xmlListingsAvailableForRestore",
  XmlHomesToListNow = "xmlHomesToListNow",
  XmlSuggestedComps = "xmlSuggestedComps",
  XmlAvailableCommunities = "xmlAvailableCommunities",
  XmlAvailablePlans = "xmlAvailablePlans",
  XmlInvalidPlans = "xmlListingsWithInvalidPlans",
  XmlListingUpdatedWithoutRequest = "xmlListingUpdatedWithoutRequest",
  ViolationWarningCallList = "violationWarningCallList",
  JsonAvailablePlans = "jsonAvailablePlans",
  JsonAvailableCommunities = "jsonAvailableCommunities",
  JsonHomesToListNow = "jsonHomesToListNow"
}

export const AlertTypeLabel = new Map<AlertType, string>([
  [AlertType.PastDueEstimatedClosingDate, "Past Due - Estimated Closing Date"],
  [
    AlertType.PastDueEstimatedCompletionDate,
    "Past Due - Estimated Completion Date"
  ],
  [
    AlertType.AgentBonusExpirationDate,
    "Past Due - Seller Concession Expiration Date"
  ],
  [
    AlertType.TempOffMarketBackOnMarketDaysOrLess,
    "Back on Market (BOM) Date Expires in 7 Days or Less"
  ],
  [AlertType.LockedListings, "Locked Listings"],
  [AlertType.ExpiringListings, "Expiring Listings"],
  [
    AlertType.CompletedHomesWithoutPhotoRequest,
    "Completed Homes without Photo Requests"
  ],
  [AlertType.PastDuePhotoRequests, "Past Due - Photo Requests"],
  [AlertType.NotListedInMls, "Saved but not submitted to MLS"],
  [
    AlertType.CommunityProfilesMissingInformation,
    "Community Profiles - Missing Information"
  ],
  [AlertType.EstimatedClosingDaysOrLess, "Estimated Closing 7 Days or Less"],
  [
    AlertType.CompletionDateDueDaysOrLess,
    "Completion Date Due in 7 Days or Less"
  ],
  [
    AlertType.AgentBonusExpirationDateOrLess,
    "Seller Concession Date Expires in 7 Days or Less"
  ],
  [AlertType.TempOffMarketBackOnMarket, "Past Due - Back on Market (BOM) Date"],
  [
    AlertType.CurrentDaysOnMarketOverDays,
    "Current Days on Market over 180 Days"
  ],
  [AlertType.InadequatePublicRemarks, "Inadequate Remarks"],
  [AlertType.ActiveEmployees, "Active Employees"],
  [
    AlertType.MarketChangesSinceYesterday,
    "Market Changes since Yesterday 6 AM"
  ],
  [AlertType.OrphanListings, "Orphan Listings"],
  [AlertType.ScraperHomesToListNow, "Homes to List NOW from your website"],
  [
    AlertType.ScraperLeaseToListNow,
    "Lease -Homes to List NOW - From your Website"
  ],
  [AlertType.ScraperAvailableCommunities, "Available Communities"],
  [AlertType.ScraperAvailablePlans, "Available Plans"],
  [
    AlertType.ScraperListingsAvailableForRestore,
    "Deleted Listings Available for Restore"
  ],
  [
    AlertType.ScraperLeasesAvailableForRestore,
    "Deleted leases Available for Restore"
  ],
  [AlertType.ScraperFilesProcessed, "Last Files Processed"],
  [AlertType.XmlFilesProcessed, "Xml Last Files Processed"],

  [AlertType.XmlLockedListingsImported, "Locked Listings Imported"],
  [
    AlertType.XmlListingsAvailableForRestore,
    "Deleted Listings Available for Restore"
  ],
  [AlertType.XmlHomesToListNow, "Homes to List NOW from your website"],
  [AlertType.XmlSuggestedComps, "Suggested Comps"],
  [AlertType.XmlAvailableCommunities, "Available Communities"],
  [AlertType.XmlAvailablePlans, "Available Plans"],
  [
    AlertType.XmlListingUpdatedWithoutRequest,
    "Listings Updated without system requests"
  ],
  [AlertType.XmlInvalidPlans, "Listings With Invalid Plans"],
  [AlertType.ViolationWarningCallList, "Violation Warning Call List"],
  [AlertType.JsonHomesToListNow, "Homes to List NOW from your website"],
  [AlertType.JsonAvailablePlans, "Available Plans"],
  [AlertType.JsonAvailableCommunities, "Available Communities"]
]);
