export enum RoofDescription {
  BuiltUpGravel = "builtUpGravel",
  Composition = "composition",
  Concrete = "concrete",
  HeavyComposition = "heavyComposition",
  Metal = "metal",
  Tile = "tile",
  WoodShingleShake = "woodShingleShake",
  Wood = "wood"
}
export const RoofDescriptionLabel = new Map<RoofDescription, string>([
  [RoofDescription.BuiltUpGravel, "Built-Up/Gravel"],
  [RoofDescription.Composition, "Composition"],
  [RoofDescription.Concrete, "Concrete"],
  [RoofDescription.HeavyComposition, "Heavy Composition"],
  [RoofDescription.Metal, "Metal"],
  [RoofDescription.Tile, "Tile"],
  [RoofDescription.WoodShingleShake, "Wood Shingle/Shake"],
  [RoofDescription.Wood, "Wood"]
]);
