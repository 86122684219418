import React from "react";

import { FormContext } from "@homesusa/form";

interface DispatchProps {
  required: boolean;
  defaultValues: Record<string, unknown>;
  omitDisable?: boolean;
}

export const useRequiredToSubmit = (): ((props: DispatchProps) => void) => {
  const { formErrorsDispatch, formDispatch, formRulesDispatch } =
    React.useContext(FormContext);

  const dispatch = ({
    defaultValues,
    required,
    omitDisable
  }: DispatchProps): void => {
    if (!formRulesDispatch) {
      return;
    }

    Object.keys(defaultValues).forEach((inputName) => {
      formRulesDispatch({
        type: "UpdateByInputName",
        inputName,
        payload: {
          requiredToSubmit: required,
          disabled: !required && !omitDisable
        }
      });
    });

    if (!required) {
      Object.entries(defaultValues).forEach(([inputName, payload]) => {
        if (formErrorsDispatch) {
          formErrorsDispatch({
            type: "Remove",
            inputName
          });
        }

        if (formDispatch) {
          formDispatch({
            type: "FormChangeByInputName",
            inputName,
            payload
          });
        }
      });
    }
  };
  return dispatch;
};
