import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { getSubdivisions } from "../../services/temp.service";
import { formatSubDivisionsArray } from "../../utils";
interface Props {
  label: string;
  onChange: (name: string, value?: string) => void;
  defaultValue?: string;
}

export const ChartAutoComplete = (props: Props): JSX.Element => {
  const [options, setOptions] = React.useState<string[]>([]);
  const defaultValue = React.useMemo(() => props.defaultValue, []);

  React.useEffect(() => {
    getSubdivisions().then(({ aggregations }) => {
      const options = formatSubDivisionsArray(
        aggregations?.subdivisions?.buckets ?? []
      );
      setOptions(options);
    });
    return (): void => setOptions([]);
  }, []);

  const onBlur = (event: React.ChangeEvent<HTMLElement>): void => {
    const element = event.target as HTMLInputElement;
    const value = element.value;
    if (!value) props.onChange("subdivision", undefined);
  };

  return (
    <Autocomplete
      options={options}
      onChange={(event, value): void =>
        props.onChange("subdivision", value ?? undefined)
      }
      onBlur={onBlur}
      size="small"
      defaultValue={defaultValue}
      renderInput={(params): JSX.Element => (
        <TextField {...params} label={props.label} />
      )}
    />
  );
};
