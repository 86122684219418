export enum StreetType {
  Alley = "alley",
  Arcade = "arcade",
  Avenue = "avenue",
  Beach = "beach",
  Bend = "bend",
  Bluff = "bluff",
  Bluffs = "bluffs",
  Boulevard = "boulevard",
  Branch = "branch",
  Bridge = "bridge",
  Brook = "brook",
  ByPass = "byPass",
  Camp = "camp",
  Canyon = "canyon",
  Cape = "cape",
  Causeway = "causeway",
  Chase = "chase",
  Circle = "circle",
  Cliff = "cliff",
  Cliffs = "cliffs",
  Club = "club",
  Common = "common",
  Corner = "corner",
  Corners = "corners",
  Court = "court",
  Cove = "cove",
  Creek = "creek",
  Crescent = "crescent",
  Crest = "crest",
  Crossing = "crossing",
  Curve = "curve",
  Dale = "dale",
  Dam = "dam",
  Drive = "drive",
  Estate = "estate",
  Estates = "estates",
  Expressway = "expressway",
  Extension = "extension",
  Fall = "fall",
  Falls = "falls",
  Field = "field",
  Fields = "fields",
  Flat = "flat",
  Flats = "flats",
  Ford = "ford",
  Forest = "forest",
  Forge = "forge",
  Fork = "fork",
  Forks = "forks",
  Garden = "garden",
  Gardens = "gardens",
  Glen = "glen",
  Green = "green",
  Grove = "grove",
  Harbor = "harbor",
  Haven = "haven",
  Heights = "heights",
  Highway = "highway",
  Hill = "hill",
  Hills = "hills",
  Hollow = "hollow",
  Island = "island",
  Isle = "isle",
  Junction = "junction",
  Key = "key",
  Knoll = "knoll",
  Knolls = "knolls",
  Lake = "lake",
  Lakes = "lakes",
  Land = "land",
  Landing = "landing",
  Lane = "lane",
  Light = "light",
  Lodge = "lodge",
  Loop = "loop",
  Manor = "manor",
  Meadow = "meadow",
  Meadows = "meadows",
  Mews = "mews",
  Mill = "mill",
  Mills = "mills",
  Mist = "mist",
  Mount = "mount",
  Mountain = "mountain",
  Oak = "oak",
  Orchard = "orchard",
  Oval = "oval",
  Park = "park",
  Parkway = "parkway",
  Pass = "pass",
  Path = "path",
  Peak = "peak",
  Pike = "pike",
  Pine = "pine",
  Place = "place",
  Plain = "plain",
  Plains = "plains",
  Plaza = "plaza",
  Point = "point",
  Port = "port",
  Prairie = "prairie",
  Ranch = "ranch",
  Rapids = "rapids",
  Ridge = "ridge",
  River = "river",
  Road = "road",
  Row = "row",
  Run = "run",
  Shores = "shores",
  Skyway = "skyway",
  Spring = "spring",
  Springs = "springs",
  Spur = "spur",
  Square = "square",
  Station = "station",
  Stream = "stream",
  Street = "street",
  Summit = "summit",
  Terrace = "terrace",
  Trace = "trace",
  Track = "track",
  Trail = "trail",
  Valley = "valley",
  View = "view",
  Village = "village",
  Vista = "vista",
  Walk = "walk",
  Way = "way",
  Wells = "wells",
  Wood = "wood"
}
export const StreetTypeLabel = new Map<StreetType, string>([
  [StreetType.Alley, "Alley"],
  [StreetType.Arcade, "Arcade"],
  [StreetType.Avenue, "Avenue"],
  [StreetType.Beach, "Beach"],
  [StreetType.Bend, "Bend"],
  [StreetType.Bluff, "Bluff"],
  [StreetType.Bluffs, "Bluffs"],
  [StreetType.Boulevard, "Boulevard"],
  [StreetType.Branch, "Branch"],
  [StreetType.Bridge, "Bridge"],
  [StreetType.Brook, "Brook"],
  [StreetType.ByPass, "ByPass"],
  [StreetType.Camp, "Camp"],
  [StreetType.Canyon, "Canyon"],
  [StreetType.Cape, "Cape"],
  [StreetType.Causeway, "Causeway"],
  [StreetType.Chase, "Chase"],
  [StreetType.Circle, "Circle"],
  [StreetType.Cliff, "Cliff"],
  [StreetType.Cliffs, "Cliffs"],
  [StreetType.Club, "Club"],
  [StreetType.Common, "Common"],
  [StreetType.Corner, "Corner"],
  [StreetType.Corners, "Corners"],
  [StreetType.Court, "Court"],
  [StreetType.Cove, "Cove"],
  [StreetType.Creek, "Creek"],
  [StreetType.Crescent, "Crescent"],
  [StreetType.Crest, "Crest"],
  [StreetType.Crossing, "Crossing"],
  [StreetType.Curve, "Curve"],
  [StreetType.Dale, "Dale"],
  [StreetType.Dam, "Dam"],
  [StreetType.Drive, "Drive"],
  [StreetType.Estate, "Estate"],
  [StreetType.Estates, "Estates"],
  [StreetType.Expressway, "Expressway"],
  [StreetType.Extension, "Extension"],
  [StreetType.Fall, "Fall"],
  [StreetType.Falls, "Falls"],
  [StreetType.Field, "Field"],
  [StreetType.Fields, "Fields"],
  [StreetType.Flat, "Flat"],
  [StreetType.Flats, "Flats"],
  [StreetType.Ford, "Ford"],
  [StreetType.Forest, "Forest"],
  [StreetType.Forge, "Forge"],
  [StreetType.Fork, "Fork"],
  [StreetType.Forks, "Forks"],
  [StreetType.Garden, "Garden"],
  [StreetType.Gardens, "Gardens"],
  [StreetType.Glen, "Glen"],
  [StreetType.Green, "Green"],
  [StreetType.Grove, "Grove"],
  [StreetType.Harbor, "Harbor"],
  [StreetType.Haven, "Haven"],
  [StreetType.Heights, "Heights"],
  [StreetType.Highway, "Highway"],
  [StreetType.Hill, "Hill"],
  [StreetType.Hills, "Hills"],
  [StreetType.Hollow, "Hollow"],
  [StreetType.Island, "Island"],
  [StreetType.Isle, "Isle"],
  [StreetType.Junction, "Junction"],
  [StreetType.Key, "Key"],
  [StreetType.Knoll, "Knoll"],
  [StreetType.Knolls, "Knolls"],
  [StreetType.Lake, "Lake"],
  [StreetType.Lakes, "Lakes"],
  [StreetType.Land, "Land"],
  [StreetType.Landing, "Landing"],
  [StreetType.Lane, "Lane"],
  [StreetType.Light, "Light"],
  [StreetType.Lodge, "Lodge"],
  [StreetType.Loop, "Loop"],
  [StreetType.Manor, "Manor"],
  [StreetType.Meadow, "Meadow"],
  [StreetType.Meadows, "Meadows"],
  [StreetType.Mews, "Mews"],
  [StreetType.Mill, "Mill"],
  [StreetType.Mills, "Mills"],
  [StreetType.Mist, "Mist"],
  [StreetType.Mount, "Mount"],
  [StreetType.Mountain, "Mountain"],
  [StreetType.Oak, "Oak"],
  [StreetType.Orchard, "Orchard"],
  [StreetType.Oval, "Oval"],
  [StreetType.Park, "Park"],
  [StreetType.Parkway, "Parkway"],
  [StreetType.Pass, "Pass"],
  [StreetType.Path, "Path"],
  [StreetType.Peak, "Peak"],
  [StreetType.Pike, "Pike"],
  [StreetType.Pine, "Pine"],
  [StreetType.Place, "Place"],
  [StreetType.Plain, "Plain"],
  [StreetType.Plains, "Plains"],
  [StreetType.Plaza, "Plaza"],
  [StreetType.Point, "Point"],
  [StreetType.Port, "Port"],
  [StreetType.Prairie, "Prairie"],
  [StreetType.Ranch, "Ranch"],
  [StreetType.Rapids, "Rapids"],
  [StreetType.Ridge, "Ridge"],
  [StreetType.River, "River"],
  [StreetType.Road, "Road"],
  [StreetType.Row, "Row"],
  [StreetType.Run, "Run"],
  [StreetType.Shores, "Shores"],
  [StreetType.Skyway, "Skyway"],
  [StreetType.Spring, "Spring"],
  [StreetType.Springs, "Springs"],
  [StreetType.Spur, "Spur"],
  [StreetType.Square, "Square"],
  [StreetType.Station, "Station"],
  [StreetType.Stream, "Stream"],
  [StreetType.Street, "Street"],
  [StreetType.Summit, "Summit"],
  [StreetType.Terrace, "Terrace"],
  [StreetType.Trace, "Trace"],
  [StreetType.Track, "Track"],
  [StreetType.Trail, "Trail"],
  [StreetType.Valley, "Valley"],
  [StreetType.View, "View"],
  [StreetType.Village, "Village"],
  [StreetType.Vista, "Vista"],
  [StreetType.Walk, "Walk"],
  [StreetType.Way, "Way"],
  [StreetType.Wells, "Wells"],
  [StreetType.Wood, "Wood"]
]);
