import {
  Company,
  getInitialPhotoInformation,
  PhotoRequest
} from "@homesusa/photo-request";
import { useUserServices } from "@homesusa/auth";

interface OfficePhones {
  backupPhone?: string;
  officePhone?: string;
}

export const usePhotoInformation = (): ((
  company: Company,
  officePhones?: OfficePhones
) => Promise<Partial<PhotoRequest> | null>) => {
  const { getUserDetails } = useUserServices();

  const getPhotoInformation = async (
    company: Company,
    officePhones?: OfficePhones
  ): Promise<Partial<PhotoRequest> | null> => {
    if (!company) {
      return null;
    }
    const user = await getUserDetails();
    return getInitialPhotoInformation({
      user,
      company,
      officePhones
    });
  };

  return getPhotoInformation;
};
