import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./indicator.style";
import { ActionCard } from "../action-card/action-card.component";

export const IndicatorComponent = ({
  title,
  value,
  onClick
}: {
  title: string;
  value: number;
  onClick?: () => void;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <ActionCard elevation={3} onClick={onClick}>
      <Box sx={classes.root}>
        <Typography textAlign="center" sx={classes.value}>
          {value}
        </Typography>
        <Typography textAlign="center" sx={classes.title} color="GrayText">
          {title}
        </Typography>
      </Box>{" "}
    </ActionCard>
  );
};
