export enum PrimaryBathroom {
  CorianCounters = "corianCounters",
  DoubleVanity = "doubleVanity",
  FullBath = "fullBath",
  GardenTub = "gardenTub",
  GraniteCounters = "graniteCounters",
  JettedTub = "jettedTub",
  LowFlowPlumbingFixtures = "lowFlowPlumbingFixtures",
  QuartzCounters = "quartzCounters",
  SeparateShower = "separateShower",
  StoneCounters = "stoneCounters",
  TileCounters = "tileCounters",
  TrayCeilings = "trayCeilings",
  VaultedCeilings = "vaultedCeilings"
}

export const PrimaryBathroomLabel = new Map<PrimaryBathroom, string>([
  [PrimaryBathroom.CorianCounters, "Corian Counters"],
  [PrimaryBathroom.DoubleVanity, "Double Vanity"],
  [PrimaryBathroom.FullBath, "Full Bath"],
  [PrimaryBathroom.GardenTub, "Garden Tub/Roman Tub"],
  [PrimaryBathroom.GraniteCounters, "Granite Counters"],
  [PrimaryBathroom.JettedTub, "Jetted Tub"],
  [PrimaryBathroom.LowFlowPlumbingFixtures, "Low Flow Plumbing Fixtures"],
  [PrimaryBathroom.QuartzCounters, "Quartz Counters"],
  [PrimaryBathroom.SeparateShower, "Separate Shower"],
  [PrimaryBathroom.StoneCounters, "Stone Counters"],
  [PrimaryBathroom.TileCounters, "Tile Counters"],
  [PrimaryBathroom.TrayCeilings, "Tray Ceiling(s)"],
  [PrimaryBathroom.VaultedCeilings, "Vaulted Ceiling(s)"]
]);
