export enum Floors {
  Brick = "brick",
  Carpeting = "carpeting",
  CeramicTile = "ceramicTile",
  Laminate = "laminate",
  Other = "other",
  SaltilloTile = "saltilloTile",
  Stone = "stone",
  Vinyl = "vinyl",
  Wood = "wood"
}

export const FloorsLabel = new Map<Floors, string>([
  [Floors.Brick, "Brick"],
  [Floors.Carpeting, "Carpeting"],
  [Floors.CeramicTile, "Ceramic Tile"],
  [Floors.Laminate, "Laminate"],
  [Floors.Other, "Other"],
  [Floors.SaltilloTile, "Saltillo Tile"],
  [Floors.Stone, "Stone"],
  [Floors.Vinyl, "Vinyl"],
  [Floors.Wood, "Wood"]
]);
