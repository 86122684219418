export enum Kitchen {
  BreakfastArea = "breakfastArea",
  BreakfastBar = "breakfastBar",
  ButlerPantry = "butlerPantry",
  CenterIsland = "centerIsland",
  CorianCounters = "corianCounters",
  DiningArea = "diningArea",
  EatInKitchen = "eatInKitchen",
  GourmetKitchen = "gourmetKitchen",
  GraniteCounters = "graniteCounters",
  OpentoFamilyRoom = "opentoFamilyRoom",
  Pantry = "pantry",
  PlumbedforIcemaker = "plumbedforIcemaker",
  QuartzCounters = "quartzCounters",
  RecessedLighting = "recessedLighting",
  StoneCounters = "stoneCounters",
  TileCounters = "tileCounters",
  TrayCeilings = "trayCeilings",
  VaultedCeilings = "vaultedCeilings"
}
export const KitchenLabel = new Map<Kitchen, string>([
  [Kitchen.BreakfastArea, "Breakfast Area"],
  [Kitchen.BreakfastBar, "Breakfast Bar"],
  [Kitchen.ButlerPantry, "Butler Pantry"],
  [Kitchen.CenterIsland, "Center Island"],
  [Kitchen.CorianCounters, "Corian Counters"],
  [Kitchen.DiningArea, "Dining Area"],
  [Kitchen.EatInKitchen, "Eat In Kitchen"],
  [Kitchen.GourmetKitchen, "Gourmet Kitchen"],
  [Kitchen.GraniteCounters, "Granite Counters"],
  [Kitchen.OpentoFamilyRoom, "Open to Family Room"],
  [Kitchen.Pantry, "Pantry"],
  [Kitchen.PlumbedforIcemaker, "Plumbed for Icemaker"],
  [Kitchen.QuartzCounters, "Quartz Counters"],
  [Kitchen.RecessedLighting, "Recessed Lighting"],
  [Kitchen.StoneCounters, "Stone Counters"],
  [Kitchen.TileCounters, "Tile Counters"],
  [Kitchen.TrayCeilings, "Tray Ceiling(s)"],
  [Kitchen.VaultedCeilings, "Vaulted Ceiling(s)"]
]);
