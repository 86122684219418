import React from "react";
import { getMediaEndpoints, MediaEndpoints } from "@homesusa/media";
import { AuthCompanyContext } from "@homesusa/auth";
import { getGatewayMarket } from "core/utils";

export const useMediaEndpoints = (
  entityType: string,
  entityId: string
): MediaEndpoints => {
  const gateway = process.env.REACT_APP_API_GATEWAY;
  const { currentMarket } = React.useContext(AuthCompanyContext);

  const mediaEndpoints = React.useMemo(() => {
    const gatewayMarket = getGatewayMarket(currentMarket);
    const apiUrl = `${gateway}/api/${gatewayMarket}/${entityType}/${entityId}`;
    const mediaUrl = `${gateway}/api/${gatewayMarket}/${entityType}/${entityId}/media`;

    const endpoints = getMediaEndpoints(apiUrl);
    endpoints.file.upload = mediaUrl;
    endpoints.file.replace = mediaUrl;
    return endpoints;
  }, [entityId, currentMarket, entityType]);

  return mediaEndpoints;
};
