import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  ShowingInstructions,
  ShowingInstructionsLabel
} from "markets/ctx/enums";

export const openHousesRules: FormRules = {
  enableOpenHouses: {
    label: "Enable Open House Feature"
  },
  showOpenHousesPending: {
    label: "Show Open House if this listing is PENDING?"
  }
};

export const showingRules: FormRules = {
  showingPhone: { label: "Showing Phone" },
  secondShowingPhone: { label: "Showing Phone #2" },
  showing: {
    label: "Showing Instructions",
    requiredToSubmit: true,
    rows: 2,
    lastOptions: [
      ShowingInstructions.None,
      ShowingInstructions.OtherSeeRemarks
    ],
    options: getOptionsFromEnumAndMap(
      Object.values(ShowingInstructions),
      ShowingInstructionsLabel
    ),
    noneOption: [ShowingInstructions.None]
  },
  directions: {
    label: "Directions",
    requiredToSubmit: true,
    maxLength: 400,
    rows: 3
  }
};
