import React from "react";
import { isValid } from "date-fns";

import { AuthCompanyContext } from "@homesusa/auth";
import { useMounted } from "@homesusa/core";
import { AlertType } from "../enums";
import {
  ChartStateActionType,
  ChartStateActions
} from "../context/chart-state.actions";
import { ChartFilters } from "../interfaces";
import { getStatusListings } from "../services/temp.service";
import {
  urgentAlertActions,
  remindersAlerts,
  formatChartPoints,
  initializeGaugeTotal,
  detailedAlertActions
} from "../utils";
import { ChartContext } from "./chart-dashboard.context";
import { chartStateReducer, initialState } from "./chart-state.reducer";
import { DashboardContext } from "./dashboard";
import { useGetXmlAlertsTotal } from "../hooks";
import { getDiscrepancyAnalysis } from "core/services/discrepancy.service";

export const ChartDashboardProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [chartState, chartDispatch] = React.useReducer(
    chartStateReducer,
    initialState
  );
  const { currentCompany, currentMarket } =
    React.useContext(AuthCompanyContext);
  const {
    alertServices: { getAlertsCount }
  } = React.useContext(DashboardContext);
  const getXmlAlertsTotal = useGetXmlAlertsTotal();

  const mounted = useMounted();
  const dispatchChartState = (value: ChartStateActions): void => {
    if (mounted.current) {
      chartDispatch(value);
    }
  };

  const verifyFilters = (filters: ChartFilters): ChartFilters => {
    return {
      ...filters,
      from: isValid(filters.from) ? filters.from : undefined,
      to: isValid(filters.to) ? filters.to : undefined
    };
  };
  const fetchChartPoints = (filters: ChartFilters): void => {
    const companyCode = currentCompany?.name;
    const verifiedFilters = verifyFilters(filters);
    getStatusListings({ ...verifiedFilters, companyCode }).then(
      ({ aggregations }): void => {
        if (aggregations) {
          const { buckets } = aggregations.listings_over_time;
          dispatchChartState({
            type: ChartStateActionType.SetListingStatus,
            payload: formatChartPoints(buckets)
          });
        }
      }
    );
  };

  const fetchData = (): void => {
    getAlertsCount({ alerts: detailedAlertActions }).then((value): void => {
      const { total } = initializeGaugeTotal(value, "detaileds");
      dispatchChartState({
        type: ChartStateActionType.SetTotalDetailed,
        payload: { value, total }
      });
    });
    getXmlAlertsTotal().then((total): void => {
      dispatchChartState({
        type: ChartStateActionType.SetTotalXml,
        payload: total
      });
    });
    getAlertsCount({ alerts: urgentAlertActions }).then((value): void => {
      const { total } = initializeGaugeTotal(value, "urgent");
      dispatchChartState({
        type: ChartStateActionType.SetTotalUrgents,
        payload: { value, total }
      });
    });
    getAlertsCount({ alerts: remindersAlerts }).then((value): void => {
      const { total } = initializeGaugeTotal(value, "reminders");
      dispatchChartState({
        type: ChartStateActionType.SetTotalReminders,
        payload: { value, total }
      });
    });
    getAlertsCount({
      alerts: [AlertType.AgentBonusExpirationDate]
    }).then((data): void =>
      dispatchChartState({
        type: ChartStateActionType.SetTotalAgentBonus,
        payload: data
      })
    );
    getAlertsCount({
      alerts: [AlertType.ActiveAndPendingListing]
    }).then((data): void =>
      dispatchChartState({
        type: ChartStateActionType.SetTotalActiveAndPending,
        payload: data
      })
    );
    getAlertsCount({
      alerts: [AlertType.LockedListings]
    }).then((data): void =>
      dispatchChartState({
        type: ChartStateActionType.SetTotalLockedListings,
        payload: data
      })
    );
    getAlertsCount({ alerts: [AlertType.NotListedInMls] }).then((data): void =>
      dispatchChartState({
        type: ChartStateActionType.SetTotalUnlisted,
        payload: data
      })
    );

    getDiscrepancyAnalysis(currentMarket).then((data): void =>
      dispatchChartState({
        type: ChartStateActionType.SetDiscrepancyData,
        payload: data
      })
    );
    fetchChartPoints({});
  };

  React.useEffect(fetchData, []);

  return (
    <ChartContext.Provider
      value={{ chartState, chartDispatch, fetchChartPoints }}
    >
      {children}
    </ChartContext.Provider>
  );
};
