export enum Exemptions {
  Agricultural = "agricultural",
  Disability = "disability",
  Historical = "historical",
  Homestead = "homestead",
  None = "none",
  Over65 = "over65",
  Wildlife = "wildlife"
}
export const ExemptionsLabel = new Map<Exemptions, string>([
  [Exemptions.Agricultural, "Agricultural"],
  [Exemptions.Disability, "Disability"],
  [Exemptions.Historical, "Historical"],
  [Exemptions.Homestead, "Homestead"],
  [Exemptions.None, "None"],
  [Exemptions.Over65, "Over 65"],
  [Exemptions.Wildlife, "Wildlife"]
]);
