export enum County {
  Angelina = "angelina",
  Aransas = "aransas",
  Armstrong = "armstrong",
  Bailey = "bailey",
  Bandera = "bandera",
  Bastrop = "bastrop",
  Beaver = "beaver",
  Bell = "bell",
  Bexar = "bexar",
  Blanco = "blanco",
  Bosque = "bosque",
  Bowie = "bowie",
  Brazoria = "brazoria",
  Brazos = "brazos",
  Briscoe = "briscoe",
  Brown = "brown",
  Burleson = "burleson",
  Burnet = "burnet",
  Carson = "carson",
  Castro = "castro",
  Childress = "childress",
  ColfaxNM = "colfaxNM",
  Collin = "collin",
  Collingsworth = "collingsworth",
  Comal = "comal",
  Coryell = "coryell",
  Cottle = "cottle",
  Crosby = "crosby",
  CurryNM = "curryNM",
  Dallam = "dallam",
  Dallas = "dallas",
  Dawson = "dawson",
  DeBaca = "deBaca",
  DeafSmith = "deafSmith",
  Denton = "denton",
  Donley = "donley",
  Ector = "ector",
  ElPaso = "elPaso",
  Ellis = "ellis",
  Erath = "erath",
  Falls = "falls",
  Fannin = "fannin",
  Floyd = "floyd",
  Foard = "foard",
  FortBend = "fortBend",
  Gaines = "gaines",
  Galveston = "galveston",
  Garza = "garza",
  Gray = "gray",
  Grayson = "grayson",
  Grimes = "grimes",
  Guadalupe = "guadalupe",
  Hail = "hail",
  Hale = "hale",
  Hall = "hall",
  Hansford = "hansford",
  Hardeman = "hardeman",
  Hardin = "hardin",
  Harris = "harris",
  Hartley = "hartley",
  Hays = "hays",
  Hemphill = "hemphill",
  Henderson = "henderson",
  Hidalgo = "hidalgo",
  Hockley = "hockley",
  Hopkins = "hopkins",
  Hunt = "hunt",
  Hutchinson = "hutchinson",
  Jefferson = "jefferson",
  JimWells = "jimWells",
  Kaufman = "kaufman",
  Kerr = "kerr",
  King = "king",
  Lamb = "lamb",
  Lampasas = "lampasas",
  Liberty = "liberty",
  Lincoln = "lincoln",
  Lipscomb = "lipscomb",
  Llano = "llano",
  Lubbock = "lubbock",
  Marion = "marion",
  Matagorda = "matagorda",
  McLennan = "mcLennan",
  Medina = "medina",
  Midland = "midland",
  Milam = "milam",
  Montague = "montague",
  Montgomery = "montgomery",
  Moore = "moore",
  Mora = "mora",
  Motley = "motley",
  Nueces = "nueces",
  Ochlitree = "ochlitree",
  Oldham = "oldham",
  Orange = "orange",
  Otero = "otero",
  Other = "other",
  Parker = "parker",
  Parmer = "parmer",
  Polk = "polk",
  Potter = "potter",
  QuayNM = "quayNM",
  Randall = "randall",
  Roberts = "roberts",
  RooseveltNM = "rooseveltNM",
  SanJacinto = "sanJacinto",
  SanPatricio = "sanPatricio",
  Sherman = "sherman",
  Smith = "smith",
  Stonewall = "stonewall",
  Swisher = "swisher",
  Tarrant = "tarrant",
  Terry = "terry",
  Texas = "texas",
  TomGreen = "tomGreen",
  Travis = "travis",
  Trinity = "trinity",
  Union = "union",
  VanZandt = "vanZandt",
  Victoria = "victoria",
  Walker = "walker",
  Wharton = "wharton",
  Wheeler = "wheeler",
  Wichita = "wichita",
  Wilbarger = "wilbarger",
  Williamson = "williamson",
  Wilson = "wilson",
  Wise = "wise",
  Wood = "wood"
}

export const CountyLabel: Record<County, string> = {
  [County.Angelina]: "Angelina",
  [County.Aransas]: "Aransas",
  [County.Armstrong]: "Armstrong",
  [County.Bailey]: "Bailey",
  [County.Bandera]: "Bandera",
  [County.Bastrop]: "Bastrop",
  [County.Beaver]: "Beaver",
  [County.Bell]: "Bell",
  [County.Bexar]: "Bexar",
  [County.Blanco]: "Blanco",
  [County.Bosque]: "Bosque",
  [County.Bowie]: "Bowie",
  [County.Brazoria]: "Brazoria",
  [County.Brazos]: "Brazos",
  [County.Briscoe]: "Briscoe",
  [County.Brown]: "Brown",
  [County.Burleson]: "Burleson",
  [County.Burnet]: "Burnet",
  [County.Carson]: "Carson",
  [County.Castro]: "Castro",
  [County.Childress]: "Childress",
  [County.ColfaxNM]: "Colfax - NM",
  [County.Collin]: "Collin",
  [County.Collingsworth]: "Collingsworth",
  [County.Comal]: "Comal",
  [County.Coryell]: "Coryell",
  [County.Cottle]: "Cottle",
  [County.Crosby]: "Crosby",
  [County.CurryNM]: "Curry - NM",
  [County.Dallam]: "Dallam",
  [County.Dallas]: "Dallas",
  [County.Dawson]: "Dawson",
  [County.DeBaca]: "De Baca",
  [County.DeafSmith]: "Deaf Smith",
  [County.Denton]: "Denton",
  [County.Donley]: "Donley",
  [County.Ector]: "Ector",
  [County.ElPaso]: "El Paso",
  [County.Ellis]: "Ellis",
  [County.Erath]: "Erath",
  [County.Falls]: "Falls",
  [County.Fannin]: "Fannin",
  [County.Floyd]: "Floyd",
  [County.Foard]: "Foard",
  [County.FortBend]: "Fort Bend",
  [County.Gaines]: "Gaines",
  [County.Galveston]: "Galveston",
  [County.Garza]: "Garza",
  [County.Gray]: "Gray",
  [County.Grayson]: "Grayson",
  [County.Grimes]: "Grimes",
  [County.Guadalupe]: "Guadalupe",
  [County.Hail]: "Hail",
  [County.Hale]: "Hale",
  [County.Hall]: "Hall",
  [County.Hansford]: "Hansford",
  [County.Hardeman]: "Hardeman",
  [County.Hardin]: "Hardin",
  [County.Harris]: "Harris",
  [County.Hartley]: "Hartley",
  [County.Hays]: "Hays",
  [County.Hemphill]: "Hemphill",
  [County.Henderson]: "Henderson",
  [County.Hidalgo]: "Hidalgo",
  [County.Hockley]: "Hockley",
  [County.Hopkins]: "Hopkins",
  [County.Hunt]: "Hunt",
  [County.Hutchinson]: "Hutchinson",
  [County.Jefferson]: "Jefferson",
  [County.JimWells]: "Jim Wells",
  [County.Kaufman]: "Kaufman",
  [County.Kerr]: "Kerr",
  [County.King]: "King",
  [County.Lamb]: "Lamb",
  [County.Lampasas]: "Lampasas",
  [County.Liberty]: "Liberty",
  [County.Lincoln]: "Lincoln",
  [County.Lipscomb]: "Lipscomb",
  [County.Llano]: "Llano",
  [County.Lubbock]: "Lubbock",
  [County.Marion]: "Marion",
  [County.Matagorda]: "Matagorda",
  [County.McLennan]: "McLennan",
  [County.Medina]: "Medina",
  [County.Midland]: "Midland",
  [County.Milam]: "Milam",
  [County.Montague]: "Montague",
  [County.Montgomery]: "Montgomery",
  [County.Moore]: "Moore",
  [County.Mora]: "Mora",
  [County.Motley]: "Motley",
  [County.Nueces]: "Nueces",
  [County.Ochlitree]: "Ochlitree",
  [County.Oldham]: "Oldham",
  [County.Orange]: "Orange",
  [County.Otero]: "Otero",
  [County.Other]: "Other",
  [County.Parker]: "Parker",
  [County.Parmer]: "Parmer",
  [County.Polk]: "Polk",
  [County.Potter]: "Potter",
  [County.QuayNM]: "Quay - NM",
  [County.Randall]: "Randall",
  [County.Roberts]: "Roberts",
  [County.RooseveltNM]: "Roosevelt - NM",
  [County.SanJacinto]: "San Jacinto",
  [County.SanPatricio]: "San Patricio",
  [County.Sherman]: "Sherman",
  [County.Smith]: "Smith",
  [County.Stonewall]: "Stonewall",
  [County.Swisher]: "Swisher",
  [County.Tarrant]: "Tarrant",
  [County.Terry]: "Terry",
  [County.Texas]: "Texas",
  [County.TomGreen]: "Tom Green",
  [County.Travis]: "Travis",
  [County.Trinity]: "Trinity",
  [County.Union]: "Union",
  [County.VanZandt]: "Van Zandt",
  [County.Victoria]: "Victoria",
  [County.Walker]: "Walker",
  [County.Wharton]: "Wharton",
  [County.Wheeler]: "Wheeler",
  [County.Wichita]: "Wichita",
  [County.Wilbarger]: "Wilbarger",
  [County.Williamson]: "Williamson",
  [County.Wilson]: "Wilson",
  [County.Wise]: "Wise",
  [County.Wood]: "Wood"
};
