export enum AccessInstruction {
  MannedGate = "mannedGate",
  DrivewayGate = "drivewayGate",
  AutomaticGate = "automaticGate",
  Intercom = "intercom"
}
export const AccessInstructionLabel = new Map<AccessInstruction, string>([
  [AccessInstruction.MannedGate, "Manned Gate"],
  [AccessInstruction.DrivewayGate, "Driveway Gate"],
  [AccessInstruction.AutomaticGate, "Automatic Gate"],
  [AccessInstruction.Intercom, "Intercom"]
]);
