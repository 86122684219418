export enum Disclosure {
  FixtureLeases = "fixtureLeases",
  Exclusions = "exclusions",
  OtherDisclosures = "otherDisclosures",
  NoDisclosures = "noDisclosures",
  SellersDisclosure = "sellersDisclosure",
  HomeProtectionPlan = "homeProtectionPlan",
  Mud = "mud",
  MiLendersApproval = "miLendersApproval",
  LeveeDistrict = "leveeDistrict"
}
export const DisclosureTypeLabel = new Map<Disclosure, string>([
  [Disclosure.FixtureLeases, "Fixture Leases"],
  [Disclosure.Exclusions, "Exclusions"],
  [Disclosure.OtherDisclosures, "Other Disclosures"],
  [Disclosure.NoDisclosures, "No Disclosures"],
  [Disclosure.SellersDisclosure, "Sellers Disclosure"],
  [Disclosure.HomeProtectionPlan, "Home Protection Plan"],
  [Disclosure.Mud, "Mud"],
  [Disclosure.MiLendersApproval, "Mi/Lenders Approval"],
  [Disclosure.LeveeDistrict, "Levee District"]
]);
