export enum ContingencyInfo {
  Inspection = "inspection",
  Title = "title",
  Financing = "financing",
  SeeRemarks = "seeRemarks",
  Appraisal = "appraisal"
}
export const ContingencyInfoLabel = new Map<ContingencyInfo, string>([
  [ContingencyInfo.Inspection, "Inspection"],
  [ContingencyInfo.Title, "Title"],
  [ContingencyInfo.Financing, "Financing"],
  [ContingencyInfo.Appraisal, "Appraisal"],
  [ContingencyInfo.SeeRemarks, "See Remarks"]
]);
