export enum WaterSewer {
  NoneWater = "noneWater",
  WaterSystem = "waterSystem",
  SewerSystem = "sewerSystem",
  Septic = "septic",
  City = "city",
  PrivateWell = "privateWell",
  Other = "other",
  NoneSewer = "noneSewer",
  WaterStorage = "waterStorage",
  CoOpWater = "coOpWater",
  AerobicSeptic = "aerobicSeptic"
}
export const WaterSewerLabel = new Map<WaterSewer, string>([
  [WaterSewer.NoneWater, "None - Water"],
  [WaterSewer.WaterSystem, "Water System"],
  [WaterSewer.SewerSystem, "Sewer System"],
  [WaterSewer.Septic, "Septic"],
  [WaterSewer.City, "City"],
  [WaterSewer.PrivateWell, "Private Well"],
  [WaterSewer.Other, "Other"],
  [WaterSewer.NoneSewer, "None - Sewer"],
  [WaterSewer.WaterStorage, "Water Storage"],
  [WaterSewer.CoOpWater, "Co-op Water"],
  [WaterSewer.AerobicSeptic, "Aerobic Septic"]
]);
