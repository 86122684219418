import React, { Context } from "react";

import { EntityContext } from "core/interfaces";

export const useRefreshEntity = <
  TEntity extends { id: string },
  TEntityContext extends EntityContext<TEntity>
>(
  entityContext: Context<TEntityContext>
): (() => Promise<void>) => {
  const { getEntity } = React.useContext(entityContext);

  const refresh = async (): Promise<void> => {
    await getEntity(true);
  };

  return refresh;
};
