export enum HomeFaces {
  East = "east",
  North = "north",
  NorthEast = "northEast",
  NorthWest = "northWest",
  South = "south",
  SouthEast = "southEast",
  SouthWest = "southWest",
  West = "west"
}
export const HomeFacesLabel = new Map<HomeFaces, string>([
  [HomeFaces.East, "East"],
  [HomeFaces.North, "North"],
  [HomeFaces.NorthEast, "North-East"],
  [HomeFaces.NorthWest, "North-West"],
  [HomeFaces.South, "South"],
  [HomeFaces.SouthEast, "South-East"],
  [HomeFaces.SouthWest, "South-West"],
  [HomeFaces.West, "West"]
]);
