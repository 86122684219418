export enum ConstructionType {
  BrickVeneer = "brickVeneer",
  FrameSteel = "frameSteel",
  FrameWood = "frameWood",
  LogHome = "logHome",
  MetalFab = "metalFab",
  Modular = "modular",
  Stone = "stone",
  StructuralInsulPnl = "structuralInsulPnl",
  Stucco = "stucco",
  OtherConst = "otherConst"
}

export const ConstructionTypeLabel: Record<ConstructionType, string> = {
  [ConstructionType.BrickVeneer]: "Brick Veneer",
  [ConstructionType.FrameSteel]: "Frame/Steel ",
  [ConstructionType.FrameWood]: "Frame/Wood ",
  [ConstructionType.LogHome]: "Log Home ",
  [ConstructionType.MetalFab]: "Metal Fab",
  [ConstructionType.Modular]: "Modular ",
  [ConstructionType.Stone]: "Stone",
  [ConstructionType.StructuralInsulPnl]: "Structural Insul Pnl ",
  [ConstructionType.Stucco]: "Stucco",
  [ConstructionType.OtherConst]: "Other Const"
};
