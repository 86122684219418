export enum ExteriorFeatures {
  CoveredPatio = "coveredPatio",
  DoublePaneWindows = "doublePaneWindows",
  DeckBalcony = "deckBalcony",
  HasGutters = "hasGutters",
  OutdoorKitchen = "outdoorKitchen",
  PartialSprinklerSystem = "partialSprinklerSystem",
  PatioSlab = "patioSlab",
  PrivacyFence = "privacyFence",
  SprinklerSystem = "sprinklerSystem",
  StoneMasonryFence = "stoneMasonryFence",
  StormWindows = "stormWindows",
  MatureTrees = "matureTrees",
  WroughtIronFence = "wroughtIronFence"
}

export const ExteriorFeaturesLabel = new Map<ExteriorFeatures, string>([
  [ExteriorFeatures.CoveredPatio, "Covered Patio"],
  [ExteriorFeatures.DoublePaneWindows, "Double Pane Windows"],
  [ExteriorFeatures.DeckBalcony, "Deck/Balcony"],
  [ExteriorFeatures.HasGutters, "Has Gutters"],
  [ExteriorFeatures.OutdoorKitchen, "Outdoor Kitchen"],
  [ExteriorFeatures.PartialSprinklerSystem, "Partial Sprinkler System"],
  [ExteriorFeatures.PatioSlab, "Patio Slab"],
  [ExteriorFeatures.PrivacyFence, "Privacy Fence"],
  [ExteriorFeatures.SprinklerSystem, "Sprinkler System"],
  [ExteriorFeatures.StoneMasonryFence, "Stone/Masonry Fence"],
  [ExteriorFeatures.StormWindows, "Storm Windows"],
  [ExteriorFeatures.MatureTrees, "Mature Trees"],
  [ExteriorFeatures.WroughtIronFence, "Wrought Iron Fence"]
]);
