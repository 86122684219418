export enum BodyOfWater {
  BeltonLake = "beltonLake",
  BlancoRiver = "blancoRiver",
  BrazosRiver = "brazosRiver",
  BuchananLake = "buchananLake",
  CanyonLake = "canyonLake",
  ColoradoRiver = "coloradoRiver",
  ComalRiver = "comalRiver",
  GuadalupeRiver = "guadalupeRiver",
  InksLake = "inksLake",
  LadyBirdLake = "ladyBirdLake",
  LakeAustin = "lakeAustin",
  LakeBastrop = "lakeBastrop",
  LakeGeorgetown = "lakeGeorgetown",
  LakeLBJ = "lakeLBJ",
  LakeMarbleFalls = "lakeMarbleFalls",
  LakeTravis = "lakeTravis",
  LlanoRiver = "llanoRiver",
  PedernalesRiver = "pedernalesRiver",
  SanGabrielRiver = "sanGabrielRiver",
  SanMarcosRiver = "sanMarcosRiver",
  SanSabaRiver = "sanSabaRiver"
}
export const BodyOfWaterLabel = new Map<BodyOfWater, string>([
  [BodyOfWater.BeltonLake, "Belton Lake"],
  [BodyOfWater.BlancoRiver, "Blanco River"],
  [BodyOfWater.BrazosRiver, "Brazos River"],
  [BodyOfWater.BuchananLake, "Buchanan Lake"],
  [BodyOfWater.CanyonLake, "Canyon Lake"],
  [BodyOfWater.ColoradoRiver, "Colorado River"],
  [BodyOfWater.ComalRiver, "Comal River"],
  [BodyOfWater.GuadalupeRiver, "Guadalupe River"],
  [BodyOfWater.InksLake, "Inks Lake"],
  [BodyOfWater.LadyBirdLake, "Lady Bird Lake"],
  [BodyOfWater.LakeAustin, "Lake Austin"],
  [BodyOfWater.LakeBastrop, "Lake Bastrop"],
  [BodyOfWater.LakeGeorgetown, "Lake Georgetown"],
  [BodyOfWater.LakeLBJ, "Lake LBJ"],
  [BodyOfWater.LakeMarbleFalls, "Lake Marble Falls"],
  [BodyOfWater.LakeTravis, "Lake Travis"],
  [BodyOfWater.LlanoRiver, "Llano River"],
  [BodyOfWater.PedernalesRiver, "Pedernales River"],
  [BodyOfWater.SanGabrielRiver, "San Gabriel River"],
  [BodyOfWater.SanMarcosRiver, "San Marcos River"],
  [BodyOfWater.SanSabaRiver, "San Saba River"]
]);
