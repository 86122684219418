import axios from "axios";

import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";
import { ImportSource } from "core/enums";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export const approveImportedCommunity =
  (market: MarketCode) =>
  async (importSource: ImportSource, id: string): Promise<void> => {
    const gatewayMarket = getGatewayMarket(market);
    const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/sale-communities`;
    return axios.patch(`${apiUrl}/${id}/approve`, null, {
      params: { importSource }
    });
  };

export const approveImportedPlan =
  (market: MarketCode) =>
  async (importSource: ImportSource, id: string): Promise<void> => {
    const gatewayMarket = getGatewayMarket(market);
    const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/plans`;
    return axios.patch(`${apiUrl}/${id}/approve`, null, {
      params: { importSource }
    });
  };

export const rejectImportedPlan =
  (market: MarketCode) =>
  async (importSource: ImportSource, id: string): Promise<void> => {
    const gatewayMarket = getGatewayMarket(market);
    const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/plans`;
    return axios.patch(`${apiUrl}/${id}/reject`, null, {
      params: { importSource }
    });
  };
