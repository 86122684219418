import React from "react";

import { Format } from "@homesusa/core";
import { Box, Typography } from "@mui/material";

export function ReportDates({
  data
}: {
  data: {
    agentLastUpdate?: Date;
    officeLastUpdate?: Date;
    listingLastUpdate?: Date;
  };
}): JSX.Element {
  return (
    <Box mt={2}>
      {data.agentLastUpdate ? (
        <Typography variant="h5">
          <b>Agent data last updated on:</b>
          {Format.DateTimeCustomFormat(data.agentLastUpdate)}
        </Typography>
      ) : null}
      {data.officeLastUpdate ? (
        <Typography variant="h5">
          <b>Office data last updated on:</b>
          {Format.DateTimeCustomFormat(data.officeLastUpdate)}
        </Typography>
      ) : null}
      {data.listingLastUpdate ? (
        <Typography variant="h5">
          <b>Listing data last updated on:</b>
          {Format.DateTimeCustomFormat(data.listingLastUpdate)}
        </Typography>
      ) : null}
    </Box>
  );
}
