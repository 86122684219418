export enum MiddleSchool {
  AJBriesemeisterMiddleSchool = "ajBriesemeisterMiddleSchool",
  AcademyIntermediateSchool = "academyIntermediateSchool",
  AcademyMiddleSchool = "academyMiddleSchool",
  AnforthJuniorHighScho = "anforthJuniorHighScho",
  AnnRichardsSchoolforYoungWomenLeaders = "annRichardsSchoolforYoungWomenLeaders",
  AudieMurphyMiddleSchool = "audieMurphyMiddleSchool",
  BaileyMiddleSchool = "baileyMiddleSchool",
  BarbaraCJordanIntermediateSchool = "barbaraCJordanIntermediateSchool",
  BartlettMiddleSchool = "bartlettMiddleSchool",
  BartonMiddleSchool = "bartonMiddleSchool",
  BastropMiddleSchool = "bastropMiddleSchool",
  BedichekMiddleSchool = "bedichekMiddleSchool",
  BeeCaveMiddleSchool = "beeCaveMiddleSchool",
  BeltonMiddleSchool = "beltonMiddleSchool",
  BenoldMiddleSchool = "benoldMiddleSchool",
  BernalMiddleSchool = "bernalMiddleSchool",
  BonhamMiddleSchool = "bonhamMiddleSchool",
  BrenhamMiddleSchool = "brenhamMiddleSchool",
  BriscoeMiddleSchool = "briscoeMiddleSchool",
  BrucevilleEddyJuniorHigh = "brucevilleEddyJuniorHigh",
  BurnetMiddleSchool = "burnetMiddleSchool",
  CDFulkesMiddleSchool = "cDFulkesMiddleSchool",
  CameronJuniorHighSchool = "cameronJuniorHighSchool",
  CanyonMiddleSchool = "canyonMiddleSchool",
  CanyonRidgeMiddleSchool = "canyonRidgeMiddleSchool",
  CanyonVistaMiddleSchool = "canyonVistaMiddleSchool",
  CedarCreekIntermediate = "cedarCreekIntermediate",
  CedarCreekMiddl = "cedarCreekMiddl",
  CedarParkMiddleMiddleSchool = "cedarParkMiddleMiddleSchool",
  CedarValleyMiddleSchool = "cedarValleyMiddleSchool",
  CeleMiddleSchool = "celeMiddleSchool",
  CesarChavezMiddle = "cesarChavezMiddle",
  ChapaMiddleSchool = "chapaMiddleSchool",
  ChiltonSchool = "chiltonSchool",
  ChinaSpringMiddleSchool = "chinaSpringMiddleSchool",
  ChisholmTrailMiddleSchool = "chisholmTrailMiddleSchool",
  ChurchHillMiddleSchool = "churchHillMiddleSchool",
  ConnallyJuniorHighSchool = "connallyJuniorHighSchool",
  ConnallyMiddleSchool = "connallyMiddleSchool",
  CopperasCoveJuniorHigh = "copperasCoveJuniorHigh",
  CouplandMiddleSchool = "couplandMiddleSchool",
  CovingtonMiddleSchool = "covingtonMiddleSchool",
  DahlstromMiddleSchool = "dahlstromMiddleSchool",
  DaileyMiddleSchool = "daileyMiddleSchool",
  DanielsonMiddleSchool = "danielsonMiddleSchool",
  DanvilleMiddleSchool = "danvilleMiddleSchool",
  DeckerMiddleSchool = "deckerMiddleSchool",
  DeerparkMiddleSchool = "deerparkMiddleSchool",
  DelValleMiddleSchool = "delValleMiddleSchool",
  DellCitySchool = "dellCitySchool",
  DessauMiddleSchool = "dessauMiddleSchool",
  DobieMiddleSchool = "dobieMiddleSchool",
  DorisMillerMiddleSchool = "dorisMillerMiddleSchool",
  DrippingSpringsMiddleSchool = "drippingSpringsMiddleSchool",
  EasternHillsMiddleSchool = "easternHillsMiddleSchool",
  EastlandMiddleSchool = "eastlandMiddleSchool",
  ElaineSSchlatherIntermediateSchool = "elaineSSchlatherIntermediateSchool",
  ElginMiddleSchool = "elginMiddleSchool",
  EvantMiddleSchool = "evantMiddleSchool",
  Fairway = "fairway",
  FarleyMiddleSchool = "farleyMiddleSchool",
  Flatonia = "flatonia",
  FlorenceMiddleSchool = "florenceMiddleSchool",
  FolksMiddleSchool = "folksMiddleSchool",
  ForbesMiddleSchool = "forbesMiddleSchool",
  FourPointsMiddleSchool = "fourPointsMiddleSchool",
  FulmoreMiddleSchool = "fulmoreMiddleSchool",
  GWCarverMiddle = "gWCarverMiddle",
  Garcia = "garcia",
  Gatesville = "gatesville",
  GatewayMiddleSchool = "gatewayMiddleSchool",
  GiddingsIntermediateSchool = "giddingsIntermediateSchool",
  GonzalesJuniorHighSchool = "gonzalesJuniorHighSchool",
  GonzalesNorthAvenueIntermediate = "gonzalesNorthAvenueIntermediate",
  GorzyckiMiddleSchool = "gorzyckiMiddleSchool",
  GrangerMiddleSchool = "grangerMiddleSchool",
  GrishamMiddleSchool = "grishamMiddleSchool",
  GusGarciaYoungMensLeadershipAcademy = "gusGarciaYoungMensLeadershipAcademy",
  HGIsbillJuniorHighSchool = "hGIsbillJuniorHighSchool",
  Haynes = "haynes",
  HectorGarciaMiddleSchool = "hectorGarciaMiddleSchool",
  HenryMetzgerMiddle = "henryMetzgerMiddle",
  HenryMiddleSchool = "henryMiddleSchool",
  HermesIntermediateSchool = "hermesIntermediateSchool",
  HernandezMiddleSchool = "hernandezMiddleSchool",
  HillCountryMiddle = "hillCountryMiddle",
  HobbyMiddleSchool = "hobbyMiddleSchool",
  HollandMiddleSchool = "hollandMiddleSchool",
  HopewellMiddleSchool = "hopewellMiddleSchool",
  HudsonMiddleSchool = "hudsonMiddleSchool",
  HuttoMiddleSchool = "huttoMiddleSchool",
  IndianSpringMiddle = "indianSpringMiddle",
  JFrankDobieJuniorHighSchool = "jFrankDobieJuniorHighSchool",
  JarrellIntermediateSchool = "jarrellIntermediateSchool",
  JarrellMiddleSchool = "jarrellMiddleSchool",
  JeffersonMiddleSchool = "jeffersonMiddleSchool",
  JimBarnesMiddleSchool = "jimBarnesMiddleSchool",
  JohnPOjedaMiddleSchool = "johnPOjedaMiddleSchool",
  JonesMiddleSchool = "jonesMiddleSchool",
  JonesboroMiddle = "jonesboroMiddle",
  JordanMiddleSchool = "jordanMiddleSchool",
  KealingMiddleSchool = "kealingMiddleSchool",
  KellyLaneMiddleSchool = "kellyLaneMiddleSchool",
  KittyHawkMiddleSchool = "kittyHawkMiddleSchool",
  LaGrangeMiddleSchool = "laGrangeMiddleSchool",
  LagoVistaIntermediateSchool = "lagoVistaIntermediateSchool",
  LagoVistaMiddleSchool = "lagoVistaMiddleSchool",
  LakeBeltonMiddleSchool = "lakeBeltonMiddleSchool",
  LakeTravisMiddleSchool = "lakeTravisMiddleSchool",
  LamarMiddleSchool = "lamarMiddleSchool",
  LampasasMiddleSchool = "lampasasMiddleSchool",
  LauraIngallsWilderIntermediateSchool = "lauraIngallsWilderIntermediateSchool",
  LaVegaMiddle = "laVegaMiddle",
  LeakeyMiddleSchool = "leakeyMiddleSchool",
  LeanderMiddleMiddleSchool = "leanderMiddleMiddleSchool",
  LexingtonMiddleSchool = "lexingtonMiddleSchool",
  LibertyHillIntermediateSchool = "libertyHillIntermediateSchool",
  LibertyHillJuniorHighSchool = "libertyHillJuniorHighSchool",
  LibertyHillMiddleSchool = "libertyHillMiddleSchool",
  LiveOakRidgeMiddleSchool = "liveOakRidgeMiddleSchool",
  LlanoJrHighSchool = "llanoJrHighSchool",
  LockhartJuniorHighSchool = "lockhartJuniorHighSchool",
  LometaMiddle = "lometaMiddle",
  LoneOakMiddleSchool = "loneOakMiddleSchool",
  LorenaMiddleSchool = "lorenaMiddleSchool",
  LulingJuniorHighSchool = "lulingJuniorHighSchool",
  LunaMiddleSchool = "lunaMiddleSchool",
  LyndonBJohnsonMS = "lyndonBJohnsonMS",
  MainStreetIntermediate = "mainStreetIntermediate",
  ManorMiddleSchool = "manorMiddleSchool",
  MarbleFallsMiddleSchool = "marbleFallsMiddleSchool",
  MarionMiddleSchool = "marionMiddleSchool",
  MartinMiddleSchool = "martinMiddleSchool",
  McCormickMiddleSchool = "mcCormickMiddleSchool",
  MendezMiddleSchool = "mendezMiddleSchool",
  MexiaJuniorHigh = "mexiaJuniorHigh",
  MidwayMiddleSchool = "midwayMiddleSchool",
  MoodyMiddleSchool = "moodyMiddleSchool",
  MountainValleyMiddleSchool = "mountainValleyMiddleSchool",
  MurchisonMiddleSchool = "murchisonMiddleSchool",
  NavarroIntermediateSchool = "navarroIntermediateSchool",
  NavarroJuniorHighSchool = "navarroJuniorHighSchool",
  NeffMiddleSchool = "neffMiddleSchool",
  NewBraunfelsMiddleSchool = "newBraunfelsMiddleSchool",
  NixonSmileyMiddleSchool = "nixonSmileyMiddleSchool",
  NolanMiddleSchool = "nolanMiddleSchool",
  NorthBeltonMiddleSchool = "northBeltonMiddleSchool",
  OHenryMiddleSchool = "oHenryMiddleSchool",
  OakRunMiddleSchool = "oakRunMiddleSchool",
  OglesbyMiddleSchool = "oglesbyMiddleSchool",
  OutofArea = "outofArea",
  OwenGoodnightMiddleSchool = "owenGoodnightMiddleSchool",
  PaloAltoMiddleSchool = "paloAltoMiddleSchool",
  ParedesMiddleSchool = "paredesMiddleSchool",
  ParkCrestMiddleSchool = "parkCrestMiddleSchool",
  PattersonMiddleSchool = "pattersonMiddleSchool",
  PearsonRanchMiddleSchool = "pearsonRanchMiddleSchool",
  PeaseMiddleSchool = "peaseMiddleSchool",
  PerkinsMiddleSchool = "perkinsMiddleSchool",
  PflugervilleMiddleSchool = "pflugervilleMiddleSchool",
  PieperMiddleSchool = "pieperMiddleSchool",
  PrairieLeaSchoolPK12 = "prairieLeaSchoolPK12",
  RancierMiddleSchool = "rancierMiddleSchool",
  RawlinsonMiddleSchool = "rawlinsonMiddleSchool",
  RayDCorbettJuniorHighSchool = "rayDCorbettJuniorHighSchool",
  RayburnMiddleSchool = "rayburnMiddleSchool",
  RaymondMaysMiddleSchool = "raymondMaysMiddleSchool",
  RidgeviewMiddleSchool = "ridgeviewMiddleSchool",
  RobertLeeJrHigh = "robertLeeJrHigh",
  RobinsonMiddleSchool = "robinsonMiddleSchool",
  RockdaleJuniorHighSchool = "rockdaleJuniorHighSchool",
  RogersMiddleSchool = "rogersMiddleSchool",
  RosebudLottMiddle = "rosebudLottMiddle",
  RossMiddleSchool = "rossMiddleSchool",
  RoyJSmithMiddleSchool = "royJSmithMiddleSchool",
  RudderMiddleSchool = "rudderMiddleSchool",
  RunningBrushyMiddleSchool = "runningBrushyMiddleSchool",
  SCLee = "sCLee",
  SaladoIntermediateSchool = "saladoIntermediateSchool",
  SaladoJuniorHigh = "saladoJuniorHigh",
  SaladoJuniorHighSchool = "saladoJuniorHighSchool",
  SantaRita = "santaRita",
  SantaRitaElementarySchool = "santaRitaElementarySchool",
  SimonMiddleSchool = "simonMiddleSchool",
  SmallMiddleSchool = "smallMiddleSchool",
  Smith = "smith",
  SmithsonValley = "smithsonValley",
  SmithvilleJuniorHighSchool = "smithvilleJuniorHighSchool",
  SomervilleMiddleSchool = "somervilleMiddleSchool",
  SouthBelton = "southBelton",
  SouthBeltonMiddleSchool = "southBeltonMiddleSchool",
  SpringBranchMiddle = "springBranchMiddle",
  StevensonMiddleSchool = "stevensonMiddleSchool",
  StilesMiddleSchool = "stilesMiddleSchool",
  StinsonMiddleSchool = "stinsonMiddleSchool",
  SycamoreSpringsMiddleSchool = "sycamoreSpringsMiddleSchool",
  TaylorMiddleSchool = "taylorMiddleSchool",
  TennysonMiddle = "tennysonMiddle",
  ThorndaleMiddleSchool = "thorndaleMiddleSchool",
  ThrallMiddleSchool = "thrallMiddleSchool",
  TippitMiddleSchool = "tippitMiddleSchool",
  TravisScienceAcademy = "travisScienceAcademy",
  UnionGroveMiddleSchool = "unionGroveMiddleSchool",
  ValeMiddleSchool = "valeMiddleSchool",
  WaelderSchoolPK12 = "waelderSchoolPK12",
  WagnerMiddleSchool = "wagnerMiddleSchool",
  WallaceMiddleSchool = "wallaceMiddleSchool",
  WalshMiddleSchool = "walshMiddleSchool",
  WebbMiddleSchool = "webbMiddleSchool",
  WestRidgeMiddle = "westRidgeMiddle",
  WestviewMiddleSchool = "westviewMiddleSchool",
  WhitneyMiddleSchool = "whitneyMiddleSchool",
  WileyMiddleSchool = "wileyMiddleSchool",
  ZachryMiddleSchool = "zachryMiddleSchool"
}

export const MiddleSchoolLabel: Record<MiddleSchool, string> = {
  [MiddleSchool.AJBriesemeisterMiddleSchool]:
    "A.J. Briesemeister Middle School",
  [MiddleSchool.AcademyIntermediateSchool]: "Academy Intermediate School",
  [MiddleSchool.AcademyMiddleSchool]: "Academy Middle School",
  [MiddleSchool.AnforthJuniorHighScho]: "anforth Junior High Scho",
  [MiddleSchool.AnnRichardsSchoolforYoungWomenLeaders]:
    "Ann Richards School for Young Women Leaders",
  [MiddleSchool.AudieMurphyMiddleSchool]: "Audie Murphy Middle School",
  [MiddleSchool.BaileyMiddleSchool]: "Bailey Middle School",
  [MiddleSchool.BarbaraCJordanIntermediateSchool]:
    "Barbara C Jordan Intermediate School",
  [MiddleSchool.BartlettMiddleSchool]: "Bartlett  Middle School",
  [MiddleSchool.BartonMiddleSchool]: "Barton Middle School",
  [MiddleSchool.BastropMiddleSchool]: "Bastrop Middle School",
  [MiddleSchool.BedichekMiddleSchool]: "Bedichek Middle School",
  [MiddleSchool.BeeCaveMiddleSchool]: "Bee Cave Middle School",
  [MiddleSchool.BeltonMiddleSchool]: "Belton Middle School",
  [MiddleSchool.BenoldMiddleSchool]: "Benold Middle School",
  [MiddleSchool.BernalMiddleSchool]: "Bernal  Middle School",
  [MiddleSchool.BonhamMiddleSchool]: "Bonham Middle School",
  [MiddleSchool.BrenhamMiddleSchool]: "Brenham Middle School",
  [MiddleSchool.BriscoeMiddleSchool]: "Briscoe Middle School",
  [MiddleSchool.BrucevilleEddyJuniorHigh]: "Bruceville-Eddy Junior High",
  [MiddleSchool.BurnetMiddleSchool]: "Burnet Middle School",
  [MiddleSchool.CDFulkesMiddleSchool]: "C. D. Fulkes Middle School",
  [MiddleSchool.CameronJuniorHighSchool]: "Cameron Junior High School",
  [MiddleSchool.CanyonMiddleSchool]: "Canyon Middle School",
  [MiddleSchool.CanyonRidgeMiddleSchool]: "Canyon Ridge Middle School",
  [MiddleSchool.CanyonVistaMiddleSchool]: "Canyon Vista Middle School",
  [MiddleSchool.CedarCreekIntermediate]: "Cedar Creek Intermediate",
  [MiddleSchool.CedarCreekMiddl]: "Cedar Creek Middl",
  [MiddleSchool.CedarParkMiddleMiddleSchool]: "Cedar Park Middle Middle School",
  [MiddleSchool.CedarValleyMiddleSchool]: "Cedar Valley Middle School",
  [MiddleSchool.CeleMiddleSchool]: "Cele Middle School",
  [MiddleSchool.CesarChavezMiddle]: "Cesar Chavez Middle",
  [MiddleSchool.ChapaMiddleSchool]: "Chapa Middle School",
  [MiddleSchool.ChiltonSchool]: "Chilton School",
  [MiddleSchool.ChinaSpringMiddleSchool]: "China Spring Middle School",
  [MiddleSchool.ChisholmTrailMiddleSchool]: "Chisholm Trail Middle School",
  [MiddleSchool.ChurchHillMiddleSchool]: "Church Hill Middle School",
  [MiddleSchool.ConnallyJuniorHighSchool]: "Connally Junior High School",
  [MiddleSchool.ConnallyMiddleSchool]: "Connally Middle School",
  [MiddleSchool.CopperasCoveJuniorHigh]: "Copperas Cove Junior High",
  [MiddleSchool.CouplandMiddleSchool]: "Coupland Middle School",
  [MiddleSchool.CovingtonMiddleSchool]: "Covington Middle School",
  [MiddleSchool.DahlstromMiddleSchool]: "Dahlstrom Middle School",
  [MiddleSchool.DaileyMiddleSchool]: "Dailey Middle School",
  [MiddleSchool.DanielsonMiddleSchool]: "Danielson Middle School",
  [MiddleSchool.DanvilleMiddleSchool]: "Danville Middle School",
  [MiddleSchool.DeckerMiddleSchool]: "Decker Middle School",
  [MiddleSchool.DeerparkMiddleSchool]: "Deerpark Middle School",
  [MiddleSchool.DelValleMiddleSchool]: "Del Valle Middle School",
  [MiddleSchool.DellCitySchool]: "Dell City School",
  [MiddleSchool.DessauMiddleSchool]: "Dessau Middle School",
  [MiddleSchool.DobieMiddleSchool]: "Dobie Middle School",
  [MiddleSchool.DorisMillerMiddleSchool]: "Doris Miller Middle School",
  [MiddleSchool.DrippingSpringsMiddleSchool]: "Dripping Springs Middle School",
  [MiddleSchool.EasternHillsMiddleSchool]: "Eastern Hills Middle School",
  [MiddleSchool.EastlandMiddleSchool]: "Eastland Middle School",
  [MiddleSchool.ElaineSSchlatherIntermediateSchool]:
    "Elaine S Schlather Intermediate School",
  [MiddleSchool.ElginMiddleSchool]: "Elgin Middle School",
  [MiddleSchool.EvantMiddleSchool]: "Evant Middle School",
  [MiddleSchool.Fairway]: "Fairway",
  [MiddleSchool.FarleyMiddleSchool]: "Farley Middle School",
  [MiddleSchool.Flatonia]: "Flatonia",
  [MiddleSchool.FlorenceMiddleSchool]: "Florence Middle School",
  [MiddleSchool.FolksMiddleSchool]: "Folks Middle School",
  [MiddleSchool.ForbesMiddleSchool]: "Forbes Middle School",
  [MiddleSchool.FourPointsMiddleSchool]: "Four Points Middle School",
  [MiddleSchool.FulmoreMiddleSchool]: "Fulmore Middle School",
  [MiddleSchool.GWCarverMiddle]: "G.W. Carver Middle",
  [MiddleSchool.Garcia]: "Garcia",
  [MiddleSchool.Gatesville]: "Gatesville",
  [MiddleSchool.GatewayMiddleSchool]: "Gateway Middle School",
  [MiddleSchool.GiddingsIntermediateSchool]: "Giddings Intermediate School",
  [MiddleSchool.GonzalesJuniorHighSchool]: "Gonzales Junior High School",
  [MiddleSchool.GonzalesNorthAvenueIntermediate]:
    "Gonzales North Avenue Intermediate",
  [MiddleSchool.GorzyckiMiddleSchool]: "Gorzycki Middle School",
  [MiddleSchool.GrangerMiddleSchool]: "Granger Middle School",
  [MiddleSchool.GrishamMiddleSchool]: "Grisham Middle School",
  [MiddleSchool.GusGarciaYoungMensLeadershipAcademy]:
    "Gus Garcia Young Men's Leadership Academy",
  [MiddleSchool.HGIsbillJuniorHighSchool]: "H.G. Isbill Junior High School",
  [MiddleSchool.Haynes]: "Haynes",
  [MiddleSchool.HectorGarciaMiddleSchool]: "Hector Garcia Middle School",
  [MiddleSchool.HenryMetzgerMiddle]: "Henry Metzger Middle",
  [MiddleSchool.HenryMiddleSchool]: "Henry Middle School",
  [MiddleSchool.HermesIntermediateSchool]: "Hermes/Intermediate School",
  [MiddleSchool.HernandezMiddleSchool]: "Hernandez Middle School",
  [MiddleSchool.HillCountryMiddle]: "Hill Country Middle",
  [MiddleSchool.HobbyMiddleSchool]: "Hobby Middle School",
  [MiddleSchool.HollandMiddleSchool]: "Holland Middle School",
  [MiddleSchool.HopewellMiddleSchool]: "Hopewell Middle School",
  [MiddleSchool.HudsonMiddleSchool]: "Hudson Middle School",
  [MiddleSchool.HuttoMiddleSchool]: "Hutto Middle School",
  [MiddleSchool.IndianSpringMiddle]: "Indian Spring Middle",
  [MiddleSchool.JFrankDobieJuniorHighSchool]:
    "J Frank Dobie Junior High School",
  [MiddleSchool.JarrellIntermediateSchool]: "Jarrell Intermediate  School",
  [MiddleSchool.JarrellMiddleSchool]: "Jarrell Middle School",
  [MiddleSchool.JeffersonMiddleSchool]: "Jefferson Middle School",
  [MiddleSchool.JimBarnesMiddleSchool]: "Jim Barnes Middle School ",
  [MiddleSchool.JohnPOjedaMiddleSchool]: "John P. Ojeda Middle School",
  [MiddleSchool.JonesMiddleSchool]: "Jones Middle School",
  [MiddleSchool.JonesboroMiddle]: "Jonesboro Middle",
  [MiddleSchool.JordanMiddleSchool]: "Jordan Middle School",
  [MiddleSchool.KealingMiddleSchool]: "Kealing Middle School",
  [MiddleSchool.KellyLaneMiddleSchool]: "Kelly Lane Middle School",
  [MiddleSchool.KittyHawkMiddleSchool]: "Kitty Hawk Middle School",
  [MiddleSchool.LaGrangeMiddleSchool]: "La Grange Middle School",
  [MiddleSchool.LagoVistaIntermediateSchool]: "Lago Vista Intermediate School",
  [MiddleSchool.LagoVistaMiddleSchool]: "Lago Vista Middle School",
  [MiddleSchool.LakeBeltonMiddleSchool]: "Lake Belton Middle School",
  [MiddleSchool.LakeTravisMiddleSchool]: "Lake Travis Middle School",
  [MiddleSchool.LamarMiddleSchool]: "Lamar Middle School",
  [MiddleSchool.LampasasMiddleSchool]: "Lampasas Middle School",
  [MiddleSchool.LauraIngallsWilderIntermediateSchool]:
    "Laura Ingalls Wilder Intermediate School",
  [MiddleSchool.LaVegaMiddle]: "LaVega Middle",
  [MiddleSchool.LeakeyMiddleSchool]: "Leakey Middle School",
  [MiddleSchool.LeanderMiddleMiddleSchool]: "Leander Middle Middle School",
  [MiddleSchool.LexingtonMiddleSchool]: "Lexington Middle School",
  [MiddleSchool.LibertyHillIntermediateSchool]:
    "Liberty Hill Intermediate  School",
  [MiddleSchool.LibertyHillJuniorHighSchool]:
    "Liberty Hill Junior High  School",
  [MiddleSchool.LibertyHillMiddleSchool]: "Liberty Hill Middle School",
  [MiddleSchool.LiveOakRidgeMiddleSchool]: "Live Oak Ridge Middle School",
  [MiddleSchool.LlanoJrHighSchool]: "Llano Jr. High School",
  [MiddleSchool.LockhartJuniorHighSchool]: "Lockhart Junior High School",
  [MiddleSchool.LometaMiddle]: "Lometa Middle",
  [MiddleSchool.LoneOakMiddleSchool]: "Lone Oak Middle School",
  [MiddleSchool.LorenaMiddleSchool]: "Lorena Middle School",
  [MiddleSchool.LulingJuniorHighSchool]: "Luling Junior High School",
  [MiddleSchool.LunaMiddleSchool]: "Luna Middle School",
  [MiddleSchool.LyndonBJohnsonMS]: "Lyndon B. Johnson MS",
  [MiddleSchool.MainStreetIntermediate]: "Main Street Intermediate",
  [MiddleSchool.ManorMiddleSchool]: "Manor Middle School",
  [MiddleSchool.MarbleFallsMiddleSchool]: "Marble Falls Middle School",
  [MiddleSchool.MarionMiddleSchool]: "Marion Middle School",
  [MiddleSchool.MartinMiddleSchool]: "Martin Middle School",
  [MiddleSchool.McCormickMiddleSchool]: "McCormick Middle School",
  [MiddleSchool.MendezMiddleSchool]: "Mendez Middle School",
  [MiddleSchool.MexiaJuniorHigh]: "Mexia Junior High",
  [MiddleSchool.MidwayMiddleSchool]: "Midway Middle School",
  [MiddleSchool.MoodyMiddleSchool]: "Moody Middle School",
  [MiddleSchool.MountainValleyMiddleSchool]: "Mountain Valley Middle School",
  [MiddleSchool.MurchisonMiddleSchool]: "Murchison Middle School",
  [MiddleSchool.NavarroIntermediateSchool]: "Navarro Intermediate School",
  [MiddleSchool.NavarroJuniorHighSchool]: "Navarro Junior High School",
  [MiddleSchool.NeffMiddleSchool]: "Neff Middle School",
  [MiddleSchool.NewBraunfelsMiddleSchool]: "New Braunfels Middle School",
  [MiddleSchool.NixonSmileyMiddleSchool]: "Nixon-Smiley Middle School",
  [MiddleSchool.NolanMiddleSchool]: "Nolan Middle School",
  [MiddleSchool.NorthBeltonMiddleSchool]: "North Belton Middle School",
  [MiddleSchool.OHenryMiddleSchool]: "O. Henry Middle School",
  [MiddleSchool.OakRunMiddleSchool]: "Oak Run Middle School",
  [MiddleSchool.OglesbyMiddleSchool]: "Oglesby Middle School",
  [MiddleSchool.OutofArea]: "Out of Area",
  [MiddleSchool.OwenGoodnightMiddleSchool]: "Owen Goodnight Middle School",
  [MiddleSchool.PaloAltoMiddleSchool]: "Palo Alto Middle School",
  [MiddleSchool.ParedesMiddleSchool]: "Paredes Middle School",
  [MiddleSchool.ParkCrestMiddleSchool]: "Park Crest Middle School",
  [MiddleSchool.PattersonMiddleSchool]: "Patterson Middle School",
  [MiddleSchool.PearsonRanchMiddleSchool]: "Pearson Ranch Middle School",
  [MiddleSchool.PeaseMiddleSchool]: "Pease Middle School",
  [MiddleSchool.PerkinsMiddleSchool]: "Perkins Middle School",
  [MiddleSchool.PflugervilleMiddleSchool]: "Pflugerville Middle School",
  [MiddleSchool.PieperMiddleSchool]: "Pieper Middle School",
  [MiddleSchool.PrairieLeaSchoolPK12]: "Prairie Lea School (PK-12)",
  [MiddleSchool.RancierMiddleSchool]: "Rancier Middle School",
  [MiddleSchool.RawlinsonMiddleSchool]: "Rawlinson Middle School",
  [MiddleSchool.RayDCorbettJuniorHighSchool]:
    "Ray D Corbett Junior High School",
  [MiddleSchool.RayburnMiddleSchool]: "Rayburn Middle School",
  [MiddleSchool.RaymondMaysMiddleSchool]: "Raymond Mays Middle School",
  [MiddleSchool.RidgeviewMiddleSchool]: "Ridgeview Middle School",
  [MiddleSchool.RobertLeeJrHigh]: "Robert Lee Jr. High",
  [MiddleSchool.RobinsonMiddleSchool]: "Robinson Middle School",
  [MiddleSchool.RockdaleJuniorHighSchool]: "Rockdale Junior High School",
  [MiddleSchool.RogersMiddleSchool]: "Rogers Middle School",
  [MiddleSchool.RosebudLottMiddle]: "Rosebud-Lott Middle",
  [MiddleSchool.RossMiddleSchool]: "Ross Middle School",
  [MiddleSchool.RoyJSmithMiddleSchool]: "Roy J Smith Middle School",
  [MiddleSchool.RudderMiddleSchool]: "Rudder Middle School",
  [MiddleSchool.RunningBrushyMiddleSchool]: "Running Brushy Middle School",
  [MiddleSchool.SCLee]: "S.C.Lee",
  [MiddleSchool.SaladoIntermediateSchool]: "Salado Intermediate School",
  [MiddleSchool.SaladoJuniorHigh]: "Salado Junior High",
  [MiddleSchool.SaladoJuniorHighSchool]: "Salado Junior High School",
  [MiddleSchool.SantaRita]: "Santa Rita",
  [MiddleSchool.SantaRitaElementarySchool]: "Santa Rita Elementary School",
  [MiddleSchool.SimonMiddleSchool]: "Simon Middle School",
  [MiddleSchool.SmallMiddleSchool]: "Small Middle School",
  [MiddleSchool.Smith]: "Smith",
  [MiddleSchool.SmithsonValley]: "Smithson Valley",
  [MiddleSchool.SmithvilleJuniorHighSchool]: "Smithville Junior High School",
  [MiddleSchool.SomervilleMiddleSchool]: "Somerville Middle School",
  [MiddleSchool.SouthBelton]: "South Belton",
  [MiddleSchool.SouthBeltonMiddleSchool]: "South Belton Middle School",
  [MiddleSchool.SpringBranchMiddle]: "Spring Branch Middle",
  [MiddleSchool.StevensonMiddleSchool]: "Stevenson Middle School",
  [MiddleSchool.StilesMiddleSchool]: "Stiles Middle School",
  [MiddleSchool.StinsonMiddleSchool]: "Stinson Middle School",
  [MiddleSchool.SycamoreSpringsMiddleSchool]: "Sycamore Springs Middle School",
  [MiddleSchool.TaylorMiddleSchool]: "Taylor Middle School",
  [MiddleSchool.TennysonMiddle]: "Tennyson Middle",
  [MiddleSchool.ThorndaleMiddleSchool]: "Thorndale Middle School",
  [MiddleSchool.ThrallMiddleSchool]: "Thrall Middle School",
  [MiddleSchool.TippitMiddleSchool]: "Tippit Middle School",
  [MiddleSchool.TravisScienceAcademy]: "Travis Science Academy",
  [MiddleSchool.UnionGroveMiddleSchool]: "Union Grove Middle School",
  [MiddleSchool.ValeMiddleSchool]: "Vale Middle School",
  [MiddleSchool.WaelderSchoolPK12]: "Waelder School (PK-12)",
  [MiddleSchool.WagnerMiddleSchool]: "Wagner Middle School",
  [MiddleSchool.WallaceMiddleSchool]: "Wallace Middle School",
  [MiddleSchool.WalshMiddleSchool]: "Walsh Middle School",
  [MiddleSchool.WebbMiddleSchool]: "Webb Middle School",
  [MiddleSchool.WestRidgeMiddle]: "West Ridge Middle",
  [MiddleSchool.WestviewMiddleSchool]: "Westview Middle School",
  [MiddleSchool.WhitneyMiddleSchool]: "Whitney Middle School",
  [MiddleSchool.WileyMiddleSchool]: "Wiley Middle School",
  [MiddleSchool.ZachryMiddleSchool]: "Zachry Middle School"
};
