export enum Stories {
  One = "one",
  Two = "two",
  Three = "three",
  SplitMultiLevel = "splitMultiLevel"
}

export const StoriesLabel = new Map<Stories, string>([
  [Stories.One, "One"],
  [Stories.Two, "Two"],
  [Stories.Three, "Three+"],
  [Stories.SplitMultiLevel, "Split/Multi Level"]
]);
