export enum HousingStyle {
  French = "french",
  Victorian = "victorian",
  OtherStyle = "otherStyle",
  Traditional = "traditional",
  Georgian = "georgian",
  ContemporaryModern = "contemporaryModern",
  English = "english",
  Ranch = "ranch",
  SplitLevel = "splitLevel",
  Mediterranean = "mediterranean",
  Colonial = "colonial",
  Spanish = "spanish"
}

export const HousingStyleLabel = new Map<HousingStyle, string>([
  [HousingStyle.French, "French"],
  [HousingStyle.Victorian, "Victorian"],
  [HousingStyle.OtherStyle, "Other Style"],
  [HousingStyle.Traditional, "Traditional"],
  [HousingStyle.Georgian, "Georgian"],
  [HousingStyle.ContemporaryModern, "Contemporary/Modern"],
  [HousingStyle.English, "English"],
  [HousingStyle.Ranch, "Ranch"],
  [HousingStyle.SplitLevel, "Split Level"],
  [HousingStyle.Mediterranean, "Mediterranean"],
  [HousingStyle.Colonial, "Colonial"],
  [HousingStyle.Spanish, "Spanish"]
]);
