export enum RoofDescription {
  BuiltUp = "builtUp",
  ClassThree = "classThree",
  ClassFour = "classFour",
  Composition = "composition",
  Fiberglass = "fiberglass",
  Membrane = "membrane",
  Metal = "metal",
  Tile = "tile",
  Wood = "wood"
}

export const RoofDescriptionLabel = new Map<RoofDescription, string>([
  [RoofDescription.BuiltUp, "Built Up"],
  [RoofDescription.ClassThree, "Class 3"],
  [RoofDescription.ClassFour, "Class 4"],
  [RoofDescription.Composition, "Composition"],
  [RoofDescription.Fiberglass, "Fiberglass"],
  [RoofDescription.Membrane, "Membrane"],
  [RoofDescription.Metal, "Metal"],
  [RoofDescription.Tile, "Tile"],
  [RoofDescription.Wood, "Wood"]
]);
