export enum PrimaryBedroom {
  CeilingFans = "ceilingFans",
  CrownMolding = "crownMolding",
  HighCeilings = "highCeilings",
  HisHerClosets = "hisHerClosets",
  PrimaryBedroomSittingStudyRoom = "primaryBedroomSittingStudyRoom",
  RecessedLighting = "recessedLighting",
  TrayCeilings = "trayCeilings",
  VaultedCeilings = "vaultedCeilings",
  WalkInClosets = "walkInClosets",
  WiredforData = "wiredforData",
  WiredforSound = "wiredforSound"
}

export const PrimaryBedroomLabel = new Map<PrimaryBedroom, string>([
  [PrimaryBedroom.CeilingFans, "Ceiling Fan(s)"],
  [PrimaryBedroom.CrownMolding, "Crown Molding"],
  [PrimaryBedroom.HighCeilings, "High Ceilings"],
  [PrimaryBedroom.HisHerClosets, "His Her Closets"],
  [
    PrimaryBedroom.PrimaryBedroomSittingStudyRoom,
    "Primary Bedroom Sitting/Study Room"
  ],
  [PrimaryBedroom.RecessedLighting, "Recessed Lighting"],
  [PrimaryBedroom.TrayCeilings, "Tray Ceiling(s)"],
  [PrimaryBedroom.VaultedCeilings, "Vaulted Ceiling(s)"],
  [PrimaryBedroom.WalkInClosets, "Walk-In Closet(s)"],
  [PrimaryBedroom.WiredforData, "Wired for Data"],
  [PrimaryBedroom.WiredforSound, "Wired for Sound"]
]);
