import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { Stories, StoriesLabel } from "markets/dfw/enums";

const roomNumberRule = (
  label: string,
  requiredToSubmit = true
): FieldRulesProps => ({
  label,
  maxLength: 1,
  requiredToSubmit,
  numberFormat: {
    min: 0
  }
});

export const spacesDimensionsRules = {
  numBedrooms: roomNumberRule("# Bedrooms", true),
  numBathsFull: roomNumberRule("# Full Baths", true),
  numBathsHalf: roomNumberRule("# Half Baths", true),
  numLivingAreas: roomNumberRule("# Living Areas", true),
  numDiningAreas: roomNumberRule("# Dining Areas", true),
  stories: {
    requiredToSubmit: true,
    label: "Levels",
    options: getOptionsFromEnumAndMap(Object.values(Stories), StoriesLabel)
  }
};
