import { MarketCode } from "@homesusa/core";

export const maxMediaMarket: Record<MarketCode, number> = {
  [MarketCode.Sabor]: 50,
  [MarketCode.CTX]: 48,
  [MarketCode.Austin]: 40,
  [MarketCode.DFW]: 40,
  [MarketCode.Houston]: 50,
  [MarketCode.Amarillo]: 50
};
