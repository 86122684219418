export enum WasherConnection {
  WasherConnections = "washerConnections",
  ElectricDryerConnections = "electricDryerConnections",
  GasDryerConnections = "gasDryerConnections"
}

export const WasherConnectionLabel = new Map<WasherConnection, string>([
  [WasherConnection.WasherConnections, "Washer Connections"],
  [WasherConnection.ElectricDryerConnections, "Electric Dryer Connections"],
  [WasherConnection.GasDryerConnections, "Gas Dryer Connections"]
]);
