import React from "react";

import { AuthCompanyContext, useHasRole } from "@homesusa/auth";
import { CompanyServiceCode, ServiceSubscriptions } from "@homesusa/core";
import { getCompanyServices } from "core/services/company.service";

export const useHasXmlService = (): boolean | undefined => {
  const { isMlsAdministrator } = useHasRole();
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const [companyServices, setCompanyServices] =
    React.useState<ServiceSubscriptions[]>();

  React.useEffect(() => {
    if (currentCompany?.id) {
      getCompanyServices(currentCompany.id).then((response) =>
        setCompanyServices(response.data)
      );
    }
  }, [currentCompany?.id]);

  const hasXmlService = React.useMemo(
    () =>
      isMlsAdministrator
        ? true
        : companyServices?.some(
            ({ serviceCode }) => serviceCode == CompanyServiceCode.XMLImport
          ),
    [isMlsAdministrator, companyServices]
  );

  return hasXmlService;
};
