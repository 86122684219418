export enum Exemptions {
  Agricultural = "agricultural",
  Disability = "disability",
  Historical = "historical",
  Homestead = "homestead",
  Senior = "senior",
  Wildlife = "wildlife",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const ExemptionsLabel = new Map<Exemptions, string>([
  [Exemptions.Agricultural, "Agricultural"],
  [Exemptions.Disability, "Disability"],
  [Exemptions.Historical, "Historical"],
  [Exemptions.Homestead, "Homestead"],
  [Exemptions.Senior, "Senior"],
  [Exemptions.Wildlife, "Wildlife"],
  [Exemptions.None, "None"],
  [Exemptions.OtherSeeRemarks, "Other-See Remarks"]
]);
