export enum WaterHeaterDescription {
  Gallons = "gallons",
  Electric = "electric",
  NaturalGas = "naturalGas",
  Propane = "propane",
  RecirculatingPump = "recirculatingPump",
  Tankless = "tankless",
  None = "none"
}

export const WaterHeaterDescriptionLabel: Record<
  WaterHeaterDescription,
  string
> = {
  [WaterHeaterDescription.Gallons]: "Gallons",
  [WaterHeaterDescription.Electric]: "Electric",
  [WaterHeaterDescription.NaturalGas]: "Natural Gas",
  [WaterHeaterDescription.Propane]: "Propane",
  [WaterHeaterDescription.RecirculatingPump]: "Recirculating Pump",
  [WaterHeaterDescription.Tankless]: "Tankless",
  [WaterHeaterDescription.None]: "None"
};
