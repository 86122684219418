import { FormRules } from "@homesusa/form";
import { showingRules } from "../../sections";

export const showingInfoRules: FormRules = {
  ...showingRules,
  agentPrivateRemarks: {
    label: "Agent or Private Remarks",
    maxLength: 375,
    rows: 5
  },
  enableOpenHouses: {
    label: "Enable Open House Feature"
  },
  showOpenHousesPending: {
    label: "Show Open House if this listing is PENDING?"
  }
};
