import React, { Suspense } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from "react-router-dom";
import ReactGA from "react-ga4";
import { LinearProgress } from "@mui/material";

import {
  PrivateRoute,
  appPublicRoutesConfig,
  appPrivateRoutesConfig,
  AppProvider,
  ThemeProvider
} from "@homesusa/layout";
import { ErrorBoundary, RoleUser, useYbug } from "@homesusa/core";
import { AuthAppOutlet } from "@homesusa/auth";
import { AppEndpoint } from "core/enums";
import {
  CommunityRoutes,
  PlanRoutes,
  SaleListingRequestRoutes,
  SaleListingRoutes,
  LotListingRoutes,
  LotRequestRoutes
} from "./routes";
import { ReportRoutes } from "modules/reports/pages/report-routes.component";
import { DashboardOutlet } from "modules/dashboard/components";
import { appState } from "../app-state";
import { RouteWrapper } from "./route-wrapper.component";

const DashboardGrid = React.lazy(
  () => import("modules/dashboard/pages/dashboard-grid.component")
);
const DashboardAlerts = React.lazy(
  () => import("modules/dashboard/pages/actions-grid.component")
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<AuthAppOutlet apiUrl={process.env.REACT_APP_API_GATEWAY} />}
    >
      {appPublicRoutesConfig.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route element={<RouteWrapper />}>
        <Route element={<PrivateRoute />}>
          {appPrivateRoutesConfig.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
        <Route
          element={
            <PrivateRoute
              allowedUserRoles={[RoleUser.MLSAdministrator, RoleUser.User]}
            />
          }
          errorElement={<ErrorBoundary />}
        >
          <Route
            path={`${AppEndpoint.LotListings}/*`}
            element={<LotListingRoutes />}
          />
          <Route
            path={`${AppEndpoint.SaleListings}/*`}
            element={<SaleListingRoutes />}
          />
          <Route
            path={`${AppEndpoint.Communities}/*`}
            element={<CommunityRoutes />}
          />
          <Route path={`${AppEndpoint.Plans}/*`} element={<PlanRoutes />} />
          <Route
            path={`${AppEndpoint.SaleListingRequests}/*`}
            element={<SaleListingRequestRoutes />}
          />
          <Route
            path={`${AppEndpoint.LotListingRequests}/*`}
            element={<LotRequestRoutes />}
          />
          <Route path={`${AppEndpoint.Reports}/*`} element={<ReportRoutes />} />
          <Route element={<DashboardOutlet />}>
            <Route index element={<DashboardGrid />} />
            <Route
              path={AppEndpoint.DashboardAlerts}
              element={<DashboardAlerts />}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);
export function App(): JSX.Element {
  const initialState = React.useMemo(() => appState, []);
  useYbug();

  ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);

  return (
    <ThemeProvider>
      <AppProvider initialState={initialState}>
        <Suspense fallback={<LinearProgress />}>
          <RouterProvider router={router} />
        </Suspense>
      </AppProvider>
    </ThemeProvider>
  );
}
