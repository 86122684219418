export enum Floors {
  Terrazo = "terrazo",
  Wood = "wood",
  Carpet = "carpet",
  Laminate = "laminate",
  Concrete = "concrete",
  Stone = "stone",
  EngineeredWood = "engineeredWood",
  Brick = "brick",
  VinylPlank = "vinylPlank",
  Travertine = "travertine",
  Slate = "slate",
  Tile = "tile",
  Vinyl = "vinyl",
  MarbleFloors = "marbleFloors"
}

export const FloorsLabel = new Map<Floors, string>([
  [Floors.Terrazo, "Terrazo"],
  [Floors.Wood, "Wood"],
  [Floors.Carpet, "Carpet"],
  [Floors.Laminate, "Laminate"],
  [Floors.Concrete, "Concrete"],
  [Floors.Stone, "Stone"],
  [Floors.EngineeredWood, "Engineered Wood"],
  [Floors.Brick, "Brick"],
  [Floors.VinylPlank, "Vinyl Plank"],
  [Floors.Travertine, "Travertine"],
  [Floors.Slate, "Slate"],
  [Floors.Tile, "Tile"],
  [Floors.Vinyl, "Vinyl"],
  [Floors.MarbleFloors, "Marble Floors"]
]);
