export enum Appliances {
  BarFridge = "barFridge",
  BuiltInElectricOven = "builtInElectricOven",
  BuiltInElectricRange = "builtInElectricRange",
  BuiltInFreezer = "builtInFreezer",
  BuiltInGasOven = "builtInGasOven",
  BuiltInGasRange = "builtInGasRange",
  BuiltInOven = "builtInOven",
  BuiltInRange = "builtInRange",
  BuiltInRefrigerator = "builtInRefrigerator",
  ConvectionOven = "convectionOven",
  Cooktop = "cooktop",
  Dishwasher = "dishwasher",
  Disposal = "disposal",
  DownDraft = "downDraft",
  Dryer = "dryer",
  ElectricCooktop = "electricCooktop",
  ElectricRange = "electricRange",
  EnergyStarQualifiedAppliances = "energyStarQualifiedAppliances",
  EnergyStarQualifiedDishwasher = "energyStarQualifiedDishwasher",
  EnergyStarQualifiedDryer = "energyStarQualifiedDryer",
  EnergyStarQualifiedFreezer = "energyStarQualifiedFreezer",
  EnergyStarQualifiedRefrigerator = "energyStarQualifiedRefrigerator",
  EnergyStarQualifiedWasher = "energyStarQualifiedWasher",
  EnergyStarQualifiedWaterHeater = "energyStarQualifiedWaterHeater",
  ExhaustFan = "exhaustFan",
  GasCooktop = "gasCooktop",
  GasRange = "gasRange",
  InstantHotWater = "instantHotWater",
  Microwave = "microwave",
  None = "none",
  Oven = "oven",
  OvenElectric = "ovenElectric",
  OvenFreeStandingElectric = "ovenFreeStandingElectric",
  OvenFreeStandingGas = "ovenFreeStandingGas",
  OvenGas = "ovenGas",
  OvenDouble = "ovenDouble",
  PlumbedForIceMaker = "plumbedForIceMaker",
  Range = "range",
  RangeFreeStandingElectric = "rangeFreeStandingElectric",
  RangeFreeStanding = "rangeFreeStanding",
  RangeFreeStandingGas = "rangeFreeStandingGas",
  RangeHood = "rangeHood",
  Refrigerator = "refrigerator",
  RefrigeratorFreeStanding = "refrigeratorFreeStanding",
  SeeRemarks = "seeRemarks",
  SelfCleaningOven = "selfCleaningOven",
  SolarHotWater = "solarHotWater",
  StainlessSteelAppliance = "stainlessSteelAppliance",
  TanklessWaterHeater = "tanklessWaterHeater",
  TrashCompactor = "trashCompactor",
  VentedExhaustFan = "ventedExhaustFan",
  WasherDryer = "washerDryer",
  WaterHeaterElectric = "waterHeaterElectric",
  WaterHeaterGas = "waterHeaterGas"
}
export const AppliancesLabel = new Map<Appliances, string>([
  [Appliances.BarFridge, "Bar Fridge"],
  [Appliances.BuiltInElectricOven, "Built-In Electric Oven"],
  [Appliances.BuiltInElectricRange, "Built-In Electric Range"],
  [Appliances.BuiltInFreezer, "Built-In Freezer"],
  [Appliances.BuiltInGasOven, "Built-In Gas Oven"],
  [Appliances.BuiltInGasRange, "Built-In Gas Range"],
  [Appliances.BuiltInOven, "Built-In Oven(s)"],
  [Appliances.BuiltInRange, "Built-In Range"],
  [Appliances.BuiltInRefrigerator, "Built-In Refrigerator"],
  [Appliances.ConvectionOven, "Convection Oven"],
  [Appliances.Cooktop, "Cooktop"],
  [Appliances.Dishwasher, "Dishwasher"],
  [Appliances.Disposal, "Disposal"],
  [Appliances.DownDraft, "Down Draft"],
  [Appliances.Dryer, "Dryer"],
  [Appliances.ElectricCooktop, "Electric Cooktop"],
  [Appliances.ElectricRange, "Electric Range"],
  [
    Appliances.EnergyStarQualifiedAppliances,
    "ENERGY STAR Qualified Appliances"
  ],
  [
    Appliances.EnergyStarQualifiedDishwasher,
    "ENERGY STAR Qualified Dishwasher"
  ],
  [Appliances.EnergyStarQualifiedDryer, "ENERGY STAR Qualified Dryer"],
  [Appliances.EnergyStarQualifiedFreezer, "ENERGY STAR Qualified Freezer"],
  [
    Appliances.EnergyStarQualifiedRefrigerator,
    "ENERGY STAR Qualified Refrigerator"
  ],
  [Appliances.EnergyStarQualifiedWasher, "ENERGY STAR Qualified Washer"],
  [
    Appliances.EnergyStarQualifiedWaterHeater,
    "ENERGY STAR Qualified Water Heater"
  ],
  [Appliances.ExhaustFan, "Exhaust Fan"],
  [Appliances.GasCooktop, "Gas Cooktop"],
  [Appliances.GasRange, "Gas Range"],
  [Appliances.InstantHotWater, "Instant Hot Water"],
  [Appliances.Microwave, "Microwave"],
  [Appliances.None, "None"],
  [Appliances.Oven, "Oven"],
  [Appliances.OvenElectric, "Oven Electric"],
  [Appliances.OvenFreeStandingElectric, "Oven Free-Standing Electric"],
  [Appliances.OvenFreeStandingGas, "Oven Free-Standing Gas"],
  [Appliances.OvenGas, "Oven Gas"],
  [Appliances.OvenDouble, "Oven-Double"],
  [Appliances.PlumbedForIceMaker, "Plumbed For Ice Maker"],
  [Appliances.Range, "Range"],
  [Appliances.RangeFreeStandingElectric, "Range Free Standing Electric"],
  [Appliances.RangeFreeStanding, "Range Free-Standing"],
  [Appliances.RangeFreeStandingGas, "Range Free-Standing Gas"],
  [Appliances.RangeHood, "Range Hood"],
  [Appliances.Refrigerator, "Refrigerator"],
  [Appliances.RefrigeratorFreeStanding, "Refrigerator Free-Standing"],
  [Appliances.SeeRemarks, "See Remarks"],
  [Appliances.SelfCleaningOven, "Self Cleaning Oven"],
  [Appliances.SolarHotWater, "Solar Hot Water"],
  [Appliances.StainlessSteelAppliance, "Stainless Steel Appliance(s)"],
  [Appliances.TanklessWaterHeater, "Tankless Water Heater"],
  [Appliances.TrashCompactor, "Trash Compactor"],
  [Appliances.VentedExhaustFan, "Vented Exhaust Fan"],
  [Appliances.WasherDryer, "Washer Dryer"],
  [Appliances.WaterHeaterElectric, "Water Heater-Electric"],
  [Appliances.WaterHeaterGas, "Water Heater-Gas"]
]);
