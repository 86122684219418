import React, { Fragment } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

import {
  NumberInput,
  DatePicker,
  Switch,
  FormContext,
  FormMode,
  Select
} from "@homesusa/form";
import { useRefresh, CommissionType } from "@homesusa/core";
import { AgentBonusInfo } from "core/interfaces";
import { commissionRules } from "core/rules/custom-rules";

export function AgentBonusSection({
  agentInfo,
  agentInfoPath,
  dispatchSubmitRule
}: {
  agentInfo: AgentBonusInfo;
  agentInfoPath: string;
  dispatchSubmitRule?: (props: {
    required: boolean;
    defaultValues: Record<string, unknown>;
  }) => void;
}): JSX.Element {
  const { formDispatch, formMode, formRulesDispatch, formErrorsDispatch } =
    React.useContext(FormContext);
  const [hideAgentBonusAmount, refreshAgentBonusAmount] = useRefresh();
  const fieldPath = React.useMemo(
    () => ({
      bonusExpirationDate: `${agentInfoPath}.bonusExpirationDate`,
      agentBonusAmount: `${agentInfoPath}.agentBonusAmount`,
      agentBonusAmountType: `${agentInfoPath}.agentBonusAmountType`,
      hasBonusWithAmount: `${agentInfoPath}.hasBonusWithAmount`,
      hasAgentBonus: `${agentInfoPath}.hasAgentBonus`
    }),
    [agentInfoPath]
  );

  const setStateValue = (inputName: string, payload: unknown): void => {
    if (formErrorsDispatch) {
      formErrorsDispatch({
        type: "Remove",
        inputName
      });
    }
    if (formDispatch) {
      formDispatch({
        type: "FormChangeByInputName",
        inputName,
        payload
      });
    }
  };

  const changeAgentBonusWithAmount = (hasBonusWithAmount: boolean): void => {
    if (!!dispatchSubmitRule) {
      dispatchSubmitRule({
        required: hasBonusWithAmount,
        defaultValues: {
          [fieldPath.agentBonusAmount]: undefined,
          [fieldPath.bonusExpirationDate]: undefined,
          [fieldPath.agentBonusAmountType]: undefined
        }
      });
    }
    if (!!hasBonusWithAmount) {
      changeAgentBonusAmountType(
        agentInfo.agentBonusAmountType ?? CommissionType.Percent
      );
    }
  };

  const changeAgentBonusAmountType = (amountType?: string | null): void => {
    if (amountType && formRulesDispatch) {
      formRulesDispatch({
        type: "UpdateByInputName",
        inputName: fieldPath.agentBonusAmount,
        payload: commissionRules(amountType)
      });
      setStateValue(fieldPath.agentBonusAmountType, amountType);
      refreshAgentBonusAmount();
    }
  };

  React.useEffect(() => {
    changeAgentBonusAmountType(agentInfo?.agentBonusAmountType);
  }, []);

  const setBooleanValue = (inputName: string, payload: string): void => {
    if (formDispatch) {
      formDispatch({
        type: "FormChangeByInputName",
        inputName,
        payload: String(payload) == "true" ? true : false
      });
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          value={agentInfo?.hasAgentBonus ? "true" : "false"}
          name={fieldPath.hasAgentBonus}
          onChange={(value): void => {
            setBooleanValue(fieldPath.hasAgentBonus, value as string);
          }}
        />
      </Grid>

      <>
        <Grid item xs={12}>
          <Switch
            value={agentInfo?.hasBonusWithAmount}
            name={fieldPath.hasBonusWithAmount}
            onChange={(value): void => changeAgentBonusWithAmount(!!value)}
          />
        </Grid>
        {!!agentInfo?.hasBonusWithAmount && (
          <Fragment>
            <Grid item xs={12}>
              <RadioGroup
                row
                value={agentInfo?.agentBonusAmountType}
                onChange={(item): void => {
                  changeAgentBonusAmountType(item.target.value);
                  setStateValue(fieldPath.agentBonusAmount, undefined);
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={formMode === FormMode.ReadOnly}
                  value={CommissionType.Percent}
                  label="%"
                  checked={
                    agentInfo?.agentBonusAmountType === CommissionType.Percent
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={formMode === FormMode.ReadOnly}
                  value={CommissionType.Amount}
                  label="$"
                  checked={
                    agentInfo?.agentBonusAmountType === CommissionType.Amount
                  }
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              {!hideAgentBonusAmount && (
                <NumberInput
                  defaultValue={agentInfo?.agentBonusAmount}
                  name={fieldPath.agentBonusAmount}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                defaultValue={agentInfo?.bonusExpirationDate}
                name={fieldPath.bonusExpirationDate}
              />
            </Grid>
          </Fragment>
        )}
      </>
    </Grid>
  );
}
