import React from "react";

import { FormContextRulesActions } from "@homesusa/form";

export const useListingAgent = (
  formRulesDispatch: React.Dispatch<FormContextRulesActions>,
  fieldPath: string
): [
  agents: Record<string, string> | undefined,
  updateAgentRule: (agents?: Record<string, string>) => void
] => {
  const [agents, setLocalAgents] = React.useState<Record<string, string>>();

  const updateAgentRule = (agents?: Record<string, string>): void => {
    updateLocalAgentRule(fieldPath, agents);
    setLocalAgents(agents);
  };

  const updateLocalAgentRule = (
    agentFieldName: string,
    agents?: Record<string, string>
  ): void => {
    formRulesDispatch({
      type: "UpdateByInputName",
      inputName: agentFieldName,
      payload: {
        options: agents,
        disabled: !agents || Object.values(agents).length == 0
      }
    });
  };
  return [agents, updateAgentRule];
};
