export enum Pool {
  Diving = "diving",
  Heated = "heated",
  InGround = "inGround",
  Lap = "lap",
  Other = "other",
  SaltWater = "saltWater",
  SeparateSpaHotTub = "separateSpaHotTub",
  WaterFeature = "waterFeature"
}
export const PoolLabel = new Map<Pool, string>([
  [Pool.Diving, "Diving"],
  [Pool.Heated, "Heated"],
  [Pool.InGround, "In Ground"],
  [Pool.Lap, "Lap"],
  [Pool.Other, "Other"],
  [Pool.SaltWater, "Salt Water"],
  [Pool.SeparateSpaHotTub, "Separate Spa/Hot Tub"],
  [Pool.WaterFeature, "Water Feature"]
]);
