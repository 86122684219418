import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle
} from "@mui/material";

import { AppContext, SubmitButton } from "@homesusa/layout";
import { ImportSource, RouterPath } from "core/enums";
import { ListType, ListTypeFilterLabel } from "modules/dashboard/enums";
import { DashboardContext } from "modules/dashboard/context/dashboard";

type Color =
  | "success"
  | "default"
  | "error"
  | "warning"
  | "info"
  | "inherit"
  | "primary"
  | "secondary"
  | undefined;

export function ProcessButton({
  listingId,
  listType,
  iconButton,
  color,
  importSource
}: {
  listingId: string;
  listType: ListType;
  iconButton: React.ReactNode;
  color?: Color;
  importSource: ImportSource;
}): JSX.Element {
  const {
    importServices: { processListing }
  } = React.useContext(DashboardContext);
  const navigate = useNavigate();
  const { addAlert } = React.useContext(AppContext);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);

  return (
    <Fragment>
      <IconButton
        title={ListTypeFilterLabel.get(listType)}
        onClick={(): void => setShowModal(true)}
        color={color}
      >
        {iconButton}
      </IconButton>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{ListTypeFilterLabel.get(listType)}</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to import this listing?</p>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            onClick={async (): Promise<void> => {
              const id = await processListing(
                importSource,
                listingId,
                listType
              );
              navigate(RouterPath.ListingSaleDetails.replace(":id", id));
              addAlert({
                message: "Home was successfully IMPORTED",
                variant: "success"
              });
              handleCloseModal();
            }}
          >
            Import
          </SubmitButton>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
