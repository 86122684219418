export class CookiesManager {
  public static getCookie<T>(name: string): T {
    const cookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith(name + "="));
    return cookie ? JSON.parse(cookie.split("=")[1]) : undefined;
  }

  public static setCookie<T = unknown>(name: string, value: T): void {
    if (!value) return;
    document.cookie = `${name}=${JSON.stringify(value)}`;
  }

  public static deleteCookie(name: string): void {
    const defaultExpiration = new Date(Date.UTC(1970, 0, 1));
    document.cookie = `${name}=; expires=${defaultExpiration.toUTCString()}`;
  }
}
