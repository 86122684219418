export enum LandTitleEvidence {
  Buyer = "buyer",
  Negotiable = "negotiable",
  Seller = "seller"
}
export const LandTitleEvidenceLabel = new Map<LandTitleEvidence, string>([
  [LandTitleEvidence.Buyer, "Buyer"],
  [LandTitleEvidence.Negotiable, "Negotiable"],
  [LandTitleEvidence.Seller, "Seller"]
]);
