import { useTheme } from "@mui/material";
import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  gaugeContainer: SxProps;
  indicatorsContainer: SxProps;
  paperAction: SxProps;
}

export const useStyles = (): Styles => {
  const { breakpoints } = useTheme();
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      rowGap: "24px",
      padding: "0 16px ",
      boxSizing: "border-box",
      height: "100%"
    },
    gaugeContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "24px",
      [breakpoints.down(1470)]: {
        gridTemplateColumns: "1fr"
      }
    },
    indicatorsContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: "16px"
    },
    paperAction: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  };
};
