import React from "react";

import { FormContext, FormRules, flattenRules } from "@homesusa/form";

export const useMlsStatusChange = <TMlsStatus>({
  rulesByStatus,
  statusSectionName = "statusFieldsInfo"
}: {
  rulesByStatus: Map<TMlsStatus, FormRules>;
  statusSectionName?: string;
}): ((newMlsStatus?: TMlsStatus) => void) => {
  const { formState, formDispatch, formRulesDispatch, formErrorsDispatch } =
    React.useContext(FormContext);

  const getStatusRules = (satus: TMlsStatus): FormRules => {
    const rules = rulesByStatus.get(satus);
    return rules != null ? flattenRules({ [statusSectionName]: rules }) : {};
  };

  const removeErrors = (): void => {
    if (!formErrorsDispatch) {
      return;
    }
    Object.keys(formState[statusSectionName]).forEach((field) => {
      formErrorsDispatch({
        type: "Remove",
        inputName: `${statusSectionName}.${field}`
      });
    });
  };

  const updateRules = (newMlsStatus: TMlsStatus): void => {
    if (!formRulesDispatch) {
      return;
    }

    Object.keys(formState[statusSectionName]).forEach((field) => {
      formRulesDispatch({
        type: "RemoveByInputName",
        inputName: `${statusSectionName}.${field}`
      });
    });

    formRulesDispatch({
      type: "UpdateRules",
      payload: getStatusRules(newMlsStatus)
    });
  };

  const handleMlsStatusDispatch = (newMlsStatus?: TMlsStatus): void => {
    if (!newMlsStatus) {
      return;
    }
    if (formDispatch) {
      formDispatch({
        type: "StatusChange",
        newMlsStatus
      });
    }
    updateRules(newMlsStatus);
    removeErrors();
  };
  return handleMlsStatusDispatch;
};
