export enum ListType {
  Residential = "re",
  Lot = "lot",
  Lease = "lease"
}

export const ListTypeLabel = new Map<ListType, string>([
  [ListType.Residential, "Residential"],
  [ListType.Lot, "Lot"],
  [ListType.Lease, "Lease"]
]);
