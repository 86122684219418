import { format } from "date-fns";

import {
  ChartFilters,
  StatusResponse,
  SubdivisionsResponse
} from "../interfaces";
import { firstDayOfCurrentYear, getDataByKey, getIntervalDate } from "../utils";
import { CalendarInterval, DateKey } from "../enums";

/* temp */

const getSubdivisions = async (): Promise<SubdivisionsResponse> => {
  const body = {
    size: 0,
    aggs: {
      subdivisions: {
        terms: {
          field: "subdivision_raw"
        }
      }
    }
  };
  const response = await fetch(
    "https://search-nelumbotest-ywbqoimohxpep5uyn4ebadsby4.us-east-2.es.amazonaws.com/listings/_search",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa("nelumbo:Nelumbo2022.")}`
      },
      body: JSON.stringify(body)
    }
  );
  return response.json();
};

const getDateFormat = (value?: Date): string | undefined => {
  return value ? format(value, "yyyy/MM/dd") : undefined;
};

const getStatusListings = async (
  params: ChartFilters
): Promise<StatusResponse> => {
  const interval = params.calendarInterval ?? CalendarInterval.Weekly;
  const {
    companyCode,
    subdivision,
    from = getDataByKey(DateKey.FirstDayOfCurrentYear, interval),
    to = getIntervalDate(new Date(), interval, true),
    exactMatch,
    calendarInterval = CalendarInterval.Weekly
  } = params;
  const fromFormat = getDateFormat(from);
  const toFormat = getDateFormat(to);
  let searchType: undefined[] | { term: { subdivision_raw: string } }[] = [];
  if (!!subdivision && exactMatch) {
    searchType = [{ term: { subdivision_raw: subdivision } }];
  }
  const body = {
    query: {
      bool: {
        filter: [
          ...searchType,
          { term: { Builder: companyCode } },
          { match_all: {} },
          {
            range: {
              Datecheck: {
                gte: fromFormat ?? firstDayOfCurrentYear(),
                ...(!!toFormat && { lte: toFormat })
              }
            }
          }
        ],
        ...(!!subdivision &&
          !exactMatch && {
            must: [
              {
                match: {
                  Subdivision: {
                    query: subdivision,
                    fuzziness: "AUTO"
                  }
                }
              }
            ]
          })
      }
    },
    size: 0,
    aggs: {
      listings_over_time: {
        date_histogram: {
          field: "Datecheck",

          format: "yyyy/MM/dd",

          calendar_interval: calendarInterval
        },

        aggs: {
          total_active: {
            sum: {
              field: "Active"
            }
          },

          total_pending: {
            sum: {
              field: "Pending"
            }
          },

          total_sold: {
            sum: {
              field: "Sold"
            }
          }
        }
      }
    }
  };

  const response = await fetch(
    "https://search-nelumbotest-ywbqoimohxpep5uyn4ebadsby4.us-east-2.es.amazonaws.com/listings/_search",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa("nelumbo:Nelumbo2022.")}`
      },
      body: JSON.stringify(body)
    }
  );

  return response.json();
};

export { getStatusListings, getSubdivisions };
