import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { FormCommunityRules } from "modules/communities/interfaces/form-community-rules.interface";

import {
  AccessRoadSurface,
  AccessRoadSurfaceLabel,
  AirQuality,
  AirQualityLabel,
  AppliancesEquipment,
  AppliancesEquipmentLabel,
  ConstructionExterior,
  ConstructionExteriorLabel,
  CoolingSystem,
  CoolingSystemLabel,
  EnergyFeatures,
  EnergyFeaturesLabel,
  ExteriorFeatures,
  ExteriorFeaturesLabel,
  Fencing,
  FencingLabel,
  FireplaceDescription,
  FireplaceDescriptionLabel,
  Floors,
  FloorsLabel,
  Foundation,
  FoundationLabel,
  GreenBuildingVerification,
  GreenBuildingVerificationLabel,
  GreenVerificationSource,
  GreenVerificationSourceLabel,
  HeatSystem,
  HeatSystemLabel,
  HousingStyle,
  HousingStyleLabel,
  Inclusions,
  InclusionsLabel,
  Kitchen,
  KitchenLabel,
  Laundry,
  LaundryLabel,
  MasterBedroom,
  MasterBedroomLabel,
  NeighborhoodAmenities,
  NeighborhoodAmenitiesLabel,
  Pool,
  PoolLabel,
  RestrictionsType,
  RestrictionsTypeLabel,
  RoofDescription,
  RoofDescriptionLabel,
  SprinklerSystemDescription,
  SprinklerSystemDescriptionLabel,
  SupplierOther,
  SupplierOtherLabel,
  TopoLandDescription,
  TopoLandDescriptionLabel,
  WaterAccessType,
  WaterAccessTypeLabel,
  WaterConservation,
  WaterConservationLabel,
  WaterFeatures,
  WaterFeaturesLabel,
  WaterSewer,
  WaterSewerLabel
} from "markets/ctx/enums";

export const utilitiesRules: FormRules | FormCommunityRules = {
  fireplaceDescription: {
    label: "Fireplace (MAX 19)",
    requiredToSubmit: true,
    lastOptions: [FireplaceDescription.None],
    options: getOptionsFromEnumAndMap(
      Object.values(FireplaceDescription),
      FireplaceDescriptionLabel
    ),
    rows: 2,
    max: 19,
    noneOption: [FireplaceDescription.None],
    requiredToCommunitySubmit: true
  },
  waterAccess: { label: "Water Access" },
  waterAccessType: {
    label: "Water Access Type",
    lastOptions: [WaterAccessType.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(WaterAccessType),
      WaterAccessTypeLabel
    )
  },
  waterFront: { label: "Waterfront" },
  waterFeatures: {
    label: "Water Features/View (MAX 18)",
    requiredToSubmit: true,
    lastOptions: [WaterFeatures.None, WaterFeatures.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(WaterFeatures),
      WaterFeaturesLabel
    ),
    max: 18
  },
  sprinklerSystem: { label: "Sprinkler System" },
  sprinklerSystemDescription: {
    label: "Sprinkler System Desc",
    lastOptions: [SprinklerSystemDescription.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(SprinklerSystemDescription),
      SprinklerSystemDescriptionLabel
    ),
    disabled: false,
    rows: 2
  },
  upgradedEnergyFeatures: { label: "Upgraded Energy Features" },
  eesFeatures: { label: "EES Features" },
  airQuality: {
    label: "Green Indoor Air Quality",
    lastOptions: [AirQuality.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(AirQuality),
      AirQualityLabel
    )
  },
  greenBuildingVerification: {
    label: "Green Building Verification",
    options: getOptionsFromEnumAndMap(
      Object.values(GreenBuildingVerification),
      GreenBuildingVerificationLabel
    )
  },
  energyFeatures: {
    label: "Green Energy Efficient",
    options: getOptionsFromEnumAndMap(
      Object.values(EnergyFeatures),
      EnergyFeaturesLabel
    )
  },
  greenVerificationSource: {
    label: "Green Verification Source",
    options: getOptionsFromEnumAndMap(
      Object.values(GreenVerificationSource),
      GreenVerificationSourceLabel
    )
  },
  waterConservation: {
    label: "Green Water Conservation",
    lastOptions: [WaterConservation.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(WaterConservation),
      WaterConservationLabel
    )
  },
  heatSystem: {
    label: "Heat",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(HeatSystem),
      HeatSystemLabel
    )
  },
  coolingSystem: {
    label: "A/C",
    requiredToSubmit: true,
    lastOptions: [CoolingSystem.OtherSeeRemarks, CoolingSystem.None],
    options: getOptionsFromEnumAndMap(
      Object.values(CoolingSystem),
      CoolingSystemLabel
    ),
    noneOption: [CoolingSystem.None]
  },
  waterSewer: {
    label: "Water/Sewer",
    requiredToSubmit: true,
    lastOptions: [WaterSewer.OtherWaterSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(WaterSewer),
      WaterSewerLabel
    )
  },
  supplierOther: {
    label: "Other Utilities",
    requiredToSubmit: true,
    lastOptions: [SupplierOther.None, SupplierOther.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(SupplierOther),
      SupplierOtherLabel
    ),
    noneOption: [SupplierOther.None]
  },
  floors: {
    label: "Flooring (MAX 6)",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Floors), FloorsLabel),
    max: 6
  },
  housingStyle: {
    label: "Style",
    requiredToSubmit: true,
    lastOptions: [HousingStyle.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(HousingStyle),
      HousingStyleLabel
    )
  },
  constructionExterior: {
    label: "Construction/Exterior (MAX 18)",
    requiredToSubmit: true,
    lastOptions: [
      ConstructionExterior.OtherSeeRemarks,
      ConstructionExterior.None
    ],
    options: getOptionsFromEnumAndMap(
      Object.values(ConstructionExterior),
      ConstructionExteriorLabel
    ),
    max: 18,
    noneOption: [ConstructionExterior.None]
  },
  roofDescription: {
    label: "Roof",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(RoofDescription),
      RoofDescriptionLabel
    )
  },
  foundation: {
    label: "Foundation",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Foundation),
      FoundationLabel
    )
  },
  fencing: {
    label: "Fencing",
    requiredToSubmit: true,
    lastOptions: [Fencing.OtherSeeRemarks, Fencing.None],
    options: getOptionsFromEnumAndMap(Object.values(Fencing), FencingLabel),
    noneOption: [Fencing.None]
  },
  gatedCommunity: { label: "Gated Community" },
  restrictionsType: {
    label: "Restrictions Type",
    lastOptions: [RestrictionsType.None],
    options: getOptionsFromEnumAndMap(
      Object.values(RestrictionsType),
      RestrictionsTypeLabel
    ),
    noneOption: [RestrictionsType.None]
  },
  pool: {
    label: "Pool",
    requiredToSubmit: true,
    lastOptions: [Pool.None],
    options: getOptionsFromEnumAndMap(Object.values(Pool), PoolLabel),
    rows: 2,
    noneOption: [Pool.None]
  },
  exteriorFeatures: {
    label: "Exterior Features",
    requiredToSubmit: true,
    lastOptions: [ExteriorFeatures.None],
    options: getOptionsFromEnumAndMap(
      Object.values(ExteriorFeatures),
      ExteriorFeaturesLabel
    ),
    noneOption: [ExteriorFeatures.None]
  },
  topoLandDescription: {
    label: "Topo/Land Desc",
    requiredToSubmit: true,
    lastOptions: [
      TopoLandDescription.OtherSeeRemarks,
      TopoLandDescription.None
    ],
    options: getOptionsFromEnumAndMap(
      Object.values(TopoLandDescription),
      TopoLandDescriptionLabel
    )
  },
  neighborhoodAmenities: {
    label: "Neighborhood Amenities (MAX 19)",
    requiredToSubmit: true,
    lastOptions: [NeighborhoodAmenities.None],
    options: getOptionsFromEnumAndMap(
      Object.values(NeighborhoodAmenities),
      NeighborhoodAmenitiesLabel
    ),
    max: 19,
    noneOption: [NeighborhoodAmenities.None]
  },
  accessRoadSurface: {
    label: "Access/Road Surface",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(AccessRoadSurface),
      AccessRoadSurfaceLabel
    )
  },
  inclusions: {
    label: "Interior Features (MAX 37)",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Inclusions),
      InclusionsLabel
    ),
    max: 37
  },
  lotDimension: { label: "Lot Dimensions" },
  lotSize: {
    label: "Apx Acreage",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 10
    },
    maxLength: 25,
    tooltip: "For less than one acre use a decimal - Example .25"
  },
  appliancesEquipment: {
    label: "Appliances/Equipment (MAX 13)",
    requiredToSubmit: true,
    lastOptions: [
      AppliancesEquipment.OtherSeeRemarks,
      AppliancesEquipment.None
    ],
    options: getOptionsFromEnumAndMap(
      Object.values(AppliancesEquipment),
      AppliancesEquipmentLabel
    ),
    max: 13,
    rows: 2,
    noneOption: [AppliancesEquipment.None]
  },
  kitchen: {
    label: "Kitchen",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Kitchen), KitchenLabel)
  },
  laundry: {
    label: "Laundry",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Laundry), LaundryLabel)
  },
  masterBedroom: {
    label: "Master Bedroom Desc",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(MasterBedroom),
      MasterBedroomLabel
    )
  }
};
