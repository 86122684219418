export enum HeatSystem {
  Unit1 = "unit1",
  Units2 = "units2",
  Units3 = "units3",
  Central = "central",
  Electric = "electric",
  Fireplace = "fireplace",
  HeatPump = "heatPump",
  NaturalGas = "naturalGas",
  PropaneButane = "propaneButane",
  Radiant = "radiant",
  Zoned = "zoned"
}

export const HeatSystemLabel = new Map<HeatSystem, string>([
  [HeatSystem.Unit1, "1 Unit"],
  [HeatSystem.Units2, "2 Units"],
  [HeatSystem.Units3, "3+ Units"],
  [HeatSystem.Central, "Central"],
  [HeatSystem.Electric, "Electric"],
  [HeatSystem.Fireplace, "Fireplace"],
  [HeatSystem.HeatPump, "Heat Pump"],
  [HeatSystem.NaturalGas, "Natural Gas"],
  [HeatSystem.PropaneButane, "Propane/Butane"],
  [HeatSystem.Radiant, "Radiant"],
  [HeatSystem.Zoned, "Zoned"]
]);
