export enum WaterfrontFeature {
  BayFront = "bayFront",
  BoatRamp = "boatRamp",
  Riverfront = "riverfront",
  Beachfront = "beachfront",
  BoatLift = "boatLift",
  RiverView = "riverView",
  BoatHouse = "boatHouse",
  Beachside = "beachside",
  Pond = "pond",
  GulfView = "gulfView",
  BoatSlip = "boatSlip",
  BayView = "bayView",
  BayouView = "bayouView",
  Lakefront = "lakefront",
  Pier = "pier",
  LakeView = "lakeView",
  BayouFrontage = "bayouFrontage",
  BeachView = "beachView",
  CanalFront = "canalFront",
  Bulkhead = "bulkhead"
}
export const WaterfrontFeatureLabel = new Map<WaterfrontFeature, string>([
  [WaterfrontFeature.BayFront, "Bay Front"],
  [WaterfrontFeature.BoatRamp, "Boat Ramp"],
  [WaterfrontFeature.Riverfront, "Riverfront"],
  [WaterfrontFeature.Beachfront, "Beachfront"],
  [WaterfrontFeature.BoatLift, "Boat Lift"],
  [WaterfrontFeature.RiverView, "River View"],
  [WaterfrontFeature.BoatHouse, "Boat House"],
  [WaterfrontFeature.Beachside, "Beachside"],
  [WaterfrontFeature.Pond, "Pond"],
  [WaterfrontFeature.GulfView, "Gulf View"],
  [WaterfrontFeature.BoatSlip, "Boat Slip"],
  [WaterfrontFeature.BayView, "Bay View"],
  [WaterfrontFeature.BayouView, "Bayou View"],
  [WaterfrontFeature.Lakefront, "Lakefront"],
  [WaterfrontFeature.Pier, "Pier"],
  [WaterfrontFeature.LakeView, "Lake View"],
  [WaterfrontFeature.BayouFrontage, "Bayou Frontage"],
  [WaterfrontFeature.BeachView, "Beach View"],
  [WaterfrontFeature.CanalFront, "Canal Front"],
  [WaterfrontFeature.Bulkhead, "Bulkhead"]
]);
