export enum PrimarySchool {
  Bishop = "bishop",
  Bonham = "bonham",
  Brashear = "brashear",
  Carthage = "carthage",
  Celina = "celina",
  ClayCounty = "clayCounty",
  Collinsville = "collinsville",
  Connally = "connally",
  CorriganCamden = "corriganCamden",
  Dobie = "dobie",
  DorisMcqueen = "dorisMcqueen",
  Dunbar = "dunbar",
  Early = "early",
  East = "east",
  Eustace = "eustace",
  Fabens = "fabens",
  Few = "few",
  Flourbluff = "flourbluff",
  Forney = "forney",
  Fredericks = "fredericks",
  Friona = "friona",
  Garrett = "garrett",
  Gayavenue = "gayavenue",
  Georgewest = "georgewest",
  Goodwin = "goodwin",
  Hackney = "hackney",
  Herty = "herty",
  Ingleside = "ingleside",
  Kemp = "kemp",
  Kurth = "kurth",
  Lakedallas = "lakedallas",
  Lamar = "lamar",
  LibertyEylau = "libertyEylau",
  Lindale = "lindale",
  Loading = "loading",
  Lonestar = "lonestar",
  Lorenzoloy = "lorenzoloy",
  Memorial = "memorial",
  Mineola = "mineola",
  Monday = "monday",
  Montgomery = "montgomery",
  NorthWard = "northWard",
  Northside = "northside",
  Pearce = "pearce",
  Pittsburg = "pittsburg",
  Rahe = "rahe",
  Risinger = "risinger",
  Robinson = "robinson",
  Rosebud = "rosebud",
  Rosemont = "rosemont",
  Rusk = "rusk",
  Scudder = "scudder",
  Sheffield = "sheffield",
  Shepherd = "shepherd",
  Southside = "southside",
  Springhill = "springhill",
  Stricklin = "stricklin",
  TaylorCo = "taylorCo",
  Trout = "trout",
  Tulosomidw = "tulosomidw",
  Westwood = "westwood",
  Whiteoak = "whiteoak",
  Willspoint = "willspoint",
  WylieWest = "wylieWest",
  Yoakum = "yoakum"
}

export const PrimarySchoolLabel: Record<PrimarySchool, string> = {
  [PrimarySchool.Bishop]: "Bishop",
  [PrimarySchool.Bonham]: "Bonham",
  [PrimarySchool.Brashear]: "Brashear",
  [PrimarySchool.Carthage]: "Carthage",
  [PrimarySchool.Celina]: "Celina",
  [PrimarySchool.ClayCounty]: "Clay County",
  [PrimarySchool.Collinsville]: "Collinsville",
  [PrimarySchool.Connally]: "Connally",
  [PrimarySchool.CorriganCamden]: "Corrigan-Camden",
  [PrimarySchool.Dobie]: "Dobie",
  [PrimarySchool.DorisMcqueen]: "Doris Mcqueen",
  [PrimarySchool.Dunbar]: "Dunbar",
  [PrimarySchool.Early]: "Early",
  [PrimarySchool.East]: "East",
  [PrimarySchool.Eustace]: "Eustace",
  [PrimarySchool.Fabens]: "Fabens",
  [PrimarySchool.Few]: "Few",
  [PrimarySchool.Flourbluff]: "Flourbluff",
  [PrimarySchool.Forney]: "Forney",
  [PrimarySchool.Fredericks]: "Fredericks",
  [PrimarySchool.Friona]: "Friona",
  [PrimarySchool.Garrett]: "Garrett",
  [PrimarySchool.Gayavenue]: "Gayavenue",
  [PrimarySchool.Georgewest]: "Georgewest",
  [PrimarySchool.Goodwin]: "Goodwin",
  [PrimarySchool.Hackney]: "Hackney",
  [PrimarySchool.Herty]: "Herty",
  [PrimarySchool.Ingleside]: "Ingleside",
  [PrimarySchool.Kemp]: "Kemp",
  [PrimarySchool.Kurth]: "Kurth",
  [PrimarySchool.Lakedallas]: "Lakedallas",
  [PrimarySchool.Lamar]: "Lamar",
  [PrimarySchool.LibertyEylau]: "Liberty-Eylau",
  [PrimarySchool.Lindale]: "Lindale",
  [PrimarySchool.Loading]: "Loading",
  [PrimarySchool.Lonestar]: "Lonestar",
  [PrimarySchool.Lorenzoloy]: "Lorenzoloy",
  [PrimarySchool.Memorial]: "Memorial",
  [PrimarySchool.Mineola]: "Mineola",
  [PrimarySchool.Monday]: "Monday",
  [PrimarySchool.Montgomery]: "Montgomery",
  [PrimarySchool.NorthWard]: "North Ward",
  [PrimarySchool.Northside]: "Northside",
  [PrimarySchool.Pearce]: "Pearce",
  [PrimarySchool.Pittsburg]: "Pittsburg",
  [PrimarySchool.Rahe]: "Rahe",
  [PrimarySchool.Risinger]: "Risinger",
  [PrimarySchool.Robinson]: "Robinson",
  [PrimarySchool.Rosebud]: "Rosebud",
  [PrimarySchool.Rosemont]: "Rosemont",
  [PrimarySchool.Rusk]: "Rusk",
  [PrimarySchool.Scudder]: "Scudder",
  [PrimarySchool.Sheffield]: "Sheffield",
  [PrimarySchool.Shepherd]: "Shepherd",
  [PrimarySchool.Southside]: "Southside",
  [PrimarySchool.Springhill]: "Springhill",
  [PrimarySchool.Stricklin]: "Stricklin",
  [PrimarySchool.TaylorCo]: "Taylor Co.",
  [PrimarySchool.Trout]: "Trout",
  [PrimarySchool.Tulosomidw]: "Tulosomidw",
  [PrimarySchool.Westwood]: "Westwood",
  [PrimarySchool.Whiteoak]: "Whiteoak",
  [PrimarySchool.Willspoint]: "Willspoint",
  [PrimarySchool.WylieWest]: "Wylie West",
  [PrimarySchool.Yoakum]: "Yoakum"
};
