export enum ShowingRequirements {
  AgentOrOwnerPresent = "agentOrOwnerPresent",
  AppointmentOnly = "appointmentOnly",
  CallFirst = "callFirst",
  Go = "go",
  Lockbox = "lockbox",
  KeyInOffice = "keyInOffice",
  KeyWithOwner = "keyWithOwner",
  CallOwner = "callOwner",
  SecuritySystem = "securitySystem",
  SeeShowingInstructions = "seeShowingInstructions",
  ShowingService = "showingService"
}

export const ShowingRequirementsLabel = new Map<ShowingRequirements, string>([
  [ShowingRequirements.AgentOrOwnerPresent, "Agent or Owner Present"],
  [ShowingRequirements.AppointmentOnly, "Appointment Only"],
  [ShowingRequirements.CallOwner, "Call Owner"],
  [ShowingRequirements.CallFirst, "CALL-1ST"],
  [ShowingRequirements.Go, "Go"],
  [ShowingRequirements.KeyInOffice, "Key In Office"],
  [ShowingRequirements.KeyWithOwner, "Key with Owner"],
  [ShowingRequirements.Lockbox, "Lockbox"],
  [ShowingRequirements.SecuritySystem, "Security System"],
  [ShowingRequirements.SeeShowingInstructions, "See Showing Instructions"],
  [ShowingRequirements.ShowingService, "Showing Service"]
]);
