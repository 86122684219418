export enum RoomType {
  Bathroom = "bathroom",
  Bedroom = "bedroom",
  BedroomII = "bedroomII",
  BedroomIII = "bedroomIII",
  BedroomIV = "bedroomIV",
  Bonus = "bonus",
  Breakfast = "breakfast",
  Dining = "dining",
  EntryFoyer = "entryFoyer",
  Family = "family",
  Game = "game",
  Great = "great",
  GuestHouse = "guestHouse",
  GymExercise = "gymExercise",
  JackNJill = "jackNJill",
  Kitchen = "kitchen",
  LibraryDen = "libraryDen",
  LivingRoom = "livingRoom",
  LivingRoomII = "livingRoomII",
  Loft = "loft",
  MasterBath = "masterBath",
  MasterBathII = "masterBathII",
  MasterBedroom = "masterBedroom",
  MasterBedroomII = "masterBedroomII",
  MediaHomeTheatre = "mediaHomeTheatre",
  OfficeStudy = "officeStudy",
  Other = "other",
  OtherRoom = "otherRoom",
  OtherRoomII = "otherRoomII",
  OtherRoomIII = "otherRoomIII",
  SaunaRoom = "saunaRoom",
  UtilityLaundry = "utilityLaundry",
  WineCellar = "wineCellar"
}

export const RoomTypeLabel = new Map<RoomType, string>([
  [RoomType.Bathroom, "Bathroom"],
  [RoomType.Bedroom, "Bedroom"],
  [RoomType.BedroomII, "Bedroom II"],
  [RoomType.BedroomIII, "Bedroom III"],
  [RoomType.BedroomIV, "Bedroom IV"],
  [RoomType.Bonus, "Bonus"],
  [RoomType.Breakfast, "Breakfast"],
  [RoomType.Dining, "Dining"],
  [RoomType.EntryFoyer, "Entry/Foyer"],
  [RoomType.Family, "Family"],
  [RoomType.Game, "Game"],
  [RoomType.Great, "Great"],
  [RoomType.GuestHouse, "Guest House"],
  [RoomType.GymExercise, "Gym/Exercise"],
  [RoomType.JackNJill, "Jack-N-Jill"],
  [RoomType.Kitchen, "Kitchen"],
  [RoomType.LibraryDen, "Library/Den"],
  [RoomType.LivingRoom, "Living Room"],
  [RoomType.LivingRoomII, "Living Room II"],
  [RoomType.Loft, "Loft"],
  [RoomType.MasterBath, "Master Bath"],
  [RoomType.MasterBathII, "Master Bath II"],
  [RoomType.MasterBedroom, "Master Bedroom"],
  [RoomType.MasterBedroomII, "Master Bedroom II"],
  [RoomType.MediaHomeTheatre, "Media/Home Theatre"],
  [RoomType.OfficeStudy, "Office/Study"],
  [RoomType.Other, "Other"],
  [RoomType.OtherRoom, "Other Room"],
  [RoomType.OtherRoomII, "Other Room II"],
  [RoomType.OtherRoomIII, "Other Room III"],
  [RoomType.SaunaRoom, "Sauna Room"],
  [RoomType.UtilityLaundry, "Utility/Laundry "],
  [RoomType.WineCellar, "Wine/Cellar"]
]);

export const RoomTypeQuantity = new Map<RoomType, number>([
  [RoomType.LivingRoom, 2],
  [RoomType.UtilityLaundry, 2]
]);
