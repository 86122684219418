import { MarketCode } from "@homesusa/core";

export const minMediaMarket: Record<MarketCode, number> = {
  [MarketCode.Sabor]: 4,
  [MarketCode.CTX]: 2,
  [MarketCode.Austin]: 4,
  [MarketCode.Houston]: 4,
  [MarketCode.DFW]: 1,
  [MarketCode.Amarillo]: 1
};
