import { CommissionType } from "@homesusa/core";

const percentMessageError = "Must be 1% to 8%";
const percentRule = {
  maxLength: 6,
  replaceRegex: "[a-zA-Z-]+",
  numberFormat: {
    min: 1,
    max: 8,
    thousandSeparator: false,
    prefix: undefined,
    decimalScale: 3,
    suffix: "%"
  },
  customFieldProps: {
    errorMessages: {
      max: percentMessageError,
      min: percentMessageError
    }
  }
};
const amountRule = {
  maxLength: 10,
  replaceRegex: "[a-zA-Z-]+",
  numberFormat: {
    min: 1000,
    thousandSeparator: true,
    prefix: "$",
    max: 30000,
    suffix: undefined,
    decimalScale: 2
  },
  customFieldProps: undefined
};

export const commissionRules = (
  commisionType?: string
): Record<string, unknown> => {
  if (!commisionType) {
    return {};
  }
  return commisionType === CommissionType.Percent ? percentRule : amountRule;
};

export const buyersCommissionRules = (
  commisionType?: CommissionType | string
): Record<string, unknown> => {
  const percentMessageError = "Value must be within 1% to 8%";
  if (commisionType === CommissionType.Percent) {
    return {
      ...percentRule,
      customFieldProps: {
        errorMessages: {
          max: percentMessageError,
          min: percentMessageError
        }
      }
    };
  }
  return amountRule;
};
