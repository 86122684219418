export enum SellerType {
  Builder = "B",
  Foreclosure = "F",
  Owner = "O",
  Relocation = "R"
}

export const SellerTypeLabel = new Map<SellerType, string>([
  [SellerType.Builder, "Builder"],
  [SellerType.Foreclosure, "Foreclosure"],
  [SellerType.Owner, "Owner"],
  [SellerType.Relocation, "Relocation"]
]);
