import axios from "axios";

import { MarketCode } from "@homesusa/core";
import {
  NewHome,
  Listing,
  RealtorOffice,
  ListingAgent,
  ReportDataset
} from "../interfaces";
import { ListFilter, AddressFilter, AgentFilter } from "../interfaces/filters";
import { DownloaderService } from "../interfaces/services";
import { getDownloaderUrl } from "core/services/downloader.service";

const DownloaderServices = (market: MarketCode): DownloaderService => {
  const apiUrl = `${getDownloaderUrl(market)}/mls-reports`;

  return {
    getNewHomes: async (
      params: ListFilter
    ): Promise<ReportDataset<NewHome>> => {
      return axios
        .get<ReportDataset<NewHome>>(`${apiUrl}/new-homes`, {
          params
        })
        .then((response) => response.data);
    },
    getListings: async (
      params: ListFilter
    ): Promise<ReportDataset<Listing>> => {
      return axios
        .get<ReportDataset<Listing>>(`${apiUrl}/listings`, {
          params
        })
        .then((response) => response.data);
    },
    getRealtorOffices: async (
      params: AddressFilter
    ): Promise<ReportDataset<RealtorOffice>> => {
      return axios
        .get<ReportDataset<RealtorOffice>>(`${apiUrl}/realtor-offices`, {
          params
        })
        .then((response) => response.data);
    },
    getListingAgents: async (
      params: AgentFilter
    ): Promise<ReportDataset<ListingAgent>> => {
      return axios
        .get<ReportDataset<ListingAgent>>(`${apiUrl}/listing-agents`, {
          params
        })
        .then((response) => response.data);
    }
  };
};

export default DownloaderServices;
