export enum RoomType {
  PrimaryBedroom = "primaryBedroom",
  PrimaryBath = "primaryBath",
  Bedroom = "bedroom",
  BreakfastRoom = "breakfast",
  Den = "den",
  DiningRoom = "diningRoom",
  ExtraRoom = "extraRoom",
  GameRoom = "gameRoom",
  Kitchen = "kitchen",
  LivingRoom = "livingRoom",
  MediaRoom = "mediaRoom",
  Library = "library",
  UtilityRoom = "utility",
  HomeOfficeStudy = "homeOfficeStudy"
}

export const RoomTypeLabel = new Map<RoomType, string>([
  [RoomType.PrimaryBedroom, "Primary Bed"],
  [RoomType.PrimaryBath, "Primary Bath"],
  [RoomType.Bedroom, "Bedroom"],
  [RoomType.BreakfastRoom, "Breakfast"],
  [RoomType.Den, "Den"],
  [RoomType.DiningRoom, "Dining Room"],
  [RoomType.ExtraRoom, "Extra Room"],
  [RoomType.GameRoom, "Gameroom"],
  [RoomType.Kitchen, "Kitchen"],
  [RoomType.LivingRoom, "Living Room"],
  [RoomType.MediaRoom, "Media Room"],
  [RoomType.UtilityRoom, "Utility"],
  [RoomType.Library, "Library"],
  [RoomType.HomeOfficeStudy, "Home Office/Study"]
]);

export const RoomTypeQuantity = new Map<RoomType, number>([
  [RoomType.LivingRoom, 2],
  [RoomType.UtilityRoom, 2],
  [RoomType.Bedroom, 6]
]);
