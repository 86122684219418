export enum Accessibility {
  AccessibleEntrance = "accessibleEntrance",
  ApproachWithRamp = "approachWithRamp",
  Bedroom = "bedroom",
  Doors = "doors",
  ElectricalAndEnvControls = "electricalAndEnvControls",
  ElevatorInstalled = "elevatorInstalled",
  FullBath = "fullBath",
  Grip = "grip",
  Hallways = "hallways",
  HearingImpairment = "hearingImpairment",
  Kitchen = "kitchen",
  MeetsADARequirements = "meetsADARequirements",
  SmartTechnology = "smartTechnology",
  StairLif = "stairLif",
  WheelchairAccessible = "wheelchairAccessible"
}

export const AccessibilityLabel = new Map<Accessibility, string>([
  [Accessibility.AccessibleEntrance, "Accessible Entrance"],
  [Accessibility.ApproachWithRamp, "Approach with Ramp"],
  [Accessibility.Bedroom, "Bedroom"],
  [Accessibility.Doors, "Doors"],
  [
    Accessibility.ElectricalAndEnvControls,
    "Electrical and Environmental Controls"
  ],
  [Accessibility.ElevatorInstalled, "Elevator Installed"],
  [Accessibility.FullBath, "Full Bath"],
  [Accessibility.Grip, "Grip"],
  [Accessibility.Hallways, "Hallway(s)"],
  [Accessibility.HearingImpairment, "Hearing-Impairment"],
  [Accessibility.Kitchen, "Kitchen"],
  [Accessibility.MeetsADARequirements, "Meets ADA Requirements"],
  [Accessibility.SmartTechnology, "Smart Technology"],
  [Accessibility.StairLif, "Stair Lif"],
  [Accessibility.WheelchairAccessible, "Wheelchair Accessible"]
]);
