export enum HomeFaces {
  East = "east",
  North = "north",
  South = "south",
  West = "west"
}
export const HomeFacesLabel = new Map<HomeFaces, string>([
  [HomeFaces.East, "East"],
  [HomeFaces.North, "North"],
  [HomeFaces.South, "South"],
  [HomeFaces.West, "West"]
]);
