import React from "react";

import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  ShowingInstructions,
  ShowingInstructionsLabel
} from "markets/sabor/enums";

export const showingRules: FormRules = {
  directions: {
    requiredToSubmit: true,
    label: "Public Driving Directions",
    regex: '^[^"|\\/]+$',
    regexMessage: 'Not allowed chars "  | \\ /',
    maxLength: 255,
    minLength: 25,
    replaceRegex: '["\\/|]+',
    rows: 5
  },
  showing: {
    label: "Showing Instructions Type",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(ShowingInstructions),
      ShowingInstructionsLabel
    )
  },
  altPhoneCommunity: {
    label: "Owner Alt Phone",
    regex: "^\\d{10}$",
    regexMessage: "invalid phone"
  },
  agentListApptPhone: {
    requiredToSubmit: true,
    regex: "^\\d{10}$",
    regexMessage: "invalid phone",
    label: "Appt. Phone"
  },
  realtorContactEmail: {
    label: "Email for realtors to contact you",
    tooltip: (
      <p>
        Separate multiple email addresses with a semicolon <br />
        (i.e., john.smith@sample.com;jane.doe@sample.com).
      </p>
    ),
    isMultiple: true
  }
};
