export enum StreetType {
  Springs = "springs",
  Shore = "shore",
  Cliff = "cliff",
  Mews = "mews",
  Lane = "lane",
  Viaduct = "viaduct",
  Branch = "branch",
  Junction = "junction",
  Bluff = "bluff",
  Way = "way",
  Glen = "glen",
  Highway = "highway",
  Valley = "valley",
  Crossing = "crossing",
  Corner = "corner",
  View = "view",
  Parkway = "parkway",
  Street = "street",
  Avenue = "avenue",
  Road = "road",
  Square = "square",
  Spur = "spur",
  Green = "green",
  Path = "path",
  Court = "court",
  Park = "park",
  Knoll = "knoll",
  ByPass = "by",
  Pike = "pike",
  Manor = "manor",
  Expressway = "expressway",
  Boulevard = "boulevard",
  Grove = "grove",
  Loop = "loop",
  Pointe = "pointe",
  Ville = "ville",
  Extension = "extension",
  Heights = "heights",
  Plaza = "plaza",
  Landing = "landing",
  Row = "row",
  Run = "run",
  Circle = "circle",
  Drive = "drive",
  Bend = "bend",
  Hollow = "hollow",
  Cove = "cove",
  Hill = "hill",
  Trail = "trail",
  Trace = "trace",
  Place = "place",
  Alley = "alley",
  Walk = "walk",
  Gardens = "gardens",
  Creek = "creek",
  Meadows = "meadows",
  Village = "village",
  Vista = "vista",
  Ridge = "ridge",
  Terrace = "terrace",
  Pass = "pass",
  Point = "point",
  Crest = "crest",
  Freeway = "freeway",
  None = "none",
  Pathway = "pathway"
}
export const StreetTypeLabel = new Map<StreetType, string>([
  [StreetType.Springs, "Springs"],
  [StreetType.Shore, "Shore"],
  [StreetType.Cliff, "Cliff"],
  [StreetType.Mews, "Mews"],
  [StreetType.Lane, "Lane"],
  [StreetType.Viaduct, "Viaduct"],
  [StreetType.Branch, "Branch"],
  [StreetType.Junction, "Junction"],
  [StreetType.Bluff, "Bluff"],
  [StreetType.Way, "Way"],
  [StreetType.Glen, "Glen"],
  [StreetType.Highway, "Highway"],
  [StreetType.Valley, "Valley"],
  [StreetType.Crossing, "Crossing"],
  [StreetType.Corner, "Corner"],
  [StreetType.View, "View"],
  [StreetType.Parkway, "Parkway"],
  [StreetType.Street, "Street"],
  [StreetType.Avenue, "Avenue"],
  [StreetType.Road, "Road"],
  [StreetType.Square, "Square"],
  [StreetType.Spur, "Spur"],
  [StreetType.Green, "Green"],
  [StreetType.Path, "Path"],
  [StreetType.Court, "Court"],
  [StreetType.Park, "Park"],
  [StreetType.Knoll, "Knoll"],
  [StreetType.ByPass, "ByPass"],
  [StreetType.Pike, "Pike"],
  [StreetType.Manor, "Manor"],
  [StreetType.Expressway, "Expressway"],
  [StreetType.Boulevard, "Boulevard"],
  [StreetType.Grove, "Grove"],
  [StreetType.Loop, "Loop"],
  [StreetType.Pointe, "Pointe"],
  [StreetType.Ville, "Ville"],
  [StreetType.Extension, "Extension"],
  [StreetType.Heights, "Heights"],
  [StreetType.Plaza, "Plaza"],
  [StreetType.Landing, "Landing"],
  [StreetType.Row, "Row"],
  [StreetType.Run, "Run"],
  [StreetType.Circle, "Circle"],
  [StreetType.Drive, "Drive"],
  [StreetType.Bend, "Bend"],
  [StreetType.Hollow, "Hollow"],
  [StreetType.Cove, "Cove"],
  [StreetType.Hill, "Hill"],
  [StreetType.Trail, "Trail"],
  [StreetType.Trace, "Trace"],
  [StreetType.Place, "Place"],
  [StreetType.Alley, "Alley"],
  [StreetType.Walk, "Walk"],
  [StreetType.Gardens, "Gardens"],
  [StreetType.Creek, "Creek"],
  [StreetType.Meadows, "Meadows"],
  [StreetType.Village, "Village"],
  [StreetType.Vista, "Vista"],
  [StreetType.Ridge, "Ridge"],
  [StreetType.Terrace, "Terrace"],
  [StreetType.Pass, "Pass"],
  [StreetType.Point, "Point"],
  [StreetType.Crest, "Crest"],
  [StreetType.Freeway, "Freeway"],
  [StreetType.None, "None"],
  [StreetType.Pathway, "Pathway"]
]);
