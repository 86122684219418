export enum WaterfrontFeatures {
  CanalFront = "canalFront",
  Creek = "creek",
  LakeFront = "lakeFront",
  LakePrivileges = "lakePrivileges",
  None = "none",
  Pond = "pond",
  RiverFront = "riverFront",
  WaterFront = "waterFront"
}
export const WaterfrontFeaturesLabel = new Map<WaterfrontFeatures, string>([
  [WaterfrontFeatures.CanalFront, "Canal Front"],
  [WaterfrontFeatures.Creek, "Creek"],
  [WaterfrontFeatures.LakeFront, "Lake Front"],
  [WaterfrontFeatures.LakePrivileges, "Lake Privileges"],
  [WaterfrontFeatures.None, "None"],
  [WaterfrontFeatures.Pond, "Pond"],
  [WaterfrontFeatures.RiverFront, "River Front"],
  [WaterfrontFeatures.WaterFront, "Water Front"]
]);
