export enum StreetDirPrefix {
  East = "east",
  North = "north",
  NorthEast = "northEast",
  NorthWest = "northWest",
  South = "south",
  SouthEast = "southEast",
  SouthWest = "southWest",
  West = "west"
}
export const StreetDirPrefixLabel = new Map<StreetDirPrefix, string>([
  [StreetDirPrefix.East, "East"],
  [StreetDirPrefix.North, "North"],
  [StreetDirPrefix.NorthEast, "North-East"],
  [StreetDirPrefix.NorthWest, "North-West"],
  [StreetDirPrefix.South, "South"],
  [StreetDirPrefix.SouthEast, "South-East"],
  [StreetDirPrefix.SouthWest, "South-West"],
  [StreetDirPrefix.West, "West"]
]);
