export enum HighSchool {
  CallisburgHighSchool = "callisburgHighSchool",
  WisdomHighSchool = "wisdomHighSchool",
  BoydHighSchool = "boydHighSchool",
  SonoraHighSchool = "sonoraHighSchool",
  LatexoHighSchool = "latexoHighSchool",
  NorthShoreSeniorHighSchool = "northShoreSeniorHighSchool",
  PilotPointHighSchool = "pilotPointHighSchool",
  CushingSchool = "cushingSchool",
  PermianHighSchool = "permianHighSchool",
  AledoHighSchool = "aledoHighSchool",
  EdgewoodHighSchool = "edgewoodHighSchool",
  NacogdochesHighSchool = "nacogdochesHighSchool",
  MabankHighSchool = "mabankHighSchool",
  KauferHighSchool = "kauferHighSchool",
  LaPryorHighSchool = "laPryorHighSchool",
  BelAirHighSchool = "belAirHighSchool",
  NoviceSchool = "noviceSchool",
  EarlySpecialProgram = "earlySpecialProgram",
  LoweryFreshmanCenter = "loweryFreshmanCenter",
  BoswellHighSchool = "boswellHighSchool",
  BartlettSchools = "bartlettSchools",
  OakwoodHighSchool = "oakwoodHighSchool",
  MccallumHighSchool = "mccallumHighSchool",
  MansfieldHighSchool = "mansfieldHighSchool",
  BrookesmithHighSchool = "brookesmithHighSchool",
  ComancheHighSchool = "comancheHighSchool",
  NimitzHighSchoolIrving = "nimitzHighSchoolIrving",
  RaymondvilleHighSchool = "raymondvilleHighSchool",
  DavisHighSchoolAldine = "davisHighSchoolAldine",
  ColumbusHighSchool = "columbusHighSchool",
  CoahomaHighSchool = "coahomaHighSchool",
  BayCityHighSchool = "bayCityHighSchool",
  MemorialHighSchoolPortArthur = "memorialHighSchoolPortArthur",
  SpearmanHighSchool = "spearmanHighSchool",
  NorthsideHighSchool = "northsideHighSchool",
  WestHardinHighSchool = "westHardinHighSchool",
  InglesideHighSchool = "inglesideHighSchool",
  MissionHighSchool = "missionHighSchool",
  HoweHighSchool = "howeHighSchool",
  HirschiHighSchool = "hirschiHighSchool",
  HoneyGroveHighSchool = "honeyGroveHighSchool",
  LibertyHillHighSchool = "libertyHillHighSchool",
  HallettsvilleHighSchool = "hallettsvilleHighSchool",
  AltoHighSchool = "altoHighSchool",
  SterlingHighSchoolGooseCreek = "sterlingHighSchoolGooseCreek",
  WhitharralSchool = "whitharralSchool",
  WeimarHighSchool = "weimarHighSchool",
  WestHighSchool = "westHighSchool",
  SabineHighSchool = "sabineHighSchool",
  BridgelandHighSchool = "bridgelandHighSchool",
  BrackenridgeHighSchool = "brackenridgeHighSchool",
  DeLeonHighSchool = "deLeonHighSchool",
  SplendoraHighSchool = "splendoraHighSchool",
  LosFresnosUnited = "losFresnosUnited",
  SharpstownHighSchool = "sharpstownHighSchool",
  ValleyViewHighSchool = "valleyViewHighSchool",
  FairviewAcceleratedBronte = "fairviewAcceleratedBronte",
  RouseHighSchool = "rouseHighSchool",
  GooseCreekMemorial = "gooseCreekMemorial",
  AustinHighSchoolElPaso = "austinHighSchoolElPaso",
  GrandSalineHighSchool = "grandSalineHighSchool",
  EasternHillsHighSchool = "easternHillsHighSchool",
  MelissaHighSchool = "melissaHighSchool",
  NorthHopkinsHighSchool = "northHopkinsHighSchool",
  ShadowCreekHighSchool = "shadowCreekHighSchool",
  McdadeHighSchool = "mcdadeHighSchool",
  EastsideHighSchool = "eastsideHighSchool",
  OglesbySchool = "oglesbySchool",
  LeverettsChapelHighSchool = "leverettsChapelHighSchool",
  LeforsSchool = "leforsSchool",
  HoustonHighSchool = "houstonHighSchool",
  VernonHighSchool = "vernonHighSchool",
  SanElizarioHighSchool = "sanElizarioHighSchool",
  KnippaSchool = "knippaSchool",
  ShallowaterHighSchool = "shallowaterHighSchool",
  HempsteadHighSchool = "hempsteadHighSchool",
  WaltripHighSchool = "waltripHighSchool",
  VegaHighSchool = "vegaHighSchool",
  HudsonHighSchool = "hudsonHighSchool",
  ColemanHighSchool = "colemanHighSchool",
  CorriganCamdenHighSchool = "corriganCamdenHighSchool",
  WhiteDeerHighSchool = "whiteDeerHighSchool",
  LovejoyHighSchool = "lovejoyHighSchool",
  KarnackHighSchool = "karnackHighSchool",
  DawsonSchool = "dawsonSchool",
  BlueRidgeHighSchool = "blueRidgeHighSchool",
  BosquevilleSchoolSecondarySchool = "bosquevilleSchoolSecondarySchool",
  BrownfieldHighSchool = "brownfieldHighSchool",
  SundownHighSchool = "sundownHighSchool",
  GodleyHighSchool = "godleyHighSchool",
  MildredHighSchool = "mildredHighSchool",
  DublinHighSchool = "dublinHighSchool",
  BrackettHighSchool = "brackettHighSchool",
  JasperHighSchoolPlano = "jasperHighSchoolPlano",
  DavidWCarterHighSchool = "davidWCarterHighSchool",
  ForneyHighSchool = "forneyHighSchool",
  WaskomHighSchool = "waskomHighSchool",
  LamarHighSchoolArlington = "lamarHighSchoolArlington",
  SlidellJHHighSchool = "slidellJHHighSchool",
  VeteransMemorialHighSchoolBrownsville = "veteransMemorialHighSchoolBrownsville",
  JohnHGuyerHighSchool = "johnHGuyerHighSchool",
  AlvordHighSchool = "alvordHighSchool",
  MansfieldLegacyHighSchool = "mansfieldLegacyHighSchool",
  EastBernardHighSchool = "eastBernardHighSchool",
  VanZandtSsa = "vanZandtSsa",
  SeminoleHighSchool = "seminoleHighSchool",
  HannaHighSchool = "hannaHighSchool",
  SachseHighSchool = "sachseHighSchool",
  WestRuskHighSchool = "westRuskHighSchool",
  RandallHighSchool = "randallHighSchool",
  NorthGarlandHighSchool = "northGarlandHighSchool",
  ChirenoHighSchool = "chirenoHighSchool",
  TompkinsHighSchool = "tompkinsHighSchool",
  WestwoodHighSchoolWestwood = "westwoodHighSchoolWestwood",
  CreekviewHighSchool = "creekviewHighSchool",
  WarrenHighSchoolWarren = "warrenHighSchoolWarren",
  IngramTomMooreSecondarySchool = "ingramTomMooreSecondarySchool",
  RayHighSchool = "rayHighSchool",
  DibollHighSchool = "dibollHighSchool",
  SanBenitoHighSchool = "sanBenitoHighSchool",
  DimmittHighSchool = "dimmittHighSchool",
  BridgeportHighSchool = "bridgeportHighSchool",
  BrazosportHighSchool = "brazosportHighSchool",
  StaffordHighSchool = "staffordHighSchool",
  PanhandleHighSchool = "panhandleHighSchool",
  CantonHighSchool = "cantonHighSchool",
  UnionHillHighSchool = "unionHillHighSchool",
  ShamrockHighSchool = "shamrockHighSchool",
  SlocumHighSchool = "slocumHighSchool",
  BigSpringHighSchool = "bigSpringHighSchool",
  MalakoffHighSchool = "malakoffHighSchool",
  StantonHighSchool = "stantonHighSchool",
  LouiseHighSchool = "louiseHighSchool",
  EulaHighSchool = "eulaHighSchool",
  TexlineSchool = "texlineSchool",
  EraSchool = "eraSchool",
  HondoHighSchool = "hondoHighSchool",
  FairviewAcceleratedChristoval = "fairviewAcceleratedChristoval",
  SeymourHighSchool = "seymourHighSchool",
  AcademyHighSchool = "academyHighSchool",
  PoteetHighSchoolPoteet = "poteetHighSchoolPoteet",
  PettusSecondarySchool = "pettusSecondarySchool",
  AvingerSchool = "avingerSchool",
  StockdaleHighSchool = "stockdaleHighSchool",
  MansfieldLakeRidgeHighSchool = "mansfieldLakeRidgeHighSchool",
  TrentonHighSchool = "trentonHighSchool",
  McmullenCountySchool = "mcmullenCountySchool",
  CollegeStationHighSchool = "collegeStationHighSchool",
  GoldthwaiteHighSchool = "goldthwaiteHighSchool",
  CleburneHighSchool = "cleburneHighSchool",
  WeslacoHighSchool = "weslacoHighSchool",
  TidehavenHighSchool = "tidehavenHighSchool",
  MadisonvilleHighSchool = "madisonvilleHighSchool",
  RobertELeeHighSchool = "robertELeeHighSchool",
  PlainsHighSchool = "plainsHighSchool",
  NewDealHighSchool = "newDealHighSchool",
  DelRioHighSchool = "delRioHighSchool",
  VirginiaAllredStaceyJuniorSeniorHighSchool = "virginiaAllredStaceyJuniorSeniorHighSchool",
  BigSandySchool = "bigSandySchool",
  CollinsvilleHighSchool = "collinsvilleHighSchool",
  FarmersvilleHighSchool = "farmersvilleHighSchool",
  RandolphHighSchool = "randolphHighSchool",
  LohnSchool = "lohnSchool",
  EastlandCareCampus = "eastlandCareCampus",
  RogersHighSchool = "rogersHighSchool",
  ClaudeJrSrHighSchool = "claudeJrSrHighSchool",
  BrandeisHighSchool = "brandeisHighSchool",
  NormangeeHighSchool = "normangeeHighSchool",
  CarrollSeniorHighSchool = "carrollSeniorHighSchool",
  PaloDuroHS = "paloDuroHS",
  SandsCisd = "sandsCisd",
  WestSabineHighSchool = "westSabineHighSchool",
  ElCampoHighSchool = "elCampoHighSchool",
  CueroHighSchool = "cueroHighSchool",
  JohnTylerHighSchool = "johnTylerHighSchool",
  ChannelviewHighSchool = "channelviewHighSchool",
  WilliamsonCoAcademy = "williamsonCoAcademy",
  MeadowSchool = "meadowSchool",
  TrinidadSchool = "trinidadSchool",
  FollettSchool = "follettSchool",
  GradySchool = "gradySchool",
  IndustrialHighSchool = "industrialHighSchool",
  LincolnHumanitiesCommunicationsMagnetHighSchool = "lincolnHumanitiesCommunicationsMagnetHighSchool",
  JordanHighSchool = "jordanHighSchool",
  GarlandHighSchool = "garlandHighSchool",
  WestBrookHighSchool = "westBrookHighSchool",
  SantaFeHighSchool = "santaFeHighSchool",
  LaMarqueHighSchool = "laMarqueHighSchool",
  KeltonSchool = "keltonSchool",
  MineralWellsHighSchool = "mineralWellsHighSchool",
  MillsapHighSchool = "millsapHighSchool",
  SanPerlitaHighSchool = "sanPerlitaHighSchool",
  StonyPointHighSchool = "stonyPointHighSchool",
  RiverRoadHighSchool = "riverRoadHighSchool",
  WaelderSchool = "waelderSchool",
  JerseyVillageHighSchool = "jerseyVillageHighSchool",
  RedwaterHighSchool = "redwaterHighSchool",
  RotanHighSchool = "rotanHighSchool",
  WheatleyHighSchool = "wheatleyHighSchool",
  PostHighSchool = "postHighSchool",
  OlneyHighSchool = "olneyHighSchool",
  OConnorHighSchool = "oConnorHighSchool",
  WestburyHighSchool = "westburyHighSchool",
  NazarethSchool = "nazarethSchool",
  HaysHighSchool = "haysHighSchool",
  MacarthurHighSchoolAldine = "macarthurHighSchoolAldine",
  SidneySchool = "sidneySchool",
  LasaraMiddleHighSchool = "lasaraMiddleHighSchool",
  JeffersonHighSchoolSanAntonio = "jeffersonHighSchoolSanAntonio",
  JonesboroSchool = "jonesboroSchool",
  MidwayHighSchool = "midwayHighSchool",
  ComoPicktonSchool = "comoPicktonSchool",
  PremontHighSchool = "premontHighSchool",
  LakeviewCentennialHighSchool = "lakeviewCentennialHighSchool",
  ConroeHighSchool = "conroeHighSchool",
  RomaHighSchool = "romaHighSchool",
  SkidmoreTynanHighSchool = "skidmoreTynanHighSchool",
  AustwellTivoliHighSchool = "austwellTivoliHighSchool",
  BunaHighSchool = "bunaHighSchool",
  MckinneyNorthHS = "mckinneyNorthHS",
  DawsonHighSchool = "dawsonHighSchool",
  PaulLaurenceDunbarHighSchool = "paulLaurenceDunbarHighSchool",
  RoyalHighSchool = "royalHighSchool",
  GroesbeckHighSchool = "groesbeckHighSchool",
  SevenLakesHighSchool = "sevenLakesHighSchool",
  RochelleSchool = "rochelleSchool",
  PflugervilleHighSchool = "pflugervilleHighSchool",
  HermleighSchool = "hermleighSchool",
  NimitzHighSchoolAldine = "nimitzHighSchoolAldine",
  CrystalCityHighSchool = "crystalCityHighSchool",
  TexhomaElementarySchool = "texhomaElementarySchool",
  HooksHighSchool = "hooksHighSchool",
  SeminoleSuccessCenter = "seminoleSuccessCenter",
  ThreeRiversHighSchool = "threeRiversHighSchool",
  DrLeoCigarroaHighSchool = "drLeoCigarroaHighSchool",
  SanSabaHighSchool = "sanSabaHighSchool",
  WinkHighSchool = "winkHighSchool",
  CotullaHighSchool = "cotullaHighSchool",
  HarperHighSchool = "harperHighSchool",
  DickinsonHighSchool = "dickinsonHighSchool",
  PrairilandHighSchool = "prairilandHighSchool",
  PaintRockSchool = "paintRockSchool",
  BowieHighSchoolAustin = "bowieHighSchoolAustin",
  CrockettHighSchoolAustin = "crockettHighSchoolAustin",
  NewcastleSchool = "newcastleSchool",
  NewHomeSchool = "newHomeSchool",
  ColumbiaHighSchool = "columbiaHighSchool",
  JourdantonHighSchool = "jourdantonHighSchool",
  OvertonHighSchool = "overtonHighSchool",
  GreenvilleHighSchool = "greenvilleHighSchool",
  TomballMemorialHS = "tomballMemorialHS",
  CypressSpringsHighSchool = "cypressSpringsHighSchool",
  CalvertSchool = "calvertSchool",
  MineralWellsAcademy = "mineralWellsAcademy",
  SouthwestHighSchoolSouthwest = "southwestHighSchoolSouthwest",
  KnoxCityHighSchool = "knoxCityHighSchool",
  SterlingCityHighSchool = "sterlingCityHighSchool",
  TahokaHighSchool = "tahokaHighSchool",
  FayettevilleHighSchool = "fayettevilleHighSchool",
  ValentineSchool = "valentineSchool",
  ChicoHighSchool = "chicoHighSchool",
  EvantHighSchool = "evantHighSchool",
  MarcusHighSchool = "marcusHighSchool",
  LeggettHighSchool = "leggettHighSchool",
  CrowellHighSchool = "crowellHighSchool",
  AdrianSchool = "adrianSchool",
  MidlandFreshmanHighSchool = "midlandFreshmanHighSchool",
  MesquiteHighSchool = "mesquiteHighSchool",
  AnthonyHighSchool = "anthonyHighSchool",
  RockportFultonHighSchool = "rockportFultonHighSchool",
  GrapevineHighSchool = "grapevineHighSchool",
  BryanAdamsHighSchool = "bryanAdamsHighSchool",
  BurbankHighSchool = "burbankHighSchool",
  SabinePassSchool = "sabinePassSchool",
  AmonCarterRiversideHighSchool = "amonCarterRiversideHighSchool",
  BellaireHighSchool = "bellaireHighSchool",
  CarverHSForAppliedTechEngineeringArts = "carverHSForAppliedTechEngineeringArts",
  CommunityHighSchool = "communityHighSchool",
  ArlingtonHeightsHighSchool = "arlingtonHeightsHighSchool",
  MarbleFallsHighSchool = "marbleFallsHighSchool",
  BookerJuniorSeniorHighSchool = "bookerJuniorSeniorHighSchool",
  FrenshipHighSchool = "frenshipHighSchool",
  RisingStarHighSchool = "risingStarHighSchool",
  BrookhavenYouthRanch = "brookhavenYouthRanch",
  SmithsonValleyHighSchool = "smithsonValleyHighSchool",
  HedleySchool = "hedleySchool",
  LibertyHighSchoolLiberty = "libertyHighSchoolLiberty",
  BoerneSamuelVChampionHighSchool = "boerneSamuelVChampionHighSchool",
  WintersHighSchool = "wintersHighSchool",
  BuffaloHighSchool = "buffaloHighSchool",
  DevineHighSchool = "devineHighSchool",
  SouthGrandPrairieHigh9ThGradeCenter = "southGrandPrairieHigh9ThGradeCenter",
  BloomingGroveHighSchool = "bloomingGroveHighSchool",
  MathisHighSchool = "mathisHighSchool",
  MartinHighSchoolArlington = "martinHighSchoolArlington",
  SaltilloSchool = "saltilloSchool",
  JoaquinHighSchool = "joaquinHighSchool",
  BrockHighSchool = "brockHighSchool",
  HarletonHighSchool = "harletonHighSchool",
  WarrenHighSchoolNorthside = "warrenHighSchoolNorthside",
  HollidayHighSchool = "hollidayHighSchool",
  AldineHighSchool = "aldineHighSchool",
  AnnaHighSchool = "annaHighSchool",
  RainsHighSchool = "rainsHighSchool",
  MilesHighSchool = "milesHighSchool",
  HuntsvilleHighSchool = "huntsvilleHighSchool",
  BruniHighSchool = "bruniHighSchool",
  RoyseCityHS = "royseCityHS",
  AntonHighSchoolJuniorHighSchool = "antonHighSchoolJuniorHighSchool",
  ClearLakeHighSchool = "clearLakeHighSchool",
  LeonardHighSchool = "leonardHighSchool",
  GrovetonJHHS = "grovetonJHHS",
  ChipRicharteHighSchool = "chipRicharteHighSchool",
  PalestineHighSchool = "palestineHighSchool",
  KleinCainHighSchool = "kleinCainHighSchool",
  AspermontHighSchool = "aspermontHighSchool",
  DimeBoxSchool = "dimeBoxSchool",
  LlanoHighSchool = "llanoHighSchool",
  MckinneyHighSchool = "mckinneyHighSchool",
  HartleySchool = "hartleySchool",
  ItascaHighSchool = "itascaHighSchool",
  McleodHighSchool = "mcleodHighSchool",
  ClarkHighSchoolNorthside = "clarkHighSchoolNorthside",
  LongviewHighSchool = "longviewHighSchool",
  PsjaSouthwestHighSchool = "psjaSouthwestHighSchool",
  LampasasHight = "lampasasHight",
  TaftHighSchoolNorthside = "taftHighSchoolNorthside",
  EisenhowerHighSchool = "eisenhowerHighSchool",
  BalmorheaSchool = "balmorheaSchool",
  RiderHighSchool = "riderHighSchool",
  PrairieValleyJuniorHighHighSchool = "prairieValleyJuniorHighHighSchool",
  PothHighSchool = "pothHighSchool",
  LazbuddieSchool = "lazbuddieSchool",
  LoopSchool = "loopSchool",
  ChesterHighSchool = "chesterHighSchool",
  CaldwellHighSchool = "caldwellHighSchool",
  SeagovilleHighSchool = "seagovilleHighSchool",
  ACJonesHighSchool = "aCJonesHighSchool",
  OzonaHighSchool = "ozonaHighSchool",
  CyFairHighSchool = "cyFairHighSchool",
  ForestburgSchool = "forestburgSchool",
  WinonaHighSchool = "winonaHighSchool",
  TornilloHighSchool = "tornilloHighSchool",
  BurlesonHighSchool = "burlesonHighSchool",
  MidwaySchool = "midwaySchool",
  HughesSpringsHighSchool = "hughesSpringsHighSchool",
  BullardHighSchool = "bullardHighSchool",
  GlasscockCountyHighSchool = "glasscockCountyHighSchool",
  JayHighSchool = "jayHighSchool",
  AtascocitaHighSchool = "atascocitaHighSchool",
  LyndonBJohnsonHighSchoolUnited = "lyndonBJohnsonHighSchoolUnited",
  RefugioHighSchool = "refugioHighSchool",
  GatesvilleHighSchool = "gatesvilleHighSchool",
  BushHighSchool = "bushHighSchool",
  GanadoHighSchool = "ganadoHighSchool",
  AbbottSchool = "abbottSchool",
  HawkinsHighSchool = "hawkinsHighSchool",
  DellCitySchool = "dellCitySchool",
  HarkerHeightsHighSchool = "harkerHeightsHighSchool",
  LyndonBJohnsonHighSchoolJohnsonCity = "lyndonBJohnsonHighSchoolJohnsonCity",
  EastCentralHS = "eastCentralHS",
  XITSecondarySchool = "xITSecondarySchool",
  AndrewsHighSchool = "andrewsHighSchool",
  MartinsMillHighSchool = "martinsMillHighSchool",
  LibertyEylauHighSchool = "libertyEylauHighSchool",
  CommerceHighSchool = "commerceHighSchool",
  StubblefieldLearningCenter = "stubblefieldLearningCenter",
  AmarilloHighSchool = "amarilloHighSchool",
  CranfillsGapSchool = "cranfillsGapSchool",
  PalaciosHighSchool = "palaciosHighSchool",
  LittlefieldHighSchool = "littlefieldHighSchool",
  CentennialHighSchoolFrisco = "centennialHighSchoolFrisco",
  DuncanvilleHighSchool = "duncanvilleHighSchool",
  PsjaHighSchool = "psjaHighSchool",
  DewittLavacaSpecialEdCoOp = "dewittLavacaSpecialEdCoOp",
  AguaDulceHighSchool = "aguaDulceHighSchool",
  BurnetHighSchool = "burnetHighSchool",
  JohnHornHighSchool = "johnHornHighSchool",
  CrossRoadsHighSchool = "crossRoadsHighSchool",
  EdinburgHighSchool = "edinburgHighSchool",
  SandersonHighSchool = "sandersonHighSchool",
  PlainviewHighSchool = "plainviewHighSchool",
  RobinsonHighSchool = "robinsonHighSchool",
  AlvaradoHighSchool = "alvaradoHighSchool",
  HartJuniorSeniorHighSchool = "hartJuniorSeniorHighSchool",
  CanyonHighSchoolCanyon = "canyonHighSchoolCanyon",
  RichardsonHighSchool = "richardsonHighSchool",
  MoranSchool = "moranSchool",
  NewWaverlyHighSchool = "newWaverlyHighSchool",
  NorthMesquiteHighSchool = "northMesquiteHighSchool",
  TopOfTexasAcceleratedEducationCenter = "topOfTexasAcceleratedEducationCenter",
  NixonHighSchool = "nixonHighSchool",
  WorthingHighSchool = "worthingHighSchool",
  VanHornHS = "vanHornHS",
  BrysonMsHighSchool = "brysonMsHighSchool",
  BroaddusHighSchool = "broaddusHighSchool",
  KleinHighSchool = "kleinHighSchool",
  WalnutSpringsSchool = "walnutSpringsSchool",
  ChapelHillHighSchool = "chapelHillHighSchool",
  FosterHighSchool = "fosterHighSchool",
  CenterPointHighSchool = "centerPointHighSchool",
  SpurgerHighSchool = "spurgerHighSchool",
  KopperlSchool = "kopperlSchool",
  FranklinDRooseveltHighSchool = "franklinDRooseveltHighSchool",
  MexiaHighSchool = "mexiaHighSchool",
  PampaHighSchool = "pampaHighSchool",
  GrapelandHighSchool = "grapelandHighSchool",
  AthensHighSchool = "athensHighSchool",
  AmericasHighSchool = "americasHighSchool",
  RungeHighSchool = "rungeHighSchool",
  ChristovalHighSchool = "christovalHighSchool",
  CelesteHighSchool = "celesteHighSchool",
  CedarParkHS = "cedarParkHS",
  BrownwoodHighSchool = "brownwoodHighSchool",
  KarnesCityHighSchool = "karnesCityHighSchool",
  WellsHighSchool = "wellsHighSchool",
  MontwoodHighSchool = "montwoodHighSchool",
  CrockettHighSchoolCrockett = "crockettHighSchoolCrockett",
  BradyHighSchool = "bradyHighSchool",
  CypressRanchHighSchool = "cypressRanchHighSchool",
  LindenKildareHighSchool = "lindenKildareHighSchool",
  KingHighSchool = "kingHighSchool",
  IowaParkHighSchool = "iowaParkHighSchool",
  VictoriaEastHighSchool = "victoriaEastHighSchool",
  FranklinHighSchoolElPaso = "franklinHighSchoolElPaso",
  RioHondoHighSchool = "rioHondoHighSchool",
  MumfordHighSchool = "mumfordHighSchool",
  LanevilleSchool = "lanevilleSchool",
  HerefordHighSchool = "herefordHighSchool",
  HMKingHighSchool = "hMKingHighSchool",
  SantaGertrudisAcademyHighSchool = "santaGertrudisAcademyHighSchool",
  BrewerHighSchool = "brewerHighSchool",
  BolingHighSchool = "bolingHighSchool",
  AxtellHighSchool = "axtellHighSchool",
  CarthageHighSchool = "carthageHighSchool",
  SamRayburnHighSchool = "samRayburnHighSchool",
  GlenRoseHighSchool = "glenRoseHighSchool",
  LivingstonHighSchool = "livingstonHighSchool",
  SeguinHighSchoolSeguin = "seguinHighSchoolSeguin",
  AkinsHighSchool = "akinsHighSchool",
  MarshallHighSchoolMarshall = "marshallHighSchoolMarshall",
  JeffersonHighSchoolJefferson = "jeffersonHighSchoolJefferson",
  WhitefaceHighSchool = "whitefaceHighSchool",
  BallHighSchool = "ballHighSchool",
  JamesBowieHighSchool = "jamesBowieHighSchool",
  PortIsabelHS = "portIsabelHS",
  RiveraHighSchool = "riveraHighSchool",
  TuliaHighSchool = "tuliaHighSchool",
  AliceHighSchool = "aliceHighSchool",
  SAndSConsHighSchool = "sAndSConsHighSchool",
  CincoRanchHighSchool = "cincoRanchHighSchool",
  HightowerHighSchool = "hightowerHighSchool",
  FortHancockHighSchool = "fortHancockHighSchool",
  ConnallyHighSchoolPflugerville = "connallyHighSchoolPflugerville",
  LakeCreekHighSchool = "lakeCreekHighSchool",
  WhitesboroHighSchool = "whitesboroHighSchool",
  BerknerHighSchool = "berknerHighSchool",
  WoodsboroHighSchool = "woodsboroHighSchool",
  ChapinHighSchool = "chapinHighSchool",
  UniversityHighSchool = "universityHighSchool",
  TascosaHighSchool = "tascosaHighSchool",
  GoodrichHighSchool = "goodrichHighSchool",
  FalfurriasHighSchool = "falfurriasHighSchool",
  VanAlstyneHighSchool = "vanAlstyneHighSchool",
  LoveladyJHHighSchool = "loveladyJHHighSchool",
  NavasotaHighSchool = "navasotaHighSchool",
  BlumHighSchool = "blumHighSchool",
  Other = "other",
  BolesHighSchool = "bolesHighSchool",
  NorthRichlandMiddleSchool = "northRichlandMiddleSchool",
  TimpsonHighSchool = "timpsonHighSchool",
  GrapeCreekHighSchool = "grapeCreekHighSchool",
  ChurchillHighSchool = "churchillHighSchool",
  MadisonHighSchoolHouston = "madisonHighSchoolHouston",
  PolytechnicHighSchool = "polytechnicHighSchool",
  RoxtonHighSchool = "roxtonHighSchool",
  DecaturHighSchool = "decaturHighSchool",
  ElginHighSchool = "elginHighSchool",
  MarshallHighSchoolNorthside = "marshallHighSchoolNorthside",
  EvadaleHighSchool = "evadaleHighSchool",
  LeeHighSchoolNorthEast = "leeHighSchoolNorthEast",
  CedarHillHighSchool = "cedarHillHighSchool",
  YatesHighSchool = "yatesHighSchool",
  MacarthurHighSchoolNorthEast = "macarthurHighSchoolNorthEast",
  AndersonShiroJrSrHighSchool = "andersonShiroJrSrHighSchool",
  LaVerniaHighSchool = "laVerniaHighSchool",
  CrosslandNinthGradeCenter = "crosslandNinthGradeCenter",
  KingwoodParkHighSchool = "kingwoodParkHighSchool",
  DenverCityHighSchool = "denverCityHighSchool",
  PaetowHighSchool = "paetowHighSchool",
  ChallengeAcademy = "challengeAcademy",
  EastlandHighSchool = "eastlandHighSchool",
  HawleyHighSchool = "hawleyHighSchool",
  PorterHighSchoolNewCaney = "porterHighSchoolNewCaney",
  CrowleyHighSchool = "crowleyHighSchool",
  BuckholtsSchool = "buckholtsSchool",
  OdemHighSchool = "odemHighSchool",
  WellmanUnionSchool = "wellmanUnionSchool",
  SamuelClemensHighSchool = "samuelClemensHighSchool",
  SouthsideHighSchool = "southsideHighSchool",
  CHYoeHighSchool = "cHYoeHighSchool",
  CeKingHighSchool = "ceKingHighSchool",
  WhitehouseHighSchool = "whitehouseHighSchool",
  NorthCrowleyHighSchool = "northCrowleyHighSchool",
  TheColonyHighSchool = "theColonyHighSchool",
  BenavidesSecondarySchool = "benavidesSecondarySchool",
  BellsHighSchool = "bellsHighSchool",
  LakeWorthHS = "lakeWorthHS",
  YantisHighSchool = "yantisHighSchool",
  ClearSpringsHighSchool = "clearSpringsHighSchool",
  IdalouHighSchool = "idalouHighSchool",
  LibertyHighSchoolFrisco = "libertyHighSchoolFrisco",
  DullesHighSchool = "dullesHighSchool",
  SaginawHighSchool = "saginawHighSchool",
  FairfieldHighSchool = "fairfieldHighSchool",
  TatumHighSchool = "tatumHighSchool",
  HighlandsHighSchool = "highlandsHighSchool",
  YorktownHighSchool = "yorktownHighSchool",
  SulphurSpringsHighSchool = "sulphurSpringsHighSchool",
  MemorialHighSchoolEdgewood = "memorialHighSchoolEdgewood",
  PlanoWestSeniorHighSchool = "planoWestSeniorHighSchool",
  GruverHighSchool = "gruverHighSchool",
  CanyonLakeHS = "canyonLakeHS",
  SantaAnnaSecondarySchool = "santaAnnaSecondarySchool",
  BeltonHighSchool = "beltonHighSchool",
  GonzalesHighSchool = "gonzalesHighSchool",
  MonteAltoHighSchool = "monteAltoHighSchool",
  HGradySpruceHighSchool = "hGradySpruceHighSchool",
  PetersburgHighSchool = "petersburgHighSchool",
  LanghamCreekHighSchool = "langhamCreekHighSchool",
  SouthSanAntonioHighSchool = "southSanAntonioHighSchool",
  IrvinHighSchool = "irvinHighSchool",
  AubreyHighSchool = "aubreyHighSchool",
  TempleHighSchool = "templeHighSchool",
  LancasterHighSchool = "lancasterHighSchool",
  WoodvilleHighSchool = "woodvilleHighSchool",
  MineolaHighSchool = "mineolaHighSchool",
  BlandHighSchool = "blandHighSchool",
  SantaRosaHS = "santaRosaHS",
  LakeHighlandsHS = "lakeHighlandsHS",
  CrandallHighSchool = "crandallHighSchool",
  JMHanksHighSchool = "jMHanksHighSchool",
  PleasantonHighSchool = "pleasantonHighSchool",
  ClarendonHighSchool = "clarendonHighSchool",
  PecosHighSchool = "pecosHighSchool",
  DelValleHighSchool = "delValleHighSchool",
  LakeViewHighSchool = "lakeViewHighSchool",
  TrinityHighSchool = "trinityHighSchool",
  MuensterHighSchool = "muensterHighSchool",
  CopperasCoveHighSchool = "copperasCoveHighSchool",
  CooperHighSchoolCooper = "cooperHighSchoolCooper",
  GrahamLearningCenter = "grahamLearningCenter",
  GrandviewHighSchool = "grandviewHighSchool",
  LaVegaHighSchool = "laVegaHighSchool",
  LindaleHighSchool = "lindaleHighSchool",
  CentennialHighSchoolBurleson = "centennialHighSchoolBurleson",
  FarwellHighSchool = "farwellHighSchool",
  FoxTechnicalHighSchool = "foxTechnicalHighSchool",
  LagoVistaHighSchool = "lagoVistaHighSchool",
  GustineSchool = "gustineSchool",
  TurnerHighSchool = "turnerHighSchool",
  MilanoHighSchool = "milanoHighSchool",
  OreCityHighSchool = "oreCityHighSchool",
  MarshallHighSchoolFortBend = "marshallHighSchoolFortBend",
  HemphillHighSchool = "hemphillHighSchool",
  EarlyHighSchool = "earlyHighSchool",
  NewDianaHighSchool = "newDianaHighSchool",
  KlondikeIsd = "klondikeIsd",
  MonahansEducationCenter = "monahansEducationCenter",
  BrenhamHighSchool = "brenhamHighSchool",
  CelinaHighSchool = "celinaHighSchool",
  ChildressHighSchool = "childressHighSchool",
  CorsicanaHighSchool = "corsicanaHighSchool",
  MotleyCountySchool = "motleyCountySchool",
  BangsHighSchool = "bangsHighSchool",
  NederlandHighSchool = "nederlandHighSchool",
  NewSummerfieldSchool = "newSummerfieldSchool",
  MarlinHighSchool = "marlinHighSchool",
  TrinityHighSchoolHeb = "trinityHighSchoolHeb",
  CityViewJuniorSeniorHighSchool = "cityViewJuniorSeniorHighSchool",
  WHAdamsonHighSchool = "wHAdamsonHighSchool",
  DarrouzettSchools = "darrouzettSchools",
  CentervilleHighSchool = "centervilleHighSchool",
  HullDaisettaHighSchool = "hullDaisettaHighSchool",
  PearsallHighSchool = "pearsallHighSchool",
  WylieHighSchool = "wylieHighSchool",
  MagnoliaHighSchool = "magnoliaHighSchool",
  GarySchool = "garySchool",
  EdinburgNorthHighSchool = "edinburgNorthHighSchool",
  SomersetHighSchool = "somersetHighSchool",
  RallsHighSchool = "rallsHighSchool",
  BowieHighSchoolElPaso = "bowieHighSchoolElPaso",
  RiversideHighSchool = "riversideHighSchool",
  PalmerHighSchool = "palmerHighSchool",
  RockwallHeathHighSchool = "rockwallHeathHighSchool",
  SpurSchool = "spurSchool",
  JonesHighSchool = "jonesHighSchool",
  SpringWoodsHighSchool = "springWoodsHighSchool",
  TomBeanHighSchool = "tomBeanHighSchool",
  LoneOakHighSchool = "loneOakHighSchool",
  BryanHighSchool = "bryanHighSchool",
  HighlandParkHighSchool = "highlandParkHighSchool",
  PerrinHighSchool = "perrinHighSchool",
  CaneyCreekHighSchool = "caneyCreekHighSchool",
  BurtonHighSchool = "burtonHighSchool",
  UnionGroveHighSchool = "unionGroveHighSchool",
  HappyHighSchool = "happyHighSchool",
  BurkevilleHighSchool = "burkevilleHighSchool",
  LittleCypressMauricevilleHighSchool = "littleCypressMauricevilleHighSchool",
  EdcouchElsaHighSchool = "edcouchElsaHighSchool",
  SheptonHighSchool = "sheptonHighSchool",
  LockneyHighSchool = "lockneyHighSchool",
  PittsburgHighSchool = "pittsburgHighSchool",
  AndrewsEducationCenter = "andrewsEducationCenter",
  BowieHighSchoolArlington = "bowieHighSchoolArlington",
  SocorroHighSchool = "socorroHighSchool",
  AndersonHighSchool = "andersonHighSchool",
  EastlakeHighSchool = "eastlakeHighSchool",
  WillsPointHighSchool = "willsPointHighSchool",
  LubbockCooperHighSchool = "lubbockCooperHighSchool",
  ArpHighSchool = "arpHighSchool",
  JasperHighSchoolJasper = "jasperHighSchoolJasper",
  ValleyMillsHighSchool = "valleyMillsHighSchool",
  ValleySchool = "valleySchool",
  DHanisSchool = "dHanisSchool",
  UnitedSouthHighSchool = "unitedSouthHighSchool",
  BairdHighSchool = "bairdHighSchool",
  BurgesHighSchool = "burgesHighSchool",
  HaskellHighSchool = "haskellHighSchool",
  TroyHighSchool = "troyHighSchool",
  PoolvilleHighSchool = "poolvilleHighSchool",
  SavoyHighSchool = "savoyHighSchool",
  RockwallHighSchool = "rockwallHighSchool",
  AvalonSchool = "avalonSchool",
  FairviewAcceleratedGrapeCreek = "fairviewAcceleratedGrapeCreek",
  WellingtonHighSchool = "wellingtonHighSchool",
  CampbellHighSchool = "campbellHighSchool",
  BastropHighSchool = "bastropHighSchool",
  ShepherdHighSchool = "shepherdHighSchool",
  HamiltonHighSchool = "hamiltonHighSchool",
  LehmanHighSchool = "lehmanHighSchool",
  GregoryPortlandHighSchool = "gregoryPortlandHighSchool",
  EastwoodHighSchool = "eastwoodHighSchool",
  RieselHighSchool = "rieselHighSchool",
  LakeDallasHighSchool = "lakeDallasHighSchool",
  FairviewAcceleratedBallinger = "fairviewAcceleratedBallinger",
  NorthDallasHighSchool = "northDallasHighSchool",
  ChavezHighSchool = "chavezHighSchool",
  GoldBurgHighSchool = "goldBurgHighSchool",
  WimberleyHighSchool = "wimberleyHighSchool",
  KenedyHighSchool = "kenedyHighSchool",
  LopezHighSchool = "lopezHighSchool",
  FrostHighSchool = "frostHighSchool",
  BlackwellSchool = "blackwellSchool",
  MerkelHighSchool = "merkelHighSchool",
  McleanSchool = "mcleanSchool",
  NewBraunfelsHighSchool = "newBraunfelsHighSchool",
  FortElliottSchool = "fortElliottSchool",
  CalhounHighSchool = "calhounHighSchool",
  MontgomeryHighSchool = "montgomeryHighSchool",
  GilmerHighSchool = "gilmerHighSchool",
  TheWoodlandsCollegeParkHighSchool = "theWoodlandsCollegeParkHighSchool",
  RedOakHS = "redOakHS",
  DesotoHighSchool = "desotoHighSchool",
  PetroliaJuniorSeniorHighSchool = "petroliaJuniorSeniorHighSchool",
  AlamoHeightsHighSchool = "alamoHeightsHighSchool",
  HarroldSchool = "harroldSchool",
  ConnallyHighSchoolConnally = "connallyHighSchoolConnally",
  MundayHighSchool = "mundayHighSchool",
  MemorialHighSchoolPasadena = "memorialHighSchoolPasadena",
  DaingerfieldHighSchool = "daingerfieldHighSchool",
  JamesMadisonHighSchool = "jamesMadisonHighSchool",
  MccollumHighSchool = "mccollumHighSchool",
  IraSchool = "iraSchool",
  ItalyHighSchool = "italyHighSchool",
  PaceHighSchool = "paceHighSchool",
  ShermanHighSchool = "shermanHighSchool",
  KilgoreHighSchool = "kilgoreHighSchool",
  LamesaHighSchool = "lamesaHighSchool",
  MtEnterpriseJuniorHighHighSchool = "mtEnterpriseJuniorHighHighSchool",
  CoolidgeHighSchool = "coolidgeHighSchool",
  AtlantaHighSchool = "atlantaHighSchool",
  AndressHighSchool = "andressHighSchool",
  VictoriaWestHighSchool = "victoriaWestHighSchool",
  WestTexasHighSchool = "westTexasHighSchool",
  ZephyrSchool = "zephyrSchool",
  AustinHighSchoolHouston = "austinHighSchoolHouston",
  WashingtonHighSchool = "washingtonHighSchool",
  LewisvilleHighSchoolKilloughCampus = "lewisvilleHighSchoolKilloughCampus",
  OltonHighSchool = "oltonHighSchool",
  FlowerMoundHighSchool = "flowerMoundHighSchool",
  GiddingsHighSchool = "giddingsHighSchool",
  MayHighSchool = "mayHighSchool",
  SamHoustonHighSchool = "samHoustonHighSchool",
  RooseveltHighSchoolNorthEast = "rooseveltHighSchoolNorthEast",
  MedinaValleyHighSchool = "medinaValleyHighSchool",
  TerrellHighSchool = "terrellHighSchool",
  HuttoHighSchool = "huttoHighSchool",
  SouthlandSchool = "southlandSchool",
  ScurryRosserHighSchool = "scurryRosserHighSchool",
  ThrallHighSchool = "thrallHighSchool",
  LanierHighSchoolAustin = "lanierHighSchoolAustin",
  FlourBluffHighSchool = "flourBluffHighSchool",
  HolmesHighSchool = "holmesHighSchool",
  MiamiSchool = "miamiSchool",
  CoronadoHighSchoolElPaso = "coronadoHighSchoolElPaso",
  SunrayHighSchool = "sunrayHighSchool",
  HardinJeffersonHighSchool = "hardinJeffersonHighSchool",
  LorenzoHighSchool = "lorenzoHighSchool",
  DentonHighSchool = "dentonHighSchool",
  ClearCreekHighSchool = "clearCreekHighSchool",
  CypressFallsHighSchool = "cypressFallsHighSchool",
  OrangefieldHighSchool = "orangefieldHighSchool",
  NavarroHighSchool = "navarroHighSchool",
  MoodyHighSchoolCorpusChristi = "moodyHighSchoolCorpusChristi",
  SpringlakeEarthHighSchool = "springlakeEarthHighSchool",
  EllisonHighSchool = "ellisonHighSchool",
  WolfeCityHighSchool = "wolfeCityHighSchool",
  AllenHighSchool = "allenHighSchool",
  JamesEarlRudderHighSchool = "jamesEarlRudderHighSchool",
  PasadenaHighSchool = "pasadenaHighSchool",
  CedarCreekHS = "cedarCreekHS",
  YsletaHighSchool = "ysletaHighSchool",
  GraysonCoCoOp = "graysonCoCoOp",
  BirdvilleHighSchool = "birdvilleHighSchool",
  HamlinHighSchool = "hamlinHighSchool",
  PleasantGroveHighSchool = "pleasantGroveHighSchool",
  ElectraHighSchool = "electraHighSchool",
  SweenyHighSchool = "sweenyHighSchool",
  DHSFreshmanCampus = "dHSFreshmanCampus",
  WylieEastHighSchool = "wylieEastHighSchool",
  BillyRyanHighSchool = "billyRyanHighSchool",
  WoodsonSchool = "woodsonSchool",
  DouglassSchool = "douglassSchool",
  RidgePointHighSchool = "ridgePointHighSchool",
  TexasCityHighSchool = "texasCityHighSchool",
  GrafordHighSchool = "grafordHighSchool",
  NuecesCanyonJhHs = "nuecesCanyonJhHs",
  SaladoHighSchool = "saladoHighSchool",
  QueenCityHS = "queenCityHS",
  KleinOakHighSchool = "kleinOakHighSchool",
  FulshearHighSchool = "fulshearHighSchool",
  SouthHoustonHighSchool = "southHoustonHighSchool",
  TrentIndependentSchoolDistrict = "trentIndependentSchoolDistrict",
  RuleSchool = "ruleSchool",
  FreerHighSchool = "freerHighSchool",
  MountPleasantHighSchool = "mountPleasantHighSchool",
  McmillenHighSchool = "mcmillenHighSchool",
  PottsboroHighSchool = "pottsboroHighSchool",
  SnookSecondarySchool = "snookSecondarySchool",
  LorenaHighSchool = "lorenaHighSchool",
  TravisHighSchoolAustin = "travisHighSchoolAustin",
  KressHighSchool = "kressHighSchool",
  VidorHighSchool = "vidorHighSchool",
  MacarthurHighSchoolIrving = "macarthurHighSchoolIrving",
  AxtellBrucevilleEddyLearningCenter = "axtellBrucevilleEddyLearningCenter",
  KleinForestHighSchool = "kleinForestHighSchool",
  FannindelHighSchool = "fannindelHighSchool",
  HubbardHighSchool = "hubbardHighSchool",
  AisdDraw = "aisdDraw",
  WoodrowWilsonHighSchool = "woodrowWilsonHighSchool",
  GrandPrairieHighSchool = "grandPrairieHighSchool",
  IolaHighSchool = "iolaHighSchool",
  KleinCollinsHighSchool = "kleinCollinsHighSchool",
  SummerCreekHighSchool = "summerCreekHighSchool",
  StrawnSchool = "strawnSchool",
  WakelandHighSchool = "wakelandHighSchool",
  RichardsHighSchool = "richardsHighSchool",
  GrandOaksHighSchool = "grandOaksHighSchool",
  BuenaVistaSchool = "buenaVistaSchool",
  EarlyEeThrough12ThGrade = "earlyEeThrough12ThGrade",
  ThorndaleHighSchool = "thorndaleHighSchool",
  QuitmanHighSchool = "quitmanHighSchool",
  DeweyvilleJuniorSeniorHighSchool = "deweyvilleJuniorSeniorHighSchool",
  RiceHighSchoolRiceRice = "riceHighSchoolRiceRice",
  KerensSchool = "kerensSchool",
  DeerParkHighSchool = "deerParkHighSchool",
  IrionHighSchool = "irionHighSchool",
  MilbyHighSchool = "milbyHighSchool",
  LamarConsolidatedHighSchool = "lamarConsolidatedHighSchool",
  BrazosHighSchool = "brazosHighSchool",
  WallHighSchool = "wallHighSchool",
  MoisesEMolinaHighSchool = "moisesEMolinaHighSchool",
  BonhamHighSchool = "bonhamHighSchool",
  PrincetonHighSchool = "princetonHighSchool",
  FallsCityHighSchool = "fallsCityHighSchool",
  KellerHighSchool = "kellerHighSchool",
  RichlandHighSchool = "richlandHighSchool",
  LibertyEylauSchoolOfSuccess = "libertyEylauSchoolOfSuccess",
  MasonHighSchool = "masonHighSchool",
  LeakeySchool = "leakeySchool",
  RockdaleHighSchool = "rockdaleHighSchool",
  DoddCitySchool = "doddCitySchool",
  HarlingenHsSouth = "harlingenHsSouth",
  HamshireFannettHighSchool = "hamshireFannettHighSchool",
  LakeTravisHighSchool = "lakeTravisHighSchool",
  AlvinHighSchool = "alvinHighSchool",
  CovingtonSchool = "covingtonSchool",
  NorthwestHighSchool = "northwestHighSchool",
  FairviewVocationalTraining = "fairviewVocationalTraining",
  WestOrangeStarkHighSchool = "westOrangeStarkHighSchool",
  SchulenburgSecondarySchool = "schulenburgSecondarySchool",
  HuntingtonHighSchool = "huntingtonHighSchool",
  FurrHighSchool = "furrHighSchool",
  MarionHighSchool = "marionHighSchool",
  WichitaFallsHighSchool = "wichitaFallsHighSchool",
  NewBostonHighSchool = "newBostonHighSchool",
  MarfaJuniorSeniorHighSchool = "marfaJuniorSeniorHighSchool",
  JohnsonHighSchool = "johnsonHighSchool",
  WaxahachieHighSchool = "waxahachieHighSchool",
  TarkingtonHighSchool = "tarkingtonHighSchool",
  RobertGColeMiddleHighSchool = "robertGColeMiddleHighSchool",
  HarmonyHighSchool = "harmonyHighSchool",
  MorganSchool = "morganSchool",
  JoshuaHighSchool = "joshuaHighSchool",
  LinglevilleSchool = "linglevilleSchool",
  WallaceAcceleratedHighSchool = "wallaceAcceleratedHighSchool",
  GreenwoodHighSchool = "greenwoodHighSchool",
  FossilRidgeHighSchool = "fossilRidgeHighSchool",
  WacoHighSchool = "wacoHighSchool",
  VeteransMemorialHighSchoolMission = "veteransMemorialHighSchoolMission",
  HuckabaySchool = "huckabaySchool",
  LeanderHighSchool = "leanderHighSchool",
  BoysRanchHighSchool = "boysRanchHighSchool",
  FairviewAcceleratedEden = "fairviewAcceleratedEden",
  NaamanForestHighSchool = "naamanForestHighSchool",
  CraneHighSchool = "craneHighSchool",
  CentralHighSchoolSanAngelo = "centralHighSchoolSanAngelo",
  BigSandyHighSchool = "bigSandyHighSchool",
  MaypearlHighSchool = "maypearlHighSchool",
  WeslacoEastHighSchool = "weslacoEastHighSchool",
  NorthbrookHighSchool = "northbrookHighSchool",
  WestlakeHighSchool = "westlakeHighSchool",
  WillisHighSchool = "willisHighSchool",
  PEPMorton = "pEPMorton",
  NorthTexasStateHospitalAfpVernon = "northTexasStateHospitalAfpVernon",
  GuthrieSchool = "guthrieSchool",
  ODWyattHighSchool = "oDWyattHighSchool",
  WinnsboroHighSchool = "winnsboroHighSchool",
  BellvilleHighSchool = "bellvilleHighSchool",
  ChillicotheHighSchool = "chillicotheHighSchool",
  HardinHighSchool = "hardinHighSchool",
  WhitewrightHighSchool = "whitewrightHighSchool",
  NorthLamarHighSchool = "northLamarHighSchool",
  FrionaHighSchool = "frionaHighSchool",
  FerrisHighSchool = "ferrisHighSchool",
  KennardHighSchool = "kennardHighSchool",
  CastleberryHighSchool = "castleberryHighSchool",
  SomervilleHighSchool = "somervilleHighSchool",
  GranburyHighSchool = "granburyHighSchool",
  EmmettJConradHighSchool = "emmettJConradHighSchool",
  SouthHillsHighSchool = "southHillsHighSchool",
  RoweHighSchool = "roweHighSchool",
  AlpineHighSchool = "alpineHighSchool",
  CaddoMillsHighSchool = "caddoMillsHighSchool",
  MansfieldTimberviewHighSchool = "mansfieldTimberviewHighSchool",
  LaVillaEarlyCollegeHighSchool = "laVillaEarlyCollegeHighSchool",
  VinesHighSchool = "vinesHighSchool",
  IowaColonyHighSchool = "iowaColonyHighSchool",
  FlatoniaSecondarySchool = "flatoniaSecondarySchool",
  LittleElmHighSchool = "littleElmHighSchool",
  AlbanyJuniorSeniorHighSchool = "albanyJuniorSeniorHighSchool",
  FruitvaleHighSchool = "fruitvaleHighSchool",
  DetroitHighSchool = "detroitHighSchool",
  AndyDekaneyHS = "andyDekaneyHS",
  DilleyHighSchool = "dilleyHighSchool",
  HenriettaHighSchool = "henriettaHighSchool",
  GainesvilleHighSchool = "gainesvilleHighSchool",
  LaGrangeHighSchool = "laGrangeHighSchool",
  ComstockSchool = "comstockSchool",
  UnitedHighSchool = "unitedHighSchool",
  CarrizoSpringsHighSchool = "carrizoSpringsHighSchool",
  GarrisonHighSchool = "garrisonHighSchool",
  ZavallaHighSchool = "zavallaHighSchool",
  SmithHighSchool = "smithHighSchool",
  EdisonHighSchool = "edisonHighSchool",
  RooseveltHighSchoolRoosevelt = "rooseveltHighSchoolRoosevelt",
  GoliadHighSchool = "goliadHighSchool",
  DrippingSpringsHighSchool = "drippingSpringsHighSchool",
  BronteSecondarySchool = "bronteSecondarySchool",
  ThrockmortonHighSchool = "throckmortonHighSchool",
  HillsboroHighSchool = "hillsboroHighSchool",
  BanderaHighSchool = "banderaHighSchool",
  RopesJuniorSeniorHighSchool = "ropesJuniorSeniorHighSchool",
  PrairieLeaSchool = "prairieLeaSchool",
  PriddySchool = "priddySchool",
  TerryHighSchool = "terryHighSchool",
  TexasHighSchool = "texasHighSchool",
  SouthwestHighSchoolFortWorth = "southwestHighSchoolFortWorth",
  CentervilleJuniorSeniorHighSchool = "centervilleJuniorSeniorHighSchool",
  GormanHighSchool = "gormanHighSchool",
  OdessaHighSchool = "odessaHighSchool",
  HebbronvilleHighSchool = "hebbronvilleHighSchool",
  UtopiaSchool = "utopiaSchool",
  JunctionHighSchool = "junctionHighSchool",
  PattonSpringsSchool = "pattonSpringsSchool",
  GalenaParkHighSchool = "galenaParkHighSchool",
  IredellSchool = "iredellSchool",
  SouthOakCliffHighSchool = "southOakCliffHighSchool",
  RayburnHighSchool = "rayburnHighSchool",
  LindsayHighSchool = "lindsayHighSchool",
  AzleHighSchool = "azleHighSchool",
  ScarboroughHighSchool = "scarboroughHighSchool",
  PearceHighSchool = "pearceHighSchool",
  ParklandHighSchool = "parklandHighSchool",
  MedinaHighSchool = "medinaHighSchool",
  NataliaHighSchool = "nataliaHighSchool",
  DiamondHillJarvisHighSchool = "diamondHillJarvisHighSchool",
  WaterValleyHighSchool = "waterValleyHighSchool",
  MuleshoeHighSchool = "muleshoeHighSchool",
  LaFeriaHighSchool = "laFeriaHighSchool",
  TulosoMidwayHighSchool = "tulosoMidwayHighSchool",
  JacksboroHighSchool = "jacksboroHighSchool",
  SpringHighSchool = "springHighSchool",
  PortAransasHighSchool = "portAransasHighSchool",
  WestwoodHighSchoolRoundRock = "westwoodHighSchoolRoundRock",
  SantaMariaHighSchool = "santaMariaHighSchool",
  HaltomHighSchool = "haltomHighSchool",
  CentralHighSchoolCentral = "centralHighSchoolCentral",
  CypressCreekHighSchool = "cypressCreekHighSchool",
  AlbaGoldenSecondarySchool = "albaGoldenSecondarySchool",
  SealyHighSchool = "sealyHighSchool",
  ClarkHighSchoolPlano = "clarkHighSchoolPlano",
  UvaldeHighSchool = "uvaldeHighSchool",
  ReaganHighSchoolAustin = "reaganHighSchoolAustin",
  KrumHighSchool = "krumHighSchool",
  ChisumHighSchool = "chisumHighSchool",
  AveryHighSchool = "averyHighSchool",
  HigginsSchool = "higginsSchool",
  PearlandHighSchool = "pearlandHighSchool",
  QuanahHighSchool = "quanahHighSchool",
  MansfieldSummitHighSchool = "mansfieldSummitHighSchool",
  FairviewSpecialProgramsChristoval = "fairviewSpecialProgramsChristoval",
  SaintJoHighSchool = "saintJoHighSchool",
  CrosbytonHighSchool = "crosbytonHighSchool",
  FrankstonHighSchool = "frankstonHighSchool",
  ColoradoHighSchool = "coloradoHighSchool",
  LyfordHighSchool = "lyfordHighSchool",
  HillcrestHighSchool = "hillcrestHighSchool",
  GunterHighSchool = "gunterHighSchool",
  BrucevilleEddyHighSchool = "brucevilleEddyHighSchool",
  NixonSmileyHighSchool = "nixonSmileyHighSchool",
  CrosbyHighSchool = "crosbyHighSchool",
  SanIsidroHighSchool = "sanIsidroHighSchool",
  SintonHighSchool = "sintonHighSchool",
  RivercrestHighSchool = "rivercrestHighSchool",
  RanchviewHighSchool = "ranchviewHighSchool",
  ReaganCountyHighSchool = "reaganCountyHighSchool",
  MercedesHighSchool = "mercedesHighSchool",
  SunsetHighSchool = "sunsetHighSchool",
  TimberCreekHighSchool = "timberCreekHighSchool",
  RoundTopCarmineHighSchool = "roundTopCarmineHighSchool",
  ColdspringOakhurstHighSchool = "coldspringOakhurstHighSchool",
  LanierHighSchoolSanAntonio = "lanierHighSchoolSanAntonio",
  MartAcademicAlernativeProgram = "martAcademicAlernativeProgram",
  NorthSideHighSchool = "northSideHighSchool",
  TeagueHighSchool = "teagueHighSchool",
  ChiltonSchool = "chiltonSchool",
  PineTreeHighSchool = "pineTreeHighSchool",
  ArgyleHighSchool = "argyleHighSchool",
  RosebudLottHighSchool = "rosebudLottHighSchool",
  MonahansHighSchool = "monahansHighSchool",
  CumbyHighSchool = "cumbyHighSchool",
  BreckenridgeHighSchool = "breckenridgeHighSchool",
  CooperHighSchoolAbilene = "cooperHighSchoolAbilene",
  SantoHighSchool = "santoHighSchool",
  LometaSchool = "lometaSchool",
  WestsideHighSchool = "westsideHighSchool",
  CoppellHighSchool = "coppellHighSchool",
  TaylorHighSchoolTaylor = "taylorHighSchoolTaylor",
  AbileneHighSchool = "abileneHighSchool",
  MartinHighSchoolLaredo = "martinHighSchoolLaredo",
  WestDAEP = "westDAEP",
  SkylineHighSchool = "skylineHighSchool",
  BovinaHighSchool = "bovinaHighSchool",
  OnalaskaJrSrHighSchool = "onalaskaJrSrHighSchool",
  IrvingHighSchool = "irvingHighSchool",
  McgregorHighSchool = "mcgregorHighSchool",
  RankinHighSchool = "rankinHighSchool",
  MoodyHighSchoolMoody = "moodyHighSchoolMoody",
  ElkhartHighSchool = "elkhartHighSchool",
  ByronNelsonHighSchool = "byronNelsonHighSchool",
  JudsonHighSchool = "judsonHighSchool",
  WestMesquiteHighSchool = "westMesquiteHighSchool",
  VenusHighSchool = "venusHighSchool",
  ReaganHighSchoolNorthEast = "reaganHighSchoolNorthEast",
  MemorialHighSchoolMcallen = "memorialHighSchoolMcallen",
  SouthGarlandHighSchool = "southGarlandHighSchool",
  AxtellBrucevilleEddy = "axtellBrucevilleEddy",
  CarlisleSchool = "carlisleSchool",
  WheelerSchool = "wheelerSchool",
  McneilHighSchool = "mcneilHighSchool",
  CrawfordHighSchool = "crawfordHighSchool",
  MagnoliaWestHighSchool = "magnoliaWestHighSchool",
  LamarHighSchoolHouston = "lamarHighSchoolHouston",
  ClevelandHighSchool = "clevelandHighSchool",
  MillerGroveSchool = "millerGroveSchool",
  SlatonHighSchool = "slatonHighSchool",
  StratfordHighSchoolSpringBranch = "stratfordHighSchoolSpringBranch",
  VanVleckHighSchool = "vanVleckHighSchool",
  MenardHighSchool = "menardHighSchool",
  WodenHighSchool = "wodenHighSchool",
  CrossPlainsHighSchool = "crossPlainsHighSchool",
  HeightsHighSchool = "heightsHighSchool",
  BurkburnettHighSchool = "burkburnettHighSchool",
  ClarksvilleHighSchool = "clarksvilleHighSchool",
  SabinalHighSchool = "sabinalHighSchool",
  PlanoEastSeniorHighSchool = "planoEastSeniorHighSchool",
  RiceHighSchoolRiceAltair = "riceHighSchoolRiceAltair",
  NordheimSchool = "nordheimSchool",
  JeffersonHighSchoolElPaso = "jeffersonHighSchoolElPaso",
  CaprockHighSchool = "caprockHighSchool",
  LGPinkstonHighSchool = "lGPinkstonHighSchool",
  JohnFKennedyHighSchool = "johnFKennedyHighSchool",
  CharlotteHighSchool = "charlotteHighSchool",
  ChanningSchool = "channingSchool",
  MontereyHighSchool = "montereyHighSchool",
  CentralHighSchoolKeller = "centralHighSchoolKeller",
  BynumSchool = "bynumSchool",
  BordenCountySchool = "bordenCountySchool",
  KennedaleHighSchool = "kennedaleHighSchool",
  DanburyHighSchool = "danburyHighSchool",
  WeatherfordHighSchool = "weatherfordHighSchool",
  CoronadoHighSchoolLubbock = "coronadoHighSchoolLubbock",
  SierraBlancaSchool = "sierraBlancaSchool",
  OrangeGroveHighSchool = "orangeGroveHighSchool",
  MaydeCreekHighSchool = "maydeCreekHighSchool",
  EnnisHighSchool = "ennisHighSchool",
  ZapataHighSchool = "zapataHighSchool",
  HumbleHighSchool = "humbleHighSchool",
  ODonnellHighSchool = "oDonnellHighSchool",
  MortonHighSchool = "mortonHighSchool",
  ByronPSteeleIiHighSchool = "byronPSteeleIiHighSchool",
  JohnRRoach = "johnRRoach",
  TomballHighSchool = "tomballHighSchool",
  HighlandParkHS = "highlandParkHS",
  DonnaHighSchool = "donnaHighSchool",
  AquillaSchool = "aquillaSchool",
  SpringHillHighSchool = "springHillHighSchool",
  KilleenHighSchool = "killeenHighSchool",
  LexingtonHighSchool = "lexingtonHighSchool",
  ClementsHighSchool = "clementsHighSchool",
  AppleSpringsHighSchool = "appleSpringsHighSchool",
  BeckvilleJrSrHighSchool = "beckvilleJrSrHighSchool",
  BloomburgHighSchool = "bloomburgHighSchool",
  SpringtownHighSchool = "springtownHighSchool",
  NoconaHighSchool = "noconaHighSchool",
  SanfordFritchHighSchool = "sanfordFritchHighSchool",
  AustinHighSchoolAustin = "austinHighSchoolAustin",
  LaPorteHighSchool = "laPorteHighSchool",
  SeguinHighSchoolArlington = "seguinHighSchoolArlington",
  PortNechesGrovesHighSchool = "portNechesGrovesHighSchool",
  FortStocktonHighSchool = "fortStocktonHighSchool",
  RowlettHighSchool = "rowlettHighSchool",
  PewittHighSchool = "pewittHighSchool",
  CedarRidgeHighSchool = "cedarRidgeHighSchool",
  LeeSeniorHighSchool = "leeSeniorHighSchool",
  HargraveHighSchool = "hargraveHighSchool",
  BroadHorizonsHighSchool = "broadHorizonsHighSchool",
  ParisHighSchool = "parisHighSchool",
  GroomSchool = "groomSchool",
  StephenvilleHighSchool = "stephenvilleHighSchool",
  CiscoHighSchool = "ciscoHighSchool",
  BoerneHighSchool = "boerneHighSchool",
  LubbockHighSchool = "lubbockHighSchool",
  SmyerHighSchool = "smyerHighSchool",
  RobertLeeHighSchool = "robertLeeHighSchool",
  EdnaHighSchool = "ednaHighSchool",
  CypressRidgeHighSchool = "cypressRidgeHighSchool",
  PorterHighSchoolBrownsville = "porterHighSchoolBrownsville",
  BremondHighSchool = "bremondHighSchool",
  HebronHighSchool = "hebronHighSchool",
  BellevueSchool = "bellevueSchool",
  MortonRanchHighSchool = "mortonRanchHighSchool",
  Connect = "connect",
  WandaRSmithHighSchool = "wandaRSmithHighSchool",
  TheWoodlandsHighSchool = "theWoodlandsHighSchool",
  EstacadoHighSchool = "estacadoHighSchool",
  ArlingtonHighSchool = "arlingtonHighSchool",
  ThomasJeffersonHighSchool = "thomasJeffersonHighSchool",
  RoscoeHighSchool = "roscoeHighSchool",
  YoakumHighSchool = "yoakumHighSchool",
  JimNedHighSchool = "jimNedHighSchool",
  WestOsoHighSchool = "westOsoHighSchool",
  BigBendHighSchool = "bigBendHighSchool",
  KountzeHighSchool = "kountzeHighSchool",
  GrangerSchool = "grangerSchool",
  MoultonHighSchool = "moultonHighSchool",
  WacoCtrForYouth = "wacoCtrForYouth",
  RoundRockHighSchool = "roundRockHighSchool",
  BallingerHighSchool = "ballingerHighSchool",
  HarlandaleHighSchool = "harlandaleHighSchool",
  HitchcockHighSchool = "hitchcockHighSchool",
  CypressWoodsHighSchool = "cypressWoodsHighSchool",
  DalhartHighSchool = "dalhartHighSchool",
  CenterHighSchool = "centerHighSchool",
  MemphisHighSchool = "memphisHighSchool",
  RioVistaHighSchool = "rioVistaHighSchool",
  HendersonHighSchool = "hendersonHighSchool",
  TaylorHighSchool = "taylorHighSchool",
  PsjaNorthHS = "psjaNorthHS",
  LeeHighSchoolGooseCreek = "leeHighSchoolGooseCreek",
  StamfordHighSchool = "stamfordHighSchool",
  MullinHighSchool = "mullinHighSchool",
  MaudSchool = "maudSchool",
  MckinneyBoydHS = "mckinneyBoydHS",
  FredericksburgHighSchool = "fredericksburgHighSchool",
  VandegriftHighSchool = "vandegriftHighSchool",
  FranklinHighSchoolFranklin = "franklinHighSchoolFranklin",
  WindthorstHighSchool = "windthorstHighSchool",
  GrandfallsRoyaltySchool = "grandfallsRoyaltySchool",
  BloomingtonHighSchool = "bloomingtonHighSchool",
  WhitneyHighSchool = "whitneyHighSchool",
  HaleCenterHighSchool = "haleCenterHighSchool",
  ClearFallsHighSchool = "clearFallsHighSchool",
  FloresvilleHighSchool = "floresvilleHighSchool",
  SanMarcosHighSchool = "sanMarcosHighSchool",
  FloydadaHighSchool = "floydadaHighSchool",
  CentralHeightsMiddleHighSchool = "centralHeightsMiddleHighSchool",
  BrazoswoodHighSchool = "brazoswoodHighSchool",
  PeasterHighSchool = "peasterHighSchool",
  NorthZulchHighSchool = "northZulchHighSchool",
  ArcherCityHighSchool = "archerCityHighSchool",
  MartinsvilleSchool = "martinsvilleSchool",
  ElPasoHS = "elPasoHS",
  CherokeeSchool = "cherokeeSchool",
  AbernathyHighSchool = "abernathyHighSchool",
  StevensHighSchool = "stevensHighSchool",
  CanadianHighSchool = "canadianHighSchool",
  HolyHighwayPickton = "holyHighwayPickton",
  DamonHighSchool = "damonHighSchool",
  LytleHighSchool = "lytleHighSchool",
  IraanHighSchool = "iraanHighSchool",
  FriendswoodHighSchool = "friendswoodHighSchool",
  ForsanHighSchool = "forsanHighSchool",
  KempHighSchool = "kempHighSchool",
  HearneHighSchool = "hearneHighSchool",
  DelValleOpportunityCenter = "delValleOpportunityCenter",
  AnsonHighSchool = "ansonHighSchool",
  BridgeCityHighSchool = "bridgeCityHighSchool",
  HicoHighSchool = "hicoHighSchool",
  ElysianFieldsHighSchool = "elysianFieldsHighSchool",
  ShelbyvilleSchool = "shelbyvilleSchool",
  BlancoHighSchool = "blancoHighSchool",
  CayugaHighSchool = "cayugaHighSchool",
  TroupHighSchool = "troupHighSchool",
  ClydeHighSchool = "clydeHighSchool",
  GrahamHighSchool = "grahamHighSchool",
  MtVernonHighSchool = "mtVernonHighSchool",
  GeorgeRanchHighSchool = "georgeRanchHighSchool",
  PerrytonHighSchool = "perrytonHighSchool",
  WhFordHighSchool = "whFordHighSchool",
  CarrollHighSchoolCorpusChristi = "carrollHighSchoolCorpusChristi",
  WillowridgeHighSchool = "willowridgeHighSchool",
  BlanketHighSchool = "blanketHighSchool",
  FtDavisHighSchool = "ftDavisHighSchool",
  NewCaneyHighSchool = "newCaneyHighSchool",
  PantherCreekSchool = "pantherCreekSchool",
  LeonHighSchool = "leonHighSchool",
  KaufmanHighSchool = "kaufmanHighSchool",
  EastChambersHighSchool = "eastChambersHighSchool",
  WWSamuellHighSchool = "wWSamuellHighSchool",
  EvermanHighSchool = "evermanHighSchool",
  EustaceHighSchool = "eustaceHighSchool",
  MccameyHighSchool = "mccameyHighSchool",
  LulingHighSchool = "lulingHighSchool",
  CanutilloHighSchool = "canutilloHighSchool",
  SanDiegoHighSchool = "sanDiegoHighSchool",
  MadisonHighSchoolNorthEast = "madisonHighSchoolNorthEast",
  RecoveryEducationCampus = "recoveryEducationCampus",
  PsjaMemorialHS = "psjaMemorialHS",
  LumbertonHighSchool = "lumbertonHighSchool",
  SharylandHighSchool = "sharylandHighSchool",
  TravisHighSchoolFortBend = "travisHighSchoolFortBend",
  BenBoltPalBlancoHighSchool = "benBoltPalBlancoHighSchool",
  AnahuacHighSchool = "anahuacHighSchool",
  HallsvilleHighSchool = "hallsvilleHighSchool",
  CarrollHighSchoolCarroll = "carrollHighSchoolCarroll",
  CypressLakesHighSchool = "cypressLakesHighSchool",
  OakRidgeHighSchool = "oakRidgeHighSchool",
  ColmesneilHighSchool = "colmesneilHighSchool",
  WilliamsHighSchool = "williamsHighSchool",
  ElkinsHighSchool = "elkinsHighSchool",
  RangerHighSchool = "rangerHighSchool",
  RockspringsHighSchool = "rockspringsHighSchool",
  SunnyvaleHighSchool = "sunnyvaleHighSchool",
  HollandHighSchool = "hollandHighSchool",
  KermitHighSchool = "kermitHighSchool",
  TaftHighSchoolTaft = "taftHighSchoolTaft",
  ColleyvilleHeritageHighSchool = "colleyvilleHeritageHighSchool",
  ComfortHighSchool = "comfortHighSchool",
  JEconomedesHighSchool = "jEconomedesHighSchool",
  KashmereHighSchool = "kashmereHighSchool",
  JacksonvilleHighSchool = "jacksonvilleHighSchool",
  ProsperHighSchool = "prosperHighSchool",
  EldoradoHighSchool = "eldoradoHighSchool",
  DekalbHighSchool = "dekalbHighSchool",
  CottonCenterSchool = "cottonCenterSchool",
  NorthsideSchool = "northsideSchool",
  SmithvilleHighSchool = "smithvilleHighSchool",
  SeagravesHighSchool = "seagravesHighSchool",
  DaytonHighSchool = "daytonHighSchool",
  GlendaDawsonHighSchool = "glendaDawsonHighSchool",
  BenjaminSchool = "benjaminSchool",
  KirbyvilleHighSchool = "kirbyvilleHighSchool",
  WesternHillsHighSchool = "westernHillsHighSchool",
  FairviewVocTraining = "fairviewVocTraining",
  FriscoHighSchool = "friscoHighSchool",
  TivyHighSchool = "tivyHighSchool",
  LapoynorHighSchool = "lapoynorHighSchool",
  CiscoLearningCenter = "ciscoLearningCenter",
  TolarHighSchool = "tolarHighSchool",
  PaducahSchool = "paducahSchool",
  JarrellHighSchool = "jarrellHighSchool",
  WestfieldHighSchool = "westfieldHighSchool",
  PenelopeSchool = "penelopeSchool",
  KatyHighSchool = "katyHighSchool",
  BrookelandHighSchool = "brookelandHighSchool",
  NewtonHighSchool = "newtonHighSchool",
  NorthForestHighSchool = "northForestHighSchool",
  CypressParkHighSchool = "cypressParkHighSchool",
  MemorialHighSchoolSpringBranch = "memorialHighSchoolSpringBranch",
  HarlingenHighSchool = "harlingenHighSchool",
  DobieHighSchool = "dobieHighSchool",
  BarbersHillHighSchool = "barbersHillHighSchool",
  HighlandSchool = "highlandSchool",
  WhartonHighSchool = "whartonHighSchool",
  SilsbeeHighSchool = "silsbeeHighSchool",
  KarenWagnerHighSchool = "karenWagnerHighSchool",
  PaintCreekSchool = "paintCreekSchool",
  MartHighSchool = "martHighSchool",
  NeedvilleHighSchool = "needvilleHighSchool",
  LuedersAvocaHighSchool = "luedersAvocaHighSchool",
  DenisonHighSchool = "denisonHighSchool",
  VeribestHighSchool = "veribestHighSchool",
  GeorgeWestHighSchool = "georgeWestHighSchool",
  LoneStarHighSchool = "loneStarHighSchool",
  LosFresnosHighSchool = "losFresnosHighSchool",
  AmherstSchool = "amherstSchool",
  CanyonHighSchoolComal = "canyonHighSchoolComal",
  WorthamHighSchool = "worthamHighSchool",
  PEPMuleshoe = "pEPMuleshoe",
  PresidioHighSchool = "presidioHighSchool",
  SilvertonSchool = "silvertonSchool",
  ParadiseHighSchool = "paradiseHighSchool",
  ShoemakerHighSchool = "shoemakerHighSchool",
  DelValleHighSchoolDelValle = "delValleHighSchoolDelValle",
  BowieHighSchoolBowie = "bowieHighSchoolBowie",
  PonderHighSchool = "ponderHighSchool",
  SanAugustineHighSchool = "sanAugustineHighSchool",
  SterlingHighSchoolHouston = "sterlingHighSchoolHouston",
  ShinerHighSchool = "shinerHighSchool",
  BushlandHighSchool = "bushlandHighSchool",
  MeridianHighSchool = "meridianHighSchool",
  SamHoustonMathScienceAndTechnologyCenter = "samHoustonMathScienceAndTechnologyCenter",
  StratfordHighSchoolStratford = "stratfordHighSchoolStratford",
  VistaRidgeHighSchool = "vistaRidgeHighSchool",
  ManvelHighSchool = "manvelHighSchool",
  HendricksonHighSchool = "hendricksonHighSchool",
  RichlandSpringsSchool = "richlandSpringsSchool",
  LoraineSchool = "loraineSchool",
  McallenHighSchool = "mcallenHighSchool",
  JaytonSchools = "jaytonSchools",
  EctorHighSchool = "ectorHighSchool",
  MilfordSchool = "milfordSchool",
  FlorenceHighSchool = "florenceHighSchool",
  MidlothianHighSchool = "midlothianHighSchool",
  ChinaSpringHighSchool = "chinaSpringHighSchool",
  RuskHighSchool = "ruskHighSchool",
  RLPaschalHighSchool = "rLPaschalHighSchool",
  EdenHighSchool = "edenHighSchool",
  LaJoyaPalmviewHighSchool = "laJoyaPalmviewHighSchool",
  LdBellHighSchool = "ldBellHighSchool",
  AMConsolidatedHighSchool = "aMConsolidatedHighSchool",
  BanqueteHighSchool = "banqueteHighSchool",
  MarathonIndpendentSchoolDist = "marathonIndpendentSchoolDist",
  BorgerHighSchool = "borgerHighSchool",
  DumasHighSchool = "dumasHighSchool",
  ClearBrookHighSchool = "clearBrookHighSchool",
  WhiteOakHighSchool = "whiteOakHighSchool",
  PoteetHighSchoolMesquite = "poteetHighSchoolMesquite",
  SudanHighSchool = "sudanHighSchool",
  LamesaSuccessAcademy = "lamesaSuccessAcademy",
  PlanoSeniorHighSchool = "planoSeniorHighSchool",
  MillerHighSchoolCtrForCommunication = "millerHighSchoolCtrForCommunication",
  AransasPassHighSchool = "aransasPassHighSchool",
  TheLarryBrownSchool = "theLarryBrownSchool",
  BrennanHighSchool = "brennanHighSchool",
  WTWhiteHighSchool = "wTWhiteHighSchool",
  BishopHighSchool = "bishopHighSchool",
  AngletonHighSchool = "angletonHighSchool",
  SulphurBluffSchool = "sulphurBluffSchool",
  JustinFKimballHighSchool = "justinFKimballHighSchool",
  NechesHighSchool = "nechesHighSchool",
  KingwoodHighSchool = "kingwoodHighSchool",
  LipanHighSchool = "lipanHighSchool",
  LaJoyaHighSchool = "laJoyaHighSchool",
  WilsonSchool = "wilsonSchool",
  GordonSchool = "gordonSchool",
  WallerHighSchool = "wallerHighSchool",
  SnyderHighSchool = "snyderHighSchool",
  ChisholmTrailHighSchool = "chisholmTrailHighSchool",
  AustinHighSchoolFortBend = "austinHighSchoolFortBend",
  NorthForneyHighSchool = "northForneyHighSchool",
  WestbrookSchool = "westbrookSchool",
  HeritageHighSchool = "heritageHighSchool",
  CliftonHighSchool = "cliftonHighSchool",
  HighIslandHighSchool = "highIslandHighSchool",
  RobyHighSchool = "robyHighSchool",
  FabensHighSchool = "fabensHighSchool",
  ElDoradoHighSchool = "elDoradoHighSchool",
  StarSchool = "starSchool",
  KempnerHighSchool = "kempnerHighSchool"
}
export const HighSchoolLabel: Record<HighSchool, string> = {
  [HighSchool.CallisburgHighSchool]: "CALLISBURG HIGH SCHOOL",
  [HighSchool.WisdomHighSchool]: "WISDOM HIGH SCHOOL",
  [HighSchool.BoydHighSchool]: "BOYD HIGH SCHOOL",
  [HighSchool.SonoraHighSchool]: "SONORA HIGH SCHOOL",
  [HighSchool.LatexoHighSchool]: "LATEXO HIGH SCHOOL",
  [HighSchool.NorthShoreSeniorHighSchool]: "NORTH SHORE SENIOR HIGH SCHOOL",
  [HighSchool.PilotPointHighSchool]: "PILOT POINT HIGH SCHOOL",
  [HighSchool.CushingSchool]: "CUSHING SCHOOL",
  [HighSchool.PermianHighSchool]: "PERMIAN HIGH SCHOOL",
  [HighSchool.AledoHighSchool]: "ALEDO HIGH SCHOOL",
  [HighSchool.EdgewoodHighSchool]: "EDGEWOOD HIGH SCHOOL",
  [HighSchool.NacogdochesHighSchool]: "NACOGDOCHES HIGH SCHOOL",
  [HighSchool.MabankHighSchool]: "MABANK HIGH SCHOOL",
  [HighSchool.KauferHighSchool]: "KAUFER HIGH SCHOOL",
  [HighSchool.LaPryorHighSchool]: "LA PRYOR HIGH SCHOOL",
  [HighSchool.BelAirHighSchool]: "BEL AIR HIGH SCHOOL",
  [HighSchool.NoviceSchool]: "NOVICE SCHOOL",
  [HighSchool.EarlySpecialProgram]: "EARLY SPECIAL PROGRAM",
  [HighSchool.LoweryFreshmanCenter]: "LOWERY FRESHMAN CENTER",
  [HighSchool.BoswellHighSchool]: "BOSWELL HIGH SCHOOL",
  [HighSchool.BartlettSchools]: "BARTLETT SCHOOLS",
  [HighSchool.OakwoodHighSchool]: "OAKWOOD HIGH SCHOOL",
  [HighSchool.MccallumHighSchool]: "MCCALLUM HIGH SCHOOL",
  [HighSchool.MansfieldHighSchool]: "MANSFIELD HIGH SCHOOL",
  [HighSchool.BrookesmithHighSchool]: "BROOKESMITH HIGH SCHOOL",
  [HighSchool.ComancheHighSchool]: "COMANCHE HIGH SCHOOL",
  [HighSchool.NimitzHighSchoolIrving]: "NIMITZ HIGH SCHOOL (IRVING)",
  [HighSchool.RaymondvilleHighSchool]: "RAYMONDVILLE HIGH SCHOOL",
  [HighSchool.DavisHighSchoolAldine]: "DAVIS HIGH SCHOOL (ALDINE)",
  [HighSchool.ColumbusHighSchool]: "COLUMBUS HIGH SCHOOL",
  [HighSchool.CoahomaHighSchool]: "COAHOMA HIGH SCHOOL",
  [HighSchool.BayCityHighSchool]: "BAY CITY HIGH SCHOOL",
  [HighSchool.MemorialHighSchoolPortArthur]:
    "MEMORIAL HIGH SCHOOL (PORT ARTHUR)",
  [HighSchool.SpearmanHighSchool]: "SPEARMAN HIGH SCHOOL",
  [HighSchool.NorthsideHighSchool]: "NORTHSIDE HIGH SCHOOL",
  [HighSchool.WestHardinHighSchool]: "WEST HARDIN HIGH SCHOOL",
  [HighSchool.InglesideHighSchool]: "INGLESIDE HIGH SCHOOL",
  [HighSchool.MissionHighSchool]: "MISSION HIGH SCHOOL",
  [HighSchool.HoweHighSchool]: "HOWE HIGH SCHOOL",
  [HighSchool.HirschiHighSchool]: "HIRSCHI HIGH SCHOOL",
  [HighSchool.HoneyGroveHighSchool]: "HONEY GROVE HIGH SCHOOL",
  [HighSchool.LibertyHillHighSchool]: "LIBERTY HILL HIGH SCHOOL",
  [HighSchool.HallettsvilleHighSchool]: "HALLETTSVILLE HIGH SCHOOL",
  [HighSchool.AltoHighSchool]: "ALTO HIGH SCHOOL",
  [HighSchool.SterlingHighSchoolGooseCreek]:
    "STERLING HIGH SCHOOL (GOOSE CREEK)",
  [HighSchool.WhitharralSchool]: "WHITHARRAL SCHOOL",
  [HighSchool.WeimarHighSchool]: "WEIMAR HIGH SCHOOL",
  [HighSchool.WestHighSchool]: "WEST HIGH SCHOOL",
  [HighSchool.SabineHighSchool]: "SABINE HIGH SCHOOL",
  [HighSchool.BridgelandHighSchool]: "BRIDGELAND HIGH SCHOOL",
  [HighSchool.BrackenridgeHighSchool]: "BRACKENRIDGE HIGH SCHOOL",
  [HighSchool.DeLeonHighSchool]: "DE LEON HIGH SCHOOL",
  [HighSchool.SplendoraHighSchool]: "SPLENDORA HIGH SCHOOL",
  [HighSchool.LosFresnosUnited]: "LOS FRESNOS UNITED",
  [HighSchool.SharpstownHighSchool]: "SHARPSTOWN HIGH SCHOOL",
  [HighSchool.ValleyViewHighSchool]: "VALLEY VIEW HIGH SCHOOL",
  [HighSchool.FairviewAcceleratedBronte]: "FAIRVIEW ACCELERATED (BRONTE)",
  [HighSchool.RouseHighSchool]: "ROUSE HIGH SCHOOL",
  [HighSchool.GooseCreekMemorial]: "GOOSE CREEK MEMORIAL",
  [HighSchool.AustinHighSchoolElPaso]: "AUSTIN HIGH SCHOOL (EL PASO)",
  [HighSchool.GrandSalineHighSchool]: "GRAND SALINE HIGH SCHOOL",
  [HighSchool.EasternHillsHighSchool]: "EASTERN HILLS HIGH SCHOOL",
  [HighSchool.MelissaHighSchool]: "MELISSA HIGH SCHOOL",
  [HighSchool.NorthHopkinsHighSchool]: "NORTH HOPKINS HIGH SCHOOL",
  [HighSchool.ShadowCreekHighSchool]: "SHADOW CREEK HIGH SCHOOL",
  [HighSchool.McdadeHighSchool]: "MCDADE HIGH SCHOOL",
  [HighSchool.EastsideHighSchool]: "EASTSIDE HIGH SCHOOL",
  [HighSchool.OglesbySchool]: "OGLESBY SCHOOL",
  [HighSchool.LeverettsChapelHighSchool]: "LEVERETTS CHAPEL HIGH SCHOOL",
  [HighSchool.LeforsSchool]: "LEFORS SCHOOL",
  [HighSchool.HoustonHighSchool]: "HOUSTON HIGH SCHOOL",
  [HighSchool.VernonHighSchool]: "VERNON HIGH SCHOOL",
  [HighSchool.SanElizarioHighSchool]: "SAN ELIZARIO HIGH SCHOOL",
  [HighSchool.KnippaSchool]: "KNIPPA SCHOOL",
  [HighSchool.ShallowaterHighSchool]: "SHALLOWATER HIGH SCHOOL",
  [HighSchool.HempsteadHighSchool]: "HEMPSTEAD HIGH SCHOOL",
  [HighSchool.WaltripHighSchool]: "WALTRIP HIGH SCHOOL",
  [HighSchool.VegaHighSchool]: "VEGA HIGH SCHOOL",
  [HighSchool.HudsonHighSchool]: "HUDSON HIGH SCHOOL",
  [HighSchool.ColemanHighSchool]: "COLEMAN HIGH SCHOOL",
  [HighSchool.CorriganCamdenHighSchool]: "CORRIGAN-CAMDEN HIGH SCHOOL",
  [HighSchool.WhiteDeerHighSchool]: "WHITE DEER HIGH SCHOOL",
  [HighSchool.LovejoyHighSchool]: "LOVEJOY HIGH SCHOOL",
  [HighSchool.KarnackHighSchool]: "KARNACK HIGH SCHOOL",
  [HighSchool.DawsonSchool]: "DAWSON SCHOOL",
  [HighSchool.BlueRidgeHighSchool]: "BLUE RIDGE HIGH SCHOOL",
  [HighSchool.BosquevilleSchoolSecondarySchool]:
    "BOSQUEVILLE SCHOOL SECONDARY SCHOOL",
  [HighSchool.BrownfieldHighSchool]: "BROWNFIELD HIGH SCHOOL",
  [HighSchool.SundownHighSchool]: "SUNDOWN HIGH SCHOOL",
  [HighSchool.GodleyHighSchool]: "GODLEY HIGH SCHOOL",
  [HighSchool.MildredHighSchool]: "MILDRED HIGH SCHOOL",
  [HighSchool.DublinHighSchool]: "DUBLIN HIGH SCHOOL",
  [HighSchool.BrackettHighSchool]: "BRACKETT HIGH SCHOOL",
  [HighSchool.JasperHighSchoolPlano]: "JASPER HIGH SCHOOL (PLANO)",
  [HighSchool.DavidWCarterHighSchool]: "DAVID W CARTER HIGH SCHOOL",
  [HighSchool.ForneyHighSchool]: "FORNEY HIGH SCHOOL",
  [HighSchool.WaskomHighSchool]: "WASKOM HIGH SCHOOL",
  [HighSchool.LamarHighSchoolArlington]: "LAMAR HIGH SCHOOL (ARLINGTON)",
  [HighSchool.SlidellJHHighSchool]: "SLIDELL J H / HIGH SCHOOL",
  [HighSchool.VeteransMemorialHighSchoolBrownsville]:
    "VETERANS MEMORIAL HIGH SCHOOL (BROWNSVILLE)",
  [HighSchool.JohnHGuyerHighSchool]: "JOHN H. GUYER HIGH SCHOOL",
  [HighSchool.AlvordHighSchool]: "ALVORD HIGH SCHOOL",
  [HighSchool.MansfieldLegacyHighSchool]: "MANSFIELD LEGACY HIGH SCHOOL",
  [HighSchool.EastBernardHighSchool]: "EAST BERNARD HIGH SCHOOL",
  [HighSchool.VanZandtSsa]: "VAN ZANDT SSA",
  [HighSchool.SeminoleHighSchool]: "SEMINOLE HIGH SCHOOL",
  [HighSchool.HannaHighSchool]: "HANNA HIGH SCHOOL",
  [HighSchool.SachseHighSchool]: "SACHSE HIGH SCHOOL",
  [HighSchool.WestRuskHighSchool]: "WEST RUSK HIGH SCHOOL",
  [HighSchool.RandallHighSchool]: "RANDALL HIGH SCHOOL",
  [HighSchool.NorthGarlandHighSchool]: "NORTH GARLAND HIGH SCHOOL",
  [HighSchool.ChirenoHighSchool]: "CHIRENO HIGH SCHOOL",
  [HighSchool.TompkinsHighSchool]: "TOMPKINS HIGH SCHOOL",
  [HighSchool.WestwoodHighSchoolWestwood]: "WESTWOOD HIGH SCHOOL (WESTWOOD)",
  [HighSchool.CreekviewHighSchool]: "CREEKVIEW HIGH SCHOOL",
  [HighSchool.WarrenHighSchoolWarren]: "WARREN HIGH SCHOOL (WARREN)",
  [HighSchool.IngramTomMooreSecondarySchool]:
    "INGRAM-TOM MOORE SECONDARY SCHOOL",
  [HighSchool.RayHighSchool]: "RAY HIGH SCHOOL",
  [HighSchool.DibollHighSchool]: "DIBOLL HIGH SCHOOL",
  [HighSchool.SanBenitoHighSchool]: "SAN BENITO HIGH SCHOOL",
  [HighSchool.DimmittHighSchool]: "DIMMITT HIGH SCHOOL",
  [HighSchool.BridgeportHighSchool]: "BRIDGEPORT HIGH SCHOOL",
  [HighSchool.BrazosportHighSchool]: "BRAZOSPORT HIGH SCHOOL",
  [HighSchool.StaffordHighSchool]: "STAFFORD HIGH SCHOOL",
  [HighSchool.PanhandleHighSchool]: "PANHANDLE HIGH SCHOOL",
  [HighSchool.CantonHighSchool]: "CANTON HIGH SCHOOL",
  [HighSchool.UnionHillHighSchool]: "UNION HILL HIGH SCHOOL",
  [HighSchool.ShamrockHighSchool]: "SHAMROCK HIGH SCHOOL",
  [HighSchool.SlocumHighSchool]: "SLOCUM HIGH SCHOOL",
  [HighSchool.BigSpringHighSchool]: "BIG SPRING HIGH SCHOOL",
  [HighSchool.MalakoffHighSchool]: "MALAKOFF HIGH SCHOOL",
  [HighSchool.StantonHighSchool]: "STANTON HIGH SCHOOL",
  [HighSchool.LouiseHighSchool]: "LOUISE HIGH SCHOOL",
  [HighSchool.EulaHighSchool]: "EULA HIGH SCHOOL",
  [HighSchool.TexlineSchool]: "TEXLINE SCHOOL",
  [HighSchool.EraSchool]: "ERA SCHOOL",
  [HighSchool.HondoHighSchool]: "HONDO HIGH SCHOOL",
  [HighSchool.FairviewAcceleratedChristoval]:
    "FAIRVIEW ACCELERATED (CHRISTOVAL)",
  [HighSchool.SeymourHighSchool]: "SEYMOUR HIGH SCHOOL",
  [HighSchool.AcademyHighSchool]: "ACADEMY HIGH SCHOOL",
  [HighSchool.PoteetHighSchoolPoteet]: "POTEET HIGH SCHOOL (POTEET)",
  [HighSchool.PettusSecondarySchool]: "PETTUS SECONDARY SCHOOL",
  [HighSchool.AvingerSchool]: "AVINGER SCHOOL",
  [HighSchool.StockdaleHighSchool]: "STOCKDALE HIGH SCHOOL",
  [HighSchool.MansfieldLakeRidgeHighSchool]: "MANSFIELD LAKE RIDGE HIGH SCHOOL",
  [HighSchool.TrentonHighSchool]: "TRENTON HIGH SCHOOL",
  [HighSchool.McmullenCountySchool]: "MCMULLEN COUNTY SCHOOL",
  [HighSchool.CollegeStationHighSchool]: "COLLEGE STATION HIGH SCHOOL",
  [HighSchool.GoldthwaiteHighSchool]: "GOLDTHWAITE HIGH SCHOOL",
  [HighSchool.CleburneHighSchool]: "CLEBURNE HIGH SCHOOL",
  [HighSchool.WeslacoHighSchool]: "WESLACO HIGH SCHOOL",
  [HighSchool.TidehavenHighSchool]: "TIDEHAVEN HIGH SCHOOL",
  [HighSchool.MadisonvilleHighSchool]: "MADISONVILLE HIGH SCHOOL",
  [HighSchool.RobertELeeHighSchool]: "ROBERT E LEE HIGH SCHOOL",
  [HighSchool.PlainsHighSchool]: "PLAINS HIGH SCHOOL",
  [HighSchool.NewDealHighSchool]: "NEW DEAL HIGH SCHOOL",
  [HighSchool.DelRioHighSchool]: "DEL RIO HIGH SCHOOL",
  [HighSchool.VirginiaAllredStaceyJuniorSeniorHighSchool]:
    "VIRGINIA ALLRED STACEY JUNIOR/SENIOR HIGH SCHOOL",
  [HighSchool.BigSandySchool]: "BIG SANDY SCHOOL",
  [HighSchool.CollinsvilleHighSchool]: "COLLINSVILLE HIGH SCHOOL",
  [HighSchool.FarmersvilleHighSchool]: "FARMERSVILLE HIGH SCHOOL",
  [HighSchool.RandolphHighSchool]: "RANDOLPH HIGH SCHOOL",
  [HighSchool.LohnSchool]: "LOHN SCHOOL",
  [HighSchool.EastlandCareCampus]: "EASTLAND CARE CAMPUS",
  [HighSchool.RogersHighSchool]: "ROGERS HIGH SCHOOL",
  [HighSchool.ClaudeJrSrHighSchool]: "CLAUDE JR SR HIGH SCHOOL",
  [HighSchool.BrandeisHighSchool]: "BRANDEIS HIGH SCHOOL",
  [HighSchool.NormangeeHighSchool]: "NORMANGEE HIGH SCHOOL",
  [HighSchool.CarrollSeniorHighSchool]: "CARROLL SENIOR HIGH SCHOOL",
  [HighSchool.PaloDuroHS]: "PALO DURO H S",
  [HighSchool.SandsCisd]: "SANDS CISD",
  [HighSchool.WestSabineHighSchool]: "WEST SABINE HIGH SCHOOL",
  [HighSchool.ElCampoHighSchool]: "EL CAMPO HIGH SCHOOL",
  [HighSchool.CueroHighSchool]: "CUERO HIGH SCHOOL",
  [HighSchool.JohnTylerHighSchool]: "JOHN TYLER HIGH SCHOOL",
  [HighSchool.ChannelviewHighSchool]: "CHANNELVIEW HIGH SCHOOL",
  [HighSchool.WilliamsonCoAcademy]: "WILLIAMSON CO ACADEMY",
  [HighSchool.MeadowSchool]: "MEADOW SCHOOL",
  [HighSchool.TrinidadSchool]: "TRINIDAD SCHOOL",
  [HighSchool.FollettSchool]: "FOLLETT SCHOOL",
  [HighSchool.GradySchool]: "GRADY SCHOOL",
  [HighSchool.IndustrialHighSchool]: "INDUSTRIAL HIGH SCHOOL",
  [HighSchool.LincolnHumanitiesCommunicationsMagnetHighSchool]:
    "LINCOLN HUMANITIES/COMMUNICATIONS MAGNET HIGH SCHOOL",
  [HighSchool.JordanHighSchool]: "JORDAN HIGH SCHOOL",
  [HighSchool.GarlandHighSchool]: "GARLAND HIGH SCHOOL",
  [HighSchool.WestBrookHighSchool]: "WEST BROOK HIGH SCHOOL",
  [HighSchool.SantaFeHighSchool]: "SANTA FE HIGH SCHOOL",
  [HighSchool.LaMarqueHighSchool]: "LA MARQUE HIGH SCHOOL",
  [HighSchool.KeltonSchool]: "KELTON SCHOOL",
  [HighSchool.MineralWellsHighSchool]: "MINERAL WELLS HIGH SCHOOL",
  [HighSchool.MillsapHighSchool]: "MILLSAP HIGH SCHOOL",
  [HighSchool.SanPerlitaHighSchool]: "SAN PERLITA HIGH SCHOOL",
  [HighSchool.StonyPointHighSchool]: "STONY POINT HIGH SCHOOL",
  [HighSchool.RiverRoadHighSchool]: "RIVER ROAD HIGH SCHOOL",
  [HighSchool.WaelderSchool]: "WAELDER SCHOOL",
  [HighSchool.JerseyVillageHighSchool]: "JERSEY VILLAGE HIGH SCHOOL",
  [HighSchool.RedwaterHighSchool]: "REDWATER HIGH SCHOOL",
  [HighSchool.RotanHighSchool]: "ROTAN HIGH SCHOOL",
  [HighSchool.WheatleyHighSchool]: "WHEATLEY HIGH SCHOOL",
  [HighSchool.PostHighSchool]: "POST HIGH SCHOOL",
  [HighSchool.OlneyHighSchool]: "OLNEY HIGH SCHOOL",
  [HighSchool.OConnorHighSchool]: "O'CONNOR HIGH SCHOOL",
  [HighSchool.WestburyHighSchool]: "WESTBURY HIGH SCHOOL",
  [HighSchool.NazarethSchool]: "NAZARETH SCHOOL",
  [HighSchool.HaysHighSchool]: "HAYS HIGH SCHOOL",
  [HighSchool.MacarthurHighSchoolAldine]: "MACARTHUR HIGH SCHOOL (ALDINE)",
  [HighSchool.SidneySchool]: "SIDNEY SCHOOL",
  [HighSchool.LasaraMiddleHighSchool]: "LASARA MIDDLE/HIGH SCHOOL",
  [HighSchool.JeffersonHighSchoolSanAntonio]:
    "JEFFERSON HIGH SCHOOL (SAN ANTONIO)",
  [HighSchool.JonesboroSchool]: "JONESBORO SCHOOL",
  [HighSchool.MidwayHighSchool]: "MIDWAY HIGH SCHOOL",
  [HighSchool.ComoPicktonSchool]: "COMO-PICKTON SCHOOL",
  [HighSchool.PremontHighSchool]: "PREMONT HIGH SCHOOL",
  [HighSchool.LakeviewCentennialHighSchool]: "LAKEVIEW CENTENNIAL HIGH SCHOOL",
  [HighSchool.ConroeHighSchool]: "CONROE HIGH SCHOOL",
  [HighSchool.RomaHighSchool]: "ROMA HIGH SCHOOL",
  [HighSchool.SkidmoreTynanHighSchool]: "SKIDMORE-TYNAN HIGH SCHOOL",
  [HighSchool.AustwellTivoliHighSchool]: "AUSTWELL-TIVOLI HIGH SCHOOL",
  [HighSchool.BunaHighSchool]: "BUNA HIGH SCHOOL",
  [HighSchool.MckinneyNorthHS]: "MCKINNEY NORTH H S",
  [HighSchool.DawsonHighSchool]: "DAWSON HIGH SCHOOL",
  [HighSchool.PaulLaurenceDunbarHighSchool]: "PAUL LAURENCE DUNBAR HIGH SCHOOL",
  [HighSchool.RoyalHighSchool]: "ROYAL HIGH SCHOOL",
  [HighSchool.GroesbeckHighSchool]: "GROESBECK HIGH SCHOOL",
  [HighSchool.SevenLakesHighSchool]: "SEVEN LAKES HIGH SCHOOL",
  [HighSchool.RochelleSchool]: "ROCHELLE SCHOOL",
  [HighSchool.PflugervilleHighSchool]: "PFLUGERVILLE HIGH SCHOOL",
  [HighSchool.HermleighSchool]: "HERMLEIGH SCHOOL",
  [HighSchool.NimitzHighSchoolAldine]: "NIMITZ HIGH SCHOOL (ALDINE)",
  [HighSchool.CrystalCityHighSchool]: "CRYSTAL CITY HIGH SCHOOL",
  [HighSchool.TexhomaElementarySchool]: "TEXHOMA ELEMENTARY SCHOOL",
  [HighSchool.HooksHighSchool]: "HOOKS HIGH SCHOOL",
  [HighSchool.SeminoleSuccessCenter]: "SEMINOLE SUCCESS CENTER",
  [HighSchool.ThreeRiversHighSchool]: "THREE RIVERS HIGH SCHOOL",
  [HighSchool.DrLeoCigarroaHighSchool]: "DR LEO CIGARROA HIGH SCHOOL",
  [HighSchool.SanSabaHighSchool]: "SAN SABA HIGH SCHOOL",
  [HighSchool.WinkHighSchool]: "WINK HIGH SCHOOL",
  [HighSchool.CotullaHighSchool]: "COTULLA HIGH SCHOOL",
  [HighSchool.HarperHighSchool]: "HARPER HIGH SCHOOL",
  [HighSchool.DickinsonHighSchool]: "DICKINSON HIGH SCHOOL",
  [HighSchool.PrairilandHighSchool]: "PRAIRILAND HIGH SCHOOL",
  [HighSchool.PaintRockSchool]: "PAINT ROCK SCHOOL",
  [HighSchool.BowieHighSchoolAustin]: "BOWIE HIGH SCHOOL (AUSTIN)",
  [HighSchool.CrockettHighSchoolAustin]: "CROCKETT HIGH SCHOOL (AUSTIN)",
  [HighSchool.NewcastleSchool]: "NEWCASTLE SCHOOL",
  [HighSchool.NewHomeSchool]: "NEW HOME SCHOOL",
  [HighSchool.ColumbiaHighSchool]: "COLUMBIA HIGH SCHOOL",
  [HighSchool.JourdantonHighSchool]: "JOURDANTON HIGH SCHOOL",
  [HighSchool.OvertonHighSchool]: "OVERTON HIGH SCHOOL",
  [HighSchool.GreenvilleHighSchool]: "GREENVILLE HIGH SCHOOL",
  [HighSchool.TomballMemorialHS]: "TOMBALL MEMORIAL H S",
  [HighSchool.CypressSpringsHighSchool]: "CYPRESS SPRINGS HIGH SCHOOL",
  [HighSchool.CalvertSchool]: "CALVERT SCHOOL",
  [HighSchool.MineralWellsAcademy]: "MINERAL WELLS ACADEMY",
  [HighSchool.SouthwestHighSchoolSouthwest]:
    "SOUTHWEST HIGH SCHOOL (SOUTHWEST)",
  [HighSchool.KnoxCityHighSchool]: "KNOX CITY HIGH SCHOOL",
  [HighSchool.SterlingCityHighSchool]: "STERLING CITY HIGH SCHOOL",
  [HighSchool.TahokaHighSchool]: "TAHOKA HIGH SCHOOL",
  [HighSchool.FayettevilleHighSchool]: "FAYETTEVILLE HIGH SCHOOL",
  [HighSchool.ValentineSchool]: "VALENTINE SCHOOL",
  [HighSchool.ChicoHighSchool]: "CHICO HIGH SCHOOL",
  [HighSchool.EvantHighSchool]: "EVANT HIGH SCHOOL",
  [HighSchool.MarcusHighSchool]: "MARCUS HIGH SCHOOL",
  [HighSchool.LeggettHighSchool]: "LEGGETT HIGH SCHOOL",
  [HighSchool.CrowellHighSchool]: "CROWELL HIGH SCHOOL",
  [HighSchool.AdrianSchool]: "ADRIAN SCHOOL",
  [HighSchool.MidlandFreshmanHighSchool]: "MIDLAND FRESHMAN HIGH SCHOOL",
  [HighSchool.MesquiteHighSchool]: "MESQUITE HIGH SCHOOL",
  [HighSchool.AnthonyHighSchool]: "ANTHONY HIGH SCHOOL",
  [HighSchool.RockportFultonHighSchool]: "ROCKPORT-FULTON HIGH SCHOOL",
  [HighSchool.GrapevineHighSchool]: "GRAPEVINE HIGH SCHOOL",
  [HighSchool.BryanAdamsHighSchool]: "BRYAN ADAMS HIGH SCHOOL",
  [HighSchool.BurbankHighSchool]: "BURBANK HIGH SCHOOL",
  [HighSchool.SabinePassSchool]: "SABINE PASS SCHOOL",
  [HighSchool.AmonCarterRiversideHighSchool]:
    "AMON CARTER-RIVERSIDE HIGH SCHOOL",
  [HighSchool.BellaireHighSchool]: "BELLAIRE HIGH SCHOOL",
  [HighSchool.CarverHSForAppliedTechEngineeringArts]:
    "CARVER H S FOR APPLIED TECH/ENGINEERING/ARTS",
  [HighSchool.CommunityHighSchool]: "COMMUNITY HIGH SCHOOL",
  [HighSchool.ArlingtonHeightsHighSchool]: "ARLINGTON HEIGHTS HIGH SCHOOL",
  [HighSchool.MarbleFallsHighSchool]: "MARBLE FALLS HIGH SCHOOL",
  [HighSchool.BookerJuniorSeniorHighSchool]: "BOOKER JUNIOR-SENIOR HIGH SCHOOL",
  [HighSchool.FrenshipHighSchool]: "FRENSHIP HIGH SCHOOL",
  [HighSchool.RisingStarHighSchool]: "RISING STAR HIGH SCHOOL",
  [HighSchool.BrookhavenYouthRanch]: "BROOKHAVEN YOUTH RANCH",
  [HighSchool.SmithsonValleyHighSchool]: "SMITHSON VALLEY HIGH SCHOOL",
  [HighSchool.HedleySchool]: "HEDLEY SCHOOL",
  [HighSchool.LibertyHighSchoolLiberty]: "LIBERTY HIGH SCHOOL (LIBERTY)",
  [HighSchool.BoerneSamuelVChampionHighSchool]:
    "BOERNE - SAMUEL V CHAMPION HIGH SCHOOL",
  [HighSchool.WintersHighSchool]: "WINTERS HIGH SCHOOL",
  [HighSchool.BuffaloHighSchool]: "BUFFALO HIGH SCHOOL",
  [HighSchool.DevineHighSchool]: "DEVINE HIGH SCHOOL",
  [HighSchool.SouthGrandPrairieHigh9ThGradeCenter]:
    "SOUTH GRAND PRAIRIE HIGH 9TH GRADE CENTER",
  [HighSchool.BloomingGroveHighSchool]: "BLOOMING GROVE HIGH SCHOOL",
  [HighSchool.MathisHighSchool]: "MATHIS HIGH SCHOOL",
  [HighSchool.MartinHighSchoolArlington]: "MARTIN HIGH SCHOOL (ARLINGTON)",
  [HighSchool.SaltilloSchool]: "SALTILLO SCHOOL",
  [HighSchool.JoaquinHighSchool]: "JOAQUIN HIGH SCHOOL",
  [HighSchool.BrockHighSchool]: "BROCK HIGH SCHOOL",
  [HighSchool.HarletonHighSchool]: "HARLETON HIGH SCHOOL",
  [HighSchool.WarrenHighSchoolNorthside]: "WARREN HIGH SCHOOL (NORTHSIDE)",
  [HighSchool.HollidayHighSchool]: "HOLLIDAY HIGH SCHOOL",
  [HighSchool.AldineHighSchool]: "ALDINE HIGH SCHOOL",
  [HighSchool.AnnaHighSchool]: "ANNA HIGH SCHOOL",
  [HighSchool.RainsHighSchool]: "RAINS HIGH SCHOOL",
  [HighSchool.MilesHighSchool]: "MILES HIGH SCHOOL",
  [HighSchool.HuntsvilleHighSchool]: "HUNTSVILLE HIGH SCHOOL",
  [HighSchool.BruniHighSchool]: "BRUNI HIGH SCHOOL",
  [HighSchool.RoyseCityHS]: "ROYSE CITY H S",
  [HighSchool.AntonHighSchoolJuniorHighSchool]:
    "ANTON HIGH SCHOOL  JUNIOR HIGH SCHOOL",
  [HighSchool.ClearLakeHighSchool]: "CLEAR LAKE HIGH SCHOOL",
  [HighSchool.LeonardHighSchool]: "LEONARD HIGH SCHOOL",
  [HighSchool.GrovetonJHHS]: "GROVETON J H-H S",
  [HighSchool.ChipRicharteHighSchool]: "CHIP RICHARTE HIGH SCHOOL",
  [HighSchool.PalestineHighSchool]: "PALESTINE HIGH SCHOOL",
  [HighSchool.KleinCainHighSchool]: "KLEIN CAIN HIGH SCHOOL",
  [HighSchool.AspermontHighSchool]: "ASPERMONT HIGH SCHOOL",
  [HighSchool.DimeBoxSchool]: "DIME BOX SCHOOL",
  [HighSchool.LlanoHighSchool]: "LLANO HIGH SCHOOL",
  [HighSchool.MckinneyHighSchool]: "MCKINNEY HIGH SCHOOL",
  [HighSchool.HartleySchool]: "HARTLEY SCHOOL",
  [HighSchool.ItascaHighSchool]: "ITASCA HIGH SCHOOL",
  [HighSchool.McleodHighSchool]: "MCLEOD HIGH SCHOOL",
  [HighSchool.ClarkHighSchoolNorthside]: "CLARK HIGH SCHOOL (NORTHSIDE)",
  [HighSchool.LongviewHighSchool]: "LONGVIEW HIGH SCHOOL",
  [HighSchool.PsjaSouthwestHighSchool]: "PSJA SOUTHWEST HIGH SCHOOL",
  [HighSchool.LampasasHight]: "LAMPASAS HIGHT",
  [HighSchool.TaftHighSchoolNorthside]: "TAFT HIGH SCHOOL (NORTHSIDE)",
  [HighSchool.EisenhowerHighSchool]: "EISENHOWER HIGH SCHOOL",
  [HighSchool.BalmorheaSchool]: "BALMORHEA SCHOOL",
  [HighSchool.RiderHighSchool]: "RIDER HIGH SCHOOL",
  [HighSchool.PrairieValleyJuniorHighHighSchool]:
    "PRAIRIE VALLEY JUNIOR HIGH HIGH SCHOOL",
  [HighSchool.PothHighSchool]: "POTH HIGH SCHOOL",
  [HighSchool.LazbuddieSchool]: "LAZBUDDIE SCHOOL",
  [HighSchool.LoopSchool]: "LOOP SCHOOL",
  [HighSchool.ChesterHighSchool]: "CHESTER HIGH SCHOOL",
  [HighSchool.CaldwellHighSchool]: "CALDWELL HIGH SCHOOL",
  [HighSchool.SeagovilleHighSchool]: "SEAGOVILLE HIGH SCHOOL",
  [HighSchool.ACJonesHighSchool]: "A C JONES HIGH SCHOOL",
  [HighSchool.OzonaHighSchool]: "OZONA HIGH SCHOOL",
  [HighSchool.CyFairHighSchool]: "CY-FAIR HIGH SCHOOL",
  [HighSchool.ForestburgSchool]: "FORESTBURG SCHOOL",
  [HighSchool.WinonaHighSchool]: "WINONA HIGH SCHOOL",
  [HighSchool.TornilloHighSchool]: "TORNILLO HIGH SCHOOL",
  [HighSchool.BurlesonHighSchool]: "BURLESON HIGH SCHOOL",
  [HighSchool.MidwaySchool]: "MIDWAY SCHOOL",
  [HighSchool.HughesSpringsHighSchool]: "HUGHES SPRINGS HIGH SCHOOL",
  [HighSchool.BullardHighSchool]: "BULLARD HIGH SCHOOL",
  [HighSchool.GlasscockCountyHighSchool]: "GLASSCOCK COUNTY HIGH SCHOOL",
  [HighSchool.JayHighSchool]: "JAY HIGH SCHOOL",
  [HighSchool.AtascocitaHighSchool]: "ATASCOCITA HIGH SCHOOL",
  [HighSchool.LyndonBJohnsonHighSchoolUnited]:
    "LYNDON B JOHNSON HIGH SCHOOL (UNITED)",
  [HighSchool.RefugioHighSchool]: "REFUGIO HIGH SCHOOL",
  [HighSchool.GatesvilleHighSchool]: "GATESVILLE HIGH SCHOOL",
  [HighSchool.BushHighSchool]: "BUSH HIGH SCHOOL",
  [HighSchool.GanadoHighSchool]: "GANADO HIGH SCHOOL",
  [HighSchool.AbbottSchool]: "ABBOTT SCHOOL",
  [HighSchool.HawkinsHighSchool]: "HAWKINS HIGH SCHOOL",
  [HighSchool.DellCitySchool]: "DELL CITY SCHOOL",
  [HighSchool.HarkerHeightsHighSchool]: "HARKER HEIGHTS HIGH SCHOOL",
  [HighSchool.LyndonBJohnsonHighSchoolJohnsonCity]:
    "LYNDON B JOHNSON HIGH SCHOOL (JOHNSON CITY)",
  [HighSchool.EastCentralHS]: "EAST CENTRAL H S",
  [HighSchool.XITSecondarySchool]: "X I T SECONDARY SCHOOL",
  [HighSchool.AndrewsHighSchool]: "ANDREWS HIGH SCHOOL",
  [HighSchool.MartinsMillHighSchool]: "MARTINS MILL HIGH SCHOOL",
  [HighSchool.LibertyEylauHighSchool]: "LIBERTY-EYLAU HIGH SCHOOL",
  [HighSchool.CommerceHighSchool]: "COMMERCE HIGH SCHOOL",
  [HighSchool.StubblefieldLearningCenter]: "STUBBLEFIELD LEARNING CENTER",
  [HighSchool.AmarilloHighSchool]: "AMARILLO HIGH SCHOOL",
  [HighSchool.CranfillsGapSchool]: "CRANFILLS GAP SCHOOL",
  [HighSchool.PalaciosHighSchool]: "PALACIOS HIGH SCHOOL",
  [HighSchool.LittlefieldHighSchool]: "LITTLEFIELD HIGH SCHOOL",
  [HighSchool.CentennialHighSchoolFrisco]: "CENTENNIAL HIGH SCHOOL (FRISCO)",
  [HighSchool.DuncanvilleHighSchool]: "DUNCANVILLE HIGH SCHOOL",
  [HighSchool.PsjaHighSchool]: "PSJA HIGH SCHOOL",
  [HighSchool.DewittLavacaSpecialEdCoOp]: "DEWITT-LAVACA SPECIAL ED CO-OP",
  [HighSchool.AguaDulceHighSchool]: "AGUA DULCE HIGH SCHOOL",
  [HighSchool.BurnetHighSchool]: "BURNET HIGH SCHOOL",
  [HighSchool.JohnHornHighSchool]: "JOHN HORN HIGH SCHOOL",
  [HighSchool.CrossRoadsHighSchool]: "CROSS ROADS HIGH SCHOOL",
  [HighSchool.EdinburgHighSchool]: "EDINBURG HIGH SCHOOL",
  [HighSchool.SandersonHighSchool]: "SANDERSON HIGH SCHOOL",
  [HighSchool.PlainviewHighSchool]: "PLAINVIEW HIGH SCHOOL",
  [HighSchool.RobinsonHighSchool]: "ROBINSON HIGH SCHOOL",
  [HighSchool.AlvaradoHighSchool]: "ALVARADO HIGH SCHOOL",
  [HighSchool.HartJuniorSeniorHighSchool]: "HART JUNIOR-SENIOR HIGH SCHOOL",
  [HighSchool.CanyonHighSchoolCanyon]: "CANYON HIGH SCHOOL (CANYON)",
  [HighSchool.RichardsonHighSchool]: "RICHARDSON HIGH SCHOOL",
  [HighSchool.MoranSchool]: "MORAN SCHOOL",
  [HighSchool.NewWaverlyHighSchool]: "NEW WAVERLY HIGH SCHOOL",
  [HighSchool.NorthMesquiteHighSchool]: "NORTH MESQUITE HIGH SCHOOL",
  [HighSchool.TopOfTexasAcceleratedEducationCenter]:
    "TOP OF TEXAS ACCELERATED EDUCATION CENTER",
  [HighSchool.NixonHighSchool]: "NIXON HIGH SCHOOL",
  [HighSchool.WorthingHighSchool]: "WORTHING HIGH SCHOOL",
  [HighSchool.VanHornHS]: "VAN HORN H S",
  [HighSchool.BrysonMsHighSchool]: "BRYSON MS HIGH SCHOOL",
  [HighSchool.BroaddusHighSchool]: "BROADDUS HIGH SCHOOL",
  [HighSchool.KleinHighSchool]: "KLEIN HIGH SCHOOL",
  [HighSchool.WalnutSpringsSchool]: "WALNUT SPRINGS SCHOOL",
  [HighSchool.ChapelHillHighSchool]: "CHAPEL HILL HIGH SCHOOL",
  [HighSchool.FosterHighSchool]: "FOSTER HIGH SCHOOL",
  [HighSchool.CenterPointHighSchool]: "CENTER POINT HIGH SCHOOL",
  [HighSchool.SpurgerHighSchool]: "SPURGER HIGH SCHOOL",
  [HighSchool.KopperlSchool]: "KOPPERL SCHOOL",
  [HighSchool.FranklinDRooseveltHighSchool]: "FRANKLIN D ROOSEVELT HIGH SCHOOL",
  [HighSchool.MexiaHighSchool]: "MEXIA HIGH SCHOOL",
  [HighSchool.PampaHighSchool]: "PAMPA HIGH SCHOOL",
  [HighSchool.GrapelandHighSchool]: "GRAPELAND HIGH SCHOOL",
  [HighSchool.AthensHighSchool]: "ATHENS HIGH SCHOOL",
  [HighSchool.AmericasHighSchool]: "AMERICAS HIGH SCHOOL",
  [HighSchool.RungeHighSchool]: "RUNGE HIGH SCHOOL",
  [HighSchool.ChristovalHighSchool]: "CHRISTOVAL HIGH SCHOOL",
  [HighSchool.CelesteHighSchool]: "CELESTE HIGH SCHOOL",
  [HighSchool.CedarParkHS]: "CEDAR PARK H S",
  [HighSchool.BrownwoodHighSchool]: "BROWNWOOD HIGH SCHOOL",
  [HighSchool.KarnesCityHighSchool]: "KARNES CITY HIGH SCHOOL",
  [HighSchool.WellsHighSchool]: "WELLS HIGH SCHOOL",
  [HighSchool.MontwoodHighSchool]: "MONTWOOD HIGH SCHOOL",
  [HighSchool.CrockettHighSchoolCrockett]: "CROCKETT HIGH SCHOOL (CROCKETT)",
  [HighSchool.BradyHighSchool]: "BRADY HIGH SCHOOL",
  [HighSchool.CypressRanchHighSchool]: "CYPRESS RANCH HIGH SCHOOL",
  [HighSchool.LindenKildareHighSchool]: "LINDEN-KILDARE HIGH SCHOOL",
  [HighSchool.KingHighSchool]: "KING HIGH SCHOOL",
  [HighSchool.IowaParkHighSchool]: "IOWA PARK HIGH SCHOOL",
  [HighSchool.VictoriaEastHighSchool]: "VICTORIA EAST HIGH SCHOOL",
  [HighSchool.FranklinHighSchoolElPaso]: "FRANKLIN HIGH SCHOOL (EL PASO)",
  [HighSchool.RioHondoHighSchool]: "RIO HONDO HIGH SCHOOL",
  [HighSchool.MumfordHighSchool]: "MUMFORD HIGH SCHOOL",
  [HighSchool.LanevilleSchool]: "LANEVILLE SCHOOL",
  [HighSchool.HerefordHighSchool]: "HEREFORD HIGH SCHOOL",
  [HighSchool.HMKingHighSchool]: "H.M. KING HIGH SCHOOL",
  [HighSchool.SantaGertrudisAcademyHighSchool]:
    "SANTA GERTRUDIS ACADEMY HIGH SCHOOL",
  [HighSchool.BrewerHighSchool]: "BREWER HIGH SCHOOL",
  [HighSchool.BolingHighSchool]: "BOLING HIGH SCHOOL",
  [HighSchool.AxtellHighSchool]: "AXTELL HIGH SCHOOL",
  [HighSchool.CarthageHighSchool]: "CARTHAGE HIGH SCHOOL",
  [HighSchool.SamRayburnHighSchool]: "SAM RAYBURN HIGH SCHOOL",
  [HighSchool.GlenRoseHighSchool]: "GLEN ROSE HIGH SCHOOL",
  [HighSchool.LivingstonHighSchool]: "LIVINGSTON HIGH SCHOOL",
  [HighSchool.SeguinHighSchoolSeguin]: "SEGUIN HIGH SCHOOL (SEGUIN)",
  [HighSchool.AkinsHighSchool]: "AKINS HIGH SCHOOL",
  [HighSchool.MarshallHighSchoolMarshall]: "MARSHALL HIGH SCHOOL (MARSHALL)",
  [HighSchool.JeffersonHighSchoolJefferson]:
    "JEFFERSON HIGH SCHOOL (JEFFERSON)",
  [HighSchool.WhitefaceHighSchool]: "WHITEFACE HIGH SCHOOL",
  [HighSchool.BallHighSchool]: "BALL HIGH SCHOOL",
  [HighSchool.JamesBowieHighSchool]: "JAMES BOWIE HIGH SCHOOL",
  [HighSchool.PortIsabelHS]: "PORT ISABEL H S",
  [HighSchool.RiveraHighSchool]: "RIVERA HIGH SCHOOL",
  [HighSchool.TuliaHighSchool]: "TULIA HIGH SCHOOL",
  [HighSchool.AliceHighSchool]: "ALICE HIGH SCHOOL",
  [HighSchool.SAndSConsHighSchool]: "S AND S CONS HIGH SCHOOL",
  [HighSchool.CincoRanchHighSchool]: "CINCO RANCH HIGH SCHOOL",
  [HighSchool.HightowerHighSchool]: "HIGHTOWER HIGH SCHOOL",
  [HighSchool.FortHancockHighSchool]: "FORT HANCOCK HIGH SCHOOL",
  [HighSchool.ConnallyHighSchoolPflugerville]:
    "CONNALLY HIGH SCHOOL (PFLUGERVILLE)",
  [HighSchool.LakeCreekHighSchool]: "LAKE CREEK HIGH SCHOOL",
  [HighSchool.WhitesboroHighSchool]: "WHITESBORO HIGH SCHOOL",
  [HighSchool.BerknerHighSchool]: "BERKNER HIGH SCHOOL",
  [HighSchool.WoodsboroHighSchool]: "WOODSBORO HIGH SCHOOL",
  [HighSchool.ChapinHighSchool]: "CHAPIN HIGH SCHOOL",
  [HighSchool.UniversityHighSchool]: "UNIVERSITY HIGH SCHOOL",
  [HighSchool.TascosaHighSchool]: "TASCOSA HIGH SCHOOL",
  [HighSchool.GoodrichHighSchool]: "GOODRICH HIGH SCHOOL",
  [HighSchool.FalfurriasHighSchool]: "FALFURRIAS HIGH SCHOOL",
  [HighSchool.VanAlstyneHighSchool]: "VAN ALSTYNE HIGH SCHOOL",
  [HighSchool.LoveladyJHHighSchool]: "LOVELADY J H HIGH SCHOOL",
  [HighSchool.NavasotaHighSchool]: "NAVASOTA HIGH SCHOOL",
  [HighSchool.BlumHighSchool]: "BLUM HIGH SCHOOL",
  [HighSchool.Other]: "OTHER",
  [HighSchool.BolesHighSchool]: "BOLES HIGH SCHOOL",
  [HighSchool.NorthRichlandMiddleSchool]: "NORTH RICHLAND MIDDLE SCHOOL",
  [HighSchool.TimpsonHighSchool]: "TIMPSON HIGH SCHOOL",
  [HighSchool.GrapeCreekHighSchool]: "GRAPE CREEK HIGH SCHOOL",
  [HighSchool.ChurchillHighSchool]: "CHURCHILL HIGH SCHOOL",
  [HighSchool.MadisonHighSchoolHouston]: "MADISON HIGH SCHOOL (HOUSTON)",
  [HighSchool.PolytechnicHighSchool]: "POLYTECHNIC HIGH SCHOOL",
  [HighSchool.RoxtonHighSchool]: "ROXTON HIGH SCHOOL",
  [HighSchool.DecaturHighSchool]: "DECATUR HIGH SCHOOL",
  [HighSchool.ElginHighSchool]: "ELGIN HIGH SCHOOL",
  [HighSchool.MarshallHighSchoolNorthside]: "MARSHALL HIGH SCHOOL (NORTHSIDE)",
  [HighSchool.EvadaleHighSchool]: "EVADALE HIGH SCHOOL",
  [HighSchool.LeeHighSchoolNorthEast]: "LEE HIGH SCHOOL (NORTH EAST)",
  [HighSchool.CedarHillHighSchool]: "CEDAR HILL HIGH SCHOOL",
  [HighSchool.YatesHighSchool]: "YATES HIGH SCHOOL",
  [HighSchool.MacarthurHighSchoolNorthEast]:
    "MACARTHUR HIGH SCHOOL (NORTH EAST)",
  [HighSchool.AndersonShiroJrSrHighSchool]: "ANDERSON-SHIRO JR/SR HIGH SCHOOL",
  [HighSchool.LaVerniaHighSchool]: "LA VERNIA HIGH SCHOOL",
  [HighSchool.CrosslandNinthGradeCenter]: "CROSSLAND NINTH GRADE CENTER",
  [HighSchool.KingwoodParkHighSchool]: "KINGWOOD PARK HIGH SCHOOL",
  [HighSchool.DenverCityHighSchool]: "DENVER CITY HIGH SCHOOL",
  [HighSchool.PaetowHighSchool]: "PAETOW HIGH SCHOOL",
  [HighSchool.ChallengeAcademy]: "CHALLENGE ACADEMY",
  [HighSchool.EastlandHighSchool]: "EASTLAND HIGH SCHOOL",
  [HighSchool.HawleyHighSchool]: "HAWLEY HIGH SCHOOL",
  [HighSchool.PorterHighSchoolNewCaney]: "PORTER HIGH SCHOOL (NEW CANEY)",
  [HighSchool.CrowleyHighSchool]: "CROWLEY HIGH SCHOOL",
  [HighSchool.BuckholtsSchool]: "BUCKHOLTS SCHOOL",
  [HighSchool.OdemHighSchool]: "ODEM HIGH SCHOOL",
  [HighSchool.WellmanUnionSchool]: "WELLMAN-UNION SCHOOL",
  [HighSchool.SamuelClemensHighSchool]: "SAMUEL CLEMENS HIGH SCHOOL",
  [HighSchool.SouthsideHighSchool]: "SOUTHSIDE HIGH SCHOOL",
  [HighSchool.CHYoeHighSchool]: "C. H. YOE HIGH SCHOOL",
  [HighSchool.CeKingHighSchool]: "CE KING HIGH SCHOOL",
  [HighSchool.WhitehouseHighSchool]: "WHITEHOUSE HIGH SCHOOL",
  [HighSchool.NorthCrowleyHighSchool]: "NORTH CROWLEY HIGH SCHOOL",
  [HighSchool.TheColonyHighSchool]: "THE COLONY HIGH SCHOOL",
  [HighSchool.BenavidesSecondarySchool]: "BENAVIDES SECONDARY SCHOOL",
  [HighSchool.BellsHighSchool]: "BELLS HIGH SCHOOL",
  [HighSchool.LakeWorthHS]: "LAKE WORTH H S",
  [HighSchool.YantisHighSchool]: "YANTIS HIGH SCHOOL",
  [HighSchool.ClearSpringsHighSchool]: "CLEAR SPRINGS HIGH SCHOOL",
  [HighSchool.IdalouHighSchool]: "IDALOU HIGH SCHOOL",
  [HighSchool.LibertyHighSchoolFrisco]: "LIBERTY HIGH SCHOOL (FRISCO)",
  [HighSchool.DullesHighSchool]: "DULLES HIGH SCHOOL",
  [HighSchool.SaginawHighSchool]: "SAGINAW HIGH SCHOOL",
  [HighSchool.FairfieldHighSchool]: "FAIRFIELD HIGH SCHOOL",
  [HighSchool.TatumHighSchool]: "TATUM HIGH SCHOOL",
  [HighSchool.HighlandsHighSchool]: "HIGHLANDS HIGH SCHOOL",
  [HighSchool.YorktownHighSchool]: "YORKTOWN HIGH SCHOOL",
  [HighSchool.SulphurSpringsHighSchool]: "SULPHUR SPRINGS HIGH SCHOOL",
  [HighSchool.MemorialHighSchoolEdgewood]: "MEMORIAL HIGH SCHOOL (EDGEWOOD)",
  [HighSchool.PlanoWestSeniorHighSchool]: "PLANO WEST SENIOR HIGH SCHOOL",
  [HighSchool.GruverHighSchool]: "GRUVER HIGH SCHOOL",
  [HighSchool.CanyonLakeHS]: "CANYON LAKE H S",
  [HighSchool.SantaAnnaSecondarySchool]: "SANTA ANNA SECONDARY SCHOOL",
  [HighSchool.BeltonHighSchool]: "BELTON HIGH SCHOOL",
  [HighSchool.GonzalesHighSchool]: "GONZALES HIGH SCHOOL",
  [HighSchool.MonteAltoHighSchool]: "MONTE ALTO HIGH SCHOOL",
  [HighSchool.HGradySpruceHighSchool]: "H GRADY SPRUCE HIGH SCHOOL",
  [HighSchool.PetersburgHighSchool]: "PETERSBURG HIGH SCHOOL",
  [HighSchool.LanghamCreekHighSchool]: "LANGHAM CREEK HIGH SCHOOL",
  [HighSchool.SouthSanAntonioHighSchool]: "SOUTH SAN ANTONIO HIGH SCHOOL",
  [HighSchool.IrvinHighSchool]: "IRVIN HIGH SCHOOL",
  [HighSchool.AubreyHighSchool]: "AUBREY HIGH SCHOOL",
  [HighSchool.TempleHighSchool]: "TEMPLE HIGH SCHOOL",
  [HighSchool.LancasterHighSchool]: "LANCASTER HIGH SCHOOL",
  [HighSchool.WoodvilleHighSchool]: "WOODVILLE HIGH SCHOOL",
  [HighSchool.MineolaHighSchool]: "MINEOLA HIGH SCHOOL",
  [HighSchool.BlandHighSchool]: "BLAND HIGH SCHOOL",
  [HighSchool.SantaRosaHS]: "SANTA ROSA H S",
  [HighSchool.LakeHighlandsHS]: "LAKE HIGHLANDS H S",
  [HighSchool.CrandallHighSchool]: "CRANDALL HIGH SCHOOL",
  [HighSchool.JMHanksHighSchool]: "J M HANKS HIGH SCHOOL",
  [HighSchool.PleasantonHighSchool]: "PLEASANTON HIGH SCHOOL",
  [HighSchool.ClarendonHighSchool]: "CLARENDON HIGH SCHOOL",
  [HighSchool.PecosHighSchool]: "PECOS HIGH SCHOOL",
  [HighSchool.DelValleHighSchool]: "DEL VALLE HIGH SCHOOL",
  [HighSchool.LakeViewHighSchool]: "LAKE VIEW HIGH SCHOOL",
  [HighSchool.TrinityHighSchool]: "TRINITY HIGH SCHOOL",
  [HighSchool.MuensterHighSchool]: "MUENSTER HIGH SCHOOL",
  [HighSchool.CopperasCoveHighSchool]: "COPPERAS COVE HIGH SCHOOL",
  [HighSchool.CooperHighSchoolCooper]: "COOPER HIGH SCHOOL (COOPER)",
  [HighSchool.GrahamLearningCenter]: "GRAHAM LEARNING CENTER",
  [HighSchool.GrandviewHighSchool]: "GRANDVIEW HIGH SCHOOL",
  [HighSchool.LaVegaHighSchool]: "LA VEGA HIGH SCHOOL",
  [HighSchool.LindaleHighSchool]: "LINDALE HIGH SCHOOL",
  [HighSchool.CentennialHighSchoolBurleson]:
    "CENTENNIAL HIGH SCHOOL (BURLESON)",
  [HighSchool.FarwellHighSchool]: "FARWELL HIGH SCHOOL",
  [HighSchool.FoxTechnicalHighSchool]: "FOX TECHNICAL HIGH SCHOOL",
  [HighSchool.LagoVistaHighSchool]: "LAGO VISTA HIGH SCHOOL",
  [HighSchool.GustineSchool]: "GUSTINE SCHOOL",
  [HighSchool.TurnerHighSchool]: "TURNER HIGH SCHOOL",
  [HighSchool.MilanoHighSchool]: "MILANO HIGH SCHOOL",
  [HighSchool.OreCityHighSchool]: "ORE CITY HIGH SCHOOL",
  [HighSchool.MarshallHighSchoolFortBend]: "MARSHALL HIGH SCHOOL (FORT BEND)",
  [HighSchool.HemphillHighSchool]: "HEMPHILL HIGH SCHOOL",
  [HighSchool.EarlyHighSchool]: "EARLY HIGH SCHOOL",
  [HighSchool.NewDianaHighSchool]: "NEW DIANA HIGH SCHOOL",
  [HighSchool.KlondikeIsd]: "KLONDIKE ISD",
  [HighSchool.MonahansEducationCenter]: "MONAHANS EDUCATION CENTER",
  [HighSchool.BrenhamHighSchool]: "BRENHAM HIGH SCHOOL",
  [HighSchool.CelinaHighSchool]: "CELINA HIGH SCHOOL",
  [HighSchool.ChildressHighSchool]: "CHILDRESS HIGH SCHOOL",
  [HighSchool.CorsicanaHighSchool]: "CORSICANA HIGH SCHOOL",
  [HighSchool.MotleyCountySchool]: "MOTLEY COUNTY SCHOOL",
  [HighSchool.BangsHighSchool]: "BANGS HIGH SCHOOL",
  [HighSchool.NederlandHighSchool]: "NEDERLAND HIGH SCHOOL",
  [HighSchool.NewSummerfieldSchool]: "NEW SUMMERFIELD SCHOOL",
  [HighSchool.MarlinHighSchool]: "MARLIN HIGH SCHOOL",
  [HighSchool.TrinityHighSchoolHeb]: "TRINITY HIGH SCHOOL (HEB)",
  [HighSchool.CityViewJuniorSeniorHighSchool]:
    "CITY VIEW JUNIOR/SENIOR HIGH SCHOOL",
  [HighSchool.WHAdamsonHighSchool]: "W H ADAMSON HIGH SCHOOL",
  [HighSchool.DarrouzettSchools]: "DARROUZETT SCHOOLS",
  [HighSchool.CentervilleHighSchool]: "CENTERVILLE HIGH SCHOOL",
  [HighSchool.HullDaisettaHighSchool]: "HULL-DAISETTA HIGH SCHOOL",
  [HighSchool.PearsallHighSchool]: "PEARSALL HIGH SCHOOL",
  [HighSchool.WylieHighSchool]: "WYLIE HIGH SCHOOL",
  [HighSchool.MagnoliaHighSchool]: "MAGNOLIA HIGH SCHOOL",
  [HighSchool.GarySchool]: "GARY SCHOOL",
  [HighSchool.EdinburgNorthHighSchool]: "EDINBURG NORTH HIGH SCHOOL",
  [HighSchool.SomersetHighSchool]: "SOMERSET HIGH SCHOOL",
  [HighSchool.RallsHighSchool]: "RALLS HIGH SCHOOL",
  [HighSchool.BowieHighSchoolElPaso]: "BOWIE HIGH SCHOOL (EL PASO)",
  [HighSchool.RiversideHighSchool]: "RIVERSIDE HIGH SCHOOL",
  [HighSchool.PalmerHighSchool]: "PALMER HIGH SCHOOL",
  [HighSchool.RockwallHeathHighSchool]: "ROCKWALL-HEATH HIGH SCHOOL",
  [HighSchool.SpurSchool]: "SPUR SCHOOL",
  [HighSchool.JonesHighSchool]: "JONES HIGH SCHOOL",
  [HighSchool.SpringWoodsHighSchool]: "SPRING WOODS HIGH SCHOOL",
  [HighSchool.TomBeanHighSchool]: "TOM BEAN HIGH SCHOOL",
  [HighSchool.LoneOakHighSchool]: "LONE OAK HIGH SCHOOL",
  [HighSchool.BryanHighSchool]: "BRYAN HIGH SCHOOL",
  [HighSchool.HighlandParkHighSchool]: "HIGHLAND PARK HIGH SCHOOL",
  [HighSchool.PerrinHighSchool]: "PERRIN HIGH SCHOOL",
  [HighSchool.CaneyCreekHighSchool]: "CANEY CREEK HIGH SCHOOL",
  [HighSchool.BurtonHighSchool]: "BURTON HIGH SCHOOL",
  [HighSchool.UnionGroveHighSchool]: "UNION GROVE HIGH SCHOOL",
  [HighSchool.HappyHighSchool]: "HAPPY HIGH SCHOOL",
  [HighSchool.BurkevilleHighSchool]: "BURKEVILLE HIGH SCHOOL",
  [HighSchool.LittleCypressMauricevilleHighSchool]:
    "LITTLE CYPRESS-MAURICEVILLE HIGH SCHOOL",
  [HighSchool.EdcouchElsaHighSchool]: "EDCOUCH-ELSA HIGH SCHOOL",
  [HighSchool.SheptonHighSchool]: "SHEPTON HIGH SCHOOL",
  [HighSchool.LockneyHighSchool]: "LOCKNEY HIGH SCHOOL",
  [HighSchool.PittsburgHighSchool]: "PITTSBURG HIGH SCHOOL",
  [HighSchool.AndrewsEducationCenter]: "ANDREWS EDUCATION CENTER",
  [HighSchool.BowieHighSchoolArlington]: "BOWIE HIGH SCHOOL (ARLINGTON)",
  [HighSchool.SocorroHighSchool]: "SOCORRO HIGH SCHOOL",
  [HighSchool.AndersonHighSchool]: "ANDERSON HIGH SCHOOL",
  [HighSchool.EastlakeHighSchool]: "EASTLAKE HIGH SCHOOL",
  [HighSchool.WillsPointHighSchool]: "WILLS POINT HIGH SCHOOL",
  [HighSchool.LubbockCooperHighSchool]: "LUBBOCK-COOPER HIGH SCHOOL",
  [HighSchool.ArpHighSchool]: "ARP HIGH SCHOOL",
  [HighSchool.JasperHighSchoolJasper]: "JASPER HIGH SCHOOL (JASPER)",
  [HighSchool.ValleyMillsHighSchool]: "VALLEY MILLS HIGH SCHOOL",
  [HighSchool.ValleySchool]: "VALLEY SCHOOL",
  [HighSchool.DHanisSchool]: "D'HANIS SCHOOL",
  [HighSchool.UnitedSouthHighSchool]: "UNITED SOUTH HIGH SCHOOL",
  [HighSchool.BairdHighSchool]: "BAIRD HIGH SCHOOL",
  [HighSchool.BurgesHighSchool]: "BURGES HIGH SCHOOL",
  [HighSchool.HaskellHighSchool]: "HASKELL HIGH SCHOOL",
  [HighSchool.TroyHighSchool]: "TROY HIGH SCHOOL",
  [HighSchool.PoolvilleHighSchool]: "POOLVILLE HIGH SCHOOL",
  [HighSchool.SavoyHighSchool]: "SAVOY HIGH SCHOOL",
  [HighSchool.RockwallHighSchool]: "ROCKWALL HIGH SCHOOL",
  [HighSchool.AvalonSchool]: "AVALON SCHOOL",
  [HighSchool.FairviewAcceleratedGrapeCreek]:
    "FAIRVIEW ACCELERATED (GRAPE CREEK)",
  [HighSchool.WellingtonHighSchool]: "WELLINGTON HIGH SCHOOL",
  [HighSchool.CampbellHighSchool]: "CAMPBELL HIGH SCHOOL",
  [HighSchool.BastropHighSchool]: "BASTROP HIGH SCHOOL",
  [HighSchool.ShepherdHighSchool]: "SHEPHERD HIGH SCHOOL",
  [HighSchool.HamiltonHighSchool]: "HAMILTON HIGH SCHOOL",
  [HighSchool.LehmanHighSchool]: "LEHMAN HIGH SCHOOL",
  [HighSchool.GregoryPortlandHighSchool]: "GREGORY-PORTLAND HIGH SCHOOL",
  [HighSchool.EastwoodHighSchool]: "EASTWOOD HIGH SCHOOL",
  [HighSchool.RieselHighSchool]: "RIESEL HIGH SCHOOL",
  [HighSchool.LakeDallasHighSchool]: "LAKE DALLAS HIGH SCHOOL",
  [HighSchool.FairviewAcceleratedBallinger]: "FAIRVIEW ACCELERATED (BALLINGER)",
  [HighSchool.NorthDallasHighSchool]: "NORTH DALLAS HIGH SCHOOL",
  [HighSchool.ChavezHighSchool]: "CHAVEZ HIGH SCHOOL",
  [HighSchool.GoldBurgHighSchool]: "GOLD BURG HIGH SCHOOL",
  [HighSchool.WimberleyHighSchool]: "WIMBERLEY HIGH SCHOOL",
  [HighSchool.KenedyHighSchool]: "KENEDY HIGH SCHOOL",
  [HighSchool.LopezHighSchool]: "LOPEZ HIGH SCHOOL",
  [HighSchool.FrostHighSchool]: "FROST HIGH SCHOOL",
  [HighSchool.BlackwellSchool]: "BLACKWELL SCHOOL",
  [HighSchool.MerkelHighSchool]: "MERKEL HIGH SCHOOL",
  [HighSchool.McleanSchool]: "MCLEAN SCHOOL",
  [HighSchool.NewBraunfelsHighSchool]: "NEW BRAUNFELS HIGH SCHOOL",
  [HighSchool.FortElliottSchool]: "FORT ELLIOTT SCHOOL",
  [HighSchool.CalhounHighSchool]: "CALHOUN HIGH SCHOOL",
  [HighSchool.MontgomeryHighSchool]: "MONTGOMERY HIGH SCHOOL",
  [HighSchool.GilmerHighSchool]: "GILMER HIGH SCHOOL",
  [HighSchool.TheWoodlandsCollegeParkHighSchool]:
    "THE WOODLANDS COLLEGE PARK HIGH SCHOOL",
  [HighSchool.RedOakHS]: "RED OAK H S",
  [HighSchool.DesotoHighSchool]: "DESOTO HIGH SCHOOL",
  [HighSchool.PetroliaJuniorSeniorHighSchool]:
    "PETROLIA JUNIOR/SENIOR HIGH SCHOOL",
  [HighSchool.AlamoHeightsHighSchool]: "ALAMO HEIGHTS HIGH SCHOOL",
  [HighSchool.HarroldSchool]: "HARROLD SCHOOL",
  [HighSchool.ConnallyHighSchoolConnally]: "CONNALLY HIGH SCHOOL (CONNALLY)",
  [HighSchool.MundayHighSchool]: "MUNDAY HIGH SCHOOL",
  [HighSchool.MemorialHighSchoolPasadena]: "MEMORIAL HIGH SCHOOL (PASADENA)",
  [HighSchool.DaingerfieldHighSchool]: "DAINGERFIELD HIGH SCHOOL",
  [HighSchool.JamesMadisonHighSchool]: "JAMES MADISON HIGH SCHOOL",
  [HighSchool.MccollumHighSchool]: "MCCOLLUM HIGH SCHOOL",
  [HighSchool.IraSchool]: "IRA SCHOOL",
  [HighSchool.ItalyHighSchool]: "ITALY HIGH SCHOOL",
  [HighSchool.PaceHighSchool]: "PACE HIGH SCHOOL",
  [HighSchool.ShermanHighSchool]: "SHERMAN HIGH SCHOOL",
  [HighSchool.KilgoreHighSchool]: "KILGORE HIGH SCHOOL",
  [HighSchool.LamesaHighSchool]: "LAMESA HIGH SCHOOL",
  [HighSchool.MtEnterpriseJuniorHighHighSchool]:
    "MT ENTERPRISE JUNIOR HIGH HIGH SCHOOL",
  [HighSchool.CoolidgeHighSchool]: "COOLIDGE HIGH SCHOOL",
  [HighSchool.AtlantaHighSchool]: "ATLANTA HIGH SCHOOL",
  [HighSchool.AndressHighSchool]: "ANDRESS HIGH SCHOOL",
  [HighSchool.VictoriaWestHighSchool]: "VICTORIA WEST HIGH SCHOOL",
  [HighSchool.WestTexasHighSchool]: "WEST TEXAS HIGH SCHOOL",
  [HighSchool.ZephyrSchool]: "ZEPHYR SCHOOL",
  [HighSchool.AustinHighSchoolHouston]: "AUSTIN HIGH SCHOOL (HOUSTON)",
  [HighSchool.WashingtonHighSchool]: "WASHINGTON HIGH SCHOOL",
  [HighSchool.LewisvilleHighSchoolKilloughCampus]:
    "LEWISVILLE HIGH SCHOOL KILLOUGH CAMPUS",
  [HighSchool.OltonHighSchool]: "OLTON HIGH SCHOOL",
  [HighSchool.FlowerMoundHighSchool]: "FLOWER MOUND HIGH SCHOOL",
  [HighSchool.GiddingsHighSchool]: "GIDDINGS HIGH SCHOOL",
  [HighSchool.MayHighSchool]: "MAY HIGH SCHOOL",
  [HighSchool.SamHoustonHighSchool]: "SAM HOUSTON HIGH SCHOOL",
  [HighSchool.RooseveltHighSchoolNorthEast]:
    "ROOSEVELT HIGH SCHOOL (NORTH EAST)",
  [HighSchool.MedinaValleyHighSchool]: "MEDINA VALLEY HIGH SCHOOL",
  [HighSchool.TerrellHighSchool]: "TERRELL HIGH SCHOOL",
  [HighSchool.HuttoHighSchool]: "HUTTO HIGH SCHOOL",
  [HighSchool.SouthlandSchool]: "SOUTHLAND SCHOOL",
  [HighSchool.ScurryRosserHighSchool]: "SCURRY-ROSSER HIGH SCHOOL",
  [HighSchool.ThrallHighSchool]: "THRALL HIGH SCHOOL",
  [HighSchool.LanierHighSchoolAustin]: "LANIER HIGH SCHOOL (AUSTIN)",
  [HighSchool.FlourBluffHighSchool]: "FLOUR BLUFF HIGH SCHOOL",
  [HighSchool.HolmesHighSchool]: "HOLMES HIGH SCHOOL",
  [HighSchool.MiamiSchool]: "MIAMI SCHOOL",
  [HighSchool.CoronadoHighSchoolElPaso]: "CORONADO HIGH SCHOOL (EL PASO)",
  [HighSchool.SunrayHighSchool]: "SUNRAY HIGH SCHOOL",
  [HighSchool.HardinJeffersonHighSchool]: "HARDIN-JEFFERSON HIGH SCHOOL",
  [HighSchool.LorenzoHighSchool]: "LORENZO HIGH SCHOOL",
  [HighSchool.DentonHighSchool]: "DENTON HIGH SCHOOL",
  [HighSchool.ClearCreekHighSchool]: "CLEAR CREEK HIGH SCHOOL",
  [HighSchool.CypressFallsHighSchool]: "CYPRESS FALLS HIGH SCHOOL",
  [HighSchool.OrangefieldHighSchool]: "ORANGEFIELD HIGH SCHOOL",
  [HighSchool.NavarroHighSchool]: "NAVARRO HIGH SCHOOL",
  [HighSchool.MoodyHighSchoolCorpusChristi]:
    "MOODY HIGH SCHOOL (CORPUS CHRISTI)",
  [HighSchool.SpringlakeEarthHighSchool]: "SPRINGLAKE-EARTH HIGH SCHOOL",
  [HighSchool.EllisonHighSchool]: "ELLISON HIGH SCHOOL",
  [HighSchool.WolfeCityHighSchool]: "WOLFE CITY HIGH SCHOOL",
  [HighSchool.AllenHighSchool]: "ALLEN HIGH SCHOOL",
  [HighSchool.JamesEarlRudderHighSchool]: "JAMES EARL RUDDER HIGH SCHOOL",
  [HighSchool.PasadenaHighSchool]: "PASADENA HIGH SCHOOL",
  [HighSchool.CedarCreekHS]: "CEDAR CREEK H S",
  [HighSchool.YsletaHighSchool]: "YSLETA HIGH SCHOOL",
  [HighSchool.GraysonCoCoOp]: "GRAYSON CO CO-OP",
  [HighSchool.BirdvilleHighSchool]: "BIRDVILLE HIGH SCHOOL",
  [HighSchool.HamlinHighSchool]: "HAMLIN HIGH SCHOOL",
  [HighSchool.PleasantGroveHighSchool]: "PLEASANT GROVE HIGH SCHOOL",
  [HighSchool.ElectraHighSchool]: "ELECTRA HIGH SCHOOL",
  [HighSchool.SweenyHighSchool]: "SWEENY HIGH SCHOOL",
  [HighSchool.DHSFreshmanCampus]: "D H S FRESHMAN CAMPUS",
  [HighSchool.WylieEastHighSchool]: "WYLIE EAST HIGH SCHOOL",
  [HighSchool.BillyRyanHighSchool]: "BILLY RYAN HIGH SCHOOL",
  [HighSchool.WoodsonSchool]: "WOODSON SCHOOL",
  [HighSchool.DouglassSchool]: "DOUGLASS SCHOOL",
  [HighSchool.RidgePointHighSchool]: "RIDGE POINT HIGH SCHOOL",
  [HighSchool.TexasCityHighSchool]: "TEXAS CITY HIGH SCHOOL",
  [HighSchool.GrafordHighSchool]: "GRAFORD HIGH SCHOOL",
  [HighSchool.NuecesCanyonJhHs]: "NUECES CANYON JH/HS",
  [HighSchool.SaladoHighSchool]: "SALADO HIGH SCHOOL",
  [HighSchool.QueenCityHS]: "QUEEN CITY H S",
  [HighSchool.KleinOakHighSchool]: "KLEIN OAK HIGH SCHOOL",
  [HighSchool.FulshearHighSchool]: "FULSHEAR HIGH SCHOOL",
  [HighSchool.SouthHoustonHighSchool]: "SOUTH HOUSTON HIGH SCHOOL",
  [HighSchool.TrentIndependentSchoolDistrict]:
    "TRENT INDEPENDENT SCHOOL DISTRICT",
  [HighSchool.RuleSchool]: "RULE SCHOOL",
  [HighSchool.FreerHighSchool]: "FREER HIGH SCHOOL",
  [HighSchool.MountPleasantHighSchool]: "MOUNT PLEASANT HIGH SCHOOL",
  [HighSchool.McmillenHighSchool]: "MCMILLEN HIGH SCHOOL",
  [HighSchool.PottsboroHighSchool]: "POTTSBORO HIGH SCHOOL",
  [HighSchool.SnookSecondarySchool]: "SNOOK SECONDARY SCHOOL",
  [HighSchool.LorenaHighSchool]: "LORENA HIGH SCHOOL",
  [HighSchool.TravisHighSchoolAustin]: "TRAVIS HIGH SCHOOL (AUSTIN)",
  [HighSchool.KressHighSchool]: "KRESS HIGH SCHOOL",
  [HighSchool.VidorHighSchool]: "VIDOR HIGH SCHOOL",
  [HighSchool.MacarthurHighSchoolIrving]: "MACARTHUR HIGH SCHOOL (IRVING)",
  [HighSchool.AxtellBrucevilleEddyLearningCenter]:
    "AXTELL/BRUCEVILLE-EDDY LEARNING CENTER",
  [HighSchool.KleinForestHighSchool]: "KLEIN FOREST HIGH SCHOOL",
  [HighSchool.FannindelHighSchool]: "FANNINDEL HIGH SCHOOL",
  [HighSchool.HubbardHighSchool]: "HUBBARD HIGH SCHOOL",
  [HighSchool.AisdDraw]: "AISD DRAW",
  [HighSchool.WoodrowWilsonHighSchool]: "WOODROW WILSON HIGH SCHOOL",
  [HighSchool.GrandPrairieHighSchool]: "GRAND PRAIRIE HIGH SCHOOL",
  [HighSchool.IolaHighSchool]: "IOLA HIGH SCHOOL",
  [HighSchool.KleinCollinsHighSchool]: "KLEIN COLLINS HIGH SCHOOL",
  [HighSchool.SummerCreekHighSchool]: "SUMMER CREEK HIGH SCHOOL",
  [HighSchool.StrawnSchool]: "STRAWN SCHOOL",
  [HighSchool.WakelandHighSchool]: "WAKELAND HIGH SCHOOL",
  [HighSchool.RichardsHighSchool]: "RICHARDS HIGH SCHOOL",
  [HighSchool.GrandOaksHighSchool]: "GRAND OAKS HIGH SCHOOL",
  [HighSchool.BuenaVistaSchool]: "BUENA VISTA SCHOOL",
  [HighSchool.EarlyEeThrough12ThGrade]: "EARLY EE THROUGH 12TH GRADE",
  [HighSchool.ThorndaleHighSchool]: "THORNDALE HIGH SCHOOL",
  [HighSchool.QuitmanHighSchool]: "QUITMAN HIGH SCHOOL",
  [HighSchool.DeweyvilleJuniorSeniorHighSchool]:
    "DEWEYVILLE JUNIOR/SENIOR HIGH SCHOOL",
  [HighSchool.RiceHighSchoolRiceRice]: "RICE HIGH SCHOOL (RICE-RICE)",
  [HighSchool.KerensSchool]: "KERENS SCHOOL",
  [HighSchool.DeerParkHighSchool]: "DEER PARK HIGH SCHOOL",
  [HighSchool.IrionHighSchool]: "IRION HIGH SCHOOL",
  [HighSchool.MilbyHighSchool]: "MILBY HIGH SCHOOL",
  [HighSchool.LamarConsolidatedHighSchool]: "LAMAR CONSOLIDATED HIGH SCHOOL",
  [HighSchool.BrazosHighSchool]: "BRAZOS HIGH SCHOOL",
  [HighSchool.WallHighSchool]: "WALL HIGH SCHOOL",
  [HighSchool.MoisesEMolinaHighSchool]: "MOISES E MOLINA HIGH SCHOOL",
  [HighSchool.BonhamHighSchool]: "BONHAM HIGH SCHOOL",
  [HighSchool.PrincetonHighSchool]: "PRINCETON HIGH SCHOOL",
  [HighSchool.FallsCityHighSchool]: "FALLS CITY HIGH SCHOOL",
  [HighSchool.KellerHighSchool]: "KELLER HIGH SCHOOL",
  [HighSchool.RichlandHighSchool]: "RICHLAND HIGH SCHOOL",
  [HighSchool.LibertyEylauSchoolOfSuccess]: "LIBERTY-EYLAU SCHOOL OF SUCCESS",
  [HighSchool.MasonHighSchool]: "MASON HIGH SCHOOL",
  [HighSchool.LeakeySchool]: "LEAKEY SCHOOL",
  [HighSchool.RockdaleHighSchool]: "ROCKDALE HIGH SCHOOL",
  [HighSchool.DoddCitySchool]: "DODD CITY SCHOOL",
  [HighSchool.HarlingenHsSouth]: "HARLINGEN HS - SOUTH",
  [HighSchool.HamshireFannettHighSchool]: "HAMSHIRE-FANNETT HIGH SCHOOL",
  [HighSchool.LakeTravisHighSchool]: "LAKE TRAVIS HIGH SCHOOL",
  [HighSchool.AlvinHighSchool]: "ALVIN HIGH SCHOOL",
  [HighSchool.CovingtonSchool]: "COVINGTON SCHOOL",
  [HighSchool.NorthwestHighSchool]: "NORTHWEST HIGH SCHOOL",
  [HighSchool.FairviewVocationalTraining]: "FAIRVIEW VOCATIONAL TRAINING",
  [HighSchool.WestOrangeStarkHighSchool]: "WEST ORANGE-STARK HIGH SCHOOL",
  [HighSchool.SchulenburgSecondarySchool]: "SCHULENBURG SECONDARY SCHOOL",
  [HighSchool.HuntingtonHighSchool]: "HUNTINGTON HIGH SCHOOL",
  [HighSchool.FurrHighSchool]: "FURR HIGH SCHOOL",
  [HighSchool.MarionHighSchool]: "MARION HIGH SCHOOL",
  [HighSchool.WichitaFallsHighSchool]: "WICHITA FALLS HIGH SCHOOL",
  [HighSchool.NewBostonHighSchool]: "NEW BOSTON HIGH SCHOOL",
  [HighSchool.MarfaJuniorSeniorHighSchool]: "MARFA JUNIOR/SENIOR HIGH SCHOOL",
  [HighSchool.JohnsonHighSchool]: "JOHNSON HIGH SCHOOL",
  [HighSchool.WaxahachieHighSchool]: "WAXAHACHIE HIGH SCHOOL",
  [HighSchool.TarkingtonHighSchool]: "TARKINGTON HIGH SCHOOL",
  [HighSchool.RobertGColeMiddleHighSchool]: "ROBERT G COLE MIDDLE/HIGH SCHOOL",
  [HighSchool.HarmonyHighSchool]: "HARMONY HIGH SCHOOL",
  [HighSchool.MorganSchool]: "MORGAN SCHOOL",
  [HighSchool.JoshuaHighSchool]: "JOSHUA HIGH SCHOOL",
  [HighSchool.LinglevilleSchool]: "LINGLEVILLE SCHOOL",
  [HighSchool.WallaceAcceleratedHighSchool]: "WALLACE ACCELERATED HIGH SCHOOL",
  [HighSchool.GreenwoodHighSchool]: "GREENWOOD HIGH SCHOOL",
  [HighSchool.FossilRidgeHighSchool]: "FOSSIL RIDGE HIGH SCHOOL",
  [HighSchool.WacoHighSchool]: "WACO HIGH SCHOOL",
  [HighSchool.VeteransMemorialHighSchoolMission]:
    "VETERANS MEMORIAL HIGH SCHOOL (MISSION)",
  [HighSchool.HuckabaySchool]: "HUCKABAY SCHOOL",
  [HighSchool.LeanderHighSchool]: "LEANDER HIGH SCHOOL",
  [HighSchool.BoysRanchHighSchool]: "BOYS RANCH HIGH SCHOOL",
  [HighSchool.FairviewAcceleratedEden]: "FAIRVIEW ACCELERATED (EDEN)",
  [HighSchool.NaamanForestHighSchool]: "NAAMAN FOREST HIGH SCHOOL",
  [HighSchool.CraneHighSchool]: "CRANE HIGH SCHOOL",
  [HighSchool.CentralHighSchoolSanAngelo]: "CENTRAL HIGH SCHOOL (SAN ANGELO)",
  [HighSchool.BigSandyHighSchool]: "BIG SANDY HIGH SCHOOL",
  [HighSchool.MaypearlHighSchool]: "MAYPEARL HIGH SCHOOL",
  [HighSchool.WeslacoEastHighSchool]: "WESLACO EAST HIGH SCHOOL",
  [HighSchool.NorthbrookHighSchool]: "NORTHBROOK HIGH SCHOOL",
  [HighSchool.WestlakeHighSchool]: "WESTLAKE HIGH SCHOOL",
  [HighSchool.WillisHighSchool]: "WILLIS HIGH SCHOOL",
  [HighSchool.PEPMorton]: "P E P (MORTON)",
  [HighSchool.NorthTexasStateHospitalAfpVernon]:
    "NORTH TEXAS STATE HOSPITAL - AFP - VERNON",
  [HighSchool.GuthrieSchool]: "GUTHRIE SCHOOL",
  [HighSchool.ODWyattHighSchool]: "O D WYATT HIGH SCHOOL",
  [HighSchool.WinnsboroHighSchool]: "WINNSBORO HIGH SCHOOL",
  [HighSchool.BellvilleHighSchool]: "BELLVILLE HIGH SCHOOL",
  [HighSchool.ChillicotheHighSchool]: "CHILLICOTHE HIGH SCHOOL",
  [HighSchool.HardinHighSchool]: "HARDIN HIGH SCHOOL",
  [HighSchool.WhitewrightHighSchool]: "WHITEWRIGHT HIGH SCHOOL",
  [HighSchool.NorthLamarHighSchool]: "NORTH LAMAR HIGH SCHOOL",
  [HighSchool.FrionaHighSchool]: "FRIONA HIGH SCHOOL",
  [HighSchool.FerrisHighSchool]: "FERRIS HIGH SCHOOL",
  [HighSchool.KennardHighSchool]: "KENNARD HIGH SCHOOL",
  [HighSchool.CastleberryHighSchool]: "CASTLEBERRY HIGH SCHOOL",
  [HighSchool.SomervilleHighSchool]: "SOMERVILLE HIGH SCHOOL",
  [HighSchool.GranburyHighSchool]: "GRANBURY HIGH SCHOOL",
  [HighSchool.EmmettJConradHighSchool]: "EMMETT J CONRAD HIGH SCHOOL",
  [HighSchool.SouthHillsHighSchool]: "SOUTH HILLS HIGH SCHOOL",
  [HighSchool.RoweHighSchool]: "ROWE HIGH SCHOOL",
  [HighSchool.AlpineHighSchool]: "ALPINE HIGH SCHOOL",
  [HighSchool.CaddoMillsHighSchool]: "CADDO MILLS HIGH SCHOOL",
  [HighSchool.MansfieldTimberviewHighSchool]:
    "MANSFIELD TIMBERVIEW HIGH SCHOOL",
  [HighSchool.LaVillaEarlyCollegeHighSchool]:
    "LA VILLA EARLY COLLEGE HIGH SCHOOL",
  [HighSchool.VinesHighSchool]: "VINES HIGH SCHOOL",
  [HighSchool.IowaColonyHighSchool]: "IOWA COLONY HIGH SCHOOL",
  [HighSchool.FlatoniaSecondarySchool]: "FLATONIA SECONDARY SCHOOL",
  [HighSchool.LittleElmHighSchool]: "LITTLE ELM HIGH SCHOOL",
  [HighSchool.AlbanyJuniorSeniorHighSchool]: "ALBANY JUNIOR-SENIOR HIGH SCHOOL",
  [HighSchool.FruitvaleHighSchool]: "FRUITVALE HIGH SCHOOL",
  [HighSchool.DetroitHighSchool]: "DETROIT HIGH SCHOOL",
  [HighSchool.AndyDekaneyHS]: "ANDY DEKANEY H S",
  [HighSchool.DilleyHighSchool]: "DILLEY HIGH SCHOOL",
  [HighSchool.HenriettaHighSchool]: "HENRIETTA HIGH SCHOOL",
  [HighSchool.GainesvilleHighSchool]: "GAINESVILLE HIGH SCHOOL",
  [HighSchool.LaGrangeHighSchool]: "LA GRANGE HIGH SCHOOL",
  [HighSchool.ComstockSchool]: "COMSTOCK SCHOOL",
  [HighSchool.UnitedHighSchool]: "UNITED HIGH SCHOOL",
  [HighSchool.CarrizoSpringsHighSchool]: "CARRIZO SPRINGS HIGH SCHOOL",
  [HighSchool.GarrisonHighSchool]: "GARRISON HIGH SCHOOL",
  [HighSchool.ZavallaHighSchool]: "ZAVALLA HIGH SCHOOL",
  [HighSchool.SmithHighSchool]: "SMITH HIGH SCHOOL",
  [HighSchool.EdisonHighSchool]: "EDISON HIGH SCHOOL",
  [HighSchool.RooseveltHighSchoolRoosevelt]:
    "ROOSEVELT HIGH SCHOOL (ROOSEVELT)",
  [HighSchool.GoliadHighSchool]: "GOLIAD HIGH SCHOOL",
  [HighSchool.DrippingSpringsHighSchool]: "DRIPPING SPRINGS HIGH SCHOOL",
  [HighSchool.BronteSecondarySchool]: "BRONTE SECONDARY SCHOOL",
  [HighSchool.ThrockmortonHighSchool]: "THROCKMORTON HIGH SCHOOL",
  [HighSchool.HillsboroHighSchool]: "HILLSBORO HIGH SCHOOL",
  [HighSchool.BanderaHighSchool]: "BANDERA HIGH SCHOOL",
  [HighSchool.RopesJuniorSeniorHighSchool]: "ROPES JUNIOR SENIOR HIGH SCHOOL",
  [HighSchool.PrairieLeaSchool]: "PRAIRIE LEA SCHOOL",
  [HighSchool.PriddySchool]: "PRIDDY SCHOOL",
  [HighSchool.TerryHighSchool]: "TERRY HIGH SCHOOL",
  [HighSchool.TexasHighSchool]: "TEXAS HIGH SCHOOL",
  [HighSchool.SouthwestHighSchoolFortWorth]:
    "SOUTHWEST HIGH SCHOOL (FORT WORTH)",
  [HighSchool.CentervilleJuniorSeniorHighSchool]:
    "CENTERVILLE JUNIOR-SENIOR HIGH SCHOOL",
  [HighSchool.GormanHighSchool]: "GORMAN HIGH SCHOOL",
  [HighSchool.OdessaHighSchool]: "ODESSA HIGH SCHOOL",
  [HighSchool.HebbronvilleHighSchool]: "HEBBRONVILLE HIGH SCHOOL",
  [HighSchool.UtopiaSchool]: "UTOPIA SCHOOL",
  [HighSchool.JunctionHighSchool]: "JUNCTION HIGH SCHOOL",
  [HighSchool.PattonSpringsSchool]: "PATTON SPRINGS SCHOOL",
  [HighSchool.GalenaParkHighSchool]: "GALENA PARK HIGH SCHOOL",
  [HighSchool.IredellSchool]: "IREDELL SCHOOL",
  [HighSchool.SouthOakCliffHighSchool]: "SOUTH OAK CLIFF HIGH SCHOOL",
  [HighSchool.RayburnHighSchool]: "RAYBURN HIGH SCHOOL",
  [HighSchool.LindsayHighSchool]: "LINDSAY HIGH SCHOOL",
  [HighSchool.AzleHighSchool]: "AZLE HIGH SCHOOL",
  [HighSchool.ScarboroughHighSchool]: "SCARBOROUGH HIGH SCHOOL",
  [HighSchool.PearceHighSchool]: "PEARCE HIGH SCHOOL",
  [HighSchool.ParklandHighSchool]: "PARKLAND HIGH SCHOOL",
  [HighSchool.MedinaHighSchool]: "MEDINA HIGH SCHOOL",
  [HighSchool.NataliaHighSchool]: "NATALIA HIGH SCHOOL",
  [HighSchool.DiamondHillJarvisHighSchool]: "DIAMOND HILL-JARVIS HIGH SCHOOL",
  [HighSchool.WaterValleyHighSchool]: "WATER VALLEY HIGH SCHOOL",
  [HighSchool.MuleshoeHighSchool]: "MULESHOE HIGH SCHOOL",
  [HighSchool.LaFeriaHighSchool]: "LA FERIA HIGH SCHOOL",
  [HighSchool.TulosoMidwayHighSchool]: "TULOSO-MIDWAY HIGH SCHOOL",
  [HighSchool.JacksboroHighSchool]: "JACKSBORO HIGH SCHOOL",
  [HighSchool.SpringHighSchool]: "SPRING HIGH SCHOOL",
  [HighSchool.PortAransasHighSchool]: "PORT ARANSAS HIGH SCHOOL",
  [HighSchool.WestwoodHighSchoolRoundRock]: "WESTWOOD HIGH SCHOOL (ROUND ROCK)",
  [HighSchool.SantaMariaHighSchool]: "SANTA MARIA HIGH SCHOOL",
  [HighSchool.HaltomHighSchool]: "HALTOM HIGH SCHOOL",
  [HighSchool.CentralHighSchoolCentral]: "CENTRAL HIGH SCHOOL (CENTRAL)",
  [HighSchool.CypressCreekHighSchool]: "CYPRESS CREEK HIGH SCHOOL",
  [HighSchool.AlbaGoldenSecondarySchool]: "ALBA-GOLDEN SECONDARY SCHOOL",
  [HighSchool.SealyHighSchool]: "SEALY HIGH SCHOOL",
  [HighSchool.ClarkHighSchoolPlano]: "CLARK HIGH SCHOOL (PLANO)",
  [HighSchool.UvaldeHighSchool]: "UVALDE HIGH SCHOOL",
  [HighSchool.ReaganHighSchoolAustin]: "REAGAN HIGH SCHOOL (AUSTIN)",
  [HighSchool.KrumHighSchool]: "KRUM HIGH SCHOOL",
  [HighSchool.ChisumHighSchool]: "CHISUM HIGH SCHOOL",
  [HighSchool.AveryHighSchool]: "AVERY HIGH SCHOOL",
  [HighSchool.HigginsSchool]: "HIGGINS SCHOOL",
  [HighSchool.PearlandHighSchool]: "PEARLAND HIGH SCHOOL",
  [HighSchool.QuanahHighSchool]: "QUANAH HIGH SCHOOL",
  [HighSchool.MansfieldSummitHighSchool]: "MANSFIELD SUMMIT HIGH SCHOOL",
  [HighSchool.FairviewSpecialProgramsChristoval]:
    "FAIRVIEW SPECIAL PROGRAMS (CHRISTOVAL)",
  [HighSchool.SaintJoHighSchool]: "SAINT JO HIGH SCHOOL",
  [HighSchool.CrosbytonHighSchool]: "CROSBYTON HIGH SCHOOL",
  [HighSchool.FrankstonHighSchool]: "FRANKSTON HIGH SCHOOL",
  [HighSchool.ColoradoHighSchool]: "COLORADO HIGH SCHOOL",
  [HighSchool.LyfordHighSchool]: "LYFORD HIGH SCHOOL",
  [HighSchool.HillcrestHighSchool]: "HILLCREST HIGH SCHOOL",
  [HighSchool.GunterHighSchool]: "GUNTER HIGH SCHOOL",
  [HighSchool.BrucevilleEddyHighSchool]: "BRUCEVILLE-EDDY HIGH SCHOOL",
  [HighSchool.NixonSmileyHighSchool]: "NIXON-SMILEY HIGH SCHOOL",
  [HighSchool.CrosbyHighSchool]: "CROSBY HIGH SCHOOL",
  [HighSchool.SanIsidroHighSchool]: "SAN ISIDRO HIGH SCHOOL",
  [HighSchool.SintonHighSchool]: "SINTON HIGH SCHOOL",
  [HighSchool.RivercrestHighSchool]: "RIVERCREST HIGH SCHOOL",
  [HighSchool.RanchviewHighSchool]: "RANCHVIEW HIGH SCHOOL",
  [HighSchool.ReaganCountyHighSchool]: "REAGAN COUNTY HIGH SCHOOL",
  [HighSchool.MercedesHighSchool]: "MERCEDES HIGH SCHOOL",
  [HighSchool.SunsetHighSchool]: "SUNSET HIGH SCHOOL",
  [HighSchool.TimberCreekHighSchool]: "TIMBER CREEK HIGH SCHOOL",
  [HighSchool.RoundTopCarmineHighSchool]: "ROUND TOP-CARMINE HIGH SCHOOL",
  [HighSchool.ColdspringOakhurstHighSchool]: "COLDSPRING-OAKHURST HIGH SCHOOL",
  [HighSchool.LanierHighSchoolSanAntonio]: "LANIER HIGH SCHOOL (SAN ANTONIO)",
  [HighSchool.MartAcademicAlernativeProgram]:
    "MART ACADEMIC ALERNATIVE PROGRAM",
  [HighSchool.NorthSideHighSchool]: "NORTH SIDE HIGH SCHOOL",
  [HighSchool.TeagueHighSchool]: "TEAGUE HIGH SCHOOL",
  [HighSchool.ChiltonSchool]: "CHILTON SCHOOL",
  [HighSchool.PineTreeHighSchool]: "PINE TREE HIGH SCHOOL",
  [HighSchool.ArgyleHighSchool]: "ARGYLE HIGH SCHOOL",
  [HighSchool.RosebudLottHighSchool]: "ROSEBUD-LOTT HIGH SCHOOL",
  [HighSchool.MonahansHighSchool]: "MONAHANS HIGH SCHOOL",
  [HighSchool.CumbyHighSchool]: "CUMBY HIGH SCHOOL",
  [HighSchool.BreckenridgeHighSchool]: "BRECKENRIDGE HIGH SCHOOL",
  [HighSchool.CooperHighSchoolAbilene]: "COOPER HIGH SCHOOL (ABILENE)",
  [HighSchool.SantoHighSchool]: "SANTO HIGH SCHOOL",
  [HighSchool.LometaSchool]: "LOMETA SCHOOL",
  [HighSchool.WestsideHighSchool]: "WESTSIDE HIGH SCHOOL",
  [HighSchool.CoppellHighSchool]: "COPPELL HIGH SCHOOL",
  [HighSchool.TaylorHighSchoolTaylor]: "TAYLOR HIGH SCHOOL (TAYLOR)",
  [HighSchool.AbileneHighSchool]: "ABILENE HIGH SCHOOL",
  [HighSchool.MartinHighSchoolLaredo]: "MARTIN HIGH SCHOOL (LAREDO)",
  [HighSchool.WestDAEP]: "WEST D A E P",
  [HighSchool.SkylineHighSchool]: "SKYLINE HIGH SCHOOL",
  [HighSchool.BovinaHighSchool]: "BOVINA HIGH SCHOOL",
  [HighSchool.OnalaskaJrSrHighSchool]: "ONALASKA JR/SR HIGH SCHOOL",
  [HighSchool.IrvingHighSchool]: "IRVING HIGH SCHOOL",
  [HighSchool.McgregorHighSchool]: "MCGREGOR HIGH SCHOOL",
  [HighSchool.RankinHighSchool]: "RANKIN HIGH SCHOOL",
  [HighSchool.MoodyHighSchoolMoody]: "MOODY HIGH SCHOOL (MOODY)",
  [HighSchool.ElkhartHighSchool]: "ELKHART HIGH SCHOOL",
  [HighSchool.ByronNelsonHighSchool]: "BYRON NELSON HIGH SCHOOL",
  [HighSchool.JudsonHighSchool]: "JUDSON HIGH SCHOOL",
  [HighSchool.WestMesquiteHighSchool]: "WEST MESQUITE HIGH SCHOOL",
  [HighSchool.VenusHighSchool]: "VENUS HIGH SCHOOL",
  [HighSchool.ReaganHighSchoolNorthEast]: "REAGAN HIGH SCHOOL (NORTH EAST)",
  [HighSchool.MemorialHighSchoolMcallen]: "MEMORIAL HIGH SCHOOL (MCALLEN)",
  [HighSchool.SouthGarlandHighSchool]: "SOUTH GARLAND HIGH SCHOOL",
  [HighSchool.AxtellBrucevilleEddy]: "AXTELL BRUCEVILLE-EDDY LEARNING CENTER",
  [HighSchool.CarlisleSchool]: "CARLISLE SCHOOL",
  [HighSchool.WheelerSchool]: "WHEELER SCHOOL",
  [HighSchool.McneilHighSchool]: "MCNEIL HIGH SCHOOL",
  [HighSchool.CrawfordHighSchool]: "CRAWFORD HIGH SCHOOL",
  [HighSchool.MagnoliaWestHighSchool]: "MAGNOLIA WEST HIGH SCHOOL",
  [HighSchool.LamarHighSchoolHouston]: "LAMAR HIGH SCHOOL (HOUSTON)",
  [HighSchool.ClevelandHighSchool]: "CLEVELAND HIGH SCHOOL",
  [HighSchool.MillerGroveSchool]: "MILLER GROVE SCHOOL",
  [HighSchool.SlatonHighSchool]: "SLATON HIGH SCHOOL",
  [HighSchool.StratfordHighSchoolSpringBranch]:
    "STRATFORD HIGH SCHOOL (SPRING BRANCH)",
  [HighSchool.VanVleckHighSchool]: "VAN VLECK HIGH SCHOOL",
  [HighSchool.MenardHighSchool]: "MENARD HIGH SCHOOL",
  [HighSchool.WodenHighSchool]: "WODEN HIGH SCHOOL",
  [HighSchool.CrossPlainsHighSchool]: "CROSS PLAINS HIGH SCHOOL",
  [HighSchool.HeightsHighSchool]: "HEIGHTS HIGH SCHOOL",
  [HighSchool.BurkburnettHighSchool]: "BURKBURNETT HIGH SCHOOL",
  [HighSchool.ClarksvilleHighSchool]: "CLARKSVILLE HIGH SCHOOL",
  [HighSchool.SabinalHighSchool]: "SABINAL HIGH SCHOOL",
  [HighSchool.PlanoEastSeniorHighSchool]: "PLANO EAST SENIOR HIGH SCHOOL",
  [HighSchool.RiceHighSchoolRiceAltair]: "RICE HIGH SCHOOL (RICE-ALTAIR)",
  [HighSchool.NordheimSchool]: "NORDHEIM SCHOOL",
  [HighSchool.JeffersonHighSchoolElPaso]: "JEFFERSON HIGH SCHOOL (EL PASO)",
  [HighSchool.CaprockHighSchool]: "CAPROCK HIGH SCHOOL",
  [HighSchool.LGPinkstonHighSchool]: "L G PINKSTON HIGH SCHOOL",
  [HighSchool.JohnFKennedyHighSchool]: "JOHN F KENNEDY HIGH SCHOOL",
  [HighSchool.CharlotteHighSchool]: "CHARLOTTE HIGH SCHOOL",
  [HighSchool.ChanningSchool]: "CHANNING SCHOOL",
  [HighSchool.MontereyHighSchool]: "MONTEREY HIGH SCHOOL",
  [HighSchool.CentralHighSchoolKeller]: "CENTRAL HIGH SCHOOL (KELLER)",
  [HighSchool.BynumSchool]: "BYNUM SCHOOL",
  [HighSchool.BordenCountySchool]: "BORDEN COUNTY SCHOOL",
  [HighSchool.KennedaleHighSchool]: "KENNEDALE HIGH SCHOOL",
  [HighSchool.DanburyHighSchool]: "DANBURY HIGH SCHOOL",
  [HighSchool.WeatherfordHighSchool]: "WEATHERFORD HIGH SCHOOL",
  [HighSchool.CoronadoHighSchoolLubbock]: "CORONADO HIGH SCHOOL (LUBBOCK)",
  [HighSchool.SierraBlancaSchool]: "SIERRA BLANCA SCHOOL",
  [HighSchool.OrangeGroveHighSchool]: "ORANGE GROVE HIGH SCHOOL",
  [HighSchool.MaydeCreekHighSchool]: "MAYDE CREEK HIGH SCHOOL",
  [HighSchool.EnnisHighSchool]: "ENNIS HIGH SCHOOL",
  [HighSchool.ZapataHighSchool]: "ZAPATA HIGH SCHOOL",
  [HighSchool.HumbleHighSchool]: "HUMBLE HIGH SCHOOL",
  [HighSchool.ODonnellHighSchool]: "O'DONNELL HIGH SCHOOL",
  [HighSchool.MortonHighSchool]: "MORTON HIGH SCHOOL",
  [HighSchool.ByronPSteeleIiHighSchool]: "BYRON P STEELE II HIGH SCHOOL",
  [HighSchool.JohnRRoach]: "JOHN R ROACH",
  [HighSchool.TomballHighSchool]: "TOMBALL HIGH SCHOOL",
  [HighSchool.HighlandParkHS]: "HIGHLAND PARK H S",
  [HighSchool.DonnaHighSchool]: "DONNA HIGH SCHOOL",
  [HighSchool.AquillaSchool]: "AQUILLA SCHOOL",
  [HighSchool.SpringHillHighSchool]: "SPRING HILL HIGH SCHOOL",
  [HighSchool.KilleenHighSchool]: "KILLEEN HIGH SCHOOL",
  [HighSchool.LexingtonHighSchool]: "LEXINGTON HIGH SCHOOL",
  [HighSchool.ClementsHighSchool]: "CLEMENTS HIGH SCHOOL",
  [HighSchool.AppleSpringsHighSchool]: "APPLE SPRINGS HIGH SCHOOL",
  [HighSchool.BeckvilleJrSrHighSchool]: "BECKVILLE JR-SR HIGH SCHOOL",
  [HighSchool.BloomburgHighSchool]: "BLOOMBURG HIGH SCHOOL",
  [HighSchool.SpringtownHighSchool]: "SPRINGTOWN HIGH SCHOOL",
  [HighSchool.NoconaHighSchool]: "NOCONA HIGH SCHOOL",
  [HighSchool.SanfordFritchHighSchool]: "SANFORD-FRITCH HIGH SCHOOL",
  [HighSchool.AustinHighSchoolAustin]: "AUSTIN HIGH SCHOOL (AUSTIN)",
  [HighSchool.LaPorteHighSchool]: "LA PORTE HIGH SCHOOL",
  [HighSchool.SeguinHighSchoolArlington]: "SEGUIN HIGH SCHOOL (ARLINGTON)",
  [HighSchool.PortNechesGrovesHighSchool]: "PORT NECHES-GROVES HIGH SCHOOL",
  [HighSchool.FortStocktonHighSchool]: "FORT STOCKTON HIGH SCHOOL",
  [HighSchool.RowlettHighSchool]: "ROWLETT HIGH SCHOOL",
  [HighSchool.PewittHighSchool]: "PEWITT HIGH SCHOOL",
  [HighSchool.CedarRidgeHighSchool]: "CEDAR RIDGE HIGH SCHOOL",
  [HighSchool.LeeSeniorHighSchool]: "LEE SENIOR HIGH SCHOOL",
  [HighSchool.HargraveHighSchool]: "HARGRAVE HIGH SCHOOL",
  [HighSchool.BroadHorizonsHighSchool]: "BROAD HORIZONS HIGH SCHOOL",
  [HighSchool.ParisHighSchool]: "PARIS HIGH SCHOOL",
  [HighSchool.GroomSchool]: "GROOM SCHOOL",
  [HighSchool.StephenvilleHighSchool]: "STEPHENVILLE HIGH SCHOOL",
  [HighSchool.CiscoHighSchool]: "CISCO HIGH SCHOOL",
  [HighSchool.BoerneHighSchool]: "BOERNE HIGH SCHOOL",
  [HighSchool.LubbockHighSchool]: "LUBBOCK HIGH SCHOOL",
  [HighSchool.SmyerHighSchool]: "SMYER HIGH SCHOOL",
  [HighSchool.RobertLeeHighSchool]: "ROBERT LEE HIGH SCHOOL",
  [HighSchool.EdnaHighSchool]: "EDNA HIGH SCHOOL",
  [HighSchool.CypressRidgeHighSchool]: "CYPRESS RIDGE HIGH SCHOOL",
  [HighSchool.PorterHighSchoolBrownsville]: "PORTER HIGH SCHOOL (BROWNSVILLE)",
  [HighSchool.BremondHighSchool]: "BREMOND HIGH SCHOOL",
  [HighSchool.HebronHighSchool]: "HEBRON HIGH SCHOOL",
  [HighSchool.BellevueSchool]: "BELLEVUE SCHOOL",
  [HighSchool.MortonRanchHighSchool]: "MORTON RANCH HIGH SCHOOL",
  [HighSchool.Connect]: "CONNECT",
  [HighSchool.WandaRSmithHighSchool]: "WANDA R SMITH HIGH SCHOOL",
  [HighSchool.TheWoodlandsHighSchool]: "THE WOODLANDS HIGH SCHOOL",
  [HighSchool.EstacadoHighSchool]: "ESTACADO HIGH SCHOOL",
  [HighSchool.ArlingtonHighSchool]: "ARLINGTON HIGH SCHOOL",
  [HighSchool.ThomasJeffersonHighSchool]: "THOMAS JEFFERSON HIGH SCHOOL",
  [HighSchool.RoscoeHighSchool]: "ROSCOE HIGH SCHOOL",
  [HighSchool.YoakumHighSchool]: "YOAKUM HIGH SCHOOL",
  [HighSchool.JimNedHighSchool]: "JIM NED HIGH SCHOOL",
  [HighSchool.WestOsoHighSchool]: "WEST OSO HIGH SCHOOL",
  [HighSchool.BigBendHighSchool]: "BIG BEND HIGH SCHOOL",
  [HighSchool.KountzeHighSchool]: "KOUNTZE HIGH SCHOOL",
  [HighSchool.GrangerSchool]: "GRANGER SCHOOL",
  [HighSchool.MoultonHighSchool]: "MOULTON HIGH SCHOOL",
  [HighSchool.WacoCtrForYouth]: "WACO CTR FOR YOUTH",
  [HighSchool.RoundRockHighSchool]: "ROUND ROCK HIGH SCHOOL",
  [HighSchool.BallingerHighSchool]: "BALLINGER HIGH SCHOOL",
  [HighSchool.HarlandaleHighSchool]: "HARLANDALE HIGH SCHOOL",
  [HighSchool.HitchcockHighSchool]: "HITCHCOCK HIGH SCHOOL",
  [HighSchool.CypressWoodsHighSchool]: "CYPRESS WOODS HIGH SCHOOL",
  [HighSchool.DalhartHighSchool]: "DALHART HIGH SCHOOL",
  [HighSchool.CenterHighSchool]: "CENTER HIGH SCHOOL",
  [HighSchool.MemphisHighSchool]: "MEMPHIS HIGH SCHOOL",
  [HighSchool.RioVistaHighSchool]: "RIO VISTA HIGH SCHOOL",
  [HighSchool.HendersonHighSchool]: "HENDERSON HIGH SCHOOL",
  [HighSchool.TaylorHighSchool]: "TAYLOR HIGH SCHOOL",
  [HighSchool.PsjaNorthHS]: "PSJA NORTH H S",
  [HighSchool.LeeHighSchoolGooseCreek]: "LEE HIGH SCHOOL (GOOSE CREEK)",
  [HighSchool.StamfordHighSchool]: "STAMFORD HIGH SCHOOL",
  [HighSchool.MullinHighSchool]: "MULLIN HIGH SCHOOL",
  [HighSchool.MaudSchool]: "MAUD SCHOOL",
  [HighSchool.MckinneyBoydHS]: "MCKINNEY BOYD H S",
  [HighSchool.FredericksburgHighSchool]: "FREDERICKSBURG HIGH SCHOOL",
  [HighSchool.VandegriftHighSchool]: "VANDEGRIFT HIGH SCHOOL",
  [HighSchool.FranklinHighSchoolFranklin]: "FRANKLIN HIGH SCHOOL (FRANKLIN)",
  [HighSchool.WindthorstHighSchool]: "WINDTHORST HIGH SCHOOL",
  [HighSchool.GrandfallsRoyaltySchool]: "GRANDFALLS-ROYALTY SCHOOL",
  [HighSchool.BloomingtonHighSchool]: "BLOOMINGTON HIGH SCHOOL",
  [HighSchool.WhitneyHighSchool]: "WHITNEY HIGH SCHOOL",
  [HighSchool.HaleCenterHighSchool]: "HALE CENTER HIGH SCHOOL",
  [HighSchool.ClearFallsHighSchool]: "CLEAR FALLS HIGH SCHOOL",
  [HighSchool.FloresvilleHighSchool]: "FLORESVILLE HIGH SCHOOL",
  [HighSchool.SanMarcosHighSchool]: "SAN MARCOS HIGH SCHOOL",
  [HighSchool.FloydadaHighSchool]: "FLOYDADA HIGH SCHOOL",
  [HighSchool.CentralHeightsMiddleHighSchool]:
    "CENTRAL HEIGHTS MIDDLE/HIGH SCHOOL",
  [HighSchool.BrazoswoodHighSchool]: "BRAZOSWOOD HIGH SCHOOL",
  [HighSchool.PeasterHighSchool]: "PEASTER HIGH SCHOOL",
  [HighSchool.NorthZulchHighSchool]: "NORTH ZULCH HIGH SCHOOL",
  [HighSchool.ArcherCityHighSchool]: "ARCHER CITY HIGH SCHOOL",
  [HighSchool.MartinsvilleSchool]: "MARTINSVILLE SCHOOL",
  [HighSchool.ElPasoHS]: "EL PASO H S",
  [HighSchool.CherokeeSchool]: "CHEROKEE SCHOOL",
  [HighSchool.AbernathyHighSchool]: "ABERNATHY HIGH SCHOOL",
  [HighSchool.StevensHighSchool]: "STEVENS HIGH SCHOOL",
  [HighSchool.CanadianHighSchool]: "CANADIAN HIGH SCHOOL",
  [HighSchool.HolyHighwayPickton]: "HOLY HIGHWAY PICKTON",
  [HighSchool.DamonHighSchool]: "DAMON HIGH SCHOOL",
  [HighSchool.LytleHighSchool]: "LYTLE HIGH SCHOOL",
  [HighSchool.IraanHighSchool]: "IRAAN HIGH SCHOOL",
  [HighSchool.FriendswoodHighSchool]: "FRIENDSWOOD HIGH SCHOOL",
  [HighSchool.ForsanHighSchool]: "FORSAN HIGH SCHOOL",
  [HighSchool.KempHighSchool]: "KEMP HIGH SCHOOL",
  [HighSchool.HearneHighSchool]: "HEARNE HIGH SCHOOL",
  [HighSchool.DelValleOpportunityCenter]: "DEL VALLE OPPORTUNITY CENTER",
  [HighSchool.AnsonHighSchool]: "ANSON HIGH SCHOOL",
  [HighSchool.BridgeCityHighSchool]: "BRIDGE CITY HIGH SCHOOL",
  [HighSchool.HicoHighSchool]: "HICO HIGH SCHOOL",
  [HighSchool.ElysianFieldsHighSchool]: "ELYSIAN FIELDS HIGH SCHOOL",
  [HighSchool.ShelbyvilleSchool]: "SHELBYVILLE SCHOOL",
  [HighSchool.BlancoHighSchool]: "BLANCO HIGH SCHOOL",
  [HighSchool.CayugaHighSchool]: "CAYUGA HIGH SCHOOL",
  [HighSchool.TroupHighSchool]: "TROUP HIGH SCHOOL",
  [HighSchool.ClydeHighSchool]: "CLYDE HIGH SCHOOL",
  [HighSchool.GrahamHighSchool]: "GRAHAM HIGH SCHOOL",
  [HighSchool.MtVernonHighSchool]: "MT VERNON HIGH SCHOOL",
  [HighSchool.GeorgeRanchHighSchool]: "GEORGE RANCH HIGH SCHOOL",
  [HighSchool.PerrytonHighSchool]: "PERRYTON HIGH SCHOOL",
  [HighSchool.WhFordHighSchool]: "WH FORD HIGH SCHOOL",
  [HighSchool.CarrollHighSchoolCorpusChristi]:
    "CARROLL HIGH SCHOOL (CORPUS CHRISTI)",
  [HighSchool.WillowridgeHighSchool]: "WILLOWRIDGE HIGH SCHOOL",
  [HighSchool.BlanketHighSchool]: "BLANKET HIGH SCHOOL",
  [HighSchool.FtDavisHighSchool]: "FT DAVIS HIGH SCHOOL",
  [HighSchool.NewCaneyHighSchool]: "NEW CANEY HIGH SCHOOL",
  [HighSchool.PantherCreekSchool]: "PANTHER CREEK SCHOOL",
  [HighSchool.LeonHighSchool]: "LEON HIGH SCHOOL",
  [HighSchool.KaufmanHighSchool]: "KAUFMAN HIGH SCHOOL",
  [HighSchool.EastChambersHighSchool]: "EAST CHAMBERS HIGH SCHOOL",
  [HighSchool.WWSamuellHighSchool]: "W W SAMUELL HIGH SCHOOL",
  [HighSchool.EvermanHighSchool]: "EVERMAN HIGH SCHOOL",
  [HighSchool.EustaceHighSchool]: "EUSTACE HIGH SCHOOL",
  [HighSchool.MccameyHighSchool]: "MCCAMEY HIGH SCHOOL",
  [HighSchool.LulingHighSchool]: "LULING HIGH SCHOOL",
  [HighSchool.CanutilloHighSchool]: "CANUTILLO HIGH SCHOOL",
  [HighSchool.SanDiegoHighSchool]: "SAN DIEGO HIGH SCHOOL",
  [HighSchool.MadisonHighSchoolNorthEast]: "MADISON HIGH SCHOOL (NORTH EAST)",
  [HighSchool.RecoveryEducationCampus]: "RECOVERY EDUCATION CAMPUS",
  [HighSchool.PsjaMemorialHS]: "PSJA MEMORIAL H S",
  [HighSchool.LumbertonHighSchool]: "LUMBERTON HIGH SCHOOL",
  [HighSchool.SharylandHighSchool]: "SHARYLAND HIGH SCHOOL",
  [HighSchool.TravisHighSchoolFortBend]: "TRAVIS HIGH SCHOOL (FORT BEND)",
  [HighSchool.BenBoltPalBlancoHighSchool]: "BEN BOLT-PAL BLANCO HIGH SCHOOL",
  [HighSchool.AnahuacHighSchool]: "ANAHUAC HIGH SCHOOL",
  [HighSchool.HallsvilleHighSchool]: "HALLSVILLE HIGH SCHOOL",
  [HighSchool.CarrollHighSchoolCarroll]: "CARROLL HIGH SCHOOL (CARROLL)",
  [HighSchool.CypressLakesHighSchool]: "CYPRESS LAKES HIGH SCHOOL",
  [HighSchool.OakRidgeHighSchool]: "OAK RIDGE HIGH SCHOOL",
  [HighSchool.ColmesneilHighSchool]: "COLMESNEIL HIGH SCHOOL",
  [HighSchool.WilliamsHighSchool]: "WILLIAMS HIGH SCHOOL",
  [HighSchool.ElkinsHighSchool]: "ELKINS HIGH SCHOOL",
  [HighSchool.RangerHighSchool]: "RANGER HIGH SCHOOL",
  [HighSchool.RockspringsHighSchool]: "ROCKSPRINGS HIGH SCHOOL",
  [HighSchool.SunnyvaleHighSchool]: "SUNNYVALE HIGH SCHOOL",
  [HighSchool.HollandHighSchool]: "HOLLAND HIGH SCHOOL",
  [HighSchool.KermitHighSchool]: "KERMIT HIGH SCHOOL",
  [HighSchool.TaftHighSchoolTaft]: "TAFT HIGH SCHOOL (TAFT)",
  [HighSchool.ColleyvilleHeritageHighSchool]:
    "COLLEYVILLE HERITAGE HIGH SCHOOL",
  [HighSchool.ComfortHighSchool]: "COMFORT HIGH SCHOOL",
  [HighSchool.JEconomedesHighSchool]: "J ECONOMEDES HIGH SCHOOL",
  [HighSchool.KashmereHighSchool]: "KASHMERE HIGH SCHOOL",
  [HighSchool.JacksonvilleHighSchool]: "JACKSONVILLE HIGH SCHOOL",
  [HighSchool.ProsperHighSchool]: "PROSPER HIGH SCHOOL",
  [HighSchool.EldoradoHighSchool]: "ELDORADO HIGH SCHOOL",
  [HighSchool.DekalbHighSchool]: "DEKALB HIGH SCHOOL",
  [HighSchool.CottonCenterSchool]: "COTTON CENTER SCHOOL",
  [HighSchool.NorthsideSchool]: "NORTHSIDE SCHOOL",
  [HighSchool.SmithvilleHighSchool]: "SMITHVILLE HIGH SCHOOL",
  [HighSchool.SeagravesHighSchool]: "SEAGRAVES HIGH SCHOOL",
  [HighSchool.DaytonHighSchool]: "DAYTON HIGH SCHOOL",
  [HighSchool.GlendaDawsonHighSchool]: "GLENDA DAWSON HIGH SCHOOL",
  [HighSchool.BenjaminSchool]: "BENJAMIN SCHOOL",
  [HighSchool.KirbyvilleHighSchool]: "KIRBYVILLE HIGH SCHOOL",
  [HighSchool.WesternHillsHighSchool]: "WESTERN HILLS HIGH SCHOOL",
  [HighSchool.FairviewVocTraining]: "FAIRVIEW VOC TRAINING",
  [HighSchool.FriscoHighSchool]: "FRISCO HIGH SCHOOL",
  [HighSchool.TivyHighSchool]: "TIVY HIGH SCHOOL",
  [HighSchool.LapoynorHighSchool]: "LAPOYNOR HIGH SCHOOL",
  [HighSchool.CiscoLearningCenter]: "CISCO LEARNING CENTER",
  [HighSchool.TolarHighSchool]: "TOLAR HIGH SCHOOL",
  [HighSchool.PaducahSchool]: "PADUCAH SCHOOL",
  [HighSchool.JarrellHighSchool]: "JARRELL HIGH SCHOOL",
  [HighSchool.WestfieldHighSchool]: "WESTFIELD HIGH SCHOOL",
  [HighSchool.PenelopeSchool]: "PENELOPE SCHOOL",
  [HighSchool.KatyHighSchool]: "KATY HIGH SCHOOL",
  [HighSchool.BrookelandHighSchool]: "BROOKELAND HIGH SCHOOL",
  [HighSchool.NewtonHighSchool]: "NEWTON HIGH SCHOOL",
  [HighSchool.NorthForestHighSchool]: "NORTH FOREST HIGH SCHOOL",
  [HighSchool.CypressParkHighSchool]: "CYPRESS PARK HIGH SCHOOL",
  [HighSchool.MemorialHighSchoolSpringBranch]:
    "MEMORIAL HIGH SCHOOL (SPRING BRANCH)",
  [HighSchool.HarlingenHighSchool]: "HARLINGEN HIGH SCHOOL",
  [HighSchool.DobieHighSchool]: "DOBIE HIGH SCHOOL",
  [HighSchool.BarbersHillHighSchool]: "BARBERS HILL HIGH SCHOOL",
  [HighSchool.HighlandSchool]: "HIGHLAND SCHOOL",
  [HighSchool.WhartonHighSchool]: "WHARTON HIGH SCHOOL",
  [HighSchool.SilsbeeHighSchool]: "SILSBEE HIGH SCHOOL",
  [HighSchool.KarenWagnerHighSchool]: "KAREN WAGNER HIGH SCHOOL",
  [HighSchool.PaintCreekSchool]: "PAINT CREEK SCHOOL",
  [HighSchool.MartHighSchool]: "MART HIGH SCHOOL",
  [HighSchool.NeedvilleHighSchool]: "NEEDVILLE HIGH SCHOOL",
  [HighSchool.LuedersAvocaHighSchool]: "LUEDERS-AVOCA HIGH SCHOOL",
  [HighSchool.DenisonHighSchool]: "DENISON HIGH SCHOOL",
  [HighSchool.VeribestHighSchool]: "VERIBEST HIGH SCHOOL",
  [HighSchool.GeorgeWestHighSchool]: "GEORGE WEST HIGH SCHOOL",
  [HighSchool.LoneStarHighSchool]: "LONE STAR HIGH SCHOOL",
  [HighSchool.LosFresnosHighSchool]: "LOS FRESNOS HIGH SCHOOL",
  [HighSchool.AmherstSchool]: "AMHERST SCHOOL",
  [HighSchool.CanyonHighSchoolComal]: "CANYON HIGH SCHOOL (COMAL)",
  [HighSchool.WorthamHighSchool]: "WORTHAM HIGH SCHOOL",
  [HighSchool.PEPMuleshoe]: "P E P (MULESHOE)",
  [HighSchool.PresidioHighSchool]: "PRESIDIO HIGH SCHOOL",
  [HighSchool.SilvertonSchool]: "SILVERTON SCHOOL",
  [HighSchool.ParadiseHighSchool]: "PARADISE HIGH SCHOOL",
  [HighSchool.ShoemakerHighSchool]: "SHOEMAKER HIGH SCHOOL",
  [HighSchool.DelValleHighSchoolDelValle]: "DEL VALLE HIGH SCHOOL (DEL VALLE)",
  [HighSchool.BowieHighSchoolBowie]: "BOWIE HIGH SCHOOL (BOWIE)",
  [HighSchool.PonderHighSchool]: "PONDER HIGH SCHOOL",
  [HighSchool.SanAugustineHighSchool]: "SAN AUGUSTINE HIGH SCHOOL",
  [HighSchool.SterlingHighSchoolHouston]: "STERLING HIGH SCHOOL (HOUSTON)",
  [HighSchool.ShinerHighSchool]: "SHINER HIGH SCHOOL",
  [HighSchool.BushlandHighSchool]: "BUSHLAND HIGH SCHOOL",
  [HighSchool.MeridianHighSchool]: "MERIDIAN HIGH SCHOOL",
  [HighSchool.SamHoustonMathScienceAndTechnologyCenter]:
    "SAM HOUSTON MATH SCIENCE AND TECHNOLOGY CENTER",
  [HighSchool.StratfordHighSchoolStratford]:
    "STRATFORD HIGH SCHOOL (STRATFORD)",
  [HighSchool.VistaRidgeHighSchool]: "VISTA RIDGE HIGH SCHOOL",
  [HighSchool.ManvelHighSchool]: "MANVEL HIGH SCHOOL",
  [HighSchool.HendricksonHighSchool]: "HENDRICKSON HIGH SCHOOL",
  [HighSchool.RichlandSpringsSchool]: "RICHLAND SPRINGS SCHOOL",
  [HighSchool.LoraineSchool]: "LORAINE SCHOOL",
  [HighSchool.McallenHighSchool]: "MCALLEN HIGH SCHOOL",
  [HighSchool.JaytonSchools]: "JAYTON SCHOOLS",
  [HighSchool.EctorHighSchool]: "ECTOR HIGH SCHOOL",
  [HighSchool.MilfordSchool]: "MILFORD SCHOOL",
  [HighSchool.FlorenceHighSchool]: "FLORENCE HIGH SCHOOL",
  [HighSchool.MidlothianHighSchool]: "MIDLOTHIAN HIGH SCHOOL",
  [HighSchool.ChinaSpringHighSchool]: "CHINA SPRING HIGH SCHOOL",
  [HighSchool.RuskHighSchool]: "RUSK HIGH SCHOOL",
  [HighSchool.RLPaschalHighSchool]: "R L PASCHAL HIGH SCHOOL",
  [HighSchool.EdenHighSchool]: "EDEN HIGH SCHOOL",
  [HighSchool.LaJoyaPalmviewHighSchool]: "LA JOYA PALMVIEW HIGH SCHOOL",
  [HighSchool.LdBellHighSchool]: "LD BELL HIGH SCHOOL",
  [HighSchool.AMConsolidatedHighSchool]: "A & M CONSOLIDATED HIGH SCHOOL",
  [HighSchool.BanqueteHighSchool]: "BANQUETE HIGH SCHOOL",
  [HighSchool.MarathonIndpendentSchoolDist]: "MARATHON INDPENDENT SCHOOL DIST",
  [HighSchool.BorgerHighSchool]: "BORGER HIGH SCHOOL",
  [HighSchool.DumasHighSchool]: "DUMAS HIGH SCHOOL",
  [HighSchool.ClearBrookHighSchool]: "CLEAR BROOK HIGH SCHOOL",
  [HighSchool.WhiteOakHighSchool]: "WHITE OAK HIGH SCHOOL",
  [HighSchool.PoteetHighSchoolMesquite]: "POTEET HIGH SCHOOL (MESQUITE)",
  [HighSchool.SudanHighSchool]: "SUDAN HIGH SCHOOL",
  [HighSchool.LamesaSuccessAcademy]: "LAMESA SUCCESS ACADEMY",
  [HighSchool.PlanoSeniorHighSchool]: "PLANO SENIOR HIGH SCHOOL",
  [HighSchool.MillerHighSchoolCtrForCommunication]:
    "MILLER HIGH SCHOOL CTR FOR COMMUNICATION",
  [HighSchool.AransasPassHighSchool]: "ARANSAS PASS HIGH SCHOOL",
  [HighSchool.TheLarryBrownSchool]: "THE LARRY BROWN SCHOOL",
  [HighSchool.BrennanHighSchool]: "BRENNAN HIGH SCHOOL",
  [HighSchool.WTWhiteHighSchool]: "W T WHITE HIGH SCHOOL",
  [HighSchool.BishopHighSchool]: "BISHOP HIGH SCHOOL",
  [HighSchool.AngletonHighSchool]: "ANGLETON HIGH SCHOOL",
  [HighSchool.SulphurBluffSchool]: "SULPHUR BLUFF SCHOOL",
  [HighSchool.JustinFKimballHighSchool]: "JUSTIN F KIMBALL HIGH SCHOOL",
  [HighSchool.NechesHighSchool]: "NECHES HIGH SCHOOL",
  [HighSchool.KingwoodHighSchool]: "KINGWOOD HIGH SCHOOL",
  [HighSchool.LipanHighSchool]: "LIPAN HIGH SCHOOL",
  [HighSchool.LaJoyaHighSchool]: "LA JOYA HIGH SCHOOL",
  [HighSchool.WilsonSchool]: "WILSON SCHOOL",
  [HighSchool.GordonSchool]: "GORDON SCHOOL",
  [HighSchool.WallerHighSchool]: "WALLER HIGH SCHOOL",
  [HighSchool.SnyderHighSchool]: "SNYDER HIGH SCHOOL",
  [HighSchool.ChisholmTrailHighSchool]: "CHISHOLM TRAIL HIGH SCHOOL",
  [HighSchool.AustinHighSchoolFortBend]: "AUSTIN HIGH SCHOOL (FORT BEND)",
  [HighSchool.NorthForneyHighSchool]: "NORTH FORNEY HIGH SCHOOL",
  [HighSchool.WestbrookSchool]: "WESTBROOK SCHOOL",
  [HighSchool.HeritageHighSchool]: "HERITAGE HIGH SCHOOL",
  [HighSchool.CliftonHighSchool]: "CLIFTON HIGH SCHOOL",
  [HighSchool.HighIslandHighSchool]: "HIGH ISLAND HIGH SCHOOL",
  [HighSchool.RobyHighSchool]: "ROBY HIGH SCHOOL",
  [HighSchool.FabensHighSchool]: "FABENS HIGH SCHOOL",
  [HighSchool.ElDoradoHighSchool]: "EL DORADO HIGH SCHOOL",
  [HighSchool.StarSchool]: "STAR SCHOOL",
  [HighSchool.KempnerHighSchool]: "KEMPNER HIGH SCHOOL"
};
