import { FormRules } from "@homesusa/form";

import { openHousesRules, showingRules } from "../../sections";

export const showingInfoRules: FormRules = {
  ...openHousesRules,
  ...showingRules,
  agentPrivateRemarks: {
    label: "Agent Remarks",
    maxLength: 500,
    disabled: true,
    rows: 5
  },
  agentPrivateRemarksAdditional: {
    label: "Agent or Private Remarks Adicional",
    maxLength: 200,
    disabled: true,
    rows: 5
  },
  directions: {
    label: "Directions",
    requiredToSubmit: true,
    maxLength: 400,
    regex: '^[^"|\\/]+$',
    regexMessage: 'Not allowed chars "  | \\ /',
    replaceRegex: '["\\/|]+',
    rows: 5
  },
  supervisorName: {
    label: "L/A Supervisor Name",
    maxLength: 50,
    disabled: true
  },
  supervisorPhone: {
    label: "Supervisor's Phone #",
    disabled: true
  },
  supervisorLicense: {
    label: "L/A Supervisor License Number",
    disabled: true
  }
};
