export enum Stories {
  One = "one",
  OneAndOneHalf = "oneAndOneHalf",
  Two = "two",
  ThreePlus = "threePlus"
}

export const StoriesLabel = new Map<Stories, string>([
  [Stories.One, "One"],
  [Stories.OneAndOneHalf, "One and One Half"],
  [Stories.Two, "Two"],
  [Stories.ThreePlus, "Three or More"]
]);
