export enum HousingStyle {
  OneStory = "oneStory",
  TwoStory = "twoStory",
  Contemporary = "contemporary",
  Craftsman = "craftsman",
  Mediterranean = "mediterranean",
  Other = "other",
  Ranch = "ranch",
  SplitLevel = "splitLevel",
  Spanish = "spanish",
  TexasHillCountry = "texasHillCountry",
  Traditional = "traditional",
  Tudor = "tudor"
}

export const HousingStyleLabel = new Map<HousingStyle, string>([
  [HousingStyle.OneStory, "One Story"],
  [HousingStyle.TwoStory, "Two Story"],
  [HousingStyle.Contemporary, "Contemporary"],
  [HousingStyle.Craftsman, "Craftsman"],
  [HousingStyle.Mediterranean, "Mediterranean"],
  [HousingStyle.Other, "Other"],
  [HousingStyle.Ranch, "Ranch"],
  [HousingStyle.SplitLevel, "Split Level"],
  [HousingStyle.Spanish, "Spanish"],
  [HousingStyle.TexasHillCountry, "Texas Hill Country"],
  [HousingStyle.Traditional, "Traditional"],
  [HousingStyle.Tudor, "Tudor"]
]);
