export enum WindowsFeatures {
  BayWindows = "bayWindows",
  Blinds = "blinds",
  DoublePaneWindows = "doublePaneWindows",
  EnergyStarQualifiedWindows = "energyStarQualifiedWindows",
  InsulatedWindows = "insulatedWindows",
  None = "none",
  PlantationShutters = "plantationShutters",
  Screens = "screens",
  Shutters = "shutters",
  StormWindows = "stormWindows",
  VinylWindows = "vinylWindows"
}

export const WindowsFeaturesLabel = new Map<WindowsFeatures, string>([
  [WindowsFeatures.BayWindows, "Bay Window(s)"],
  [WindowsFeatures.Blinds, "Blinds"],
  [WindowsFeatures.DoublePaneWindows, "Double Pane Windows"],
  [WindowsFeatures.EnergyStarQualifiedWindows, "ENERGYSTAR Qualified Windows"],
  [WindowsFeatures.InsulatedWindows, "Insulated Windows"],
  [WindowsFeatures.None, "None"],
  [WindowsFeatures.PlantationShutters, "Plantation Shutters"],
  [WindowsFeatures.Screens, "Screens"],
  [WindowsFeatures.Shutters, "Shutters"],
  [WindowsFeatures.StormWindows, "Storm Windows"],
  [WindowsFeatures.VinylWindows, "Vinyl Windows"]
]);
