import { Agent } from "core/interfaces";

export const getAgentTitle = (
  { fullName, companyName, mlsId, memberStateLicense }: Agent,
  useMemberStateLicese = false
): string => {
  const id = useMemberStateLicese ? memberStateLicense : mlsId;
  return `${id != null && id !== "" ? id + " - " : ""}${fullName}${
    companyName ? " - " + companyName : ""
  }`;
};
