import React from "react";

import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  CancelledOption,
  CancelledOptionsLabel,
  HowSold,
  HowSoldLabel,
  MlsStatus,
  SellerConcessionDesc,
  SellerConcessionDescLabel
} from "markets/sabor/enums";
import {
  ClosedDateTooltip,
  ContractDateTooltip,
  EstimatedCloseDateTooltip
} from "core/components";
import { getNextDayDate, getYesterday } from "core/utils/dates";

export const statusFieldLabels = {
  hasBuyerAgent: {
    label: "Represented by Buyer's Agent"
  }
};

export const rulesByStatus = new Map<MlsStatus, FormRules>([
  [MlsStatus.Active, {}],
  [MlsStatus.ActiveOption, {}],
  [MlsStatus.ActiveRFR, {}],
  [MlsStatus.BackOnMarket, {}],
  [MlsStatus.PriceChange, {}],
  [
    MlsStatus.Withdrawn,
    {
      offMarketDate: {
        label: "Off Market Date",
        requiredToSubmit: true
      },
      backOnMarketDate: {
        label: "Back On Market Date",
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Cancelled,
    {
      cancelledOption: {
        label: "Cancelled Option",
        lastOptions: [CancelledOption.Other],
        options: getOptionsFromEnumAndMap(
          Object.values(CancelledOption),
          CancelledOptionsLabel
        ),
        requiredToSubmit: true
      },
      cancelledReason: {
        label: "Cancelled Reason",
        maxLength: 300,
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Pending,
    {
      estimatedClosedDate: {
        label: "Estimated Close Date",
        tooltip: <EstimatedCloseDateTooltip />,
        requiredToSubmit: true,
        customFieldProps: {
          errorMessages: {
            minDate: "Estimated Close Date must be after Today's Date"
          }
        },
        minDate: getNextDayDate(new Date())
      },
      contractDate: {
        label: "Contract Date",
        requiredToSubmit: true,
        tooltip: <ContractDateTooltip />
      },
      hasBuyerAgent: {
        label: "Represented by Buyer's Agent"
      },
      agentId: {
        label: "Buyer's Agent"
      }
    }
  ],
  [
    MlsStatus.PendingSB,
    {
      estimatedClosedDate: {
        label: "Estimated Close Date",
        tooltip: <EstimatedCloseDateTooltip />,
        requiredToSubmit: true
      },
      contractDate: {
        label: "Contract Date",
        requiredToSubmit: true,
        tooltip: <ContractDateTooltip />
      },
      hasBuyerAgent: {
        label: "Represented by Buyer's Agent"
      },
      agentId: {
        label: "Buyer's Agent"
      }
    }
  ],

  [
    MlsStatus.Sold,
    {
      contingencyInfo: {
        label: "Contingent Info",
        maxLength: 100
      },
      howSold: {
        label: "How Sold",
        options: getOptionsFromEnumAndMap(Object.values(HowSold), HowSoldLabel),
        requiredToSubmit: true
      },
      contractDate: {
        label: "Contract Date",
        requiredToSubmit: true,
        tooltip: <ContractDateTooltip />,
        maxDate: getYesterday()
      },
      closedDate: {
        label: "Closing Date",
        requiredToSubmit: true,
        maxDate: new Date(),
        tooltip: <ClosedDateTooltip />,
        customFieldProps: {
          errorMessages: {
            minDate: "Closing Date must be after contract date"
          }
        }
      },
      closePrice: {
        label: "Sold Price",
        numberFormat: {
          max: 2000000,
          min: 100000,
          prefix: "$",
          thousandSeparator: true
        },
        maxLength: 10,
        requiredToSubmit: true
      },
      hasBuyerAgent: {
        label: "Represented by Buyer's Agent"
      },
      agentId: {
        label: "Buyer's Agent"
      },

      sellConcess: {
        label: "Seller Concessions($)",
        maxLength: 7,
        numberFormat: {
          min: 0,
          max: 99999,
          prefix: "$",
          thousandSeparator: true
        },
        requiredToSubmit: true
      },
      sellerConcessionDescription: {
        label: "Seller Concessions Description",
        options: getOptionsFromEnumAndMap(
          Object.values(SellerConcessionDesc),
          SellerConcessionDescLabel
        ),
        requiredToSubmit: true,
        rows: 2
      }
    }
  ]
]);
