import { styled } from "@mui/material";

import { fieldErrorClassName } from "../constants";

export const FormStyled = styled("div")(({ theme }) => ({
  "& .form-field": {
    width: "100%"
  },
  "& .MuiFormLabel-root:not(.Mui-error).Mui-required": {
    color: theme.palette.primary.light
  },
  [`& .required-submit:not(.${fieldErrorClassName}) .MuiFormLabel-root:not(.Mui-error)`]:
    {
      color: theme.palette.primary.light
    },
  [`& .${fieldErrorClassName} .MuiOutlinedInput-notchedOutline`]: {
    borderColor: theme.palette.error.main
  },
  [`& .${fieldErrorClassName} .MuiFormLabel-root`]: {
    color: theme.palette.error.main
  }
}));
