export enum EnergyFeatures {
  TanklessWaterHeater = "tanklessWaterHeater",
  CeilingFans = "ceilingFans",
  EnergyStarAppliances = "energyStarAppliances",
  SixteenPlusSeerAC = "sixteenPlusSeerAC",
  NinetyPercentEfficientFurnace = "ninetyPercentEfficientFurnace",
  DoublePaneWindows = "doublePaneWindows",
  SmartElectricMeter = "smartElectricMeter",
  TwelvePlusAtticInsulation = "twelvePlusAtticInsulation",
  StormWindows = "stormWindows",
  ThirteenSeerAx = "thirteenSeerAx",
  RadiantBarrier = "radiantBarrier",
  VariableSpeedHVAC = "variableSpeedHVAC",
  CelluloseInsulation = "celluloseInsulation",
  FoamInsulation = "foamInsulation",
  LowEmissivityWindows = "lowEmissivityWindows",
  ProgrammableThermostat = "programmableThermostat",
  HighEfficiencyWaterHeater = "highEfficiencyWaterHeater",
  RecirculatingHotWater = "recirculatingHotWater"
}

export const EnergyFeaturesLabel = new Map<EnergyFeatures, string>([
  [EnergyFeatures.TanklessWaterHeater, "Tankless Water Heater"],
  [EnergyFeatures.CeilingFans, "Ceiling Fans"],
  [EnergyFeatures.EnergyStarAppliances, "Energy Star Appliances"],
  [EnergyFeatures.SixteenPlusSeerAC, "16+ SEER AC"],
  [EnergyFeatures.NinetyPercentEfficientFurnace, "90% Efficient Furnace"],
  [EnergyFeatures.DoublePaneWindows, "Double Pane Windows"],
  [EnergyFeatures.SmartElectricMeter, "Smart Electric Meter"],
  [EnergyFeatures.TwelvePlusAtticInsulation, '12"+ Attic Insulation'],
  [EnergyFeatures.StormWindows, "Storm Windows"],
  [EnergyFeatures.ThirteenSeerAx, "13-15 SEER AX"],
  [EnergyFeatures.RadiantBarrier, "Radiant Barrier"],
  [EnergyFeatures.VariableSpeedHVAC, "Variable Speed HVAC"],
  [EnergyFeatures.CelluloseInsulation, "Cellulose Insulation"],
  [EnergyFeatures.FoamInsulation, "Foam Insulation"],
  [EnergyFeatures.LowEmissivityWindows, "Low E Windows"],
  [EnergyFeatures.ProgrammableThermostat, "Programmable Thermostat"],
  [EnergyFeatures.HighEfficiencyWaterHeater, "High Efficiency Water Heater"],
  [EnergyFeatures.RecirculatingHotWater, "Recirculating Hot Water"]
]);
