export enum ConstructionExterior {
  SideMasonry1 = "sideMasonry1",
  SideMasonry2 = "sideMasonry2",
  SideMasonry3 = "sideMasonry3",
  SideMasonry4 = "sideMasonry4",
  BattsInsulation = "battsInsulation",
  BlownInInsulation = "blownInInsulation",
  BoardAndBattenSiding = "boardAndBattenSiding",
  Brick = "brick",
  BrickVeneer = "brickVeneer",
  ConcreteFiberPlank = "concreteFiberPlank",
  DuctsProfessionallyAirSealed = "ductsProfessionallyAirSealed",
  FoamInsulation = "foamInsulation",
  Frame = "frame",
  FrameBrickVeneer = "frameBrickVeneer",
  FrameStone = "frameStone",
  Glass = "glass",
  Hardiplank = "hardiplank",
  ICATRecessedLighting = "iCATRecessedLighting",
  OtherSeeRemarks = "otherSeeRemarks",
  RadiantBarrier = "radiantBarrier",
  RockStoneVeneer = "rockStoneVeneer",
  SidingVinyl = "sidingVinyl",
  SprayFoamInsulation = "sprayFoamInsulation",
  SteelFrame = "steelFrame",
  StoneVeneer = "stoneVeneer",
  Stucco = "stucco",
  SyntheticStucco = "syntheticStucco",
  Wood = "wood",
  None = "none"
}

export const ConstructionExteriorLabel = new Map<ConstructionExterior, string>([
  [ConstructionExterior.SideMasonry1, "1-Side Masonry"],
  [ConstructionExterior.SideMasonry2, "2-Side Masonry"],
  [ConstructionExterior.SideMasonry3, "3-Side Masonry"],
  [ConstructionExterior.SideMasonry4, "4-Side Masonry"],
  [ConstructionExterior.BattsInsulation, "Batts Insulation"],
  [ConstructionExterior.BlownInInsulation, "Blown-In Insulation"],
  [ConstructionExterior.BoardAndBattenSiding, "Board & Batten Siding"],
  [ConstructionExterior.Brick, "Brick"],
  [ConstructionExterior.BrickVeneer, "Brick Veneer"],
  [ConstructionExterior.ConcreteFiberPlank, "Concrete/Fiber Plank"],
  [
    ConstructionExterior.DuctsProfessionallyAirSealed,
    "Ducts Professionally Air-Sealed"
  ],
  [ConstructionExterior.FoamInsulation, "Foam Insulation"],
  [ConstructionExterior.Frame, "Frame"],
  [ConstructionExterior.FrameBrickVeneer, "Frame/Brick Veneer"],
  [ConstructionExterior.FrameStone, "Frame/Stone"],
  [ConstructionExterior.Glass, "Glass"],
  [ConstructionExterior.Hardiplank, "Hardiplank"],
  [ConstructionExterior.ICATRecessedLighting, "ICAT Recessed Lighting"],
  [ConstructionExterior.OtherSeeRemarks, "Other-See Remarks"],
  [ConstructionExterior.RadiantBarrier, "Radiant Barrier"],
  [ConstructionExterior.RockStoneVeneer, "Rock/Stone/Veneer"],
  [ConstructionExterior.SidingVinyl, "Siding-Vinyl"],
  [ConstructionExterior.SprayFoamInsulation, "Spray Foam Insulation"],
  [ConstructionExterior.SteelFrame, "Steel Frame"],
  [ConstructionExterior.StoneVeneer, "Stone Veneer"],
  [ConstructionExterior.Stucco, "Stucco"],
  [ConstructionExterior.SyntheticStucco, "Synthetic Stucco"],
  [ConstructionExterior.Wood, "Wood"],
  [ConstructionExterior.None, "None"]
]);
