export enum OvenDescription {
  FreestandingOven = "freestandingOven",
  ConvectionOven = "convectionOven",
  GasOven = "gasOven",
  ElectricOven = "electricOven",
  DoubleOven = "doubleOven",
  SingleOven = "singleOven"
}

export const OvenDescriptionLabel = new Map<OvenDescription, string>([
  [OvenDescription.FreestandingOven, "Freestanding Oven"],
  [OvenDescription.ConvectionOven, "Convection Oven"],
  [OvenDescription.GasOven, "Gas Oven"],
  [OvenDescription.ElectricOven, "Electric Oven"],
  [OvenDescription.DoubleOven, "Double Oven"],
  [OvenDescription.SingleOven, "Single Oven"]
]);
