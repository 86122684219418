export enum HeatSystem {
  OneUnit = "oneUnit",
  TwoUnits = "twoUnits",
  Central = "central",
  Zoned = "zoned",
  HeatPump = "heatPump",
  Other = "other"
}

export const HeatSystemLabel = new Map<HeatSystem, string>([
  [HeatSystem.OneUnit, "1 Unit"],
  [HeatSystem.TwoUnits, "2 Units"],
  [HeatSystem.Central, "Central"],
  [HeatSystem.Zoned, "Zoned"],
  [HeatSystem.HeatPump, "Heat Pump"],
  [HeatSystem.Other, "Other"]
]);
