export enum SellerConcessionDesc {
  Cash = "cash",
  ContractForDeed = "contractForDeed",
  Conventional = "conventional",
  Fha = "fha",
  HousingVoucher = "housingVoucher",
  Other = "other",
  OwnerFinancing = "ownerFinancing",
  Va = "va"
}
export const SellerConcessionDescLabel = new Map<SellerConcessionDesc, string>([
  [SellerConcessionDesc.Cash, "Cash"],
  [SellerConcessionDesc.ContractForDeed, "Contract for Deed"],
  [SellerConcessionDesc.Conventional, "Conventional"],
  [SellerConcessionDesc.Fha, "FHA"],
  [SellerConcessionDesc.HousingVoucher, "Housing Voucher"],
  [SellerConcessionDesc.Other, "Other"],
  [SellerConcessionDesc.OwnerFinancing, "Owner Financing"],
  [SellerConcessionDesc.Va, "VA"]
]);
