import { FormRules } from "@homesusa/form";
import { ownerNameRule } from "./community/common-rules";
import { spacesDimensionsRules } from "./sections";

export const planRules: FormRules = {
  ...spacesDimensionsRules,
  ownerName: ownerNameRule,
  name: {
    label: "Plan Name",
    required: true,
    notAllowedWhiteSpaces: "start"
  }
};
