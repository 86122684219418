import { MlsStatusColor } from "core/enums";

export enum MlsStatus {
  Active = "active",
  Closed = "closed",
  Cancelled = "cancelled",
  UnderContractWContingency = "underContractWContingency",
  Pending = "pending",
  Withdrawn = "withdrawn"
}
export const MlsStatusForNewListings = [
  MlsStatus.Active,
  MlsStatus.UnderContractWContingency,
  MlsStatus.Pending,
  MlsStatus.Closed
];

export const MlsStatusLabel = new Map<MlsStatus, string>([
  [MlsStatus.Active, "Active"],
  [MlsStatus.Closed, "Closed"],
  [MlsStatus.Cancelled, "Cancelled"],
  [MlsStatus.UnderContractWContingency, "Under Contract W/Contingency"],
  [MlsStatus.Pending, "Pending"],
  [MlsStatus.Withdrawn, "Withdrawn"]
]);

export const StatusColorLabel = new Map<MlsStatus, MlsStatusColor>([
  [MlsStatus.Active, MlsStatusColor.Active],
  [MlsStatus.UnderContractWContingency, MlsStatusColor.Active],
  [MlsStatus.Pending, MlsStatusColor.Pending],
  [MlsStatus.Closed, MlsStatusColor.Sold],
  [MlsStatus.Withdrawn, MlsStatusColor.Withdrawn],
  [MlsStatus.Cancelled, MlsStatusColor.Canceled]
]);

export const MlsStatusWithoutStatusFields: MlsStatus[] = [MlsStatus.Active];
