export enum MiddleSchool {
  Academy = "academy",
  AlterLearning = "alterLearning",
  Paredes = "paredes",
  AnnRichards = "annRichards",
  ArlonRSeayInt = "arlonRSeayInt",
  ArmandoChapa = "armandoChapa",
  ArtieLHenry = "artieLHenry",
  AudieMurphy = "audieMurphy",
  Bailey = "bailey",
  BarbaraJordanInt = "barbaraJordanInt",
  Bartlett = "bartlett",
  BastropIntermediate = "bastropIntermediate",
  Bastrop = "bastrop",
  Bedichek = "bedichek",
  BeeCaveMiddleSchool = "beeCaveMiddleSchool",
  Belton = "belton",
  BerthaSadlerMeans = "berthaSadlerMeans",
  Blanco = "blanco",
  BoerneMiddleNorth = "boerneMiddleNorth",
  BoerneMiddleSouth = "boerneMiddleSouth",
  Bohls = "bohls",
  Bonham = "bonham",
  BorgfeldArea = "borgfeldArea",
  Bowen = "bowen",
  Bowman = "bowman",
  Briesemeister = "briesemeister",
  BrucevilleEddy = "brucevilleEddy",
  Buckholts = "buckholts",
  Burnet = "burnet",
  CameronMiddle = "cameronMiddle",
  CanyonIntermediate = "canyonIntermediate",
  CanyonMiddle = "canyonMiddle",
  CanyonRidge = "canyonRidge",
  CanyonVista = "canyonVista",
  CDFulkes = "cdFulkes",
  CedarCreekIntermediate = "cedarCreekIntermediate",
  CedarCreek = "cedarCreek",
  CedarPark = "cedarPark",
  CedarValley = "cedarValley",
  Cele = "cele",
  CharlesAForbes = "charlesAForbes",
  CharlesEPatterson = "charlesEPatterson",
  Cherokee = "cherokee",
  ChisholmTrail = "chisholmTrail",
  ChurchHill = "churchHill",
  Comal = "comal",
  CopperasCove = "copperasCove",
  Corbett = "corbett",
  CouplandMiddle = "couplandMiddle",
  Covington = "covington",
  CPLERobertPHernandez = "cplERobertPHernandez",
  Cuero = "cuero",
  CueroIntermediate = "cueroIntermediate",
  EricDahlstrom = "ericDahlstrom",
  Dailey = "dailey",
  Danford = "danford",
  Danielson = "danielson",
  Decker = "decker",
  Deerpark = "deerpark",
  DelValle = "delValle",
  Dessau = "dessau",
  DimeBox = "dimeBox",
  DJRedSimon = "djRedSimon",
  Dobie = "dobie",
  DorisMiller = "dorisMiller",
  DouglasBenold = "douglasBenold",
  DrippingSpringsInt = "drippingSpringsInt",
  DrippingSpringsMiddle = "drippingSpringsMiddle",
  EasternHills = "easternHills",
  ElaineSWSchlatherInt = "elaineSWSchlatherInt",
  Elgin = "elgin",
  Evant = "evant",
  Fairway = "fairway",
  Farley = "farley",
  Fayetteville = "fayetteville",
  FaltoniaSecondary = "faltoniaSecondary",
  Florence = "florence",
  FourPoints = "fourPoints",
  Fredericksburg = "fredericksburg",
  Fulmore = "fulmore",
  Garcia = "garcia",
  Gatesville = "gatesville",
  GatesvilleInt = "gatesvilleInt",
  Gateway = "gateway",
  Gause = "gause",
  GiddingsInt = "giddingsInt",
  Giddings = "giddings",
  GilbertGerdes = "gilbertGerdes",
  Goldthwaite = "goldthwaite",
  Gonzales = "gonzales",
  GonzalesNAveIntermediate = "gonzalesNAveIntermediate",
  Goodnight = "goodnight",
  Gorzycki = "gorzycki",
  Granger = "granger",
  Harper = "harper",
  Hearne = "hearne",
  Henry = "henry",
  Hernandez = "hernandez",
  HillCountry = "hillCountry",
  Holland = "holland",
  Hopewell = "hopewell",
  HudsonBend = "hudsonBend",
  Hutto = "hutto",
  JamesTippit = "jamesTippit",
  Jarrell = "jarrell",
  JimBarnes = "jimBarnes",
  JoeFSaegert6thGradeCenter = "joeFSaegert6thGradeCenter",
  JohnPOjeda = "johnPOjeda",
  Kealing = "kealing",
  KellyLane = "kellyLane",
  LagoVista = "lagoVista",
  LaGrangeInt = "laGrangeInt",
  LaGrange = "laGrange",
  LakeBelton = "lakeBelton",
  LakeTravis = "lakeTravis",
  Lamar = "lamar",
  Lampasas = "lampasas",
  LauraIngallsWilderInt = "lauraIngallsWilderInt",
  LaVernia = "laVernia",
  LeanderMiddle = "leanderMiddle",
  Lexington = "lexington",
  LibertyHillJr = "libertyHillJr",
  LibertyHillIntermediate = "libertyHillIntermediate",
  LibertyHillMiddle = "libertyHillMiddle",
  Lively = "lively",
  LiveOakRidge = "liveOakRidge",
  Llano = "llano",
  Lockhart = "lockhart",
  Lometa = "lometa",
  Luling = "luling",
  LyndonBJohnson = "lyndonBJohnson",
  Manor = "manor",
  MarbleFalls = "marbleFalls",
  Marion = "marion",
  Martin = "martin",
  Mason = "mason",
  McCormick = "mccormick",
  McDade = "mcDade",
  McNeilArea = "mcNeilArea",
  Mendez = "mendez",
  Milano = "milano",
  Miller = "miller",
  Moody = "moody",
  Moulton = "moulton",
  MountainValleyMiddle = "mountainValleyMiddle",
  Mumford = "mumford",
  Murchison = "murchison",
  NotApplicable = "notApplicable",
  Navarro = "navarro",
  NewBraunfels = "newBraunfels",
  NewSouth = "newSouth",
  NixonSmiley = "nixonSmiley",
  NoelGrisham = "noelGrisham",
  Nolan = "nolan",
  NorthBelton = "northBelton",
  Oakrun = "oakrun",
  OHenry = "oHenry",
  Ojeda = "ojeda",
  OwenGoodnight = "owenGoodnight",
  PaloAlto = "paloAlto",
  ParkCrest = "parkCrest",
  Pearce = "pearce",
  PearsonRanch = "pearsonRanch",
  Pflugerville = "pflugerville",
  PleasantHill = "pleasantHill",
  Porter = "porter",
  PrairieLea = "prairieLea",
  Rancier = "rancier",
  RayDCorbett = "rayDCorbett",
  RaymondMays = "raymondMays",
  RCBarton = "rcBarton",
  RichlandSprings = "richlandSprings",
  Ridgeview = "ridgeview",
  Rockdale = "rockdale",
  Rogers = "rogers",
  RosebudLott = "rosebudLott",
  RosebudInt = "rosebudInt",
  RoundTopCarmine = "roundTopCarmine",
  RunningBrushy = "runningBrushy",
  Salado = "salado",
  SaladoIntermediate = "saladoIntermediate",
  SanSaba = "sanSaba",
  SantaRitaMiddle = "santaRitaMiddle",
  SchulenburgSecondary = "schulenburgSecondary",
  SCLee = "scLee",
  Shiner = "shiner",
  SisterRidge = "sisterRidge",
  Small = "small",
  Smith = "smith",
  SmithsonValley = "smithsonValley",
  Smithville = "smithville",
  SouthBelton = "southBelton",
  SpringBranch = "springBranch",
  Star = "star",
  Stiles = "stiles",
  SycamoreSprings = "sycamoreSprings",
  Taylor = "taylor",
  Thorndale = "thorndale",
  Thrall = "thrall",
  Travis = "travis",
  Troy = "troy",
  UnionGrove = "unionGrove",
  Waelder = "waelder",
  Wagner = "wagner",
  LauraWallace = "lauraWallace",
  Walsh = "walsh",
  Webb = "webb",
  Weimar = "weimar",
  WestRidge = "westRidge",
  Westside = "westside",
  Westview = "westview",
  KnoxWiley = "knoxWiley",
  Danforth = "danforth",
  Yoakum = "yoakum"
}
export const MiddleSchoolLabel = new Map<MiddleSchool, string>([
  [MiddleSchool.Academy, "Academy"],
  [MiddleSchool.AlterLearning, "Alter Learning"],
  [MiddleSchool.Paredes, "Paredes"],
  [MiddleSchool.AnnRichards, "Ann Richards"],
  [MiddleSchool.ArlonRSeayInt, "Arlon R Seay Int"],
  [MiddleSchool.ArmandoChapa, "Armando Chapa"],
  [MiddleSchool.ArtieLHenry, "Artie L Henry"],
  [MiddleSchool.AudieMurphy, "Audie Murphy"],
  [MiddleSchool.Bailey, "Bailey"],
  [MiddleSchool.BarbaraJordanInt, "Barbara Jordan Int"],
  [MiddleSchool.Bartlett, "Bartlett"],
  [MiddleSchool.BastropIntermediate, "Bastrop Intermediate"],
  [MiddleSchool.Bastrop, "Bastrop"],
  [MiddleSchool.Bedichek, "Bedichek"],
  [MiddleSchool.BeeCaveMiddleSchool, "Bee Cave Middle School"],
  [MiddleSchool.Belton, "Belton"],
  [MiddleSchool.BerthaSadlerMeans, "Bertha Sadler Means"],
  [MiddleSchool.Blanco, "Blanco"],
  [MiddleSchool.BoerneMiddleNorth, "Boerne Middle North"],
  [MiddleSchool.BoerneMiddleSouth, "Boerne Middle South"],
  [MiddleSchool.Bohls, "Bohls"],
  [MiddleSchool.Bonham, "Bonham"],
  [MiddleSchool.BorgfeldArea, "Borgfeld Area"],
  [MiddleSchool.Bowen, "Bowen"],
  [MiddleSchool.Bowman, "Bowman"],
  [MiddleSchool.Briesemeister, "Briesemeister"],
  [MiddleSchool.BrucevilleEddy, "Bruceville-Eddy"],
  [MiddleSchool.Buckholts, "Buckholts"],
  [MiddleSchool.Burnet, "Burnet"],
  [MiddleSchool.CameronMiddle, "Cameron Middle"],
  [MiddleSchool.CanyonIntermediate, "Canyon Intermediate"],
  [MiddleSchool.CanyonMiddle, "Canyon Middle"],
  [MiddleSchool.CanyonRidge, "Canyon Ridge"],
  [MiddleSchool.CanyonVista, "Canyon Vista"],
  [MiddleSchool.CDFulkes, "CD Fulkes"],
  [MiddleSchool.CedarCreekIntermediate, "Cedar Creek Intermediate"],
  [MiddleSchool.CedarCreek, "Cedar Creek"],
  [MiddleSchool.CedarPark, "Cedar Park"],
  [MiddleSchool.CedarValley, "Cedar Valley"],
  [MiddleSchool.Cele, "Cele"],
  [MiddleSchool.CharlesAForbes, "Charles A Forbes"],
  [MiddleSchool.CharlesEPatterson, "Charles E Patterson"],
  [MiddleSchool.Cherokee, "Cherokee"],
  [MiddleSchool.ChisholmTrail, "Chisholm Trail"],
  [MiddleSchool.ChurchHill, "Church Hill"],
  [MiddleSchool.Comal, "Comal"],
  [MiddleSchool.CopperasCove, "Copperas Cove"],
  [MiddleSchool.Corbett, "Corbett"],
  [MiddleSchool.CouplandMiddle, "Coupland Middle"],
  [MiddleSchool.Covington, "Covington"],
  [MiddleSchool.CPLERobertPHernandez, "CPL Robert P Hernandez"],
  [MiddleSchool.Cuero, "Cuero"],
  [MiddleSchool.CueroIntermediate, "Cuero Intermediate"],
  [MiddleSchool.EricDahlstrom, "Eric Dahlstrom"],
  [MiddleSchool.Dailey, "Dailey"],
  [MiddleSchool.Danford, "Danford"],
  [MiddleSchool.Danielson, "Danielson"],
  [MiddleSchool.Decker, "Decker"],
  [MiddleSchool.Deerpark, "Deerpark"],
  [MiddleSchool.DelValle, "Del Valle"],
  [MiddleSchool.Dessau, "Dessau"],
  [MiddleSchool.DimeBox, "Dime Box"],
  [MiddleSchool.DJRedSimon, "D J Red Simon"],
  [MiddleSchool.Dobie, "Dobie"],
  [MiddleSchool.DorisMiller, "Doris Miller"],
  [MiddleSchool.DouglasBenold, "Douglas Benold"],
  [MiddleSchool.DrippingSpringsInt, "Dripping Springs Int"],
  [MiddleSchool.DrippingSpringsMiddle, "Dripping Springs Middle"],
  [MiddleSchool.EasternHills, "Eastern Hills"],
  [MiddleSchool.ElaineSWSchlatherInt, "Elaine SW Schlather Int"],
  [MiddleSchool.Elgin, "Elgin"],
  [MiddleSchool.Evant, "Evant"],
  [MiddleSchool.Fairway, "Fairway"],
  [MiddleSchool.Farley, "Farley"],
  [MiddleSchool.Fayetteville, "Fayetteville"],
  [MiddleSchool.FaltoniaSecondary, "Faltonia Secondary"],
  [MiddleSchool.Florence, "Florence"],
  [MiddleSchool.FourPoints, "Four Points"],
  [MiddleSchool.Fredericksburg, "Fredericksburg"],
  [MiddleSchool.Fulmore, "Fulmore"],
  [MiddleSchool.Garcia, "Garcia"],
  [MiddleSchool.Gatesville, "Gatesville"],
  [MiddleSchool.GatesvilleInt, "Gatesville Int"],
  [MiddleSchool.Gateway, "Gateway"],
  [MiddleSchool.Gause, "Gause"],
  [MiddleSchool.GiddingsInt, "Giddings Int"],
  [MiddleSchool.Giddings, "Giddings"],
  [MiddleSchool.GilbertGerdes, "Gilbert Gerdes"],
  [MiddleSchool.Goldthwaite, "Goldthwaite"],
  [MiddleSchool.Gonzales, "Gonzales"],
  [MiddleSchool.GonzalesNAveIntermediate, "Gonzales N Ave Intermediate"],
  [MiddleSchool.Goodnight, "Goodnight"],
  [MiddleSchool.Gorzycki, "Gorzycki"],
  [MiddleSchool.Granger, "Granger"],
  [MiddleSchool.Harper, "Harper"],
  [MiddleSchool.Hearne, "Hearne"],
  [MiddleSchool.Henry, "Henry"],
  [MiddleSchool.Hernandez, "Hernandez"],
  [MiddleSchool.HillCountry, "Hill Country"],
  [MiddleSchool.Holland, "Holland"],
  [MiddleSchool.Hopewell, "Hopewell"],
  [MiddleSchool.HudsonBend, "Hudson Bend"],
  [MiddleSchool.Hutto, "Hutto"],
  [MiddleSchool.JamesTippit, "James Tippit"],
  [MiddleSchool.Jarrell, "Jarrell"],
  [MiddleSchool.JimBarnes, "Jim Barnes"],
  [MiddleSchool.JoeFSaegert6thGradeCenter, "Joe F Saegert 6th Grade Center"],
  [MiddleSchool.JohnPOjeda, "John P Ojeda"],
  [MiddleSchool.Kealing, "Kealing"],
  [MiddleSchool.KellyLane, "Kelly Lane"],
  [MiddleSchool.LagoVista, "Lago Vista"],
  [MiddleSchool.LaGrangeInt, "La Grange Int"],
  [MiddleSchool.LaGrange, "La Grange"],
  [MiddleSchool.LakeBelton, "Lake Belton"],
  [MiddleSchool.LakeTravis, "Lake Travis"],
  [MiddleSchool.Lamar, "Lamar"],
  [MiddleSchool.Lampasas, "Lampasas"],
  [MiddleSchool.LauraIngallsWilderInt, "Laura Ingalls Wilder Int"],
  [MiddleSchool.LaVernia, "La Vernia"],
  [MiddleSchool.LeanderMiddle, "Leander Middle"],
  [MiddleSchool.Lexington, "Lexington"],
  [MiddleSchool.LibertyHillJr, "Liberty Hill Jr"],
  [MiddleSchool.LibertyHillIntermediate, "Liberty Hill Intermediate"],
  [MiddleSchool.LibertyHillMiddle, "Liberty Hill Middle"],
  [MiddleSchool.Lively, "Lively"],
  [MiddleSchool.LiveOakRidge, "Live Oak Ridge"],
  [MiddleSchool.Llano, "Llano"],
  [MiddleSchool.Lockhart, "Lockhart"],
  [MiddleSchool.Lometa, "Lometa"],
  [MiddleSchool.Luling, "Luling"],
  [MiddleSchool.LyndonBJohnson, "Lyndon B Johnson"],
  [MiddleSchool.Manor, "Manor"],
  [MiddleSchool.MarbleFalls, "Marble Falls"],
  [MiddleSchool.Marion, "Marion"],
  [MiddleSchool.Martin, "Martin"],
  [MiddleSchool.Mason, "Mason"],
  [MiddleSchool.McCormick, "McCormick"],
  [MiddleSchool.McDade, "McDade"],
  [MiddleSchool.McNeilArea, "McNeil Area"],
  [MiddleSchool.Mendez, "Mendez"],
  [MiddleSchool.Milano, "Milano"],
  [MiddleSchool.Miller, "Miller"],
  [MiddleSchool.Moody, "Moody"],
  [MiddleSchool.Moulton, "Moulton"],
  [MiddleSchool.MountainValleyMiddle, "Mountain Valley Middle"],
  [MiddleSchool.Mumford, "Mumford"],
  [MiddleSchool.Murchison, "Murchison"],
  [MiddleSchool.NotApplicable, "N/A"],
  [MiddleSchool.Navarro, "Navarro"],
  [MiddleSchool.NewBraunfels, "New Braunfels"],
  [MiddleSchool.NewSouth, "New South"],
  [MiddleSchool.NixonSmiley, "Nixon-Smiley"],
  [MiddleSchool.NoelGrisham, "Noel Grisham"],
  [MiddleSchool.Nolan, "Nolan"],
  [MiddleSchool.NorthBelton, "North Belton"],
  [MiddleSchool.Oakrun, "Oakrun"],
  [MiddleSchool.OHenry, "O Henry"],
  [MiddleSchool.Ojeda, "Ojeda"],
  [MiddleSchool.OwenGoodnight, "Owen Goodnight"],
  [MiddleSchool.PaloAlto, "Palo Alto"],
  [MiddleSchool.ParkCrest, "Park Crest"],
  [MiddleSchool.Pearce, "Pearce"],
  [MiddleSchool.PearsonRanch, "Pearson Ranch"],
  [MiddleSchool.Pflugerville, "Pflugerville"],
  [MiddleSchool.PleasantHill, "Pleasant Hill"],
  [MiddleSchool.Porter, "Porter"],
  [MiddleSchool.PrairieLea, "Prairie Lea"],
  [MiddleSchool.Rancier, "Rancier"],
  [MiddleSchool.RayDCorbett, "Ray D Corbett"],
  [MiddleSchool.RaymondMays, "Raymond Mays"],
  [MiddleSchool.RCBarton, "R C Barton"],
  [MiddleSchool.RichlandSprings, "Richland Springs"],
  [MiddleSchool.Ridgeview, "Ridgeview"],
  [MiddleSchool.Rockdale, "Rockdale"],
  [MiddleSchool.Rogers, "Rogers"],
  [MiddleSchool.RosebudLott, "Rosebud-Lott"],
  [MiddleSchool.RosebudInt, "Rosebud Int"],
  [MiddleSchool.RoundTopCarmine, "Round Top-Carmine"],
  [MiddleSchool.RunningBrushy, "Running Brushy"],
  [MiddleSchool.Salado, "Salado"],
  [MiddleSchool.SaladoIntermediate, "Salado Intermediate"],
  [MiddleSchool.SanSaba, "San Saba"],
  [MiddleSchool.SantaRitaMiddle, "Santa Rita Middle"],
  [MiddleSchool.SchulenburgSecondary, "Schulenburg Secondary"],
  [MiddleSchool.SCLee, "S C Lee"],
  [MiddleSchool.Shiner, "Shiner"],
  [MiddleSchool.SisterRidge, "Sister Ridge"],
  [MiddleSchool.Small, "Small"],
  [MiddleSchool.Smith, "Smith"],
  [MiddleSchool.SmithsonValley, "Smithson Valley"],
  [MiddleSchool.Smithville, "Smithville"],
  [MiddleSchool.SouthBelton, "South Belton"],
  [MiddleSchool.SpringBranch, "Spring Branch"],
  [MiddleSchool.Star, "Star"],
  [MiddleSchool.Stiles, "Stiles"],
  [MiddleSchool.SycamoreSprings, "Sycamore Springs"],
  [MiddleSchool.Taylor, "Taylor"],
  [MiddleSchool.Thorndale, "Thorndale"],
  [MiddleSchool.Thrall, "Thrall"],
  [MiddleSchool.Travis, "Travis"],
  [MiddleSchool.Troy, "Troy"],
  [MiddleSchool.UnionGrove, "Union Grove"],
  [MiddleSchool.Waelder, "Waelder"],
  [MiddleSchool.Wagner, "Wagner"],
  [MiddleSchool.LauraWallace, "Laura B Wallace"],
  [MiddleSchool.Walsh, "Walsh"],
  [MiddleSchool.Webb, "Webb"],
  [MiddleSchool.Weimar, "Weimar"],
  [MiddleSchool.WestRidge, "West Ridge"],
  [MiddleSchool.Westside, "Westside"],
  [MiddleSchool.Westview, "Westview"],
  [MiddleSchool.KnoxWiley, "Knox Wiley"],
  [MiddleSchool.Danforth, "Danforth"],
  [MiddleSchool.Yoakum, "Yoakum"]
]);
