import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { SpecialtyRooms, SpecialtyRoomsLabel } from "markets/ctx/enums";
import { commonPlanRules, utilitiesRules } from "../../sections";

export const featuresInfoRules: FormRules = {
  ...utilitiesRules,
  ...commonPlanRules,
  specialtyRooms: {
    label: "Attic",
    requiredToSubmit: true,
    lastOptions: [SpecialtyRooms.None, SpecialtyRooms.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(SpecialtyRooms),
      SpecialtyRoomsLabel
    ),
    noneOption: [SpecialtyRooms.None]
  }
};
