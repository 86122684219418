import React from "react";
import { AuthCompanyContext } from "@homesusa/auth";
import { getGatewayMarket } from "core/utils";

export const useEntityUrl = (entityType: string, entityId: string): string => {
  const gateway = process.env.REACT_APP_API_GATEWAY;
  const { currentMarket } = React.useContext(AuthCompanyContext);

  const notesUrl = React.useMemo(() => {
    const gatewayMarket = getGatewayMarket(currentMarket);
    return `${gateway}/api/${gatewayMarket}/${entityType}/${entityId}`;
  }, [entityId, currentMarket, entityType]);

  return notesUrl;
};
