export enum Pool {
  AboveGround = "aboveGround",
  Community = "community",
  Heated = "heated",
  InGround = "inGround",
  Indoor = "indoor",
  SaltWater = "saltWater",
  Spa = "spa",
  None = "none"
}

export const PoolLabel = new Map<Pool, string>([
  [Pool.AboveGround, "Above Ground"],
  [Pool.Community, "Community"],
  [Pool.Heated, "Heated"],
  [Pool.InGround, "In Ground"],
  [Pool.Indoor, "Indoor"],
  [Pool.SaltWater, "Salt Water"],
  [Pool.Spa, "Spa"],
  [Pool.None, "None"]
]);
