export enum RoadSurface {
  AlleyPaved = "alleyPaved",
  Asphalt = "asphalt",
  Caliche = "caliche",
  ChipAndSeal = "chipAndSeal",
  Concrete = "concrete",
  CurbGutter = "curbGutter",
  Dirt = "dirt",
  Gravel = "gravel",
  Paved = "paved",
  Sidewalks = "sidewalks",
  Unimproved = "unimproved",
  None = "none",
  SeeRemarks = "seeRemarks"
}
export const RoadSurfaceLabel = new Map<RoadSurface, string>([
  [RoadSurface.AlleyPaved, "Alley Paved"],
  [RoadSurface.Asphalt, "Asphalt"],
  [RoadSurface.Caliche, "Caliche"],
  [RoadSurface.ChipAndSeal, "Chip And Seal"],
  [RoadSurface.Concrete, "Concrete"],
  [RoadSurface.CurbGutter, "Curb/Gutter"],
  [RoadSurface.Dirt, "Dirt"],
  [RoadSurface.Gravel, "Gravel"],
  [RoadSurface.Paved, "Paved"],
  [RoadSurface.Sidewalks, "Sidewalks"],
  [RoadSurface.Unimproved, "Unimproved"],
  [RoadSurface.None, "None"],
  [RoadSurface.SeeRemarks, "See Remarks"]
]);
