import React from "react";
import { Format } from "@homesusa/core";
import { Columns } from "@homesusa/grid-table";
import { XmlListing } from "../interfaces/alerts/xml";
import { TruncateText } from "core/components";

export const XmlListingGridColumns = (
  cityOptions: Record<string, string>
): Columns<XmlListing> => [
  {
    Header: "Builder",
    accessor: "builder"
  },
  {
    Header: "Subdivision",
    accessor: "subdivision"
  },
  {
    Header: "City",
    accessor: (data: XmlListing): string => cityOptions[data.city] ?? data.city,
    Cell: ({ value }: { value: string }): JSX.Element => (
      <TruncateText text={value} />
    )
  },
  {
    Header: "Address",
    accessor: (data: XmlListing): string => data.street1,
    id: "street1"
  },
  {
    Header: "Price",
    accessor: (data: XmlListing): string => Format.Money(data.price)
  },
  {
    Header: "Last Updated",
    accessor: (data: XmlListing): string => Format.Date(data.sysModifiedOn),
    id: "sysModifiedOn"
  },
  {
    Header: "Obtained",
    accessor: (data: XmlListing): string => Format.Date(data.obtainedAt),
    id: "sysCreatedOn"
  }
];
