export enum NeighborhoodAmenities {
  BoatRamp = "boatRamp",
  ClubHouse = "clubHouse",
  CommunityDock = "communityDock",
  CommunityPool = "communityPool",
  CommunitySprinkler = "communitySprinkler",
  Curbs = "curbs",
  ElectricChargingStation = "electricChargingStation",
  Fishing = "fishing",
  FitnessCenter = "fitnessCenter",
  Gated = "gated",
  Golf = "golf",
  Greenbelt = "greenbelt",
  GuardedEntrance = "guardedEntrance",
  JoggingPathBikePath = "joggingPathBikePath",
  Lake = "lake",
  Marina = "marina",
  Other = "othe",
  Park = "park",
  PerimeterFencing = "perimeterFencing",
  Playground = "playground",
  Sauna = "sauna",
  Sidewalks = "sidewalks",
  Spa = "spa",
  TennisCourts = "tennisCourts"
}

export const NeighborhoodAmenitieslabel = new Map<
  NeighborhoodAmenities,
  string
>([
  [NeighborhoodAmenities.BoatRamp, "Boat Ramp"],
  [NeighborhoodAmenities.ClubHouse, "Club House"],
  [NeighborhoodAmenities.CommunityDock, "Community Dock"],
  [NeighborhoodAmenities.CommunityPool, "Community Pool"],
  [NeighborhoodAmenities.CommunitySprinkler, "Community Sprinkler"],
  [NeighborhoodAmenities.Curbs, "Curbs"],
  [NeighborhoodAmenities.ElectricChargingStation, "Electric Charging Station"],
  [NeighborhoodAmenities.Fishing, "Fishing"],
  [NeighborhoodAmenities.FitnessCenter, "Fitness Center"],
  [NeighborhoodAmenities.Gated, "Gated"],
  [NeighborhoodAmenities.Golf, "Golf"],
  [NeighborhoodAmenities.Greenbelt, "Greenbelt"],
  [NeighborhoodAmenities.GuardedEntrance, "Guarded Entrance"],
  [NeighborhoodAmenities.JoggingPathBikePath, "Jogging Path/Bike Path"],
  [NeighborhoodAmenities.Lake, "Lake"],
  [NeighborhoodAmenities.Marina, "Marina"],
  [NeighborhoodAmenities.Other, "Other"],
  [NeighborhoodAmenities.Park, "Park"],
  [NeighborhoodAmenities.PerimeterFencing, "Perimeter Fencing"],
  [NeighborhoodAmenities.Playground, "Playground"],
  [NeighborhoodAmenities.Sauna, "Sauna"],
  [NeighborhoodAmenities.Sidewalks, "Sidewalks"],
  [NeighborhoodAmenities.Spa, "Spa"],
  [NeighborhoodAmenities.TennisCourts, "Tennis Court(s)"]
]);
