import React, { Fragment } from "react";
import { Box } from "@mui/material";

import {
  FormContext,
  FormContextActions,
  formContextReducer,
  FormMode,
  getOptionValueFromRule
} from "@homesusa/form";
import {
  GridTable,
  GridProvider,
  GridToolbar,
  FetchData,
  Columns
} from "@homesusa/grid-table";
import { Format } from "@homesusa/core";
import { ListFilter } from "../interfaces/filters";
import { RealtorOffice, ReportDataset } from "../interfaces";
import { ReportsContext } from "../contexts";
import { Filters } from "./filters.component";
import { ReportDates } from "./report-dates.component";
import { AuthCompanyContext } from "@homesusa/auth";
import { getMarketCityRule } from "markets/utils";

type columnReport = Columns<RealtorOffice>;

export function RealtorOfficesReport(): JSX.Element {
  const {
    services: { getRealtorOffices }
  } = React.useContext(ReportsContext);
  const [formState, formDispatch] = React.useReducer<
    (state: ListFilter, action: FormContextActions) => ListFilter
  >(formContextReducer, Object.assign({}));
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const marketRules = React.useMemo(
    () => ({
      city: getMarketCityRule(currentMarket)
    }),
    [currentMarket]
  );

  const [data, setData] = React.useState<ReportDataset<RealtorOffice>>({
    data: [],
    total: 0,
    agentLastUpdate: undefined,
    officeLastUpdate: undefined,
    listingLastUpdate: undefined
  });

  const defaultSortBy = "+name";

  const fetchData = async ({
    pageSize,
    pageIndex,
    sortBy,
    globalFilter
  }: FetchData): Promise<void> => {
    return getRealtorOffices({
      ...formState,
      top: pageSize,
      skip: pageIndex,
      sortBy: sortBy ?? defaultSortBy,
      searchBy: globalFilter
    }).then((response) => {
      setData(response);
    });
  };
  const fetchDataForDownloading = async (): Promise<RealtorOffice[]> => {
    return getRealtorOffices({
      ...formState,
      top: data.total,
      skip: 0
    }).then((response) => {
      return response.data;
    });
  };

  const columns: columnReport = React.useMemo(
    () => [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1
      },
      {
        Header: "Office",
        accessor: "name"
      },
      {
        Header: "Office Phone",
        accessor: (data: RealtorOffice): string =>
          Format.PhoneNumber(data.phone),
        id: "phone"
      },
      {
        Header: "Address",
        accessor: "address",
        id: "address"
      },
      {
        Header: "City",
        accessor: (data: RealtorOffice): string =>
          getOptionValueFromRule(marketRules.city, data.city),
        id: "city"
      },
      {
        Header: "Zip",
        accessor: "zip"
      },
      {
        Header: "Agents",
        accessor: "agents"
      }
    ],
    []
  );

  return (
    <Fragment>
      <FormContext.Provider
        value={{
          formState: formState,
          formDispatch,
          formMode: FormMode.Update,
          formRules: {},
          otherProps: {}
        }}
      >
        <GridProvider
          options={{
            columns,
            data: data?.data,
            showHeaderTooltip: true
          }}
          totalRows={data?.total}
        >
          <Filters onFetch={fetchData}>
            <Filters.Address />
          </Filters>
          <GridToolbar>
            <GridToolbar.ExportButtons
              onClick={fetchDataForDownloading}
              fileName="realtors"
            />
            <GridToolbar.SearchFilter />
          </GridToolbar>
          <Box mt={2} />
          <GridTable />
          <ReportDates data={data} />
        </GridProvider>
      </FormContext.Provider>
    </Fragment>
  );
}
