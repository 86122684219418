import { FieldRulesProps, FormRules } from "@homesusa/form";
import { amarilloCityRule, countyRules } from "../../sections";

export const propertyRules: Record<string, FormRules | FieldRulesProps> = {
  city: {
    ...amarilloCityRule,
    required: false
  },
  subdivision: { label: "Subdivision" },
  county: {
    ...countyRules,
    required: false
  }
};
