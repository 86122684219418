import { FormRules, FieldRulesProps } from "@homesusa/form";
import { emailLeadRules, communityNameRule } from "modules/communities/rules";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";
import { harSalesOfficeRules } from "./sales-office";
import { ownerNameRule } from "../common-rules";

export const profileRules: Record<string, FormRules | FieldRulesProps> = {
  useLatLong: { label: "Use Lat/Long?" },
  latitude: latitudeRule,
  longitude: longitudeRule,
  emailMailViolationsWarnings: {
    label: "Email Violations Warnings",
    isMultiple: true
  },
  name: communityNameRule,
  ownerName: ownerNameRule,
  officePhone: { label: "Office Phone" },
  backupPhone: { label: "Backup Phone" },
  fax: { label: "Fax Number" },
  emailLead: emailLeadRules,
  salesOffice: harSalesOfficeRules
};
