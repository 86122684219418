import { ListingRouterPath } from "./router-path.enum";

export enum ListType {
  Sale = "sale",
  Lot = "lot"
}

export const ListTypeEndpoint = new Map<ListType, string>([
  [ListType.Sale, "sale-listings"],
  [ListType.Lot, "lot-listings"]
]);
export const RequestTypeEndpoint = new Map<ListType, string>([
  [ListType.Sale, "sale-listing-requests"],
  [ListType.Lot, "lot-listing-requests"]
]);
export const RequestTypeTitle = new Map<ListType, string>([
  [ListType.Sale, "Residential Listing Requests"],
  [ListType.Lot, "Lot Listing Requests"]
]);
export const ListingTypeTitle = new Map<ListType, string>([
  [ListType.Sale, "Residential Listing"],
  [ListType.Lot, "Lot Listing"]
]);
export const listTypeName: Record<ListType, string> = {
  [ListType.Sale]: "listing",
  [ListType.Lot]: "lot"
};

export const getListingRoute = (
  listType: ListType,
  router: ListingRouterPath
): string => {
  return `/${ListTypeEndpoint.get(listType)}${router}`;
};

export const getRequestDetailRoute = (
  listType: ListType,
  id: string
): string => {
  const requestTypeEndpoint = new Map<ListType, string>([
    [ListType.Sale, "sale-requests"],
    [ListType.Lot, "lot-requests"]
  ]);
  return `/${requestTypeEndpoint.get(listType)}/${id}`;
};

export const getListingRouteWithId = (
  listType: ListType,
  router: ListingRouterPath,
  id: string
): string => {
  return getListingRoute(listType, router).replace(":id", id);
};
