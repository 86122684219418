import {
  FieldInfo,
  FieldType,
  RecordSummary,
  toYesNo,
  useGetFieldInfo
} from "@homesusa/record";

export const useSummarySections = (
  commissionSectionName: string,
  summary: RecordSummary,
  salesOfficeSectionName = "SalesOffice"
): { commissionInfo: FieldInfo[]; salesOffice: FieldInfo[] } => {
  const { defaultFieldInfo } = useGetFieldInfo(summary);
  const commissionInfo: FieldInfo[] = [
    defaultFieldInfo(commissionSectionName, "ReadableBuyersAgentCommission"),
    defaultFieldInfo(commissionSectionName, "HasAgentBonus", toYesNo),
    defaultFieldInfo(commissionSectionName, "HasBonusWithAmount", toYesNo),
    defaultFieldInfo(commissionSectionName, "ReadableAgentBonusAmount"),
    {
      ...defaultFieldInfo(commissionSectionName, "BonusExpirationDate"),
      type: FieldType.Date
    }
  ];
  const salesOffice: FieldInfo[] = [
    {
      ...defaultFieldInfo(salesOfficeSectionName, "SalesOfficeCity"),
      type: FieldType.Multiple
    },
    defaultFieldInfo(salesOfficeSectionName, "StreetNumber"),
    defaultFieldInfo(salesOfficeSectionName, "StreetName"),
    defaultFieldInfo(salesOfficeSectionName, "StreetSuffix"),
    defaultFieldInfo(salesOfficeSectionName, "SalesOfficeZip")
  ];

  return { commissionInfo, salesOffice };
};
