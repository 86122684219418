export enum SaleTerms {
  Cash = "cash",
  TexasVet = "texasVet",
  Conventional = "conventional",
  UsdaEligible = "usdaEligible",
  FHA = "fha",
  VA = "va"
}
export const SaleTermsLabel = new Map<SaleTerms, string>([
  [SaleTerms.Cash, "Cash"],
  [SaleTerms.Conventional, "Conventional"],
  [SaleTerms.FHA, "FHA"],
  [SaleTerms.TexasVet, "Texas Vet"],
  [SaleTerms.UsdaEligible, "USDA Eligible"],
  [SaleTerms.VA, "VA"]
]);
