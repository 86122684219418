export enum ProposedTerms {
  Cash = "cash",
  Conventional = "conventional",
  FHA = "fha",
  OtherSeeRemarks = "otherSeeRemarks",
  TexasVet = "texasVet",
  USDA = "usda",
  VA = "va"
}
export const ProposedTermsLabel = new Map<ProposedTerms, string>([
  [ProposedTerms.Cash, "Cash"],
  [ProposedTerms.Conventional, "Conventional"],
  [ProposedTerms.FHA, "FHA"],
  [ProposedTerms.OtherSeeRemarks, "Other-See Remarks"],
  [ProposedTerms.TexasVet, "Texas Vet"],
  [ProposedTerms.USDA, "USDA"],
  [ProposedTerms.VA, "VA"]
]);
