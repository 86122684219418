export enum DocumentsAvailable {
  BuildingPlans = "buildingPlans",
  MUDWaterDistrict = "mudWaterDistrict",
  PlansAndSpecs = "plansAndSpecs",
  Plat = "plat",
  SubdivisionPlat = "subdivisionPlat",
  SubdivisionRestrictions = "subdivisionRestrictions",
  Survey = "survey",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const DocumentsAvailableLabel = new Map<DocumentsAvailable, string>([
  [DocumentsAvailable.BuildingPlans, "Building Plans"],
  [DocumentsAvailable.MUDWaterDistrict, "MUD/Water District"],
  [DocumentsAvailable.PlansAndSpecs, "Plans and Specs"],
  [DocumentsAvailable.Plat, "Plat"],
  [DocumentsAvailable.SubdivisionPlat, "Subdivision Plat"],
  [DocumentsAvailable.SubdivisionRestrictions, "Subdivision Restrictions"],
  [DocumentsAvailable.Survey, "Survey"],
  [DocumentsAvailable.None, "None"],
  [DocumentsAvailable.OtherSeeRemarks, "Other-See Remarks"]
]);
