export enum JsonImportStatus {
  Approved = "approved",
  AwaitingApproval = "awaitingApproval",
  Rejected = "rejected",
  NotFromJson = "notFromJson",
  Matched = "matched"
}

export const AvailableJsonImportStatus = [
  JsonImportStatus.Approved,
  JsonImportStatus.NotFromJson,
  JsonImportStatus.Matched
];
