import { SxProps } from "@mui/system";

interface Classes {
  menu: SxProps;
  dateForm: SxProps;
}

export const classes: Classes = {
  menu: {
    "& .MuiList-root": {
      padding: "16px"
    }
  },
  dateForm: {
    display: "flex",
    columnGap: "8px",
    width: 210,
    alignItems: "flex-start"
  }
};
