export enum HighSchool {
  ACJones = "acJones",
  Abbott = "abbott",
  Abilene = "abilene",
  Academy = "academy",
  Adams = "adams",
  Adamson = "adamson",
  Aguadulce = "aguadulce",
  AimCenter = "aimCenter",
  Alabama = "alabama",
  Alamoheigh = "alamoheigh",
  Alaska = "alaska",
  Albagolden = "albagolden",
  Albany = "albany",
  Aledo = "aledo",
  Allen = "allen",
  Alpine = "alpine",
  Alter = "alter",
  Alto = "alto",
  Alvarado = "alvarado",
  Alvin = "alvin",
  Alvord = "alvord",
  Amarillo = "amarillo",
  Amcons = "amcons",
  Americas = "americas",
  Anahuac = "anahuac",
  AndersonShiro = "andersonShiro",
  Andress = "andress",
  Andrews = "andrews",
  Anguilla = "anguilla",
  Anna = "anna",
  Anson = "anson",
  Anthony = "anthony",
  AppleSprings = "appleSprings",
  Aquilla = "aquilla",
  Archercity = "archercity",
  Argyle = "argyle",
  Arizona = "arizona",
  Arkansas = "arkansas",
  Arlington = "arlington",
  Arlngtnhts = "arlngtnhts",
  Arp = "arp",
  Aruba = "aruba",
  Aspermont = "aspermont",
  Athens = "athens",
  Atlanta = "atlanta",
  Aubrey = "aubrey",
  Austin = "austin",
  Australia = "australia",
  Austwellti = "austwellti",
  Avalon = "avalon",
  Avery = "avery",
  Avinger = "avinger",
  Axtell = "axtell",
  Azle = "azle",
  Bahamas = "bahamas",
  Baird = "baird",
  Ball = "ball",
  Ballinger = "ballinger",
  Balmorhea = "balmorhea",
  Bangs = "bangs",
  Banquete = "banquete",
  Barbados = "barbados",
  Bartlett = "bartlett",
  Bastrop = "bastrop",
  Baycity = "baycity",
  Belair = "belair",
  Belize = "belize",
  Bell = "bell",
  Bellevue = "bellevue",
  Bells = "bells",
  Bellville = "bellville",
  Belton = "belton",
  Benbrook = "benbrook",
  Benjamin = "benjamin",
  Berkner = "berkner",
  BigSpring = "bigSpring",
  Bigsandy = "bigsandy",
  Birdville = "birdville",
  Bishop = "bishop",
  Blackwell = "blackwell",
  Blanco = "blanco",
  Bland = "bland",
  Blanket = "blanket",
  Bloomburg = "bloomburg",
  Bloominggr = "bloominggr",
  Blueridge = "blueridge",
  Blum = "blum",
  Boerne = "boerne",
  Boggess = "boggess",
  Boles = "boles",
  Bonham = "bonham",
  BordenCountySchool = "bordenCountySchool",
  Borger = "borger",
  Bosqueville = "bosqueville",
  Boswell = "boswell",
  Bowie = "bowie",
  Boyd = "boyd",
  Brackenrid = "brackenrid",
  Brackett = "brackett",
  Brady = "brady",
  Brazil = "brazil",
  Brazosport = "brazosport",
  Breckenrid = "breckenrid",
  Bremond = "bremond",
  Brewer = "brewer",
  Bridgeport = "bridgeport",
  Brock = "brock",
  Bronte = "bronte",
  Brookeland = "brookeland",
  Brookesmith = "brookesmith",
  Brownfield = "brownfield",
  Brownsboro = "brownsboro",
  Brownsville = "brownsville",
  Brownwood = "brownwood",
  BrucevilleEddy = "brucevilleEddy",
  Bryan = "bryan",
  BryanCollegiate = "bryanCollegiate",
  Bryson = "bryson",
  BuenaVistaSchool = "buenaVistaSchool",
  Buffalo = "buffalo",
  Bulgaria = "bulgaria",
  Bullard = "bullard",
  Buna = "buna",
  Burbank = "burbank",
  Burges = "burges",
  Burkburnett = "burkburnett",
  Burkeville = "burkeville",
  Burleson = "burleson",
  BurlesonCentennial = "burlesonCentennial",
  Burnet = "burnet",
  Burton = "burton",
  Bushland = "bushland",
  Bynum = "bynum",
  ByronNelson = "byronNelson",
  ByronPSteeleIi = "byronPSteeleIi",
  Caddomills = "caddomills",
  Calallen = "calallen",
  Caldwell = "caldwell",
  Calhoun = "calhoun",
  California = "california",
  Calisburg = "calisburg",
  Callisburg = "callisburg",
  Calvert = "calvert",
  CameronYoe = "cameronYoe",
  Campbell = "campbell",
  Canada = "canada",
  Canton = "canton",
  Canutillo = "canutillo",
  Canyon = "canyon",
  Canyonlake = "canyonlake",
  Caprock = "caprock",
  Carlisle = "carlisle",
  Carroll = "carroll",
  Carter = "carter",
  CarterRiv = "carterRiv",
  Carthage = "carthage",
  Castleberr = "castleberr",
  Cates = "cates",
  Cathedral = "cathedral",
  CaymanIslands = "caymanIslands",
  Cayuga = "cayuga",
  CedarHillEarlyCollege = "cedarHillEarlyCollege",
  Cedarhill = "cedarhill",
  Cedarpark = "cedarpark",
  Celeste = "celeste",
  Celina = "celina",
  Centennial = "centennial",
  Center = "center",
  Centerville = "centerville",
  Central = "central",
  CentralHeights = "centralHeights",
  Centralhei = "centralhei",
  Chapelhill = "chapelhill",
  Chapin = "chapin",
  Chico = "chico",
  Childress = "childress",
  Chile = "chile",
  Chillicoth = "chillicoth",
  Chinasprng = "chinasprng",
  Chireno = "chireno",
  ChisholmTrail = "chisholmTrail",
  ChisolmTrail = "chisolmTrail",
  Chisolmtra = "chisolmtra",
  Chisum = "chisum",
  ChoiceOfSchool = "choiceOfSchool",
  Churchill = "churchill",
  Cigarroa = "cigarroa",
  Cisco = "cisco",
  Clarendon = "clarendon",
  Clark = "clark",
  Clarksvill = "clarksvill",
  Claude = "claude",
  Cleburne = "cleburne",
  Cleveland = "cleveland",
  Clifton = "clifton",
  Clint = "clint",
  Clyde = "clyde",
  ColdspringOakhurst = "coldspringOakhurst",
  Coleman = "coleman",
  Coles = "coles",
  Collegiate = "collegiate",
  Collinsville = "collinsville",
  Colorado = "colorado",
  Columbus = "columbus",
  Comanche = "comanche",
  Comfort = "comfort",
  Commerce = "commerce",
  Community = "community",
  Comopickto = "comopickto",
  Comstock = "comstock",
  Connally = "connally",
  Connecticut = "connecticut",
  Conrad = "conrad",
  Conroe = "conroe",
  Coolidge = "coolidge",
  Cooper = "cooper",
  Coppell = "coppell",
  Copperasco = "copperasco",
  Corley = "corley",
  Coronado = "coronado",
  CorriganCamden = "corriganCamden",
  Corsicana = "corsicana",
  CostaRica = "costaRica",
  Covington = "covington",
  Crandall = "crandall",
  Cranfillsg = "cranfillsg",
  Crawford = "crawford",
  Creekview = "creekview",
  Crockett = "crockett",
  CrossPlains = "crossPlains",
  CrosslandNinthGradeCenter = "crosslandNinthGradeCenter",
  Crossroads = "crossroads",
  Crowell = "crowell",
  Crowley = "crowley",
  Crowley9ThGrade = "crowley9ThGrade",
  Cuero = "cuero",
  Cumby = "cumby",
  CyFair = "cyFair",
  CypressFalls = "cypressFalls",
  CypressSprings = "cypressSprings",
  Daingerfie = "daingerfie",
  Dawson = "dawson",
  Dayton = "dayton",
  Decalb = "decalb",
  Decatur = "decatur",
  DeerPark = "deerPark",
  DelValle = "delValle",
  Deleon = "deleon",
  Delrio = "delrio",
  Delvalle = "delvalle",
  Denison = "denison",
  Denton = "denton",
  Desoto = "desoto",
  DesotoHsFreshmanCampus = "desotoHsFreshmanCampus",
  Detroit = "detroit",
  Devers = "devers",
  Devine = "devine",
  Dickinson = "dickinson",
  Doddcity = "doddcity",
  DominicanRepublic = "dominicanRepublic",
  Douglass = "douglass",
  DrippingSprings = "drippingSprings",
  Dublin = "dublin",
  Dumas = "dumas",
  Dunbar = "dunbar",
  Duncanvill = "duncanvill",
  Eaglepass = "eaglepass",
  Early = "early",
  Eastcentra = "eastcentra",
  Easternhil = "easternhil",
  Eastland = "eastland",
  Eastwood = "eastwood",
  Eaton = "eaton",
  Ector = "ector",
  Eden = "eden",
  Edgewood = "edgewood",
  Edison = "edison",
  Edna = "edna",
  Eisenhower = "eisenhower",
  ElSalvador = "elSalvador",
  Electra = "electra",
  Elkhart = "elkhart",
  Ellison = "ellison",
  Elpaso = "elpaso",
  Elysianfie = "elysianfie",
  Emerson = "emerson",
  Ennis = "ennis",
  Era = "era",
  Eula = "eula",
  Eustace = "eustace",
  Evadale = "evadale",
  Evant = "evant",
  Everman = "everman",
  Excel = "excel",
  Fabens = "fabens",
  Fairfield = "fairfield",
  Fallscity = "fallscity",
  Fannindell = "fannindell",
  Farmersvil = "farmersvil",
  Farwell = "farwell",
  Ferris = "ferris",
  Florence = "florence",
  Florida = "florida",
  Flourbluff = "flourbluff",
  FlowerMound = "flowerMound",
  Ford = "ford",
  Forestburg = "forestburg",
  Forney = "forney",
  Forsan = "forsan",
  FortDavis = "fortDavis",
  FortStockton = "fortStockton",
  Fossilridg = "fossilridg",
  Fox = "fox",
  France = "france",
  Franklin = "franklin",
  Frankston = "frankston",
  Fredericks = "fredericks",
  Frenship = "frenship",
  Friona = "friona",
  Frisco = "frisco",
  Frost = "frost",
  Fruitvale = "fruitvale",
  Gainesvill = "gainesvill",
  GalenaPark = "galenaPark",
  Ganado = "ganado",
  Garrison = "garrison",
  Gary = "gary",
  Gatesville = "gatesville",
  Genesis = "genesis",
  Georgetown = "georgetown",
  Georgewest = "georgewest",
  Georgia = "georgia",
  Germany = "germany",
  Gholson = "gholson",
  Gilmer = "gilmer",
  Gladewater = "gladewater",
  GlenRose = "glenRose",
  Godley = "godley",
  Goldthwaite = "goldthwaite",
  Goliad = "goliad",
  Gordon = "gordon",
  Gorman = "gorman",
  Graford = "graford",
  Graham = "graham",
  Granbury = "granbury",
  Grandprair = "grandprair",
  Grandsalin = "grandsalin",
  Grandview = "grandview",
  Grapeland = "grapeland",
  Grapevine = "grapevine",
  Greenville = "greenville",
  Groesbeck = "groesbeck",
  Guatemala = "guatemala",
  Gunter = "gunter",
  Gustine = "gustine",
  Guyer = "guyer",
  HGradySpruce = "hGradySpruce",
  Hallsville = "hallsville",
  Haltom = "haltom",
  Hamilton = "hamilton",
  Hamlin = "hamlin",
  Hanks = "hanks",
  HappyHighSchool = "happyHighSchool",
  Hardin = "hardin",
  HardinJefferson = "hardinJefferson",
  Harkerheig = "harkerheig",
  Harlandale = "harlandale",
  Harleton = "harleton",
  Harlingen = "harlingen",
  HarlingenSouth = "harlingenSouth",
  Harmony = "harmony",
  Harper = "harper",
  Haskell = "haskell",
  Hawkins = "hawkins",
  Hawley = "hawley",
  Hearne = "hearne",
  Heath = "heath",
  Hebron = "hebron",
  Hemphill = "hemphill",
  Henderson = "henderson",
  Henrietta = "henrietta",
  HenryTWaskow = "henryTWaskow",
  Heritage = "heritage",
  Hermliegh = "hermliegh",
  Hico = "hico",
  Highland = "highland",
  HighlandPark = "highlandPark",
  Highlandpa = "highlandpa",
  Highlands = "highlands",
  HillCountry = "hillCountry",
  Hillcrest = "hillcrest",
  Hillsboro = "hillsboro",
  Hitchcock = "hitchcock",
  Holland = "holland",
  HollandMedical = "hollandMedical",
  Holliday = "holliday",
  Hondo = "hondo",
  Honduras = "honduras",
  Honeygrove = "honeygrove",
  Hooks = "hooks",
  Hope = "hope",
  Horizon = "horizon",
  Horn = "horn",
  Houston = "houston",
  Howe = "howe",
  Hubbard = "hubbard",
  Huckabay = "huckabay",
  Hudson = "hudson",
  HughesSprings = "hughesSprings",
  Humble = "humble",
  Hunt = "hunt",
  Huntsville = "huntsville",
  Hutto = "hutto",
  Idaho = "idaho",
  Illinois = "illinois",
  Independence = "independence",
  Indiana = "indiana",
  Indonesia = "indonesia",
  Industrial = "industrial",
  Ingleside = "ingleside",
  IngramTomMoore = "ingramTomMoore",
  IowaPark = "iowaPark",
  Iredell = "iredell",
  Irion = "irion",
  Irvin = "irvin",
  Irving = "irving",
  Italy = "italy",
  Itasca = "itasca",
  Jacksboro = "jacksboro",
  Jacksonvil = "jacksonvil",
  Jamacia = "jamacia",
  JamesEarlRudder = "jamesEarlRudder",
  JamesMSteeleAccelerated = "jamesMSteeleAccelerated",
  Jarrell = "jarrell",
  Jarvis = "jarvis",
  Jasper = "jasper",
  Jay = "jay",
  Jefferson = "jefferson",
  JimNed = "jimNed",
  Jonesboro = "jonesboro",
  Joshua = "joshua",
  JuarezLincoln = "juarezLincoln",
  Judson = "judson",
  Junction = "junction",
  Kansas = "kansas",
  Karnack = "karnack",
  Karnescity = "karnescity",
  Katy = "katy",
  Kaufman = "kaufman",
  Keene = "keene",
  Keller = "keller",
  Kemp = "kemp",
  Kenedy = "kenedy",
  Kennard = "kennard",
  Kennedale = "kennedale",
  Kennedy = "kennedy",
  Kentucky = "kentucky",
  Kerens = "kerens",
  Kermit = "kermit",
  Kilgore = "kilgore",
  Killeen = "killeen",
  Kimball = "kimball",
  King = "king",
  Kirbyville = "kirbyville",
  Klein = "klein",
  KnoxCity = "knoxCity",
  Kopperl = "kopperl",
  Kountze = "kountze",
  Krum = "krum",
  LaGrange = "laGrange",
  LaVega = "laVega",
  Lagovista = "lagovista",
  LakeDallas = "lakeDallas",
  LakeHighlands = "lakeHighlands",
  LakeTravis = "lakeTravis",
  LakeWorth = "lakeWorth",
  Lakehighla = "lakehighla",
  Lamar = "lamar",
  LamarAlternative = "lamarAlternative",
  Lampasas = "lampasas",
  Lancaster = "lancaster",
  Laneville = "laneville",
  Lanier = "lanier",
  Lapoyner = "lapoyner",
  Latexo = "latexo",
  Leander = "leander",
  LebanonTrail = "lebanonTrail",
  Lee = "lee",
  Legacy = "legacy",
  Lehman = "lehman",
  Leon = "leon",
  Leonard = "leonard",
  Lewisville = "lewisville",
  LewisvilleHarmon = "lewisvilleHarmon",
  LewisvilleKillough = "lewisvilleKillough",
  Lexington = "lexington",
  Liberty = "liberty",
  LibertyEylau = "libertyEylau",
  Libertyhil = "libertyhil",
  Lincoln = "lincoln",
  Lindale = "lindale",
  Lindsay = "lindsay",
  Linglevill = "linglevill",
  Linkildare = "linkildare",
  Lipan = "lipan",
  LittleElm = "littleElm",
  Livingston = "livingston",
  Llano = "llano",
  Lohn = "lohn",
  Lometa = "lometa",
  LoneStar = "loneStar",
  Loneoak = "loneoak",
  Longview = "longview",
  Loraine = "loraine",
  Lorena = "lorena",
  Loretto = "loretto",
  LosFresnos = "losFresnos",
  Louisiana = "louisiana",
  Lovejoy = "lovejoy",
  Lovelady = "lovelady",
  Lubbock = "lubbock",
  LubbockCooper = "lubbockCooper",
  LuedersAvoca = "luedersAvoca",
  Lufkin = "lufkin",
  LyndonBJohnson = "lyndonBJohnson",
  Lytle = "lytle",
  Mabank = "mabank",
  Macarthur = "macarthur",
  Madison = "madison",
  Madisonville = "madisonville",
  Maine = "maine",
  Malakoff = "malakoff",
  Manns = "manns",
  Manor = "manor",
  Mansfield = "mansfield",
  MansfieldFrontier = "mansfieldFrontier",
  MansfieldLakeRidge = "mansfieldLakeRidge",
  Manvel = "manvel",
  Marblefall = "marblefall",
  Marcus = "marcus",
  Marfa = "marfa",
  Marietta = "marietta",
  Marlin = "marlin",
  Marshall = "marshall",
  Mart = "mart",
  Martin = "martin",
  Martinsmil = "martinsmil",
  MaryGrett = "maryGrett",
  Maryland = "maryland",
  Mason = "mason",
  Massachusetts = "massachusetts",
  May = "may",
  Maypearl = "maypearl",
  Mcallen = "mcallen",
  Mccollum = "mccollum",
  Mcgregor = "mcgregor",
  Mckinney = "mckinney",
  MckinneyBoyd = "mckinneyBoyd",
  McKinneyNorth = "mcKinneyNorth",
  Mckinneyno = "mckinneyno",
  Mcmillen = "mcmillen",
  Mcneil = "mcneil",
  MedinaValley = "medinaValley",
  Medinavall = "medinavall",
  Melissa = "melissa",
  Memorial = "memorial",
  Memphis = "memphis",
  Menard = "menard",
  Meridian = "meridian",
  Merkel = "merkel",
  Mesquite = "mesquite",
  Mexia = "mexia",
  Mexico = "mexico",
  Michigan = "michigan",
  Midland = "midland",
  MidlandFreshman = "midlandFreshman",
  Midlothian = "midlothian",
  Midway = "midway",
  Milano = "milano",
  Mildred = "mildred",
  Miles = "miles",
  Milford = "milford",
  Miller = "miller",
  Millergrov = "millergrov",
  Millsap = "millsap",
  Mineola = "mineola",
  MineralWells = "mineralWells",
  Mineralwel = "mineralwel",
  Mississippi = "mississippi",
  Missouri = "missouri",
  Molina = "molina",
  Montana = "montana",
  Monterey = "monterey",
  Montgomery = "montgomery",
  Montwood = "montwood",
  Moody = "moody",
  Moore = "moore",
  Moran = "moran",
  Morgan = "morgan",
  MorganMil = "morganMil",
  Morganmill = "morganmill",
  Morton = "morton",
  MotleyCountySchool = "motleyCountySchool",
  MountEnterprise = "mountEnterprise",
  Mountainvi = "mountainvi",
  Mountpleas = "mountpleas",
  Mtcalm = "mtcalm",
  Mtvernon = "mtvernon",
  Muenster = "muenster",
  Mullin = "mullin",
  Munday = "munday",
  Nacogdoche = "nacogdoche",
  Nacona = "nacona",
  Natalia = "natalia",
  Neches = "neches",
  Nevada = "nevada",
  NewBoston = "newBoston",
  NewBraunfels = "newBraunfels",
  NewCaney = "newCaney",
  NewHome = "newHome",
  NewMexico = "newMexico",
  NewOpe = "newOpe",
  NewSummerfield = "newSummerfield",
  NewTech = "newTech",
  NewWaverly = "newWaverly",
  NewYork = "newYork",
  Newbraunfe = "newbraunfe",
  Newcastle = "newcastle",
  Newdiana = "newdiana",
  Newton = "newton",
  Nicaragua = "nicaragua",
  Nimitz = "nimitz",
  NinthGradeCenter = "ninthGradeCenter",
  Nixon = "nixon",
  Nocona = "nocona",
  Normangee = "normangee",
  NorthCarolina = "northCarolina",
  NorthCrowley = "northCrowley",
  NorthCrowley9ThGrade = "northCrowley9ThGrade",
  NorthDakota = "northDakota",
  NorthForney = "northForney",
  Northdalla = "northdalla",
  Northhopki = "northhopki",
  Northlamar = "northlamar",
  Northmesqu = "northmesqu",
  Northside = "northside",
  Northwest = "northwest",
  NorthwestEarlyCollege = "northwestEarlyCollege",
  NtreisTestOnly = "ntreisTestOnly",
  Nueces = "nueces",
  Oakwood = "oakwood",
  Oconnor = "oconnor",
  Odessa = "odessa",
  Oglesby = "oglesby",
  Ohio = "ohio",
  Oklahoma = "oklahoma",
  Olfen = "olfen",
  Olney = "olney",
  Orecity = "orecity",
  Oregon = "oregon",
  Other = "other",
  Overton = "overton",
  Ozen = "ozen",
  Ozona = "ozona",
  Paducah = "paducah",
  PaintCreek = "paintCreek",
  PaintRock = "paintRock",
  Palacios = "palacios",
  Palestine = "palestine",
  Palmer = "palmer",
  PaloDuro = "paloDuro",
  Pampa = "pampa",
  Panama = "panama",
  Panhandle = "panhandle",
  PantherCreek = "pantherCreek",
  Paradise = "paradise",
  Paris = "paris",
  Parkland = "parkland",
  Paschal = "paschal",
  Pathways = "pathways",
  PattonSprings = "pattonSprings",
  Pearce = "pearce",
  Pearland = "pearland",
  Peaster = "peaster",
  Penelope = "penelope",
  Pennsylvania = "pennsylvania",
  Perrin = "perrin",
  PerrytonHighSchool = "perrytonHighSchool",
  Peru = "peru",
  Petrolia = "petrolia",
  Pettus = "pettus",
  Pflugervil = "pflugervil",
  PilotPoint = "pilotPoint",
  Pinetree = "pinetree",
  Pinkston = "pinkston",
  Pittsburg = "pittsburg",
  Plainview = "plainview",
  PleasantGrove = "pleasantGrove",
  Polytechni = "polytechni",
  Ponder = "ponder",
  Poolville = "poolville",
  PortIsabel = "portIsabel",
  PortNechesGroves = "portNechesGroves",
  Portaransa = "portaransa",
  Post = "post",
  Poteet = "poteet",
  Pottsboro = "pottsboro",
  PrairieLea = "prairieLea",
  PrairieVa = "prairieVa",
  Prairiland = "prairiland",
  Presidio = "presidio",
  Priddy = "priddy",
  Princeton = "princeton",
  Prosper = "prosper",
  PuertoRico = "puertoRico",
  Quanah = "quanah",
  QueenCity = "queenCity",
  Quitman = "quitman",
  Rains = "rains",
  Ranchview = "ranchview",
  Randall = "randall",
  Randolph = "randolph",
  Ranger = "ranger",
  Ray = "ray",
  RayBraswell = "rayBraswell",
  Reagan = "reagan",
  RedOak = "redOak",
  Redoak = "redoak",
  Redwater = "redwater",
  Reedy = "reedy",
  Refugio = "refugio",
  Rice = "rice",
  Richards = "richards",
  Richardson = "richardson",
  Richarte = "richarte",
  Richland = "richland",
  Riesel = "riesel",
  RioGrandeCity = "rioGrandeCity",
  RioGrandeCityCisdGrulla = "rioGrandeCityCisdGrulla",
  RioHondo = "rioHondo",
  RioVista = "rioVista",
  RisingStar = "risingStar",
  RiverRoad = "riverRoad",
  Rivercrest = "rivercrest",
  Riverside = "riverside",
  Robertlee = "robertlee",
  Robinson = "robinson",
  Robstown = "robstown",
  Roby = "roby",
  Rochelle = "rochelle",
  Rochester = "rochester",
  RockHill = "rockHill",
  Rockdale = "rockdale",
  Rockportfu = "rockportfu",
  Rocksprings = "rocksprings",
  Rockwall = "rockwall",
  Rogers = "rogers",
  Roosevelt = "roosevelt",
  Roscoe = "roscoe",
  RosebudLott = "rosebudLott",
  Rotan = "rotan",
  Roundrock = "roundrock",
  Roxton = "roxton",
  RoyseCity = "royseCity",
  Rule = "rule",
  Runge = "runge",
  Rusk = "rusk",
  RyanHS = "ryanHS",
  SAndS = "sAndS",
  Sabinal = "sabinal",
  Sabine = "sabine",
  Saginaw = "saginaw",
  SaintJo = "saintJo",
  Salado = "salado",
  Saltillo = "saltillo",
  SamHouston = "samHouston",
  Samrayburn = "samrayburn",
  SamuelClemens = "samuelClemens",
  Samuell = "samuell",
  SanAugustine = "sanAugustine",
  SanMarcus = "sanMarcus",
  SanSaba = "sanSaba",
  Sanelizaro = "sanelizaro",
  Sanger = "sanger",
  SantaAnna = "santaAnna",
  Santo = "santo",
  Saugustine = "saugustine",
  Savoy = "savoy",
  SchoolofChoice = "schoolofChoice",
  Scotland = "scotland",
  Scurry = "scurry",
  Seagoville = "seagoville",
  Seguin = "seguin",
  Serenity = "serenity",
  SevenLakes = "sevenLakes",
  Seymour = "seymour",
  Sharyland = "sharyland",
  Shepherd = "shepherd",
  Shepton = "shepton",
  Sherman = "sherman",
  Shoemaker = "shoemaker",
  Sidney = "sidney",
  SierraBlanca = "sierraBlanca",
  Silsbee = "silsbee",
  Skyline = "skyline",
  Slidell = "slidell",
  Slocum = "slocum",
  Smith = "smith",
  Smithsonva = "smithsonva",
  Snook = "snook",
  Snyder = "snyder",
  Socorro = "socorro",
  Somerset = "somerset",
  Somerville = "somerville",
  Sonora = "sonora",
  Sosananton = "sosananton",
  SouthAfrica = "southAfrica",
  SouthDakota = "southDakota",
  SouthGrandPrairie = "southGrandPrairie",
  Southhills = "southhills",
  Southoakcl = "southoakcl",
  Southside = "southside",
  Southwest = "southwest",
  Spain = "spain",
  Springhill = "springhill",
  Springtown = "springtown",
  Sprngwoods = "sprngwoods",
  Spruce = "spruce",
  Spur = "spur",
  StLucia = "stLucia",
  StVincent = "stVincent",
  Stacey = "stacey",
  Stafford = "stafford",
  Stamford = "stamford",
  StephenFAustin = "stephenFAustin",
  Stephenvil = "stephenvil",
  Stockdale = "stockdale",
  StoneBurg = "stoneBurg",
  Stonypoint = "stonypoint",
  Strawn = "strawn",
  Sudan = "sudan",
  Sulphurblu = "sulphurblu",
  Sulphurspr = "sulphurspr",
  Summit = "summit",
  Sunnyvale = "sunnyvale",
  Sunset = "sunset",
  Sweetwater = "sweetwater",
  Taft = "taft",
  Tascosa = "tascosa",
  Tatum = "tatum",
  Taylor = "taylor",
  Teague = "teague",
  Temple = "temple",
  Tennessee = "tennessee",
  Terrell = "terrell",
  Texas = "texas",
  TheColony = "theColony",
  ThePhilippines = "thePhilippines",
  Thrall = "thrall",
  Threeriver = "threeriver",
  Throckmort = "throckmort",
  Tidehaven = "tidehaven",
  TimberCreek = "timberCreek",
  Timberview = "timberview",
  Timpson = "timpson",
  Tioga = "tioga",
  Tivy = "tivy",
  Tolar = "tolar",
  TomBean = "tomBean",
  Tornillo = "tornillo",
  Trenton = "trenton",
  Trimble = "trimble",
  Trinidad = "trinidad",
  Trinity = "trinity",
  Troup = "troup",
  Troy = "troy",
  Tulia = "tulia",
  Tulosomidw = "tulosomidw",
  Turner = "turner",
  Tyler = "tyler",
  TylerLegacy = "tylerLegacy",
  Uniongrove = "uniongrove",
  Unionhill = "unionhill",
  Uvalde = "uvalde",
  Valley = "valley",
  ValleyMil = "valleyMil",
  Valleyview = "valleyview",
  Van = "van",
  VanAlstyne = "vanAlstyne",
  VanHorn = "vanHorn",
  Vanvleck = "vanvleck",
  Vega = "vega",
  Venus = "venus",
  Veribest = "veribest",
  Vernon = "vernon",
  Vidor = "vidor",
  Vines = "vines",
  Virginia = "virginia",
  Vistaridge = "vistaridge",
  Waco = "waco",
  Wakeland = "wakeland",
  Walker = "walker",
  WallaceAccelerated = "wallaceAccelerated",
  Waller = "waller",
  WalnutGrove = "walnutGrove",
  WalnutSp = "walnutSp",
  Waltrip = "waltrip",
  Warren = "warren",
  Washington = "washington",
  Waskom = "waskom",
  Waxahachie = "waxahachie",
  WaxahachieNinthGradeAcademy = "waxahachieNinthGradeAcademy",
  Weatherford = "weatherford",
  Wellington = "wellington",
  Wells = "wells",
  Weslaco = "weslaco",
  West = "west",
  WestPlainsHighSchool = "westPlainsHighSchool",
  WestSabine = "westSabine",
  WestTexas = "westTexas",
  WestVirginia = "westVirginia",
  Westbrook = "westbrook",
  Westlake = "westlake",
  Westmesqui = "westmesqui",
  WestnHill = "westnHill",
  Westoso = "westoso",
  Westrusk = "westrusk",
  Westwood = "westwood",
  Wheeler = "wheeler",
  White = "white",
  Whiteface = "whiteface",
  Whitehouse = "whitehouse",
  Whiteoak = "whiteoak",
  Whitesboro = "whitesboro",
  Whitewrigh = "whitewrigh",
  Whitney = "whitney",
  WichitaFalls = "wichitaFalls",
  Wildorado = "wildorado",
  Williams = "williams",
  Willis = "willis",
  Willspoint = "willspoint",
  Wilmerhutc = "wilmerhutc",
  WoodrowWilson = "woodrowWilson",
  Wimberley = "wimberley",
  Windthorst = "windthorst",
  Winnsboro = "winnsboro",
  Winona = "winona",
  Winters = "winters",
  Woden = "woden",
  Wolfecity = "wolfecity",
  Woodlands = "woodlands",
  Woodsboro = "woodsboro",
  Woodson = "woodson",
  Woodville = "woodville",
  Wortham = "wortham",
  WyattOd = "wyattOd",
  Wylie = "wylie",
  WylieEast = "wylieEast",
  Wyoming = "wyoming",
  Yantis = "yantis",
  Yermo = "yermo",
  Yoakum = "yoakum",
  Ysleta = "ysleta",
  Zephyr = "zephyr"
}

export const HighSchoolLabel: Record<HighSchool, string> = {
  [HighSchool.ACJones]: "A C Jones",
  [HighSchool.Abbott]: "Abbott",
  [HighSchool.Abilene]: "Abilene",
  [HighSchool.Academy]: "Academy",
  [HighSchool.Adams]: "Adams",
  [HighSchool.Adamson]: "Adamson",
  [HighSchool.Aguadulce]: "Aguadulce",
  [HighSchool.AimCenter]: "Aim Center",
  [HighSchool.Alabama]: "Alabama",
  [HighSchool.Alamoheigh]: "Alamoheigh",
  [HighSchool.Alaska]: "Alaska",
  [HighSchool.Albagolden]: "Albagolden",
  [HighSchool.Albany]: "Albany",
  [HighSchool.Aledo]: "Aledo",
  [HighSchool.Allen]: "Allen",
  [HighSchool.Alpine]: "Alpine",
  [HighSchool.Alter]: "Alter",
  [HighSchool.Alto]: "Alto",
  [HighSchool.Alvarado]: "Alvarado",
  [HighSchool.Alvin]: "Alvin",
  [HighSchool.Alvord]: "Alvord",
  [HighSchool.Amarillo]: "Amarillo",
  [HighSchool.Amcons]: "Amcons",
  [HighSchool.Americas]: "Americas",
  [HighSchool.Anahuac]: "Anahuac",
  [HighSchool.AndersonShiro]: "Anderson-Shiro",
  [HighSchool.Andress]: "Andress",
  [HighSchool.Andrews]: "Andrews",
  [HighSchool.Anguilla]: "Anguilla",
  [HighSchool.Anna]: "Anna",
  [HighSchool.Anson]: "Anson",
  [HighSchool.Anthony]: "Anthony",
  [HighSchool.AppleSprings]: "Apple Springs",
  [HighSchool.Aquilla]: "Aquilla",
  [HighSchool.Archercity]: "Archercity",
  [HighSchool.Argyle]: "Argyle",
  [HighSchool.Arizona]: "Arizona",
  [HighSchool.Arkansas]: "Arkansas",
  [HighSchool.Arlington]: "Arlington",
  [HighSchool.Arlngtnhts]: "Arlngtnhts",
  [HighSchool.Arp]: "Arp",
  [HighSchool.Aruba]: "Aruba",
  [HighSchool.Aspermont]: "Aspermont",
  [HighSchool.Athens]: "Athens",
  [HighSchool.Atlanta]: "Atlanta",
  [HighSchool.Aubrey]: "Aubrey",
  [HighSchool.Austin]: "Austin",
  [HighSchool.Australia]: "Australia",
  [HighSchool.Austwellti]: "Austwellti",
  [HighSchool.Avalon]: "Avalon",
  [HighSchool.Avery]: "Avery",
  [HighSchool.Avinger]: "Avinger",
  [HighSchool.Axtell]: "Axtell",
  [HighSchool.Azle]: "Azle",
  [HighSchool.Bahamas]: "Bahamas",
  [HighSchool.Baird]: "Baird",
  [HighSchool.Ball]: "Ball",
  [HighSchool.Ballinger]: "Ballinger",
  [HighSchool.Balmorhea]: "Balmorhea",
  [HighSchool.Bangs]: "Bangs",
  [HighSchool.Banquete]: "Banquete",
  [HighSchool.Barbados]: "Barbados",
  [HighSchool.Bartlett]: "Bartlett",
  [HighSchool.Bastrop]: "Bastrop",
  [HighSchool.Baycity]: "Baycity",
  [HighSchool.Belair]: "Belair",
  [HighSchool.Belize]: "Belize",
  [HighSchool.Bell]: "Bell",
  [HighSchool.Bellevue]: "Bellevue",
  [HighSchool.Bells]: "Bells",
  [HighSchool.Bellville]: "Bellville",
  [HighSchool.Belton]: "Belton",
  [HighSchool.Benbrook]: "Benbrook",
  [HighSchool.Benjamin]: "Benjamin",
  [HighSchool.Berkner]: "Berkner",
  [HighSchool.BigSpring]: "Big Spring",
  [HighSchool.Bigsandy]: "Bigsandy",
  [HighSchool.Birdville]: "Birdville",
  [HighSchool.Bishop]: "Bishop",
  [HighSchool.Blackwell]: "Blackwell",
  [HighSchool.Blanco]: "Blanco",
  [HighSchool.Bland]: "Bland",
  [HighSchool.Blanket]: "Blanket",
  [HighSchool.Bloomburg]: "Bloomburg",
  [HighSchool.Bloominggr]: "Bloominggr",
  [HighSchool.Blueridge]: "Blueridge",
  [HighSchool.Blum]: "Blum",
  [HighSchool.Boerne]: "Boerne",
  [HighSchool.Boggess]: "Boggess",
  [HighSchool.Boles]: "Boles",
  [HighSchool.Bonham]: "Bonham",
  [HighSchool.BordenCountySchool]: "Borden County School",
  [HighSchool.Borger]: "Borger",
  [HighSchool.Bosqueville]: "Bosqueville",
  [HighSchool.Boswell]: "Boswell",
  [HighSchool.Bowie]: "Bowie",
  [HighSchool.Boyd]: "Boyd",
  [HighSchool.Brackenrid]: "Brackenrid",
  [HighSchool.Brackett]: "Brackett",
  [HighSchool.Brady]: "Brady",
  [HighSchool.Brazil]: "Brazil",
  [HighSchool.Brazosport]: "Brazosport",
  [HighSchool.Breckenrid]: "Breckenrid",
  [HighSchool.Bremond]: "Bremond",
  [HighSchool.Brewer]: "Brewer",
  [HighSchool.Bridgeport]: "Bridgeport",
  [HighSchool.Brock]: "Brock",
  [HighSchool.Bronte]: "Bronte",
  [HighSchool.Brookeland]: "Brookeland",
  [HighSchool.Brookesmith]: "Brookesmith",
  [HighSchool.Brownfield]: "Brownfield",
  [HighSchool.Brownsboro]: "Brownsboro",
  [HighSchool.Brownsville]: "Brownsville",
  [HighSchool.Brownwood]: "Brownwood",
  [HighSchool.BrucevilleEddy]: "Bruceville-Eddy",
  [HighSchool.Bryan]: "Bryan",
  [HighSchool.BryanCollegiate]: "Bryan Collegiate",
  [HighSchool.Bryson]: "Bryson",
  [HighSchool.BuenaVistaSchool]: "Buena Vista School",
  [HighSchool.Buffalo]: "Buffalo",
  [HighSchool.Bulgaria]: "Bulgaria",
  [HighSchool.Bullard]: "Bullard",
  [HighSchool.Buna]: "Buna",
  [HighSchool.Burbank]: "Burbank",
  [HighSchool.Burges]: "Burges",
  [HighSchool.Burkburnett]: "Burkburnett",
  [HighSchool.Burkeville]: "Burkeville",
  [HighSchool.Burleson]: "Burleson",
  [HighSchool.BurlesonCentennial]: "Burleson Centennial",
  [HighSchool.Burnet]: "Burnet",
  [HighSchool.Burton]: "Burton",
  [HighSchool.Bushland]: "Bushland",
  [HighSchool.Bynum]: "Bynum",
  [HighSchool.ByronNelson]: "Byron Nelson",
  [HighSchool.ByronPSteeleIi]: "Byron P Steele Ii",
  [HighSchool.Caddomills]: "Caddomills",
  [HighSchool.Calallen]: "Calallen",
  [HighSchool.Caldwell]: "Caldwell",
  [HighSchool.Calhoun]: "Calhoun",
  [HighSchool.California]: "California",
  [HighSchool.Calisburg]: "Calisburg",
  [HighSchool.Callisburg]: "Callisburg",
  [HighSchool.Calvert]: "Calvert",
  [HighSchool.CameronYoe]: "Cameron Yoe",
  [HighSchool.Campbell]: "Campbell",
  [HighSchool.Canada]: "Canada",
  [HighSchool.Canton]: "Canton",
  [HighSchool.Canutillo]: "Canutillo",
  [HighSchool.Canyon]: "Canyon",
  [HighSchool.Canyonlake]: "Canyonlake",
  [HighSchool.Caprock]: "Caprock",
  [HighSchool.Carlisle]: "Carlisle",
  [HighSchool.Carroll]: "Carroll",
  [HighSchool.Carter]: "Carter",
  [HighSchool.CarterRiv]: "Carter Riv",
  [HighSchool.Carthage]: "Carthage",
  [HighSchool.Castleberr]: "Castleberr",
  [HighSchool.Cates]: "Cates",
  [HighSchool.Cathedral]: "Cathedral",
  [HighSchool.CaymanIslands]: "Cayman Islands",
  [HighSchool.Cayuga]: "Cayuga",
  [HighSchool.CedarHillEarlyCollege]: "Cedar Hill Early College",
  [HighSchool.Cedarhill]: "Cedarhill",
  [HighSchool.Cedarpark]: "Cedarpark",
  [HighSchool.Celeste]: "Celeste",
  [HighSchool.Celina]: "Celina",
  [HighSchool.Centennial]: "Centennial",
  [HighSchool.Center]: "Center",
  [HighSchool.Centerville]: "Centerville",
  [HighSchool.Central]: "Central",
  [HighSchool.CentralHeights]: "Central Heights",
  [HighSchool.Centralhei]: "Centralhei",
  [HighSchool.Chapelhill]: "Chapelhill",
  [HighSchool.Chapin]: "Chapin",
  [HighSchool.Chico]: "Chico",
  [HighSchool.Childress]: "Childress",
  [HighSchool.Chile]: "Chile",
  [HighSchool.Chillicoth]: "Chillicoth",
  [HighSchool.Chinasprng]: "Chinasprng",
  [HighSchool.Chireno]: "Chireno",
  [HighSchool.ChisholmTrail]: "Chisholm Trail",
  [HighSchool.ChisolmTrail]: "Chisolm Trail",
  [HighSchool.Chisolmtra]: "Chisolmtra",
  [HighSchool.Chisum]: "Chisum",
  [HighSchool.ChoiceOfSchool]: "Choice Of School",
  [HighSchool.Churchill]: "Churchill",
  [HighSchool.Cigarroa]: "Cigarroa",
  [HighSchool.Cisco]: "Cisco",
  [HighSchool.Clarendon]: "Clarendon",
  [HighSchool.Clark]: "Clark",
  [HighSchool.Clarksvill]: "Clarksvill",
  [HighSchool.Claude]: "Claude",
  [HighSchool.Cleburne]: "Cleburne",
  [HighSchool.Cleveland]: "Cleveland",
  [HighSchool.Clifton]: "Clifton",
  [HighSchool.Clint]: "Clint",
  [HighSchool.Clyde]: "Clyde",
  [HighSchool.ColdspringOakhurst]: "Coldspring-Oakhurst",
  [HighSchool.Coleman]: "Coleman",
  [HighSchool.Coles]: "Coles",
  [HighSchool.Collegiate]: "Collegiate",
  [HighSchool.Collinsville]: "Collinsville",
  [HighSchool.Colorado]: "Colorado",
  [HighSchool.Columbus]: "Columbus",
  [HighSchool.Comanche]: "Comanche",
  [HighSchool.Comfort]: "Comfort",
  [HighSchool.Commerce]: "Commerce",
  [HighSchool.Community]: "Community",
  [HighSchool.Comopickto]: "Comopickto",
  [HighSchool.Comstock]: "Comstock",
  [HighSchool.Connally]: "Connally",
  [HighSchool.Connecticut]: "Connecticut",
  [HighSchool.Conrad]: "Conrad",
  [HighSchool.Conroe]: "Conroe",
  [HighSchool.Coolidge]: "Coolidge",
  [HighSchool.Cooper]: "Cooper",
  [HighSchool.Coppell]: "Coppell",
  [HighSchool.Copperasco]: "Copperasco",
  [HighSchool.Corley]: "Corley",
  [HighSchool.Coronado]: "Coronado",
  [HighSchool.CorriganCamden]: "Corrigan-Camden",
  [HighSchool.Corsicana]: "Corsicana",
  [HighSchool.CostaRica]: "Costa Rica",
  [HighSchool.Covington]: "Covington",
  [HighSchool.Crandall]: "Crandall",
  [HighSchool.Cranfillsg]: "Cranfillsg",
  [HighSchool.Crawford]: "Crawford",
  [HighSchool.Creekview]: "Creekview",
  [HighSchool.Crockett]: "Crockett",
  [HighSchool.CrossPlains]: "Cross Plains",
  [HighSchool.CrosslandNinthGradeCenter]: "Crossland Ninth Grade Center",
  [HighSchool.Crossroads]: "Crossroads",
  [HighSchool.Crowell]: "Crowell",
  [HighSchool.Crowley]: "Crowley",
  [HighSchool.Crowley9ThGrade]: "Crowley 9Th Grade",
  [HighSchool.Cuero]: "Cuero",
  [HighSchool.Cumby]: "Cumby",
  [HighSchool.CyFair]: "Cy-Fair",
  [HighSchool.CypressFalls]: "Cypress Falls",
  [HighSchool.CypressSprings]: "Cypress Springs",
  [HighSchool.Daingerfie]: "Daingerfie",
  [HighSchool.Dawson]: "Dawson",
  [HighSchool.Dayton]: "Dayton",
  [HighSchool.Decalb]: "Decalb",
  [HighSchool.Decatur]: "Decatur",
  [HighSchool.DeerPark]: "Deer Park",
  [HighSchool.DelValle]: "Del Valle",
  [HighSchool.Deleon]: "Deleon",
  [HighSchool.Delrio]: "Delrio",
  [HighSchool.Delvalle]: "Delvalle",
  [HighSchool.Denison]: "Denison",
  [HighSchool.Denton]: "Denton",
  [HighSchool.Desoto]: "Desoto",
  [HighSchool.DesotoHsFreshmanCampus]: "Desoto Hs Freshman Campus",
  [HighSchool.Detroit]: "Detroit",
  [HighSchool.Devers]: "Devers",
  [HighSchool.Devine]: "Devine",
  [HighSchool.Dickinson]: "Dickinson",
  [HighSchool.Doddcity]: "Doddcity",
  [HighSchool.DominicanRepublic]: "Dominican Republic",
  [HighSchool.Douglass]: "Douglass",
  [HighSchool.DrippingSprings]: "Dripping Springs",
  [HighSchool.Dublin]: "Dublin",
  [HighSchool.Dumas]: "Dumas",
  [HighSchool.Dunbar]: "Dunbar",
  [HighSchool.Duncanvill]: "Duncanvill",
  [HighSchool.Eaglepass]: "Eaglepass",
  [HighSchool.Early]: "Early",
  [HighSchool.Eastcentra]: "Eastcentra",
  [HighSchool.Easternhil]: "Easternhil",
  [HighSchool.Eastland]: "Eastland",
  [HighSchool.Eastwood]: "Eastwood",
  [HighSchool.Eaton]: "Eaton",
  [HighSchool.Ector]: "Ector",
  [HighSchool.Eden]: "Eden",
  [HighSchool.Edgewood]: "Edgewood",
  [HighSchool.Edison]: "Edison",
  [HighSchool.Edna]: "Edna",
  [HighSchool.Eisenhower]: "Eisenhower",
  [HighSchool.ElSalvador]: "El Salvador",
  [HighSchool.Electra]: "Electra",
  [HighSchool.Elkhart]: "Elkhart",
  [HighSchool.Ellison]: "Ellison",
  [HighSchool.Elpaso]: "Elpaso",
  [HighSchool.Elysianfie]: "Elysianfie",
  [HighSchool.Emerson]: "Emerson",
  [HighSchool.Ennis]: "Ennis",
  [HighSchool.Era]: "Era",
  [HighSchool.Eula]: "Eula",
  [HighSchool.Eustace]: "Eustace",
  [HighSchool.Evadale]: "Evadale",
  [HighSchool.Evant]: "Evant",
  [HighSchool.Everman]: "Everman",
  [HighSchool.Excel]: "Excel",
  [HighSchool.Fabens]: "Fabens",
  [HighSchool.Fairfield]: "Fairfield",
  [HighSchool.Fallscity]: "Fallscity",
  [HighSchool.Fannindell]: "Fannindell",
  [HighSchool.Farmersvil]: "Farmersvil",
  [HighSchool.Farwell]: "Farwell",
  [HighSchool.Ferris]: "Ferris",
  [HighSchool.Florence]: "Florence",
  [HighSchool.Florida]: "Florida",
  [HighSchool.Flourbluff]: "Flourbluff",
  [HighSchool.FlowerMound]: "Flower Mound",
  [HighSchool.Ford]: "Ford",
  [HighSchool.Forestburg]: "Forestburg",
  [HighSchool.Forney]: "Forney",
  [HighSchool.Forsan]: "Forsan",
  [HighSchool.FortDavis]: "Fort Davis",
  [HighSchool.FortStockton]: "Fort Stockton",
  [HighSchool.Fossilridg]: "Fossilridg",
  [HighSchool.Fox]: "Fox",
  [HighSchool.France]: "France",
  [HighSchool.Franklin]: "Franklin",
  [HighSchool.Frankston]: "Frankston",
  [HighSchool.Fredericks]: "Fredericks",
  [HighSchool.Frenship]: "Frenship",
  [HighSchool.Friona]: "Friona",
  [HighSchool.Frisco]: "Frisco",
  [HighSchool.Frost]: "Frost",
  [HighSchool.Fruitvale]: "Fruitvale",
  [HighSchool.Gainesvill]: "Gainesvill",
  [HighSchool.GalenaPark]: "Galena Park",
  [HighSchool.Ganado]: "Ganado",
  [HighSchool.Garrison]: "Garrison",
  [HighSchool.Gary]: "Gary",
  [HighSchool.Gatesville]: "Gatesville",
  [HighSchool.Genesis]: "Genesis",
  [HighSchool.Georgetown]: "Georgetown",
  [HighSchool.Georgewest]: "Georgewest",
  [HighSchool.Georgia]: "Georgia",
  [HighSchool.Germany]: "Germany",
  [HighSchool.Gholson]: "Gholson",
  [HighSchool.Gilmer]: "Gilmer",
  [HighSchool.Gladewater]: "Gladewater",
  [HighSchool.GlenRose]: "Glen Rose",
  [HighSchool.Godley]: "Godley",
  [HighSchool.Goldthwaite]: "Goldthwaite",
  [HighSchool.Goliad]: "Goliad",
  [HighSchool.Gordon]: "Gordon",
  [HighSchool.Gorman]: "Gorman",
  [HighSchool.Graford]: "Graford",
  [HighSchool.Graham]: "Graham",
  [HighSchool.Granbury]: "Granbury",
  [HighSchool.Grandprair]: "Grandprair",
  [HighSchool.Grandsalin]: "Grandsalin",
  [HighSchool.Grandview]: "Grandview",
  [HighSchool.Grapeland]: "Grapeland",
  [HighSchool.Grapevine]: "Grapevine",
  [HighSchool.Greenville]: "Greenville",
  [HighSchool.Groesbeck]: "Groesbeck",
  [HighSchool.Guatemala]: "Guatemala",
  [HighSchool.Gunter]: "Gunter",
  [HighSchool.Gustine]: "Gustine",
  [HighSchool.Guyer]: "Guyer",
  [HighSchool.HGradySpruce]: "H Grady Spruce",
  [HighSchool.Hallsville]: "Hallsville",
  [HighSchool.Haltom]: "Haltom",
  [HighSchool.Hamilton]: "Hamilton",
  [HighSchool.Hamlin]: "Hamlin",
  [HighSchool.Hanks]: "Hanks",
  [HighSchool.HappyHighSchool]: "Happy High School",
  [HighSchool.Hardin]: "Hardin",
  [HighSchool.HardinJefferson]: "Hardin-Jefferson",
  [HighSchool.Harkerheig]: "Harkerheig",
  [HighSchool.Harlandale]: "Harlandale",
  [HighSchool.Harleton]: "Harleton",
  [HighSchool.Harlingen]: "Harlingen",
  [HighSchool.HarlingenSouth]: "Harlingen South",
  [HighSchool.Harmony]: "Harmony",
  [HighSchool.Harper]: "Harper",
  [HighSchool.Haskell]: "Haskell",
  [HighSchool.Hawkins]: "Hawkins",
  [HighSchool.Hawley]: "Hawley",
  [HighSchool.Hearne]: "Hearne",
  [HighSchool.Heath]: "Heath",
  [HighSchool.Hebron]: "Hebron",
  [HighSchool.Hemphill]: "Hemphill",
  [HighSchool.Henderson]: "Henderson",
  [HighSchool.Henrietta]: "Henrietta",
  [HighSchool.HenryTWaskow]: "Henry T Waskow",
  [HighSchool.Heritage]: "Heritage",
  [HighSchool.Hermliegh]: "Hermliegh",
  [HighSchool.Hico]: "Hico",
  [HighSchool.Highland]: "Highland",
  [HighSchool.HighlandPark]: "Highland Park",
  [HighSchool.Highlandpa]: "Highlandpa",
  [HighSchool.Highlands]: "Highlands",
  [HighSchool.HillCountry]: "Hill Country",
  [HighSchool.Hillcrest]: "Hillcrest",
  [HighSchool.Hillsboro]: "Hillsboro",
  [HighSchool.Hitchcock]: "Hitchcock",
  [HighSchool.Holland]: "Holland",
  [HighSchool.HollandMedical]: "Holland Medical",
  [HighSchool.Holliday]: "Holliday",
  [HighSchool.Hondo]: "Hondo",
  [HighSchool.Honduras]: "Honduras",
  [HighSchool.Honeygrove]: "Honeygrove",
  [HighSchool.Hooks]: "Hooks",
  [HighSchool.Hope]: "Hope",
  [HighSchool.Horizon]: "Horizon",
  [HighSchool.Horn]: "Horn",
  [HighSchool.Houston]: "Houston",
  [HighSchool.Howe]: "Howe",
  [HighSchool.Hubbard]: "Hubbard",
  [HighSchool.Huckabay]: "Huckabay",
  [HighSchool.Hudson]: "Hudson",
  [HighSchool.HughesSprings]: "Hughes Springs",
  [HighSchool.Humble]: "Humble",
  [HighSchool.Hunt]: "Hunt",
  [HighSchool.Huntsville]: "Huntsville",
  [HighSchool.Hutto]: "Hutto",
  [HighSchool.Idaho]: "Idaho",
  [HighSchool.Illinois]: "Illinois",
  [HighSchool.Independence]: "Independence",
  [HighSchool.Indiana]: "Indiana",
  [HighSchool.Indonesia]: "Indonesia",
  [HighSchool.Industrial]: "Industrial",
  [HighSchool.Ingleside]: "Ingleside",
  [HighSchool.IngramTomMoore]: "Ingram Tom Moore",
  [HighSchool.IowaPark]: "Iowa Park",
  [HighSchool.Iredell]: "Iredell",
  [HighSchool.Irion]: "Irion",
  [HighSchool.Irvin]: "Irvin",
  [HighSchool.Irving]: "Irving",
  [HighSchool.Italy]: "Italy",
  [HighSchool.Itasca]: "Itasca",
  [HighSchool.Jacksboro]: "Jacksboro",
  [HighSchool.Jacksonvil]: "Jacksonvil",
  [HighSchool.Jamacia]: "Jamacia",
  [HighSchool.JamesEarlRudder]: "James Earl Rudder",
  [HighSchool.JamesMSteeleAccelerated]: "James M Steele Accelerated",
  [HighSchool.Jarrell]: "Jarrell",
  [HighSchool.Jarvis]: "Jarvis",
  [HighSchool.Jasper]: "Jasper",
  [HighSchool.Jay]: "Jay",
  [HighSchool.Jefferson]: "Jefferson",
  [HighSchool.JimNed]: "Jim Ned",
  [HighSchool.Jonesboro]: "Jonesboro",
  [HighSchool.Joshua]: "Joshua",
  [HighSchool.JuarezLincoln]: "Juarez-Lincoln",
  [HighSchool.Judson]: "Judson",
  [HighSchool.Junction]: "Junction",
  [HighSchool.Kansas]: "Kansas",
  [HighSchool.Karnack]: "Karnack",
  [HighSchool.Karnescity]: "Karnescity",
  [HighSchool.Katy]: "Katy",
  [HighSchool.Kaufman]: "Kaufman",
  [HighSchool.Keene]: "Keene",
  [HighSchool.Keller]: "Keller",
  [HighSchool.Kemp]: "Kemp",
  [HighSchool.Kenedy]: "Kenedy",
  [HighSchool.Kennard]: "Kennard",
  [HighSchool.Kennedale]: "Kennedale",
  [HighSchool.Kennedy]: "Kennedy",
  [HighSchool.Kentucky]: "Kentucky",
  [HighSchool.Kerens]: "Kerens",
  [HighSchool.Kermit]: "Kermit",
  [HighSchool.Kilgore]: "Kilgore",
  [HighSchool.Killeen]: "Killeen",
  [HighSchool.Kimball]: "Kimball",
  [HighSchool.King]: "King",
  [HighSchool.Kirbyville]: "Kirbyville",
  [HighSchool.Klein]: "Klein",
  [HighSchool.KnoxCity]: "Knox City",
  [HighSchool.Kopperl]: "Kopperl",
  [HighSchool.Kountze]: "Kountze",
  [HighSchool.Krum]: "Krum",
  [HighSchool.LaGrange]: "La Grange",
  [HighSchool.LaVega]: "La Vega",
  [HighSchool.Lagovista]: "Lagovista",
  [HighSchool.LakeDallas]: "Lake Dallas",
  [HighSchool.LakeHighlands]: "Lake Highlands",
  [HighSchool.LakeTravis]: "Lake Travis",
  [HighSchool.LakeWorth]: "Lake Worth",
  [HighSchool.Lakehighla]: "Lakehighla",
  [HighSchool.Lamar]: "Lamar",
  [HighSchool.LamarAlternative]: "Lamar Alternative",
  [HighSchool.Lampasas]: "Lampasas",
  [HighSchool.Lancaster]: "Lancaster",
  [HighSchool.Laneville]: "Laneville",
  [HighSchool.Lanier]: "Lanier",
  [HighSchool.Lapoyner]: "Lapoyner",
  [HighSchool.Latexo]: "Latexo",
  [HighSchool.Leander]: "Leander",
  [HighSchool.LebanonTrail]: "Lebanon Trail",
  [HighSchool.Lee]: "Lee",
  [HighSchool.Legacy]: "Legacy",
  [HighSchool.Lehman]: "Lehman",
  [HighSchool.Leon]: "Leon",
  [HighSchool.Leonard]: "Leonard",
  [HighSchool.Lewisville]: "Lewisville",
  [HighSchool.LewisvilleHarmon]: "Lewisville-Harmon",
  [HighSchool.LewisvilleKillough]: "Lewisville-Killough",
  [HighSchool.Lexington]: "Lexington",
  [HighSchool.Liberty]: "Liberty",
  [HighSchool.LibertyEylau]: "Liberty-Eylau",
  [HighSchool.Libertyhil]: "Libertyhil",
  [HighSchool.Lincoln]: "Lincoln",
  [HighSchool.Lindale]: "Lindale",
  [HighSchool.Lindsay]: "Lindsay",
  [HighSchool.Linglevill]: "Linglevill",
  [HighSchool.Linkildare]: "Linkildare",
  [HighSchool.Lipan]: "Lipan",
  [HighSchool.LittleElm]: "Little Elm",
  [HighSchool.Livingston]: "Livingston",
  [HighSchool.Llano]: "Llano",
  [HighSchool.Lohn]: "Lohn",
  [HighSchool.Lometa]: "Lometa",
  [HighSchool.LoneStar]: "Lone Star",
  [HighSchool.Loneoak]: "Loneoak",
  [HighSchool.Longview]: "Longview",
  [HighSchool.Loraine]: "Loraine",
  [HighSchool.Lorena]: "Lorena",
  [HighSchool.Loretto]: "Loretto",
  [HighSchool.LosFresnos]: "Los Fresnos",
  [HighSchool.Louisiana]: "Louisiana",
  [HighSchool.Lovejoy]: "Lovejoy",
  [HighSchool.Lovelady]: "Lovelady",
  [HighSchool.Lubbock]: "Lubbock",
  [HighSchool.LubbockCooper]: "Lubbock-Cooper",
  [HighSchool.LuedersAvoca]: "Lueders-Avoca",
  [HighSchool.Lufkin]: "Lufkin",
  [HighSchool.LyndonBJohnson]: "Lyndon B Johnson",
  [HighSchool.Lytle]: "Lytle",
  [HighSchool.Mabank]: "Mabank",
  [HighSchool.Macarthur]: "Macarthur",
  [HighSchool.Madison]: "Madison",
  [HighSchool.Madisonville]: "Madisonville",
  [HighSchool.Maine]: "Maine",
  [HighSchool.Malakoff]: "Malakoff",
  [HighSchool.Manns]: "Manns",
  [HighSchool.Manor]: "Manor",
  [HighSchool.Mansfield]: "Mansfield",
  [HighSchool.MansfieldFrontier]: "Mansfield Frontier",
  [HighSchool.MansfieldLakeRidge]: "Mansfield Lake Ridge",
  [HighSchool.Manvel]: "Manvel",
  [HighSchool.Marblefall]: "Marblefall",
  [HighSchool.Marcus]: "Marcus",
  [HighSchool.Marfa]: "Marfa",
  [HighSchool.Marietta]: "Marietta",
  [HighSchool.Marlin]: "Marlin",
  [HighSchool.Marshall]: "Marshall",
  [HighSchool.Mart]: "Mart",
  [HighSchool.Martin]: "Martin",
  [HighSchool.Martinsmil]: "Martinsmil",
  [HighSchool.MaryGrett]: "Mary Grett",
  [HighSchool.Maryland]: "Maryland",
  [HighSchool.Mason]: "Mason",
  [HighSchool.Massachusetts]: "Massachusetts",
  [HighSchool.May]: "May",
  [HighSchool.Maypearl]: "Maypearl",
  [HighSchool.Mcallen]: "Mcallen",
  [HighSchool.Mccollum]: "Mccollum",
  [HighSchool.Mcgregor]: "Mcgregor",
  [HighSchool.Mckinney]: "Mckinney",
  [HighSchool.MckinneyBoyd]: "Mckinney Boyd",
  [HighSchool.McKinneyNorth]: "McKinney North",
  [HighSchool.Mckinneyno]: "Mckinneyno",
  [HighSchool.Mcmillen]: "Mcmillen",
  [HighSchool.Mcneil]: "Mcneil",
  [HighSchool.MedinaValley]: "Medina Valley",
  [HighSchool.Medinavall]: "Medinavall",
  [HighSchool.Melissa]: "Melissa",
  [HighSchool.Memorial]: "Memorial",
  [HighSchool.Memphis]: "Memphis",
  [HighSchool.Menard]: "Menard",
  [HighSchool.Meridian]: "Meridian",
  [HighSchool.Merkel]: "Merkel",
  [HighSchool.Mesquite]: "Mesquite",
  [HighSchool.Mexia]: "Mexia",
  [HighSchool.Mexico]: "Mexico",
  [HighSchool.Michigan]: "Michigan",
  [HighSchool.Midland]: "Midland",
  [HighSchool.MidlandFreshman]: "Midland Freshman",
  [HighSchool.Midlothian]: "Midlothian",
  [HighSchool.Midway]: "Midway",
  [HighSchool.Milano]: "Milano",
  [HighSchool.Mildred]: "Mildred",
  [HighSchool.Miles]: "Miles",
  [HighSchool.Milford]: "Milford",
  [HighSchool.Miller]: "Miller",
  [HighSchool.Millergrov]: "Millergrov",
  [HighSchool.Millsap]: "Millsap",
  [HighSchool.Mineola]: "Mineola",
  [HighSchool.MineralWells]: "Mineral Wells",
  [HighSchool.Mineralwel]: "Mineralwel",
  [HighSchool.Mississippi]: "Mississippi",
  [HighSchool.Missouri]: "Missouri",
  [HighSchool.Molina]: "Molina",
  [HighSchool.Montana]: "Montana",
  [HighSchool.Monterey]: "Monterey",
  [HighSchool.Montgomery]: "Montgomery",
  [HighSchool.Montwood]: "Montwood",
  [HighSchool.Moody]: "Moody",
  [HighSchool.Moore]: "Moore",
  [HighSchool.Moran]: "Moran",
  [HighSchool.Morgan]: "Morgan",
  [HighSchool.MorganMil]: "Morgan Mil",
  [HighSchool.Morganmill]: "Morganmill",
  [HighSchool.Morton]: "Morton",
  [HighSchool.MotleyCountySchool]: "Motley County School",
  [HighSchool.MountEnterprise]: "Mount Enterprise",
  [HighSchool.Mountainvi]: "Mountainvi",
  [HighSchool.Mountpleas]: "Mountpleas",
  [HighSchool.Mtcalm]: "Mtcalm",
  [HighSchool.Mtvernon]: "Mtvernon",
  [HighSchool.Muenster]: "Muenster",
  [HighSchool.Mullin]: "Mullin",
  [HighSchool.Munday]: "Munday",
  [HighSchool.Nacogdoche]: "Nacogdoche",
  [HighSchool.Nacona]: "Nacona",
  [HighSchool.Natalia]: "Natalia",
  [HighSchool.Neches]: "Neches",
  [HighSchool.Nevada]: "Nevada",
  [HighSchool.NewBoston]: "New Boston",
  [HighSchool.NewBraunfels]: "New Braunfels",
  [HighSchool.NewCaney]: "New Caney",
  [HighSchool.NewHome]: "New Home",
  [HighSchool.NewMexico]: "New Mexico",
  [HighSchool.NewOpe]: "New Ope",
  [HighSchool.NewSummerfield]: "New Summerfield",
  [HighSchool.NewTech]: "New Tech",
  [HighSchool.NewWaverly]: "New Waverly",
  [HighSchool.NewYork]: "New York",
  [HighSchool.Newbraunfe]: "Newbraunfe",
  [HighSchool.Newcastle]: "Newcastle",
  [HighSchool.Newdiana]: "Newdiana",
  [HighSchool.Newton]: "Newton",
  [HighSchool.Nicaragua]: "Nicaragua",
  [HighSchool.Nimitz]: "Nimitz",
  [HighSchool.NinthGradeCenter]: "Ninth Grade Center",
  [HighSchool.Nixon]: "Nixon",
  [HighSchool.Nocona]: "Nocona",
  [HighSchool.Normangee]: "Normangee",
  [HighSchool.NorthCarolina]: "North Carolina",
  [HighSchool.NorthCrowley]: "North Crowley",
  [HighSchool.NorthCrowley9ThGrade]: "North Crowley 9Th Grade",
  [HighSchool.NorthDakota]: "North Dakota",
  [HighSchool.NorthForney]: "North Forney",
  [HighSchool.Northdalla]: "Northdalla",
  [HighSchool.Northhopki]: "Northhopki",
  [HighSchool.Northlamar]: "Northlamar",
  [HighSchool.Northmesqu]: "Northmesqu",
  [HighSchool.Northside]: "Northside",
  [HighSchool.Northwest]: "Northwest",
  [HighSchool.NorthwestEarlyCollege]: "Northwest Early College",
  [HighSchool.NtreisTestOnly]: "Ntreis Test Only",
  [HighSchool.Nueces]: "Nueces",
  [HighSchool.Oakwood]: "Oakwood",
  [HighSchool.Oconnor]: "Oconnor",
  [HighSchool.Odessa]: "Odessa",
  [HighSchool.Oglesby]: "Oglesby",
  [HighSchool.Ohio]: "Ohio",
  [HighSchool.Oklahoma]: "Oklahoma",
  [HighSchool.Olfen]: "Olfen",
  [HighSchool.Olney]: "Olney",
  [HighSchool.Orecity]: "Orecity",
  [HighSchool.Oregon]: "Oregon",
  [HighSchool.Other]: "Other",
  [HighSchool.Overton]: "Overton",
  [HighSchool.Ozen]: "Ozen",
  [HighSchool.Ozona]: "Ozona",
  [HighSchool.Paducah]: "Paducah",
  [HighSchool.PaintCreek]: "Paint Creek",
  [HighSchool.PaintRock]: "Paint Rock",
  [HighSchool.Palacios]: "Palacios",
  [HighSchool.Palestine]: "Palestine",
  [HighSchool.Palmer]: "Palmer",
  [HighSchool.PaloDuro]: "Palo Duro",
  [HighSchool.Pampa]: "Pampa",
  [HighSchool.Panama]: "Panama",
  [HighSchool.Panhandle]: "Panhandle",
  [HighSchool.PantherCreek]: "Panther Creek",
  [HighSchool.Paradise]: "Paradise",
  [HighSchool.Paris]: "Paris",
  [HighSchool.Parkland]: "Parkland",
  [HighSchool.Paschal]: "Paschal",
  [HighSchool.Pathways]: "Pathways",
  [HighSchool.PattonSprings]: "Patton Springs",
  [HighSchool.Pearce]: "Pearce",
  [HighSchool.Pearland]: "Pearland",
  [HighSchool.Peaster]: "Peaster",
  [HighSchool.Penelope]: "Penelope",
  [HighSchool.Pennsylvania]: "Pennsylvania",
  [HighSchool.Perrin]: "Perrin",
  [HighSchool.PerrytonHighSchool]: "Perryton High School",
  [HighSchool.Peru]: "Peru",
  [HighSchool.Petrolia]: "Petrolia",
  [HighSchool.Pettus]: "Pettus",
  [HighSchool.Pflugervil]: "Pflugervil",
  [HighSchool.PilotPoint]: "Pilot Point",
  [HighSchool.Pinetree]: "Pinetree",
  [HighSchool.Pinkston]: "Pinkston",
  [HighSchool.Pittsburg]: "Pittsburg",
  [HighSchool.Plainview]: "Plainview",
  [HighSchool.PleasantGrove]: "Pleasant Grove",
  [HighSchool.Polytechni]: "Polytechni",
  [HighSchool.Ponder]: "Ponder",
  [HighSchool.Poolville]: "Poolville",
  [HighSchool.PortIsabel]: "Port Isabel",
  [HighSchool.PortNechesGroves]: "Port Neches-Groves",
  [HighSchool.Portaransa]: "Portaransa",
  [HighSchool.Post]: "Post",
  [HighSchool.Poteet]: "Poteet",
  [HighSchool.Pottsboro]: "Pottsboro",
  [HighSchool.PrairieLea]: "Prairie Lea",
  [HighSchool.PrairieVa]: "Prairie Va",
  [HighSchool.Prairiland]: "Prairiland",
  [HighSchool.Presidio]: "Presidio",
  [HighSchool.Priddy]: "Priddy",
  [HighSchool.Princeton]: "Princeton",
  [HighSchool.Prosper]: "Prosper",
  [HighSchool.PuertoRico]: "Puerto Rico",
  [HighSchool.Quanah]: "Quanah",
  [HighSchool.QueenCity]: "Queen City",
  [HighSchool.Quitman]: "Quitman",
  [HighSchool.Rains]: "Rains",
  [HighSchool.Ranchview]: "Ranchview",
  [HighSchool.Randall]: "Randall",
  [HighSchool.Randolph]: "Randolph",
  [HighSchool.Ranger]: "Ranger",
  [HighSchool.Ray]: "Ray",
  [HighSchool.RayBraswell]: "Ray Braswell",
  [HighSchool.Reagan]: "Reagan",
  [HighSchool.RedOak]: "Red Oak",
  [HighSchool.Redoak]: "Redoak",
  [HighSchool.Redwater]: "Redwater",
  [HighSchool.Reedy]: "Reedy",
  [HighSchool.Refugio]: "Refugio",
  [HighSchool.Rice]: "Rice",
  [HighSchool.Richards]: "Richards",
  [HighSchool.Richardson]: "Richardson",
  [HighSchool.Richarte]: "Richarte",
  [HighSchool.Richland]: "Richland",
  [HighSchool.Riesel]: "Riesel",
  [HighSchool.RioGrandeCity]: "Rio Grande City",
  [HighSchool.RioGrandeCityCisdGrulla]: "Rio Grande City Cisd Grulla",
  [HighSchool.RioHondo]: "Rio Hondo",
  [HighSchool.RioVista]: "Rio Vista",
  [HighSchool.RisingStar]: "Rising Star",
  [HighSchool.RiverRoad]: "River Road",
  [HighSchool.Rivercrest]: "Rivercrest",
  [HighSchool.Riverside]: "Riverside",
  [HighSchool.Robertlee]: "Robertlee",
  [HighSchool.Robinson]: "Robinson",
  [HighSchool.Robstown]: "Robstown",
  [HighSchool.Roby]: "Roby",
  [HighSchool.Rochelle]: "Rochelle",
  [HighSchool.Rochester]: "Rochester",
  [HighSchool.RockHill]: "Rock Hill",
  [HighSchool.Rockdale]: "Rockdale",
  [HighSchool.Rockportfu]: "Rockportfu",
  [HighSchool.Rocksprings]: "Rocksprings",
  [HighSchool.Rockwall]: "Rockwall",
  [HighSchool.Rogers]: "Rogers",
  [HighSchool.Roosevelt]: "Roosevelt",
  [HighSchool.Roscoe]: "Roscoe",
  [HighSchool.RosebudLott]: "Rosebud-Lott",
  [HighSchool.Rotan]: "Rotan",
  [HighSchool.Roundrock]: "Roundrock",
  [HighSchool.Roxton]: "Roxton",
  [HighSchool.RoyseCity]: "Royse City",
  [HighSchool.Rule]: "Rule",
  [HighSchool.Runge]: "Runge",
  [HighSchool.Rusk]: "Rusk",
  [HighSchool.RyanHS]: "Ryan H S",
  [HighSchool.SAndS]: "S And S",
  [HighSchool.Sabinal]: "Sabinal",
  [HighSchool.Sabine]: "Sabine",
  [HighSchool.Saginaw]: "Saginaw",
  [HighSchool.SaintJo]: "Saint Jo",
  [HighSchool.Salado]: "Salado",
  [HighSchool.Saltillo]: "Saltillo",
  [HighSchool.SamHouston]: "Sam Houston",
  [HighSchool.Samrayburn]: "Samrayburn",
  [HighSchool.SamuelClemens]: "Samuel Clemens",
  [HighSchool.Samuell]: "Samuell",
  [HighSchool.SanAugustine]: "San Augustine",
  [HighSchool.SanMarcus]: "San Marcus",
  [HighSchool.SanSaba]: "San Saba",
  [HighSchool.Sanelizaro]: "Sanelizaro",
  [HighSchool.Sanger]: "Sanger",
  [HighSchool.SantaAnna]: "Santa Anna",
  [HighSchool.Santo]: "Santo",
  [HighSchool.Saugustine]: "Saugustine",
  [HighSchool.Savoy]: "Savoy",
  [HighSchool.SchoolofChoice]: "School of Choice",
  [HighSchool.Scotland]: "Scotland",
  [HighSchool.Scurry]: "Scurry",
  [HighSchool.Seagoville]: "Seagoville",
  [HighSchool.Seguin]: "Seguin",
  [HighSchool.Serenity]: "Serenity",
  [HighSchool.SevenLakes]: "Seven Lakes",
  [HighSchool.Seymour]: "Seymour",
  [HighSchool.Sharyland]: "Sharyland",
  [HighSchool.Shepherd]: "Shepherd",
  [HighSchool.Shepton]: "Shepton",
  [HighSchool.Sherman]: "Sherman",
  [HighSchool.Shoemaker]: "Shoemaker",
  [HighSchool.Sidney]: "Sidney",
  [HighSchool.SierraBlanca]: "Sierra Blanca",
  [HighSchool.Silsbee]: "Silsbee",
  [HighSchool.Skyline]: "Skyline",
  [HighSchool.Slidell]: "Slidell",
  [HighSchool.Slocum]: "Slocum",
  [HighSchool.Smith]: "Smith",
  [HighSchool.Smithsonva]: "Smithsonva",
  [HighSchool.Snook]: "Snook",
  [HighSchool.Snyder]: "Snyder",
  [HighSchool.Socorro]: "Socorro",
  [HighSchool.Somerset]: "Somerset",
  [HighSchool.Somerville]: "Somerville",
  [HighSchool.Sonora]: "Sonora",
  [HighSchool.Sosananton]: "Sosananton",
  [HighSchool.SouthAfrica]: "South Africa",
  [HighSchool.SouthDakota]: "South Dakota",
  [HighSchool.SouthGrandPrairie]: "South Grand Prairie",
  [HighSchool.Southhills]: "Southhills",
  [HighSchool.Southoakcl]: "Southoakcl",
  [HighSchool.Southside]: "Southside",
  [HighSchool.Southwest]: "Southwest",
  [HighSchool.Spain]: "Spain",
  [HighSchool.Springhill]: "Springhill",
  [HighSchool.Springtown]: "Springtown",
  [HighSchool.Sprngwoods]: "Sprngwoods",
  [HighSchool.Spruce]: "Spruce",
  [HighSchool.Spur]: "Spur",
  [HighSchool.StLucia]: "St. Lucia",
  [HighSchool.StVincent]: "St. Vincent",
  [HighSchool.Stacey]: "Stacey",
  [HighSchool.Stafford]: "Stafford",
  [HighSchool.Stamford]: "Stamford",
  [HighSchool.StephenFAustin]: "Stephen F Austin",
  [HighSchool.Stephenvil]: "Stephenvil",
  [HighSchool.Stockdale]: "Stockdale",
  [HighSchool.StoneBurg]: "Stone Burg",
  [HighSchool.Stonypoint]: "Stonypoint",
  [HighSchool.Strawn]: "Strawn",
  [HighSchool.Sudan]: "Sudan",
  [HighSchool.Sulphurblu]: "Sulphurblu",
  [HighSchool.Sulphurspr]: "Sulphurspr",
  [HighSchool.Summit]: "Summit",
  [HighSchool.Sunnyvale]: "Sunnyvale",
  [HighSchool.Sunset]: "Sunset",
  [HighSchool.Sweetwater]: "Sweetwater",
  [HighSchool.Taft]: "Taft",
  [HighSchool.Tascosa]: "Tascosa",
  [HighSchool.Tatum]: "Tatum",
  [HighSchool.Taylor]: "Taylor",
  [HighSchool.Teague]: "Teague",
  [HighSchool.Temple]: "Temple",
  [HighSchool.Tennessee]: "Tennessee",
  [HighSchool.Terrell]: "Terrell",
  [HighSchool.Texas]: "Texas",
  [HighSchool.TheColony]: "The Colony",
  [HighSchool.ThePhilippines]: "The Philippines",
  [HighSchool.Thrall]: "Thrall",
  [HighSchool.Threeriver]: "Threeriver",
  [HighSchool.Throckmort]: "Throckmort",
  [HighSchool.Tidehaven]: "Tidehaven",
  [HighSchool.TimberCreek]: "Timber Creek",
  [HighSchool.Timberview]: "Timberview",
  [HighSchool.Timpson]: "Timpson",
  [HighSchool.Tioga]: "Tioga",
  [HighSchool.Tivy]: "Tivy",
  [HighSchool.Tolar]: "Tolar",
  [HighSchool.TomBean]: "Tom Bean",
  [HighSchool.Tornillo]: "Tornillo",
  [HighSchool.Trenton]: "Trenton",
  [HighSchool.Trimble]: "Trimble",
  [HighSchool.Trinidad]: "Trinidad",
  [HighSchool.Trinity]: "Trinity",
  [HighSchool.Troup]: "Troup",
  [HighSchool.Troy]: "Troy",
  [HighSchool.Tulia]: "Tulia",
  [HighSchool.Tulosomidw]: "Tulosomidw",
  [HighSchool.Turner]: "Turner",
  [HighSchool.Tyler]: "Tyler",
  [HighSchool.TylerLegacy]: "Tyler Legacy",
  [HighSchool.Uniongrove]: "Uniongrove",
  [HighSchool.Unionhill]: "Unionhill",
  [HighSchool.Uvalde]: "Uvalde",
  [HighSchool.Valley]: "Valley",
  [HighSchool.ValleyMil]: "Valley Mil",
  [HighSchool.Valleyview]: "Valleyview",
  [HighSchool.Van]: "Van",
  [HighSchool.VanAlstyne]: "Van Alstyne",
  [HighSchool.VanHorn]: "Van Horn",
  [HighSchool.Vanvleck]: "Vanvleck",
  [HighSchool.Vega]: "Vega",
  [HighSchool.Venus]: "Venus",
  [HighSchool.Veribest]: "Veribest",
  [HighSchool.Vernon]: "Vernon",
  [HighSchool.Vidor]: "Vidor",
  [HighSchool.Vines]: "Vines",
  [HighSchool.Virginia]: "Virginia",
  [HighSchool.Vistaridge]: "Vistaridge",
  [HighSchool.Waco]: "Waco",
  [HighSchool.Wakeland]: "Wakeland",
  [HighSchool.Walker]: "Walker",
  [HighSchool.WallaceAccelerated]: "Wallace Accelerated",
  [HighSchool.Waller]: "Waller",
  [HighSchool.WalnutGrove]: "Walnut Grove",
  [HighSchool.WalnutSp]: "Walnut Sp",
  [HighSchool.Waltrip]: "Waltrip",
  [HighSchool.Warren]: "Warren",
  [HighSchool.Washington]: "Washington",
  [HighSchool.Waskom]: "Waskom",
  [HighSchool.Waxahachie]: "Waxahachie",
  [HighSchool.WaxahachieNinthGradeAcademy]: "Waxahachie Ninth Grade Academy",
  [HighSchool.Weatherford]: "Weatherford",
  [HighSchool.Wellington]: "Wellington",
  [HighSchool.Wells]: "Wells",
  [HighSchool.Weslaco]: "Weslaco",
  [HighSchool.West]: "West",
  [HighSchool.WestPlainsHighSchool]: "West Plains High School",
  [HighSchool.WestSabine]: "West Sabine",
  [HighSchool.WestTexas]: "West Texas",
  [HighSchool.WestVirginia]: "West Virginia",
  [HighSchool.Westbrook]: "Westbrook",
  [HighSchool.Westlake]: "Westlake",
  [HighSchool.Westmesqui]: "Westmesqui",
  [HighSchool.WestnHill]: "Westn Hill",
  [HighSchool.Westoso]: "Westoso",
  [HighSchool.Westrusk]: "Westrusk",
  [HighSchool.Westwood]: "Westwood",
  [HighSchool.Wheeler]: "Wheeler",
  [HighSchool.White]: "White",
  [HighSchool.Whiteface]: "Whiteface",
  [HighSchool.Whitehouse]: "Whitehouse",
  [HighSchool.Whiteoak]: "Whiteoak",
  [HighSchool.Whitesboro]: "Whitesboro",
  [HighSchool.Whitewrigh]: "Whitewrigh",
  [HighSchool.Whitney]: "Whitney",
  [HighSchool.WichitaFalls]: "Wichita Falls",
  [HighSchool.Wildorado]: "Wildorado",
  [HighSchool.Williams]: "Williams",
  [HighSchool.Willis]: "Willis",
  [HighSchool.Willspoint]: "Willspoint",
  [HighSchool.Wilmerhutc]: "Wilmerhutc",
  [HighSchool.WoodrowWilson]: "Woodrow Wilson",
  [HighSchool.Wimberley]: "Wimberley",
  [HighSchool.Windthorst]: "Windthorst",
  [HighSchool.Winnsboro]: "Winnsboro",
  [HighSchool.Winona]: "Winona",
  [HighSchool.Winters]: "Winters",
  [HighSchool.Woden]: "Woden",
  [HighSchool.Wolfecity]: "Wolfecity",
  [HighSchool.Woodlands]: "Woodlands",
  [HighSchool.Woodsboro]: "Woodsboro",
  [HighSchool.Woodson]: "Woodson",
  [HighSchool.Woodville]: "Woodville",
  [HighSchool.Wortham]: "Wortham",
  [HighSchool.WyattOd]: "Wyatt Od",
  [HighSchool.Wylie]: "Wylie",
  [HighSchool.WylieEast]: "Wylie East",
  [HighSchool.Wyoming]: "Wyoming",
  [HighSchool.Yantis]: "Yantis",
  [HighSchool.Yermo]: "Yermo",
  [HighSchool.Yoakum]: "Yoakum",
  [HighSchool.Ysleta]: "Ysleta",
  [HighSchool.Zephyr]: "Zephyr"
};
