export enum Stories {
  One = "one",
  OnePointFive = "onePointFive",
  Two = "two",
  ThreePlus = "threePlus",
  MultiLevel = "multiLevel"
}

export const StoriesLabel = new Map<Stories, string>([
  [Stories.One, "1"],
  [Stories.OnePointFive, "1.5"],
  [Stories.Two, "2"],
  [Stories.ThreePlus, "3+"],
  [Stories.MultiLevel, "Multi-Level"]
]);
