export enum SecurityFeatures {
  CarbonMonoxideDetectors = "carbonMonoxideDetectors",
  FireAlarm = "fireAlarm",
  FireSprinklerSystem = "fireSprinklerSystem",
  GatedwithGuard = "gatedwithGuard",
  None = "none",
  Prewired = "prewired",
  SecuredGarageParking = "securedGarageParking",
  SecuritySystem = "securitySystem",
  SecuritySystemLeased = "securitySystemLeased",
  SecuritySystemOwned = "securitySystemOwned",
  SeeRemarks = "seeRemarks",
  SmokeDetectors = "smokeDetectors"
}
export const SecurityFeaturesLabel = new Map<SecurityFeatures, string>([
  [SecurityFeatures.CarbonMonoxideDetectors, "Carbon Monoxide Detector(s)"],
  [SecurityFeatures.FireAlarm, "Fire Alarm"],
  [SecurityFeatures.FireSprinklerSystem, "Fire Sprinkler System"],
  [SecurityFeatures.GatedwithGuard, "Gated with Guard"],
  [SecurityFeatures.None, "None"],
  [SecurityFeatures.Prewired, "Prewired"],
  [SecurityFeatures.SecuredGarageParking, "Secured Garage/Parking"],
  [SecurityFeatures.SecuritySystem, "Security System"],
  [SecurityFeatures.SecuritySystemLeased, "Security System Leased"],
  [SecurityFeatures.SecuritySystemOwned, "Security System Owned"],
  [SecurityFeatures.SeeRemarks, "See Remarks"],
  [SecurityFeatures.SmokeDetectors, "Smoke Detector(s)"]
]);
