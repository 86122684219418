import { Typography, styled } from "@mui/material";

const StatusStyled = styled(Typography)(({ theme }) => ({
  color: "white",
  borderRadius: theme.shape.borderRadius,
  padding: "3px 3px",
  lineHeight: "1em",
  fontsize: "11px",
  marginRight: theme.spacing(1)
}));

export { StatusStyled };
