export enum ProposedTerms {
  Cash = "cash",
  Conventional = "conventional",
  FHA = "fha",
  InvestorsOK = "investorsOK",
  Other = "other",
  TxVet = "txVet",
  USDA = "usda",
  VA = "va"
}
export const ProposedTermsLabel = new Map<ProposedTerms, string>([
  [ProposedTerms.Cash, "Cash"],
  [ProposedTerms.Conventional, "Conventional"],
  [ProposedTerms.FHA, "FHA"],
  [ProposedTerms.InvestorsOK, "Investors OK"],
  [ProposedTerms.Other, "Other"],
  [ProposedTerms.TxVet, "TX Vet"],
  [ProposedTerms.USDA, "USDA"],
  [ProposedTerms.VA, "VA"]
]);
