export enum RoomFeatures {
  BreakfastBar = "breakfastBar",
  BuiltInCabinets = "builtInCabinets",
  ButlersPantry = "butlersPantry",
  CedarClosets = "cedarClosets",
  ConcreteCountertop = "concreteCountertop",
  CorianTypeCountertop = "corianTypeCountertop",
  CustomClosetSystem = "customClosetSystem",
  DripDryArea = "dripDryArea",
  DualMasterBaths = "dualMasterBaths",
  DualSinks = "dualSinks",
  Dumbwaiter = "dumbwaiter",
  EatInKitchen = "eatInKitchen",
  EnsuiteBath = "ensuiteBath",
  FloorDrain = "floorDrain",
  GalleyKitchen = "galleyKitchen",
  GardenTub = "gardenTub",
  GraniteTypeCountertop = "graniteTypeCountertop",
  HollywoodBath = "hollywoodBath",
  JettedTub = "jettedTub",
  KitchenIsland = "kitchenIsland",
  LinenCloset = "linenCloset",
  MedicineCabinet = "medicineCabinet",
  NaturalStoneGraniteType = "naturalStoneGraniteType",
  Other = "other",
  Pantry = "pantry",
  PotFiller = "potFiller",
  RecycleBin = "recycleBin",
  RoomForFreezer = "roomForFreezer",
  SecondPantry = "secondPantry",
  SecondSink = "secondSink",
  SeparateShower = "separateShower",
  SeparateUtilityRoom = "separateUtilityRoom",
  SeparateVanities = "separateVanities",
  ShowerBodySprays = "showerBodySprays",
  SinkInUtility = "sinkInUtility",
  SittingAreaInMaster = "sittingAreaInMaster",
  SolidSurfaceNonNatural = "solidSurfaceNonNatural",
  SteamShower = "steamShower",
  TileCountertop = "tileCountertop",
  UtilityCloset = "utilityCloset",
  UtilityInKitchen = "utilityInKitchen",
  WalkInClosets = "walkInClosets",
  WalkInPantry = "walkInPantry"
}

export const RoomFeaturesLabel = new Map<RoomFeatures, string>([
  [RoomFeatures.BreakfastBar, "Breakfast Bar"],
  [RoomFeatures.BuiltInCabinets, "Built-in Cabinets"],
  [RoomFeatures.ButlersPantry, "Butlers Pantry"],
  [RoomFeatures.CedarClosets, "Cedar Closets"],
  [RoomFeatures.ConcreteCountertop, "Concrete Countertop"],
  [RoomFeatures.CorianTypeCountertop, "Corian/Corian Type Countertop"],
  [RoomFeatures.CustomClosetSystem, "Custom Closet System"],
  [RoomFeatures.DualMasterBaths, "Dual Master Baths"],
  [RoomFeatures.DripDryArea, "Drip/Dry Area"],
  [RoomFeatures.DualSinks, "Dual Sinks"],
  [RoomFeatures.Dumbwaiter, "Dumbwaiter"],
  [RoomFeatures.EatInKitchen, "Eat-in Kitchen"],
  [RoomFeatures.EnsuiteBath, "Ensuite Bath"],
  [RoomFeatures.FloorDrain, "Floor Drain"],
  [RoomFeatures.GalleyKitchen, "Galley Kitchen"],
  [RoomFeatures.GardenTub, "Garden Tub"],
  [RoomFeatures.GraniteTypeCountertop, "Granite/Granite Type Countertop"],
  [RoomFeatures.HollywoodBath, "Hollywood Bath"],
  [RoomFeatures.JettedTub, "Jetted Tub"],
  [RoomFeatures.KitchenIsland, "Kitchen Island"],
  [RoomFeatures.LinenCloset, "Linen Closet"],
  [RoomFeatures.MedicineCabinet, "Medicine Cabinet"],
  [RoomFeatures.NaturalStoneGraniteType, "Natural Stone/Granite Type"],
  [RoomFeatures.Other, "Other"],
  [RoomFeatures.Pantry, "Pantry"],
  [RoomFeatures.PotFiller, "Pot Filler"],
  [RoomFeatures.RecycleBin, "Recycle Bin"],
  [RoomFeatures.RoomForFreezer, "Room for Freezer"],
  [RoomFeatures.SecondPantry, "Second Pantry"],
  [RoomFeatures.SecondSink, "Second Sink"],
  [RoomFeatures.SeparateShower, "Separate Shower"],
  [RoomFeatures.SeparateUtilityRoom, "Separate Utility Room"],
  [RoomFeatures.SeparateVanities, "Separate Vanities"],
  [RoomFeatures.ShowerBodySprays, "Shower Body Sprays"],
  [RoomFeatures.SinkInUtility, "Sink in Utility"],
  [RoomFeatures.SittingAreaInMaster, "Sitting Area in Master"],
  [RoomFeatures.SolidSurfaceNonNatural, "Solid Surface/Non-Natural Type"],
  [RoomFeatures.SteamShower, "Steam Shower"],
  [RoomFeatures.TileCountertop, "Tile Countertop"],
  [RoomFeatures.UtilityCloset, "Utility Closet"],
  [RoomFeatures.UtilityInKitchen, "Utility in Kitchen"],
  [RoomFeatures.WalkInClosets, "Walk-in Closets"],
  [RoomFeatures.WalkInPantry, "Walk-in Pantry"]
]);

export const BreakfastRoomFeatures = [
  RoomFeatures.BreakfastBar,
  RoomFeatures.BuiltInCabinets,
  RoomFeatures.ButlersPantry,
  RoomFeatures.ConcreteCountertop,
  RoomFeatures.CorianTypeCountertop,
  RoomFeatures.EatInKitchen,
  RoomFeatures.GraniteTypeCountertop,
  RoomFeatures.KitchenIsland,
  RoomFeatures.TileCountertop
];
export const KitchenRoomFeatures = [
  RoomFeatures.BreakfastBar,
  RoomFeatures.BuiltInCabinets,
  RoomFeatures.ButlersPantry,
  RoomFeatures.ConcreteCountertop,
  RoomFeatures.CorianTypeCountertop,
  RoomFeatures.DualSinks,
  RoomFeatures.Dumbwaiter,
  RoomFeatures.EatInKitchen,
  RoomFeatures.GalleyKitchen,
  RoomFeatures.GraniteTypeCountertop,
  RoomFeatures.KitchenIsland,
  RoomFeatures.NaturalStoneGraniteType,
  RoomFeatures.Pantry,
  RoomFeatures.PotFiller,
  RoomFeatures.RecycleBin,
  RoomFeatures.RoomForFreezer,
  RoomFeatures.SecondPantry,
  RoomFeatures.SecondSink,
  RoomFeatures.SolidSurfaceNonNatural,
  RoomFeatures.TileCountertop,
  RoomFeatures.UtilityInKitchen,
  RoomFeatures.WalkInPantry
];
export const UtilityRoomFeatures = [
  RoomFeatures.BuiltInCabinets,
  RoomFeatures.DualSinks,
  RoomFeatures.DripDryArea,
  RoomFeatures.Dumbwaiter,
  RoomFeatures.FloorDrain,
  RoomFeatures.Other,
  RoomFeatures.RoomForFreezer,
  RoomFeatures.SecondSink,
  RoomFeatures.SeparateUtilityRoom,
  RoomFeatures.SinkInUtility,
  RoomFeatures.UtilityCloset
];
export const MasterBedroomFeatures = [
  RoomFeatures.CedarClosets,
  RoomFeatures.CustomClosetSystem,
  RoomFeatures.DualMasterBaths,
  RoomFeatures.DualSinks,
  RoomFeatures.EnsuiteBath,
  RoomFeatures.GardenTub,
  RoomFeatures.HollywoodBath,
  RoomFeatures.JettedTub,
  RoomFeatures.LinenCloset,
  RoomFeatures.MedicineCabinet,
  RoomFeatures.SeparateShower,
  RoomFeatures.SittingAreaInMaster,
  RoomFeatures.ShowerBodySprays,
  RoomFeatures.SeparateVanities,
  RoomFeatures.ShowerBodySprays,
  RoomFeatures.SteamShower,
  RoomFeatures.WalkInClosets
];
