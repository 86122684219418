import React from "react";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { SxProps } from "@mui/system";
import { Search } from "@mui/icons-material";

import { GridTableContext } from "@homesusa/grid-table";

export const SearchBar = ({ sx }: { sx?: SxProps }): JSX.Element => {
  const [searchBy, setSearchBy] = React.useState<string>();
  const {
    instance: { gotoPage, setGlobalFilter }
  } = React.useContext(GridTableContext);

  return (
    <Box display={"flex"} gap={2} alignItems="flex-start">
      <TextField
        sx={sx}
        placeholder="Search"
        variant="standard"
        onChange={({ target }): void => setSearchBy(target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          )
        }}
      />
      <Button
        onClick={(): void => {
          gotoPage(0);
          setGlobalFilter(searchBy !== "" ? searchBy : undefined);
        }}
      >
        Search
      </Button>
    </Box>
  );
};
