export enum ExteriorFeatures {
  Block = "block",
  Brick = "brick",
  CementBoard = "cementBoard",
  FiberBoard = "fiberBoard",
  Log = "log",
  Metal = "metal",
  Slate = "slate",
  Stone = "stone",
  Stucco = "stucco",
  StuccoSynthetic = "stuccoSynthetic",
  Vinyl = "vinyl",
  VinylSiding = "vinylSiding",
  Wood = "wood"
}

export const ExteriorFeaturesLabel = new Map<ExteriorFeatures, string>([
  [ExteriorFeatures.Block, "Block"],
  [ExteriorFeatures.Brick, "Brick"],
  [ExteriorFeatures.CementBoard, "Cement Board"],
  [ExteriorFeatures.FiberBoard, "Fiber Board"],
  [ExteriorFeatures.Log, "Log"],
  [ExteriorFeatures.Metal, "Metal"],
  [ExteriorFeatures.Slate, "Slate"],
  [ExteriorFeatures.Stone, "Stone"],
  [ExteriorFeatures.Stucco, "Stucco"],
  [ExteriorFeatures.StuccoSynthetic, "Stucco Synthetic"],
  [ExteriorFeatures.Vinyl, "Vinyl"],
  [ExteriorFeatures.VinylSiding, "Vinyl Siding"],
  [ExteriorFeatures.Wood, "Wood"]
]);
