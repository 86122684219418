export enum AppEndpoint {
  DashboardAlerts = "alerts",
  SaleListings = "sale-listings",
  LotListings = "lot-listings",
  Communities = "communities",
  Plans = "plans",
  SaleListingRequests = "sale-requests",
  LotListingRequests = "lot-requests",
  Reports = "reports"
}

export enum ListingRouterPath {
  Grid = "/",
  Details = "/:id",
  ImportNewMedia = "/:id/import-new-media",
  ImportMedia = "/:id/import-media",
  ImporMediaFromMarket = "/:id/import-from-market"
}

export enum RouterPath {
  DashboardAlerts = "/alerts",
  LotListingGrid = "/lot-listings",
  ListingSaleGrid = "/sale-listings",
  ListingSaleDetails = "/sale-listings/:id",
  ListingLotDetails = "/lot-listings/:id",
  ImportNewMedia = "/sale-listings/:id/import-new-media",
  ImportMedia = "/sale-listings/:id/import-media",
  ImporMediaFromMarket = "/sale-listings/:id/import-from-market",
  CommunityGrid = "/communities",
  CommunityDetails = "/communities/:id",
  CommunityHistoryDetails = "/communities/community-saved-changes/:id",
  PlanGrid = "/plans",
  PlanDetails = "/plans/:id",
  ListingSaleRequestGrid = "/sale-requests",
  ListingSaleRequestDetails = "/sale-requests/:id",
  ListingLotRequestGrid = "/lot-requests",
  ListingLotRequestDetails = "/lot-requests/:id",
  Reports = "/reports"
}
