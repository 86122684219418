import React from "react";
import { Box } from "@mui/material";

import { FormContext, FormMode, getOptionValueFromRule } from "@homesusa/form";
import { Format, MarketCode } from "@homesusa/core";
import {
  GridTable,
  GridProvider,
  GridToolbar,
  Columns
} from "@homesusa/grid-table";
import { ReportsContext } from "../contexts";
import { Listing } from "../interfaces";
import { ListType, ReportStatus, SellerTypeLabel } from "../enums";
import { useGetListings } from "../hooks";
import { Filters } from "./filters.component";
import { ReportDates } from "./report-dates.component";
import { AuthCompanyContext } from "@homesusa/auth";

export function CmaListingsReport(): JSX.Element {
  const { formState, formDispatch, fetchData, fetchDataForDownloading, data } =
    useGetListings();
  const { marketRules } = React.useContext(ReportsContext);
  const { currentMarket } = React.useContext(AuthCompanyContext);

  React.useEffect(() => {
    formDispatch({
      type: "FormChangeByInputName",
      inputName: "status",
      payload: ReportStatus.Cma
    });
  }, []);

  const columns: Columns<Listing> = React.useMemo(
    () => [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1
      },
      {
        Header: "MLS #",
        accessor: "mlsNumber"
      },
      {
        Header: "Address",
        accessor: "address"
      },
      {
        Header: "Unit #",
        accessor: "unitNumber"
      },
      {
        Header: "City",
        accessor: (data: Listing): string =>
          getOptionValueFromRule(marketRules.city, data.city),
        id: "city"
      },
      {
        Header: "Zip",
        accessor: "zip"
      },
      {
        Header: "Subdivision",
        accessor: "subdivision"
      },
      {
        Header: "Acres",
        accessor: "lotSize"
      },
      {
        Header: "Year Built",
        accessor: "yearBuilt"
      },
      {
        Header: "New/Resale",
        accessor: (data: Listing): string =>
          data.isNewHome ? "New" : "Resale",
        id: "isNewHome"
      },
      {
        Header: "B/B/B/G",
        headertooltip: "Bed / Bath / Half Bath / Garage",
        accessor: (data: Listing): string =>
          `${data.bedrooms ?? 0}/${data.fullBath ?? 0}/${data.halfBath ?? 0}/${
            data.garageSpaces ?? 0
          }`,
        id: "bedrooms"
      },

      {
        Header: "Pool",
        accessor: (data: Listing): string => (data.hasPrivatePool ? "Y" : "N"),
        id: "hasPrivatePool"
      },
      {
        Header: "List Price",
        accessor: (data: Listing): string => Format.Money(data.listPrice),
        id: "listPrice"
      },
      {
        Header: "Sale Price",
        accessor: (data: Listing): string => Format.Money(data.closePrice),
        id: "closePrice"
      },
      {
        Header: "SP $ / SF",
        accessor: (data: Listing): string => Format.Money(data.soldSqft),
        id: "soldSqft"
      },
      {
        Header: "SQFT",
        accessor: "sqFt"
      },
      {
        Header: "Sold Date",
        accessor: (data: Listing): string => Format.Date(data.closeDate),
        id: "closeDate"
      },
      {
        Header: "CDOM",
        accessor: "cdom"
      },
      {
        Header: "Owner",
        accessor: "ownerName"
      },
      ...(currentMarket === MarketCode.CTX
        ? []
        : [
            {
              Header: "Occup",
              accessor: (data: Listing): string =>
                marketRules.occupancy
                  ? getOptionValueFromRule(
                      marketRules.occupancy,
                      data.occupancy
                    )
                  : data.occupancy,
              id: "occupancy"
            }
          ]),
      {
        Header: "Seller Type",
        accessor: (data: Listing): string =>
          SellerTypeLabel.get(data.sellerType) ?? data.sellerType,
        id: "sellerType"
      }
    ],
    [currentMarket]
  );

  return (
    <FormContext.Provider
      value={{
        formState: formState,
        formDispatch,
        formMode: FormMode.Update,
        formRules: {},
        otherProps: {}
      }}
    >
      <GridProvider
        options={{
          columns,
          data: data?.data,
          showHeaderTooltip: true
        }}
        totalRows={data?.total}
      >
        <Filters onFetch={fetchData}>
          <Filters.ListType typeAvailable={[ListType.Residential]} />
          <Filters.Price />
          <Filters.Address />
          <Filters.Subdivision />
        </Filters>
        <GridToolbar>
          <GridToolbar.ExportButtons
            onClick={fetchDataForDownloading}
            fileName="cma-report"
          />
          <GridToolbar.SearchFilter />
        </GridToolbar>
        <Box mt={2} />
        <GridTable />
        <ReportDates data={data} />
      </GridProvider>
    </FormContext.Provider>
  );
}
