export enum SecurityFeatures {
  Burglar = "burglar",
  CarbonMonoxideDetector = "carbonMonoxideDetector",
  FireSprinklerSystem = "fireSprinklerSystem",
  Firewalls = "firewalls",
  GatedCommunity = "gatedCommunity",
  PreWired = "preWired",
  SecurityGuard = "securityGuard",
  SecurityLights = "securityLights",
  SecurityService = "securityService",
  SecuritySystem = "securitySystem",
  SecuritySystemLeased = "securitySystemLeased",
  SecuritySystemOwned = "securitySystemOwned",
  SmokeDetector = "smokeDetector",
  Unknown = "unknown",
  Wireless = "wireless",
  Other = "other"
}

export const SecurityFeaturesLabel = new Map<SecurityFeatures, string>([
  [SecurityFeatures.Burglar, "Burglar"],
  [SecurityFeatures.CarbonMonoxideDetector, "Carbon Monoxide Detector"],
  [SecurityFeatures.FireSprinklerSystem, "Fire Sprinkler System"],
  [SecurityFeatures.Firewalls, "Firewall(s)"],
  [SecurityFeatures.GatedCommunity, "Gated Community"],
  [SecurityFeatures.PreWired, "Pre-Wired"],
  [SecurityFeatures.SecurityGuard, "Security Guard"],
  [SecurityFeatures.SecurityLights, "Security Lights"],
  [SecurityFeatures.SecurityService, "Security Service"],
  [SecurityFeatures.SecuritySystem, "Security System"],
  [SecurityFeatures.SecuritySystemLeased, "Security System Leased"],
  [SecurityFeatures.SecuritySystemOwned, "Security System Owned"],
  [SecurityFeatures.SmokeDetector, "Smoke Detector"],
  [SecurityFeatures.Unknown, "Unknown"],
  [SecurityFeatures.Wireless, "Wireless"],
  [SecurityFeatures.Other, "Other"]
]);
