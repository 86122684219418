export enum Fencing {
  BackYard = "backYard",
  Block = "block",
  Brick = "brick",
  Electric = "electric",
  Fenced = "fenced",
  FrontYard = "frontYard",
  Full = "full",
  Gate = "gate",
  HighFence = "highFence",
  Metal = "metal",
  None = "non",
  Other = "other",
  RockStone = "rockStone",
  Security = "security",
  Vinyl = "vinyl",
  Wood = "wood",
  PartiallyFenced = "partiallyFenced",
  WroughtIron = "wroughtIron"
}

export const FencingLabel = new Map<Fencing, string>([
  [Fencing.BackYard, "Back Yard"],
  [Fencing.Block, "Block"],
  [Fencing.Brick, "Brick"],
  [Fencing.Electric, "Electric"],
  [Fencing.Fenced, "Fenced"],
  [Fencing.FrontYard, "Front Yard"],
  [Fencing.Full, "Full"],
  [Fencing.Gate, "Gate"],
  [Fencing.HighFence, "High Fence"],
  [Fencing.Metal, "Metal"],
  [Fencing.None, "None"],
  [Fencing.Other, "Other"],
  [Fencing.RockStone, "Rock/Stone"],
  [Fencing.Security, "Security"],
  [Fencing.Vinyl, "Vinyl"],
  [Fencing.Wood, "Wood"],
  [Fencing.PartiallyFenced, "Partially Fenced"],
  [Fencing.WroughtIron, "Wrought Iron"]
]);
