export enum Foundation {
  PierAndBeam = "pierAndBeam",
  OnStilts = "onStilts",
  Other = "other",
  Slab = "slab",
  BlockAndBeam = "blockAndBeam",
  SlabonBuildersPier = "slabonBuildersPier"
}

export const FoundationLabel = new Map<Foundation, string>([
  [Foundation.PierAndBeam, "Pier And Beam"],
  [Foundation.OnStilts, "On Stilts"],
  [Foundation.Other, "Other"],
  [Foundation.Slab, "Slab"],
  [Foundation.BlockAndBeam, "Block And Beam"],
  [Foundation.SlabonBuildersPier, "Slab on Builder's Pier"]
]);
