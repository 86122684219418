import React from "react";

import {
  FieldErrorProps,
  useFieldSuggestions,
  FormContext
} from "@homesusa/form";

export const useFormSuggestions = (): (() => Promise<boolean>) => {
  const getFieldSuggestions = useFieldSuggestions();
  const { formState, formWarningsDispatch, formWarnings, formRules } =
    React.useContext(FormContext);

  const getPropertySuggestions = (
    propertyName: string,
    value: unknown
  ): FieldErrorProps | null => {
    const rules = formRules[propertyName];
    if (!rules) {
      return null;
    }
    const fieldErrors = getFieldSuggestions(rules, value);

    return fieldErrors.length > 0
      ? {
          errors: fieldErrors,
          id: rules.id ?? propertyName,
          label: rules.label
        }
      : null;
  };

  const getFormSuggestionsProps = (
    formValues: Record<string, unknown>,
    path: string
  ): string[] => {
    let localSuggestions: string[] = [];
    Object.entries(formValues).forEach(([key, value]) => {
      const inputPath = path !== "" ? `${path}.${key}` : key;
      if (value && typeof value == "object" && !(value instanceof Date)) {
        const suggestions = getFormSuggestionsProps(
          value as Record<string, unknown>,
          inputPath
        );
        localSuggestions = [...localSuggestions, ...suggestions];
      } else {
        const fieldSuggestion = getPropertySuggestions(key, value);
        if (!formWarningsDispatch || !fieldSuggestion) {
          return;
        }
        localSuggestions.push(inputPath);
        formWarningsDispatch({
          type: "Add",
          payload: fieldSuggestion,
          inputName: inputPath
        });
      }
    });
    return localSuggestions;
  };

  const validate = async (): Promise<boolean> => {
    if (formWarningsDispatch) {
      formWarningsDispatch({ type: "Clean" });
    }

    const localSuggestions = getFormSuggestionsProps(formState, "");
    return (
      (formWarnings != null && Object.keys(formWarnings).length > 0) ||
      localSuggestions.length > 0
    );
  };

  return validate;
};
