import { FormRules, flattenRules } from "@homesusa/form";
import { MlsStatus } from "markets/abor/enums";
import { rulesByStatus } from "./status";
import { listingRules } from "./listing";
import {
  featuresInfoRules,
  financialInfoRules,
  lotAddressInfoRules,
  propertyInfoRules,
  showingInfoRules
} from "./lot-properties";
import { schoolsRules } from "../sections";

const lotPropertyInfoRules: FormRules = {
  ...propertyInfoRules,
  builderRestrictions: {
    label: "Builder Restrictions",
    requiredToSubmit: true
  },
  alsoListedAs: {
    label: "Also Listed As (enter ML#)",
    maxLength: 25
  }
};

const rules = {
  ...listingRules,
  listPrice: {
    ...listingRules.listPrice,
    numberFormat: {
      min: 5000,
      max: 3000000,
      prefix: "$",
      thousandSeparator: true
    }
  },
  addressInfo: lotAddressInfoRules,
  showingInfo: showingInfoRules,
  propertyInfo: lotPropertyInfoRules,
  featuresInfo: featuresInfoRules,
  financialInfo: financialInfoRules,
  schoolsInfo: schoolsRules
};

export const lotListingRules: FormRules = flattenRules(rules);

export const lotListingRulesWithStatus = (mlsStatus: MlsStatus): FormRules =>
  flattenRules({
    ...rules,
    statusFieldsInfo: rulesByStatus.get(mlsStatus)
  });
