import { FieldErrorProps } from "@homesusa/form";
import { isBefore } from "date-fns";
import { getDate } from "@homesusa/core";

export const validateBonusExpirationDate = (
  addOrRemoveError: (
    inputName: string,
    payload?: FieldErrorProps | null | undefined
  ) => void,
  name: string,
  bonusExpirationDate?: Date,
  hasBonusWithAmount?: boolean
): boolean => {
  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);
  const errorMessage: string[] = [];

  const parsedBonusExpirationDate = getDate(bonusExpirationDate);

  if (hasBonusWithAmount && !parsedBonusExpirationDate) {
    errorMessage.push("is required.");
  } else if (
    parsedBonusExpirationDate &&
    isBefore(parsedBonusExpirationDate, minDate)
  ) {
    errorMessage.push("Must be on or after today.");
  }
  addOrRemoveError(name, {
    label: "Concession Expiration Date",
    errors: errorMessage
  });
  return errorMessage.length === 0;
};
