export enum PatioAndPorchFeatures {
  Arbor = "arbor",
  Awnings = "awnings",
  Covered = "covered",
  Deck = "deck",
  Enclosed = "enclosed",
  FrontPorch = "frontPorch",
  GlassEnclosed = "glassEnclosed",
  MosquitoSystem = "mosquitoSystem",
  None = "none",
  Patio = "patio",
  Porch = "porch",
  RearPorch = "rearPorch",
  Screened = "screened",
  SeeRemarks = "seeRemarks",
  SidePorch = "sidePorch",
  Terrace = "terrace",
  WrapAround = "wrapAround"
}
export const PatioAndPorchFeaturesLabel = new Map<
  PatioAndPorchFeatures,
  string
>([
  [PatioAndPorchFeatures.Arbor, "Arbor"],
  [PatioAndPorchFeatures.Awnings, "Awning(s)"],
  [PatioAndPorchFeatures.Covered, "Covered"],
  [PatioAndPorchFeatures.Deck, "Deck"],
  [PatioAndPorchFeatures.Enclosed, "Enclosed"],
  [PatioAndPorchFeatures.FrontPorch, "Front Porch"],
  [PatioAndPorchFeatures.GlassEnclosed, "Glass Enclosed"],
  [PatioAndPorchFeatures.MosquitoSystem, "Mosquito System"],
  [PatioAndPorchFeatures.None, "None"],
  [PatioAndPorchFeatures.Patio, "Patio"],
  [PatioAndPorchFeatures.Porch, "Porch"],
  [PatioAndPorchFeatures.RearPorch, "Rear Porch"],
  [PatioAndPorchFeatures.Screened, "Screened"],
  [PatioAndPorchFeatures.SeeRemarks, "See Remarks"],
  [PatioAndPorchFeatures.SidePorch, "Side Porch"],
  [PatioAndPorchFeatures.Terrace, "Terrace"],
  [PatioAndPorchFeatures.WrapAround, "Wrap Around"]
]);
