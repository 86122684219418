import React from "react";
import { Grid } from "@mui/material";

import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { Select } from "@homesusa/form";
import { AgentType, HomesUSAType, HomesUSATypeLabel } from "../enums";
import { useAgentListingsGridColumns } from "../hooks/grid/use-agent-report-columns";
import { AgentsReport } from "./agents-report.component";

function HomesUSATypeFilter(): JSX.Element {
  return (
    <Grid item xs={4} sm={3} md={2}>
      <Select
        label="Filter by"
        name="homesUSAType"
        options={getOptionsFromEnumAndMap(
          [
            HomesUSAType.IsInHomesUSA,
            HomesUSAType.NotInHomesUSA
          ] as HomesUSAType[],
          HomesUSATypeLabel
        )}
        fullWidth
        removeBlankOption
      />
    </Grid>
  );
}

export function InternalSellingAgentsReport(): JSX.Element {
  const columns = useAgentListingsGridColumns(AgentType.SellingInternal);

  return (
    <AgentsReport
      columns={columns}
      agentType={AgentType.Selling}
      homesUSAType={HomesUSAType.IsInHomesUSA}
    >
      <HomesUSATypeFilter />
    </AgentsReport>
  );
}
