export enum ConstructionMaterial {
  Brick = "brick",
  Aluminum = "aluminum",
  Vinyl = "vinyl",
  Stone = "stone",
  Other = "other",
  SyntheticStucco = "syntheticStucco",
  Unknown = "unknown",
  CementBoard = "cementBoard",
  Wood = "wood",
  Stucco = "stucco"
}

export const ConstructionMaterialLabel = new Map<ConstructionMaterial, string>([
  [ConstructionMaterial.Brick, "Brick"],
  [ConstructionMaterial.Aluminum, "Aluminum"],
  [ConstructionMaterial.Vinyl, "Vinyl"],
  [ConstructionMaterial.Stone, "Stone"],
  [ConstructionMaterial.Other, "Other"],
  [ConstructionMaterial.SyntheticStucco, "Synthetic Stucco"],
  [ConstructionMaterial.Unknown, "Unknown"],
  [ConstructionMaterial.CementBoard, "Cement Board"],
  [ConstructionMaterial.Wood, "Wood"],
  [ConstructionMaterial.Stucco, "Stucco"]
]);
