import { MarketCode } from "@homesusa/core";

export const GridMarketCodeLabel = new Map<MarketCode, string>([
  [MarketCode.CTX, "CTX"],
  [MarketCode.DFW, "DFW"],
  [MarketCode.Sabor, "SAN"],
  [MarketCode.Austin, "AUS"],
  [MarketCode.Houston, "HOU"],
  [MarketCode.Amarillo, "AMA"]
]);
