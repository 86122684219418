import {
  HarCities,
  HarCitiesLabel,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { County, CountyLabel } from "markets/har/enums";

export const getHarCityLabel = (city: string | HarCities): string =>
  HarCitiesLabel[city as HarCities] ?? city;

export const harCityRule: FieldRulesProps = {
  label: "City",
  options: getOptionsFromEnumAndRecord(Object.values(HarCities), HarCitiesLabel)
};

export const countyRules: FieldRulesProps = {
  label: "County",
  lastOptions: [County.Other],
  options: getOptionsFromEnumAndRecord(Object.values(County), CountyLabel),
  required: true
};
