export enum SpecialFeatures {
  AirFilterSystem = "airFilterSystem",
  Balcony = "balcony",
  CanyonView = "canyonView",
  CeilingFan = "ceilingFan",
  CentralVacuum = "centralVacuum",
  CornerLot = "cornerLot",
  Courtyard = "courtyard",
  CulDeSac = "culDeSac",
  Deck = "deck",
  DogRun = "dogRun",
  Elevator = "elevator",
  Fountain = "fountain",
  GarageDoorOpener = "garageDoorOpener",
  GolfCourseFairway = "golfCourseFairway",
  HandicapAccess = "handicapAccess",
  HistoricalRegistry = "historicalRegistry",
  HotTub = "hotTub",
  Humidifier = "humidifier",
  Intercom = "intercom",
  LakeFront = "lakeFront",
  OutdoorKitchen = "outdoorKitchen",
  PlayEquipment = "playEquipment",
  PuttingGreen = "puttingGreen",
  SecuritySystem = "securitySystem",
  Skylights = "skylights",
  SprinklersIrrigation = "sprinklersIrrigation",
  StormWindows = "stormWindows",
  WaterfallPond = "waterfallPond"
}

export const SpecialFeaturesLabel: Record<SpecialFeatures, string> = {
  [SpecialFeatures.AirFilterSystem]: "Air Filter System",
  [SpecialFeatures.Balcony]: "Balcony ",
  [SpecialFeatures.CanyonView]: "Canyon View ",
  [SpecialFeatures.CeilingFan]: "Ceiling Fan",
  [SpecialFeatures.CentralVacuum]: "Central Vacuum",
  [SpecialFeatures.CornerLot]: "Corner Lot",
  [SpecialFeatures.Courtyard]: "Courtyard ",
  [SpecialFeatures.CulDeSac]: "Cul-De-Sac ",
  [SpecialFeatures.Deck]: "Deck",
  [SpecialFeatures.DogRun]: "Dog Run ",
  [SpecialFeatures.Elevator]: "Elevator ",
  [SpecialFeatures.Fountain]: "Fountain ",
  [SpecialFeatures.GarageDoorOpener]: "Garage Door Opener",
  [SpecialFeatures.GolfCourseFairway]: "Golf Course Fairway ",
  [SpecialFeatures.HandicapAccess]: "Handicap Access",
  [SpecialFeatures.HistoricalRegistry]: "Historical Registry ",
  [SpecialFeatures.HotTub]: "Hot Tub",
  [SpecialFeatures.Humidifier]: "Humidifier",
  [SpecialFeatures.Intercom]: "Intercom",
  [SpecialFeatures.LakeFront]: "Lake Front",
  [SpecialFeatures.OutdoorKitchen]: "Outdoor Kitchen ",
  [SpecialFeatures.PlayEquipment]: "Play Equipment ",
  [SpecialFeatures.PuttingGreen]: "Putting Green ",
  [SpecialFeatures.SecuritySystem]: "Security System",
  [SpecialFeatures.Skylights]: "Skylights",
  [SpecialFeatures.SprinklersIrrigation]: "Sprinklers/Irrigation",
  [SpecialFeatures.StormWindows]: "Storm Windows",
  [SpecialFeatures.WaterfallPond]: "Waterfall/Pond "
};
