export enum RoomType {
  Entry = "entry",
  Living = "living",
  Family = "family",
  Other = "other",
  Game = "game",
  Media = "media",
  Dining = "dining",
  Breakfast = "breakfast",
  Kitchen = "kitchen",
  Study = "study",
  MasterBedroom = "masterBedroom",
  SCMasterBedroom = "scMasterBedroom",
  MasterBedroomCloset = "masterBedroomCloset",
  Bed = "bed",
  MasterBath = "masterBath",
  Utility = "utility",
  FullBath = "fullBath",
  Office = "office",
  Student = "student",
  HalfBath = "halfBath"
}

export const RoomTypeLabel = new Map<RoomType, string>([
  [RoomType.Entry, "Entry"],
  [RoomType.Living, "Living"],
  [RoomType.Family, "Family"],
  [RoomType.Other, "Mud Room (Other)"],
  [RoomType.Game, "Game (Other)"],
  [RoomType.Media, "Media (Other)"],
  [RoomType.Dining, "Dining"],
  [RoomType.Breakfast, "Breakfast"],
  [RoomType.Kitchen, "Kitchen"],
  [RoomType.Study, "Study"],
  [RoomType.MasterBedroom, "Master Bedroom"],
  [RoomType.SCMasterBedroom, "Secondary Master Bedroom"],
  [RoomType.MasterBedroomCloset, "Master Bedroom Closet"],
  [RoomType.Bed, "Bedroom"],
  [RoomType.MasterBath, "Master Bath (Other)"],
  [RoomType.Utility, "Utility"],
  [RoomType.FullBath, "Full Bath"],
  [RoomType.Office, "Office"],
  [RoomType.Student, "Student"],
  [RoomType.HalfBath, "Half Bath"]
]);

export const RoomTypeQuantity = new Map<RoomType, number>([
  [RoomType.Entry, 1],
  [RoomType.Living, 1],
  [RoomType.Family, 1],
  [RoomType.Game, 1],
  [RoomType.Media, 1],
  [RoomType.Dining, 1],
  [RoomType.Breakfast, 1],
  [RoomType.Kitchen, 1],
  [RoomType.Study, 1],
  [RoomType.MasterBedroom, 1],
  [RoomType.MasterBedroomCloset, 1],
  [RoomType.SCMasterBedroom, 1],
  [RoomType.Bed, 6],
  [RoomType.MasterBath, 2],
  [RoomType.Utility, 2],
  [RoomType.FullBath, 9]
]);
