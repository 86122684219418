export enum TypeOfHomeAllowed {
  ApprovalRequired = "approvalRequired",
  Manufactured = "manufactured",
  Mobile = "mobile",
  Modular = "modular",
  SiteBuilt = "siteBuilt",
  SeeRemarks = "seeRemarks"
}
export const TypeOfHomeAllowedLabel = new Map<TypeOfHomeAllowed, string>([
  [TypeOfHomeAllowed.ApprovalRequired, "Approval Required"],
  [TypeOfHomeAllowed.Manufactured, "Manufactured"],
  [TypeOfHomeAllowed.Mobile, "Mobile"],
  [TypeOfHomeAllowed.Modular, "Modular"],
  [TypeOfHomeAllowed.SiteBuilt, "Site Built"],
  [TypeOfHomeAllowed.SeeRemarks, "See Remarks"]
]);
