export enum EnergyFeatures {
  Appliances = "appliances",
  Construction = "construction",
  Doors = "doors",
  ExposureShade = "exposureShade",
  HVAC = "hvac",
  Incentives = "incentives",
  Insulation = "insulation",
  Lighting = "lighting",
  Roof = "roof",
  Thermostat = "thermostat",
  WaterHeater = "waterHeater",
  Windows = "windows"
}

export const EnergyFeaturesLabel = new Map<EnergyFeatures, string>([
  [EnergyFeatures.Appliances, "Appliances"],
  [EnergyFeatures.Construction, "Construction"],
  [EnergyFeatures.Doors, "Doors"],
  [EnergyFeatures.ExposureShade, "Exposure/Shade"],
  [EnergyFeatures.HVAC, "HVAC"],
  [EnergyFeatures.Incentives, "Incentives"],
  [EnergyFeatures.Insulation, "Insulation"],
  [EnergyFeatures.Lighting, "Lighting"],
  [EnergyFeatures.Roof, "Roof"],
  [EnergyFeatures.Thermostat, "Thermostat"],
  [EnergyFeatures.WaterHeater, "Water Heater"],
  [EnergyFeatures.Windows, "Windows"]
]);
