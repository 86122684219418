import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  BathroomDescription,
  BathroomDescriptionLabel,
  BedroomDescription,
  BedroomDescriptionsLabel,
  KitchenDescription,
  KitchenDescriptionLabel,
  RoomDescription,
  RoomDescriptionLabel
} from "markets/har/enums";

export const roomsDescriptionRules: FormRules = {
  bedroomDescription: {
    label: "Bedroom Description",
    options: getOptionsFromEnumAndMap(
      Object.values(BedroomDescription),
      BedroomDescriptionsLabel
    ),
    required: false,
    rows: 2
  },
  roomDescription: {
    label: "Room Description",
    options: getOptionsFromEnumAndMap(
      Object.values(RoomDescription),
      RoomDescriptionLabel
    ),
    required: false,
    rows: 2
  },
  primaryBathDescription: {
    label: "Bathroom Description",
    options: getOptionsFromEnumAndMap(
      Object.values(BathroomDescription),
      BathroomDescriptionLabel
    ),
    required: false,
    rows: 2
  },
  kitchenDescription: {
    label: "Kitchen Description",
    options: getOptionsFromEnumAndMap(
      Object.values(KitchenDescription),
      KitchenDescriptionLabel
    ),
    required: false,
    rows: 2
  }
};
