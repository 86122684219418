import React from "react";

import { FormContext } from "@homesusa/form";
import { useRefresh } from "@homesusa/core";
import { AuthCompanyContext } from "@homesusa/auth";
import { constCompletionDateRules } from "./custom-rules";

export const useConstructionStageChange = ({
  completeStage,
  completionDatefieldName = "saleProperty.propertyInfo.constructionCompletionDate"
}: {
  completeStage: string;
  completionDatefieldName?: string;
}): {
  onChangeConstructionStage: (value?: string) => void;
  hideCompletionDate: boolean;
} => {
  const { formRulesDispatch, formErrorsDispatch, formDispatch } =
    React.useContext(FormContext);
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const [hideCompletionDate, refreshCompletionDate] = useRefresh();
  const onChange = (value?: string): void => {
    if (value == null && formErrorsDispatch && formDispatch) {
      formErrorsDispatch({
        type: "Remove",
        inputName: completionDatefieldName
      });
      formDispatch({
        type: "FormChangeByInputName",
        inputName: completionDatefieldName,
        payload: undefined
      });
    }
    if (formRulesDispatch) {
      formRulesDispatch({
        type: "UpdateByInputName",
        payload: constCompletionDateRules(completeStage, value, currentMarket),
        inputName: completionDatefieldName
      });
    }
    refreshCompletionDate();
  };

  return {
    onChangeConstructionStage: onChange,
    hideCompletionDate
  };
};
