import axios from "axios";

import { MarketCode } from "@homesusa/core";

import { ImportSource } from "core/enums";

import { ListType } from "../enums";
import { ImportListingActionsService as IImportListingActionsService } from "../interfaces/services";
import { getImportSourceUrl } from "./import-source.service";

const ImportListingActionsService = (
  market: MarketCode
): IImportListingActionsService => {
  const apiUrl = (importSource: ImportSource): string =>
    getImportSourceUrl(importSource, market);

  return {
    processListing: async (
      importSource: ImportSource,
      id: string,
      type: ListType
    ): Promise<string> => {
      const result: { data: string } = await axios.post(
        `${apiUrl(importSource)}/${id}`,
        {
          type
        }
      );
      return result.data;
    },

    listLater: async (
      importSource: ImportSource,
      id: string,
      listOn: Date
    ): Promise<void> => {
      await axios.patch(`${apiUrl(importSource)}/${id}`, listOn);
    },

    deleteListing: async (
      importSource: ImportSource,
      id: string
    ): Promise<void> => axios.delete(`${apiUrl(importSource)}/${id}`),

    restoreListing: async (
      importSource: ImportSource,
      id: string
    ): Promise<void> => axios.patch(`${apiUrl(importSource)}/${id}/restore`)
  };
};

export default ImportListingActionsService;
