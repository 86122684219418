export enum LaundryLocation {
  Kitchen = "kitchen",
  LaundryCloset = "laundryCloset",
  LaundryRoom = "laundryRoom",
  MainLevel = "mainLevel",
  UpperLevel = "upperLevel",
  Inside = "inside"
}
export const LaundryLocationLabel = new Map<LaundryLocation, string>([
  [LaundryLocation.Kitchen, "Kitchen"],
  [LaundryLocation.LaundryCloset, "Laundry Closet"],
  [LaundryLocation.LaundryRoom, "Laundry Room"],
  [LaundryLocation.MainLevel, "Main Level"],
  [LaundryLocation.UpperLevel, "Upper Level"],
  [LaundryLocation.Inside, "Inside"]
]);
