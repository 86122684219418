export enum ElementarySchool {
  NotApplicable = "notApplicable",
  ElementarySchool = "elementarySchool",
  Adams = "adams",
  AdamsHill = "adamsHill",
  Alkek = "alkek",
  AlpineElementary = "alpineElementary",
  Alvarez = "alvarez",
  AndersonElementary = "andersonElementary",
  AndyMireles = "andyMireles",
  Anthon = "anthon",
  ArlonSeay = "arlonSeay",
  ArmandoCerna = "armandoCerna",
  Arnold = "arnold",
  Atascosa = "atascosa",
  Athens = "athens",
  AueElementarySchool = "aueElementarySchool",
  Austin = "austin",
  Ball = "ball",
  Bandera = "bandera",
  Barkley = "barkley",
  Barrera = "barrera",
  Baskin = "baskin",
  Batesville = "batesville",
  BeaconHill = "beaconHill",
  Beard = "beard",
  BehlauElementary = "behlauElementary",
  Bellaire = "bellaire",
  BenavidesHeights = "benavidesHeights",
  Benavidez = "benavidez",
  BielfeldtElementary = "bielfeldtElementary",
  BigCountry = "bigCountry",
  BigWells = "bigWells",
  BillBrown = "billBrown",
  Blanco = "blanco",
  Blattman = "blattman",
  BlockHouseCreek = "blockHouseCreek",
  BobHope = "bobHope",
  BobLewisElementary = "bobLewisElementary",
  BobbyeBehlau = "bobbyeBehlau",
  Boerne = "boerne",
  Bonham = "bonham",
  BonnieEllison = "bonnieEllison",
  Boone = "boone",
  Bowden = "bowden",
  Bowen = "bowen",
  Bowie = "bowie",
  BrackenridgeJT = "brackenridgeJT",
  Brauchle = "brauchle",
  Braun = "braun",
  BraunStation = "braunStation",
  Brewer = "brewer",
  Briscoe = "briscoe",
  Brown = "brown",
  BuenaVista = "buenaVista",
  Bulverde = "bulverde",
  BulverdeCreek = "bulverdeCreek",
  BurkeElementary = "burkeElementary",
  Burleson = "burleson",
  Burnet = "burnet",
  ChiltonElementary = "chiltonElementary",
  CiboloCreek = "ciboloCreek",
  CiboloValley = "ciboloValley",
  CopperfieldEle = "copperfieldEle",
  CrystalCity = "crystalCity",
  Cable = "cable",
  CallDistrict = "callDistrict",
  Cambridge = "cambridge",
  Camelot = "camelot",
  Cameron = "cameron",
  Candlewood = "candlewood",
  CanyonLake = "canyonLake",
  CanyonRidgeElem = "canyonRidgeElem",
  CarlSchurzEle = "carlSchurzEle",
  CarlosCoonEle = "carlosCoonEle",
  Carlson = "carlson",
  Carnahan = "carnahan",
  Carrillo = "carrillo",
  CarrizoSprings = "carrizoSprings",
  CarrizoSpringsIntermediate = "carrizoSpringsIntermediate",
  CarrollBell = "carrollBell",
  CarrollHenry = "carrollHenry",
  Carson = "carson",
  Carvajal = "carvajal",
  Carvaljal = "carvaljal",
  CastleHills = "castleHills",
  CastrovilleElementary = "castrovilleElementary",
  Cenizo = "cenizo",
  CenizoPark = "cenizoPark",
  CenterPoint = "centerPoint",
  CenterPointPT = "centerPointPT",
  Channel = "channel",
  CharlesKuentz = "charlesKuentz",
  Charlotte = "charlotte",
  ChristianEvers = "christianEvers",
  Ciavarra = "ciavarra",
  CiboloGreen = "ciboloGreen",
  ClearSpring = "clearSpring",
  CodyEd = "codyEd",
  Coker = "coker",
  Colby = "colby",
  Cole = "cole",
  Collier = "collier",
  CollinsGarden = "collinsGarden",
  ColonialHills = "colonialHills",
  ColoniesNorth = "coloniesNorth",
  ColumbiaHeights = "columbiaHeights",
  Comal = "comal",
  Comfort = "comfort",
  Comstock = "comstock",
  Converse = "converse",
  CoronadoEscobar = "coronadoEscobar",
  CoronadoVillage = "coronadoVillage",
  Cotton = "cotton",
  Cotulla = "cotulla",
  CountyLine = "countyLine",
  CreekwoodPark = "creekwoodPark",
  Crestview = "crestview",
  Crockett = "crockett",
  Curington = "curington",
  DHanis = "dHanis",
  DrippingSpringsElementary = "drippingSpringsElementary",
  Dalton = "dalton",
  DeZavala = "deZavala",
  DelRio = "delRio",
  Dellview = "dellview",
  Derry = "derry",
  Devine = "devine",
  Dezavala = "dezavala",
  Dilley = "dilley",
  Divide = "divide",
  Douglass = "douglass",
  DrFerminCalderon = "drFerminCalderon",
  DrLonnieGreen = "drLonnieGreen",
  DrTomasRivera = "drTomasRivera",
  Driggers = "driggers",
  EastCentral = "eastCentral",
  EastElementary = "eastElementary",
  EastTerrellHillsEle = "eastTerrellHillsEle",
  EdFranz = "edFranz",
  EdmundLieck = "edmundLieck",
  ElDorado = "elDorado",
  ElmCreek = "elmCreek",
  EloiseJaphetAcademy = "eloiseJaphetAcademy",
  Elolf = "elolf",
  ElrodJimmy = "elrodJimmy",
  EmmaFrey = "emmaFrey",
  Encino = "encino",
  EncinoPark = "encinoPark",
  Erskine = "erskine",
  Escobar = "escobar",
  EscondidoElementary = "escondidoElementary",
  Esid = "esid",
  EsparzaGregorio = "esparzaGregorio",
  Evers = "evers",
  Fields = "fields",
  FlourBluff = "flourBluff",
  Fabra = "fabra",
  FairOaksRanch = "fairOaksRanch",
  FallCity = "fallCity",
  Fenwick = "fenwick",
  Fernandez = "fernandez",
  Fisher = "fisher",
  FivePal = "fivePal",
  FivePalms = "fivePalms",
  Flanders = "flanders",
  Flores = "flores",
  Floresville = "floresville",
  Forbes = "forbes",
  Forester = "forester",
  Foster = "foster",
  FoxRun = "foxRun",
  FrankMadla = "frankMadla",
  Franklin = "franklin",
  Franz = "franz",
  Fredericksburg = "fredericksburg",
  FreedomElementary = "freedomElementary",
  Freiheit = "freiheit",
  Freihert = "freihert",
  Frey = "frey",
  GatesAcademy = "gatesAcademy",
  GalenEloff = "galenEloff",
  Gallardo = "gallardo",
  Galm = "galm",
  GardenRidge = "gardenRidge",
  Gardendale = "gardendale",
  Garfield = "garfield",
  Garriga = "garriga",
  Gates = "gates",
  GeorgeWest = "georgeWest",
  Gilbert = "gilbert",
  Gillette = "gillette",
  GlassColby = "glassColby",
  GlennJohn = "glennJohn",
  Glenoaks = "glenoaks",
  Gonzales = "gonzales",
  GoodwinFrazier = "goodwinFrazier",
  Graebner = "graebner",
  Grandview = "grandview",
  Graves = "graves",
  Green = "green",
  GreenValley = "greenValley",
  Guerra = "guerra",
  HKWilliams = "hKWilliams",
  HerbertGBoldtEle = "herbertGBoldtEle",
  HoffMann = "hoffMann",
  HannaSpringsElementary = "hannaSpringsElementary",
  HardyOak = "hardyOak",
  Harmony = "harmony",
  HarmonyHills = "harmonyHills",
  HarmonySchoolOfInnovation = "harmonySchoolOfInnovation",
  HarperElementary = "harperElementary",
  HatchetEle = "hatchetEle",
  Hawthorne = "hawthorne",
  Helotes = "helotes",
  Henderson = "henderson",
  HenryBGonzalez = "henryBGonzalez",
  HenryGonzalez = "henryGonzalez",
  Herff = "herff",
  HernandezElementry = "hernandezElementry",
  HiddenCove = "hiddenCove",
  HiddenForest = "hiddenForest",
  HighlandForest = "highlandForest",
  HighlandHills = "highlandHills",
  HighlandPark = "highlandPark",
  HillCntry = "hillCntry",
  HillCountry = "hillCountry",
  Hillcrest = "hillcrest",
  Hirsch = "hirsch",
  Hoelscher = "hoelscher",
  HoffmanLane = "hoffmanLane",
  Hondo = "hondo",
  HopkinsEle = "hopkinsEle",
  Hopperzt = "hopperzt",
  Housman = "housman",
  Howard = "howard",
  Howsman = "howsman",
  Hudspech = "hudspech",
  Huebner = "huebner",
  HullMary = "hullMary",
  Hunt = "hunt",
  Huppertz = "huppertz",
  Hutchins = "hutchins",
  IndianCreek = "indianCreek",
  IndianSprings = "indianSprings",
  Ingram = "ingram",
  JERhodes = "jERhodes",
  JamesMastersElementary = "jamesMastersElementary",
  JacksonKeller = "jacksonKeller",
  JacobsWellElementarySchool = "jacobsWellElementarySchool",
  JamesMadison = "jamesMadison",
  Japhet = "japhet",
  Jefferson = "jefferson",
  JerryAllen = "jerryAllen",
  JimGMartin = "jimGMartin",
  JohnASippel = "johnASippel",
  JohnBarns = "johnBarns",
  JohnCFrenchElementary = "johnCFrenchElementary",
  JohnCFrenchCElementary = "johnCFrenchCElementary",
  JohnGlennJr = "johnGlennJr",
  JohnsonCity = "johnsonCity",
  JohnsonRanch = "johnsonRanch",
  Jones = "jones",
  JonesElementary = "jonesElementary",
  Jordan = "jordan",
  Jourdanton = "jourdanton",
  JulianCGallardoElementary = "julianCGallardoElementary",
  JulieNewtonAue = "julieNewtonAue",
  Junction = "junction",
  Kallison = "kallison",
  KatieReed = "katieReed",
  KarnesCityElem = "karnesCityElem",
  Kelly = "kelly",
  KendallElementary = "kendallElementary",
  Kenedy = "kenedy",
  Kerrville = "kerrville",
  KinderRanchElementary = "kinderRanchElementary",
  Kindred = "kindred",
  King = "king",
  Kirby = "kirby",
  Kirchner = "kirchner",
  KleinRoad = "kleinRoad",
  KlineWhitisElementary = "klineWhitisElementary",
  Knowlton = "knowlton",
  Knox = "knox",
  Koenneckee = "koenneckee",
  KriewaldRoad = "kriewaldRoad",
  Krueger = "krueger",
  LittleBayPortAransas = "littleBayPortAransas",
  LuckyRanch = "luckyRanch",
  LaPryor = "laPryor",
  LaSoya = "laSoya",
  LaVernia = "laVernia",
  LacklandCityAllen = "lacklandCityAllen",
  LacosteElementary = "lacosteElementary",
  Ladera = "ladera",
  LagoVista = "lagoVista",
  LakehillsElementary = "lakehillsElementary",
  Lamar = "lamar",
  LargoVista = "largoVista",
  Larkspur = "larkspur",
  LasLomas = "lasLomas",
  LasPalmas = "lasPalmas",
  Leakey = "leakey",
  LeonSprings = "leonSprings",
  LeonValley = "leonValley",
  Lewis = "lewis",
  Liberty = "liberty",
  LintonDolores = "lintonDolores",
  LiveOak = "liveOak",
  Llano = "llano",
  LockeHill = "lockeHill",
  Lockhill = "lockhill",
  LomaPark = "lomaPark",
  LoneStarElementary = "loneStarElementary",
  LongsCreek = "longsCreek",
  LorenzoDeZavala = "lorenzoDeZavala",
  LosReyes = "losReyes",
  LuckeyRanch = "luckeyRanch",
  Luling = "luling",
  Lumberton = "lumberton",
  Lynch = "lynch",
  LyndonBJohnson = "lyndonBJohnson",
  Lytle = "lytle",
  MillerAcademy = "millerAcademy",
  Madison = "madison",
  MageeElementary = "mageeElementary",
  MarbleFallsElem = "marbleFallsElem",
  Margil = "margil",
  Marion = "marion",
  MarthaMead = "marthaMead",
  MartinLutherKing = "martinLutherKing",
  MaryB = "maryB",
  MaryLouHartman = "maryLouHartman",
  MaryMichael = "maryMichael",
  MasonElem = "masonElem",
  MathewsElementary = "mathewsElementary",
  Mathis = "mathis",
  Maverick = "maverick",
  May = "may",
  McDermott = "mcDermott",
  Mcqueeney = "mcqueeney",
  Mead = "mead",
  MeadowVillage = "meadowVillage",
  Medina = "medina",
  MedinaValley = "medinaValley",
  MedioCreek = "medioCreek",
  Memorial = "memorial",
  MeyerElementary = "meyerElementary",
  Michael = "michael",
  Miller = "miller",
  MillersPoint = "millersPoint",
  MissionAcademy = "missionAcademy",
  MonroeMay = "monroeMay",
  Montgomery = "montgomery",
  Mora = "mora",
  Morningside = "morningside",
  Morrill = "morrill",
  MountainValley = "mountainValley",
  Murnin = "murnin",
  MyersVirginia = "myersVirginia",
  Natalia = "natalia",
  NavarroElementary = "navarroElementary",
  Neal = "neal",
  NeilArmstrong = "neilArmstrong",
  NellieMaeGlass = "nellieMaeGlass",
  Nelson = "nelson",
  NewBraunfel = "newBraunfel",
  Nichols = "nichols",
  Nimitz = "nimitz",
  NixonElementary = "nixonElementary",
  NoraForester = "noraForester",
  NormaJPaschal = "normaJPaschal",
  North = "north",
  NorthElementaryFloresville = "northElementaryFloresville",
  NorthHeights = "northHeights",
  NorthernHills = "northernHills",
  NorthwestCrossing = "northwestCrossing",
  Northwood = "northwood",
  NuecesCanyon = "nuecesCanyon",
  OakCreek = "oakCreek",
  OakCrestElementary = "oakCrestElementary",
  OakGrove = "oakGrove",
  OakHillsTerrace = "oakHillsTerrace",
  OakMeadow = "oakMeadow",
  Oakmead = "oakmead",
  Ogden = "ogden",
  OlivaresAntonio = "olivaresAntonio",
  Olmos = "olmos",
  Olsen = "olsen",
  Olympia = "olympia",
  Ott = "ott",
  PeggyCarnahan = "peggyCarnahan",
  PacksaddleElementry = "packsaddleElementry",
  PaloAlto = "paloAlto",
  ParkVillage = "parkVillage",
  Paschall = "paschall",
  Passmore = "passmore",
  Patlan = "patlan",
  Patrick = "patrick",
  PearceWilliam = "pearceWilliam",
  Pearsall = "pearsall",
  PecanValley = "pecanValley",
  Perales = "perales",
  PerfectoMancha = "perfectoMancha",
  Pershing = "pershing",
  PeteGallego = "peteGallego",
  Pfeiffer = "pfeiffer",
  Pleasanton = "pleasanton",
  Poteet = "poteet",
  Poth = "poth",
  Potranco = "potranco",
  PowellLawrence = "powellLawrence",
  Price = "price",
  Raba = "raba",
  RaheBulverdeElementary = "raheBulverdeElementary",
  RahePrimary = "rahePrimary",
  RailRidgeElementary = "railRidgeElementary",
  RalphLangley = "ralphLangley",
  RamirezBurksElementary = "ramirezBurksElementary",
  RayHDarr = "rayHDarr",
  Rayburn = "rayburn",
  RebeccaCreek = "rebeccaCreek",
  RedlandOaks = "redlandOaks",
  RegencyPlace = "regencyPlace",
  Rhodes = "rhodes",
  Ridgeview = "ridgeview",
  RiversidePark = "riversidePark",
  RoanForest = "roanForest",
  RoanOak = "roanOak",
  Robb = "robb",
  RobbElementary = "robbElementary",
  RobertELee = "robertELee",
  RobertoBobbyBarrera = "robertoBobbyBarrera",
  Rocksprings = "rocksprings",
  Rodriguez = "rodriguez",
  Rogers = "rogers",
  RogersESides = "rogersESides",
  RollingMeadows = "rollingMeadows",
  Roosevelt = "roosevelt",
  RoseGarden = "roseGarden",
  RositaValley = "rositaValley",
  RoyBenavidez = "royBenavidez",
  RoyCisneros = "royCisneros",
  RoyalRidge = "royalRidge",
  Royalgate = "royalgate",
  RubenChavira = "rubenChavira",
  Ruiz = "ruiz",
  Runge = "runge",
  Startzville = "startzville",
  Sabinal = "sabinal",
  Saisd = "saisd",
  Salinas = "salinas",
  SamHouston = "samHouston",
  SanLuis = "sanLuis",
  SandersonElem = "sandersonElem",
  SaraBMcAndrew = "saraBMcAndrew",
  Scarborough = "scarborough",
  Scheh = "scheh",
  Schenck = "schenck",
  Schertz = "schertz",
  Schulenburg = "schulenburg",
  Schulze = "schulze",
  Scobee = "scobee",
  Scudder = "scudder",
  ScudderPrimarySchool = "scudderPrimarySchool",
  Secht = "secht",
  SecoMines = "secoMines",
  Seele = "seele",
  Seely = "seely",
  Seguin = "seguin",
  Serna = "serna",
  Shenandoah = "shenandoah",
  Sinclair = "sinclair",
  SkyHarbor = "skyHarbor",
  Smith = "smith",
  SmithsonValley = "smithsonValley",
  Somerset = "somerset",
  SouthElementaryFloresville = "southElementaryFloresville",
  SouthsideHeritage = "southsideHeritage",
  Southwest = "southwest",
  Specht = "specht",
  SpicewoodPark = "spicewoodPark",
  SpringMeadows = "springMeadows",
  Stafford = "stafford",
  Stahl = "stahl",
  Starkey = "starkey",
  Starky = "starky",
  Steele = "steele",
  Steubing = "steubing",
  SteubingRanch = "steubingRanch",
  Stewart = "stewart",
  Stockdale = "stockdale",
  StoneOak = "stoneOak",
  StonewallElementary = "stonewallElementary",
  Storm = "storm",
  SueEvelynRattan = "sueEvelynRattan",
  Summerlin = "summerlin",
  SunValley = "sunValley",
  Tally = "tally",
  TaylorCreekElementary = "taylorCreekElementary",
  TerriCornerS = "terriCornerS",
  Thornton = "thornton",
  ThousandOaks = "thousandOaks",
  ThreeRivers = "threeRivers",
  ThunderbirdHills = "thunderbirdHills",
  TildenElementary = "tildenElementary",
  Timberwilde = "timberwilde",
  TimberwoodPark = "timberwoodPark",
  TomDan = "tomDan",
  TomDaniels = "tomDaniels",
  TomlinsonElementary = "tomlinsonElementary",
  Tradition = "tradition",
  Travis = "travis",
  Trivino = "trivino",
  TuscanyHeights = "tuscanyHeights",
  Tynan = "tynan",
  Utopia = "utopia",
  Uvalde = "uvalde",
  ValleyHi = "valleyHi",
  VanRaub = "vanRaub",
  Veramendi = "veramendi",
  Vestal = "vestal",
  Villarreal = "villarreal",
  VineyardRanch = "vineyardRanch",
  VirginaMeyers = "virginaMeyers",
  Vogel = "vogel",
  VogelElementary = "vogelElementary",
  VossFarms = "vossFarms",
  WalnutSpringsElementarySchool = "walnutSpringsElementarySchool",
  Walzem = "walzem",
  Wanke = "wanke",
  Ward = "ward",
  Washington = "washington",
  Watts = "watts",
  Weinert = "weinert",
  WernliElementarySchool = "wernliElementarySchool",
  WestAvenue = "westAvenue",
  WesthoffElementary = "westhoffElementary",
  WestwoodTerrace = "westwoodTerrace",
  WetmoreElementary = "wetmoreElementary",
  White = "white",
  Wiederstein = "wiederstein",
  WildernessOakElementary = "wildernessOakElementary",
  WilliamFBarnett = "williamFBarnett",
  Wilshire = "wilshire",
  Wilson = "wilson",
  Windcrest = "windcrest",
  Winston = "winston",
  WoodLandEle = "woodLandEle",
  WoodRiverElementary = "woodRiverElementary",
  Woodlake = "woodlake",
  Woodlawn = "woodlawn",
  WoodlawnHills = "woodlawnHills",
  Woodridge = "woodridge",
  Woodstone = "woodstone",
  WorthamOaks = "worthamOaks",
  Wright = "wright",
  YorktownElementary = "yorktownElementary"
}

export const ElementarySchoolLabel: Record<ElementarySchool, string> = {
  [ElementarySchool.NotApplicable]: "Not Applicable",
  [ElementarySchool.ElementarySchool]: "Elementary School",
  [ElementarySchool.Adams]: "Adams",
  [ElementarySchool.AdamsHill]: "Adams Hill",
  [ElementarySchool.Alkek]: "Alkek",
  [ElementarySchool.AlpineElementary]: "Alpine Elementary",
  [ElementarySchool.Alvarez]: "Alvarez",
  [ElementarySchool.AndersonElementary]: "Anderson Elementary",
  [ElementarySchool.AndyMireles]: "Andy Mireles",
  [ElementarySchool.Anthon]: "Anthon",
  [ElementarySchool.ArlonSeay]: "Arlon Seay",
  [ElementarySchool.ArmandoCerna]: "Armando Cerna",
  [ElementarySchool.Arnold]: "Arnold",
  [ElementarySchool.Atascosa]: "Atascosa",
  [ElementarySchool.Athens]: "Athens",
  [ElementarySchool.AueElementarySchool]: "Aue Elementary School",
  [ElementarySchool.Austin]: "Austin",
  [ElementarySchool.Ball]: "Ball",
  [ElementarySchool.Bandera]: "Bandera",
  [ElementarySchool.Barkley]: "Barkley",
  [ElementarySchool.Barrera]: "Barrera",
  [ElementarySchool.Baskin]: "Baskin",
  [ElementarySchool.Batesville]: "Batesville",
  [ElementarySchool.BeaconHill]: "Beacon Hill",
  [ElementarySchool.Beard]: "Beard",
  [ElementarySchool.BehlauElementary]: "Behlau Elementary",
  [ElementarySchool.Bellaire]: "Bellaire",
  [ElementarySchool.BenavidesHeights]: "Benavides Heights",
  [ElementarySchool.Benavidez]: "Benavidez",
  [ElementarySchool.BielfeldtElementary]: "Bielfeldt Elementary",
  [ElementarySchool.BigCountry]: "Big Country",
  [ElementarySchool.BigWells]: "Big Wells",
  [ElementarySchool.BillBrown]: "Bill Brown",
  [ElementarySchool.Blanco]: "Blanco",
  [ElementarySchool.Blattman]: "Blattman",
  [ElementarySchool.BlockHouseCreek]: "Block House Creek",
  [ElementarySchool.BobHope]: "Bob Hope",
  [ElementarySchool.BobLewisElementary]: "Bob Lewis Elementary",
  [ElementarySchool.BobbyeBehlau]: "Bobbye Behlau",
  [ElementarySchool.Boerne]: "Boerne",
  [ElementarySchool.Bonham]: "Bonham",
  [ElementarySchool.BonnieEllison]: "Bonnie Ellison",
  [ElementarySchool.Boone]: "Boone",
  [ElementarySchool.Bowden]: "Bowden",
  [ElementarySchool.Bowen]: "Bowen",
  [ElementarySchool.Bowie]: "Bowie",
  [ElementarySchool.BrackenridgeJT]: "Brackenridge J. T.",
  [ElementarySchool.Brauchle]: "Brauchle",
  [ElementarySchool.Braun]: "Braun",
  [ElementarySchool.BraunStation]: "Braun Station",
  [ElementarySchool.Brewer]: "Brewer",
  [ElementarySchool.Briscoe]: "Briscoe",
  [ElementarySchool.Brown]: "Brown",
  [ElementarySchool.BuenaVista]: "Buena Vista",
  [ElementarySchool.Bulverde]: "Bulverde",
  [ElementarySchool.BulverdeCreek]: "Bulverde Creek",
  [ElementarySchool.BurkeElementary]: "Burke Elementary",
  [ElementarySchool.Burleson]: "Burleson",
  [ElementarySchool.Burnet]: "Burnet",
  [ElementarySchool.ChiltonElementary]: "Chilton Elementary",
  [ElementarySchool.CiboloCreek]: "Cibolo Creek",
  [ElementarySchool.CiboloValley]: "Cibolo Valley",
  [ElementarySchool.CopperfieldEle]: "Copperfield Ele",
  [ElementarySchool.CrystalCity]: "Crystal City Elementary Zavala County",
  [ElementarySchool.Cable]: "Cable",
  [ElementarySchool.CallDistrict]: "Call District",
  [ElementarySchool.Cambridge]: "Cambridge",
  [ElementarySchool.Camelot]: "Camelot",
  [ElementarySchool.Cameron]: "Cameron",
  [ElementarySchool.Candlewood]: "Candlewood",
  [ElementarySchool.CanyonLake]: "Canyon Lake",
  [ElementarySchool.CanyonRidgeElem]: "Canyon Ridge Elem",
  [ElementarySchool.CarlSchurzEle]: "Carl Schurz Ele",
  [ElementarySchool.CarlosCoonEle]: "Carlos Coon Ele",
  [ElementarySchool.Carlson]: "Carlson",
  [ElementarySchool.Carnahan]: "Carnahan",
  [ElementarySchool.Carrillo]: "Carrillo",
  [ElementarySchool.CarrizoSprings]: "Carrizo Springs",
  [ElementarySchool.CarrizoSpringsIntermediate]: "Carrizo Springs Intermediate",
  [ElementarySchool.CarrollBell]: "Carroll Bell",
  [ElementarySchool.CarrollHenry]: "Carroll Henry",
  [ElementarySchool.Carson]: "Carson",
  [ElementarySchool.Carvajal]: "Carvajal",
  [ElementarySchool.Carvaljal]: "Carvaljal",
  [ElementarySchool.CastleHills]: "Castle Hills",
  [ElementarySchool.CastrovilleElementary]: "Castroville Elementary",
  [ElementarySchool.Cenizo]: "Cenizo",
  [ElementarySchool.CenizoPark]: "Cenizo Park",
  [ElementarySchool.CenterPoint]: "Center Point",
  [ElementarySchool.CenterPointPT]: "Center Point",
  [ElementarySchool.Channel]: "Channel",
  [ElementarySchool.CharlesKuentz]: "Charles Kuentz",
  [ElementarySchool.Charlotte]: "Charlotte",
  [ElementarySchool.ChristianEvers]: "Christian Evers",
  [ElementarySchool.Ciavarra]: "Ciavarra",
  [ElementarySchool.CiboloGreen]: "Cibolo Green",
  [ElementarySchool.ClearSpring]: "Clear Spring",
  [ElementarySchool.CodyEd]: "Cody Ed",
  [ElementarySchool.Coker]: "Coker",
  [ElementarySchool.Colby]: "Colby",
  [ElementarySchool.Cole]: "Cole",
  [ElementarySchool.Collier]: "Collier",
  [ElementarySchool.CollinsGarden]: "Collins Garden",
  [ElementarySchool.ColonialHills]: "Colonial Hills",
  [ElementarySchool.ColoniesNorth]: "Colonies North",
  [ElementarySchool.ColumbiaHeights]: "Columbia Heights",
  [ElementarySchool.Comal]: "Comal",
  [ElementarySchool.Comfort]: "Comfort",
  [ElementarySchool.Comstock]: "Comstock",
  [ElementarySchool.Converse]: "Converse",
  [ElementarySchool.CoronadoEscobar]: "Coronado Escobar",
  [ElementarySchool.CoronadoVillage]: "Coronado Village",
  [ElementarySchool.Cotton]: "Cotton",
  [ElementarySchool.Cotulla]: "Cotulla",
  [ElementarySchool.CountyLine]: "County Line",
  [ElementarySchool.CreekwoodPark]: "Creekwood Park",
  [ElementarySchool.Crestview]: "Crestview",
  [ElementarySchool.Crockett]: "Crockett",
  [ElementarySchool.Curington]: "Curington",
  [ElementarySchool.DHanis]: "DHanis",
  [ElementarySchool.DrippingSpringsElementary]: "Dripping Springs Hays Cty",
  [ElementarySchool.Dalton]: "Dalton",
  [ElementarySchool.DeZavala]: "De Zavala",
  [ElementarySchool.DelRio]: "Del Rio",
  [ElementarySchool.Dellview]: "Dellview",
  [ElementarySchool.Derry]: "Derry",
  [ElementarySchool.Devine]: "Devine",
  [ElementarySchool.Dezavala]: "Dezavala",
  [ElementarySchool.Dilley]: "Dilley",
  [ElementarySchool.Divide]: "Divide",
  [ElementarySchool.Douglass]: "Douglass",
  [ElementarySchool.DrFerminCalderon]: "Dr. Fermin Calderon",
  [ElementarySchool.DrLonnieGreen]: "Dr. Lonnie Green",
  [ElementarySchool.DrTomasRivera]: "Dr. Tomas Rivera",
  [ElementarySchool.Driggers]: "Driggers",
  [ElementarySchool.EastCentral]: "East Central",
  [ElementarySchool.EastElementary]: "East Elementary",
  [ElementarySchool.EastTerrellHillsEle]: "East Terrell Hills Ele",
  [ElementarySchool.EdFranz]: "Ed Franz",
  [ElementarySchool.EdmundLieck]: "Edmund Lieck",
  [ElementarySchool.ElDorado]: "El Dorado",
  [ElementarySchool.ElmCreek]: "Elm Creek",
  [ElementarySchool.EloiseJaphetAcademy]: "Eloise Japhet Academy",
  [ElementarySchool.Elolf]: "Elolf",
  [ElementarySchool.ElrodJimmy]: "Elrod Jimmy",
  [ElementarySchool.EmmaFrey]: "Emma Frey",
  [ElementarySchool.Encino]: "Encino",
  [ElementarySchool.EncinoPark]: "Encino Park",
  [ElementarySchool.Erskine]: "Erskine",
  [ElementarySchool.Escobar]: "Escobar",
  [ElementarySchool.EscondidoElementary]: "Escondido Elementary",
  [ElementarySchool.Esid]: "Esid",
  [ElementarySchool.EsparzaGregorio]: "Esparza Gregorio",
  [ElementarySchool.Evers]: "Evers",
  [ElementarySchool.Fields]: "Fields",
  [ElementarySchool.FlourBluff]: "Flour Bluff",
  [ElementarySchool.Fabra]: "Fabra",
  [ElementarySchool.FairOaksRanch]: "Fair Oaks Ranch",
  [ElementarySchool.FallCity]: "Fall City",
  [ElementarySchool.Fenwick]: "Fenwick",
  [ElementarySchool.Fernandez]: "Fernandez",
  [ElementarySchool.Fisher]: "Fisher",
  [ElementarySchool.FivePal]: "Five Pal",
  [ElementarySchool.FivePalms]: "Five Palms",
  [ElementarySchool.Flanders]: "Flanders",
  [ElementarySchool.Flores]: "Flores",
  [ElementarySchool.Floresville]: "Floresville",
  [ElementarySchool.Forbes]: "Forbes",
  [ElementarySchool.Forester]: "Forester",
  [ElementarySchool.Foster]: "Foster",
  [ElementarySchool.FoxRun]: "Fox Run",
  [ElementarySchool.FrankMadla]: "Frank Madla",
  [ElementarySchool.Franklin]: "Franklin",
  [ElementarySchool.Franz]: "Franz",
  [ElementarySchool.Fredericksburg]: "Fredericksburg",
  [ElementarySchool.FreedomElementary]: "Freedom Elementary",
  [ElementarySchool.Freiheit]: "Freiheit",
  [ElementarySchool.Freihert]: "Freihert",
  [ElementarySchool.Frey]: "Frey",
  [ElementarySchool.GatesAcademy]: "Gates Academy",
  [ElementarySchool.GalenEloff]: "Galen Eloff",
  [ElementarySchool.Gallardo]: "Gallardo",
  [ElementarySchool.Galm]: "Galm",
  [ElementarySchool.GardenRidge]: "Garden Ridge",
  [ElementarySchool.Gardendale]: "Gardendale",
  [ElementarySchool.Garfield]: "Garfield",
  [ElementarySchool.Garriga]: "Garriga",
  [ElementarySchool.Gates]: "Gates",
  [ElementarySchool.GeorgeWest]: "George West",
  [ElementarySchool.Gilbert]: "Gilbert",
  [ElementarySchool.Gillette]: "Gillette",
  [ElementarySchool.GlassColby]: "Glass Colby",
  [ElementarySchool.GlennJohn]: "Glenn John",
  [ElementarySchool.Glenoaks]: "Glenoaks",
  [ElementarySchool.Gonzales]: "Gonzales",
  [ElementarySchool.GoodwinFrazier]: "Goodwin Frazier",
  [ElementarySchool.Graebner]: "Graebner",
  [ElementarySchool.Grandview]: "Grandview",
  [ElementarySchool.Graves]: "Graves",
  [ElementarySchool.Green]: "Green",
  [ElementarySchool.GreenValley]: "Green Valley",
  [ElementarySchool.Guerra]: "Guerra",
  [ElementarySchool.HKWilliams]: "H. K. Williams",
  [ElementarySchool.HerbertGBoldtEle]: "Herbert G. Boldt Ele",
  [ElementarySchool.HoffMann]: "HoffMann",
  [ElementarySchool.HannaSpringsElementary]: "Hanna Springs Elementary",
  [ElementarySchool.HardyOak]: "Hardy Oak",
  [ElementarySchool.Harmony]: "Harmony",
  [ElementarySchool.HarmonyHills]: "Harmony Hills",
  [ElementarySchool.HarmonySchoolOfInnovation]: "Harmony School of Innovation",
  [ElementarySchool.HarperElementary]: "Harper Elementary",
  [ElementarySchool.HatchetEle]: "Hatchet Ele",
  [ElementarySchool.Hawthorne]: "Hawthorne",
  [ElementarySchool.Helotes]: "Helotes",
  [ElementarySchool.Henderson]: "Henderson",
  [ElementarySchool.HenryBGonzalez]: "Henry B. Gonzalez",
  [ElementarySchool.HenryGonzalez]: "Henry B. Gonzalez",
  [ElementarySchool.Herff]: "Herff",
  [ElementarySchool.HernandezElementry]: "Hernandez Elementry",
  [ElementarySchool.HiddenCove]: "Hidden Cove",
  [ElementarySchool.HiddenForest]: "Hidden Forest",
  [ElementarySchool.HighlandForest]: "Highland Forest",
  [ElementarySchool.HighlandHills]: "Highland Hills",
  [ElementarySchool.HighlandPark]: "Highland Park",
  [ElementarySchool.HillCntry]: "Hill Country",
  [ElementarySchool.HillCountry]: "Hill Country",
  [ElementarySchool.Hillcrest]: "Hillcrest",
  [ElementarySchool.Hirsch]: "Hirsch",
  [ElementarySchool.Hoelscher]: "Hoelscher",
  [ElementarySchool.HoffmanLane]: "Hoffman Lane",
  [ElementarySchool.Hondo]: "Hondo",
  [ElementarySchool.HopkinsEle]: "Hopkins Ele",
  [ElementarySchool.Hopperzt]: "Hopperzt",
  [ElementarySchool.Housman]: "Housman",
  [ElementarySchool.Howard]: "Howard",
  [ElementarySchool.Howsman]: "Howsman",
  [ElementarySchool.Hudspech]: "Hudspech",
  [ElementarySchool.Huebner]: "Huebner",
  [ElementarySchool.HullMary]: "Hull Mary",
  [ElementarySchool.Hunt]: "Hunt",
  [ElementarySchool.Huppertz]: "Huppertz",
  [ElementarySchool.Hutchins]: "Hutchins",
  [ElementarySchool.IndianCreek]: "Indian Creek",
  [ElementarySchool.IndianSprings]: "Indian Springs",
  [ElementarySchool.Ingram]: "Ingram",
  [ElementarySchool.JERhodes]: "J. E. Rhodes",
  [ElementarySchool.JamesMastersElementary]: "James L Masters Elementary",
  [ElementarySchool.JacksonKeller]: "Jackson Keller",
  [ElementarySchool.JacobsWellElementarySchool]:
    "Jacobs Well Elementary School",
  [ElementarySchool.JamesMadison]: "James Madison",
  [ElementarySchool.Japhet]: "Japhet",
  [ElementarySchool.Jefferson]: "Jefferson",
  [ElementarySchool.JerryAllen]: "Jerry Allen",
  [ElementarySchool.JimGMartin]: "Jim G Martin",
  [ElementarySchool.JohnASippel]: "John A Sippel",
  [ElementarySchool.JohnBarns]: "John Barns",
  [ElementarySchool.JohnCFrenchElementary]: "John C French Elementary",
  [ElementarySchool.JohnCFrenchCElementary]: "John C French Elementary",
  [ElementarySchool.JohnGlennJr]: "John Glenn Jr.",
  [ElementarySchool.JohnsonCity]: "Johnson City",
  [ElementarySchool.JohnsonRanch]: "Johnson Ranch",
  [ElementarySchool.Jones]: "Jones",
  [ElementarySchool.JonesElementary]: "Jones Elementary",
  [ElementarySchool.Jordan]: "Jordan",
  [ElementarySchool.Jourdanton]: "Jourdanton",
  [ElementarySchool.JulianCGallardoElementary]: "Julian C. Gallardo Elementary",
  [ElementarySchool.JulieNewtonAue]: "Julie Newton Aue",
  [ElementarySchool.Junction]: "Junction",
  [ElementarySchool.Kallison]: "Kallison",
  [ElementarySchool.KatieReed]: "Katie Reed",
  [ElementarySchool.KarnesCityElem]: "Karnes City Elem.",
  [ElementarySchool.Kelly]: "Kelly",
  [ElementarySchool.KendallElementary]: "Kendall Elementary",
  [ElementarySchool.Kenedy]: "Kenedy",
  [ElementarySchool.Kerrville]: "Kerrville",
  [ElementarySchool.KinderRanchElementary]: "Kinder Ranch Elementary",
  [ElementarySchool.Kindred]: "Kindred",
  [ElementarySchool.King]: "King",
  [ElementarySchool.Kirby]: "Kirby",
  [ElementarySchool.Kirchner]: "Kirchner",
  [ElementarySchool.KleinRoad]: "Klein Road",
  [ElementarySchool.KlineWhitisElementary]: "Kline Whitis Elementary",
  [ElementarySchool.Knowlton]: "Knowlton",
  [ElementarySchool.Knox]: "Knox",
  [ElementarySchool.Koenneckee]: "Koenneckee",
  [ElementarySchool.KriewaldRoad]: "Kriewald Road",
  [ElementarySchool.Krueger]: "Krueger",
  [ElementarySchool.LittleBayPortAransas]: "Little Bay Port Aransas",
  [ElementarySchool.LuckyRanch]: "Lucky Ranch",
  [ElementarySchool.LaPryor]: "La Pryor",
  [ElementarySchool.LaSoya]: "La Soya",
  [ElementarySchool.LaVernia]: "La Vernia",
  [ElementarySchool.LacklandCityAllen]: "Lackland City (Allen)",
  [ElementarySchool.LacosteElementary]: "Lacoste Elementary",
  [ElementarySchool.Ladera]: "Ladera",
  [ElementarySchool.LagoVista]: "Lago Vista",
  [ElementarySchool.LakehillsElementary]: "Lakehills Elementary",
  [ElementarySchool.Lamar]: "Lamar",
  [ElementarySchool.LargoVista]: "Largo Vista",
  [ElementarySchool.Larkspur]: "Larkspur",
  [ElementarySchool.LasLomas]: "Las Lomas",
  [ElementarySchool.LasPalmas]: "Las Palmas",
  [ElementarySchool.Leakey]: "Leakey",
  [ElementarySchool.LeonSprings]: "Leon Springs",
  [ElementarySchool.LeonValley]: "Leon Valley",
  [ElementarySchool.Lewis]: "Lewis",
  [ElementarySchool.Liberty]: "Liberty",
  [ElementarySchool.LintonDolores]: "Linton Dolores",
  [ElementarySchool.LiveOak]: "Live Oak",
  [ElementarySchool.Llano]: "Llano",
  [ElementarySchool.LockeHill]: "Locke Hill",
  [ElementarySchool.Lockhill]: "Lockhill",
  [ElementarySchool.LomaPark]: "Loma Park",
  [ElementarySchool.LoneStarElementary]: "Lone Star Elementary",
  [ElementarySchool.LongsCreek]: "Longs Creek",
  [ElementarySchool.LorenzoDeZavala]: "Lorenzo De Zavala",
  [ElementarySchool.LosReyes]: "Los Reyes",
  [ElementarySchool.LuckeyRanch]: "Luckey Ranch",
  [ElementarySchool.Luling]: "Luling",
  [ElementarySchool.Lumberton]: "Lumberton",
  [ElementarySchool.Lynch]: "Lynch",
  [ElementarySchool.LyndonBJohnson]: "Lyndon B. Johnson",
  [ElementarySchool.Lytle]: "Lytle",
  [ElementarySchool.MillerAcademy]: "Miller Academy",
  [ElementarySchool.Madison]: "Madison",
  [ElementarySchool.MageeElementary]: "Magee Elementary",
  [ElementarySchool.MarbleFallsElem]: "Marble Falls Elem",
  [ElementarySchool.Margil]: "Margil",
  [ElementarySchool.Marion]: "Marion",
  [ElementarySchool.MarthaMead]: "Martha Mead",
  [ElementarySchool.MartinLutherKing]: "Martin Luther King",
  [ElementarySchool.MaryB]: "Mary B",
  [ElementarySchool.MaryLouHartman]: "Mary Lou Hartman",
  [ElementarySchool.MaryMichael]: "Mary Michael",
  [ElementarySchool.MasonElem]: "Mason Elem",
  [ElementarySchool.MathewsElementary]: "Mathews Elementary",
  [ElementarySchool.Mathis]: "Mathis",
  [ElementarySchool.Maverick]: "Maverick",
  [ElementarySchool.May]: "May",
  [ElementarySchool.McDermott]: "Mc Dermott",
  [ElementarySchool.Mcqueeney]: "Mcqueeney",
  [ElementarySchool.Mead]: "Mead",
  [ElementarySchool.MeadowVillage]: "Meadow Village",
  [ElementarySchool.Medina]: "Medina",
  [ElementarySchool.MedinaValley]: "Medina Valley",
  [ElementarySchool.MedioCreek]: "Medio Creek",
  [ElementarySchool.Memorial]: "Memorial",
  [ElementarySchool.MeyerElementary]: "Meyer Elementary",
  [ElementarySchool.Michael]: "Michael",
  [ElementarySchool.Miller]: "Miller",
  [ElementarySchool.MillersPoint]: "Millers Point",
  [ElementarySchool.MissionAcademy]: "Mission Academy",
  [ElementarySchool.MonroeMay]: "Monroe May",
  [ElementarySchool.Montgomery]: "Montgomery",
  [ElementarySchool.Mora]: "Mora",
  [ElementarySchool.Morningside]: "Morningside",
  [ElementarySchool.Morrill]: "Morrill",
  [ElementarySchool.MountainValley]: "Mountain Valley",
  [ElementarySchool.Murnin]: "Murnin",
  [ElementarySchool.MyersVirginia]: "Myers Virginia",
  [ElementarySchool.Natalia]: "Natalia",
  [ElementarySchool.NavarroElementary]: "Navarro Elementary",
  [ElementarySchool.Neal]: "Neal",
  [ElementarySchool.NeilArmstrong]: "Neil Armstrong",
  [ElementarySchool.NellieMaeGlass]: "Nellie Mae Glass",
  [ElementarySchool.Nelson]: "Nelson",
  [ElementarySchool.NewBraunfel]: "New Braunfel",
  [ElementarySchool.Nichols]: "Nichols",
  [ElementarySchool.Nimitz]: "Nimitz",
  [ElementarySchool.NixonElementary]: "Nixon Elementary",
  [ElementarySchool.NoraForester]: "Nora Forester",
  [ElementarySchool.NormaJPaschal]: "Norma J Paschal",
  [ElementarySchool.North]: "North",
  [ElementarySchool.NorthElementaryFloresville]: "North Elementary Floresville",
  [ElementarySchool.NorthHeights]: "North Heights",
  [ElementarySchool.NorthernHills]: "Northern Hills",
  [ElementarySchool.NorthwestCrossing]: "Northwest Crossing",
  [ElementarySchool.Northwood]: "Northwood",
  [ElementarySchool.NuecesCanyon]: "Nueces Canyon",
  [ElementarySchool.OakCreek]: "Oak Creek",
  [ElementarySchool.OakCrestElementary]: "Oak Crest Elementary",
  [ElementarySchool.OakGrove]: "Oak Grove",
  [ElementarySchool.OakHillsTerrace]: "Oak Hills Terrace",
  [ElementarySchool.OakMeadow]: "Oak Meadow",
  [ElementarySchool.Oakmead]: "Oakmead",
  [ElementarySchool.Ogden]: "Ogden",
  [ElementarySchool.OlivaresAntonio]: "Olivares Antonio",
  [ElementarySchool.Olmos]: "Olmos",
  [ElementarySchool.Olsen]: "Olsen",
  [ElementarySchool.Olympia]: "Olympia",
  [ElementarySchool.Ott]: "Ott",
  [ElementarySchool.PeggyCarnahan]: "Peggy Carnahan",
  [ElementarySchool.PacksaddleElementry]: "Packsaddle Elementry",
  [ElementarySchool.PaloAlto]: "Palo Alto",
  [ElementarySchool.ParkVillage]: "Park Village",
  [ElementarySchool.Paschall]: "Paschall",
  [ElementarySchool.Passmore]: "Passmore",
  [ElementarySchool.Patlan]: "Patlan",
  [ElementarySchool.Patrick]: "Patrick",
  [ElementarySchool.PearceWilliam]: "Pearce William",
  [ElementarySchool.Pearsall]: "Pearsall",
  [ElementarySchool.PecanValley]: "Pecan Valley",
  [ElementarySchool.Perales]: "Perales",
  [ElementarySchool.PerfectoMancha]: "Perfecto Mancha",
  [ElementarySchool.Pershing]: "Pershing",
  [ElementarySchool.PeteGallego]: "Pete Gallego",
  [ElementarySchool.Pfeiffer]: "Pfeiffer",
  [ElementarySchool.Pleasanton]: "Pleasanton",
  [ElementarySchool.Poteet]: "Poteet",
  [ElementarySchool.Poth]: "Poth",
  [ElementarySchool.Potranco]: "Potranco",
  [ElementarySchool.PowellLawrence]: "Powell Lawrence",
  [ElementarySchool.Price]: "Price",
  [ElementarySchool.Raba]: "Raba",
  [ElementarySchool.RaheBulverdeElementary]: "Rahe Bulverde Elementary",
  [ElementarySchool.RahePrimary]: "Rahe Primary",
  [ElementarySchool.RailRidgeElementary]: "Rail Ridge Elementary",
  [ElementarySchool.RalphLangley]: "Ralph Langley",
  [ElementarySchool.RamirezBurksElementary]: "Ramirez-Burks Elementary",
  [ElementarySchool.RayHDarr]: "Ray H Darr",
  [ElementarySchool.Rayburn]: "Rayburn",
  [ElementarySchool.RebeccaCreek]: "Rebecca Creek",
  [ElementarySchool.RedlandOaks]: "Redland Oaks",
  [ElementarySchool.RegencyPlace]: "Regency Place",
  [ElementarySchool.Rhodes]: "Rhodes",
  [ElementarySchool.Ridgeview]: "Ridgeview",
  [ElementarySchool.RiversidePark]: "Riverside Park",
  [ElementarySchool.RoanForest]: "Roan Forest",
  [ElementarySchool.RoanOak]: "Roan Oak",
  [ElementarySchool.Robb]: "Robb",
  [ElementarySchool.RobbElementary]: "Robb Elementary",
  [ElementarySchool.RobertELee]: "Robert E. Lee",
  [ElementarySchool.RobertoBobbyBarrera]: 'Roberto "Bobby" Barrera',
  [ElementarySchool.Rocksprings]: "Rocksprings",
  [ElementarySchool.Rodriguez]: "Rodriguez",
  [ElementarySchool.Rogers]: "Rogers",
  [ElementarySchool.RogersESides]: "Rogers E Sides",
  [ElementarySchool.RollingMeadows]: "Rolling Meadows",
  [ElementarySchool.Roosevelt]: "Roosevelt",
  [ElementarySchool.RoseGarden]: "Rose Garden",
  [ElementarySchool.RositaValley]: "Rosita Valley",
  [ElementarySchool.RoyBenavidez]: "Roy Benavidez",
  [ElementarySchool.RoyCisneros]: "Roy Cisneros",
  [ElementarySchool.RoyalRidge]: "Royal Ridge",
  [ElementarySchool.Royalgate]: "Royalgate",
  [ElementarySchool.RubenChavira]: "Ruben Chavira",
  [ElementarySchool.Ruiz]: "Ruiz",
  [ElementarySchool.Runge]: "Runge",
  [ElementarySchool.Startzville]: "Startzville",
  [ElementarySchool.Sabinal]: "Sabinal",
  [ElementarySchool.Saisd]: "Saisd",
  [ElementarySchool.Salinas]: "Salinas",
  [ElementarySchool.SamHouston]: "Sam Houston",
  [ElementarySchool.SanLuis]: "San Luis",
  [ElementarySchool.SandersonElem]: "Sanderson Elem.",
  [ElementarySchool.SaraBMcAndrew]: "Sara B McAndrew",
  [ElementarySchool.Scarborough]: "Scarborough",
  [ElementarySchool.Scheh]: "Scheh",
  [ElementarySchool.Schenck]: "Schenck",
  [ElementarySchool.Schertz]: "Schertz",
  [ElementarySchool.Schulenburg]: "Schulenburg",
  [ElementarySchool.Schulze]: "Schulze",
  [ElementarySchool.Scobee]: "Scobee",
  [ElementarySchool.Scudder]: "Scudder",
  [ElementarySchool.ScudderPrimarySchool]: "Scudder Primary School",
  [ElementarySchool.Secht]: "Secht",
  [ElementarySchool.SecoMines]: "Seco Mines",
  [ElementarySchool.Seele]: "Seele",
  [ElementarySchool.Seely]: "Seely",
  [ElementarySchool.Seguin]: "Seguin",
  [ElementarySchool.Serna]: "Serna",
  [ElementarySchool.Shenandoah]: "Shenandoah",
  [ElementarySchool.Sinclair]: "Sinclair",
  [ElementarySchool.SkyHarbor]: "Sky Harbor",
  [ElementarySchool.Smith]: "Smith",
  [ElementarySchool.SmithsonValley]: "Smithson Valley",
  [ElementarySchool.Somerset]: "Somerset",
  [ElementarySchool.SouthElementaryFloresville]: "South Elementary Floresville",
  [ElementarySchool.SouthsideHeritage]: "Southside Heritage",
  [ElementarySchool.Southwest]: "Southwest",
  [ElementarySchool.Specht]: "Specht",
  [ElementarySchool.SpicewoodPark]: "Spicewood Park",
  [ElementarySchool.SpringMeadows]: "Spring Meadows",
  [ElementarySchool.Stafford]: "Stafford",
  [ElementarySchool.Stahl]: "Stahl",
  [ElementarySchool.Starkey]: "Starkey",
  [ElementarySchool.Starky]: "Starky",
  [ElementarySchool.Steele]: "Steele",
  [ElementarySchool.Steubing]: "Steubing",
  [ElementarySchool.SteubingRanch]: "Steubing Ranch",
  [ElementarySchool.Stewart]: "Stewart",
  [ElementarySchool.Stockdale]: "Stockdale",
  [ElementarySchool.StoneOak]: "Stone Oak",
  [ElementarySchool.StonewallElementary]: "Stonewall Elementary",
  [ElementarySchool.Storm]: "Storm",
  [ElementarySchool.SueEvelynRattan]: "Sue Evelyn Rattan",
  [ElementarySchool.Summerlin]: "Summerlin",
  [ElementarySchool.SunValley]: "Sun Valley",
  [ElementarySchool.Tally]: "Tally",
  [ElementarySchool.TaylorCreekElementary]: "Taylor Creek Elementary",
  [ElementarySchool.TerriCornerS]: "Terri CornerS",
  [ElementarySchool.Thornton]: "Thornton",
  [ElementarySchool.ThousandOaks]: "Thousand Oaks",
  [ElementarySchool.ThreeRivers]: "Three Rivers",
  [ElementarySchool.ThunderbirdHills]: "Thunderbird Hills",
  [ElementarySchool.TildenElementary]: "Tilden Elementary",
  [ElementarySchool.Timberwilde]: "Timberwilde",
  [ElementarySchool.TimberwoodPark]: "Timberwood Park",
  [ElementarySchool.TomDan]: "Tom Daniels",
  [ElementarySchool.TomDaniels]: "Tom Daniels",
  [ElementarySchool.TomlinsonElementary]: "Tomlinson Elementary",
  [ElementarySchool.Tradition]: "Tradition",
  [ElementarySchool.Travis]: "Travis",
  [ElementarySchool.Trivino]: "Trivino",
  [ElementarySchool.TuscanyHeights]: "Tuscany Heights",
  [ElementarySchool.Tynan]: "Tynan",
  [ElementarySchool.Utopia]: "Utopia",
  [ElementarySchool.Uvalde]: "Uvalde",
  [ElementarySchool.ValleyHi]: "Valley Hi",
  [ElementarySchool.VanRaub]: "Van Raub",
  [ElementarySchool.Veramendi]: "Veramendi",
  [ElementarySchool.Vestal]: "Vestal",
  [ElementarySchool.Villarreal]: "Villarreal",
  [ElementarySchool.VineyardRanch]: "Vineyard Ranch",
  [ElementarySchool.VirginaMeyers]: "Virgina Meyers",
  [ElementarySchool.Vogel]: "Vogel",
  [ElementarySchool.VogelElementary]: "Vogel Elementary",
  [ElementarySchool.VossFarms]: "Voss Farms",
  [ElementarySchool.WalnutSpringsElementarySchool]:
    "Walnut Springs Elementary School",
  [ElementarySchool.Walzem]: "Walzem",
  [ElementarySchool.Wanke]: "Wanke",
  [ElementarySchool.Ward]: "Ward",
  [ElementarySchool.Washington]: "Washington",
  [ElementarySchool.Watts]: "Watts",
  [ElementarySchool.Weinert]: "Weinert",
  [ElementarySchool.WernliElementarySchool]: "Wernli Elementary School",
  [ElementarySchool.WestAvenue]: "West Avenue",
  [ElementarySchool.WesthoffElementary]: "Westhoff Elementary",
  [ElementarySchool.WestwoodTerrace]: "Westwood Terrace",
  [ElementarySchool.WetmoreElementary]: "Wetmore Elementary",
  [ElementarySchool.White]: "White",
  [ElementarySchool.Wiederstein]: "Wiederstein",
  [ElementarySchool.WildernessOakElementary]: "Wilderness Oak Elementary",
  [ElementarySchool.WilliamFBarnett]: "William F. Barnett",
  [ElementarySchool.Wilshire]: "Wilshire",
  [ElementarySchool.Wilson]: "Wilson",
  [ElementarySchool.Windcrest]: "Windcrest",
  [ElementarySchool.Winston]: "Winston",
  [ElementarySchool.WoodLandEle]: "Wood Land Ele",
  [ElementarySchool.WoodRiverElementary]: "Wood River Elementary",
  [ElementarySchool.Woodlake]: "Woodlake",
  [ElementarySchool.Woodlawn]: "Woodlawn",
  [ElementarySchool.WoodlawnHills]: "Woodlawn Hills",
  [ElementarySchool.Woodridge]: "Woodridge",
  [ElementarySchool.Woodstone]: "Woodstone",
  [ElementarySchool.WorthamOaks]: "Wortham Oaks",
  [ElementarySchool.Wright]: "Wright",
  [ElementarySchool.YorktownElementary]: "Yorktown Elementary"
};
