export enum HighSchool {
  AcademyHighSchool = "academyHighSchool",
  AkinsHighSchool = "akinsHighSchool",
  AllisonSteeleEnhancedLearningCenter = "allisonSteeleEnhancedLearningCenter",
  AndersonHighSchool = "andersonHighSchool",
  AnnRichardsSchoolforYoungWomenLeaders = "annRichardsSchoolforYoungWomenLeaders",
  AtlasAcademy = "atlasAcademy",
  AustinHighSchool = "austinHighSchool",
  BartlettHighSchool = "bartlettHighSchool",
  BastropHighSchool = "bastropHighSchool",
  Belton = "belton",
  BeltonHighSchool = "beltonHighSchool",
  BowieHighSchool = "bowieHighSchool",
  Brandeis = "brandeis",
  BrandeisHighSchool = "brandeisHighSchool",
  BrazosHigh = "brazosHigh",
  BrenhamHighSchool = "brenhamHighSchool",
  BrennanHighSchool = "brennanHighSchool",
  BrucevilleEddyHighSchool = "brucevilleEddyHighSchool",
  BurnetHighSchool = "burnetHighSchool",
  BusinessCareersHighSchool = "businessCareersHighSchool",
  ByronPSteeleIIHighSchool = "byronPSteeleIIHighSchool",
  CameronHighSchool = "cameronHighSchool",
  CanyonHighSchool = "canyonHighSchool",
  CanyonLakeHighSchool = "canyonLakeHighSchool",
  CedarCreekHighSchool = "cedarCreekHighSchool",
  CedarParkHighHighSchool = "cedarParkHighHighSchool",
  CedarRidgeHighSchool = "cedarRidgeHighSchool",
  ChaparralHighSchool = "chaparralHighSchool",
  ChiltonSchool = "chiltonSchool",
  ChinaSpringHighSchool = "chinaSpringHighSchool",
  ClarkHighSchool = "clarkHighSchool",
  CommunicationsArtsHighSchool = "communicationsArtsHighSchool",
  ConnallyHighSchool = "connallyHighSchool",
  ConstructionCareersAcademyHighSchool = "constructionCareersAcademyHighSchool",
  CopperasCoveHighSchool = "copperasCoveHighSchool",
  CrockettHighSchool = "crockettHighSchool",
  CrossroadsHighSchool = "crossroadsHighSchool",
  DavenportHighSchool = "davenportHighSchool",
  DeLeonHighSchool = "deLeonHighSchool",
  DelValleSchool = "delValleSchool",
  DellCitySchool = "dellCitySchool",
  DrippingSpringsHighSchool = "drippingSpringsHighSchool",
  EarlyCollegeHighSchool = "earlyCollegeHighSchool",
  EarlyCollegeHighSchoolACCRoundRock = "earlyCollegeHighSchoolACCRoundRock",
  EastViewHighSchool = "eastViewHighSchool",
  EastlandHighSchool = "eastlandHighSchool",
  EastsideMemorialHighSchool = "eastsideMemorialHighSchool",
  ElginHighSchool = "elginHighSchool",
  EllisonHighSchool = "ellisonHighSchool",
  EvantHighSchool = "evantHighSchool",
  FallsCareerHighSchool = "fallsCareerHighSchool",
  Flatonia = "flatonia",
  FlorenceHighSchool = "florenceHighSchool",
  GarzaIndependenceHighSchool = "garzaIndependenceHighSchool",
  Gatesville = "gatesville",
  GatewayHighSchool = "gatewayHighSchool",
  GenesisHighSchool = "genesisHighSchool",
  GeorgetownHighSchool = "georgetownHighSchool",
  GiddingsHighSchool = "giddingsHighSchool",
  GlennHighSchool = "glennHighSchool",
  GonzalesHighSchool = "gonzalesHighSchool",
  GPAatLanier = "gPAatLanier",
  GPAatTravis = "gPAatTravis",
  GrangerHighSchool = "grangerHighSchool",
  GWAHCA = "gWAHCA",
  GWAMA = "gWAMA",
  HarkerHeightsHighSchool = "harkerHeightsHighSchool",
  HarlanHighSchool = "harlanHighSchool",
  HaysHighSchool = "haysHighSchool",
  HealthCareersHighSchool = "healthCareersHighSchool",
  HendricksonHighSchool = "hendricksonHighSchool",
  HollandHighschool = "hollandHighschool",
  HolmesHighSchool = "holmesHighSchool",
  HuttoHighSchool = "huttoHighSchool",
  InternationalHighSchool = "internationalHighSchool",
  JarrellHighSchool = "jarrellHighSchool",
  JohnConnallyHighSchool = "johnConnallyHighSchool",
  JohnJayHighSchool = "johnJayHighSchool",
  JohnJayScienceandEngineeringAcademy = "johnJayScienceandEngineeringAcademy",
  JohnsonHighSchool = "johnsonHighSchool",
  JonesboroHigh = "jonesboroHigh",
  JuanNavarroHighSchool = "juanNavarroHighSchool",
  KilleenHighSchool = "killeenHighSchool",
  LaGrangeHighSchool = "laGrangeHighSchool",
  LagoVistaHighSchool = "lagoVistaHighSchool",
  LakeBeltonHighSchool = "lakeBeltonHighSchool",
  LakeTravisHighSchool = "lakeTravisHighSchool",
  LampasasHighSchool = "lampasasHighSchool",
  LaVegaHighSchool = "laVegaHighSchool",
  LBJEarlyCollegeHighSchool = "lBJEarlyCollegeHighSchool",
  LeakeyHighSchool = "leakeyHighSchool",
  LeanderHighHighSchool = "leanderHighHighSchool",
  LegacyEarlyCollegeHS = "legacyEarlyCollegeHS",
  LehmanHighSchool = "lehmanHighSchool",
  LexingtonHighSchool = "lexingtonHighSchool",
  LiberalArtsandScienceAcademy = "liberalArtsandScienceAcademy",
  LiberyHillHighSchoolSchool = "liberyHillHighSchoolSchool",
  LiveOakAcademy = "liveOakAcademy",
  LlanoHighSchool = "llanoHighSchool",
  LockhartHighSchool = "lockhartHighSchool",
  LockhartPrideHighSchool = "lockhartPrideHighSchool",
  LometaHigh = "lometaHigh",
  LoneOakHighSchool = "loneOakHighSchool",
  LorenaHighSchool = "lorenaHighSchool",
  LulingHighSchool = "lulingHighSchool",
  LyndonJohnsonHS = "lyndonJohnsonHS",
  ManorHighSchool = "manorHighSchool",
  ManorNewTechHighSchool = "manorNewTechHighSchool",
  MarbleFallsHighSchool = "marbleFallsHighSchool",
  MarionHighSchool = "marionHighSchool",
  MarshallHighSchool = "marshallHighSchool",
  McCallumHighSchool = "mcCallumHighSchool",
  McGregorHighSchool = "mcGregorHighSchool",
  McLennanChallenge = "mcLennanChallenge",
  McNeilHighSchool = "mcNeilHighSchool",
  MemorialEarlyCollege = "memorialEarlyCollege",
  MexiaHighSchool = "mexiaHighSchool",
  MidwayHighSchool = "midwayHighSchool",
  MoodyHighSchool = "moodyHighSchool",
  NavarroHighSchool = "navarroHighSchool",
  NewBraunfelsHighSchool = "newBraunfelsHighSchool",
  NewBraunfelsNinthGradeCenter = "newBraunfelsNinthGradeCenter",
  NixonSmileyHighSchoo = "nixonSmileyHighSchoo",
  NortheastEarlyCollege = "northeastEarlyCollege",
  OConnorHighSchool = "oConnorHighSchool",
  OglesbyHighSchool = "oglesbyHighSchool",
  OutofArea = "outofArea",
  PflugervilleHighSchool = "pflugervilleHighSchool",
  PheonixHighSchool = "pheonixHighSchool",
  PhoenixHighSchool = "phoenixHighSchool",
  PrairieLeaSchoolPK12 = "prairieLeaSchoolPK12",
  QuestHighSchool = "questHighSchool",
  ReaganEarlyCollegeHighSchool = "reaganEarlyCollegeHighSchool",
  RicharteHighSchool = "richarteHighSchool",
  RobertLeeHighSchool = "robertLeeHighSchool",
  RobinsonHighSchool = "robinsonHighSchool",
  RockdaleHighSchool = "rockdaleHighSchool",
  RogersHighSchool = "rogersHighSchool",
  RosebudLottHigh = "rosebudLottHigh",
  RoundRockHighSchool = "roundRockHighSchool",
  RouseHighSchool = "rouseHighSchool",
  SaladoHighSchool = "saladoHighSchool",
  SamuelClemensHighSchool = "samuelClemensHighSchool",
  SanMarcosHighSchool = "sanMarcosHighSchool",
  SCLeeJuniorHighSchool = "sCLeeJuniorHighSchool",
  SeguinHighSchoo = "seguinHighSchoo",
  ShoemakerHighSchool = "shoemakerHighSchool",
  SmithsonValley = "smithsonValley",
  SmithsonValleyHigh = "smithsonValleyHigh",
  SmithvilleHighSchool = "smithvilleHighSchool",
  SomervilleHighSchool = "somervilleHighSchool",
  StevensHighSchool = "stevensHighSchool",
  StonyPointHighSchool = "stonyPointHighSchool",
  SuccessHighSchool = "successHighSchool",
  TaftHighSchool = "taftHighSchool",
  TaylorHighSchool = "taylorHighSchool",
  TempleHighSchool = "templeHighSchool",
  ThorndaleHighSchool = "thorndaleHighSchool",
  ThrallHighSchool = "thrallHighSchool",
  TravisEarlyCollegeHighSchool = "travisEarlyCollegeHighSchool",
  TravisHighSchool = "travisHighSchool",
  TroyHighSchool = "troyHighSchool",
  UniversityHigh = "universityHigh",
  VandegriftHighSchool = "vandegriftHighSchool",
  VeteransMemorialHighSchool = "veteransMemorialHighSchool",
  VistaRidgeHighSchool = "vistaRidgeHighSchool",
  WacoHigh = "wacoHigh",
  WaelderSchoolPK12 = "waelderSchoolPK12",
  WagnerHighSchool = "wagnerHighSchool",
  WarrenHighSchool = "warrenHighSchool",
  WeissHighSchool = "weissHighSchool",
  WestlakeHighSchool = "westlakeHighSchool",
  WestwoodHighSchool = "westwoodHighSchool",
  WhitneyHighSchool = "whitneyHighSchool",
  WileyOpportunityCenter = "wileyOpportunityCenter",
  WimberleyHighSchool = "wimberleyHighSchool"
}

export const HighSchoolLabel: Record<HighSchool, string> = {
  [HighSchool.AcademyHighSchool]: "Academy High School",
  [HighSchool.AkinsHighSchool]: "Akins High School",
  [HighSchool.AllisonSteeleEnhancedLearningCenter]:
    "Allison L. Steele Enhanced Learning Center",
  [HighSchool.AndersonHighSchool]: "Anderson High School",
  [HighSchool.AnnRichardsSchoolforYoungWomenLeaders]:
    "Ann Richards School for Young Women Leaders",
  [HighSchool.AtlasAcademy]: "Atlas Academy",
  [HighSchool.AustinHighSchool]: "Austin High School",
  [HighSchool.BartlettHighSchool]: "Bartlett  High School",
  [HighSchool.BastropHighSchool]: "Bastrop High School",
  [HighSchool.Belton]: "Belton",
  [HighSchool.BeltonHighSchool]: "Belton High School",
  [HighSchool.BowieHighSchool]: "Bowie High School",
  [HighSchool.Brandeis]: "Brandeis",
  [HighSchool.BrandeisHighSchool]: "Brandeis High School",
  [HighSchool.BrazosHigh]: "Brazos High",
  [HighSchool.BrenhamHighSchool]: "Brenham High School",
  [HighSchool.BrennanHighSchool]: "Brennan High School",
  [HighSchool.BrucevilleEddyHighSchool]: "Bruceville-Eddy High School",
  [HighSchool.BurnetHighSchool]: "Burnet High School",
  [HighSchool.BusinessCareersHighSchool]: "Business Careers High School",
  [HighSchool.ByronPSteeleIIHighSchool]: "Byron P Steele II High School",
  [HighSchool.CameronHighSchool]: "Cameron High School",
  [HighSchool.CanyonHighSchool]: "Canyon High School",
  [HighSchool.CanyonLakeHighSchool]: "Canyon Lake High School",
  [HighSchool.CedarCreekHighSchool]: "Cedar Creek High School",
  [HighSchool.CedarParkHighHighSchool]: "Cedar Park High High School",
  [HighSchool.CedarRidgeHighSchool]: "Cedar Ridge High School",
  [HighSchool.ChaparralHighSchool]: "Chaparral High School",
  [HighSchool.ChiltonSchool]: "Chilton School",
  [HighSchool.ChinaSpringHighSchool]: "China Spring High School",
  [HighSchool.ClarkHighSchool]: "Clark High School",
  [HighSchool.CommunicationsArtsHighSchool]: "Communications Arts High School",
  [HighSchool.ConnallyHighSchool]: "Connally High School",
  [HighSchool.ConstructionCareersAcademyHighSchool]:
    "Construction Careers Academy High School",
  [HighSchool.CopperasCoveHighSchool]: "Copperas Cove High School",
  [HighSchool.CrockettHighSchool]: "Crockett High School",
  [HighSchool.CrossroadsHighSchool]: "Crossroads High School",
  [HighSchool.DavenportHighSchool]: "Davenport High School",
  [HighSchool.DeLeonHighSchool]: "De Leon High School",
  [HighSchool.DelValleSchool]: "Del Valle School",
  [HighSchool.DellCitySchool]: "Dell City School",
  [HighSchool.DrippingSpringsHighSchool]: "Dripping Springs High School",
  [HighSchool.EarlyCollegeHighSchool]: "Early College High School ",
  [HighSchool.EarlyCollegeHighSchoolACCRoundRock]:
    "Early College High School - ACC Round Rock",
  [HighSchool.EastViewHighSchool]: "East View High School",
  [HighSchool.EastlandHighSchool]: "Eastland High School",
  [HighSchool.EastsideMemorialHighSchool]: "Eastside Memorial High School",
  [HighSchool.ElginHighSchool]: "Elgin High School",
  [HighSchool.EllisonHighSchool]: "Ellison High School",
  [HighSchool.EvantHighSchool]: "Evant High School",
  [HighSchool.FallsCareerHighSchool]: "Falls Career High School",
  [HighSchool.Flatonia]: "Flatonia",
  [HighSchool.FlorenceHighSchool]: "Florence High School",
  [HighSchool.GarzaIndependenceHighSchool]: "Garza Independence High School",
  [HighSchool.Gatesville]: "Gatesville",
  [HighSchool.GatewayHighSchool]: "Gateway High School",
  [HighSchool.GenesisHighSchool]: "Genesis High School",
  [HighSchool.GeorgetownHighSchool]: "Georgetown High School",
  [HighSchool.GiddingsHighSchool]: "Giddings High School",
  [HighSchool.GlennHighSchool]: "Glenn High  School",
  [HighSchool.GonzalesHighSchool]: "Gonzales High School",
  [HighSchool.GPAatLanier]: "GPA at Lanier",
  [HighSchool.GPAatTravis]: "GPA at Travis",
  [HighSchool.GrangerHighSchool]: "Granger High School",
  [HighSchool.GWAHCA]: "GWAHCA",
  [HighSchool.GWAMA]: "GWAMA",
  [HighSchool.HarkerHeightsHighSchool]: "Harker Heights High School",
  [HighSchool.HarlanHighSchool]: "Harlan  High School",
  [HighSchool.HaysHighSchool]: "Hays High School",
  [HighSchool.HealthCareersHighSchool]: "Health Careers High School",
  [HighSchool.HendricksonHighSchool]: "Hendrickson High School",
  [HighSchool.HollandHighschool]: "Holland High school",
  [HighSchool.HolmesHighSchool]: "Holmes High School",
  [HighSchool.HuttoHighSchool]: "Hutto High School",
  [HighSchool.InternationalHighSchool]: "International High School",
  [HighSchool.JarrellHighSchool]: "Jarrell High School",
  [HighSchool.JohnConnallyHighSchool]: "John B. Connally High School",
  [HighSchool.JohnJayHighSchool]: "John Jay High School",
  [HighSchool.JohnJayScienceandEngineeringAcademy]:
    "John Jay Science and Engineering Academy",
  [HighSchool.JohnsonHighSchool]: "Johnson High School",
  [HighSchool.JonesboroHigh]: "Jonesboro High",
  [HighSchool.JuanNavarroHighSchool]: "Juan Navarro High School",
  [HighSchool.KilleenHighSchool]: "Killeen High School",
  [HighSchool.LaGrangeHighSchool]: "La Grange High School",
  [HighSchool.LagoVistaHighSchool]: "Lago Vista High School",
  [HighSchool.LakeBeltonHighSchool]: "Lake Belton High School",
  [HighSchool.LakeTravisHighSchool]: "Lake Travis High School",
  [HighSchool.LampasasHighSchool]: "Lampasas High School",
  [HighSchool.LaVegaHighSchool]: "LaVega High School",
  [HighSchool.LBJEarlyCollegeHighSchool]: "LBJ Early College High School",
  [HighSchool.LeakeyHighSchool]: "Leakey High School",
  [HighSchool.LeanderHighHighSchool]: "Leander High High School",
  [HighSchool.LegacyEarlyCollegeHS]: "Legacy Early College HS",
  [HighSchool.LehmanHighSchool]: "Lehman High School",
  [HighSchool.LexingtonHighSchool]: "Lexington High School",
  [HighSchool.LiberalArtsandScienceAcademy]: "Liberal Arts and Science Academy",
  [HighSchool.LiberyHillHighSchoolSchool]: "Libery Hill High School School",
  [HighSchool.LiveOakAcademy]: "Live Oak Academy",
  [HighSchool.LlanoHighSchool]: "Llano High School",
  [HighSchool.LockhartHighSchool]: "Lockhart High School",
  [HighSchool.LockhartPrideHighSchool]: "Lockhart Pride High School",
  [HighSchool.LometaHigh]: "Lometa High",
  [HighSchool.LoneOakHighSchool]: "Lone Oak High School",
  [HighSchool.LorenaHighSchool]: "Lorena High School",
  [HighSchool.LulingHighSchool]: "Luling High School",
  [HighSchool.LyndonJohnsonHS]: "Lyndon B. Johnson HS",
  [HighSchool.ManorHighSchool]: "Manor High School",
  [HighSchool.ManorNewTechHighSchool]: "Manor New Tech High School",
  [HighSchool.MarbleFallsHighSchool]: "Marble Falls High School",
  [HighSchool.MarionHighSchool]: "Marion High School",
  [HighSchool.MarshallHighSchool]: "Marshall High School",
  [HighSchool.McCallumHighSchool]: "McCallum High School",
  [HighSchool.McGregorHighSchool]: "McGregor High School",
  [HighSchool.McLennanChallenge]: "McLennan Challenge",
  [HighSchool.McNeilHighSchool]: "McNeil High School",
  [HighSchool.MemorialEarlyCollege]: "Memorial Early College",
  [HighSchool.MexiaHighSchool]: "Mexia High School",
  [HighSchool.MidwayHighSchool]: "Midway High School",
  [HighSchool.MoodyHighSchool]: "Moody High School",
  [HighSchool.NavarroHighSchool]: "Navarro High School",
  [HighSchool.NewBraunfelsHighSchool]: "New Braunfels High School",
  [HighSchool.NewBraunfelsNinthGradeCenter]: "New Braunfels Ninth Grade Center",
  [HighSchool.NixonSmileyHighSchoo]: "Nixon-Smiley High Schoo",
  [HighSchool.NortheastEarlyCollege]: "Northeast Early College",
  [HighSchool.OConnorHighSchool]: "O'Connor High School",
  [HighSchool.OglesbyHighSchool]: "Oglesby High School",
  [HighSchool.OutofArea]: "Out of Area",
  [HighSchool.PflugervilleHighSchool]: "Pflugerville High School",
  [HighSchool.PheonixHighSchool]: "Pheonix High School",
  [HighSchool.PhoenixHighSchool]: "Phoenix High School ",
  [HighSchool.PrairieLeaSchoolPK12]: "Prairie Lea School (PK-12)",
  [HighSchool.QuestHighSchool]: "Quest  High School",
  [HighSchool.ReaganEarlyCollegeHighSchool]: "Reagan Early College High School",
  [HighSchool.RicharteHighSchool]: "Richarte High School",
  [HighSchool.RobertLeeHighSchool]: "Robert Lee High School",
  [HighSchool.RobinsonHighSchool]: "Robinson High School",
  [HighSchool.RockdaleHighSchool]: "Rockdale High School",
  [HighSchool.RogersHighSchool]: "Rogers High School",
  [HighSchool.RosebudLottHigh]: "Rosebud-Lott High",
  [HighSchool.RoundRockHighSchool]: "Round Rock High School",
  [HighSchool.RouseHighSchool]: "Rouse High School",
  [HighSchool.SaladoHighSchool]: "Salado High School",
  [HighSchool.SamuelClemensHighSchool]: "Samuel Clemens High School",
  [HighSchool.SanMarcosHighSchool]: "San Marcos High School",
  [HighSchool.SCLeeJuniorHighSchool]: "SC Lee Junior High School",
  [HighSchool.SeguinHighSchoo]: "Seguin High Schoo",
  [HighSchool.ShoemakerHighSchool]: "Shoemaker High School",
  [HighSchool.SmithsonValley]: "Smithson Valley",
  [HighSchool.SmithsonValleyHigh]: "Smithson Valley High",
  [HighSchool.SmithvilleHighSchool]: "Smithville High School",
  [HighSchool.SomervilleHighSchool]: "Somerville High School",
  [HighSchool.StevensHighSchool]: "Stevens High School",
  [HighSchool.StonyPointHighSchool]: "Stony Point High School",
  [HighSchool.SuccessHighSchool]: "Success High School",
  [HighSchool.TaftHighSchool]: "Taft High School",
  [HighSchool.TaylorHighSchool]: "Taylor High School",
  [HighSchool.TempleHighSchool]: "Temple High School",
  [HighSchool.ThorndaleHighSchool]: "Thorndale High School",
  [HighSchool.ThrallHighSchool]: "Thrall High School",
  [HighSchool.TravisEarlyCollegeHighSchool]: "Travis Early College High School",
  [HighSchool.TravisHighSchool]: "Travis High School",
  [HighSchool.TroyHighSchool]: "Troy High School",
  [HighSchool.UniversityHigh]: "University High",
  [HighSchool.VandegriftHighSchool]: "Vandegrift High School",
  [HighSchool.VeteransMemorialHighSchool]: "Veterans Memorial High School",
  [HighSchool.VistaRidgeHighSchool]: "Vista Ridge High School",
  [HighSchool.WacoHigh]: "Waco High",
  [HighSchool.WaelderSchoolPK12]: "Waelder School (PK-12)",
  [HighSchool.WagnerHighSchool]: "Wagner High School",
  [HighSchool.WarrenHighSchool]: "Warren High School",
  [HighSchool.WeissHighSchool]: "Weiss High School",
  [HighSchool.WestlakeHighSchool]: "Westlake High School",
  [HighSchool.WestwoodHighSchool]: "Westwood High School",
  [HighSchool.WhitneyHighSchool]: "Whitney High School",
  [HighSchool.WileyOpportunityCenter]: "Wiley Opportunity Center",
  [HighSchool.WimberleyHighSchool]: "Wimberley High School"
};
