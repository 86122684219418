import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  ConstructionStage,
  ConstructionStageLabel,
  FemaFloodPlain,
  FemaFloodPlainLabel,
  LotDescription,
  LotDescriptionLabel,
  MlsArea,
  MlsAreaLabel,
  TypeCategory,
  TypeCategoryLabel
} from "markets/abor/enums";

export const femaFloodPlainRule = {
  label: "FEMA Flood Plain",
  options: getOptionsFromEnumAndMap(
    Object.values(FemaFloodPlain),
    FemaFloodPlainLabel
  ),
  lastOptions: [FemaFloodPlain.No],
  noneOption: [FemaFloodPlain.No],
  requiredToSubmit: true,
  rows: 2
};

export const propertyRules: FormRules = {
  lotDimension: { label: "Lot Dimensions", maxLength: 25 },
  lotSize: {
    label: "Lot Size/Acreage",
    maxLength: 25,
    requiredToSubmit: true,
    tooltip: "For less than one acre use a decimal - Example .25",
    numberFormat: {
      decimalScale: 10
    }
  },
  mlsArea: {
    label: "Area",
    options: getOptionsFromEnumAndMap(Object.values(MlsArea), MlsAreaLabel)
  },
  propertyType: {
    label: "Property Type",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(TypeCategory),
      TypeCategoryLabel
    )
  },
  constructionStage: {
    label: "Construction Stage",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(ConstructionStage),
      ConstructionStageLabel
    )
  },
  lotDescription: {
    label: "Lot Description",
    options: getOptionsFromEnumAndMap(
      Object.values(LotDescription),
      LotDescriptionLabel
    ),
    lastOptions: [LotDescription.None],
    noneOption: [LotDescription.None],
    requiredToSubmit: true,
    rows: 2
  },
  legalDescription: {
    label: "Legal Description",
    maxLength: 238,
    requiredToSubmit: true,
    tooltip: "If Legal Description is unknown, enter Street Address"
  },
  taxLot: { label: "Tax Lot", maxLength: 25, requiredToSubmit: true },
  taxId: {
    label: "Tax ID #",
    maxLength: 50,
    requiredToSubmit: true,
    tooltip: "If Tax ID is unknown, enter Street Address"
  }
};
