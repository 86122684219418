export enum StreetType {
  ALY = "aly",
  ANX = "anx",
  ARC = "arc",
  AVE = "ave",
  BCH = "bch",
  BG = "bg",
  BGS = "bgs",
  BLF = "blf",
  BLFS = "blfs",
  BLVD = "blvd",
  BND = "bnd",
  BR = "br",
  BRG = "brg",
  BRK = "brk",
  BRKS = "brks",
  BTM = "btm",
  BYP = "byp",
  BYU = "byu",
  Canyon = "canyon",
  Cape = "cape",
  CIR = "cir",
  CIRS = "cirs",
  CLB = "clb",
  CLF = "clf",
  CLFS = "clfs",
  CMN = "cmn",
  CMS = "cms",
  COR = "cor",
  CORS = "cors",
  Cove = "cove",
  Coves = "coves",
  CP = "cp",
  CRES = "cres",
  CRK = "crk",
  CRSE = "crse",
  CRST = "crst",
  CSWY = "cswy",
  CT = "ct",
  CTR = "ctr",
  CTRS = "ctrs",
  CTS = "cts",
  CURV = "curv",
  DL = "dl",
  DM = "dm",
  DR = "dr",
  DRS = "drs",
  DV = "dv",
  EST = "est",
  ESTS = "ests",
  EXPY = "expy",
  EXT = "ext",
  EXTS = "exts",
  FALL = "fall",
  FLD = "fld",
  FLDS = "flds",
  FLS = "fls",
  FLT = "flt",
  FLTS = "flts",
  FRD = "frd",
  FRDS = "frds",
  FRG = "frg",
  FRGS = "frgs",
  FRK = "frk",
  FRKS = "frks",
  FRST = "frst",
  FRY = "fry",
  FT = "ft",
  FWY = "fwy",
  GDN = "gdn",
  GDNS = "gdns",
  GLN = "gln",
  GLNS = "glns",
  GRN = "grn",
  GRNS = "grns",
  GRV = "grv",
  GRVS = "grvs",
  GTWY = "gtwy",
  HBR = "hbr",
  HBRS = "hbrs",
  HL = "hl",
  HLS = "hls",
  HOLW = "holw",
  HTS = "hts",
  HVN = "hvn",
  HWY = "hwy",
  INLT = "inlt",
  IS = "is",
  ISLE = "isle",
  ISS = "iss",
  JCT = "jct",
  JCTS = "jcts",
  KNL = "knl",
  KNLS = "knls",
  KY = "ky",
  KYS = "kys",
  LAND = "land",
  LCK = "lck",
  LCKS = "lcks",
  LDG = "ldg",
  LF = "lf",
  LGT = "lgt",
  LGTS = "lgts",
  LK = "lk",
  LKS = "lks",
  LN = "ln",
  LNDG = "lndg",
  LOOP = "loop",
  MALL = "mall",
  MDW = "mdw",
  MDWS = "mdws",
  MEWS = "mews",
  ML = "ml",
  MLS = "mls",
  MNR = "mnr",
  MNRS = "mnrs",
  MSN = "msn",
  MT = "mt",
  MTN = "mtn",
  MTNS = "mtns",
  MTWY = "mtwy",
  NCK = "nck",
  OPAS = "opas",
  ORCH = "orch",
  OVAL = "oval",
  PARK = "park",
  PASS = "pass",
  PATH = "path",
  PIKE = "pike",
  PINE = "pine",
  PKWY = "pkwy",
  PL = "pl",
  PLN = "pln",
  PLNS = "plns",
  PLZ = "plz",
  PNE = "pne",
  PNES = "pnes",
  PNS = "pns",
  PR = "pr",
  PRT = "prt",
  PRTS = "prts",
  PSGE = "psge",
  PT = "pt",
  PTS = "pts",
  RADL = "radl",
  RAMP = "ramp",
  RD = "rd",
  RDG = "rdg",
  RDGS = "rdgs",
  RDS = "rds",
  RIV = "riv",
  RNCH = "rnch",
  ROW = "row",
  RPD = "rpd",
  RPDS = "rpds",
  RST = "rst",
  RTE = "rte",
  RUE = "rue",
  RUN = "run",
  SHL = "shl",
  SHLS = "shls",
  SHR = "shr",
  SHRS = "shrs",
  SKWY = "skwy",
  SMT = "smt",
  SPG = "spg",
  SPGS = "spgs",
  SPUR = "spur",
  SQ = "sq",
  SQS = "sqs",
  ST = "st",
  STA = "sta",
  STRA = "stra",
  STRM = "strm",
  STS = "sts",
  TER = "ter",
  TPKE = "tpke",
  TRAK = "trak",
  TRCE = "trce",
  TRFY = "trfy",
  TRL = "trl",
  TRLR = "trlr",
  TRWY = "trwy",
  TUNL = "tunl",
  UN = "un",
  UNS = "uns",
  UPAS = "upas",
  VIA = "via",
  VIS = "vis",
  VL = "vl",
  VLG = "vlg",
  VLGS = "vlgs",
  VLY = "vly",
  VLYS = "vlys",
  VW = "vw",
  VWS = "vws",
  WALK = "walk",
  WALL = "wall",
  WAY = "way",
  WAYS = "ways",
  WL = "wl",
  WLS = "wls",
  XING = "xing",
  XRD = "xrd",
  XRDS = "xrds",
  None = "none"
}
export const StreetTypeLabel = new Map<StreetType, string>([
  [StreetType.ALY, "ALY"],
  [StreetType.ANX, "ANX"],
  [StreetType.ARC, "ARC"],
  [StreetType.AVE, "AVE"],
  [StreetType.BCH, "BCH"],
  [StreetType.BG, "BG"],
  [StreetType.BGS, "BGS"],
  [StreetType.BLF, "BLF"],
  [StreetType.BLFS, "BLFS"],
  [StreetType.BLVD, "BLVD"],
  [StreetType.BND, "BND"],
  [StreetType.BR, "BR"],
  [StreetType.BRG, "BRG"],
  [StreetType.BRK, "BRK"],
  [StreetType.BRKS, "BRKS"],
  [StreetType.BTM, "BTM"],
  [StreetType.BYP, "BYP"],
  [StreetType.BYU, "BYU"],
  [StreetType.Canyon, "Canyon"],
  [StreetType.Cape, "Cape"],
  [StreetType.CIR, "CIR"],
  [StreetType.CIRS, "CIRS"],
  [StreetType.CLB, "CLB"],
  [StreetType.CLF, "CLF"],
  [StreetType.CLFS, "CLFS"],
  [StreetType.CMN, "CMN"],
  [StreetType.CMS, "CMS"],
  [StreetType.COR, "COR"],
  [StreetType.CORS, "CORS"],
  [StreetType.Cove, "Cove"],
  [StreetType.Coves, "Coves"],
  [StreetType.CP, "CP"],
  [StreetType.CRES, "CRES"],
  [StreetType.CRK, "CRK"],
  [StreetType.CRSE, "CRSE"],
  [StreetType.CRST, "CRST"],
  [StreetType.CSWY, "CSWY"],
  [StreetType.CT, "CT"],
  [StreetType.CTR, "CTR"],
  [StreetType.CTRS, "CTRS"],
  [StreetType.CTS, "CTS"],
  [StreetType.CURV, "CURV"],
  [StreetType.DL, "DL"],
  [StreetType.DM, "DM"],
  [StreetType.DR, "DR"],
  [StreetType.DRS, "DRS"],
  [StreetType.DV, "DV"],
  [StreetType.EST, "EST"],
  [StreetType.ESTS, "ESTS"],
  [StreetType.EXPY, "EXPY"],
  [StreetType.EXT, "EXT"],
  [StreetType.EXTS, "EXTS"],
  [StreetType.FALL, "FALL"],
  [StreetType.FLD, "FLD"],
  [StreetType.FLDS, "FLDS"],
  [StreetType.FLS, "FLS"],
  [StreetType.FLT, "FLT"],
  [StreetType.FLTS, "FLTS"],
  [StreetType.FRD, "FRD"],
  [StreetType.FRDS, "FRDS"],
  [StreetType.FRG, "FRG"],
  [StreetType.FRGS, "FRGS"],
  [StreetType.FRK, "FRK"],
  [StreetType.FRKS, "FRKS"],
  [StreetType.FRST, "FRST"],
  [StreetType.FRY, "FRY"],
  [StreetType.FT, "FT"],
  [StreetType.FWY, "FWY"],
  [StreetType.GDN, "GDN"],
  [StreetType.GDNS, "GDNS"],
  [StreetType.GLN, "GLN"],
  [StreetType.GLNS, "GLNS"],
  [StreetType.GRN, "GRN"],
  [StreetType.GRNS, "GRNS"],
  [StreetType.GRV, "GRV"],
  [StreetType.GRVS, "GRVS"],
  [StreetType.GTWY, "GTWY"],
  [StreetType.HBR, "HBR"],
  [StreetType.HBRS, "HBRS"],
  [StreetType.HL, "HL"],
  [StreetType.HLS, "HLS"],
  [StreetType.HOLW, "HOLW"],
  [StreetType.HTS, "HTS"],
  [StreetType.HVN, "HVN"],
  [StreetType.HWY, "HWY"],
  [StreetType.INLT, "INLT"],
  [StreetType.IS, "IS"],
  [StreetType.ISLE, "ISLE"],
  [StreetType.ISS, "ISS"],
  [StreetType.JCT, "JCT"],
  [StreetType.JCTS, "JCTS"],
  [StreetType.KNL, "KNL"],
  [StreetType.KNLS, "KNLS"],
  [StreetType.KY, "KY"],
  [StreetType.KYS, "KYS"],
  [StreetType.LAND, "LAND"],
  [StreetType.LCK, "LCK"],
  [StreetType.LCKS, "LCKS"],
  [StreetType.LDG, "LDG"],
  [StreetType.LF, "LF"],
  [StreetType.LGT, "LGT"],
  [StreetType.LGTS, "LGTS"],
  [StreetType.LK, "LK"],
  [StreetType.LKS, "LKS"],
  [StreetType.LN, "LN"],
  [StreetType.LNDG, "LNDG"],
  [StreetType.LOOP, "LOOP"],
  [StreetType.MALL, "MALL"],
  [StreetType.MDW, "MDW"],
  [StreetType.MDWS, "MDWS"],
  [StreetType.MEWS, "MEWS"],
  [StreetType.ML, "ML"],
  [StreetType.MLS, "MLS"],
  [StreetType.MNR, "MNR"],
  [StreetType.MNRS, "MNRS"],
  [StreetType.MSN, "MSN"],
  [StreetType.MT, "MT"],
  [StreetType.MTN, "MTN"],
  [StreetType.MTNS, "MTNS"],
  [StreetType.MTWY, "MTWY"],
  [StreetType.NCK, "NCK"],
  [StreetType.OPAS, "OPAS"],
  [StreetType.ORCH, "ORCH"],
  [StreetType.OVAL, "OVAL"],
  [StreetType.PARK, "PARK"],
  [StreetType.PASS, "PASS"],
  [StreetType.PATH, "PATH"],
  [StreetType.PIKE, "PIKE"],
  [StreetType.PINE, "PINE"],
  [StreetType.PKWY, "PKWY"],
  [StreetType.PL, "PL"],
  [StreetType.PLN, "PLN"],
  [StreetType.PLNS, "PLNS"],
  [StreetType.PLZ, "PLZ"],
  [StreetType.PNE, "PNE"],
  [StreetType.PNES, "PNES"],
  [StreetType.PNS, "PNS"],
  [StreetType.PR, "PR"],
  [StreetType.PRT, "PRT"],
  [StreetType.PRTS, "PRTS"],
  [StreetType.PSGE, "PSGE"],
  [StreetType.PT, "PT"],
  [StreetType.PTS, "PTS"],
  [StreetType.RADL, "RADL"],
  [StreetType.RAMP, "RAMP"],
  [StreetType.RD, "RD"],
  [StreetType.RDG, "RDG"],
  [StreetType.RDGS, "RDGS"],
  [StreetType.RDS, "RDS"],
  [StreetType.RIV, "RIV"],
  [StreetType.RNCH, "RNCH"],
  [StreetType.ROW, "ROW"],
  [StreetType.RPD, "RPD"],
  [StreetType.RPDS, "RPDS"],
  [StreetType.RST, "RST"],
  [StreetType.RTE, "RTE"],
  [StreetType.RUE, "RUE"],
  [StreetType.RUN, "RUN"],
  [StreetType.SHL, "SHL"],
  [StreetType.SHLS, "SHLS"],
  [StreetType.SHR, "SHR"],
  [StreetType.SHRS, "SHRS"],
  [StreetType.SKWY, "SKWY"],
  [StreetType.SMT, "SMT"],
  [StreetType.SPG, "SPG"],
  [StreetType.SPGS, "SPGS"],
  [StreetType.SPUR, "SPUR"],
  [StreetType.SQ, "SQ"],
  [StreetType.SQS, "SQS"],
  [StreetType.ST, "ST"],
  [StreetType.STA, "STA"],
  [StreetType.STRA, "STRA"],
  [StreetType.STRM, "STRM"],
  [StreetType.STS, "STS"],
  [StreetType.TER, "TER"],
  [StreetType.TPKE, "TPKE"],
  [StreetType.TRAK, "TRAK"],
  [StreetType.TRCE, "TRCE"],
  [StreetType.TRFY, "TRFY"],
  [StreetType.TRL, "TRL"],
  [StreetType.TRLR, "TRLR"],
  [StreetType.TRWY, "TRWY"],
  [StreetType.TUNL, "TUNL"],
  [StreetType.UN, "UN"],
  [StreetType.UNS, "UNS"],
  [StreetType.UPAS, "UPAS"],
  [StreetType.VIA, "VIA"],
  [StreetType.VIS, "VIS"],
  [StreetType.VL, "VL"],
  [StreetType.VLG, "VLG"],
  [StreetType.VLGS, "VLGS"],
  [StreetType.VLY, "VLY"],
  [StreetType.VLYS, "VLYS"],
  [StreetType.VW, "VW"],
  [StreetType.VWS, "VWS"],
  [StreetType.WALK, "WALK"],
  [StreetType.WALL, "WALL"],
  [StreetType.WAY, "WAY"],
  [StreetType.WAYS, "WAYS"],
  [StreetType.WL, "WL"],
  [StreetType.WLS, "WLS"],
  [StreetType.XING, "XING"],
  [StreetType.XRD, "XRD"],
  [StreetType.XRDS, "XRDS"],
  [StreetType.None, "None"]
]);
