export enum ConstructionMaterial {
  AluminumSiding = "aluminumSiding",
  Brick = "brick",
  Cedar = "cedar",
  Concrete = "concrete",
  FiberCement = "fiberCement",
  FiberglassSiding = "fiberglassSiding",
  Frame = "frame",
  MetalSiding = "metalSiding",
  Other = "other",
  RockStone = "rockStone",
  SeeRemarks = "seeRemarks",
  Siding = "siding",
  SteelSiding = "steelSiding",
  StoneVeneer = "stoneVeneer",
  Stucco = "stucco",
  Unknown = "unknown",
  VinylSiding = "vinylSiding",
  Wood = "wood"
}

export const ConstructionMaterialLabel = new Map<ConstructionMaterial, string>([
  [ConstructionMaterial.AluminumSiding, "Aluminum Siding"],
  [ConstructionMaterial.Brick, "Brick"],
  [ConstructionMaterial.Cedar, "Cedar"],
  [ConstructionMaterial.Concrete, "Concrete"],
  [ConstructionMaterial.FiberCement, "Fiber Cement"],
  [ConstructionMaterial.FiberglassSiding, "Fiberglass Siding"],
  [ConstructionMaterial.Frame, "Frame"],
  [ConstructionMaterial.MetalSiding, "Metal Siding"],
  [ConstructionMaterial.Other, "Other"],
  [ConstructionMaterial.RockStone, "Rock/Stone"],
  [ConstructionMaterial.SeeRemarks, "See Remarks"],
  [ConstructionMaterial.Siding, "Siding"],
  [ConstructionMaterial.SteelSiding, "Steel Siding"],
  [ConstructionMaterial.StoneVeneer, "Stone Veneer"],
  [ConstructionMaterial.Stucco, "Stucco"],
  [ConstructionMaterial.Unknown, "Unknown"],
  [ConstructionMaterial.VinylSiding, "Vinyl Siding"],
  [ConstructionMaterial.Wood, "Wood"]
]);
