import React, { Fragment } from "react";
import { Paper, Typography, styled } from "@mui/material";

export const BoxStyled = styled(Paper)(() => ({
  height: "100%",
  "& .MuiGrid-direction-xs-column > .MuiGrid-item": {
    width: "100%"
  }
}));

export function SectionStatusTitle({
  children
}: {
  children: React.ReactNode;
  title?: string;
}): JSX.Element {
  return (
    <Fragment>
      <BoxStyled elevation={0}>
        <Typography variant="subtitle2" sx={{ pb: 1.5 }} color="primary">
          {children}
        </Typography>
      </BoxStyled>
    </Fragment>
  );
}
