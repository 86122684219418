import React, { Fragment } from "react";
import { Typography, Divider } from "@mui/material";

function SectionTitle({
  title,
  helperText,
  hasError
}: {
  title: string;
  helperText?: string;
  hasError?: boolean;
}): JSX.Element {
  return (
    <Fragment>
      <Typography variant="h3">{title}</Typography>
      <Divider sx={{ mt: 1 }} />
      {helperText && (
        <Typography variant="caption" color={hasError ? "error" : ""}>
          {helperText}
        </Typography>
      )}
    </Fragment>
  );
}

export const MemoizedSectionTitle = React.memo(SectionTitle);
