import React, { Fragment } from "react";
import { Box } from "@mui/material";

import {
  FormContext,
  FormContextActions,
  getOptionValueFromRule,
  formContextReducer,
  FormMode
} from "@homesusa/form";
import {
  GridTable,
  GridProvider,
  GridToolbar,
  FetchData,
  Columns,
  Column
} from "@homesusa/grid-table";
import { Format } from "@homesusa/core";
import { ListFilter } from "../interfaces/filters";
import { NewHome, ReportDataset } from "../interfaces";
import { ReportStatus } from "../enums";
import { ReportsContext } from "../contexts";
import { NewHomesFilters } from "./new-homes-filters.component";
import { ReportDates } from "./report-dates.component";

type columnReport = Columns<NewHome>;

export function NewHomesReport(): JSX.Element {
  const {
    services: { getNewHomes },
    marketRules
  } = React.useContext(ReportsContext);
  const [formState, formDispatch] = React.useReducer<
    (state: ListFilter, action: FormContextActions) => ListFilter
  >(formContextReducer, Object.assign({}));

  const [data, setData] = React.useState<ReportDataset<NewHome>>({
    data: [],
    total: 0,
    agentLastUpdate: undefined,
    officeLastUpdate: undefined,
    listingLastUpdate: undefined
  });

  const fetchData = async ({
    pageSize,
    pageIndex,
    sortBy,
    globalFilter
  }: FetchData): Promise<void> => {
    if (formState.status == null) {
      return;
    }
    return getNewHomes({
      ...formState,
      status: formState.status ?? ReportStatus.ForSale,
      top: pageSize,
      skip: pageIndex,
      sortBy,
      searchBy: globalFilter
    }).then((response) => {
      setData(response);
    });
  };

  const fetchDataForDownloading = async (): Promise<NewHome[]> => {
    return getNewHomes({
      ...formState,
      top: data.total,
      skip: 0
    }).then((response) => {
      return response.data;
    });
  };

  const columns: columnReport = React.useMemo(() => {
    let cols: columnReport = [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1
      },
      {
        Header: "Builder",
        accessor: "ownerName"
      },
      {
        Header: "MLS #",
        accessor: "mlsNumber"
      },
      {
        Header: "Address",
        accessor: "address"
      },
      {
        Header: "City",
        accessor: (data: NewHome): string =>
          getOptionValueFromRule(marketRules.city, data.city),
        id: "city"
      },
      {
        Header: "Subdivision",
        accessor: "subdivision"
      },
      {
        Header: "Zip",
        accessor: "zip"
      },
      {
        Header: "B/B/B/G",
        accessor: (data: NewHome): string =>
          `${data.bedrooms ?? 0}/${data.fullBath ?? 0}/${data.halfBath ?? 0}/${
            data.garageSpaces ?? 0
          }`,
        id: "bedrooms",
        headertooltip: "Bed / Bath / Half Bath / Garage"
      },
      {
        Header: "List Price",
        accessor: (data: NewHome): string => Format.Money(data.listPrice),
        id: "listPrice"
      }
    ];

    const sqft: Column<NewHome> = {
      Header: "SQFT",
      accessor: "sqFt"
    };
    if (formState.status == ReportStatus.Sold) {
      cols = [
        ...cols,
        {
          Header: "Sale Price",
          accessor: (data: NewHome): string => Format.Money(data.soldPrice),
          id: "soldPrice"
        },
        {
          Header: "SP $ / SF",
          accessor: (data: NewHome): string => Format.Money(data.soldSqft),
          id: "soldSqft"
        },
        sqft,
        {
          Header: "Sold Date",
          accessor: (data: NewHome): string => Format.Date(data.closeDate),
          id: "closeDate"
        },
        {
          Header: "Selling Agent",
          accessor: "listingAgent"
        }
      ];
    } else {
      cols = [
        ...cols,
        {
          Header: "LP $ / SF",
          accessor: (data: NewHome): string => Format.Money(data.listSqft),
          id: "listSqft"
        },
        sqft,
        {
          Header: "Listing Agent",
          accessor: "listingAgent"
        }
      ];
    }

    return [
      ...cols,
      {
        Header: "Agent Phone",
        accessor: "agentPhone"
      },
      {
        Header: "CDOM",
        accessor: "cdom"
      }
    ];
  }, [formState.status]);

  return (
    <Fragment>
      <FormContext.Provider
        value={{
          formState: formState,
          formDispatch,
          formMode: FormMode.Update,
          formRules: {},
          otherProps: {}
        }}
      >
        <GridProvider
          options={{
            columns,
            data: data?.data,
            showHeaderTooltip: true
          }}
          totalRows={data?.total}
        >
          <NewHomesFilters onFetch={fetchData} />
          <GridToolbar>
            <GridToolbar.ExportButtons
              onClick={fetchDataForDownloading}
              fileName="new-homes"
            />
            <GridToolbar.SearchFilter />
          </GridToolbar>
          <Box mt={2} />
          <GridTable />
          <ReportDates data={data} />
        </GridProvider>
      </FormContext.Provider>
    </Fragment>
  );
}
