import React from "react";

import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  MlsStatus,
  SellerConcessionDesc,
  SellerConcessionDescLabel
} from "markets/ctx/enums";
import {
  ClosedDateTooltip,
  ContractDateTooltip,
  WithdrawalDateTooltip
} from "core/components";
import { getNextDayDate, getYesterday } from "core/utils/dates";

export const buyerAgentRules = {
  hasBuyerAgent: {
    label: "Represented by Buyer's Agent"
  },
  hasSecondBuyerAgent: {
    label: "Represented by second Buyer's Agent"
  }
};

export const rulesByStatus = new Map<MlsStatus, FormRules>([
  [
    MlsStatus.Pending,
    {
      estimatedClosedDate: {
        label: "Estimated Close Date",
        customFieldProps: {
          errorMessages: {
            minDate: "Estimated Close Date must be after Today's Date"
          }
        },
        minDate: getNextDayDate(new Date()),
        tooltip: <ClosedDateTooltip />,
        requiredToSubmit: true
      },
      contractDate: {
        label: "Contract Date",
        tooltip: <ContractDateTooltip />,
        maxDate: new Date(),
        requiredToSubmit: true
      },
      hasBuyerAgent: buyerAgentRules.hasBuyerAgent,
      agentId: {
        label: "Buyer's Agent"
      }
    }
  ],
  [
    MlsStatus.CompSold,
    {
      contractDate: {
        label: "Under Contract Date",
        tooltip: <ContractDateTooltip />,
        maxDate: getYesterday(),
        requiredToSubmit: true
      },
      closedDate: {
        maxDate: new Date(),
        tooltip: <ClosedDateTooltip />,
        label: "Close Date",
        customFieldProps: {
          errorMessages: {
            minDate: "Close Date must be after contract date"
          }
        },
        requiredToSubmit: true
      },
      closePrice: {
        label: "Sold Price",
        numberFormat: {
          max: 2000000,
          min: 100000,
          prefix: "$",
          thousandSeparator: true
        },
        maxLength: 10,
        requiredToSubmit: true
      },
      ...buyerAgentRules,
      agentId: {
        label: "Buyer's Agent"
      },
      agentIdSecond: {
        label: "Second Buyer's Agent"
      },
      sellConcess: {
        label: "Seller Concessions($)",
        maxLength: 7,
        numberFormat: {
          min: 0,
          max: 99999,
          prefix: "$",
          thousandSeparator: true
        },
        requiredToSubmit: true
      },
      sellerConcessionDescription: {
        label: "Financing",
        options: getOptionsFromEnumAndMap(
          Object.values(SellerConcessionDesc),
          SellerConcessionDescLabel
        ),
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Withdrawn,
    {
      withdrawalDate: {
        label: "Withdrawal Date",
        tooltip: <WithdrawalDateTooltip />,
        maxDate: new Date(),
        customFieldProps: {
          errorMessages: {
            maxDate: "Must be today or before the minimum date"
          }
        },
        requiredToSubmit: true
      },
      withdrawalReason: {
        label: "Early Withdrawal Reason",
        maxLength: 30,
        requiredToSubmit: true
      },
      withdrawalActiveListingAgreement: {
        label: "Withdrawn with Active Listing Agreement?",
        requiredToSubmit: true
      }
    }
  ],
  [MlsStatus.Active, {}],
  [
    MlsStatus.ActiveUnderContract,
    {
      estimatedClosedDate: {
        label: "Estimated Close Date",
        customFieldProps: {
          errorMessages: {
            minDate: "Estimated Close Date must be after Today's Date"
          }
        },
        minDate: getNextDayDate(new Date()),
        tooltip: <ClosedDateTooltip />,
        requiredToSubmit: true
      },
      contractDate: {
        label: "Contract Date",
        tooltip: <ContractDateTooltip />,
        maxDate: new Date(),
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.ComingSoon,
    {
      offMarketDate: {
        label: "Off Market Date",
        requiredToSubmit: true
      },
      backOnMarketDate: {
        label: "Back On Market Date",
        requiredToSubmit: true
      }
    }
  ]
]);
