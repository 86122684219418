import { AlertType } from "modules/dashboard/enums";
import React from "react";
import { useLocation } from "react-router-dom";

import { SummaryData } from "../../interfaces";
import { AlertSummaryContext } from "./alert-summary.context";

export const AlertSummaryProvider = ({
  gridRows = 10,
  children
}: {
  children: React.ReactNode;
  gridRows?: number;
}): JSX.Element => {
  const { search } = useLocation();
  const [currentData, setCurrentData] = React.useState<SummaryData>();
  const [alertIncidents, setAlertIncidents] = React.useState<
    Record<AlertType, number>
  >(Object.assign({}));

  const firstSelected: string | undefined = React.useMemo(() => {
    if (search && search !== "") {
      const selected = search.split("=")[1];
      return selected;
    }
    const alertsKeysWithIncidents = Object.entries(alertIncidents).find(
      ([, value]) => value > 0
    );
    return alertsKeysWithIncidents ? alertsKeysWithIncidents[0] : undefined;
  }, [alertIncidents]);

  return (
    <AlertSummaryContext.Provider
      value={{
        currentData,
        setCurrentData,
        firstSelected,
        gridRows,
        alertIncidents,
        setAlertIncidents
      }}
    >
      {children}
    </AlertSummaryContext.Provider>
  );
};
