import { FormRules } from "@homesusa/form";
import { salesOfficeRules } from "core/rules/sales-office";
import { amarilloCityRule } from "../../sections";

export const amarilloSalesOfficeRules: FormRules = {
  ...salesOfficeRules,
  salesOfficeCity: {
    ...amarilloCityRule,
    required: false
  },
  isSalesOffice: {
    label: "Sales Office"
  }
};
