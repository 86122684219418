import { MarketCode } from "@homesusa/core";
import { GridResponse } from "@homesusa/grid-table";
import axios from "axios";
import { DiscrepancyData, DiscrepancyDetail } from "core/interfaces";
import { getGatewayMarket } from "core/utils";

export const getDiscrepancyUrl = (marketCode: MarketCode): string => {
  const gatewayMarket = getGatewayMarket(marketCode);
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY}/api/${gatewayMarket}`;
  return apiUrl;
};
const fetchDiscrepancyAnalysisReport = async <
  TResponse,
  TParam = Record<string, unknown>
>(
  market: MarketCode,
  params?: TParam
): Promise<TResponse> => {
  const apiUrl = getDiscrepancyUrl(market);
  const response = await axios.get(
    `${apiUrl}/residential-discrepancy`,
    params ? { params } : undefined
  );
  return response.data;
};
export const getDiscrepancyAnalysis = async (
  market: MarketCode
): Promise<DiscrepancyData> => fetchDiscrepancyAnalysisReport(market);

export const getDiscrepancyAnalysisDetail = async (
  market: MarketCode,
  params?: { isDetail: boolean; skip: number; top: number }
): Promise<GridResponse<DiscrepancyDetail>> =>
  fetchDiscrepancyAnalysisReport(market, params);
