import React from "react";

export const useHasChanges = <T = unknown>(
  initialValue?: T
): ((newValue: T, updateOldValue?: boolean) => boolean) => {
  const value = React.useRef<T | undefined>(initialValue);

  const checkHasChanges = React.useCallback(
    (newValue?: T, updateOldValue?: boolean): boolean => {
      const hasChanges =
        JSON.stringify(newValue) !== JSON.stringify(value.current);

      if (hasChanges && updateOldValue) value.current = newValue;

      return hasChanges;
    },
    []
  );

  return checkHasChanges;
};
