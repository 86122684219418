import React from "react";

import { AgentType } from "../enums";
import { useAgentListingsGridColumns } from "../hooks/grid/use-agent-report-columns";
import { AgentsReport } from "./agents-report.component";

export function ListingAgentsReport(): JSX.Element {
  const columns = useAgentListingsGridColumns(AgentType.Listing);

  return (
    <AgentsReport
      columns={columns}
      agentType={AgentType.Listing}
    ></AgentsReport>
  );
}
