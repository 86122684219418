export enum Carport {
  One = "one",
  Two = "two",
  Three = "three",
  None = "none"
}

export const CarportLabel = new Map<Carport, string>([
  [Carport.One, "1"],
  [Carport.Two, "2"],
  [Carport.Three, "3+"],
  [Carport.None, "None"]
]);
