export enum LotDescription {
  Acreage = "acreage",
  AdjacentGreenbelt = "adjacentGreenbelt",
  CornerLot = "cornerLot",
  CulDeSac = "culDeSac",
  FewTrees = "fewTrees",
  Greenbelt = "greenbelt",
  InteriorLot = "interiorLot",
  IrregularLot = "irregularLot",
  Landscaped = "landscaped",
  LrgBackyardGrass = "lrgBackyardGrass",
  ManyTrees = "manyTrees",
  OnGolfCourse = "onGolfCourse",
  ParkView = "parkView",
  SprinklerSystem = "sprinklerSystem",
  Subdivision = "subdivision",
  TankPond = "tankPond",
  WaterLakeView = "waterLakeView",
  Waterfront = "waterfront"
}

export const LotDescriptionLabel = new Map<LotDescription, string>([
  [LotDescription.Acreage, "Acreage"],
  [LotDescription.AdjacentGreenbelt, "Adjacent to Greenbelt"],
  [LotDescription.CornerLot, "Corner Lot"],
  [LotDescription.CulDeSac, "Cul-De-Sac"],
  [LotDescription.FewTrees, "Few Trees"],
  [LotDescription.Greenbelt, "Greenbelt"],
  [LotDescription.InteriorLot, "Interior Lot"],
  [LotDescription.IrregularLot, "Irregular Lot"],
  [LotDescription.Landscaped, "Landscaped"],
  [LotDescription.LrgBackyardGrass, "Lrg. Backyard Grass"],
  [LotDescription.ManyTrees, "Many Trees"],
  [LotDescription.OnGolfCourse, "On Golf Course"],
  [LotDescription.ParkView, "Park View"],
  [LotDescription.SprinklerSystem, "Sprinkler System"],
  [LotDescription.Subdivision, "Subdivision"],
  [LotDescription.TankPond, "Tank/ Pond"],
  [LotDescription.WaterLakeView, "Water/Lake View"],
  [LotDescription.Waterfront, "Waterfront"]
]);
