import { Format, MarketCode } from "@homesusa/core";

export const constructionStartYearRules = (
  currentMarket?: MarketCode
): Record<string, unknown> => {
  const today = new Date();
  const minYear = new Date();
  const maxYear = new Date();
  const yearsToSubtract = currentMarket === MarketCode.Sabor ? 15 : 6;
  minYear.setFullYear(today.getFullYear() - yearsToSubtract);
  maxYear.setFullYear(today.getFullYear() + 2);

  return {
    maxLength: 4,
    numberFormat: {
      min: minYear.getFullYear(),
      max: maxYear.getFullYear()
    }
  };
};

export const constCompletionDateRules = (
  completeStage: string,
  constructionStage?: string,
  currentMarket?: MarketCode
): Record<string, unknown> => {
  const defaultRules = {
    helperText:
      constructionStage == null ? "Select a Construction Status" : undefined,
    requiredToSubmit: constructionStage != null,
    disabled: constructionStage == null
  };

  const today = new Date();
  const minDate = new Date(today);
  const maxDate = new Date(today);
  const yearsToSubtract = currentMarket === MarketCode.Sabor ? 14 : 5;
  const errorMessages: Record<string, string> = {
    minDate: ""
  };

  if (completeStage !== constructionStage) {
    minDate.setDate(today.getDate() + 1);
    maxDate.setFullYear(today.getFullYear() + 2);
    errorMessages["minDate"] = `Must be after ${Format.Date(today)}`;
    errorMessages["maxDate"] = "";
  } else {
    minDate.setFullYear(today.getFullYear() - yearsToSubtract);
    errorMessages["maxDate"] = `Must be on or before ${Format.Date(today)}`;
    errorMessages["minDate"] = `Must be after ${Format.Date(minDate)}`;
  }

  return constructionStage == null || constructionStage === ""
    ? {
        minDate: undefined,
        maxDate: undefined,
        customFieldProps: undefined,
        ...defaultRules
      }
    : {
        minDate,
        maxDate,
        customFieldProps: { errorMessages },
        ...defaultRules
      };
};
