export enum HighSchool {
  Academy = "academy",
  Akins = "akins",
  Anderson = "anderson",
  Austin = "austin",
  Bartlett = "bartlett",
  Bastrop = "bastrop",
  Belton = "belton",
  BeltonNewTechWaskow = "beltonNewTechWaskow",
  Blanco = "blanco",
  BoerneHS = "boerneHS",
  Bowie = "bowie",
  BrucevilleEddy = "brucevilleEddy",
  Buckholts = "buckholts",
  Burnet = "burnet",
  ByronPSteeleII = "byronPSteeleII",
  CameronYoe = "cameronYoe",
  Canyon = "canyon",
  CanyonLake = "canyonLake",
  CedarCreek = "cedarCreek",
  CedarPark = "cedarPark",
  CedarRidge = "cedarRidge",
  Cherokee = "cherokee",
  ChipRicharte = "chipRicharte",
  Comal = "comal",
  CopperasCove = "copperasCove",
  Crockett = "crockett",
  Crossroads = "crossroads",
  Cuero = "cuero",
  DelValle = "delValle",
  DimeBox = "dimeBox",
  DrippingSprings = "drippingSprings",
  EastView = "eastView",
  Elgin = "elgin",
  Ellison = "ellison",
  Evant = "evant",
  Excel = "excel",
  Fayetteville = "fayetteville",
  FlatoniaSecondary = "flatoniaSecondary",
  Florence = "florence",
  Fredericksburg = "fredericksburg",
  Gatesville = "gatesville",
  Genesis = "genesis",
  Georgetown = "georgetown",
  Giddings = "giddings",
  Glenn = "glenn",
  Goldthwaite = "goldthwaite",
  Gonzales = "gonzales",
  GarzaIndep = "garzaIndep",
  Granger = "granger",
  HarkerHeights = "harkerHeights",
  Harper = "harper",
  Hearn = "hearn",
  Hearne = "hearne",
  Hendrickson = "hendrickson",
  HenryTWaskow = "henryTWaskow",
  Holland = "holland",
  Hutto = "hutto",
  JackCHays = "jackCHays",
  Jarrell = "jarrell",
  JohnBConnally = "johnBConnally",
  EastsideMemorial = "eastsideMemorial",
  Killeen = "killeen",
  LagoVista = "lagoVista",
  LaGrange = "laGrange",
  LakeTravis = "lakeTravis",
  Lampasas = "lampasas",
  Lanier = "lanier",
  Lasa = "lasa",
  LaVernia = "laVernia",
  LeanderHigh = "leanderHigh",
  Lehman = "lehman",
  Lexington = "lexington",
  LibertyHill = "libertyHill",
  Llano = "llano",
  Lockhart = "lockhart",
  LockhartPride = "lockhartPride",
  Lometa = "lometa",
  Luling = "luling",
  LyndonJohnson = "lyndonJohnson",
  Manor = "manor",
  MarbleFalls = "marbleFalls",
  Marion = "marion",
  Mason = "mason",
  McCallum = "mcCallum",
  McDade = "mcDade",
  McNeil = "mcNeil",
  Memorial = "memorial",
  Milano = "milano",
  Moody = "moody",
  Moulton = "moulton",
  Mumford = "mumford",
  NotApplicable = "notApplicable",
  Navarro = "navarro",
  NewBraunfels = "newBraunfels",
  NewHope = "newHope",
  NixonSmiley = "nixonSmiley",
  Northeast = "northeast",
  Pflugerville = "pflugerville",
  PrairieLea = "prairieLea",
  Pride = "pride",
  Reagan = "reagan",
  Richarte = "richarte",
  RichlandSprings = "richlandSprings",
  Rockdale = "rockdale",
  Rogers = "rogers",
  RosebudLott = "rosebudLott",
  RoundRock = "roundRock",
  RoundTopCarmine = "roundTopCarmine",
  Rouse = "rouse",
  Salado = "salado",
  SamuelClemens = "samuelClemens",
  SamuelVChampion = "samuelVChampion",
  SanMarcos = "sanMarcos",
  SanSaba = "sanSaba",
  SchulenburgSecondar = "schulenburgSecondar",
  Seguin = "seguin",
  Shiner = "shiner",
  Shoemaker = "shoemaker",
  SmithsonValley = "smithsonValley",
  Smithville = "smithville",
  Star = "star",
  StonyPoint = "stonyPoint",
  Taylor = "taylor",
  Temple = "temple",
  Thorndale = "thorndale",
  Thrall = "thrall",
  Travis = "travis",
  Troy = "troy",
  Vandegrift = "vandegrift",
  VistaRidge = "vistaRidge",
  Waelder = "waelder",
  Weimar = "weimar",
  Weiss = "weiss",
  Westlake = "westlake",
  Westwood = "westwood",
  Wimberley = "wimberley",
  Yoakum = "yoakum"
}

export const HighSchoolLabel = new Map<HighSchool, string>([
  [HighSchool.Academy, "Academy"],
  [HighSchool.Akins, "Akins"],
  [HighSchool.Anderson, "Anderson"],
  [HighSchool.Austin, "Austin"],
  [HighSchool.Bartlett, "Bartlett"],
  [HighSchool.Bastrop, "Bastrop"],
  [HighSchool.Belton, "Belton"],
  [HighSchool.BeltonNewTechWaskow, "Belton New Tech Waskow"],
  [HighSchool.Blanco, "Blanco"],
  [HighSchool.BoerneHS, "Boerne HS"],
  [HighSchool.Bowie, "Bowie"],
  [HighSchool.BrucevilleEddy, "Bruceville-Eddy"],
  [HighSchool.Buckholts, "Buckholts"],
  [HighSchool.Burnet, "Burnet"],
  [HighSchool.ByronPSteeleII, "Byron P Steele II"],
  [HighSchool.CameronYoe, "Cameron Yoe"],
  [HighSchool.Canyon, "Canyon"],
  [HighSchool.CanyonLake, "Canyon Lake"],
  [HighSchool.CedarCreek, "Cedar Creek"],
  [HighSchool.CedarPark, "Cedar Park"],
  [HighSchool.CedarRidge, "Cedar Ridge"],
  [HighSchool.Cherokee, "Cherokee"],
  [HighSchool.ChipRicharte, "Chip Richarte"],
  [HighSchool.Comal, "Comal"],
  [HighSchool.CopperasCove, "Copperas Cove"],
  [HighSchool.Crockett, "Crockett"],
  [HighSchool.Crossroads, "Crossroads"],
  [HighSchool.Cuero, "Cuero"],
  [HighSchool.DelValle, "Del Valle"],
  [HighSchool.DimeBox, "Dime Box"],
  [HighSchool.DrippingSprings, "Dripping Springs"],
  [HighSchool.EastView, "East View"],
  [HighSchool.EastsideMemorial, "Eastside Memorial"],
  [HighSchool.Elgin, "Elgin"],
  [HighSchool.Ellison, "Ellison"],
  [HighSchool.Evant, "Evant"],
  [HighSchool.Excel, "Excel"],
  [HighSchool.Fayetteville, "Fayetteville"],
  [HighSchool.FlatoniaSecondary, "Flatonia Secondary"],
  [HighSchool.Florence, "Florence"],
  [HighSchool.Fredericksburg, "Fredericksburg"],
  [HighSchool.GarzaIndep, "Garza Indep"],
  [HighSchool.Gatesville, "Gatesville"],
  [HighSchool.Genesis, "Genesis"],
  [HighSchool.Georgetown, "Georgetown"],
  [HighSchool.Giddings, "Giddings"],
  [HighSchool.Glenn, "Glenn"],
  [HighSchool.Goldthwaite, "Goldthwaite"],
  [HighSchool.Gonzales, "Gonzales"],
  [HighSchool.Granger, "Granger"],
  [HighSchool.HarkerHeights, "Harker Heights"],
  [HighSchool.Harper, "Harper"],
  [HighSchool.Hearn, "Hearn"],
  [HighSchool.Hearne, "Hearne"],
  [HighSchool.Hendrickson, "Hendrickson"],
  [HighSchool.HenryTWaskow, "Henry T Waskow"],
  [HighSchool.Holland, "Holland"],
  [HighSchool.Hutto, "Hutto"],
  [HighSchool.JackCHays, "Jack C Hays"],
  [HighSchool.Jarrell, "Jarrell"],
  [HighSchool.JohnBConnally, "John B Connally"],
  [HighSchool.Killeen, "Killeen"],
  [HighSchool.LaGrange, "La Grange"],
  [HighSchool.LaVernia, "La Vernia"],
  [HighSchool.LagoVista, "Lago Vista"],
  [HighSchool.LakeTravis, "Lake Travis"],
  [HighSchool.Lampasas, "Lampasas"],
  [HighSchool.Lanier, "Lanier"],
  [HighSchool.Lasa, "Lasa"],
  [HighSchool.LeanderHigh, "Leander High"],
  [HighSchool.Lehman, "Lehman"],
  [HighSchool.Lexington, "Lexington"],
  [HighSchool.LibertyHill, "Liberty Hill"],
  [HighSchool.Llano, "Llano"],
  [HighSchool.Lockhart, "Lockhart"],
  [HighSchool.LockhartPride, "Lockhart Pride"],
  [HighSchool.Lometa, "Lometa"],
  [HighSchool.Luling, "Luling"],
  [HighSchool.LyndonJohnson, "Lyndon B Johnson"],
  [HighSchool.Manor, "Manor"],
  [HighSchool.MarbleFalls, "Marble Falls"],
  [HighSchool.Marion, "Marion"],
  [HighSchool.Mason, "Mason"],
  [HighSchool.McCallum, "McCallum"],
  [HighSchool.McDade, "McDade"],
  [HighSchool.McNeil, "McNeil"],
  [HighSchool.Memorial, "Memorial"],
  [HighSchool.Milano, "Milano"],
  [HighSchool.Moody, "Moody"],
  [HighSchool.Moulton, "Moulton"],
  [HighSchool.Mumford, "Mumford"],
  [HighSchool.NotApplicable, "N/A"],
  [HighSchool.Navarro, "Navarro"],
  [HighSchool.NewBraunfels, "New Braunfels"],
  [HighSchool.NewHope, "New Hope"],
  [HighSchool.NixonSmiley, "Nixon-Smiley"],
  [HighSchool.Northeast, "Northeast"],
  [HighSchool.Pflugerville, "Pflugerville"],
  [HighSchool.PrairieLea, "Prairie Lea"],
  [HighSchool.Pride, "Pride"],
  [HighSchool.Reagan, "Reagan"],
  [HighSchool.Richarte, "Richarte"],
  [HighSchool.RichlandSprings, "Richland Springs"],
  [HighSchool.Rockdale, "Rockdale"],
  [HighSchool.Rogers, "Rogers"],
  [HighSchool.RosebudLott, "Rosebud-Lott"],
  [HighSchool.RoundRock, "Round Rock"],
  [HighSchool.RoundTopCarmine, "Round Top-Carmine"],
  [HighSchool.Rouse, "Rouse"],
  [HighSchool.Salado, "Salado"],
  [HighSchool.SamuelClemens, "Samuel Clemens"],
  [HighSchool.SamuelVChampion, "Samuel V Champion"],
  [HighSchool.SanMarcos, "San Marcos"],
  [HighSchool.SanSaba, "San Saba"],
  [HighSchool.SchulenburgSecondar, "Schulenburg Secondar"],
  [HighSchool.Seguin, "Seguin"],
  [HighSchool.Shiner, "Shiner"],
  [HighSchool.Shoemaker, "Shoemaker"],
  [HighSchool.SmithsonValley, "Smithson Valley"],
  [HighSchool.Smithville, "Smithville"],
  [HighSchool.Star, "Star"],
  [HighSchool.StonyPoint, "Stony Point"],
  [HighSchool.Taylor, "Taylor"],
  [HighSchool.Temple, "Temple"],
  [HighSchool.Thorndale, "Thorndale"],
  [HighSchool.Thrall, "Thrall"],
  [HighSchool.Travis, "Travis"],
  [HighSchool.Troy, "Troy"],
  [HighSchool.Vandegrift, "Vandegrift"],
  [HighSchool.VistaRidge, "Vista Ridge"],
  [HighSchool.Waelder, "Waelder"],
  [HighSchool.Weimar, "Weimar"],
  [HighSchool.Weiss, "Weiss"],
  [HighSchool.Westlake, "Westlake"],
  [HighSchool.Westwood, "Westwood"],
  [HighSchool.Wimberley, "Wimberley"],
  [HighSchool.Yoakum, "Yoakum"]
]);
