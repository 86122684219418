import {
  SaborCities,
  SaborCitiesLabel,
  getOptionsFromEnumAndMap,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { County, CountyLabel } from "markets/sabor/enums";

export const getSaborCityLabel = (city: string | SaborCities): string =>
  SaborCitiesLabel[city as SaborCities] ?? city;

export const saborCityRule: FieldRulesProps = {
  label: "City",
  lastOptions: [SaborCities.Other],
  options: getOptionsFromEnumAndRecord(
    Object.values(SaborCities),
    SaborCitiesLabel
  )
};

export const countyRules: FieldRulesProps = {
  label: "County/Parish",
  lastOptions: [County.Other],
  options: getOptionsFromEnumAndMap(Object.values(County), CountyLabel),
  required: true
};

export const subdivisionRules: FieldRulesProps = {
  label: "Subdivision",
  requiredToSubmit: true,
  maxLength: 47
};
