export enum CoolingSystem {
  AtticFan = "atticFan",
  CeilingFans = "ceilingFans",
  CentralAir = "centralAir",
  Electric = "electric",
  EnergyStarQualifiedEquipment = "energyStarQualifiedEquipment",
  Geothermal = "geothermal",
  HeatPump = "heatPump",
  HumidityControl = "humidityControl",
  Zoned = "zoned",
  Other = "other"
}

export const CoolingSystemLabel = new Map<CoolingSystem, string>([
  [CoolingSystem.AtticFan, "Attic Fan"],
  [CoolingSystem.CeilingFans, "Ceiling Fan(s)"],
  [CoolingSystem.CentralAir, "Central Air"],
  [CoolingSystem.Electric, "Electric"],
  [
    CoolingSystem.EnergyStarQualifiedEquipment,
    "ENERGY STAR Qualified Equipment"
  ],
  [CoolingSystem.Geothermal, "Geothermal"],
  [CoolingSystem.HeatPump, "Heat Pump"],
  [CoolingSystem.HumidityControl, "Humidity Control"],
  [CoolingSystem.Zoned, "Zoned"],
  [CoolingSystem.Other, "Other"]
]);
