export enum Inclusions {
  SecondFloorUtilityRoom = "secondFloorUtilityRoom",
  AtticFan = "atticFan",
  BuiltInOven = "builtInOven",
  Chandelier = "chandelier",
  CityGarbageservice = "cityGarbageservice",
  CookTop = "cookTop",
  CeilingFans = "ceilingFans",
  CentralVacuum = "centralVacuum",
  CarbonMonoxideDetector = "carbonMonoxideDetector",
  CustomCabinets = "customCabinets",
  DoubleOvens = "doubleOvens",
  Disposal = "disposal",
  DownDraft = "downDraft",
  DryerConnection = "dryerConnection",
  Dryer = "dryer",
  Dishwasher = "dishwasher",
  ElectricWaterHeater = "electricWaterHeater",
  GarageDoorOpener = "garageDoorOpener",
  GasGrill = "gasGrill",
  GasCooking = "gasCooking",
  GasWaterHeater = "gasWaterHeater",
  WholeHouseFan = "wholeHouseFan",
  IceMakerConnection = "iceMakerConnection",
  MicrowaveOven = "microwaveOven",
  PlumbingWaterSoftener = "plumbingWaterSoftener",
  PrivateGarbageService = "privateGarbageService",
  PropaneWaterHeater = "propaneWaterHeater",
  PreWiredSecurity = "preWiredSecurity",
  InWallPestControl = "inWallPestControl",
  Refrigerator = "refrigerator",
  SelfCleaningOven = "selfCleaningOven",
  SolidCounterTops = "solidCounterTops",
  SmoothCooktop = "smoothCooktop",
  SmokeAlarm = "smokeAlarm",
  SecuritySystemLeased = "securitySystemLeased",
  SecuritySystemOwned = "securitySystemOwned",
  StoveRange = "stoveRange",
  TwoPlusWaterHeaterUnits = "twoPlusWaterHeaterUnits",
  VentFan = "ventFan",
  WetBar = "wetBar",
  WasherConnection = "washerConnection",
  Washer = "washer"
}
export const InclusionsLabel = new Map<Inclusions, string>([
  [Inclusions.SecondFloorUtilityRoom, "2nd Floor Utility Room"],
  [Inclusions.AtticFan, "Attic Fan"],
  [Inclusions.BuiltInOven, "Built-In Oven"],
  [Inclusions.Chandelier, "Chandelier"],
  [Inclusions.CityGarbageservice, "City Garbage service"],
  [Inclusions.CookTop, "Cook Top"],
  [Inclusions.CeilingFans, "Ceiling Fans"],
  [Inclusions.CentralVacuum, "Central Vacuum"],
  [Inclusions.CarbonMonoxideDetector, "Carbon Monoxide Detector"],
  [Inclusions.CustomCabinets, "Custom Cabinets"],
  [Inclusions.DoubleOvens, "Double Ovens"],
  [Inclusions.Disposal, "Disposal"],
  [Inclusions.DownDraft, "Down Draft"],
  [Inclusions.DryerConnection, "Dryer Connection"],
  [Inclusions.Dryer, "Dryer"],
  [Inclusions.Dishwasher, "Dishwasher"],
  [Inclusions.ElectricWaterHeater, "Electric Water Heater"],
  [Inclusions.GarageDoorOpener, "Garage Door Opener"],
  [Inclusions.GasGrill, "Gas Grill"],
  [Inclusions.GasCooking, "Gas Cooking"],
  [Inclusions.GasWaterHeater, "Gas Water Heater"],
  [Inclusions.WholeHouseFan, "Whole House Fan"],
  [Inclusions.IceMakerConnection, "Ice Maker Connection"],
  [Inclusions.MicrowaveOven, "Microwave Oven"],
  [Inclusions.PlumbingWaterSoftener, "Plumb for Water Softener"],
  [Inclusions.PrivateGarbageService, "Private Garbage Service"],
  [Inclusions.PropaneWaterHeater, "Propane Water Heater"],
  [Inclusions.PreWiredSecurity, "Pre-Wired for Security"],
  [Inclusions.InWallPestControl, "In Wall Pest Control"],
  [Inclusions.Refrigerator, "Refrigerator"],
  [Inclusions.SelfCleaningOven, "Self-Cleaning Oven"],
  [Inclusions.SolidCounterTops, "Solid Counter Tops"],
  [Inclusions.SmoothCooktop, "Smooth Cooktop"],
  [Inclusions.SmokeAlarm, "Smoke Alarm"],
  [Inclusions.SecuritySystemLeased, "Security System (Leased)"],
  [Inclusions.SecuritySystemOwned, "Security System (Owned)"],
  [Inclusions.StoveRange, "Stove/Range"],
  [Inclusions.TwoPlusWaterHeaterUnits, "2+ Water Heater Units"],
  [Inclusions.VentFan, "Vent Fan"],
  [Inclusions.WetBar, "Wet Bar"],
  [Inclusions.WasherConnection, "Washer Connection"],
  [Inclusions.Washer, "Washer"]
]);
