export enum PrivatePool {
  PoolsSweep = "poolsSweep",
  None = "none",
  AdjoiningPoolSpa = "adjoiningPoolSpa",
  Other = "other",
  FencedPool = "fencedPool",
  DivingBoard = "divingBoard",
  PoolSolarHeated = "poolSolarHeated",
  InGroundPool = "inGroundPool",
  HotTub = "hotTub",
  PoolIsHeated = "poolIsHeated",
  EnclosedPool = "enclosedPool",
  AboveGroundPool = "aboveGroundPool"
}
export const PrivatePoolLabel = new Map<PrivatePool, string>([
  [PrivatePool.PoolsSweep, "Pools Sweep"],
  [PrivatePool.None, "None"],
  [PrivatePool.AdjoiningPoolSpa, "AdjoiningPool/Spa"],
  [PrivatePool.Other, "Other"],
  [PrivatePool.FencedPool, "Fenced Pool"],
  [PrivatePool.DivingBoard, "Diving Board"],
  [PrivatePool.PoolSolarHeated, "Pool Solar Heated"],
  [PrivatePool.InGroundPool, "In Ground Pool"],
  [PrivatePool.HotTub, "Hot Tub"],
  [PrivatePool.PoolIsHeated, "Pool is Heated"],
  [PrivatePool.EnclosedPool, "Enclosed Pool"],
  [PrivatePool.AboveGroundPool, "Above Ground Pool"]
]);
