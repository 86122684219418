import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";
import { AlertBaseFilter, AlertsCountParams } from "../interfaces";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

const alertApiUrl = (market: MarketCode): string => {
  const gatewayMarket = getGatewayMarket(market);
  return `${gatewayUrl}/api/${gatewayMarket}/alerts`;
};

const getAlert =
  (market: MarketCode) =>
  async <TResponse = { id: string }>(
    alertKey: string,
    params: AlertBaseFilter
  ): Promise<GridResponse<TResponse>> => {
    const apiUrl = alertApiUrl(market);
    const response = await axios.get<GridResponse<TResponse>>(
      `${apiUrl}/${alertKey}`,
      {
        params
      }
    );
    return response.data;
  };

const getAlertsCount =
  (market: MarketCode) =>
  async (params: AlertsCountParams): Promise<number> => {
    const apiUrl = alertApiUrl(market);
    const response = await axios.get<number>(apiUrl, {
      params
    });

    return response.data;
  };

const markAsResolved =
  (market: MarketCode) =>
  async (alertId: string): Promise<void> => {
    const apiUrl = alertApiUrl(market);
    await axios.patch(`${apiUrl}/violation-warning/${alertId}/resolve`);
  };

export { getAlert, getAlertsCount, markAsResolved };
