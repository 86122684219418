export enum AgentType {
  Listing = "listingAgent",
  Selling = "sellingAgent",
  SellingInternal = "sellingAgentInternal"
}

export const AgentTypeLabel = new Map<AgentType, string>([
  [AgentType.Listing, "Listing Agent"],
  [AgentType.Selling, "Selling Agent"],
  [AgentType.SellingInternal, "Selling Agent (Internal)"]
]);
