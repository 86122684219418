export enum HeatSystem {
  NaturalGas = "naturalGas",
  Propane = "propane",
  Baseboard = "baseboard",
  Central = "central",
  Ductless = "ductless",
  Electric = "electric",
  FloorFurnace = "floorFurnace",
  Geothermal = "geothermal",
  HeatPump = "heatPump",
  Radiant = "radiant",
  Solar = "solar",
  UnitOne = "unitOne",
  UnitTwo = "unitTwo",
  UnitThree = "unitThree",
  WallFurnace = "wallFurnace",
  Zoned = "zoned",
  None = "none",
  OtherHeat = "otherHeat"
}

export const HeatSystemLabel = new Map<HeatSystem, string>([
  [HeatSystem.NaturalGas, "Natural Gas"],
  [HeatSystem.Propane, "Propane"],
  [HeatSystem.Baseboard, "Baseboard"],
  [HeatSystem.Central, "Central"],
  [HeatSystem.Ductless, "Ductless"],
  [HeatSystem.Electric, "Electric"],
  [HeatSystem.FloorFurnace, "Floor Furnace"],
  [HeatSystem.Geothermal, "Geothermal"],
  [HeatSystem.HeatPump, "Heat Pump"],
  [HeatSystem.Radiant, "Radiant"],
  [HeatSystem.Solar, "Solar"],
  [HeatSystem.UnitOne, "Unit - 1"],
  [HeatSystem.UnitTwo, "Unit - 2"],
  [HeatSystem.UnitThree, "Unit - 3 or More"],
  [HeatSystem.WallFurnace, "Wall Furnace"],
  [HeatSystem.Zoned, "Zoned"],
  [HeatSystem.None, "None"],
  [HeatSystem.OtherHeat, "Other Heat"]
]);
