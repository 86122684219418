export enum AccessRoadSurface {
  Alley = "alley",
  Asphalt = "asphalt",
  CityStreet = "cityStreet",
  Concrete = "concrete",
  CountyRoad = "countyRoad",
  Curbs = "curbs",
  PublicMaintainedRoad = "publicMaintainedRoad",
  Sidewalks = "sidewalks",
  StreetGutters = "streetGutters",
  StreetLights = "streetLights",
  Paved = "paved",
  InterstateHwyOrLess = "interstateHwyOrLess"
}

export const AccessRoadSurfaceLabel = new Map<AccessRoadSurface, string>([
  [AccessRoadSurface.Paved, "Paved"],
  [AccessRoadSurface.InterstateHwyOrLess, "Interstate Hwy-1 mi or Less"],
  [AccessRoadSurface.Alley, "Alley"],
  [AccessRoadSurface.Asphalt, "Asphalt"],
  [AccessRoadSurface.CityStreet, "City Street"],
  [AccessRoadSurface.Concrete, "Concrete"],
  [AccessRoadSurface.CountyRoad, "County Road"],
  [AccessRoadSurface.Curbs, "Curbs"],
  [AccessRoadSurface.PublicMaintainedRoad, "Public Maintained Road"],
  [AccessRoadSurface.Sidewalks, "Sidewalks"],
  [AccessRoadSurface.StreetGutters, "Street Gutters"],
  [AccessRoadSurface.StreetLights, "Street Lights"]
]);
