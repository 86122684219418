import { getDate } from "@homesusa/core";

export const getYesterday = (): Date => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  return yesterday;
};

export const getNextDayDate = (date?: Date): Date | undefined => {
  const dateFormat = date != null ? getDate(date) : null;
  if (dateFormat != null) {
    const nextDay = new Date(dateFormat);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  }
  return undefined;
};

export const getDateMinusDays = (
  date?: Date,
  daysToSubtract = 0
): Date | undefined => {
  const dateFormat = date != null ? new Date(date) : null;
  if (dateFormat != null) {
    const newDate = new Date(dateFormat);
    newDate.setDate(newDate.getDate() - daysToSubtract);
    return newDate;
  }
  return undefined;
};

export const isToday = (date?: Date): boolean => {
  if (!date) {
    return false;
  }
  const today = new Date();

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
};
