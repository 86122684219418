export enum CoolingSystem {
  CentralAir = "centralAir",
  DuctedEvap = "ductedEvap",
  Ductless = "ductless",
  Electric = "electric",
  Gas = "gas",
  Geothermal = "geothermal",
  HeatPump = "heatPump",
  UnitOne = "unitOne",
  UnitTwo = "unitTwo",
  UnitThree = "unitThree",
  WindowEvap = "windowEvap",
  Zoned = "zoned",
  None = "none"
}

export const CoolingSystemLabel = new Map<CoolingSystem, string>([
  [CoolingSystem.CentralAir, "Central Air"],
  [CoolingSystem.DuctedEvap, "Ducted Evap"],
  [CoolingSystem.Ductless, "Ductless"],
  [CoolingSystem.Electric, "Electric"],
  [CoolingSystem.Gas, "Gas"],
  [CoolingSystem.Geothermal, "Geothermal"],
  [CoolingSystem.HeatPump, "Heat Pump"],
  [CoolingSystem.UnitOne, "Unit - 1"],
  [CoolingSystem.UnitTwo, "Unit - 2"],
  [CoolingSystem.UnitThree, "Unit - 3 or More"],
  [CoolingSystem.WindowEvap, "Window Evap"],
  [CoolingSystem.Zoned, "Zoned"],
  [CoolingSystem.None, "None"]
]);
