import { useHasRole } from "@homesusa/auth";
import { RoleEmployee } from "@homesusa/core";
import React from "react";

export const useHasPermissions = (): {
  canCreate: boolean;
  canDelete: boolean;
  canEdit: boolean;
} => {
  const { hasEmployeeRole } = useHasRole();

  const canCreate = React.useMemo((): boolean => {
    if (
      hasEmployeeRole([
        RoleEmployee.SalesEmployeeReadonly,
        RoleEmployee.Readonly,
        RoleEmployee.CompanyAdminReadonly
      ])
    )
      return false;
    return true;
  }, [hasEmployeeRole]);

  const canDelete = React.useMemo((): boolean => {
    if (hasEmployeeRole([RoleEmployee.SalesEmployeeReadonly])) return false;
    return true;
  }, [hasEmployeeRole]);

  const canEdit = React.useMemo((): boolean => {
    if (hasEmployeeRole([RoleEmployee.SalesEmployeeReadonly])) return false;
    return true;
  }, [hasEmployeeRole]);

  return {
    canCreate,
    canDelete,
    canEdit
  };
};
