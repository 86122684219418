export enum ExteriorFeatures {
  AttachedGrill = "attachedGrill",
  Awnings = "awnings",
  Balcony = "balcony",
  Barbecue = "barbecue",
  BasketballCourt = "basketballCourt",
  BoatSlip = "boatSlip",
  BuiltInBarbecue = "builtInBarbecue",
  Courtyard = "courtyard",
  CoveredCourtyard = "coveredCourtyard",
  CoveredDeck = "coveredDeck",
  CoveredPatioPorch = "coveredPatioPorch",
  Dock = "dock",
  GrayWaterSystem = "grayWaterSystem",
  Lighting = "lighting",
  MosquitoMistSystem = "mosquitoMistSystem",
  Other = "other",
  OutdoorGrill = "outdoorGrill",
  OutdoorKitchen = "outdoorKitchen",
  OutdoorLivingCenter = "outdoorLivingCenter",
  Playground = "playground",
  PrivateEntrance = "privateEntrance",
  PrivateYard = "privateYard",
  RainGutters = "rainGutters",
  StormCellar = "stormCellar",
  UncoveredCourtyard = "uncoveredCourtyard"
}

export const ExteriorFeaturesLabel = new Map<ExteriorFeatures, string>([
  [ExteriorFeatures.AttachedGrill, "Attached Grill"],
  [ExteriorFeatures.Awnings, "Awning(s)"],
  [ExteriorFeatures.Balcony, "Balcony"],
  [ExteriorFeatures.Barbecue, "Barbecue"],
  [ExteriorFeatures.BasketballCourt, "Basketball Court"],
  [ExteriorFeatures.BoatSlip, "Boat Slip"],
  [ExteriorFeatures.BuiltInBarbecue, "Built-in Barbecue"],
  [ExteriorFeatures.Courtyard, "Courtyard"],
  [ExteriorFeatures.CoveredCourtyard, "Covered Courtyard"],
  [ExteriorFeatures.CoveredDeck, "Covered Deck"],
  [ExteriorFeatures.CoveredPatioPorch, "Covered Patio/Porch"],
  [ExteriorFeatures.Dock, "Dock"],
  [ExteriorFeatures.GrayWaterSystem, "Gray Water System"],
  [ExteriorFeatures.Lighting, "Lighting"],
  [ExteriorFeatures.MosquitoMistSystem, "Mosquito Mist System"],
  [ExteriorFeatures.Other, "Other"],
  [ExteriorFeatures.OutdoorGrill, "Outdoor Grill"],
  [ExteriorFeatures.OutdoorKitchen, "Outdoor Kitchen"],
  [ExteriorFeatures.OutdoorLivingCenter, "Outdoor Living Center"],
  [ExteriorFeatures.Playground, "Playground"],
  [ExteriorFeatures.PrivateEntrance, "Private Entrance"],
  [ExteriorFeatures.PrivateYard, "Private Yard"],
  [ExteriorFeatures.RainGutters, "Rain Gutters"],
  [ExteriorFeatures.StormCellar, "Storm Cellar"],
  [ExteriorFeatures.UncoveredCourtyard, "Uncovered Courtyard"]
]);
