export enum LakeName {
  Abilene = "abilene",
  AlanHenry = "alanHenry",
  AlvaradoPark = "alvaradoPark",
  Amistad = "amistad",
  AmonGCarter = "amonGCarter",
  Aquilla = "aquilla",
  Arlington = "arlington",
  Arrowhead = "arrowhead",
  Athens = "athens",
  Austin = "austin",
  Averhoff = "averhoff",
  BASteinhagen = "baSteinhagen",
  Balmorhea = "balmorhea",
  Bardwell = "bardwell",
  Bastrop = "bastrop",
  Belton = "belton",
  Benbrook = "benbrook",
  BigCreek = "bigCreek",
  Bistineau = "bistineau",
  BlackBayou = "blackBayou",
  BobSandlin = "bobSandlin",
  BoisdArc = "boisdArc",
  Bonham = "bonham",
  Bowie = "bowie",
  Brazos = "brazos",
  Bridgeport = "bridgeport",
  Brooks = "brooks",
  Brownwood = "brownwood",
  BuffaloCreek = "buffaloCreek",
  Caddo = "caddo",
  Calaveras = "calaveras",
  Callender = "callender",
  CedarCreek = "cedarCreek",
  Cisco = "cisco",
  Claiborne = "claiborne",
  CleburneStatePark = "cleburneStatePark",
  CoffeeMill = "coffeeMill",
  Coleman = "coleman",
  ColoradoCity = "coloradoCity",
  Conroe = "conroe",
  Cooper = "cooper",
  Cross = "cross",
  CypressBayou = "cypressBayou",
  CypressSprings = "cypressSprings",
  DavyCrockett = "davyCrockett",
  EVSpence = "evSpence",
  EagleMountain = "eagleMountain",
  Fairfield = "fairfield",
  Falcon = "falcon",
  Fork = "fork",
  FortPhantomHill = "fortPhantomHill",
  Georgetown = "georgetown",
  Gladewater = "gladewater",
  Graham = "graham",
  Granbury = "granbury",
  Grapevine = "grapevine",
  Halbert = "halbert",
  Hawkins = "hawkins",
  Holbrook = "holbrook",
  Holly = "holly",
  Houston = "houston",
  HoustonCounty = "houstonCounty",
  HubbardCreek = "hubbardCreek",
  HubertMoss = "hubertMoss",
  Jacksonville = "jacksonville",
  JoePool = "joePool",
  Kiowa = "kiowa",
  Kirby = "kirby",
  LakeOThePines = "lakeOThePines",
  LakeWorth = "lakeWorth",
  Lavon = "lavon",
  Leon = "leon",
  Lewisville = "lewisville",
  Limestone = "limestone",
  Livingston = "livingston",
  LyndonBJohnson = "lyndonBJohnson",
  MarbleFalls = "marbleFalls",
  MarineCreek = "marineCreek",
  MartinCreek = "martinCreek",
  Mexia = "mexia",
  Moss = "moss",
  MountainCreek = "mountainCreek",
  Murvaul = "murvaul",
  NavarroMills = "navarroMills",
  Nocona = "nocona",
  OHIvie = "ohIvie",
  Other = "other",
  Palestine = "palestine",
  Palestine1 = "palestine1",
  PaloPinto = "paloPinto",
  PatCleburne = "patCleburne",
  PossumKingdom = "possumKingdom",
  Proctor = "proctor",
  PurtisCreek = "purtisCreek",
  Quitman = "quitman",
  RayHubbard = "rayHubbard",
  RayRoberts = "rayRoberts",
  RedBluff = "redBluff",
  RichlandChambers = "richlandChambers",
  Safari = "safari",
  SamRayburn = "samRayburn",
  SquawCreek = "squawCreek",
  Stamford = "stamford",
  SulphurSprings = "sulphurSprings",
  Sweetwater = "sweetwater",
  Tawakoni = "tawakoni",
  Texana = "texana",
  Texoma = "texoma",
  ToledoBend = "toledoBend",
  Travis = "travis",
  TwinButtes = "twinButtes",
  Tyler = "tyler",
  Waco = "waco",
  Waxahachie = "waxahachie",
  Weatherford = "weatherford",
  WhiteRock = "whiteRock",
  Whitney = "whitney",
  Winnsboro = "winnsboro",
  Wood = "wood",
  WrightPatman = "wrightPatman"
}

export const LakeNameLabel = new Map<LakeName, string>([
  [LakeName.Abilene, "Abilene"],
  [LakeName.AlanHenry, "Alan Henry"],
  [LakeName.AlvaradoPark, "Alvarado Park"],
  [LakeName.Amistad, "Amistad"],
  [LakeName.AmonGCarter, "Amon G. Carter"],
  [LakeName.Aquilla, "Aquilla"],
  [LakeName.Arlington, "Arlington"],
  [LakeName.Arrowhead, "Arrowhead"],
  [LakeName.Athens, "Athens"],
  [LakeName.Austin, "Austin"],
  [LakeName.Averhoff, "Averhoff"],
  [LakeName.BASteinhagen, "B.A. Steinhagen"],
  [LakeName.Balmorhea, "Balmorhea"],
  [LakeName.Bardwell, "Bardwell"],
  [LakeName.Bastrop, "Bastrop"],
  [LakeName.Belton, "Belton"],
  [LakeName.Benbrook, "Benbrook"],
  [LakeName.BigCreek, "Big Creek"],
  [LakeName.Bistineau, "Bistineau"],
  [LakeName.BlackBayou, "Black Bayou"],
  [LakeName.BobSandlin, "Bob Sandlin"],
  [LakeName.BoisdArc, "Bois d'Arc"],
  [LakeName.Bonham, "Bonham"],
  [LakeName.Bowie, "Bowie"],
  [LakeName.Brazos, "Brazos"],
  [LakeName.Bridgeport, "Bridgeport"],
  [LakeName.Brooks, "Brooks"],
  [LakeName.Brownwood, "Brownwood"],
  [LakeName.BuffaloCreek, "Buffalo Creek"],
  [LakeName.Caddo, "Caddo"],
  [LakeName.Calaveras, "Calaveras"],
  [LakeName.Callender, "Callender"],
  [LakeName.CedarCreek, "Cedar Creek"],
  [LakeName.Cisco, "Cisco"],
  [LakeName.Claiborne, "Claiborne"],
  [LakeName.CleburneStatePark, "Cleburne State Park"],
  [LakeName.CoffeeMill, "Coffee Mill"],
  [LakeName.Coleman, "Coleman"],
  [LakeName.ColoradoCity, "Colorado City"],
  [LakeName.Conroe, "Conroe"],
  [LakeName.Cooper, "Cooper"],
  [LakeName.Cross, "Cross"],
  [LakeName.CypressBayou, "Cypress Bayou"],
  [LakeName.CypressSprings, "Cypress Springs"],
  [LakeName.DavyCrockett, "Davy Crockett"],
  [LakeName.EVSpence, "E. V. Spence"],
  [LakeName.EagleMountain, "Eagle Mountain"],
  [LakeName.Fairfield, "Fairfield"],
  [LakeName.Falcon, "Falcon"],
  [LakeName.Fork, "Fork"],
  [LakeName.FortPhantomHill, "Fort Phantom Hill"],
  [LakeName.Georgetown, "Georgetown"],
  [LakeName.Gladewater, "Gladewater"],
  [LakeName.Graham, "Graham"],
  [LakeName.Granbury, "Granbury"],
  [LakeName.Grapevine, "Grapevine"],
  [LakeName.Halbert, "Halbert"],
  [LakeName.Hawkins, "Hawkins"],
  [LakeName.Holbrook, "Holbrook"],
  [LakeName.Holly, "Holly"],
  [LakeName.Houston, "Houston"],
  [LakeName.HoustonCounty, "Houston County"],
  [LakeName.HubbardCreek, "Hubbard Creek"],
  [LakeName.HubertMoss, "Hubert Moss"],
  [LakeName.Jacksonville, "Jacksonville"],
  [LakeName.JoePool, "Joe Pool"],
  [LakeName.Kiowa, "Kiowa"],
  [LakeName.Kirby, "Kirby"],
  [LakeName.LakeOThePines, "Lake O' The Pines"],
  [LakeName.LakeWorth, "Lake Worth"],
  [LakeName.Lavon, "Lavon"],
  [LakeName.Leon, "Leon"],
  [LakeName.Lewisville, "Lewisville"],
  [LakeName.Limestone, "Limestone"],
  [LakeName.Livingston, "Livingston"],
  [LakeName.LyndonBJohnson, "Lyndon B. Johnson"],
  [LakeName.MarbleFalls, "Marble Falls"],
  [LakeName.MarineCreek, "Marine Creek"],
  [LakeName.MartinCreek, "Martin Creek"],
  [LakeName.Mexia, "Mexia"],
  [LakeName.Moss, "Moss"],
  [LakeName.MountainCreek, "Mountain Creek"],
  [LakeName.Murvaul, "Murvaul"],
  [LakeName.NavarroMills, "Navarro Mills"],
  [LakeName.Nocona, "Nocona"],
  [LakeName.OHIvie, "O.H. Ivie"],
  [LakeName.Other, "Other"],
  [LakeName.Palestine, "Palestine"],
  [LakeName.Palestine1, "Palestine1"],
  [LakeName.PaloPinto, "Palo Pinto"],
  [LakeName.PatCleburne, "Pat Cleburne"],
  [LakeName.PossumKingdom, "Possum Kingdom"],
  [LakeName.Proctor, "Proctor"],
  [LakeName.PurtisCreek, "Purtis Creek"],
  [LakeName.Quitman, "Quitman"],
  [LakeName.RayHubbard, "Ray Hubbard"],
  [LakeName.RayRoberts, "Ray Roberts"],
  [LakeName.RedBluff, "Red Bluff"],
  [LakeName.RichlandChambers, "Richland-Chambers"],
  [LakeName.Safari, "Safari"],
  [LakeName.SamRayburn, "Sam Rayburn"],
  [LakeName.SquawCreek, "Squaw Creek"],
  [LakeName.Stamford, "Stamford"],
  [LakeName.SulphurSprings, "Sulphur Springs"],
  [LakeName.Sweetwater, "Sweetwater"],
  [LakeName.Tawakoni, "Tawakoni"],
  [LakeName.Texana, "Texana"],
  [LakeName.Texoma, "Texoma"],
  [LakeName.ToledoBend, "Toledo Bend"],
  [LakeName.Travis, "Travis"],
  [LakeName.TwinButtes, "Twin Buttes"],
  [LakeName.Tyler, "Tyler"],
  [LakeName.Waco, "Waco"],
  [LakeName.Waxahachie, "Waxahachie"],
  [LakeName.Weatherford, "Weatherford"],
  [LakeName.WhiteRock, "White Rock"],
  [LakeName.Whitney, "Whitney"],
  [LakeName.Winnsboro, "Winnsboro"],
  [LakeName.Wood, "Wood"],
  [LakeName.WrightPatman, "Wright Patman"]
]);
