export enum Pool {
  Gunite = "gunite",
  SaltWater = "saltWater",
  Fiberglass = "fiberglass",
  Enclosed = "enclosed",
  InGround = "inGround",
  VinylLined = "vinylLined",
  Heated = "heated"
}
export const PoolLabel = new Map<Pool, string>([
  [Pool.Gunite, "Gunite"],
  [Pool.SaltWater, "Salt Water"],
  [Pool.Fiberglass, "Fiberglass"],
  [Pool.Enclosed, "Enclosed"],
  [Pool.InGround, "In Ground"],
  [Pool.VinylLined, "Vinyl Lined"],
  [Pool.Heated, "Heated"]
]);
