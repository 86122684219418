export enum HousingType {
  AttachedDuplex = "attachedDuplex",
  CondoTownhome = "condoTownhome",
  GardenZeroLotLine = "gardenZeroLotLine",
  SingleDetached = "singleDetached"
}

export const HousingTypeLabel = new Map<HousingType, string>([
  [HousingType.AttachedDuplex, "Attached or 1/2 Duplex"],
  [HousingType.CondoTownhome, "Condo/Townhome"],
  [HousingType.GardenZeroLotLine, "Garden/Zero Lot Line"],
  [HousingType.SingleDetached, "Single Detached"]
]);
