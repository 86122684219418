import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";

import { ImportSource } from "core/enums";
import { JsonListing } from "../interfaces/alerts/json";
import { ImportAlertFilter } from "../interfaces";
import { getImportSourceUrl } from "./import-source.service";

export const getJsonListings =
  (market: MarketCode) =>
  async (params: ImportAlertFilter): Promise<GridResponse<JsonListing>> => {
    const { listingsWithInvalidPlans = false, ...otherParams } = params;
    const apiUrl = getImportSourceUrl(ImportSource.Json, market);
    const response = await axios.get<GridResponse<JsonListing>>(apiUrl, {
      params: { ...otherParams, listingsWithInvalidPlans }
    });
    return response.data;
  };
