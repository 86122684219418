import React from "react";

import { NumberInput } from "@homesusa/form";
import { useHasRole } from "@homesusa/auth";
import { ListingContext } from "modules/listings/contexts";

export function SquareFootageField({ name }: { name: string }): JSX.Element {
  const { company } = React.useContext(ListingContext);
  const { isMlsAdministrator } = useHasRole();
  return (
    <NumberInput
      disabled={company?.mlsInfo?.blockSquareFootage && !isMlsAdministrator}
      name={name}
    />
  );
}
