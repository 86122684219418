import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps, FormRules } from "@homesusa/form";
import {
  HoaRequirement,
  HoaRequirementLabel,
  BillingFrequency,
  BillingFrequencyLabel,
  HoaIncludes,
  HoaIncludesLabel,
  ProposedTerms,
  ProposedTermsLabel
} from "markets/dfw/enums";

const moneyRule = (label: string): FieldRulesProps => ({
  label,
  numberFormat: {
    prefix: "$",
    thousandSeparator: true
  }
});

export const buyersAgentCommissionRules: FormRules = {
  buyersAgentCommission: {
    label: "Buyer Agency Compensation",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 2
    }
  },
  readableBuyersAgentCommission: { label: "Buyer Agency Compensation" },
  buyersAgentCommissionType: { label: "Buyer Agency Compensation Type" }
};

export const financialRules: FormRules = {
  titleCompany: {
    label: "Preferred Title Company"
  },
  titleCompanyPhone: { label: "Title Co Phone" },
  titleCompanyLocation: {
    label: "Title Co Location"
  },
  proposedTerms: {
    label: "Proposed Financing",
    lastOptions: [ProposedTerms.Other],
    options: getOptionsFromEnumAndMap(
      Object.values(ProposedTerms),
      ProposedTermsLabel
    )
  },
  hoaRequirement: {
    label: "HOA",
    requiredToSubmit: true,
    lastOptions: [HoaRequirement.None],
    options: getOptionsFromEnumAndMap(
      Object.values(HoaRequirement),
      HoaRequirementLabel
    )
  },
  hoaFee: moneyRule("HOA Fee"),
  hoaTerm: {
    label: "Billing Frequency",
    options: getOptionsFromEnumAndMap(
      Object.values(BillingFrequency),
      BillingFrequencyLabel
    )
  },
  hoaIncludes: {
    label: "HOA Includes",
    options: getOptionsFromEnumAndMap(
      Object.values(HoaIncludes),
      HoaIncludesLabel
    )
  },
  hoaManagement: {
    label: "HOA Management Co"
  },
  hoaPhone: { label: "HOA Mgmt Co Phone" }
};
