export enum Appliances {
  BuiltInCoffeeMaker = "builtInCoffeeMaker",
  BuiltInGasRange = "builtInGasRange",
  BuiltInRefrigeratorFreezer = "builtInRefrigeratorFreezer",
  CommercialGradeRange = "commercialGradeRange",
  CommercialGradeVent = "commercialGradeVent",
  Dishwasher = "dishwasher",
  Disposal = "disposal",
  Dryer = "dryer",
  ElectricCooktop = "electricCooktop",
  ElectricOven = "electricOven",
  ElectricRange = "electricRange",
  ElectricWaterHeater = "electricWaterHeater",
  GasCooktop = "gasCooktop",
  GasOven = "gasOven",
  GasRange = "gasRange",
  GasWaterHeater = "gasWaterHeater",
  IceMaker = "iceMaker",
  IndoorGrill = "indoorGrill",
  Microwave = "microwave",
  OvenConvection = "ovenConvection",
  OvenDouble = "ovenDouble",
  PlumbedForGasInKitchen = "plumbedForGasInKitchen",
  Refrigerator = "refrigerator",
  TanklessWaterHeater = "tanklessWaterHeater",
  TrashCompactor = "trashCompactor",
  VentedExhaustFan = "ventedExhaustFan",
  WarmingDrawer = "warmingDrawer",
  Washer = "washer",
  WaterFilter = "waterFilter",
  WaterPurifier = "waterPurifier",
  Other = "other",
  None = "none"
}

export const AppliancesLabel = new Map<Appliances, string>([
  [Appliances.BuiltInCoffeeMaker, "Built-in Coffee Maker"],
  [Appliances.BuiltInGasRange, "Built-in Gas Range"],
  [Appliances.BuiltInRefrigeratorFreezer, "Built-in Refrigerator/Freezer"],
  [Appliances.CommercialGradeRange, "Commercial Grade Range"],
  [Appliances.CommercialGradeVent, "Commercial Grade Vent"],
  [Appliances.Dishwasher, "Dishwasher"],
  [Appliances.Disposal, "Disposal"],
  [Appliances.Dryer, "Dryer"],
  [Appliances.ElectricCooktop, "Electric Cooktop"],
  [Appliances.ElectricOven, "Electric Oven"],
  [Appliances.ElectricRange, "Electric Range"],
  [Appliances.ElectricWaterHeater, "Electric Water Heater"],
  [Appliances.GasCooktop, "Gas Cooktop"],
  [Appliances.GasOven, "Gas Oven"],
  [Appliances.GasRange, "Gas Range"],
  [Appliances.GasWaterHeater, "Gas Water Heater"],
  [Appliances.IceMaker, "Ice Maker"],
  [Appliances.IndoorGrill, "Indoor Grill"],
  [Appliances.Microwave, "Microwave"],
  [Appliances.OvenConvection, "Oven-Convection"],
  [Appliances.OvenDouble, "Oven-Double"],
  [Appliances.PlumbedForGasInKitchen, "Plumbed For Gas in Kitchen"],
  [Appliances.Refrigerator, "Refrigerator"],
  [Appliances.TanklessWaterHeater, "Tankless Water Heater"],
  [Appliances.TrashCompactor, "Trash Compactor"],
  [Appliances.VentedExhaustFan, "Vented Exhaust Fan"],
  [Appliances.WarmingDrawer, "Warming Drawer"],
  [Appliances.Washer, "Washer"],
  [Appliances.WaterFilter, "Water Filter"],
  [Appliances.WaterPurifier, "Water Purifier"],
  [Appliances.Other, "Other"],
  [Appliances.None, "None"]
]);
