export enum WaterSewer {
  AerobicSeptic = "aerobicSeptic",
  EngineeredSeptic = "engineeredSeptic",
  MUD = "mud",
  PrivateSewer = "privateSewer",
  PublicSewer = "publicSewer",
  SepticTank = "septicTank"
}
export const WaterSewerLabel = new Map<WaterSewer, string>([
  [WaterSewer.AerobicSeptic, "Aerobic Septic"],
  [WaterSewer.EngineeredSeptic, "Engineered Septic"],
  [WaterSewer.MUD, "MUD"],
  [WaterSewer.PrivateSewer, "Private Sewer"],
  [WaterSewer.PublicSewer, "Public Sewer"],
  [WaterSewer.SepticTank, "Septic Tank"]
]);
