import React, { Fragment } from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { AppContext, SubmitButton } from "@homesusa/layout";
import { ImportSource } from "core/enums";
import { DashboardContext } from "modules/dashboard/context/dashboard";
import { ImportAlertsContext } from "modules/dashboard/context/import-alerts";
import { AlertType } from "modules/dashboard/enums";
import { useRemoveElemFromGrid } from "modules/dashboard/hooks";

export function DeleteButton({
  listingId,
  importSource
}: {
  listingId: string;
  importSource: ImportSource;
}): JSX.Element {
  const {
    importServices: { deleteListing }
  } = React.useContext(DashboardContext);
  const { addAlert } = React.useContext(AppContext);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);
  const { refreshReminders } = React.useContext(ImportAlertsContext);
  const removeElementFromAlertGrid = useRemoveElemFromGrid(
    importSource == ImportSource.Json
      ? AlertType.JsonHomesToListNow
      : AlertType.XmlHomesToListNow
  );

  return (
    <Fragment>
      <IconButton
        title="Delete"
        color="error"
        onClick={(): void => setShowModal(true)}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogContent>
          <p>Are you sure you want to delete this listing?</p>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            onClick={async (): Promise<void> => {
              await deleteListing(importSource, listingId);
              addAlert({
                message: "Item successfully removed",
                variant: "success"
              });
              removeElementFromAlertGrid(listingId);
              refreshReminders();
              handleCloseModal();
            }}
          >
            Delete
          </SubmitButton>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
