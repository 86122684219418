export enum GarageDescription {
  OversizedGarage = "oversizedGarage",
  AttachedDetachedGarage = "attachedDetachedGarage",
  DetachedGarage = "detachedGarage",
  Tandem = "tandem",
  AttachedGarage = "attachedGarage"
}

export const GarageDescriptionLabel = new Map<GarageDescription, string>([
  [GarageDescription.OversizedGarage, "Oversized Garage"],
  [GarageDescription.AttachedDetachedGarage, "Attached/Detached Garage"],
  [GarageDescription.DetachedGarage, "Detached Garage"],
  [GarageDescription.Tandem, "Tandem"],
  [GarageDescription.AttachedGarage, "Attached Garage"]
]);
