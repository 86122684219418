export enum GuestAccommodationsDescription {
  Connected = "connected",
  GarageApartment = "garageApartment",
  GuestHouse = "guestHouse",
  MainLevel = "mainLevel",
  None = "none",
  NotConnected = "notConnected",
  RoomWithPrivateBath = "roomWithPrivateBath",
  SeeRemarks = "seeRemarks",
  SeparateEntrance = "separateEntrance",
  SeparateKitFacilities = "separateKitFacilities",
  SeparateLivingQuarters = "separateLivingQuarters",
  SeparateUtilities = "separateUtilities"
}
export const GuestAccommodationsDescriptionLabel = new Map<
  GuestAccommodationsDescription,
  string
>([
  [GuestAccommodationsDescription.Connected, "Connected"],
  [GuestAccommodationsDescription.GarageApartment, "Garage Apartment"],
  [GuestAccommodationsDescription.GuestHouse, "Guest House"],
  [GuestAccommodationsDescription.MainLevel, "Main Level"],
  [GuestAccommodationsDescription.None, "None"],
  [GuestAccommodationsDescription.NotConnected, "Not Connected"],
  [
    GuestAccommodationsDescription.RoomWithPrivateBath,
    "Room with Private Bath"
  ],
  [GuestAccommodationsDescription.SeeRemarks, "See Remarks"],
  [GuestAccommodationsDescription.SeparateEntrance, "Separate Entrance"],
  [
    GuestAccommodationsDescription.SeparateKitFacilities,
    "Separate Kit Facilities"
  ],
  [
    GuestAccommodationsDescription.SeparateLivingQuarters,
    "Separate Living Quarters"
  ],
  [GuestAccommodationsDescription.SeparateUtilities, "Separate Utilities"]
]);
