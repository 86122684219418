import React, { Context } from "react";

import { SubmitButton } from "@homesusa/layout";
import { ImportSource, JsonImportStatus, XmlStatus } from "core/enums";
import { useRefreshEntity } from "core/hooks";
import { EntityContext } from "core/interfaces";

export function ApproveProfileButton<
  TEntity extends {
    id: string;
    jsonImportStatus: JsonImportStatus;
    xmlStatus: XmlStatus;
  },
  TEntityContext extends EntityContext<TEntity>
>({
  entityContext,
  onApprove,
  importSource
}: {
  importSource: ImportSource;
  entityContext: Context<TEntityContext>;
  onApprove: (importSource: ImportSource, entityId: string) => Promise<void>;
}): JSX.Element {
  const {
    entity: { id }
  } = React.useContext(entityContext);
  const refreshEntity = useRefreshEntity(entityContext);

  return (
    <SubmitButton
      buttonProps={{
        sx: {
          backgroundColor: "white",
          color: (theme): string => theme.palette.primary.dark,
          borderBlockColor: (theme): string => theme.palette.primary.dark
        }
      }}
      onClick={async (): Promise<void> => {
        await onApprove(importSource, id);
        await refreshEntity();
      }}
    >
      Approve
    </SubmitButton>
  );
}
