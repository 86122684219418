import { SxProps } from "@mui/system";

interface Styles {
  noData: SxProps;
  icon: SxProps;
}

export const useStyles = (height: number, width?: number): Styles => ({
  noData: {
    height: `${height}px`,
    width: width ? `${width}px` : "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "8px",
    color: "#8791a3"
  },
  icon: { fontSize: "5rem" }
});
