import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { CircularProgress } from "@mui/material";

export function MapContainer({
  coordinates,
  height = 300
}: {
  coordinates?: { lat?: number; lng?: number };
  height?: number;
}): JSX.Element {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY ?? ""
  });

  const isNullOrEmpty = (value: unknown): boolean => {
    return value === null || value === undefined || value === "";
  };

  const markerPosition = React.useMemo(() => {
    if (
      !coordinates ||
      isNullOrEmpty(coordinates.lat) ||
      isNullOrEmpty(coordinates.lng)
    ) {
      return undefined;
    }

    return {
      lat: parseFloat(`${coordinates.lat}`),
      lng: parseFloat(`${coordinates.lng}`)
    };
  }, [coordinates]);

  const center = markerPosition
    ? markerPosition
    : {
        lat: 32.5,
        lng: -96.5
      };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: `${height}px`
      }}
      center={center}
      zoom={10}
    >
      {markerPosition ? <Marker position={markerPosition} /> : null}
    </GoogleMap>
  ) : (
    <CircularProgress />
  );
}
