export enum LotSizeSource {
  Survey = "survey",
  Other = "other",
  AppraisalDistrict = "appraisalDistrict"
}
export const LotSizeSourceLabel = new Map<LotSizeSource, string>([
  [LotSizeSource.Survey, "Survey"],
  [LotSizeSource.Other, "Other"],
  [LotSizeSource.AppraisalDistrict, "Appraisal District"]
]);
