export enum Inclusions {
  PrimaryBath = "primaryBath",
  PrimarySuites = "primarySuites",
  Bar = "bar",
  Bookcases = "bookcases",
  BreakfastBar = "breakfastBar",
  BuiltInFeatures = "builtInFeatures",
  CeilingFans = "ceilingFans",
  CeilingBeamed = "ceilingBeamed",
  CeilingCathedral = "ceilingCathedral",
  CeilingCoffered = "ceilingCoffered",
  CeilingHigh = "ceilingHigh",
  CeilingTray = "ceilingTray",
  CeilingVaulted = "ceilingVaulted",
  Chandelier = "chandelier",
  CrownMolding = "crownMolding",
  DoubleVanity = "doubleVanity",
  DryerElectricHookup = "dryerElectricHookup",
  DryerGasHookup = "dryerGasHookup",
  EatInKitchen = "eatInKitchen",
  EntranceFoyer = "entranceFoyer",
  FrenchDoors = "frenchDoors",
  HighSpeedInternet = "highSpeedInternet",
  HisAndHersClosets = "hisAndHersClosets",
  InLawFloorplan = "inLawFloorplan",
  InteriorSteps = "interiorSteps",
  KitchenIsland = "kitchenIsland",
  LowFlowPlumbingFixtures = "lowFlowPlumbingFixtures",
  MultipleDiningAreas = "multipleDiningAreas",
  MultipleLivingAreas = "multipleLivingAreas",
  NaturalWoodwork = "naturalWoodwork",
  NoInteriorSteps = "noInteriorSteps",
  None = "none",
  OpenFloorplan = "openFloorplan",
  Pantry = "pantry",
  PrimaryBedroomOnMain = "primaryBedroomOnMain",
  RecessedLighting = "recessedLighting",
  SeeRemarks = "seeRemarks",
  SmartHome = "smartHome",
  SmartThermostat = "smartThermostat",
  SoakingTub = "soakingTub",
  SolarTubes = "solarTubes",
  SoundSystem = "soundSystem",
  Storage = "storage",
  TileCounters = "tileCounters",
  TrackLighting = "trackLighting",
  WalkInClosets = "walkInClosets",
  WasherHookup = "washerHookup",
  WaterSenseFixtures = "waterSenseFixtures",
  WiredForData = "wiredForData",
  WiredForSound = "wiredForSound"
}
export const InclusionsLabel = new Map<Inclusions, string>([
  [Inclusions.PrimaryBath, "1st Floor Entry"],
  [Inclusions.PrimarySuites, "2nd Floor Entry"],
  [Inclusions.Bar, "Bar"],
  [Inclusions.Bookcases, "Bookcases"],
  [Inclusions.BreakfastBar, "Breakfast Bar"],
  [Inclusions.BuiltInFeatures, "Built-in Features"],
  [Inclusions.CeilingFans, "Ceiling Fan(s)"],
  [Inclusions.CeilingBeamed, "Ceiling(s)-Beamed"],
  [Inclusions.CeilingCathedral, "Ceiling(s)-Cathedral"],
  [Inclusions.CeilingCoffered, "Ceiling(s)-Coffered"],
  [Inclusions.CeilingHigh, "Ceiling(s)-High"],
  [Inclusions.CeilingTray, "Ceiling(s)-Tray"],
  [Inclusions.CeilingVaulted, "Ceiling(s)-Vaulted"],
  [Inclusions.Chandelier, "Chandelier"],
  [Inclusions.CrownMolding, "Crown Molding"],
  [Inclusions.DoubleVanity, "Double Vanity"],
  [Inclusions.DryerElectricHookup, "Dryer-Electric Hookup"],
  [Inclusions.DryerGasHookup, "Dryer-Gas Hookup"],
  [Inclusions.EatInKitchen, "Eat-in Kitchen"],
  [Inclusions.EntranceFoyer, "Entrance Foyer"],
  [Inclusions.FrenchDoors, "French Doors"],
  [Inclusions.HighSpeedInternet, "High Speed Internet"],
  [Inclusions.HisAndHersClosets, "His and Hers Closets"],
  [Inclusions.InLawFloorplan, "In-Law Floorplan"],
  [Inclusions.InteriorSteps, "Interior Steps"],
  [Inclusions.KitchenIsland, "Kitchen Island"],
  [Inclusions.LowFlowPlumbingFixtures, "Low Flow Plumbing Fixtures"],
  [Inclusions.MultipleDiningAreas, "Multiple Dining Areas"],
  [Inclusions.MultipleLivingAreas, "Multiple Living Areas"],
  [Inclusions.NaturalWoodwork, "Natural Woodwork"],
  [Inclusions.NoInteriorSteps, "No Interior Steps"],
  [Inclusions.None, "None"],
  [Inclusions.OpenFloorplan, "Open Floorplan"],
  [Inclusions.Pantry, "Pantry"],
  [Inclusions.PrimaryBedroomOnMain, "Primary Bedroom on Main"],
  [Inclusions.RecessedLighting, "Recessed Lighting"],
  [Inclusions.SeeRemarks, "See Remarks"],
  [Inclusions.SmartHome, "Smart Home"],
  [Inclusions.SmartThermostat, "Smart Thermostat"],
  [Inclusions.SoakingTub, "Soaking Tub"],
  [Inclusions.SolarTubes, "Solar Tube(s)"],
  [Inclusions.SoundSystem, "Sound System"],
  [Inclusions.Storage, "Storage"],
  [Inclusions.TileCounters, "Tile Counters"],
  [Inclusions.TrackLighting, "Track Lighting"],
  [Inclusions.WalkInClosets, "Walk-In Closet(s"],
  [Inclusions.WasherHookup, "Washer Hookup"],
  [Inclusions.WaterSenseFixtures, "WaterSense Fixture(s)"],
  [Inclusions.WiredForData, "Wired For Data"],
  [Inclusions.WiredForSound, "Wired For Sound"]
]);
