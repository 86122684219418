export enum Refreshments {
  None = "none",
  Lunch = "lunch",
  Drinks = "drinks",
  Snacks = "snacks"
}

export const RefreshmentsLabels = new Map<Refreshments, string>([
  [Refreshments.None, "None"],
  [Refreshments.Lunch, "Lunch"],
  [Refreshments.Drinks, "Drinks"],
  [Refreshments.Snacks, "Snacks"]
]);
