import { EmailInput, FormContext } from "@homesusa/form";
import React from "react";

type EmailMultipleInputProps = { defaultValue?: string[]; name: string };

export const EmailMultipleInput: React.FC<EmailMultipleInputProps> = ({
  name,
  defaultValue
}) => {
  const { formDispatch } = React.useContext(FormContext);

  const handleSaveReatorEmail = (value: string): void => {
    if (formDispatch) {
      formDispatch({
        type: "FormChangeByInputName",
        inputName: name,
        payload: value ? value.split(";") : null
      });
    }
  };

  return (
    <EmailInput
      name={name}
      defaultValue={defaultValue ? defaultValue.join(";") : ""}
      onChange={handleSaveReatorEmail}
    />
  );
};
