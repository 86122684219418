export enum AirQuality {
  ContaminantControl = "contaminantControl",
  IntegratedPestManagement = "integratedPestManagement",
  MoistureControl = "moistureControl",
  Ventilation = "ventilation",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const AirQualityLabel = new Map<AirQuality, string>([
  [AirQuality.ContaminantControl, "Contaminant Control"],
  [AirQuality.IntegratedPestManagement, "Integrated Pest Management"],
  [AirQuality.MoistureControl, "Moisture Control"],
  [AirQuality.Ventilation, "Ventilation"],
  [AirQuality.OtherSeeRemarks, "Other-See Remarks"]
]);
