export enum MlsZone {
  hundredNW = "hundredNW",
  twoHundredSW = "twoHundredSW",
  threeHundredNE = "threeHundredNE",
  fourHundredSE = "fourHundredSE",
  nineHundredAllOfCanyon = "nineHundredAllOfCanyon",
  thousandNW = "thousandNW",
  twoThousandSW = "twoThousandSW",
  threeThousandNE = "threeThousandNE",
  fourThousandSE = "fourThousandSE",
  fiveThousandAll = "fiveThousandAll",
  sixThousandAll = "sixThousandAll",
  sevenThousandAll = "sevenThousandAll",
  eigthThousandAll = "eigthThousandAll",
  nineThousandAll = "nineThousandAll"
}

export const MlsZoneLabel: Record<MlsZone, string> = {
  [MlsZone.hundredNW]: "0100 - NW Amarillo in City Limits",
  [MlsZone.twoHundredSW]: "0200 - SW Amarillo in City Limits",
  [MlsZone.threeHundredNE]: "0300 - NE Amarillo in City Limits",
  [MlsZone.fourHundredSE]: "0400 - SE Amarillo in City Limits",
  [MlsZone.nineHundredAllOfCanyon]: "0900 - All of Canyon in City Limits",
  [MlsZone.thousandNW]: "1000 - NW of Amarillo City Limits",
  [MlsZone.twoThousandSW]: "2000 - SW of Amarillo City Limits",
  [MlsZone.threeThousandNE]: "3000 - NE of Amarillo City Limits",
  [MlsZone.fourThousandSE]: "4000 - SE of Amarillo City Limits",
  [MlsZone.fiveThousandAll]: "5000 - All areas in the 5000",
  [MlsZone.sixThousandAll]: "6000 - All areas in the 6000",
  [MlsZone.sevenThousandAll]: "7000 - All areas in the 7000",
  [MlsZone.eigthThousandAll]: "8000 - All areas in the 8000",
  [MlsZone.nineThousandAll]: "9000 - All Areas in the 9000"
};
