export enum SchoolDistrict {
  Amarillo = "amarillo",
  Balko = "balko",
  BorgerISD = "borgerISD",
  Bushland = "bushland",
  CanadianISD = "canadianISD",
  Canyon = "canyon",
  Claude = "claude",
  Dumas = "dumas",
  FrenshipISD = "frenshipISD",
  Happy = "happy",
  HighlandPark = "highlandPark",
  LevellandISD = "levellandISD",
  LubbockCooperISD = "lubbockCooperISD",
  LubbockISD = "lubbockISD",
  Other = "other",
  Panhandle = "panhandle",
  PerrytonIndependentSchoolDistrict = "perrytonIndependentSchoolDistrict",
  RiverRoad = "riverRoad",
  SanfordFritchISD = "sanfordFritchISD",
  ShallowaterISD = "shallowaterISD",
  VegaISD = "vegaISD",
  WildoradoISD = "wildoradoISD"
}

export const SchoolDistrictLabel: Record<SchoolDistrict, string> = {
  [SchoolDistrict.Amarillo]: "Amarillo",
  [SchoolDistrict.Balko]: "Balko",
  [SchoolDistrict.BorgerISD]: "Borger ISD",
  [SchoolDistrict.Bushland]: "Bushland",
  [SchoolDistrict.CanadianISD]: "Canadian ISD",
  [SchoolDistrict.Canyon]: "Canyon",
  [SchoolDistrict.Claude]: "Claude",
  [SchoolDistrict.Dumas]: "Dumas",
  [SchoolDistrict.FrenshipISD]: "Frenship ISD",
  [SchoolDistrict.Happy]: "Happy",
  [SchoolDistrict.HighlandPark]: "Highland Park",
  [SchoolDistrict.LevellandISD]: "Levelland ISD",
  [SchoolDistrict.LubbockCooperISD]: "Lubbock - Cooper ISD",
  [SchoolDistrict.LubbockISD]: "Lubbock ISD",
  [SchoolDistrict.Other]: "Other",
  [SchoolDistrict.Panhandle]: "Panhandle",
  [SchoolDistrict.PerrytonIndependentSchoolDistrict]:
    "Perryton Independent School District",
  [SchoolDistrict.RiverRoad]: "River Road",
  [SchoolDistrict.SanfordFritchISD]: "Sanford/Fritch ISD",
  [SchoolDistrict.ShallowaterISD]: "Shallowater ISD",
  [SchoolDistrict.VegaISD]: "Vega ISD",
  [SchoolDistrict.WildoradoISD]: "Wildorado ISD"
};
