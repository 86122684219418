import { FormRules } from "@homesusa/form";
import { salesOfficeRules } from "core/rules/sales-office";
import { saborCityRule } from "../../sections";

export const saborSalesOfficeRules: FormRules = {
  ...salesOfficeRules,
  salesOfficeCity: {
    ...saborCityRule,
    required: false
  }
};
