export enum LaundryFeatures {
  HookUpElectric = "hookUpElectric",
  HookUpGas = "hookUpGas",
  Sink = "sink",
  StackableSpace = "stackableSpace"
}
export const LaundryFeaturesLabel = new Map<LaundryFeatures, string>([
  [LaundryFeatures.HookUpElectric, "Hook Up - Electric"],
  [LaundryFeatures.HookUpGas, "Hook Up - Gas"],
  [LaundryFeatures.Sink, "Sink"],
  [LaundryFeatures.StackableSpace, "Stackable Space"]
]);
