export enum HomesUSAType {
  All = "all",
  IsInHomesUSA = "isInHomesUSA",
  NotInHomesUSA = "notInHomesUSA"
}

export const HomesUSATypeLabel = new Map<HomesUSAType, string>([
  [HomesUSAType.IsInHomesUSA, "HomesUSA Listings"],
  [HomesUSAType.NotInHomesUSA, "Non HomesUSA Listings"],
  [HomesUSAType.All, "All"]
]);
