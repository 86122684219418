export enum GreenCertification {
  BuildSanAntonioGreen = "buildSanAntonioGreen",
  EnergyStarCertified = "energyStarCertified",
  HersZeroEigthyFive = "hersZeroEigthyFive",
  HersHundredOnePlus = "hersHundredOnePlus",
  HersEigthySixHundred = "hersEigthySixHundred",
  HersRated = "hersRated",
  LeedCertified = "leedCertified",
  LeedGold = "leedGold"
}

export const GreenCertificationLabel = new Map<GreenCertification, string>([
  [GreenCertification.BuildSanAntonioGreen, "Build San Antonio Green"],
  [GreenCertification.EnergyStarCertified, "Energy Star Certified"],
  [GreenCertification.HersZeroEigthyFive, "HERS 0-85"],
  [GreenCertification.HersHundredOnePlus, "HERS 101+"],
  [GreenCertification.HersEigthySixHundred, "HERS 86-100"],
  [GreenCertification.HersRated, "HERS Rated"],
  [GreenCertification.LeedCertified, "LEED Certified"],
  [GreenCertification.LeedGold, "LEED Gold"]
]);
