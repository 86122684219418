export enum BillActionTypeFilter {
  All = "all",
  ActiveTransfer = "activeTransfer",
  Comparable = "comparable",
  NewListing = "newListing",
  PendingTransfer = "pendingTransfer",
  Relist = "relist"
}

export const BillActionTypeFilterLabel = new Map<BillActionTypeFilter, string>([
  [BillActionTypeFilter.All, "All"],
  [BillActionTypeFilter.NewListing, "New Listing"],
  [BillActionTypeFilter.Relist, "Relist"],
  [BillActionTypeFilter.Comparable, "Comparable"],
  [BillActionTypeFilter.ActiveTransfer, "Active Transfer"],
  [BillActionTypeFilter.PendingTransfer, "Pending Transfer"]
]);
