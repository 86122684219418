import { FormRules } from "@homesusa/form";
import { femaFloodPlainRule, propertyRules } from "../../sections";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  PropCondition,
  PropConditionLabel,
  PropertySubTypeLots,
  PropertySubTypeLotsLabels,
  SoilType,
  SoilTypeLabel,
  TypeOfHomeAllowed,
  TypeOfHomeAllowedLabel
} from "markets/abor/enums";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";

export const propertyInfoRules: FormRules = {
  mlsArea: { ...propertyRules.mlsArea, requiredToSubmit: true },
  lotDescription: propertyRules.lotDescription,
  femaFloodPlain: femaFloodPlainRule,
  legalDescription: { ...propertyRules.legalDescription, rows: 6 },
  taxLot: propertyRules.taxLot,
  taxBlock: { label: "Tax Block", maxLength: 25, requiredToSubmit: false },
  taxId: propertyRules.taxId,
  lotSize: propertyRules.lotSize,
  lotDimension: propertyRules.lotDimension,
  numberOfWells: { label: "# Of Wells" },
  numberOfPonds: { label: "# Of Ponds" },
  propertySubType: {
    label: "Property Sub Type",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(PropertySubTypeLots),
      PropertySubTypeLotsLabels
    ),
    rows: 2
  },
  surfaceWater: { label: "Surface Water", requiredToSubmit: true },
  typeOfHomeAllowed: {
    label: "Type Of Home Allowed",
    options: getOptionsFromEnumAndMap(
      Object.values(TypeOfHomeAllowed),
      TypeOfHomeAllowedLabel
    ),
    rows: 2
  },
  liveStock: { label: "Livestock" },
  commercialAllowed: { label: "Commercial Allowed", requiredToSubmit: true },
  soilType: {
    label: "Soil Type",
    options: getOptionsFromEnumAndMap(Object.values(SoilType), SoilTypeLabel),
    rows: 2
  },
  propCondition: {
    label: "Prop Condition",
    options: getOptionsFromEnumAndMap(
      Object.values(PropCondition),
      PropConditionLabel
    ),
    rows: 2
  },
  updateGeocodes: { label: "Update Geocodes" },
  latitude: latitudeRule,
  longitude: longitudeRule
};
