import React, { useContext } from "react";
import { Paper } from "@mui/material";
import { ActionCard } from "../action-card/action-card.component";
import { getDataByKey, getIntervalDate } from "../../../utils";
import { DateKey } from "../../../enums";
import { FormContext, DefaultFormContextProps } from "@homesusa/form";
import { ChartFilters } from "modules/dashboard/interfaces";
import { useStyles } from "./date-selector.styles";

export const DateSelector = ({
  handleSearch
}: {
  handleSearch: (from: Date, to: Date) => void;
}): JSX.Element => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState<string>();
  const {
    formState: { calendarInterval }
  } = useContext<DefaultFormContextProps<ChartFilters>>(FormContext);

  const onClick = (date: DateKey): void => {
    if (calendarInterval) {
      const from = getDataByKey(date, calendarInterval);
      const to = getIntervalDate(new Date(), calendarInterval, true);
      setSelectedDate(date);
      handleSearch(from, to);
    }
  };

  return (
    <Paper sx={classes.root}>
      {Object.values(DateKey)
        .filter((key) => key != DateKey.Today)
        .map((date) => (
          <ActionCard
            key={date}
            sx={classes.selector}
            elevation={0}
            className={selectedDate === date ? "selected" : undefined}
            onClick={(): void => onClick(date)}
          >
            {date}
          </ActionCard>
        ))}
    </Paper>
  );
};
