import { FormRules } from "@homesusa/form";
import {
  distanceToWaterAccessRule,
  utilitiesRules,
  waterfrontFeaturesRule
} from "../../sections";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  Disclosures,
  DisclosuresLabel,
  DocumentsAvailable,
  DocumentsAvailableLabel,
  HorseAmenities,
  HorseAmenitiesLabel,
  MineralsFeatures,
  MineralsFeaturesLabel,
  OtherStructures,
  OtherStructuresLabel,
  RoadSurface,
  RoadSurfaceLabel
} from "markets/abor/enums";

export const featuresInfoRules: FormRules = {
  restrictionsDescription: utilitiesRules.restrictionsDescription,
  waterfrontFeatures: waterfrontFeaturesRule,
  view: utilitiesRules.view,
  waterSewer: utilitiesRules.waterSewer,
  utilitiesDescription: utilitiesRules.utilitiesDescription,
  waterSource: utilitiesRules.waterSource,
  distanceToWaterAccess: distanceToWaterAccessRule,
  fencing: { ...utilitiesRules.fencing, requiredToSubmit: false },
  exteriorFeatures: {
    ...utilitiesRules.exteriorFeatures,
    requiredToSubmit: false
  },
  neighborhoodAmenities: utilitiesRules.neighborhoodAmenities,
  horseAmenities: {
    label: "Horse Amenities",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(HorseAmenities),
      HorseAmenitiesLabel
    ),
    noneOption: [HorseAmenities.None],
    lastOptions: [HorseAmenities.None],
    rows: 2
  },
  mineralsFeatures: {
    label: "Minerals Features",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(MineralsFeatures),
      MineralsFeaturesLabel
    ),
    noneOption: [HorseAmenities.None],
    lastOptions: [HorseAmenities.None],
    rows: 2
  },
  roadSurface: {
    label: "Road Surface",
    options: getOptionsFromEnumAndMap(
      Object.values(RoadSurface),
      RoadSurfaceLabel
    ),
    noneOption: [RoadSurface.None],
    lastOptions: [RoadSurface.None],
    rows: 2
  },
  otherStructures: {
    label: "Other Structures",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(OtherStructures),
      OtherStructuresLabel
    ),
    noneOption: [OtherStructures.None],
    lastOptions: [OtherStructures.None],
    rows: 2
  },
  documentsAvailable: {
    label: "Documents Available",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(DocumentsAvailable),
      DocumentsAvailableLabel
    ),
    noneOption: [DocumentsAvailable.NoneAvailable],
    lastOptions: [DocumentsAvailable.NoneAvailable],
    rows: 2
  },
  disclosures: {
    label: "Disclosures",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(Disclosures),
      DisclosuresLabel
    ),
    noneOption: [Disclosures.None],
    lastOptions: [Disclosures.None],
    rows: 2
  },
  groundWaterConservDistric: {
    label: "Ground Water Conservation District"
  },
  waterBodyName: utilitiesRules.waterBodyName
};
