import React from "react";
import { Format } from "@homesusa/core";
import { Columns } from "@homesusa/grid-table";
import { ListingRequestGrid as IGrid } from "core/interfaces";
import { ListingContext } from "modules/listings/contexts";
import { TruncateText } from "core/components";

export const useListingRequestColumns = (): Columns<IGrid> => {
  const {
    entity: { id },
    marketRules
  } = React.useContext(ListingContext);
  const cityOptions = React.useMemo(
    () => marketRules.city.options ?? {},
    [marketRules.city.options]
  );

  const columns: Columns<IGrid> = React.useMemo(
    () => [
      {
        Header: "MLS Number",
        accessor: (data: IGrid): string => data.mlsNumber
      },
      {
        Header: "Subdivision",
        accessor: (data: IGrid): string => data.subdivision,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Address",
        accessor: (data: IGrid): string =>
          `${data.address}${data.unitNumber ? ` ${data.unitNumber}` : ""}${
            data.streetType ? ` ${data.streetType}` : ""
          }`,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "City",
        accessor: (data: IGrid): string => cityOptions[data.city] ?? data.city,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Price",
        accessor: (data: IGrid): string => Format.Money(data.listPrice),
        id: "listPrice"
      },
      {
        Header: "Submitted On",
        accessor: (data: IGrid): string => Format.Date(data.sysCreatedOn),
        id: "sysCreatedOn"
      },
      {
        Header: "Submitted By",
        accessor: (data: IGrid): string => data.createdBy,
        id: "sysCreatedBy"
      }
    ],
    [cityOptions, id]
  );

  return columns;
};
