import React from "react";

import { FormContext } from "@homesusa/form";
import { BaseSaleListing, SaleProperty } from "core/interfaces";
import { useRequiredToSubmit } from "modules/listings/hooks";
import { BaseListingFormContextProps } from "modules/sale-listings/contexts";

interface CurrentSaleProperty extends SaleProperty {
  featuresInfo: { fireplaceDescription: string[] };
}

export const useFireplacesChange = (
  fireplaceDescriptionFieldName?: string
): ((fireplaceNumber?: unknown) => void) => {
  const {
    formState: {
      saleProperty: {
        featuresInfo: { fireplaceDescription }
      }
    }
  } =
    React.useContext<
      BaseListingFormContextProps<BaseSaleListing<CurrentSaleProperty>>
    >(FormContext);
  const dispatchSubmitRule = useRequiredToSubmit();

  const onChangeFireplaceNumber = (fireplaceNumber?: unknown): void => {
    dispatchSubmitRule({
      required: !!fireplaceNumber && Number(fireplaceNumber) > 0,
      defaultValues: {
        [fireplaceDescriptionFieldName ??
        "saleProperty.featuresInfo.fireplaceDescription"]:
          fireplaceNumber != null && fireplaceNumber !== 0
            ? fireplaceDescription
            : undefined
      }
    });
  };

  return onChangeFireplaceNumber;
};
