export enum BillActionType {
  NewListing = "newListing",
  Relist = "relist",
  Comparable = "comparable",
  ActiveTransfer = "activeTransfer",
  PendingTransfer = "pendingTransfer"
}

export const BillActionTypeLabel = new Map<BillActionType, string>([
  [BillActionType.NewListing, "New Listing"],
  [BillActionType.Relist, "Relist"],
  [BillActionType.Comparable, "Comparable"],
  [BillActionType.ActiveTransfer, "Active Transfer"],
  [BillActionType.PendingTransfer, "Pending Transfer"]
]);
