export enum Foundation {
  Combination = "combination",
  Permanent = "permanent",
  Slab = "slab"
}

export const FoundationLabel = new Map<Foundation, string>([
  [Foundation.Combination, "Combination"],
  [Foundation.Permanent, "Permanent"],
  [Foundation.Slab, "Slab"]
]);
