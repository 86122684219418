export enum RestrictionsDescription {
  BuildLineRestricted = "buildLineRestricted",
  ConservationDistrict = "conservationDistrict",
  DeedRestrictions = "deedRestrictions",
  HistoricRestrictions = "historicRestrictions",
  LotSizeRestricted = "lotSizeRestricted",
  MobileHomeAllowed = "mobileHomeAllowed",
  NoRestrictions = "noRestrictions",
  Restricted = "restricted",
  Unknown = "unknown",
  Zoning = "zoning"
}
export const RestrictionsDescriptionLabel = new Map<
  RestrictionsDescription,
  string
>([
  [RestrictionsDescription.BuildLineRestricted, "Build Line Restricted"],
  [RestrictionsDescription.ConservationDistrict, "Conservation District"],
  [RestrictionsDescription.DeedRestrictions, "Deed Restrictions"],
  [RestrictionsDescription.HistoricRestrictions, "Historic Restrictions"],
  [RestrictionsDescription.LotSizeRestricted, "Lot Size Restricted"],
  [RestrictionsDescription.MobileHomeAllowed, "Mobile Home Allowed"],
  [RestrictionsDescription.NoRestrictions, "No Restrictions"],
  [RestrictionsDescription.Restricted, "Restricted"],
  [RestrictionsDescription.Unknown, "Unknown"],
  [RestrictionsDescription.Zoning, "Zoning"]
]);
