export enum HoaWebsite {
  coloniesmasterassoc = "coloniesmasterassoc",
  heritagehillsama = "heritagehillsama",
  ddpoa = "ddpoa",
  EagleTreeAma = "eagleTreeAma",
  greenwayshoa = "greenwayshoa",
  laketanglewood = "laketanglewood",
  nrlah = "nrlah",
  orgsitestxlmhpoa = "orgsitestxlmhpoa",
  springlakeowners = "springlakeowners",
  thereserveamarillo = "thereserveamarillo",
  timbercreekcanyon = "timbercreekcanyon",
  Other = "other",
  None = "none",
  trailsattgc = "trailsattgc",
  townsquareliving = "townsquareliving",
  springcanyon = "springcanyon"
}

export const HoaWebsiteLabel: Record<HoaWebsite, string> = {
  [HoaWebsite.coloniesmasterassoc]: "www.coloniesmasterassoc.com ",
  [HoaWebsite.heritagehillsama]: "www.heritagehillsama.com",
  [HoaWebsite.ddpoa]: "www.ddpoa.webs.com",
  [HoaWebsite.EagleTreeAma]: "www.EagleTreeAma.org",
  [HoaWebsite.greenwayshoa]: "www.greenwayshoa.com",
  [HoaWebsite.laketanglewood]: "www.laketanglewood.org",
  [HoaWebsite.nrlah]: "https://nrlah.com/",
  [HoaWebsite.orgsitestxlmhpoa]: "www.orgsites.com/tx/lmhpoa/",
  [HoaWebsite.springlakeowners]: "www.springlakeowners.org",
  [HoaWebsite.thereserveamarillo]: "www.thereserveamarillo.com",
  [HoaWebsite.timbercreekcanyon]: "www.timbercreekcanyon.org",
  [HoaWebsite.Other]: "Other",
  [HoaWebsite.None]: "None",
  [HoaWebsite.trailsattgc]: "https://www.trailsattgc.com/",
  [HoaWebsite.townsquareliving]: "www.hoa.townsquareliving.com/",
  [HoaWebsite.springcanyon]: "www.springcanyon.net"
};
