import React from "react";
import { Format } from "@homesusa/core";
import { Box, Typography } from "@mui/material";

export const AmountDiscrepancy = ({
  amount,
  description
}: {
  amount: number;
  description: string;
}): JSX.Element => {
  return (
    <Box>
      <Typography
        color="GrayText"
        variant="h1"
        fontSize="20px"
        fontWeight="bold"
      >
        {Format.Money(amount)}
      </Typography>

      <Typography color="GrayText" fontSize={"12px"} lineHeight={"14px"}>
        {description}
      </Typography>
    </Box>
  );
};
