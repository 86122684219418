import React from "react";

import { AuthCompanyContext } from "@homesusa/auth";
import {
  getMarketCityRule,
  getMarketOccupancyRule,
  getMarketStatusRule
} from "markets/utils";
import DownloaderServices from "../services/downloader.service";
import { getBillingReport } from "../services/quicklister.service";
import { ReportsContext } from "./reports.context";

export const ReportsProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { currentMarket } = React.useContext(AuthCompanyContext);

  const marketRules = React.useMemo(
    () => ({
      city: getMarketCityRule(currentMarket),
      mlsStatus: getMarketStatusRule(currentMarket),
      occupancy: getMarketOccupancyRule(currentMarket)
    }),
    [currentMarket]
  );

  const services = React.useMemo(() => {
    return {
      ...DownloaderServices(currentMarket),
      getBillingReport: getBillingReport(currentMarket)
    };
  }, [currentMarket]);

  return (
    <ReportsContext.Provider value={{ services, marketRules }}>
      {children}
    </ReportsContext.Provider>
  );
};
