import { FormRules, flattenRules } from "@homesusa/form";
import { MlsStatus } from "markets/abor/enums";
import { salePropertyRules } from "./sale-property";
import { rulesByStatus } from "./status";
import { listingRules } from "./listing";

const rules = {
  ...listingRules,
  saleProperty: salePropertyRules
};

export const saleListingRules: FormRules = flattenRules(rules);

export const saleListingRulesWithStatus = (mlsStatus: MlsStatus): FormRules =>
  flattenRules({
    ...rules,
    statusFieldsInfo: rulesByStatus.get(mlsStatus)
  });
