export enum StoriesFeatures {
  Loft = "loft",
  Single = "single",
  SplitLevel = "splitLevel",
  ThreeStory = "threeStory",
  TwoStory = "twoStory",
  WalkOut = "walkOut"
}

export const StoriesFeaturesLabel = new Map<StoriesFeatures, string>([
  [StoriesFeatures.Loft, "Loft"],
  [StoriesFeatures.Single, "Single"],
  [StoriesFeatures.SplitLevel, "Split Level"],
  [StoriesFeatures.ThreeStory, "Three Story"],
  [StoriesFeatures.TwoStory, "Two Story"],
  [StoriesFeatures.WalkOut, "Walk Out"]
]);
