export enum HoaTerm {
  Annual = "annual",
  Monthly = "monthly",
  Quarter = "quarter",
  SemiAnnual = "semiAnnual"
}
export const HoaTermLabel = new Map<HoaTerm, string>([
  [HoaTerm.Annual, "Annual"],
  [HoaTerm.Monthly, "Monthly"],
  [HoaTerm.Quarter, "Quarter"],
  [HoaTerm.SemiAnnual, "Semi Annual"]
]);
