export enum HeatSystem {
  HeatPump = "heatPump",
  Zoned = "zoned",
  Butane = "butane",
  NoHeating = "noHeating",
  OtherHeating = "otherHeating",
  Propane = "propane",
  CentralElectric = "centralElectric",
  SolarAssisted = "solarAssisted",
  CentralGas = "centralGas"
}

export const HeatSystemLabel = new Map<HeatSystem, string>([
  [HeatSystem.HeatPump, "Heat Pump"],
  [HeatSystem.Zoned, "Zoned"],
  [HeatSystem.Butane, "Butane"],
  [HeatSystem.NoHeating, "No Heating"],
  [HeatSystem.OtherHeating, "Other Heating"],
  [HeatSystem.Propane, "Propane"],
  [HeatSystem.CentralElectric, "Central Electric"],
  [HeatSystem.SolarAssisted, "Solar Assisted"],
  [HeatSystem.CentralGas, "Central Gas"]
]);
