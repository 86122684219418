import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  AcceptableFinancing,
  AcceptableFinancingLabel,
  Exemptions,
  ExemptionsLabel
} from "markets/abor/enums";

export const financialRules: FormRules = {
  taxRate: {
    requiredToSubmit: true,
    label: "Tax",
    maxLength: 14,
    numberFormat: {
      min: 0,
      thousandSeparator: true,
      decimalScale: 2
    }
  },
  titleCompany: {
    label: "Preferred Title Company",
    maxLength: 45
  },
  buyersAgentCommission: {
    label: "Buyer's Agent Commission",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 3
    }
  },
  readableBuyersAgentCommission: { label: "Buyer's Agent Commission" },
  acceptableFinancing: {
    label: "Acceptable Financing",
    options: getOptionsFromEnumAndMap(
      Object.values(AcceptableFinancing),
      AcceptableFinancingLabel
    ),
    rows: 2,
    requiredToSubmit: true
  },
  taxExemptions: {
    label: "Exemptions",
    options: getOptionsFromEnumAndMap(
      Object.values(Exemptions),
      ExemptionsLabel
    ),
    rows: 2,
    requiredToSubmit: true,
    lastOptions: [Exemptions.None],
    noneOption: [Exemptions.None]
  }
};
