export enum RoomLevel {
  One = "one",
  Two = "two",
  Three = "three",
  Four = "four",
  Five = "five",
  Six = "six",
  Seven = "seven",
  Eight = "eight",
  Nine = "nine",
  B = "b"
}

export const RoomLevelLabel = new Map<RoomLevel, string>([
  [RoomLevel.One, "1"],
  [RoomLevel.Two, "2"],
  [RoomLevel.Three, "3"],
  [RoomLevel.Four, "4"],
  [RoomLevel.Five, "5"],
  [RoomLevel.Six, "6"],
  [RoomLevel.Seven, "7"],
  [RoomLevel.Eight, "8"],
  [RoomLevel.Nine, "9"],
  [RoomLevel.B, "B"]
]);
