import React from "react";

import { Format } from "@homesusa/core";
import { getOptionValueFromRule } from "@homesusa/form";
import { Column } from "@homesusa/grid-table";
import { ListingAgent } from "../../interfaces";
import { AgentType, SellerTypeLabel } from "modules/reports/enums";
import { ReportsContext } from "modules/reports/contexts";

type columnReport = Array<Column<ListingAgent>>;
type columnsRecord = Record<string, Column<ListingAgent>>;

export const useAgentListingsGridColumns = (
  agentType: AgentType
): columnReport => {
  const { marketRules } = React.useContext(ReportsContext);
  let columns: columnReport = [];

  const allColumns: columnsRecord = React.useMemo(
    () => ({
      row: {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1
      },
      sellingAgent: {
        Header: "Agent",
        accessor: "sellingAgent"
      },
      sellingAgentEmail: {
        Header: "Email",
        accessor: "sellingAgentEmail"
      },
      sellingAgentPhone: {
        Header: "Agent Phone",
        accessor: "sellingAgentPhone"
      },
      office: {
        Header: "Office",
        accessor: "officeName"
      },
      subdivision: {
        Header: "Subdivision",
        accessor: "subdivision"
      },
      city: {
        Header: "City",
        accessor: (data: ListingAgent): string =>
          getOptionValueFromRule(marketRules.city, data.city),
        id: "city"
      },
      listingAgent: {
        Header: "Listing Agent",
        accessor: "listingAgent"
      },
      listingAgentPhone: {
        Header: "Agent Phone",
        accessor: "listingAgentPhone"
      },
      sellerType: {
        Header: "Seller Type",
        accessor: (data: ListingAgent): string =>
          SellerTypeLabel.get(data.sellerType) ?? data.sellerType,
        id: "sellerType"
      },
      closePrice: {
        Header: "Sale Price",
        accessor: (data: ListingAgent): string => Format.Money(data.closePrice),
        id: "closePrice"
      },
      closeDate: {
        Header: "Sold On",
        accessor: (data: ListingAgent): string => Format.Date(data.closeDate),
        id: "closeDate"
      },
      cdom: {
        Header: "CDOM",
        accessor: "cdom"
      },
      mlsNumber: {
        Header: "MLS Number",
        accessor: "mlsNumber"
      },
      soldSqft: {
        Header: "SP $ / SF",
        accessor: (data: ListingAgent): string => Format.Money(data.soldSqft),
        id: "soldSqft"
      },
      listSqft: {
        Header: "LP $ / SF",
        accessor: (data: ListingAgent): string => Format.Money(data.listSqft),
        id: "listSqft"
      },
      listPrice: {
        Header: "List Price",
        accessor: (data: ListingAgent): string => Format.Money(data.listPrice),
        id: "listPrice"
      },
      listDate: {
        Header: "List Date",
        accessor: (data: ListingAgent): string => Format.Date(data.listDate),
        id: "listDate"
      }
    }),
    [marketRules.city]
  );

  switch (agentType) {
    case AgentType.Selling:
      columns = [
        allColumns.row,
        allColumns.sellingAgent,
        allColumns.sellingAgentPhone,
        allColumns.office,
        allColumns.subdivision,
        allColumns.city,
        allColumns.sellerType,
        allColumns.listPrice,
        allColumns.closePrice,
        allColumns.soldSqft,
        allColumns.closeDate,
        allColumns.cdom,
        allColumns.mlsNumber
      ];
      break;
    case AgentType.SellingInternal:
      columns = [
        allColumns.row,
        allColumns.sellingAgent,
        allColumns.sellingAgentEmail,
        allColumns.sellingAgentPhone,
        allColumns.office,
        allColumns.subdivision,
        allColumns.city,
        allColumns.listingAgent,
        allColumns.sellerType,
        allColumns.listPrice,
        allColumns.closePrice,
        allColumns.soldSqft,
        allColumns.closeDate,
        allColumns.cdom,
        allColumns.mlsNumber
      ];
      break;
    default:
      columns = [
        allColumns.row,
        allColumns.listingAgent,
        allColumns.listingAgentPhone,
        allColumns.office,
        allColumns.subdivision,
        allColumns.city,
        allColumns.listPrice,
        allColumns.listSqft,
        allColumns.listDate,
        allColumns.cdom,
        allColumns.mlsNumber
      ];
      break;
  }

  return React.useMemo(() => columns, [agentType]);
};
