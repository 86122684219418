export enum HousingStyle {
  Colonial = "colonial",
  ContemporaryModern = "contemporaryModern",
  Craftsman = "craftsman",
  EarlyAmerican = "earlyAmerican",
  English = "english",
  French = "french",
  Mediterranean = "mediterranean",
  MidCenturyModern = "midCenturyModern",
  Oriental = "oriental",
  Other = "other",
  Prairie = "prairie",
  Ranch = "ranch",
  Southwestern = "southwestern",
  Spanish = "spanish",
  SplitLevel = "splitLevel",
  Traditional = "traditional",
  Tudor = "tudor",
  Victorian = "victorian"
}

export const HousingStyleLabel = new Map<HousingStyle, string>([
  [HousingStyle.Colonial, "Colonial"],
  [HousingStyle.ContemporaryModern, "Contemporary/Modern"],
  [HousingStyle.Craftsman, "Craftsman"],
  [HousingStyle.EarlyAmerican, "Early American"],
  [HousingStyle.English, "English"],
  [HousingStyle.French, "French"],
  [HousingStyle.Mediterranean, "Mediterranean"],
  [HousingStyle.MidCenturyModern, "Mid-Century Modern"],
  [HousingStyle.Oriental, "Oriental"],
  [HousingStyle.Other, "Other"],
  [HousingStyle.Prairie, "Prairie"],
  [HousingStyle.Ranch, "Ranch"],
  [HousingStyle.Southwestern, "Southwestern"],
  [HousingStyle.Spanish, "Spanish"],
  [HousingStyle.SplitLevel, "Split Level"],
  [HousingStyle.Traditional, "Traditional"],
  [HousingStyle.Tudor, "Tudor"],
  [HousingStyle.Victorian, "Victorian"]
]);
