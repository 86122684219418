export enum ParkingFeatures {
  AdditionalParking = "additionalParking",
  GarageFacesFront = "garageFacesFront",
  GarageFacesRear = "garageFacesRear",
  GarageFacesSide = "garageFacesSide",
  GolfCartGarage = "golfCartGarage",
  PorteCochere = "porteCochere",
  RVCarport = "rvCarport",
  RVGarage = "rvGarage",
  None = "none",
  Other = "other"
}

export const ParkingFeaturesLabel: Record<ParkingFeatures, string> = {
  [ParkingFeatures.AdditionalParking]: "Additional Parking",
  [ParkingFeatures.GarageFacesFront]: "Garage Faces Front",
  [ParkingFeatures.GarageFacesRear]: "Garage Faces Rear",
  [ParkingFeatures.GarageFacesSide]: "Garage Faces Side",
  [ParkingFeatures.GolfCartGarage]: "Golf Cart Garage",
  [ParkingFeatures.PorteCochere]: "Porte-Cochere",
  [ParkingFeatures.RVCarport]: "RV Carport",
  [ParkingFeatures.RVGarage]: "RV Garage",
  [ParkingFeatures.None]: "None ",
  [ParkingFeatures.Other]: "Other"
};
