import { FormRules } from "@homesusa/form";
import { showingRules } from "../../sections";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  ShowingContactType,
  ShowingContactTypeLabel
} from "markets/abor/enums";

export const showingInfoRules: FormRules = {
  showingRequirements: showingRules.showingRequirements,
  ownerName: showingRules.ownerName,
  showingInstructions: showingRules.showingInstructions,
  showingServicePhone: showingRules.occupantPhone,
  apptPhone: { ...showingRules.contactPhone, requiredToSubmit: true },
  directions: showingRules.directions,
  showingContactType: {
    label: "Showing Contact Type",
    options: getOptionsFromEnumAndMap(
      Object.values(ShowingContactType),
      ShowingContactTypeLabel
    ),
    requiredToSubmit: true
  },
  publicRemarks: {
    label: "Public Remarks",
    requiredToSubmit: true,
    minLengthSuggestion: 25,
    maxLength: 2000,
    rows: 5
  },
  showingContactName: {
    label: "Showing Contact Name"
  }
};
