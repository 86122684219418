export enum RestrictionsDesc {
  Adult55 = "adult55",
  Adult62 = "adult62",
  BuildingSize = "buildingSize",
  BuildingStyle = "buildingStyle",
  CityRestrictions = "cityRestrictions",
  Covenant = "covenant",
  DeedRestrictions = "deedRestrictions",
  DevelopmentType = "developmentType",
  EasementROW = "easementROW",
  Environmental = "environmental",
  Lease = "lease",
  LimitedVehicles = "limitedVehicles",
  Livestock = "livestock",
  None = "none",
  SellerImposed = "sellerImposed",
  Zoning = "zoning"
}
export const RestrictionsDescLabel = new Map<RestrictionsDesc, string>([
  [RestrictionsDesc.Adult55, "Adult 55+"],
  [RestrictionsDesc.Adult62, "Adult 62+"],
  [RestrictionsDesc.BuildingSize, "Building Size"],
  [RestrictionsDesc.BuildingStyle, "Building Style"],
  [RestrictionsDesc.CityRestrictions, "City Restrictions"],
  [RestrictionsDesc.Covenant, "Covenant"],
  [RestrictionsDesc.DeedRestrictions, "Deed Restrictions"],
  [RestrictionsDesc.DevelopmentType, "Development Type"],
  [RestrictionsDesc.EasementROW, "Easement/R.O.W."],
  [RestrictionsDesc.Environmental, "Environmental"],
  [RestrictionsDesc.Lease, "Lease"],
  [RestrictionsDesc.LimitedVehicles, "Limited # Vehicles"],
  [RestrictionsDesc.Livestock, "Livestock"],
  [RestrictionsDesc.None, "None"],
  [RestrictionsDesc.SellerImposed, "Seller Imposed"],
  [RestrictionsDesc.Zoning, "Zoning"]
]);
