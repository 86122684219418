export enum GarageDescription {
  AlleyAccess = "alleyAccess",
  Attached = "attached",
  CircularDriveway = "circularDriveway",
  Concrete = "concrete",
  Covered = "covered",
  Detached = "detached",
  DirectAccess = "directAccess",
  DoorMulti = "doorMulti",
  DoorSingle = "doorSingle",
  DriveThrough = "driveThrough",
  Driveway = "driveway",
  ElectricGate = "electricGate",
  Garage = "garage",
  GarageDoorOpener = "garageDoorOpener",
  GarageFacesFront = "garageFacesFront",
  GarageFacesRear = "garageFacesRear",
  GarageFacesSide = "garageFacesSide",
  Gravel = "gravel",
  InsideEntrance = "insideEntrance",
  KitchenLevel = "kitchenLevel",
  Lighted = "lighted",
  Oversized = "oversized",
  Private = "private",
  SideBySide = "sideBySide",
  Storage = "storage",
  Tandem = "tandem"
}
export const GarageDescriptionLabel = new Map<GarageDescription, string>([
  [GarageDescription.AlleyAccess, "Alley Access"],
  [GarageDescription.Attached, "Attached"],
  [GarageDescription.CircularDriveway, "Circular Driveway"],
  [GarageDescription.Concrete, "Concrete"],
  [GarageDescription.Covered, "Covered"],
  [GarageDescription.Detached, "Detached"],
  [GarageDescription.DirectAccess, "Direct Access"],
  [GarageDescription.DoorMulti, "Door-Multi"],
  [GarageDescription.DoorSingle, "Door-Single"],
  [GarageDescription.DriveThrough, "Drive Through"],
  [GarageDescription.Driveway, "Driveway"],
  [GarageDescription.ElectricGate, "Electric Gate"],
  [GarageDescription.Garage, "Garage"],
  [GarageDescription.GarageDoorOpener, "Garage Door Opener"],
  [GarageDescription.GarageFacesFront, "Garage Faces Front"],
  [GarageDescription.GarageFacesRear, "Garage Faces Rear"],
  [GarageDescription.GarageFacesSide, "Garage Faces Side"],
  [GarageDescription.Gravel, "Gravel"],
  [GarageDescription.InsideEntrance, "Inside Entrance"],
  [GarageDescription.KitchenLevel, "Kitchen Level"],
  [GarageDescription.Lighted, "Lighted"],
  [GarageDescription.Oversized, "Oversized"],
  [GarageDescription.Private, "Private"],
  [GarageDescription.SideBySide, "Side By Side"],
  [GarageDescription.Storage, "Storage"],
  [GarageDescription.Tandem, "Tandem"]
]);
