export enum SqFtSource {
  AppraisalDistrict = "appraisalDistrict",
  ApprMeasure = "apprMeasure",
  Architect = "architect",
  BuilderPlan = "builderPlan",
  BuilderStatement = "builderStatement",
  DealerPlan = "dealerPlan",
  FhaAppraisal = "fhaAppraisal",
  ProfEngineer = "profEngineer"
}
export const SqFtSourceLabel = new Map<SqFtSource, string>([
  [SqFtSource.AppraisalDistrict, "Appraisal District"],
  [SqFtSource.ApprMeasure, "Appr Measure"],
  [SqFtSource.Architect, "Architect"],
  [SqFtSource.BuilderPlan, "Builder Plan"],
  [SqFtSource.BuilderStatement, "Builder Statement"],
  [SqFtSource.DealerPlan, "Dealer Plan"],
  [SqFtSource.FhaAppraisal, "FHA Appraisal"],
  [SqFtSource.ProfEngineer, "Prof Engineer"]
]);
