import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  HoaRequirement,
  HoaRequirementLabel,
  ProposedTerms,
  ProposedTermsLabel
} from "markets/sabor/enums";

export const financialRules: FormRules = {
  taxRate: {
    requiredToSubmit: true,
    label: "Total Tax (Without Exemptions)",
    maxLength: 14,
    numberFormat: {
      min: 0,
      thousandSeparator: true,
      decimalScale: 12
    }
  },
  titleCompany: {
    requiredToSubmit: true,
    label: "Title Company",
    maxLength: 45
  },
  isMultipleTaxed: { label: "Taxed by Multiple Counties" },
  proposedTerms: {
    requiredToSubmit: true,
    label: "Proposed Terms",
    options: getOptionsFromEnumAndMap(
      Object.values(ProposedTerms),
      ProposedTermsLabel
    ),
    rows: 2
  },
  hoaRequirement: {
    label: "HOA Requirement",
    requiredToSubmit: true,
    lastOptions: [HoaRequirement.None],
    options: getOptionsFromEnumAndMap(
      Object.values(HoaRequirement),
      HoaRequirementLabel
    ),
    sort: false
  },
  buyersAgentCommissionType: {
    label: "Buyers Agent Commission Type"
  },
  buyersAgentCommission: {
    label: "Buyer's Agent Commission",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 2
    }
  },
  readableBuyersAgentCommission: { label: "Buyer's Agent Commission" }
};
