export enum BedroomDescription {
  OneBedroomDownNotPrimary = "oneBedroomDownNotPrimary",
  OneBedroomUp = "oneBedroomUp",
  TwoBedroomsDown = "twoBedroomsDown",
  TwoPrimaryBedrooms = "twoPrimaryBedrooms",
  AllBedroomsDown = "allBedroomsDown",
  AllBedroomsUp = "allBedroomsUp",
  EnsuiteBath = "enSuiteBath",
  PrimaryBed1stFloor = "primaryBed1stFloor",
  PrimaryBed2ndFloor = "primaryBed2ndFloor",
  PrimaryBed3rdFloor = "primaryBed3rdFloor",
  PrimaryBed4thFloor = "primaryBed4thFloor",
  MultilevelBedroom = "multilevelBedroom",
  SittingArea = "sittingArea",
  SplitPlan = "splitPlan",
  WalkInCloset = "walkInCloset"
}

export const BedroomDescriptionsLabel = new Map<BedroomDescription, string>([
  [
    BedroomDescription.OneBedroomDownNotPrimary,
    "1 Bedroom Down - Not Primary BR"
  ],
  [BedroomDescription.OneBedroomUp, "1 Bedroom Up"],
  [BedroomDescription.TwoBedroomsDown, "2 Bedrooms Down"],
  [BedroomDescription.TwoPrimaryBedrooms, "2 Primary Bedrooms"],
  [BedroomDescription.AllBedroomsDown, "All Bedrooms Down"],
  [BedroomDescription.AllBedroomsUp, "All Bedrooms Up"],
  [BedroomDescription.EnsuiteBath, "En-Suite Bath"],
  [BedroomDescription.PrimaryBed1stFloor, "Primary Bed - 1st Floor"],
  [BedroomDescription.PrimaryBed2ndFloor, "Primary Bed - 2nd Floor"],
  [BedroomDescription.PrimaryBed3rdFloor, "Primary Bed - 3rd Floor"],
  [BedroomDescription.PrimaryBed4thFloor, "Primary Bed - 4th Floor"],
  [BedroomDescription.MultilevelBedroom, "Multilevel Bedroom"],
  [BedroomDescription.SittingArea, "Sitting Area"],
  [BedroomDescription.SplitPlan, "Split Plan"],
  [BedroomDescription.WalkInCloset, "Walk-In Closet"]
]);
