export enum SoldTerms {
  AssumptionOfEquity = "assumptionOfEquity",
  BondMoney = "bondMoney",
  Cash = "cash",
  Conventional = "conventional",
  FederalLandBank = "federalLandBank",
  FHA = "fha",
  FMHA = "fmha",
  LeasePurchase = "leasePurchase",
  Other = "other",
  OwnerCarryFirst = "ownerCarryFirst",
  OwnerCarrySecond = "ownerCarrySecond",
  TexasVet = "texasVet",
  TradeExchange = "tradeExchange",
  USDA = "usda",
  VA = "va"
}

export const SoldTermsLabel = new Map<SoldTerms, string>([
  [SoldTerms.AssumptionOfEquity, "Assumption of Equity"],
  [SoldTerms.BondMoney, "Bond Money"],
  [SoldTerms.Cash, "Cash"],
  [SoldTerms.Conventional, "Conventional"],
  [SoldTerms.FederalLandBank, "Federal Land Bank"],
  [SoldTerms.FHA, "FHA"],
  [SoldTerms.FMHA, "FMHA"],
  [SoldTerms.LeasePurchase, "Lease Purchase"],
  [SoldTerms.Other, "Other"],
  [SoldTerms.OwnerCarryFirst, "Owner Carry First"],
  [SoldTerms.OwnerCarrySecond, "Owner Carry Second"],
  [SoldTerms.TexasVet, "Texas Vet"],
  [SoldTerms.TradeExchange, "Trade/Exchange"],
  [SoldTerms.USDA, "USDA"],
  [SoldTerms.VA, "VA"]
]);
