export enum AppliancesEquipment {
  CookTopElectric = "cookTopElectric",
  CookTopGas = "cookTopGas",
  Dishwasher = "dishwasher",
  Disposal = "disposal",
  Downdraft = "downdraft",
  Dryer = "dryer",
  ExhaustFanRecirculated = "exhaustFanRecirculated",
  ExhaustFanVented = "exhaustFanVented",
  IceMaker = "iceMaker",
  IceMakerConnection = "iceMakerConnection",
  IndoorGrill = "indoorGrill",
  InstantHotWater = "instantHotWater",
  Microwave = "microwave",
  OtherSeeRemarks = "otherSeeRemarks",
  OvenConvection = "ovenConvection",
  OvenDouble = "ovenDouble",
  OvenSingle = "ovenSingle",
  RangeElectric = "rangeElectric",
  RangeGas = "rangeGas",
  RefigeratorSubZeroTypeR = "refigeratorSubZeroTypeR",
  Refrigerator = "refrigerator",
  RefrigeratorWine = "refrigeratorWine",
  VentHood = "ventHood",
  Washer = "washer",
  WaterHeaterTankless = "waterHeaterTankless",
  WaterHeaterOneUnit = "waterHeaterOneUnit",
  WaterHeaterTwoUnits = "waterHeaterTwoUnits",
  WaterHeaterElectric = "waterHeaterElectric",
  WaterHeaterGas = "waterHeaterGas",
  WaterHeaterPropaneButane = "waterHeaterPropaneButane",
  WaterHeaterSolar = "waterHeaterSolar",
  WaterHeaterSolarCombo = "waterHeaterSolarCombo",
  None = "none"
}
export const AppliancesEquipmentLabel = new Map<AppliancesEquipment, string>([
  [AppliancesEquipment.CookTopElectric, "Cook Top Electric"],
  [AppliancesEquipment.CookTopGas, "Cook Top Gas"],
  [AppliancesEquipment.Dishwasher, "Dishwasher"],
  [AppliancesEquipment.Disposal, "Disposal"],
  [AppliancesEquipment.Downdraft, "Downdraft"],
  [AppliancesEquipment.Dryer, "Dryer"],
  [AppliancesEquipment.ExhaustFanRecirculated, "Exhaust Fan Recirculated"],
  [AppliancesEquipment.ExhaustFanVented, "Exhaust Fan Vented"],
  [AppliancesEquipment.IceMaker, "Ice Maker"],
  [AppliancesEquipment.IceMakerConnection, "Ice Maker Connection"],
  [AppliancesEquipment.IndoorGrill, "Indoor Grill"],
  [AppliancesEquipment.InstantHotWater, "Instant Hot Water"],
  [AppliancesEquipment.Microwave, "Microwave"],
  [AppliancesEquipment.OtherSeeRemarks, "Other-See Remarks"],
  [AppliancesEquipment.OvenConvection, "Oven-Convection"],
  [AppliancesEquipment.OvenDouble, "Oven-Double"],
  [AppliancesEquipment.OvenSingle, "Oven-Single"],
  [AppliancesEquipment.RangeElectric, "Range-Electric"],
  [AppliancesEquipment.RangeGas, "Range-Gas"],
  [AppliancesEquipment.RefigeratorSubZeroTypeR, "Refigerator Sub-Zero Type R"],
  [AppliancesEquipment.Refrigerator, "Refrigerator"],
  [AppliancesEquipment.RefrigeratorWine, "Refrigerator - Wine"],
  [AppliancesEquipment.VentHood, "Vent Hood"],
  [AppliancesEquipment.Washer, "Washer"],
  [AppliancesEquipment.WaterHeaterTankless, "Water Heater -Tankless"],
  [AppliancesEquipment.WaterHeaterOneUnit, "Water Heater 1 Unit"],
  [AppliancesEquipment.WaterHeaterTwoUnits, "Water Heater 2+ Units"],
  [AppliancesEquipment.WaterHeaterElectric, "Water Heater Electric"],
  [AppliancesEquipment.WaterHeaterGas, "Water Heater Gas"],
  [AppliancesEquipment.WaterHeaterPropaneButane, "Water Heater Propane/Butane"],
  [AppliancesEquipment.WaterHeaterSolar, "Water Heater Solar"],
  [AppliancesEquipment.WaterHeaterSolarCombo, "Water Heater Solar Combo"],
  [AppliancesEquipment.None, "None"]
]);
