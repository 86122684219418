export enum OtherParking {
  NotApplicable = "notApplicable",
  OneCarCarport = "oneCarCarport",
  TwoCarCarport = "twoCarCarport",
  Open = "open",
  PadOnlyOffStreet = "padOnlyOffStreet",
  StreetParkingOnly = "streetParkingOnly"
}
export const OtherParkingLabel = new Map<OtherParking, string>([
  [OtherParking.NotApplicable, "None/Not Applicable"],
  [OtherParking.OneCarCarport, "One Car Carport"],
  [OtherParking.TwoCarCarport, "Two Car Carport"],
  [OtherParking.Open, "Open"],
  [OtherParking.PadOnlyOffStreet, "Pad Only (Off Street)"],
  [OtherParking.StreetParkingOnly, "Street Parking Only"]
]);
