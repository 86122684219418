export enum SeniorSchool {
  Carroll = "carroll",
  CityView = "cityView",
  Groveton = "groveton",
  Loading = "loading",
  Lockhart = "lockhart",
  LockhartHsFreshman = "lockhartHsFreshman",
  Midway = "midway",
  NorthShore = "northShore",
  PlanoEast = "planoEast",
  PlanoSenior = "planoSenior",
  PlanoWest = "planoWest",
  Wylie = "wylie"
}

export const SeniorSchoolLabel: Record<SeniorSchool, string> = {
  [SeniorSchool.Carroll]: "Carroll",
  [SeniorSchool.CityView]: "City View",
  [SeniorSchool.Groveton]: "Groveton",
  [SeniorSchool.Loading]: "Loading",
  [SeniorSchool.Lockhart]: "Lockhart",
  [SeniorSchool.LockhartHsFreshman]: "Lockhart Hs Freshman",
  [SeniorSchool.Midway]: "Midway",
  [SeniorSchool.NorthShore]: "North Shore",
  [SeniorSchool.PlanoEast]: "Plano East",
  [SeniorSchool.PlanoSenior]: "Plano Senior",
  [SeniorSchool.PlanoWest]: "Plano West",
  [SeniorSchool.Wylie]: "Wylie"
};
