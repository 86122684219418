export enum HoaRequirement {
  Mandatory = "mandatory",
  Voluntary = "voluntary",
  No = "no"
}

export const HoaRequirementLabel = new Map<HoaRequirement, string>([
  [HoaRequirement.Mandatory, "Mandatory"],
  [HoaRequirement.Voluntary, "Voluntary"],
  [HoaRequirement.No, "No"]
]);
