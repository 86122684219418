import React from "react";
import { Box } from "@mui/material";
import { LockedStatus } from "core/enums";
import { StatusStyled } from "core/styles";

export const CloseBadge: React.FC<{ status?: LockedStatus }> = ({ status }) => {
  if (status !== LockedStatus.Closed) return <></>;
  return (
    <Box display="flex" alignItems="center" ml={1}>
      <StatusStyled
        variant="caption"
        sx={{
          backgroundColor: "#012b5b",
          padding: "4px 8px"
        }}
        noWrap
      >
        Closed
      </StatusStyled>
    </Box>
  );
};
