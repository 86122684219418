import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { CompanyHeader } from "@homesusa/auth";
import { MarketCode } from "@homesusa/core";
import {
  BaseListing,
  ListingCreate,
  ListingGrid,
  ListingRequestGrid
} from "core/interfaces";
import { getGatewayMarket } from "core/utils";
import { ListingFilter } from "core/interfaces/filters";
import { ListedType, ListType, ListTypeEndpoint } from "core/enums";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export const getListingsApiUrl = (
  listType: ListType,
  market: MarketCode
): string => {
  const gatewayMarket = getGatewayMarket(market);
  const listTypeEnpoint = ListTypeEndpoint.get(listType) ?? listType;
  return `${gatewayUrl}/api/${gatewayMarket}/${listTypeEnpoint}`;
};

export const getListing =
  <TListing extends BaseListing>(listType: ListType, market: MarketCode) =>
  async (listingId: string): Promise<TListing> => {
    const apiUrl = getListingsApiUrl(listType, market);
    const response = await axios.get<TListing>(`${apiUrl}/${listingId}`);
    return response.data;
  };

export const getListings =
  (listType: ListType, market: MarketCode) =>
  async (
    params: ListingFilter,
    companyId?: string
  ): Promise<GridResponse<ListingGrid>> => {
    const apiUrl = getListingsApiUrl(listType, market);
    if (params?.listedType === ListedType.None) {
      params.listedType = undefined;
    }
    const config: AxiosRequestConfig = {
      params: {
        ...params,
        sortBy: params?.sortBy ?? "-sysModifiedOn"
      },
      headers: companyId ? { [CompanyHeader]: companyId } : undefined
    };
    const response = await axios.get<GridResponse<ListingGrid>>(apiUrl, config);
    return response.data;
  };

export const getListingRequestsByListingId =
  (listType: ListType, market: MarketCode) =>
  async (listingId: string): Promise<ListingRequestGrid[]> => {
    const apiUrl = getListingsApiUrl(listType, market);
    const response = await axios.get(`${apiUrl}/${listingId}/listing-requests`);
    return response.data;
  };

export const createListing =
  (listType: ListType, market: MarketCode) =>
  async (data: ListingCreate): Promise<string> => {
    const apiUrl = getListingsApiUrl(listType, market);
    const response = await axios.post<ListingCreate, AxiosResponse<string>>(
      apiUrl,
      data
    );
    return response.data;
  };
