import React from "react";

import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  CancelledOption,
  CancelledOptionsLabel,
  MlsStatus,
  SoldTerms,
  SoldTermsLabel,
  TitlePaidBy,
  TitlePaidByLabel
} from "markets/har/enums";
import { ClosedDateTooltip, ContractDateTooltip } from "core/components";
import { getNextDayDate, getYesterday } from "core/utils/dates";

const buyerAgentRules = {
  hasBuyerAgent: {
    label: "Represented by Buyer's Agent"
  },
  hasSecondBuyerAgent: {
    label: "Represented by second Buyer's Agent"
  }
};

const hasContingencyInfoRules = {
  label: "Contingent on Sale of Other Property"
};

export const summaryStatusRules = {
  ...buyerAgentRules,
  hasContingencyInfo: hasContingencyInfoRules
};

export const pendingStatusRules = {
  estimatedClosedDate: {
    label: "Estimated Close Date",
    customFieldProps: {
      errorMessages: {
        minDate: "Estimated Close Date must be after Today's Date"
      }
    },
    minDate: getNextDayDate(new Date()),
    tooltip: <ClosedDateTooltip />,
    requiredToSubmit: true
  },
  contractDate: {
    label: "Contract Date",
    tooltip: <ContractDateTooltip />,
    maxDate: new Date(),
    requiredToSubmit: true
  },

  hasContingencyInfo: hasContingencyInfoRules,
  hasBuyerAgent: buyerAgentRules.hasBuyerAgent,
  agentId: {
    label: "Buyer's Agent"
  }
};
export const rulesByStatus = new Map<MlsStatus, FormRules>([
  [
    MlsStatus.Pending,
    {
      ...pendingStatusRules,
      trecLicenseNumber: {
        label: "TREC License Number",
        maxLength: 12
      }
    }
  ],
  [
    MlsStatus.PendingContinueToShow,
    {
      ...pendingStatusRules,
      expiredDate: {
        label: "Option End Date",
        requiredToSubmit: true,
        customFieldProps: {
          errorMessages: {
            minDate: "Option End Date must be after Contract Date"
          }
        },
        minDate: new Date()
      },
      trecLicenseNumber: {
        label: "TREC License Number",
        maxLength: 12
      }
    }
  ],
  [
    MlsStatus.OptionPending,
    {
      ...pendingStatusRules,
      expiredDate: {
        label: "Option End Date",
        requiredToSubmit: true,
        customFieldProps: {
          errorMessages: {
            minDate: "Option End Date must be after Contract Date"
          }
        },
        minDate: new Date()
      }
    }
  ],
  [
    MlsStatus.Terminated,
    {
      cancelledOption: {
        label: "Cancelled Option",
        lastOptions: [CancelledOption.Other],
        options: getOptionsFromEnumAndMap(
          Object.values(CancelledOption),
          CancelledOptionsLabel
        ),
        requiredToSubmit: true
      },
      cancelledReason: {
        label: "Cancelled Reason",
        maxLength: 300,
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Sold,
    {
      contractDate: {
        label: "Contract Date",
        tooltip: <ContractDateTooltip />,
        maxDate: getYesterday(),
        requiredToSubmit: true
      },
      closedDate: {
        maxDate: new Date(),
        tooltip: <ClosedDateTooltip />,
        label: "Closing Date",
        customFieldProps: {
          errorMessages: {
            minDate: "Closing Date must be after Contract Date"
          }
        },
        requiredToSubmit: true
      },
      closePrice: {
        label: "Sales Price",
        numberFormat: {
          max: 2000000,
          min: 100000,
          prefix: "$",
          thousandSeparator: true
        },
        maxLength: 10,
        requiredToSubmit: true
      },
      ...buyerAgentRules,
      agentId: {
        label: "Buyer's Agent"
      },
      agentIdSecond: {
        label: "Second Buyer's Agent"
      },
      hasSellingTeam: {
        label: "Selling Team"
      },
      sellingTeam: {
        label: "Selling Team Name",
        disabled: true
      },
      hasContingencyInfo: hasContingencyInfoRules,
      sellerBuyerCost: {
        label: "Seller Contribution to Buyer Costs",
        maxLength: 7,
        numberFormat: {
          min: 0,
          max: 99999,
          prefix: "$",
          thousandSeparator: true
        },
        requiredToSubmit: true
      },
      soldTerms: {
        label: "Sold Terms",
        options: getOptionsFromEnumAndMap(
          Object.values(SoldTerms),
          SoldTermsLabel
        ),
        rows: 2,
        requiredToSubmit: true
      },
      titlePaidBy: {
        label: "Title Paid By",
        options: getOptionsFromEnumAndMap(
          Object.values(TitlePaidBy),
          TitlePaidByLabel
        ),
        rows: 2,
        requiredToSubmit: true
      },
      repairsAmount: {
        label: "Repairs Paid by Seller",
        requiredToSubmit: true
      },
      trecLicenseNumber: {
        label: "TREC License Number",
        maxLength: 12
      }
    }
  ],
  [
    MlsStatus.Withdrawn,
    {
      offMarketDate: {
        label: "Off Market Date",
        requiredToSubmit: true
      },
      backOnMarketDate: {
        label: "Back On Market Date",
        requiredToSubmit: true
      }
    }
  ],
  [
    MlsStatus.Expired,
    {
      expiredDate: {
        label: "Expiration Date",
        requiredToSubmit: true,
        customFieldProps: {
          errorMessages: {
            minDate: "Expiration Date must be Today or Before the Current Date"
          }
        },
        maxDate: new Date()
      }
    }
  ],
  [MlsStatus.Active, {}]
]);
