import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules, FieldRulesProps } from "@homesusa/form";
import { Stories, StoriesLabel } from "markets/abor/enums";

const roomNumberRule = (
  label: string,
  requiredToSubmit = true,
  maxLength = 1
): FieldRulesProps => ({
  label,
  maxLength,
  requiredToSubmit,
  numberFormat: {
    min: 0
  }
});
export const spacesDimensionsRules: FormRules = {
  fullBathsTotal: roomNumberRule("# Full Baths"),
  halfBathsTotal: roomNumberRule("# Half Baths"),
  sqFtTotal: {
    label: "Sq Ft",
    numberFormat: {
      min: 500,
      max: 10000,
      thousandSeparator: true
    },
    maxLength: 10,
    requiredToSubmit: true
  },
  livingAreasTotal: roomNumberRule("# Living Areas"),
  mainLevelBedroomTotal: roomNumberRule("# Main Level Bedrooms"),
  otherLevelsBedroomTotal: roomNumberRule("# Other Level Bedrooms", false, 2),
  diningAreasTotal: roomNumberRule("# Dining Areas"),
  storiesTotal: {
    label: "Levels",
    options: getOptionsFromEnumAndMap(Object.values(Stories), StoriesLabel),
    requiredToSubmit: true
  }
};
