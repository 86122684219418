import React from "react";

import { Format, MarketCode, MarketCodeLabel } from "@homesusa/core";
import { getOptionValueFromRule } from "@homesusa/form";
import { Columns, Column } from "@homesusa/grid-table";
import { DetailedAlertCellItem } from "../interfaces/alerts";
import { DashboardContext } from "../context/dashboard";
import { AlertColumnName, AlertType } from "../enums";
import { AlertTypeColumns } from "../utils";

export const useAlertGridColumns = <TEntity extends { id: string }>(
  alertType: AlertType,
  cityOptions?: Record<string, string>
): Columns<TEntity> => {
  const { marketRules } = React.useContext(DashboardContext);
  const isLockedListingsImported = (alertType: AlertType): boolean =>
    alertType === AlertType.XmlLockedListingsImported;
  const allColumns: Record<
    AlertColumnName,
    Column<DetailedAlertCellItem>
  > = React.useMemo(
    () => ({
      [AlertColumnName.MlsNumber]: {
        Header: "MLS #",
        accessor: AlertColumnName.MlsNumber
      },
      [AlertColumnName.Builder]: {
        Header: "Builder",
        accessor: AlertColumnName.Builder
      },
      [AlertColumnName.Address]: {
        Header: "Address",
        accessor: AlertColumnName.Address
      },
      [AlertColumnName.City]: {
        Header: "City",
        accessor: (data: DetailedAlertCellItem): string =>
          cityOptions?.[data.city] ?? data.city
      },
      [AlertColumnName.Community]: {
        Header: "Community",
        accessor: AlertColumnName.Community
      },
      [AlertColumnName.LastUser]: {
        Header: "Last User",
        accessor: AlertColumnName.LastUser
      },
      [AlertColumnName.AssignedTo]: {
        Header: "Assigned To",
        accessor: AlertColumnName.AssignedTo
      },
      [AlertColumnName.Obtained]: {
        Header: "Obtained",
        accessor: AlertColumnName.Obtained
      },
      [AlertColumnName.MissingField]: {
        Header: "Missing Field",
        accessor: AlertColumnName.MissingField
      },
      [AlertColumnName.Dom]: {
        Header: "DOM",
        accessor: AlertColumnName.Dom
      },
      [AlertColumnName.XmlErrorMessage]: {
        Header: "Error Message",
        accessor: AlertColumnName.XmlErrorMessage
      },
      [AlertColumnName.PublicRemarks]: {
        Header: "Property Description",
        accessor: AlertColumnName.PublicRemarks
      },
      [AlertColumnName.OldPrice]: {
        Header: "Old Price",
        accessor: AlertColumnName.OldPrice
      },
      [AlertColumnName.NewPrice]: {
        Header: "New Price",
        accessor: AlertColumnName.NewPrice
      },
      [AlertColumnName.OldmlsStatus]: {
        Header: "Old Status",
        accessor: AlertColumnName.OldmlsStatus
      },
      [AlertColumnName.NewmlsStatus]: {
        Header: "New Status",
        accessor: AlertColumnName.NewmlsStatus
      },
      [AlertColumnName.MarketCode]: {
        Header: "Market",
        id: AlertColumnName.MarketCode,
        accessor: (data: DetailedAlertCellItem): string =>
          MarketCodeLabel.get(data.marketCode as MarketCode) ?? data.marketCode
      },
      [AlertColumnName.MlsStatus]: {
        Header: "Status",
        accessor: (data: DetailedAlertCellItem): string =>
          getOptionValueFromRule(marketRules.mlsStatus, data.mlsStatus),
        id: AlertColumnName.MlsStatus
      },
      [AlertColumnName.EstimatedClosedDate]: {
        Header: "Est. Closed Date",
        id: AlertColumnName.EstimatedClosedDate,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.estimatedClosedDate)
      },
      [AlertColumnName.ConstructionCompletionDate]: {
        Header: "Completion",
        id: AlertColumnName.ConstructionCompletionDate,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.constructionCompletionDate)
      },
      [AlertColumnName.ExpirationDate]: {
        Header: "Expire Date",
        id: AlertColumnName.ExpirationDate,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.expirationDate)
      },
      [AlertColumnName.BonusExpirationDate]: {
        Header: "Expire Date",
        id: AlertColumnName.BonusExpirationDate,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.bonusExpirationDate)
      },
      [AlertColumnName.BackOnMarketDate]: {
        Header: "BOM Date",
        id: AlertColumnName.BackOnMarketDate,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.backOnMarketDate)
      },
      [AlertColumnName.AssignedOn]: {
        Header: "Assigned On",
        id: AlertColumnName.AssignedOn,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.assignedOn)
      },
      [AlertColumnName.ContactDate]: {
        Header: "Contact Date",
        id: AlertColumnName.ContactDate,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.contactDate)
      },
      [AlertColumnName.ScheduleDate]: {
        Header: "Schedule Date",
        id: AlertColumnName.ScheduleDate,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.scheduleDate)
      },
      [AlertColumnName.ChangedOn]: {
        Header: "Changed On",
        id: AlertColumnName.ChangedOn,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.marketModifiedOn)
      },
      [AlertColumnName.SysModifiedOn]: {
        Header: isLockedListingsImported(alertType)
          ? "Obtained"
          : "Modified On",
        id: AlertColumnName.SysModifiedOn,
        accessor: (data: DetailedAlertCellItem): string =>
          Format.Date(data.sysModifiedOn)
      }
    }),
    [marketRules.mlsStatus]
  );

  const getColumns = (): Columns<TEntity> => {
    const columns =
      AlertTypeColumns.get(alertType)?.map((key) => allColumns[key]) ?? [];
    return columns as Columns<TEntity>;
  };
  return getColumns();
};
