import { Alert, styled } from "@mui/material";

export const ToolbarAlertStyled = styled(Alert)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  ["& .MuiAlert-icon"]: {
    padding: 0,
    fontSize: 16
  },
  ["& .MuiAlert-message"]: {
    padding: 0
  }
}));
