export enum SoilType {
  BlackLand = "blackLand",
  Caliche = "caliche",
  Clay = "clay",
  Gravel = "gravel",
  Limestone = "limestone",
  SandyLoam = "sandyLoam",
  Shoals = "shoals",
  SeeRemarks = "seeRemarks"
}
export const SoilTypeLabel = new Map<SoilType, string>([
  [SoilType.BlackLand, "Black Land"],
  [SoilType.Caliche, "Caliche"],
  [SoilType.Clay, "Clay"],
  [SoilType.Gravel, "Gravel"],
  [SoilType.Limestone, "Limestone"],
  [SoilType.SandyLoam, "Sandy Loam"],
  [SoilType.Shoals, "Shoals"],
  [SoilType.SeeRemarks, "See Remarks"]
]);
