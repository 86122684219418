export enum SqFtSource {
  Appraiser = "appraiser",
  Owner = "owner",
  Assessor = "assessor",
  Plans = "plans",
  Builder = "builder",
  PublicRecords = "publicRecords",
  Other = "other",
  SeeRemarks = "seeRemarks"
}
export const SqFtSourceLabel = new Map<SqFtSource, string>([
  [SqFtSource.Appraiser, "Appraiser"],
  [SqFtSource.Owner, "Owner"],
  [SqFtSource.Assessor, "Assesor"],
  [SqFtSource.Plans, "Plans"],
  [SqFtSource.Builder, "Builder"],
  [SqFtSource.PublicRecords, "Public Records"],
  [SqFtSource.Other, "Other"],
  [SqFtSource.SeeRemarks, "See Remarks"]
]);
