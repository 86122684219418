import { FormRules } from "@homesusa/form";

export const emailLeadRules: FormRules = {
  emailLeadPrincipal: {
    label: "Email"
  },
  emailLeadSecondary: {
    label: "Secondary Email"
  },
  emailLeadThird: {
    label: "Third Email"
  },
  emailLeadFourth: {
    label: "Fourth Email"
  },
  emailLeadFifth: {
    label: "Fifth Email"
  },
  emailLeadOther: {
    label: "Other Email"
  }
};
