export enum LotDescription {
  Airpark = "airpark",
  Cleared = "cleared",
  Corner = "corner",
  CulDeSac = "culDeSac",
  Greenbelt = "greenbelt",
  InGolfCourseCommunity = "inGolfCourseCommunity",
  OnGolfCourse = "onGolfCourse",
  Other = "other",
  PatioLot = "patioLot",
  Ravine = "ravine",
  SubdivisionLot = "subdivisionLot",
  WaterView = "waterView",
  Waterfront = "waterfront",
  Wooded = "wooded"
}

export const LotDescriptionLabel = new Map<LotDescription, string>([
  [LotDescription.Airpark, "Airpark"],
  [LotDescription.Cleared, "Cleared"],
  [LotDescription.Corner, "Corner"],
  [LotDescription.CulDeSac, "Cul-De-Sac"],
  [LotDescription.Greenbelt, "Greenbelt"],
  [LotDescription.InGolfCourseCommunity, "In Golf Course Community"],
  [LotDescription.OnGolfCourse, "On Golf Course"],
  [LotDescription.Other, "Other"],
  [LotDescription.PatioLot, "Patio Lot"],
  [LotDescription.Ravine, "Ravine"],
  [LotDescription.SubdivisionLot, "Subdivision Lot"],
  [LotDescription.WaterView, "Water View"],
  [LotDescription.Waterfront, "Waterfront"],
  [LotDescription.Wooded, "Wooded"]
]);
