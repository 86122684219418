export enum HoaIncludes {
  Electricity = "electricity",
  FullUseOfFacilities = "fullUseOfFacilities",
  Gas = "gas",
  Insurance = "insurance",
  Internet = "internet",
  MaintenanceGrounds = "maintenanceGrounds",
  MaintenanceStructure = "maintenanceStructure",
  ManagementFees = "managementFees",
  PestControl = "pestControl",
  Security = "security",
  Sewer = "sewer",
  Utilities = "utilities",
  Water = "water"
}

export const HoaIncludesLabel = new Map<HoaIncludes, string>([
  [HoaIncludes.Electricity, "Electricity"],
  [HoaIncludes.FullUseOfFacilities, "Full Use of Facilities"],
  [HoaIncludes.Gas, "Gas"],
  [HoaIncludes.Insurance, "Insurance"],
  [HoaIncludes.Internet, "Internet"],
  [HoaIncludes.MaintenanceGrounds, "Maintenance Grounds"],
  [HoaIncludes.MaintenanceStructure, "Maintenance Structure"],
  [HoaIncludes.ManagementFees, "Management Fees"],
  [HoaIncludes.PestControl, "Pest Control"],
  [HoaIncludes.Security, "Security"],
  [HoaIncludes.Sewer, "Sewer"],
  [HoaIncludes.Utilities, "Utilities"],
  [HoaIncludes.Water, "Water"]
]);
