import { flattenRules, FormRules } from "@homesusa/form";
import {
  utilitiesRules,
  financialSchoolsRules,
  profileRules,
  propertyRules
} from "./properties";
import { showingRules } from "../sections";

export const communityRules: FormRules = flattenRules({
  showing: {
    ...showingRules,
    directions: {
      ...showingRules.directions,
      maxLength: 255
    }
  },
  utilities: utilitiesRules,
  property: propertyRules,
  profile: profileRules,
  financialSchools: financialSchoolsRules
});
