import { cloneDeep } from "lodash";

import { FormContextActions, formContextReducer } from "@homesusa/form";

export const listingFormReducer = <
  TMlsStatus extends string,
  TListing extends {
    mlsStatus: TMlsStatus;
    // eslint-disable-next-line
    statusFieldsInfo: {};
  }
>({
  state,
  action,
  getRequiredInputNames
}: {
  state: TListing;
  action:
    | FormContextActions<TListing>
    | {
        type: "StatusChange";
        newMlsStatus: TMlsStatus;
      };
  getRequiredInputNames: (status: TMlsStatus) => string[];
}): TListing => {
  switch (action.type) {
    case "StatusChange": {
      if (action.newMlsStatus == state.mlsStatus) {
        return state;
      }
      const requiredFieldNames = getRequiredInputNames(action.newMlsStatus).map(
        (inputName) => {
          const inputPath = inputName.split(".");
          return inputPath.pop();
        }
      );
      const newStatusFieldsInfo = Object.keys(cloneDeep(state.statusFieldsInfo))
        .filter((fieldName) => !requiredFieldNames.includes(fieldName))
        .reduce(
          (current, fieldName) => ({
            ...current,
            [fieldName]: undefined
          }),
          {}
        );

      return {
        ...state,
        statusFieldsInfo: {
          ...state.statusFieldsInfo,
          ...newStatusFieldsInfo
        },
        mlsStatus: action.newMlsStatus
      };
    }
    default: {
      return formContextReducer(state, action);
    }
  }
};
