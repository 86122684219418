export enum SchoolDistrict {
  NewBraunfels = "newBraunfels",
  Hallettsville = "hallettsville",
  NorthZulch = "northZulch",
  Laredo = "laredo",
  Dayton = "dayton",
  Eanes = "eanes",
  Lumberton = "lumberton",
  Huntsville = "huntsville",
  Westwood = "westwood",
  Lexington = "lexington",
  Leggett = "leggett",
  Anahuac = "anahuac",
  LaMarque = "laMarque",
  Hardin = "hardin",
  Palestine = "palestine",
  Slocum = "slocum",
  Stafford = "stafford",
  Yoakum = "yoakum",
  Aldine = "aldine",
  Pflugerville = "pflugerville",
  MarbleFalls = "marbleFalls",
  Willis = "willis",
  Hitchcock = "hitchcock",
  Sheldon = "sheldon",
  CypressFairbanks = "cypressFairbanks",
  Nederland = "nederland",
  Chester = "chester",
  Waller = "waller",
  RoundRock = "roundRock",
  Brenham = "brenham",
  NewWaverly = "newWaverly",
  Goodrich = "goodrich",
  Jacksonville = "jacksonville",
  HighIsland = "highIsland",
  SantaFe = "santaFe",
  BigSandy = "bigSandy",
  Montgomery = "montgomery",
  VanVleck = "vanVleck",
  Damon = "damon",
  HardinJefferson = "hardinJefferson",
  Sealy = "sealy",
  Sweeny = "sweeny",
  Alief = "alief",
  ClearCreek = "clearCreek",
  ElCampo = "elCampo",
  ColumbiaBrazoria = "columbiaBrazoria",
  Frankston = "frankston",
  Boling = "boling",
  Marlin = "marlin",
  Kennard = "kennard",
  BarbersHill = "barbersHill",
  Victoria = "victoria",
  Columbus = "columbus",
  Needville = "needville",
  Angleton = "angleton",
  Manor = "manor",
  CalhounCounty = "calhounCounty",
  TexasCity = "texasCity",
  Jasper = "jasper",
  DeerPark = "deerPark",
  GalenaPark = "galenaPark",
  McDade = "mcDade",
  Devers = "devers",
  Llano = "llano",
  Cayuga = "cayuga",
  Royal = "royal",
  Onalaska = "onalaska",
  BuffaloLonestar = "buffaloLonestar",
  Galveston = "galveston",
  Iola = "iola",
  Rusk = "rusk",
  WestHardin = "westHardin",
  Brazos = "brazos",
  Coupland = "coupland",
  HamshireFannett = "hamshireFannett",
  Tarkington = "tarkington",
  Brookeland = "brookeland",
  Alvin = "alvin",
  Livingston = "livingston",
  Jarrell = "jarrell",
  Palacios = "palacios",
  LamarConsolidated = "lamarConsolidated",
  Crosby = "crosby",
  BayCity = "bayCity",
  Brazosport = "brazosport",
  Shepherd = "shepherd",
  FortBend = "fortBend",
  LakeWorth = "lakeWorth",
  Friendswood = "friendswood",
  Hempstead = "hempstead",
  Mexia = "mexia",
  Leon = "leon",
  Bryan = "bryan",
  Snook = "snook",
  Rockdale = "rockdale",
  Comal = "comal",
  Houston = "houston",
  Matagorda = "matagorda",
  Northwest = "northwest",
  Lockhart = "lockhart",
  Ezzell = "ezzell",
  Trinity = "trinity",
  Danbury = "danbury",
  Channelview = "channelview",
  Tomball = "tomball",
  Klein = "klein",
  Centerville = "centerville",
  Crockett = "crockett",
  LakeTravis = "lakeTravis",
  GooseCreek = "gooseCreek",
  ColdspringOakhurstConsolidated = "coldspringOakhurstConsolidated",
  JohnsonCity = "johnsonCity",
  Normangee = "normangee",
  Burton = "burton",
  Somerville = "somerville",
  Leander = "leander",
  Latexo = "latexo",
  MadisonvilleConsolidated = "madisonvilleConsolidated",
  DelValle = "delValle",
  EastBernard = "eastBernard",
  Wharton = "wharton",
  Conroe = "conroe",
  Kerrville = "kerrville",
  Tidehaven = "tidehaven",
  Pasadena = "pasadena",
  Beaumont = "beaumont",
  LaPorte = "laPorte",
  Elkhart = "elkhart",
  Dickinson = "dickinson",
  EastChambers = "eastChambers",
  Lufkin = "lufkin",
  Grapeland = "grapeland",
  RioVista = "rio Vista",
  Seguin = "seguin",
  Other = "other",
  Humble = "humble",
  Navasota = "navasota",
  Lovelady = "lovelady",
  Huffman = "huffman",
  Katy = "katy",
  LagoVista = "lagoVista",
  NuecesCanyon = "nuecesCanyon",
  BurnetConsolidated = "burnetConsolidated",
  Zavalla = "zavalla",
  CollegeStation = "collegeStation",
  HullDaisetta = "hullDaisetta",
  LaPoynor = "laPoynor",
  Liberty = "liberty",
  Groveton = "groveton",
  Brownsboro = "brownsboro",
  SpringBranch = "springBranch",
  AndersonShiro = "andersonShiro",
  Giddings = "giddings",
  Magnolia = "magnolia",
  Spring = "spring",
  Splendora = "splendora",
  Cleveland = "cleveland",
  Neches = "neches",
  NewCaney = "newCaney",
  Bellville = "bellville",
  RiceCons = "riceCons",
  HaysConsolidated = "haysConsolidated",
  Austin = "austin",
  Pearland = "pearland",
  DrippingSprings = "drippingSprings",
  Elgin = "elgin",
  Keller = "keller"
}
export const SchoolDistrictLabel = new Map<SchoolDistrict, string>([
  [SchoolDistrict.NewBraunfels, "142 - New Braunfels"],
  [SchoolDistrict.Hallettsville, "179 - Hallettsville"],
  [SchoolDistrict.NorthZulch, "159 - North Zulch"],
  [SchoolDistrict.Laredo, "200 - Laredo"],
  [SchoolDistrict.Dayton, "74 - Dayton"],
  [SchoolDistrict.Eanes, "113 - Eanes"],
  [SchoolDistrict.Lumberton, "154 - Lumberton"],
  [SchoolDistrict.Huntsville, "64 - Huntsville"],
  [SchoolDistrict.Westwood, "72 - Westwood"],
  [SchoolDistrict.Lexington, "165 - Lexington"],
  [SchoolDistrict.Leggett, "151 - Leggett"],
  [SchoolDistrict.Anahuac, "4 - Anahuac"],
  [SchoolDistrict.LaMarque, "34 - La Marque"],
  [SchoolDistrict.Hardin, "107 - Hardin"],
  [SchoolDistrict.Palestine, "71 - Palestine"],
  [SchoolDistrict.Slocum, "65 - Slocum"],
  [SchoolDistrict.Stafford, "50 - Stafford"],
  [SchoolDistrict.Yoakum, "177 - Yoakum"],
  [SchoolDistrict.Aldine, "1 - Aldine"],
  [SchoolDistrict.Pflugerville, "117 - Pflugerville"],
  [SchoolDistrict.MarbleFalls, "124 - Marble Falls"],
  [SchoolDistrict.Willis, "56 - Willis"],
  [SchoolDistrict.Hitchcock, "26 - Hitchcock"],
  [SchoolDistrict.Sheldon, "46 - Sheldon"],
  [SchoolDistrict.CypressFairbanks, "13 - Cypress-Fairbanks"],
  [SchoolDistrict.Nederland, "152 - Nederland"],
  [SchoolDistrict.Chester, "181 - Chester"],
  [SchoolDistrict.Waller, "55 - Waller"],
  [SchoolDistrict.RoundRock, "125 - Round Rock"],
  [SchoolDistrict.Brenham, "137 - Brenham"],
  [SchoolDistrict.NewWaverly, "105 - New Waverly"],
  [SchoolDistrict.Goodrich, "138 - Goodrich"],
  [SchoolDistrict.Jacksonville, "106 - Jacksonville"],
  [SchoolDistrict.HighIsland, "25 - High Island"],
  [SchoolDistrict.SantaFe, "45 - Santa Fe"],
  [SchoolDistrict.BigSandy, "164 - Big Sandy"],
  [SchoolDistrict.Montgomery, "37 - Montgomery"],
  [SchoolDistrict.VanVleck, "134 - Van Vleck"],
  [SchoolDistrict.Damon, "14 - Damon"],
  [SchoolDistrict.HardinJefferson, "163 - Hardin-Jefferson"],
  [SchoolDistrict.Sealy, "109 - Sealy"],
  [SchoolDistrict.Sweeny, "51 - Sweeny"],
  [SchoolDistrict.Alief, "2 - Alief"],
  [SchoolDistrict.ClearCreek, "9 - Clear Creek"],
  [SchoolDistrict.ElCampo, "198 - El Campo"],
  [SchoolDistrict.ColumbiaBrazoria, "10 - Columbia-Brazoria"],
  [SchoolDistrict.Frankston, "67 - Frankston"],
  [SchoolDistrict.Boling, "140 - Boling"],
  [SchoolDistrict.Marlin, "199 - Marlin"],
  [SchoolDistrict.Kennard, "60 - Kennard"],
  [SchoolDistrict.BarbersHill, "6 - Barbers Hill"],
  [SchoolDistrict.Victoria, "191 - Victoria"],
  [SchoolDistrict.Columbus, "188 - Columbus"],
  [SchoolDistrict.Needville, "38 - Needville"],
  [SchoolDistrict.Angleton, "5 - Angleton"],
  [SchoolDistrict.Manor, "116 - Manor"],
  [SchoolDistrict.CalhounCounty, "169 - Calhoun County"],
  [SchoolDistrict.TexasCity, "52 - Texas City"],
  [SchoolDistrict.Jasper, "149 - Jasper"],
  [SchoolDistrict.DeerPark, "16 - Deer Park"],
  [SchoolDistrict.GalenaPark, "21 - Galena Park"],
  [SchoolDistrict.McDade, "162 - McDade"],
  [SchoolDistrict.Devers, "176 - Devers"],
  [SchoolDistrict.Llano, "183 - Llano"],
  [SchoolDistrict.Cayuga, "66 - Cayuga"],
  [SchoolDistrict.Royal, "44 - Royal"],
  [SchoolDistrict.Onalaska, "104 - Onalaska"],
  [SchoolDistrict.BuffaloLonestar, "194 - Buffalo/Lonestar"],
  [SchoolDistrict.Galveston, "22 - Galveston"],
  [SchoolDistrict.Iola, "171 - Iola"],
  [SchoolDistrict.Rusk, "147 - Rusk"],
  [SchoolDistrict.WestHardin, "182 - West Hardin"],
  [SchoolDistrict.Brazos, "54 - Brazos"],
  [SchoolDistrict.Coupland, "118 - Coupland"],
  [SchoolDistrict.HamshireFannett, "110 - Hamshire Fannett"],
  [SchoolDistrict.Tarkington, "102 - Tarkington"],
  [SchoolDistrict.Brookeland, "144 - Brookeland"],
  [SchoolDistrict.Alvin, "3 - Alvin"],
  [SchoolDistrict.Livingston, "103 - Livingston"],
  [SchoolDistrict.Friendswood, "20 - Friendswood"],
  [SchoolDistrict.Brazosport, "7 - Brazosport"],
  [SchoolDistrict.LakeWorth, "160 - Lake Worth"],
  [SchoolDistrict.Jarrell, "187 - Jarrell"],
  [SchoolDistrict.Palacios, "156 - Palacios"],
  [SchoolDistrict.LamarConsolidated, "33 - Lamar Consolidated"],
  [SchoolDistrict.Crosby, "12 - Crosby"],
  [SchoolDistrict.BayCity, "132 - Bay City"],
  [SchoolDistrict.Shepherd, "130 - Shepherd"],
  [SchoolDistrict.FortBend, "19 - Fort Bend"],
  [SchoolDistrict.Hempstead, "24 - Hempstead"],
  [SchoolDistrict.Mexia, "196 - Mexia"],
  [SchoolDistrict.Leon, "195 - Leon"],
  [SchoolDistrict.Bryan, "148 - Bryan"],
  [SchoolDistrict.Snook, "192 - Snook"],
  [SchoolDistrict.Rockdale, "126 - Rockdale"],
  [SchoolDistrict.Comal, "141 - Comal"],
  [SchoolDistrict.Houston, "27 - Houston"],
  [SchoolDistrict.Matagorda, "170 - Matagorda"],
  [SchoolDistrict.Northwest, "127 - Northwest"],
  [SchoolDistrict.Lockhart, "178 - Lockhart"],
  [SchoolDistrict.Ezzell, "197 - Ezzell"],
  [SchoolDistrict.Trinity, "63 - Trinity"],
  [SchoolDistrict.Danbury, "15 - Danbury"],
  [SchoolDistrict.Channelview, "8 - Channelview"],
  [SchoolDistrict.Tomball, "53 - Tomball"],
  [SchoolDistrict.Klein, "32 - Klein"],
  [SchoolDistrict.Centerville, "155 - Centerville"],
  [SchoolDistrict.Crockett, "57 - Crockett"],
  [SchoolDistrict.LakeTravis, "115 - Lake Travis"],
  [SchoolDistrict.GooseCreek, "23 - Goose Creek"],
  [
    SchoolDistrict.ColdspringOakhurstConsolidated,
    "101 - Coldspring-Oakhurst Consolidated"
  ],
  [SchoolDistrict.JohnsonCity, "122 - Johnson City"],
  [SchoolDistrict.Normangee, "131 - Normangee"],
  [SchoolDistrict.Burton, "128 - Burton"],
  [SchoolDistrict.Somerville, "158 - Somerville"],
  [SchoolDistrict.Leander, "123 - Leander"],
  [SchoolDistrict.Latexo, "61 - Latexo"],
  [SchoolDistrict.MadisonvilleConsolidated, "175 - Madisonville Consolidated"],
  [SchoolDistrict.DelValle, "112 - Del Valle"],
  [SchoolDistrict.EastBernard, "145 - East Bernard"],
  [SchoolDistrict.Wharton, "180 - Wharton"],
  [SchoolDistrict.Conroe, "11 - Conroe"],
  [SchoolDistrict.Kerrville, "139 - Kerrville"],
  [SchoolDistrict.Tidehaven, "133 - Tidehaven"],
  [SchoolDistrict.Pasadena, "41 - Pasadena"],
  [SchoolDistrict.Beaumont, "143 - Beaumont"],
  [SchoolDistrict.LaPorte, "35 - La Porte"],
  [SchoolDistrict.Elkhart, "68 - Elkhart"],
  [SchoolDistrict.Dickinson, "17 - Dickinson"],
  [SchoolDistrict.EastChambers, "18 - East Chambers"],
  [SchoolDistrict.Lufkin, "186 - Lufkin"],
  [SchoolDistrict.Grapeland, "58 - Grapeland"],
  [SchoolDistrict.RioVista, "190 - Rio Vista"],
  [SchoolDistrict.Seguin, "157 - Seguin"],
  [SchoolDistrict.Other, "99 - Other"],
  [SchoolDistrict.Humble, "29 - Humble"],
  [SchoolDistrict.Navasota, "129 - Navasota"],
  [SchoolDistrict.Lovelady, "62 - Lovelady"],
  [SchoolDistrict.Huffman, "28 - Huffman"],
  [SchoolDistrict.Katy, "30 - Katy"],
  [SchoolDistrict.LagoVista, "114 - Lago Vista"],
  [SchoolDistrict.NuecesCanyon, "193 - Nueces-Canyon"],
  [SchoolDistrict.BurnetConsolidated, "184 - Burnet Consolidated"],
  [SchoolDistrict.Zavalla, "168 - Zavalla"],
  [SchoolDistrict.CollegeStation, "153 - College Station"],
  [SchoolDistrict.HullDaisetta, "108 - Hull-Daisetta"],
  [SchoolDistrict.LaPoynor, "69 - La Poynor"],
  [SchoolDistrict.Liberty, "73 - Liberty"],
  [SchoolDistrict.Groveton, "59 - Groveton"],
  [SchoolDistrict.Brownsboro, "150 - Brownsboro"],
  [SchoolDistrict.SpringBranch, "49 - Spring Branch"],
  [SchoolDistrict.AndersonShiro, "135 - Anderson-Shiro"],
  [SchoolDistrict.Giddings, "189 - Giddings"],
  [SchoolDistrict.Magnolia, "36 - Magnolia"],
  [SchoolDistrict.Spring, "48 - Spring"],
  [SchoolDistrict.Splendora, "47 - Splendora"],
  [SchoolDistrict.Cleveland, "100 - Cleveland"],
  [SchoolDistrict.Neches, "70 - Neches"],
  [SchoolDistrict.NewCaney, "39 - New Caney"],
  [SchoolDistrict.Bellville, "136 - Bellville"],
  [SchoolDistrict.RiceCons, "161 - Rice Cons"],
  [SchoolDistrict.HaysConsolidated, "121 - Hays Consolidated"],
  [SchoolDistrict.Austin, "111 - Austin"],
  [SchoolDistrict.Pearland, "42 - Pearland"],
  [SchoolDistrict.DrippingSprings, "119 - Dripping Springs"],
  [SchoolDistrict.Elgin, "120 - Elgin"],
  [SchoolDistrict.Keller, "185 - Keller"]
]);
