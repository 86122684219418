import { AlertType } from "../enums";

export const detailedAlertActions = [
  AlertType.PastDueEstimatedClosingDate,
  AlertType.PastDueEstimatedCompletionDate,
  AlertType.AgentBonusExpirationDate,
  AlertType.TempOffMarketBackOnMarket,
  AlertType.LockedListings,
  AlertType.ExpiringListings,
  AlertType.CompletedHomesWithoutPhotoRequest,
  AlertType.PastDuePhotoRequests,
  AlertType.NotListedInMls,
  AlertType.OrphanListings,
  AlertType.EstimatedClosingDaysOrLess,
  AlertType.CompletionDateDueDaysOrLess,
  AlertType.AgentBonusExpirationDateOrLess,
  AlertType.TempOffMarketBackOnMarketDaysOrLess,
  AlertType.CurrentDaysOnMarketOverDays,
  AlertType.InadequatePublicRemarks,
  AlertType.ViolationWarningCallList
];

export const urgentAlertActions = [
  AlertType.PastDueEstimatedClosingDate,
  AlertType.PastDueEstimatedCompletionDate,
  AlertType.AgentBonusExpirationDate,
  AlertType.TempOffMarketBackOnMarket,
  AlertType.LockedListings
];

export const remindersAlerts = [
  AlertType.CompletedHomesWithoutPhotoRequest,
  AlertType.NotListedInMls,
  AlertType.EstimatedClosingDaysOrLess,
  AlertType.CompletionDateDueDaysOrLess,
  AlertType.AgentBonusExpirationDateOrLess
];
