import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndRecord } from "@homesusa/core";
import {
  ElementarySchools,
  ElementarySchoolsLabels,
  HighSchool,
  HighSchoolLabel,
  IntermediateSchoolLabel,
  IntermediateSchools,
  JuniorSchool,
  JuniorSchoolLabel,
  MiddleSchool,
  MiddleSchoolLabels,
  PrimarySchool,
  PrimarySchoolLabel,
  SchoolDistrict,
  SchoolDistrictLabel,
  SeniorSchool,
  SeniorSchoolLabel
} from "markets/dfw/enums";

export const schoolsRules: FormRules = {
  schoolDistrict: {
    label: "School District",
    options: getOptionsFromEnumAndRecord(
      Object.values(SchoolDistrict),
      SchoolDistrictLabel
    ),
    requiredToSubmit: true
  },
  primarySchool: {
    label: "Primary School Name",
    options: getOptionsFromEnumAndRecord(
      Object.values(PrimarySchool),
      PrimarySchoolLabel
    )
  },
  elementarySchool: {
    label: "Elementary School",
    options: getOptionsFromEnumAndRecord(
      Object.values(ElementarySchools),
      ElementarySchoolsLabels
    )
  },
  intermediateSchool: {
    label: "Intermediate School Name",
    options: getOptionsFromEnumAndRecord(
      Object.values(IntermediateSchools),
      IntermediateSchoolLabel
    )
  },
  middleSchool: {
    label: "Middle School",
    options: getOptionsFromEnumAndRecord(
      Object.values(MiddleSchool),
      MiddleSchoolLabels
    )
  },
  juniorSchool: {
    label: "Junior High School",
    options: getOptionsFromEnumAndRecord(
      Object.values(JuniorSchool),
      JuniorSchoolLabel
    )
  },
  highSchool: {
    label: "High School",
    options: getOptionsFromEnumAndRecord(
      Object.values(HighSchool),
      HighSchoolLabel
    )
  },
  seniorSchool: {
    label: "Senior School Name",
    options: getOptionsFromEnumAndRecord(
      Object.values(SeniorSchool),
      SeniorSchoolLabel
    )
  }
};
