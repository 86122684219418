import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { MlsArea, MlsAreaLabel } from "markets/sabor/enums";

export const propertyRules: FormRules = {
  mapscoGrid: {
    label: "Mapsco Grid",
    maxLength: 5
  },
  mlsArea: {
    label: "Area",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(MlsArea), MlsAreaLabel)
  }
};
