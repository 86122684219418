import { FormRules } from "@homesusa/form";
import { spacesDimensionsRules } from "../../sections";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  SqFtSource,
  SqFtSourceLabel
} from "markets/dfw/enums/sqft-source.enum";

export const spacesDimensionsInfoRules: FormRules = {
  ...spacesDimensionsRules,
  sqftTotal: {
    requiredToSubmit: true,
    label: "Sq Ft",
    numberFormat: {
      min: 500,
      max: 10000,
      thousandSeparator: true
    },
    maxLength: 10
  },
  sqftSource: {
    label: "Sqft Source",
    options: getOptionsFromEnumAndMap(
      Object.values(SqFtSource),
      SqFtSourceLabel
    ),
    requiredToSubmit: true
  },
  fireplaces: { label: "# Fireplaces" }
};
