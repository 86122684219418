import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useRefresh, CommissionType } from "@homesusa/core";
import { FormContext, NumberInput, FormMode } from "@homesusa/form";
import { buyersCommissionRules } from "core/rules/custom-rules";

interface CommissionFieldNames {
  commissionType: string;
  commissionValue: string;
}
const AgentCommissionSectionContext = React.createContext<{
  hideBuyersAgentCommission: boolean;
  refreshBuyersAgentCommission: () => void;
  fieldNames: CommissionFieldNames;
}>(Object.assign({}));

export function AgentCommissionSection({
  children,
  fieldNames
}: {
  children: React.ReactNode;
  fieldNames: CommissionFieldNames;
}): JSX.Element {
  const [hideBuyersAgentCommission, refreshBuyersAgentCommission] =
    useRefresh();
  return (
    <AgentCommissionSectionContext.Provider
      value={{
        hideBuyersAgentCommission,
        refreshBuyersAgentCommission,
        fieldNames
      }}
    >
      {children}
    </AgentCommissionSectionContext.Provider>
  );
}

AgentCommissionSection.TypeField = function AgentCommissionTypeField({
  defaulValue
}: {
  defaulValue?: CommissionType;
}): JSX.Element {
  const { formDispatch, formMode, formRulesDispatch } =
    React.useContext(FormContext);
  const { refreshBuyersAgentCommission, fieldNames } = React.useContext(
    AgentCommissionSectionContext
  );

  const changeCommissionType = (value: string): void => {
    if (formDispatch && formRulesDispatch) {
      formRulesDispatch({
        type: "UpdateByInputName",
        inputName: fieldNames.commissionValue,
        payload: buyersCommissionRules(value)
      });

      formDispatch({
        type: "FormChangeByInputName",
        inputName: fieldNames.commissionType,
        payload: value
      });

      refreshBuyersAgentCommission();
    }
  };

  return (
    <RadioGroup
      row
      value={defaulValue}
      onChange={(item): void => {
        changeCommissionType(item.target.value);

        if (formDispatch) {
          formDispatch({
            type: "FormChangeByInputName",
            inputName: fieldNames.commissionValue,
            payload: undefined
          });
        }
      }}
      name={fieldNames.commissionType}
    >
      <FormControlLabel
        value={CommissionType.Percent}
        disabled={formMode === FormMode.ReadOnly}
        control={<Radio />}
        label="%"
        key={"buyersCommissionPercent"}
        checked={defaulValue === CommissionType.Percent}
      />

      <FormControlLabel
        value={CommissionType.Amount}
        disabled={formMode === FormMode.ReadOnly}
        control={<Radio />}
        label="$"
        key={"buyersCommissionAmount"}
        checked={defaulValue === CommissionType.Amount}
      />
    </RadioGroup>
  );
};

AgentCommissionSection.ValueField = function AgentCommissionField({
  defaultValue
}: {
  defaultValue?: string | number;
}): JSX.Element {
  const { hideBuyersAgentCommission, fieldNames } = React.useContext(
    AgentCommissionSectionContext
  );

  if (hideBuyersAgentCommission) {
    return <></>;
  }
  return (
    <NumberInput
      name={fieldNames.commissionValue}
      defaultValue={defaultValue}
    />
  );
};
