import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndRecord } from "@homesusa/core";
import {
  ElementarySchool,
  ElementarySchoolLabel,
  HighSchool,
  HighSchoolLabel,
  MiddleSchool,
  MiddleSchoolLabel,
  SchoolDistrict,
  SchoolDistrictLabel
} from "markets/ctx/enums";

export const schoolsRules: FormRules = {
  schoolDistrict: {
    label: "School District",
    options: getOptionsFromEnumAndRecord(
      Object.values(SchoolDistrict),
      SchoolDistrictLabel
    ),
    requiredToSubmit: true
  },
  elementarySchool: {
    label: "Elementary",
    options: getOptionsFromEnumAndRecord(
      Object.values(ElementarySchool),
      ElementarySchoolLabel
    )
  },
  middleSchool: {
    label: "Middle",
    options: getOptionsFromEnumAndRecord(
      Object.values(MiddleSchool),
      MiddleSchoolLabel
    )
  },
  highSchool: {
    label: "High",
    options: getOptionsFromEnumAndRecord(
      Object.values(HighSchool),
      HighSchoolLabel
    )
  }
};
