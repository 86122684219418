export enum UnitStyle {
  FirstFloorEntry = "firstFloorEntry",
  SecondFloorEntry = "secondFloorEntry",
  ThirdPlusFloorEntry = "thirdPlusFloorEntry",
  Elevator = "elevator",
  EndUnit = "endUnit",
  EntrySteps = "entrySteps",
  MultiLevelFloorPlan = "multiLevelFloorPlan",
  SingleLevelFloorPlan = "singleLevelFloorPlan"
}
export const UnitStyleLabel = new Map<UnitStyle, string>([
  [UnitStyle.FirstFloorEntry, "1st Floor Entry"],
  [UnitStyle.SecondFloorEntry, "2nd Floor Entry"],
  [UnitStyle.ThirdPlusFloorEntry, "3rd+ Floor Entry"],
  [UnitStyle.Elevator, "Elevator"],
  [UnitStyle.EndUnit, "End Unit"],
  [UnitStyle.EntrySteps, "Entry Steps"],
  [UnitStyle.MultiLevelFloorPlan, "Multi-level Floor Plan"],
  [UnitStyle.SingleLevelFloorPlan, "Single-level Floor Plan"]
]);
