export enum StreetDirection {
  East = "east",
  North = "north",
  Northeast = "northeast",
  Northwest = "northwest",
  South = "south",
  Southeast = "southeast",
  Southwest = "southwest",
  West = "west"
}

export const StreetDirectionLabel = new Map<StreetDirection, string>([
  [StreetDirection.East, "E"],
  [StreetDirection.North, "N"],
  [StreetDirection.Northeast, "NE"],
  [StreetDirection.Northwest, "NW"],
  [StreetDirection.South, "S"],
  [StreetDirection.Southeast, "SE"],
  [StreetDirection.Southwest, "SW"],
  [StreetDirection.West, "W"]
]);
