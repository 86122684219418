export enum HighSchool {
  Amarillo = "amarillo",
  Borger = "borger",
  Bushland = "bushland",
  Canyon = "canyon",
  Caprock = "caprock",
  Claude = "claude",
  DumasHigh = "dumasHigh",
  HappyHighSchool = "happyHighSchool",
  HighlandPark = "highlandPark",
  Other = "other",
  PaloDuro = "paloDuro",
  Panhandle = "panhandle",
  PerrytonHighSchool = "perrytonHighSchool",
  Randall = "randall",
  RiverRoad = "riverRoad",
  Tascosa = "tascosa",
  Vega = "vega",
  WestPlainsHighSchool = "westPlainsHighSchool",
  Wildorado = "wildorado"
}

export const HighSchoolLabel: Record<HighSchool, string> = {
  [HighSchool.Amarillo]: "Amarillo",
  [HighSchool.Borger]: "Borger",
  [HighSchool.Bushland]: "Bushland",
  [HighSchool.Canyon]: "Canyon",
  [HighSchool.Caprock]: "Caprock",
  [HighSchool.Claude]: "Claude",
  [HighSchool.DumasHigh]: "Dumas High",
  [HighSchool.HappyHighSchool]: "Happy High School",
  [HighSchool.HighlandPark]: "Highland Park",
  [HighSchool.Other]: "Other",
  [HighSchool.PaloDuro]: "Palo Duro",
  [HighSchool.Panhandle]: "Panhandle",
  [HighSchool.PerrytonHighSchool]: "Perryton High School",
  [HighSchool.Randall]: "Randall",
  [HighSchool.RiverRoad]: "River Road",
  [HighSchool.Tascosa]: "Tascosa",
  [HighSchool.Vega]: "Vega",
  [HighSchool.WestPlainsHighSchool]: "West Plains High School",
  [HighSchool.Wildorado]: "Wildorado"
};
