import React from "react";

import { FormRules } from "@homesusa/form";
import {
  ImportCommunityService,
  XmlListingService,
  ImportListingActionsService,
  ImportPlanService,
  AlertService,
  XmlPanelAlerts,
  JsonListingService
} from "modules/dashboard/interfaces/services";

export const DashboardContext = React.createContext<{
  importServices: ImportCommunityService &
    ImportPlanService &
    ImportListingActionsService;
  xmlServices: XmlListingService & XmlPanelAlerts;
  jsonServices: JsonListingService;
  alertServices: AlertService;
  marketRules: FormRules;
}>(Object.assign({}));
