export enum ConstructionStage {
  Complete = "complete",
  Incomplete = "incomplete",
  Proposed = "proposed"
}

export const ConstructionStageLabel = new Map<ConstructionStage, string>([
  [ConstructionStage.Complete, "Complete"],
  [ConstructionStage.Incomplete, "Incomplete"],
  [ConstructionStage.Proposed, "Proposed"]
]);
