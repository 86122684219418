import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { FormCommunityRules } from "modules/communities/interfaces/form-community-rules.interface";

import {
  AccessInstruction,
  AccessInstructionLabel,
  ConstructionMaterial,
  ConstructionMaterialLabel,
  CoolingSystem,
  CoolingSystemLabel,
  EnergyFeatures,
  EnergyFeaturesLabel,
  ExteriorFeatures,
  ExteriorFeaturesLabel,
  FireplaceDescription,
  FireplaceDescriptionLabel,
  Floors,
  FloorsLabel,
  Foundation,
  FoundationLabel,
  GarageCarpotDescription,
  GarageCarpotDescriptionLabel,
  GarageDescription,
  GarageDescriptionLabel,
  GolfCourseLabel,
  GolfCourseName,
  GreenCertification,
  GreenCertificationLabel,
  HeatSystem,
  HeatSystemLabel,
  HousingStyle,
  HousingStyleLabel,
  InteriorFeatures,
  InteriorFeaturesLabel,
  OvenDescription,
  OvenDescriptionLabel,
  Pool,
  PoolLabel,
  RangeDescription,
  RangeDescriptionLabel,
  RoofDescription,
  RoofDescriptionLabel,
  WasherConnection,
  WasherConnectionLabel,
  WaterSewer,
  WaterSewerLabel
} from "markets/har/enums";

export const utilitiesRules: FormRules | FormCommunityRules = {
  fireplaces: { label: "Fireplace - Number" },
  fireplaceDescription: {
    label: "Fireplace Description",
    options: getOptionsFromEnumAndMap(
      Object.values(FireplaceDescription),
      FireplaceDescriptionLabel
    ),
    rows: 2
  },
  washerConnections: {
    label: "Washer/Dryer",
    options: getOptionsFromEnumAndMap(
      Object.values(WasherConnection),
      WasherConnectionLabel
    )
  },
  garageSpaces: {
    label: "Garage - Number of Spaces",
    requiredToSubmit: true
  },
  garageDescription: {
    label: "Garage Description",
    options: getOptionsFromEnumAndMap(
      Object.values(GarageDescription),
      GarageDescriptionLabel
    ),
    rows: 2
  },
  garageCarpotDescription: {
    label: "Garage Carpot Description",
    options: getOptionsFromEnumAndMap(
      Object.values(GarageCarpotDescription),
      GarageCarpotDescriptionLabel
    ),
    rows: 2
  },
  greenCertification: {
    label: "Green/Energy Certifications",
    options: getOptionsFromEnumAndMap(
      Object.values(GreenCertification),
      GreenCertificationLabel
    )
  },
  golfCourseName: {
    label: "Golf Course Name",
    options: getOptionsFromEnumAndMap(
      Object.values(GolfCourseName),
      GolfCourseLabel
    )
  },
  accessInstructions: {
    label: "Access",
    options: getOptionsFromEnumAndMap(
      Object.values(AccessInstruction),
      AccessInstructionLabel
    )
  },
  energyFeatures: {
    label: "Energy Features",
    options: getOptionsFromEnumAndMap(
      Object.values(EnergyFeatures),
      EnergyFeaturesLabel
    )
  },
  heatSystem: {
    label: "Heating System Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(HeatSystem),
      HeatSystemLabel
    )
  },
  coolingSystem: {
    label: "Cooling System Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(CoolingSystem),
      CoolingSystemLabel
    )
  },
  waterSewer: {
    label: "Water/Sewer Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(WaterSewer),
      WaterSewerLabel
    )
  },
  floors: {
    label: "Flooring",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Floors), FloorsLabel)
  },
  housingStyle: {
    label: "Housing Style",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(HousingStyle),
      HousingStyleLabel
    )
  },
  constructionMaterials: {
    label: "Construction Materials",
    options: getOptionsFromEnumAndMap(
      Object.values(ConstructionMaterial),
      ConstructionMaterialLabel
    ),
    requiredToSubmit: true
  },
  roofDescription: {
    label: "Roof Description",
    options: getOptionsFromEnumAndMap(
      Object.values(RoofDescription),
      RoofDescriptionLabel
    ),
    requiredToSubmit: true
  },
  foundation: {
    label: "Foundation Description",
    options: getOptionsFromEnumAndMap(
      Object.values(Foundation),
      FoundationLabel
    ),
    requiredToSubmit: true
  },
  pool: {
    label: "Pool",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Pool), PoolLabel),
    rows: 2
  },
  exteriorFeatures: {
    label: "Exterior Features",
    options: getOptionsFromEnumAndMap(
      Object.values(ExteriorFeatures),
      ExteriorFeaturesLabel
    )
  },

  lotDimension: { label: "Lot Dimensions" },
  ovenDescription: {
    label: "Oven Type",
    options: getOptionsFromEnumAndMap(
      Object.values(OvenDescription),
      OvenDescriptionLabel
    )
  },
  rangeDescription: {
    label: "Range Type",
    options: getOptionsFromEnumAndMap(
      Object.values(RangeDescription),
      RangeDescriptionLabel
    )
  },
  interiorFeatures: {
    label: "Interior Features",
    options: getOptionsFromEnumAndMap(
      Object.values(InteriorFeatures),
      InteriorFeaturesLabel
    )
  },
  hasIcemaker: { label: "Separate Ice Maker" },
  hasDisposal: { label: "Disposal" },
  hasDishwasher: { label: "Dishwasher" },
  hasMicrowawe: { label: "Microwave" },
  hasCompactor: { label: "Compactor" },
  hasUtilitiesDescription: { label: "Utility District" },
  hasCommunityPool: { label: "Community Pool" },
  isActiveCommunity: { label: "55+ Active Community" },
  countertops: { label: "Countertops" }
};
