export enum Refreshments {
  Appetizers = "appetizers",
  Beverages = "beverages",
  CateredLunch = "cateredLunch",
  Desserts = "desserts",
  Lunch = "lunch",
  Pastries = "pastries",
  Snacks = "snacks"
}

export const RefreshmentsLabels = new Map<Refreshments, string>([
  [Refreshments.Appetizers, "Appetizers"],
  [Refreshments.Beverages, "Beverages"],
  [Refreshments.CateredLunch, "Catered Lunch"],
  [Refreshments.Desserts, "Desserts"],
  [Refreshments.Lunch, "Lunch"],
  [Refreshments.Pastries, "Pastries"],
  [Refreshments.Snacks, "Snacks"]
]);
