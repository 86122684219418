export enum LaundryLocation {
  DryerElectricHookup = "electricDryerHookup",
  DryerGasHookup = "gasDryerHookup",
  InGarage = "ingarage",
  InHall = "inHall",
  InKitchen = "inKitchen",
  InUtilityRoom = "utility",
  LaundryChute = "laundryChute",
  SizeWDArea = "fullSizeArea",
  StackedWDArea = "stackedArea",
  WasherHookup = "washerHookup"
}
export const LaundryLocationLabel = new Map<LaundryLocation, string>([
  [LaundryLocation.DryerElectricHookup, "Dryer - Electric Hookup"],
  [LaundryLocation.DryerGasHookup, "Dryer - Gas Hookup"],
  [LaundryLocation.InGarage, "In Garage"],
  [LaundryLocation.InHall, "In Hall"],
  [LaundryLocation.InKitchen, "In Kitchen"],
  [LaundryLocation.InUtilityRoom, "In Utility Room"],
  [LaundryLocation.LaundryChute, "Laundry Chute"],
  [LaundryLocation.SizeWDArea, "Full Size W/D Area"],
  [LaundryLocation.StackedWDArea, "Stacked W/D Area"],
  [LaundryLocation.WasherHookup, "Washer Hookup"]
]);
