export enum GarageDescription {
  AttachedCarport = "attachedCarport",
  AttachedGarage = "attachedGarage",
  CircularDrive = "circularDrive",
  DetachedCarport = "detachedCarport",
  DetachedGarage = "detachedGarage",
  DoorMulti = "doorMulti",
  DoorSingle = "doorSingle",
  EntryFrontCarport = "entryFrontCarport",
  EntryFrontGarage = "entryFrontGarage",
  EntryRearCarport = "entryRearCarport",
  EntryRearGarage = "entryRearGarage",
  EntrySideCarport = "entrySideCarport",
  EntrySideGarage = "entrySideGarage",
  GarageDoorOpener = "garageDoorOpener",
  GolfCartHalfGarage = "golfCartHalfGarage",
  OversizedGarage = "oversizedGarage",
  Tandem = "tandem",
  NoneGarage = "noneGarage",
  OtherGarageSeeRemarks = "otherGarageSeeRemarks"
}

export const GarageDescriptionLabel = new Map<GarageDescription, string>([
  [GarageDescription.AttachedCarport, "Attached Carport"],
  [GarageDescription.AttachedGarage, "Attached Garage"],
  [GarageDescription.CircularDrive, "Circular Drive"],
  [GarageDescription.DetachedCarport, "Detached Carport"],
  [GarageDescription.DetachedGarage, "Detached Garage"],
  [GarageDescription.DoorMulti, "Door-Multi"],
  [GarageDescription.DoorSingle, "Door-Single"],
  [GarageDescription.EntryFrontCarport, "Entry-Front Carport"],
  [GarageDescription.EntryFrontGarage, "Entry-Front Garage"],
  [GarageDescription.EntryRearCarport, "Entry-Rear Carport"],
  [GarageDescription.EntryRearGarage, "Entry-Rear Garage"],
  [GarageDescription.EntrySideCarport, "Entry-Side Carport"],
  [GarageDescription.EntrySideGarage, "Entry-Side Garage"],
  [GarageDescription.GarageDoorOpener, "Garage Door Opener(s)"],
  [GarageDescription.GolfCartHalfGarage, "Golf Cart/Half Garage"],
  [GarageDescription.OversizedGarage, "Oversized Garage"],
  [GarageDescription.Tandem, "Tandem"],
  [GarageDescription.NoneGarage, "None"],
  [GarageDescription.OtherGarageSeeRemarks, "Other Garage-See Remarks"]
]);
