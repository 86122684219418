import { FormRules } from "@homesusa/form";
import { financialRules, buyersAgentCommissionRules } from "../../sections";
import { agentBonusRules } from "core/rules/agent-bonus-rules";

export const financialInfoRules: FormRules = {
  ...financialRules,
  ...buyersAgentCommissionRules,
  ...agentBonusRules,
  taxExemptions: {
    label: "Exemptions"
  }
};
