import { FormRules } from "@homesusa/form";
import { financialRules, buyersAgentCommissionRules } from "../../sections";
import { agentBonusRules } from "core/rules/agent-bonus-rules";
import {
  PossibleFinancing,
  PossibleFinancingLabel
} from "markets/amarillo/enums";
import { getOptionsFromEnumAndRecord } from "@homesusa/core";

export const financialInfoRules: FormRules = {
  ...financialRules,
  ...buyersAgentCommissionRules,
  ...agentBonusRules,
  subAgentCommission: {
    label: "Sub Agency Compensation",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 2
    }
  },
  subAgentCommissionType: { label: "Sub Agency Compensation Type" },
  exclusions: {
    label: "Exclusions",
    maxLength: 20
  },
  taxRate: {
    label: "Tax Rate",
    maxLength: 20
  },
  taxExemptions: {
    label: "Tax w/o Exemptions",
    maxLength: 20
  },
  possibleFinancing: {
    label: "Possible Financing",
    max: 5,
    options: getOptionsFromEnumAndRecord(
      Object.values(PossibleFinancing),
      PossibleFinancingLabel
    )
  },
  suggestedTitleCompany: {
    label: "Suggested Title Company/Address",
    maxLength: 75
  }
};
