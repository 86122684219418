import { FormRules, zipCodeRule } from "@homesusa/form";

export const salesOfficeRules: FormRules = {
  streetNumber: {
    label: "Street Number",
    id: "salesOfficeStreetNum",
    maxLength: 8
  },
  streetName: {
    label: "Street Name",
    id: "salesOfficeStreetName"
  },
  streetSuffix: {
    label: "Street Suffix",
    id: "salesOfficestreetSuffix"
  },
  salesOfficeZip: {
    ...zipCodeRule,
    label: "Zip"
  }
};
