import { FormRules } from "@homesusa/form";
import { commonPlanRules, spacesDimensionsRules } from "./sections";

export const planRules: FormRules = {
  ...spacesDimensionsRules,
  ...commonPlanRules,
  ownerName: {
    label: "Owner Name",
    required: true,
    maxLength: 50,
    disabled: true
  },
  name: {
    label: "Plan Name",
    required: true,
    notAllowedWhiteSpaces: "start"
  }
};
