export enum RoomType {
  GameRoom = "gameRoom",
  MediaRoom = "mediaRoom",
  BonusRoom = "bonusRoom",
  DiningRoom = "diningRoom",
  BreakfastRoom = "breakfastRoom",
  KitchenRoom = "kitchenRoom",
  MasterBedroom = "masterBedroom",
  Bedroom = "bedroom",
  UtilityRoom = "utilityRoom",
  OfficeRoom = "officeRoom",
  LivingRoom = "livingRoom"
}

export const RoomTypeLabel = new Map<RoomType, string>([
  [RoomType.GameRoom, "Game/Other"],
  [RoomType.MediaRoom, "Media/Other"],
  [RoomType.BonusRoom, "Bonus Room"],
  [RoomType.DiningRoom, "Dining Room"],
  [RoomType.BreakfastRoom, "Breakfast Room"],
  [RoomType.KitchenRoom, "Kitchen"],
  [RoomType.MasterBedroom, "Bedroom-Primary"],
  [RoomType.Bedroom, "Bedroom"],
  [RoomType.UtilityRoom, "Utility Room"],
  [RoomType.OfficeRoom, "Office"],
  [RoomType.LivingRoom, "Living Room"]
]);

export const RoomTypeQuantity = new Map<RoomType, number>([
  [RoomType.LivingRoom, 2],
  [RoomType.MasterBedroom, 2],
  [RoomType.Bedroom, 6],
  [RoomType.UtilityRoom, 2]
]);
