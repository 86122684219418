export enum ElementarySchool {
  ASTEAMAcademySchool = "asteamAcademySchool",
  AcademyElementarySchool = "academyElementarySchool",
  AdamsHillElementarySchool = "adamsHillElementarySchool",
  AkinElementary = "akinElementary",
  AliceWDouseElementarySchool = "aliceWDouseElementarySchool",
  AllenElementarySchool = "allenElementarySchool",
  AllisonElementarySchool = "allisonElementarySchool",
  AlmaBrewerStrawnElementary = "almaBrewerStrawnElementary",
  AltaVista = "altaVista",
  AndersonMillElementarySchool = "andersonMillElementarySchool",
  AndrewsElementarySchool = "andrewsElementarySchool",
  ArlonRSeayElementary = "arlonRSeayElementary",
  ArtsIntegrationAcademySchool = "artsIntegrationAcademySchool",
  AueElementarySchool = "aueElementarySchool",
  BagdadElementarySchool = "bagdadElementarySchool",
  BaldwinElementarySchool = "baldwinElementarySchool",
  BaranoffElementarySchool = "baranoffElementarySchool",
  BarbaraJordanElementarySchool = "barbaraJordanElementarySchool",
  BarringtonElementarySchool = "barringtonElementarySchool",
  BarronElementarySchool = "barronElementarySchool",
  BartlettElementarySchool = "bartlettElementarySchool",
  BartonCreekElementary = "bartonCreekElementary",
  BartonHillsElementarySchool = "bartonHillsElementarySchool",
  BatyElementary = "batyElementary",
  BeardElementarySchool = "beardElementarySchool",
  BeckerElementarySchool = "beckerElementarySchool",
  BeeCaveElementary = "beeCaveElementary",
  BehlauElementarySchool = "behlauElementarySchool",
  BellsHill = "bellsHill",
  BellaireElementarySchool = "bellaireElementarySchool",
  BenMilamElementary = "benMilamElementary",
  BenjaminDocKerleyElementary = "benjaminDocKerleyElementary",
  BerkmanElementarySchool = "berkmanElementarySchool",
  BertramElementarySchool = "bertramElementarySchool",
  BillBrownElementary = "billBrownElementary",
  BillBurdenElementarySchool = "billBurdenElementarySchool",
  BlacklandPrairieElementarySchool = "blacklandPrairieElementarySchool",
  BlackshearElementary = "blackshearElementary",
  BlackshearElementarySchool = "blackshearElementarySchool",
  BlakeManorElementary = "blakeManorElementary",
  BlancoVistaElementary = "blancoVistaElementary",
  BlantonElementarySchool = "blantonElementarySchool",
  BlattmanElementarySchool = "blattmanElementarySchool",
  BlazierElementarySchool = "blazierElementarySchool",
  BlockHouseCreekElementarySchool = "blockHouseCreekElementarySchool",
  BluebonnetElementary = "bluebonnetElementary",
  BluebonnetElementarySchool = "bluebonnetElementarySchool",
  BluebonnetTrainElementary = "bluebonnetTrainElementary",
  BluebonnettElementary = "bluebonnettElementary",
  BoldtElementarySchool = "boldtElementarySchool",
  BookerTWashingtonELEM = "bookerTWashingtonELEM",
  BooneElementarySchool = "booneElementarySchool",
  BowieElementary = "bowieElementary",
  BrauchleElementarySchool = "brauchleElementarySchool",
  BraunStationElementarySchool = "braunStationElementarySchool",
  BrenhamElementarySchool = "brenhamElementarySchool",
  BrentwoodElementarySchool = "brentwoodElementarySchool",
  BridgePointElementary = "bridgePointElementary",
  BrookAvenue = "brookAvenue",
  BrookeElementarySchool = "brookeElementarySchool",
  BrookhavenElementarySchool = "brookhavenElementarySchool",
  BrookhollowElementarySchool = "brookhollowElementarySchool",
  BrucevilleEddyElementary = "brucevilleEddyElementary",
  BrushyCreekElementarySchool = "brushyCreekElementarySchool",
  BrykerWoodsElementarySchool = "brykerWoodsElementarySchool",
  BudaElementary = "budaElementary",
  BurkeElementarySchool = "burkeElementarySchool",
  CRClementsHollieParsons = "cRClementsHollieParsons",
  CableElementarySchool = "cableElementarySchool",
  CactusRanchElementarySchool = "cactusRanchElementarySchool",
  CaldwellElementarySchool = "caldwellElementarySchool",
  CaldwellHeightsElementarySchool = "caldwellHeightsElementarySchool",
  CallisonElementarySchool = "callisonElementarySchool",
  CamachoElementarySchool = "camachoElementarySchool",
  CaminoRealElementary = "caminoRealElementary",
  CampbellElementarySchool = "campbellElementarySchool",
  CanyonCreekElementarySchool = "canyonCreekElementarySchool",
  CarawayElementarySchool = "carawayElementarySchool",
  CarlSchurzElementary = "carlSchurzElementary",
  CarlosCoonElementarySchool = "carlosCoonElementarySchool",
  CarnahanElementarySchool = "carnahanElementarySchool",
  CarpenterHillElementary = "carpenterHillElementary",
  CarsonElementarySchool = "carsonElementarySchool",
  CarverElementarySchool = "carverElementarySchool",
  CaseyElementarySchool = "caseyElementarySchool",
  CasisElementarySchool = "casisElementarySchool",
  CastlemanCreekElementary = "castlemanCreekElementary",
  CaterElementary = "caterElementary",
  CavazosElementarySchool = "cavazosElementarySchool",
  CedarCreekElementary = "cedarCreekElementary",
  CedarRidge = "cedarRidge",
  CedarValleyElementarySchool = "cedarValleyElementarySchool",
  ChandlerOaksElementarySchool = "chandlerOaksElementarySchool",
  CharterOakElementary = "charterOakElementary",
  ChiltonSchool = "chiltonSchool",
  ChinaSpringElementarySchool = "chinaSpringElementarySchool",
  ChisholmTrail = "chisholmTrail",
  ChisholmTrailElementary = "chisholmTrailElementary",
  CiboloValleyElementary = "ciboloValleyElementary",
  ClarkeElementarySchool = "clarkeElementarySchool",
  ClaytonElementarySchool = "claytonElementarySchool",
  ClearCreekElementarySchool = "clearCreekElementarySchool",
  ClearForkElementary = "clearForkElementary",
  ClearSpringElementary = "clearSpringElementary",
  CliftonParkElementarySchool = "cliftonParkElementarySchool",
  CodyElementarySchool = "codyElementarySchool",
  ColeElementarySchool = "coleElementarySchool",
  ColoniesNorthElementarySchool = "coloniesNorthElementarySchool",
  ColtElementary = "coltElementary",
  ConnallyElementrySchool = "connallyElementrySchool",
  CookElementarySchool = "cookElementarySchool",
  CooperElementarySchool = "cooperElementarySchool",
  CopperfieldElementarySchool = "copperfieldElementarySchool",
  CottonwoodCreekElementarySchool = "cottonwoodCreekElementarySchool",
  CountyLineElementary = "countyLineElementary",
  CouplandElementarySchool = "couplandElementarySchool",
  CowanElementarySchool = "cowanElementarySchool",
  CoxElementarySchool = "coxElementarySchool",
  CreedmoorElementary = "creedmoorElementary",
  Crestview = "crestview",
  CrockettElementary = "crockettElementary",
  CunninghamElementarySchool = "cunninghamElementarySchool",
  CypressElementarySchool = "cypressElementarySchool",
  CypressSpringsElementary = "cypressSpringsElementary",
  DannaSpring = "dannaSpring",
  DavisElementarySchool = "davisElementarySchool",
  DawsonElementarySchool = "dawsonElementarySchool",
  DeLeonElementary = "deLeonElementary",
  DeZavalaElementary = "deZavalaElementary",
  DeanHighlandElementary = "deanHighlandElementary",
  DearingElementarySchool = "dearingElementarySchool",
  DeckerElementary = "deckerElementary",
  DeepWoodElementarySchool = "deepWoodElementarySchool",
  DeerCreekElementarySchool = "deerCreekElementarySchool",
  DelValleElementary = "delValleElementary",
  DelcoPrimarySchool = "delcoPrimarySchool",
  DellCitySchool = "dellCitySchool",
  DessauElementarySchool = "dessauElementarySchool",
  DobiePrekindergartenCenter = "dobiePrekindergartenCenter",
  DossElementarySchool = "dossElementarySchool",
  DoubleFileTrailElementarySchool = "doubleFileTrailElementarySchool",
  DriggersElementarySchool = "driggersElementarySchool",
  DrippingSpringsElementary = "drippingSpringsElementary",
  DuncanElementarySchool = "duncanElementarySchool",
  EanesElementary = "eanesElementary",
  EastWardElementarySchool = "eastWardElementarySchool",
  ElginElementarySchool = "elginElementarySchool",
  EllisonElementarySchool = "ellisonElementarySchool",
  ElmGroveElementary = "elmGroveElementary",
  ElrodElementarySchool = "elrodElementarySchool",
  EmileElementary = "emileElementary",
  EnglandElementarySchool = "englandElementarySchool",
  EsparzaElementarySchool = "esparzaElementarySchool",
  EvantElementarySchool = "evantElementarySchool",
  EversElementarySchool = "eversElementarySchool",
  FairviewMissJewellElementary = "fairviewMissJewellElementary",
  FaubionElementarySchool = "faubionElementarySchool",
  FernBluffElementarySchool = "fernBluffElementarySchool",
  FernandezElementarySchool = "fernandezElementarySchool",
  FieldsElementarySchool = "fieldsElementarySchool",
  FisherElementarySchool = "fisherElementarySchool",
  FlatoniaElementarySchool = "flatoniaElementarySchool",
  FlorenceElementarySchool = "florenceElementarySchool",
  FordElementarySchool = "fordElementarySchool",
  ForestCreekElementarySchool = "forestCreekElementarySchool",
  ForestNorthElementarySchool = "forestNorthElementarySchool",
  ForesterElementarySchool = "foresterElementarySchool",
  ForrestTrailElementary = "forrestTrailElementary",
  FowlerElementarySchool = "fowlerElementarySchool",
  FranklinElementarySchool = "franklinElementarySchool",
  FreiheitElementary = "freiheitElementary",
  FrostElementarySchool = "frostElementarySchool",
  FuentesElementary = "fuentesElementary",
  GalindoElementarySchool = "galindoElementarySchool",
  GalmElementarySchool = "galmElementarySchool",
  GarciaElementary = "garciaElementary",
  GardenRidgeElementary = "gardenRidgeElementary",
  Gatesville = "gatesville",
  GattisElementarySchool = "gattisElementarySchool",
  GeorgeWCarverEarlyEducation = "georgeWCarverEarlyEducation",
  GiddensElementarySchool = "giddensElementarySchool",
  GiddingsElementarySchool = "giddingsElementarySchool",
  GlassElementarySchool = "glassElementarySchool",
  GlennElementarySchool = "glennElementarySchool",
  GlenoaksElementarySchool = "glenoaksElementarySchool",
  GonzalesEastAvenuePrimarySchool = "gonzalesEastAvenuePrimarySchool",
  GonzalesElementary = "gonzalesElementary",
  GoodwinFrazierElementary = "goodwinFrazierElementary",
  GovalleElementarySchool = "govalleElementarySchool",
  GrahamElementarySchool = "grahamElementarySchool",
  GrandviewHillsElementarySchool = "grandviewHillsElementarySchool",
  GrangerElementarySchool = "grangerElementarySchool",
  GreatOaksElementarySchool = "greatOaksElementarySchool",
  GreenElementary = "greenElementary",
  GreenValleyElementary = "greenValleyElementary",
  GuerreroThompsonElementarySchool = "guerreroThompsonElementarySchool",
  GullettElementarySchool = "gullettElementarySchool",
  HannaSprings = "hannaSprings",
  HarkerHeightsElementarySchool = "harkerHeightsElementarySchool",
  HarrisElementarySchool = "harrisElementarySchool",
  HartElementarySchool = "hartElementarySchool",
  HatchettElementarySchool = "hatchettElementarySchool",
  HayBranchElementarySchool = "hayBranchElementarySchool",
  HaynesElementarySchool = "haynesElementarySchool",
  HelotesElementarySchool = "helotesElementarySchool",
  HemphillElementary = "hemphillElementary",
  HendersonElementarySchool = "hendersonElementarySchool",
  HernandezElementary = "hernandezElementary",
  HerringtonElementarySchool = "herringtonElementarySchool",
  HettieHalesteadElementary = "hettieHalesteadElementary",
  HewittElementarySchool = "hewittElementarySchool",
  HighPointElementary = "highPointElementary",
  HighlandLakesElementary = "highlandLakesElementary",
  HighlandParkElementarySchool = "highlandParkElementarySchool",
  HillElementarySchool = "hillElementarySchool",
  HillcrestElementary = "hillcrestElementary",
  HillcrestPDSElementary = "hillcrestPDSElementary",
  HoffmanLaneElementary = "hoffmanLaneElementary",
  HoffmannElementarySchool = "hoffmannElementarySchool",
  HollandElementarySchool = "hollandElementarySchool",
  HornsbyDunlapElementary = "hornsbyDunlapElementary",
  HouseCreekElementary = "houseCreekElementary",
  HoustonElementarySchool = "houstonElementarySchool",
  HowardNormanElementarySchool = "howardNormanElementarySchool",
  HowsmanElementarySchool = "howsmanElementarySchool",
  HuttoElementarySchool = "huttoElementarySchool",
  IdumaElementarySchool = "idumaElementarySchool",
  IgoElementary = "igoElementary",
  IndianSpringsElementary = "indianSpringsElementary",
  IraCrossElementarySchool = "iraCrossElementarySchool",
  JHHinesElementary = "jHHinesElementary",
  JacobsWell = "jacobsWell",
  JarrellElementarySchool = "jarrellElementarySchool",
  JeffersonElementary = "jeffersonElementary",
  JLWilliamsLovettLedgerElementary = "jLWilliamsLovettLedgerElementary",
  JoeLeeJohnsonElementarySchool = "joeLeeJohnsonElementarySchool",
  JohnASippelElementary = "johnASippelElementary",
  JohnBWinnElementarySchool = "johnBWinnElementarySchool",
  JohnsonRanchElementary = "johnsonRanchElementary",
  JollyvilleElementarySchool = "jollyvilleElementarySchool",
  JonesboroElementary = "jonesboroElementary",
  JosephGilbertElementarySchool = "josephGilbertElementarySchool",
  JoslinElementarySchool = "joslinElementarySchool",
  KallisonElementarySchool = "kallisonElementarySchool",
  KendrickElementary = "kendrickElementary",
  KennedyPowellElementary = "kennedyPowellElementary",
  KikerElementarySchool = "kikerElementarySchool",
  KilleenElementary = "killeenElementary",
  KinderRanchElementary = "kinderRanchElementary",
  KleinRoadElementary = "kleinRoadElementary",
  KlineWhitis = "klineWhitis",
  KnowlesElementarySchool = "knowlesElementarySchool",
  KnowltonElementarySchool = "knowltonElementarySchool",
  KocurekElementarySchool = "kocurekElementarySchool",
  KoenneckeElementary = "koenneckeElementary",
  KruegerElementarySchool = "kruegerElementarySchool",
  KuentzElementarySchool = "kuentzElementarySchool",
  KyleElementary = "kyleElementary",
  LagoVistaElementarySchool = "lagoVistaElementarySchool",
  LakeAirMontessori = "lakeAirMontessori",
  LakePointeElementary = "lakePointeElementary",
  LakeTravisElementary = "lakeTravisElementary",
  LakewayElementary = "lakewayElementary",
  LakewoodElementary = "lakewoodElementary",
  LamarElementary = "lamarElementary",
  LangfordElementarySchool = "langfordElementarySchool",
  LangleyElementarySchool = "langleyElementarySchool",
  LarkspurElementary = "larkspurElementary",
  LauraWelchBushElementarySchool = "lauraWelchBushElementarySchool",
  LaurelMountainElementarySchool = "laurelMountainElementarySchool",
  LaVegaElementary = "laVegaElementary",
  LeakeyElementarySchool = "leakeyElementarySchool",
  LeeElementarySchool = "leeElementarySchool",
  LeonHeightsElementary = "leonHeightsElementary",
  LeonSpringsElementarySchool = "leonSpringsElementarySchool",
  LeonValleyElementarySchool = "leonValleyElementarySchool",
  LeonardShanklinElementary = "leonardShanklinElementary",
  LewisElementarySchool = "lewisElementarySchool",
  LexingtonElementarySchool = "lexingtonElementarySchool",
  LibertyHillElementarySchool = "libertyHillElementarySchool",
  LieckElementarySchool = "lieckElementarySchool",
  LinderElementarySchool = "linderElementarySchool",
  LintonElementarySchool = "lintonElementarySchool",
  LiveOakElementarySchool = "liveOakElementarySchool",
  LlanoElmentary = "llanoElmentary",
  LockeHillElementarySchool = "lockeHillElementarySchool",
  LometaElementary = "lometaElementary",
  LoneOakElementarySchool = "loneOakElementarySchool",
  LoneStarElementary = "loneStarElementary",
  LorenaElementarySchool = "lorenaElementarySchool",
  LosReyesElementarySchool = "losReyesElementarySchool",
  LostPinesElementary = "lostPinesElementary",
  LottElementary = "lottElementary",
  LouineNoble = "louineNoble",
  LucyReadPreKindergartenSchool = "lucyReadPreKindergartenSchool",
  LulingPrimary = "lulingPrimary",
  LyndonBJohnsonES = "lyndonBJohnsonES",
  MaeStevens = "maeStevens",
  ManorElementary = "manorElementary",
  MaplewoodElementarySchool = "maplewoodElementarySchool",
  MarbleFallsElementary = "marbleFallsElementary",
  MartinElementarySchool = "martinElementarySchool",
  MartinWalkerElementary = "martinWalkerElementary",
  MaryHullElementarySchool = "maryHullElementarySchool",
  MasonElementarySchool = "masonElementarySchool",
  MastersElementary = "mastersElementary",
  MathewsElementarySchool = "mathewsElementarySchool",
  MaudeMooreWoodElementarySchool = "maudeMooreWoodElementarySchool",
  MaxdaleElementarySchool = "maxdaleElementarySchool",
  MaxineLutrellWattsElementary = "maxineLutrellWattsElementary",
  May = "may",
  MayElementarySchool = "mayElementarySchool",
  MaysElementarySchool = "maysElementarySchool",
  McAndrewElementarySchool = "mcAndrewElementarySchool",
  McBayElementarySchool = "mcBayElementarySchool",
  McBeeElementarySchool = "mcBeeElementarySchool",
  McCoyElementarySchool = "mcCoyElementarySchool",
  McDermottElementarySchool = "mcDermottElementarySchool",
  McGregorElementarySchool = "mcGregorElementarySchool",
  McQueeneyElementary = "mcQueeneyElementary",
  MeadElementarySchool = "meadElementarySchool",
  MeadowVillageElementarySchool = "meadowVillageElementarySchool",
  MeadowsElementarySchool = "meadowsElementarySchool",
  MemorialElementary = "memorialElementary",
  MenchacaElementarySchool = "menchacaElementarySchool",
  MendezElementary = "mendezElementary",
  MeridithDunbar = "meridithDunbar",
  MetroplexBHCElementarySchool = "metroplexBHCElementarySchool",
  MetzElementarySchool = "metzElementarySchool",
  MHSpechtElementary = "mHSpechtElementary",
  MichaelElementarySchool = "michaelElementarySchool",
  MillerHeightsElementary = "millerHeightsElementary",
  MillsElementarySchool = "millsElementarySchool",
  MinaElementary = "minaElementary",
  MirelesElementarySchool = "mirelesElementarySchool",
  MissionValleyElementarySchool = "missionValleyElementarySchool",
  MitchellElementarySchool = "mitchellElementarySchool",
  MontagueElementarySchool = "montagueElementarySchool",
  MoodyElementarySchool = "moodyElementarySchool",
  MoraElementary = "moraElementary",
  MorningsideElementary = "morningsideElementary",
  MottElementary = "mottElementary",
  MountainValleyElementary = "mountainValleyElementary",
  MountainViewElementary = "mountainViewElementary",
  MountainviewElementary = "mountainviewElementary",
  MurchisonElementarySchool = "murchisonElementarySchool",
  MurninElementarySchool = "murninElementarySchool",
  MyersElementarySchool = "myersElementarySchool",
  NadineJohnsonElementarySchool = "nadineJohnsonElementarySchool",
  NaomiPademannElementarySchool = "naomiPademannElementarySchool",
  NaumannElementarySchool = "naumannElementarySchool",
  NavarroElementary = "navarroElementary",
  NegleyElementary = "negleyElementary",
  NeidigElementarySchool = "neidigElementarySchool",
  NicholsElementarySchool = "nicholsElementarySchool",
  NixonSmileyElementary = "nixonSmileyElementary",
  NolanvilleElementarySchool = "nolanvilleElementarySchool",
  NormaJPaschalElementary = "normaJPaschalElementary",
  NormaKruegerElementary = "normaKruegerElementary",
  NormaKruegerBertKarrerCampus = "normaKruegerBertKarrerCampus",
  NormanElementarySchool = "normanElementarySchool",
  NorthwestCrossingElementarySchool = "northwestCrossingElementarySchool",
  NorthwestElementarySchool = "northwestElementarySchool",
  OGWiedersteinElementary = "oGWiedersteinElementary",
  OakCreek = "oakCreek",
  OakCreekElementary = "oakCreekElementary",
  OakHillElementarySchool = "oakHillElementarySchool",
  OakHillsTerraceElementarySchool = "oakHillsTerraceElementarySchool",
  OakMeadowsElementary = "oakMeadowsElementary",
  OakSpringsElementarySchool = "oakSpringsElementarySchool",
  OdomElementarySchool = "odomElementarySchool",
  OglesbyElementarySchool = "oglesbyElementarySchool",
  OldTownElementarySchool = "oldTownElementarySchool",
  OrtegaElementarySchool = "ortegaElementarySchool",
  OttElementarySchool = "ottElementarySchool",
  OutofArea = "outofArea",
  OvertonElementarySchool = "overtonElementarySchool",
  OvetaCulpHobbyElementarySchool = "ovetaCulpHobbyElementarySchool",
  PacksaddleElementary = "packsaddleElementary",
  PadrónElementarySchool = "padrónElementarySchool",
  PalmElementarySchool = "palmElementarySchool",
  ParkdaleElementary = "parkdaleElementary",
  ParksideElementarySchool = "parksideElementarySchool",
  ParmerLaneElementarySchool = "parmerLaneElementarySchool",
  PassmoreElementarySchool = "passmoreElementarySchool",
  PatCarneyElementary = "patCarneyElementary",
  PatlanElementary = "patlanElementary",
  PattonElementarySchool = "pattonElementarySchool",
  PeaseElementarySchool = "peaseElementarySchool",
  PecanSpringsElementarySchool = "pecanSpringsElementarySchool",
  PeeblesElementarySchool = "peeblesElementarySchool",
  PerezElementarySchool = "perezElementarySchool",
  PershingParkElementarySchool = "pershingParkElementarySchool",
  PflugerElementary = "pflugerElementary",
  PflugervilleElementarySchool = "pflugervilleElementarySchool",
  PickettElementarySchool = "pickettElementarySchool",
  PickleElementarySchool = "pickleElementarySchool",
  PillowElementarySchool = "pillowElementarySchool",
  PioneerCrossingElementary = "pioneerCrossingElementary",
  PirtleElementary = "pirtleElementary",
  PlainElementarySchool = "plainElementarySchool",
  PleasantHillElementarySchool = "pleasantHillElementarySchool",
  PlumCreekElementary = "plumCreekElementary",
  PondSpringsElementarySchool = "pondSpringsElementarySchool",
  PophamElementary = "pophamElementary",
  PowellElementarySchool = "powellElementarySchool",
  PrairieLeaSchoolPK12 = "prairieLeaSchoolPK12",
  PresidentialMeadowsElementary = "presidentialMeadowsElementary",
  ProvidentHeightsElementary = "providentHeightsElementary",
  PurlElementarySchool = "purlElementarySchool",
  PurpleSageElementarySchool = "purpleSageElementarySchool",
  RabaElementarySchool = "rabaElementarySchool",
  RaheBulverdeElementarySchool = "raheBulverdeElementarySchool",
  RanchoSiennaElementary = "ranchoSiennaElementary",
  RayElementarySchool = "rayElementarySchool",
  RayeAllenElementary = "rayeAllenElementary",
  ReaganElementarySchool = "reaganElementarySchool",
  RebeccaCreek = "rebeccaCreek",
  ReecesCreekElementarySchool = "reecesCreekElementarySchool",
  ReedElementarySchool = "reedElementarySchool",
  ReillyElementarySchool = "reillyElementarySchool",
  RhodesElementarySchool = "rhodesElementarySchool",
  RidgetopElementarySchool = "ridgetopElementarySchool",
  RiojasElementarySchool = "riojasElementarySchool",
  RiverOaksElementarySchool = "riverOaksElementarySchool",
  RiverPlaceElementarySchool = "riverPlaceElementarySchool",
  RiverRidgeElementarySchool = "riverRidgeElementarySchool",
  RJRichyElementarySchool = "rJRichyElementarySchool",
  RobertLeeElem = "robertLeeElem",
  RobertsonElementarySchool = "robertsonElementarySchool",
  RobinsonElementary = "robinsonElementary",
  RockdaleElementarySchool = "rockdaleElementarySchool",
  RodriguezElementary = "rodriguezElementary",
  RodriguezElementarySch = "rodriguezElementarySch",
  RodriguezElementarySchool = "rodriguezElementarySchool",
  RogersElementarySchool = "rogersElementarySchool",
  RoosterSpringsElementary = "roosterSpringsElementary",
  RoseGardenElementary = "roseGardenElementary",
  RosenwaldPrimary = "rosenwaldPrimary",
  RoweLaneElementarySchool = "roweLaneElementarySchool",
  RutledgeElementarySchool = "rutledgeElementarySchool",
  SaegertElementarySchool = "saegertElementarySchool",
  SalinasElementarySchool = "salinasElementarySchool",
  SanchezElementarySchool = "sanchezElementarySchool",
  SantaRitaElementarySchool = "santaRitaElementarySchool",
  ScarboroughElementarySchool = "scarboroughElementarySchool",
  SchertzElementary = "schertzElementary",
  ScienceHallElementary = "scienceHallElementary",
  ScobeeElementarySchool = "scobeeElementarySchool",
  ScottElementary = "scottElementary",
  ScudderPrimary = "scudderPrimary",
  SeeleElementary = "seeleElementary",
  SereneHillsElementary = "sereneHillsElementary",
  ShadowGlenElementary = "shadowGlenElementary",
  ShadyGroveElementarySchool = "shadyGroveElementarySchool",
  SiebertElementarySchool = "siebertElementarySchool",
  SimmsElementarySchool = "simmsElementarySchool",
  SimsElementarySchool = "simsElementarySchool",
  SkipchaElementarySchool = "skipchaElementarySchool",
  SmithElementary = "smithElementary",
  SmithvilleElementary = "smithvilleElementary",
  SmithvilleElementarySchool = "smithvilleElementarySchool",
  SomervilleElementarySchool = "somervilleElementarySchool",
  SommerElementarySchool = "sommerElementarySchool",
  SouthWacoElementary = "southWacoElementary",
  SouthwestElementary = "southwestElementary",
  SpartaElementary = "spartaElementary",
  SpicewoodElementary = "spicewoodElementary",
  SpicewoodElementarySchool = "spicewoodElementarySchool",
  SpringHillElementarySchool = "springHillElementarySchool",
  StElmoElementarySchool = "stElmoElementarySchool",
  Startzville = "startzville",
  SteinerRanchElementarySchool = "steinerRanchElementarySchool",
  SteubingElementarySchool = "steubingElementarySchool",
  SugarLoafElementarySchool = "sugarLoafElementarySchool",
  SummittElementarySchool = "summittElementarySchool",
  SunfieldElementary = "sunfieldElementary",
  SunsetValleyElementarySchool = "sunsetValleyElementarySchool",
  TABrownElementarySchool = "tABrownElementarySchool",
  TarverElementary = "tarverElementary",
  TaylorCreek = "taylorCreek",
  TeravistaElementarySchool = "teravistaElementarySchool",
  ThomasArnoldElementary = "thomasArnoldElementary",
  ThorndaleElementarySchool = "thorndaleElementarySchool",
  ThorntonElementary = "thorntonElementary",
  ThorntonElementarySchool = "thorntonElementarySchool",
  ThrallElementarySchool = "thrallElementarySchool",
  TimberRidgeElementarySchool = "timberRidgeElementarySchool",
  TimberwildeElementarySchool = "timberwildeElementarySchool",
  TimberwoodParkElementary = "timberwoodParkElementary",
  TimmermanElementarySchool = "timmermanElementarySchool",
  TobiasElementary = "tobiasElementary",
  TravisElementary = "travisElementary",
  TravisHeightsElementarySchool = "travisHeightsElementarySchool",
  TrimmierElementarySchool = "trimmierElementarySchool",
  TroyElementary = "troyElementary",
  Tyler = "tyler",
  UnionHillElementarySchool = "unionHillElementarySchool",
  UphausEarlyChildhoodCenter = "uphausEarlyChildhoodCenter",
  ValleyHiElementarySchool = "valleyHiElementarySchool",
  ValleyViewElementary = "valleyViewElementary",
  VenableVillageElementarySchool = "venableVillageElementarySchool",
  VeramendiElementarySchool = "veramendiElementarySchool",
  VeteransHillElementarySchool = "veteransHillElementarySchool",
  VillageElementarySchool = "villageElementarySchool",
  VillarrealElementarySchool = "villarrealElementarySchool",
  VogelElementary = "vogelElementary",
  VoigtElementarySchool = "voigtElementarySchool",
  VossFarmsElementary = "vossFarmsElementary",
  WaelderSchoolPK12 = "waelderSchoolPK12",
  WalnutCreekElementarySchool = "walnutCreekElementarySchool",
  WalnutSprings = "walnutSprings",
  WalnutSpringsElementary = "walnutSpringsElementary",
  WankeElementarySchool = "wankeElementarySchool",
  WardElementarySchool = "wardElementarySchool",
  WebbPrimarySchool = "webbPrimarySchool",
  WeinertElementary = "weinertElementary",
  WellsBranchElementarySchool = "wellsBranchElementarySchool",
  WestAvenueElementary = "westAvenueElementary",
  WestCypressHillsElementary = "westCypressHillsElementary",
  WestWardElementarySchool = "westWardElementarySchool",
  WesternHillsElementary = "westernHillsElementary",
  WestsideElementarySchool = "westsideElementarySchool",
  WestwoodTerraceElementarySchool = "westwoodTerraceElementarySchool",
  Whillis = "whillis",
  WhitestoneElementarySchool = "whitestoneElementarySchool",
  WhitneyElementarySchool = "whitneyElementarySchool",
  WidénElementarySchool = "widénElementarySchool",
  WielandElementarySchool = "wielandElementarySchool",
  WilliamsElementarySchool = "williamsElementarySchool",
  WillowSpringsElementarySchool = "willowSpringsElementarySchool",
  WindermereElementarySchool = "windermereElementarySchool",
  WindermerePrimarySchool = "windermerePrimarySchool",
  WinkleyElementarySchool = "winkleyElementarySchool",
  WolfRanchElementarySchool = "wolfRanchElementarySchool",
  WoodwayElementarySchool = "woodwayElementarySchool",
  WooldridgeElementarySchool = "wooldridgeElementarySchool",
  WootenElementarySchool = "wootenElementarySchool",
  ZavalaElementarySchool = "zavalaElementarySchool",
  ZilkerElementarySchool = "zilkerElementarySchool"
}

export const ElementarySchoolLabel: Record<ElementarySchool, string> = {
  [ElementarySchool.ASTEAMAcademySchool]: "A STEAM Academy School",
  [ElementarySchool.AcademyElementarySchool]: "Academy Elementary School",
  [ElementarySchool.AdamsHillElementarySchool]: "Adams Hill Elementary School",
  [ElementarySchool.AkinElementary]: "Akin Elementary",
  [ElementarySchool.AliceWDouseElementarySchool]:
    "Alice W. Douse Elementary School",
  [ElementarySchool.AllenElementarySchool]: "Allen Elementary School",
  [ElementarySchool.AllisonElementarySchool]: "Allison Elementary School",
  [ElementarySchool.AlmaBrewerStrawnElementary]:
    "Alma Brewer Strawn Elementary",
  [ElementarySchool.AltaVista]: "Alta Vista",
  [ElementarySchool.AndersonMillElementarySchool]:
    "Anderson Mill Elementary School",
  [ElementarySchool.AndrewsElementarySchool]: "Andrews Elementary School",
  [ElementarySchool.ArlonRSeayElementary]: "Arlon R Seay Elementary",
  [ElementarySchool.ArtsIntegrationAcademySchool]:
    "Arts Integration Academy School",
  [ElementarySchool.AueElementarySchool]: "Aue Elementary School",
  [ElementarySchool.BagdadElementarySchool]: "Bagdad Elementary School",
  [ElementarySchool.BaldwinElementarySchool]: "Baldwin Elementary School",
  [ElementarySchool.BaranoffElementarySchool]: "Baranoff Elementary School",
  [ElementarySchool.BarbaraJordanElementarySchool]:
    "Barbara Jordan Elementary School",
  [ElementarySchool.BarringtonElementarySchool]: "Barrington Elementary School",
  [ElementarySchool.BarronElementarySchool]: "Barron Elementary School",
  [ElementarySchool.BartlettElementarySchool]: "Bartlett  Elementary School",
  [ElementarySchool.BartonCreekElementary]: "Barton Creek Elementary",
  [ElementarySchool.BartonHillsElementarySchool]:
    "Barton Hills Elementary School",
  [ElementarySchool.BatyElementary]: "Baty Elementary",
  [ElementarySchool.BeardElementarySchool]: "Beard Elementary School",
  [ElementarySchool.BeckerElementarySchool]: "Becker Elementary School",
  [ElementarySchool.BeeCaveElementary]: "Bee Cave Elementary",
  [ElementarySchool.BehlauElementarySchool]: "Behlau Elementary School",
  [ElementarySchool.BellsHill]: "Bell's Hill",
  [ElementarySchool.BellaireElementarySchool]: "Bellaire Elementary School",
  [ElementarySchool.BenMilamElementary]: "Ben Milam Elementary",
  [ElementarySchool.BenjaminDocKerleyElementary]:
    "Benjamin Doc Kerley Elementary",
  [ElementarySchool.BerkmanElementarySchool]: "Berkman Elementary School",
  [ElementarySchool.BertramElementarySchool]: "Bertram Elementary School",
  [ElementarySchool.BillBrownElementary]: "Bill Brown Elementary",
  [ElementarySchool.BillBurdenElementarySchool]:
    "Bill Burden Elementary  School",
  [ElementarySchool.BlacklandPrairieElementarySchool]:
    "Blackland Prairie Elementary School",
  [ElementarySchool.BlackshearElementary]: "Blackshear Elementary",
  [ElementarySchool.BlackshearElementarySchool]: "Blackshear Elementary School",
  [ElementarySchool.BlakeManorElementary]: "Blake Manor Elementary",
  [ElementarySchool.BlancoVistaElementary]: "Blanco Vista Elementary",
  [ElementarySchool.BlantonElementarySchool]: "Blanton Elementary School",
  [ElementarySchool.BlattmanElementarySchool]: "Blattman Elementary School",
  [ElementarySchool.BlazierElementarySchool]: "Blazier Elementary School",
  [ElementarySchool.BlockHouseCreekElementarySchool]:
    "Block House Creek Elementary School",
  [ElementarySchool.BluebonnetElementary]: "Bluebonnet Elementary",
  [ElementarySchool.BluebonnetElementarySchool]: "Bluebonnet Elementary School",
  [ElementarySchool.BluebonnetTrainElementary]: "Bluebonnet Train Elementary",
  [ElementarySchool.BluebonnettElementary]: "Bluebonnett Elementary",
  [ElementarySchool.BoldtElementarySchool]: "Boldt Elementary School",
  [ElementarySchool.BookerTWashingtonELEM]: "Booker T. Washington ELEM",
  [ElementarySchool.BooneElementarySchool]: "Boone Elementary School",
  [ElementarySchool.BowieElementary]: "Bowie Elementary",
  [ElementarySchool.BrauchleElementarySchool]: "Brauchle Elementary School",
  [ElementarySchool.BraunStationElementarySchool]:
    "Braun Station Elementary School",
  [ElementarySchool.BrenhamElementarySchool]: "Brenham Elementary School",
  [ElementarySchool.BrentwoodElementarySchool]: "Brentwood Elementary School",
  [ElementarySchool.BridgePointElementary]: "Bridge Point Elementary",
  [ElementarySchool.BrookAvenue]: "Brook Avenue",
  [ElementarySchool.BrookeElementarySchool]: "Brooke Elementary School",
  [ElementarySchool.BrookhavenElementarySchool]: "Brookhaven Elementary School",
  [ElementarySchool.BrookhollowElementarySchool]:
    "Brookhollow Elementary School",
  [ElementarySchool.BrucevilleEddyElementary]: "Bruceville-Eddy Elementary",
  [ElementarySchool.BrushyCreekElementarySchool]:
    "Brushy Creek Elementary School",
  [ElementarySchool.BrykerWoodsElementarySchool]:
    "Bryker Woods Elementary School",
  [ElementarySchool.BudaElementary]: "Buda Elementary",
  [ElementarySchool.BurkeElementarySchool]: "Burke Elementary School",
  [ElementarySchool.CRClementsHollieParsons]: "C.R. Clements/Hollie Parsons",
  [ElementarySchool.CableElementarySchool]: "Cable Elementary School",
  [ElementarySchool.CactusRanchElementarySchool]:
    "Cactus Ranch Elementary School",
  [ElementarySchool.CaldwellElementarySchool]: "Caldwell Elementary School",
  [ElementarySchool.CaldwellHeightsElementarySchool]:
    "Caldwell Heights Elementary School",
  [ElementarySchool.CallisonElementarySchool]: "Callison Elementary School",
  [ElementarySchool.CamachoElementarySchool]: "Camacho Elementary School",
  [ElementarySchool.CaminoRealElementary]: "Camino Real Elementary",
  [ElementarySchool.CampbellElementarySchool]: "Campbell Elementary School",
  [ElementarySchool.CanyonCreekElementarySchool]:
    "Canyon Creek Elementary School",
  [ElementarySchool.CarawayElementarySchool]: "Caraway Elementary School",
  [ElementarySchool.CarlSchurzElementary]: "Carl Schurz Elementary",
  [ElementarySchool.CarlosCoonElementarySchool]:
    "Carlos Coon Elementary School",
  [ElementarySchool.CarnahanElementarySchool]: "Carnahan Elementary School",
  [ElementarySchool.CarpenterHillElementary]: "Carpenter Hill Elementary",
  [ElementarySchool.CarsonElementarySchool]: "Carson Elementary School",
  [ElementarySchool.CarverElementarySchool]: "Carver Elementary School",
  [ElementarySchool.CaseyElementarySchool]: "Casey Elementary School",
  [ElementarySchool.CasisElementarySchool]: "Casis Elementary School",
  [ElementarySchool.CastlemanCreekElementary]: "Castleman Creek Elementary",
  [ElementarySchool.CaterElementary]: "Cater Elementary",
  [ElementarySchool.CavazosElementarySchool]: "Cavazos Elementary School",
  [ElementarySchool.CedarCreekElementary]: "Cedar Creek Elementary",
  [ElementarySchool.CedarRidge]: "Cedar Ridge",
  [ElementarySchool.CedarValleyElementarySchool]:
    "Cedar Valley Elementary School",
  [ElementarySchool.ChandlerOaksElementarySchool]:
    "Chandler Oaks Elementary School",
  [ElementarySchool.CharterOakElementary]: "Charter Oak Elementary",
  [ElementarySchool.ChiltonSchool]: "Chilton School",
  [ElementarySchool.ChinaSpringElementarySchool]:
    "China Spring Elementary School",
  [ElementarySchool.ChisholmTrail]: "Chisholm Trail",
  [ElementarySchool.ChisholmTrailElementary]: "Chisholm Trail Elementary",
  [ElementarySchool.CiboloValleyElementary]: "Cibolo Valley Elementary",
  [ElementarySchool.ClarkeElementarySchool]: "Clarke Elementary School",
  [ElementarySchool.ClaytonElementarySchool]: "Clayton Elementary School",
  [ElementarySchool.ClearCreekElementarySchool]:
    "Clear Creek Elementary School",
  [ElementarySchool.ClearForkElementary]: "Clear Fork Elementary",
  [ElementarySchool.ClearSpringElementary]: "Clear Spring Elementary",
  [ElementarySchool.CliftonParkElementarySchool]:
    "Clifton Park Elementary School",
  [ElementarySchool.CodyElementarySchool]: "Cody Elementary School",
  [ElementarySchool.ColeElementarySchool]: "Cole Elementary School",
  [ElementarySchool.ColoniesNorthElementarySchool]:
    "Colonies North Elementary School",
  [ElementarySchool.ColtElementary]: "Colt Elementary",
  [ElementarySchool.ConnallyElementrySchool]: "Connally Elementry School",
  [ElementarySchool.CookElementarySchool]: "Cook Elementary School",
  [ElementarySchool.CooperElementarySchool]: "Cooper Elementary School",
  [ElementarySchool.CopperfieldElementarySchool]:
    "Copperfield Elementary School",
  [ElementarySchool.CottonwoodCreekElementarySchool]:
    "Cottonwood Creek Elementary School",
  [ElementarySchool.CountyLineElementary]: "County Line Elementary",
  [ElementarySchool.CouplandElementarySchool]: "Coupland Elementary School",
  [ElementarySchool.CowanElementarySchool]: "Cowan Elementary School",
  [ElementarySchool.CoxElementarySchool]: "Cox Elementary School",
  [ElementarySchool.CreedmoorElementary]: "Creedmoor Elementary",
  [ElementarySchool.Crestview]: "Crestview",
  [ElementarySchool.CrockettElementary]: "Crockett Elementary",
  [ElementarySchool.CunninghamElementarySchool]: "Cunningham Elementary School",
  [ElementarySchool.CypressElementarySchool]: "Cypress Elementary School",
  [ElementarySchool.CypressSpringsElementary]: "Cypress Springs Elementary",
  [ElementarySchool.DannaSpring]: "Danna Spring",
  [ElementarySchool.DavisElementarySchool]: "Davis Elementary School",
  [ElementarySchool.DawsonElementarySchool]: "Dawson Elementary School",
  [ElementarySchool.DeLeonElementary]: "De Leon Elementary",
  [ElementarySchool.DeZavalaElementary]: "De Zavala Elementary",
  [ElementarySchool.DeanHighlandElementary]: "Dean Highland Elementary",
  [ElementarySchool.DearingElementarySchool]: "Dearing Elementary School",
  [ElementarySchool.DeckerElementary]: "Decker Elementary",
  [ElementarySchool.DeepWoodElementarySchool]: "Deep Wood Elementary School",
  [ElementarySchool.DeerCreekElementarySchool]: "Deer Creek Elementary School",
  [ElementarySchool.DelValleElementary]: "Del Valle Elementary",
  [ElementarySchool.DelcoPrimarySchool]: "Delco Primary School",
  [ElementarySchool.DellCitySchool]: "Dell City School",
  [ElementarySchool.DessauElementarySchool]: "Dessau Elementary School",
  [ElementarySchool.DobiePrekindergartenCenter]: "Dobie Prekindergarten Center",
  [ElementarySchool.DossElementarySchool]: "Doss Elementary School",
  [ElementarySchool.DoubleFileTrailElementarySchool]:
    "Double File Trail Elementary School",
  [ElementarySchool.DriggersElementarySchool]: "Driggers Elementary School",
  [ElementarySchool.DrippingSpringsElementary]: "Dripping Springs Elementary",
  [ElementarySchool.DuncanElementarySchool]: "Duncan Elementary School",
  [ElementarySchool.EanesElementary]: "Eanes Elementary",
  [ElementarySchool.EastWardElementarySchool]: "East Ward Elementary School",
  [ElementarySchool.ElginElementarySchool]: "Elgin Elementary School",
  [ElementarySchool.EllisonElementarySchool]: "Ellison Elementary School",
  [ElementarySchool.ElmGroveElementary]: "Elm Grove Elementary",
  [ElementarySchool.ElrodElementarySchool]: "Elrod Elementary School",
  [ElementarySchool.EmileElementary]: "Emile Elementary",
  [ElementarySchool.EnglandElementarySchool]: "England Elementary School",
  [ElementarySchool.EsparzaElementarySchool]: "Esparza Elementary School",
  [ElementarySchool.EvantElementarySchool]: "Evant Elementary School",
  [ElementarySchool.EversElementarySchool]: "Evers Elementary School",
  [ElementarySchool.FairviewMissJewellElementary]:
    "Fairview/Miss Jewell Elementary",
  [ElementarySchool.FaubionElementarySchool]: "Faubion Elementary School",
  [ElementarySchool.FernBluffElementarySchool]: "Fern Bluff Elementary School",
  [ElementarySchool.FernandezElementarySchool]: "Fernandez Elementary School",
  [ElementarySchool.FieldsElementarySchool]: "Fields Elementary School",
  [ElementarySchool.FisherElementarySchool]: "Fisher Elementary School",
  [ElementarySchool.FlatoniaElementarySchool]: "Flatonia Elementary School",
  [ElementarySchool.FlorenceElementarySchool]: "Florence Elementary School",
  [ElementarySchool.FordElementarySchool]: "Ford Elementary School",
  [ElementarySchool.ForestCreekElementarySchool]:
    "Forest Creek Elementary School",
  [ElementarySchool.ForestNorthElementarySchool]:
    "Forest North Elementary School",
  [ElementarySchool.ForesterElementarySchool]: "Forester Elementary School",
  [ElementarySchool.ForrestTrailElementary]: "Forrest Trail Elementary",
  [ElementarySchool.FowlerElementarySchool]: "Fowler Elementary School",
  [ElementarySchool.FranklinElementarySchool]: "Franklin Elementary School",
  [ElementarySchool.FreiheitElementary]: "Freiheit Elementary",
  [ElementarySchool.FrostElementarySchool]: "Frost Elementary School",
  [ElementarySchool.FuentesElementary]: "Fuentes Elementary",
  [ElementarySchool.GalindoElementarySchool]: "Galindo Elementary School",
  [ElementarySchool.GalmElementarySchool]: "Galm Elementary School",
  [ElementarySchool.GarciaElementary]: "Garcia Elementary",
  [ElementarySchool.GardenRidgeElementary]: "Garden Ridge Elementary",
  [ElementarySchool.Gatesville]: "Gatesville",
  [ElementarySchool.GattisElementarySchool]: "Gattis Elementary School",
  [ElementarySchool.GeorgeWCarverEarlyEducation]:
    "George W. Carver Early Education",
  [ElementarySchool.GiddensElementarySchool]: "Giddens Elementary School",
  [ElementarySchool.GiddingsElementarySchool]: "Giddings Elementary School",
  [ElementarySchool.GlassElementarySchool]: "Glass Elementary School",
  [ElementarySchool.GlennElementarySchool]: "Glenn Elementary School",
  [ElementarySchool.GlenoaksElementarySchool]: "Glenoaks Elementary School",
  [ElementarySchool.GonzalesEastAvenuePrimarySchool]:
    "Gonzales East Avenue Primary School",
  [ElementarySchool.GonzalesElementary]: "Gonzales Elementary",
  [ElementarySchool.GoodwinFrazierElementary]: "Goodwin-Frazier Elementary",
  [ElementarySchool.GovalleElementarySchool]: "Govalle Elementary School",
  [ElementarySchool.GrahamElementarySchool]: "Graham Elementary School",
  [ElementarySchool.GrandviewHillsElementarySchool]:
    "Grandview Hills Elementary School",
  [ElementarySchool.GrangerElementarySchool]: "Granger Elementary School",
  [ElementarySchool.GreatOaksElementarySchool]: "Great Oaks Elementary School",
  [ElementarySchool.GreenElementary]: "Green Elementary",
  [ElementarySchool.GreenValleyElementary]: "Green Valley Elementary",
  [ElementarySchool.GuerreroThompsonElementarySchool]:
    "Guerrero Thompson Elementary School",
  [ElementarySchool.GullettElementarySchool]: "Gullett Elementary School",
  [ElementarySchool.HannaSprings]: "Hanna Springs",
  [ElementarySchool.HarkerHeightsElementarySchool]:
    "Harker Heights Elementary School",
  [ElementarySchool.HarrisElementarySchool]: "Harris Elementary School",
  [ElementarySchool.HartElementarySchool]: "Hart Elementary School",
  [ElementarySchool.HatchettElementarySchool]: "Hatchett Elementary School",
  [ElementarySchool.HayBranchElementarySchool]: "Hay Branch Elementary School",
  [ElementarySchool.HaynesElementarySchool]: "Haynes Elementary School",
  [ElementarySchool.HelotesElementarySchool]: "Helotes Elementary School",
  [ElementarySchool.HemphillElementary]: "Hemphill Elementary",
  [ElementarySchool.HendersonElementarySchool]: "Henderson Elementary School",
  [ElementarySchool.HernandezElementary]: "Hernandez Elementary",
  [ElementarySchool.HerringtonElementarySchool]: "Herrington Elementary School",
  [ElementarySchool.HettieHalesteadElementary]: "Hettie Halestead Elementary",
  [ElementarySchool.HewittElementarySchool]: "Hewitt Elementary School",
  [ElementarySchool.HighPointElementary]: "High Point Elementary",
  [ElementarySchool.HighlandLakesElementary]: "Highland Lakes Elementary",
  [ElementarySchool.HighlandParkElementarySchool]:
    "Highland Park Elementary School",
  [ElementarySchool.HillElementarySchool]: "Hill Elementary School",
  [ElementarySchool.HillcrestElementary]: "Hillcrest Elementary",
  [ElementarySchool.HillcrestPDSElementary]: "Hillcrest PDS Elementary",
  [ElementarySchool.HoffmanLaneElementary]: "Hoffman Lane Elementary",
  [ElementarySchool.HoffmannElementarySchool]: "Hoffmann Elementary School",
  [ElementarySchool.HollandElementarySchool]: "Holland Elementary School",
  [ElementarySchool.HornsbyDunlapElementary]: "Hornsby-Dunlap Elementary",
  [ElementarySchool.HouseCreekElementary]: "House Creek Elementary",
  [ElementarySchool.HoustonElementarySchool]: "Houston Elementary School",
  [ElementarySchool.HowardNormanElementarySchool]:
    "Howard Norman Elementary School",
  [ElementarySchool.HowsmanElementarySchool]: "Howsman Elementary School",
  [ElementarySchool.HuttoElementarySchool]: "Hutto Elementary School",
  [ElementarySchool.IdumaElementarySchool]: "Iduma Elementary School",
  [ElementarySchool.IgoElementary]: "Igo Elementary",
  [ElementarySchool.IndianSpringsElementary]: "Indian Springs Elementary",
  [ElementarySchool.IraCrossElementarySchool]: "Ira Cross Elementary School",
  [ElementarySchool.JHHinesElementary]: "J.H. Hines Elementary",
  [ElementarySchool.JacobsWell]: "Jacob's Well",
  [ElementarySchool.JarrellElementarySchool]: "Jarrell Elementary School",
  [ElementarySchool.JeffersonElementary]: "Jefferson Elementary",
  [ElementarySchool.JLWilliamsLovettLedgerElementary]:
    "JL Williams/Lovett Ledger Elementary",
  [ElementarySchool.JoeLeeJohnsonElementarySchool]:
    "Joe Lee Johnson Elementary School",
  [ElementarySchool.JohnASippelElementary]: "John A Sippel Elementary",
  [ElementarySchool.JohnBWinnElementarySchool]:
    "John B. Winn Elementary School",
  [ElementarySchool.JohnsonRanchElementary]: "Johnson Ranch Elementary ",
  [ElementarySchool.JollyvilleElementarySchool]: "Jollyville Elementary School",
  [ElementarySchool.JonesboroElementary]: "Jonesboro Elementary",
  [ElementarySchool.JosephGilbertElementarySchool]:
    "Joseph Gilbert Elementary School",
  [ElementarySchool.JoslinElementarySchool]: "Joslin Elementary School",
  [ElementarySchool.KallisonElementarySchool]: "Kallison Elementary School",
  [ElementarySchool.KendrickElementary]: "Kendrick Elementary",
  [ElementarySchool.KennedyPowellElementary]: "Kennedy-Powell Elementary",
  [ElementarySchool.KikerElementarySchool]: "Kiker Elementary School",
  [ElementarySchool.KilleenElementary]: "Killeen Elementary",
  [ElementarySchool.KinderRanchElementary]: "Kinder Ranch Elementary",
  [ElementarySchool.KleinRoadElementary]: "Klein Road Elementary",
  [ElementarySchool.KlineWhitis]: "Kline Whitis",
  [ElementarySchool.KnowlesElementarySchool]: "Knowles Elementary School",
  [ElementarySchool.KnowltonElementarySchool]: "Knowlton Elementary School",
  [ElementarySchool.KocurekElementarySchool]: "Kocurek Elementary School",
  [ElementarySchool.KoenneckeElementary]: "Koennecke Elementary",
  [ElementarySchool.KruegerElementarySchool]: "Krueger Elementary School",
  [ElementarySchool.KuentzElementarySchool]: "Kuentz Elementary School",
  [ElementarySchool.KyleElementary]: "Kyle Elementary",
  [ElementarySchool.LagoVistaElementarySchool]: "Lago Vista Elementary School",
  [ElementarySchool.LakeAirMontessori]: "Lake Air Montessori",
  [ElementarySchool.LakePointeElementary]: "Lake Pointe Elementary",
  [ElementarySchool.LakeTravisElementary]: "Lake Travis Elementary",
  [ElementarySchool.LakewayElementary]: "Lakeway Elementary",
  [ElementarySchool.LakewoodElementary]: "Lakewood Elementary",
  [ElementarySchool.LamarElementary]: "Lamar Elementary",
  [ElementarySchool.LangfordElementarySchool]: "Langford Elementary School",
  [ElementarySchool.LangleyElementarySchool]: "Langley Elementary School",
  [ElementarySchool.LarkspurElementary]: "Larkspur Elementary",
  [ElementarySchool.LauraWelchBushElementarySchool]:
    "Laura Welch Bush Elementary School",
  [ElementarySchool.LaurelMountainElementarySchool]:
    "Laurel Mountain Elementary School",
  [ElementarySchool.LaVegaElementary]: "LaVega Elementary",
  [ElementarySchool.LeakeyElementarySchool]: "Leakey Elementary School",
  [ElementarySchool.LeeElementarySchool]: "Lee Elementary School",
  [ElementarySchool.LeonHeightsElementary]: "Leon Heights Elementary",
  [ElementarySchool.LeonSpringsElementarySchool]:
    "Leon Springs Elementary School",
  [ElementarySchool.LeonValleyElementarySchool]:
    "Leon Valley Elementary School",
  [ElementarySchool.LeonardShanklinElementary]: "Leonard Shanklin Elementary",
  [ElementarySchool.LewisElementarySchool]: "Lewis Elementary School",
  [ElementarySchool.LexingtonElementarySchool]: "Lexington Elementary School",
  [ElementarySchool.LibertyHillElementarySchool]:
    "Liberty Hill Elementary  School",
  [ElementarySchool.LieckElementarySchool]: "Lieck Elementary School",
  [ElementarySchool.LinderElementarySchool]: "Linder Elementary School",
  [ElementarySchool.LintonElementarySchool]: "Linton Elementary School",
  [ElementarySchool.LiveOakElementarySchool]: "Live Oak Elementary School",
  [ElementarySchool.LlanoElmentary]: "Llano Elmentary",
  [ElementarySchool.LockeHillElementarySchool]: "Locke Hill Elementary School",
  [ElementarySchool.LometaElementary]: "Lometa Elementary",
  [ElementarySchool.LoneOakElementarySchool]: "Lone Oak Elementary School",
  [ElementarySchool.LoneStarElementary]: "Lone Star Elementary",
  [ElementarySchool.LorenaElementarySchool]: "Lorena Elementary School",
  [ElementarySchool.LosReyesElementarySchool]: "Los Reyes Elementary School",
  [ElementarySchool.LostPinesElementary]: "Lost Pines Elementary",
  [ElementarySchool.LottElementary]: "Lott Elementary",
  [ElementarySchool.LouineNoble]: "Louine Noble",
  [ElementarySchool.LucyReadPreKindergartenSchool]:
    "Lucy Read Pre-Kindergarten School",
  [ElementarySchool.LulingPrimary]: "Luling Primary",
  [ElementarySchool.LyndonBJohnsonES]: "Lyndon B. Johnson ES",
  [ElementarySchool.MaeStevens]: "Mae Stevens",
  [ElementarySchool.ManorElementary]: "Manor Elementary",
  [ElementarySchool.MaplewoodElementarySchool]: "Maplewood Elementary School",
  [ElementarySchool.MarbleFallsElementary]: "Marble Falls Elementary",
  [ElementarySchool.MartinElementarySchool]: "Martin Elementary School",
  [ElementarySchool.MartinWalkerElementary]: "Martin Walker Elementary",
  [ElementarySchool.MaryHullElementarySchool]: "Mary Hull Elementary School",
  [ElementarySchool.MasonElementarySchool]: "Mason Elementary School",
  [ElementarySchool.MastersElementary]: "Masters Elementary",
  [ElementarySchool.MathewsElementarySchool]: "Mathews Elementary School",
  [ElementarySchool.MaudeMooreWoodElementarySchool]:
    "Maude Moore Wood Elementary School",
  [ElementarySchool.MaxdaleElementarySchool]: "Maxdale  Elementary School",
  [ElementarySchool.MaxineLutrellWattsElementary]:
    "Maxine & Lutrell Watts Elementary",
  [ElementarySchool.May]: "May",
  [ElementarySchool.MayElementarySchool]: "May Elementary School",
  [ElementarySchool.MaysElementarySchool]: "Mays Elementary School",
  [ElementarySchool.McAndrewElementarySchool]: "McAndrew Elementary School",
  [ElementarySchool.McBayElementarySchool]: "McBay Elementary School",
  [ElementarySchool.McBeeElementarySchool]: "McBee Elementary School",
  [ElementarySchool.McCoyElementarySchool]: "McCoy Elementary School",
  [ElementarySchool.McDermottElementarySchool]: "McDermott Elementary School",
  [ElementarySchool.McGregorElementarySchool]: "McGregor Elementary School",
  [ElementarySchool.McQueeneyElementary]: "McQueeney Elementary",
  [ElementarySchool.MeadElementarySchool]: "Mead Elementary School",
  [ElementarySchool.MeadowVillageElementarySchool]:
    "Meadow Village Elementary School",
  [ElementarySchool.MeadowsElementarySchool]: "Meadows Elementary School",
  [ElementarySchool.MemorialElementary]: "Memorial Elementary",
  [ElementarySchool.MenchacaElementarySchool]: "Menchaca Elementary School",
  [ElementarySchool.MendezElementary]: "Mendez Elementary",
  [ElementarySchool.MeridithDunbar]: "Meridith-Dunbar",
  [ElementarySchool.MetroplexBHCElementarySchool]:
    "Metroplex BHC Elementary School",
  [ElementarySchool.MetzElementarySchool]: "Metz Elementary School",
  [ElementarySchool.MHSpechtElementary]: "MH Specht Elementary",
  [ElementarySchool.MichaelElementarySchool]: "Michael Elementary School",
  [ElementarySchool.MillerHeightsElementary]: "Miller Heights Elementary",
  [ElementarySchool.MillsElementarySchool]: "Mills Elementary School",
  [ElementarySchool.MinaElementary]: "Mina Elementary",
  [ElementarySchool.MirelesElementarySchool]: "Mireles Elementary School",
  [ElementarySchool.MissionValleyElementarySchool]:
    "Mission Valley Elementary School",
  [ElementarySchool.MitchellElementarySchool]: "Mitchell Elementary School",
  [ElementarySchool.MontagueElementarySchool]: "Montague Elementary School",
  [ElementarySchool.MoodyElementarySchool]: "Moody Elementary School",
  [ElementarySchool.MoraElementary]: "Mora Elementary",
  [ElementarySchool.MorningsideElementary]: "Morningside Elementary",
  [ElementarySchool.MottElementary]: "Mott Elementary",
  [ElementarySchool.MountainValleyElementary]: "Mountain Valley Elementary",
  [ElementarySchool.MountainViewElementary]: "Mountain View Elementary",
  [ElementarySchool.MountainviewElementary]: "Mountainview Elementary",
  [ElementarySchool.MurchisonElementarySchool]: "Murchison Elementary School",
  [ElementarySchool.MurninElementarySchool]: "Murnin Elementary School",
  [ElementarySchool.MyersElementarySchool]: "Myers Elementary School",
  [ElementarySchool.NadineJohnsonElementarySchool]:
    "Nadine Johnson Elementary School",
  [ElementarySchool.NaomiPademannElementarySchool]:
    "Naomi Pademann Elementary School",
  [ElementarySchool.NaumannElementarySchool]: "Naumann Elementary School",
  [ElementarySchool.NavarroElementary]: "Navarro Elementary",
  [ElementarySchool.NegleyElementary]: "Negley Elementary",
  [ElementarySchool.NeidigElementarySchool]: "Neidig Elementary School",
  [ElementarySchool.NicholsElementarySchool]: "Nichols Elementary School",
  [ElementarySchool.NixonSmileyElementary]: "Nixon Smiley Elementary",
  [ElementarySchool.NolanvilleElementarySchool]: "Nolanville Elementary School",
  [ElementarySchool.NormaJPaschalElementary]: "Norma J Paschal Elementary",
  [ElementarySchool.NormaKruegerElementary]: "Norma Krueger Elementary",
  [ElementarySchool.NormaKruegerBertKarrerCampus]:
    "Norma Krueger/Bert Karrer Campus",
  [ElementarySchool.NormanElementarySchool]: "Norman Elementary School",
  [ElementarySchool.NorthwestCrossingElementarySchool]:
    "Northwest Crossing Elementary School",
  [ElementarySchool.NorthwestElementarySchool]: "Northwest Elementary School",
  [ElementarySchool.OGWiedersteinElementary]: "O. G. Wiederstein Elementary",
  [ElementarySchool.OakCreek]: "Oak Creek",
  [ElementarySchool.OakCreekElementary]: "Oak Creek Elementary",
  [ElementarySchool.OakHillElementarySchool]: "Oak Hill Elementary School",
  [ElementarySchool.OakHillsTerraceElementarySchool]:
    "Oak Hills Terrace Elementary School",
  [ElementarySchool.OakMeadowsElementary]: "Oak Meadows Elementary",
  [ElementarySchool.OakSpringsElementarySchool]:
    "Oak Springs Elementary School",
  [ElementarySchool.OdomElementarySchool]: "Odom Elementary School",
  [ElementarySchool.OglesbyElementarySchool]: "Oglesby Elementary School",
  [ElementarySchool.OldTownElementarySchool]: "Old Town Elementary School",
  [ElementarySchool.OrtegaElementarySchool]: "Ortega Elementary School",
  [ElementarySchool.OttElementarySchool]: "Ott Elementary School",
  [ElementarySchool.OutofArea]: "Out of Area",
  [ElementarySchool.OvertonElementarySchool]: "Overton Elementary School",
  [ElementarySchool.OvetaCulpHobbyElementarySchool]:
    "Oveta Culp Hobby Elementary School",
  [ElementarySchool.PacksaddleElementary]: "Packsaddle Elementary",
  [ElementarySchool.PadrónElementarySchool]: "Padrón Elementary School",
  [ElementarySchool.PalmElementarySchool]: "Palm Elementary School",
  [ElementarySchool.ParkdaleElementary]: "Parkdale Elementary",
  [ElementarySchool.ParksideElementarySchool]: "Parkside Elementary School",
  [ElementarySchool.ParmerLaneElementarySchool]:
    "Parmer Lane Elementary School",
  [ElementarySchool.PassmoreElementarySchool]: "Passmore Elementary School",
  [ElementarySchool.PatCarneyElementary]: "Pat Carney Elementary",
  [ElementarySchool.PatlanElementary]: "Patlan Elementary",
  [ElementarySchool.PattonElementarySchool]: "Patton Elementary School",
  [ElementarySchool.PeaseElementarySchool]: "Pease Elementary School",
  [ElementarySchool.PecanSpringsElementarySchool]:
    "Pecan Springs Elementary School",
  [ElementarySchool.PeeblesElementarySchool]: "Peebles Elementary School",
  [ElementarySchool.PerezElementarySchool]: "Perez Elementary School",
  [ElementarySchool.PershingParkElementarySchool]:
    "Pershing Park Elementary School ",
  [ElementarySchool.PflugerElementary]: "Pfluger Elementary",
  [ElementarySchool.PflugervilleElementarySchool]:
    "Pflugerville Elementary School",
  [ElementarySchool.PickettElementarySchool]: "Pickett Elementary School",
  [ElementarySchool.PickleElementarySchool]: "Pickle Elementary School",
  [ElementarySchool.PillowElementarySchool]: "Pillow Elementary School",
  [ElementarySchool.PioneerCrossingElementary]: "Pioneer Crossing Elementary",
  [ElementarySchool.PirtleElementary]: "Pirtle Elementary",
  [ElementarySchool.PlainElementarySchool]: "Plain Elementary School",
  [ElementarySchool.PleasantHillElementarySchool]:
    "Pleasant Hill Elementary School",
  [ElementarySchool.PlumCreekElementary]: "Plum Creek Elementary",
  [ElementarySchool.PondSpringsElementarySchool]:
    "Pond Springs Elementary School",
  [ElementarySchool.PophamElementary]: "Popham Elementary",
  [ElementarySchool.PowellElementarySchool]: "Powell Elementary School",
  [ElementarySchool.PrairieLeaSchoolPK12]: "Prairie Lea School (PK-12)",
  [ElementarySchool.PresidentialMeadowsElementary]:
    "Presidential Meadows Elementary",
  [ElementarySchool.ProvidentHeightsElementary]: "Provident Heights Elementary",
  [ElementarySchool.PurlElementarySchool]: "Purl Elementary School",
  [ElementarySchool.PurpleSageElementarySchool]:
    "Purple Sage Elementary School",
  [ElementarySchool.RabaElementarySchool]: "Raba Elementary School",
  [ElementarySchool.RaheBulverdeElementarySchool]:
    "Rahe Bulverde Elementary School",
  [ElementarySchool.RanchoSiennaElementary]: "Rancho Sienna Elementary",
  [ElementarySchool.RayElementarySchool]: "Ray Elementary School",
  [ElementarySchool.RayeAllenElementary]: "Raye-Allen Elementary",
  [ElementarySchool.ReaganElementarySchool]: "Reagan Elementary School",
  [ElementarySchool.RebeccaCreek]: "Rebecca Creek",
  [ElementarySchool.ReecesCreekElementarySchool]:
    "Reeces Creek Elementary School",
  [ElementarySchool.ReedElementarySchool]: "Reed Elementary School",
  [ElementarySchool.ReillyElementarySchool]: "Reilly Elementary School",
  [ElementarySchool.RhodesElementarySchool]: "Rhodes Elementary School",
  [ElementarySchool.RidgetopElementarySchool]: "Ridgetop Elementary School",
  [ElementarySchool.RiojasElementarySchool]: "Riojas Elementary School",
  [ElementarySchool.RiverOaksElementarySchool]: "River Oaks Elementary School",
  [ElementarySchool.RiverPlaceElementarySchool]:
    "River Place Elementary School",
  [ElementarySchool.RiverRidgeElementarySchool]:
    "River Ridge Elementary School",
  [ElementarySchool.RJRichyElementarySchool]: "RJ Richy Elementary School",
  [ElementarySchool.RobertLeeElem]: "Robert Lee Elem",
  [ElementarySchool.RobertsonElementarySchool]: "Robertson Elementary School",
  [ElementarySchool.RobinsonElementary]: "Robinson Elementary",
  [ElementarySchool.RockdaleElementarySchool]: "Rockdale Elementary School",
  [ElementarySchool.RodriguezElementary]: "Rodriguez Elementary",
  [ElementarySchool.RodriguezElementarySch]: "Rodriguez Elementary Sch",
  [ElementarySchool.RodriguezElementarySchool]: "Rodriguez Elementary School",
  [ElementarySchool.RogersElementarySchool]: "Rogers Elementary School",
  [ElementarySchool.RoosterSpringsElementary]: "Rooster Springs Elementary",
  [ElementarySchool.RoseGardenElementary]: "Rose Garden Elementary",
  [ElementarySchool.RosenwaldPrimary]: "Rosenwald Primary",
  [ElementarySchool.RoweLaneElementarySchool]: "Rowe Lane Elementary School",
  [ElementarySchool.RutledgeElementarySchool]: "Rutledge Elementary School",
  [ElementarySchool.SaegertElementarySchool]: "Saegert Elementary School",
  [ElementarySchool.SalinasElementarySchool]: "Salinas Elementary School",
  [ElementarySchool.SanchezElementarySchool]: "Sanchez Elementary School",
  [ElementarySchool.SantaRitaElementarySchool]: "Santa Rita Elementary School",
  [ElementarySchool.ScarboroughElementarySchool]:
    "Scarborough Elementary School",
  [ElementarySchool.SchertzElementary]: "Schertz Elementary",
  [ElementarySchool.ScienceHallElementary]: "Science Hall Elementary",
  [ElementarySchool.ScobeeElementarySchool]: "Scobee Elementary School",
  [ElementarySchool.ScottElementary]: "Scott Elementary",
  [ElementarySchool.ScudderPrimary]: "Scudder Primary",
  [ElementarySchool.SeeleElementary]: "Seele Elementary",
  [ElementarySchool.SereneHillsElementary]: "Serene Hills Elementary",
  [ElementarySchool.ShadowGlenElementary]: "Shadow Glen Elementary",
  [ElementarySchool.ShadyGroveElementarySchool]:
    "Shady Grove Elementary School",
  [ElementarySchool.SiebertElementarySchool]: "Siebert Elementary School",
  [ElementarySchool.SimmsElementarySchool]: "Simms Elementary School",
  [ElementarySchool.SimsElementarySchool]: "Sims Elementary School",
  [ElementarySchool.SkipchaElementarySchool]: "Skipcha Elementary School",
  [ElementarySchool.SmithElementary]: "Smith Elementary",
  [ElementarySchool.SmithvilleElementary]: "Smithville Elementary",
  [ElementarySchool.SmithvilleElementarySchool]: "Smithville Elementary School",
  [ElementarySchool.SomervilleElementarySchool]: "Somerville Elementary School",
  [ElementarySchool.SommerElementarySchool]: "Sommer Elementary School",
  [ElementarySchool.SouthWacoElementary]: "South Waco Elementary",
  [ElementarySchool.SouthwestElementary]: "Southwest Elementary",
  [ElementarySchool.SpartaElementary]: "Sparta Elementary",
  [ElementarySchool.SpicewoodElementary]: "Spicewood Elementary",
  [ElementarySchool.SpicewoodElementarySchool]: "Spicewood Elementary School",
  [ElementarySchool.SpringHillElementarySchool]:
    "Spring Hill Elementary School",
  [ElementarySchool.StElmoElementarySchool]: "St. Elmo Elementary School",
  [ElementarySchool.Startzville]: "Startzville",
  [ElementarySchool.SteinerRanchElementarySchool]:
    "Steiner Ranch Elementary School",
  [ElementarySchool.SteubingElementarySchool]: "Steubing Elementary School",
  [ElementarySchool.SugarLoafElementarySchool]: "Sugar Loaf Elementary School",
  [ElementarySchool.SummittElementarySchool]: "Summitt Elementary School",
  [ElementarySchool.SunfieldElementary]: "Sunfield Elementary",
  [ElementarySchool.SunsetValleyElementarySchool]:
    "Sunset Valley Elementary School",
  [ElementarySchool.TABrownElementarySchool]: "T. A. Brown Elementary School",
  [ElementarySchool.TarverElementary]: "Tarver Elementary",
  [ElementarySchool.TaylorCreek]: "Taylor Creek",
  [ElementarySchool.TeravistaElementarySchool]: "Teravista Elementary School",
  [ElementarySchool.ThomasArnoldElementary]: "Thomas Arnold Elementary",
  [ElementarySchool.ThorndaleElementarySchool]: "Thorndale Elementary School",
  [ElementarySchool.ThorntonElementary]: "Thornton Elementary",
  [ElementarySchool.ThorntonElementarySchool]: "Thornton Elementary School",
  [ElementarySchool.ThrallElementarySchool]: "Thrall Elementary School",
  [ElementarySchool.TimberRidgeElementarySchool]:
    "Timber Ridge Elementary School",
  [ElementarySchool.TimberwildeElementarySchool]:
    "Timberwilde Elementary School",
  [ElementarySchool.TimberwoodParkElementary]: "Timberwood Park Elementary",
  [ElementarySchool.TimmermanElementarySchool]: "Timmerman Elementary School",
  [ElementarySchool.TobiasElementary]: "Tobias Elementary",
  [ElementarySchool.TravisElementary]: "Travis Elementary",
  [ElementarySchool.TravisHeightsElementarySchool]:
    "Travis Heights Elementary School",
  [ElementarySchool.TrimmierElementarySchool]: "Trimmier Elementary School",
  [ElementarySchool.TroyElementary]: "Troy Elementary",
  [ElementarySchool.Tyler]: "Tyler",
  [ElementarySchool.UnionHillElementarySchool]: "Union Hill Elementary School",
  [ElementarySchool.UphausEarlyChildhoodCenter]:
    "Uphaus Early Childhood Center",
  [ElementarySchool.ValleyHiElementarySchool]: "Valley Hi Elementary School",
  [ElementarySchool.ValleyViewElementary]: "Valley View Elementary",
  [ElementarySchool.VenableVillageElementarySchool]:
    "Venable Village Elementary School",
  [ElementarySchool.VeramendiElementarySchool]: "Veramendi Elementary School",
  [ElementarySchool.VeteransHillElementarySchool]:
    "Veteran's Hill Elementary School",
  [ElementarySchool.VillageElementarySchool]: "Village Elementary School",
  [ElementarySchool.VillarrealElementarySchool]: "Villarreal Elementary School",
  [ElementarySchool.VogelElementary]: "Vogel Elementary",
  [ElementarySchool.VoigtElementarySchool]: "Voigt Elementary School",
  [ElementarySchool.VossFarmsElementary]: "Voss Farms Elementary",
  [ElementarySchool.WaelderSchoolPK12]: "Waelder School (PK-12)",
  [ElementarySchool.WalnutCreekElementarySchool]:
    "Walnut Creek Elementary School",
  [ElementarySchool.WalnutSprings]: "Walnut Springs",
  [ElementarySchool.WalnutSpringsElementary]: "Walnut Springs Elementary",
  [ElementarySchool.WankeElementarySchool]: "Wanke Elementary School",
  [ElementarySchool.WardElementarySchool]: "Ward Elementary School",
  [ElementarySchool.WebbPrimarySchool]: "Webb Primary School",
  [ElementarySchool.WeinertElementary]: "Weinert Elementary",
  [ElementarySchool.WellsBranchElementarySchool]:
    "Wells Branch Elementary School",
  [ElementarySchool.WestAvenueElementary]: "West Avenue Elementary",
  [ElementarySchool.WestCypressHillsElementary]:
    "West Cypress Hills Elementary",
  [ElementarySchool.WestWardElementarySchool]: "West Ward Elementary School",
  [ElementarySchool.WesternHillsElementary]: "Western Hills Elementary",
  [ElementarySchool.WestsideElementarySchool]: "Westside Elementary School",
  [ElementarySchool.WestwoodTerraceElementarySchool]:
    "Westwood Terrace Elementary School",
  [ElementarySchool.Whillis]: "Whillis",
  [ElementarySchool.WhitestoneElementarySchool]: "Whitestone Elementary School",
  [ElementarySchool.WhitneyElementarySchool]: "Whitney Elementary School",
  [ElementarySchool.WidénElementarySchool]: "Widén Elementary School",
  [ElementarySchool.WielandElementarySchool]: "Wieland Elementary School",
  [ElementarySchool.WilliamsElementarySchool]: "Williams Elementary School",
  [ElementarySchool.WillowSpringsElementarySchool]:
    "Willow Springs Elementary School",
  [ElementarySchool.WindermereElementarySchool]: "Windermere Elementary School",
  [ElementarySchool.WindermerePrimarySchool]: "Windermere Primary School",
  [ElementarySchool.WinkleyElementarySchool]: "Winkley Elementary School",
  [ElementarySchool.WolfRanchElementarySchool]: "Wolf Ranch Elementary School",
  [ElementarySchool.WoodwayElementarySchool]: "Woodway Elementary School",
  [ElementarySchool.WooldridgeElementarySchool]: "Wooldridge Elementary School",
  [ElementarySchool.WootenElementarySchool]: "Wooten Elementary School",
  [ElementarySchool.ZavalaElementarySchool]: "Zavala Elementary School",
  [ElementarySchool.ZilkerElementarySchool]: "Zilker Elementary School"
};
