import { FormRules } from "@homesusa/form";
import { agentBonusRules } from "core/rules/agent-bonus-rules";
import { financialRules } from "../../sections";

export const financialInfoRules: FormRules = {
  ...agentBonusRules,
  ...financialRules,
  taxYear: {
    label: "Certified Tax Year",
    maxLength: 4,
    requiredToSubmit: true
  }
};
