export enum SoldTerms {
  AssumptionOfEquity = "assumptionOfEquity",
  Cash = "cash",
  Conventional = "conventional",
  FHA = "fha",
  Other = "other",
  SellerFinancing = "sellerFinancing",
  USDA = "usda",
  VA = "va"
}

export const SoldTermsLabel = new Map<SoldTerms, string>([
  [SoldTerms.AssumptionOfEquity, "Assumption"],
  [SoldTerms.Cash, "Cash"],
  [SoldTerms.Conventional, "Conv"],
  [SoldTerms.FHA, "FHA"],
  [SoldTerms.Other, "Other"],
  [SoldTerms.SellerFinancing, "Seller Financing"],
  [SoldTerms.USDA, "USDA"],
  [SoldTerms.VA, "VA"]
]);
