export enum RoofDescription {
  FiberCement = "fiberCement",
  Flat = "flat",
  Overlay = "overlay",
  ShingleComposition = "shingleComposition",
  SlateImitationSlate = "slateImitationSlate",
  Tile = "tile"
}

export const RoofDescriptionLabel = new Map<RoofDescription, string>([
  [RoofDescription.FiberCement, "Fiber Cement"],
  [RoofDescription.Flat, "Flat"],
  [RoofDescription.Overlay, "Overlay"],
  [RoofDescription.ShingleComposition, "Shingle-Composition"],
  [RoofDescription.SlateImitationSlate, "Slate/Imitation Slate"],
  [RoofDescription.Tile, "Tile"]
]);
