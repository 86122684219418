export enum InteriorFeatures {
  BuiltInWineCooler = "builtInWineCooler",
  CableTVAvailable = "cableTVAvailable",
  CedarClosets = "cedarClosets",
  DecorativeLighting = "decorativeLighting",
  DoubleVanity = "doubleVanity",
  DryBar = "dryBar",
  EatInKitchen = "eatInKitchen",
  Elevator = "elevator",
  FlatScreenWiring = "flatScreenWiring",
  GraniteCounters = "graniteCounters",
  HighSpeedInternetAvailable = "highSpeedInternetAvailable",
  KitchenIsland = "kitchenIsland",
  Loft = "loft",
  MultipleStaircases = "multipleStaircases",
  OpenFloorplan = "openFloorplan",
  Other = "other",
  Pantry = "pantry",
  SmartHomeSystem = "smartHomeSystem",
  SoundSystemWiring = "soundSystemWiring",
  TileCounters = "tileCounters",
  VaultedCeilings = "vaultedCeilings",
  Wainscoting = "wainscoting",
  WalkInClosets = "walkInClosets",
  WetBar = "wetBar",
  WiredForData = "wiredForData"
}

export const InteriorFeaturesLabel = new Map<InteriorFeatures, string>([
  [InteriorFeatures.BuiltInWineCooler, "Built-in Wine Cooler"],
  [InteriorFeatures.CableTVAvailable, "Cable TV Available"],
  [InteriorFeatures.CedarClosets, "Cedar Closet(s)"],
  [InteriorFeatures.DecorativeLighting, "Decorative Lighting"],
  [InteriorFeatures.DoubleVanity, "Double Vanity"],
  [InteriorFeatures.DryBar, "Dry Bar"],
  [InteriorFeatures.EatInKitchen, "Eat-in Kitchen"],
  [InteriorFeatures.Elevator, "Elevator"],
  [InteriorFeatures.FlatScreenWiring, "Flat Screen Wiring"],
  [InteriorFeatures.GraniteCounters, "Granite Counters"],
  [
    InteriorFeatures.HighSpeedInternetAvailable,
    "High Speed Internet Available"
  ],
  [InteriorFeatures.KitchenIsland, "Kitchen Island"],
  [InteriorFeatures.Loft, "Loft"],
  [InteriorFeatures.MultipleStaircases, "Multiple Staircases"],
  [InteriorFeatures.OpenFloorplan, "Open Floorplan"],
  [InteriorFeatures.Other, "Other"],
  [InteriorFeatures.Pantry, "Pantry"],
  [InteriorFeatures.SmartHomeSystem, "Smart Home System"],
  [InteriorFeatures.SoundSystemWiring, "Sound System Wiring"],
  [InteriorFeatures.TileCounters, "Tile Counters"],
  [InteriorFeatures.VaultedCeilings, "Vaulted Ceiling(s)"],
  [InteriorFeatures.Wainscoting, "Wainscoting"],
  [InteriorFeatures.WalkInClosets, "Walk-In Closet(s)"],
  [InteriorFeatures.WetBar, "Wet Bar"],
  [InteriorFeatures.WiredForData, "Wired for Data"]
]);
