import { getOptionsFromEnumAndRecord } from "@homesusa/core";
import { FieldRulesProps, FormRules } from "@homesusa/form";
import {
  BillingFrequency,
  BillingFrequencyLabel,
  HoaName,
  HoaNameLabel,
  HoaWebsite,
  HoaWebsiteLabel
} from "markets/amarillo/enums";

const moneyRule = (label: string): FieldRulesProps => ({
  label,
  numberFormat: {
    prefix: "$",
    thousandSeparator: true
  }
});

export const buyersAgentCommissionRules: FormRules = {
  buyersAgentCommission: {
    label: "Buyer Agency Compensation",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 2
    }
  },
  readableBuyersAgentCommission: { label: "Buyer Agency Compensation" },
  buyersAgentCommissionType: { label: "Buyer Agency Compensation Type" }
};

export const financialRules: FormRules = {
  hoaRequirement: {
    label: "HOA Requirement"
  },
  hoaName: {
    label: "HOA Name",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndRecord(Object.values(HoaName), HoaNameLabel)
  },
  hoaContactName: {
    label: "Contact Name",
    maxLength: 50
  },
  hoaContactPhone: {
    label: "Contact Phone",
    regex: "^\\d{10}$",
    regexMessage: "invalid phone"
  },
  hoaWebsite: {
    label: "HOA Website",
    options: getOptionsFromEnumAndRecord(
      Object.values(HoaWebsite),
      HoaWebsiteLabel
    )
  },
  hoaTransferFee: moneyRule("Association Fee"),
  hoaAssociationFee: moneyRule("Transfer Fee"),
  billingFrequency: {
    label: "Association Frequency",
    options: getOptionsFromEnumAndRecord(
      Object.values(BillingFrequency),
      BillingFrequencyLabel
    )
  },
  membershipApprovalRequest: { label: "Membership Approval Required" },
  otherFees: moneyRule("Other Fees")
};
