import { FormRules, FieldRulesProps } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  Stories,
  StoriesLabel,
  GarageDescriptionLabel,
  GarageDescription
} from "markets/sabor/enums";

const roomNumberRule = (label: string): FieldRulesProps => ({
  label,
  requiredToSubmit: true,
  maxLength: 1,
  numberFormat: {
    min: 0
  }
});

export const spacesDimensionsRules: FormRules = {
  bathsFull: roomNumberRule("# Full Baths"),
  bathsHalf: roomNumberRule("# Half Baths"),
  numBedrooms: roomNumberRule("# Bedrooms"),
  garageDescription: {
    label: "Garage Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(GarageDescription),
      GarageDescriptionLabel
    ),
    noneOption: [GarageDescription.NotApplicable],
    rows: 2
  },
  stories: {
    requiredToSubmit: true,
    label: "# Stories",
    options: getOptionsFromEnumAndMap(Object.values(Stories), StoriesLabel)
  }
};
