import { FormRules, FieldRulesProps } from "@homesusa/form";
import { schoolsRules } from "../sections";
import {
  showingInfoRules,
  featuresInfoRules,
  propertyInfoRules,
  addressInfoRules,
  financialInfoRules,
  spacesDimensionsInfoRules
} from "./sale-properties";

const salePropertyInfoRules: FormRules = {
  ownerName: {
    label: "Owner Name",
    disabled: true
  }
};

export const salePropertyRules: Record<string, FormRules | FieldRulesProps> = {
  addressInfo: addressInfoRules,
  showingInfo: showingInfoRules,
  propertyInfo: propertyInfoRules,
  featuresInfo: featuresInfoRules,
  financialInfo: financialInfoRules,
  schoolsInfo: schoolsRules,
  spacesDimensionsInfo: spacesDimensionsInfoRules,
  salePropertyInfo: salePropertyInfoRules
};
