import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Report } from "../enums";
import { ReportsOutlet } from "../components/reports-outlet.component";
import {
  ListingAgentsReport,
  SellingAgentsReport,
  ActivePendingListingsReport,
  NewHomesReport,
  RealtorOfficesReport,
  CmaListingsReport,
  BillingReport,
  InternalSellingAgentsReport
} from "../components";

const ComparisonReport = React.lazy(
  () => import("modules/reports/pages/comparison-report.component")
);
const DiscrepancyReport = React.lazy(
  () => import("modules/reports/pages/discrepancy-report.component")
);

export function ReportRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="comparison" element={<ComparisonReport />} />
      <Route path="discrepancy" element={<DiscrepancyReport />} />
      <Route element={<ReportsOutlet />}>
        <Route path={Report.CmaListings} element={<CmaListingsReport />} />
        <Route path={Report.ListingAgents} element={<ListingAgentsReport />} />
        <Route path={Report.SellingAgents} element={<SellingAgentsReport />} />
        <Route
          path={Report.SellingAgentsInternal}
          element={<InternalSellingAgentsReport />}
        />
        <Route
          path={Report.ActivePendingListings}
          element={<ActivePendingListingsReport />}
        />
        <Route path={Report.NewHomes} element={<NewHomesReport />} />
        <Route
          path={Report.RealtorOffices}
          element={<RealtorOfficesReport />}
        />
        <Route path={Report.BillingReport} element={<BillingReport />} />
        <Route
          index
          element={<Navigate to={`${Report.ActivePendingListings}`} />}
        />
      </Route>
    </Routes>
  );
}
