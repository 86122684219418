export enum BillingFrequency {
  Annually = "annually",
  Monthly = "monthly",
  Quarterly = "quarterly"
}
export const BillingFrequencyLabel = new Map<BillingFrequency, string>([
  [BillingFrequency.Annually, "Annually"],
  [BillingFrequency.Monthly, "Monthly"],
  [BillingFrequency.Quarterly, "Quarterly"]
]);
