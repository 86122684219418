export enum GreenWaterFeatures {
  EfficientHotWaterDistribution = "efficientHotWaterDistribution",
  GrayWaterSystem = "grayWaterSystem",
  GreenInfrastructure = "greenInfrastructure",
  LowFlowFixtures = "lowFlowFixtures",
  WaterRecycling = "waterRecycling",
  WaterSmartLandscaping = "waterSmartLandscaping"
}

export const GreenWaterFeaturesLabel = new Map<GreenWaterFeatures, string>([
  [
    GreenWaterFeatures.EfficientHotWaterDistribution,
    "Efficient Hot Water Distribution"
  ],
  [GreenWaterFeatures.GrayWaterSystem, "Gray Water System"],
  [GreenWaterFeatures.GreenInfrastructure, "Green Infrastructure"],
  [GreenWaterFeatures.LowFlowFixtures, "Low-Flow Fixtures"],
  [GreenWaterFeatures.WaterRecycling, "Water Recycling"],
  [GreenWaterFeatures.WaterSmartLandscaping, "Water-Smart Landscaping"]
]);
