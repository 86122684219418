export enum HeatSystem {
  ActiveSolar = "activeSolar",
  Central = "central",
  Electric = "electric",
  EnergyStareEquipment = "energyStareEquipment",
  EnergyStarInstallation = "energyStarInstallation",
  FireplaceInsert = "fireplaceInsert",
  Fireplaces = "fireplaces",
  GasJets = "gasJets",
  Geothermal = "geothermal",
  HeatPump = "heatPump",
  HumidityControl = "humidityControl",
  NaturalGas = "naturalGas",
  Oil = "oil",
  PassiveSolar = "passiveSolar",
  Propane = "propane",
  Solar = "solar",
  Zoned = "zoned",
  Other = "other",
  None = "none"
}

export const HeatSystemLabel = new Map<HeatSystem, string>([
  [HeatSystem.ActiveSolar, "Active Solar"],
  [HeatSystem.Central, "Central"],
  [HeatSystem.Electric, "Electric"],
  [HeatSystem.EnergyStareEquipment, "ENERGY STAR Equipment"],
  [HeatSystem.EnergyStarInstallation, "ENERGY STAR Installation"],
  [HeatSystem.FireplaceInsert, "Fireplace Insert"],
  [HeatSystem.Fireplaces, "Fireplace(s)"],
  [HeatSystem.GasJets, "Gas Jets"],
  [HeatSystem.Geothermal, "Geothermal"],
  [HeatSystem.HeatPump, "Heat Pump"],
  [HeatSystem.HumidityControl, "Humidity Control"],
  [HeatSystem.NaturalGas, "Natural Gas"],
  [HeatSystem.Oil, "Oil"],
  [HeatSystem.PassiveSolar, "Passive Solar"],
  [HeatSystem.Propane, "Propane"],
  [HeatSystem.Solar, "Solar"],
  [HeatSystem.Zoned, "Zoned"],
  [HeatSystem.Other, "Other"],
  [HeatSystem.None, "None"]
]);
