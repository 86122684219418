import React from "react";
import { AuthCompanyContext } from "@homesusa/auth";
import { Format } from "@homesusa/core";
import {
  GridProvider,
  GridTable,
  GridToolbar,
  Columns,
  GridResponse,
  FetchData
} from "@homesusa/grid-table";
import { getOptionValueFromRule } from "@homesusa/form";

import { DiscrepancyDetail } from "core/interfaces";

import { ReportsContext } from "../contexts";
import { getDiscrepancyAnalysisDetail } from "core/services/discrepancy.service";

export const DiscrepancyReportGrid: React.FC = () => {
  const { marketRules } = React.useContext(ReportsContext);
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const [data, setData] = React.useState<GridResponse<DiscrepancyDetail>>({
    data: [],
    total: 0
  });

  const columns: Columns<DiscrepancyDetail> = React.useMemo(() => {
    return [
      {
        Header: "DOM",
        accessor: "dom",
        disableTooltipCell: true
      },
      {
        Header: "MLS #",
        accessor: ({ mlsNumber }): React.ReactElement => (
          <span className="mlsNumber">{mlsNumber}</span>
        )
      },
      {
        Header: "MLS Status",
        accessor: ({ mlsStatus }): string =>
          getOptionValueFromRule(marketRules.mlsStatus, mlsStatus),
        disableTooltipCell: true
      },
      {
        Header: "Community",
        accessor: "community",
        disableTooltipCell: true
      },
      {
        Header: "Address",
        accessor: (row): string => `${row.streetNumber} ${row.streetName}`,
        disableTooltipCell: true
      },
      {
        Header: "City",
        accessor: ({ city }): string =>
          getOptionValueFromRule(marketRules.city, city),
        disableTooltipCell: true
      },
      {
        Header: "List Date",
        accessor: ({ listDate }): string => Format.Date(listDate),
        disableTooltipCell: true
      },
      {
        Header: "MLS Price",
        accessor: ({ mlsPrice }): string => Format.Money(mlsPrice),
        disableTooltipCell: true
      },
      {
        Header: "Price Variance",
        accessor: ({ priceVariance }): string => Format.Money(priceVariance),
        disableTooltipCell: true
      },
      {
        Header: "XML Price",
        accessor: ({ xmlPrice }): string => Format.Money(xmlPrice),
        disableTooltipCell: true
      }
    ];
  }, []);

  const fetchData = React.useCallback(
    async ({ pageIndex, pageSize }: FetchData): Promise<void> =>
      getDiscrepancyAnalysisDetail(currentMarket, {
        isDetail: true,
        skip: pageIndex,
        top: pageSize
      }).then(setData),
    []
  );

  return (
    <GridProvider
      options={{
        columns,
        data: data?.data,
        showCellTooltip: true,
        disableSortBy: true
      }}
      fetchData={fetchData}
      totalRows={data?.total}
    >
      <GridToolbar title="Discrepancy Report" />
      <GridTable
        sx={{
          "& .mlsNumber": {
            overflow: "hidden",
            display: "inline-block",
            maxWidth: "15ch",
            textOverflow: "ellipsis"
          }
        }}
      />
    </GridProvider>
  );
};
