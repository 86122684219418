export enum HoaName {
  sevenHundredAndFiveDowntownCondoAssn = "sevenHundredAndFiveDowntownCondoAssn",
  AmarilloCitadelHomeOwnersAssnInc = "amarilloCitadelHomeOwnersAssnInc",
  BeaconPointeMasterAssociation = "beaconPointeMasterAssociation",
  BrennanCourtSleepyHollowTownhomeAssn = "brennanCourtSleepyHollowTownhomeAssn",
  BrookplaceOneHomeownersAssnCondos = "brookplaceOneHomeownersAssnCondos",
  BrownAcresHOALLC = "brownAcresHOALLC",
  BryanPlaceHomeownersAssociation = "bryanPlaceHomeownersAssociation",
  CallahanAcresHomeownersAssociation = "callahanAcresHomeownersAssociation",
  CanalStreetFlagpoleAssn = "canalStreetFlagpoleAssn",
  CanyonNorth = "canyonNorth",
  CanyonNorthPropertyOwnersAssn = "canyonNorthPropertyOwnersAssn",
  CanyonRidge = "canyonRidge",
  ChaumontPampa = "chaumontPampa",
  ConnerTownhomesAssn = "connerTownhomesAssn",
  CottonwoodGlen = "cottonwoodGlen",
  CottonwoodTownhomes = "cottonwoodTownhomes",
  CreightonParkHomeownersAssnInc = "creightonParkHomeownersAssnInc",
  DeerRunAddition = "deerrunAddition",
  DoubleDiamondEstates = "doubleDiamondEstates",
  EagleTreeHomeownersAssnInc = "eagleTreeHomeownersAssnInc",
  ElkCanyon = "elkCanyon",
  FrenchVillageHOA = "frenchVillageHOA",
  GoldenPondOfficeCondos = "goldenPondOfficeCondos",
  GreenwaysHomeownersAssn = "greenwaysHomeownersAssn",
  GreenwoodHomeownersAssn = "greenwoodHomeownersAssn",
  HeritageHillsHomeownersAssociation = "heritageHillsHomeownersAssociation",
  HighCountryEstates = "highCountryEstates",
  IndianBluffofAmarilloUnit8 = "indianBluffofAmarilloUnit8",
  InvernessHoustonPOAInc = "invernessHoustonPOAInc",
  LaPalomaEstatesUnit3TheReserveatLaPaloma = "laPalomaEstatesUnit3TheReserveatLaPaloma",
  LaPalomaEstatesUnit6 = "laPalomaEstatesUnit6",
  LaPalomaEstatesUnit7 = "laPalomaEstatesUnit7",
  LaPalomaEstatesUnit9TheEnclave = "laPalomaEstatesUnit9TheEnclave",
  LaPalomaEstatesUnits14ONLY = "laPalomaEstatesUnits14ONLY",
  LaTourCondominiums = "laTourCondominiums",
  LakeMeredithHarborOwnersAssociation = "lakeMeredithHarborOwnersAssociation",
  LakeTanglewoodHomeowners = "lakeTanglewoodHomeowners",
  LesMaisons = "lesMaisons",
  LoneStarEstates = "loneStarEstates",
  LynnwoodPlaceTownHomes = "lynnwoodPlaceTownHomes",
  MesquiteRidgeMasterAssociation = "mesquiteRidgeMasterAssociation",
  NorthRidgeatLakeAlanHenry = "northRidgeatLakeAlanHenry",
  OlsenCircleHOA = "olsenCircleHOA",
  OlsenPlazaOfficeParkOwnersAssnInc = "olsenPlazaOfficeParkOwnersAssnInc",
  Other = "other",
  PaloDuroCanyonEstates = "paloDuroCanyonEstates",
  PinecrestGardensatTascosaChampionship = "pinecrestGardensatTascosaChampionship",
  Pinnacle = "pinnacle",
  PolkCondominiums = "polkCondominiums",
  QuadrilleBusinessParkOwnersAssnInc = "quadrilleBusinessParkownersAssninc",
  QuadrilleHomeownersAssn = "quadrilleHomeownersAssn",
  QuadrilleParkTownhouseOwnersAssn = "quadrilleParkTownhouseOwnersAssn",
  RebaVerdeHomeownersAssnInc = "rebaVerdeHomeownersAssnInc",
  RiverFalls = "riverFalls",
  SandSpurHOA = "sandSpurHOA",
  SandhillsHomeownersAssn = "sandhillsHomeownersAssn",
  ShoresHomeownersAssnInc = "shoresHomeownersAssnInc",
  SpringCanyonMasterAssociation = "springCanyonMasterAssociation",
  SpringLakeOwnersAssnInc = "springLakeOwnersAssnInc",
  SteeplechaseAssn = "steeplechaseAssn",
  SundayCanyonPropertyOwnersAssnInc = "sundayCanyonPropertyOwnersAssnInc",
  TascosaCountryClubEstates = "tascosaCountryClubEstates",
  TealwoodLakesResidentAssnInc = "tealwoodLakesResidentAssnInc",
  TheCanyonsMasterAssn = "theCanyonsMasterAssn",
  TheCedarsInc = "theCedarsInc",
  TheCliffsatCanyonCountryClub = "theCliffsatCanyonCountryClub",
  TheCloisterHomeownersAssociation = "theCloisterHomeownersAssociation",
  TheColonialatLaPalomaHomeownersAssn = "theColonialatLaPalomaHomeownersAssn",
  TheColoniesMasterAssn = "theColoniesMasterAssn",
  TheColoniesUnit9MasterAssnAKATutburyCourtAssn = "theColoniesUnit9MasterAssnAKATutburyCourtAssn",
  TheHamptons = "theHamptons",
  TheReserve = "theReserve",
  TheTownhomesofWestcliff = "theTownhomesofWestcliff",
  TheTrailsAtTascosaGolfClub = "theTrailsAtTascosaGolfClub",
  TheVillageofFourCornersOwnersAssociationInc = "theVillageofFourCornersOwnersAssociationInc",
  TimbercreekCanyonPropertyOwnersAssnInc = "timbercreekCanyonPropertyOwnersAssnInc",
  TimbercreekPropertyOwnersAssnInc = "timbercreekPropertyOwnersAssnInc",
  TownSquareHomeownersAssociation = "townSquareHomeownersAssociation",
  TutburyCourtAssn = "tutburyCourtAssn",
  Versailles = "versailles",
  VillaPreeCondos = "villaPreeCondos",
  West15thCondominiumAssociationInc = "west15thCondominiumAssociationInc",
  WestviewHomeowners = "westviewHomeowners",
  WindwoodPlace = "windwoodPlace",
  Woodstone = "woodstone"
}

export const HoaNameLabel: Record<HoaName, string> = {
  [HoaName.sevenHundredAndFiveDowntownCondoAssn]: "705 Downtown Condo Assn.",
  [HoaName.AmarilloCitadelHomeOwnersAssnInc]:
    "Amarillo Citadel Home Owners Assn., Inc.",
  [HoaName.BeaconPointeMasterAssociation]: "Beacon Pointe Master Association",
  [HoaName.BrennanCourtSleepyHollowTownhomeAssn]:
    "Brennan Court Sleepy Hollow Townhome Assn.",
  [HoaName.BrookplaceOneHomeownersAssnCondos]:
    "Brookplace One Homeowners Assn. (Condos)",
  [HoaName.BrownAcresHOALLC]: "Brown Acres HOA, LLC",
  [HoaName.BryanPlaceHomeownersAssociation]:
    "Bryan Place Homeowners Association",
  [HoaName.CallahanAcresHomeownersAssociation]:
    "Callahan Acres Homeowners Association",
  [HoaName.CanalStreetFlagpoleAssn]: "Canal Street Flagpole Assn.",
  [HoaName.CanyonNorth]: "Canyon North",
  [HoaName.CanyonNorthPropertyOwnersAssn]: "Canyon North Property Owners Assn",
  [HoaName.CanyonRidge]: "Canyon Ridge",
  [HoaName.ChaumontPampa]: "Chaumont - Pampa",
  [HoaName.ConnerTownhomesAssn]: "Conner Townhomes Assn.",
  [HoaName.CottonwoodGlen]: "Cottonwood Glen",
  [HoaName.CottonwoodTownhomes]: "Cottonwood Townhomes",
  [HoaName.CreightonParkHomeownersAssnInc]:
    "Creighton Park Homeowners Assn., Inc.",
  [HoaName.DeerRunAddition]: "Deer Run Addition",
  [HoaName.DoubleDiamondEstates]: "Double Diamond Estates",
  [HoaName.EagleTreeHomeownersAssnInc]: "Eagle Tree Homeowners Assn., Inc.",
  [HoaName.ElkCanyon]: "Elk Canyon",
  [HoaName.FrenchVillageHOA]: "French Village HOA",
  [HoaName.GoldenPondOfficeCondos]: "Golden Pond Office Condos",
  [HoaName.GreenwaysHomeownersAssn]: "Greenways Homeowners Assn.",
  [HoaName.GreenwoodHomeownersAssn]: "Greenwood Homeowners Assn.",
  [HoaName.HeritageHillsHomeownersAssociation]:
    "Heritage Hills Homeowners Association",
  [HoaName.HighCountryEstates]: "High Country Estates",
  [HoaName.IndianBluffofAmarilloUnit8]: "Indian Bluff of Amarillo Unit #8",
  [HoaName.InvernessHoustonPOAInc]: "Inverness Houston POA, Inc.",
  [HoaName.LaPalomaEstatesUnit3TheReserveatLaPaloma]:
    "La Paloma Estates Unit # 3 - The Reserve at La Paloma",
  [HoaName.LaPalomaEstatesUnit6]: "La Paloma Estates Unit #6",
  [HoaName.LaPalomaEstatesUnit7]: "La Paloma Estates Unit #7",
  [HoaName.LaPalomaEstatesUnit9TheEnclave]:
    "La Paloma Estates Unit #9 - The Enclave",
  [HoaName.LaPalomaEstatesUnits14ONLY]: "La Paloma Estates Units #1 & 4 ONLY",
  [HoaName.LaTourCondominiums]: "La Tour Condominiums",
  [HoaName.LakeMeredithHarborOwnersAssociation]:
    "Lake Meredith Harbor Owners Association",
  [HoaName.LakeTanglewoodHomeowners]: "Lake Tanglewood Homeowners",
  [HoaName.LesMaisons]: "Les Maisons",
  [HoaName.LoneStarEstates]: "Lone Star Estates",
  [HoaName.LynnwoodPlaceTownHomes]: "Lynnwood Place Town Homes",
  [HoaName.MesquiteRidgeMasterAssociation]: "Mesquite Ridge Master Association",
  [HoaName.NorthRidgeatLakeAlanHenry]: "North Ridge at Lake Alan Henry",
  [HoaName.OlsenCircleHOA]: "Olsen Circle HOA",
  [HoaName.OlsenPlazaOfficeParkOwnersAssnInc]:
    "Olsen Plaza Office Park Owners Assn., Inc.",
  [HoaName.Other]: "Other",
  [HoaName.PaloDuroCanyonEstates]: "Palo Duro Canyon Estates",
  [HoaName.PinecrestGardensatTascosaChampionship]:
    "Pinecrest Gardens at Tascosa Championship",
  [HoaName.Pinnacle]: "Pinnacle",
  [HoaName.PolkCondominiums]: "Polk Condominiums",
  [HoaName.QuadrilleBusinessParkOwnersAssnInc]:
    "Quadrille Business Park Owners Assn., Inc.",
  [HoaName.QuadrilleHomeownersAssn]: "Quadrille Homeowners Assn.",
  [HoaName.QuadrilleParkTownhouseOwnersAssn]:
    "Quadrille Park Townhouse Owners Assn.",
  [HoaName.RebaVerdeHomeownersAssnInc]: "Reba Verde Homeowners Assn., Inc.",
  [HoaName.RiverFalls]: "River Falls",
  [HoaName.SandSpurHOA]: "Sand Spur HOA",
  [HoaName.SandhillsHomeownersAssn]: "Sandhills Homeowners Assn.",
  [HoaName.ShoresHomeownersAssnInc]: "Shores Homeowners Assn., Inc.",
  [HoaName.SpringCanyonMasterAssociation]: "Spring Canyon Master Association",
  [HoaName.SpringLakeOwnersAssnInc]: "Spring Lake Owners Assn., Inc.",
  [HoaName.SteeplechaseAssn]: "Steeplechase Assn.",
  [HoaName.SundayCanyonPropertyOwnersAssnInc]:
    "Sunday Canyon Property Owners Assn., Inc.",
  [HoaName.TascosaCountryClubEstates]: "Tascosa Country Club Estates",
  [HoaName.TealwoodLakesResidentAssnInc]: "Tealwood Lakes Resident Assn., Inc.",
  [HoaName.TheCanyonsMasterAssn]: "The Canyons Master Assn",
  [HoaName.TheCedarsInc]: "The Cedars, Inc.",
  [HoaName.TheCliffsatCanyonCountryClub]: "The Cliffs at Canyon Country Club",
  [HoaName.TheCloisterHomeownersAssociation]:
    "The Cloister Homeowners Association",
  [HoaName.TheColonialatLaPalomaHomeownersAssn]:
    "The Colonial at La Paloma Homeowner Assn.",
  [HoaName.TheColoniesMasterAssn]: "The Colonies Master Assn.",
  [HoaName.TheColoniesUnit9MasterAssnAKATutburyCourtAssn]:
    "The Colonies Unit #9 Master Assn. (AKA Tutbury Court Assn.)",
  [HoaName.TheHamptons]: "The Hamptons",
  [HoaName.TheReserve]: "The Reserve",
  [HoaName.TheTownhomesofWestcliff]: "The Townhomes of Westcliff",
  [HoaName.TheTrailsAtTascosaGolfClub]: "The Trails At Tascosa Golf Club",
  [HoaName.TheVillageofFourCornersOwnersAssociationInc]:
    "The Village of Four Corners Owners Association, Inc.",
  [HoaName.TimbercreekCanyonPropertyOwnersAssnInc]:
    "Timbercreek Canyon Property Owners Assn., Inc.",
  [HoaName.TimbercreekPropertyOwnersAssnInc]:
    "Timbercreek Property Owners Assn., Inc.",
  [HoaName.TownSquareHomeownersAssociation]:
    "Town Square Homeowners Association",
  [HoaName.TutburyCourtAssn]: "Tutbury Court Assn.",
  [HoaName.Versailles]: "Versailles",
  [HoaName.VillaPreeCondos]: "Villa Pree Condos",
  [HoaName.West15thCondominiumAssociationInc]:
    "West 15th Condominium Association, Inc.",
  [HoaName.WestviewHomeowners]: "Westview Homeowners",
  [HoaName.WindwoodPlace]: "Windwood Place",
  [HoaName.Woodstone]: "Woodstone"
};
