import { FieldRulesProps } from "@homesusa/form";

const roomNumberRule = (
  label: string,
  requiredToSubmit = true
): FieldRulesProps => ({
  label,
  maxLength: 1,
  requiredToSubmit,
  numberFormat: {
    min: 0
  }
});

export const spacesDimensionsRules = {
  numBedrooms: roomNumberRule("Bedrooms", true),
  bathsFull: roomNumberRule("Baths - Full", true),
  bathsHalf: roomNumberRule("Baths - Half", true),
  stories: {
    requiredToSubmit: true,
    label: "Stories"
  }
};
