export enum ExteriorFeatures {
  Awning = "awning",
  BalconyCovered = "balconyCovered",
  BalconyUncovered = "balconyUncovered",
  BuiltInKitchen = "builtInKitchen",
  CoveredPorch = "coveredPorch",
  Deck = "deck",
  DoublePaneWindows = "doublePaneWindows",
  GrillGas = "grillGas",
  GrillOther = "grillOther",
  GuttersFull = "guttersFull",
  GuttersPartial = "guttersPartial",
  OutdoorFireplace = "outdoorFireplace",
  OutdoorKitchen = "outdoorKitchen",
  PatioCovered = "patioCovered",
  PatioEnclosed = "patioEnclosed",
  PatioUncovered = "patioUncovered",
  PestTubesInWall = "pestTubesInWall",
  PrivateBackyard = "privateBackyard",
  PrivateGate = "privateGate",
  SecurityLighting = "securityLighting",
  SpecialYardLighting = "specialYardLighting",
  ZeroLotLine = "zeroLotLine",
  None = "none"
}

export const ExteriorFeaturesLabel = new Map<ExteriorFeatures, string>([
  [ExteriorFeatures.PestTubesInWall, "Pest Tubes in Wall"],
  [ExteriorFeatures.Awning, "Awning"],
  [ExteriorFeatures.BalconyCovered, "Balcony - Covered"],
  [ExteriorFeatures.BalconyUncovered, "Balcony - Uncovered"],
  [ExteriorFeatures.BuiltInKitchen, "Built-In Kitchen"],
  [ExteriorFeatures.CoveredPorch, "Covered Porch"],
  [ExteriorFeatures.Deck, "Deck"],
  [ExteriorFeatures.DoublePaneWindows, "Double Pane Windows"],
  [ExteriorFeatures.GrillGas, "Grill-Gas"],
  [ExteriorFeatures.GrillOther, "Grill-Other"],
  [ExteriorFeatures.GuttersFull, "Gutters-Full"],
  [ExteriorFeatures.GuttersPartial, "Gutters-Partial"],
  [ExteriorFeatures.OutdoorFireplace, "Outdoor Fireplace"],
  [ExteriorFeatures.OutdoorKitchen, "Outdoor Kitchen"],
  [ExteriorFeatures.PatioCovered, "Patio-Covered"],
  [ExteriorFeatures.PatioEnclosed, "Patio-Enclosed"],
  [ExteriorFeatures.PatioUncovered, "Patio-Uncovered"],
  [ExteriorFeatures.PrivateBackyard, "Private Backyard"],
  [ExteriorFeatures.PrivateGate, "Private Gate"],
  [ExteriorFeatures.SecurityLighting, "Security Lighting"],
  [ExteriorFeatures.SpecialYardLighting, "Special Yard Lighting"],
  [ExteriorFeatures.ZeroLotLine, "Zero Lot Line"],
  [ExteriorFeatures.None, "None"]
]);
