export enum Pool {
  Community = "community",
  Private = "private",
  None = "none"
}
export const PoolLabel = new Map<Pool, string>([
  [Pool.Community, "Community"],
  [Pool.Private, "Private"],
  [Pool.None, "None"]
]);
