export enum Fence {
  BackYard = "backYard",
  Block = "block",
  Brick = "brick",
  Gate = "gate",
  Masonry = "masonry",
  None = "none",
  Partial = "partial",
  Privacy = "privacy",
  Security = "security",
  Stone = "stone",
  Vinyl = "vinyl",
  Wood = "wood",
  WroughtIron = "wroughtIron"
}
export const FenceLabel = new Map<Fence, string>([
  [Fence.BackYard, "Back Yard"],
  [Fence.Block, "Block"],
  [Fence.Brick, "Brick"],
  [Fence.Gate, "Gate"],
  [Fence.Masonry, "Masonry"],
  [Fence.None, "None"],
  [Fence.Partial, "Partial"],
  [Fence.Privacy, "Privacy"],
  [Fence.Security, "Security"],
  [Fence.Stone, "Stone"],
  [Fence.Vinyl, "Vinyl"],
  [Fence.Wood, "Wood"],
  [Fence.WroughtIron, "Wrought Iron"]
]);
