import React from "react";
import { Grid } from "@mui/material";

import { AnimatedContainer, useRefresh } from "@homesusa/core";
import { useDocumentTitle } from "@homesusa/layout";
import { ImportSource } from "core/enums";
import { AlertSummaryProvider, ChartDashboardProvider } from "../context";
import { LeftPanel, RightPanel } from "./chart-dashboard";
import { ImportAlertsContext } from "../context/import-alerts";

export const ChartDashboard = (): JSX.Element => {
  useDocumentTitle("Dashboard");
  const [, refreshAlerts] = useRefresh();
  const [hideRemineders, refreshReminders] = useRefresh();
  return (
    <ChartDashboardProvider>
      <AnimatedContainer>
        <ImportAlertsContext.Provider
          value={{
            refreshAlerts,
            refreshReminders,
            importSource: ImportSource.Xml
          }}
        >
          <AlertSummaryProvider>
            <Grid container spacing={2} sx={{ minHeight: "800px" }}>
              <Grid item xs={4}>
                <LeftPanel />
              </Grid>
              {!hideRemineders && (
                <Grid item xs={8}>
                  <RightPanel />
                </Grid>
              )}
            </Grid>
          </AlertSummaryProvider>
        </ImportAlertsContext.Provider>
      </AnimatedContainer>
    </ChartDashboardProvider>
  );
};
