export enum Floors {
  BrickAdobe = "brickAdobe",
  Carpet = "carpet",
  CeramicTile = "ceramicTile",
  Concrete = "concrete",
  Laminate = "laminate",
  LuxuryVinylPlank = "luxuryVinylPlank",
  Marble = "marble",
  Other = "other",
  Parquet = "parquet",
  Slate = "slate",
  Stone = "stone",
  Terrazzo = "terrazzo",
  Tile = "tile",
  TravertineStone = "travertineStone",
  Vinyl = "vinyl",
  Wood = "wood",
  WoodUnderCarpet = "woodUnderCarpet"
}

export const FloorsLabel = new Map<Floors, string>([
  [Floors.BrickAdobe, "Brick/Adobe"],
  [Floors.Carpet, "Carpet"],
  [Floors.CeramicTile, "Ceramic Tile"],
  [Floors.Concrete, "Concrete"],
  [Floors.Laminate, "Laminate"],
  [Floors.LuxuryVinylPlank, "Luxury Vinyl Plank"],
  [Floors.Marble, "Marble"],
  [Floors.Other, "Other"],
  [Floors.Parquet, "Parquet"],
  [Floors.Slate, "Slate"],
  [Floors.Stone, "Stone"],
  [Floors.Terrazzo, "Terrazzo"],
  [Floors.Tile, "Tile"],
  [Floors.TravertineStone, "Travertine Stone"],
  [Floors.Vinyl, "Vinyl"],
  [Floors.Wood, "Wood"],
  [Floors.WoodUnderCarpet, "Wood Under Carpet"]
]);
