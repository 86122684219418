import { FormRules } from "@homesusa/form";
import { addressInfoRules } from "../address-info";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { StreetDirPrefix, StreetDirPrefixLabel } from "markets/abor/enums";

export const lotAddressInfoRules: FormRules = {
  ...addressInfoRules,
  streetDirPrefix: {
    label: "Street Dir Prefix",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetDirPrefix),
      StreetDirPrefixLabel
    ),
    rows: 2
  },
  streetDirSuffix: {
    label: "Street Dir Suffix",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetDirPrefix),
      StreetDirPrefixLabel
    ),
    rows: 2
  }
};
