import { FormRules } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  GarageDescription,
  GarageDescriptionLabel,
  Stories,
  StoriesLabel,
  Garage,
  GarageLabel,
  CarportLabel,
  Carport
} from "markets/ctx/enums";

export const commonPlanRules: FormRules = {
  garageDescription: {
    label: "Garage/Carport Desc",
    lastOptions: [
      GarageDescription.NoneGarage,
      GarageDescription.OtherGarageSeeRemarks
    ],
    options: getOptionsFromEnumAndMap(
      Object.values(GarageDescription),
      GarageDescriptionLabel
    ),
    rows: 2,
    noneOption: [GarageDescription.NoneGarage]
  },
  stories: {
    requiredToSubmit: true,
    label: "# Stories",
    options: getOptionsFromEnumAndMap(Object.values(Stories), StoriesLabel)
  },

  garage: {
    label: "Garage",
    requiredToSubmit: true,
    sort: false,
    lastOptions: [Garage.None],
    options: getOptionsFromEnumAndMap(Object.values(Garage), GarageLabel)
  },
  carport: {
    label: "Carport",
    requiredToSubmit: true,
    sort: false,
    lastOptions: [Carport.None],
    options: getOptionsFromEnumAndMap(Object.values(Carport), CarportLabel)
  }
};
