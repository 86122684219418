export enum Fencing {
  BackYard = "backYard",
  Brick = "brick",
  Cedar = "cedar",
  Electric = "electric",
  Full = "full",
  Privacy = "privacy",
  Stone = "stone",
  Vinyl = "vinyl",
  Wood = "wood",
  WroughtIron = "wroughtIron",
  None = "none",
  OtherSeeRemarks = "otherSeeRemarks"
}

export const FencingLabel = new Map<Fencing, string>([
  [Fencing.BackYard, "Back Yard"],
  [Fencing.Brick, "Brick"],
  [Fencing.Cedar, "Cedar"],
  [Fencing.Electric, "Electric"],
  [Fencing.Full, "Full"],
  [Fencing.Privacy, "Privacy"],
  [Fencing.Stone, "Stone"],
  [Fencing.Vinyl, "Vinyl"],
  [Fencing.Wood, "Wood"],
  [Fencing.WroughtIron, "Wrought Iron"],
  [Fencing.None, "None"],
  [Fencing.OtherSeeRemarks, "Other-See Remarks"]
]);
