export enum FireplaceDescription {
  DualFP = "dualFP",
  Electric = "electric",
  Firepit = "firepit",
  GasLog = "gasLog",
  Insert = "insert",
  Mock = "mock",
  PelletStove = "pelletStove",
  SeeRemarks = "seeRemarks",
  SeeThrough = "seeThrough",
  WoodBurning = "woodBurning",
  WoodBurningStove = "woodBurningStove",
  None = "none",
  Other = "other"
}

export const FireplaceDescriptionLabel = new Map<FireplaceDescription, string>([
  [FireplaceDescription.DualFP, "Dual FP"],
  [FireplaceDescription.Electric, "Electric"],
  [FireplaceDescription.Firepit, "Firepit"],
  [FireplaceDescription.GasLog, "Gas Log"],
  [FireplaceDescription.Insert, "Insert"],
  [FireplaceDescription.Mock, "Mock"],
  [FireplaceDescription.PelletStove, "Pellet Stove"],
  [FireplaceDescription.SeeRemarks, "See Remarks"],
  [FireplaceDescription.SeeThrough, "See Through"],
  [FireplaceDescription.WoodBurning, "Wood Burning"],
  [FireplaceDescription.WoodBurningStove, "Wood Burning Stove"],
  [FireplaceDescription.None, "None"],
  [FireplaceDescription.Other, "Other"]
]);
